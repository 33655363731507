import styled from 'styled-components'

// import {
//     mobileSize
// } from 'ui/styled'

export const Content = styled.div.attrs({})``

export const PageHeader = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px 0;

    margin-bottom: 20px;
`

export const PageHeaderTitle = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: var(--grey-color);
`

export const GrayAlertIcon = styled.img.attrs({
    src: '/icons/gray-alert-icon.svg',
    height: 24,
    width: 24,
})`
    margin: 0 0 1rem;
`

export const TrashIcon = styled.img.attrs({
    src: '/icons/check-green.svg',
    height: 26,
    width: 26,
})`
    margin: 0 0.5rem;
    cursor: pointer;
    margin-right: 10px;
`

export const BlockIcon = styled.img.attrs({
    src: '/icons/close-red.svg',
    height: 26,
    width: 26,
})`
    margin: 0 0.5rem;
    cursor: pointer;
`

export const UnblockIcon = styled.img.attrs({
    src: '/icons/green-checkbox.svg',
    height: 26,
    width: 26,
})`
    margin: 0 0.5rem;
    cursor: pointer;
`

export const IconHolder = styled.div.attrs({})`
    display: flex;
`

export const PageCard = styled.div.attrs({})`
    padding: 24px;
    background: var(--white-color);

    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
    box-shadow: 0px 1.5px 6px var(--shadow-color);
    margin: 32px 0;
`

export const ListText = styled.div.attrs({})`
    ${(props) =>
        props.blocked
            ? `
        color: #909090
        `
            : ``}
`
