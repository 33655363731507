import React from 'react'

import Button from 'components/Form/Button'

import { ModalBody, ModalBodyTitle } from './styled'

export default function ModalChatModeClose({ close, back, currentChat }) {
    return (
        <>
            <ModalBody>
                <ModalBodyTitle>Do you really want to end the chat?</ModalBodyTitle>
                <Button small primary onClick={close}>
                    Close chat
                </Button>
                <Button white={true} onClick={back}>
                    Talk back with {currentChat?.clerck}
                </Button>
            </ModalBody>
        </>
    )
}
