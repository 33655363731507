import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const CardSearched = styled.div.attrs({})`
    min-width: calc(100%);
    max-width: calc(100%);
    background: var(--white-color);
    padding: 24px;
    margin-bottom: 12px;
    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);

    display: flex;
    align-items: flex-start;
    cursor: pointer;
`
export const CardImage = styled.div.attrs({})`
    width: 76px;
    height: 76px;
    border-radius: 38px;
    ${(props) =>
        props.url
            ? `
    background: url(${props.url}) no-repeat center center / cover #fff;
    background-size: 70px;
        `
            : `
    background: url(/images/no-image.png) no-repeat center center / cover;
        `}
`
export const CardContent = styled.div.attrs({})`
    padding: 0 20px;
    flex: 1;
`
export const CardTitle = styled.div.attrs({})`
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--grey-color);
`
export const CardSubtitle = styled.div.attrs({})`
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    margin-top: 3px;
`
export const CardText = styled.div.attrs({})`
    font-size: 13px;
    color: var(--grey-color);
    max-height: 40px;
    overflow: hidden;
    margin: 10px 0 0;
`

export const CardActions = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    height: 100%;
`

export const CardRemove = styled.img.attrs({
    src: '/icons/trash.svg',
})``

export const CardChatStatus = styled.div.attrs({})`
    border-radius: 4px;
    width: 8px;
    height: 8px;
    background: var(--red-color);
`

export const ChatTimeText = styled.div.attrs({})`
    font-size: 13px;
    color: var(--lightgrey-color);
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
`
