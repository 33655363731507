import React, { useContext, useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { CoreContext } from 'context/CoreContext'
import { parseStrapiImage } from 'utils'

import {
    CardSearched,
    CardImage,
    CardContent,
    CardTitle,
    CardSubtitle,
    CardText,
    CardActions,
    CardRemove,
    CardChatStatus,
    ChatTimeText,
} from './styled'

export default function DashboardCardChat({ room, viewMessages, deleteRoom, selectedRoomId }) {
    const { user, findlanguage } = useContext(CoreContext)

    let user_key = user.id

    let sender = room.firstUserId.id === user_key ? room.secondUserId : room.firstUserId

    let avatarUrl = ''

    avatarUrl = sender.avatar ? parseStrapiImage(sender.avatar) : `/images/no-image.png`

    const utcTimeString = room.lastMessage;


    let localDate = new Date(utcTimeString);
    localDate.setTime(localDate.getTime() - (12 * 60 * 60 * 1000));

    const date = localDate.toLocaleString();

    let newMessage = false

    if (room.firstUserId.id === user_key && room.newMessageUserOne === true) newMessage = true

    if (room.secondUserId.id === user_key && room.newMessageUserTwo === true) newMessage = true

    return (
        <>
            <CardSearched
                onClick={() => viewMessages(sender, newMessage)}
                style={{ backgroundColor: room.id === selectedRoomId ? '#e8e8e8' : '' }}
            >
                {newMessage ? <CardChatStatus /> : ''}

                <CardImage url={avatarUrl} />
                <CardContent>
                    <CardTitle>{sender.name}</CardTitle>
                    <CardSubtitle>
                        {sender?.contactName}
                    </CardSubtitle>
                    <CardSubtitle>
                        {sender?.role == 4 ? (
                            <span
                                className="badge"
                                style={{ backgroundColor: '#263640', color: 'white' }}
                            >
                                {findlanguage().supplierTitle}
                            </span>
                        ) : null}

                        {
                            sender?.role == 3 ?
                                <span className="badge bg-primary">{findlanguage().buyerTitle}</span>
                                :
                                null
                        }
                        {
                            sender?.role == 6 ?
                                <span
                                    className="badge"
                                    style={{ backgroundColor: '#263640', color: 'white' }}
                                >
                                    {findlanguage().buyerTitle} / {findlanguage().supplierTitle}
                                </span>
                                :
                                null
                        }
                        {
                            sender?.role == 7 ?
                                <>
                                    <span
                                        className="badge"
                                        style={{ backgroundColor: '#4182ab', color: 'white' }}
                                    >
                                        {findlanguage().headquarters}
                                    </span>
                                </>
                                :
                                null
                        }

                        {sender?.role == 5 ? (
                            <span
                                className="badge"
                                style={{ backgroundColor: '#ded514', color: 'white' }}
                            >
                                {findlanguage().serviceProviderTitle}
                            </span>
                        ) : null}
                    </CardSubtitle>
                    <CardText>{date}</CardText>
                </CardContent>
            </CardSearched>
        </>
    )
}
