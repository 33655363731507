import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import Card from '../Card/index.js'

import { PageCard, CardHeader, CardHeaderTitleContainer } from './styled'
import _ from 'lodash'

export default function B12({
    certifications,
    onChangeCertifications,
    saveCertifications,
    saveDocument,
    form,
    returnHeaders,
    mfsQuestionarie
}) {
    const { findlanguageEsg } = useContext(CoreContext)

    //#region B.1.2
    const b12 = {
        ref: 'B.1.2',
        requirementTitle: findlanguageEsg().b12RequirementTitle,
        inputForYesAnswer: true,
        placeHolderTextYes: findlanguageEsg().b12PlaceHolderTextYes,
        inputForNoAnswer: false,
        placeHolderTextNo: findlanguageEsg().b12PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    //#region B.1.2 Yes

    //#region B.1.2.Y.A

    const b12ya = {
        ref: 'B.1.2.Y.A',
        requirementTitle: findlanguageEsg().b12yaRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().b12yaPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const b12yay = {
        ref: 'B.1.2.Y.A.Y',
        requirementTitle: findlanguageEsg().b12yayRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().b12yayPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const b12yayy = {
        ref: 'B.1.2.Y.A.Y.Y',
        requirementTitle: findlanguageEsg().b12yayyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().b12yayyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
    }

    const b12yayyql1 = {
        ref: 'B.1.2.Y.A.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().b12yayyqlRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().b12yayyqlPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        fileForYesAnswerAndNoInput: true,
        color: '#2f8c5d',
        question: true,
    }

    //#endregion

    //#region B.1.2.Y.B

    const b12yb = {
        ref: 'B.1.2.Y.B',
        requirementTitle: findlanguageEsg().b12ybRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().b12ybPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const b12yby = {
        ref: 'B.1.2.Y.B.Y',
        requirementTitle: findlanguageEsg().b12ybyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: findlanguageEsg().b12ybyPlaceHolderTextYes,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().b12ybyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const b12ybyyqt1 = {
        ref: 'B.1.2.Y.B.Y.Y.QT.1',
        requirementTitle: findlanguageEsg().b12ybyyqt1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const b12ybyyqt2 = {
        ref: 'B.1.2.Y.B.Y.Y.QT.2',
        requirementTitle: findlanguageEsg().b12ybyyqt2RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const b12ybyyqtn = {
        ref: 'B.1.2.Y.B.Y.Y.QT.N',
        requirementTitle: findlanguageEsg().b12ybyyqtnRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const b12ybyyql1 = {
        ref: 'B.1.2.Y.B.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().b12ybyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().b12ybyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.C

    const b12yc = {
        ref: 'B.1.2.Y.C',
        requirementTitle: findlanguageEsg().b12ycRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().b12ycPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const b12ycy = {
        ref: 'B.1.2.Y.C.Y',
        requirementTitle: findlanguageEsg().b12ycyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().b12ycyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const b12ycyy = {
        ref: 'B.1.2.Y.C.Y.Y',
        requirementTitle: findlanguageEsg().b12ycyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().b12ycyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const b12ycyyql1 = {
        ref: 'B.1.2.Y.C.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().b12ycyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().b12ycyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.D

    const b12yd = {
        ref: 'B.1.2.Y.D',
        requirementTitle: findlanguageEsg().b12ydRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().b12ydPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const b12ydy = {
        ref: 'B.1.2.Y.D.Y',
        requirementTitle: findlanguageEsg().b12ydyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().b12ydyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const b12ydyy = {
        ref: 'B.1.2.Y.D.Y.Y',
        requirementTitle: findlanguageEsg().b12ydyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().b12ydyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const b12ydyyql1 = {
        ref: 'B.1.2.Y.D.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().b12ydyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().b12ydyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#endregion

    //#region A.1.1 NO
    const b12na = {
        ref: 'B.1.2.N.A',
        requirementTitle: findlanguageEsg().b12naRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().b12naPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const b12nb = {
        ref: 'B.1.2.N.B',
        requirementTitle: findlanguageEsg().b12nbRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().b12nbPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const b12nc = {
        ref: 'B.1.2.N.C',
        requirementTitle: findlanguageEsg().b12ncRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().b12ncPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    const returnCondition = (item, layers, category1, category2, category3, category4) => {
        switch (layers) {
            case 0:
                return (
                    <Card
                        item={item}
                        onChangeCertifications={onChangeCertifications}
                        saveCertifications={saveCertifications}
                        saveDocument={saveDocument}
                        certifications={certifications}
                        form={form}
                        mfsQuestionarie={mfsQuestionarie}
                    />
                )
                break
            case 1:
                if (certifications?.find((x) => x.category === category1)?.next_level === true)
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 2:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 3:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 4:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true &&
                    certifications?.find((x) => x.category === category4)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            default:
                break
        }
    }

    return (
        <>
            <PageCard>
                <CardHeader>
                    <CardHeaderTitleContainer>
                        {findlanguageEsg().b12CardHeaderTitleContainer}
                    </CardHeaderTitleContainer>
                </CardHeader>
                {returnHeaders()}
                {returnCondition(b12, 0)}
            </PageCard>
            {
                mfsQuestionarie !== '1' ?
                    <>
                        {
                            certifications?.find((x) => x.category === 'B.1.2')?.next_level === true ? (
                            <>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            B.1.2 CARBON FOOTPRINT REDUCTION - Answer Yes - A
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(b12ya, 1, 'B.1.2')}
                                    {returnCondition(b12yay, 2, 'B.1.2', 'B.1.2.Y.A')}
                                    {returnCondition(b12yayy, 3, 'B.1.2', 'B.1.2.Y.A', 'B.1.2.Y.A.Y')}
                                    {returnCondition(
                                        b12yayyql1,
                                        4,
                                        'B.1.2',
                                        'B.1.2.Y.A',
                                        'B.1.2.Y.A.Y',
                                        'B.1.2.Y.A.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            B.1.2 CARBON FOOTPRINT REDUCTION - Answer Yes - B
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(b12yb, 1, 'B.1.2')}
                                    {returnCondition(b12yby, 2, 'B.1.2', 'B.1.2.Y.B')}
                                    {returnCondition(b12ybyyqt1, 3, 'B.1.2', 'B.1.2.Y.B', 'B.1.2.Y.B.Y')}
                                    {returnCondition(b12ybyyqt2, 3, 'B.1.2', 'B.1.2.Y.B', 'B.1.2.Y.B.Y')}
                                    {returnCondition(b12ybyyqtn, 3, 'B.1.2', 'B.1.2.Y.B', 'B.1.2.Y.B.Y')}
                                    {returnCondition(b12ybyyql1, 3, 'B.1.2', 'B.1.2.Y.B', 'B.1.2.Y.B.Y')}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            B.1.2 CARBON FOOTPRINT REDUCTION - Answer Yes - C
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(b12yc, 1, 'B.1.2')}
                                    {returnCondition(b12ycy, 2, 'B.1.2', 'B.1.2.Y.C')}
                                    {returnCondition(b12ycyy, 3, 'B.1.2', 'B.1.2.Y.C', 'B.1.2.Y.C.Y')}
                                    {returnCondition(
                                        b12ycyyql1,
                                        4,
                                        'B.1.2',
                                        'B.1.2.Y.C',
                                        'B.1.2.Y.C.Y',
                                        'B.1.2.Y.C.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            B.1.2 CARBON FOOTPRINT REDUCTION - Answer Yes - D
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(b12yd, 1, 'B.1.2')}
                                    {returnCondition(b12ydy, 2, 'B.1.2', 'B.1.2.Y.D')}
                                    {returnCondition(b12ydyy, 3, 'B.1.2', 'B.1.2.Y.D', 'B.1.2.Y.D.Y')}
                                    {returnCondition(
                                        b12ydyyql1,
                                        4,
                                        'B.1.2',
                                        'B.1.2.Y.D',
                                        'B.1.2.Y.D.Y',
                                        'B.1.2.Y.D.Y.Y'
                                    )}
                                </PageCard>
                            </>
                        ) : null}
                        {certifications?.find((x) => x.category === 'B.1.2')?.next_level === false ? (
                            <PageCard>
                                <CardHeader>
                                    <CardHeaderTitleContainer>
                                        B.1.2 CARBON FOOTPRINT REDUCTION - Answer No
                                    </CardHeaderTitleContainer>
                                </CardHeader>
                                {returnHeaders()}
                                {returnCondition(b12na, 0)}
                                {returnCondition(b12nb, 0)}
                                {returnCondition(b12nc, 0)}
                            </PageCard>
                        ) : null}
                    </>
                    :
                    null
            }
        </>
    )
}
