import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { ContentScroll, ScrollWrapper } from './styled';

import DashboardPanel from 'components/Dashboard/Panels/Wrapper';
import DashboardCardMyWish from 'components/Dashboard/Cards/MyWish';
import { FetchWishList } from 'services/dashboard';
import { Load } from 'ui/styled';
import { CoreContext } from 'context/CoreContext';
import { NewConnection } from 'services/connections';

export default function DashboardPanelMyWish({ cardTitle, onClose }) {
    const { setModal, premium, setNewChat, user, findlanguage } = useContext(CoreContext);
    const history = useHistory();
    const navigate = (to) => history.push(`/${to}`);

    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(false);

    const panel = {
        title: findlanguage().myWishList,
        actions: [],
        onClose,
        big: true,
    };

    const checkAccess = () => {
        return premium.type === 'free';
    };

    const loadWishlist = async () => {
        setLoading(true);
        try {
            const wishlist = await FetchWishList();
            const formattedCards = wishlist.map((card) => ({
                wishlist_id: card.wishlist_id,
                id: card.id,
                image: card.url || '/images/no-image.png',
                title: card.company_name,
                subtitle: card.state && card.country ? `${card.state}, ${card.country}` : '',
                label: '',
                labelIcon: '',
                description: '',
                actions: [
                    {
                        title: findlanguage().sendMessage,
                        small: true,
                        action: () => createNewChat(card.id),
                    },
                    {
                        title: findlanguage().connect,
                        outline: true,
                        dark: true,
                        small: true,
                        action: () => addConnection(card.id),
                    },
                ],
            }));
            setCards(formattedCards);
        } catch (error) {
            console.error('Failed to load wishlist:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!checkAccess()) {
            loadWishlist();
        }
    }, []);

    const addConnection = async (id) => {
        setModal({
            type: 'loading',
            text: 'Creating new connection.',
        });

        try {
            const response = await NewConnection(id);

            if (response.statusCode === 400) {
                setModal({
                    type: 'alert',
                    text: 'This connection exists already.',
                    warning: true,
                });
            } else if (response.statusCode) {
                setModal({
                    type: 'alert',
                    text: 'Failed to create connection.',
                    warning: true,
                });
            } else {
                setModal({
                    type: 'alert',
                    text: 'New connection created.',
                });
                loadWishlist();
            }
        } catch (error) {
            setModal({
                type: 'alert',
                text: 'Failed to create connection due to an error.',
                warning: true,
            });
            console.error('Failed to create connection:', error);
        }
    };

    const createNewChat = (id) => {
        const user_key = user.team_access ? user.company_id : user.id;
        setNewChat({
            firstUserId: user_key,
            secondUserId: id,
            newMessageUserOne: false,
            newMessageUserTwo: false,
            lastMessage: new Date().toISOString(),
        });
        navigate('dashboard/message');
    };

    return (
        <DashboardPanel {...panel} subscription={premium?.type} locked={checkAccess()}>
            {loading ? (
                <Load color="#263640" />
            ) : (
                <ContentScroll>
                    <ScrollWrapper>
                        {cards.length === 0 ? (
                            <div style={{ width: '100%' }}>{findlanguage().noWishListFound}</div>
                        ) : (
                            cards.map((item, key) => (
                                <DashboardCardMyWish key={`card-${key}`} {...item} refresh={loadWishlist} />
                            ))
                        )}
                    </ScrollWrapper>
                </ContentScroll>
            )}
        </DashboardPanel>
    );
}
