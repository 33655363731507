import styled from 'styled-components'

export const CheckContent = styled.div.attrs({})`
    margin: 20px 0 0;
`

export const CheckHeader = styled.div.attrs({})`
    display: flex;
    cursor: pointer;
`

export const CheckList = styled.div.attrs({})`
    padding: 0 20px 20px;
`

export const CheckIcon = styled.img.attrs({
    src: '/icons/chevron.svg',
})`
    padding: 0 20px;
`
export const SelectManyActionIcon = styled.img.attrs({
    src: '/icons/add.svg',
})`
    margin-right: 12px;
`
export const SelectManyText = styled.div.attrs({})``
