import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'

import Button from 'components/Form/Button'
import Input from 'components/Form/Input'
import Textarea from 'components/Form/Textarea'

import {
    ModalHeader,
    ModalHeaderSpace,
    ModalHeaderClose,
    ModalBody,
    ModalImage,
    ModalTitle,
    ModalDescription,
} from './styled'

export default function ModalChatModeLobby({ close, handleStart }) {
    const [name, setName] = useState('')
    const [value, setValue] = useState('')

    const valid = (verbose = false) => {
        if (!value || !value.length) {
            if (verbose) {
                toast.error('Fill the field: What`s going on')
            }
            return false
        }

        if (!name || !name.length) {
            if (verbose) {
                toast.error('Fill the field: Name')
            }
            return false
        }

        return true
    }

    const start = () => {
        if (!valid(true)) {
            return
        }
        if (handleStart && typeof handleStart === 'function') {
            handleStart(name, value)
        }
    }

    return (
        <>
            <ModalHeader>
                <ModalHeaderSpace />
                <ModalHeaderClose onClick={close} />
            </ModalHeader>

            <ModalBody>
                <ModalImage />
                <ModalTitle>Welcome</ModalTitle>
                <ModalDescription>How can we help you today?</ModalDescription>
                <Input
                    placeholder={'Name'}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <Textarea
                    label={'Tell us what`s going on'}
                    maxLength={300}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                />
                <Button small primary onClick={start}>
                    Start Chat
                </Button>
            </ModalBody>
        </>
    )
}
