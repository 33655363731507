import React, { useContext, useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import {
    ProfileFormContent,
    ProfileFormActions,
    RequirementContent,
    ProfileFormTitle,
    ProfileLabel,
    PageFormSpace,
} from './styled'

import Input from 'components/Form/Input'
import Button from 'components/Form/Button'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

import { AddRequirement, GetRequirement, UpdateRequirement } from 'services/myProfile'

export default function ModalAddRequirement() {
    const { modal, setModal, user, findlanguage } = useContext(CoreContext)

    const [requirement, setRequirement] = useState('')

    const [highlight, setHighlight] = useState(false)

    const action = async () => {
        let _body

        if (modal.id === undefined) {
            if (requirement === undefined || requirement.trim().length === 0) {
                toast.error('Fill the field: Requirement')
                setHighlight(true)
                return
            }

            setHighlight(false)

            _body = {
                requirement: requirement,
                user_id: user.company_id,
            }

            await AddRequirement(_body)
        } else {
            _body = { requirement: requirement }

            await UpdateRequirement(modal.id, _body)
        }

        setModal({
            type: 'alert',
            text:
                modal.id === undefined
                    ? findlanguage().requirementAdded
                    : findlanguage().changesSaved,
        })

        const requestRefresh = modal.onRefresh

        if (requestRefresh) requestRefresh()
    }

    useEffect(() => {
        if (modal.id !== undefined) {
            GetRequirement(modal.id).then((resp) => {
                setRequirement(resp.requirement)
            })
        }
    }, [modal.id])

    return (
        <>
            <ModalWrapper>
                <ProfileFormContent>
                    <Input
                        placeholder={findlanguage().requirement}
                        outline
                        value={requirement}
                        onChange={(e) => setRequirement(e.target.value)}
                        big
                        highlight={highlight}
                    />
                </ProfileFormContent>
                <PageFormSpace />
                <ProfileFormActions>
                    <Button small primary onClick={action}>
                        {findlanguage().add}
                    </Button>
                </ProfileFormActions>
            </ModalWrapper>
        </>
    )
}
