import React, { useContext } from 'react'

import { SuccessIcon, SuccessText, WarningIcon } from './styled'

import Button from 'components/Form/Button'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

export default function ModalAlert() {
    const { modal, setModal, findlanguage } = useContext(CoreContext)

    const close = () => {
        setModal(null)
    }

    return (
        <>
            <ModalWrapper successable>
                {modal.warning ? <WarningIcon /> : <SuccessIcon />}
                <SuccessText>{modal?.text}</SuccessText>
                <SuccessText description>{modal?.description}</SuccessText>
                {modal?.cancel === true ? (
                    <div style={{ display: 'inline-flex' }}>
                        <Button
                            small
                            primary
                            onClick={() => modal?.confirmRemoveSupplier(modal?.idConfirm)}
                        >
                            {findlanguage().confirm}
                        </Button>
                        <div style={{ width: '30px' }}></div>
                        <Button small secundary onClick={close}>
                            {findlanguage().cancel}
                        </Button>
                    </div>
                ) : (
                    <Button small primary onClick={close}>
                        OK
                    </Button>
                )}
            </ModalWrapper>
        </>
    )
}
