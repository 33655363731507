import styled from 'styled-components'

export const ProfileFormContent = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: center;
    padding: 0 10px 10px;
    width: 100%;
`

export const ProfileFormActions = styled.div.attrs({})`
    display: flex;
    justify-content: center;
`

export const PageFormSpace = styled.div.attrs({})`
    margin-right: 12px;
`

export const Header = styled.div.attrs({})`
    font-size: 17px;
`

export const SemiHeader = styled.div.attrs({})`
    font-size: 15px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    ${(props) => (props.highlight ? `color: red` : `color: #000`)}
`

export const PermissionsTab = styled.div.attrs({})`
    display: grid;
    grid-template-columns: 2.5fr 2fr 2fr;
    grid-gap: 20px;
    margin-bottom: 30px;
`
