import React from 'react'

import { Action } from './styled'

export default function ButtonIcon({ link, children, onClick }) {
    return (
        <>
            <Action link={link} onClick={onClick}>
                {children}
            </Action>
        </>
    )
}
