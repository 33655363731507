import React, { useContext, useEffect, useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { CoreContext } from 'context/CoreContext'
import { toast } from 'react-toastify'

import {
    PageHeader,
    PageHeaderTitle,
    GrayAlertIcon,
    PageCard,
    IconHolder,
    BlockIcon,
    UnblockIcon,
    TrashIcon,
    ListText,
    EditIcon,
    SendIcon,
} from './styled'

import ContainerAuthenticated from 'containers/Authenticated'
import Button from 'components/Form/Button'
import DashboardFilterSearch from 'components/Dashboard/Filter/Search'
import { Load } from 'ui/styled'
import {
    BlockTeamMembers,
    DeleteTeamMembers,
    FetchTeamMembers,
    ResendInvite,
} from 'services/teamAccess'

export default function DashboardTeamAccess() {
    const { user, setModal, teamAccessList, setTeamAccessList, findlanguage, premium, language } =
        useContext(CoreContext)

    const displayCreateUserModal = () => {
        if (premium.num_users <= teamAccessList.length) {
            toast.error(
                language === 'portugues'
                    ? 'Você já chegou ao limite de usuários para seu plano'
                    : 'You have reached the limit of users for your subscription plan.'
            )
        } else {
            setModal({
                type: 'team-access',
                is_company_visible: true
            })
        }
    }

    const editUserModal = (id, is_company_visible) => {
        setModal({
            type: 'team-access',
            onRefresh: fetchUserList,
            id: id,
            is_company_visible: is_company_visible
        })
    }

    const [searchExpression, setSearchExpression] = useState('')

    const fetchUserList = async () => {
        const updatedList = await FetchTeamMembers()
        if (updatedList.statusCode === 400) return

        console.log(updatedList, 'updatedList')
        setTeamAccessList(updatedList)
    }

    const askDelete = (id) => {
        setModal({
            type: 'choose',
            title: findlanguage().doYouWantToDeleteTheUser,
            options: [
                { title: findlanguage().no, action: () => setModal(null) },
                { title: findlanguage().yes, action: () => deleteMyUser(id), outline: true },
            ],
        })
    }

    const deleteMyUser = async (id) => {
        setModal({
            type: 'loading',
            title: findlanguage().doYouWantToDeleteTheUser,
        })
        const request = await DeleteTeamMembers(id)
            .then((r) => r)
            .catch((err) => {
                return false
            })

        if (!request) {
            toast.error('Error on delete')
            setModal(null)
            return
        }

        toast.success(findlanguage().userDeletedSuccessfully)

        const updatedList = await FetchTeamMembers()
        setTeamAccessList(updatedList)
        setModal(null)
    }

    const askSend = (team) => {
        setModal({
            type: 'choose',
            title: findlanguage().doYouWantToSendTheInvitation,
            options: [
                { title: findlanguage().no, action: () => setModal(null) },
                { title: findlanguage().yes, action: () => sendInvite(team), outline: true },
            ],
        })
    }

    const sendInvite = async (team) => {
        setModal({
            type: 'loading',
            title: findlanguage().doYouWantToSendTheInvitation,
        })

        const request = await ResendInvite(team.access_account.id)
            .then((r) => r)
            .catch((err) => {
                return false
            })

        if (!request) return

        toast.success(findlanguage().inviteSent)
        setModal(null)
    }

    const askBlock = (id) => {
        setModal({
            type: 'choose',
            title: findlanguage().doYouWantToBlockTheUser,
            options: [
                { title: findlanguage().no, action: () => setModal(null) },
                { title: findlanguage().yes, action: () => blockMyUser(id), outline: true },
            ],
        })
    }

    const blockMyUser = async (id) => {
        setModal({
            type: 'loading',
            title: findlanguage().doYouWantToBlockTheUser,
        })
        const request = await BlockTeamMembers(id)
            .then(() => true)
            .catch((err) => {
                return false
            })

        if (!request) return

        toast.success(findlanguage().userBlockedSuccessfully)

        const updatedList = await FetchTeamMembers()
        setTeamAccessList(updatedList)
        setModal(null)
    }

    const askUnblock = (id) => {
        setModal({
            type: 'choose',
            title: findlanguage().doYouWantReactivateTheUser,
            options: [
                { title: findlanguage().no, action: () => setModal(null) },
                { title: findlanguage().yes, action: () => unblockMyUser(id), outline: true },
            ],
        })
    }

    const unblockMyUser = async (id) => {
        setModal({
            type: 'loading',
            title: findlanguage().doYouWantReactivateTheUser,
        })
        const request = await BlockTeamMembers(id)
            .then(() => true)
            .catch((err) => {
                return false
            })

        if (!request) return

        toast.success(findlanguage().userReactivatedSuccessfully)

        const updatedList = await FetchTeamMembers()
        setTeamAccessList(updatedList)
        setModal(null)
    }

    useEffect(() => {
        fetchUserList()
    }, [])

    const listSelector = () => {
        if (!teamAccessList) return loadingListComponent
        if (teamAccessList.length !== 0) return teamListComponent()
        else return emtpyListComponent
    }

    const loadingListComponent = (
        <Row
            style={{
                minHeight: '60vh',
                justifyContent: 'center',
                display: 'grid',
                alignItems: 'center',
            }}
        >
            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Load white />
            </Col>
        </Row>
    )

    const emtpyListComponent = (
        <Row
            style={{
                minHeight: '60vh',
                justifyContent: 'center',
                display: 'grid',
                alignItems: 'center',
            }}
        >
            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <GrayAlertIcon />
                <p style={{ color: '#838383' }}>{findlanguage().noUsersCreatedYet}</p>
            </Col>
        </Row>
    )

    const onChangeFilter = (val) => {
        setSearchExpression(val)
    }

    const teamListComponent = () => (
        <PageCard>
            <Row style={{ marginBottom: '.6rem', color: '#707070' }}>
                <Col md="3">{findlanguage().nameNonMandatory}</Col>
                <Col md>{findlanguage().emailNonMandatory}</Col>
                <Col md="auto">{findlanguage().actions}</Col>
            </Row>
            {teamAccessList
                .filter((teamMember) => {
                    if (!searchExpression) return true
                    return teamMember.name.includes(searchExpression)
                })
                .map((teamMember, i) => (
                    <Row key={i} style={{ padding: '.8rem 0', borderTop: '1px solid #70707020' }}>
                        <Col md="3">
                            <ListText blocked={teamMember?.access_account?.blocked}>
                                {teamMember.name}
                            </ListText>
                        </Col>
                        <Col md>
                            <ListText blocked={teamMember?.access_account?.blocked}>
                                {teamMember.email}
                                {teamMember?.access_account?.blocked
                                    ? ` ${findlanguage().blocked}`
                                    : ''}
                            </ListText>
                        </Col>
                        <Col md="auto">
                            <IconHolder>
                                <SendIcon onClick={() => askSend(teamMember)} />

                                <EditIcon onClick={() => editUserModal(teamMember.id, teamMember.access_account.is_company_visible)} />

                                {teamMember?.access_account?.blocked ? (
                                    <UnblockIcon onClick={() => askUnblock(teamMember.id)} />
                                ) : (
                                    <BlockIcon onClick={() => askBlock(teamMember.id)} />
                                )}

                                <TrashIcon onClick={() => askDelete(teamMember.id)} />
                            </IconHolder>
                        </Col>
                    </Row>
                ))}
        </PageCard>
    )

    if ((user.IsCompanyUser && user.role.id !== 8 ) || premium.type === 'free') {
        return (
            <>
                <ContainerAuthenticated>
                    <Container fluid={false}>
                        <Row>
                            <Col>
                                <PageHeader>
                                    <PageHeaderTitle>{findlanguage().myUsers}</PageHeaderTitle>
                                </PageHeader>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div>Access Forbidden</div>
                            </Col>
                        </Row>
                    </Container>
                </ContainerAuthenticated>
            </>
        )
    } else {
        return (
            <>
                <ContainerAuthenticated>
                    <Container fluid={false}>
                        <Row style={{ gap: '1rem' }} noGutters={true}>
                            <Col md={'auto'} style={{ display: 'flex', justifyContent: 'center' }}>
                                <PageHeader style={{ margin: 0 }}>
                                    <PageHeaderTitle>{findlanguage().myUsers}</PageHeaderTitle>
                                </PageHeader>
                            </Col>
                            <Col>
                                <Row style={{ padding: '0 1rem' }}>
                                    <DashboardFilterSearch
                                        style={{ marginBottom: 0, borderRadius: 99 }}
                                        placeholder={findlanguage().search}
                                        value={searchExpression}
                                        onChangeList={(e) => onChangeFilter(e.target.value)}
                                    />
                                </Row>
                            </Col>
                            {premium.type !== 'free' && (
                                <Col
                                    md={'auto'}
                                    style={{ display: 'flex', justifyContent: 'center' }}
                                >
                                    <Button
                                        onClick={displayCreateUserModal}
                                        style={{ margin: 0, padding: '0 2rem', height: '3rem' }}
                                        primary
                                    >
                                        {findlanguage().newUser}
                                    </Button>
                                </Col>
                            )}
                        </Row>

                        {listSelector()}
                    </Container>
                </ContainerAuthenticated>
            </>
        )
    }
}
