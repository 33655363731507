import styled from 'styled-components'

export const Content = styled.div.attrs({})``

export const PageHeader = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 20px;
`
export const PageHeaderTitle = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: var(--grey-color);
`
export const PageHeaderActions = styled.div.attrs({})`
    display: flex;
    align-items: center;

    position: relative;
`
export const PageHeaderBack = styled.img.attrs({
    src: '/icons/back.svg',
})`
    cursor: pointer;
    margin-right: 20px;
`

export const PageCard = styled.div.attrs({})`
    padding: 24px;
    background: var(--white-color);

    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
    box-shadow: 0px 1.5px 6px var(--shadow-color);
    margin-bottom: 20px;
`
export const ProfileContainer = styled.div.attrs({})`
    display: flex;
`
export const ProfileContent = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const ProfileFormContent = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: space-between;
    max-width: 1120px;
    margin: 0 auto;
`

export const PageScroller = styled.div.attrs({})`
    margin-top: 20px;
    height: 640px;
    overflow: auto;
`
