import React, { useState, useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const SupplierStatusPieChart = ({ showDataLabels, supplierRequest }) => {
    const [timeFrame, setTimeFrame] = useState('all');

    const chartData = useMemo(() => {
        const now = new Date();
        now.setHours(0, 0, 0, 0);

        const statusCounts = {
            'Condition': 0,
            'Expired': 0,
            'Pending Approval': 0,
            'Pending Document': 0,
            'Active': 0
        };

        supplierRequest.forEach(item => {
            const createdAt = new Date(item.updated_at);
            const daysAgo = (now - createdAt) / (1000 * 60 * 60 * 24);

            let include = false;
            switch (timeFrame) {
                case 'last7days':
                    include = daysAgo < 7;
                    break;
                case 'last30days':
                    include = daysAgo < 30;
                    break;
                case 'current':
                    include = daysAgo < 1;
                    break;
                case 'all':
                    include = true;
                    break;
                default:
                    break;
            }

            if (include && item.supplier_status_requirements) {
                const status = item.supplier_status_requirements;
                statusCounts[status] = (statusCounts[status] || 0) + 1;
            }
        });

        const filteredLabels = Object.keys(statusCounts).filter(key => statusCounts[key] > 0);
        const filteredData = filteredLabels.map(key => statusCounts[key]);

        return {
            labels: filteredLabels,
            datasets: [{
                data: filteredData,
                backgroundColor: [
                    '#7189bf', // Condition
                    '#df7599', // Expired
                    '#ffc785', // Pending Approval
                    '#72d6c9', // Pending Document
                    '#90d7ff'  // Active
                ],
                borderColor: [
                    '#7189bf',
                    '#df7599',
                    '#ffc785',
                    '#72d6c9',
                    '#90d7ff'
                ],
                borderWidth: 1
            }]
        };
    }, [supplierRequest, timeFrame]);

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    usePointStyle: true,
                }
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.label}: ${tooltipItem.raw}`;
                    }
                }
            },
            datalabels: {
                display: true,
                color: showDataLabels ? 'black' : 'white',
                align: 'center',
                font: {
                    weight: 'bold',
                    size: 14
                },
                formatter: (value, context) => {
                    if (showDataLabels) {
                        const label = context.chart.data.labels[context.dataIndex];
                        return `${label}: ${value}`;
                    } else {
                        return value;
                    }
                }
            }
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                bottom: 75,
                top: 0
            }
        },
        radius: '90%'
    };

    return (
        <div style={{ height: '385px' }}>
            <select value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)} style={{ marginBottom: '10px' }}>
                <option value="current">Current</option>
                <option value="last7days">Last 7 Days</option>
                <option value="last30days">Last 30 Days</option>
                <option value="all">All</option>
            </select>
            <h2>Status Requirements</h2>
            <Doughnut data={chartData} options={options} plugins={[ChartDataLabels]} />
        </div>
    );
};

export default SupplierStatusPieChart;
