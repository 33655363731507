import styled from 'styled-components'

export const Title = styled.div.attrs({})`
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    color: #fff;
    margin-top: 14px;
    margin-bottom: 40px;
    width: 400px;
    text-align: center;
    font-weight: 400;
    margin-left: 15px;
`

export const Text = styled.div.attrs({})`
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 56px;
    color: #fff;
    width: 400px;
    line-height: 70px;
    text-align: center;
    margin-left: 15px;
    margin-bottom: 60px;
`
