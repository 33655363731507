import React, { useContext } from 'react'

import { FooterContainer, FooterSpace } from './styled'

import Button from 'components/Form/Button'
import { CoreContext } from 'context/CoreContext'

export default function RegisterFooter({ action, loading, back, single }) {
    const { setModal, findlanguage } = useContext(CoreContext)

    return (
        <>
            <FooterContainer centred={true}>
                {single ? (
                    <>
                        <Button primary small onClick={action} loading={loading}>
                            {findlanguage().createAccount}
                        </Button>
                    </>
                ) : (
                    <>
                        {back ? (
                            <>
                                <Button outline primary small onClick={back} loading={loading}>
                                    {findlanguage().prevStep}
                                </Button>
                                <FooterSpace />
                                <Button primary small onClick={action} loading={loading}>
                                    {findlanguage().createAccount}
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button primary small onClick={action} loading={loading}>
                                    {findlanguage().nextStep}
                                </Button>
                            </>
                        )}
                    </>
                )}
            </FooterContainer>
        </>
    )
}
