import { GET } from './api'

export const ReadCountries = async () => {
    //return await GET(`/places/countries`, false)

    return [
        { id: 233, name: 'United States', iso2: 'US', name_pt: 'Estados Unidos' },
        { id: 1, name: 'Afghanistan', iso2: 'AF', name_pt: 'Afeganistão' },
        { id: 2, name: 'Aland Islands', iso2: 'AX', name_pt: 'Ilhas Aland' },
        { id: 3, name: 'Albania', iso2: 'AL', name_pt: 'Albânia' },
        { id: 4, name: 'Algeria', iso2: 'DZ', name_pt: 'Argélia' },
        { id: 5, name: 'American Samoa', iso2: 'AS', name_pt: 'Samoa Americana' },
        { id: 6, name: 'Andorra', iso2: 'AD', name_pt: 'Andorra' },
        { id: 7, name: 'Angola', iso2: 'AO', name_pt: 'Angola' },
        { id: 8, name: 'Anguilla', iso2: 'AI', name_pt: 'Anguilla' },
        { id: 9, name: 'Antarctica', iso2: 'AQ', name_pt: 'Antártica' },
        { id: 10, name: 'Antigua And Barbuda', iso2: 'AG', name_pt: 'Antígua e Barbuda' },
        { id: 11, name: 'Argentina', iso2: 'AR', name_pt: 'Argentina' },
        { id: 12, name: 'Armenia', iso2: 'AM', name_pt: 'Armênia' },
        { id: 13, name: 'Aruba', iso2: 'AW', name_pt: 'Aruba' },
        { id: 14, name: 'Australia', iso2: 'AU', name_pt: 'Austrália' },
        { id: 15, name: 'Austria', iso2: 'AT', name_pt: 'Áustria' },
        { id: 16, name: 'Azerbaijan', iso2: 'AZ', name_pt: 'Azerbaijão' },
        { id: 17, name: 'The Bahamas', iso2: 'BS', name_pt: 'Bahamas' },
        { id: 18, name: 'Bahrain', iso2: 'BH', name_pt: 'Bahrein' },
        { id: 19, name: 'Bangladesh', iso2: 'BD', name_pt: 'Bangladesh' },
        { id: 20, name: 'Barbados', iso2: 'BB', name_pt: 'Barbados' },
        { id: 21, name: 'Belarus', iso2: 'BY', name_pt: 'Belarus' },
        { id: 22, name: 'Belgium', iso2: 'BE', name_pt: 'Bélgica' },
        { id: 23, name: 'Belize', iso2: 'BZ', name_pt: 'Belize' },
        { id: 24, name: 'Benin', iso2: 'BJ', name_pt: 'Benin' },
        { id: 25, name: 'Bermuda', iso2: 'BM', name_pt: 'Bermudas' },
        { id: 26, name: 'Bhutan', iso2: 'BT', name_pt: 'Butão' },
        { id: 27, name: 'Bolivia', iso2: 'BO', name_pt: 'Bolívia' },
        { id: 28, name: 'Bosnia and Herzegovina', iso2: 'BA', name_pt: 'Bósnia e Herzegovina' },
        { id: 29, name: 'Botswana', iso2: 'BW', name_pt: 'Botsuana' },
        { id: 30, name: 'Bouvet Island', iso2: 'BV', name_pt: 'Ilha Bouvet' },
        { id: 31, name: 'Brazil', iso2: 'BR', name_pt: 'Brasil' },
        {
            id: 32,
            name: 'British Indian Ocean Territory',
            iso2: 'IO',
            name_pt: 'Território Britânico do Oceano Índico',
        },
        { id: 33, name: 'Brunei', iso2: 'BN', name_pt: 'Brunei' },
        { id: 34, name: 'Bulgaria', iso2: 'BG', name_pt: 'Bulgária' },
        { id: 35, name: 'Burkina Faso', iso2: 'BF', name_pt: 'Burkina Faso' },
        { id: 36, name: 'Burundi', iso2: 'BI', name_pt: 'Burundi' },
        { id: 37, name: 'Cambodia', iso2: 'KH', name_pt: 'Camboja' },
        { id: 38, name: 'Cameroon', iso2: 'CM', name_pt: 'Camarões' },
        { id: 39, name: 'Canada', iso2: 'CA', name_pt: 'Canadá' },
        { id: 40, name: 'Cape Verde', iso2: 'CV', name_pt: 'Cabo Verde' },
        { id: 41, name: 'Cayman Islands', iso2: 'KY', name_pt: 'Ilhas Cayman' },
        {
            id: 42,
            name: 'Central African Republic',
            iso2: 'CF',
            name_pt: 'República Centro-Africana',
        },
        { id: 43, name: 'Chad', iso2: 'TD', name_pt: 'Chade' },
        { id: 44, name: 'Chile', iso2: 'CL', name_pt: 'Chile' },
        { id: 45, name: 'China', iso2: 'CN', name_pt: 'China' },
        { id: 46, name: 'Christmas Island', iso2: 'CX', name_pt: 'Ilha Christmas' },
        { id: 47, name: 'Cocos (Keeling) Islands', iso2: 'CC', name_pt: 'Ilhas Cocos (Keeling)' },
        { id: 48, name: 'Colombia', iso2: 'CO', name_pt: 'Colômbia' },
        { id: 49, name: 'Comoros', iso2: 'KM', name_pt: 'Comores' },
        { id: 50, name: 'Congo', iso2: 'CG', name_pt: 'Congo' },
        {
            id: 51,
            name: 'Democratic Republic of the Congo',
            iso2: 'CD',
            name_pt: 'República Democrática do Congo',
        },
        { id: 52, name: 'Cook Islands', iso2: 'CK', name_pt: 'Ilhas Cook' },
        { id: 53, name: 'Costa Rica', iso2: 'CR', name_pt: 'Costa Rica' },
        { id: 54, name: "Cote D'Ivoire (Ivory Coast)", iso2: 'CI', name_pt: 'Costa do Marfim' },
        { id: 55, name: 'Croatia', iso2: 'HR', name_pt: 'Croácia' },
        { id: 56, name: 'Cuba', iso2: 'CU', name_pt: 'Cuba' },
        { id: 57, name: 'Cyprus', iso2: 'CY', name_pt: 'Chipre' },
        { id: 58, name: 'Czech Republic', iso2: 'CZ', name_pt: 'República Tcheca' },
        { id: 59, name: 'Denmark', iso2: 'DK', name_pt: 'Dinamarca' },
        { id: 60, name: 'Djibouti', iso2: 'DJ', name_pt: 'Djibuti' },
        { id: 61, name: 'Dominica', iso2: 'DM', name_pt: 'Dominica' },
        { id: 62, name: 'Dominican Republic', iso2: 'DO', name_pt: 'República Dominicana' },
        { id: 63, name: 'East Timor', iso2: 'TL', name_pt: 'Timor-Leste' },
        { id: 64, name: 'Ecuador', iso2: 'EC', name_pt: 'Equador' },
        { id: 65, name: 'Egypt', iso2: 'EG', name_pt: 'Egito' },
        { id: 66, name: 'El Salvador', iso2: 'SV', name_pt: 'El Salvador' },
        { id: 67, name: 'Equatorial Guinea', iso2: 'GQ', name_pt: 'Guiné Equatorial' },
        { id: 68, name: 'Eritrea', iso2: 'ER', name_pt: 'Eritreia' },
        { id: 69, name: 'Estonia', iso2: 'EE', name_pt: 'Estônia' },
        { id: 70, name: 'Ethiopia', iso2: 'ET', name_pt: 'Etiópia' },
        { id: 71, name: 'Falkland Islands', iso2: 'FK', name_pt: 'Ilhas Falkland' },
        { id: 72, name: 'Faroe Islands', iso2: 'FO', name_pt: 'Ilhas Faroe' },
        { id: 73, name: 'Fiji Islands', iso2: 'FJ', name_pt: 'Fiji' },
        { id: 74, name: 'Finland', iso2: 'FI', name_pt: 'Finlândia' },
        { id: 75, name: 'France', iso2: 'FR', name_pt: 'França' },
        { id: 76, name: 'French Guiana', iso2: 'GF', name_pt: 'Guiana Francesa' },
        { id: 77, name: 'French Polynesia', iso2: 'PF', name_pt: 'Polinésia Francesa' },
        {
            id: 78,
            name: 'French Southern Territories',
            iso2: 'TF',
            name_pt: 'Terras Austrais e Antárticas Francesas',
        },
        { id: 79, name: 'Gabon', iso2: 'GA', name_pt: 'Gabão' },
        { id: 80, name: 'Gambia The', iso2: 'GM', name_pt: 'Gâmbia' },
        { id: 81, name: 'Georgia', iso2: 'GE', name_pt: 'Geórgia' },
        { id: 82, name: 'Germany', iso2: 'DE', name_pt: 'Alemanha' },
        { id: 83, name: 'Ghana', iso2: 'GH', name_pt: 'Gana' },
        { id: 84, name: 'Gibraltar', iso2: 'GI', name_pt: 'Gibraltar' },
        { id: 85, name: 'Greece', iso2: 'GR', name_pt: 'Grécia' },
        { id: 86, name: 'Greenland', iso2: 'GL', name_pt: 'Groênlandia' },
        { id: 87, name: 'Grenada', iso2: 'GD', name_pt: 'Granada' },
        { id: 88, name: 'Guadeloupe', iso2: 'GP', name_pt: 'Guadalupe' },
        { id: 89, name: 'Guam', iso2: 'GU', name_pt: 'Guam' },
        { id: 90, name: 'Guatemala', iso2: 'GT', name_pt: 'Guatemala' },
        { id: 91, name: 'Guernsey and Alderney', iso2: 'GG', name_pt: 'Guernsey' },
        { id: 92, name: 'Guinea', iso2: 'GN', name_pt: 'Guiné' },
        { id: 93, name: 'Guinea-Bissau', iso2: 'GW', name_pt: 'Guiné-Bissau' },
        { id: 94, name: 'Guyana', iso2: 'GY', name_pt: 'Guiana' },
        { id: 95, name: 'Haiti', iso2: 'HT', name_pt: 'Haiti' },
        {
            id: 96,
            name: 'Heard Island and McDonald Islands',
            iso2: 'HM',
            name_pt: 'Ilha Heard e Ilhas McDonald',
        },
        { id: 97, name: 'Honduras', iso2: 'HN', name_pt: 'Honduras' },
        { id: 98, name: 'Hong Kong S.A.R.', iso2: 'HK', name_pt: 'Hong Kong' },
        { id: 99, name: 'Hungary', iso2: 'HU', name_pt: 'Hungria' },
        { id: 100, name: 'Iceland', iso2: 'IS', name_pt: 'Islândia' },
        { id: 101, name: 'India', iso2: 'IN', name_pt: 'Índia' },
        { id: 102, name: 'Indonesia', iso2: 'ID', name_pt: 'Indonésia' },
        { id: 103, name: 'Iran', iso2: 'IR', name_pt: 'Irã' },
        { id: 104, name: 'Iraq', iso2: 'IQ', name_pt: 'Iraque' },
        { id: 105, name: 'Ireland', iso2: 'IE', name_pt: 'Irlanda' },
        { id: 106, name: 'Israel', iso2: 'IL', name_pt: 'Israel' },
        { id: 107, name: 'Italy', iso2: 'IT', name_pt: 'Itália' },
        { id: 108, name: 'Jamaica', iso2: 'JM', name_pt: 'Jamaica' },
        { id: 109, name: 'Japan', iso2: 'JP', name_pt: 'Japão' },
        { id: 110, name: 'Jersey', iso2: 'JE', name_pt: 'Jersey' },
        { id: 111, name: 'Jordan', iso2: 'JO', name_pt: 'Jordânia' },
        { id: 112, name: 'Kazakhstan', iso2: 'KZ', name_pt: 'Cazaquistão' },
        { id: 113, name: 'Kenya', iso2: 'KE', name_pt: 'Quênia' },
        { id: 114, name: 'Kiribati', iso2: 'KI', name_pt: 'Kiribati' },
        { id: 115, name: 'North Korea', iso2: 'KP', name_pt: 'Coreia do Norte' },
        { id: 116, name: 'South Korea', iso2: 'KR', name_pt: 'Coreia do Sul' },
        { id: 117, name: 'Kuwait', iso2: 'KW', name_pt: 'Kuwait' },
        { id: 118, name: 'Kyrgyzstan', iso2: 'KG', name_pt: 'Quirguistão' },
        { id: 119, name: 'Laos', iso2: 'LA', name_pt: 'Laos' },
        { id: 120, name: 'Latvia', iso2: 'LV', name_pt: 'Letônia' },
        { id: 121, name: 'Lebanon', iso2: 'LB', name_pt: 'Líbano' },
        { id: 122, name: 'Lesotho', iso2: 'LS', name_pt: 'Lesoto' },
        { id: 123, name: 'Liberia', iso2: 'LR', name_pt: 'Libéria' },
        { id: 124, name: 'Libya', iso2: 'LY', name_pt: 'Líbia' },
        { id: 125, name: 'Liechtenstein', iso2: 'LI', name_pt: 'Liechtenstein' },
        { id: 126, name: 'Lithuania', iso2: 'LT', name_pt: 'Lituânia' },
        { id: 127, name: 'Luxembourg', iso2: 'LU', name_pt: 'Luxemburgo' },
        { id: 128, name: 'Macau S.A.R.', iso2: 'MO', name_pt: 'Macau' },
        { id: 129, name: 'North Macedonia', iso2: 'MK', name_pt: 'Macedônia do Norte' },
        { id: 130, name: 'Madagascar', iso2: 'MG', name_pt: 'Madagascar' },
        { id: 131, name: 'Malawi', iso2: 'MW', name_pt: 'Malawi' },
        { id: 132, name: 'Malaysia', iso2: 'MY', name_pt: 'Malásia' },
        { id: 133, name: 'Maldives', iso2: 'MV', name_pt: 'Maldivas' },
        { id: 134, name: 'Mali', iso2: 'ML', name_pt: 'Mali' },
        { id: 135, name: 'Malta', iso2: 'MT', name_pt: 'Malta' },
        { id: 136, name: 'Man (Isle of)', iso2: 'IM', name_pt: 'Ilha de Man' },
        { id: 137, name: 'Marshall Islands', iso2: 'MH', name_pt: 'Ilhas Marshall' },
        { id: 138, name: 'Martinique', iso2: 'MQ', name_pt: 'Martinica' },
        { id: 139, name: 'Mauritania', iso2: 'MR', name_pt: 'Mauritânia' },
        { id: 140, name: 'Mauritius', iso2: 'MU', name_pt: 'Maurício' },
        { id: 141, name: 'Mayotte', iso2: 'YT', name_pt: 'Mayotte' },
        { id: 142, name: 'Mexico', iso2: 'MX', name_pt: 'México' },
        { id: 143, name: 'Micronesia', iso2: 'FM', name_pt: 'Micronésia' },
        { id: 144, name: 'Moldova', iso2: 'MD', name_pt: 'Moldávia' },
        { id: 145, name: 'Monaco', iso2: 'MC', name_pt: 'Mônaco' },
        { id: 146, name: 'Mongolia', iso2: 'MN', name_pt: 'Mongólia' },
        { id: 147, name: 'Montenegro', iso2: 'ME', name_pt: 'Montenegro' },
        { id: 148, name: 'Montserrat', iso2: 'MS', name_pt: 'Montserrat' },
        { id: 149, name: 'Morocco', iso2: 'MA', name_pt: 'Marrocos' },
        { id: 150, name: 'Mozambique', iso2: 'MZ', name_pt: 'Moçambique' },
        { id: 151, name: 'Myanmar', iso2: 'MM', name_pt: 'Mianmar' },
        { id: 152, name: 'Namibia', iso2: 'NA', name_pt: 'Namíbia' },
        { id: 153, name: 'Nauru', iso2: 'NR', name_pt: 'Nauru' },
        { id: 154, name: 'Nepal', iso2: 'NP', name_pt: 'Nepal' },
        {
            id: 155,
            name: 'Bonaire, Sint Eustatius and Saba',
            iso2: 'BQ',
            name_pt: 'Bonaire, Santo Eustáquio e Saba',
        },
        { id: 156, name: 'Netherlands', iso2: 'NL', name_pt: 'Países Baixos' },
        { id: 157, name: 'New Caledonia', iso2: 'NC', name_pt: 'Nova Caledônia' },
        { id: 158, name: 'New Zealand', iso2: 'NZ', name_pt: 'Nova Zelândia' },
        { id: 159, name: 'Nicaragua', iso2: 'NI', name_pt: 'Nicarágua' },
        { id: 160, name: 'Niger', iso2: 'NE', name_pt: 'Níger' },
        { id: 161, name: 'Nigeria', iso2: 'NG', name_pt: 'Nigéria' },
        { id: 162, name: 'Niue', iso2: 'NU', name_pt: 'Niue' },
        { id: 163, name: 'Norfolk Island', iso2: 'NF', name_pt: 'Ilha Norfolk' },
        {
            id: 164,
            name: 'Northern Mariana Islands',
            iso2: 'MP',
            name_pt: 'Ilhas Marianas do Norte',
        },
        { id: 165, name: 'Norway', iso2: 'NO', name_pt: 'Noruega' },
        { id: 166, name: 'Oman', iso2: 'OM', name_pt: 'Omã' },
        { id: 167, name: 'Pakistan', iso2: 'PK', name_pt: 'Paquistão' },
        { id: 168, name: 'Palau', iso2: 'PW', name_pt: 'Palau' },
        {
            id: 169,
            name: 'Palestinian Territory Occupied',
            iso2: 'PS',
            name_pt: 'Território Palestino Ocupado',
        },
        { id: 170, name: 'Panama', iso2: 'PA', name_pt: 'Panamá' },
        { id: 171, name: 'Papua new Guinea', iso2: 'PG', name_pt: 'Papua-Nova Guiné' },
        { id: 172, name: 'Paraguay', iso2: 'PY', name_pt: 'Paraguai' },
        { id: 173, name: 'Peru', iso2: 'PE', name_pt: 'Peru' },
        { id: 174, name: 'Philippines', iso2: 'PH', name_pt: 'Filipinas' },
        { id: 175, name: 'Pitcairn Island', iso2: 'PN', name_pt: 'Ilha Pitcairn' },
        { id: 176, name: 'Poland', iso2: 'PL', name_pt: 'Polônia' },
        { id: 177, name: 'Portugal', iso2: 'PT', name_pt: 'Portugal' },
        { id: 178, name: 'Puerto Rico', iso2: 'PR', name_pt: 'Porto Rico' },
        { id: 179, name: 'Qatar', iso2: 'QA', name_pt: 'Catar' },
        { id: 180, name: 'Reunion', iso2: 'RE', name_pt: 'Reunião' },
        { id: 181, name: 'Romania', iso2: 'RO', name_pt: 'Romênia' },
        { id: 182, name: 'Russia', iso2: 'RU', name_pt: 'Rússia' },
        { id: 183, name: 'Rwanda', iso2: 'RW', name_pt: 'Ruanda' },
        { id: 184, name: 'Saint Helena', iso2: 'SH', name_pt: 'Santa Helena' },
        { id: 185, name: 'Saint Kitts And Nevis', iso2: 'KN', name_pt: 'Saint Kitts e Nevis' },
        { id: 186, name: 'Saint Lucia', iso2: 'LC', name_pt: 'Santa Lúcia' },
        {
            id: 187,
            name: 'Saint Pierre and Miquelon',
            iso2: 'PM',
            name_pt: 'Saint Pierre e Miquelon',
        },
        {
            id: 188,
            name: 'Saint Vincent And The Grenadines',
            iso2: 'VC',
            name_pt: 'São Vicente e Granadinas',
        },
        { id: 189, name: 'Saint-Barthelemy', iso2: 'BL', name_pt: 'Saint Barthélemy' },
        { id: 190, name: 'Saint-Martin (French part)', iso2: 'MF', name_pt: 'Saint-Martin' },
        { id: 191, name: 'Samoa', iso2: 'WS', name_pt: 'Samoa' },
        { id: 192, name: 'San Marino', iso2: 'SM', name_pt: 'San Marino' },
        { id: 193, name: 'Sao Tome and Principe', iso2: 'ST', name_pt: 'São Tomé e Príncipe' },
        { id: 194, name: 'Saudi Arabia', iso2: 'SA', name_pt: 'Arábia Saudita' },
        { id: 195, name: 'Senegal', iso2: 'SN', name_pt: 'Senegal' },
        { id: 196, name: 'Serbia', iso2: 'RS', name_pt: 'Sérvia' },
        { id: 197, name: 'Seychelles', iso2: 'SC', name_pt: 'Seicheles' },
        { id: 198, name: 'Sierra Leone', iso2: 'SL', name_pt: 'Serra Leoa' },
        { id: 199, name: 'Singapore', iso2: 'SG', name_pt: 'Singapura' },
        { id: 200, name: 'Slovakia', iso2: 'SK', name_pt: 'Eslováquia' },
        { id: 201, name: 'Slovenia', iso2: 'SI', name_pt: 'Eslovênia' },
        { id: 202, name: 'Solomon Islands', iso2: 'SB', name_pt: 'Ilhas Salomão' },
        { id: 203, name: 'Somalia', iso2: 'SO', name_pt: 'Somália' },
        { id: 204, name: 'South Africa', iso2: 'ZA', name_pt: 'África do Sul' },
        {
            id: 205,
            name: 'South Georgia',
            iso2: 'GS',
            name_pt: 'Geórgia do Sul e Ilhas Sandwich do Sul',
        },
        { id: 206, name: 'South Sudan', iso2: 'SS', name_pt: 'Sudão do Sul' },
        { id: 207, name: 'Spain', iso2: 'ES', name_pt: 'Espanha' },
        { id: 208, name: 'Sri Lanka', iso2: 'LK', name_pt: 'Sri Lanka' },
        { id: 209, name: 'Sudan', iso2: 'SD', name_pt: 'Sudão' },
        { id: 210, name: 'Suriname', iso2: 'SR', name_pt: 'Suriname' },
        {
            id: 211,
            name: 'Svalbard And Jan Mayen Islands',
            iso2: 'SJ',
            name_pt: 'Svalbard e Jan Mayen',
        },
        { id: 212, name: 'Swaziland', iso2: 'SZ', name_pt: 'Suazilândia' },
        { id: 213, name: 'Sweden', iso2: 'SE', name_pt: 'Suécia' },
        { id: 214, name: 'Switzerland', iso2: 'CH', name_pt: 'Suíça' },
        { id: 215, name: 'Syria', iso2: 'SY', name_pt: 'Síria' },
        { id: 216, name: 'Taiwan', iso2: 'TW', name_pt: 'Taiwan' },
        { id: 217, name: 'Tajikistan', iso2: 'TJ', name_pt: 'Tajiquistão' },
        { id: 218, name: 'Tanzania', iso2: 'TZ', name_pt: 'Tanzânia' },
        { id: 219, name: 'Thailand', iso2: 'TH', name_pt: 'Tailândia' },
        { id: 220, name: 'Togo', iso2: 'TG', name_pt: 'Togo' },
        { id: 221, name: 'Tokelau', iso2: 'TK', name_pt: 'Tokelau' },
        { id: 222, name: 'Tonga', iso2: 'TO', name_pt: 'Tonga' },
        { id: 223, name: 'Trinidad And Tobago', iso2: 'TT', name_pt: 'Trinidad e Tobago' },
        { id: 224, name: 'Tunisia', iso2: 'TN', name_pt: 'Tunísia' },
        { id: 225, name: 'Turkey', iso2: 'TR', name_pt: 'Turquia' },
        { id: 226, name: 'Turkmenistan', iso2: 'TM', name_pt: 'Turcomenistão' },
        { id: 227, name: 'Turks And Caicos Islands', iso2: 'TC', name_pt: 'Ilhas Turks e Caicos' },
        { id: 228, name: 'Tuvalu', iso2: 'TV', name_pt: 'Tuvalu' },
        { id: 229, name: 'Uganda', iso2: 'UG', name_pt: 'Uganda' },
        { id: 230, name: 'Ukraine', iso2: 'UA', name_pt: 'Ucrânia' },
        { id: 231, name: 'United Arab Emirates', iso2: 'AE', name_pt: 'Emirados Árabes Unidos' },
        { id: 232, name: 'United Kingdom', iso2: 'GB', name_pt: 'Reino Unido' },
        {
            id: 234,
            name: 'United States Minor Outlying Islands',
            iso2: 'UM',
            name_pt: 'Ilhas Menores Distantes dos Estados Unidos',
        },
        { id: 235, name: 'Uruguay', iso2: 'UY', name_pt: 'Uruguai' },
        { id: 236, name: 'Uzbekistan', iso2: 'UZ', name_pt: 'Uzbequistão' },
        { id: 237, name: 'Vanuatu', iso2: 'VU', name_pt: 'Vanuatu' },
        {
            id: 238,
            name: 'Vatican City State (Holy See)',
            iso2: 'VA',
            name_pt: 'Cidade do Vaticano',
        },
        { id: 239, name: 'Venezuela', iso2: 'VE', name_pt: 'Venezuela' },
        { id: 240, name: 'Vietnam', iso2: 'VN', name_pt: 'Vietnã' },
        {
            id: 241,
            name: 'Virgin Islands (British)',
            iso2: 'VG',
            name_pt: 'Ilhas Virgens Britânicas',
        },
        { id: 242, name: 'Virgin Islands (US)', iso2: 'VI', name_pt: 'Ilhas Virgens Americanas' },
        { id: 243, name: 'Wallis And Futuna Islands', iso2: 'WF', name_pt: 'Wallis e Futuna' },
        { id: 244, name: 'Western Sahara', iso2: 'EH', name_pt: 'Saara Ocidental' },
        { id: 245, name: 'Yemen', iso2: 'YE', name_pt: 'Iêmen' },
        { id: 246, name: 'Zambia', iso2: 'ZM', name_pt: 'Zâmbia' },
        { id: 247, name: 'Zimbabwe', iso2: 'ZW', name_pt: 'Zimbábue' },
        { id: 248, name: 'Kosovo', iso2: 'XK', name_pt: 'Kosovo' },
        { id: 249, name: 'Curaçao', iso2: 'CW', name_pt: 'Curaçao' },
        {
            id: 250,
            name: 'Sint Maarten (Dutch part)',
            iso2: 'SX',
            name_pt: 'São Martinho (parte holandesa)',
        },
    ]
}
export const CountryDetails = async (countryIso) => {
    return await GET(`/places/countries/${countryIso}`, false)
}

export const ReadStates = async (countryIso, stateIso) => {
    return await GET(`/places/states/${countryIso}`, false)
}
export const StateDetails = async (countryIso, stateIso) => {
    return await GET(`/places/states/${countryIso}/${stateIso}`, false)
}

export const ReadCities = async (countryIso, stateIso) => {
    return await GET(`/places/cities/${countryIso}/${stateIso}`, false)
}
