import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const Division = styled.div.attrs({})`
    flex: 0.5;
    ${(props) => `
        margin-bottom: ${props.space || 12}px
    `}
`

export const CardButton = styled.button.attrs({})`
    background: var(--white-color);
    padding: 16px 24px;
    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
    box-shadow: 0 3px 10px #00000015;
    display: flex;
    align-items: center;

    &:hover {
        box-shadow: 0px 1px 3px rgba(112, 112, 112, 0.35);
    }

    &:focus {
        border: 2px solid #263640;
        outline-color: #263640;
        outline-width: 2px;
    }
`

export const PageTitle = styled.div.attrs({})`
    font-size: 17px;
    color: #303030;
    font-weight: 600;
`

export const ProcessesContainer = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`

export const PageCard = styled.div.attrs({})`
    background: var(--white-color);
    padding: 24px;
    margin-top: 48px;
    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
`

export const PlusGreenIcon = styled.img.attrs({
    src: '/icons/circle-add-green.svg',
})`
    width: 16px;
    height: 16px;
    margin-right: 8px;
`

export const TextGreenIcon = styled.img.attrs({
    src: '/icons/green-text-fields.svg',
})`
    width: 16px;
    height: 16px;
    margin-right: 8px;
`

export const DropdownGreenIcon = styled.img.attrs({
    src: '/icons/green-dropdown.svg',
})`
    width: 16px;
    height: 16px;
    margin-right: 8px;
`

export const CheckboxGreenIcon = styled.img.attrs({
    src: '/icons/green-checkbox.svg',
})`
    width: 16px;
    height: 16px;
    margin-right: 8px;
`

export const DateGreenIcon = styled.img.attrs({
    src: '/icons/green-calendar.svg',
})`
    width: 16px;
    height: 16px;
    margin-right: 8px;
`

export const AttachGreenIcon = styled.img.attrs({
    src: '/icons/green-attach.svg',
})`
    width: 16px;
    height: 16px;
    margin-right: 8px;
`
