import Input from '../Input'
import PasswordStrengthMeter from '../PasswordStrengthMeter'

function InputPassword({
    id,
    placeholder,
    value,
    onChange,
    onFocus,
    onBlur,
    highlight,
    autoComplete,
    small,
}) {
    return (
        <>
            <div style={{ width: !small ? '353px' : '303px' }}>
                <Input
                    id={id}
                    type={'password'}
                    placeholder={placeholder}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    value={value}
                    highlight={highlight}
                    autoComplete={'new-password'}
                    small={small}
                />
                <PasswordStrengthMeter password={value} />
            </div>
        </>
    )
}
export default InputPassword
