import styled from 'styled-components'

export const ProfileFormContent = styled.div.attrs({})`
    width: 100%;
    padding: 40px 0 20px;
`

export const ProfileFormActions = styled.div.attrs({})`
    display: flex;
    justify-content: center;
`
export const PageFormSpace = styled.div.attrs({})`
    margin-right: 12px;
`

export const RowForm = styled.div.attrs({})`
    display: flex;
    gap: 0 20px;
`
export const ModalFormContent = styled.div.attrs({})`
    flex: 1;
`
export const ModalFormLabel = styled.div.attrs({})`
    margin-bottom: 7px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--primary-color);
    ${(props) =>
        props.spaced
            ? `
            margin-bottom: 15px;
        `
            : ``}
`

export const UserProfileProgressActions = styled.div.attrs({})`
    display: flex;
    padding: 0px 0 20px;
    margin-top: -10px;
`

export const Label = styled.div.attrs({})`
    width: 100%;
    display: flex;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: var(--primary-color);
    justify-content: center;
    margin-bottom: 15px;
`

export const LabelLeft = styled.div.attrs({})`
    width: 100%;
    display: flex;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    margin-bottom: 15px;

    ${(props) => (props.highlight ? `color: red` : `color: var(--primary-color);`)}
`

export const RadioHeader = styled.div.attrs({})`
    display: flex;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
`

export const RadioHeaderLabel = styled.div.attrs({})`
    cursor: pointer;
`

export const RadioContent = styled.div.attrs({})`
    height: 25px;
    padding-left: 15px;
    position: relative;
    width: 25px;
`

export const RadioButtonCircle = styled.label`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #eee;
    cursor: pointer;
    position: absolute;
    left: 0;
`

export const RadioButtonLabel = styled.div`
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    height: 25px;
    margin-left: 8px;
    padding-top: 3px;
    width: 200px;
`

export const RadioButton = styled.input.attrs({
    input: 'radio',
})`
    position: absolute;
    left: 0;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    width: 25px;
    height: 25px;

    &:hover ~ ${RadioButtonCircle} {
        background: #eee;
        border: 1px solid #ccc;
        &::after {
            content: url('/icons/check.svg');
            display: block;
            margin: 0px 0px 0px 5px;
        }
    }

    &:checked + ${RadioButtonCircle} {
        background: yellowgreen;
        border: 1px solid yellowgreen;
        &::after {
            content: url('/icons/check.svg');
            display: block;
            margin: 0px 0px 0px 5px;
        }
    }

    ${(props) => (props.highlight ? `color: red` : `color: var(--primary-color);`)}
`

export const IconInfo = styled.img.attrs({
    src: '/icons/circle-info.svg',
})``
