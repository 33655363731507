import React, { useEffect, useState } from 'react';
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, IconButton, Menu, MenuItem } from '@material-ui/core';
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TextField from '@material-ui/core/TextField'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { GetAllPrecheck } from 'services/backoffice';
import BuyerSupplier from './BuyerSupplier/index'
import ServiceProvider from './ServiceProvider/index'
import * as Papa from 'papaparse'
import moment from 'moment-timezone'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    container: {
        margin: '20px',
        overflowY: 'auto',
    }
});

const Precheck = () => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [showReview, setShowReview] = useState(false);
    const [sortConfig, setSortConfig] = React.useState({
        key: 'name',
        direction: 'asc',
    })
    const [filter, setFilter] = useState({
        name: '',
        role: '',
        status: '',
        created_at: '',
        updated_at: '',
        expiration_date: '',
        reminder_days: '',
    })

    const fetchUsers = async () => {
        const response = await GetAllPrecheck();
        const mappedData = response.map(item => ({
            logo: item.avatar ? item.avatar : '/images/no-image.png',
            id: item.id,
            ownerId: item.ownerId,
            company_id: item.company_id,
            name: item.name,
            role: item.ownerRole,
            status: item.status,
            created_at: convertUtcToEst(item.created_at),
            updated_at: convertUtcToEst(item.updated_at),
            expiration_date: item.expiration_date,
            reminder_days: item.reminder_days,
        }));
        setData(mappedData);
        setTotal(mappedData.length);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleAction = async (action) => {
        if (action === 'review') {
            setShowReview(true);
        }
    };

    const goBackToGrid = () => {
        setShowReview(false);
        fetchUsers();
    };

    const downloadToCsv = () => {
        let downloadArra = []

        downloadArra.push(['Name', 'Role', 'Company_ID', 'Owner_Id', 'Created_At', 'Updated_At', 'Status', 'Expiration', 'Reminder_In_Days'])
        data.forEach((x) => {
            downloadArra.push([
                x.name,
                x.role === 3 ? 'Buyer' : x.role === 4 ? 'Supplier' : x.role === 5 ? 'Service Provider' : 'Unknown' ,
                x.company_id,
                x.ownerId,
                moment.utc(x.created_at).tz('America/New_York').format('MM/DD/YYYY HH:mm'),
                moment.utc(x.updated_at).tz('America/New_York').format('MM/DD/YYYY HH:mm'),
                x.status,
                x.expiration_date,
                x.reminder_days,
            ])
        })

        var csv = Papa.unparse(downloadArra, {
            quotes: false,
            quoteChar: '"',
            escapeChar: '"',
            delimiter: ',',
            header: true,
            newline: '\r\n',
            skipEmptyLines: false,
            columns: [
                'Name',
                'Role',
                'Company_ID',
                'Owner_Id',
                'Created_At',
                'Updated_At',
                'Status',
                'Expiration',
                'Reminder_In_Days'
            ],
        })

        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' })

        var csvURL = window.URL.createObjectURL(csvData)

        var testLink = document.createElement('a')
        testLink.href = csvURL
        testLink.setAttribute('test', 'test.csv')
        testLink.click()
    }

    const handleFilterChange = (event) => {
        const { name, value } = event.target

        setFilter({
            ...filter,
            [name]: value,
        })  
    }

    const handleRequestSort = (key) => {
        let direction = 'asc'
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc'
        }
        setSortConfig({ key, direction })
    }

    const filteredData = data
        .filter((item) =>
            Object.keys(filter).every((key) => {
                if (!filter[key]) return true // if filter is blank, ignore
                return (
                    item[key] &&
                    item[key]
                        .toString()
                        .toLowerCase()
                        .includes(filter[key].toString().toLowerCase())
                )
            })
        )
        .sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1
            }
            return 0
        })

    // Calculate the data for the current page
    const currentPageData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    // Set the total count
    useEffect(() => {
        setTotal(filteredData.length)
    }, [filteredData])

    const convertUtcToEst = (utcDate) => {
        // Convert UTC date to EST and format it
        return moment.utc(utcDate).tz('America/New_York').format('MM/DD/YYYY HH:mm')
    }

    return (
        <>
            <br />
            {
                showReview ?
                    currentRow.role === 5 ?
                        <ServiceProvider company_id={currentRow.company_id} status={currentRow.status} goBack={goBackToGrid} />
                        :
                        <BuyerSupplier company_id={currentRow.company_id} status={currentRow.status} goBack={goBackToGrid} />
                    : (
                        <TableContainer component={Paper} className="table container">
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <span onClick={downloadToCsv} style={{ fontSize: '1em', padding: '5px 12px', cursor: 'pointer' }} className={`badge bg-secondary bg-green`}>
                                                Download
                                                <br></br>
                                                CSV
                                            </span>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={sortConfig.key === 'name'}
                                                direction={sortConfig.direction}
                                                onClick={() => handleRequestSort('name')}
                                            >
                                                Name
                                            </TableSortLabel>
                                            <TextField
                                                name="name"
                                                value={filter.companyName}
                                                onChange={handleFilterChange}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={sortConfig.key === 'role'}
                                                direction={sortConfig.direction}
                                                onClick={() => handleRequestSort('role')}
                                            >
                                                Role
                                            </TableSortLabel>
                                            <TextField
                                                name="role"
                                                value={filter.companyName}
                                                onChange={handleFilterChange}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={sortConfig.key === 'created_at'}
                                                direction={sortConfig.direction}
                                                onClick={() => handleRequestSort('created_at')}
                                            >
                                                Created At
                                            </TableSortLabel>
                                            <TextField
                                                name="created_at"
                                                value={filter.companyName}
                                                onChange={handleFilterChange}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={sortConfig.key === 'updated_at'}
                                                direction={sortConfig.direction}
                                                onClick={() => handleRequestSort('updated_at')}
                                            >
                                                Updated At
                                            </TableSortLabel>
                                            <TextField
                                                name="updated_at"
                                                value={filter.companyName}
                                                onChange={handleFilterChange}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={sortConfig.key === 'expiration_date'}
                                                direction={sortConfig.direction}
                                                onClick={() => handleRequestSort('expiration_date')}
                                            >
                                                Expiration
                                            </TableSortLabel>
                                            <TextField
                                                name="expiration_date"
                                                value={filter.companyName}
                                                onChange={handleFilterChange}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={sortConfig.key === 'reminder_days'}
                                                direction={sortConfig.direction}
                                                onClick={() => handleRequestSort('reminder_days')}
                                            >
                                                Reminder in Days
                                            </TableSortLabel>
                                            <TextField
                                                name="reminder_days"
                                                value={filter.companyName}
                                                onChange={handleFilterChange}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={sortConfig.key === 'status'}
                                                direction={sortConfig.direction}
                                                onClick={() => handleRequestSort('status')}
                                            >
                                                Status
                                            </TableSortLabel>
                                            <TextField
                                                name="status"
                                                value={filter.companyName}
                                                onChange={handleFilterChange}
                                            />
                                        </TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {currentPageData.map((row, i) => (
                                        <TableRow key={i}>
                                            <TableCell><img src={row.logo} alt="Logo" style={{ width: '50px', height: '50px', borderRadius: '50%' }} /></TableCell>
                                            <TableCell>
                                                {
                                                    row.status.toLowerCase() === 'approved' ?
                                                        <div style={{ marginTop: '15px' }} >
                                                            {row.name}
                                                            <img src='/icons/checked.svg' alt='Approved' style={{ marginLeft: '10px', height: '25px' }} />
                                                        </div>
                                                        :
                                                        <div style={{ marginTop: '15px' }} >
                                                            {row.name}
                                                        </div>
                                                }

                                            </TableCell>
                                            <TableCell>
                                                <div style={{ marginTop: '15px' }} >
                                                    {row.role === 3 ? 'Buyer' : row.role === 4 ? 'Supplier' : row.role === 5 ? 'Service Provider' : 'Unknown'}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{ marginTop: '15px' }} >
                                                    {row.created_at}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{ marginTop: '15px' }} >
                                                    {row.updated_at}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{ marginTop: '15px' }} >
                                                    {row.expiration_date}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{ marginTop: '15px' }} >
                                                    {row.reminder_days}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{ marginTop: '15px' }} >
                                                    <span style={{ fontSize: '1em', padding: '5px 12px' }} className={`badge ${row.status === 'open' ? 'bg-primary' : row.status === 'approved' ? 'bg-green' : 'bg-danger'}`}>
                                                        {row.status.charAt(0).toUpperCase() + row.status.slice(1)}
                                                    </span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    aria-label="more"
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true"
                                                    onClick={(event) => {
                                                        setAnchorEl(event.currentTarget);
                                                        setCurrentRow(row);
                                                    }}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    id="long-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={() => setAnchorEl(null)}
                                                >
                                                    <MenuItem onClick={() => { handleAction('review'); setAnchorEl(null); }}>Review</MenuItem>
                                                </Menu>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    )
            }
        </>
    );
};

export default Precheck;
