import React, { useEffect, useState, useContext } from 'react';
import Form from './Form/index';
import { PageCard } from './styled';
import Select from 'react-select';
import { GetSteps, GetFullStep, PutStepsForm, PostAssignedStepForm } from 'services/steps';
import SectionsToUploadTheUser from './SectionsToUploadUsers/index';
import { Modal, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FetchSuplierRequestsFind } from 'services/sms';
import { CoreContext } from 'context/CoreContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function OutsideScreen() {
    const { user, findlanguage } = useContext(CoreContext);
    const [isCreatingNew, setIsCreatingNew] = useState(false);
    const [formData, setFormData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [formMode, setFormMode] = useState('new');
    const [selectedFormId, setSelectedFormId] = useState(null);
    const [selectedForm, setSelectedForm] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [userAssignments, setUserAssignments] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [expirationDate, setExpirationDate] = useState(new Date());
    const [users, setUsers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [allUsers, setAllUsers] = useState([]);

    useEffect(() => {
        const init = async () => {
            const request = await GetSteps();
            setFormData(request);
        };

        const listConnections = async () => {
            const list = await FetchSuplierRequestsFind()

            const usersList = list.map(connection => {
                let role = '';

                switch (connection.role) {
                    case 3:
                        role = findlanguage().buyerTitle;
                        break;
                    case 4:
                        role = findlanguage().supplierTitle;
                        break;
                    case 5:
                        role = findlanguage().serviceProviderTitle;
                        break;
                    case 6:
                        role = `${findlanguage().buyerTitle} / ${findlanguage().supplierTitle}`;
                        break;
                    case 7:
                        role = findlanguage().headquarters;
                        break;
                    default:
                        role = "";
                        break;
                }

                return {
                    value: connection.userId,
                    label: `${connection.contactName} - ${role}`,
                    company_id: connection.company_id,
                    role: connection.role,
                };
            });

            const uniqueCompanies = Array.from(
                new Map(list.map(company => [company.company_id, { value: company.company_id, label: company.name }])).values()
            );

            setCompanies(uniqueCompanies);
            setAllUsers(usersList);
        };

        init();
        listConnections();
    }, [findlanguage]);

    const handleFormSelect = async (selectedOption) => {
        const form = await GetFullStep(selectedOption.value);
        setSelectedForm(form);
        const initialAssignments = form.sections.reduce((acc, section) => {
            acc[section.id] = [];
            return acc;
        }, {});
        setUserAssignments(initialAssignments);
        setSelectedFormId(selectedOption.value);
    };

    const handleCompanySelect = (selectedOption) => {
        setSelectedCompany(selectedOption);

        const filteredUsers = allUsers
            .filter(x => x.company_id === selectedOption.value || x.role === 5)
            .sort((a, b) => a.label.localeCompare(b.label));

        setUsers(filteredUsers);
    };

    const handleUserAssignmentChange = (sectionId, selectedOptions) => {
        setUserAssignments(prevAssignments => ({
            ...prevAssignments,
            [sectionId]: selectedOptions.map(option => option.value)
        }));
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handleCreateNew = () => {
        setIsCreatingNew(true);
        setFormMode('new');
        setSelectedFormId(null);
    };

    const handleEdit = (formId) => {
        setIsCreatingNew(true);
        setFormMode('edit');
        setSelectedFormId(formId);
    };

    const handleView = (formId) => {
        setIsCreatingNew(true);
        setFormMode('view');
        setSelectedFormId(formId);
    };

    const handleDelete = async (form) => {
        const updatedForm = { ...form, is_active: false };
        await PutStepsForm(updatedForm);
        setFormData(formData.filter(item => item.id !== form.id));
    };

    const isSubmitDisabled = !Object.values(userAssignments).every(assignment => assignment.length > 0);

    const handleSave = async () => {
        const firstSectionId = Object.keys(userAssignments)[0];

        const submissionData = {
            step_assigned_to_companies: {
                status: 'open',
                expired_date: expirationDate.toISOString().slice(0, 10),
                user: selectedCompany?.value,
                step_form_id: selectedFormId,
                company_id: allUsers.find(company => company.value === selectedCompany?.value)?.company_id,
                current_step: firstSectionId
            },
            step_assigned_to_users: Object.entries(userAssignments).flatMap(([sectionId, userIds]) => {
                return userIds.map(userId => ({
                    step_section_id: sectionId,
                    user: userId,
                    status: 'waiting',
                }));
            }),
        };

        await PostAssignedStepForm(submissionData);
        toast.success(`Step submitted to company ${selectedCompany.label}`);

        setSelectedForm(null);
        setSelectedFormId(null);
        setSelectedCompany(null);
        setUserAssignments({});
        setUsers([]);

        setShowModal(false);
    };

    const filteredData = formData.filter(form =>
        form.name.toLowerCase().includes(searchTerm) && form.is_active
    );

    return (
        <div>
            {isCreatingNew ? (
                <Form setIsCreatingNew={setIsCreatingNew} formId={selectedFormId} mode={formMode} init={() => { }} />
            ) : (
                <>
                    <PageCard>
                        <div className="container">
                            <div className="row mt-4">
                                <div className="col-12">
                                    <h2>Send Requirements</h2>
                                </div>
                                <div className="col-md-5">
                                    <label>Forms</label>
                                    <Select
                                        options={formData.map(form => ({ value: form.id, label: form.name }))}
                                        onChange={handleFormSelect}
                                        value={selectedForm ? { value: selectedForm.id, label: selectedForm.name } : null}
                                    />
                                </div>
                                <div className="col-md-5">
                                    <label>Companies</label>
                                    <Select
                                        options={companies}
                                        onChange={handleCompanySelect}
                                        isDisabled={!selectedForm}
                                        value={selectedCompany}
                                    />
                                </div>
                                <div className="col-md-2" style={{ marginTop: '31px' }}>
                                    <button
                                        style={{ width: '100%' }}
                                        className="btn colorTealDark"
                                            onClick={handleSave}
                                        disabled={!selectedForm || !selectedCompany || isSubmitDisabled}
                                    >
                                        Submit Step
                                    </button>
                                </div>
                            </div>
                        </div>
                    </PageCard>
                    {selectedForm && selectedCompany && (
                        <SectionsToUploadTheUser
                            sections={selectedForm.sections}
                            setSections={(sections) => setSelectedForm({ ...selectedForm, sections })}
                            currentStep={{}}
                            setCurrentStep={() => { }}
                            is_draft={false}
                            userAssignments={userAssignments}
                            handleUserAssignmentChange={handleUserAssignmentChange}
                            fakeUsers={users}
                        />
                    )}
                    <PageCard>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-12">
                                    <h2>My Requirements</h2>
                                </div>
                                <div className="col-md-2">
                                    <div><b>Total Requirements: {formData.length}</b></div>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Here"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <button className="btn colorTealDark" style={{ width: '100%' }} onClick={handleCreateNew}>
                                        Create new
                                    </button>
                                </div>
                            </div>
                            <div className="row mt-4">
                                {filteredData.map(form => (
                                    <div key={form.id} className="col-md-4 mb-3">
                                        <div className="card car-box-shadow">
                                            <div className="card-body d-flex justify-content-between align-items-center">
                                                <span>{form.name}</span>
                                                <div className="ms-auto">
                                                    {form.is_draft ? (
                                                        <button className="btn btn-icon btn-sm btn-light me-2" onClick={() => handleEdit(form.id)}>
                                                            <i className="bi bi-pencil" style={{ color: '#5B5B5B' }}></i>
                                                        </button>
                                                    ) : (
                                                        <button className="btn btn-icon btn-sm btn-light me-2" onClick={() => handleView(form.id)}>
                                                            <i className="bi bi-eye" style={{ color: '#5B5B5B' }}></i>
                                                        </button>
                                                    )}
                                                    <button className="btn btn-icon btn-sm btn-light" onClick={() => handleDelete(form)}>
                                                        <i className="bi bi-trash" style={{ color: '#FF4141' }}></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </PageCard>
                    <Modal show={showModal} onHide={() => setShowModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Select Expiration Date</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <DatePicker selected={expirationDate} onChange={date => setExpirationDate(date)} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleSave}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <ToastContainer />
                </>
            )}
        </div>
    );
}
