import { DELETE, GET, POST, PUT } from './api'

export const SearchNewSuppliers = async (id) => {
    return await GET(`/supplier-reg-requests/search?id=${id}`, true)
}

export const SearchNewServiceProviders = async (id) => {
    return await GET(`/supplier-reg-requests/searchservicerprovider?id=${id}`, true)
}

export const SearchNoUserSuppliers = async () => {
    return await GET(`/product-source-certifications/getnames`, true)
}

export const SearchGetProducts = async (id) => {
    return await GET(`/product-source-certifications/getproducts?id=${id}`, true)
}

export const SearchEmailExist = async (id, email) => {
    return await GET(`/supplier-reg-requests/searchemail?id=${id}&email=${email}`, true)
}

export const SearchUserEmailExist = async (email) => {
    return await GET(`/supplier-reg-requests/searchuseremail?email=${email}`, true)
}

export const CreateFormRequestForSupplier = async (body) => {
    return await POST(`/validation-process-responses`, body, true)
}

export const FindValidationProcess = async () => {
    return await GET(`/validation-process-responses/getusers`, true)
}

export const FindValidationProcessForSuppliers = async () => {
    return await GET(`/validation-process-responses/getValidations`, true)
}

export const UpdateForm = async (body) => {
    return await POST(`/validation-process-responses/updateForm`, body, true)
}

export const UpdateFormRequestForSupplier = async (body) => {
    return await PUT(`/validation-process-responses/${body.id}`, body, true)
}

export const GetForm = async (formId) => {
    return await GET(`/validation-process-responses/formulario?formId=${formId}`, true)
}

export const DeleteForm = async (id) => {
    return await DELETE(`/validation-process-responses/${id}`, true)
}
