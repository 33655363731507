import styled from 'styled-components'

export const CardFormStatus = styled.div.attrs({})`
    width: 18px;
    height: 18px;
    border-radius: 9px;
    background-color: ${(props) => props.color};
`
export const EditIcon = styled.img.attrs({
    src: '/icons/edit.svg',
    height: '20px',
})`
    margin-left: 5px;
    cursor: pointer;
`

export const CardDocument = styled.img.attrs({
    src: '/icons/document.svg',
})``

export const BlockedIcon = styled.img.attrs({
    src: '/icons/block-icon.svg',
})``
