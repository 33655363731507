import React, { useEffect, useState, useContext } from 'react'

import { CoreContext } from 'context/CoreContext'
import { GetSupplierQuestionnarieGetOne } from 'services/egs'
import { GetPrecheck } from 'services/precheck'
import { GetPrecheckServiceProvider } from 'services/precheckServiceProvider'
import { useHistory } from 'react-router-dom'

import DashboardButton from 'components/Dashboard/Button'

import Mfs from 'components/Dashboard/Sms/MyValidationProcess/Mfs/index'
import Esg from 'components/Dashboard/Sms/MyValidationProcess/Esg/index'
import Score from 'components/Dashboard/Sms/MyValidationProcess/Score/index'
import Capa from 'components/Dashboard/Sms/MyValidationProcess/Capa/index'
import Precheck from 'components/Dashboard/Sms/MyValidationProcess/Precheck/index'
import PrecheckServiceProvider from 'components/Dashboard/Sms/MyValidationProcess/PrecheckServiceProvider/index'

import {
    PageCard,
    CardLine,
    CardLineColumn,
    CardFormStatus,
    MfsGreenCertificate,
    MfsGrayCertificate,
    ContentFilters,
    FilterContent,
} from './styled'

export default function MyValidationProcess() {
    const {
        premium,
        profile,
        showAccessDenied,
        showContactSales,
        findlanguage,
        user,
        permissions,
    } = useContext(CoreContext)

    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)
    const [screen, setScreen] = useState(1)
    const isDevEnv = window.location.href.includes('dev-app.mytrustedsc.com')
    const [mfsValidation, setMfsValidation] = useState(false)
    const [questionnaire, setQuestionnaire] = useState({
        id: -1,
        legal_company_name: '',
        ein_number: '',
        address: '',
        city: '',
        state: '',
        country: '',
        zip: '',
        phone: '',
        email: '',
        category: '',
        other: '',
        position: '',
        type_of_company: '',
        person: '',
        form_status: '',
    })
    const [basicInformation, setBasicInformation] = useState({
        id: -1,
        status: '',
    })

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        const getSupplierQuestionnarie = await GetSupplierQuestionnarieGetOne(user.company_id)
        let getBasicInformation =
            user.active_account.type === 'service-provider'
                ? await GetPrecheckServiceProvider(user.company_id)
                : await GetPrecheck(user.company_id)

        setQuestionnaire(getSupplierQuestionnarie[0])
        setBasicInformation(getBasicInformation[0])
    }

    const callScreen = (screenId) => {
        if (profile === 'buyer') {
            if (premium.type === 'premium') {
                switch (screenId) {
                    case 4: // MyTS Validation
                        break
                    case 5: // ESG
                        //showContactSales()
                        //return false
                        break
                    default:
                        break
                }
            } else {
                switch (screenId) {
                    case 4: // MyTS Validation
                        showAccessDenied(premium.type)
                        return false
                    case 5: // ESG
                        //showContactSales()
                        //return false
                        break
                    default:
                        break
                }
            }
        } else {
            if (premium.type === 'premium') {
                switch (screenId) {
                    case 4: // MyTS Validation
                        break
                    case 5: // ESG
                        //showContactSales()
                        //return false
                        break
                    default:
                        break
                }
            } else {
                switch (screenId) {
                    case 4: // MyTS Validation
                        showAccessDenied(premium.type)
                        return false
                    case 5: // ESG
                        //showContactSales()
                        //return false
                        break
                    default:
                        break
                }
            }
        }

        setScreen(screenId)
    }

    const changeScreen = (screenId) => {
        setScreen(screenId)
    }

    const readBlackBoldCss = {
        whiteSpace: 'pre-line',
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#565656',
        cursor: 'pointer',
    }

    const getDate = () => {
        const endDate = new Date(questionnaire?.created_at)
        return endDate.getMonth() + '/' + endDate.getDate() + '/' + (endDate.getFullYear() + 1)
    }

    return (
        <>
            <PageCard>
                <ContentFilters>
                    <FilterContent>
                        <DashboardButton
                            icon={'check'}
                            green
                            outline={screen === 1 ? false : true}
                            onClick={() => changeScreen(1)}
                        >
                            {findlanguage().myValidationProcess}
                        </DashboardButton>
                        {questionnaire?.form_status === 'accepted' ? (
                            <>
                                <DashboardButton
                                    icon={'check'}
                                    green
                                    outline={screen === 2 ? false : true}
                                    onClick={() => changeScreen(2)}
                                >
                                    Score
                                </DashboardButton>
                                {questionnaire?.form_status === 'accepted' &&
                                (questionnaire?.score_a !== 100 ||
                                    questionnaire?.score_b !== 100 ||
                                    questionnaire?.score_c !== 100) ? (
                                    <DashboardButton
                                        icon={'check'}
                                        green
                                        outline={screen === 3 ? false : true}
                                        onClick={() => changeScreen(3)}
                                    >
                                        CAPA
                                    </DashboardButton>
                                ) : null}
                            </>
                        ) : null}
                    </FilterContent>
                </ContentFilters>
            </PageCard>
            {screen === 1 ? (
                <>
                    <PageCard>
                        <CardLine>
                            <CardLineColumn>
                                <label
                                    onClick={() => callScreen(6)}
                                    style={readBlackBoldCss}
                                    className="form-label"
                                >
                                    {findlanguage().myPrecheck}
                                    {basicInformation?.status === 'approved' ? (
                                        <img
                                            src="/icons/checked.svg"
                                            alt="Approved"
                                            style={{ marginLeft: '10px', height: '25px' }}
                                        />
                                    ) : null}
                                    {basicInformation?.status === 'decline' ? (
                                        <i
                                            style={{ color: 'red', marginLeft: '#e13939' }}
                                            className="icon fas fa-ban"
                                        ></i>
                                    ) : null}
                                </label>
                            </CardLineColumn>
                            <CardLineColumn>
                                <label
                                    onClick={() => setMfsValidation(true)}
                                    style={readBlackBoldCss}
                                    className="form-label"
                                >
                                    {basicInformation?.status === 'open' ? (
                                        <div style={{ display: 'inline-flex' }}>
                                            <CardFormStatus
                                                color="gray"
                                                style={{ marginRight: '5px' }}
                                            />{' '}
                                            {findlanguage().formOnOpenStatus}{' '}
                                        </div>
                                    ) : null}
                                </label>
                                <label
                                    onClick={() => setMfsValidation(true)}
                                    style={readBlackBoldCss}
                                    className="form-label"
                                >
                                    {basicInformation?.status === 'submitted' ? (
                                        <div style={{ display: 'inline-flex' }}>
                                            <CardFormStatus
                                                color="#263640"
                                                style={{ marginRight: '5px' }}
                                            />{' '}
                                            {findlanguage().submitted}
                                        </div>
                                    ) : null}
                                </label>
                            </CardLineColumn>
                        </CardLine>
                    </PageCard>
                </>
            ) : null}
            {screen === 2 ? <Score questionnaire={questionnaire} /> : null}
            {screen === 3 ? <Capa questionnaire={questionnaire} /> : null}
            {screen === 4 ? <Mfs setScreen={setScreen} /> : null}
            {screen === 5 ? <Esg setScreen={setScreen} /> : null}
            {screen === 6 && user.active_account.type === 'service-provider' ? (
                <PrecheckServiceProvider setScreen={setScreen} setBasic={setBasicInformation} />
            ) : null}
            {screen === 6 && user.active_account.type !== 'service-provider' ? (
                <Precheck setScreen={setScreen} setBasic={setBasicInformation} />
            ) : null}

            {screen === 1 && isDevEnv ? (
                <>
                    <PageCard>
                        <Mfs setScreen={setScreen} />
                        <Esg setScreen={setScreen} />
                    </PageCard>
                </>
            ) : null}
        </>
    )
}
