import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import ButtonIcon from 'components/Form/ButtonIcon'

import {
    HeaderContainer,
    HeaderBackIcon,
    HeaderContent,
    HeaderTitle,
    HeaderActionIcon,
    HeaderActionSpace,
} from './styled'
import Button from 'components/Form/Button'
import { CoreContext } from 'context/CoreContext'

export default function RegisterHeader({ title, chatable, backable, rightAction, loading }) {
    const { goBack } = useHistory()
    const { setModal } = useContext(CoreContext)

    const openChat = () => {
        setModal({ type: 'chat' })
    }

    return (
        <>
            <HeaderContainer>
                {!backable ? (
                    <HeaderActionSpace bigger={!!rightAction} />
                ) : (
                    <ButtonIcon link onClick={goBack}>
                        <HeaderBackIcon />
                    </ButtonIcon>
                )}
                <HeaderContent>
                    <HeaderTitle>{title}</HeaderTitle>
                </HeaderContent>
                {!chatable && !rightAction ? <HeaderActionSpace /> : null}
                {!chatable ? null : (
                    <ButtonIcon onClick={openChat}>
                        <HeaderActionIcon />
                    </ButtonIcon>
                )}
                {!rightAction ? null : (
                    <Button small onClick={rightAction.action} loading={loading}>
                        {rightAction.title}
                    </Button>
                )}
            </HeaderContainer>
        </>
    )
}
