import styled from 'styled-components'

export const Action = styled.div.attrs({})`
    width: 56px;
    height: 56px;
    border-radius: 28px;
    ${(props) => (!props.link ? `background: var(--grey-color);` : ``)}

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        box-shadow: 2px 2px 3px var(--shadow-color);
    }
`
