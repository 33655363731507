import styled from 'styled-components'

export const CardLineBadge = styled.div.attrs({})`
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background: var(--lightgrey-color);

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--white-color);

    ${(props) =>
        props.percentage >= 80
            ? `
            background: var(--primary-color);
        `
            : ``}
    ${(props) =>
        props.percentage >= 50 && props.percentage < 80
            ? `
            background: var(--orange-color);
            color: var(--grey-color);
        `
            : ``}
    ${(props) =>
        props.percentage >= 20 && props.percentage < 50
            ? `
            background: var(--yellow-color);
            color: var(--grey-color);
        `
            : ``}
    ${(props) =>
        props.percentage >= 1 && props.percentage < 20
            ? `
            background: var(--red-color);
            color: var(--grey-color);
        `
            : ``}
`
