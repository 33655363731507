import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
    ProfileFormContent,
    ProfileFormActions,
    PageFormSpace,
    RowForm,
    Label,
    ModalFormLabel,
} from './styled'

import Button from 'components/Form/Button'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

import SelectSupplierProduct from 'components/Form/SelectSupplierProduct'
import { ReadSupplierProductsSource } from 'services/infos'

import { AddProduct, GetProduct } from 'services/myProfile'

import Check from 'components/Form/Check'
import { LoadBlack } from 'ui/styled'
import SupplierProductsOptions from 'components/Form/SupplierProductsOptions'

export default function ModalAddProduct() {
    const { user, modal, setModal, language, findlanguage } = useContext(CoreContext)

    const [productSelected, setProductSelected] = useState({})

    const getRadioData = (category, radio) => {
        if (language === 'portugues')
            setProductSelected({
                category: category,
                product_id: radio.id,
                product_name: radio.name_pt,
            })
        else
            setProductSelected({
                category: category,
                product_id: radio.id,
                product_name: radio.name,
            })
    }

    const action = async () => {
        let body_prod

        if (modal.id === undefined) {
            if (productSelected.category === undefined) {
                toast.error(`${findlanguage().fillTheField}: ${findlanguage().product}`)
                return
            }

            body_prod = {
                category: productSelected.category,
                product_id: productSelected.product_id < 0 ? 0 : productSelected.product_id,
                product_name: productSelected.product_name,
                user_id: user.company_id,
            }
        } else {
            body_prod = { ...productSelected }
        }

        await AddProduct(body_prod)

        setModal({
            type: 'alert',
            text:
                modal.id === undefined
                    ? findlanguage().productAddedSuccessfully
                    : findlanguage().changesSaved,
        })

        const requestRefresh = modal.onRefresh

        if (requestRefresh) requestRefresh()
    }

    const [products, setProducts] = useState([])

    useEffect(() => {
        if (modal.id === undefined) {
            ReadSupplierProductsSource().then((resp) => {
                let allprods = []
                if (language === 'portugues')
                    allprods = resp
                        ?.sort((a, b) => (a.category_pt > b.category_pt ? 1 : -1))
                        .map((item) => ({
                            category: item.category_pt,
                            checked: false,
                            options: item.items?.length
                                ? item.items.sort((a, b) => (a.name_pt > b._pt ? 1 : -1))
                                : [],
                        }))
                else if (language === 'english')
                    allprods = resp
                        ?.sort((a, b) => (a.category > b.category ? 1 : -1))
                        .map((item) => ({
                            category: item.category,
                            checked: false,
                            options: item.items?.length
                                ? item.items.sort((a, b) => (a.name > b.name ? 1 : -1))
                                : [],
                        }))

                setProducts(allprods)
            })
        } else {
            GetProduct(modal.id).then((prod) => {
                const _prod = {
                    id: prod.id,
                    category: prod.category,
                    product_id: prod.product_id,
                    product_name: prod.product_name,
                    user_id: prod.user_id,
                }

                setProductSelected({ ..._prod })
            })
        }
    }, [modal.id])

    return (
        <>
            <ModalWrapper>
                <ProfileFormContent>
                    <ModalFormLabel spaced>
                        {findlanguage().whatProductsAreProducedSupplied}
                    </ModalFormLabel>

                    {modal.id === undefined ? (
                        <RowForm>
                            {products.length === 0 ? <LoadBlack size={50} /> : ''}
                            <SupplierProductsOptions
                                unique={true}
                                datasource={products}
                                onSelectChange={getRadioData}
                            ></SupplierProductsOptions>
                        </RowForm>
                    ) : (
                        <RowForm>
                            <Label>
                                <div>{productSelected.category}</div>

                                <Label>
                                    <Check
                                        checked={true}
                                        label={productSelected.product_name}
                                        nowidth
                                        locked
                                    />
                                </Label>
                            </Label>
                        </RowForm>
                    )}
                </ProfileFormContent>
                <PageFormSpace />
                <ProfileFormActions>
                    <Button primary onClick={action}>
                        {findlanguage().save}
                    </Button>
                </ProfileFormActions>
            </ModalWrapper>
        </>
    )
}
