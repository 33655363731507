import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { CoreContext } from 'context/CoreContext'

import {
    PageCard,
    ProfileContainer,
    ProfileContent,
    ProfileContentSpace,
    ProfileFormContent,
    ProfileFormActions,
    InputSpace,
} from './styled'

import Input from 'components/Form/Input'

import DashboardButton from 'components/Dashboard/Button'
import FormImagePicker from 'components/Form/ImagePicker'

export default function UserProfileConsumer() {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const { setModal, user } = useContext(CoreContext)

    const openEdit = () => {
        setModal({
            type: 'company-information',
            title: 'Consumer Information',
        })
    }

    return (
        <>
            <PageCard>
                <ProfileContainer>
                    <ProfileContent>
                        <FormImagePicker image={'/images/company.png'} />
                        <ProfileContentSpace />
                        <span style={{ marginLeft: '15px' }}>
                            <DashboardButton
                                onClick={() => navigate('dashboard/account-information')}
                            >
                                Account Information
                            </DashboardButton>
                        </span>
                    </ProfileContent>
                    <ProfileFormContent>
                        <Input placeholder="Name" value={user.name} />
                        <Input placeholder="E-mail" value={user.email} />
                        <Input placeholder="Phone Number" value={user.phone} />
                        <Input blank_space={true} />
                        <Input placeholder="Address" value={user.address?.street} />
                        <Input placeholder="Country" value={user.address?.country} />
                        <Input placeholder="State" value={user.address?.state} />
                        <Input placeholder="City" value={user.address?.city} />
                        <Input placeholder="Zip Code" value={user.address?.zipCode} />
                        <Input placeholder="Website" value={user.website} />
                        {user.department ? (
                            <Input placeholder="Department" value={user.department} />
                        ) : (
                            ''
                        )}
                        <ProfileFormActions>
                            <DashboardButton onClick={openEdit}>Edit Information</DashboardButton>
                        </ProfileFormActions>
                    </ProfileFormContent>
                </ProfileContainer>
            </PageCard>
        </>
    )
}
