import styled from 'styled-components'

export const ContentTitle = styled.div.attrs({})`
    font-size: 22px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--grey-color);
`

export const PageBody = styled.div.attrs({})`
    padding: 20px 40px;
    border-radius: 15px;
    background: var(--white-color);
    box-shadow: 0px 1.5px 6px var(--lightshadow-color);
    margin-bottom: 20px;
`

export const BodySubtitle = styled.div.attrs({})`
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--primary-color);
    margin: 25px 0 15px;
`
export const BodyText = styled.div.attrs({})`
    font-size: 16px;
    color: var(--lightgrey-color);
`

export const CertificateContent = styled.div.attrs({})`
    display: flex;
    justify-content: center;
    padding: 32px 0;
    gap: 0 12px;
`
export const CertificateItem = styled.div.attrs({})`
    width: 93px;
    height: 93px;

    background: url(/icons/certificate-${(props) => props.certificate}.svg) no-repeat center center /
        contain;
`

export const BodyDocumentsWapper = styled.div.attrs({})`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
`

export const DocumentContainer = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const DocumentContent = styled.div.attrs({})`
    width: 261px;
    height: 120px;
    border: 0.5px solid var(--backgroundlightgrey-color);
    border-radius: 15px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`
export const DocumentContentIcon = styled.img.attrs({
    src: '/icons/document.svg',
})`
    margin-bottom: 12px;
`
export const DocumentContentTitle = styled.div.attrs({})``
export const DocumentActions = styled.div.attrs({})`
    display: flex;
    padding-top: 20px;
`

export const ButtonIcon = styled.img.attrs((props) => ({
    src: `/icons/${props.icon}-white.svg`,
}))`
    margin-right: 8px;
`

export const ServicesTable = styled.div.attrs({})`
    padding: 30px 30px 0;
    border: 0.5px solid var(--backgroundlightgrey-color);
    border-radius: 15px;
    margin-top: 20px;
`
export const ServicesTableTitle = styled.div.attrs({})`
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--primary-color);
    margin-bottom: 30px;
`
export const ServicesTableRow = styled.div.attrs({})`
    border-top: 0.5px solid var(--backgroundlightgrey-color);
    padding: 16px 0;
    display: flex;
    align-items: center;
`
export const ServiceTableColumn = styled.div.attrs({})`
    min-width: 200px;
`
export const ServiceTableText = styled.div.attrs({})`
    font-size: 16px;
    ${(props) =>
        props.bold
            ? `
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            color: var(--grey-color);
        `
            : `
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            color: var(--lightgrey-color);
        `}
`
