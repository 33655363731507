import styled from 'styled-components'

export const ChartContent = styled.div.attrs({})`
    flex: 1;
    margin: 0 -30px 0px;
    width: 70%;
    padding-top: 0px;
    position: relative;

    overflow: visible;
    border-radius: 15px;
`

export const ChartPosition = styled.div.attrs({})`
    flex: 1;
    width: 100%;
    padding-top: 1px;
    padding-bottom: 30px;
    position: relative;
    overflow: visible;
    border-radius: 15px;
    min-height: 250px;
    max-height: 250px;
`

export const ChartDecoration = styled.div.attrs({})`
    position: absolute;
    bottom: -30px;
    right: -10px;
    left: -40px;
`

export const ChartPanel = styled.div.attrs({})`
    width: 90%;
    margin: auto;
    padding: 20px;
    max-height: 250px;

    ${(props) =>
        props.visible
            ? `
            display: inline;
        `
            : `display: none;`}
`
