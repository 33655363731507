import React, { useState } from 'react'
import { toast } from 'react-toastify'

import Button from 'components/Form/Button'

import { ModalBody, ModalBodyTitle, RateContent, RateStar } from './styled'

export default function ModalChatModeRate({ close }) {
    const [reviewRate, setReviewRate] = useState(0)

    const action = () => {
        if (!reviewRate) {
            toast.success('Rate to send')
            return
        }
        toast.success('Review sent')
        if (close && typeof close === 'function') {
            close()
        }
    }

    return (
        <>
            <ModalBody>
                <ModalBodyTitle>How was your service?</ModalBodyTitle>

                <RateContent>
                    {[1, 2, 3, 4, 5].map((item, key) => (
                        <RateStar
                            key={key}
                            active={reviewRate >= item}
                            onClick={() => setReviewRate(item)}
                        />
                    ))}
                </RateContent>
                <Button small primary onClick={action}>
                    Submit review
                </Button>
                <Button white={true} onClick={close}>
                    I don’t want to evaluate
                </Button>
            </ModalBody>
        </>
    )
}
