import React, { useContext, useEffect, useState } from 'react';
import DashboardPanel from 'components/Dashboard/Panels/Wrapper';
import DashboardCardMessage from 'components/Dashboard/Cards/Messages';
import { CoreContext } from 'context/CoreContext';
import { FetchLatestMessages } from 'services/dashboard';
import { Load } from 'ui/styled';

export default function DashboardPanelMessage({ cardTitle, onClose }) {
    const { setModal, premium, findlanguage } = useContext(CoreContext);

    const panel = {
        title: findlanguage().messages,
        actions: [],
        onClose,
    };

    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(false);

    const loadMessageList = async () => {
        setLoading(true);
        try {
            const messages = await FetchLatestMessages();
            const formattedCards = messages.map((card) => ({
                room_id: card.room_id,
                image: card.url,
                title: card.sender_company_name,
                subtitle: card.state && card.country ? `${card.state}, ${card.country}` : '',
                description: card.message,
            }));
            setCards(formattedCards);
        } catch (error) {
            console.error('Failed to load messages:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadMessageList();
    }, []);

    const newMessage = () => {
        setModal({
            type: 'new-message',
            title: 'Who would you like to send the message to?',
        });
    };

    return (
        <DashboardPanel {...panel} subscription={premium?.type}>
            {loading ? (
                <Load color="#263640" />
            ) : cards.length === 0 ? (
                <div style={{ width: '100%' }}>{findlanguage().noData}</div>
            ) : (
                cards.map((item, key) => (
                    <DashboardCardMessage key={`card-${key}`} {...item} reload={loadMessageList} />
                ))
            )}
        </DashboardPanel>
    );
}
