import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { parseStrapiImage } from 'utils'
import Check from 'components/Form/Check'
import Button from 'components/Form/Button'
import {
    CardSearched,
    CardImage,
    CardContent,
    CardTitle,
    CardReadIcon,
    CardTouch,
    CardDate,
    CheckHeader,
    CheckIcon,
} from './styled'

import DashboardMore from 'components/Dashboard/More'
import { CoreContext } from 'context/CoreContext'

export default function DashboardCardNotification({
    item,
    refresh,
    acceptOrDeclineSupplier,
    acceptOrDeclineConnection,
    deleteNotification,
    add,
}) {
    const { formatDateTimeValue, setModal, user, findlanguage } = useContext(CoreContext)

    // const item = reg?.receiverUser.id === user.company_id ? reg?.user : reg?.receiverUser
    // const address =
    //     reg?.receiverUser.id === user.company_id ? reg?.user?.address : reg?.receiverUser?.address

    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const moreOptions = (card) => {
        let options = [
            {
                title: findlanguage().removeNotification,
                action: async () => await deleteNotification(card.id),
            },
        ]
        return options
    }

    return (
        <>
            <CardSearched>
                <CardTouch>
                    {item.read || item.decided_connection ? (
                        <CardReadIcon url={'/icons/green-check.svg'} />
                    ) : (
                        <>
                            <CheckHeader onClick={() => add(item)}>
                                <Check />
                                <CheckIcon />
                            </CheckHeader>
                        </>
                    )}
                </CardTouch>
                <CardTouch>
                    <CardImage
                        url={
                            item.user?.avatar
                                ? parseStrapiImage(item.user?.avatar?.url)
                                : `/images/no-image.png`
                        }
                    />
                </CardTouch>

                <CardContent>
                    <CardTouch>
                        <CardTitle>{item.label}</CardTitle>
                        <CardDate>{formatDateTimeValue(item.created_at)}</CardDate>
                        {item.type === 'add_connection' && item.decided_connection === false ? (
                            <CardTitle>
                                <button
                                    className="btn btn-success"
                                    onClick={() => acceptOrDeclineConnection(true, item)}
                                >
                                    {findlanguage().yes}
                                </button>
                                <button
                                    style={{ marginLeft: '20px' }}
                                    onClick={() => acceptOrDeclineConnection(false, item)}
                                    className="btn btn-danger"
                                >
                                    {findlanguage().no}
                                </button>
                            </CardTitle>
                        ) : null}
                        {item.type === 'new_myqms' && item.decided_connection === false ? (
                            <CardTitle>
                                <button
                                    className="btn btn-success"
                                    onClick={() => acceptOrDeclineSupplier(true, item)}
                                >
                                    {findlanguage().yes}
                                </button>
                                <button
                                    style={{ marginLeft: '20px' }}
                                    onClick={() => acceptOrDeclineSupplier(false, item)}
                                    className="btn btn-danger"
                                >
                                    {findlanguage().no}
                                </button>
                            </CardTitle>
                        ) : null}
                    </CardTouch>
                </CardContent>
                <DashboardMore options={moreOptions(item)} />
            </CardSearched>
        </>
    )
}
