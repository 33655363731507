import styled from 'styled-components'

export const Content = styled.div.attrs({})``

export const ChartContent = styled.div.attrs({})`
    display: flex;
    justify-content: center;
    flex: 1;
    height: 260px;
    margin-top: 60px;
`
export const ChartItem = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 0 10px;
`
export const ChartItemBar = styled.div.attrs({})`
    width: 20px;
    border-radius: 15px;
    background: ${(props) => props.color};
    height: ${(props) => props.progress}%;
    margin: 8px 0;
`
export const ChartItemText = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--lightgrey-color);
    font-size: 17px;
`

export const TotalsContainer = styled.div.attrs({})`
    flex: 1;
`

export const TotalsContent = styled.div.attrs({})`
    flex: 1;
`

export const TotalsTitle = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--lightgrey-color);
    font-size: 17px;
    margin-bottom: 12px;
`

export const TotalsInfo = styled.div.attrs({})`
    background: var(--backgroundlightgrey-color);
    border-radius: 11px;
    padding: 20px;
`

export const TotalsInfoText = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--grey-color);
    font-size: 34px;
    text-align: center;
`

export const WrapperTotal = styled.div.attrs({})`
    margin: 20px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0 20px;
`
export const SubtotalContent = styled.div.attrs({})``
export const SubtotalTitle = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--lightgrey-color);
    font-size: 16px;
    margin-bottom: 12px;
`
export const SubtotalInfo = styled.div.attrs({})`
    width: 96px;
    height: 87px;
    background: var(--backgroundlightgrey-color);
    border-radius: 11px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--grey-color);
    font-size: 34px;
`
