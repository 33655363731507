import React, { useContext, useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import { Load } from 'ui/styled'
import DashboardPanel from 'components/Dashboard/Panels/Wrapper'
import { CoreContext } from 'context/CoreContext'
import { CountSupplierProductViews } from 'services/dashboard'

export default function DashboardPanelMyProducts({ cardTitle, onClose }) {
    const { setModal, premium, findlanguage } = useContext(CoreContext)
    const panel = {
        title: findlanguage().competitors,
        actions: [],
        onClose,
        big: false,
    }

    const [chartData, setChartData] = useState(null)
    const [chartOptions, setChartOptions] = useState(null)
    const [loading, setLoading] = useState(false)

    const checkAccess = () => premium.type === 'free'

    const loadMyProducts = () => {
        setLoading(true)
        CountSupplierProductViews().then((chart) => {
            if (!chart || chart.length === 0) {
                setChartData(null)
            } else {
                const labels = []
                const data = []
                const backgroundColors = [
                    '#7189bf',
                    '#df7599',
                    '#ffc785',
                    '#72d6c9',
                    '#90d7ff',
                    '#3f8cb5',
                    '#ffe156',
                    '#3f88c5',
                ]

                chart.forEach((entry, i) => {
                    labels.push(entry.product_name)
                    data.push(parseInt(entry.count))
                })

                setChartData({
                    labels: labels,
                    datasets: [
                        {
                            label: findlanguage().views,
                            data: data,
                            backgroundColor: backgroundColors.slice(0, data.length),
                            borderColor: '#ffffff',
                            borderWidth: 2,
                        },
                    ],
                })

                setChartOptions({
                    plugins: {
                        legend: {
                            display: false,
                        },
                        datalabels: {
                            display: true,
                            color: 'white',
                            font: {
                                size: 16,
                                family: 'Open Sans',
                                weight: 'bold',
                            },
                            formatter: (value) => value,
                        },
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                        x: {
                            beginAtZero: true,
                        },
                        y: {
                            beginAtZero: true,
                        },
                    },
                })
            }

            setLoading(false)
        })
    }

    useEffect(() => {
        if (!checkAccess()) loadMyProducts()
    }, [])

    return (
        <>
            <DashboardPanel {...panel} subscription={premium?.type} locked={checkAccess()}>
                {loading ? (
                    <Load color="#263640" />
                ) : (
                    <>
                        {!chartData?.datasets?.length ? (
                            <div style={{ width: '100%' }}>{findlanguage().noData}</div>
                        ) : (
                            <div style={{ width: '95%', height: '270px' }}>
                                <Bar
                                    data={chartData}
                                    options={chartOptions}
                                    plugins={[ChartDataLabels]}
                                />
                            </div>
                        )}
                    </>
                )}
            </DashboardPanel>
        </>
    )
}
