import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import {
    CardSearched,
    CardImage,
    CardContent,
    CardTitle,
    CardSubtitle,
    CardContentLabel,
    CardLabelText,
    CardLabelIcon,
    CardLink,
    CardCertificationTitle,
    CardCertificationWrapper,
    CardCertificationItem,
    CardCertificationCheck,
    CardCertificationCheckIn,
    CardCertificationText,
    CardTouch,
    CardProfileContent,
    CardButtonDock,
} from './styled'
import DashboardMore from 'components/Dashboard/More'
import Button from 'components/Form/Button'
import { parseStrapiImage } from 'utils'
import { NewConnection } from 'services/connections'
import { NewWishlist } from 'services/wishlist'
import { CoreContext } from 'context/CoreContext'

export default function DashboardFilterCard({ serviceProvider, item, recommendationView }) {
    const { setModal, premium, setNewChat, user, findlanguage, permissions } = useContext(CoreContext)
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)
    const back = () => history.goBack()

    const moreOptions = () => {
        let options = [{ title: findlanguage().addConnection, action: () => addConnection() }]
        if (premium.type !== 'free')
            if (permissions?.wishlist?.canEdit === true) {
                options = options.concat([
                    { title: findlanguage().sendMessage, action: createNewChat },
                    { title: findlanguage().addToWishList, action: addWishlist },
                ])
            }
            else {
                options = options.concat([
                    { title: findlanguage().sendMessage, action: createNewChat },
                ])
            }
        return options
    }

    const createNewChat = () => {
        let user_key = user.id
      
        setNewChat({
            firstUserId: user_key,
            secondUserId: item.id,
            newMessageUserOne: false,
            newMessageUserTwo: false,
            lastMessage: new Date().toISOString(),
        })
        navigate('dashboard/message')
    }

    const addWishlist = async () => {
        setModal({
            type: 'loading',
            text: findlanguage().creatingNewWishList,
        })

        const response = await NewWishlist(item.id)

        if (response.statusCode === 400)
            return setModal({
                type: 'alert',
                text: response.message,
                warning: true,
            })

        if (response.statusCode)
            return setModal({
                type: 'alert',
                text: findlanguage().failedToCreatedWishList,
                warning: true,
            })

        setModal({
            type: 'alert',
            text: findlanguage().newWishListCreated,
        })
    }

    const addConnection = async () => {
        setModal({
            type: 'loading',
            text: findlanguage().creatingNewConnection,
        })

        const response = await NewConnection(item.id)

        if (response.statusCode === 400)
            return setModal({
                type: 'alert',
                text: findlanguage().thieConnedctionExistsAlready,
                warning: true,
            })

        if (response.statusCode)
            return setModal({
                type: 'alert',
                text: findlanguage().failedToCreateConnection,
                warning: true,
            })

        setModal({
            type: 'alert',
            text: findlanguage().newConnectionCreated,
        })
    }

    return (
        <>
            <CardSearched>
                <CardProfileContent>
                    <CardTouch onClick={() => navigate(`dashboard/user/${item.id}`)}>
                        <CardImage
                            src={item?.url ? parseStrapiImage(item?.url) : `/images/no-image.png`}
                        />
                    </CardTouch>
                </CardProfileContent>
                <CardContent onClick={() => navigate(`dashboard/user/${item.id}`)}>
                    {item.role == 4 ? (
                        <span
                            className="badge"
                            style={{ backgroundColor: '#263640', color: 'white' }}
                        >
                            {findlanguage().supplierTitle}
                        </span>
                    ) : null}

                    {
                        item.role == 3 ?
                            <span className="badge bg-primary">{findlanguage().buyerTitle}</span>
                            :
                            null
                    }
                    {
                        item.role == 6 ?
                            <span
                                className="badge"
                                style={{ backgroundColor: '#263640', color: 'white' }}
                            >
                                {findlanguage().buyerTitle} / {findlanguage().supplierTitle}
                            </span>
                            :
                            null
                    }
                    {
                        item.role == 7 ?
                            <>
                                <span
                                    className="badge"
                                    style={{ backgroundColor: '#4182ab', color: 'white' }}
                                >
                                    {findlanguage().headquarters}
                                </span>
                            </>
                            :
                            null
                    }

                    {item.role == 5 ? (
                        <span
                            className="badge"
                            style={{ backgroundColor: '#ded514', color: 'white' }}
                        >
                            {findlanguage().serviceProviderTitle}
                        </span>
                    ) : null}
                    {
                        item.status === 'approved' ?
                            <img src='/icons/checked.svg' alt='Approved' style={{ marginLeft: '10px', height: '25px' }} />
                            :
                            null
                    }

                    <CardTouch>
                        <CardTitle>{item?.company_name}</CardTitle>
                        <CardSubtitle>
                            {item?.country !== null && item?.state !== null
                                ? item?.country + ', ' + item?.state
                                : ''}
                            <br />
                            {item?.city === '' ? <br /> : item?.city}
                        </CardSubtitle>

                        {recommendationView === true ? (
                            <CardButtonDock>
                                <Button
                                    primary
                                    small
                                    style={{ width: '120px' }}
                                    onClick={() => navigate(`dashboard/user/${item.id}`)}
                                >
                                    {findlanguage().viewPage}
                                </Button>
                            </CardButtonDock>
                        ) : (
                            ''
                        )}
                    </CardTouch>
                </CardContent>
                {recommendationView ? '' : <DashboardMore options={moreOptions()} />}
            </CardSearched>
        </>
    )
}
