import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { CoreContext } from 'context/CoreContext'
import { toast } from 'react-toastify'
import { parseStrapiImage } from 'utils'

import {
    PageHeader,
    PageHeaderTitle,
    PageHeaderActions,
    PageCard,
    ProfileContainer,
    ProfileContent,
    ProfileContentSpace,
    ProfileFormContent,
    ProfileFormActions,
    DocumentUploadContent,
    DocumentUploadWrapper,
    DocumentItem,
    DocumentClose,
    DocumentIcon,
    DocumentTitle,
    ItemRow,
    Link,
    DeleteIcon,
} from './styled'

import { ReadCountries, ReadStates } from 'services/location'

import Checklist from 'components/Form/Checklist'
import Input from 'components/Form/Input'
import Button from 'components/Form/Button'
import Select from 'components/Form/Select'
import SelectMany from 'components/Form/SelectMany'

import DashboardButton from 'components/Dashboard/Button'
import FormImagePicker from 'components/Form/ImagePicker'
import { Load } from 'ui/styled'

import { UpdateMe, GetMe, UpdateMyUser } from 'services/authentication'
import { exposeStrapiErrorNew } from 'utils'
import { ReadServicesOffered, ReadProvidedServices, ReadCompanyCategories } from 'services/infos'

import {
    FetchMyServiceProviderRegions,
    FetchMyServiceProviderDocuments,
    RemoveServiceProviderDocument,
} from 'services/myProfile'
import { SubscribeToPremium } from 'services/premium'
import Modal from '../Modal/index'
import DummyInput from 'components/Form/DummyInput'
import InfoIcon from 'components/Form/InfoIcon'

export default function UserProfileServiceProvider() {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)
    const [loading, setLoading] = useState(false)

    const { setModal, user, profile, setUser, findlanguage, language, premium, setPremium } =
        useContext(CoreContext)

    const [colorDistribution, setColorDistribution] = useState('var(--primary-color)')
    const [loadingSelect, setLoadingSelect] = useState(true)
    const [loadingGrid, setLoadingGrid] = useState(true)
    const [countriesOptions, setCountriesOptions] = useState([])
    const [formValues, setFormValues] = useState([])

    const [categories, setCategories] = useState([])
    const [documents, setDocuments] = useState([])
    const [servicesOffered, setServicesOffered] = useState([])
    const [providedServices, setProvidedServices] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])

    const [services, setServices] = useState([])
    const [service_provide, setService_provide] = useState([])
    const [description_service, setDescription_service] = useState('')
    const [about, setAbout] = useState('')
    const [checked, setChecked] = useState(false)
    const [dueDate, setDueDate] = useState(null)
    const [currentDate, setCurrentDate] = useState(new Date())
    const [initGet, setInitGet] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const closeModal = () => {
        setIsModalOpen(false)
    }

    useEffect(() => {
        if (user?.subscription?.expiration_date) {
            setDueDate(new Date(user.subscription.expiration_date))
        }
    }, [user?.subscription?.expiration_date])

    useEffect(() => {
        if (initGet) {
            action()
            setInitGet(false)
        }
    }, [user?.address])

    const isValidForPremium = () => {
        return (
            user?.address?.state !== undefined &&
            user?.address?.state !== '' &&
            user?.address?.city !== undefined &&
            user?.address?.city !== '' &&
            user?.address?.zipCode !== undefined &&
            user?.address?.zipCode !== '' &&
            services.length !== 0 &&
            providedServices.length !== 0 &&
            selectedCategories.length !== 0
        )
    }

    const upgradeToPremium = async () => {
        const result = await SubscribeToPremium('freepremium', [])
        if (result) {
            setUser({
                ...user,
                subscription: {
                    ...user.subscription,
                    type: 'premium',
                },
            })

            const currentDate = new Date()
            const dueDate = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000)

            setPremium({
                ...premium,
                due: dueDate.toISOString(),
                type: 'premium',
            })

            setIsModalOpen(true)
        }
    }

    const init = async () => {
        setChecked(user.is_company_visible)
        const __user = { ...user }

        const _loadServicesOffered = await ReadServicesOffered()
        const _loadProvidedServices = await ReadProvidedServices()
        const _loadCountries = await ReadCountries()
        const _loadCompanyCategories = await ReadCompanyCategories()

        const tempCategories = _loadCompanyCategories
            ?.sort((a, b) => (a.title > b.title ? 1 : -1))
            .map((mit, key) => ({ id: key + 1, ...mit }))

        setCategories(tempCategories)

        const tempServicesOffered = _loadServicesOffered
            .sort((a, b) => (a.title > b.title ? 1 : -1))
            .map((item, key) => {
                if (language === 'portugues')
                    return {
                        id: key + 1,
                        title: item.label,
                        title_pt: item.label_pt,
                    }
                else return { id: key + 1, title: item.label }
            })

        setServicesOffered(tempServicesOffered)

        const tempProvidedServices = _loadProvidedServices
            .sort((a, b) => (a.title > b.title ? 1 : -1))
            .map((item, key) => {
                if (language === 'portugues') {
                    return {
                        id: key + 1,
                        title: item.label,
                        title_pt: item.label_pt,
                    }
                } else return { id: key + 1, title: item.label }
            })

        setProvidedServices(tempProvidedServices)

        if (user.IsCompanyUser) {
            __user.active_account = user.company_account.active_account
        }

        if (language === 'portugues' && __user.active_account?.offeredServices.length !== 0) {
            __user.active_account?.offeredServices.forEach((x) => {
                if (x.label === 'Consultant') x.label_pt = 'Consultor'
                else x.label_pt = x.label
            })

            __user.active_account?.providedServices.forEach((x) => {
                if (x.label === 'Quality') x.label_pt = 'Qualidade'
                else x.label_pt = x.label
            })
        }

        // console.log(__user.active_account?.providedServices)

        setServices(__user.active_account?.offeredServices)
        setService_provide(__user.active_account?.providedServices)
        setDescription_service(
            __user.active_account?.serviceDescription
                ? __user.active_account?.serviceDescription
                : ''
        )
        setAbout(__user.active_account?.aboutCompany ? __user.active_account?.aboutCompany : '')
        setSelectedCategories(__user.active_account?.myCategories)

        loadCheckList(
            tempServicesOffered,
            __user.active_account?.offeredServices,
            tempProvidedServices,
            __user.active_account?.providedServices
        )

        //region
        setLoadingSelect(true)

        const countries = _loadCountries
            .sort((a, b) => {
                const nameA = a.name ? a.name.toUpperCase() : ''
                const nameB = b.name ? b.name.toUpperCase() : ''
                if (nameA > nameB) {
                    return 1
                }
                return -1
            })
            .map((mit) => ({ ...mit, title: mit.name, field: 'country' }))

        countries.unshift({
            country: findlanguage().entireWorld,
            title: findlanguage().entireWorld,
            name: findlanguage().entireWorld,
            iso2: 'EW0',
            state: findlanguage().allRegions,
            field: 'country',
            stateOptions: [
                {
                    id: 99999,
                    name: findlanguage().allRegions,
                    iso2: 'AR0',
                    field: 'state',
                    value: findlanguage().allRegions,
                    traducao: findlanguage().allRegions,
                },
            ],
        })

        countries.unshift({
            country: '',
            state: '',
            field: 'country',
            stateOptions: [],
        })

        setCountriesOptions([...countries])

        await fetchMyServiceProviderRegions(countries)

        setLoadingSelect(false)

        await fetchMyServiceProviderDocuments()
        setInitGet(true)
    }

    const getSelected = (e) => {
        setSelectedCategories([...e])
    }

    const findCountryIso2 = (countries, name) => {
        const found = countries.filter((item) => item.title === name)
        if (found[0]) return found[0].iso2
        else return ''
    }

    const fetchMyServiceProviderRegions = async (countries) => {
        setLoadingSelect(true)

        const _loadServiceProviderRegions = await FetchMyServiceProviderRegions(
            user.company_id
        ).then((response) => {
            return response
                .sort((a, b) => (a.country > b.country ? 1 : -1))
                .map((mit) => ({ country: mit.country, state: mit.state }))
        })

        // Reading Countries
        const uniqueCountries = Array.from(
            new Set(_loadServiceProviderRegions.map((item) => item.country))
        )

        let newFormValues = []

        uniqueCountries.forEach((item, i) => {
            newFormValues.push({ country: '', state: '', stateOptions: [] })
            newFormValues[i]['country'] = item

            let _states = _loadServiceProviderRegions
                .filter((_filter) => _filter.country === item)
                .map((_state, index) => ({
                    value: _state.state,
                }))

            _states = _states[0].value === '' || _states[0].value === undefined ? '' : _states

            newFormValues[i]['state'] = _states

            changeCountry(
                i,
                {
                    field: 'country',
                    name: item,
                    title: item,
                    iso2: findCountryIso2(countries, item),
                },
                newFormValues
            )
        })

        if (newFormValues.length === 0) {
            addFormFields()
        } else {
            setFormValues([...newFormValues])
        }

        setLoadingSelect(false)
    }

    const fetchMyServiceProviderDocuments = async () => {
        setLoadingGrid(true)

        const _loadServiceProviderDocuments = await FetchMyServiceProviderDocuments(
            user.company_id
        ).then((response) => {
            return response
                .sort((a, b) => (a.document_name > b.document_name ? 1 : -1))
                .map((item) => ({
                    id: item.id,
                    document_name: item.document_name,
                    file: item.file.length ? item.file[0] : null,
                }))
        })

        setDocuments([..._loadServiceProviderDocuments])

        setLoadingGrid(false)
    }

    let handleChangeCountry = (i, e) => {
        changeCountry(i, e)
        let newFormValues = [...formValues]
        const _country = e.country ? e.country : e.name
        newFormValues[i]['country'] = _country
        if (_country === findlanguage().entireWorld) {
            newFormValues[i]['state'] = ''
            newFormValues[i]['stateOptions'] = [
                {
                    value: findlanguage().allRegions,
                },
            ]
        } else {
            newFormValues[i]['state'] = ''
            newFormValues[i]['stateOptions'] = []
        }
        setFormValues(newFormValues)
    }

    let handleChangeState = (i, e) => {
        let statesSelected = e.map((item, index) => ({
            value: item.value,
        }))
        let newFormValues = [...formValues]
        newFormValues[i]['state'] = statesSelected.length > 0 ? statesSelected : []
        setFormValues(newFormValues)
    }

    let addFormFields = () => {
        setFormValues([...formValues, { country: '', state: '', stateOptions: [] }])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues]
        newFormValues.splice(i, 1)
        setFormValues(newFormValues)
    }

    const changeCountry = async (i, item, formValuesAux) => {
        setLoadingSelect(true)
        let newFormValues = formValuesAux !== undefined ? [...formValuesAux] : [...formValues]
        let _options = []

        if (item.iso2 === undefined || item.iso2 === '') {
            newFormValues[i]['country'] = ''
            _options = []
        } else if (
            item.country === findlanguage().entireWorld ||
            item.name === findlanguage().entireWorld
        ) {
            _options = [
                {
                    id: 99999,
                    name: findlanguage().allRegions,
                    iso2: 'AR0',
                    field: 'state',
                    value: findlanguage().allRegions,
                    traducao: findlanguage().allRegions,
                },
            ]
        } else {
            const result = await ReadStates(item.iso2)
            if (result?.length) {
                newFormValues[i]['state'] =
                    typeof newFormValues[i]['state'] === 'string' ? [] : newFormValues[i]['state']

                _options = result
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((item) => ({
                        ...item,
                        field: 'state',
                        value: item.name,
                        traducao: item.name,
                        checked: !!newFormValues[i]['state'].find((el) => el.value === item.name),
                    }))
                _options.unshift({
                    id: 99999,
                    name: 'Entire country',
                    iso2: 'all',
                    field: 'state',
                    traducao: 'Entire country',
                    value: 'Entire country',
                })
            } else {
                _options = [
                    {
                        id: 99999,
                        name: 'Entire country',
                        iso2: 'all',
                        field: 'state',
                        value: 'Entire country',
                        traducao: 'Entire country',
                    },
                ]
            }
        }

        newFormValues[i]['stateOptions'] = _options
        setFormValues(newFormValues)

        setLoadingSelect(false)
    }

    const loadCheckList = (svcOfferedDefault, svcOfferedUser, provSvcDefault, provSvcUser) => {
        var _temp = [...svcOfferedDefault]
        if (svcOfferedUser !== undefined) {
            svcOfferedUser.forEach((item) => {
                if (language === 'portugues') {
                    const found = _temp.find((element) => element.title_pt === item.label_pt)
                    if (!found) {
                        _temp.push({
                            id: item.id,
                            title: item.label,
                            title_pt: item.label_pt,
                        })
                    }
                } else {
                    const found = _temp.find((element) => element.title === item.label)
                    if (!found) {
                        _temp.push({ id: item.id, title: item.label })
                    }
                }
            })

            setServicesOffered([..._temp])
        }

        _temp = [...provSvcDefault]

        // console.log(provSvcUser, 'provSvcUser')
        if (provSvcUser !== undefined) {
            provSvcUser.forEach((item) => {
                if (language === 'portugues') {
                    const found = _temp.find((element) => element.title === item.label)
                    if (!found) {
                        _temp.push({
                            id: item.id,
                            title: item.label,
                            title_pt: item.label_pt,
                        })
                    }
                } else {
                    const found = _temp.find((element) => element.title === item.label)
                    if (!found) {
                        _temp.push({ id: item.id, title: item.label })
                    }
                }
            })
            setProvidedServices([..._temp])
        }
    }

    const handleOnChange = async () => {
        setChecked(!checked)
        setUser({
            ...user,
            is_company_visible: !checked,
        })

        setLoading(true)

        await UpdateMyUser({
            name: user.name,
            contactName: user.contactName,
            email: user.email,
            phone: user.phone,
            is_company_visible: !checked,
            address: {
                street: user.address?.street,
                country: user.address?.country,
                state: user.address?.state,
                city: user.address?.city,
                zipCode: user.address?.zipCode,
                latitude: user.address?.latitude,
                longitude: user.address?.longitude,
            },
            website: user.address?.website,
        })

        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])

    const openEdit = () => {
        setModal({
            type: 'company-information',
            title: 'Company Information',
            onRefresh: () => {
                if (user.subscription.type === 'free') action()
            },
        })
    }

    const openEditMyInfo = () => {
        setModal({
            type: 'company-my-information',
            title: findlanguage().companyMyInformation,
        })
    }

    const openDocument = () => {
        setModal({
            type: 'add-service-provider-document',
            title: findlanguage().addNewDocuments,
            upload: true,
            onRefresh: () => {
                fetchMyServiceProviderDocuments()
            },
        })
    }

    const deleteDocument = (id) => {
        setModal({
            type: 'choose',
            title: 'Do you want to delete the document?',
            options: [
                { title: findlanguage().no, action: () => setModal(null) },
                { title: findlanguage().yes, action: () => doDeleteDocument(id), outline: true },
            ],
        })
    }

    const doDeleteDocument = async (id) => {
        setModal({
            type: 'loading',
            title: 'Do you want to delete the document?',
        })
        const request = await RemoveServiceProviderDocument(id)
            .then(() => true)
            .catch((err) => {
                return false
            })
        setModal(null)
        if (!request) return
        toast.success('Document Removed')
        fetchMyServiceProviderDocuments()
    }

    const action = async () => {
        // Getting Service Provider Regions
        const service_provider_regions = []
        formValues.forEach((item) => {
            if (item.state.length) {
                item.state.forEach((subitem) => {
                    service_provider_regions.push({
                        country: item.country,
                        state: subitem.value,
                    })
                })
            } else {
                service_provider_regions.push({
                    country: item.country,
                    state: '',
                })
            }
        })

        // if (service_provider_regions.length === 0) {
        //     toast.error('Fill the field: Where services are offered')
        //     setColorDistribution('red')
        //     return false
        // }

        // const regions_validation = service_provider_regions.filter(
        //     (item) => item.country.length === 0 || item.state.length === 0
        // )

        // if (regions_validation.length > 0) {
        //     toast.error('Fill all fields: Where services are offered')
        //     setColorDistribution('red')
        //     return false
        // }

        setColorDistribution('var(--primary-color)')

        const _payload = {
            ...user,
            is_company_visible: checked,
            aditionalInformations: {
                offeredServices:
                    services?.map((item) => (item.label ? item.label : item.title)) || [],
                providedServices:
                    service_provide?.map((item) => (item.label ? item.label : item.title)) || [],
                myCategories: selectedCategories?.map((item) => item.id) || [],
                serviceDescription: description_service,
                aboutCompany: about,
                type: profile,
            },
            service_provider_regions: service_provider_regions,
        }

        //console.log('payload ', _payload)

        setLoading(true)

        const result = await UpdateMe(_payload)
        const loggedUserData = await GetMe()

        // console.log('result', result)

        setLoading(false)

        if (result && exposeStrapiErrorNew(result)) {
            result.user = loggedUserData
            completeUpdate(result)
        }

        if (user?.subscription?.expiration_date !== undefined) {
            const dueDate = new Date(user.subscription.expiration_date)
            const currentDate = new Date()
            currentDate.setDate(currentDate.getDate())

            if (dueDate && currentDate < dueDate) {
                if (user?.subscription?.trial === true && user.subscription.type === 'free') {
                    if (isValidForPremium()) {
                        upgradeToPremium()
                    }
                }
            }
        }
    }

    const completeUpdate = (result) => {
        if (result?.user) {
            setUser(result.user)
        }

        toast.success('Account updated')
    }

    return (
        <>
            {dueDate &&
            currentDate < dueDate &&
            user?.subscription?.trial === true &&
            user.subscription.type === 'free' &&
            !isValidForPremium() ? (
                <div>
                    <p style={{ margin: 0, padding: 0 }}>
                        All the fields below are required to qualify for the premium trial.
                    </p>
                    <p style={{ margin: 0, padding: 0 }}>Full Address (State, City, ZIP Code)</p>
                    <p style={{ margin: 0, padding: 0 }}>Services Offered</p>
                    <p style={{ margin: 0, padding: 0 }}>What Kind of Service Can You Provide?</p>
                    <p style={{ margin: 0, padding: 0 }}>Category</p>
                </div>
            ) : null}
            <PageCard>
                <ProfileContainer>
                    <ProfileContent>
                        <FormImagePicker image={'/images/company.png'} />
                        <ProfileContentSpace />

                        {!user.IsCompanyUser ? (
                            <span style={{ marginLeft: '15px' }}>
                                <DashboardButton
                                    onClick={() => navigate('dashboard/account-information')}
                                >
                                    {findlanguage().accountInformation}
                                </DashboardButton>
                            </span>
                        ) : (
                            <div style={{ width: '235px' }}></div>
                        )}
                    </ProfileContent>
                    <ProfileFormContent>
                        <DummyInput></DummyInput>
                        <DummyInput alignItems="end">
                            <div className="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="customSwitches"
                                    checked={checked}
                                    onChange={handleOnChange}
                                    style={{ cursor: 'pointer' }} // Inline style for cursor
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="customSwitches"
                                    style={{ cursor: 'pointer' }}
                                >
                                    {checked ? 'Visible' : 'Not Visible'}
                                </label>
                                l
                                <InfoIcon
                                    tooltipContent={findlanguage().visibleIcon}
                                    className="ml-2"
                                />
                            </div>
                        </DummyInput>
                        <Input
                            placeholder={findlanguage().companyName}
                            value={user.company_account ? user.company_account.name : user.name}
                        />
                        <Input
                            placeholder={findlanguage().contactName}
                            value={
                                user.company_account
                                    ? user.company_account.contactName
                                    : user.contactName
                            }
                        />
                        <Input
                            placeholder={findlanguage().email}
                            value={user.company_account ? user.company_account.email : user.email}
                        />
                        <Input
                            placeholder={findlanguage().phoneNumber}
                            value={user.company_account ? user.company_account.phone : user.phone}
                        />
                        <Input
                            placeholder={findlanguage().address}
                            value={
                                user.company_account
                                    ? user.company_account.address?.street
                                    : user.address?.street
                            }
                        />
                        <Input
                            placeholder={findlanguage().country}
                            value={
                                user.company_account
                                    ? user.company_account.address?.country
                                    : user.address?.country
                            }
                        />
                        <Input
                            placeholder={findlanguage().state}
                            value={
                                user.company_account
                                    ? user.company_account.address?.state
                                    : user.address?.state
                            }
                        />
                        <Input
                            placeholder={findlanguage().city}
                            value={
                                user.company_account
                                    ? user.company_account.address?.city
                                    : user.address?.city
                            }
                        />
                        <Input
                            placeholder={findlanguage().zip}
                            value={
                                user.company_account
                                    ? user.company_account.address?.zipCode
                                    : user.address?.zipCode
                            }
                        />
                        <Input
                            placeholder={findlanguage().website}
                            value={
                                user.company_account ? user.company_account.website : user.website
                            }
                        />

                        <ProfileFormActions>
                            <DashboardButton onClick={openEdit}>
                                {findlanguage().editInformation}
                            </DashboardButton>
                        </ProfileFormActions>
                    </ProfileFormContent>
                </ProfileContainer>
            </PageCard>

            {/* <PageCard>
                <PageHeader>
                    <PageHeaderTitle>{findlanguage().myValidations}</PageHeaderTitle>
                    <PageHeaderActions></PageHeaderActions>
                </PageHeader>
                <CertificateContent>
                    <CertificateItem certificate={'mfs'} />
                    <CertificateItem certificate={'esg'} />
                </CertificateContent>
            </PageCard> */}

            {user.IsCompanyUser ? (
                <>
                    <PageHeader>
                        <PageHeaderTitle>{findlanguage().companyMyInformation}</PageHeaderTitle>
                    </PageHeader>
                    <PageCard>
                        <ProfileContainer>
                            <ProfileContent>
                                <div style={{ width: '235px' }}></div>

                                {user.IsCompanyUser ? (
                                    <span style={{ marginLeft: '15px' }}>
                                        <ProfileContentSpace />
                                        <DashboardButton
                                            onClick={() =>
                                                navigate('dashboard/account-information')
                                            }
                                        >
                                            {findlanguage().accountInformation}
                                        </DashboardButton>
                                    </span>
                                ) : (
                                    ''
                                )}
                            </ProfileContent>

                            <ProfileFormContent>
                                <Input placeholder={findlanguage().name} value={user.contactName} />
                                <Input placeholder={findlanguage().email} value={user.email} />
                                <Input
                                    placeholder={findlanguage().phoneNumber}
                                    value={user.phone}
                                />
                                <Input placeholder="Department" value={user.department} />

                                <ProfileFormActions>
                                    <DashboardButton onClick={openEditMyInfo}>
                                        {findlanguage().editMyUserInformation}
                                    </DashboardButton>
                                </ProfileFormActions>
                            </ProfileFormContent>
                        </ProfileContainer>
                    </PageCard>
                </>
            ) : (
                ''
            )}

            <p>&nbsp;</p>

            <>
                <PageHeader>
                    <div>
                        <PageHeaderTitle>{findlanguage().additionalInformation}</PageHeaderTitle>
                    </div>
                    <div className="d-flex" style={{ justifyContent: 'right', width: '200' }}>
                        <div> {findlanguage().saveAfterAnyUpdate}</div>
                    </div>
                </PageHeader>

                <PageCard>
                    <PageHeader>
                        <PageHeaderTitle>{findlanguage().servicesOfered}</PageHeaderTitle>
                    </PageHeader>

                    <Checklist
                        action={findlanguage().addService}
                        actionMore={findlanguage().newService}
                        options={servicesOffered}
                        small
                        value={services}
                        onChange={(e) => {
                            setServices(e)
                        }}
                        itemsselected={services}
                        checkkey={'title'}
                    />
                </PageCard>

                <PageCard>
                    <PageHeader>
                        <PageHeaderTitle> {findlanguage().whatKindServiceProvide}</PageHeaderTitle>
                    </PageHeader>

                    <Checklist
                        action={findlanguage().addKindOfService}
                        actionMore={findlanguage().newKindService}
                        small
                        options={providedServices}
                        value={service_provide}
                        onChange={(e) => setService_provide(e)}
                        itemsselected={service_provide}
                        checkkey={'title'}
                    />
                </PageCard>

                <PageCard>
                    <PageHeader>
                        <PageHeaderTitle>{findlanguage().descriptionOfService}</PageHeaderTitle>
                    </PageHeader>

                    <Input
                        type={'textarea'}
                        big
                        maxLength={1500}
                        placeholder=""
                        value={description_service}
                        onChange={(e) => setDescription_service(e.target.value)}
                    />
                </PageCard>

                <PageCard>
                    <PageHeader>
                        <PageHeaderTitle>{findlanguage().aboutCompany}</PageHeaderTitle>
                    </PageHeader>

                    <Input
                        type={'textarea'}
                        big
                        maxLength={1000}
                        placeholder=""
                        value={about}
                        onChange={(e) => setAbout(e.target.value)}
                    />
                </PageCard>

                <PageCard>
                    <PageHeader>
                        <PageHeaderTitle style={{ color: colorDistribution, display: 'flex' }}>
                            <div>{findlanguage().whatServicesAreOffered}</div>
                            <div style={{ width: '40px' }}>
                                {loadingSelect ? <Load size={20} /> : ' '}
                            </div>
                        </PageHeaderTitle>
                    </PageHeader>

                    <>
                        {formValues.map((element, index) => (
                            <ItemRow key={index} loading={loadingSelect.toString()}>
                                <div
                                    style={{
                                        width: '300px',
                                        marginTop: '10px',
                                        verticalAlign: 'top',
                                    }}
                                >
                                    <Select
                                        black={true}
                                        placeholder="Country"
                                        value={element.country || ''}
                                        onChange={(e) => handleChangeCountry(index, e)}
                                        small
                                        style={{ marginTop: '0px' }}
                                        options={countriesOptions}
                                    />
                                </div>
                                <div style={{ width: '50px' }}>&nbsp;</div>

                                {element.country === findlanguage().entireWorld ? (
                                    <>
                                        <div style={{ width: '100px', marginTop: '26px' }}>
                                            {findlanguage().allRegions}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <SelectMany
                                            title={''}
                                            placeholder={
                                                element.stateOptions.length === 0
                                                    ? findlanguage().selectCountryFirst
                                                    : findlanguage().state
                                            }
                                            options={element.stateOptions}
                                            value={element.state || ''}
                                            onChange={(e) => handleChangeState(index, e)}
                                        />
                                    </>
                                )}

                                {index ? (
                                    <Button
                                        style={{ marginLeft: '15px', marginTop: '20px' }}
                                        red
                                        micro
                                        outline
                                        onClick={() => {
                                            removeFormFields(index)
                                        }}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                ) : (
                                    ''
                                )}
                                {formValues.length === index + 1 ? (
                                    <Button
                                        style={{
                                            marginLeft: '15px',
                                            marginTop: '20px',
                                        }}
                                        primary
                                        outline
                                        micro
                                        onClick={() => {
                                            addFormFields()
                                        }}
                                    >
                                        +
                                    </Button>
                                ) : (
                                    ''
                                )}
                            </ItemRow>
                        ))}
                    </>
                </PageCard>
                <PageCard>
                    <PageHeader>
                        <PageHeaderTitle>{findlanguage().myDocuments}</PageHeaderTitle>
                        <PageHeaderActions>
                            <DashboardButton dark onClick={openDocument}>
                                {findlanguage().addNewDocuments}
                            </DashboardButton>
                        </PageHeaderActions>
                    </PageHeader>

                    <DocumentUploadContent>
                        <DocumentUploadWrapper>
                            {loadingGrid ? (
                                <Load size={30} />
                            ) : documents.length > 0 ? (
                                documents.map((item, key) => (
                                    <DocumentItem key={key}>
                                        <DocumentClose onClick={() => deleteDocument(item.id)} />
                                        <DocumentIcon />
                                        <DocumentTitle>
                                            <Link
                                                href={parseStrapiImage(item.file.url)}
                                                target="_blank"
                                                rel="noreferrer noopener"
                                                download={item.file.name}
                                            >
                                                {item.document_name}
                                            </Link>
                                        </DocumentTitle>
                                    </DocumentItem>
                                ))
                            ) : (
                                <div> {findlanguage().noDocumentsFound}</div>
                            )}
                        </DocumentUploadWrapper>
                    </DocumentUploadContent>
                </PageCard>

                <PageCard>
                    <PageHeader>
                        <PageHeaderTitle>
                            {findlanguage().whichCategoryCanYouProvideService}
                        </PageHeaderTitle>
                    </PageHeader>

                    <Checklist
                        options={categories}
                        small
                        value={selectedCategories}
                        onChange={(e) => {
                            getSelected(e)
                        }}
                        itemsselected={selectedCategories}
                    />
                </PageCard>

                <PageCard>
                    <div className="d-flex" style={{ justifyContent: 'center', width: '100%' }}>
                        <Button primary small onClick={action} loading={loading}>
                            {findlanguage().updateProfile}
                        </Button>
                    </div>
                </PageCard>
            </>
            <div className="modaldivrouter">{isModalOpen && <Modal onClose={closeModal} />}</div>
        </>
    )
}
