import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const HomeCard = styled.div.attrs({})`
    padding: 30px;
    background: var(--white-color);
    border-radius: 15px;
    box-shadow: 0px 1.5px 6px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(112, 112, 112, 0.1);
    height: calc(100% - 30px);
    ${(props) =>
        props.relative
            ? `
            padding: 30px 30px 0;
        `
            : ``}
`

export const HomeCardHeader = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
`

export const HomeCardHeaderTitle = styled.div.attrs({})`
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--grey-color);
`

export const HomeCardCloseContent = styled.div.attrs({})`
    display: flex;
    justify-content: flex-end;
`

export const HomeCardHeaderClose = styled.img.attrs({
    src: '/icons/close.svg',
    width: 14,
})`
    cursor: pointer;
`

export const HomeCardHeaderActions = styled.div.attrs({})`
    display: flex;
    padding: 0 20px;
`

export const HomeCardBody = styled.div.attrs({})`
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    overflow: auto;

    ${(props) =>
        props.relative
            ? `
            overflow: initial;
            position:relative;
        `
            : ``}
    ${(props) =>
        props.big
            ? `
            min-height: 620px;
            max-height: 620px;
        `
            : `
            min-height: 275px;
            max-height: 620px;
        `}
`

export const HomeCardSpacer = styled.div.attrs({})`
    min-height: 30px;
    width: 100%;
`

export const HomeCardLocked = styled.img.attrs((props) => ({
    src: `/images/card-locked-${props.subscription}.png`,
    width: 420,
}))`
    margin: auto;
    display: block;
    cursor: pointer;
`

export const HomeCardHeaderWarning = styled.img.attrs((props) => ({
    src: `/icons/warn.svg`,
    width: 32,
    title: `Pay attention to this chart`,
}))`
    margin-left: 15px;
`
