import './style.css'

import React, { useEffect, useState, useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import { IsUserOnline, GetUserOnline } from 'services/users'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Cookies from 'js-cookie'

import NotFound from 'screens/NotFound'

import Login from 'screens/Authentication/Login'
import Register from 'screens/Authentication/Register'
import Admin from 'screens/Dashboard/Admin'
import SupportCases from 'screens/Dashboard/Admin/SupportCases'
import RegisterBuyer from 'screens/Authentication/Register/Buyer'
import RegisterSupplier from 'screens/Authentication/Register/Supplier'
import RegisterServiceProvider from 'screens/Authentication/Register/ServiceProvider'
import RegisterConsumer from 'screens/Authentication/Register/Consumer'
import GetFullAccess from 'screens/Authentication/Register/GetFullAccess'
import Forgot from 'screens/Authentication/Forgot'
import CreatePassword from 'screens/Authentication/CreatePassword'

import Backoffice from 'screens/Backoffice'
import DashboardHome from 'screens/Dashboard/Home'
import DashboardProfile from 'screens/Dashboard/Profile'
import DashboardAskSupport from 'screens/Dashboard/Profile/AskSupport'
import DashboardTeamAccess from 'screens/Dashboard/Profile/TeamAccess'
import DashboardTeamAccessServiceProvider from 'screens/Dashboard/ProfileServiceProvider/TeamAccess'
import DashboardProfileViews from 'screens/Dashboard/ProfileViews'
import DashboardAccountInformation from 'screens/Dashboard/Account/Information'
import DashboardAccountComplete from 'screens/Dashboard/Account/Complete'
import DashboardAccountSubscription from 'screens/Dashboard/Account/Subscription'
import DashboardNewPremium from 'screens/Dashboard/Account/NewPremium'

// import DashboardBuy from 'screens/Dashboard/Buy'
import BuyComplete from 'screens/Dashboard/Buy/BuyComplete/BuyComplete'
import Filter from 'screens/Dashboard/Filter'
import DashboardConnections from 'screens/Dashboard/Connections'
import DashboardWishlist from 'screens/Dashboard/Wishlist'
import DashboardMessage from 'screens/Dashboard/Message'
import DashboardNotification from 'screens/Dashboard/Notification'
import DashboardSms from 'screens/Dashboard/Sms'
import DashboardUser from 'screens/Dashboard/User'
import DashboardProduct from 'screens/Dashboard/Product'
import ServiceProviderSelector from 'screens/Dashboard/ServiceProviderSelector'

import FreeFilter from 'screens/Dashboard/FreeFilter'

import ModalCore from 'components/Modal/Core'
import Modal from './Modal/index'

function PrivateRoute({ children, ...rest }) {
    const [userData, setUserData] = useState({})
    const [isOnline, setIsOnline] = useState(navigator.onLine)
    const { user } = useContext(CoreContext)

    useEffect(() => {
        if (window.location.href.includes('app.myfoodsc')) {
            window.location.href = 'https://app.mytrustedsc.com/'
        }

        const intervalId = setInterval(async () => {
            try {
                const existingRecord = await GetUserOnline(user.id)

                if (existingRecord.statusCode === 404) {
                    await IsUserOnline(
                        {
                            user_id: user.id,
                            last_time_seen: new Date().toISOString(), // current UTC date-time
                        },
                        'POST'
                    )
                } else {
                    await IsUserOnline(
                        {
                            ...existingRecord,
                            last_time_seen: new Date().toISOString(), // current UTC date-time
                        },
                        'PUT'
                    )
                }
            } catch (error) {
                console.error('Failed to update user status:', error)
            }
        }, 60000) // ping every 60 seconds

        return () => clearInterval(intervalId) // cleanup on unmount
    }, [])

    const jwt = Cookies.get('auth_token')
    return (
        <Route
            {...rest}
            render={({ location }) =>
                jwt ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    )
}

export default function AppRouter() {
    const { user, showModal, setShowModal } = useContext(CoreContext)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const closeModal = () => {
        setIsModalOpen(false)
    }

    useEffect(() => {
        if (user?.subscription?.expiration_date !== undefined) {
            const dueDate = new Date(user.subscription.expiration_date)
            const currentDate = new Date()
            currentDate.setDate(currentDate.getDate())

            const oneDay = 24 * 60 * 60 * 1000
            const differenceInMilliseconds = dueDate - currentDate
            const differenceInDays = Math.round(differenceInMilliseconds / oneDay)

            if (
                user.subscription.trial === true &&
                user.subscription.type === 'free' &&
                currentDate < dueDate &&
                differenceInDays >= 0
            ) {
                setIsModalOpen(true)
            }
        }
    }, [])

    useEffect(() => {
        if (user?.subscription?.expiration_date !== undefined) {
            const dueDate = new Date(user.subscription.expiration_date)
            const currentDate = new Date()
            currentDate.setDate(currentDate.getDate())
            const oneDay = 24 * 60 * 60 * 1000
            const differenceInMilliseconds = dueDate - currentDate
            const differenceInDays = Math.round(differenceInMilliseconds / oneDay)

            if (
                user.subscription.trial === true &&
                user.subscription.type === 'free' &&
                currentDate < dueDate &&
                differenceInDays >= 0
            ) {
                setIsModalOpen(true)
            }
        }
        setShowModal(false)
    }, [showModal === true])

    return (
        <Router>
            <Switch>
                <Route path="/" exact>
                    <Login />
                </Route>
                <Route path="/login" exact>
                    <Login />
                </Route>
                <Route path="/register" exact>
                    <Register />
                </Route>
                <Route path="/register/buyer" exact>
                    <RegisterBuyer />
                </Route>
                <Route path="/register/supplier" exact>
                    <RegisterSupplier />
                </Route>
                <Route path="/register/service-provider" exact>
                    <RegisterServiceProvider />
                </Route>
                <Route path="/register/consumer" exact>
                    <RegisterConsumer />
                </Route>
                <Route path="/register/full-access" exact>
                    <GetFullAccess />
                </Route>
                <Route path="/forgot" exact>
                    <Forgot />
                </Route>
                <Route path="/create-password" exact>
                    <CreatePassword />
                </Route>
                <Route path="/freefilter" exact>
                    <FreeFilter />
                </Route>
                <PrivateRoute path="/admin" exact>
                    <Admin />
                </PrivateRoute>
                <PrivateRoute path="/admin/support-cases" exact>
                    <SupportCases />
                </PrivateRoute>
                <PrivateRoute path="/backoffice">
                    <Backoffice />
                </PrivateRoute>
                <PrivateRoute path="/dashboard" exact>
                    <DashboardHome />
                </PrivateRoute>
                <PrivateRoute path="/dashboard/profile" exact>
                    <DashboardProfile />
                </PrivateRoute>
                <PrivateRoute path="/dashboard/profile/ask-support" exact>
                    <DashboardAskSupport />
                </PrivateRoute>
                <PrivateRoute path="/dashboard/profile/team-access" exact>
                    <DashboardTeamAccess />
                </PrivateRoute>
                <PrivateRoute path="/dashboard/profile/service-provider" exact>
                    <DashboardTeamAccessServiceProvider />
                </PrivateRoute>
                <PrivateRoute path="/dashboard/profileViews" exact>
                    <DashboardProfileViews />
                </PrivateRoute>
                <PrivateRoute path="/dashboard/account-information" exact>
                    <DashboardAccountInformation />
                </PrivateRoute>
                <PrivateRoute path="/dashboard/account-complete" exact>
                    <DashboardAccountComplete />
                </PrivateRoute>
                <PrivateRoute path="/dashboard/account-subscription" exact>
                    <DashboardAccountSubscription />
                </PrivateRoute>
                <PrivateRoute path="/dashboard/account-premium" exact>
                    <DashboardNewPremium />
                </PrivateRoute>
                {/* <PrivateRoute path="/dashboard/buy" exact>
                    <DashboardBuy />
                </PrivateRoute> */}
                <PrivateRoute path="/dashboard/buy/complete" exact>
                    <BuyComplete />
                </PrivateRoute>
                <PrivateRoute path="/dashboard/filter" exact>
                    <Filter />
                </PrivateRoute>
                <PrivateRoute path="/dashboard/wishlist" exact>
                    <DashboardWishlist />
                </PrivateRoute>
                <PrivateRoute path="/dashboard/connections" exact>
                    <DashboardConnections />
                </PrivateRoute>
                <PrivateRoute path="/dashboard/message" exact>
                    <DashboardMessage />
                </PrivateRoute>
                <PrivateRoute path="/dashboard/notification" exact>
                    <DashboardNotification />
                </PrivateRoute>
                <PrivateRoute path="/dashboard/message/:slug" exact>
                    <DashboardMessage />
                </PrivateRoute>
                <PrivateRoute path="/dashboard/sms" exact>
                    <DashboardSms />
                </PrivateRoute>
                <PrivateRoute path="/dashboard/user/:slug" exact>
                    <DashboardUser />
                </PrivateRoute>
                <PrivateRoute path="/dashboard/product/:slug" exact>
                    <DashboardProduct />
                </PrivateRoute>
                <PrivateRoute path="/dashboard/service-provider-selector" exact>
                    <ServiceProviderSelector />
                </PrivateRoute>
                <Route path="*" exact>
                    <NotFound />
                </Route>
            </Switch>
            <ModalCore />
            <div className="modaldivrouter">{isModalOpen && <Modal onClose={closeModal} />}</div>
        </Router>
    )
}
