import React, { useContext } from 'react'
import { Container } from 'reactstrap'
import { useHistory } from 'react-router-dom'

import {
    PanelPageBottomAction,
    PanelPageBottomActionSpacer,
    PanelPageBottomAlignLeft,
} from './styled'

import ContainerAuthenticated from 'containers/Authenticated'

import DashboardButton from 'components/Dashboard/Button'

import { CoreContext } from 'context/CoreContext'

import DashboardAccountCompleteSupplier from 'components/Dashboard/Complete/Supplier'
import DashboardAccountCompleteBuyer from 'components/Dashboard/Complete/Buyer'
import DashboardAccountCompleteServiceProvider from 'components/Dashboard/Complete/ServiceProvider'

export default function DashboardAccountComplete() {
    const { profile } = useContext(CoreContext)

    return (
        <>
            <ContainerAuthenticated>
                <Container fluid={false}>
                    {profile === 'supplier' ? <DashboardAccountCompleteSupplier /> : null}
                    {profile === 'buyer' ? <DashboardAccountCompleteBuyer /> : null}
                    {profile === 'service-provider' ? (
                        <DashboardAccountCompleteServiceProvider />
                    ) : null}
                </Container>
                <PanelPageBottomActionSpacer />
                <PanelPageBottomAction>
                    <Container>
                        <PanelPageBottomAlignLeft>
                            <DashboardButton>Update the Information</DashboardButton>
                        </PanelPageBottomAlignLeft>
                    </Container>
                </PanelPageBottomAction>
            </ContainerAuthenticated>
        </>
    )
}
