import React, { useContext, useState, useRef, useEffect } from 'react'
import { toast } from 'react-toastify'

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import {
    ProfileFormContent,
    ProfileFormActions,
    InputBlankContainer,
    InputSpace,
    CheckContainer,
    RowForm,
    ModalFormContent,
    LabelLeft,
    RadioHeader,
    RadioContent,
    RadioButton,
    RadioButtonCircle,
    RadioButtonLabel
} from './styled'

import { Popover, PopoverHeader, PopoverBody } from 'reactstrap'

import Input from 'components/Form/Input'
import Button from 'components/Form/Button'
import Check from 'components/Form/Check'
import Select from 'components/Form/Select'
import SelectMany from 'components/Form/SelectMany'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'
import { UpdateCompany, GetCompany } from 'services/authentication'
import { exposeStrapiErrorNew } from 'utils'
import { ReadCountries, ReadStates, ReadCities } from 'services/location'
import InfoIcon from 'components/Form/InfoIcon'

export default function ModalCompanyInformation() {
    const { modal, language, setModal, user, setUser, profile, findlanguage } =
        useContext(CoreContext)
    const [fieldValidation, setFieldValidation] = useState([])
    const [stateTooltipPhone, setStateTooltipPhone] = useState(false)
    const [init, setInit] = useState(false)

    const [name, setName] = useState('')
    const [contactName, setContactName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [street, setStreet] = useState('')

    const [country, setCountry] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')

    const [countryAC, setCountryAC] = useState('')
    const [stateAC, setStateAC] = useState('')
    const [cityAC, setCityAC] = useState('')

    const [countrySearch, setCountrySearch] = useState('')
    const [stateSearch, setStateSearch] = useState('')
    const [citySearch, setCitySearch] = useState('')

    const [zipCode, setZipCode] = useState('')
    const [website, setWebSite] = useState('')
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')

    const [identification, setIdentification] = useState('');
    const [segment, setSegment] = useState('');
    const [segmentString, setSegmentString] = useState('');
    const [numberEmployees, setNumberEmployees] = useState('');
    const [qualityManagement, setQualityManagement] = useState(false);

    const [customAddress, setCustomAddress] = useState(false)

    const segmentOptions = [
        {
            id: 1,
            name: 'Perishables',
            iso2: 'P01',
            field: 'segment',
            value: 'Perishables',
            traducao: 'Perecíveis',
        },
        {
            id: 2,
            name: 'Food',
            iso2: 'A01',
            field: 'segment',
            value: 'Food',
            traducao: 'Alimentos',
        },
        {
            id: 3,
            name: 'Non-Food – Hygiene and Cleaning',
            iso2: 'NA01',
            field: 'segment',
            value: 'Non-Food – Hygiene and Cleaning',
            traducao: 'Não Alimentos – Higiele e Limpeza',
        },
        {
            id: 4,
            name: 'Non-Food – Household',
            iso2: 'NA02',
            field: 'segment',
            value: 'Non-Food – Household',
            traducao: 'Não Alimentos – Utilidades Domésticas',
        },
    ]

    const autoCompleteRef = useRef()
    const inputRef = useRef()
    const options = {
        fields: ['address_components', 'geometry', 'icon', 'name'],
        types: ['address'],
    }

    // *********************************************************

    const clearCountry = () => {
        setCountry('')
        setState('')
        setCity('')
        setCountryAC('')
        setStateAC('')
        setCityAC('')
        setStateSearch('  ')
        setCitySearch('  ')
    }

    const clearState = () => {
        setCity('')
        setCityAC('')
        setCitySearch('  ')
    }

    const handleOnSelectCountry = (item) => {
        setCountry(item.name)
        setCountryAC(item.name)
        changeCountry(item)
        clearState()
    }

    const handleOnSelectState = (item) => {
        setState(item.name)
        setStateAC(item.name)
        changeState(item)
        setCitySearch('  ')
        setCity('')
        setCityAC('')
    }

    const handleOnSelectCity = (item) => {
        setCity(item.name)
        setCityAC(item.name)
    }

    const formatResult = (item) => {
        return (
            <>
                <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

    // *********************************************************

    // Init Loading
    // Init Loading
    useEffect(() => {
        if (!init) {
            setInit(true);
        }

        const companyData = user.company_account ? user.company_account : user;

        setName(companyData.name);
        setContactName(companyData.contactName);
        setPhone(companyData.phone);
        setEmail(companyData.email);
        setStreet(companyData.address?.street);
        setCountry(companyData.address?.country);
        setState(companyData.address?.state);
        setStateAC(companyData.address?.state);
        setCity(companyData.address?.city);
        setCityAC(companyData.address?.city);
        setZipCode(companyData.address?.zipCode);
        setWebSite(companyData.website === null ? '' : companyData.website);
        setLatitude(companyData.address?.latitude);
        setLongitude(companyData.address?.longitude);
        setIdentification(companyData?.companyInfo?.identification ?? '');
        setNumberEmployees(companyData?.companyInfo?.number_employees ?? '');
        setQualityManagement(companyData?.companyInfo?.quality_management ?? false);

        const segmentoValues = companyData?.companyInfo?.segmento?.split(', ') ?? [];
        const segmentoObjects = segmentoValues.map(value => segmentOptions.find(option => option.value === value)).filter(Boolean);
        setSegment(segmentoObjects);

        return true;
    }, [init]);


    const close = () => {
        setModal({
            type: 'alert',
            text: findlanguage().changesSaved,
        })
    }

    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])

    useEffect(() => {
        ReadCountries().then((r) =>
            setCountryList(
                language === 'portugues'
                    ? r
                        .sort((a, b) => (a.name_pt > b.name_pt) - (a.name_pt < b.name_pt))
                        .map((item) => ({
                            name: item.name_pt,
                            title: item.name_pt,
                            iso2: item.iso2,
                        }))
                    : r
                        .sort((a, b) => (a.name > b.name) - (a.name < b.name))
                        .map((item) => ({
                            name: item.name,
                            title: item.name,
                            iso2: item.iso2,
                        }))
            )
        )
    }, [])

    const changeCountry = async (item) => {
        setLoading(true)
        setStateList([])
        setCityList([])

        setState('')
        setCity('')

        let states = []

        let result = await ReadStates(item.iso2)

        if (result.length === 0) {
            result = [{ id: 0, name: item.name, iso2: item.iso2 }]
        }

        result.forEach((x) => {
            states.push({
                ...x,
                country: item.iso2,
            })
        })

        if (states.length !== 0) {
            const _state = states.sort((a, b) => a.name > b.name)
            setStateList(_state)
        }

        setLoading(false)
    }

    const changeState = async (item) => {
        setLoading(true)

        setCityList([])

        setCity('')

        let result = await ReadCities(item.country, item.iso2)

        if (result.length === 0) {
            result = [{ id: 0, name: item.name, iso2: item.iso2 }]
        }

        setCityList(result)

        setLoading(false)
    }

    const [loading, setLoading] = useState(false)

    const buildForm = () => {
        return {
            name: name,
            contactName: contactName,
            email: email,
            phone: phone,
            address: {
                street: street,
                country: customAddress ? countryAC : country,
                state: customAddress ? stateAC : state,
                city: customAddress ? cityAC : city,
                zipCode: zipCode,
                latitude: latitude,
                longitude: longitude,
            },
            website: website,
            companyInfo: {
                identification: identification,
                segment: segmentString,
                number_employees: numberEmployees,
                quality_management: qualityManagement,
            }
        }
    }

    const action = async () => {
        if (!valid()) {
            return
        }

        setLoading(true)

        const _form = buildForm()
        const result = await UpdateCompany(_form)
        const loggedUserData = await GetCompany()

        setLoading(false)

        if (result && exposeStrapiErrorNew(result)) {
            setUser({
                ...user,
                name: _form.name,
                contactName: _form.contactName,
                email: _form.email,
                phone: _form.phone,
                website: _form.website,
                address: loggedUserData.address,
                company_account: loggedUserData,
                companyInfo: {
                    identification: loggedUserData?.companyInfo?.identification,
                    segmento: loggedUserData?.companyInfo?.segmento,
                    number_employees: loggedUserData?.companyInfo?.number_employees,
                    quality_management: loggedUserData?.companyInfo?.quality_management,
                },
            })
            result.user = loggedUserData
            completeUpdate(result)
        } else {
            setUser({
                ...user,
                name: _form.name,
            })
        }
    }


    const completeUpdate = (result) => {
        toast.success(findlanguage().accountUpdated)
        close()
    }

    useEffect(() => {
        if (!customAddress) {
            autoCompleteRef.current = new window.google.maps.places.Autocomplete(
                inputRef.current,
                options
            )

            autoCompleteRef.current.addListener('place_changed', async function () {
                const place = await autoCompleteRef.current.getPlace()
                const _place = readPlace(place)

                setStreet(_place.name)
                setCountry(_place.country)
                setState(_place.state)
                setCity(_place.city)
                setZipCode(_place.zip_code)
                setLatitude(_place.latitude)
                setLongitude(_place.longitude)

                return true
            })
        } else {
            autoCompleteRef.current.addListener('place_changed', async function () {
                return true
            })
        }
    }, [customAddress])

    const readPlace = (place) => {
        const _address = place.address_components
        let _lat = 0
        let _lng = 0

        try {
            _lat = place.geometry.location?.lat()
            _lng = place.geometry.location?.lng()
        } catch (error) {
            _lat = -1
            _lng = -1
        }

        let dataAddress = {
            country: '',
            state: 'Not Found',
            city: '',
            street: '',
            zip_code: '',
            longitude: '',
            latitude: '',
            name: '',
        }

        if (place.name.length > 0 && place.name !== undefined) dataAddress.name = place.name

        dataAddress.latitude = _lat
        dataAddress.longitude = _lng

        _address.forEach((element) => {
            switch (element.types[0].toString().toLowerCase()) {
                case 'route':
                    dataAddress.street = element.short_name.toString()
                    break
                case 'administrative_area_level_1':
                    dataAddress.state = element.long_name.toString()
                    break
                case 'country':
                    dataAddress.country = element.long_name.toString()
                    break
                case 'administrative_area_level_2':
                    dataAddress.city = element.long_name.toString()
                    break
                case 'postal_code':
                    dataAddress.zip_code = element.long_name.toString()
                    break
                default:
                    break
            }
        })

        return dataAddress
    }

    const showHighlight = (name) => {
        const field = fieldValidation.find((item) => item.field === name)
        if (field !== undefined) return true
        return false
    }

    const showWarningAddress = (name) => {
        if (!customAddress) {
            if (language === 'portugues') {
                toast.warning(`Este campo é somente leitura. Use o campo Endereço.`)
            } else {
                toast.warning(`Field ${name} is read only. Fill Address Field.`)
            }
            return false
        }
    }

    const handleInputPhone = (event) => {
        setPhone(event.target.value)
        togglePhone(false)
    }

    const togglePhone = (_state) => {
        if (typeof _state !== 'boolean') return
        setStateTooltipPhone(_state)
    }

    const handleAddress = (value) => {
        if (!customAddress) {
            setCountry('')
            setState('')
            setCity('')
            setZipCode('')
            setLatitude('')
            setLongitude('')
        }
        setStreet(value)
    }

    const valid = () => {
        setFieldValidation([]);
        let errors = [];

        if (profile === 'consumer') {
            if (name.length === 0) {
                errors.push({
                    field: 'name',
                    message: findlanguage().fillTheField + findlanguage().name,
                });
            } else if (name.length < 2) {
                errors.push({
                    field: 'name',
                    message: findlanguage().errorContactName,
                });
            }
        } else {
            if (name.length === 0) {
                errors.push({
                    field: 'name',
                    message: findlanguage().fillTheField + findlanguage().companyName,
                });
            } else if (name.length < 2) {
                errors.push({
                    field: 'name',
                    message: findlanguage().errorCompanyName,
                });
            }

            if (contactName.length === 0) {
                errors.push({
                    field: 'contactName',
                    message: findlanguage().fillTheField + findlanguage().name,
                });
            } else if (contactName.length < 2) {
                errors.push({
                    field: 'contactName',
                    message: findlanguage().errorName,
                });
            }
        }

        if (email.length === 0) {
            errors.push({
                field: 'email',
                message: findlanguage().fillTheField + findlanguage().email,
            });
        } else if (
            !email.match(
                /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
            )
        ) {
            errors.push({ field: 'email', message: findlanguage().errorEmail });
        }

        if (phone.length === 0) {
            errors.push({
                field: 'phone',
                message: findlanguage().fillTheField + findlanguage().phone,
            });
        } else if (!/^\+(?:\d[-.\s]?){6,14}\d$/.test(phone)) {
            errors.push({
                field: 'phone',
                message: findlanguage().errorPhone,
            });
        }

        if (street?.length === 0) {
            errors.push({
                field: 'street',
                message: findlanguage().fillTheField + findlanguage().address,
            });
        }

        if (customAddress) {
            if (countryAC?.length === 0) {
                errors.push({
                    field: 'country',
                    message: findlanguage().fillTheField + findlanguage().country,
                });
            }

            if (stateAC?.length === 0) {
                errors.push({
                    field: 'state',
                    message: findlanguage().fillTheField + findlanguage().state,
                });
            }
        } else {
            if (country?.length === 0) {
                errors.push({
                    field: 'country',
                    message: findlanguage().fillTheField + findlanguage().country,
                });
            }

            if (state?.length === 0) {
                errors.push({
                    field: 'state',
                    message: findlanguage().fillTheField + findlanguage().state,
                });
            }
        }

        if (website.length > 0) {
            if (
                !/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%.+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/.test(
                    website
                )
            ) {
                errors.push({
                    field: 'website',
                    message: findlanguage().errorWebSite,
                });
            }
        }

        if (errors.length > 0) {
            let message = '';
            message = findlanguage().someErrorsFound;
            errors.forEach((item) => {
                message += '\n - ' + item.message;
            });
            toast.error(message);
            setFieldValidation(errors);
            return false;
        }

        return true;
    }

    return (
        <>
            <ModalWrapper big>
                <ProfileFormContent>
                    {profile !== 'consumer' ? (
                        <>
                            <Input
                                placeholder={findlanguage().companyName}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                highlight={showHighlight('name')}
                            />

                            <Input
                                placeholder={findlanguage().contactName}
                                value={contactName}
                                onChange={(e) => setContactName(e.target.value)}
                                highlight={showHighlight('contactName')}
                            />
                        </>
                    ) : (
                        <Input
                            placeholder={findlanguage().name}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            highlight={showHighlight('name')}
                        />
                    )}
                    <Input
                        placeholder={findlanguage().phoneNumber}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        onBlur={handleInputPhone}
                        onFocus={(e) => togglePhone(true)}
                        id="phone1"
                        type={'tel'}
                        autoComplete={'xoff'}
                        highlight={showHighlight('phone')}
                    />
                    <Popover
                        placement="top"
                        isOpen={stateTooltipPhone}
                        toggle={togglePhone}
                        target={'phone1'}
                    >
                        <PopoverHeader>{findlanguage().rulesPhoneNumberHeader}</PopoverHeader>
                        <PopoverBody>{findlanguage().rulesPhoneNumberText}</PopoverBody>
                    </Popover>
                    <Input
                        placeholder={
                            <>
                                {findlanguage().email}
                                <InfoIcon
                                    tooltipautoHide={false}
                                    tooltipContent={findlanguage().cantChangeEmail}
                                    className="ml-2"
                                />
                            </>
                        }
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        highlight={showHighlight('email')}
                        readOnly={true}
                    />
                    {profile === 'consumer' ? <Input blank_space={true} /> : null}
                    <InputBlankContainer>
                        <Input
                            placeholder={findlanguage().address}
                            value={street}
                            xref={inputRef}
                            onChange={(e) => handleAddress(e.target.value)}
                            highlight={showHighlight('street')}
                        />

                        <CheckContainer>
                            <Check
                                value={customAddress}
                                onChange={(e) => {
                                    setStreet('')
                                    setCountry('')
                                    setState('')
                                    setCity('')
                                    setCountryAC('')
                                    setStateAC('')
                                    setCityAC('')
                                    setCustomAddress(e)
                                }}
                                label={findlanguage().autoFill}
                                checked={customAddress}
                            />
                        </CheckContainer>
                    </InputBlankContainer>

                    {customAddress ? (
                        <>
                            <div style={{ width: 350, marginBottom: 30 }}>
                                <label
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '15px',
                                        color: showHighlight('country') ? 'red' : '#000',
                                    }}
                                >
                                    {findlanguage().country}
                                </label>
                                <ReactSearchAutocomplete
                                    items={countryList}
                                    showNoResultsText={findlanguage().noResultsFilter}
                                    formatResult={formatResult}
                                    onSelect={handleOnSelectCountry}
                                    onSearch={clearCountry}
                                    onClear={clearCountry}
                                    onFocus={() => {
                                        setCountrySearch(countrySearch.trim())
                                    }}
                                    placeholder={findlanguage().placeholderFilter}
                                    inputSearchString={countrySearch}
                                    showIcon={true}
                                    showItemsOnFocus={true}
                                    maxResults={4}
                                    fuseOptions={{
                                        includeScore: true,
                                        includeMatches: true,
                                        threshold: 0.2,
                                        location: 0,
                                        distance: 100,
                                        maxPatternLength: 32,
                                        minMatchCharLength: 1,
                                        keys: ['name'],
                                    }}
                                    styling={{
                                        height: '35px',
                                        borderRadius: '0px',
                                        backgroundColor: '#FFF',
                                        boxShadow: 'none',
                                        hoverBackgroundColor: '#EAEAEA',
                                        color: '#263640',
                                        fontSize: '15px',
                                        iconColor: '#263640',
                                        lineColor: '#EAEAEA',
                                        placeholderColor: '#cccccc',
                                        clearIconMargin: '3px 8px 0 0',
                                        zIndex: 11,
                                    }}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <Input
                                placeholder={findlanguage().country}
                                value={country}
                                readOnly={!customAddress}
                                onChange={(e) => setCountry(e.target.value)}
                                onFocus={(e) => showWarningAddress('Country')}
                                highlight={showHighlight('country')}
                            />
                        </>
                    )}

                    {customAddress ? (
                        <>
                            <div style={{ width: 350, marginBottom: 30 }}>
                                <label
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '15px',
                                        color: showHighlight('state') ? 'red' : '#000',
                                    }}
                                >
                                    {findlanguage().state}
                                </label>
                                <ReactSearchAutocomplete
                                    items={stateList}
                                    showNoResultsText={findlanguage().noResultsFilter}
                                    formatResult={formatResult}
                                    onSelect={handleOnSelectState}
                                    onSearch={clearState}
                                    onClear={clearState}
                                    onFocus={() => {
                                        setStateSearch(stateSearch.trim())
                                    }}
                                    placeholder={findlanguage().placeholderFilter}
                                    inputSearchString={stateSearch}
                                    showIcon={true}
                                    showItemsOnFocus={true}
                                    maxResults={4}
                                    fuseOptions={{
                                        includeScore: true,
                                        includeMatches: true,
                                        threshold: 0.2,
                                        location: 0,
                                        distance: 100,
                                        maxPatternLength: 32,
                                        minMatchCharLength: 1,
                                        keys: ['name'],
                                    }}
                                    styling={{
                                        height: '35px',
                                        borderRadius: '0px',
                                        backgroundColor: '#FFF',
                                        boxShadow: 'none',
                                        hoverBackgroundColor: '#EAEAEA',
                                        color: '#263640',
                                        fontSize: '15px',
                                        iconColor: '#263640',
                                        lineColor: '#EAEAEA',
                                        placeholderColor: '#cccccc',
                                        clearIconMargin: '3px 8px 0 0',
                                        zIndex: 10,
                                    }}
                                />
                            </div>
                            {/* <Select
                                placeholder={findlanguage().state}
                                onChange={(e) => {
                                    setState(e.name)
                                    changeState(e)
                                }}
                                value={state}
                                options={stateList}
                                highlight={showHighlight('state')}
                                setAutoComplete={true}
                                selectedInfo={setStateSelected}
                            /> */}
                        </>
                    ) : (
                        <>
                            <Input
                                placeholder={findlanguage().state}
                                value={state}
                                readOnly={!customAddress}
                                onChange={(e) => setState(e.target.value)}
                                onFocus={(e) => showWarningAddress('State')}
                                highlight={showHighlight('state')}
                            />
                        </>
                    )}

                    {customAddress ? (
                        <>
                            <div style={{ width: 350 }}>
                                <label
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '15px',
                                        marginTop: 8,
                                        marginBottom: 5,
                                    }}
                                >
                                    {findlanguage().city}
                                </label>
                                <ReactSearchAutocomplete
                                    items={cityList}
                                    showNoResultsText={findlanguage().noResultsFilter}
                                    formatResult={formatResult}
                                    onSelect={handleOnSelectCity}
                                    onSearch={() => {
                                        setCity('')
                                    }}
                                    onFocus={() => {
                                        setCitySearch(citySearch.trim())
                                    }}
                                    placeholder={findlanguage().placeholderFilter}
                                    inputSearchString={citySearch}
                                    showIcon={true}
                                    showItemsOnFocus={true}
                                    maxResults={4}
                                    fuseOptions={{
                                        includeScore: true,
                                        includeMatches: true,
                                        threshold: 0.2,
                                        location: 0,
                                        distance: 100,
                                        maxPatternLength: 32,
                                        minMatchCharLength: 1,
                                        keys: ['name'],
                                    }}
                                    styling={{
                                        height: '35px',
                                        borderRadius: '0px',
                                        backgroundColor: '#FFF',
                                        boxShadow: 'none',
                                        hoverBackgroundColor: '#EAEAEA',
                                        color: '#263640',
                                        fontSize: '15px',
                                        iconColor: '#263640',
                                        lineColor: '#EAEAEA',
                                        placeholderColor: '#cccccc',
                                        clearIconMargin: '3px 8px 0 0',
                                        zIndex: 10,
                                    }}
                                />
                            </div>
                            {/* <Select
                                placeholder={findlanguage().city}
                                onChange={(e) => setCity(e.name)}
                                value={city}
                                options={cityList}
                                highlight={showHighlight('city')}
                                setAutoComplete={true}
                                selectedInfo={setCitySelected}
                            /> */}
                        </>
                    ) : (
                        <>
                            <Input
                                placeholder={findlanguage().city}
                                value={city}
                                readOnly={!customAddress}
                                onChange={(e) => setCity(e.target.value)}
                                highlight={showHighlight('city')}
                            />
                        </>
                    )}

                    <Input
                        placeholder={findlanguage().zip}
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                    />
                    <Input
                        placeholder={findlanguage().website}
                        value={website}
                        onChange={(e) => setWebSite(e.target.value)}
                        highlight={showHighlight('website')}
                    />
                    <Input
                        placeholder={findlanguage().companyIdentification}
                        value={identification}
                        onChange={(e) => setIdentification(e.target.value)}
                        highlight={showHighlight('identification')}
                    />

                    <SelectMany
                        key={segment.length} // This ensures the component re-renders if segment array changes
                        title={''}
                        placeholder={findlanguage().segment}
                        options={segmentOptions}
                        value={segment}
                        onChange={(selectedOptions) => {
                            setSegment(selectedOptions);
                            setSegmentString(selectedOptions.map(option => option.value).join(', '));
                        }}
                    />

                    <Input
                        type="number"
                        placeholder={findlanguage().numberEmployees}
                        value={numberEmployees?.toString()}
                        onChange={(e) => setNumberEmployees(e.target.value)}
                        highlight={showHighlight('numberEmployees')}
                    />
                    <RowForm>
                        <ModalFormContent>
                            <LabelLeft>{findlanguage().qualityManagement}</LabelLeft>
                            <RadioHeader>
                                <RadioContent>
                                    <RadioButton
                                        type="radio"
                                        name="qualityManagement"
                                        value="sim"
                                        onChange={() => setQualityManagement(true)}
                                        checked={qualityManagement === true}
                                    />
                                    <RadioButtonCircle />
                                </RadioContent>
                                <RadioButtonLabel>{findlanguage().yes}</RadioButtonLabel>
                                <RadioContent>
                                    <RadioButton
                                        type="radio"
                                        name="qualityManagement"
                                        value="nao"
                                        onChange={() => setQualityManagement(false)}
                                        checked={qualityManagement === false}
                                    />
                                    <RadioButtonCircle />
                                </RadioContent>
                                <RadioButtonLabel>{findlanguage().no}</RadioButtonLabel>
                            </RadioHeader>
                        </ModalFormContent>
                    </RowForm>
                    <InputSpace></InputSpace>
                </ProfileFormContent>
                <ProfileFormActions>
                    <Button primary onClick={action} loading={loading}>
                        {findlanguage().updateInformation}
                    </Button>
                </ProfileFormActions>
            </ModalWrapper>
        </>
    )
}
