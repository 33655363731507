import styled from 'styled-components'

export const CardFormStatus = styled.div.attrs({})`
    width: 18px;
    height: 18px;
    border-radius: 9px;
    background-color: ${(props) => props.color};
`

export const CardDocument = styled.img.attrs({
    src: '/icons/document.svg',
})``
