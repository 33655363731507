import React, { useContext, useState, useEffect } from 'react'
import { CoreContext } from 'context/CoreContext'
import { useHistory } from 'react-router-dom'

import { PageCard, ContentFilters, FilterContent } from './styled'

import DashboardButton from 'components/Dashboard/Button'
import DashboardTabSupplierInfo from 'components/Dashboard/Sms/TabSupplierDetails'
import DashboardTabOverview from 'components/Dashboard/Sms/TabOverview'
import DashboardTabOwnValidation from 'components/Dashboard/Sms/OwnValidation'

export default function MySuppliers() {
    const { findlanguage, permissions } = useContext(CoreContext)
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)
    const [screen, setScreen] = useState(1)

    const changeScreen = (screenId) => {
        setScreen(screenId)

        history.push({
            pathname: '/dashboard/sms',
            search: `?tab=2&subTab=${screenId}`,
        })
    }

    useEffect(() => {
        if (
            history.location.search === '?tab=2&subTab=1' ||
            history.location.search === '?tab=2&subTab=2' ||
            history.location.search === '?tab=2&subTab=3'
        )
            changeScreen(parseInt(history.location.search.replace('?tab=2&subTab=', '')))
    }, [])

    useEffect(() => {
        if (!permissions?.mySuppliers?.canView && !permissions?.mySuppliers?.canEdit) {
            navigate('dashboard')
        }
    }, [permissions, navigate])

    return (
        <>
            <PageCard>
                <ContentFilters>
                    <FilterContent>
                        <DashboardButton
                            icon={'check'}
                            green
                            outline={screen === 1 ? false : true}
                            onClick={() => changeScreen(1)}
                        >
                            {findlanguage().mySuppliers}
                        </DashboardButton>
                        <DashboardButton
                            icon={'check'}
                            green
                            outline={screen === 2 ? false : true}
                            onClick={() => changeScreen(2)}
                        >
                            {findlanguage().supplierOverview}
                        </DashboardButton>
                        <DashboardButton
                            icon={'check'}
                            green
                            outline={screen === 3 ? false : true}
                            onClick={() => changeScreen(3)}
                        >
                            {findlanguage().myRequirements}
                        </DashboardButton>
                    </FilterContent>
                </ContentFilters>
            </PageCard>

            {screen === 1 ? (
                <DashboardTabSupplierInfo title={findlanguage().supplierDetailsInfo} />
            ) : null}
            {screen === 2 ? <DashboardTabOverview /> : null}
            {screen === 3 ? <DashboardTabOwnValidation /> : null}
        </>
    )
}
