import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import Card from '../Card/index.js'

import { PageCard, CardHeader, CardHeaderTitleContainer } from './styled'
import _ from 'lodash'

export default function C11({
    certifications,
    onChangeCertifications,
    saveCertifications,
    saveDocument,
    form,
    returnHeaders,
    mfsQuestionarie
}) {
    const { findlanguageEsg } = useContext(CoreContext)

    //#region C.1.1
    const c11 = {
        ref: 'C.1.1',
        requirementTitle: findlanguageEsg().c11RequirementTitle,
        inputForYesAnswer: true,
        placeHolderTextYes: findlanguageEsg().c11PlaceHolderTextYes,
        inputForNoAnswer: false,
        placeHolderTextNo: findlanguageEsg().c11PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    //#region C.1.1 Yes

    //#region C.1.1.Y.A

    const c11ya = {
        ref: 'C.1.1.Y.A',
        requirementTitle: findlanguageEsg().c11yaRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c11yaPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const c11yay = {
        ref: 'C.1.1.Y.A.Y',
        requirementTitle: findlanguageEsg().c11yayRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c11yayPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const c11yayy = {
        ref: 'C.1.1.Y.A.Y.Y',
        requirementTitle: findlanguageEsg().c11yayyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c11yayyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
    }

    const c11yayyql1 = {
        ref: 'C.1.1.Y.A.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().c11yayyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c11yayyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        fileForYesAnswerAndNoInput: true,
        color: '#2f8c5d',
        question: true,
    }

    //#endregion

    //#region C.1.1.Y.B

    const c11yb = {
        ref: 'C.1.1.Y.B',
        requirementTitle: findlanguageEsg().c11ybRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c11ybPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const c11yby = {
        ref: 'C.1.1.Y.B.Y',
        requirementTitle: findlanguageEsg().c11ybyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: findlanguageEsg().c11ybyPlaceHolderTextYes,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c11ybyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const c11ybyyqt1 = {
        ref: 'C.1.1.Y.B.Y.Y.QT.1',
        requirementTitle: findlanguageEsg().c11ybyyqt1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const c11ybyyqt2 = {
        ref: 'C.1.1.Y.B.Y.Y.QT.2',
        requirementTitle: findlanguageEsg().c11ybyyqt2RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const c11ybyyqtn = {
        ref: 'C.1.1.Y.B.Y.Y.QT.N',
        requirementTitle: findlanguageEsg().c11ybyyqtnRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const c11ybyyql1 = {
        ref: 'C.1.1.Y.B.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().c11ybyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c11ybyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.C

    const c11yc = {
        ref: 'C.1.1.Y.C',
        requirementTitle: findlanguageEsg().c11ycRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c11ycPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const c11ycy = {
        ref: 'C.1.1.Y.C.Y',
        requirementTitle: findlanguageEsg().c11ycyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c11ycyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const c11ycyy = {
        ref: 'C.1.1.Y.C.Y.Y',
        requirementTitle: findlanguageEsg().c11ycyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c11ycyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const c11ycyyql1 = {
        ref: 'C.1.1.Y.C.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().c11ycyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c11ycyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.D

    const c11yd = {
        ref: 'C.1.1.Y.D',
        requirementTitle: findlanguageEsg().c11ydRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c11ydPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const c11ydy = {
        ref: 'C.1.1.Y.D.Y',
        requirementTitle: findlanguageEsg().c11ydyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c11ydyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const c11ydyy = {
        ref: 'C.1.1.Y.D.Y.Y',
        requirementTitle: findlanguageEsg().c11ydyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c11ydyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const c11ydyyql1 = {
        ref: 'C.1.1.Y.D.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().c11ydyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c11ydyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#endregion

    //#region A.1.1 NO
    const c11na = {
        ref: 'C.1.1.N.A',
        requirementTitle: findlanguageEsg().c11naRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c11naPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const c11nb = {
        ref: 'C.1.1.N.B',
        requirementTitle: findlanguageEsg().c11nbRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c11nbPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const c11nc = {
        ref: 'C.1.1.N.C',
        requirementTitle: findlanguageEsg().c11ncRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c11ncPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    const returnCondition = (item, layers, category1, category2, category3, category4) => {
        switch (layers) {
            case 0:
                return (
                    <Card
                        item={item}
                        onChangeCertifications={onChangeCertifications}
                        saveCertifications={saveCertifications}
                        saveDocument={saveDocument}
                        certifications={certifications}
                        form={form}
                        mfsQuestionarie={mfsQuestionarie}
                    />
                )
                break
            case 1:
                if (certifications?.find((x) => x.category === category1)?.next_level === true)
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 2:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 3:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 4:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true &&
                    certifications?.find((x) => x.category === category4)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            default:
                break
        }
    }

    return (
        <>
            <PageCard>
                <CardHeader>
                    <CardHeaderTitleContainer>{findlanguageEsg().c11CardHeaderTitleContainer}</CardHeaderTitleContainer>
                </CardHeader>
                {returnHeaders()}
                {returnCondition(c11, 0)}
            </PageCard>
            {
                mfsQuestionarie !== '1' ?
                    <>

                        {certifications?.find((x) => x.category === 'C.1.1')?.next_level === true ? (
                            <>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            C.1.1 WATER CONSUMPTION - Answer Yes - A
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(c11ya, 1, 'C.1.1')}
                                    {returnCondition(c11yay, 2, 'C.1.1', 'C.1.1.Y.A')}
                                    {returnCondition(c11yayy, 3, 'C.1.1', 'C.1.1.Y.A', 'C.1.1.Y.A.Y')}
                                    {returnCondition(
                                        c11yayyql1,
                                        4,
                                        'C.1.1',
                                        'C.1.1.Y.A',
                                        'C.1.1.Y.A.Y',
                                        'C.1.1.Y.A.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            C.1.1 WATER CONSUMPTION - Answer Yes - B
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(c11yb, 1, 'C.1.1')}
                                    {returnCondition(c11yby, 2, 'C.1.1', 'C.1.1.Y.B')}
                                    {returnCondition(c11ybyyqt1, 3, 'C.1.1', 'C.1.1.Y.B', 'C.1.1.Y.B.Y')}
                                    {returnCondition(c11ybyyqt2, 3, 'C.1.1', 'C.1.1.Y.B', 'C.1.1.Y.B.Y')}
                                    {returnCondition(c11ybyyqtn, 3, 'C.1.1', 'C.1.1.Y.B', 'C.1.1.Y.B.Y')}
                                    {returnCondition(c11ybyyql1, 3, 'C.1.1', 'C.1.1.Y.B', 'C.1.1.Y.B.Y')}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            C.1.1 WATER CONSUMPTION - Answer Yes - C
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(c11yc, 1, 'C.1.1')}
                                    {returnCondition(c11ycy, 2, 'C.1.1', 'C.1.1.Y.C')}
                                    {returnCondition(c11ycyy, 3, 'C.1.1', 'C.1.1.Y.C', 'C.1.1.Y.C.Y')}
                                    {returnCondition(
                                        c11ycyyql1,
                                        4,
                                        'C.1.1',
                                        'C.1.1.Y.C',
                                        'C.1.1.Y.C.Y',
                                        'C.1.1.Y.C.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            C.1.1 WATER CONSUMPTION - Answer Yes - D
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(c11yd, 1, 'C.1.1')}
                                    {returnCondition(c11ydy, 2, 'C.1.1', 'C.1.1.Y.D')}
                                    {returnCondition(c11ydyy, 3, 'C.1.1', 'C.1.1.Y.D', 'C.1.1.Y.D.Y')}
                                    {returnCondition(
                                        c11ydyyql1,
                                        4,
                                        'C.1.1',
                                        'C.1.1.Y.D',
                                        'C.1.1.Y.D.Y',
                                        'C.1.1.Y.D.Y.Y'
                                    )}
                                </PageCard>
                            </>
                        ) : null}
                        {certifications?.find((x) => x.category === 'C.1.1')?.next_level === false ? (
                            <PageCard>
                                <CardHeader>
                                    <CardHeaderTitleContainer>
                                        C.1.1 WATER CONSUMPTION - Answer No
                                    </CardHeaderTitleContainer>
                                </CardHeader>
                                {returnHeaders()}
                                {returnCondition(c11na, 0)}
                                {returnCondition(c11nb, 0)}
                                {returnCondition(c11nc, 0)}
                            </PageCard>
                        ) : null}
                    </>
                    :
                    null
            }
        </>
    )
}
