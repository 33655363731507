import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import { useHistory } from 'react-router-dom'

import {} from './styled'

import DashboardButton from 'components/Dashboard/Button'

export default function UserProfileSelector() {
    const { user, profile, setProfile } = useContext(CoreContext)

    const availableProfiles = [
        //{ title: 'Buyer profile', ref: 'buyer' },
        //{ title: 'Supplier profile', ref: 'supplier' },
        // { title:'Service provider', ref:'service-provider' },
    ]

    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    return (
        <>
            {availableProfiles.map((item, key) => (
                <DashboardButton
                    key={key}
                    icon={profile === item.ref ? 'check' : null}
                    dark
                    outline={profile !== item.ref}
                    onClick={() => setProfile(item.ref)}
                >
                    {item.title}
                </DashboardButton>
            ))}
        </>
    )
}
