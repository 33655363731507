import React, { useEffect, useState, useContext } from 'react'

import {
    TotalsContainer,
    TotalsContent,
    TotalsTitle,
    TotalsInfo,
    TotalsInfoText,
    WrapperTotal,
    SubtotalContent,
    SubtotalTitle,
    SubtotalInfo,
} from './styled'

import DashboardPanel from 'components/Dashboard/Panels/Wrapper'
import { CoreContext } from 'context/CoreContext'
import { CountSupplierMyClients } from 'services/dashboard'

const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

export default function DashboardPanelMyClients({ cardTitle, onClose }) {
    const { premium, findlanguage } = useContext(CoreContext)

    const panel = {
        title: findlanguage().myClients,
        actions: [
            { title: new Date().getFullYear(), outline: false, small: true, action: () => null },
        ],
        onClose,
        big: false,
    }

    const offSetDate = (offsetMonth, date) => {
        const newDate = new Date(date || new Date())
        newDate.setDate(1)
        newDate.setMonth(newDate.getMonth() - offsetMonth)
        return newDate
    }
    const getMonthName = (date) => {
        let dt = date.split('-')
        const getDate = new Date(parseInt(dt[2]), parseInt(dt[1]) - 1, 1)
        return monthNames[getDate.getMonth()]
    }

    const [totalData, setTotalData] = useState(0)
    const [chartData, setChartData] = useState()
    const [loading, setLoading] = useState(false)
    const [locked, setLocked] = useState(true)

    const checkAccess = () => {
        let result = true
        if (premium.type !== 'free') {
            result = false
        }
        return result
    }

    const getDataMonth = (ds, month_index) => {
        let d = new Date()
        d.setDate(1)
        d.setMonth(d.getMonth() - month_index)
        const _date = d.toISOString().split('T')[0]
        const result = ds.filter((item) => item.DT_DATE === _date)
        return result
    }

    const fetchChart = async () => {
        setLoading(true)
        CountSupplierMyClients().then((result) => {
            const request = []
            let _date = getDataMonth(result, 0)[0]
            const count_total = result.filter((item) => item.DT_DATE === null)[0]
            setTotalData(count_total.count)

            const _dateToday = new Date()

            if (_date !== null && _date !== undefined) {
                request.push({
                    label: getMonthName(_date.DT_DATE),
                    value: parseInt(_date.count),
                    color: '#263640',
                })
            } else {
                const month0 = _dateToday.getMonth()
                const auxmonth0 = 11 - month0

                request.push({
                    label: monthNames[month0 >= 0 ? month0 : auxmonth0],
                    value: '-',
                    color: '#263640',
                })
            }

            _date = getDataMonth(result, 1)[0]
            if (_date !== null && _date !== undefined) {
                request.push({
                    label: getMonthName(_date.DT_DATE),
                    value: parseInt(_date.count),
                    color: '#263640',
                })
            } else {
                let month1 = _dateToday.getMonth() - 1
                month1 = month1 < 0 ? 12 - Math.abs(month1) : month1
                request.push({
                    label: monthNames[month1],
                    value: '-',
                    color: '#263640',
                })
            }

            _date = getDataMonth(result, 2)[0]
            if (_date !== null && _date !== undefined) {
                request.push({
                    label: getMonthName(_date.DT_DATE),
                    value: parseInt(_date.count),
                    color: '#263640',
                })
            } else {
                let month2 = _dateToday.getMonth() - 2
                month2 = month2 < 0 ? 12 - Math.abs(month2) : month2
                request.push({
                    label: monthNames[month2],
                    value: '-',
                    color: '#263640',
                })
            }

            _date = getDataMonth(result, 3)[0]
            if (_date !== null && _date !== undefined) {
                request.push({
                    label: getMonthName(_date.DT_DATE),
                    value: parseInt(_date.count),
                    color: '#263640',
                })
            } else {
                let month3 = _dateToday.getMonth() - 3
                month3 = month3 < 0 ? 12 - Math.abs(month3) : month3
                request.push({
                    label: monthNames[month3],
                    value: '-',
                    color: '#263640',
                })
            }

            setChartData(request)

            setLoading(false)
        })
    }

    useEffect(() => {
        if (!checkAccess()) fetchChart()
    }, [])

    return (
        <>
            <DashboardPanel {...panel} subscription={premium?.type} locked={checkAccess()}>
                <TotalsContainer>
                    <>
                        <TotalsContent>
                            <TotalsTitle>Total</TotalsTitle>
                            <TotalsInfo>
                                <TotalsInfoText>{totalData}</TotalsInfoText>
                            </TotalsInfo>
                        </TotalsContent>
                        {chartData && (
                            <WrapperTotal>
                                {chartData.map((item, key) => (
                                    <SubtotalContent key={key}>
                                        <SubtotalTitle>{item.label}</SubtotalTitle>
                                        <SubtotalInfo>{item.value}</SubtotalInfo>
                                    </SubtotalContent>
                                ))}
                            </WrapperTotal>
                        )}
                    </>
                </TotalsContainer>
            </DashboardPanel>
        </>
    )
}
