import { DELETE, GET, POST, PUT } from './api'

export const FetchSuplierRequests = async (userId, start = 0, limit = 1000) => {
    return await GET(
        `/supplier-reg-requests/findinfoByUser`,
        true
    )
}
export const FetchSuplierRequestsFind = async () => {
    return await GET(`/supplier-reg-requests/findinfo?_limit=-1`, true)
}

export const FetchSuplierRequestsFindWithId = async (userId, start = 0, limit = 1000) => {
    return await GET(`/supplier-reg-requests/findinfowithid?id=${userId}&_limit=-1`, true)
}

export const FetchMyProvisionedProducts = async (supplierRegReqId, start = 0, limit = 1000) => {
    return await GET(
        `/supplier-foods?owner=${supplierRegReqId}&_limit=${limit}&_start=${start}`,
        true
    )
}

export const FetchSupplierProducts = async (supplierId, start = 0, limit = 1000) => {
    return await GET(`/supplier-foods?owner=${supplierId}&_limit=${limit}&_start=${start}`, true)
}

export const FetchMyDocumentRequest = async (userId, start = 0, limit = 1000) => {
    return await GET(
        `/supplier-document-requests?request_by=${userId}&_limit=${limit}&_start=${start}`,
        true
    )
}

export const FetchProducts = async (term, start = 0, limit = 1000) => {
    return await GET(`/Products?name_contains=${term}&_limit=${limit}&_start=${start}`, true)
}

export const CreateNewSupplierReg = async (body) => {
    return await POST(`/supplier-reg-requests`, body, true)
}

export const UpdateSupplierReg = async (body) => {
    if (body?.id !== undefined) return await PUT(`/supplier-reg-requests/${body?.id}`, body, true)
}

export const CreateNewDocument = async (body) => {
    return await POST(`/supplier-documents`, body, true)
}

export const RequestDocument = async (body) => {
    return await POST(`/supplier-document-requests`, body, true)
}

export const CreateNewProduct = async (body) => {
    return await POST(`/supplier-foods`, body, true)
}

export const FetchMyForms = async (userId, formIds = [], start = 0, limit = 1000) => {
    let query = `/validation-process-forms?draft=false&owner=${userId}&_limit=${limit}&_start=${start}`;
    if (formIds.length > 0) {
        const idsQuery = formIds.map(id => `id_in=${id}`).join('&');
        query += `&${idsQuery}`;
    }
    return await GET(query, true);
};


export const FetchMyDraftForms = async (userId, start = 0, limit = 1000) => {
    return await GET(
        `/validation-process-forms?draft=true&owner=${userId}&_limit=${limit}&_start=${start}`,
        true
    )
}

export const SaveValidationForms = async (body) => {
    return await POST(`/validation-process-forms`, body, true)
}

export const UpdateValidationForms = async (body, formId) => {
    return await PUT(`/validation-process-forms/${formId}`, body, true)
}

export const RemoveValidationForm = async (formId) => {
    return await DELETE(`/validation-process-forms/${formId}`, true)
}

export const RemoveSupplier = async (id) => {
    return await DELETE(`/supplier-reg-requests/${id}`, true)
}
