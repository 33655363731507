import styled from 'styled-components'

export const SuccessText = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: var(--grey-color);
    margin: 30px 0;
`

export const ModalActions = styled.div.attrs({})`
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 0 20px;
`
