import styled from 'styled-components'

export const DashboardPage = styled.div.attrs({})``

export const DashboardBody = styled.div.attrs({})`
    min-height: calc(100vh - 114px);
    max-height: calc(100vh - 114px);
    background: var(--background-color);

    display: flex;
    align-items: flex-start;
    overflow: auto;
`

export const DashboardBodyContent = styled.div.attrs({})`
    background: var(--background-color);

    ${(props) =>
        props.full
            ? `
            margin: 0px;
            width: 100%;
            `
            : `
            margin: 12px 24px;
            background: var(--background-color);
            padding: 20px 20px 0;
            width: 100%;
            border-radius: 11px;
            min-height: calc(100vh - 140px);
            `}
`

export const Content = styled.div.attrs({})`
    overflow: hidden;
`
