import React, { useState, useEffect, useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import { toast } from 'react-toastify'

import {
    FormTitle,
    CheckList,
    CheckContent,
    BodyContent,
    FormContent,
    RequiredText,
    Label,
    ItemRow,
    DeleteIcon,
} from './styled'

import Check from 'components/Form/Check'
import Checklist from 'components/Form/Checklist'
import Textarea from 'components/Form/Textarea'
import RegisterFooter from 'components/Register/Footer'
import Select from 'components/Form/Select'
import { Load } from 'ui/styled'

import Button from 'components/Form/Button'
import Input from 'components/Form/Input'
import SelectMany from 'components/Form/SelectMany'

import { ReadCountries, ReadStates } from 'services/location'

export default function RegisterServiceProviderStep2Form({
    formValue,
    changeForm,
    next,
    registerInfos,
    back,
    loading,
}) {
    const { findlanguage, language } = useContext(CoreContext)
    const loadServiceOffered = () => {
        if (language === 'portugues')
            return registerInfos?.servicesOffered?.length
                ? registerInfos?.servicesOffered
                      ?.sort((a, b) => (a.title > b.title ? 1 : -1))
                      .map((item, key) => ({ id: key + 1, title_pt: item.label_pt }))
                : []
        else
            return registerInfos?.servicesOffered?.length
                ? registerInfos?.servicesOffered
                      ?.sort((a, b) => (a.title > b.title ? 1 : -1))
                      .map((item, key) => ({ id: key + 1, title: item.label }))
                : []
    }

    const categories = registerInfos?.companyCategories?.length
        ? registerInfos?.companyCategories
              ?.sort((a, b) => (a.title > b.title ? 1 : -1))
              .map((mit, key) => ({ id: key + 1, ...mit }))
        : []

    const loadProvidedServices = () => {
        if (language === 'portugues')
            return registerInfos?.providedServices?.length
                ? registerInfos?.providedServices
                      ?.sort((a, b) => (a.title > b.title ? 1 : -1))
                      .map((item, key) => ({ id: key + 1, title_pt: item.label_pt }))
                : []
        else
            return registerInfos?.providedServices?.length
                ? registerInfos?.providedServices
                      ?.sort((a, b) => (a.title > b.title ? 1 : -1))
                      .map((item, key) => ({ id: key + 1, title: item.label }))
                : []
    }

    const [loadingSelect, setLoadingSelect] = useState(true)
    const [countriesOptions, setCountriesOptions] = useState([])
    const [servicesOffered, setServicesOffered] = useState(loadServiceOffered())
    const [providedServices, setProvidedServices] = useState(loadProvidedServices())
    const [colorDistribution, setColorDistribution] = useState('var(--primary-color)')

    useEffect(() => {
        setLoadingSelect(true)
        ReadCountries().then((resp) => {
            const countries = resp
                .sort((a, b) => a.name > b.name)
                .map((mit) => ({ ...mit, title: mit.name, field: 'country' }))

            countries.unshift({
                country: findlanguage().entireWorld,
                title: findlanguage().entireWorld,
                name: findlanguage().entireWorld,
                iso2: 'EW0',
                state: findlanguage().allRegions,
                field: 'country',
                stateOptions: [
                    {
                        id: 99999,
                        name: findlanguage().allRegions,
                        iso2: 'AR0',
                        field: 'state',
                        value: findlanguage().allRegions,
                        traducao: findlanguage().allRegions,
                    },
                ],
            })

            countries.unshift({
                country: '',
                state: '',
                field: 'country',
                stateOptions: [],
            })

            setCountriesOptions([...countries])
        })
        setLoadingSelect(false)
    }, [])

    const toggleAdd = (item, key, param) => {
        const selected = formValue(key) || []
        const nxtValue = isAdded(item, key)
            ? [...selected.filter((fit) => fit.id !== item.id)]
            : [...selected, item]
        changeForm(nxtValue, key)
    }

    const isAdded = (item, key) => {
        return (
            formValue(key)?.length &&
            formValue(key)
                ?.map((mit) => mit.id)
                .includes(item.id)
        )
    }

    const action = async () => {
        // Getting Service Provider Regions
        const service_provider_regions = []
        formValues.forEach((item) => {
            if (item.state.length) {
                item.state.forEach((subitem) => {
                    service_provider_regions.push({
                        country: item.country,
                        state: subitem.value,
                    })
                })
            } else {
                service_provider_regions.push({
                    country: item.country,
                    state: '',
                })
            }
        })

        // if (service_provider_regions.length === 0) {
        //     toast.error('Fill the field: Where services are offered')
        //     setColorDistribution('red')
        //     return false
        //}

        // const regions_validation = service_provider_regions.filter(
        //     (item) => item.country.length === 0 || item.state.length === 0
        // )

        // if (regions_validation.length > 0) {
        //     toast.error('Fill all fields: Where services are offered')
        //     setColorDistribution('red')
        //     return false
        // }

        setColorDistribution('var(--primary-color)')

        if (next && typeof next === 'function') {
            next(service_provider_regions)
        }
    }

    const uncheck = () => {
        changeForm([], 'service_provide')
        changeForm([], 'services')
    }

    const backwards = () => {
        uncheck()
        back()
    }

    const changeCountry = async (i, item, formValuesAux) => {
        setLoadingSelect(true)
        let newFormValues = formValuesAux !== undefined ? [...formValuesAux] : [...formValues]
        let _options = []

        if (item.iso2 === undefined || item.iso2 === '') {
            newFormValues[i]['country'] = ''
            _options = []
        } else if (
            item.country === findlanguage().entireWorld ||
            item.name === findlanguage().entireWorld
        ) {
            _options = [
                {
                    id: 99999,
                    name: findlanguage().allRegions,
                    iso2: 'AR0',
                    field: 'state',
                    value: findlanguage().allRegions,
                    traducao: findlanguage().allRegions,
                },
            ]
        } else {
            const result = await ReadStates(item.iso2)
            if (result?.length) {
                newFormValues[i]['state'] =
                    typeof newFormValues[i]['state'] === 'string' ? [] : newFormValues[i]['state']

                _options = result
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((item) => ({
                        ...item,
                        field: 'state',
                        value: item.name,
                        traducao: item.name,
                        checked: !!newFormValues[i]['state'].find((el) => el.value === item.name),
                    }))
                _options.unshift({
                    id: 99999,
                    name: 'Entire country',
                    iso2: 'all',
                    field: 'state',
                    traducao: 'Entire country',
                    value: 'Entire country',
                })
            } else {
                _options = [
                    {
                        id: 99999,
                        name: 'Entire country',
                        iso2: 'all',
                        field: 'state',
                        value: 'Entire country',
                        traducao: 'Entire country',
                    },
                ]
            }
        }

        newFormValues[i]['stateOptions'] = _options
        setFormValues(newFormValues)

        setLoadingSelect(false)
    }

    const [formValues, setFormValues] = useState([{ country: '', state: '', stateOptions: [] }])

    let handleChangeCountry = (i, e) => {
        changeCountry(i, e)
        let newFormValues = [...formValues]
        newFormValues[i][e.field] = e.name
        setFormValues(newFormValues)
    }

    let handleChangeState = (i, e) => {
        let statesSelected = e.map((item, index) => ({
            value: item.value,
        }))

        let newFormValues = [...formValues]
        newFormValues[i]['state'] = statesSelected.length > 0 ? statesSelected : []
        setFormValues(newFormValues)
    }

    let addFormFields = () => {
        setFormValues([...formValues, { country: '', state: '', stateOptions: [] }])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues]
        newFormValues.splice(i, 1)
        setFormValues(newFormValues)
    }

    return (
        <>
            <BodyContent>
                <FormContent>
                    <Checklist
                        label={findlanguage().serviceOffered}
                        action={findlanguage().addService}
                        actionMore={findlanguage().newService}
                        options={servicesOffered}
                        small
                        value={formValue('services')}
                        onChange={(e) => changeForm(e, 'services')}
                    />
                    <Checklist
                        label={findlanguage().whatKindServiceProvide}
                        action={findlanguage().addKindOfService}
                        actionMore={findlanguage().newKindService}
                        small
                        options={providedServices}
                        value={formValue('service_provide')}
                        onChange={(e) => changeForm(e, 'service_provide')}
                    />

                    <Textarea
                        label={findlanguage().descriptionOfService}
                        maxLength={500}
                        value={formValue('service_description')}
                        onChange={(e) => changeForm(e.target.value, 'service_description')}
                        bigger
                    />
                    <Textarea
                        label={findlanguage().aboutCompany}
                        maxLength={500}
                        value={formValue('about_company')}
                        onChange={(e) => changeForm(e.target.value, 'about_company')}
                        bigger
                    />

                    <div style={{ marginBottom: '30px' }}>
                        <Label style={{ color: colorDistribution, display: 'flex' }}>
                            <div>{findlanguage().whatServicesAreOffered}</div>
                            <div style={{ width: '40px' }}>
                                {loadingSelect ? <Load size={20} /> : ' '}
                            </div>
                        </Label>

                        <>
                            {formValues.map((element, index) => (
                                <ItemRow key={index} loading={loadingSelect.toString()}>
                                    <div
                                        style={{
                                            width: '300px',
                                            marginTop: '10px',
                                            verticalAlign: 'top',
                                        }}
                                    >
                                        <Select
                                            black={true}
                                            placeholder="Country"
                                            value={element.country || ''}
                                            onChange={(e) => handleChangeCountry(index, e)}
                                            small
                                            style={{ marginTop: '0px' }}
                                            setAutoComplete={false}
                                            options={countriesOptions}
                                        />
                                    </div>
                                    <div style={{ width: '50px' }}>&nbsp;</div>

                                    {element.country === findlanguage().entireWorld ? (
                                        <>
                                            <div style={{ width: '100px', marginTop: '26px' }}>
                                                {findlanguage().allRegions}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <SelectMany
                                                title={''}
                                                placeholder={
                                                    element.stateOptions.length === 0
                                                        ? findlanguage().selectCountryFirst
                                                        : findlanguage().state
                                                }
                                                options={element.stateOptions}
                                                value={element.state || ''}
                                                onChange={(e) => handleChangeState(index, e)}
                                            />
                                        </>
                                    )}

                                    {index ? (
                                        <Button
                                            style={{ marginLeft: '15px', marginTop: '20px' }}
                                            red
                                            micro
                                            outline
                                            onClick={() => {
                                                removeFormFields(index)
                                            }}
                                        >
                                            <DeleteIcon />
                                        </Button>
                                    ) : (
                                        ''
                                    )}
                                    {formValues.length === index + 1 ? (
                                        <Button
                                            style={{
                                                marginLeft: '15px',
                                                marginTop: '20px',
                                            }}
                                            primary
                                            outline
                                            micro
                                            onClick={() => {
                                                addFormFields()
                                            }}
                                        >
                                            +
                                        </Button>
                                    ) : (
                                        ''
                                    )}
                                </ItemRow>
                            ))}
                        </>
                    </div>

                    <CheckList>
                        <FormTitle>{findlanguage().whichCategoryCanYouProvideService}</FormTitle>
                        {language === 'portugues'
                            ? categories.map((item, key) => (
                                  <CheckContent key={key}>
                                      <Check
                                          title={item.title_pt}
                                          label={item.description_pt}
                                          onChange={() => toggleAdd(item, 'categories')}
                                          checked={isAdded(item, 'categories')}
                                          nowidth={true}
                                      />
                                  </CheckContent>
                              ))
                            : null}
                        {language === 'english'
                            ? categories.map((item, key) => (
                                  <CheckContent key={key}>
                                      <Check
                                          title={item.title}
                                          label={item.description}
                                          onChange={() => toggleAdd(item, 'categories')}
                                          checked={isAdded(item, 'categories')}
                                          nowidth={true}
                                      />
                                  </CheckContent>
                              ))
                            : null}
                    </CheckList>

                    <div
                        style={{
                            width: '100%',
                            marginBottom: '40px',
                        }}
                    >
                        <RequiredText>{findlanguage().requiredFields}</RequiredText>
                        <RegisterFooter action={action} back={backwards} loading={loading} />
                    </div>
                </FormContent>
            </BodyContent>
        </>
    )
}
