import styled from 'styled-components'

export const ProfileFormContent = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: space-evenly;
    padding: 40px 0 20px;
`

export const ProfileFormActions = styled.div.attrs({})`
    display: flex;
    justify-content: center;
`
export const PageFormSpace = styled.div.attrs({})`
    margin-right: 12px;
`
