import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const HeaderContainer = styled.div.attrs({})`
    position: relative;
`

export const UserProfileContainer = styled.div.attrs({})`
    display: flex;
    align-items: center;
    padding-bottom: 0px;

    ${(props) =>
        props.hide
            ? `display: none;visibility: hidden;`
            : `
        `}
`
export const UserProfile = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    margin-left: 40px;
`
export const UserProfileTitle = styled.div.attrs({})`
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--grey-color);
    margin-left: 45px;
    margin-top: 10px;
    margin-bottom: 10px;
`

export const UserProfileProgress = styled.div.attrs({})`
    flex: 1;
    padding: 10px 30px 0;
`
export const UserProfileProgressTitle = styled.div.attrs({})`
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--grey-color);
`
export const UserProfileProgressBar = styled.div.attrs({})`
    height: 11px;
    border-radius: 6px;
    background: var(--progressgrey-color);
    flex: 1;
    display: flex;

    margin-top: 55px;
`
export const UserProfileProgressBarIn = styled.div.attrs({})`
    height: 11px;
    border-radius: 6px;
    background: var(--primary-color);
    transition: all 0.3s ease;
    transition-delay: 1s;
    width: ${(props) => props.progress || 0}%;
`
export const UserProfileProgressBarText = styled.div.attrs({})`
    margin: -40px -24px 0;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--lightgrey-color);
`

export const UserProfileProgressActions = styled.div.attrs({})`
    display: flex;
    padding: 0px 0 0;
`

export const UserProfileSpacer = styled.div.attrs({})`
    padding: 20px 0;
`
