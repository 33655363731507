import React, { useEffect, useState, useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import { useHistory } from 'react-router-dom'

import { toast } from 'react-toastify'
import { DoRegisterBuyer } from 'services/authentication'
import { exposeStrapiErrorNew } from 'utils'

import ContainerUnauthenticated from 'containers/Unauthenticated'

import RegisterHeader from 'components/Register/Header'
import RegisterStepIndicator from 'components/Register/StepIndicator'
import RegisterBuyerStep1 from 'components/Register/Steps/Buyer/Step1'
import RegisterBuyerStep2 from 'components/Register/Steps/Buyer/Step2'
import {
    ReadBusinessTypes,
    ReadCompanyCategories,
    ReadSupplierPerRequirements,
} from 'services/infos'
import { SubscribeToPremium } from 'services/premium'

export default function RegisterBuyer() {
    const { findlanguage } = useContext(CoreContext)
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const [loading, setLoading] = useState(false)

    const steps = [
        { highlight: findlanguage().buyerTitle, title: findlanguage().businessInformation },
        { title: findlanguage().additionalInformation },
    ]

    const [step, setStep] = useState(0)
    const [registerInfos, setRegisterInfos] = useState({})

    const [form, setForm] = useState({})
    const formValue = (ref) => {
        return form?.[ref] ? form?.[ref] : ''
    }
    const changeForm = (value, ref) => {
        setForm({ ...form, [ref]: value })
    }

    const back = () => {
        if (step === 0) {
            history.goBack()
        } else {
            setStep(step - 1)
        }
    }

    const next = (formValues) => {
        const nxt = step + 1
        if (nxt < steps.length) {
            setStep(nxt)
        } else {
            action(formValues)
        }
    }

    const action = async (formValues) => {
        const _payload = {
            ...form,
            address: {
                street: form?.street,
                city: form?.city,
                state: form?.state,
                country: form?.country,
                zipCode: form?.zipCode,
                latitude: form?.latitude,
                longitude: form?.longitude,
            },
            aditionalInformations: {
                businessTypes: form?.businessType?.map((it) => it.value) || [],
                companyCategories: form?.categories?.map((it) => it.id) || [],
                supplierPreRequirements: form?.supplierPreRequirements?.map((it) => it.value) || [],
                aboutCompany: form?.about_company,
            },
            requirements: formValues?.length
                ? formValues?.map((item) => ({
                      requirement: item.requirement,
                  }))
                : [],
        }

        setLoading(true)

        const result = await DoRegisterBuyer(_payload)

        setLoading(false)

        if (result && exposeStrapiErrorNew(result)) {
            completeLogin()
        }
    }

    const completeLogin = () => {
        localStorage.removeItem('form')
        toast.success('Account created')
        navigate('register/buyer/full-access')
    }

    const init = async () => {
        const companyCategories = await ReadCompanyCategories()
        const businessTypes = await ReadBusinessTypes()
        const supplierPreRequirements = await ReadSupplierPerRequirements()

        const infos = {
            businessTypes,
            companyCategories,
            supplierPreRequirements,
        }

        setRegisterInfos(infos)
    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        if (step === 1) {
            localStorage.setItem('form', JSON.stringify(form))
        }
    }, [step])

    return (
        <>
            <ContainerUnauthenticated>
                <RegisterHeader title={'Sign Up'} loading={loading} />
                <RegisterStepIndicator steps={steps} current={step + 1} active {...steps[step]} />

                {step === 0 ? (
                    <RegisterBuyerStep1
                        formValue={formValue}
                        changeForm={changeForm}
                        form={form}
                        setForm={setForm}
                        next={next}
                        registerInfos={registerInfos}
                    />
                ) : null}
                {step === 1 ? (
                    <RegisterBuyerStep2
                        formValue={formValue}
                        changeForm={changeForm}
                        form={form}
                        setForm={setForm}
                        next={next}
                        back={back}
                        loading={loading}
                        registerInfos={registerInfos}
                    />
                ) : null}
            </ContainerUnauthenticated>
        </>
    )
}
