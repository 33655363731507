import React, { createRef, useContext, useEffect, useRef, useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useParams, useHistory } from 'react-router-dom'
import { parseStrapiImage } from 'utils'
import { FetchConnections } from 'services/connections'

import _ from 'lodash'

import {
    PageHeader,
    PageHeaderTitle,
    PageHeaderActions,
    ContentScroll,
    ScrollWrapper,
    ChatContent,
    ChatHeaderContent,
    ChatHeaderImage,
    ChatHeaderInfo,
    ChatHeaderInfoTitle,
    ChatHeaderInfoText,
    ChatBody,
    ChatMessage,
    ChatMessageBalloon,
    ChatMessageTime,
    ChatInputContent,
    ChatInput,
    ChatInputAction,
    ChatInputActionIcon,
    ChatBalloonActions,
    ContactScroll,
    ContactScrollWrapper,
} from './styled'

import ContainerAuthenticated from 'containers/Authenticated'

import DashboardFilterSearch from 'components/Dashboard/Filter/Search'
import DashboardCardChat from 'components/Dashboard/Cards/Chat'
import DashboardCardContact from 'components/Dashboard/Cards/Contact'

import { CreateNewSupplierReg } from 'services/sms'
import { SearchEmailExist } from 'services/validationProcess'
import { NewWishlist } from 'services/wishlist'

import DashboardMore from 'components/Dashboard/More'
import { CoreContext } from 'context/CoreContext'
import DashboardButton from 'components/Dashboard/Button'
import Select from 'components/Form/Select'

import {
    FetchAllChatRooms,
    FetchChatMessages,
    SendMessage,
    DeleteChat,
    CreateRoom,
    UpdateRoom,
    ShouldUpdateChat,
} from 'services/chat'
import { Load } from 'ui/styled'

export default function DashboardMessage() {
    const params = useParams()

    const { user, setModal, modal, newChat, setNewChat, getPermission, findlanguage, setTotalMessages, permissions } =
        useContext(CoreContext)
    const [chatRoomList, setChatRoomList] = useState([])
    const [chatRoomList2, setChatRoomList2] = useState([])
    const [selectedSender, setSelectedSender] = useState({})
    const [selectedRoomId, setSelectedRoomId] = useState(-5)
    const [messages, setMessages] = useState()
    const [myMessage, setMyMessage] = useState('')
    const [firstUser, setFirstUser] = useState(true)
    const [searchValue, setSearchValue] = useState('')
    const [searchValue2, setSearchValue2] = useState('')
    const [connectionList, setConnectionList] = useState()
    const [fullList, setFullList] = useState()

    setTotalMessages(0)

    const listConnections = async (_rooms) => {
        await FetchConnections().then((resp) => {
            setFullList(resp)
            resp = resp.filter((item) => item.acceptedInvitation === 'Accepted')
            const owner = resp
                .filter((item) => item.user.id === user.id)
                .sort((a, b) => a.receiverUser.name > b.receiverUser.name)
                .map((item) => ({
                    name: item.receiverUser.name,
                    id: item.receiverUser.id,
                    avatar: item.receiverUser.avatarUrl ? item.receiverUser.avatarUrl : '',
                }))

            const receiver = resp
                .filter((item) => item.receiverUser.id === user.id)
                .sort((a, b) => a.user.name > b.user.name)
                .map((item) => ({
                    name: item.user.name,
                    id: item.user.id,
                    avatar: item.user.avatarUrl ? item.user.avatarUrl : '',
                }))

            let connections = [...owner, ...receiver].sort((a, b) => a.name > b.name)

            connections = connections.map((el) => {
                const items = _rooms.filter(
                    (el2) => el2.firstUserId.id === el.id || el2.secondUserId.id === el.id
                )

                //console.log(el.id, items)
                return items.length === 0 ? el : null
            })

            connections = connections.filter((x) => x !== null)

            setConnectionList([...connections])
        })
    }

    let user_key = user.id

    useEffect(() => {
        const interval = setInterval(async () => {
            let data = await ShouldUpdateChat(user_key)

            if (data[0].count !== '0') {
                const result = await FetchAllChatRooms(user_key)
                setChatRoomList(result)

                const chatMessageFromRoom = await FetchChatMessages(selectedRoomId)
                setMessages(
                    chatMessageFromRoom?.sort(
                        (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
                    )
                )
            }
        }, 6000)
        return () => clearInterval(interval)
    }, [selectedRoomId])

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        if (newChat !== undefined) await CreateRoom(newChat)

        let result = await FetchAllChatRooms(user_key)

        listConnections(result)

        let selectedRoom = 0
        if (params?.slug) {
            selectedRoom = parseInt(params?.slug)
        }

        if (result.length !== 0) {
            // Verifying if is owner of this room
            let foundRoom = null
            if (selectedRoom > 0) {
                foundRoom = result.find((item) => item.id === selectedRoom)
                if (!foundRoom) {
                    selectedRoom = 0
                    setSelectedRoomId(-5)
                }
            }

            if (foundRoom) {
                if (foundRoom.firstUserId.id === user_key) {
                    foundRoom.newMessageUserOne = false

                    UpdateRoom({
                        id: foundRoom.id,
                        newMessageUserOne: false,
                    })
                } else {
                    foundRoom.newMessageUserTwo = false

                    UpdateRoom({
                        id: foundRoom.id,
                        newMessageUserTwo: false,
                    })
                }
            } else {
                if (result[0].firstUserId.id === user_key) {
                    result[0].newMessageUserOne = false

                    UpdateRoom({
                        id: result[0].id,
                        newMessageUserOne: false,
                    })
                } else {
                    result[0].newMessageUserTwo = false

                    UpdateRoom({
                        id: result[0].id,
                        newMessageUserTwo: false,
                    })
                }
            }

            result = result.sort((a, b) => new Date(b.lastMessage) - new Date(a.lastMessage))

            if (newChat !== undefined) {
                const holdValue = result.find(
                    (a) =>
                        (a.firstUserId.id === newChat.firstUserId &&
                            a.secondUserId.id === newChat.secondUserId) ||
                        (a.firstUserId.id === newChat.secondUserId &&
                            a.secondUserId.id === newChat.firstUserId)
                )

                result = result.filter((x) => x.id !== holdValue.id)
                result.unshift(holdValue)
            }


            let chatMessageFromRoom = 0
            if (foundRoom) {
                chatMessageFromRoom = await FetchChatMessages(foundRoom.id)

                setMessages(
                    chatMessageFromRoom.sort(
                        (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
                    )
                )

                setSelectedSender(
                    foundRoom.firstUserId.id !== user_key
                        ? foundRoom.firstUserId
                        : foundRoom.secondUserId
                )

                setSelectedRoomId(foundRoom.id)
                setChatRoomList(result)
                setFirstUser(foundRoom.firstUserId.id === user_key ? true : false)
            } else {
                chatMessageFromRoom = await FetchChatMessages(result[0].id)

                setMessages(
                    chatMessageFromRoom.sort(
                        (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
                    )
                )

                setSelectedSender(
                    result[0].firstUserId.id !== user_key
                        ? result[0].firstUserId
                        : result[0].secondUserId
                )

                setSelectedRoomId(result[0].id)
                setChatRoomList(result)
                setFirstUser(result[0].firstUserId.id === user_key ? true : false)
            }
        }
    }

    const viewProfile = (sender) => {
        navigate(`dashboard/user/${sender.id}`)
    }

    const addToMyQMS = async (form) => {
        if (
            form.name === '' ||
            form.email === '' ||
            form.phone === '' ||
            form.email === undefined ||
            form.phone === undefined ||
            form.email === null ||
            form.phone === null
        ) {
            alert('Name, Email and Phone are required')
            return false
        }

        const result = await SearchEmailExist(user_key, form.email)

        if (result[0].total === '0') {
            if (form.id === undefined) {
                setModal({
                    type: 'alert',
                    warning: true,
                    text: findlanguage().nothingSelected,
                })
            } else {
                setModal({
                    type: 'loading',
                    text: findlanguage().processing,
                })

                form.users_permissions_user = form.id

                form.isUser = user_key

                const response = await CreateNewSupplierReg(form)

                if (!response)
                    return setModal({
                        type: 'alert',
                        warning: true,
                        text: 'Failed to create.',
                    })

                if (response.message === undefined) {
                    setModal({
                        type: 'alert',
                        text: findlanguage().requestSuccessful,
                    })
                } else {
                    return setModal({
                        type: 'alert',
                        warning: true,
                        text: response.message,
                    })
                }
            }
        } else {
            setModal({
                type: 'alert',
                warning: true,
                text: findlanguage().emailAlreadyRegisterSupplierList,
            })
        }
    }

    const addWishlist = async (item) => {
        setModal({
            type: 'loading',
            text: findlanguage().creatingNewWishList,
        })

        const response = await NewWishlist(item.id)

        if (response.statusCode === 400)
            return setModal({
                type: 'alert',
                text: response.message,
                warning: true,
            })

        if (response.statusCode)
            return setModal({
                type: 'alert',
                text: findlanguage().failedToCreatedWishList,
                warning: true,
            })

        setModal({
            type: 'alert',
            text: findlanguage().newWishListCreated,
        })
    }

    const blockConnection = async (id) => { }

    const moveToTrash = async (item, selectedRoomId) => {
        deleteRoom(selectedRoomId)
    }

    const moreOptions = [
        { title: findlanguage().viewProfile, primary: true, action: viewProfile },
        { title: findlanguage().addToMyQMS, action: addToMyQMS },
        { title: findlanguage().addToWishList, action: addWishlist },
        // { title: findlanguage().blockConnection, action: blockConnection },
        //{ title: findlanguage().moveToTrash, action: moveToTrash },
    ]

    const deleteRoom = async (id) => {
        await DeleteChat(id)

        let result = await FetchAllChatRooms(user_key)

        listConnections(result)

        if (result.length !== 0) {
            result = result.sort((a, b) => new Date(b.lastMessage) - new Date(a.lastMessage))

            const chatMessageFromRoom = await FetchChatMessages(result[0].id)

            setMessages(
                chatMessageFromRoom.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at))
            )
            setSelectedSender(
                result[0].firstUserId.id !== user_key
                    ? result[0].firstUserId
                    : result[0].secondUserId
            )
            setSelectedRoomId(result[0].id)
            setChatRoomList(result)
            setFirstUser(result[0].firstUserId.id === user_key ? true : false)
        } else {
            setMessages([])
            setSelectedSender({})
            setSelectedRoomId(-1)
            setChatRoomList([])
        }
    }

    const createChat = async (id) => {
        let user_key = user.id

        const _newChat = await CreateRoom({
            firstUserId: user_key,
            secondUserId: id,
            newMessageUserOne: false,
            newMessageUserTwo: false,
            lastMessage: new Date().toISOString(),
        })

        let result = await FetchAllChatRooms(user_key)

        listConnections(result)

        let selectedRoom = parseInt(_newChat.id)

        if (result.length !== 0) {
            // Verifying if is owner of this room
            let foundRoom = null
            if (selectedRoom > 0) {
                foundRoom = result.find((item) => item.id === selectedRoom)
                if (!foundRoom) {
                    selectedRoom = 0
                    setSelectedRoomId(-5)
                }
            }

            if (foundRoom.firstUserId.id === user_key) {
                foundRoom.newMessageUserOne = false

                UpdateRoom({
                    id: foundRoom.id,
                    newMessageUserOne: false,
                })
            } else {
                foundRoom.newMessageUserTwo = false

                UpdateRoom({
                    id: foundRoom.id,
                    newMessageUserTwo: false,
                })
            }

            result = result.sort((a, b) => new Date(b.lastMessage) - new Date(a.lastMessage))

            if (newChat !== undefined) {
                const holdValue = result.find(
                    (a) =>
                        (a.firstUserId.id === newChat.firstUserId &&
                            a.secondUserId.id === newChat.secondUserId) ||
                        (a.firstUserId.id === newChat.secondUserId &&
                            a.secondUserId.id === newChat.firstUserId)
                )

                result = result.filter((x) => x.id !== holdValue.id)
                result.unshift(holdValue)
            }

            let chatMessageFromRoom = 0

            chatMessageFromRoom = await FetchChatMessages(foundRoom.id)

            setMessages(
                chatMessageFromRoom.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at))
            )

            setSelectedSender(
                foundRoom.firstUserId.id !== user_key
                    ? foundRoom.firstUserId
                    : foundRoom.secondUserId
            )

            setSelectedRoomId(foundRoom.id)
            setChatRoomList(result)
            setFirstUser(foundRoom.firstUserId.id === user_key ? true : false)
        }
    }

    const viewMessages = async (x, updateMessage) => {
        const roomId = chatRoomList.find(
            (room) =>
                (room.firstUserId.id === x.id && room.secondUserId.id === user_key) ||
                (room.firstUserId.id === user_key && room.secondUserId.id === x.id)
        )?.id

        setSelectedSender(x)
        setSelectedRoomId(roomId)
        setMyMessage('')

        const chatMessageFromRoom = await FetchChatMessages(roomId)
        setMessages(
            chatMessageFromRoom?.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at))
        )
        setFirstUser(
            chatRoomList.find((x) => x.id === roomId).firstUserId.id === user_key ? true : false
        )

        if (chatRoomList.find((x) => x.id === roomId)?.firstUserId.id === user_key) {
            let cloneChatRoom = _.cloneDeep(chatRoomList)

            cloneChatRoom.find((x) => x.id === roomId).newMessageUserOne = false

            setChatRoomList(cloneChatRoom)

            UpdateRoom({
                id: roomId,
                newMessageUserOne: false,
            })
        } else {
            let cloneChatRoom = _.cloneDeep(chatRoomList)

            cloneChatRoom.find((x) => x.id === roomId).newMessageUserTwo = false

            setChatRoomList(cloneChatRoom)

            UpdateRoom({
                id: roomId,
                newMessageUserTwo: false,
            })
        }
    }

    const saveMessages = async () => {
        await SendMessage({
            roomId: selectedRoomId,
            message: myMessage,
            firstUser: firstUser,
        })

        const chatMessageFromRoom = await FetchChatMessages(selectedRoomId)
        setMessages(
            chatMessageFromRoom.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at))
        )
        setMyMessage('')
    }

    const setMyMessageOnKeyPress = (e) => {
        if (e.keyCode === 13) {
            saveMessages()
        }
    }

    const messageEndRef = createRef()

    const scrollToBottom = () => {
        if (messageEndRef.current) messageEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    useEffect(() => {
        // setView();
        scrollToBottom()
    }, [messages])

    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    useEffect(() => {
        if (!permissions?.message?.canUse) {
            navigate('dashboard')
        }
    }, [permissions, navigate]);


    return (
        <>
            <ContainerAuthenticated>
                <Container fluid={false}>
                    <Row>
                        <Col>
                            <PageHeader>
                                <PageHeaderActions>
                                    <PageHeaderTitle>{findlanguage().message}</PageHeaderTitle>
                                </PageHeaderActions>
                            </PageHeader>

                            <DashboardFilterSearch
                                style={{ marginBottom: '5px' }}
                                placeholder={findlanguage().searchContacts}
                                onChangeList={(e) => setSearchValue2(e.target.value)}
                            />

                            {!connectionList && <Load color="#263640" />}

                            {connectionList && (
                                <ContactScroll>
                                    <ContactScrollWrapper>
                                        {connectionList
                                            ?.filter((x) => {
                                                return x.name
                                                    ?.toLowerCase()
                                                    ?.includes(searchValue2?.toLowerCase())
                                            })
                                            .map((x, i) => (
                                                <>
                                                    <DashboardCardContact
                                                        key={x.id}
                                                        contact={x}
                                                        sendMessage={createChat}
                                                    />
                                                </>
                                            ))}
                                    </ContactScrollWrapper>
                                </ContactScroll>
                            )}

                            <DashboardFilterSearch
                                style={{ marginBottom: '5px' }}
                                placeholder={findlanguage().searchChats}
                                onChangeList={(e) => setSearchValue(e.target.value)}
                            />

                            {!chatRoomList && <Load color="#263640" />}

                            {chatRoomList && (
                                <ContentScroll>
                                    <ScrollWrapper>
                                        {chatRoomList
                                            ?.filter((x) => {
                                                if (x.firstUserId.id !== user_key)
                                                    return x.firstUserId.name
                                                        ?.toLowerCase()
                                                        ?.includes(searchValue?.toLowerCase())
                                                else
                                                    return x.secondUserId.name
                                                        ?.toLowerCase()
                                                        ?.includes(searchValue?.toLowerCase())
                                            })
                                            .map((x, i) => (
                                                <>
                                                    <DashboardCardChat
                                                        key={x.id}
                                                        viewMessages={viewMessages}
                                                        room={x}
                                                        deleteRoom={deleteRoom}
                                                        selectedRoomId={selectedRoomId}
                                                        chatRoomList2={chatRoomList2}
                                                    />
                                                </>
                                            ))}
                                    </ScrollWrapper>
                                </ContentScroll>
                            )}
                        </Col>
                        <Col md={{ size: 8 }}>
                            <ChatContent>
                                <ChatHeaderContent>
                                    <ChatHeaderImage
                                        url={
                                            selectedSender?.avatar
                                                ? parseStrapiImage(selectedSender?.avatar)
                                                : `/images/no-image.png`
                                        }
                                    />
                                    <ChatHeaderInfo>
                                        <ChatHeaderInfoTitle>
                                            {selectedSender?.name}
                                        </ChatHeaderInfoTitle>
                                        <ChatHeaderInfoText>
                                            {selectedSender?.contactName}
                                        </ChatHeaderInfoText>
                                        <ChatHeaderInfoText>
                                            {selectedSender?.role == 4 ? (
                                                <span
                                                    className="badge"
                                                    style={{ backgroundColor: '#263640', color: 'white' }}
                                                >
                                                    {findlanguage().supplierTitle}
                                                </span>
                                            ) : null}

                                            {
                                                selectedSender?.role == 3 ?
                                                    <span className="badge bg-primary">{findlanguage().buyerTitle}</span>
                                                    :
                                                    null
                                            }
                                            {
                                                selectedSender?.role == 6 ?
                                                    <span
                                                        className="badge"
                                                        style={{ backgroundColor: '#263640', color: 'white' }}
                                                    >
                                                        {findlanguage().buyerTitle} / {findlanguage().supplierTitle}
                                                    </span>
                                                    :
                                                    null
                                            }
                                            {
                                                selectedSender?.role == 7 ?
                                                    <>
                                                        <span
                                                            className="badge"
                                                            style={{ backgroundColor: '#4182ab', color: 'white' }}
                                                        >
                                                            {findlanguage().headquarters}
                                                        </span>
                                                    </>
                                                    :
                                                    null
                                            }

                                            {selectedSender?.role == 5 ? (
                                                <span
                                                    className="badge"
                                                    style={{ backgroundColor: '#ded514', color: 'white' }}
                                                >
                                                    {findlanguage().serviceProviderTitle}
                                                </span>
                                            ) : null}

                                        </ChatHeaderInfoText>
                                    </ChatHeaderInfo>
                                    <DashboardMore
                                        options={moreOptions}
                                        sender={selectedSender}
                                        selectedRoomId={selectedRoomId}
                                        vertical
                                    />
                                </ChatHeaderContent>

                                <ChatBody>
                                    {messages?.length === 0 ? (
                                        <ChatHeaderInfoText>
                                            {findlanguage().youDontHaveAnyMessages}
                                        </ChatHeaderInfoText>
                                    ) : null}
                                    {messages?.map((item) => {
                                        const utcTimeString = item.created_at;
                                        let localDate = new Date(utcTimeString);
                                        localDate.setTime(localDate.getTime() - (6 * 60 * 60 * 1000));

                                        const date222 = localDate.toLocaleString();
                                        return <ChatMessage key={item.id} ref={messageEndRef}>
                                            {item?.sender?.id === user?.id ? (
                                                <ChatBalloonActions>
                                                    <ChatMessageBalloon mine={true}>
                                                        {item.message}
                                                    </ChatMessageBalloon>
                                                    <ChatMessageTime>
                                                        {date222}
                                                    </ChatMessageTime>
                                                </ChatBalloonActions>
                                            ) : (
                                                <>
                                                    <ChatMessageBalloon>
                                                        {item.message}
                                                    </ChatMessageBalloon>
                                                    <ChatMessageTime>
                                                            {date222}
                                                    </ChatMessageTime>
                                                </>
                                            )}
                                        </ChatMessage>
                                    })}
                                </ChatBody>

                                <ChatInputContent>
                                    <ChatInput
                                        value={myMessage}
                                        onChange={(e) => setMyMessage(e.target.value)}
                                        onKeyUp={(e) => setMyMessageOnKeyPress(e)}
                                        placeholder={findlanguage().typeYourMessage}
                                    />
                                    <ChatInputAction onClick={saveMessages}>
                                        <ChatInputActionIcon />
                                    </ChatInputAction>
                                </ChatInputContent>
                            </ChatContent>
                        </Col>
                    </Row>
                </Container>
            </ContainerAuthenticated>
        </>
    )
}
