import styled from 'styled-components'

export const SideBackgroundImageContainer = styled.div.attrs({})`
    ${(props) =>
        props.burned
            ? `
            background: var(--primary-color); 
        `
            : `
            background: var(--backgroundgrey-color); 
        `}
    min-height:100vh;
    position: fixed;
    width: 100%;
`
export const SideBackgroundImage = styled.div.attrs({})`
    background: white url(/images/background.png) no-repeat top + 35% right + 30% / cover;
    min-height: 100vh;

    @media (max-width: 767px) {
        position: absolute;
        height: -webkit-fill-available;
        height: 100%;
        width: 100%;
        z-index: 1;
    }
`
export const SideBackgroundImageDegree = styled.div.attrs({})`
    min-height: 100vh;

    background: -moz-linear-gradient(80deg, #263640 10%, #7c7c7c 90%);
    background: -webkit-linear-gradient(80deg, #263640 10%, #7c7c7c 90%);
    background: linear-gradient(80deg, #263640 10%, #7c7c7c 90%);

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    opacity: 0.9;

    @media (max-width: 767px) {
        padding-top: 20px;
        z-index: 2;
    }
`

export const FormContent = styled.div.attrs({})`
    margin: auto 0;
    padding: 35px 45px;
    z-index: 2;
    background: #fff;
    border-radius: 30px;
    box-shadow: 0px 1.5px 15px rgba(0, 0, 0, 0.12);

    width: 100%;
    max-width: 440px;

    display: flex;
    flex-direction: column;
    align-items: center;
`
export const AppLogo = styled.img.attrs({
    src: '/logo1024.png',
    width: 120,
})``

export const Content = styled.div.attrs({})`
    overflow: auto;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
`

export const Touch = styled.div.attrs({})`
    cursor: pointer;
`

export const VerticalCenter = styled.div.attrs({})`
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    ${(props) =>
        props.degradee
            ? `
            background: -moz-linear-gradient(80deg, #26364011 10%, #475F3211 90%);
            background: -webkit-linear-gradient(80deg, #26364011 10%, #475F3211 90%);
            background: linear-gradient(80deg, #26364011 10%, #475F3211 90%);
        `
            : ``}

    @media(max-width:767px) {
        min-height: initial;
        min-height: 100vh;
    }
`

export const Logo = styled.img.attrs({
    src: '/icons/logo-text.svg',
})`
    width: 100%;
    max-width: 367px;
    height: 67px;
    margin-bottom: 11px;
`
export const Title = styled.div.attrs({})`
    font-size: 23px;
    font-weight: bold;
    color: var(--white-color);
    margin-bottom: 18px;
`
export const Text = styled.div.attrs({})`
    font-size: 17px;
    line-height: 26px;
    color: var(--white-color);
`
export const ActionChat = styled.div.attrs({})`
    width: 66px;
    height: 66px;
    border-radius: 33px;
    background: #303030;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    cursor: pointer;

    &:hover {
        box-shadow: 2px 2px 3px var(--shadow-color);
    }
`
export const ActionChatIcon = styled.img.attrs({
    src: '/icons/chat.svg',
})`
    width: 25px;
    height: 25px;
`

export const ContainerLogo = styled.div.attrs({})`
    width: 100%;
    max-width: 370px;
    padding-top: 125px;
`

export const BodyContainer = styled.div.attrs({})``

export const HeaderContent = styled.div.attrs({})`
    height: 96px;
    width: 100%;
    background: white url(/images/background.png) no-repeat top + 35% right + 30% / cover;
`

export const HeaderDegree = styled.div.attrs({})`
    min-height: 96px;

    background: -moz-linear-gradient(80deg, #263640 10%, #7c7c7c 90%);
    background: -webkit-linear-gradient(80deg, #263640 10%, #7c7c7c 90%);
    background: linear-gradient(80deg, #263640 10%, #7c7c7c 90%);

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0.9;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
`

export const HeaderLogo = styled.img.attrs({
    src: '/icons/logo-text.svg',
})`
    max-width: 205px;
    width: 100%;
`
