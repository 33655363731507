import React, { useState, useEffect } from 'react';
import { PageCard } from './styled';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { PostSteps, GetFullStep, PutStepsFormComplete } from 'services/steps';
import { PostStepFiles } from 'services/api';
import LivePreviewModal from './LivePreviewModal/index';
import Elements from './Elements/index';
import Sections from './Sections/index';
import AddElementsButtons from './AddElementsButtons/index';
import Select from 'react-select';

export default function Form({ setIsCreatingNew, formId, mode, init }) {
    const [name, setName] = useState('');
    const [formType, setFormType] = useState(null);
    const [is_draft, setIs_draft] = useState(true);
    const [sections, setSections] = useState([{
        name: 'Step 1',
        elements: [],
        icon: 'plus-circle',
        questions_to_pass: 0,
        type: 'form'
    }
    ]);
    const [currentStep, setCurrentStep] = useState(0);
    const [showPreview, setShowPreview] = useState(false);

    const formTypeOptions = [
        { value: 'checklist', label: 'Checklist' },
        { value: 'form', label: 'Form' },
        { value: 'quiz', label: 'Quiz' }
    ];

    const handleSubmit = async (isDraft) => {
        const hasApprovalNeededSet = sections.every(step => step.approval_needed !== null && step.approval_needed !== undefined);

        if (!name.trim() || !formType || !hasApprovalNeededSet) {
            alert("Please ensure all fields are completed and approval needed is set for each section.");
            return;
        }

        const quizSteps = sections.filter(section => section.type === 'quiz');
        for (const step of quizSteps) {
            const totalWeight = step.elements.reduce((sum, el) => sum + (el.weight || 0), 0);
            if (totalWeight !== 100) {
                alert(`Total weight for quiz step "${step.name}" must be 100%. Currently it is ${totalWeight}%.`);
                return;
            }
        }

        const body = {
            name: name,
            form_type: formType.value,
            is_draft: isDraft,
            sections: sections.map((step, stepIndex) => ({
                ...step,
                order: stepIndex,
                elements: step.elements.map((element, elementIndex) => ({
                    ...element,
                    order: elementIndex
                })),
                questions_to_pass: step.questions_to_pass
            })),
            is_active: true
        };

        try {
            let response;
            if (formId) {
                response = await PutStepsFormComplete({
                    ...body,
                    id: formId
                });
            } else {
                response = await PostSteps(body);
            }

            if (response.message === 'Form created successfully' || response.message === 'Form updated successfully') {
                const elementFileMappings = prepareFileUploadData(sections, response.elementIds);
                await uploadFiles(elementFileMappings);
            } else {
                throw new Error('Failed to process form');
            }
        } catch (error) {
            console.error('Submission error:', error);
            alert('Error submitting form and files.');
        }

        setName('');
        setIsCreatingNew(false);
        init();
    };

    function prepareFileUploadData(sections, elementIds) {
        let fileMappings = [];
        sections.forEach((section, sectionIndex) => {
            section.elements.forEach((element, elementIndex) => {
                if (element.stepFiles) {
                    const match = elementIds.find(e => e.index === elementIndex && e.stepIndex === sectionIndex);
                    if (match) {
                        fileMappings.push({
                            elementId: match.elementId,
                            file: element.stepFiles
                        });
                    }
                }
            });
        });
        return fileMappings;
    }

    async function uploadFiles(elementFileMappings) {
        for (const mapping of elementFileMappings) {
            const formData = new FormData();
            formData.append('step_element_id', mapping.elementId);
            formData.append('files', mapping.file.fileObject, mapping.file.fileName);

            await PostStepFiles(formData);
        }
    }

    useEffect(() => {
        const getStep = async () => {
            const test = await GetFullStep(formId);

            setIs_draft(test.is_draft);
            setSections(test.sections);
            setName(test.name);
            setFormType(formTypeOptions.find(option => option.value === test.form_type));
        };

        if (formId && mode !== 'new') {
            getStep();
        }
    }, [formId, mode]);

    return (
        <>
            <PageCard>
                <div className="container mt-0">
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                            Please, include the process name and type
                        </label>
                        <div className="d-flex">
                            <input
                                type="text"
                                className="form-control me-2"
                                style={{ flex: 1 }}
                                id="name"
                                name="name"
                                value={name}
                                disabled={!is_draft}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            <div style={{ width: '50%' }}>
                                <Select
                                    options={formTypeOptions}
                                    value={formType}
                                    onChange={setFormType}
                                    placeholder="Select Form Type"
                                    isDisabled={!is_draft}
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            flex: 1
                                        })
                                    }}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn colorTealDark" disabled={!name.trim() || !formType || !is_draft} onClick={() => handleSubmit(false)}>
                        Submit
                    </button>
                    <button type="button" className="btn colorTealDark" style={{ marginLeft: '10px' }} disabled={!name.trim() || !formType || !is_draft} onClick={() => handleSubmit(true)}>
                        Save as Draft
                    </button>
                    <button type="button" className="btn btn-secondary" style={{ marginLeft: '10px' }} onClick={() => setIsCreatingNew(false)}>
                        Go Back
                    </button>
                </div>
            </PageCard>

            {is_draft ? (
                <AddElementsButtons sections={sections} setSections={setSections} currentStep={currentStep} setCurrentStep={setCurrentStep} setShowPreview={setShowPreview} />
            ) : null}

            <Sections sections={sections} setSections={setSections} currentStep={currentStep} setCurrentStep={setCurrentStep} is_draft={!is_draft} />

            <Elements sections={sections} setSections={setSections} currentStep={currentStep} is_draft={!is_draft} />

            <LivePreviewModal show={showPreview} onHide={() => setShowPreview(false)} sections={sections} />
        </>
    );
}
