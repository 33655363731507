import styled from 'styled-components'

export const Content = styled.div.attrs({})``

export const PageHeader = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px 0;

    margin-bottom: 20px;
`
export const PageHeaderTitle = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: var(--grey-color);
`
export const PageHeaderActions = styled.div.attrs({})`
    display: flex;
    align-items: center;
`

export const ValidatedItem = styled.div.attrs({})`
    border: 1px solid var(--red-color);
    color: var(--red-color);
    ${(props) =>
        props.validating
            ? `
        border: 1px solid var(--lightgrey-color);
        color: var(--lightgrey-color);
    `
            : ``}
    ${(props) =>
        props.validated
            ? `
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
    `
            : ``}
    padding: 17px 22px;
    margin: 0 0 0 20px;
    border-radius: 6px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
`
export const ValidatedItemIcon = styled.img.attrs((props) => ({
    src: props.validating
        ? '/icons/pause.svg'
        : props.validated
        ? '/icons/check-green.svg'
        : '/icons/close-red.svg',
}))`
    margin: 0 18px 0 0;
`
