import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import { useHistory } from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import ChooseButton from 'components/Dashboard/Sms/TabOverview/ChooseButton/index'
import { CoreContext } from 'context/CoreContext'
import {
    FetchMyProvisionedProducts,
    FetchSuplierRequests,
    UpdateSupplierReg,
    FetchMyForms,
} from 'services/sms'
import { FindValidationProcess } from 'services/validationProcess'
import { FetchSuplierRequestsFind } from 'services/sms';
import _ from 'lodash'
import { FetchMyProducts } from 'services/myProfile'
import { FetchUserCategory } from 'services/filters'
import SupplierStatus from './Graphs/supplierStatus'
import StatusRequirement from './Graphs/statusRequirement'
import DocumentCondition from './Graphs/documentCondition'
import SpecificStatusByRequirement from './Graphs/specificStatusByRequirement'
import Status from './Graphs/status'
import Products from './Graphs/products'
import RequirementExpiration from './Graphs/requirementExpiration'
import ExpiredRequirement from './Graphs/expiredRequirement'
import NumberOfResponses from './Graphs/numberOfResponses'
import TeamSupplier from './Graphs/teamSupplier'
import { GetUsersFromCompany } from 'services/document'

import {
    PageCard,
    CardLineContainer,
    CardLine,
    CardLineText,
    CardLineColumn,
    CardHeader,
    ImageSeal,
    EditIcon,
    Filter,
    IconInfo,
} from './styled'

export default function DashboardTabOverview() {
    const { user, findlanguage, permissions } = useContext(CoreContext)
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)
    const cssTable = { fontSize: '15px', fontWeight: '700', color: '#646464' }
    const cssList = { fontSize: '15px', color: '#646464', marginLeft: '10px' }
    const labelCss = {
        fontWeight: 'bold',
        fontSize: '15px',
        color: 'rgb(86, 86, 86)',
        marginLeft: '-30px',
    }
    const labelFilterCss = {
        fontWeight: 'bold',
        fontSize: '15px',
        color: 'rgb(86, 86, 86)',
    }
    const categoryCss = {
        fontWeight: 'bold',
        fontSize: '15px',
        color: 'rgb(86, 86, 86)',
        marginLeft: '-60px',
    }

    const [filters, setFilters] = useState([
        { name: 'category', value: '' },
        { name: 'supplier_status_requirements', value: [] },
        { name: 'supplier_general_requirements', value: [] },
        { name: 'status', value: [] },
        { name: 'name', value: '' },
        { name: 'requirements', value: [] },
        { name: 'products', value: '' },
    ])

    const labelCssNoMargin = { fontWeight: 'bold', fontSize: '15px', color: 'rgb(86, 86, 86)' }
    const optionsColor = { backgroundColor: 'white', color: 'black' }

    const [overview, setOverview] = useState([])
    const [supplierRequest, setSupplierRequest] = useState([])
    const [overViewIncrement, setOverViewIncrement] = useState([])
    const [usersWithForms, setUsersWithForms] = useState([])
    const [viewReport, setViewReport] = useState(false)
    const [mfsApproval, setMfsApproval] = useState(false)
    const [edit, setEdit] = useState(true)
    const [editProducts, setEditProducts] = useState(true)
    const [myFormList, setMyFormList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [showDataLabels, setShowDataLabels] = useState(false);
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
    const [companyUsers, setCompanyUsers] = useState([])

    useEffect(() => {
        searchOverview()
        init()
        updateMyFormList()
    }, [])

    useEffect(() => {
        treatOverviewResult()
    }, [overViewIncrement])

    const searchOverview = async () => {
        const result = await FetchSuplierRequests(user.company_id)

        setOverViewIncrement(result)
    }

    const treatOverviewResult = async () => {
        const treatment = await Promise.all(
            overViewIncrement
                .filter((x) => x.users_permissions_user.company_id !== null)
                .map(async (reg) => {
                    let product = []
                    let products = []
                    let categories = []

                    if (reg?.connection_requested === 'User does not exist') {
                        product = await FetchMyProvisionedProducts(
                            reg.users_permissions_user.company_id,
                            0,
                            1000
                        ).then((r) => r)
                        products = product?.map((x) => {
                            return x.name + ' - '
                        })
                    } else {
                        const resultCategories = await FetchUserCategory(
                            reg.users_permissions_user.company_id
                        ).then((r) => r)
                        product = await FetchMyProducts(reg.users_permissions_user.company_id).then(
                            (r) => r
                        )

                        products = product?.map((x) => {
                            return x.product_name + ' - '
                        })
                        categories = resultCategories?.companyCategories?.map((x) => {
                            return x.title + ' - '
                        })
                    }

                    if (products.length !== 0)
                        products[products.length - 1] = products[products.length - 1].slice(0, -3)

                    if (categories !== undefined && categories?.length !== 0)
                        categories[categories.length - 1] = categories[categories.length - 1].slice(
                            0,
                            -3
                        )

                    return {
                        id: reg.id,
                        supplier: reg.name,
                        userExist:
                            reg.users_permissions_user.userAddedByBuyer === null
                                ? 'MyTS Member'
                                : 'Non MyTS Member',
                        products: reg.products === null ? '' : reg.products,
                        mfsCategories: categories,
                        mfsProducts: products,
                        category: reg.category === null ? '' : reg.category,
                        mfsPercentage: 100,
                        buyerPercentage: 100,
                        userId: reg.users_permissions_user.company_id,
                        expanded: false,
                        supplier_status_requirements:
                            reg.supplier_status_requirements === null
                                ? ''
                                : reg.supplier_status_requirements,
                        supplier_general_requirements:
                            reg.supplier_general_requirements === null
                                ? ''
                                : reg.supplier_general_requirements,
                        certificationStatus: reg.certificationStatus,
                        user_who_owns: reg.user_who_owns
                    }
                })
        )

        setOverview([...treatment])
    }

    const init = async () => {
        const request = await FindValidationProcess()
        const response = await FetchSuplierRequestsFind();

        request.forEach((x) => (x.editProducts = false))

        const usersFromCompany = await GetUsersFromCompany()

        let reformulateUsers = []

        usersFromCompany
            .sort((a, b) => (a.name > b.name) - (a.name < b.name))
            .forEach((x) => {
                reformulateUsers.push({
                    id: x.id,
                    value: x.contactName,
                    label: x.contactName,
                    email: x.email,
                    title: x.contactName,
                })
            })

        setCompanyUsers(reformulateUsers)
        setSupplierRequest(response);
        setUsersWithForms(request)
    }

    const changeUsersWithForm = (form) => {
        let usersWithFormsCopy = _.cloneDeep(usersWithForms)

        usersWithFormsCopy.find((x) => x.id === form.id).status = form.status

        if (form.status === 'Declined') {
            usersWithFormsCopy.find((x) => x.id === form.id).reason = form.reason
            usersWithFormsCopy.find((x) => x.id === form.id).buyerResponse = form.buyerResponse
        }

        setUsersWithForms(usersWithFormsCopy)
    }

    const openCloseDiv = (id, openClose) => {
        let overviewChange = [...overview]
        overview.find((x) => x.id === id).expanded = !openClose

        setOverview(overviewChange)
    }

    const changeOverview = (id, ref, value) => {
        let overviewClone = _.cloneDeep(overview)

        overviewClone.find((x) => x.id === id)[ref] = value

        setOverview(overviewClone)
        UpdateSupplierReg(overviewClone.find((x) => x.id === id))
    }

    const changeCategory = (id, ref, value) => {
        let overviewClone = _.cloneDeep(overview)

        overviewClone.find((x) => x.id === id)[ref] = value

        setOverview(overviewClone)
    }

    const saveCategory = (id) => {
        setEdit(!edit)
        UpdateSupplierReg(overview.find((x) => x.id === id))
    }

    const saveProducts = (id) => {
        setEditProducts(!editProducts)
        UpdateSupplierReg(overview.find((x) => x.id === id))
    }

    const options = [
        {
            value: 'Active',
            label: findlanguage().active,
        },
        {
            value: 'Pending Approval',
            label: findlanguage().pendingApproval,
        },
        {
            value: 'Pending Document',
            label: findlanguage().pendingDocument,
        },
        {
            value: 'Expired',
            label: findlanguage().expired,
        },
    ]

    const optionsGeneral = [
        {
            value: 'Active',
            label: findlanguage().active,
        },
        {
            value: 'In Progress',
            label: findlanguage().inProgress,
        },
        {
            value: 'Inactive',
            label: findlanguage().inactive,
        },
    ]

    const optionsStatus = [
        {
            value: 'Condition',
            label: findlanguage().condition,
        },
        {
            value: 'Accepted',
            label: findlanguage().accepted,
        },
        {
            value: 'Pending',
            label: findlanguage().pending,
        },
        {
            value: 'Denied',
            label: findlanguage().denied,
        },
        {
            value: 'Is Expired',
            label: findlanguage().isExpired,
        },
    ]

    const [selectedFilters, setSelectedFilters] = useState([
        { name: 'name', value: '' },
        { name: 'products', value: '' },
        { name: 'category', value: '' },
        { name: 'supplier_status_requirements', value: [] },
        { name: 'supplier_general_requirements', value: [] },
        { name: 'status', value: [] },
        { name: 'requirements', value: [] },
    ])

    const createQuery = (value, field) => {
        selectedFilters.find((el) => el.name === field).value = value

        let filtersCLone = _.cloneDeep(filters)

        if (field === 'category') filtersCLone.find((x) => x.name === field).value = value
        else if (field === 'name') filtersCLone.find((x) => x.name === field).value = value
        else if (field === 'products') {
            filtersCLone.find((x) => x.name === field).value = value
        } else {
            let valuesArray = []
            value.forEach((y) => {
                valuesArray.push(y.value)
            })

            if (field === 'status') {
                let findIds = []

                usersWithForms
                    .filter((x) => {
                        if (valuesArray.includes('Is Expired')) {
                            return (
                                x.status === 'Accepted' && new Date(x.expirationDate) <= new Date()
                            )
                        }
                        return valuesArray.includes(x.status)
                    })
                    .forEach((x) => {
                        if (!findIds.includes(x.toUser.id)) findIds.push(x.toUser.id)
                    })

                if (findIds.length === 0 && value.length !== 0) findIds.push(-1)

                filtersCLone.find((x) => x.name === field).value = findIds
            } else if (field === 'requirements') {
                let findIds = []

                usersWithForms
                    .filter((x) => valuesArray.includes(x.form.name))
                    .forEach((x) => {
                        if (!findIds.includes(x.toUser.id)) findIds.push(x.toUser.id)
                    })

                if (findIds.length === 0 && value.length !== 0) findIds.push(-1)

                filtersCLone.find((x) => x.name === field).value = findIds
            } else filtersCLone.find((x) => x.name === field).value = valuesArray
        }

        setFilters(filtersCLone)
        setCurrentPage(1)
    }

    const optionsRequirements = myFormList.map((x) => {
        return {
            value: x.name,
            label: x.name,
        }
    })

    const updateMyFormList = async () => {
        const formIds = permissions.mySuppliers?.forms || [];
        const request = await FetchMyForms(user.company_id, formIds);
        setMyFormList(request)
    }

    const changeProducts = (id, value) => {
        let usersWithFormsCopy = _.cloneDeep(usersWithForms)

        usersWithFormsCopy.find((x) => x.id === id).products = value

        setUsersWithForms(usersWithFormsCopy)
    }

    const editProductsForm = (id, value) => {
        let usersWithFormsCopy = _.cloneDeep(usersWithForms)

        usersWithFormsCopy.find((x) => x.id === id).editProducts = value

        setUsersWithForms(usersWithFormsCopy)
    }

    const sortOverviewBySupplier = (overview) => {
        return overview.sort((a, b) => a.supplier.localeCompare(b.supplier));
    };

    const sortedOverview = sortOverviewBySupplier([...overview]);

    const filteredOverview = sortedOverview?.filter((x) => {
        return (
            x.category?.toLowerCase().includes(filters[0].value?.toLowerCase()) &&
            x.products?.toLowerCase().includes(filters[6].value?.toLowerCase()) &&
            x.supplier?.toLowerCase().includes(filters[4].value?.toLowerCase()) &&
            (filters[1].value.length === 0 ||
                filters[1].value.includes(x.supplier_status_requirements)) &&
            (filters[2].value.length === 0 ||
                filters[2].value.includes(x.supplier_general_requirements)) &&
            (filters[3].value.length === 0 ||
                filters[3].value.includes(x.userId)) &&
            (filters[5].value.length === 0 || filters[5].value.includes(x.userId))
        )
    })

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredOverview.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredOverview.length / itemsPerPage);

    const handlePageChange = (data) => {
        setCurrentPage(data.selected + 1)
    }
    const handleTotalPerPageChange = (event) => {
        setItemsPerPage(Number(event.target.value))
        setCurrentPage(1) // Reset to first page
    }

    const [activeTab, setActiveTab] = useState('listagem');

    const tabs = [
        { id: 'listagem', label: findlanguage().list, icon: '📋' },
        { id: 'charts', label: findlanguage().charts, icon: '📊' },
        { id: 'generateReport', label: findlanguage().generateReport, icon: '🖨️' }
    ];

    const generatePDF = () => {
        setIsGeneratingPDF(true); // Set loading state
        setShowDataLabels(true);
        setTimeout(() => {
            const input = document.getElementById('chart-grid');
            html2canvas(input, { scale: 2 }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a2');
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save('charts.pdf');
                setShowDataLabels(false);
                setIsGeneratingPDF(false); // Reset loading state
            });
        }, 1000);
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                {tabs.map(tab => (
                    <button
                        key={tab.id}
                        onClick={() => tab.id !== 'generateReport' && setActiveTab(tab.id)}
                        style={{
                            flex: 1,
                            padding: '10px',
                            margin: '0 5px',
                            border: '1px solid #ccc',
                            backgroundColor: activeTab === tab.id ? '#4b6273' : '#fff',
                            color: activeTab === tab.id ? '#fff' : '#000',
                            borderRadius: '5px',
                            cursor: tab.id === 'generateReport' ? 'not-allowed' : 'pointer',
                            position: 'relative'
                        }}
                        disabled={tab.id === 'generateReport'}
                    >
                        {tab.icon} {tab.label}
                        {tab.id === 'generateReport' && (
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '14px',
                                    backgroundColor: 'rgba(0,0,0,0.7)',
                                    color: '#fff',
                                    padding: '5px',
                                    borderRadius: '5px',
                                    display: 'none'
                                }}
                                className="tooltip-text"
                            >
                                Coming Soon
                            </span>
                        )}
                    </button>
                ))}
            </div>
            {
                activeTab === 'listagem' ?
                    <>
                        <PageCard className="sticky-top2">
                            <div className="row">
                                <div className="col-12">
                                    <Filter />
                                    <label className="form-label" style={labelFilterCss}>
                                        {findlanguage().name}
                                    </label>
                                    <input
                                        className="form-control"
                                        onChange={(event) => createQuery(event.target.value, 'name')}
                                    />
                                </div>
                                <div className="col-2">
                                    <label className="form-label" style={labelFilterCss}>
                                        {findlanguage().products}
                                    </label>
                                    <input
                                        className="form-control"
                                        onChange={(event) => createQuery(event.target.value, 'products')}
                                    />
                                </div>
                                <div className="col-2">
                                    <label className="form-label" style={labelFilterCss}>
                                        {findlanguage().category}
                                    </label>
                                    <input
                                        className="form-control"
                                        onChange={(event) => createQuery(event.target.value, 'category')}
                                    />
                                </div>
                                <div className="col-2">
                                    <label className="form-label" style={labelFilterCss}>
                                        {findlanguage().supplierRequirements}
                                    </label>
                                    <Select
                                        onChange={(values) =>
                                            createQuery(values, 'supplier_status_requirements')
                                        }
                                        isMulti
                                        name="colors"
                                        options={options.sort(
                                            (a, b) => (a.label > b.label) - (a.label < b.label)
                                        )}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder={findlanguage().select}
                                    />
                                </div>
                                <div className="col-2">
                                    <label className="form-label" style={labelFilterCss}>
                                        {findlanguage().suppplierGeneralStatus}
                                    </label>
                                    <Select
                                        onChange={(values) =>
                                            createQuery(values, 'supplier_general_requirements')
                                        }
                                        isMulti
                                        name="colors"
                                        options={optionsGeneral.sort(
                                            (a, b) => (a.label > b.label) - (a.label < b.label)
                                        )}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder={findlanguage().select}
                                    />
                                </div>
                                <div className="col-2">
                                    <label className="form-label" style={labelFilterCss}>
                                        {findlanguage().conditionStatus}
                                    </label>
                                    <Select
                                        onChange={(values) => createQuery(values, 'status')}
                                        isMulti
                                        name="colors"
                                        options={optionsStatus.sort(
                                            (a, b) => (a.label > b.label) - (a.label < b.label)
                                        )}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder={findlanguage().select}
                                    />
                                </div>
                                <div className="col-2">
                                    <label className="form-label" style={labelFilterCss}>
                                        {findlanguage().requirements}
                                    </label>
                                    <Select
                                        onChange={(values) => createQuery(values, 'requirements')}
                                        isMulti
                                        name="colors"
                                        options={optionsRequirements.sort(
                                            (a, b) => (a.label > b.label) - (a.label < b.label)
                                        )}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder={findlanguage().select}
                                    />
                                </div>
                            </div>
                        </PageCard>
                        {
                            currentItems.length !== 0 ?
                                <>
                                    {
                                        currentItems
                                            .map((item) => {
                                                let backgroundReq = { marginLeft: '-30px' }
                                                let backgroundStatus = {}

                                                switch (item.supplier_status_requirements) {
                                                    case 'Active':
                                                        backgroundReq.backgroundColor = '#263640'
                                                        backgroundReq.color = 'white'
                                                        break
                                                    case 'Pending Approval':
                                                        backgroundReq.backgroundColor = '#ff9621'
                                                        backgroundReq.color = 'white'
                                                        break
                                                    case 'Pending Document':
                                                        backgroundReq.backgroundColor = '#f0df3c'
                                                        backgroundReq.color = 'black'
                                                        break
                                                    case 'Expired':
                                                        backgroundReq.backgroundColor = '#d62323'
                                                        backgroundReq.color = 'white'
                                                        break
                                                    default:
                                                        break
                                                }
                                                switch (item.supplier_general_requirements) {
                                                    case 'Active':
                                                        backgroundStatus.backgroundColor = '#263640'
                                                        backgroundStatus.color = 'white'
                                                        break
                                                    case 'In Progress':
                                                        backgroundStatus.backgroundColor = '#f0df3c'
                                                        backgroundStatus.color = 'black'
                                                        break
                                                    case 'Inactive':
                                                        backgroundStatus.backgroundColor = '#d62323'
                                                        backgroundStatus.color = 'white'
                                                        break
                                                    default:
                                                        break
                                                }

                                                return (
                                                    <PageCard key={item.id}>
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-3" style={{ display: 'inline-flex' }}>
                                                                    <div style={{
                                                                        width: '50px', marginTop: usersWithForms.filter(
                                                                            (x) =>
                                                                                x.toUser.id === item.userId &&
                                                                                x.status === 'Pending'
                                                                        ).length !== 0 ? '10px' : '22px'
                                                                    }}>
                                                                        {usersWithForms.filter(
                                                                            (x) =>
                                                                                x.toUser.id === item.userId &&
                                                                                x.status === 'Pending'
                                                                        ).length !== 0 ? (
                                                                            <>
                                                                                <button
                                                                                    type="button"
                                                                                    style={{
                                                                                        backgroundColor: '#263640',
                                                                                        color: 'white',
                                                                                        width: '206px',
                                                                                        marginTop: '-45px',
                                                                                        cursor: 'default',
                                                                                    }}
                                                                                    className="btn position-relative"
                                                                                >
                                                                                    {findlanguage().pendingAction}
                                                                                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                                                        {
                                                                                            usersWithForms.filter(
                                                                                                (x) =>
                                                                                                    x.toUser.id ===
                                                                                                    item.userId &&
                                                                                                    x.status === 'Pending'
                                                                                            ).length
                                                                                        }
                                                                                    </span>
                                                                                </button>
                                                                                <div
                                                                                    style={{
                                                                                        fontSize: '30px',
                                                                                        fontWeight: 'bolder',
                                                                                        color: 'black',
                                                                                        cursor: 'pointer',
                                                                                        marginTop: '-25px',
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        openCloseDiv(item.id, item.expanded)
                                                                                    }
                                                                                >
                                                                                    {item.expanded ? '-' : '+'}
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <div
                                                                                style={{
                                                                                    fontSize: '30px',
                                                                                    fontWeight: 'bolder',
                                                                                    color: 'black',
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                onClick={() =>
                                                                                    openCloseDiv(item.id, item.expanded)
                                                                                }
                                                                            >
                                                                                {item.expanded ? '-' : '+'}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <CardLineText>
                                                                        <CardHeader
                                                                            onClick={() =>
                                                                                navigate(`dashboard/user/${item.userId}`)
                                                                            }
                                                                        >
                                                                            {item.supplier}
                                                                        </CardHeader>
                                                                    </CardLineText>
                                                                </div>
                                                                <div className="col-1">
                                                                    {item.certificationStatus === 'approved' ? (
                                                                        <ImageSeal image={'/icons/checked.svg'}></ImageSeal>
                                                                    ) : null}
                                                                </div>
                                                                <div className="col-2">
                                                                    <>
                                                                        <label
                                                                            className="form-label"
                                                                            style={categoryCss}
                                                                        >
                                                                            {findlanguage().responsible}
                                                                        </label>
                                                                        <br />
                                                                        <select
                                                                            value={item.user_who_owns}
                                                                            style={{ height: '39px', paddingBottom: '0px', marginLeft: '-60px' }}
                                                                            className="form-select"
                                                                            onChange={(event) => {
                                                                                const value = parseInt(event.target.value, 10);
                                                                                changeOverview(item.id, 'user_who_owns', value);
                                                                            }}

                                                                        >
                                                                            <option value={0}></option>
                                                                            {companyUsers.map((user) => (
                                                                                <option value={user.id}>
                                                                                    {user.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </>
                                                                </div>
                                                                <div className="col-2">
                                                                    {item.category !== null ? (
                                                                        <>
                                                                            <label
                                                                                className="form-label"
                                                                                style={categoryCss}
                                                                            >
                                                                                {findlanguage().category}
                                                                            </label>
                                                                            <br />
                                                                            <input
                                                                                className="form-control"
                                                                                value={item.category}
                                                                                disabled={true}
                                                                                style={{ marginLeft: '-60px' }}
                                                                            />
                                                                        </>
                                                                    ) : null}
                                                                </div>

                                                                <div className="col-2">
                                                                    <label className="form-label" style={labelCss}>
                                                                        {findlanguage().supplierStatusRequirements}
                                                                        <IconInfo
                                                                            title={findlanguage().iconInfoTitle}
                                                                            style={{ width: '16px', marginLeft: '6px' }}
                                                                        />
                                                                    </label>
                                                                    <select
                                                                        style={backgroundReq}
                                                                        className="form-select"
                                                                        aria-label="Default select example"
                                                                        value={item.supplier_status_requirements}
                                                                        onChange={(event) =>
                                                                            changeOverview(
                                                                                item.id,
                                                                                'supplier_status_requirements',
                                                                                event.target.value
                                                                            )
                                                                        }
                                                                    >
                                                                        <option style={optionsColor} value="">
                                                                            {findlanguage().selectAOption}
                                                                        </option>
                                                                        {options.map((x) => (
                                                                            <option
                                                                                key={x.value}
                                                                                style={optionsColor}
                                                                                value={x.value}
                                                                            >
                                                                                {x.label}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-2">
                                                                    <label className="form-label" style={labelCssNoMargin}>
                                                                        {findlanguage().suppplierGeneralStatus}
                                                                    </label>
                                                                    <select
                                                                        style={backgroundStatus}
                                                                        className="form-select"
                                                                        aria-label="Default select example"
                                                                        value={item.supplier_general_requirements}
                                                                        onChange={(event) =>
                                                                            changeOverview(
                                                                                item.id,
                                                                                'supplier_general_requirements',
                                                                                event.target.value
                                                                            )
                                                                        }
                                                                    >
                                                                        <option style={optionsColor} value="">
                                                                            {findlanguage().selectAOption}
                                                                        </option>
                                                                        {optionsGeneral.map((x) => (
                                                                            <option
                                                                                key={x.value}
                                                                                style={optionsColor}
                                                                                value={x.value}
                                                                            >
                                                                                {x.label}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: item.expanded ? '' : 'none' }}>
                                                            {!viewReport ? (
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col" style={{ display: 'flex' }}>
                                                                            <label style={cssTable} className="form-label">
                                                                                {mfsApproval
                                                                                    ? 'MyTS'
                                                                                    : findlanguage().buyerTitle}{' '}
                                                                                {findlanguage().category}
                                                                            </label>
                                                                            <p style={cssList} className="user-select-all">
                                                                                {mfsApproval ? (
                                                                                    item.mfsCategories
                                                                                ) : (
                                                                                    <>{edit ? item.category : null}</>
                                                                                )}
                                                                            </p>
                                                                            {
                                                                                mfsApproval || edit === false ?
                                                                                    null
                                                                                    :
                                                                                    permissions?.mySuppliers?.canEdit ?
                                                                                        <EditIcon onClick={() => setEdit(!edit)} />
                                                                                        :
                                                                                        null

                                                                            }

                                                                            {mfsApproval || edit ? null : (
                                                                                <>
                                                                                    <input
                                                                                        value={item.category}
                                                                                        className="form-control"
                                                                                        onChange={(event) =>
                                                                                            changeCategory(
                                                                                                item.id,
                                                                                                'category',
                                                                                                event.target.value
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <button
                                                                                        className="btn"
                                                                                        style={{
                                                                                            backgroundColor: '#263640',
                                                                                            color: '#fff',
                                                                                            marginLeft: '5px',
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            saveCategory(item.id)
                                                                                        }
                                                                                    >
                                                                                        {findlanguage().save}
                                                                                    </button>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <br />

                                                                    <div className="row">
                                                                        <div className="col" style={{ display: 'flex' }}>
                                                                            <label style={cssTable} className="form-label">
                                                                                {findlanguage().products}
                                                                            </label>
                                                                            <p style={cssList} className="user-select-all">
                                                                                {mfsApproval ? (
                                                                                    item.mfsProducts
                                                                                ) : (
                                                                                    <>
                                                                                        {editProducts
                                                                                            ? item.products
                                                                                            : null}
                                                                                    </>
                                                                                )}
                                                                            </p>
                                                                            {mfsApproval ||
                                                                                editProducts === false ? null :
                                                                                permissions?.mySuppliers?.canEdit ?
                                                                                    <EditIcon
                                                                                        onClick={() =>
                                                                                            saveProducts(!editProducts)
                                                                                        }
                                                                                    />
                                                                                    :
                                                                                    null
                                                                            }

                                                                            {mfsApproval || editProducts ? null : (
                                                                                <>
                                                                                    <input
                                                                                        value={item.products}
                                                                                        className="form-control"
                                                                                        onChange={(event) =>
                                                                                            changeCategory(
                                                                                                item.id,
                                                                                                'products',
                                                                                                event.target.value
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <button
                                                                                        className="btn"
                                                                                        style={{
                                                                                            backgroundColor: '#263640',
                                                                                            color: '#fff',
                                                                                            marginLeft: '5px',
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            saveProducts(item.id)
                                                                                        }
                                                                                    >
                                                                                        {findlanguage().save}
                                                                                    </button>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : null}
                                                            <ChooseButton
                                                                usersWithForms={usersWithForms.filter((x) => {
                                                                    const statusFilter = selectedFilters.find(
                                                                        (el) => el.name === 'status'
                                                                    )
                                                                    const statusValues = statusFilter
                                                                        ? statusFilter.value.map((el) => el.value)
                                                                        : []
                                                                    const isExpired =
                                                                        new Date(x.expirationDate) <= new Date()

                                                                    const statusCondition =
                                                                        statusValues.length > 0
                                                                            ? statusValues.includes(x.status) &&
                                                                            !(x.status === 'Accepted' && isExpired)
                                                                            : true

                                                                    const isExpiredCondition = statusValues.includes(
                                                                        'Is Expired'
                                                                    )
                                                                        ? x.status === 'Accepted' && isExpired
                                                                        : false

                                                                    const requirementsFilter = selectedFilters.find(
                                                                        (el) => el.name === 'requirements'
                                                                    )
                                                                    const requirementsValues = requirementsFilter
                                                                        ? requirementsFilter.value.map((el) => el.value)
                                                                        : []
                                                                    const requirementsCondition =
                                                                        requirementsValues.length > 0
                                                                            ? requirementsValues.includes(x.form.name)
                                                                            : true

                                                                    return (
                                                                        x.toUser.id === item.userId &&
                                                                        (statusCondition || isExpiredCondition) &&
                                                                        requirementsCondition
                                                                    )
                                                                })}
                                                                viewReport={viewReport}
                                                                setViewReport={setViewReport}
                                                                changeUsersWithForm={changeUsersWithForm}
                                                                setMfsApproval={setMfsApproval}
                                                                init={init}
                                                                changeProducts={changeProducts}
                                                                editProductsForm={editProductsForm}
                                                                item={item}
                                                                myFormList={myFormList}
                                                            />
                                                        </div>
                                                    </PageCard>
                                                )
                                            })
                                    }
                                    <br></br>
                                    <div id="reactPaginate" style={{ display: 'flex' }}>
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel={`${findlanguage().next} > `}
                                            onPageChange={handlePageChange}
                                            pageRangeDisplayed={5}
                                            pageCount={totalPages}
                                            previousLabel={`< ${findlanguage().previous}`}
                                            renderOnZeroPageCount={null}
                                            breakClassName={'page-item'}
                                            breakLinkClassName={'page-link'}
                                            containerClassName={'pagination'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={'active'}
                                            forcePage={currentPage - 1}
                                        />
                                        <select
                                            className="form-select"
                                            style={{ marginLeft: '10px', width: '10%', height: '38px' }}
                                            value={itemsPerPage}
                                            onChange={handleTotalPerPageChange}
                                        >
                                            <option value={1}>1</option>
                                            <option value={3}>3</option>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={15}>15</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                        </select>
                                    </div>
                                </>
                                :
                                <PageCard>
                                    <b>{findlanguage().noResultsChangeFilter}</b>
                                </PageCard>
                        }
                    </>
                    :
                    null
            }
            {
                activeTab === 'charts' ?
                    <>
                        <button className="btn btn-light btn-block colorTealDark generate-pdf" onClick={generatePDF} disabled={isGeneratingPDF}>
                            {isGeneratingPDF ? 'Please wait...' : 'Generate PDF'}
                        </button>
                        <div id="chart-grid" className="chart-grid">
                            <div className="chart-container">
                                <SupplierStatus showDataLabels={showDataLabels} supplierRequest={supplierRequest} />
                            </div>
                            <div className="chart-container">
                                <DocumentCondition showDataLabels={showDataLabels} usersWithForms={usersWithForms} />
                            </div>
                            <div className="chart-container">
                                <StatusRequirement showDataLabels={showDataLabels} supplierRequest={supplierRequest} />
                            </div>
                            <div className="chart-container">
                                <SpecificStatusByRequirement showDataLabels={showDataLabels} usersWithForms={usersWithForms} optionsRequirements={optionsRequirements} />
                            </div>
                            <div className="chart-container">
                                <Status showDataLabels={showDataLabels} supplierRequest={supplierRequest} />
                            </div>
                            <div className="chart-container">
                                <Products showDataLabels={showDataLabels} supplierRequest={supplierRequest} />
                            </div>
                            <div className="chart-container">
                                <RequirementExpiration showDataLabels={showDataLabels} usersWithForms={usersWithForms} />
                            </div>
                            <div className="chart-container">
                                <ExpiredRequirement showDataLabels={showDataLabels} usersWithForms={usersWithForms} />
                            </div>
                            <br></br>
                            <div className="chart-container large">
                                <TeamSupplier showDataLabels={showDataLabels} currentItems={currentItems} companyUsers={companyUsers} />
                            </div>
                            <div className="chart-container large">
                                <NumberOfResponses showDataLabels={showDataLabels} supplierRequest={supplierRequest} />
                            </div>
                        </div>
                    </>
                    :
                    null
            }

        </>
    )
}
