import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { Title, Text } from './styled'

import Button from 'components/Form/Button'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

export default function ModalPlanSelected() {
    const { setModal, findlanguage, language } = useContext(CoreContext)

    const history = useHistory()
    const navigate = (to) => {
        history.push(`/${to}`)
        close()
    }

    const close = () => {
        setModal(null)
    }

    return (
        <>
            <ModalWrapper colorized={true} hideclose={true}>
                <Title>{language === 'portugues' ? 'Obrigado!' : 'Thank you!'}</Title>

                <Text>
                    {language === 'portugues'
                        ? 'Nosso time irá enviar um link de pagamento em breve'
                        : 'Our team will send you a link for payment'}
                </Text>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '30px',
                        marginBottom: '50px',
                        marginLeft: '15px',
                    }}
                >
                    <Button whiteAndBlack onClick={() => navigate('dashboard')}>
                        {language === 'portugues' ? 'Ir para a plataforma' : 'Go to platform'}
                    </Button>
                </div>
            </ModalWrapper>
        </>
    )
}
