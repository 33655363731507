import styled from 'styled-components'

export const ChartLegend = styled.div.attrs({})`
    flex: 1;
    align-self: center;
    margin-top: -20px;
    margin-left: 30px;
`
export const ChartLegendItem = styled.div.attrs({})`
    padding: 7px 0;
    display: flex;
    align-items: center;
`
export const ChartLegendItemDecoration = styled.div.attrs({})`
    width: 18px;
    height: 18px;
    border-radius: 4px;
    margin-right: 15px;
    ${(props) =>
        props.color
            ? `
            background: ${props.color};
        `
            : ``}
`
export const ChartLegendItemText = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: var(--grey-color);
`
