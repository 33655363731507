import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const Content = styled.div.attrs({})``

export const PageHeader = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 20px;
`
export const PageHeaderTitle = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: var(--grey-color);
`
export const PageHeaderActions = styled.div.attrs({})`
    display: flex;
    align-items: center;

    position: relative;
`
export const PageHeaderBack = styled.img.attrs({
    src: '/icons/back.svg',
})`
    cursor: pointer;
    margin-right: 20px;
`

export const PageCard = styled.div.attrs({})`
    padding: 24px;
    background: var(--white-color);

    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
    box-shadow: 0px 1.5px 6px var(--shadow-color);
    margin-bottom: 20px;
`
export const ProfileContainer = styled.div.attrs({})`
    display: flex;
`
export const ProfileContent = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const ProfileContentSpace = styled.div.attrs({})`
    padding: 10px;
`
export const ProfileFormContent = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: space-between;
    max-width: 760px;
    margin: 0 auto;
`

export const ProfileFormActions = styled.div.attrs({})`
    flex: 1;
    min-width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px;
`

export const PageScroller = styled.div.attrs({})`
    margin-top: 20px;
    height: 260px;
    overflow: auto;
`

export const PageWrapper = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
`

export const CardContent = styled.div.attrs({})`
    padding: 17px 20px;
    background: var(--backgroundgrey-color);
    border-radius: 10px;
    width: 100%;
    max-width: 340px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 22px;
    margin-bottom: 22px;
`
export const CardContentInfo = styled.div.attrs({})`
    display: flex;
    align-items: center;
`
export const CardContentImage = styled.img.attrs((props) => ({
    src: `/icons/${props.icon}.svg`,
}))`
    margin-right: 15px;
`
export const CardContentText = styled.div.attrs({})`
    max-width: 140px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-family: 16px;
    color: var(--textgrey-color);
`
export const CardContentRemove = styled.img.attrs({
    src: '/icons/trash.svg',
})`
    cursor: pointer;
`
