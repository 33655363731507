import styled from 'styled-components'

export const BodyContent = styled.div.attrs({})`
    padding-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
`

export const FormContent = styled.div.attrs({})`
    padding: 40px 0px;
    background: var(--white-color);
    border-radius: 15px;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    max-width: 810px;
    flex-wrap: wrap;
    box-shadow: 0px 1.5px 6px var(--shadow-color);
    margin-bottom: 30px;
`

export const RequiredText = styled.div.attrs({})`
    color: var(--primary-color);
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-align: center;
    margin: 35px 0;
    padding-bottom: 10px;
`

export const FormTitle = styled.div.attrs({})`
    color: var(--primary-color);
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    padding-bottom: 30px;
`

export const CheckList = styled.div.attrs({})`
    padding-bottom: 30px;
`
export const CheckContent = styled.div.attrs({})`
    padding-bottom: 30px;
`

export const InputBlankContainer = styled.div.attrs({})`
    max-width: 100%;
    display: flex;
    width: 100%;
    position: relative;
    padding-top: 5px;
    margin-bottom: 10px;
    margin-left: 15px;
`

export const InputSpace = styled.div.attrs({})`
    width: 300px;
`

export const CheckContainer = styled.div.attrs({})`
    margin: 30px 0px;
`
