import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { CoreContext } from 'context/CoreContext';
import { useContext } from 'react';
import { PageHeader, PageHeaderTitle, PageCard, TrashIcon, EditIcon } from './styled';
import ContainerAuthenticated from 'containers/Authenticated';
import Button from 'components/Form/Button';
import { FetchMyServiceProviders, DeleteServiceProvider } from 'services/serviceProvider';

export default function DashboardTeamAccess() {
    const { findlanguage, setModal } = useContext(CoreContext);
    const [teamAccessList, setTeamAccessList] = useState([]);
    const [searchExpression, setSearchExpression] = useState('');

    const fetchUserList = async () => {
        const updatedList = await FetchMyServiceProviders();
        if (updatedList.statusCode === 400) return;

        setTeamAccessList(updatedList);
    };

    useEffect(() => {
        fetchUserList();
    }, []);

    const displayCreateUserModal = (id) => {
        setModal({
            type: 'team-access-service-provider',
            refresh: fetchUserList,
            id: id
        });
    };

    const deleteServiceProvider = async (id) => {
        await DeleteServiceProvider(id);
        fetchUserList();
    };

    const teamListComponent = () => (
        <PageCard>
            <Row style={{ marginBottom: '.6rem', color: '#707070', textAlign: 'center' }}>
                <Col md="1">Avatar</Col>
                <Col md="2">{findlanguage().nameNonMandatory}</Col>
                <Col md="3">{findlanguage().contactName}</Col>
                <Col md="3">{findlanguage().phone}</Col>
                <Col md="2">{findlanguage().emailNonMandatory}</Col>
                <Col md="1">{findlanguage().actions}</Col>
            </Row>
            {teamAccessList.map((teamMember, i) => (
                <Row key={i} style={{ padding: '.8rem 0', borderTop: '1px solid #70707020', textAlign: 'center' }}>
                    <Col md="1">
                        <img src={teamMember.serviceProvider.avatar === null ? `/images/no-image.png` : ''} style={{ width: '40px', borderRadius: '50%' }} />
                    </Col>
                    <Col md="2" style={{ wordWrap: 'break-word' }}>
                        {teamMember.serviceProvider.name}
                    </Col>
                    <Col md="3" style={{ wordWrap: 'break-word' }}>
                        {teamMember.serviceProvider.contactName}
                    </Col>
                    <Col md="3" style={{ wordWrap: 'break-word' }}>
                        {teamMember.serviceProvider.phone}
                    </Col>
                    <Col md="2" style={{ wordWrap: 'break-word' }}>
                        {teamMember.serviceProvider.email}
                    </Col>
                    <Col md="1" style={{ display: 'inline-flex' }}>
                        <EditIcon onClick={() => displayCreateUserModal(teamMember.id)} />
                        <TrashIcon onClick={() => deleteServiceProvider(teamMember.id)} />
                    </Col>
                </Row>
            ))}
        </PageCard>
    );


    return (
        <ContainerAuthenticated>
            <Container fluid={false}>
                <Row style={{ gap: '1rem' }} noGutters={true}>
                    <Col md={'auto'} style={{ display: 'flex', justifyContent: 'center' }}>
                        <PageHeader style={{ margin: 0 }}>
                            <PageHeaderTitle>{findlanguage().myServiceProviders}</PageHeaderTitle>
                        </PageHeader>
                    </Col>
                    <Col>
                        <Row style={{ padding: '0 1rem' }}>
                            {/* Your Search Component */}
                        </Row>
                    </Col>
                    <Col md={'auto'} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            onClick={() => displayCreateUserModal(-1)}
                            style={{ margin: 0, padding: '0 2rem', height: '3rem' }}
                            primary
                        >
                            {findlanguage().newServiceProvider}
                        </Button>
                    </Col>
                </Row>
                {teamListComponent()}
            </Container>
        </ContainerAuthenticated>
    );
}
