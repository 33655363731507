import styled from 'styled-components'

export const FormTitle = styled.div.attrs({})`
    color: var(--black-color);
    font-weight: bold;
    font-size: 17px;
    text-align: center;
    margin-bottom: 40px;
`
export const FormSpacer = styled.div.attrs({})`
    margin-top: 40px;
`

export const RegisterCall = styled.div.attrs({})`
    margin: 30px 0 10px;
    color: var(--lightgrey-color);
    font-size: 15px;
    font-weight: bold;
`

export const RememberForgotContent = styled.div.attrs({})`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0;
`
export const ForgotText = styled.div.attrs({})`
    color: var(--primary-color);
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`

export const InputIcon = styled.img.attrs({})`
    margin-top: 10px;
    height: 20px;
    margin-left: 10px;
    cursor: pointer;
`
