import { CoreContext } from 'context/CoreContext'
import React, { useContext, useEffect, useState } from 'react'
import Button from '../Button'
import Check from '../Check'

import {
    CheckContent,
    CheckHeader,
    CheckList,
    CheckIcon,
    SelectManyActionIcon,
    SelectManyText,
} from './styled'

export default function CheckMany({
    checked,
    onChange,
    label,
    title,
    options,
    locked,
    returnValue,
}) {
    const [opened, setOpened] = useState(false)
    const { setModal, language, findlanguage } = useContext(CoreContext)

    const [currentOptions, setCurrentOptions] = useState(options ? options : [])
    const [enableReturn, setEnableReturn] = useState(false)

    const addMore = async () => {
        const rand = new Date().getTime()
        setEnableReturn(false)
        setModal({
            type: 'add',
            title: findlanguage().other,
            action: (value) => {
                setEnableReturn(true)
                setCurrentOptions([
                    ...currentOptions,
                    { id: rand, title: value, label: value, checked: false },
                ])
            },
        })
    }

    useEffect(() => {
        if (enableReturn) {
            returnValue(title, [...currentOptions])
        }
        return () => {}
    }, [currentOptions])

    return (
        <>
            {language === 'portugues' ? (
                <>
                    {options?.length ? (
                        <>
                            <CheckHeader>
                                <Check
                                    label={label}
                                    title={title}
                                    locked={locked}
                                    onChange={(e) => onChange(title, null, e)}
                                    checked={checked}
                                />
                                <CheckIcon onClick={() => setOpened(!opened)} />
                            </CheckHeader>
                        </>
                    ) : (
                        <>
                            <Check
                                checked={checked}
                                onChange={(e) => onChange(title, null, e)}
                                label={label}
                                title={title}
                                locked={locked}
                            />
                        </>
                    )}

                    {!opened ? null : currentOptions?.length ? (
                        <>
                            <CheckList>
                                {currentOptions.map((item, key) => (
                                    <CheckContent key={key}>
                                        <Check
                                            onChange={(e) => onChange(title, item.label_pt, e)}
                                            title={item.label_pt}
                                            label={''}
                                            checked={item.checked}
                                            locked={locked}
                                        />
                                    </CheckContent>
                                ))}
                            </CheckList>

                            <Button small primary onClick={addMore}>
                                <SelectManyActionIcon />
                                <SelectManyText>{findlanguage().other}</SelectManyText>
                            </Button>
                        </>
                    ) : null}
                </>
            ) : null}
            {language === 'english' ? (
                <>
                    {options?.length ? (
                        <>
                            <CheckHeader>
                                <Check
                                    label={label}
                                    title={title}
                                    locked={locked}
                                    onChange={(e) => onChange(title, null, e)}
                                    checked={checked}
                                />
                                <CheckIcon onClick={() => setOpened(!opened)} />
                            </CheckHeader>
                        </>
                    ) : (
                        <>
                            <Check
                                checked={checked}
                                onChange={(e) => onChange(title, null, e)}
                                label={label}
                                title={title}
                                locked={locked}
                            />
                        </>
                    )}

                    {!opened ? null : currentOptions?.length ? (
                        <>
                            <CheckList>
                                {currentOptions.map((item, key) => (
                                    <CheckContent key={key}>
                                        <Check
                                            onChange={(e) => onChange(title, item.label, e)}
                                            title={item.label}
                                            label={''}
                                            checked={item.checked}
                                            locked={locked}
                                        />
                                    </CheckContent>
                                ))}
                            </CheckList>

                            <Button small primary onClick={addMore}>
                                <SelectManyActionIcon />
                                <SelectManyText>Other</SelectManyText>
                            </Button>
                        </>
                    ) : null}
                </>
            ) : null}
        </>
    )
}
