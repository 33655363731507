import styled from 'styled-components'
import ReactLoading from 'react-loading'

export const ModalHeader = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const ModalHeaderSpace = styled.div.attrs({})``
export const ModalHeaderClose = styled.img.attrs((props) => ({
    src: props.min ? '/icons/minimize.svg' : '/icons/close.svg',
    width: 16,
}))`
    cursor: pointer;
`

export const ModalLoad = styled(ReactLoading).attrs({
    type: 'spin',
    color: 'var(--primary-color)',
    height: 16,
    width: 16,
})`
    margin-top: -10px;
`
export const ModalTitle = styled.div.attrs({})`
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--grey-color);
    margin: 0 12px;
`
export const ModalHeaderCenterContent = styled.div.attrs({})`
    display: flex;
    align-items: center;
    cursor: pointer;
`
export const ModalBadge = styled.div.attrs({})`
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: var(--red-color);

    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 3px var(--shadow-color);

    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--white-color);
`
