import styled from 'styled-components'

export const ModalBody = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ModalBodyTitle = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: var(--grey-color);
    max-width: 250px;
    text-align: center;
    margin-bottom: 12px;
`
