import React, { useContext, useEffect, useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import ReactPaginate from 'react-paginate'
import _ from 'lodash'
import { toast } from 'react-toastify'
import Button from 'components/Form/Button'

import {
    PageHeader,
    PageHeaderTitle,
    PageHeaderActions,
    ContentScroll,
    ScrollWrapper,
    LoadSpammer,
    ContentFilters,
    ReadIcon,
    IconButton,
} from './styled'

import ContainerAuthenticated from 'containers/Authenticated'

import DashboardCardNotification from 'components/Dashboard/Cards/Notification'

import {
    FetchMyNotifications,
    RemoveNotification,
    UpdateNotifications,
} from 'services/notifications'
import { UpdateConnection } from 'services/connections'
import { UpdateSupplierReg } from 'services/sms'
import { NotificationManagerAdd } from 'services/admin'

import { LoadBlack } from 'ui/styled'
import { CoreContext } from 'context/CoreContext'

// import _ from 'lodash'

export default function DashboardNotification() {
    const { getPermission, findlanguage, profile, user, setModal } = useContext(CoreContext)

    // const [notificationsCount, setNotificationsCount] = useState()
    // const [filteredCount, setFilteredCount] = useState(0)
    const [notificationsList, setNotificationsList] = useState()
    const [filter, setFilter] = useState('')
    const [loading, setLoading] = useState(true)
    const [totalPerPage, setTotalPerPage] = useState(9)
    const [page, setPage] = useState(0)
    const [notificationUnreadList, setNotificationUnreadList] = useState([])

    const changeValuePerPage = async (value) => {
        setTotalPerPage(value)
    }

    const handlePageClick = async (event) => {
        setPage(event.selected)
        setLoading(true)

        const newOffset = (event.selected * totalPerPage) % notificationsCount

        await listNotifications(newOffset)

        setLoading(false)
    }

    const mark = async () => {
        for (let pos = 0; pos < notificationUnreadList.length; pos++) {
            await UpdateNotifications({
                ...notificationUnreadList[pos],
                read: true,
            })
        }
        setPage(0)
        await listNotifications(-1)
    }

    const add = async (item) => {
        let list = [...notificationUnreadList]

        if (!list.includes(item)) {
            list.push(item)
        }

        setNotificationUnreadList(list)
    }

    const listNotifications = async (offset = 0) => {
        setLoading(true)
        let result = ''
        let _sort,
            _direction,
            _start,
            _limit,
            _read = ''

        if (offset < 0) {
            offset = 0
        }

        if (filter === 'read') {
            _sort = 'created_at'
            _direction = 'ASC'
            _start = offset
            _limit = totalPerPage
            _read = '1'
        } else if (filter === 'unread') {
            _sort = 'created_at'
            _direction = 'ASC'
            _start = offset
            _limit = totalPerPage
            _read = '0'
        } else if (filter === 'older') {
            _sort = 'created_at'
            _direction = 'ASC'
            _start = offset
            _limit = totalPerPage
            _read = ''
        } else if (filter === 'newer') {
            _sort = 'created_at'
            _direction = 'DESC'
            _start = offset
            _limit = totalPerPage
            _read = ''
        } else {
            _sort = 'id'
            _direction = 'ASC'
            _start = offset
            _limit = totalPerPage
            _read = ''
        }

        result = await FetchMyNotifications(_sort, _direction, _start, _limit, _read)

        if (result.error) {
            toast.error('Error on loading')
            setNotificationsList([])
        } else {
            setNotificationsList(result)
        }
        setLoading(false)
    }

    // useEffect(() => {
    //     listNotifications()
    //     setPageCount(Math.ceil(notificationsCount / totalPerPage))
    // }, [filter, totalPerPage])

    useEffect(() => {
        const fetchData = async () => {
            await listNotifications(-1)
        }

        setPage(0)
        fetchData().catch(console.error)
    }, [filter])

    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const acceptOrDeclineSupplier = async (accept, item) => {
        await UpdateSupplierReg({
            id: item.connection_id,
            connection_requested: accept ? 'Accepted' : 'Denied',
        })
        if (accept) {
            item.type = 'myqms_accepted'
            await NotificationManagerAdd('myqms_accepted', item.user_requesting_id, user.name, user)
        } else {
            item.type = 'myqms_not_accepted'
            await NotificationManagerAdd(
                'myqms_not_accepted',
                item.user_requesting_id,
                user.name,
                user
            )
        }
        item.decided_connection = true
        item.receiver_id = item.user_requesting_id
        item.from_name = user.name
        const updateNotification = await UpdateNotifications(item)

        await listNotifications(-1)
    }

    const acceptOrDeclineConnection = async (accept, item) => {
        await UpdateConnection({
            id: item.connection_id,
            acceptedInvitation: accept ? 'Accepted' : 'Denied',
        })
        if (accept) {
            item.type = 'connection_accepted'
            await NotificationManagerAdd(
                'connection_accepted',
                item.user_requesting_id,
                user.name,
                user
            )
        } else {
            item.type = 'connection_not_accepted'
            await NotificationManagerAdd(
                'connection_not_accepted',
                item.user_requesting_id,
                user.name,
                user
            )
        }
        item.decided_connection = true
        item.receiver_id = item.user_requesting_id
        item.from_name = user.name
        const updateNotification = await UpdateNotifications(item)

        await listNotifications(-1)
    }

    const deleteNotification = async (id) => {
        setModal({
            type: 'choose',
            title: findlanguage().deleteQuestion,
            options: [
                { title: findlanguage().no, action: () => setModal(null) },
                {
                    title: findlanguage().yes,
                    action: async () => await doDeleteNotification(id),
                    outline: true,
                },
            ],
        })
    }

    const doDeleteNotification = async (id) => {
        setModal({
            type: 'loading',
            text: findlanguage().processing,
        })
        const response = await RemoveNotification(id)
        if (response.statusCode)
            return setModal({
                type: 'alert',
                text: 'Operation failed.',
                warning: true,
            })
        setModal(null)
        setPage(0)
        await listNotifications(-1)
    }

    useEffect(() => {
        const fetchData = async () => {
            await listNotifications(-1)
        }

        fetchData().catch(console.error)
    }, [])

    const notificationsCount = notificationsList ? parseInt(notificationsList.count[0].qty) : 0
    const filteredCount = notificationsList ? parseInt(notificationsList.data.length) : 0
    const pageCount = Math.ceil(notificationsCount / totalPerPage)

    return (
        <>
            <ContainerAuthenticated>
                <Container fluid={false}>
                    <Row>
                        <Col>
                            <PageHeader>
                                <PageHeaderActions>
                                    <PageHeaderTitle>
                                        {findlanguage().notification}{' '}
                                        {notificationsCount ? (
                                            `(${notificationsCount}) `
                                        ) : (
                                            <LoadSpammer>
                                                {' '}
                                                <LoadBlack />{' '}
                                            </LoadSpammer>
                                        )}
                                    </PageHeaderTitle>
                                    <ContentFilters>
                                        <IconButton
                                            onClick={async () => await mark()}
                                            className="tooltip_element"
                                        >
                                            <ReadIcon />
                                            <span className="tooltip">
                                                {findlanguage().markAsRead}
                                            </span>
                                        </IconButton>
                                        <Select
                                            onChange={(e) => setFilter(e.value)}
                                            placeholder={findlanguage().filterBy}
                                            name="filter"
                                            isSearchable={false}
                                            options={findlanguage().filterNotificationsOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </ContentFilters>
                                </PageHeaderActions>
                            </PageHeader>

                            <>
                                <ContentScroll>
                                    <ScrollWrapper>
                                        {!notificationsList || loading ? (
                                            <LoadBlack />
                                        ) : (
                                            notificationsList.data.map((x, i) => (
                                                <DashboardCardNotification
                                                    acceptOrDeclineSupplier={
                                                        acceptOrDeclineSupplier
                                                    }
                                                    acceptOrDeclineConnection={
                                                        acceptOrDeclineConnection
                                                    }
                                                    deleteNotification={deleteNotification}
                                                    add={add}
                                                    item={x}
                                                    key={x.id}
                                                />
                                            ))
                                        )}
                                    </ScrollWrapper>
                                </ContentScroll>

                                <div id="reactPaginate" style={{ display: 'flex' }}>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel={`${findlanguage().next} > `}
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={15}
                                        pageCount={pageCount}
                                        previousLabel={`< ${findlanguage().previous}`}
                                        renderOnZeroPageCount={null}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link'}
                                        containerClassName={'pagination'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        activeClassName={'active'}
                                        forcePage={page}
                                    />
                                    {/* <select
                                        className="form-select"
                                        style={{
                                            marginLeft: '10px',
                                            width: '10%',
                                            height: '38px',
                                        }}
                                        value={totalPerPage}
                                        onChange={(e) => changeValuePerPage(e.target.value)}
                                    >
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                    </select> */}
                                </div>
                            </>
                        </Col>
                    </Row>
                </Container>
            </ContainerAuthenticated>
        </>
    )
}
