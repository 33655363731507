import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { CoreContext } from 'context/CoreContext'

import { BodyContentTabs, BodyTab, BodyContentTable, TableLine, LineText } from './styled'

export default function DashboardUserDetailsTabCompany({ products }) {
    const { findlanguage } = useContext(CoreContext)

    return (
        <>
            {products?.length !== 0 ? (
                <>
                    <BodyContentTabs>
                        <BodyTab>{findlanguage().products}</BodyTab>
                    </BodyContentTabs>

                    <BodyContentTable>
                        {products?.map((item, key) => (
                            <TableLine key={key} odd={key % 2 === 0}>
                                <LineText>{item.name}</LineText>
                            </TableLine>
                        ))}
                    </BodyContentTable>
                </>
            ) : null}
        </>
    )
}
