import React, { useContext, useState } from 'react'
import _ from 'lodash'

import { PageCard, PageCardActions, CardFormStatus, Filter } from './styled'

import DashboardButton from 'components/Dashboard/Button'
import { CoreContext } from 'context/CoreContext'
import ReactPaginate from 'react-paginate'

export default function DashboardTabSupplierInfo({ addDocument, companyUsers, overview, isOwnerGrid }) {
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPerPage, setTotalPerPage] = useState(10)
    const biggerButton = { fontSize: '13px', cursor: 'pointer' }
    const cssTableInLineFlex = {
        fontSize: '15px',
        fontWeight: '700',
        color: '#646464',
        display: 'flex',
    }
    const biggerButtonGreen = { fontSize: '13px', cursor: 'pointer', backgroundColor: '#8dbd64' }

    const { findlanguage, language, formatDateValue, permissions, user } = useContext(CoreContext)
    const typeOfUser = language === 'english' ? 'Service Provider' : 'Prestador de Serviço';
    const [seeFilters, setSeeFilters] = useState(false)
    const [filters, setFilters] = useState({
        status: '',
        type: '',
        name: '',
        code: '',
        revision: '',
        emission_revision_date: '',
        issue_date: '',
        responsible_review: '',
        responsible_approval: '',
    })

    const columns = [
        { title: findlanguage().status, name: 'status' },
        { title: findlanguage().documentType, name: 'type' },
        { title: findlanguage().documentName, name: 'name' },
        { title: findlanguage().documentCode, name: 'code' },
        { title: findlanguage().revisionNumber, name: 'revision' },
        { title: findlanguage().emissionDate, name: 'emission_revision_date' },
        { title: findlanguage().issueDate, name: 'issue_date' },
        { title: findlanguage().responsibleReview, name: 'responsible_review' },
        { title: findlanguage().responsibleApproval, name: 'responsible_approval' },
    ]

    const cssTable = { fontSize: '15px', fontWeight: '700', color: '#646464' }

    const createQuery = (value, field) => {
        setFilters({
            ...filters,
            [field]: value
        })
        setCurrentPage(1)
    }
    function translate(reason) {
        switch (language) {
            case 'portugues':
                switch (reason) {
                    case 'Agreement':
                        return 'Acordo'
                    case 'Form':
                        return 'Formulário'
                    case 'Procedure':
                        return 'Procedimento'
                    case 'Work Instruction':
                        return 'Instrução de Trabalho'
                    default:
                        return reason
                }
            case 'english':
                switch (reason) {
                    case 'Agreement':
                        return 'Agreement'
                    case 'Form':
                        return 'Form'
                    case 'Procedure':
                        return 'Procedure'
                    case 'Work Instruction':
                        return 'Work Instruction'
                    default:
                        return reason
                }
            default:
                return reason
        }
    }

    const formStatus = [
        {
            key: 'active',
            value: 'Active'
        },
        {
            key: 'pending',
            value: 'Pending'
        },
        {
            key: 'expired',
            value: 'Expired'
        },
        {
            key: 'obsolete',
            value: 'Obsolete'
        }
    ]
    function convertDateFormat(dateStr) {
        if (dateStr !== undefined) {
            const [year, month, day] = dateStr.split('-');
            return `${month}/${day}/${year}`;
        }
        else {
            return '';
        }
    }

    const handlePageChange = (data) => {
        setCurrentPage(data.selected + 1)
    }

    const handleTotalPerPageChange = (event) => {
        setTotalPerPage(Number(event.target.value))
        setCurrentPage(1) // Reset to first page
    }

    const pageCount = Math.ceil(overview
        ?.filter((x) => {
            if (isOwnerGrid) {
                return x
            } else {
                if
                    (
                    x?.status === 'Active' ||
                    x?.status === 'Expired' ||
                    x?.status === 'Obsolete'
                )

                    return x
            }
        })
        ?.filter((x) => {
            const strtDt = new Date(x.issue_date);
            const endDt = new Date();
            let expired = false;

            let datesLeft = x.issue_date !== null
                ? Math.floor(
                    (Date.parse(x.issue_date) - Date.parse(new Date())) / 86400000
                )
                : null;
            if (datesLeft !== null) datesLeft = datesLeft + 1;

            if (endDt >= strtDt) {
                expired = true;
            }
            if (filters.status?.toLowerCase() === 'active' && expired) {
                return false;
            }

            if (x.status?.toLowerCase() === 'active' && expired && filters.status?.toLowerCase() === 'expired') {
                return true;
            }

            // Rest of your filter conditions
            if (
                (
                    filters?.status?.toLowerCase() !== '' ?
                        x?.status?.toLowerCase() === filters?.status?.toLowerCase()
                        : x?.status?.toLowerCase().includes('')
                ) &&
                x?.status?.toLowerCase().includes(filters?.status?.toLowerCase()) &&
                x?.type?.toLowerCase().includes(filters?.type?.toLowerCase()) &&
                x?.name?.toLowerCase().includes(filters?.name?.toLowerCase()) &&
                x?.code?.toLowerCase().includes(filters?.code?.toLowerCase()) &&
                x?.revision?.toLowerCase().includes(filters?.revision?.toLowerCase()) &&
                convertDateFormat(x?.emission_revision_date?.toLowerCase()).includes(filters?.emission_revision_date?.toLowerCase()) &&
                convertDateFormat(x?.issue_date?.toLowerCase()).includes(filters?.issue_date?.toLowerCase()) &&
                (
                    filters.responsible_review?.toLowerCase() !== '' ?
                        x?.responsible_review?.contactName?.toLowerCase() === filters?.responsible_review?.toLowerCase()
                        : x?.responsible_review?.contactName?.toLowerCase().includes('')
                ) &&
                (
                    filters.responsible_approval?.toLowerCase() !== '' ?
                        x?.responsible_approval?.contactName?.toLowerCase() === filters?.responsible_approval?.toLowerCase()
                        : x?.responsible_approval?.contactName?.toLowerCase().includes('')
                )
            ) {
                return true;
            }

            return false;
        }).length / totalPerPage)

    const indexOfLastItem = currentPage * totalPerPage

    const indexOfFirstItem = indexOfLastItem - totalPerPage

    const table = () => {
        return <table className="table" style={{ wordBreak: 'break-all' }}>
            {thead}
            <tbody className="table-group-divider" style={{ borderTopColor: '#263640' }}>
                {overview
                    ?.filter((x) => {
                        if
                            (
                            x?.status === 'Active' ||
                            x?.status === 'Expired' ||
                            x?.status === 'Obsolete'
                        )

                            return x
                    })
                    ?.filter((x) => {
                        const strtDt = new Date(x.issue_date);
                        const endDt = new Date();
                        let expired = false;

                        let datesLeft = x.issue_date !== null
                            ? Math.floor(
                                (Date.parse(x.issue_date) - Date.parse(new Date())) / 86400000
                            )
                            : null;
                        if (datesLeft !== null) datesLeft = datesLeft + 1;

                        if (endDt >= strtDt) {
                            expired = true;
                        }
                        if (filters.status?.toLowerCase() === 'active' && expired) {
                            return false;
                        }

                        if (x.status?.toLowerCase() === 'active' && expired && filters.status?.toLowerCase() === 'expired') {
                            return true;
                        }

                        if (
                            (
                                filters?.status?.toLowerCase() !== '' ?
                                    x?.status?.toLowerCase() === filters?.status?.toLowerCase()
                                    : x?.status?.toLowerCase().includes('')
                            ) &&
                            x?.status?.toLowerCase().includes(filters?.status?.toLowerCase()) &&
                            x?.type?.toLowerCase().includes(filters?.type?.toLowerCase()) &&
                            x?.name?.toLowerCase().includes(filters?.name?.toLowerCase()) &&
                            x?.code?.toLowerCase().includes(filters?.code?.toLowerCase()) &&
                            x?.revision?.toLowerCase().includes(filters?.revision?.toLowerCase()) &&
                            convertDateFormat(x?.emission_revision_date?.toLowerCase()).includes(filters?.emission_revision_date?.toLowerCase()) &&
                            convertDateFormat(x?.issue_date?.toLowerCase()).includes(filters?.issue_date?.toLowerCase()) &&
                            (
                                filters.responsible_review?.toLowerCase() !== '' ?
                                    x?.responsible_review?.contactName?.toLowerCase() === filters?.responsible_review?.toLowerCase()
                                    : x?.responsible_review?.contactName?.toLowerCase().includes('')
                            ) &&
                            (
                                filters.responsible_approval?.toLowerCase() !== '' ?
                                    x?.responsible_approval?.contactName?.toLowerCase() === filters?.responsible_approval?.toLowerCase()
                                    : x?.responsible_approval?.contactName?.toLowerCase().includes('')
                            )
                        ) {
                            return true;
                        }

                        return false;
                    })
                    ?.slice(indexOfFirstItem, indexOfLastItem)
                    .map((item) => {
                        const strtDt = new Date(item.issue_date)
                        const endDt = new Date()
                        let expired = false

                        let datesLeft =
                            item.issue_date !== null
                                ? Math.floor(
                                    (Date.parse(item.issue_date) -
                                        Date.parse(new Date())) /
                                    86400000
                                )
                                : null
                        if (datesLeft !== null) datesLeft = datesLeft + 1

                        if (endDt >= strtDt) expired = true

                        return (
                            <tr key={item.id}>
                                <td style={cssTable}>
                                    {item?.status === 'Active' && expired === false ? (
                                        <span
                                            style={biggerButton}
                                            onClick={() => addDocument('active', item)}
                                            className="badge bg-secondary"
                                        >
                                            {findlanguage().active}
                                        </span>
                                    ) : item?.status !== 'Obsolete' ? (
                                        <span
                                            style={biggerButton}
                                            onClick={() => addDocument('active', item)}
                                            className="badge bg-secondary"
                                        >
                                            {findlanguage().expired}
                                        </span>
                                    ) : null}
                                    {item?.status === 'Obsolete' ? (
                                        <span
                                            style={biggerButton}
                                            onClick={() => addDocument('obsolete', item)}
                                            className="badge bg-secondary"
                                        >
                                            {findlanguage().obsolete}
                                        </span>
                                    ) : null}
                                    {item?.status === 'Expired' ? (
                                        <span
                                            style={biggerButton}
                                            onClick={() => addDocument('expired', item)}
                                            className="badge bg-secondary"
                                        >
                                            {findlanguage().obsolete}
                                        </span>
                                    ) : null}
                                </td>
                                <td style={cssTable}>
                                    <div
                                        style={datesLeft !== null ? cssTableInLineFlex : {}}
                                    >
                                        {item?.status === 'Obsolete' ? (
                                            <CardFormStatus
                                                color="#6c757d"
                                                className="tooltip_element"
                                            >
                                                <span className="tooltip">
                                                    {findlanguage().obsolete}
                                                </span>
                                            </CardFormStatus>
                                        ) : item?.status === 'Active' &&
                                            expired === false ? (
                                            <CardFormStatus
                                                color="#8dbd64"
                                                className="tooltip_element"
                                            >
                                                <span className="tooltip">
                                                    {findlanguage().active}
                                                </span>
                                            </CardFormStatus>
                                        ) : (
                                            <CardFormStatus
                                                color="#dc3545"
                                                className="tooltip_element"
                                            >
                                                <span className="tooltip">
                                                    {findlanguage().expired}
                                                </span>
                                            </CardFormStatus>
                                        )}

                                        {datesLeft !== null &&
                                            datesLeft > 0 &&
                                            datesLeft < 30 ? (
                                            <span
                                                style={{
                                                    fontSize: '13px',
                                                    cursor: 'pointer',
                                                    color: 'white',
                                                    marginLeft: '5px',
                                                }}
                                                className="badge bg-warning"
                                            >
                                                {findlanguage().expiredIn} {datesLeft}{' '}
                                                {findlanguage().daysLowerCase}
                                            </span>
                                        ) : null}
                                    </div>
                                </td>
                                <td style={cssTable}>{translate(item?.type)}</td>
                                <td style={cssTable}>{item?.name}</td>
                                <td style={cssTable}>{item?.code}</td>
                                <td style={cssTable}>{item?.revision}</td>
                                <td style={cssTable}>
                                    {formatDateValue(item?.emission_revision_date)}
                                </td>
                                <td style={cssTable}>
                                    {formatDateValue(item?.issue_date)}
                                </td>
                                <td style={cssTable}>
                                    {item?.responsible_review?.contactName} {item?.responsible_review?.role === 5 ? `(${typeOfUser})` : null}
                                </td>
                                <td style={cssTable}>
                                    {item?.responsible_approval?.contactName} {item?.responsible_approval?.role === 5 ? `(${typeOfUser})` : null}
                                </td>
                            </tr>
                        )
                    })}
            </tbody>
        </table>
    }

    const tableOwner = () => {
        return <table className="table" style={{ wordBreak: 'break-all' }}>
            {thead}
            <tbody
                className="table-group-divider"
                style={{ borderTopColor: '#263640' }}
            >
                {
                    overview
                        ?.filter((x) => {
                            const strtDt = new Date(x.issue_date);
                            const endDt = new Date();
                            let expired = false;

                            let datesLeft = x.issue_date !== null
                                ? Math.floor(
                                    (Date.parse(x.issue_date) - Date.parse(new Date())) / 86400000
                                )
                                : null;
                            if (datesLeft !== null) datesLeft = datesLeft + 1;

                            if (endDt >= strtDt) {
                                expired = true;
                            }
                            if (filters.status?.toLowerCase() === 'active' && expired) {
                                return false;
                            }

                            if (x.status?.toLowerCase() === 'active' && expired && filters.status?.toLowerCase() === 'expired') {
                                return true;
                            }

                            // Rest of your filter conditions
                            if (
                                (
                                    filters?.status?.toLowerCase() !== '' ?
                                        x?.status?.toLowerCase() === filters?.status?.toLowerCase()
                                        : x?.status?.toLowerCase().includes('')
                                ) &&
                                x?.status?.toLowerCase().includes(filters?.status?.toLowerCase()) &&
                                x?.type?.toLowerCase().includes(filters?.type?.toLowerCase()) &&
                                x?.name?.toLowerCase().includes(filters?.name?.toLowerCase()) &&
                                ((x?.code === null && filters?.code === '') || x?.code?.toLowerCase().includes(filters?.code?.toLowerCase())) &&
                                ((x?.revision === null && filters?.revision === '') || x?.revision?.toLowerCase().includes(filters?.revision?.toLowerCase())) &&
                                convertDateFormat(x?.emission_revision_date?.toLowerCase()).includes(filters?.emission_revision_date?.toLowerCase()) &&
                                convertDateFormat(x?.issue_date?.toLowerCase()).includes(filters?.issue_date?.toLowerCase()) &&
                                (
                                    filters.responsible_review?.toLowerCase() !== '' ?
                                        x?.responsible_review?.contactName?.toLowerCase() === filters?.responsible_review?.toLowerCase()
                                        : x?.responsible_review?.contactName?.toLowerCase().includes('')
                                ) &&
                                (
                                    filters.responsible_approval?.toLowerCase() !== '' ?
                                        x?.responsible_approval?.contactName?.toLowerCase() === filters?.responsible_approval?.toLowerCase()
                                        : x?.responsible_approval?.contactName?.toLowerCase().includes('')
                                )
                            ) {
                                return true;
                            }

                            return false;
                        })
                        ?.slice(indexOfFirstItem, indexOfLastItem)
                        .map((item) => (
                            <tr key={item.id}>
                                <td style={cssTable}>
                                    {item?.status === 'Pending' ? (
                                        <span
                                            style={biggerButtonGreen}
                                            onClick={() =>
                                                addDocument(
                                                    item.responsible_approval?.id ===
                                                        user.id
                                                        ? 'pending'
                                                        : 'view',
                                                    item
                                                )
                                            }
                                            className="badge"
                                        >
                                            {item.responsible_approval?.id ===
                                                user.id
                                                ? findlanguage().pendingApproval
                                                : findlanguage().view}
                                        </span>
                                    ) : null}
                                    {item?.status === 'Returned' ? (
                                        <span
                                            style={biggerButton}
                                            onClick={() =>
                                                addDocument(
                                                    item.responsible_review?.id ===
                                                        user.id
                                                        ? 'returned'
                                                        : 'view',
                                                    item
                                                )
                                            }
                                            className="badge bg-danger"
                                        >
                                            {item.responsible_review?.id === user.id
                                                ? findlanguage().returned
                                                : findlanguage().view}
                                        </span>
                                    ) : null}
                                </td>
                                <td style={cssTable}>
                                    {item?.status === 'Returned' ? (
                                        <CardFormStatus
                                            color="#dc3545"
                                            className="tooltip_element"
                                        >
                                            <span className="tooltip">
                                                {findlanguage().returned}
                                            </span>
                                        </CardFormStatus>
                                    ) : null}
                                    {item?.status === 'Pending' ? (
                                        <CardFormStatus
                                            color="#f0f32b"
                                            className="tooltip_element"
                                        >
                                            <span className="tooltip">
                                                {findlanguage().pending}
                                            </span>
                                        </CardFormStatus>
                                    ) : null}
                                </td>
                                <td style={cssTable}>{translate(item?.type)}</td>
                                <td style={cssTable}>{item?.name}</td>
                                <td style={cssTable}>{item?.code}</td>
                                <td style={cssTable}>{item?.revision}</td>
                                <td style={cssTable}>
                                    {formatDateValue(item?.emission_revision_date)}
                                </td>
                                <td style={cssTable}>
                                    {formatDateValue(item?.issue_date)}
                                </td>
                                <td style={cssTable}>
                                    {item?.responsible_review?.contactName} {item?.responsible_review?.role === 5 ? `(${typeOfUser})` : null}
                                </td>
                                <td style={cssTable}>
                                    {item?.responsible_approval?.contactName} {item?.responsible_approval?.role === 5 ? `(${typeOfUser})` : null}
                                </td>
                            </tr>
                        ))}
            </tbody>
        </table>
    }

    const thead = <thead style={{ backgroundColor: '#4f6b7d', color: '#f4fff7' }}>
        <tr>
            <th scope="col" onClick={() => setSeeFilters(!seeFilters)} >
                <Filter />
            </th>
            {columns.map((x) => (
                <th key={x.title} scope="col">
                    {x.title}
                    {seeFilters ? (
                        <>
                            {x.name === 'status' ? (
                                <select
                                    style={{ height: '31px', paddingBottom: '0px' }}
                                    className="form-select"
                                    onChange={(event) =>
                                        createQuery(event.target.value, x.name)
                                    }
                                >
                                    <option value="">All</option>
                                    {formStatus.map((x) => (
                                        <option value={x.key}>
                                            {x.value}
                                        </option>
                                    ))}
                                </select>
                            ) : null}
                            {x.name !== 'responsible_approval' &&
                                x.name !== 'responsible_review' &&
                                x.name !== 'status' ? (
                                <input
                                    className="form-control"
                                    style={{ height: '31px' }}
                                    onChange={(event) =>
                                        createQuery(event.target.value, x.name)
                                    }
                                />
                            ) : null}
                            {x.name === 'responsible_approval' ||
                                x.name === 'responsible_review' ? (
                                <select
                                    style={{ height: '31px', paddingBottom: '0px' }}
                                    className="form-select"
                                    onChange={(event) =>
                                        createQuery(event.target.value, x.name)
                                    }
                                >
                                    <option value="">All</option>
                                    {companyUsers.map((user) => (
                                        <option value={user.company_id}>
                                            {user.label}
                                        </option>
                                    ))}
                                </select>
                            ) : null}
                        </>
                    ) : null}
                </th>
            ))}
        </tr>
    </thead>

    return (
        <PageCard style={{ marginTop: !isOwnerGrid ? '15px' : ''}}>
            {
                isOwnerGrid ?
                    tableOwner()
                    :
                    table()
            }
            <div id="reactPaginate" style={{ display: 'flex' }}>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel={`${findlanguage().next} > `}
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel={`< ${findlanguage().previous}`}
                    renderOnZeroPageCount={null}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                    forcePage={currentPage - 1}
                />
                <select
                    className="form-select"
                    style={{ marginLeft: '10px', width: '10%', height: '38px' }}
                    value={totalPerPage}
                    onChange={handleTotalPerPageChange}
                >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                </select>
            </div>
        </PageCard>
    )
}
