import React, { useState, useContext } from 'react';
import ModalWrapper from '../Wrapper';
import { CoreContext } from 'context/CoreContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function ModalReviewDateForSupplier() {
    const { findlanguage, modal } = useContext(CoreContext);
    const [activeButton, setActiveButton] = useState('date'); // Default to 'date'
    const [expirationDate, setExpirationDate] = useState(new Date());
    const [reminderDays, setReminderDays] = useState(30); // Default to 30 days

    const handleButtonClick = (value) => {
        setActiveButton(value);
    };

    const handleReminderDaysChange = (event) => setReminderDays(parseInt(event.target.value, 10));

    const formatDate = (date) => {
        const d = new Date(date);
        const month = `${d.getMonth() + 1}`.padStart(2, '0');
        const day = `${d.getDate()}`.padStart(2, '0');
        const year = d.getFullYear();
        return [month, day, year].join('/');
    };

    return (
        <ModalWrapper>
            <label className="form-label" style={{ fontWeight: 'bold', fontSize: '15px', color: 'rgb(86, 86, 86)' }}>
                Choose an option
            </label>
            <div className="btn-group" role="group" aria-label="Expiration options">
                <button
                    type="button"
                    className={`btn ${activeButton === 'date' ? 'btn-custom-active' : 'btn-custom'}`}
                    onClick={() => handleButtonClick('date')}
                >
                    Expiration Date
                </button>
                <button
                    type="button"
                    className={`btn ${activeButton === 'noExpiration' ? 'btn-custom-active' : 'btn-custom'}`}
                    onClick={() => handleButtonClick('noExpiration')}
                >
                    No Expiration
                </button>
            </div>
            <div className="modal-body">
                {activeButton === 'date' && (
                    <form>
                        <div className="form-group">
                            <label htmlFor="expirationDate">{findlanguage().expirationDate || 'Expiration Date'}</label>
                            <DatePicker
                                selected={expirationDate}
                                onChange={(date) => setExpirationDate(date)}
                                className="form-control"
                                id="expirationDate"
                                dateFormat={findlanguage().dateFormat}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="reminderDays">{findlanguage().reminderDays || 'Send reminder starting in'}</label>
                            <input
                                type="number"
                                className="form-control"
                                id="reminderDays"
                                value={reminderDays}
                                onChange={handleReminderDaysChange}
                                min="1"
                            />
                            <small>days before expiration</small>
                        </div>
                    </form>
                )}
            </div>
            <br />
            <div>
                <button
                    style={{
                        marginRight: '5px',
                        backgroundColor: '#263640',
                        border: '1px solid #263640',
                        color: '#fff',
                    }}
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                        const dateToSend = activeButton === 'noExpiration' ? '2200-01-01T00:00:00.000Z' : expirationDate.toISOString();
                        modal.saveForm(true, '', '', dateToSend, reminderDays);
                    }}
                >
                    {findlanguage().accept || 'Accept'}
                </button>
            </div>
        </ModalWrapper>
    );
}
