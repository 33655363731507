import { DELETE, GET, POST, PUT } from './api'

export const CreateNewTeamMember = async (body) => {
    return await POST(`/team-access`, body, true)
}

export const FetchTeamMembers = async () => {
    return await GET(`/team-access`, true)
}

export const BlockTeamMembers = async (id) => {
    return await PUT(`/team-access/block-access/${id}`, {}, true)
}

export const DeleteTeamMembers = async (id) => {
    return await DELETE(`/team-accesses/${id}`, true)
}

export const ResendInvite = async (id) => {
    return await PUT(`/team-access/invite/${id}`, {}, true)
}

export const FetchTeamUser = async (id) => {
    return await GET(`/team-access/${id}`, true)
}

export const UpdateTeamUser = async (id, body) => {
    return await PUT(`/team-access/${id}`, body, true)
}
