import styled from 'styled-components'

export const Content = styled.div.attrs({})`
    height: 458px;
    margin: 0;
    padding: 0;
    background: linear-gradient(180deg, #e0e9ed 58.33%, rgba(224, 233, 237, 0) 100%);
    padding-top: 30px;
`

export const PageTitle = styled.div.attrs({})`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 130%;

    text-align: center;
    letter-spacing: -0.01em;

    color: #3d5061;
`

export const PageSubTitle = styled.div.attrs({})`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;

    text-align: center;

    color: #3d5061;

    opacity: 0.84;
`

export const PageCard = styled.div.attrs({})`
    width: 994px;
    height: 505px;
    margin: 0 auto;
    margin-top: 30px;

    background: #ffffff;

    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    overflow: hidden;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-items: center;
    gap: 40px;
`

export const PageCardChecks = styled.div.attrs({})`
    width: 994px;
    height: 48px;
    margin: 0 auto;
    margin-top: 30px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    justify-content: center;
`

export const PageCardCheckText = styled.div.attrs({})`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #171923;
    width: 220px;
`

export const PageCardCheckIco = styled.img.attrs((props) => ({
    src: `/icons/check-balloon.png`,
}))`
    margin-left: 20px;
`

export const PageCardLeft = styled.div.attrs({})`
    width: 378px;
    height: 505px;
    background: #f0eafb;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-items: center;
    justify-content: center;
`

export const PageCardLeftGroup = styled.div.attrs({})`
    width: 300px;
    height: 200px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-items: center;
    justify-content: center;
`

export const PageCardLeftTitle = styled.div.attrs({})`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #171923;
`

export const PageCardLeftSubtitleGroup = styled.div.attrs({})`
    width: 250px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    align-items: flex-start;
    align-items: center;
    justify-content: center;
`

export const PageCardLeftSubtitle = styled.div.attrs({})`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #171923;
`
export const PageCardLeftSubtitleDash = styled.div.attrs({})`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    text-decoration-line: line-through;
    color: #171923;
`

export const PageCardLeftPrice = styled.div.attrs({})`
    width: 74px;
    height: 60px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #171923;
`

export const PageCardLeftButton = styled.div.attrs({})`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 10px;
    gap: 10px;

    margin-top: 20px;

    width: 298px;
    height: 43px;

    background: #6c8fa4;
    border-radius: 4px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    text-align: center;
    letter-spacing: 0.01em;

    color: #ffffff;
`

export const PageCardRight = styled.div.attrs({})`
    width: 532px;
    height: 400px;
`

export const PageCardRightTitle = styled.div.attrs({})`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #2d3748;
`

export const PageCardRightGroup = styled.div.attrs({})`
    width: 532px;

    display: flex;
    align-items: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 30px;
`

export const PageCardRightBullet = styled.img.attrs((props) => ({
    src: `/icons/elipse.png`,
}))`
    margin-right: 10px;
`

export const PageCardRightItem = styled.div.attrs({})`
    width: 532px;
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    align-items: flex-start;
`

export const PageCardRightItemText = styled.div.attrs({})`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #2d3748;
`
