import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import Card from '../Card/index.js'

import { PageCard, CardHeader, CardHeaderTitleContainer } from './styled'
import _ from 'lodash'

export default function SelfAssessmentChecklist({
    certifications,
    onChangeCertifications,
    saveCertifications,
    saveDocument,
    form,
    returnHeaders,
    mfsQuestionarie
}) {
    const { findlanguageEsg } = useContext(CoreContext) 

    //#region J.2.1
    const j21 = {
        ref: 'J.2.1',
        requirementTitle: findlanguageEsg().j21RequirementTitle,
        inputForYesAnswer: true,
        placeHolderTextYes: findlanguageEsg().j21PlaceHolderTextYes,
        inputForNoAnswer: false,
        placeHolderTextNo: findlanguageEsg().j21PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    //#region J.2.1 Yes

    //#region J.2.1.Y.A

    const j21ya = {
        ref: 'J.2.1.Y.A',
        requirementTitle: findlanguageEsg().j21yaRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j21yaPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const j21yay = {
        ref: 'J.2.1.Y.A.Y',
        requirementTitle: findlanguageEsg().j21yayRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j21yayPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const j21yayy = {
        ref: 'J.2.1.Y.A.Y.Y',
        requirementTitle: findlanguageEsg().j21yayyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j21yayyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
    }

    const j21yayyql1 = {
        ref: 'J.2.1.Y.A.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().j21yayyqlRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j21yayyqlPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        fileForYesAnswerAndNoInput: true,
        color: '#2f8c5d',
        question: true,
    }

    //#endregion

    //#region J.2.1.Y.B

    const j21yb = {
        ref: 'J.2.1.Y.B',
        requirementTitle: findlanguageEsg().j21ybRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j21ybPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const j21yby = {
        ref: 'J.2.1.Y.B.Y',
        requirementTitle: findlanguageEsg().j21ybyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: findlanguageEsg().j21ybyPlaceHolderTextYes,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j21ybyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const j21ybyyqt1 = {
        ref: 'J.2.1.Y.B.Y.Y.QT.1',
        requirementTitle: findlanguageEsg().j21ybyyqt1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const j21ybyyqt2 = {
        ref: 'J.2.1.Y.B.Y.Y.QT.2',
        requirementTitle: findlanguageEsg().j21ybyyqt2RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }


    const j21ybyyqtn = {
        ref: 'J.2.1.Y.B.Y.Y.QT.N',
        requirementTitle: findlanguageEsg().j21ybyyqtnRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const j21ybyyql1 = {
        ref: 'J.2.1.Y.B.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().j21ybyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j21ybyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.C

    const j21yc = {
        ref: 'J.2.1.Y.C',
        requirementTitle: findlanguageEsg().j21ycRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j21ycPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const j21ycy = {
        ref: 'J.2.1.Y.C.Y',
        requirementTitle: findlanguageEsg().j21ycyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j21ycyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const j21ycyy = {
        ref: 'J.2.1.Y.C.Y.Y',
        requirementTitle: findlanguageEsg().j21ycyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j21ycyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const j21ycyyql1 = {
        ref: 'J.2.1.Y.C.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().j21ycyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j21ycyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.D

    const j21yd = {
        ref: 'J.2.1.Y.D',
        requirementTitle: findlanguageEsg().j21ydRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j21ydPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const j21ydy = {
        ref: 'J.2.1.Y.D.Y',
        requirementTitle: findlanguageEsg().j21ydyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j21ydyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const j21ydyy = {
        ref: 'J.2.1.Y.D.Y.Y',
        requirementTitle: findlanguageEsg().j21ydyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j21ydyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const j21ydyyql1 = {
        ref: 'J.2.1.Y.D.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().j21ydyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j21ydyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#endregion

    //#region A.1.1 NO
    const j21na = {
        ref: 'J.2.1.N.A',
        requirementTitle: findlanguageEsg().j21naRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j21naPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const j21nb = {
        ref: 'J.2.1.N.B',
        requirementTitle: findlanguageEsg().j21nbRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j21nbPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const j21nc = {
        ref: 'J.2.1.N.C',
        requirementTitle: findlanguageEsg().j21ncRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j21ncPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    const returnCondition = (item, layers, category1, category2, category3, category4) => {
        switch (layers) {
            case 0:
                return (
                    <Card
                        item={item}
                        onChangeCertifications={onChangeCertifications}
                        saveCertifications={saveCertifications}
                        saveDocument={saveDocument}
                        certifications={certifications}
                        form={form}
                        mfsQuestionarie={mfsQuestionarie}
                    />
                )
                break
            case 1:
                if (certifications?.find((x) => x.category === category1)?.next_level === true)
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 2:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 3:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 4:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true &&
                    certifications?.find((x) => x.category === category4)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            default:
                break
        }
    }

    return (
        <>
            <PageCard>
                <CardHeader>
                    <CardHeaderTitleContainer>
                        {findlanguageEsg().j21CardHeaderTitleContainer}
                    </CardHeaderTitleContainer>
                </CardHeader>
                {returnHeaders()}
                {returnCondition(j21, 0)}
            </PageCard>
            {
                mfsQuestionarie !== '1' ?
                    <>

                        {certifications?.find((x) => x.category === 'J.2.1')?.next_level === true ? (
                            <>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().j21aCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(j21ya, 1, 'J.2.1')}
                                    {returnCondition(j21yay, 2, 'J.2.1', 'J.2.1.Y.A')}
                                    {returnCondition(j21yayy, 3, 'J.2.1', 'J.2.1.Y.A', 'J.2.1.Y.A.Y')}
                                    {returnCondition(
                                        j21yayyql1,
                                        4,
                                        'J.2.1',
                                        'J.2.1.Y.A',
                                        'J.2.1.Y.A.Y',
                                        'J.2.1.Y.A.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().j21bCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(j21yb, 1, 'J.2.1')}
                                    {returnCondition(j21yby, 2, 'J.2.1', 'J.2.1.Y.B')}
                                    {returnCondition(j21ybyyqt1, 3, 'J.2.1', 'J.2.1.Y.B', 'J.2.1.Y.B.Y')}
                                    {returnCondition(j21ybyyqt2, 3, 'J.2.1', 'J.2.1.Y.B', 'J.2.1.Y.B.Y')}
                                    {returnCondition(j21ybyyqtn, 3, 'J.2.1', 'J.2.1.Y.B', 'J.2.1.Y.B.Y')}
                                    {returnCondition(j21ybyyql1, 3, 'J.2.1', 'J.2.1.Y.B', 'J.2.1.Y.B.Y')}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().j21cCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(j21yc, 1, 'J.2.1')}
                                    {returnCondition(j21ycy, 2, 'J.2.1', 'J.2.1.Y.C')}
                                    {returnCondition(j21ycyy, 3, 'J.2.1', 'J.2.1.Y.C', 'J.2.1.Y.C.Y')}
                                    {returnCondition(
                                        j21ycyyql1,
                                        4,
                                        'J.2.1',
                                        'J.2.1.Y.C',
                                        'J.2.1.Y.C.Y',
                                        'J.2.1.Y.C.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().j21dCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(j21yd, 1, 'J.2.1')}
                                    {returnCondition(j21ydy, 2, 'J.2.1', 'J.2.1.Y.D')}
                                    {returnCondition(j21ydyy, 3, 'J.2.1', 'J.2.1.Y.D', 'J.2.1.Y.D.Y')}
                                    {returnCondition(
                                        j21ydyyql1,
                                        4,
                                        'J.2.1',
                                        'J.2.1.Y.D',
                                        'J.2.1.Y.D.Y',
                                        'J.2.1.Y.D.Y.Y'
                                    )}
                                </PageCard>
                            </>
                        ) : null}
                        {certifications?.find((x) => x.category === 'J.2.1')?.next_level === false ? (
                            <PageCard>
                                <CardHeader>
                                    <CardHeaderTitleContainer>
                                        {findlanguageEsg().j21noCardHeaderTitleContainer}
                                    </CardHeaderTitleContainer>
                                </CardHeader>
                                {returnHeaders()}
                                {returnCondition(j21na, 0)}
                                {returnCondition(j21nb, 0)}
                                {returnCondition(j21nc, 0)}
                            </PageCard>
                        ) : null}
                    </>
                    :
                    null
            }
        </>
    )
}
