import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'components/Dashboard/Button'

import { toast } from 'react-toastify'
import {
    PageCard,
    PageTitle,
    PageSubTitle,
    Content,
    FeaturesPanel,
    FeatureTitle,
    FeatureDescription,
    PlansBody,
    PlansAction,
    PlansBodyCentered,
    SuccessIcon,
    AttachItem,
    AttachItemLink,
    UploadHiddenInput,
    Link,
    WarningIcon,
    LogPanel,
    ResultItem,
    LogPanelTitle,
} from './styled'

import { Mailchimploader, Usersfileloader, Companiesfileloader } from 'services/admin'

import ContainerAuthenticated from 'containers/Authenticated'
import { Container, Row, Col } from 'reactstrap'

import { CoreContext } from 'context/CoreContext'

export default function DashboardAdmin() {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)
    const { user, profile, userPanels, setUserPanels, setModal, premium } = useContext(CoreContext)

    const [fileSelected, setFileSelected] = useState('')

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [loadingB, setLoadingB] = useState(false)
    const [successB, setSuccessB] = useState(false)
    const [errorB, setErrorB] = useState(false)

    const [loadingC, setLoadingC] = useState(false)
    const [successC, setSuccessC] = useState(false)
    const [errorC, setErrorC] = useState(false)

    const [resultFile, setResultFile] = useState([])

    const mailchimpLoad = async () => {
        setLoading(true)
        setSuccess(false)
        try {
            const result = await Mailchimploader().then((data) => {
                setLoading(false)
                setSuccess(true)
                toast.success(`Users data has been uploaded to Mailchimp successfully`)
            })
        } catch (error) {
            toast.error(`Error on processing mailchimp loader`)
            console.log(`Error on processing mailchimp loader`, error)
            setSuccess(false)
            setLoading(false)
        }
    }

    const attach = (action) => {
        document.getElementById('upload-input-hidden')?.click()
    }

    const showFileName = () => {
        const file = document.getElementById('upload-input-hidden')?.files?.[0]
        setFileSelected(file)
    }

    const usersload = async () => {
        setLoadingC(true)
        setSuccessC(false)
        setErrorC(false)
        try {
            if (fileSelected === undefined || fileSelected === '') {
                toast.error('Fill the field: Attachment')
                setLoadingC(false)
                setSuccessC(false)
                return
            }

            const formData = new FormData()
            formData.append('fileCsv', document.getElementById('upload-input-hidden')?.files?.[0])

            const result = await Usersfileloader(formData).then((data) => {
                setLoadingC(false)

                setResultFile(data)

                document.getElementById('upload-input-hidden').value = ''
                setFileSelected('')

                if (data.errors > 0 && data.successful_lines === 0) {
                    setErrorC(true)
                    toast.error(`Errors on loading new users`)
                } else if (data.errors > 0 && data.successful_lines > 0) {
                    setErrorC(true)
                    toast.warning(
                        `Errors on loading new users but ${data.successful_lines} were loaded`
                    )
                } else if (data.successful_lines > 0) {
                    setSuccessC(true)
                    toast.success(`New users have been uploaded successfully`)
                } else if (data.errors === 0 && data.successful_lines === 0) {
                    setErrorC(true)
                    toast.error(`Nothing was processed`)
                } else {
                    setErrorC(true)
                    toast.error(`Unable to load this file`)
                }
            })
        } catch (error) {
            toast.error(`Error on processing users loader`)
            console.log(`Error on processing users loader`, error)
            setSuccessC(false)
            setLoadingC(false)
        }
    }

    const companiesload = async () => {
        setLoadingB(true)
        setSuccessB(false)
        setErrorB(false)
        try {
            if (fileSelected === undefined || fileSelected === '') {
                toast.error('Fill the field: Attachment')
                setLoadingB(false)
                setSuccessB(false)
                return
            }

            const formData = new FormData()
            formData.append('fileCsv', document.getElementById('upload-input-hidden')?.files?.[0])

            const result = await Companiesfileloader(formData).then((data) => {
                setLoadingB(false)

                setResultFile(data)

                document.getElementById('upload-input-hidden').value = ''
                setFileSelected('')

                if (data.errors > 0 && data.successful_lines === 0) {
                    setErrorB(true)

                    toast.error(`Errors on loading new users`)
                } else if (data.errors > 0 && data.successful_lines > 0) {
                    setErrorB(true)
                    toast.warning(
                        `Errors on loading new companies but ${data.successful_lines} were loaded`
                    )
                } else if (data.successful_lines > 0) {
                    setSuccessB(true)
                    toast.success(`New companies have been uploaded successfully`)
                } else if (data.errors === 0 && data.successful_lines === 0) {
                    setErrorB(true)
                    toast.error(`Errors on loading new users. Verify the file.`)
                } else {
                    setErrorB(true)
                    toast.error(`Unable to load this file`)
                }
            })
        } catch (error) {
            toast.error(`Error on processing companies loader`)
            console.log(`Error on processing companies loader`, error)
            setSuccessB(false)
            setLoadingB(false)
        }
    }

    useEffect(() => {
        if (user.company_id !== 1)
            navigate(`dashboard`)
    }, []);

    return (
        <>
            {
                user.company_id === 1 ?
                    <ContainerAuthenticated full={true}>
                        <Container fluid={true} style={{ margin: 0, padding: 0 }}>
                            <Row style={{ margin: 0, padding: 0 }}>
                                <Col style={{ margin: 0, padding: 0 }}>
                                    <PageTitle>Administration</PageTitle>
                                    <PageSubTitle></PageSubTitle>

                                    <Content style={{ margin: 0, borderTop: '1px solid #dddddd' }}>
                                        <PageCard>
                                            <PlansBody>
                                                <PlansBodyCentered>
                                                    <FeaturesPanel>
                                                        <FeatureTitle>Support Cases</FeatureTitle>
                                                        <FeatureDescription>
                                                            Support Cases Administration
                                                        </FeatureDescription>
                                                    </FeaturesPanel>
                                                </PlansBodyCentered>

                                                <PlansAction>
                                                    <Button
                                                        loading={loading}
                                                        onClick={() => navigate('admin/support-cases')}
                                                    >
                                                        Open
                                                    </Button>
                                                    {success ? <SuccessIcon /> : ''}
                                                </PlansAction>

                                                <PlansBodyCentered>
                                                    <FeaturesPanel>
                                                        <FeatureTitle>Mailchimp loader</FeatureTitle>
                                                        <FeatureDescription>
                                                            Admin feature to load mailchimp database.
                                                        </FeatureDescription>
                                                    </FeaturesPanel>
                                                </PlansBodyCentered>

                                                <PlansAction>
                                                    <Button loading={loading} onClick={mailchimpLoad}>
                                                        Start
                                                    </Button>
                                                    {success ? <SuccessIcon /> : ''}
                                                </PlansAction>

                                                <PlansBodyCentered>
                                                    <FeaturesPanel>
                                                        <FeatureTitle>Companies file loader</FeatureTitle>
                                                        <FeatureDescription>
                                                            Admin feature to upload new companies (buyer,
                                                            supplier, service provider) using a CSV file
                                                        </FeatureDescription>
                                                    </FeaturesPanel>
                                                </PlansBodyCentered>

                                                <PlansAction>
                                                    <Button loading={loadingB} onClick={companiesload}>
                                                        Start
                                                    </Button>
                                                    {successB ? <SuccessIcon /> : ''}
                                                    {errorB ? <WarningIcon /> : ''}
                                                </PlansAction>

                                                <PlansBodyCentered>
                                                    <FeaturesPanel>
                                                        <FeatureTitle>
                                                            My Users (Team) file loader
                                                        </FeatureTitle>
                                                        <FeatureDescription>
                                                            Admin feature to upload new users to a company's
                                                            team using a CSV file
                                                        </FeatureDescription>
                                                    </FeaturesPanel>
                                                </PlansBodyCentered>

                                                <PlansAction>
                                                    <Button loading={loadingC} onClick={usersload}>
                                                        Start
                                                    </Button>
                                                    {successC ? <SuccessIcon /> : ''}
                                                    {errorC ? <WarningIcon /> : ''}
                                                </PlansAction>

                                                <PlansAction>
                                                    <Button dark small onClick={attach}>
                                                        Choose file
                                                    </Button>

                                                    <AttachItem>
                                                        <AttachItemLink>
                                                            {fileSelected ? (
                                                                <Link
                                                                    target="_blank"
                                                                    rel="noreferrer noopener"
                                                                    download={fileSelected.name}
                                                                >
                                                                    {fileSelected.name}
                                                                </Link>
                                                            ) : (
                                                                'No file selected'
                                                            )}
                                                        </AttachItemLink>
                                                    </AttachItem>

                                                    <UploadHiddenInput onChange={showFileName} />
                                                </PlansAction>

                                                <LogPanel>
                                                    <LogPanelTitle>Execution Log</LogPanelTitle>

                                                    {resultFile.result_list?.map((item, key) =>
                                                        item.data ? (
                                                            <ResultItem>{item.data.error}</ResultItem>
                                                        ) : (
                                                            ''
                                                        )
                                                    )}
                                                </LogPanel>
                                            </PlansBody>
                                        </PageCard>
                                    </Content>
                                </Col>
                            </Row>
                        </Container>
                    </ContainerAuthenticated>
                :
                null
            }

        </>
    )
}
