import styled from 'styled-components'

export const InfoIconStyled = styled.img.attrs(
    ({ tooltipContent, tooltipPlacement, tooltipautoHide, tooltipTrigger, ...props }) => ({
        ...props,
        src: '/icons/circle-info.svg',
    })
)`
    width: 16px;
    height: 16px;
`
