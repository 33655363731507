import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const TeamSuppliersChart = ({ currentItems, companyUsers }) => {
    const chartData = useMemo(() => {
        const userSupplierMap = currentItems.reduce((acc, item) => {
            const userId = item.user_who_owns || 0;
            const user = companyUsers.find(user => user.id === userId)?.label || 'Companies with no owner';
            const supplier = item.supplier;

            if (!acc[user]) {
                acc[user] = {
                    count: 0,
                    suppliers: []
                };
            }
            acc[user].count += 1;
            acc[user].suppliers.push(supplier);

            return acc;
        }, {});

        const labels = Object.keys(userSupplierMap);
        const counts = labels.map(label => userSupplierMap[label].count);
        const tooltips = labels.map(label => userSupplierMap[label].suppliers.join(', '));

        return {
            labels,
            datasets: [{
                label: 'Number of Suppliers',
                data: counts,
                backgroundColor: 'rgba(54, 162, 235, 0.6)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1
            }],
            tooltips
        };
    }, [currentItems, companyUsers]);

    const options = {
        indexAxis: 'y',
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.label}: ${tooltipItem.raw}`;
                    }
                }
            },
            datalabels: {
                display: false,
            }
        },
        scales: {
            x: {
                beginAtZero: true,
                grid: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: false
                }
            }
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 10,
                bottom: 30
            }
        },
        elements: {
            bar: {
                borderWidth: 1,
                borderRadius: 4
            }
        }
    };

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <h2>Team x Suppliers</h2>
            <Bar data={chartData} options={options} plugins={[ChartDataLabels]} />
        </div>
    );
};

export default TeamSuppliersChart;
