import React, { useState, useRef, useEffect, useContext } from 'react'

import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { ReadCountries, ReadStates, ReadCities } from 'services/location'

import { toast } from 'react-toastify'

import {
    BodyContent,
    FormContent,
    RequiredText,
    InputBlankContainer,
    InputSpace,
    CheckContainer,
} from './styled'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap'

import Input from 'components/Form/Input'
import InputPassword from 'components/Form/InputPassword'
import Check from 'components/Form/Check'
import { CoreContext } from 'context/CoreContext'

import RegisterFooter from 'components/Register/Footer'

export default function RegisterSupplierStep1({
    formValue,
    changeForm,
    next,
    registerInfos,
    form,
    setForm,
}) {
    const { findlanguage, language } = useContext(CoreContext)
    const autoCompleteRef = useRef()
    const inputRef = useRef()
    const options = {
        fields: ['address_components', 'geometry', 'icon', 'name'],
        types: ['address'],
    }
    const [fieldValidation, setFieldValidation] = useState([])
    const [stateTooltipPhone, setStateTooltipPhone] = useState(false)
    const [stateTooltipPassword, setStateTooltipPassword] = useState(false)

    const [init, setInit] = useState(false)

    const [name, setName] = useState('')
    const [contactName, setContactName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [street, setStreet] = useState('')
    const [country, setCountry] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [website, setWebSite] = useState('')

    const [customAddress, setCustomAddress] = useState(false)

    const [countryAC, setCountryAC] = useState('')
    const [stateAC, setStateAC] = useState('')
    const [cityAC, setCityAC] = useState('')

    const [countrySearch, setCountrySearch] = useState('')
    const [stateSearch, setStateSearch] = useState('')
    const [citySearch, setCitySearch] = useState('')

    const [loading, setLoading] = useState(false)

    // *********************************************************

    const clearCountry = () => {
        setCountry('')
        setState('')
        setCity('')
        setCountryAC('')
        setStateAC('')
        setCityAC('')
        setStateSearch('  ')
        setCitySearch('  ')
    }

    const clearState = () => {
        setCity('')
        setCityAC('')
        setCitySearch('  ')
    }

    const handleOnSelectCountry = (item) => {
        setCountry(item.name)
        setCountryAC(item.name)
        changeCountry(item)
        clearState()
    }

    const handleOnSelectState = (item) => {
        setState(item.name)
        setStateAC(item.name)
        changeState(item)
        setCitySearch('  ')
        setCity('')
        setCityAC('')
    }

    const handleOnSelectCity = (item) => {
        setCity(item.name)
        setCityAC(item.name)
    }

    const formatResult = (item) => {
        return (
            <>
                <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])

    useEffect(() => {
        ReadCountries().then((r) =>
            setCountryList(
                language === 'portugues'
                    ? r
                          .sort((a, b) => (a.name_pt > b.name_pt) - (a.name_pt < b.name_pt))
                          .map((item) => ({
                              name: item.name_pt,
                              title: item.name_pt,
                              iso2: item.iso2,
                          }))
                    : r
                          .sort((a, b) => (a.name > b.name) - (a.name < b.name))
                          .map((item) => ({
                              name: item.name,
                              title: item.name,
                              iso2: item.iso2,
                          }))
            )
        )
    }, [])

    const changeCountry = async (item) => {
        setLoading(true)
        setStateList([])
        setCityList([])

        setState('')
        setCity('')

        let states = []

        let result = await ReadStates(item.iso2)

        if (result.length === 0) {
            result = [{ id: 0, name: item.name, iso2: item.iso2 }]
        }

        result.forEach((x) => {
            states.push({
                ...x,
                country: item.iso2,
            })
        })

        if (states.length !== 0) {
            const _state = states.sort((a, b) => a.name > b.name)
            setStateList(_state)
        }

        setLoading(false)
    }

    const changeState = async (item) => {
        setLoading(true)

        setCityList([])

        setCity('')

        let result = await ReadCities(item.country, item.iso2)

        if (result.length === 0) {
            result = [{ id: 0, name: item.name, iso2: item.iso2 }]
        }

        setCityList(result)

        setLoading(false)
    }

    // *********************************************************

    // Init Loading
    useEffect(() => {
        if (!init) {
            setInit(true)
            const _form = localStorage.getItem('form')
            if (_form !== undefined && _form !== null) {
                localStorage.removeItem('form')
                const newform = JSON.parse(_form)
                if (newform !== undefined) {
                    setName(newform.name)
                    setContactName(newform.contactName)
                    setEmail(newform.email)
                    setPhone(newform.phone)
                    setPassword(newform.password)
                    setConfirmPassword(newform.password)
                    setStreet(newform.street)
                    setCountry(newform.country)
                    setState(newform.state)
                    setCity(newform.city)
                    setZipCode(newform.zipCode)
                    setLatitude(newform.latitude)
                    setLongitude(newform.longitude)
                    setWebSite(newform.website)
                }
            }
        }
    }, [init])

    const buildForm = () => {
        setForm({
            ...form,
            name: name,
            contactName: contactName,
            email: email,
            phone: phone,
            password: password,
            street: street,
            country: customAddress ? countryAC : country,
            state: customAddress ? stateAC : state,
            city: customAddress ? cityAC : city,
            zipCode: zipCode,
            latitude: latitude,
            longitude: longitude,
            website: website,
        })
    }

    useEffect(() => {
        buildForm()
    }, [name, contactName, email, phone, password, street, zipCode, latitude, longitude, website])

    useEffect(() => {
        if (!customAddress) {
            autoCompleteRef.current = new window.google.maps.places.Autocomplete(
                inputRef.current,
                options
            )

            autoCompleteRef.current.addListener('place_changed', async function () {
                const place = await autoCompleteRef.current.getPlace()
                const _place = readPlace(place)

                setStreet(_place.name)
                setCountry(_place.country)
                setState(_place.state)
                setCity(_place.city)
                setZipCode(_place.zip_code)
                setLatitude(_place.latitude)
                setLongitude(_place.longitude)

                return true
            })
        } else {
            autoCompleteRef.current.addListener('place_changed', async function () {
                return true
            })
        }
    }, [customAddress])

    const showHighlight = (name) => {
        const field = fieldValidation.find((item) => item.field === name)
        if (field !== undefined) return true
        return false
    }

    const showWarningAddress = (name) => {
        if (!customAddress) {
            toast.warning(`Field ${name} is read only. Fill Address Field.`)
            return false
        }
    }

    const handleAddress = (value) => {
        if (!customAddress) {
            setCountry('')
            setState('')
            setCity('')
            setZipCode('')
            setLatitude('')
            setLongitude('')
        }
        setStreet(value)
    }

    const valid = () => {
        setFieldValidation([])
        let errors = []

        if (name.length === 0) {
            errors.push({
                field: 'name',
                message: findlanguage().fillTheField + findlanguage().companyName,
            })
        } else if (name.length < 2) {
            errors.push({
                field: 'name',
                message: findlanguage().errorCompanyName,
            })
        }

        if (contactName.length === 0) {
            errors.push({
                field: 'contactName',
                message: findlanguage().fillTheField + findlanguage().name,
            })
        } else if (contactName.length < 2) {
            errors.push({
                field: 'contactName',
                message: findlanguage().errorName,
            })
        }

        if (email.length === 0) {
            errors.push({
                field: 'email',
                message: findlanguage().fillTheField + findlanguage().email,
            })
        } else if (
            !email.match(
                /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
            )
        ) {
            errors.push({ field: 'email', message: findlanguage().errorEmail })
        }

        if (phone.length === 0) {
            errors.push({
                field: 'phone',
                message: findlanguage().fillTheField + findlanguage().phone,
            })
        } else if (!/^\+(?:\d[-.\s]?){6,14}\d$/.test(phone)) {
            errors.push({
                field: 'phone',
                message: findlanguage().errorPhone,
            })
        }

        if (street?.length === 0) {
            errors.push({
                field: 'street',
                message: findlanguage().fillTheField + findlanguage().address,
            })
        }

        if (customAddress) {
            if (countryAC?.length === 0) {
                errors.push({
                    field: 'country',
                    message: findlanguage().fillTheField + findlanguage().country,
                })
            }

            if (stateAC?.length === 0) {
                errors.push({
                    field: 'state',
                    message: findlanguage().fillTheField + findlanguage().state,
                })
            }
        } else {
            if (country?.length === 0) {
                errors.push({
                    field: 'country',
                    message: findlanguage().fillTheField + findlanguage().country,
                })
            }

            if (state?.length === 0) {
                errors.push({
                    field: 'state',
                    message: findlanguage().fillTheField + findlanguage().state,
                })
            }
        }

        if (website.length > 0) {
            if (
                !/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%.+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/.test(
                    website
                )
            ) {
                errors.push({
                    field: 'website',
                    message: findlanguage().errorWebSite,
                })
            }
        }

        if (errors.length > 0) {
            let message = ''
            message = findlanguage().someErrorsFound
            errors.forEach((item) => {
                message += '\n - ' + item.message
            })
            toast.error(message)
            setFieldValidation(errors)
            return false
        }

        return true
    }

    const action = async () => {
        buildForm()

        if (!valid()) {
            return
        }
        if (next && typeof next === 'function') {
            next()
        }
    }

    const togglePhone = (_state) => {
        if (typeof _state !== 'boolean') return
        setStateTooltipPhone(_state)
    }

    const togglePassword = (_state) => {
        if (typeof _state !== 'boolean') return
        setStateTooltipPassword(_state)
    }

    //

    const handleInputPhone = (event) => {
        changeForm(event.target.value, event.target.name)
        togglePhone(false)
    }

    //

    const handleInputPassword = (event) => {
        changeForm(event.target.value, event.target.name)
        togglePassword(false)
    }

    const readPlace = (place) => {
        const _address = place.address_components
        let _lat = 0
        let _lng = 0

        try {
            _lat = place.geometry.location?.lat()
            _lng = place.geometry.location?.lng()
        } catch (error) {
            _lat = -1
            _lng = -1
        }

        let dataAddress = {
            country: '',
            state: 'Not Found',
            city: '',
            street: '',
            zip_code: '',
            longitude: '',
            latitude: '',
            name: '',
        }

        if (place.name.length > 0 && place.name !== undefined) dataAddress.name = place.name

        dataAddress.latitude = _lat
        dataAddress.longitude = _lng

        _address.forEach((element) => {
            switch (element.types[0].toString().toLowerCase()) {
                case 'route':
                    dataAddress.street = element.short_name.toString()
                    break
                case 'administrative_area_level_1':
                    dataAddress.state = element.long_name.toString()
                    break
                case 'country':
                    dataAddress.country = element.long_name.toString()
                    break
                case 'administrative_area_level_2':
                    dataAddress.city = element.long_name.toString()
                    break
                case 'postal_code':
                    dataAddress.zip_code = element.long_name.toString()
                    break
                default:
                    break
            }
        })

        return dataAddress
    }

    //

    return (
        <>
            <BodyContent>
                <FormContent>
                    <Input
                        placeholder={findlanguage().companyName}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type={'text'}
                        highlight={showHighlight('name')}
                        autoComplete={'xoff'}
                        small
                    />
                    <Input
                        placeholder={findlanguage().contactName}
                        value={contactName}
                        onChange={(e) => setContactName(e.target.value)}
                        type={'text'}
                        highlight={showHighlight('contactName')}
                        autoComplete={'xoff'}
                        small
                    />
                    <Input
                        placeholder={findlanguage().email}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type={'text'}
                        highlight={showHighlight('email')}
                        autoComplete={'xoff'}
                        small
                    />

                    <Input
                        placeholder={findlanguage().phoneNumber}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        onBlur={handleInputPhone}
                        onFocus={(e) => togglePhone(true)}
                        id="phone1"
                        type={'tel'}
                        autoComplete={'xoff'}
                        highlight={showHighlight('phone')}
                        small
                    />
                    <Popover
                        placement="top"
                        isOpen={stateTooltipPhone}
                        toggle={togglePhone}
                        target={'phone1'}
                    >
                        <PopoverHeader>{findlanguage().rulesPhoneNumberHeader}</PopoverHeader>
                        <PopoverBody>{findlanguage().rulesPhoneNumberText}</PopoverBody>
                    </Popover>

                    <InputPassword
                        placeholder={findlanguage().password}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        id="password"
                        onFocus={(e) => togglePassword(true)}
                        onBlur={handleInputPassword}
                        highlight={showHighlight('password')}
                        autoComplete={'xoff'}
                        small
                    />
                    <Popover
                        placement="top"
                        isOpen={stateTooltipPassword}
                        toggle={togglePassword}
                        target={'password'}
                    >
                        <PopoverHeader>{findlanguage().rulesPasswordHeader}</PopoverHeader>
                        <PopoverBody>{findlanguage().rulesPasswordText}</PopoverBody>
                    </Popover>

                    <div style={{ width: '303px' }}>
                        <Input
                            type={'password'}
                            placeholder={findlanguage().confirmPassword}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            highlight={showHighlight('confirmPassword')}
                            autoComplete={'new-password'}
                            small
                        />
                        <p></p>
                    </div>
                </FormContent>

                <FormContent>
                    <Input
                        placeholder={findlanguage().address}
                        value={street}
                        onChange={(e) => handleAddress(e.target.value)}
                        xref={inputRef}
                        xautoComplete={'xoff'}
                        highlight={showHighlight('street')}
                        small
                    />

                    <CheckContainer>
                        <Check
                            value={customAddress}
                            onChange={(e) => setCustomAddress(e)}
                            label={findlanguage().autoFill}
                            checked={customAddress}
                        />
                    </CheckContainer>

                    {customAddress ? (
                        <>
                            <div style={{ width: 300, marginBottom: 20 }}>
                                <label
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '15px',
                                        color: showHighlight('country') ? 'red' : '#000',
                                    }}
                                >
                                    {findlanguage().country}
                                </label>
                                <ReactSearchAutocomplete
                                    items={countryList}
                                    showNoResultsText={findlanguage().noResultsFilter}
                                    formatResult={formatResult}
                                    onSelect={handleOnSelectCountry}
                                    onSearch={clearCountry}
                                    onClear={clearCountry}
                                    onFocus={() => {
                                        setCountrySearch(countrySearch.trim())
                                    }}
                                    placeholder={findlanguage().placeholderFilter}
                                    inputSearchString={countrySearch}
                                    showIcon={true}
                                    showItemsOnFocus={true}
                                    maxResults={4}
                                    fuseOptions={{
                                        includeScore: true,
                                        includeMatches: true,
                                        threshold: 0.2,
                                        location: 0,
                                        distance: 100,
                                        maxPatternLength: 32,
                                        minMatchCharLength: 1,
                                        keys: ['name'],
                                    }}
                                    styling={{
                                        height: '35px',
                                        borderRadius: '0px',
                                        backgroundColor: '#FFF',
                                        boxShadow: 'none',
                                        hoverBackgroundColor: '#EAEAEA',
                                        color: '#263640',
                                        fontSize: '15px',
                                        iconColor: '#263640',
                                        lineColor: '#EAEAEA',
                                        placeholderColor: '#cccccc',
                                        clearIconMargin: '3px 8px 0 0',
                                        zIndex: 11,
                                    }}
                                />
                            </div>
                        </>
                    ) : (
                        <Input
                            placeholder={findlanguage().country}
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            onFocus={(e) => showWarningAddress('Country')}
                            small
                            highlight={showHighlight('country')}
                            readOnly={!customAddress}
                            autoComplete={'xoff'}
                        />
                    )}

                    {customAddress ? (
                        <>
                            <div style={{ width: 300, marginBottom: 20 }}>
                                <label
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '15px',
                                        color: showHighlight('state') ? 'red' : '#000',
                                    }}
                                >
                                    {findlanguage().state}
                                </label>
                                <ReactSearchAutocomplete
                                    items={stateList}
                                    showNoResultsText={findlanguage().noResultsFilter}
                                    formatResult={formatResult}
                                    onSelect={handleOnSelectState}
                                    onSearch={clearState}
                                    onClear={clearState}
                                    onFocus={() => {
                                        setStateSearch(stateSearch.trim())
                                    }}
                                    placeholder={findlanguage().placeholderFilter}
                                    inputSearchString={stateSearch}
                                    showIcon={true}
                                    showItemsOnFocus={true}
                                    maxResults={4}
                                    fuseOptions={{
                                        includeScore: true,
                                        includeMatches: true,
                                        threshold: 0.2,
                                        location: 0,
                                        distance: 100,
                                        maxPatternLength: 32,
                                        minMatchCharLength: 1,
                                        keys: ['name'],
                                    }}
                                    styling={{
                                        height: '35px',
                                        borderRadius: '0px',
                                        backgroundColor: '#FFF',
                                        boxShadow: 'none',
                                        hoverBackgroundColor: '#EAEAEA',
                                        color: '#263640',
                                        fontSize: '15px',
                                        iconColor: '#263640',
                                        lineColor: '#EAEAEA',
                                        placeholderColor: '#cccccc',
                                        clearIconMargin: '3px 8px 0 0',
                                        zIndex: 10,
                                    }}
                                />
                            </div>
                        </>
                    ) : (
                        <Input
                            placeholder={findlanguage().state}
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            onFocus={(e) => showWarningAddress('State')}
                            small
                            autoComplete={'xoff'}
                            readOnly={!customAddress}
                            highlight={showHighlight('state')}
                        />
                    )}

                    {customAddress ? (
                        <>
                            <div style={{ width: 300 }}>
                                <label
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '15px',
                                        marginTop: 8,
                                        marginBottom: 5,
                                    }}
                                >
                                    {findlanguage().city}
                                </label>
                                <ReactSearchAutocomplete
                                    items={cityList}
                                    showNoResultsText={findlanguage().noResultsFilter}
                                    formatResult={formatResult}
                                    onSelect={handleOnSelectCity}
                                    onSearch={() => {
                                        setCity('')
                                    }}
                                    onFocus={() => {
                                        setCitySearch(citySearch.trim())
                                    }}
                                    placeholder={findlanguage().placeholderFilter}
                                    inputSearchString={citySearch}
                                    showIcon={true}
                                    showItemsOnFocus={true}
                                    maxResults={4}
                                    fuseOptions={{
                                        includeScore: true,
                                        includeMatches: true,
                                        threshold: 0.2,
                                        location: 0,
                                        distance: 100,
                                        maxPatternLength: 32,
                                        minMatchCharLength: 1,
                                        keys: ['name'],
                                    }}
                                    styling={{
                                        height: '35px',
                                        borderRadius: '0px',
                                        backgroundColor: '#FFF',
                                        boxShadow: 'none',
                                        hoverBackgroundColor: '#EAEAEA',
                                        color: '#263640',
                                        fontSize: '15px',
                                        iconColor: '#263640',
                                        lineColor: '#EAEAEA',
                                        placeholderColor: '#cccccc',
                                        clearIconMargin: '3px 8px 0 0',
                                        zIndex: 10,
                                    }}
                                />
                            </div>
                        </>
                    ) : (
                        <Input
                            placeholder={findlanguage().city}
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            onFocus={(e) => showWarningAddress('City')}
                            small
                            highlight={showHighlight('city')}
                            readOnly={!customAddress}
                            autoComplete={'xoff'}
                        />
                    )}

                    <Input
                        placeholder={findlanguage().zip}
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                        readOnly={!customAddress}
                        small
                        autoComplete={'xoff'}
                    />

                    <Input
                        placeholder={findlanguage().website}
                        value={website}
                        onChange={(e) => setWebSite(e.target.value)}
                        highlight={showHighlight('website')}
                        autoComplete={'xoff'}
                        small
                    />

                    <InputSpace></InputSpace>

                    <div style={{ width: '100%' }}>
                        <RequiredText>{findlanguage().requiredFields}</RequiredText>
                        <RegisterFooter loading={loading} action={action} />
                    </div>
                </FormContent>
            </BodyContent>
        </>
    )
}
