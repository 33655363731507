import React from 'react'

import {
    CardAddableUser,
    CardImage,
    CardContent,
    CardTitle,
    CardText,
    CardActions,
    CardAction,
    CardActionIcon,
} from './styled'

export default function CardUserAddable({ actions }) {
    return (
        <>
            <CardAddableUser>
                <CardImage />
                <CardContent>
                    <CardTitle>Carrefour</CardTitle>
                    <CardText>L.A. - USA</CardText>
                </CardContent>
                <CardActions>
                    {actions?.map((item, key) => (
                        <CardAction key={key}>
                            <CardActionIcon icon={item.icon} />
                        </CardAction>
                    ))}
                </CardActions>
            </CardAddableUser>
        </>
    )
}
