import styled from 'styled-components'

export const CardHeader = styled.div.attrs({})`
    padding: 5px 0;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--primary-color);
`

export const CardHeaderSubTitle = styled.div.attrs({})`
    padding: 5px 0;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: rgb(86, 86, 86);
`

export const Paragraph = styled.p.attrs({})`
    font-size: 14px;
    font-family: 'Open Sans',sans-serif;
    font-weight: 700;
    color: rgb(86, 86, 86);
`
export const CardDocument = styled.img.attrs({
    src: '/icons/document.svg'
})``