import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const PageCard = styled.div.attrs({})`
    background: var(--white-color);
    padding: 24px;
    margin-top: 48px;
    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
`
export const CardHeader = styled.div.attrs({})`
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--primary-color);
    border-bottom: 1px solid var(--lightshadow-color);

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;

    @media (max-width: ${mobileSize}px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px 0;
    }
`
export const CardLineContainer = styled.div.attrs({})`
    display: flex;
`
export const CardLine = styled.div.attrs({})`
    display: flex;
    padding: 20px 0;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--lightshadow-color);
    ${(props) =>
        props.mobileHeader
            ? `
            display: none;
        `
            : ``}
    @media(max-width: ${mobileSize}px) {
        flex-direction: column;
        gap: 20px 0;
        flex: 1;
        ${(props) =>
            props.header
                ? `
                display: none;
            `
                : ``}
        ${(props) =>
            props.mobileHeader
                ? `
                display: flex;
                padding: 0 10px;
                flex:0;
                align-items: flex-start;
            `
                : ``}
    }
`
export const CardLineText = styled.div.attrs({})`
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--grey-color);

    ${(props) =>
        props.regular
            ? `
              font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            `
            : ``}

    ${(props) =>
        props.link
            ? `
              font-family: 'Open Sans', sans-serif;
            color: var(--lightgrey-color);
            text-decoration-line: underline;
            text-align: center;
            max-width: 120px;
            cursor: pointer;
        `
            : ``}

    ${(props) =>
        props.grey
            ? `
            color: var(--lightgrey-color);
            padding-right: 20px;
        `
            : ``}
`

export const CardLineActionSpacer = styled.div.attrs({})`
    flex: 1;
    max-width: 110px;
`

export const CardLineColumn = styled.div.attrs({})`
    ${(props) =>
        props.last
            ? ``
            : `
            flex:1;
        `}
    ${(props) =>
        props.first
            ? `
            max-width: 60px;
        `
            : `
        `}
    ${(props) =>
        props.grade
            ? `
            max-width: 120px;
        `
            : `
        `}
    ${(props) =>
        props.textarea
            ? `
            max-width: 230px;
        `
            : `
        `}
    @media(max-width: ${mobileSize}px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`

export const PageCardActions = styled.div.attrs({})`
    display: flex;
    justify-content: center;
    padding-top: 24px;
`

export const CardHeaderTitleContainer = styled.div.attrs({})`
    flex: 1;
`

export const CardHeaderSection = styled.div.attrs({})`
    display: flex;
    align-items: center;
    color: var(--grey-color);
    background: var(--backgroundlightgrey-color);
    padding: 10px 70px;
    ${(props) =>
        props.flex
            ? `
            flex:1;
            margin: 0 20px;
            justify-content: center;
        `
            : ``}
`

export const ButtonActionContent = styled.div.attrs({})`
    max-width: 196px;
`

export const ContentApproval = styled.div.attrs({})`
    display: flex;
    justify-content: center;
    padding-bottom: 12px;
    gap: 0 20px;
    padding-right: 30px;
`
export const ApprovalItem = styled.div.attrs({})`
    width: 28px;
    height: 28px;
    border-radius: 14px;

    ${(props) =>
        props.refuse
            ? `
            background: var(--redlabel-color);
        `
            : `
            background: var(--primary-color);
        `}

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    &:hover {
        box-shadow: 0px 1.5px 6px var(--shadow-color);
    }
`
export const ApprovalItemIcon = styled.img.attrs((props) => ({
    src: props.refuse ? '/icons/refuse.svg' : '/icons/check.svg',
}))``
