import React, {  useState } from 'react'
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Modal } from 'react-bootstrap';

const LivePreviewModal = ({ show, onHide, sections }) => {
    const [currentPreviewStep, setCurrentPreviewStep] = useState(0);

    const renderPreviewElementsWithRowLogic = () => {
        let rows = [];
        let currentRow = [];
        let currentRowTotalColSize = 0;
        const currentElements = sections[currentPreviewStep].elements;

        currentElements.forEach((element, index) => {
            const elementColSize = typeof element.col_size === 'number' ? element.col_size : parseInt(element.col_size?.replace('col-', '')) || 12;
            currentRowTotalColSize += elementColSize;
            currentRow.push(element);
            if (currentRowTotalColSize >= 12 || index === currentElements.length - 1) {
                rows.push([...currentRow]);
                currentRow = [];
                currentRowTotalColSize = 0;
            }
        });

        return rows.map((row, rowIndex) => (
            <div key={rowIndex} className="row">
                {row.map((element, elementIndex) => (
                    <div key={element.id} className={element.col_size || 'col-12'}>
                        <FormElementPreview element={element} />
                    </div>
                ))}
            </div>
        ));
    };

    return (
        <Modal show={show} onHide={() => { onHide(); setCurrentPreviewStep(0); }} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Form Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-between align-items-center mb-4">
                    {sections.map((step, index) => (
                        <React.Fragment key={index}>
                            <div
                                className={`step ${index === currentPreviewStep ? 'active' : ''}`}
                                onClick={() => setCurrentPreviewStep(index)}
                            >
                                <div className="step-icon-and-delete">
                                    <div className="step-icon">
                                        <i className={`bi bi-${step.icon}`}></i>
                                    </div>
                                </div>
                                <h5>{step.name}</h5>
                            </div>
                            {index < sections.length - 1 && <div className="step-line"></div>}
                        </React.Fragment>
                    ))}
                </div>
                {renderPreviewElementsWithRowLogic()}
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => { onHide(); setCurrentPreviewStep(0); }} className="btn btn-secondary">
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    );
};

const FormElementPreview = ({ element }) => {
    const renderOptions = (options, allowMultiple) => options.map(option => (
        <div key={option.id} className="form-check">
            <input
                type={allowMultiple ? "checkbox" : "radio"}
                className="form-check-input"
                value={option.value}
                id={`preview-${element.type}-${option.id}`}
                readOnly
            />
            <label className="form-check-label" htmlFor={`preview-${element.type}-${option.id}`}>
                {option.label}
            </label>
        </div>
    ));

    switch (element.type) {
        case 'title':
        case 'subtitle':
        case 'description':
            return (
                <div className="col-12" style={{ wordWrap: 'break-word' }}>
                    {element.type === 'title' && <h1>{element.content}</h1>}
                    {element.type === 'subtitle' && <h2>{element.content}</h2>}
                    {element.type === 'description' && <p>{element.content}</p>}
                </div>
            );
        case 'text':
            return (
                <div className="col-12">
                    <label className="form-label">
                        {element.label} {element.is_required && <span className="text-danger">*</span>}
                    </label>
                    <input type="text" className="form-control" value={element.content || ''} disabled />
                </div>
            );
        case 'list':
            return (
                <div className="col-12">
                    <label className="form-label">
                        {element.label} {element.is_required && <span className="text-danger">*</span>}
                    </label>
                    <select className="form-select" defaultValue="" aria-label="Select option">
                        <option value="" disabled>Select option</option>
                        {element.options?.map(option => (
                            <option key={option.id} value={option.value} disabled>{option.value}</option>
                        ))}
                    </select>
                </div>
            );
        case 'date':
            return (
                <div className="col-12">
                    <label className="form-label">
                        {element.label} {element.is_required && <span className="text-danger">*</span>}
                    </label>
                    <input type="date" className="form-control" disabled />
                </div>
            );
        case 'calendar':
            return (
                <div className="col-12">
                    <label className="form-label">{element.label || 'Calendar'}</label>
                    <label className="form-label">
                        {element.label} {element.is_required && <span className="text-danger">*</span>}
                    </label>
                    <input type="text" className="form-control" placeholder="Calendar input" disabled />
                </div>
            );
        case 'checkbox':
            return (
                <div className="col-12">
                    {element.label && <label>{element.label}</label>}
                    {renderOptions(element.options, element.allowMultiple)}
                </div>
            );
        case 'attach':
            return (
                <div key={element.id} className="col-12">
                    <label className="form-label">{element.label || 'Attachment'}</label>
                    <input
                        type="file"
                        className="form-control"
                        disabled={true}
                    />
                    {element.fileUrl && (
                        <a href={element.fileUrl} download>
                            <p>Current file: {element.fileName}</p>
                        </a>
                    )}
                </div>
            );
        case 'video':
            if (element.fileUrl) {
                return (
                    <div key={element.id} className="col-12">
                        <label className="form-label">{element.label || 'Attachment'}</label>
                        <video controls style={{ height: '200px', width: '100%' }} src={element.fileUrl}>
                            Your browser does not support the video tag.
                        </video>
                    </div>
                );
            } else {
                return (
                    <div key={element.id} className="col-12">
                        <label className="form-label">{element.label}</label>
                        <br></br>
                        <label className="form-label">No video added</label>
                    </div>
                );
            }
        case 'signature':
            return (
                <div className="col-12" style={{ wordWrap: 'break-word' }}>
                    <label className="form-label">{element.label || 'Signature'}</label>
                    <div className="signature-preview-placeholder" style={{ border: '1px solid black', minHeight: '50px', width: '100%', marginBottom: '10px' }}>
                    </div>
                </div>
            );
        default:
            return null;
    }
};

export default LivePreviewModal;