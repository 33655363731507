import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { toast } from 'react-toastify'
import { exposeStrapiErrorNew } from 'utils'

import {
    Content,
    PageTitle,
    PageSubTitle,
    PageCard,
    PageCardChecks,
    PageCardCheckIco,
    PageCardCheckText,
    PageCardLeft,
    PageCardRight,
    PageCardLeftGroup,
    PageCardLeftTitle,
    PageCardLeftSubtitle,
    PageCardLeftSubtitleDash,
    PageCardLeftPrice,
    PageCardLeftButton,
    PageCardLeftSubtitleGroup,
    PageCardRightTitle,
    PageCardRightGroup,
    PageCardRightItemText,
    PageCardRightBullet,
    PageCardRightItem,
} from './styled'

import ContainerUnauthenticated from 'containers/Unauthenticated'

export default function GetFullAccess({ param }) {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)
    const CheckMark = () => <span style={{ color: '#02c776' }}>✓</span>;

    return (
        <>
            <ContainerUnauthenticated fluid={true}>
                <Content>
                    <PageCard>
                        <PageCardLeft>
                            <PageCardLeftGroup>
                                <PageCardLeftSubtitleGroup>
                                    <PageCardLeftSubtitle>You've received</PageCardLeftSubtitle>
                                </PageCardLeftSubtitleGroup>
                                <PageCardLeftTitle>7-Day Free Trial!</PageCardLeftTitle>
                                <PageCardLeftSubtitleGroup>
                                    <PageCardLeftSubtitle><b>Complete your full profile to get access to the PREMIUM features for 30 days!</b></PageCardLeftSubtitle>
                                </PageCardLeftSubtitleGroup>
                                <PageCardLeftButton onClick={() => navigate('login')}>
                                    Get Started
                                </PageCardLeftButton>
                            </PageCardLeftGroup>
                        </PageCardLeft>
                        <PageCardRight>
                            <PageCardRightTitle>
                                With Premium Subscription you will be able to:
                            </PageCardRightTitle>
                            <PageCardRightGroup>
                                <PageCardRightItem>
                                    <PageCardRightItemText>
                                        <CheckMark /> Find new companies with advanced filter tool
                                    </PageCardRightItemText>
                                </PageCardRightItem>

                                <PageCardRightItem>
                                    <PageCardRightItemText>
                                        <CheckMark /> Control your suppliers on MyQMS
                                    </PageCardRightItemText>
                                </PageCardRightItem>

                                <PageCardRightItem>
                                    <PageCardRightItemText>
                                        <CheckMark /> Document Management
                                    </PageCardRightItemText>
                                </PageCardRightItem>

                                <PageCardRightItem>
                                    <PageCardRightItemText>
                                        <CheckMark /> Your company will be listed in our filters
                                    </PageCardRightItemText>
                                </PageCardRightItem>

                                <PageCardRightItem>
                                    <PageCardRightItemText>
                                        <CheckMark /> Control your own documents with “MyDocuments”
                                    </PageCardRightItemText>
                                </PageCardRightItem>

                                <PageCardRightItem>
                                    <PageCardRightItemText>
                                        <CheckMark /> Fill out the Basic Pre-Check
                                    </PageCardRightItemText>
                                </PageCardRightItem>

                                <PageCardRightItem>
                                    <PageCardRightItemText>
                                        <CheckMark /> Add up to 1 more users in your team on My Users
                                    </PageCardRightItemText>
                                </PageCardRightItem>
                            </PageCardRightGroup>
                        </PageCardRight>
                    </PageCard>
                </Content>
            </ContainerUnauthenticated>
        </>
    )
}
