import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'

const TopCountriesComponent = ({ dataForTopCountries }) => {
    const { findlanguage } = useContext(CoreContext)
    // Assuming each entry in data is a unique product for the country
    const summaryByCountry = dataForTopCountries.reduce((acc, item) => {
        if (item.country && item.country !== 'Unknown') {
            // Check that country exists and is not 'Unknown'
            acc[item.country] = (acc[item.country] || 0) + 1 // Increment count for the country
        }

        return acc
    }, {})

    // Convert the summary object into an array and sort by the count of products
    const sortedCountries = Object.keys(summaryByCountry)
        .map((key) => ({ country: key, totalProducts: summaryByCountry[key] }))
        .sort((a, b) => b.totalProducts - a.totalProducts) // Sort descending

    return (
        <div className="top-countries-box">
            <header className="box-header">{findlanguage().topCountries}</header>
            <ul className="countries-list">
                {sortedCountries?.slice(0, 9).map((entry, index) => {
                    let barWidth = '';
                    let imageUrl = '';
                    let style = {};

                    let countryName = findlanguage()[entry.country.toLocaleLowerCase().replace(' ', '')]; // Assuming findlanguage() returns an object with country translations based on user's language preference

                    switch (entry.country) {
                        case 'Brazil':
                            barWidth = '100';
                            imageUrl = 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Brazilian_flag_icon_round.svg';
                            break;
                        case 'United States':
                            barWidth = '64';
                            imageUrl = 'https://upload.wikimedia.org/wikipedia/commons/8/88/United-states_flag_icon_round.svg';
                            break;
                        case 'Mexico':
                            barWidth = '40';
                            imageUrl = 'https://upload.wikimedia.org/wikipedia/commons/c/c0/Mexico_flag_icon.svg';
                            break;
                        case 'United Kingdom':
                            barWidth = '35';
                            imageUrl = 'https://upload.wikimedia.org/wikipedia/commons/1/13/United-kingdom_flag_icon_round.svg';
                            break;
                        case 'China':
                            barWidth = '27';
                            imageUrl = 'https://upload.wikimedia.org/wikipedia/commons/a/ae/Circle_Flag_of_the_People%27s_Republic_of_China.svg';
                            break;
                        case 'Italy':
                            barWidth = '21';
                            imageUrl = 'https://upload.wikimedia.org/wikipedia/commons/c/c5/Flag_orb_Italy.svg';
                            break;
                        case 'Canada':
                            barWidth = '17';
                            imageUrl = 'https://upload.wikimedia.org/wikipedia/commons/9/97/Circle-icons-Canada.svg';
                            break;
                        case 'Spain':
                            barWidth = '16';
                            imageUrl = 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Spain_flag_icon.svg';
                            break;
                        case 'Netherlands':
                            barWidth = '15';
                            style = { transform: 'rotate(180deg)' };
                            imageUrl = 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/Roundel_of_Yugoslavia_%281992%E2%80%932003%29.svg/800px-Roundel_of_Yugoslavia_%281992%E2%80%932003%29.svg.png';
                            break;
                        default:
                            break;
                    }

                    return (
                        <li key={entry.country} className="country-item" translate="yes">
                            <span className="country-rank">
                                <img
                                    className="country-rank-img"
                                    src={imageUrl}
                                    alt="Flag"
                                    style={{ ...style }}
                                />
                            </span>
                            <span className={`flag-icon flag-icon-${entry.country.toLowerCase()}`}></span>
                            <span className="country-name" style={{ width: `${barWidth}%` }}>
                                {countryName}
                            </span>
                            <span className="country-total">{entry.totalProducts}</span>
                            <span className="country-trend">
                                <img
                                    src="https://assets.codepen.io/3685267/res-react-dash-country-up.svg"
                                    alt=""
                                    className="w-4 h-4 mx-3"
                                />
                            </span>
                        </li>
                    );
                })}

            </ul>
        </div>
    )
}

export default TopCountriesComponent
