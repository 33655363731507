import React, { useState, useEffect, useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import { PageCard } from '../../../../components/Dashboard/Panels/SMSControl/styled'
import {
    GetPrecheckServiceProvider,
    PostPrecheck,
    PutPrecheck,
} from 'services/precheckServiceProvider'
import { UpdatePrecheckServiceProvider } from 'services/api'
import { ReadServicesOffered } from 'services/infos'
import { ReadCertificationsAndApprovals } from 'services/infos'
import { NotificationManagerAdd } from 'services/admin'

import { TitleGreen } from './styled'
import Select from 'react-select'
import { parseStrapiImage } from 'utils'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const labelCss = { fontWeight: 'bold', fontSize: '15px', color: 'rgb(86, 86, 86)' }
const labelTextCss = { fontSize: '14px', color: 'rgb(86, 86, 86)' }
const fixRow = { marginTop: '-22px' }
const inputCss = { borderColor: '#70707059' }
const col6 = { fontSize: '700', color: '#707070' }
const col6WIthTop = { fontSize: '700', color: '#707070', marginTop: '22px' }
const spacer = { borderTop: '0.1px solid rgb(235 235 235)' }
const doneTypingInterval = 2000

let typingTimer

export default function PrecheckServiceProvider({ company_id, status, goBack }) {
    const [form, setForm] = useState(true)
    const { user, findlanguage, setModal, language } = useContext(CoreContext)
    const [servicesOffered, setServicesOffered] = useState([])
    const [certifications, setCertifications] = useState([])
    const [basicInformation, setBasicInformation] = useState({
        id: -1,
        company_name_user: '',
        personal_name: '',
        email: '',
        services_offered: [],
        phone: '',
        latest_resume_file: {},
        bachelors_degree: '',
        standards_provided: [],
        active_company_proof: '',
        active_company_proof_file: {},
        business_license: '',
        business_license_file: {},
        sanitary_license: '',
        sanitary_license_file: {},
        labor_and_tax_compliance: '',
        labor_and_tax_compliance_standards: '',
        labor_and_tax_compliance_standards_file: {},
    })
    const [show, setShow] = useState(false);
    const [reason, setReason] = useState('')
    const [reasonMessage, setReasonMessage] = useState('')

    const showModal = () => setShow(true)
    const hideModal = () => setShow(false)
    const handleSelectChange = (event) => setReason(event.target.value)

    const options = ["Missing Requirements", "Invalid Document", "Incomplete Document", "Corrupt Document"]

    const [showReminderModal, setShowReminderModal] = useState(false);
    const [expirationDate, setExpirationDate] = useState('');
    const [reminderDays, setReminderDays] = useState(30); // Default to 30 days

    const showReminder = () => setShowReminderModal(true);
    const hideReminder = () => setShowReminderModal(false);
    const handleDateChange = (event) => setExpirationDate(event.target.value);
    const handleReminderDaysChange = (event) => setReminderDays(event.target.value);

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        const getAdditionalInformation = await GetPrecheckServiceProvider(company_id)

        if (
            getAdditionalInformation[0]?.services_offered !== undefined &&
            getAdditionalInformation[0]?.services_offered !== null &&
            getAdditionalInformation[0]?.services_offered !== ''
        )
            getAdditionalInformation[0].services_offered = JSON.parse(
                getAdditionalInformation[0]?.services_offered
            )
        else getAdditionalInformation[0].services_offered = []

        if (
            getAdditionalInformation[0]?.standards_provided !== undefined &&
            getAdditionalInformation[0]?.standards_provided !== null &&
            getAdditionalInformation[0]?.standards_provided !== ''
        )
            getAdditionalInformation[0].standards_provided = JSON.parse(
                getAdditionalInformation[0]?.standards_provided
            )
        else getAdditionalInformation[0].standards_provided = []

        setBasicInformation(getAdditionalInformation[0])

        const _loadServicesOffered = await ReadServicesOffered()
        const tempServicesOffered = _loadServicesOffered
            .sort((a, b) => (a.title > b.title ? 1 : -1))
            .map((item, key) => {
                const label = language === 'portugues' ? item.label_pt : item.label
                return {
                    value: key + 1,
                    label: label,
                }
            })

        setServicesOffered(tempServicesOffered)

        const certifications = await ReadCertificationsAndApprovals()
        let certificationsList = []

        if (language === 'portugues') {
            certifications.forEach((x) => {
                return x.items.forEach((y) => {
                    certificationsList.push({
                        value: y.id,
                        label: y.label_pt,
                    })
                })
            })
        } else {
            certifications.forEach((x) => {
                return x.items.forEach((y) => {
                    certificationsList.push({
                        value: y.id,
                        label: y.label,
                    })
                })
            })
        }

        setCertifications(
            certificationsList.sort((a, b) => (a.label > b.label) - (a.label < b.label))
        )
    }

    const changeQuestionaire = (value, field) => {}

    const submit = async () => {
        setBasicInformation({
            ...basicInformation,
            status: 'approved',
            expiration_date: expirationDate,
            reminder_days: reminderDays,
        })

        await PutPrecheck({
            ...basicInformation,
            expiration_date: expirationDate,
            reminder_days: reminderDays,
            services_offered:
                typeof basicInformation.services_offered === 'string'
                    ? basicInformation.services_offered
                    : JSON.stringify(basicInformation.services_offered),
            standards_provided:
                typeof basicInformation.standards_provided === 'string'
                    ? basicInformation.standards_provided
                    : JSON.stringify(basicInformation.standards_provided),
            status: 'approved',
        })

        await NotificationManagerAdd('mfs_approved', company_id, user.name, user, 'full')

        goBack()

        return setModal({
            type: 'alert',
            text: 'MyTS form approved.',
            warning: true,
        })
    }

    const decline = async () => {
        setBasicInformation({
            ...basicInformation,
            reason_denied: reason,
            reason_denied_message: reasonMessage,
            status: 'decline',
        })

        await PutPrecheck({
            ...basicInformation,
            reason_denied: reason,
            reason_denied_message: reasonMessage,
            status: 'decline',
        })

        await NotificationManagerAdd('mfs_declined', company_id, user.name, user, 'full')

        goBack()

        return setModal({
            type: 'alert',
            text: 'Declined.',
            warning: true,
        })
    }

    const downloadPdfDocument = () => {
        const input = document.getElementById('form-precheck')

        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: 'portrait',
                    unit: 'px',
                    format: [canvas.width, canvas.height]
                });
                pdf.addImage(imgData, 'PNG', 0, 0)
                pdf.save('download.pdf')
            })
            .catch(err => {
                console.error('Error generating PDF', err)
            })
    }

    return (
        <PageCard id="form-precheck" >
            <TitleGreen>
                {findlanguage().basicPreCheck}
                <div style={{ float: 'right', marginTop: '-27px', position: 'sticky' }}>
                    <button
                        className="btn btn-secondary"
                        style={{ marginRight: '5px', marginTop: '15px' }}
                        onClick={downloadPdfDocument}
                    >
                        Export PDF
                    </button>
                    <button
                        className="btn btn-secondary"
                        style={{ marginRight: '5px', marginTop: '15px' }}
                        onClick={() => goBack()}
                    >
                        {findlanguage().goBack}
                    </button>
                    {status === 'submitted' ? (
                        <>
                            <button
                                className="btn"
                                onClick={showModal}
                                style={{
                                    marginRight: '5px',
                                    marginTop: '15px',
                                    backgroundColor: '#ff4f4f',
                                    color: 'white',
                                }}
                            >
                                {findlanguage().reasonBuyerDenied}
                            </button>
                            <button
                                className="btn"
                                onClick={submit}
                                style={{
                                    marginRight: '5px',
                                    marginTop: '15px',
                                    backgroundColor: '#199b2f',
                                    color: 'white',
                                }}
                            >
                                {findlanguage().submit}
                            </button>
                        </>
                    ) : null}
                </div>
            </TitleGreen>
            <div className="row  mb-3" style={{ borderBottom: '1px solid #7070700f' }}>
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().companyNameUser}
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        style={inputCss}
                        value={basicInformation.company_name_user}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'company_name_user')
                        }
                        disabled={form}
                    />
                </div>
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().companyAddress}
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        style={inputCss}
                        value={basicInformation.personalName}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'personal_name')
                        }
                        disabled={form}
                    />
                </div>
            </div>
            <div className="row  mb-3" style={{ borderBottom: '1px solid #7070700f' }}>
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().email}
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        style={inputCss}
                        value={basicInformation.email}
                        onChange={(event) => changeQuestionaire(event.target.value, 'email')}
                        disabled={form}
                    />
                </div>
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().phone}
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        style={inputCss}
                        value={basicInformation.phone}
                        onChange={(event) => changeQuestionaire(event.target.value, 'phone')}
                        disabled={form}
                    />
                </div>
            </div>
            <div className="row  mb-3" style={{ borderBottom: '1px solid #7070700f' }}>
                <div className="col-12" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().servicesOfered}
                    </label>
                    <Select
                        value={basicInformation.services_offered}
                        options={servicesOffered}
                        isMulti
                        onChange={(values) => changeQuestionaire(values, 'services_offered')}
                        isDisabled={form}
                    />
                </div>
            </div>
            {basicInformation.services_offered?.some((service) =>
                [
                    findlanguage().training,
                    findlanguage().thirdPartyAudits,
                    findlanguage().secondPartyAudits,
                    findlanguage().internalAudits,
                    findlanguage().consultant,
                ].includes(service.label)
            ) ? (
                <>
                    <div className="row  mb-3" style={fixRow}>
                        <div className="col-6" style={col6WIthTop}>
                            <label className="form-label" style={labelTextCss}>
                                {findlanguage().attachLatestResume}
                            </label>
                            <FileUploader file={basicInformation.latest_resume_file} />
                        </div>
                    </div>
                    <div className="row  mb-3" style={{ borderBottom: '1px solid #7070700f' }}>
                        <div className="col-12" style={col6}>
                            <label className="form-label" style={labelCss}>
                                {findlanguage().bachelorsDegreeQuestion}
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                style={inputCss}
                                value={basicInformation.bachelors_degree}
                                onChange={(event) =>
                                    changeQuestionaire(event.target.value, 'bachelors_degree')
                                }
                                disabled={form}
                            />
                        </div>
                    </div>
                    <div className="row  mb-3" style={{ borderBottom: '1px solid #7070700f' }}>
                        <div className="col-12" style={col6}>
                            <label className="form-label" style={labelCss}>
                                {findlanguage().serviceStandardsQuestion}
                            </label>
                            <Select
                                value={basicInformation.standards_provided}
                                options={certifications}
                                isMulti
                                onChange={(values) =>
                                    changeQuestionaire(values, 'standards_provided')
                                }
                                isDisabled={form}
                            />
                        </div>
                    </div>
                </>
            ) : null}
            {basicInformation.services_offered?.some((service) =>
                [
                    findlanguage().labAnalysis,
                    findlanguage().pestControl,
                    findlanguage().calibrationOfMeasuringInstruments,
                ].includes(service.label)
            ) ? (
                <>
                    <div className="row">
                        <div className="col-12" style={col6}>
                            <label className="form-label" style={labelCss}>
                                {findlanguage().activeCompanyProof}
                            </label>
                            <br />
                            <label className="form-label" style={labelTextCss}>
                                {findlanguage().proofCompanyActiveRequirement}
                            </label>
                        </div>
                    </div>
                    <div className="row mb-3" style={fixRow}>
                        <div className="col-6" style={col6}>
                            <label className="form-label" style={labelTextCss}></label>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                value={basicInformation.active_company_proof}
                                onChange={(event) =>
                                    changeQuestionaire(event.target.value, 'active_company_proof')
                                }
                                disabled={form}
                            >
                                {language === 'portugues' ? (
                                    <>
                                        <option value=""></option>
                                        <option value="CNPJ">CNPJ</option>
                                        <option value="CPF">CPF</option>
                                    </>
                                ) : (
                                    <>
                                        <option value=""></option>
                                        <option value="EIN Number">EIN Number</option>
                                        <option value="Tax ID">Tax ID</option>
                                        <option value="DUNS Number">DUNS Number</option>
                                        <option value="Driver License Number">
                                            Driver License Number
                                        </option>
                                    </>
                                )}
                            </select>
                        </div>
                        <div className="col-6" style={col6}>
                            <FileUploader file={basicInformation.active_company_proof_file} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" style={col6}>
                            <label className="form-label" style={labelCss}>
                                {findlanguage().businessLicense}
                            </label>
                            <br />
                            <label className="form-label" style={labelTextCss}>
                                {findlanguage().validOperatingLicenseQuestion}
                            </label>
                        </div>
                    </div>
                    <div className="row mb-3" style={fixRow}>
                        <div className="col-6" style={col6}>
                            <label className="form-label" style={labelTextCss}></label>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                value={basicInformation.business_license}
                                onChange={(event) =>
                                    changeQuestionaire(event.target.value, 'business_license')
                                }
                                disabled={form}
                            >
                                <option value=""></option>
                                <option value={findlanguage().no}>{findlanguage().no}</option>
                                <option value={findlanguage().yes}>{findlanguage().yes}</option>
                                <option value="N/A">N/A</option>
                            </select>
                        </div>
                        <div className="col-6" style={col6}>
                            <FileUploader file={basicInformation.business_license_file} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" style={col6}>
                            <label className="form-label" style={labelCss}>
                                {findlanguage().sanitaryLicense}
                            </label>
                            <br />
                            <label className="form-label" style={labelTextCss}>
                                {findlanguage().healthPermitRequirement}
                            </label>
                        </div>
                    </div>
                    <div className="row  mb-3" style={fixRow}>
                        <div className="col-6" style={col6}>
                            <label className="form-label" style={labelTextCss}></label>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                value={basicInformation.sanitary_license}
                                onChange={(event) =>
                                    changeQuestionaire(event.target.value, 'sanitary_license')
                                }
                                disabled={form}
                            >
                                <option value=""></option>
                                <option value={findlanguage().no}>{findlanguage().no}</option>
                                <option value={findlanguage().yes}>{findlanguage().yes}</option>
                                <option value="N/A">N/A</option>
                            </select>
                        </div>
                        <div className="col-6" style={col6}>
                            <FileUploader file={basicInformation.business_license_file} />
                        </div>
                    </div>
                    <br />
                    <div className="row  mb-3" style={fixRow}>
                        <div className="col-4" style={col6}>
                            <label className="form-label" style={labelCss}>
                                {findlanguage().complyLegislationQuestion}
                            </label>
                            <select
                                className="form-select"
                                aria-label="More than one factory"
                                value={basicInformation.labor_and_tax_compliance}
                                onChange={(event) =>
                                    changeQuestionaire(
                                        event.target.value,
                                        'labor_and_tax_compliance'
                                    )
                                }
                                disabled={form}
                            >
                                <option value={findlanguage().no}>{findlanguage().no}</option>
                                <option value={findlanguage().yes}>{findlanguage().yes}</option>
                            </select>
                        </div>
                        {basicInformation.labor_and_tax_compliance === findlanguage().yes ? (
                            <>
                                <div className="col-4" style={col6}>
                                    <label className="form-label" style={labelCss}>
                                        {findlanguage().ifYesIncludeStandards}
                                    </label>
                                    <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        value={basicInformation.what_standards}
                                        onChange={(event) =>
                                            changeQuestionaire(event.target.value, 'what_standards')
                                        }
                                        disabled={form}
                                    >
                                        <option value=""></option>
                                        <option value={findlanguage().regulatoryOption}>
                                            {findlanguage().regulatoryOption}
                                        </option>
                                        <option
                                            value={findlanguage().gfsiRecognizedStandardsOption}
                                        >
                                            {findlanguage().gfsiRecognizedStandardsOption}
                                        </option>
                                        <option value={findlanguage().socialOption}>
                                            {findlanguage().socialOption}
                                        </option>
                                        <option value={findlanguage().environmentalOption}>
                                            {findlanguage().environmentalOption}
                                        </option>
                                        <option value={findlanguage().otherOption}>
                                            {findlanguage().otherOption}
                                        </option>
                                    </select>
                                </div>
                                <div className="col-4" style={col6}>
                                    <label className="form-label" style={labelTextCss}>
                                        {findlanguage().attachCertifications}
                                    </label>
                                    <MultipleFileUploader
                                        file={
                                            basicInformation.labor_and_tax_compliance_standards_file
                                        }
                                    />
                                </div>
                            </>
                        ) : null}
                    </div>
                </>
            ) : null}
            <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Review Pre-check</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="reasonSelect">Choose a reason</label>
                                    <select className="form-control" id="reasonSelect" value={reason} onChange={handleSelectChange}>
                                        {options.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="responseText">Type a response to the supplier</label>
                                    <textarea className="form-control" id="responseText" rows="3" onChange={(e) => setReasonMessage(e.target.value)} ></textarea>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={decline}>Decline</button>
                        </div>
                    </div>
                </div>
            </div>

            {show && <div className="modal-backdrop fade show"></div>}

            {show && <div className="modal-backdrop fade show"></div>}
            <div className={`modal fade ${showReminderModal ? 'show' : ''}`} style={{ display: showReminderModal ? 'block' : 'none' }} tabIndex="-1">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Choose a expiration date</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideReminder}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <input type="date" className="form-control" value={expirationDate} onChange={handleDateChange} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="reminderDays">Send reminder to this company starting in </label>
                                    <input type="number" className="form-control" id="reminderDays" value={reminderDays} onChange={handleReminderDaysChange} min="1" />
                                    <small>days before expiration</small>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={submit}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>

            {showReminderModal && <div className="modal-backdrop fade show"></div>}
        </PageCard>
    )
}
const FileUploader = ({ file }) => {
    return (
        <>
            {file === undefined || file === null ? null : (
                <a
                    style={{
                        fontSize: '13px',
                        color: 'white',
                        height: '35px',
                        padding: '10px',
                        marginLeft: '10px',
                        marginTop: '25px',
                        width: '100%',
                        backgroundColor: '#263640',
                    }}
                    className="badge"
                    href={parseStrapiImage(file?.url)}
                    download={parseStrapiImage(file?.url)}
                >
                    Download {file?.name}
                </a>
            )}
        </>
    )
}

const MultipleFileUploader = ({ file }) => {
    return (
        <>
            {file === undefined || file === null || file?.length === 0
                ? null
                : file?.map((y) => (
                      <a
                          style={{
                              fontSize: '13px',
                              color: 'white',
                              height: '35px',
                              padding: '10px',
                              marginLeft: '10px',
                              marginTop: '25px',
                              width: '100%',
                              backgroundColor: '#263640',
                          }}
                          className="badge"
                          href={parseStrapiImage(y?.url)}
                          download={parseStrapiImage(y?.url)}
                      >
                          Download {y?.name}
                      </a>
                  ))}
        </>
    )
}
