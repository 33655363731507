import React, { useContext, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { CoreContext } from 'context/CoreContext'
import {
    PageHeader,
    PageHeaderTitle,
} from './styled'
import ContainerAuthenticated from 'containers/Authenticated'
import Fusao from 'components/Dashboard/UserProfile/Fusao'
import UserProfileServiceProvider from 'components/Dashboard/UserProfile/ServiceProvider'
import DashboardMore from 'components/Dashboard/More'
import { useHistory } from 'react-router-dom'
import UserProfileConsumer from 'components/Dashboard/UserProfile/Consumer'

export default function DashboardProfile() {
    const { user, profile, premium, findlanguage, permissions } =
        useContext(CoreContext)
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const moreOptions = () => {
        let options = []
        if (premium.type !== 'free' && permissions?.myProfile?.canEdit) {
            options.push({
                title: findlanguage().myUsers,
                action: () => navigate('dashboard/profile/team-access'),
            })
            options.push({
                title: findlanguage().myServiceProvider,
                action: () => navigate('dashboard/profile/service-provider'),
            })
        }

        options.push({
            title: findlanguage().askSupport,
            action: () => navigate('dashboard/profile/ask-support'),
        })
        return options
    }

    useEffect(() => {
        if (!permissions?.myProfile?.canView && !permissions?.myProfile?.canEdit) {
            navigate('dashboard')
        }
    }, [permissions, navigate]);

    return (
        <>
            <ContainerAuthenticated>
                <Container fluid={false}>
                    <Row>
                        <Col>
                            <PageHeader>
                                {profile !== 'consumer' && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <PageHeaderTitle>
                                            {findlanguage().companyInformation}
                                        </PageHeaderTitle>
                                        <DashboardMore options={moreOptions()} />
                                    </div>
                                )}
                                {profile === 'consumer' && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <PageHeaderTitle>Consumer Information</PageHeaderTitle>
                                        <DashboardMore options={moreOptions()} />
                                    </div>
                                )}
                            </PageHeader>
                            {profile === 'consumer' ? <UserProfileConsumer /> : null}
                            {profile === 'buyer' || profile === 'supplier' || profile === 'admin' || profile === 'buyer-supplier' || profile === 'administrative' ? <Fusao /> : null}
                            {profile === 'service-provider' ? <UserProfileServiceProvider /> : null}
                        </Col>
                    </Row>
                </Container>
            </ContainerAuthenticated>
        </>
    )
}
