import React from 'react'

import { Load } from 'ui/styled'
import { ButtonColor } from './styled'

export default function Button({ children, loading, ...props }) {
    return (
        <>
            <ButtonColor {...props}>{loading ? <Load /> : <>{children}</>}</ButtonColor>
        </>
    )
}
