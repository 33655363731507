import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'

import { ProfileFormContent, ProfileFormActions, PageFormSpace } from './styled'

import Input from 'components/Form/Input'
import Button from 'components/Form/Button'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'
import FormCreditCard from 'components/Form/CreditCard'
import DashboardCreditCardLabel from 'components/Dashboard/Cards/CreditCard'

export default function ModalAddCard() {
    const { modal, setModal } = useContext(CoreContext)

    const [form, setForm] = useState({})
    const formValue = (ref) => {
        return form?.[ref] ? form?.[ref] : ''
    }
    const changeForm = (value, ref) => {
        setForm({ ...form, [ref]: value })
    }

    const close = () => {
        setModal({
            type: 'alert',
            text: 'Credit Card Added',
        })
    }
    return (
        <>
            <ModalWrapper big>
                <DashboardCreditCardLabel
                    name={formValue('name')}
                    number={formValue('number')}
                    expires_at={formValue('expires_at')}
                    cvv={formValue('cvv')}
                />
                <PageFormSpace />
                <FormCreditCard formValue={formValue} changeForm={changeForm} />
                <ProfileFormActions>
                    <Button primary onClick={close}>
                        Add
                    </Button>
                </ProfileFormActions>
            </ModalWrapper>
        </>
    )
}
