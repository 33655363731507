import React, { useState, createContext, useEffect } from 'react'
import { ReadObject, SaveObject } from '../services/storage'
import { ReadMe } from 'services/authentication'
import { CheckSubscription } from 'services/authentication'
import Cookies from 'js-cookie'

export const CoreContext = createContext({})

export const CoreState = ({ children }) => {
    const [language, setLanguage] = useState(
        localStorage.getItem('language') ? localStorage.getItem('language') : 'english'
    )

    const [modal, setModal] = useState(null)
    const [user, setUser] = useState(ReadObject('user') ? ReadObject('user') : [])
    const [permissions, setPermissions] = useState(
        ReadObject('permissions')
            ? ReadObject('permissions')
            : {
                  myProfile: {
                      canView: false,
                      canEdit: false,
                  },
                  profileViews: {
                      canView: false,
                      showOnDashboard: false,
                  },
                  filter: {
                      canUse: false,
                  },
                  wishlist: {
                      canView: false,
                      canEdit: false,
                      showOnDashboard: false,
                  },
                  connections: {
                      canView: false,
                      canEdit: false,
                      notifications: false,
                  },
                  message: {
                      canUse: false,
                      showOnDashboard: false,
                  },
                  myQms: {
                      canView: false,
                  },
                  myProcessOverview: {
                      canView: false,
                      canEdit: false,
                  },
                  myBuyers: {
                      canView: false,
                      canEdit: false,
                      showOnDashboard: false,
                      notifications: false,
                  },
                  mySuppliers: {
                      canView: false,
                      canEdit: false,
                      notifications: false,
                  },
                  myDocuments: {
                      canView: false,
                      canEdit: false,
                      notifications: false,
                  },
              }
    )

    const [profile, setProfile] = useState(
        ReadObject('profile') ? ReadObject('profile') : 'profile_not_found'
    )
    const [filter, setFilter] = useState(ReadObject('filter') ? ReadObject('filter') : {})
    const [filterService, setFilterService] = useState(
        ReadObject('filterService') ? ReadObject('filterService') : {}
    )
    const [filterScraping, setFilterScraping] = useState(
        ReadObject('filterScraping') ? ReadObject('filterScraping') : {}
    )
    const [premium, setPremium] = useState(
        ReadObject('premium') ? ReadObject('premium') : { type: 'free' }
    )
    const [teamAccessList, setTeamAccessList] = useState()
    const [newChat, setNewChat] = useState()

    const [serviceProviderChoosedAccount, setServiceProviderChoosedAccount] = useState(false)

    const [totalMessages, setTotalMessages] = useState(0)

    const [paymentIntent, setPaymentIntent] = useState(null)
    const [showModal, setShowModal] = useState(false)

    // const findlanguage = (data) => { }

    const findlanguage = (_language) => {
        const __language = _language ? _language : language

        switch (__language) {
            case 'portugues':
                return {
                    seeMore: 'Ver mais...',
                    numUsers: 'Número de Usuários',
                    nameNonMandatory: 'Nome',
                    stateNonMandatory: 'Estado',
                    emailNonMandatory: 'E-mail',
                    formating: 'Formating',
                    password: 'Senha',
                    passwordNotMatch: 'As senhas não conferem',
                    actions: 'Ações',
                    document: 'Documento',
                    forgotPassword: 'Esqueceu a senha?',
                    enter: 'Entrar',
                    noAccount: 'Não tem conta? Cadastre-se agora!',
                    createCount: 'Criar conta',
                    noAddress: 'Sem endereço cadastrado',
                    singup: 'Bem-vindo à My Trusted Source',
                    selectAccountType: 'Escolha um tipo de conta',
                    selectTheFields: 'Selecione os campos',
                    titleExample: 'Exemplo de Título',
                    subTitleExample: 'Exemplo de Subtítulo',
                    imageInputTitle: 'Imagem',
                    selectImage: 'Selecione a Imagem',
                    selectFormComponent: 'Selecione um formulário acima para iniciar',
                    dropdownTitle: 'Título da Lista',
                    textInputTitle: 'Título do Campo de Texto',
                    documentInputTitle: 'Título do Documento',
                    accountType: 'Tipo de conta',
                    supplierTitle: 'Fornecedor',
                    supplierDescription: 'Vendo meus produtos para diversas empresas',
                    buyerTitle: 'Comprador',
                    buyerDescription: 'Gerencio toda a minha cadeia de fornecedores',
                    serviceProviderTitle: 'Prestador de Serviço',
                    serviceProviderDescription: 'Ofereço serviços para a industria de alimentos',
                    businessInformation: 'Informações da sua empresa',
                    companyName: 'Nome da empresa*',
                    companyNameNonMandatory: 'Nome da empresa',
                    contactName: 'Nome do contato',
                    name: 'Nome*',
                    email: 'E-mail*',
                    phoneNumber: 'Telefone*',
                    emailReadOnly: 'E-mail (Somente Leitura)',
                    phoneNumberReadOnly: 'Telefone (Somente Leitura)',
                    phoneNumberNonMandatory: 'Telefone',
                    rulesPhoneNumberHeader: 'Regra para o Telefone',
                    passwordhasBeenChanged: 'Senha alterada com sucesso',
                    rulesPhoneNumberText:
                        'Use o formato internacional como no exemplo +558899990011',
                    rulesPasswordHeader: 'Regra para a Senha',
                    rulesPasswordText:
                        'A senha deve ter no mínimo 8 caracteres, incluir ao menos 1 letra maiúscula, 1 número e 1 caracter especial (!@#$%^&*)',
                    phoneNumberNonMandatory: 'Telefone',
                    confirmPassword: 'Confirme sua senha*',
                    address: 'Endereço',
                    autoFill: 'Quero preencher manualmente',
                    country: 'País*',
                    confirm: 'Confirmar',
                    cancel: 'Cancelar',
                    countryNonMandatory: 'País',
                    state: 'Estado',
                    city: 'Cidade',
                    zip: 'CEP',
                    website: 'Website',
                    requiredFields: '*Campos obrigatórios',
                    nextStep: 'Próximo passo',
                    additionalInformation: 'Informação Adicional',
                    typeOfBusiness: 'Tipo de empresa',
                    addBusiness: 'Adicionar Empresa',
                    typeOfCategoryCompanyFit: 'Em que tipo de categoria sua empresa se enquadra:',
                    doYouHaveCertificationApproval:
                        'Sua empresa tem alguma certificação ou aprovação?',
                    whatProductsAreProducedSupplied: 'Quais produtos são produzidos/fornecidos?',
                    whatAreThePreRequirements:
                        'Quais são os pré-requisitos ao escolher um fornecedor?',
                    addProduct: 'Adicionar Produto',
                    descriptionProduct: 'Descrição dos seus produtos',
                    aboutCompany: 'Sobre a sua empresa',
                    distribution: 'Distribuição',
                    distributionState: 'Estadual',
                    national: 'Nacional',
                    international: 'Internacional',
                    prevStep: 'Passo anterior',
                    createAccount: 'Criar Conta',
                    completeYourProfile: 'Complete seu perfil',
                    profileCompleted: 'Perfil completo!',
                    completeProfile: 'Completar Perfil',
                    hello: 'Olá',
                    recommendedForYou: 'Recomendado para você',
                    myWishList: 'Meus favoritos',
                    myWidgets: 'Meus Painéis',
                    messages: 'Mensagens',
                    views: 'Visualizações',
                    supplierValidaonProcess: 'Processo de Validação do Fornecedor - Requerimentos',
                    suppplierGeneralStatus: 'Status Geral do Fornecedor',
                    supplierPerCategory: 'Categoria MyTS',
                    documentControl: 'Controle de documentos',
                    expiredDocument: 'Documento expirado',
                    supplierPerRegion: 'Fornecedores por região',
                    supplierMap: 'Mapa de Fornecedores',
                    reply: 'Responder',
                    days: 'Dias',
                    companyInformation: 'Informações da Empresa',
                    companyMyInformation: 'Meus Dados',
                    accountInformation: 'Informações da conta',
                    editInformation: 'Editar informações',
                    editMyUserInformation: 'Editar minhas informações',
                    deleteAccount: 'Deletar conta',
                    myUsers: 'Meus usuários',
                    supportCases: 'Chamados de Suporte',
                    askSupport: 'Solicitar Suporte',
                    caseSentSuccess: 'Caso de suporte aberto com sucesso!',
                    sendCase: 'Enviar chamado',
                    currentPassword: 'Senha atual*',
                    newPassword: 'Nova Senha*',
                    changePassword: 'Mudar de senha',
                    myCreditCards: 'Meus cartões de crédito/débito',
                    addNewCard: 'Adicionar novo cartão',
                    saveAfterAnyUpdate: '* Salve após qualquer atualização',
                    myRequirements: 'Meus Requisitos',
                    other: 'Outros',
                    addNewRequirement: 'Adicionar novo requerimento',
                    updateProfile: 'Atualizar perfil',
                    editRequirement: 'Editar requerimento',
                    updateInformation: 'Editar Informação',
                    supplierDetails: 'Detalhes do Fornecedor',
                    aboutTheCompany: 'Sobre a Empresa',
                    noInfo: 'Nenhuma informação',
                    productDetails: 'Detalhes do produto',
                    supplierProducts: 'Produtos do fornecedor',
                    category: 'Categoria',
                    noItems: 'Sem items',
                    seeAll: 'Ver todos',
                    sendMessage: 'Enviar Mensagem',
                    addToMyQMS: 'Adicionar no MyQMS',
                    addToWishList: 'Adicionar no Favoritos',
                    disconnect: 'Desconectar',
                    newWishListCreated: 'Novo favorito adicionado.',
                    wishlistRemoved: 'Removido dos favoritos',
                    avatarChanged: 'Foto alterada',
                    avatarDeleted: 'Foto excluída',
                    systemError: 'Erro no sistema',
                    failedToCreatedWishList: 'Erro ao adicionar favorito.',
                    creatingNewWishList: 'Adicionando novo favorito.',
                    doYouReallyWantToDisconect: 'Voce realmente gostaria de desconectar?',
                    newConnectionCreated: 'Nova conexão criada.',
                    connectionDeleted: 'Conexão excluída',
                    failedToCreateConnection: 'Erro ao adicionar nova conexão.',
                    thieConnedctionExistsAlready: 'Essa conexão ja existe.',

                    thisEmailOrPhoneAlreadyExistsAlready:
                        'Já existe um usuário utilizando esse e-mail ou telefone',

                    creatingNewConnection: 'Criando nova conexão.',
                    totalResultsFound: 'Total resultados encontrados, mostrando',
                    perPage: 'por pagina',
                    filters: 'Filtros',
                    loading: 'Carregando',
                    noResultsChangeFilter: 'Nenhum resultado encontrado, tente mudar o filtro.',
                    searchByCompanyName: 'Pesquise pelo nome da empresa ou produto',
                    search: 'Pesquisar',
                    permissions: 'Permissões',
                    addConnection: 'Adicionar conexão',
                    connections: 'Conexões',
                    searchConnections: 'Pesquisar conexões',
                    buyerDetails: 'Detalhes do Comprador',
                    preRequirementChooseSupplier: 'Pre requerimento para escolher um fornecedor.',
                    mostSearchedCategories: 'Categorias mais buscadas.',
                    certificationApproval: 'Certificação/Aprovação',
                    connectionPending: 'Conexao Pendente',
                    recommended: 'Recomendado',
                    filterProducts: 'Filtro de produtos',
                    serviceType: 'Tipo de documento',
                    products: 'Produtos',
                    radious: 'Raio',
                    mfsPreCheck: 'MyTS Pré-Check',
                    zeroMilesMeans: '0 Milhas (0 siginifica sem limites.)',
                    searchWishList: 'Busque na lista de favoritos',
                    delete: 'Deletar',
                    removeConnection: 'Deletar conexão',
                    removeNotification: 'Deletar notificação',
                    doYouReallyWantToDeleteConnection:
                        'Você realmente gostaria de deletar a conexão',
                    accept: 'Aceitar',
                    decline: 'Recusar',
                    accepted: 'Aceito',
                    pending: 'Pendente',
                    processing: 'Processando...',
                    searchChats: 'Pesquisar conversas',
                    searchContacts: 'Pesquisar contatos',
                    typeYourMessage: 'Digite sua mensagem.',
                    viewProfile: 'Ver Perfil',
                    file: 'Arquivo',
                    blockConnection: 'Bloquear conexão',
                    moveToTrash: 'Mover para lixeira',
                    validationProcessOverview: 'Visão Geral do Processo de Validação',
                    myBuyers: 'Meus Compradores',
                    mySuppliers: 'Meus Fornecedores',
                    myDocuments: 'Meus Documentos',
                    myClients: 'Meus Clientes',
                    myValidationProcess: 'Meu Processo de Validação',
                    myPrecheck: 'My Pre-Check',
                    typeReasonRejecting: 'Informe o motivo da reprovação',
                    rejectionReason: 'Motivo da reprovação',
                    documentsWaitingForYourAction: 'Documentos esperando sua ação.',
                    status: 'Status',
                    buyerRequirements: 'Requisitos do Comprador',
                    documentType: 'Tipo de documento',
                    documentName: 'Nome do documento',
                    documentCode: 'Código do documento',
                    revisionNumber: 'Número de revisão',
                    emissionDate: 'Data de emissão/revisão',
                    issueDate: 'Data da validade',
                    dateFormat: 'dd-MM-yyyy',
                    responsibleReview: 'Responsável pela revisão',
                    responsibleReviewEmail: 'Email do responsável pela revisão',
                    responsibleApproval: 'Responsável pela aprovação',
                    makeSureYouFillFields: 'Verifique o preenchimento dos campos',
                    responsibleApprovalEmail: 'Email do responsável pela aprovação',
                    addNewDocuments: 'Adicionar Novo Documento',
                    reviewSupplierForm: 'Revisão do Formulário do Fornecedor',
                    typerResponseSupplier: 'Escreva uma resposta ao fornecedor',
                    filterBy: 'Organizar por...',
                    filterNotificationsOptions: [
                        { value: 'all', label: 'Exibir todas' },
                        { value: 'read', label: 'Somente as lidas' },
                        { value: 'unread', label: 'Somente não lidas' },
                        { value: 'newer', label: 'Ordenar por novas' },
                        { value: 'older', label: 'Ordenar por antigas' },
                    ],
                    filterSupportCasesOptions: [
                        { value: 'all', label: 'Exibir todos' },
                        { value: 'open', label: 'Aberto' },
                        { value: 'in-progress', label: 'Em atendimento' },
                        { value: 'closed', label: 'Fechado' },
                    ],
                    active: 'Ativo',
                    expired: 'Expirado',
                    obsolete: 'Obsoleto',
                    expiredIn: 'Expira em',
                    daysLowerCase: 'dias',
                    view: 'Visualizar',
                    pendingApproval: 'Pendente Aprovação',
                    returned: 'Retornado',
                    myValidations: 'Minhas Validaçoes',
                    myCertificates: 'Minhas Certificações',
                    addNewCertificate: 'Adicionar Nova Certificação',
                    myProducts: 'Meus Produtos',
                    addNewProducts: 'Adicionar Novo Produto',
                    newProducts: 'Novo Produto',
                    pendingAction: 'Ação Pendente',
                    typeOfRequirements: 'Tipo de Requerimentos',
                    condition: 'Condição',
                    conditional: 'Condicional',
                    approvalDate: 'Data Aprovada',
                    expirationDate: 'Data de Expiração',
                    edit: 'Editar',
                    review: 'Revisar',
                    goBack: 'Voltar',
                    save: 'Salvar',
                    reject: 'Reprovar',
                    formDeleted: 'Formulário excluído',
                    supplierDeletedSuccessfully: 'Fornecedor excluído com sucesso',
                    serviceProviderDeletedSuccessfully: 'Prestador de Serviço excluído com sucesso',
                    deleteQuestion: 'Tem certeza que deseja excluir?',
                    reasonBuyerDenied: 'Razão do Declínio',
                    reason: 'Motivo',
                    filter: 'Filtro',
                    myProfile: 'Meu Perfil',
                    manage: 'Gerenciar',
                    wishlist: 'Favoritos',
                    message: 'Mensagem',
                    logout: 'Sair',
                    noData: 'Sem dados para exibir',
                    requirement: 'Requerimentos',
                    serviceOffered: 'Serviço Oferecido',
                    whatKindServiceProvide: 'Que tipo de serviço você oferece?',
                    descriptionOfService: 'Descrição do seu serviço',
                    addService: 'Adicionar Serviço',
                    addKindOfService: 'Adicionar outros tipos de serviços',
                    whatServicesAreOffered: 'Onde os serviços são oferecidos:',
                    entireWorld: 'Todo o mundo',
                    allRegions: 'Todas as regiões',
                    entireCountry: 'Todo o país',
                    chooseExpirationDate: 'Informe a validade',
                    addExpirationDate: 'Data de validade',
                    selectCountryFirst: 'Seleciona um pais primeiro*',
                    whichCategoryCanYouProvideService:
                        'Que categoria voce pode oferecer o serviço?',
                    supplierOverview: 'Visão dos Fornecedores',
                    supplierDetailsInfo: 'Dados dos Fornecedores',
                    supplierName: 'Nome do Fornecedor',
                    serviceProviderName: 'Nome do Prestador',
                    supplierInformation: 'Informação do Documento',
                    addNewUser: 'Adicionar novo usuário',
                    addNewSupplier: 'Adicionar Novo',
                    requestNewSupplier: 'Adicionando um novo Fornecedor',
                    requirements: 'Requerimentos',
                    supplierRequirements: 'Requerimentos do Fornecedor',
                    conditionStatus: 'Condição do Documento',
                    documentStatus: 'Status do Documento',
                    servicesOfered: 'Serviços Oferecidos',
                    reviewDocument: 'Revisão do Documento',
                    documentReturned: 'Documento Devolvido',
                    chooseIssueDate: 'Por favor, informe a Data de Emissão',
                    newService: 'Novo serviço',
                    newServiceProvider: 'Novo Prestador de serviço',
                    noDocumentsFound: 'Nenhum documento encontrado.',
                    inProgress: 'Em progresso',
                    pendingDocument: 'Pendente documentação',
                    inactive: 'Inativo',
                    denied: 'Negado',
                    supplierStatusRequirements: 'Status do Fornecedor - Requerimentos',
                    selectAOption: 'Selecione uma opção',
                    mfsApproval: 'MyTS Aprovação',
                    myApproval: 'Minha Aprovação',
                    report: 'Relatorios',
                    documents: 'Documentos',
                    isExpired: 'Expirado',
                    willExpire: 'Expira em',
                    currentFile: 'Arquivo atual',
                    uploadFileNotice: 'O arquivo antigo será substituído ao salvar um novo',
                    myRequirementsText: `Nesta seção, crie seus próprios requisitos para validar e manter seus fornecedores. Solicite documentos específicos, ou mesmo crie um formulário com diversas dúvidas`,
                    sendTheRequirements: 'Enviar requerimentos',
                    totalRequirements: 'Total requerimentos',
                    createNew: 'Criar Novo',
                    loginText: 'Construindo confiança em todos os elos cadeia de fornecedores.',
                    myServiceProvider: 'Meus Prestadores de Serviços',
                    myServiceProviders: 'Meus Prestadores de Serviços',
                    serviceProviderDetailsInfo: 'Dados dos Prestadores de Serviço',
                    serviceProviderDetails: 'Dados dos Prestadores de Serviço',
                    addNewServiceProvider: 'Adicionar Novo',
                    totalServiceProvider: 'Total',
                    totalSupplier: 'Total',
                    serviceProviderInformation: 'Dados do Prestador de Serviço',
                    noWishListFound: 'Nenhuma lista de favoritos encontrada neste momento',
                    add: 'Adicionar',
                    markAsRead: 'Marcar como Lidas',
                    noRequirementsFound: 'Requerimentos não encontrado.',
                    apply: 'Aplicar',
                    applyFilters: 'Aplicar Filtro',
                    cleanFilters: 'Limpar Filtro',
                    downloadCsv: 'Fazer download do CSV',
                    to: 'Para',
                    viewPage: 'Vizualizar pagina',
                    termsOfUse: 'Termos de Uso',
                    privacyPolicy: 'Política de Privacidade',
                    membershipAndSubscription: 'Assinaturas',
                    premiumSubscription: 'Assinatura Premium',
                    proSubscription: 'Assinatura Pro',
                    month: 'Mês',
                    renewdText: 'Renova em 9 de março de 2022',
                    paymentMethod: 'Método de Pagamento',
                    paymentComplete: 'Pagamento Concluído',
                    paymentProceed: 'Prossiga com o Pagamento',
                    ending: 'Terminando',
                    change: 'Alterar',
                    upgradeSubscription: 'Atualizar Assinatura',
                    cancelSubscription: 'Cancelar Assinatura',
                    contactMfsForMoreInformation:
                        'Entre em contato com o suporte da MyTS para obter mais informações',
                    businessType: 'Tipo de Negócio',
                    approvals: 'Aprovações',
                    phone: 'Telefone',
                    nameOnly: 'Nome',
                    companyDetails: 'Detalhes da Empresa',
                    youDontHaveAnyMessages: `Nenhuma mensagem`,
                    formOnOpenStatus: 'Formulário em status aberto',
                    pleaseChooseTheQuestionnaireThatBestFitsYourCompany:
                        'Por favor, escolha o questionário que melhor se adapta à sua empresa.',
                    chooseYourQuestionarie: 'Escolha o seu questionário',
                    emailAlreadyRegisterSupplierList:
                        'Esta empresa já estava adicionada na sua lista de fornecedores',
                    select: 'Selecione',
                    agreement: 'Acordo',
                    iAgree: 'Estou de acordo com os termos',
                    whatYouNeed: 'O que você precisa?',
                    form: 'Formulário',
                    procedure: 'Procedimento',
                    workInstruction: 'Instrução de Trabalho',
                    addDocument: 'Adicionar Documento',
                    resubmit: 'Reenviar',
                    generateNewRevision: 'Gerar Nova Revisão',
                    addNewRevision: 'Adicionar Nova Revisão',
                    member: 'Membro',
                    nonMember: 'Não Membro',
                    registerUsers: 'Registrar Usuários',
                    nonRegisterUsers: 'Usuários Não Registrados',
                    inviteSent: 'Convite enviado!',
                    requirementSent: 'Solicitação enviada',
                    requestSuccessful: 'Cadastro feito com sucesso!',
                    nonRegisterUsersLabel:
                        'Envie um convite para uma empresa se juntar a você no My Trusted Source!',
                    addSupplier: 'Adicionar Usuário',
                    draft: 'Rascunho',
                    newValidationProcess: 'Novo Processo de Validação',
                    whatIsTheNameOfTheProcess: 'Qual é o nome do processo?',
                    addTitle: 'Adicionar Título',
                    addSubTitle: 'Adicionar Subtítulo',
                    text: 'Texto',
                    dropdown: 'Lista', // Dropdown is commonly used in Portuguese as well.
                    formSavedDescriptionMyQMS: 'O formulário foi salvo como rascunho',
                    checkbox: 'Caixa de seleção',
                    date: 'Data',
                    attachDocument: 'Anexar Documento',
                    description: 'Descrição',
                    buyerValidationProcessRequirements:
                        'Processo de Validação do Comprador - Requisitos',
                    supplierGeneralStatus: 'Status Geral do Fornecedor',
                    clientsPerBusiness: 'Clientes por Negócio',
                    clientsPerRegion: 'Clientes por Região',
                    clientsMap: 'Mapa de Clientes',
                    competitors: 'Concorrentes',
                    validated: 'Validado',
                    clientDetailsInfo: 'Detalhe dos Clientes',
                    nothingSelected: 'Nada foi selecionado',
                    clientsOverview: 'Visão Geral dos Clientes',
                    missingRequirements: 'Requisitos ausentes',
                    selectAll: 'Selecionar Todos',
                    invalidDocument: 'Documento inválido',
                    incompleteDocument: 'Documento incompleto',
                    corruptDocument: 'Documento corrompido',
                    noFileSelected: 'Nenhum arquivo selecionado',
                    chooseAReason: 'Escolha um motivo',

                    fillTheField: 'Preencha o campo: ',
                    someErrorsFound: 'Alguns erros foram encontrados: ',
                    errorName: 'O nome deve ter ao menos 2 letras',
                    errorContactName: 'O nome deve ter ao menos 2 letras',
                    errorCompanyName: 'O nome da empresa deve ter ao menos 2 letras',
                    teamAccessSuccess:
                        'Usuário criado com sucesso e um e-mail foi enviado para finalização do cadastro',
                    teamAccessUpdated: 'Usuário alterado com sucesso!',
                    errorEmail: 'O e-mail é inválido',
                    errorDepartment: 'O departamento deve ter ao menos 2 letras',
                    errorPhone:
                        'O número de telefone deve seguir o formato internacional e164. exemplo: +5511999988777',
                    errorWebSite:
                        'Verifique o endereço do Website: http://www.google.com.br ou https://www.amazon.com.br',
                    startLocation:
                        'Oi, vamos começar com a sua Tipos de Serviço então. Em que país você está?',
                    findSuppliers: 'Agora, estou procurando fornecedores em ',
                    productLookingFor: ', que produto você está procurando?',
                    specifyRadius:
                        'Por favor, especifique o raio dentro do qual você deseja procurar fornecedores.',
                    specificCertification:
                        'Os fornecedores precisam ter uma certificação específica?',
                    clickResults: 'Clique aqui para ver os resultados.',
                    skipLocation: 'Localização pulada',
                    skipProduct: 'Produto pulado',
                    skipRadius: 'Raio pulado',
                    skipCertification: 'Certificação pulada',
                    next: 'Próximo',
                    back: 'Voltar',
                    skip: 'Pular',
                    previous: 'Anterior',
                    botTyping: 'Bot está digitando...',
                    buyerResponse: 'Resposta do Comprador',
                    saveDraft: 'Salvar Rascunho',
                    publishForm: 'Publicar Formulário',
                    generateNewVersion: 'Gerar nova versão',
                    formSaved: 'Formulário Salvo',
                    connect: 'Conectar',
                    resend: 'Reenviar',
                    send: 'Enviar',
                    approvalCertification: 'Aprovação/Certificação',
                    typeResponseToSupplier: 'Digite uma resposta ao fornecedor',
                    returnToReviewer: 'Voltar ao revisor',
                    updateAndReturnDocument: 'Atualizar e Voltar para Revisor',
                    updateAndSubmitDocument: 'Atualizar e Enviar Documento',
                    checkboxTitle: 'Título da Caixa de Seleção',
                    dateTitle: 'Título da Data',
                    option: 'Opção',
                    doYouWantToSendTheInvitation: 'Deseja zerar a senha e reenviar o convite?',
                    doYouWantToDeleteTheCertificate: 'Deseja excluir o certificado?',
                    doYouWantToDeleteTheProduct: 'Deseja excluir o produto?',
                    doYouWantToDeleteTheUser: 'Deseja excluir o usuário?',
                    doYouWantToGetThisCase: 'Deseja capturar esse chamado?',
                    doYouWantToCloseThisCase: 'Deseja encerrar esse chamado?',
                    getCaseSuccess: 'Capturado com sucesso! Agora faça o login novamente.',
                    closedCaseSuccess: 'Fechado com sucesso!',
                    doYouWantToBlockTheUser: 'Deseja bloquear o usuário?',
                    doYouWantReactivateTheUser: 'Deseja reativar o usuário?',
                    certificateRemoved: 'Certificado Removido',
                    productRemoved: 'Produto Removido',
                    blocked: '(Bloqueado)',
                    editCertificate: 'Editar Certificado',
                    addNewProduct: 'Adicionar Novo Produto',
                    accountUpdated: 'Conta Atualizada',
                    noCertificatesFound: 'Nenhum certificado encontrado',
                    noProductsFound: 'Nenhum produto encontrado',
                    yes: 'Sim',
                    no: 'Não',
                    locale: 'pt-BR',
                    currency: 'brl',
                    currencySymbol: 'R$',
                    pay: 'Pagar',
                    creation: 'Criado em',
                    lastUpdate: 'Última Atualização',
                    filteredLabel: 'Listados',
                    thereIsNoMyBuyers: 'Sem compradores adicionados',

                    supportCaseInfoTitle: 'ATENÇÃO: Login de Suporte Detectado',
                    supportCaseInfoText: `Você está conectado como um usuário da empresa `,

                    trialTitle: 'O seu período de TESTE terminou',
                    trialText:
                        'Entre em contato com o time de vendas pelo chat e escolha o melhor plano de assinatura pra você',

                    salesTitle: 'Você DESEJA acessar essa funcionalidade?',
                    salesText:
                        'Entre em contato com o time de vendas pelo chat e escolha o melhor plano de assinatura pra você',

                    modalPlanTitle: 'Você DESEJA acessar essa funcionalidade?',
                    premiumPlanText: 'Contrate o plano PREMIUM',
                    proPlanText: 'Contrate o plano PRO',
                    trialButtonStay: 'Continuar no plano Grátis',
                    planButtonStay: 'Continuar no plano atual',

                    trialButtonChoose: 'Escolher um plano de assinatura',
                    noResultsFilter: 'Não encontrado',
                    placeholderFilter: 'Digite...',
                    product: 'Produto',
                    productOverview: 'Visão Geral do Produto',
                    productDescription: 'Descrição do Produto',
                    attachment: 'Anexo',
                    chooseFile: 'Escolher arquivo',
                    makeDocumentPublic: 'Tornar o documento público',
                    doYouWantToDeleteTheRequirement: 'Deseja excluir o requisito?',
                    requirementRemoved: 'Requisito removido',
                    newUser: 'Novo Usuário',
                    editUser: 'Alterar Usuário',
                    noUsersCreatedYet: 'Nenhum usuário criado ainda',
                    department: 'Departamento*',
                    departmentNonMandatory: 'Departamento',
                    myWishlist: 'Favoritos',
                    createUser: 'Criar Usuário',
                    addServiceProvider: 'Adicionar Prestador de Serviço',
                    newKindOfCertificate: 'Novo tipo de certificado',
                    acceptForm: 'Aceitar Formulário',
                    formDeleted: 'Formulário excluído',
                    myPrecheckESG: 'My Pre-Check - ESG',
                    deletePhoto: 'Excluir foto',
                    changePhoto: 'Alterar foto',
                    certificateAddedSuccessfully: 'Certificado adicionado com sucesso',
                    productAddedSuccessfully: 'Produto adicionado com sucesso',
                    userDeletedSuccessfully: 'Usuário deletado com sucesso!',
                    userBlockedSuccessfully: 'Usuário bloqueado com sucesso!',
                    userReactivatedSuccessfully: 'Usuário reactivado com sucesso!',
                    changesSaved: 'Alterações salvas',
                    expiresInDays: 'Expira em 30 dias',
                    formSaved: 'Formulário salvo',
                    somethingWentWrong: 'Algo deu errado',
                    notification: 'Notificações',
                    successful: 'Bem-sucedido',
                    cancellationSuccessful: 'Cancelamento bem-sucedido',
                    documentApproved: 'Documento Aprovado',
                    documentAdded: 'Documento Adicionado com sucesso',
                    documentUpdated: 'Documento Atualizado',
                    documentResubmitted: 'Documento Reenviado',
                    whatToDo: 'O que você gostaria de fazer?',
                    requirementAdded: 'Requisito adicionado com sucesso',
                    profileViews: 'Visualizações do Perfil',
                    dataBase: 'Banco de dados',
                    companyLegalName: 'Nome Jurídico da Empresa',
                    companyAddress: 'Endereço da Empresa',
                    companyPhoneNumber: 'Número de Telefone da Empresa',
                    directPhoneNumber: 'Número de Telefone do Responsável',
                    activeCompanyProof: 'Por favor, imforme o CNPJ da empresa',
                    proofCompanyActiveRequirement:
                        '*Se não possuir CNPJ, favor informar o CPF do proprietario',
                    businessLicense: 'ALVARÁ DE FUNCIONAMENTO -',
                    validOperatingLicenseQuestion:
                        'AA empresa possui Licença de Operação válida fornecida pelo órgão regulador local?',
                    sanitaryLicense: 'LICENÇA SANITÁRIA -',
                    healthPermitRequirement:
                        'Se aplicável, a empresa deverá enviar a Alvará Sanitário expedido pela Vigilância Sanitária local e  quando aplicável: SIF, SIE, SISB. SIM ou selo Arte',
                    companyCertifiedQuestion: 'A empresa é certificada/aprovada em alguma norma?',
                    includeStandards: 'Por favor, inclua quais normas',
                    supplierCountQuestion:
                        'Quantos fornecedores a companhia possui em sua cadeia? ',
                    multipleFactoriesQuestion: 'A empresa possui mais de uma planta/fábrica?',
                    factoryCountQuestion: 'Quantas',
                    internalDocsControlQuestion:
                        'Como sua empresa controla seus documentos e procedimentos internos?',
                    supplierControlQuestion: 'Como sua empresa controla os fornecedores?',
                    supplierManagementQuestion: 'De que forma esses fornecedores são gerenciados?',
                    spreadsheetOption: 'Planilha',
                    ownSystemOption: 'Sistema próprio',
                    thirdPartySystemOption: 'Sistema de terceiros',
                    othersOption: 'Outros',
                    noControlOption: 'Não controlamos',
                    throughDocumentationOption: 'Através de Documentação',
                    through2ndPartyAuditOption: 'Através de Auditoria 2a Parte',
                    throughDocumentationAndAuditingOption: 'Através de Documentação e Auditoria',
                    platformNameQuestion: 'Qual é o nome da plataforma/sistema?',
                    averageDocumentsQuestion:
                        'Quantos documentos (em média), a empresa controla e valida de cada fornecedor?',
                    findNewSuppliersBuyersQuestion: 'Como sua empresa buscam novos fornecedores?',
                    recommendations: 'Recomendações',
                    sustainabilityPracticeQuestion:
                        'A empresa possui alguma prática voltada a sustentabilidade ou ESG?',
                    responsibleToFillPreCheck: 'Responsável por Preencher o Pré-check',
                    fullName: 'Nome completo',
                    basicPreCheck: 'Pré-Check Básico',
                    submit: 'Enviar',
                    openFile: 'Abrir Arquivo',
                    changeFile: 'Alterar Arquivo',
                    regulatoryOption: 'Regulatório',
                    gfsiRecognizedStandardsOption: 'Padrões reconhecidos pela GFSI',
                    socialOption: 'Social',
                    environmentalOption: 'Ambiental',
                    otherOption: 'Outro',
                    companyNameUser: 'Nome da Empresa / Usuário',
                    personalName: 'Nome Pessoal',
                    attachLatestResume: 'Por favor, anexe seu currículo mais recente',
                    bachelorsDegreeQuestion: 'Qual é a sua graduação?',
                    serviceStandardsQuestion:
                        'Quais padrões você pode fornecer serviço? (você pode escolher mais de um)',
                    complyLegislationQuestion:
                        'A empresa cumpre com a legislação trabalhista e tributária vigente aplicável a todas as atividades e locais da empresa onde opera?',
                    ifYesIncludeStandards: 'Se sim, por favor inclua quais padrões',
                    attachCertifications: 'Anexe as certificações',
                    noButLookingToImplement: 'Não mas estamos buscando implementar',
                    declareInformationIsAccurate:
                        'Eu declaro que as informações inseridas no relatório acima são precisas.',
                    declareWillingToSendInformationToBuyer:
                        'Eu declaro que estou disposto a enviar quaisquer informações adicionais solicitadas pelo comprador.',
                    labAnalysis: 'Análises laboratoriais',
                    pestControl: 'Controle de Pragas',
                    calibrationOfMeasuringInstruments: 'Calibração de Instrumentos de Medição',
                    training: 'Treinamento',
                    thirdPartyAudits: 'Auditorias de Terceiros',
                    secondPartyAudits: 'Auditoria 2ª parte',
                    internalAudits: 'Auditoria Interna',
                    cleaningCompany: 'Empresa de Limpeza',
                    consultant: 'Consultor',
                    itemNotAvailable: 'Este item nao esta disponivel para esse tipo de busca',
                    servicesOffered: 'Serviços Oferecidos',
                    servicesProvided: 'Serviço Prestado',
                    submitted: 'Submetido',
                    productsApprovedByMyTS: 'Produtos aprovados pela MyTS',
                    companyCategories: 'Categorias da empresa',
                    descriptionOfServiceProvided: 'Descrição do Serviço Prestado',
                    serviceProviderCategories: 'Categorias dos Serviços Prestados',
                    visibleIcon: `Torne sua empresa visível para outras empresas na ferramenta de  "Filtro"`,
                    cantChangeEmail: `Para alteração do e-mail principal, favor entrar em contato com suporte@myt-s.com ou clique no chat abaixo`,
                    headquarters: 'Matriz',
                    whatAreYouLookingFor: 'O que você está procurando na nossa solução?',
                    selectYourDepartment: 'Selecione seu Departamento',
                    selectYourCountry: 'Selecione seu País *',
                    findNewSuppliers: 'Encontrar novos fornecedores',
                    findNewConsultants: 'Encontrar novos consultores',
                    controlTheDocumentationOfMySuppliers:
                        'Controlar a documentação dos meus fornecedores',
                    controlMyInternalDocuments: 'Controlar meus documentos internos',
                    beValidatedForNewBuyers: 'Ser validado para novos compradores',
                    supplyToNewCompanies: 'Fornecer para novas empresas',
                    ceo: 'CEO',
                    procurement: 'Aquisição',
                    sales: 'Vendas',
                    salesRepresentative: 'Vendas (Representante)',
                    researchAndDevelopment: 'Pesquisa e Desenvolvimento (P&D)',
                    quality: 'Qualidade',
                    projectManagement: 'Gestão de Projetos',
                    canViewProfile: 'Pode ver Perfil',
                    canViewSupplierInformation: 'Pode ver informações do fornecedor',
                    canViewBuyerInformation: 'Pode ver informações do comprador',
                    canEditProfile: 'Pode editar Perfil',
                    connection: 'Conexão',
                    canView: 'Pode ver',
                    canEdit: 'Pode editar',
                    receiveNotifications: 'Receber notificações',
                    showMyWishlistOnTheDashboard: 'Mostrar favoritos no Painel',
                    canUseIt: 'Pode usá-lo',
                    showMessagesOnTheDashboard: 'Mostrar mensagens no Painel',
                    profileView: 'Visualização do Perfil',
                    showProfileViewsOnTheDashboard: 'Mostrar visualizações do perfil no Painel',
                    myQMS: 'Meu QMS',
                    myProcessOverview: 'Visão Geral do Meu Processo',
                    showOnDashboard: 'Mostrar no Painel',
                    role: 'Função*',
                    useForSupplier: 'Usar para Fornecedor',
                    useForBuyer: 'Usar para Comprador',
                    useForAdministrative: 'Usar para Administrativo',
                    visible: 'Visível',
                    notVisible: 'Não Visível',
                    selectARole: 'Selecione um Tipo de conta',
                    almostFinished: 'Quase terminado! Obrigado por esperar.',
                    encryptingData:
                        'Criptografando os dados do usuário para segurança. Por favor, aguarde.',
                    backToWebsite: 'Voltar ao site',
                    contact: 'Contato',
                    enterProduct: 'Digite o produto',
                    selectACountry: 'Selecione um País',
                    enterState: 'Digite o estado',
                    enterCity: 'Digite a cidade',
                    brazil: 'Brasil',
                    unitedstates: 'Estados Unidos',
                    mexico: 'México',
                    unitedkingdom: 'Reino Unido',
                    china: 'China',
                    italy: 'Itália',
                    canada: 'Canadá',
                    spain: 'Espanha',
                    netherlands: 'Holanda',
                    topCountries: 'Principais Países',
                    map: 'Mapa',
                    connectsBuyersAndSuppliers:
                        'Conecte com compradores e fornecedores para novas oportunidades de negócios.',
                    sellProductsToAWiderAudience: 'Venda produtos para um público mais amplo.',
                    controlRequirementsAndDueDates:
                        'Controle requerimentos e prazos de vencimento.',
                    controlInternalDocuments:
                        'Controle documentacao interna - Centralize envio, revisão e aprovação de documentos internos.',
                    createDetailedProfiles:
                        'Crie perfis detalhados apresentando conhecimento, experiência e certificações.',
                    setCompetitivePricing: 'Defina preços competitivos para seus serviços.',
                    promoteServicesThroughAdvertising:
                        'Promova serviços por meio de publicidade direcionada e marketing de conteúdo na plataforma.',
                    buyerOrSupplier: 'Comprador ou fornecedor',
                    offerYourServices: 'Ofereça seus serviços aos clientes na plataforma',
                    allFieldsRequired:
                        'Todos os campos abaixo são necessários para se qualificar para o teste premium.',
                    fullAddress: 'Endereço completo (Estado, Cidade, CEP)',
                    aboutYourCompany: 'Sobre a Sua Empresa',
                    distributionRequired: 'Distribuição (pelo menos uma)',
                    iconInfoTitle:
                        'Se a condição for verde = Status ativo. Se a condição for Amarelo = Aprovação Pendente. Se a condição for Cinza = Documento Pendente (Do Fornecedor). Se a condição for Vermelho = Expirado ou Documento Negado',
                    fullAddressInstruction:
                        'Endereço completo (Estado, Cidade, CEP)\nInforme seu endereço completo para obter 30 dias de teste do plano premium',

                    addForPremiumTrial:
                        'Adicione pelo menos um para obter 30 dias de teste do plano premium.',
                    completeCompanyInfo:
                        'Descreva sua empresa para obter 30 dias de teste do plano premium',
                    responsibleReply: 'Responsavel por responder',
                    private: 'Privado',
                    sendReminder: 'Enviar Lembrete',
                    reminderSent: 'Lembrete enviado!',
                    daysForReminder: 'Dias para lembrete',
                    responsible: 'Responsavel',
                    list: 'Listagem',
                    charts: 'Gráficos',
                    generateReport: 'Gerar Gráfico',
                    expiredRequirement: 'Requerimento Expirado',
                    companyIdentification: 'Identificação da Empresa',
                    segment: 'Segmento',
                    numberEmployees: 'Número de Empregados',
                    qualityManagement: 'Gestão de Qualidade',
                    requirementsCanBeEdit: 'Requerimentos que podem ser editados',
                    updateServiceProvider: 'Atualizar Prestador de Serviço',
                    saveWithoutSubmit: 'Salvar sem enviar',
                    perishables: 'Perecíveis',
                    food: 'Alimentos',
                    nonFoodHlp: 'Não Alimentos – Higiele e Limpeza',
                    nonFoodBazaar: 'Não Alimentos – Utilidades Domésticas',
                }
                break
            default:
                return {
                    perishables: 'Perishables',
                    food: 'Food',
                    nonFoodHlp: 'Non-Food – Hygiene and Cleaning',
                    nonFoodBazaar: 'Non-Food – Household',
                    saveWithoutSubmit: 'Save without submit',
                    updateServiceProvider: 'Update Service Provider',
                    requirementsCanBeEdit: 'Requirements that can be edit',
                    companyIdentification: 'Company Identification',
                    segment: 'Segment',
                    numberEmployees: 'Number of Employees',
                    qualityManagement: 'Quality Management',
                    expiredRequirement: 'Expired Requirement',
                    generateReport: 'Generate report',
                    charts: 'Charts',
                    list: 'List',
                    responsible: 'Responsible',
                    daysForReminder: 'Days for reminder',
                    reminderSent: 'Reminder sent!',
                    sendReminder: 'Send Reminder',
                    private: 'Private',
                    responsibleReply: 'Responsible to Reply',
                    addForPremiumTrial: 'Add at least one to get 30 days of premium plan trial.',
                    completeCompanyInfo:
                        'Complete your company information to get 30 days of premium plan trial',

                    fullAddressInstruction:
                        'Full address (State, City, ZIP Code)\nComplete your full address to get 30 days of premium plan trial',
                    iconInfoTitle:
                        'If condition is green = Status active. If condition is Yellow = Pending Approval. If condition is Gray = Pending Document (From Supplier). If condition is Red = Expired or Document Denied',
                    allFieldsRequired:
                        'All the fields below are required to qualify for the premium trial.',
                    fullAddress: 'Full address (State, City, ZIP Code)',
                    aboutYourCompany: 'About Your Company',
                    distributionRequired: 'Distribution (at least one)',
                    offerYourServices: 'Offer your services to clients on the platform',
                    buyerOrSupplier: 'Buyer or supplier',
                    promoteServicesThroughAdvertising:
                        'Promote services through targeted advertising and content marketing on the platform.',
                    setCompetitivePricing: 'Set competitive pricing for their services.',
                    createDetailedProfiles:
                        'Create detailed profiles showcasing expertise, experience, and certifications.',
                    controlInternalDocuments:
                        'Control internal documents - Centralize submission, review and approval of internal documents.',
                    controlRequirementsAndDueDates: 'Control requirements and due dates.',
                    sellProductsToAWiderAudience: 'Sell products to a wider audience.',
                    connectsBuyersAndSuppliers:
                        'Connects buyers and suppliers for new business opportunities.',
                    map: 'Map',
                    topCountries: 'Top Countries',
                    brazil: 'Brazil',
                    unitedstates: 'United States',
                    mexico: 'Mexico',
                    unitedkingdom: 'United Kingdom',
                    china: 'China',
                    italy: 'Italy',
                    canada: 'Canada',
                    spain: 'Spain',
                    netherlands: 'Netherlands',

                    backToWebsite: 'Back to website',
                    contact: 'Contact',
                    enterProduct: 'Enter product',
                    selectACountry: 'Select a Country',
                    enterState: 'Enter a State',
                    enterCity: 'Enter a City',

                    almostFinished: 'Almost finished! Thank you for waiting.',
                    encryptingData: "Encrypting our user's data for safety. Please wait.",

                    selectARole: 'Select a Role',
                    quality: 'Quality',
                    ceo: 'CEO',
                    procurement: 'Procurement',
                    sales: 'Sales',
                    salesRepresentative: 'Sales (Representative)',
                    researchAndDevelopment: 'Research and Development (R&D)',
                    projectManagement: 'Project Management',
                    supplyToNewCompanies: 'Supply to new companies',
                    beValidatedForNewBuyers: 'Be validated for new buyers',
                    controlMyInternalDocuments: 'Control my internal documents',
                    controlTheDocumentationOfMySuppliers:
                        'Control the documentation of my suppliers',
                    findNewSuppliers: 'Find new suppliers',
                    findNewConsultants: 'Find new consultants',
                    selectYourCountry: 'Select your Country *',
                    whatAreYouLookingFor: 'What are you looking for on our solution?',
                    selectYourDepartment: 'Select your Department',
                    selectAll: 'Select All',
                    missingRequirements: 'Missing requirements',
                    invalidDocument: 'Invalid Document',
                    incompleteDocument: 'Incomplete Document',
                    corruptDocument: 'Corrupt Document',
                    noFileSelected: 'No file selected',
                    chooseAReason: 'Choose a reason',
                    noAddress: 'No Address',
                    nameNonMandatory: 'Name',
                    seeMore: 'See more...',
                    numUsers: 'Number of Users',
                    stateNonMandatory: 'State',
                    name: 'Name',
                    formating: 'Formatando',
                    emailNonMandatory: 'E-mail',
                    password: 'Password',
                    actions: 'Actions',
                    document: 'Document',
                    forgotPassword: 'Forgot Password',
                    enter: 'Sign In',
                    noAccount: `Don't have an account?`,
                    createCount: 'Sign Up',
                    singup: 'Sign Up',
                    selectAccountType: 'Select your account type',
                    selectTheFields: 'Select the Fields',
                    accountType: 'Account Type',
                    titleExample: 'Title Example',
                    subTitleExample: 'Subtitle Example',
                    imageInputTitle: 'Image Input Title',
                    selectImage: 'Select Image',
                    selectFormComponent: 'Select a form component above to get started',
                    dropdownTitle: 'Dropdown Title',
                    textInputTitle: 'Text Input Title',
                    documentInputTitle: 'Document Input Title',
                    supplierTitle: 'Supplier',
                    supplierDescription: 'I sell products to wholesale buyers',
                    buyerTitle: 'Buyer',
                    buyerDescription: 'I purchase products at wholesale',
                    serviceProviderTitle: 'Service Provider',
                    serviceProviderDescription: 'I sell services to buyers and suppliers',
                    businessInformation: 'Supplier Business information',
                    companyName: 'Company Name*',
                    companyNameNonMandatory: 'Company Name',
                    contactName: 'Contact Name*',
                    name: 'Name*',
                    email: 'E-mail*',
                    phoneNumber: 'Phone Number*',
                    emailReadOnly: 'E-mail (Read Only)',
                    phoneNumberReadOnly: 'Phone Number (Read Only)',
                    phoneNumberNonMandatory: 'Phone Number',
                    rulesPhoneNumberHeader: 'Rules for Phone Number',
                    rulesPhoneNumberText: 'Use International Phone Number as +9900099995566',
                    passwordhasBeenChanged: 'Password has been successfully changed',
                    rulesPasswordHeader: 'Rules for Password',
                    rulesPasswordText:
                        'Password must have at least 8 characters, include at least 1 Capital letter, 1 number and 1 special character (!@#$%^&*)',
                    phoneNumberNonMandatory: 'Phone Number',
                    password: 'Password*',
                    passwordNotMatch: 'New Password and Confirm Password not match',
                    confirmPassword: 'Confirm Password*',
                    address: 'Address*',
                    autoFill: `I'd like to fill Address by myself`,
                    country: 'Country*',
                    countryNonMandatory: 'Country',
                    confirm: 'Confirm',
                    cancel: 'Cancel',
                    state: 'State*',
                    city: 'City',
                    zip: 'ZIP Code',
                    website: 'Website',
                    requiredFields: '*Required fields',
                    nextStep: 'Next step 1/2',
                    additionalInformation: 'Additional Information',
                    typeOfBusiness: 'Type of Business',
                    addBusiness: 'Add Business',
                    typeOfCategoryCompanyFit: 'What type of category does your company fit in:',
                    doYouHaveCertificationApproval: 'Do you have any certifications/approvals?',
                    whatProductsAreProducedSupplied: 'What products are produced/supplied?',
                    whatAreThePreRequirements:
                        'What are the pre-requirements when choosing a supplier?',
                    addProduct: 'Add Product',
                    descriptionProduct: 'Description of your products',
                    aboutCompany: 'About your company',
                    distribution: 'Distribution',
                    distributionState: 'State',
                    national: 'National',
                    international: 'International',
                    prevStep: 'Previous step',
                    createAccount: 'Create account',
                    completeYourProfile: 'Complete your profile',
                    profileCompleted: 'Profile completed!',
                    completeProfile: 'Complete Profile',
                    hello: 'Hello',
                    recommendedForYou: 'Recommended for You',
                    myWishList: 'My Wishlist',
                    myWidgets: 'My Widgets',
                    messages: 'Messages',
                    views: 'Views',
                    supplierValidaonProcess: 'Supplier Validation Process - Requirements',
                    suppplierGeneralStatus: 'Supplier General Status',
                    supplierPerCategory: 'MyTS Category',
                    documentControl: 'Document Control',
                    expiredDocument: 'Expired Document',
                    supplierPerRegion: 'Supplier per Region',
                    supplierMap: 'Supplier Map',
                    reply: 'Reply',
                    days: 'Days',
                    companyInformation: 'Company Information',
                    companyMyInformation: 'My User Information',
                    accountInformation: 'Account Information',
                    editInformation: 'Edit Information',
                    editMyUserInformation: 'Edit My Information',
                    deleteAccount: 'Delete Account',
                    myUsers: 'My Users',
                    supportCases: 'Support Cases',
                    askSupport: 'Ask for Support',
                    caseSentSuccess: 'Support Case oppened successfully!',
                    sendCase: 'Send Case',
                    currentPassword: 'Current Password*',
                    newPassword: 'New Password*',
                    changePassword: 'Change Password',
                    myCreditCards: 'My Credit/Debit Cards',
                    addNewCard: 'Add new card',
                    saveAfterAnyUpdate: '',
                    myRequirements: 'My Requirements',
                    other: 'Other',
                    addNewRequirement: 'Add new requirement',
                    updateProfile: 'Update profile',
                    editRequirement: 'Edit requirement',
                    updateInformation: 'Update Information',
                    supplierDetails: 'Supplier Details',
                    aboutTheCompany: 'About the Company',
                    noInfo: 'No info',
                    productDetails: 'Product Details',
                    supplierProducts: 'Supplier products',
                    category: 'Category',
                    noItems: 'No itens',
                    seeAll: 'See all',
                    sendMessage: 'Send Message',
                    addToMyQMS: 'Add to MyQMS',
                    addToWishList: 'Add Wishlist',
                    disconnect: 'Disconnect',
                    newWishListCreated: 'New wishlist created.',
                    wishlistRemoved: 'Removed from Wishlist',
                    avatarChanged: 'Avatar has been changed',
                    avatarDeleted: 'Avatar has been deleted',
                    systemError: 'System error',
                    failedToCreatedWishList: 'Failed to create wishlist.',
                    creatingNewWishList: 'Creating new Wishlist.',
                    doYouReallyWantToDisconect: 'Do you really want to disconnect?',
                    newConnectionCreated: 'New connection created.',
                    connectionDeleted: 'Connection deleted',
                    failedToCreateConnection: 'Failed to create connection.',
                    thieConnedctionExistsAlready: 'This connection exists already.',
                    thisEmailOrPhoneAlreadyExistsAlready:
                        'There is an user already using this email or phone',
                    creatingNewConnection: 'Creating new Connection.',
                    totalResultsFound: 'Total results found, showing',
                    perPage: 'per page',
                    filters: 'Filters',
                    loading: 'Loading',
                    noResultsChangeFilter: 'No results, try changing your filters.',
                    searchByCompanyName: 'Search by company name or product',
                    search: 'Search',
                    permissions: 'Permissions',
                    addConnection: 'Add Connection',
                    connections: 'Connections',
                    searchConnections: 'Search connections',
                    buyerDetails: 'Buyer Details',
                    preRequirementChooseSupplier: 'Pre-requirements for choosing a supplier',
                    mostSearchedCategories: 'Most searched categories',
                    certificationApproval: 'Certifications/Approval',
                    connectionPending: 'Connection Pending',
                    processing: 'Processing...',
                    recommended: 'Recommended',
                    filterProducts: 'Filter Products',
                    serviceType: 'Document Type',
                    products: 'Products',
                    radious: 'Radius',
                    mfsPreCheck: 'Pre-Check',
                    zeroMilesMeans: '0 Miles (0 means no limit.)',
                    searchWishList: 'Search wishlist',
                    delete: 'Delete',
                    removeConnection: 'Remove Connection',
                    removeNotification: 'Remove Notification',
                    doYouReallyWantToDeleteConnection:
                        'Do you really want to delete the connection?',
                    accept: 'Accept',
                    decline: 'Decline',
                    accepted: 'Accepted',
                    pending: 'Pending',
                    searchChats: 'Search chats',
                    searchContacts: 'Search contacts',
                    typeYourMessage: 'Type your message.',
                    viewProfile: 'View Profile',
                    file: 'File',
                    blockConnection: 'Block Connection',
                    moveToTrash: 'Move to Trash',
                    validationProcessOverview: 'My Process Overview',
                    myBuyers: 'My Buyers',
                    mySuppliers: 'My Suppliers',
                    myDocuments: 'My Documents',
                    myClients: 'My Clients',
                    myValidationProcess: 'MyTS Pre-check',
                    myPrecheck: 'My Pre-Check',
                    myPrecheckFoodSafety: 'My Pre-Check - Food Safety',
                    myPrecheckESG: 'My Pre-Check - ESG',
                    typeReasonRejecting: 'Type the reason for rejecting',
                    rejectionReason: 'Reason document was rejected',
                    documentsWaitingForYourAction: 'Documents waiting for your action.',
                    status: 'Status',
                    buyerRequirements: 'Buyer Requirements',
                    documentType: 'Document Type',
                    documentName: 'Document Name',
                    documentCode: 'Document Code',
                    revisionNumber: 'Revision Number',
                    emissionDate: 'Submission/Revision Date',
                    issueDate: 'Expiration Date',
                    dateFormat: 'MM-dd-yyyy',
                    responsibleReview: 'Responsible for Review',
                    responsibleReviewEmail: 'Responsible for Review Email',
                    responsibleApproval: 'Responsible for Approval',
                    makeSureYouFillFields: 'Make sure you fill fields',
                    responsibleApprovalEmail: 'Responsible for Approval Email',
                    addNewDocuments: 'Add New Documents',
                    reviewSupplierForm: 'Review Supplier Form',
                    typerResponseSupplier: 'Type a response to the supplier',
                    filterBy: 'Filter by...',
                    filterNotificationsOptions: [
                        { value: 'all', label: 'Show all' },
                        { value: 'read', label: 'Only read' },
                        { value: 'unread', label: 'Only unread' },
                        { value: 'newer', label: 'Sort by newer' },
                        { value: 'older', label: 'Sort by older' },
                    ],
                    filterSupportCasesOptions: [
                        { value: 'all', label: 'Show all' },
                        { value: 'open', label: 'Open' },
                        { value: 'in-progress', label: 'In Progress' },
                        { value: 'closed', label: 'Closed' },
                    ],
                    active: 'Active',
                    expired: 'Expired',
                    obsolete: 'Obsolete',
                    expiredIn: 'Expired in',
                    daysLowerCase: 'days',
                    view: 'View',
                    pendingApproval: 'Pending Approval',
                    returned: 'Returned',
                    myValidations: 'My Validations',
                    myCertificates: 'My Certificates',
                    addNewCertificate: 'Add new Certificate',
                    myProducts: 'My Products',
                    addNewProducts: 'Add new Product',
                    newProducts: 'New Product',
                    pendingAction: 'Pending Action',
                    typeOfRequirements: 'Type of Requirements',
                    condition: 'Condition',
                    conditional: 'Conditional',
                    approvalDate: 'Approval Date',
                    expirationDate: 'Expiration Date',
                    edit: 'Edit',
                    review: 'Review',
                    goBack: 'Go Back',
                    save: 'Save',
                    reject: 'Reject',
                    formDeleted: 'Form deleted',
                    reasonBuyerDenied: 'Reason buyer denied',
                    reason: 'Reason',
                    filter: 'Filter',
                    myProfile: 'My Profile',
                    manage: 'Manage',
                    wishlist: 'Wishlist',
                    message: 'Message',
                    logout: 'Logout',
                    noData: 'No data found',
                    requirement: 'Requirement',
                    serviceOffered: 'Service Offered',
                    whatKindServiceProvide: 'What kind of service can you provide?',
                    descriptionOfService: 'Description of your service',
                    addService: 'Add Service',
                    addKindOfService: 'Add Kind of Service',
                    whatServicesAreOffered: 'Where services are offered:',
                    entireWorld: 'Entire World',
                    allRegions: 'All regions',
                    entireCountry: 'Entire Country',
                    chooseExpirationDate: 'Choose a expiration date',
                    addExpirationDate: 'Add Expiration Date',
                    acceptForm: 'Accept Form',
                    supplierDeletedSuccessfully: 'Supplier deleted successfully',
                    serviceProviderDeletedSuccessfully: 'Service Provider deleted successfully',
                    deleteQuestion: 'Are you sure you want to delete?',
                    selectCountryFirst: 'Select a country first *',
                    whichCategoryCanYouProvideService: 'Which category can you provide service?',
                    newService: 'New Service',
                    newServiceProvider: 'New Service Provider',
                    newKindService: 'New Kind of Service',
                    supplierOverview: 'Supplier Overview',
                    supplierDetailsInfo: 'Suppliers Details Info',
                    supplierName: 'Supplier Name',
                    serviceProviderName: 'Service Provider Name',
                    supplierInformation: 'Document Information',
                    addNewUser: 'Add new User',
                    addNewSupplier: 'Add New',
                    requestNewSupplier: 'Requesting a new supplier',
                    requirements: 'Requirements',
                    supplierRequirements: 'Supplier Requirements',
                    conditionStatus: 'Document Condition',
                    documentStatus: 'Document Status',
                    servicesOfered: 'Services Offered',
                    reviewDocument: 'Review Document',
                    documentReturned: 'Document Returned',
                    chooseIssueDate: 'Please, choose a Issue Date',
                    noDocumentsFound: 'No documents found',
                    inProgress: 'In Progress',
                    pendingDocument: 'Pending Document',
                    inactive: 'Inactive',
                    denied: 'Denied',
                    supplierStatusRequirements: 'Supplier Status - Requirements',
                    selectAOption: 'Select a option',
                    mfsApproval: 'MyTS Approval',
                    myApproval: 'My Approval',
                    report: 'Report',
                    documents: 'Documents',
                    isExpired: 'Is expired',
                    willExpire: 'Will expire in',
                    currentFile: 'Current File',
                    uploadFileNotice: 'If you insert a new file you will replace the old one',
                    myRequirementsText: `In this section, create your own requirements for validating and
                                maintaining your suppliers.Request specific documents, or even
                                create a form with several questions`,
                    sendTheRequirements: 'Send the Requirements',
                    totalRequirements: 'Total requirements',
                    createNew: 'Create New',
                    loginText: 'Choose your source and together, we can change the world.',
                    myServiceProvider: 'My Service Provider',
                    myServiceProviders: 'My Service Providers',
                    serviceProviderDetailsInfo: 'Service Provider Details Info',
                    serviceProviderDetails: 'Service Provider Details',
                    addNewServiceProvider: 'Add New',
                    totalServiceProvider: 'Total',
                    totalSupplier: 'Total',
                    serviceProviderInformation: 'Service Provider Information',
                    noWishListFound: 'No wishlist found at this moment.',
                    add: 'Add',
                    markAsRead: 'Mark as read',
                    noRequirementsFound: 'No requirements found',
                    apply: 'Apply',
                    applyFilters: 'Apply Filters',
                    cleanFilters: 'Clean Filters',
                    downloadCsv: 'Download report as CSV',
                    to: 'To',
                    viewPage: 'View page',
                    termsOfUse: 'Terms of Use',
                    membershipAndSubscription: 'Membership & Subscription',
                    premiumSubscription: 'Premium Subscription',
                    proSubscription: 'Pro Subscription',
                    month: 'Month',
                    renewdText: 'Renews on March 9, 2022',
                    paymentMethod: 'Payment Method',

                    paymentComplete: 'Payment Complete',
                    paymentProceed: 'Proceed with the Payment',

                    ending: 'Ending',
                    change: 'Change',
                    upgradeSubscription: 'Upgrade Subscription',
                    CancelSubscription: 'Cancel Subscription',
                    contactMfsForMoreInformation: 'Contact MyTS Support for more information',
                    businessType: 'Business Type',
                    approvals: 'Approvals',
                    phone: 'Phone',
                    nameOnly: 'Name',
                    companyDetails: 'Company Details',
                    youDontHaveAnyMessages: `You don't have any messages yet.`,
                    formOnOpenStatus: 'Form on open status',
                    myValidationProcess: 'My Validation Process',
                    pleaseChooseTheQuestionnaireThatBestFitsYourCompany:
                        'Please, choose the questionnaire that best fits your company.',
                    chooseYourQuestionarie: 'Choose your questionarie',
                    emailAlreadyRegisterSupplierList:
                        'Email already register to your supplier list.',
                    select: 'Select',
                    agreement: 'Agreement',
                    iAgree: 'i agree with the terms and conditions',
                    whatYouNeed: 'What do you need?',
                    form: 'Form',
                    procedure: 'Procedure',
                    workInstruction: 'Work Instruction',
                    addDocument: 'Add Document',
                    resubmit: 'Resubmit',
                    generateNewRevision: 'Generate New Revision',
                    addNewRevision: 'Add New Revision',
                    registerUsers: 'Register Users',
                    member: 'Member',
                    nonMember: 'Non Member',
                    nonRegisterUsers: 'Non Register Users',
                    inviteSent: 'Invite Sent!',
                    requirementSent: 'Requirements Sent',
                    requestSuccessful: 'Request Successful!',
                    nonRegisterUsersLabel:
                        'Send an invite for a company to join you on My Trusted Source!',
                    addSupplier: 'Add User',
                    draft: 'Draft',
                    newValidationProcess: 'New Validation Process',
                    whatIsTheNameOfTheProcess: 'What is the name of the process?',
                    addTitle: 'Add Title',
                    addSubTitle: 'Add SubTitle',
                    text: 'Text',
                    dropdown: 'Dropdown',
                    formSavedDescriptionMyQMS: 'Your validation form has been saved as a draft',
                    checkbox: 'Checkbox',
                    date: 'Date',
                    attachDocument: 'Attach Document',
                    description: 'Description',
                    buyerValidationProcessRequirements: 'Buyer Validation Process - Requirements',
                    supplierGeneralStatus: 'Supplier General Status',
                    clientsPerBusiness: 'Clients per Business',
                    clientsPerRegion: 'Clients per Region',
                    clientsMap: 'Clients Map',
                    competitors: 'Competitors',
                    validated: 'Validated',
                    clientDetailsInfo: 'Client Details Info',
                    nothingSelected: 'Nothing was selected',
                    clientsOverview: 'Clients Overview',
                    invalidDocument: 'Invalid Document',
                    incompleteDocument: 'Incomplete Document',
                    corruptDocument: 'Corrupt Document',
                    chooseAReason: 'Choose a reason',
                    startLocation:
                        "Let's start with type of services then. Are you looking for a specific Service Type?",
                    startLocationInitialText:
                        'What about location then. Which country you want to find suppliers?',
                    findSuppliers: "Now, I'm looking for suppliers in ",
                    productLookingFor: ', what product are you looking for?',
                    specifyRadius:
                        'Please specify the radius within which you want to look for suppliers.',
                    specificCertification:
                        'Do the suppliers need to have a specific certification?',
                    clickResults: 'Click here to view the results.',
                    skipLocation: 'Location skipped',
                    skipProduct: 'Product skipped',
                    skipRadius: 'Radius skipped',
                    skipCertification: 'Certification skipped',
                    next: 'Next',
                    back: 'Back',
                    skip: 'Skip',
                    previous: 'Previous',
                    botTyping: 'Bot is typing...',

                    fillTheField: 'Fill the field: ',
                    someErrorsFound: 'Some errors was found: ',
                    errorName: 'Name must contain at least 2 characters',
                    errorContactName: 'Name must contain at least 2 characters',
                    errorCompanyName: 'Company Name must contain at least 2 characters',
                    teamAccessSuccess:
                        'Account created successfully. The new user will receive an email to complete the registration.',
                    teamAccessUpdated: 'Account updated successfully!',
                    errorEmail: 'Your e-mail is invalid',
                    errorDepartment: 'Department must contain at least 2 characters',
                    errorPhone:
                        'The phone number must be in e164 international format ex: +9900099995566',
                    errorWebSite:
                        'Wrong website format: http://www.google.com or https://www.amazon.com',
                    buyerResponse: 'Buyer response',
                    saveDraft: 'Save Draft',
                    publishForm: 'Publish Form',
                    generateNewVersion: 'Generate new version',
                    formSaved: 'Form Saved',
                    connect: 'Connect',
                    resend: 'Resend',
                    send: 'Send',
                    approvalCertification: 'Approval/Certification',
                    typeResponseToSupplier: 'Type a response to the supplier',
                    returnToReviewer: 'Return to reviewer',
                    updateAndReturnDocument: 'Update and Return Document',
                    updateAndSubmitDocument: 'Update and Submit Document',
                    checkboxTitle: 'Checkbox Title',
                    dateTitle: 'Date Title',
                    option: 'Option',
                    doYouWantToSendTheInvitation:
                        'Would you like to reset the password and send a new invite?',
                    doYouWantToDeleteTheCertificate: 'Do you want to delete the certificate?',
                    doYouWantToDeleteTheProduct: 'Do you want to delete the product?',
                    doYouWantToDeleteTheUser: 'Do you want to delete the user?',
                    doYouWantToGetThisCase: 'Do you want to get this case?',
                    doYouWantToCloseThisCase: 'Do you want to close this case?',
                    getCaseSuccess: 'Captured sucessful! Proceed to login',
                    closedCaseSuccess: 'Closed successful!',
                    doYouWantToBlockTheUser: 'Do you want to block the user?',
                    doYouWantReactivateTheUser: 'Do you want to reactivate the user?',
                    certificateRemoved: 'Certificate Removed',
                    productRemoved: 'Product Removed',
                    blocked: '(Blocked)',
                    editCertificate: 'Edit Certificate',
                    addNewProduct: 'Add New Product',
                    accountUpdated: 'Account Updated',
                    noCertificatesFound: 'No certificates found',
                    noProductsFound: 'No products found',
                    yes: 'Yes',
                    no: 'No',
                    locale: 'en',
                    currency: 'usd',
                    currencySymbol: '$',
                    pay: 'Pay',
                    creation: 'Creation',
                    lastUpdate: 'Last Update',
                    filteredLabel: 'Filtered',
                    thereIsNoMyBuyers: 'There is no buyer added',

                    supportCaseInfoTitle: 'WARNING: Support Case Login Detected',
                    supportCaseInfoText: `You're connected as an user from `,

                    trialTitle: 'Your TRIAL has ended',
                    trialText:
                        'Contact sales team on Chat icon and choose the best subscription for you',

                    salesTitle: 'Want TO USE this feature?',
                    salesText:
                        'Contact sales team on Chat icon and choose the best subscription for you',

                    modalPlanTitle: 'Want TO USE this feature?',
                    premiumPlanText: 'Get PREMIUM',
                    proPlanText: 'Get PRO',

                    trialButtonStay: 'Stay as a Free',
                    planButtonStay: 'Stay as Is',

                    trialButtonChoose: 'Choose Subscription',
                    noResultsFilter: 'Not found',
                    placeholderFilter: 'Type...',
                    product: 'Product',
                    productOverview: 'Product Overview',
                    productDescription: 'Product Description',
                    attachment: 'Attachment',
                    chooseFile: 'Choose file',
                    makeDocumentPublic: 'Make document public',
                    doYouWantToDeleteTheRequirement: 'Do you want to delete the requirement?',
                    requirementRemoved: 'Requirement removed',
                    search: 'Search',
                    newUser: 'New User',
                    editUser: 'Edit User',
                    noUsersCreatedYet: 'No users created yet',
                    department: 'Department*',
                    departmentNonMandatory: 'Department',
                    myWishlist: 'My Wishlist',
                    createUser: 'Create User',
                    addServiceProvider: 'Add Service Provider',
                    newKindOfCertificate: 'New kind of certificate',
                    addExpirationDate: 'Add Expiration Date',
                    acceptForm: 'Accept Form',
                    deletePhoto: 'Delete photo',
                    changePhoto: 'Change photo',
                    certificateAddedSuccessfully: 'Certificate added successfully',
                    productAddedSuccessfully: 'Product added successfully',
                    userDeletedSuccessfully: 'User deleted successfully',
                    userBlockedSuccessfully: 'User blocked successfully',
                    userReactivatedSuccessfully: 'User reactivated successfully',
                    changesSaved: 'Changes saved',
                    expiresInDays: 'Expires in 30 days',
                    formSaved: 'Form Saved',
                    somethingWentWrong: 'Something went wrong',
                    notification: 'Notifications',
                    successful: 'Successful',
                    cancellationSuccessful: 'Cancellation Successful',
                    documentApproved: 'Document Approved',
                    documentAdded: 'Document added successfully',
                    documentUpdated: 'Document Updated',
                    documentResubmitted: 'Document Resubmitted',
                    whatToDo: 'What do you want to do?',
                    formDeleted: 'Form deleted',
                    requirementAdded: 'Requirement added successfully',
                    profileViews: 'Profile Views',
                    dataBase: 'Database',
                    companyLegalName: 'Company Legal Name',
                    companyAddress: 'Company Address',
                    companyPhoneNumber: 'Company Phone Number',
                    directPhoneNumber: 'Direct Phone Number',
                    activeCompanyProof: 'ACTIVE COMPANY PROOF -',
                    proofCompanyActiveRequirement:
                        'The company must send proof that it is duly active; if it is a company, send the EIN/Tax ID / DUNS number.',
                    businessLicense: 'BUSINESS LICENSE -',
                    validOperatingLicenseQuestion:
                        'Does the company have a valid Operating License provided by the local regulatory body?',
                    sanitaryLicense: 'SANITARY LICENSE -',
                    healthPermitRequirement:
                        'If Applicable, the company must send the Health Permit issued by the local regulatory agency and, when applicable, FDA Approval, USDA Approval, etc.',
                    companyCertifiedQuestion:
                        'Is the company certified / approved in any standard?',
                    includeStandards: 'Please include what standard(s)',
                    supplierCountQuestion: 'How many suppliers does the company have in its chain?',
                    multipleFactoriesQuestion: 'Does the company have more than one factory?',
                    factoryCountQuestion: 'How many',
                    internalDocsControlQuestion:
                        'How does your company control your internal documents and procedures?',
                    supplierControlQuestion: 'How does your company control suppliers?',
                    supplierManagementQuestion: 'How are these suppliers managed?',
                    spreadsheetOption: 'Spreadsheet',
                    ownSystemOption: 'Own System',
                    thirdPartySystemOption: 'Third Party System',
                    othersOption: 'Others',
                    noControlOption: "We don't control",
                    throughDocumentationOption: 'Through Documentation',
                    through2ndPartyAuditOption: 'Through 2nd Party Audit',
                    throughDocumentationAndAuditingOption: 'Through Documentation and Auditing',
                    platformNameQuestion: "What's the name of the platform/system?",
                    averageDocumentsQuestion:
                        'How many documents (on average) does the company control and validate from each supplier?',
                    findNewSuppliersBuyersQuestion:
                        'How your company find new suppliers or buyers?',
                    recommendations: 'Recommendations',
                    sustainabilityPracticeQuestion:
                        'Does the company have any practice focused on sustainability or ESG?',
                    responsibleToFillPreCheck: 'Responsible to Fill the Pre-check',
                    fullName: 'Full name',
                    basicPreCheck: 'Basic Pre-Check',
                    submit: 'Submit',
                    openFile: 'Open File',
                    changeFile: 'Change File',
                    otherOption: 'Other',
                    environmentalOption: 'Environmental',
                    socialOption: 'Social',
                    gfsiRecognizedStandardsOption: 'GFSI Recognized Standards',
                    regulatoryOption: 'Regulatory',
                    companyNameUser: 'Company Name / User',
                    personalName: 'Personal Name',
                    attachLatestResume: 'Please, attach your latest resume',
                    bachelorsDegreeQuestion: "What is your bachelor's degree?",
                    serviceStandardsQuestion:
                        'Which standards can you provide service? (you can choose more than one)',
                    complyLegislationQuestion:
                        'Does the company comply with current labor and tax legislation applicable to all company activities and locations where it operates?',
                    ifYesIncludeStandards: 'If yes, please include what standard(s)',
                    attachCertifications: 'Attach the certifications',
                    noButLookingToImplement: 'No, but we are looking to implement',
                    declareInformationIsAccurate:
                        'I declare that the information entered in the report above is accurate.',
                    declareWillingToSendInformationToBuyer:
                        'I declare that I will be willing to send any additional information requested by the buyer.',
                    labAnalysis: 'Lab analysis',
                    pestControl: 'Pest Control',
                    calibrationOfMeasuringInstruments: 'Calibration of Measuring Instruments',
                    training: 'Training',
                    thirdPartyAudits: '3rd Party Audits',
                    secondPartyAudits: '2nd party audits',
                    internalAudits: 'Internal audits',
                    cleaningCompany: 'Cleaning company',
                    consultant: 'Consultant',
                    itemNotAvailable: 'This item is not available for type of search',
                    servicesOffered: 'Services Offered',
                    servicesProvided: 'Service Provided',
                    submitted: 'Submitted',
                    productsApprovedByMyTS: 'Products approved by MyTS',
                    companyCategories: 'Company categories',
                    descriptionOfServiceProvided: 'Description of Service Provided',
                    serviceProviderCategories: 'Service Provider Categories',
                    visibleIcon: `Make your company visible for other companies on Filter's feature`,
                    cantChangeEmail: `To change your main email, please contact support@myt-s.com or clink on chat icon below`,
                    headquarters: 'Headquarters',
                    canViewProfile: 'Can view Profile',
                    canViewSupplierInformation: 'Can view supplier information',
                    canViewBuyerInformation: 'Can view buyer information',
                    canEditProfile: 'Can edit Profile',
                    connection: 'Connection',
                    canView: 'Can view',
                    canEdit: 'Can edit',
                    receiveNotifications: 'Receive notifications',
                    showMyWishlistOnTheDashboard: 'Show my wishlist on the Dashboard',
                    canUseIt: 'Can use it',
                    showMessagesOnTheDashboard: 'Show messages on the Dashboard',
                    profileView: 'Profile View',
                    showProfileViewsOnTheDashboard: 'Show Profile Views on the Dashboard',
                    myQMS: 'My QMS',
                    myProcessOverview: 'My Process Overview',
                    showOnDashboard: 'Show on Dashboard',
                    role: 'Role*',
                    useForSupplier: 'Use for Supplier',
                    useForBuyer: 'Use for Buyer',
                    useForAdministrative: 'Use for Administrative',
                    visible: 'Visible',
                    notVisible: 'Not Visible',
                }
                break
        }
    }

    const findlanguageEsg = (_language) => {
        const __language = _language ? _language : language

        switch (__language) {
            case 'portugues':
                return {
                    other: 'Outros',
                    questionanswer: `RESPOSTA`,
                    comments: `Comentários`,
                    specificQuestionUSDAFDA: `Perguntas específicas sobre USDA e/ou FDA, se aplicável`,
                    specificQuestionFsmaPlan: `A empresa possui um plano de adulteração intencional FSMA?`,
                    a11RequirementTitle: `A empresa possui alguma prática de redução de combustíveis ou de consumo de energia? Ou possui alguma forma de autogeração de eletricidade?'`,
                    a11PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo.`,
                    a11PlaceHolderTextNo: `Responda as seguintes perguntas, por favor:`,
                    a11yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    a11yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    a11yayRequirementTitle: `Esta prática foi adotada por mais de um ano fiscal?`,
                    a11yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    a11yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    a11yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pelas quais não incluiu este tema do ESG na estratégia do negócio.`,
                    a11yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    a11yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor se possível justique.`,
                    a11ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática do ESG?`,
                    a11ybPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor comente sobre as razões por não avaliar periodicamente os resultados desta prática do ESG?`,
                    a11ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e de reputação, por exemplo:`,
                    a11ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo:`,
                    a11ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    a11ybyyqt1RequirementTitle: `Total de energia consumida [Gigajoules (GJ) = 0.28 Megawatthour (MWh)] - indicadores de processos e de reputação, por exemplo:`,
                    a11ybyyqt2RequirementTitle: `Consumo de Combustível da Frota - Tipo de Combustível (Diesel/Gasolina) (Em Litros/ Galões / Toneladas)`,
                    a11ybyyqt3RequirementTitle: `Porcetagem de combustível de fontes renováveis do total de combustível - %`,
                    a11ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos.`,
                    a11ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos`,
                    a11ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    a11ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    a11ycPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente as razões por não considerar positivo os resultados da adoção desta prática ESG`,
                    a11ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    a11ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    a11ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    a11ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG`,
                    a11ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    a11ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    a11ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico do ESG em seus critérios?`,
                    a11ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    a11ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    a11ydyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    a11ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seus fornecedores e prestadores de serviço?`,
                    a11ydyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    a11ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    a11ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    a11naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    a11naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    a11nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    a11nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    a11ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    a11ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    aCardHeaderTitleContainer: `A. CONSUMO DE ENERGIA E COMBUSTÍVEL`,
                    a1aCardHeaderTitleContainer: `A.1.CONSUMO DE ENERGIA E COMBUSTÍVEL - Resposta Sim - A`,
                    a1bCardHeaderTitleContainer: `A.1.CONSUMO DE ENERGIA E COMBUSTÍVEL - Resposta Sim - B`,
                    a1cCardHeaderTitleContainer: `A.1.CONSUMO DE ENERGIA E COMBUSTÍVEL - Resposta Sim - C`,
                    a1dCardHeaderTitleContainer: `A.1.CONSUMO DE ENERGIA E COMBUSTÍVEL - Resposta Sim - D`,
                    a1noCardHeaderTitleContainer: `A.1.CONSUMO DE ENERGIA E COMBUSTÍVEL - Resposta Não`,
                    a21RequirementTitle: `A empresa adota práticas, processos ou sistemas de monitoramento e gerenciamento de consumo de energia?`,
                    a21PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo.`,
                    a21PlaceHolderTextNo: `Por favor responda as questões abaixo:`,
                    a21yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    a21yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    a21yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    a21yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    a21yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    a21yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pelas quais não incluiu este tema do ESG na estratégia do negócio.`,
                    a21yayyql1RequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    a21yayyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    a21ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática do ESG?`,
                    a21ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática do ESG.`,
                    a21ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e de reputação, por exemplo:`,
                    a21ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo:`,
                    a21ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    a21ybyyqt1RequirementTitle: `Antes da adoção da prática - (a) Energia operacional consumida`,
                    a21ybyyqt2RequirementTitle: `Antes da adoção da prática - (b) porcentagem da grade de eletricidade`,
                    a21ybyyqt3RequirementTitle: `Antes da adoção da prática - (c) porcentagem de energia renovável (%)`,
                    a21ybyyqt4RequirementTitle: `Depois da adoção da prática - (a) Energia operacional consumida`,
                    a21ybyyqt5RequirementTitle: `Depois da adoção da prática - (b) porcentagem da grade de eletricidade`,
                    a21ybyyqt6RequirementTitle: `Depois da adoção da prática - (c) porcentagem de energia renovável (%)`,
                    a21ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos.`,
                    a21ybyyqtl1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    a21ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    a21ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    a21ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática do ESG.`,
                    a21ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    a21ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    a21ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    a21ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    a21ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    a21ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    a21ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico do ESG em seus critérios?`,
                    a21ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    a21ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    a21ydyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    a21ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seus fornecedores e prestadores de serviço?`,
                    a21ydyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    a21ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    a21ydyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    a21naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    a21naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    a21nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    a21nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    a21ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    a21ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    a21CardHeaderTitleContainer: `A.2.1 EFICIÊNCIA ENERGÉTICA`,
                    a21aCardHeaderTitleContainer: `A.2.1 EFICIÊNCIA ENERGÉTICA - Resposta Sim - A`,
                    a21bCardHeaderTitleContainer: `A.2.1 EFICIÊNCIA ENERGÉTICA - Resposta Sim - B`,
                    a21cCardHeaderTitleContainer: `A.2.1 EFICIÊNCIA ENERGÉTICA - Resposta Sim - C`,
                    a21dCardHeaderTitleContainer: `A.2.1 EFICIÊNCIA ENERGÉTICA - Resposta Sim - D`,
                    a21noCardHeaderTitleContainer: `A.2.1 EFICIÊNCIA ENERGÉTICA - Resposta Não`,
                    a22RequirementTitle: ` A prática, processo ou sistema que monitora ou gerencia o consumo de energia têm como objetivo aprimorar o uso de eficiência energética?`,
                    a22PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo.`,
                    a22PlaceHolderTextNo: `Por favor responda as questões abaixo`,
                    a22yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    a22yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    a22yayRequirementTitle: ` Essa prática foi adotada por mais de um ano contábil?`,
                    a22yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    a22yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    a22yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pelas quais não incluiu este tema do ESG na estratégia do negócio.`,
                    a22yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio`,
                    a22yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    a22ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática do ESG?`,
                    a22ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática do ESG.`,
                    a22ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e de reputação, por exemplo.`,
                    a22ybyPlaceHolderTextYes: ` Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    a22ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    a22ybyyqt1RequirementTitle: `Porcentagem de unidades de negócio que adotam a práticas de eficiência de energia`,
                    a22ybyyqt2RequirementTitle: `Média do tempo para a implementação do sistema - Unidade de tempo`,
                    a22ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos.`,
                    a22ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    a22ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    a22ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    a22ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática do ESG.`,
                    a22ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    a22ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    a22ycyyRequirementTitle: ` É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    a22ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    a22ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    a22ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    a22ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico do ESG em seus critérios?`,
                    a22ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    a22ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    a22ydyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    a22ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seus fornecedores e prestadores de serviço?`,
                    a22ydyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    a22ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    a22ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    a22naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    a22naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    a22nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    a22nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    a22ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    a22ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    a22CardHeaderTitleContainer: `A.2.2 EFICIÊNCIA ENERGÉTICA`,
                    a22aCardHeaderTitleContainer: `A.2.2 EFICIÊNCIA ENERGÉTICA - Resposta Sim - A`,
                    a22bCardHeaderTitleContainer: `A.2.2 EFICIÊNCIA ENERGÉTICA - Resposta Sim - B`,
                    a22cCardHeaderTitleContainer: `A.2.2 EFICIÊNCIA ENERGÉTICA - Resposta Sim - C`,
                    a22dCardHeaderTitleContainer: `A.2.2 EFICIÊNCIA ENERGÉTICA - Resposta Sim - D`,
                    a22noCardHeaderTitleContainer: `A.2.2 EFICIÊNCIA ENERGÉTICA - Resposta Não`,
                    b11RequirementTitle: `A empresa realiza medições de rastro de carbono e publica, periodicamente, um inventário de emissões?`,
                    b11PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    b11PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    b11yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    b11yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    b11yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    b11yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    b11yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    b11yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pelas quais não incluiu este tema do ESG na estratégia do negócio.`,
                    b11yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    b11yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    b11ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática do ESG?`,
                    b11ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática do ESG.`,
                    b11ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e de reputação, por exemplo.`,
                    b11ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    b11ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    b11ybyyqt1RequirementTitle: `Porcentagem de unidades de negócio que medem sua pegada de carbono.`,
                    b11ybyyqt2RequirementTitle: ` Número anual de inventários informados.`,
                    b11ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos.`,
                    b11ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    b11ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    b11ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    b11ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática do ESG.`,
                    b11ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    b11ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    b11ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    b11ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    b11ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    b11ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    b11ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico do ESG em seus critérios?`,
                    b11ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    b11ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    b11ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    b11ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seus fornecedores e prestadores de serviço?`,
                    b11ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    b11ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    b11ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    b11naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    b11naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    b11nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    b11nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    b11ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    b11ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    b11CardHeaderTitleContainer: `B.1.1 EMISSÕES DE GÁS DE EFEITO ESTUFA`,
                    b11aCardHeaderTitleContainer: `B.1.1 REDUÇÃO DE RASTRO DE CARBONO - Resposta Sim - A`,
                    b11bCardHeaderTitleContainer: `B.1.1 REDUÇÃO DE RASTRO DE CARBONO - Resposta Sim - B`,
                    b11cCardHeaderTitleContainer: `B.1.1 REDUÇÃO DE RASTRO DE CARBONO - Resposta Sim - C`,
                    b11dCardHeaderTitleContainer: `B.1.1 REDUÇÃO DE RASTRO DE CARBONO - Resposta Sim - D`,
                    b11noCardHeaderTitleContainer: `B.1.1 REDUÇÃO DE RASTRO DE CARBONO - Resposta Não`,
                    b12RequirementTitle: `A empresa é adepta a algum programa que reduz emissões de Escopo 1?`,
                    b12PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    b12PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    b12yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    b12yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    b12yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    b12yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    b12yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    b12yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pelas quais não incluiu este tema do ESG na estratégia do negócio.`,
                    b12yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    b12yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    b12ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática do ESG?`,
                    b12ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática do ESG.`,
                    b12ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e de reputação, por exemplo.`,
                    b12ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    b12ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    b12ybyyqt1RequirementTitle: `Toneladas de CO2e medidas no primeiro inventário.`,
                    b12ybyyqt2RequirementTitle: `Toneladas de CO2e medidas no último inventário.`,
                    b12ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos.`,
                    b12ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    b12ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    b12ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    b12ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática do ESG.`,
                    b12ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    b12ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    b12ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    b12ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    b12ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    b12ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    b12ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico do ESG em seus critérios?`,
                    b12ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    b12ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    b12ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    b12ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seus fornecedores e prestadores de serviço?`,
                    b12ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    b12ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    b12ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    b12naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    b12naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    b12nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    b12nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    b12ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    b12ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    b12CardHeaderTitleContainer: `B.1.2 REDUÇÃO DE RASTRO DE CARBONO`,
                    b12aCardHeaderTitleContainer: `B.1.2 REDUÇÃO DE RASTRO DE CARBONO - Resposta Sim - A`,
                    b12bCardHeaderTitleContainer: `B.1.2 REDUÇÃO DE RASTRO DE CARBONO - Resposta Sim - B`,
                    b12cCardHeaderTitleContainer: `B.1.2 REDUÇÃO DE RASTRO DE CARBONO - Resposta Sim - C`,
                    b12dCardHeaderTitleContainer: `B.1.2 REDUÇÃO DE RASTRO DE CARBONO - Resposta Sim - D`,
                    b12noCardHeaderTitleContainer: `B.1.2 REDUÇÃO DE RASTRO DE CARBONO - Resposta Não`,
                    c11RequirementTitle: `A empresa é adepta a algum programa que reduz o consumo de água?`,
                    c11PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    c11PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    c11yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    c11yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    c11yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    c11yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    c11yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    c11yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pelas quais não incluiu este tema do ESG na estratégia do negócio.`,
                    c11yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    c11yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    c11ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática do ESG?`,
                    c11ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática do ESG.`,
                    c11ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e de reputação, por exemplo.`,
                    c11ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    c11ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    c11ybyyqt1RequirementTitle: `Antes da adoção da prática - Total do consumo de água -M3`,
                    c11ybyyqt2RequirementTitle: `Depois da adoção da prática - Total do consumo de água -M3.`,
                    c11ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos.`,
                    c11ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    c11ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    c11ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    c11ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática do ESG.`,
                    c11ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    c11ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    c11ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    c11ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    c11ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    c11ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    c11ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico do ESG em seus critérios?`,
                    c11ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    c11ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    c11ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    c11ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seus fornecedores e prestadores de serviço?`,
                    c11ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    c11ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    c11ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    c11naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    c11naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    c11nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    c11nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    c11ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    c11ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    c11CardHeaderTitleContainer: `C.1.1 CONSUMO DE ÁGUA`,
                    c11aCardHeaderTitleContainer: `C.1.1 CONSUMO DE ÁGUA - Resposta Sim - A`,
                    c11bCardHeaderTitleContainer: `C.1.1 CONSUMO DE ÁGUA - Resposta Sim - B`,
                    c11cCardHeaderTitleContainer: `C.1.1 CONSUMO DE ÁGUA - Resposta Sim - C`,
                    c11dCardHeaderTitleContainer: `C.1.1 CONSUMO DE ÁGUA - Resposta Sim - D`,
                    c11noCardHeaderTitleContainer: `C.1.1 CONSUMO DE ÁGUA - Resposta Não`,
                    c21RequirementTitle: `A empresa é adepta a algum processo, dispositivo ou sistema para a reutilização ou reciclagem da água?`,
                    c21PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    c21PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    c21yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    c21yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    c21yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    c21yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    c21yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    c21yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pelas quais não incluiu este tema do ESG na estratégia do negócio.`,
                    c21yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    c21yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    c21ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática do ESG?`,
                    c21ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática do ESG.`,
                    c21ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e de reputação, por exemplo.`,
                    c21ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    c21ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    c21ybyyqt1RequirementTitle: `Quantidade de água que é reciclada em relação ao total de água utilizada. `,
                    c21ybyyqt2RequirementTitle: `Proporção de contaminantes que são removidos do sistema de água reciclada.`,
                    c21ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos.`,
                    c21ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    c21ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    c21ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    c21ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática do ESG.`,
                    c21ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    c21ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    c21ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    c21ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    c21ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    c21ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    c21ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico do ESG em seus critérios?`,
                    c21ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    c21ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    c21ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    c21ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seus fornecedores e prestadores de serviço?`,
                    c21ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    c21ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    c21ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    c21naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    c21naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    c21nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    c21nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    c21ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    c21ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    c21CardHeaderTitleContainer: `C.2.1 REUTILIZAÇÃO E RECICLAGEM DA ÁGUA`,
                    c21aCardHeaderTitleContainer: `C.2.1 REUTILIZAÇÃO E RECICLAGEM DA ÁGUA - Resposta Sim - A`,
                    c21bCardHeaderTitleContainer: `C.2.1 REUTILIZAÇÃO E RECICLAGEM DA ÁGUA - Resposta Sim - B`,
                    c21cCardHeaderTitleContainer: `C.2.1 REUTILIZAÇÃO E RECICLAGEM DA ÁGUA - Resposta Sim - C`,
                    c21dCardHeaderTitleContainer: `C.2.1 REUTILIZAÇÃO E RECICLAGEM DA ÁGUA - Resposta Sim - D`,
                    c21noCardHeaderTitleContainer: `C.2.1 REUTILIZAÇÃO E RECICLAGEM DA ÁGUA - Resposta Não`,
                    d11RequirementTitle: `Há utilização do solo para agricultura ou produção animal? Se sim, o solo é utilizado intensamente, porém de uma forma sustentável?`,
                    d11PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    d11PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    d11yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    d11yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    d11yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    d11yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    d11yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    d11yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pelas quais não incluiu este tema do ESG na estratégia do negócio.`,
                    d11yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    d11yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    d11ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática do ESG?`,
                    d11ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática do ESG.`,
                    d11ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e de reputação, por exemplo.`,
                    d11ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    d11ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    d11ybyyqt1RequirementTitle: `Área total de terra com produção ativa - Hectáres. `,
                    d11ybyyqt2RequirementTitle: `Média do tamanho das propriedades na sua região - Hectáres.`,
                    d11ybyyqt3RequirementTitle: `Quantidade de produtos agrícolas ou proteína animal produzida por hectáre.`,
                    d11ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos.`,
                    d11ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    d11ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    d11ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    d11ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática do ESG.`,
                    d11ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    d11ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    d11ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    d11ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    d11ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    d11ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    d11ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico do ESG em seus critérios?`,
                    d11ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    d11ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    d11ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    d11ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seus fornecedores e prestadores de serviço?`,
                    d11ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    d11ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    d11ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    d11naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    d11naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    d11nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    d11nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    d11ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    d11ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    d11CardHeaderTitleContainer: `D.1.1 UTILIZAÇÃO RACIONAL DO SOLO`,
                    d11aCardHeaderTitleContainer: `D.1.1 UTILIZAÇÃO RACIONAL DO SOLO - Resposta Sim - A`,
                    d11bCardHeaderTitleContainer: `D.1.1 UTILIZAÇÃO RACIONAL DO SOLO - Resposta Sim - B`,
                    d11cCardHeaderTitleContainer: `D.1.1 UTILIZAÇÃO RACIONAL DO SOLO - Resposta Sim - C`,
                    d11dCardHeaderTitleContainer: `D.1.1 UTILIZAÇÃO RACIONAL DO SOLO - Resposta Sim - D`,
                    d11noCardHeaderTitleContainer: `D.1.1 UTILIZAÇÃO RACIONAL DO SOLO - Resposta Não`,
                    d21RequirementTitle: `A empresa possui um programa de preservação florestal nas áreas em que produz ou têm alguma influência?`,
                    d21PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    d21PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    d21yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    d21yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    d21yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    d21yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    d21yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    d21yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pelas quais não incluiu este tema do ESG na estratégia do negócio.`,
                    d21yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    d21yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    d21ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática do ESG?`,
                    d21ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática do ESG.`,
                    d21ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e de reputação, por exemplo.`,
                    d21ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    d21ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    d21ybyyqt1RequirementTitle: `Porcentagem da área de terra coberta por floresta que a empresa possui, se aplicável`,
                    d21ybyyqt2RequirementTitle: `Área reflorestada ou preservada por programas ou iniciativas.`,
                    d21ybyyqt3RequirementTitle: `Porcentagem da área de terra que a empresa possui que é protegida por leis governamentais e regulamentações, se aplicável`,
                    d21ybyyqt4RequirementTitle: `Montante investido em seu próprio reflorestamento ou programas de preservação florestal ou que foi realizada por outras organizações no último ano.`,
                    d21ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos.`,
                    d21ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos? `,
                    d21ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    d21ycRequirementTitle: `A empresa considera o resultado positivo ? `,
                    d21ycPlaceHolderTextNo: `Sua resposta foi "NÃO".Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática do ESG.`,
                    d21ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG ? `,
                    d21ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    d21ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores(fornecedores, prestadores de serviço e clientes) ? `,
                    d21ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    d21ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG ? `,
                    d21ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    d21ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico do ESG em seus critérios ? `,
                    d21ydPlaceHolderTextNo: `Sua resposta foi "NÃO".Comente se considera a certificação relevante ou não.`,
                    d21ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações ? `,
                    d21ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    d21ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seus fornecedores e prestadores de serviço ? `,
                    d21ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    d21ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    d21ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    d21naRequirementTitle: `A empresa tem a intenção de adotar esta prática ? `,
                    d21naPlaceHolderTextNo: `Sua resposta foi "NÃO".Comente sobre os motivos de não adotar esta prática.`,
                    d21nbRequirementTitle: `Há algum projeto de adoção em andamento ? `,
                    d21nbPlaceHolderTextNo: `Sua resposta foi "NÃO".Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    d21ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil ? `,
                    d21ncPlaceHolderTextNo: `Sua resposta foi "NÃO".Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    d21CardHeaderTitleContainer: `D.2.1 PRESERVAÇÃO FLORESTAL`,
                    d21aCardHeaderTitleContainer: `D.2.1 PRESERVAÇÃO FLORESTAL - Resposta Sim - A`,
                    d21bCardHeaderTitleContainer: `D.2.1 PRESERVAÇÃO FLORESTAL - Resposta Sim - B`,
                    d21cCardHeaderTitleContainer: `D.2.1 PRESERVAÇÃO FLORESTAL - Resposta Sim - C`,
                    d21dCardHeaderTitleContainer: `D.2.1 PRESERVAÇÃO FLORESTAL - Resposta Sim - D`,
                    d21noCardHeaderTitleContainer: `D.2.1 PRESERVAÇÃO FLORESTAL - Resposta Não`,
                    d31RequirementTitle: `Há critérios rigorosos sobre o uso de compostos químicos na agricultura, em ingredientes ou alimentos processados?`,
                    d31PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    d31PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    d31yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    d31yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    d31yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    d31yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    d31yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    d31yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pelas quais não incluiu este tema do ESG na estratégia do negócio.`,
                    d31yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    d31yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    d31ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática do ESG?`,
                    d31ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática do ESG.`,
                    d31ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e de reputação, por exemplo.`,
                    d31ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    d31ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    d31ybyyqt1RequirementTitle: `Limites máximo de resíduos - LMRs (Quantidade de pesticidas, herbicidas, e outros químicos que podem ficar no produto alimentício`,
                    d31ybyyqt2RequirementTitle: `Porcentagem de produção animal que recebeu importantes antibióticos médicos, por tipo de animal %`,
                    d31ybyyqt3RequirementTitle: `Volume e tipo de químico perigoso utilizado na agricultura, processamento de alimentos e embalamento.`,
                    d31ybyyqt4RequirementTitle: `Frequência de aplicação de químico - Unidade de tempo.`,
                    d31ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos.`,
                    d31ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    d31ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    d31ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    d31ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática do ESG.`,
                    d31ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    d31ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    d31ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    d31ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    d31ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    d31ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    d31ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico do ESG em seus critérios?`,
                    d31ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    d31ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    d31ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    d31ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seus fornecedores e prestadores de serviço?`,
                    d31ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    d31ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    d31ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    d31naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    d31naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    d31nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    d31nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    d31ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    d31ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    d31CardHeaderTitleContainer: `D.3.1 USO RACIONAL DE INGREDIENTES QUÍMICOS`,
                    d31aCardHeaderTitleContainer: `D.3.1 USO RACIONAL DE INGREDIENTES QUÍMICOS - Resposta Sim - A`,
                    d31bCardHeaderTitleContainer: `D.3.1 USO RACIONAL DE INGREDIENTES QUÍMICOS - Resposta Sim - B`,
                    d31cCardHeaderTitleContainer: `D.3.1 USO RACIONAL DE INGREDIENTES QUÍMICOS - Resposta Sim - C`,
                    d31dCardHeaderTitleContainer: `D.3.1 USO RACIONAL DE INGREDIENTES QUÍMICOS - Resposta Sim - D`,
                    d31noCardHeaderTitleContainer: `D.3.1 USO RACIONAL DE INGREDIENTES QUÍMICOS - Resposta Não`,
                    d41RequirementTitle: `Há critérios rigorosos sobre o uso de OGM (Organismos geneticamente modificados) na agricultura, em ingredientes ou alimentos processados?`,
                    d41PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    d41PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    d41yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    d41yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    d41yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    d41yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    d41yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    d41yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pelas quais não incluiu este tema do ESG na estratégia do negócio.`,
                    d41yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    d41yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    d41ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática do ESG?`,
                    d41ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática do ESG.`,
                    d41ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e de reputação, por exemplo.`,
                    d41ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    d41ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    d41ybyyqt1RequirementTitle: `Porcentagem de produção agrícola que recebeu OGM mesmo que permitido, por tipo de cultura.`,
                    d41ybyyqt2RequirementTitle: `Porcentagem de produtos com ingredientes OGM do total de produtos - %.`,
                    d41ybyyqt3RequirementTitle: `Produtividade e rendimento do OGMs comparado as culturas não transgênica.`,
                    d41ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos.`,
                    d41ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    d41ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    d41ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    d41ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática do ESG.`,
                    d41ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    d41ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    d41ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    d41ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    d41ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    d41ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    d41ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico do ESG em seus critérios?`,
                    d41ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    d41ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    d41ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    d41ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seus fornecedores e prestadores de serviço?`,
                    d41ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    d41ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    d41ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    d41naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    d41naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    d41nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    d41nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    d41ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    d41ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    d41CardHeaderTitleContainer: `D.4.1 USO RACIONAL DE OGM ORGANISMOS GENETICAMENTE MODIFICADOS`,
                    d41aCardHeaderTitleContainer: `D.4.1 USO RACIONAL DE OGM ORGANISMOS GENETICAMENTE MODIFICADOS - Resposta Sim - A`,
                    d41bCardHeaderTitleContainer: `D.4.1 USO RACIONAL DE OGM ORGANISMOS GENETICAMENTE MODIFICADOS - Resposta Sim - B`,
                    d41cCardHeaderTitleContainer: `D.4.1 USO RACIONAL DE OGM ORGANISMOS GENETICAMENTE MODIFICADOS - Resposta Sim - C`,
                    d41dCardHeaderTitleContainer: `D.4.1 USO RACIONAL DE OGM ORGANISMOS GENETICAMENTE MODIFICADOS - Resposta Sim - D`,
                    d41noCardHeaderTitleContainer: `D.4.1 USO RACIONAL DE OGM ORGANISMOS GENETICAMENTE MODIFICADOS - Resposta Não`,
                    d51RequirementTitle: `A empresa é adepta a algum processo ou sistema para reciclagem de resíduos?`,
                    d51PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    d51PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    d51yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    d51yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    d51yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    d51yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    d51yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    d51yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pelas quais não incluiu este tema do ESG na estratégia do negócio.`,
                    d51yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    d51yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    d51ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática do ESG?`,
                    d51ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática do ESG.`,
                    d51ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e de reputação, por exemplo.`,
                    d51ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    d51ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    d51ybyyqt1RequirementTitle: `Porcentagem de material perigoso do total de resíduo - % `,
                    d51ybyyqt2RequirementTitle: `Porcentagem de material reciclado/reutilizado do total de resíduo - %.`,
                    d51ybyyqt3RequirementTitle: `Quantidade de material reciclado/ recuperado durante o processo de produção alimentício.`,
                    d51ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos.`,
                    d51ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    d51ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    d51ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    d51ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática do ESG.`,
                    d51ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    d51ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    d51ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    d51ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    d51ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    d51ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    d51ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico do ESG em seus critérios?`,
                    d51ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    d51ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    d51ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    d51ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seus fornecedores e prestadores de serviço?`,
                    d51ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    d51ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    d51ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    d51naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    d51naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    d51nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    d51nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    d51ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    d51ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    d51CardHeaderTitleContainer: `D.5.1 REDUÇÃO E RECICLAGEM DE RESÍDUOS`,
                    d51aCardHeaderTitleContainer: `D.5.1 REDUÇÃO E RECICLAGEM DE RESÍDUOS - Resposta Sim - A`,
                    d51bCardHeaderTitleContainer: `D.5.1 REDUÇÃO E RECICLAGEM DE RESÍDUOS - Resposta Sim - B`,
                    d51cCardHeaderTitleContainer: `D.5.1 REDUÇÃO E RECICLAGEM DE RESÍDUOS - Resposta Sim - C`,
                    d51dCardHeaderTitleContainer: `D.5.1 REDUÇÃO E RECICLAGEM DE RESÍDUOS - Resposta Sim - D`,
                    d51noCardHeaderTitleContainer: `D.5.1 REDUÇÃO E RECICLAGEM DE RESÍDUOS - Resposta Não`,
                    e11RequirementTitle: `A empresa possui envolvimento direto com as pessoas que lá trabalham, com suas famílias ou com a comunidade em que opera?`,
                    e11PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    e11PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    e11yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    e11yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    e11yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    e11yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    e11yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    e11yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pelas quais não incluiu este tema do ESG na estratégia do negócio.`,
                    e11yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    e11yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    e11ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática do ESG?`,
                    e11ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática do ESG.`,
                    e11ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e de reputação, por exemplo.`,
                    e11ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    e11ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    e11ybyyqt1RequirementTitle: `Montante de investimento da empresa em projetos sociais que beneficiam a comunidade local`,
                    e11ybyyqt2RequirementTitle: `Porcentagem de material reciclado/reutilizado do total de resíduo - %.`,
                    e11ybyyqt3RequirementTitle: `Porcentagem reclamações comunitária resolvidas - %.`,
                    e11ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos.`,
                    e11ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    e11ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    e11ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    e11ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática do ESG.`,
                    e11ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    e11ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    e11ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    e11ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    e11ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    e11ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    e11ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico do ESG em seus critérios?`,
                    e11ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    e11ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    e11ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    e11ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seus fornecedores e prestadores de serviço?`,
                    e11ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    e11ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    e11ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    e11naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    e11naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    e11nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    e11nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    e11ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    e11ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    e11CardHeaderTitleContainer: `E.1.1 RESPEITO PELO TERRITÓRIO`,
                    e11aCardHeaderTitleContainer: `E.1.1 RESPEITO PELO TERRITÓRIO - Resposta Sim - A`,
                    e11bCardHeaderTitleContainer: `E.1.1 RESPEITO PELO TERRITÓRIO - Resposta Sim - B`,
                    e11cCardHeaderTitleContainer: `E.1.1 RESPEITO PELO TERRITÓRIO - Resposta Sim - C`,
                    e11dCardHeaderTitleContainer: `E.1.1 RESPEITO PELO TERRITÓRIO - Resposta Sim - D`,
                    e11noCardHeaderTitleContainer: `E.1.1 RESPEITO PELO TERRITÓRIO - Resposta Não`,
                    e21RequirementTitle: `A empresa oferece oportunidades de trabalho e desenvolvimento equitativas a todas as pessoas, independentemente de: incapacidade física ou mental, raça, religião, idade, orientação sexual, gênero ou identidade de gênero? `,
                    e21PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    e21PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    e21yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    e21yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    e21yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    e21yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    e21yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    e21yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pelas quais não incluiu este tema do ESG na estratégia do negócio.`,
                    e21yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    e21yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    e21ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática do ESG?`,
                    e21ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática do ESG.`,
                    e21ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e de reputação, por exemplo.`,
                    e21ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    e21ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    e21ybyyqt1RequirementTitle: `Taxa de retenção de empregados de grupos de diversidades comparado ao total do numero de empregados `,
                    e21ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos.`,
                    e21ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    e21ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    e21ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    e21ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática do ESG.`,
                    e21ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    e21ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    e21ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    e21ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    e21ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    e21ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    e21ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico do ESG em seus critérios?`,
                    e21ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    e21ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    e21ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    e21ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seus fornecedores e prestadores de serviço?`,
                    e21ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    e21ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    e21ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    e21naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    e21naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    e21nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    e21nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    e21ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    e21ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    e21CardHeaderTitleContainer: `E.2.1 OPORTUNIDADE À DIVERSIDADE`,
                    e21aCardHeaderTitleContainer: `E.2.1 OPORTUNIDADE À DIVERSIDADE - Resposta Sim - A`,
                    e21bCardHeaderTitleContainer: `E.2.1 OPORTUNIDADE À DIVERSIDADEO - Resposta Sim - B`,
                    e21cCardHeaderTitleContainer: `E.2.1 OPORTUNIDADE À DIVERSIDADE - Resposta Sim - C`,
                    e21dCardHeaderTitleContainer: `E.2.1 OPORTUNIDADE À DIVERSIDADE - Resposta Sim - D`,
                    e21noCardHeaderTitleContainer: `E.2.1 OPORTUNIDADE À DIVERSIDADE - Resposta Não`,
                    f11RequirementTitle: `A empresa implementa, registra e avalia programas de saúde para funcionários diretos e temporários?`,
                    f11PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    f11PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    f11yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    f11yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    f11yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    f11yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    f11yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    f11yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pelas quais não incluiu este tema do ESG na estratégia do negócio.`,
                    f11yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    f11yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    f11ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática do ESG?`,
                    f11ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática do ESG.`,
                    f11ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e de reputação, por exemplo.`,
                    f11ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    f11ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    f11ybyyqt1RequirementTitle: `Treinamento e educação (Quantidade e qualidade de saúde e treinamento de segurança e educação dada aos empregados) - unidade de tempo em anos `,
                    f11ybyyqt2RequirementTitle: `Porcentagem de empregados que participam de checkups de saúde (tais como exames de sangue, pressão e colesterol) - unidade de tempo em anos.`,
                    f11ybyyqt3RequirementTitle: `Rotação de empregados (taxa em que os empregados deixam a empresa, que pode ser influenciado pelas condições de saúde e segurança do ambiente de trabalho) - Unidade de tempo em anos. `,
                    f11ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos.`,
                    f11ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    f11ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    f11ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    f11ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática do ESG.`,
                    f11ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    f11ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    f11ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    f11ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    f11ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    f11ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    f11ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico do ESG em seus critérios?`,
                    f11ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    f11ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    f11ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    f11ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seus fornecedores e prestadores de serviço?`,
                    f11ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    f11ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    f11ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    f11naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    f11naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    f11nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    f11nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    f11ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    f11ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    f11CardHeaderTitleContainer: `F.1.1 MELHORES CONDIÇÕES DE TRABALHO`,
                    f11aCardHeaderTitleContainer: `F.1.1 MELHORES CONDIÇÕES DE TRABALHO - Resposta Sim - A`,
                    f11bCardHeaderTitleContainer: `F.1.1 MELHORES CONDIÇÕES DE TRABALHO - Resposta Sim - B`,
                    f11cCardHeaderTitleContainer: `F.1.1 MELHORES CONDIÇÕES DE TRABALHO - Resposta Sim - C`,
                    f11dCardHeaderTitleContainer: `F.1.1 MELHORES CONDIÇÕES DE TRABALHO - Resposta Sim - D`,
                    f11noCardHeaderTitleContainer: `F.1.1 MELHORES CONDIÇÕES DE TRABALHO - Resposta Não`,
                    f12RequirementTitle: `A empresa implementa, registra e avalia um programa de segurança ocupacional para seus funcionários diretos e temporários?`,
                    f12PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    f12PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    f12yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    f12yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    f12yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    f12yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    f12yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    f12yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pela quais não incluiu este tema do ESG na estratégia do negócio.`,
                    f12yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    f12yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    f12ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática da ESG?`,
                    f12ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática da ESG.`,
                    f12ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e reputação, por exemplo.`,
                    f12ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    f12ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    f12ybyyqt1RequirementTitle: `Taxa de acidentes de trabalho (número de acidentes de trabalho por cada 100 empregados) - unidade de tempo em anos`,
                    f12ybyyqt2RequirementTitle: `Absenteísmo (número de dias que os empregados ficam afastados por conta de acidente de trabalho) - Unidade de tempo em anos.`,
                    f12ybyyqt3RequirementTitle: `Quase incidentes (número de incidentes que poderiam ter resultado em acidentes, mas que não resultaram).`,
                    f12ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos`,
                    f12ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    f12ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    f12ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    f12ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática da ESG.`,
                    f12ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    f12ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    f12ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    f12ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    f12ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    f12ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    f12ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico da ESG em seus critérios?`,
                    f12ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    f12ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    f12ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    f12ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seu fornecedores e prestadores de serviço?`,
                    f12ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    f12ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    f12ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    f12naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    f12naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    f12nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    f12nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    f12ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    f12ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    f12CardHeaderTitleContainer: `F.1.2 MELHORES CONDIÇÕES DE TRABALHO`,
                    f12aCardHeaderTitleContainer: `F.1.2 MELHORES CONDIÇÕES DE TRABALHO - Resposta Sim - A`,
                    f12bCardHeaderTitleContainer: `F.1.2 MELHORES CONDIÇÕES DE TRABALHO - Resposta Sim - B`,
                    f12cCardHeaderTitleContainer: `F.1.2 MELHORES CONDIÇÕES DE TRABALHO - Resposta Sim - C`,
                    f12dCardHeaderTitleContainer: `F.1.2 MELHORES CONDIÇÕES DE TRABALHO - Resposta Sim - D`,
                    f12noCardHeaderTitleContainer: `F.1.2 MELHORES CONDIÇÕES DE TRABALHO - Resposta Não`,
                    g11RequirementTitle: `É considerado que as horas de trabalho da empresa estão abaixo do que é requerido pelas leis trabalhistas (Fair Labor Standards Act)?`,
                    g11PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    g11PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    g11yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    g11yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    g11yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    g11yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    g11yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    g11yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pela quais não incluiu este tema do ESG na estratégia do negócio.`,
                    g11yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    g11yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    g11ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática da ESG?`,
                    g11ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática da ESG.`,
                    g11ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e reputação, por exemplo.`,
                    g11ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    g11ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    g11ybyyqt1RequirementTitle: `Total de horas trabalhadas, incluindo treinamento, viagem e folga - em anos`,
                    g11ybyyqt2RequirementTitle: `Média de horas extras por empregado - em anos`,
                    g11ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos`,
                    g11ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    g11ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    g11ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    g11ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática da ESG.`,
                    g11ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    g11ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    g11ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    g11ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    g11ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    g11ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    g11ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico da ESG em seus critérios?`,
                    g11ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    g11ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    g11ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    g11ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seu fornecedores e prestadores de serviço?`,
                    g11ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    g11ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    g11ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    g11naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    g11naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    g11nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    g11nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    g11ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    g11ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    g11CardHeaderTitleContainer: `G.1.1 CONTRATOS DE TRABALHO LEGAIS`,
                    g11aCardHeaderTitleContainer: `G.1.1 CONTRATOS DE TRABALHO LEGAIS - Resposta Sim - A`,
                    g11bCardHeaderTitleContainer: `G.1.1 CONTRATOS DE TRABALHO LEGAIS - Resposta Sim - B`,
                    g11cCardHeaderTitleContainer: `G.1.1 CONTRATOS DE TRABALHO LEGAIS - Resposta Sim - C`,
                    g11dCardHeaderTitleContainer: `G.1.1 CONTRATOS DE TRABALHO LEGAIS - Resposta Sim - D`,
                    g11noCardHeaderTitleContainer: `G.1.1 CONTRATOS DE TRABALHO LEGAIS - Resposta Não`,
                    g12RequirementTitle: `É considerado que os salários estão acima do que é requerido pelas leis trabalhistas (Fair Labor Standards Act)?`,
                    g12PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    g12PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    g12yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    g12yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    g12yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    g12yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    g12yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    g12yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pela quais não incluiu este tema do ESG na estratégia do negócio.`,
                    g12yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    g12yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    g12ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática da ESG?`,
                    g12ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática da ESG.`,
                    g12ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e reputação, por exemplo.`,
                    g12ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    g12ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    g12ybyyqt1RequirementTitle: `Porcentagem da média salarial da empresa comparado as empresas do mesmo setor e região - %`,
                    g12ybyyqt2RequirementTitle: `Menor salário dentre todos os empregados`,
                    g12ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos`,
                    g12ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    g12ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    g12ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    g12ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática da ESG.`,
                    g12ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    g12ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    g12ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    g12ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    g12ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    g12ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    g12ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico da ESG em seus critérios?`,
                    g12ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    g12ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    g12ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    g12ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seu fornecedores e prestadores de serviço?`,
                    g12ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    g12ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    g12ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    g12naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    g12naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    g12nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    g12nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    g12ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    g12ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    g12CardHeaderTitleContainer: `G.1.2 CONTRATOS DE TRABALHO LEGAIS`,
                    g12aCardHeaderTitleContainer: `G.1.2 CONTRATOS DE TRABALHO LEGAIS - Resposta Sim - A`,
                    g12bCardHeaderTitleContainer: `G.1.2 CONTRATOS DE TRABALHO LEGAIS - Resposta Sim - B`,
                    g12cCardHeaderTitleContainer: `G.1.2 CONTRATOS DE TRABALHO LEGAIS - Resposta Sim - C`,
                    g12dCardHeaderTitleContainer: `G.1.2 CONTRATOS DE TRABALHO LEGAIS - Resposta Sim - D`,
                    g12noCardHeaderTitleContainer: `G.1.2 CONTRATOS DE TRABALHO LEGAIS - Resposta Não`,
                    attachEvidence: `Se sim, por favor anexe a Evidência.`,
                    optionNo: `No`,
                    optionYes: `Yes`,
                    buttonOpenFile: `Carregar aquivo`,
                    buttonChangeFile: `Alterar Aquivo`,
                    priorNoticetoImport: `A empresa protocolou Aviso Prévio de Importação?`,
                    g13RequirementTitle: `A empresa possui regulamentos claros sobre a não permissão de trabalho infantil e trabalho escravo?`,
                    g13PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    g13PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    g13yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    g13yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    g13yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    g13yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    g13yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    g13yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pela quais não incluiu este tema do ESG na estratégia do negócio.`,
                    g13yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    g13yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    g13ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática da ESG?`,
                    g13ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática da ESG.`,
                    g13ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e reputação, por exemplo.`,
                    g13ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    g13ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    g13ybyyqt1RequirementTitle: `Idade mínima aceitável para trabalhar na empresa.`,
                    g13ybyyqt2RequirementTitle: `Número máximo de horas trabalhadas por semana.`,
                    g13ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos`,
                    g13ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    g13ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    g13ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    g13ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática da ESG.`,
                    g13ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    g13ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    g13ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    g13ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    g13ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    g13ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    g13ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico da ESG em seus critérios?`,
                    g13ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    g13ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    g13ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    g13ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seu fornecedores e prestadores de serviço?`,
                    g13ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    g13ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    g13ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    g13naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    g13naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    g13nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    g13nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    g13ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    g13ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    g13CardHeaderTitleContainer: `G.1.3 CONTRATOS DE TRABALHO LEGAIS`,
                    g13aCardHeaderTitleContainer: `G.1.3 CONTRATOS DE TRABALHO LEGAIS - Resposta Sim - A`,
                    g13bCardHeaderTitleContainer: `G.1.3 CONTRATOS DE TRABALHO LEGAIS - Resposta Sim - B`,
                    g13cCardHeaderTitleContainer: `G.1.3 CONTRATOS DE TRABALHO LEGAIS - Resposta Sim - C`,
                    g13dCardHeaderTitleContainer: `G.1.3 CONTRATOS DE TRABALHO LEGAIS - Resposta Sim - D`,
                    g13noCardHeaderTitleContainer: `G.1.3 CONTRATOS DE TRABALHO LEGAIS - Resposta Não`,
                    h11RequirementTitle: `Os fornecedores recebem precificação especial para produtos fairtrade?`,
                    h11PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    h11PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    h11yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    h11yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    h11yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    h11yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    h11yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    h11yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pela quais não incluiu este tema do ESG na estratégia do negócio.`,
                    h11yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    h11yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    h11ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática da ESG?`,
                    h11ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática da ESG.`,
                    h11ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e reputação, por exemplo.`,
                    h11ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    h11ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    h11ybyyqt1RequirementTitle: `Diferença média entre o valor pago por um produto fairtrade e um produto regular - (%)`,
                    h11ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos`,
                    h11ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    h11ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    h11ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    h11ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática da ESG.`,
                    h11ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    h11ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    h11ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    h11ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    h11ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    h11ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    h11ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico da ESG em seus critérios?`,
                    h11ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    h11ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    h11ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    h11ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seu fornecedores e prestadores de serviço?`,
                    h11ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    h11ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    h11ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    h11naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    h11naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    h11nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    h11nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    h11ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    h11ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    h11CardHeaderTitleContainer: `H.1.1 CONDIÇÕES COMERCIAIS LEGAIS `,
                    h11aCardHeaderTitleContainer: `H.1.1 CONDIÇÕES COMERCIAIS LEGAIS - Resposta Sim - A`,
                    h11bCardHeaderTitleContainer: `H.1.1 CONDIÇÕES COMERCIAIS LEGAIS - Resposta Sim - B`,
                    h11cCardHeaderTitleContainer: `H.1.1 CONDIÇÕES COMERCIAIS LEGAIS - Resposta Sim - C`,
                    h11dCardHeaderTitleContainer: `H.1.1 CONDIÇÕES COMERCIAIS LEGAIS - Resposta Sim - D`,
                    h11noCardHeaderTitleContainer: `H.1.1 CONDIÇÕES COMERCIAIS LEGAIS - Resposta Não`,
                    h12RequirementTitle: `Há um sistema que registra e assegura a rastreabilidade de todas as transações, produtos e volumes, especialmente os que são fairtrade?`,
                    h12PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    h12PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    h12yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    h12yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    h12yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    h12yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    h12yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    h12yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pela quais não incluiu este tema do ESG na estratégia do negócio.`,
                    h12yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    h12yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    h12ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática da ESG?`,
                    h12ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática da ESG.`,
                    h12ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e reputação, por exemplo.`,
                    h12ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    h12ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    h12ybyyqt1RequirementTitle: `Porcentagem de produtos rastreado em relação ao total de produtos - (%)`,
                    h12ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos`,
                    h12ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    h12ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    h12ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    h12ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática da ESG.`,
                    h12ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    h12ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    h12ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    h12ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    h12ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    h12ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    h12ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico da ESG em seus critérios?`,
                    h12ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    h12ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    h12ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    h12ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seu fornecedores e prestadores de serviço?`,
                    h12ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    h12ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    h12ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    h12naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    h12naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    h12nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    h12nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    h12ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    h12ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    h12CardHeaderTitleContainer: `H.1.2 CONDIÇÕES COMERCIAIS LEGAIS `,
                    h12aCardHeaderTitleContainer: `H.1.2 CONDIÇÕES COMERCIAIS LEGAIS - Resposta Sim - A`,
                    h12bCardHeaderTitleContainer: `H.1.2 CONDIÇÕES COMERCIAIS LEGAIS - Resposta Sim - B`,
                    h12cCardHeaderTitleContainer: `H.1.2 CONDIÇÕES COMERCIAIS LEGAIS - Resposta Sim - C`,
                    h12dCardHeaderTitleContainer: `H.1.2 CONDIÇÕES COMERCIAIS LEGAIS - Resposta Sim - D`,
                    h12noCardHeaderTitleContainer: `H.1.2 CONDIÇÕES COMERCIAIS LEGAIS - Resposta Não`,
                    i11RequirementTitle: `A empresa segue todas as normas regulamentadoras requeridas para produzir, processar ou comercializar alimentos (exemplo: USDA/FDA)?`,
                    i11PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    i11PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    i11yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    i11yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    i11yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    i11yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    i11yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    i11yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pela quais não incluiu este tema do ESG na estratégia do negócio.`,
                    i11yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    i11yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i11ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática da ESG?`,
                    i11ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática da ESG.`,
                    i11ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e reputação, por exemplo.`,
                    i11ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    i11ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    i11ybyyqt1RequirementTitle: `Quantidade total de prejuízos financeiros resultados de processos associados com permissões regulatórios - Quantidade`,
                    i11ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos`,
                    i11ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    i11ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i11ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    i11ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática da ESG.`,
                    i11ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    i11ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    i11ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    i11ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    i11ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    i11ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i11ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico da ESG em seus critérios?`,
                    i11ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    i11ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    i11ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    i11ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seu fornecedores e prestadores de serviço?`,
                    i11ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    i11ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    i11ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i11naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    i11naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    i11nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    i11nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    i11ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    i11ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    i11CardHeaderTitleContainer: `I.1.1 NORMAS REGULAMENTADORA `,
                    i11aCardHeaderTitleContainer: `I.1.1 NORMAS REGULAMENTADORA - Resposta Sim - A`,
                    i11bCardHeaderTitleContainer: `I.1.1 NORMAS REGULAMENTADORA - Resposta Sim - B`,
                    i11cCardHeaderTitleContainer: `I.1.1 NORMAS REGULAMENTADORA - Resposta Sim - C`,
                    i11dCardHeaderTitleContainer: `I.1.1 NORMAS REGULAMENTADORA - Resposta Sim - D`,
                    i11noCardHeaderTitleContainer: `I.1.1 NORMAS REGULAMENTADORA - Resposta Não`,
                    i21RequirementTitle: `A empresa segue todas as normas regulamentadoras requeridas para produzir, processar ou comercializar alimentos (exemplo: USDA/FDA)?`,
                    i21PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    i21PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    i21yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    i21yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    i21yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    i21yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    i21yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    i21yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pela quais não incluiu este tema do ESG na estratégia do negócio.`,
                    i21yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    i21yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i21ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática da ESG?`,
                    i21ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática da ESG.`,
                    i21ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e reputação, por exemplo.`,
                    i21ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    i21ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    i21ybyyqt1RequirementTitle: `Quantidade total de prejuízos financeiros resultados de processos associados com permissões regulatórios - Quantidade`,
                    i21ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos`,
                    i21ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    i21ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i21ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    i21ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática da ESG.`,
                    i21ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    i21ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    i21ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    i21ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    i21ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    i21ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i21ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico da ESG em seus critérios?`,
                    i21ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    i21ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    i21ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    i21ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seu fornecedores e prestadores de serviço?`,
                    i21ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    i21ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    i21ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i21naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    i21naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    i21nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    i21nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    i21ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    i21ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    i21CardHeaderTitleContainer: `I.2.1  NORMAS AMBIENTAIS`,
                    i21aCardHeaderTitleContainer: `I.2.1 NORMAS AMBIENTAIS - Resposta Sim - A`,
                    i21bCardHeaderTitleContainer: `I.2.1 NORMAS AMBIENTAIS - Resposta Sim - B`,
                    i21cCardHeaderTitleContainer: `I.2.1 NORMAS AMBIENTAIS - Resposta Sim - C`,
                    i21dCardHeaderTitleContainer: `I.2.1 NORMAS AMBIENTAIS - Resposta Sim - D`,
                    i21noCardHeaderTitleContainer: `I.2.1 NORMAS AMBIENTAIS - Resposta Não`,

                    i31RequirementTitle: `A empresa utiliza da rotulagem para comunicar informações regulamentadoras adicionais, como: declaração de organismos geneticamente modificados (OGM) utilizados como ingredientes ou se empenha para promover a consumação responsável destes produtos?`,
                    i31PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    i31PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    i31yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    i31yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    i31yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    i31yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    i31yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    i31yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pela quais não incluiu este tema do ESG na estratégia do negócio.`,
                    i31yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    i31yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i31ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática da ESG?`,
                    i31ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática da ESG.`,
                    i31ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e reputação, por exemplo.`,
                    i31ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    i31ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    i31ybyyqt1RequirementTitle: `Porcentagem de produtos rotulados como "proativo" (tais como: Declarando OMGs como ingredientes ou esforços para promover consumo responsável) em relação ao total de produtos - %`,
                    i31ybyyqt2RequirementTitle: `Total de prejuízo financeiro resultado de ações legais associado com rotulação não conforme - Quantidade`,
                    i31ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos`,
                    i31ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    i31ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i31ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    i31ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática da ESG.`,
                    i31ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    i31ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    i31ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    i31ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    i31ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    i31ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i31ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico da ESG em seus critérios?`,
                    i31ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    i31ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    i31ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    i31ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seu fornecedores e prestadores de serviço?`,
                    i31ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    i31ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    i31ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i31naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    i31naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    i31nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    i31nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    i31ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    i31ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    i31CardHeaderTitleContainer: `I.3.1  AVISOS DE ISENÇÃO DE RESPONSABILIDADE`,
                    i31aCardHeaderTitleContainer: `I.3.1 AVISOS DE ISENÇÃO DE RESPONSABILIDADE - Resposta Sim - A`,
                    i31bCardHeaderTitleContainer: `I.3.1 AVISOS DE ISENÇÃO DE RESPONSABILIDADE - Resposta Sim - B`,
                    i31cCardHeaderTitleContainer: `I.3.1 AVISOS DE ISENÇÃO DE RESPONSABILIDADE - Resposta Sim - C`,
                    i31dCardHeaderTitleContainer: `I.3.1 AVISOS DE ISENÇÃO DE RESPONSABILIDADE - Resposta Sim - D`,
                    i31noCardHeaderTitleContainer: `I.3.1 AVISOS DE ISENÇÃO DE RESPONSABILIDADE - Resposta Não`,

                    i41RequirementTitle: `A empresa divulga voluntariamente dados sobre taxas para todos os stakeholders (partes interessadas)?`,
                    i41PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    i41PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    i41yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    i41yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    i41yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    i41yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    i41yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    i41yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pela quais não incluiu este tema do ESG na estratégia do negócio.`,
                    i41yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    i41yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i41ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática da ESG?`,
                    i41ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática da ESG.`,
                    i41ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e reputação, por exemplo.`,
                    i41ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    i41ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    i41ybyyqt1RequirementTitle: `Nível de satisfação: A medida da satisfação dos stakeholder, geralmente medido através de pesquisa de feedback.`,
                    i41ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos`,
                    i41ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos ? `,
                    i41ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i41ycRequirementTitle: `A empresa considera o resultado positivo ? `,
                    i41ycPlaceHolderTextNo: `Sua resposta foi "NÃO".Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática da ESG.`,
                    i41ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG ? `,
                    i41ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    i41ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores(fornecedores, prestadores de serviço e clientes) ? `,
                    i41ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    i41ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG ? `,
                    i41ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i41ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico da ESG em seus critérios ? `,
                    i41ydPlaceHolderTextNo: `Sua resposta foi "NÃO".Comente se considera a certificação relevante ou não.`,
                    i41ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações ? `,
                    i41ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    i41ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seu fornecedores e prestadores de serviço ? `,
                    i41ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    i41ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    i41ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i41naRequirementTitle: `A empresa tem a intenção de adotar esta prática ? `,
                    i41naPlaceHolderTextNo: `Sua resposta foi "NÃO".Comente sobre os motivos de não adotar esta prática.`,
                    i41nbRequirementTitle: `Há algum projeto de adoção em andamento ? `,
                    i41nbPlaceHolderTextNo: `Sua resposta foi "NÃO".Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    i41ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil ? `,
                    i41ncPlaceHolderTextNo: `Sua resposta foi "NÃO".Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    i41CardHeaderTitleContainer: `I.4.1  CONFORMIDADE FISCAL `,
                    i41aCardHeaderTitleContainer: `I.4.1 CONFORMIDADE FISCAL  - Resposta Sim - A`,
                    i41bCardHeaderTitleContainer: `I.4.1 CONFORMIDADE FISCAL  - Resposta Sim - B`,
                    i41cCardHeaderTitleContainer: `I.4.1 CONFORMIDADE FISCAL  - Resposta Sim - C`,
                    i41dCardHeaderTitleContainer: `I.4.1 CONFORMIDADE FISCAL  - Resposta Sim - D`,
                    i41noCardHeaderTitleContainer: `I.4.1 CONFORMIDADE FISCAL - Resposta Não`,
                    i51RequirementTitle: `A empresa identifica voluntariamente seus stakeholders principais, divulga as estratégias da ESG, fornece dados auditáveis e utiliza parâmetros para reportar seus resultados?`,
                    i51PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    i51PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    i51yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    i51yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    i51yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    i51yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    i51yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    i51yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pela quais não incluiu este tema do ESG na estratégia do negócio.`,
                    i51yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    i51yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i51ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática da ESG?`,
                    i51ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática da ESG.`,
                    i51ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e reputação, por exemplo.`,
                    i51ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    i51ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    i51ybyyqt1RequirementTitle: `Número de reunião feitas com os stakeholders, tais como clientes, fornecedores, empregados, entre outros.`,
                    i51ybyyqt2RequirementTitle: `Porcentagem de stakeholders que responderam a pesquisa ou questionário.`,
                    i51ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos`,
                    i51ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    i51ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i51ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    i51ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática da ESG.`,
                    i51ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    i51ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    i51ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    i51ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    i51ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    i51ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i51ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico da ESG em seus critérios?`,
                    i51ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    i51ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    i51ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    i51ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seu fornecedores e prestadores de serviço?`,
                    i51ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    i51ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    i51ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i51naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    i51naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    i51nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    i51nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    i51ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    i51ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    i51CardHeaderTitleContainer: `I.5.1  PRÁTICAS DE RELATÓRIOS `,
                    i51aCardHeaderTitleContainer: `I.5.1  PRÁTICAS DE RELATÓRIOS  - Resposta Sim - A`,
                    i51bCardHeaderTitleContainer: `I.5.1  PRÁTICAS DE RELATÓRIOS  - Resposta Sim - B`,
                    i51cCardHeaderTitleContainer: `I.5.1  PRÁTICAS DE RELATÓRIOS  - Resposta Sim - C`,
                    i51dCardHeaderTitleContainer: `I.5.1  PRÁTICAS DE RELATÓRIOS  - Resposta Sim - D`,
                    i51noCardHeaderTitleContainer: `I.5.1  PRÁTICAS DE RELATÓRIOS - Resposta Não`,
                    i61RequirementTitle: `A empresa possui regulamentos claros a fim de evitar situações de corrupção interna ou externa?`,
                    i61PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    i61PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    i61yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    i61yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    i61yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    i61yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    i61yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    i61yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pela quais não incluiu este tema do ESG na estratégia do negócio.`,
                    i61yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    i61yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i61ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática da ESG?`,
                    i61ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática da ESG.`,
                    i61ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e reputação, por exemplo.`,
                    i61ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    i61ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    i61ybyyqt1RequirementTitle: `Número de reclamações recebidas relacionadas a atividades de corrupção tais como propina ou lavagem de dinheiro.`,
                    i61ybyyqt2RequirementTitle: `Média de tempo necessária para resolver um violação de compliance, do momento que foi detectado até o momento que foi resolvido. `,
                    i61ybyyqt3RequirementTitle: `Número de empregados que participam de treinamento de políticas e práticas de anti-corrupção `,
                    i61ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos.`,
                    i61ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    i61ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i61ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    i61ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática da ESG.`,
                    i61ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    i61ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    i61ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    i61ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    i61ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    i61ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i61ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico da ESG em seus critérios?`,
                    i61ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    i61ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    i61ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    i61ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seu fornecedores e prestadores de serviço?`,
                    i61ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    i61ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    i61ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    i61naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    i61naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    i61nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    i61nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    i61ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    i61ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    i61CardHeaderTitleContainer: `I.6.1  PRÁTICAS DE RELATÓRIOS `,
                    i61aCardHeaderTitleContainer: `I.6.1  PRÁTICAS DE RELATÓRIOS  - Resposta Sim - A`,
                    i61bCardHeaderTitleContainer: `I.6.1  PRÁTICAS DE RELATÓRIOS  - Resposta Sim - B`,
                    i61cCardHeaderTitleContainer: `I.6.1  PRÁTICAS DE RELATÓRIOS  - Resposta Sim - C`,
                    i61dCardHeaderTitleContainer: `I.6.1  PRÁTICAS DE RELATÓRIOS  - Resposta Sim - D`,
                    i61noCardHeaderTitleContainer: `I.6.1  PRÁTICAS DE RELATÓRIOS - Resposta Não`,

                    j11RequirementTitle: `A empresa exige práticas da ESG de sua cadeia de suprimentos?`,
                    j11PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    j11PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    j11yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    j11yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    j11yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    j11yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    j11yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    j11yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pela quais não incluiu este tema do ESG na estratégia do negócio.`,
                    j11yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    j11yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    j11ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática da ESG?`,
                    j11ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática da ESG.`,
                    j11ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e reputação, por exemplo.`,
                    j11ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    j11ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    j11ybyyqt1RequirementTitle: `Número de fornecedores avaliados pela práticas do ESG.`,
                    j11ybyyqt2RequirementTitle: `Porcentagem de fornecedores que atendem o padrão de sustentabilidade estabelecido pela empresa. `,
                    j11ybyyqt3RequirementTitle: `Número de fornecedores que receberam treinamento nas práticas do ESG.`,
                    j11ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos.`,
                    j11ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    j11ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    j11ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    j11ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática da ESG.`,
                    j11ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    j11ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    j11ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    j11ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    j11ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    j11ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    j11ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico da ESG em seus critérios?`,
                    j11ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    j11ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    j11ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    j11ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seu fornecedores e prestadores de serviço?`,
                    j11ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    j11ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    j11ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    j11naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    j11naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    j11nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    j11nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    j11ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    j11ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    j11CardHeaderTitleContainer: `J.1.1  PARTICIPAÇÃO NA CADEIA DE SUPRIMENTOS `,
                    j11aCardHeaderTitleContainer: `J.1.1  PARTICIPAÇÃO NA CADEIA DE SUPRIMENTOS  - Resposta Sim - A`,
                    j11bCardHeaderTitleContainer: `J.1.1  PARTICIPAÇÃO NA CADEIA DE SUPRIMENTOS  - Resposta Sim - B`,
                    j11cCardHeaderTitleContainer: `J.1.1  PARTICIPAÇÃO NA CADEIA DE SUPRIMENTOS  - Resposta Sim - C`,
                    j11dCardHeaderTitleContainer: `J.1.1  PARTICIPAÇÃO NA CADEIA DE SUPRIMENTOS  - Resposta Sim - D`,
                    j11noCardHeaderTitleContainer: `J.1.1  PARTICIPAÇÃO NA CADEIA DE SUPRIMENTOS - Resposta Não`,
                    j21RequirementTitle: `A empresa se associa com outras empresas do setor, região ou ramo para benefícios ou ações em comum, incluindo as práticas da ESG?`,
                    j21PlaceHolderTextYes: `Sua resposta foi "SIM". Descreva brevemente a prática adotada e responda as perguntas abaixo. `,
                    j21PlaceHolderTextNo: `Por favor responda as questões abaixo.`,
                    j21yaRequirementTitle: `A adoção desta prática foi baseada em planejamentos elaborados e documentados?`,
                    j21yaPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não planejar adotar esta prática`,
                    j21yayRequirementTitle: `Essa prática foi adotada por mais de um ano contábil?`,
                    j21yayPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente sobre qual a dificuldade em desenvolver projetos para adotar esta prática do ESG no curto prazo.`,
                    j21yayyRequirementTitle: `Este tema do ESG está incluso na estratégia do negócio?`,
                    j21yayyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões pela quais não incluiu este tema do ESG na estratégia do negócio.`,
                    j21yayyqlRequirementTitle: `Por favor envie um documento detalhado com a relevância desse tópico do ESG para a estratégia de negócio.`,
                    j21yayyqlPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    j21ybRequirementTitle: `A empresa avalia periodicamente os resultados da adoção desta prática da ESG?`,
                    j21ybPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não avaliar periodicamente os resultados desta prática da ESG.`,
                    j21ybyRequirementTitle: `Quais tipos de indicadores de perfomance do ESG, ou KPIs, que são utilizados em sua empresa para ajudar a entender o impacto ambiental (e também social) desta prática? Liste os indicadores financeiros, processuais e reputação, por exemplo.`,
                    j21ybyPlaceHolderTextYes: `Você indicou os KPI, por favor nos forneça os dados abaixo`,
                    j21ybyPlaceHolderTextNo: `Você não listou os indicadores, por favor justique.`,
                    j21ybyyqt1RequirementTitle: `Número de parcerias estabelecidas com outras empresas para a implementação de práticas sustentáveis.`,
                    j21ybyyqt2RequirementTitle: `Total investido, junto com outras empresas, em iniciativas de junção para sustentabilidade.`,
                    j21ybyyqtnRequirementTitle: `Outro tipo de KPI? Por favor descreva brevemente e insira os últimos resultados medidos.`,
                    j21ybyyql1RequirementTitle: `Favor enviar um documento detalhado dos KPis utilizados e os último resultados medidos?`,
                    j21ybyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    j21ycRequirementTitle: `A empresa considera o resultado positivo?`,
                    j21ycPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos para não considerar positivos os resultados da adoção desta prática da ESG.`,
                    j21ycyRequirementTitle: `A empresa pretende expandir a adoção da prática do ESG?`,
                    j21ycyPlaceHolderTextNo: `Sua resposta foi "NÃO", portanto comente o que previne de expandir essa prática.`,
                    j21ycyyRequirementTitle: `É parte da estratégia da empresa envolver os stakeholders nas práticas deste tópico do ESG, especialmente em sua cadeia de valores (fornecedores, prestadores de serviço e clientes)?`,
                    j21ycyyPlaceHolderTextNo: `Sua resposta foi "NÃO", por favor explique as razões de não envolver sua cadeia de valores neste tópico do ESG.`,
                    j21ycyyql1RequirementTitle: `Por favor nos envie um documento detalhando como os stakeholders estão envolvidos neste tópico do ESG?`,
                    j21ycyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    j21ydRequirementTitle: `A empresa possui alguma certificação que inclui este tópico da ESG em seus critérios?`,
                    j21ydPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente se considera a certificação relevante ou não.`,
                    j21ydyRequirementTitle: `A empresa usa o critério de certificação em todas suas operações?`,
                    j21ydyPlaceHolderTextNo: ` Sua resposta foi "NÃO", portanto comente o que previne de expandir o critério`,
                    j21ydyyRequirementTitle: `O critério adotado para este tópico do ESG também é cobrado de seu fornecedores e prestadores de serviço?`,
                    j21ydyyPlaceHolderTextNo: ` Sua resposta foi "NÃO", por favor explique a razão de não envolver sua cadeia de valores neste tópico do ESG.`,
                    j21ydyyql1RequirementTitle: `Por favor nos envie um documento detalhando este critério.`,
                    j21ydyyql1PlaceHolderTextNo: `Sua resposta foi "NÃO", por favor justifique, se possível.`,
                    j21naRequirementTitle: `A empresa tem a intenção de adotar esta prática?`,
                    j21naPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre os motivos de não adotar esta prática.`,
                    j21nbRequirementTitle: `Há algum projeto de adoção em andamento?`,
                    j21nbPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre as dificuldades em desenvolver projetos para adoção.`,
                    j21ncRequirementTitle: `A implementação desta prática estará completa neste ano contábil?`,
                    j21ncPlaceHolderTextNo: `Sua resposta foi "NÃO". Comente sobre a previsão do tempo que levaria para esta prática ser adotada.`,
                    j21CardHeaderTitleContainer: `J.2.1  ASSOCIAÇÃO À SUSTENTABILIDADE `,
                    j21aCardHeaderTitleContainer: `J.2.1  ASSOCIAÇÃO À SUSTENTABILIDADE  - Resposta Sim - A`,
                    j21bCardHeaderTitleContainer: `J.2.1  ASSOCIAÇÃO À SUSTENTABILIDADE  - Resposta Sim - B`,
                    j21cCardHeaderTitleContainer: `J.2.1  ASSOCIAÇÃO À SUSTENTABILIDADE  - Resposta Sim - C`,
                    j21dCardHeaderTitleContainer: `J.2.1  ASSOCIAÇÃO À SUSTENTABILIDADE  - Resposta Sim - D`,
                    j21noCardHeaderTitleContainer: `J.2.1  ASSOCIAÇÃO À SUSTENTABILIDADE - Resposta Não`,
                    specificQuestionUSDAFDA: `Perguntas específicas sobre USDA e/ou FDA, se aplicável`,
                    specificQuestionFsmaPlan: `A empresa possui um plano de adulteração intencional FSMA?`,
                    attachEvidence: `Se sim, por favor anexe a Evidência.`,
                    optionNo: `Não`,
                    optionYes: `Sim`,
                    buttonOpenFile: `Carregar aquivo`,
                    buttonChangeFile: `Alterar Aquivo`,
                    priorNoticetoImport: `A empresa protocolou Aviso Prévio de Importação?`,
                    titleAdditionalInformation: `Informação Adicional`,
                    auditwithin12months: `A empresa realizou algum tipo de auditoria nos últimos 12 meses?`,
                    federalAudit: ` Auditoria Federal:`,
                    codeFederalRegualations: `Nos EUA, para regra PC de alimentos para humanos, Parte 117/ para regra de segurança da Produção, Part 112 do USDA - Código de Regulamentação Federal (CFR)`,
                    internalAudit: `Auditoria de 1ª Parte - Auditoria Interna`,
                    noteFirstPartyAudit: `Nota: Uma auditoria de primeira parte ocorre quando uma auditoria é realizada dentro da sua empresa pelo seu próprio auditor. Isso é frequentemente chamado de auditoria interna`,
                    companyHired: `Empresa Contratada`,
                    standard: `Norma`,
                    secondPartyAudit: `Auditoria de 2ª Parte - Controle de Fornecedores`,
                    noteSecondPartyAudit: `Nota: Uma auditoria de segunda parte é realizada em um fornecedor, cliente, ou terceiro, geralmente com base em seus requisitos próprios. (Costco, Walmart, Yum Brands, Etc..)`,
                    thirdPartyAudit: `Auditoria de 3ª parte - Auditoria de Certificação / Auditoria Federal`,
                    notethirdPartyAudit: `Nota: Uma auditoria de terceira parte é uma auditoria realizada por um orgão independente (ex. Um assessor ou certificadora) com base em uma norma reconhecida (SQF, BRCGS, PrimusGFS, ISO 22000, etc..)`,
                    certificationBody: `Certificadora`,
                    productDescr: `Descrição do Produto e Produção`,
                    listOfAllProducts: `Lista de todos os produtos produzido(s)`,
                    exampleListOfAllProducts: `Exemplo: Carne bovina, cortes de carne bovina, peixe fresco, frutas frescas, frutas semi processadas (descreva cada uma), pão congelado, sopa, etc).`,
                    listOfProdValidatedMFS: `Lista de produto(s) à serem validados pela MyTS:`,
                    establishmentData: `Dados do estabelecimento`,
                    numberOfEmployees: `Número de empregados (incluindo o departamento administrativo)`,
                    female: `Mulheres:`,
                    male: `Homens:`,
                    total: `Total:`,
                    numberEmployeeQualityDept: `Especifique o número de empregados no departamento da qualidade/segurança do alimento`,
                    associationOrCooperative: ` A empresa é parte de uma associação ou cooperativa?`,
                    supportMinorityGroup: `A empresa participa ou ajuda algum grupo minoritário`,
                    exampleMinorityGroup: `(exemplo: racial, étnica, gênero, sexualidade, religião, deficiências)`,
                    usFsvpImporter: `A empresa é qualificada para importação US FSVP?`,
                    usdaImportRequirement: `Você atende os Requisitos de importação do USDA?`,
                    importerRecordUs: `Você possui registro de importador nos Estados Unidos`,
                    recallRegulatoryInspection: `Já teve algum recall/advertência recentemente de alguma inspeção regulatória?`,
                    usAlertList: `Você está em alguma lista de alerta de importação dos EUA`,
                    supplierQuestionnaireEsg: `Questionário de Fornecedor - ESG`,
                    submit: `Enviar`,
                    legalCompanyName: `Razão Social`,
                    einNumber: `CNPJ:`,
                    redMeat: `Carne Bovina`,
                    poultry: `Frango`,
                    fish: `Peixe`,
                    fruit: `Frutas`,
                    vegetables: `Legumes e Verduras`,
                    nuts: `Frutos Secos`,
                    dairyEgg: `Ovos e Lácteos`,
                    mealsSandwich: `Comidas prontas e sanduíches`,
                    beverages: `Bebidas`,
                    alcoholicDrinks: `Bebidas Alcoólicas`,
                    coffee: `Café`,
                    bakery: `Padaria`,
                    pantryProduct: `Armazém (enlatado)`,
                    ingredients: `Ingredientes`,
                    dryProducts: `Produtos Secos`,
                    confectionerySnacks: `Confeitaria & Snacks`,
                    sauce: `Molhos`,
                    oilFats: `Óleos e Gorduras`,
                    plantBased: `Feito de Plantas`,
                    foodProdIndustry: `Indústria de Produção Alimentos `,
                    farmFieldPacking: `Fazenda (Campo e Empacotamento)`,
                    farmRuralProducer: `Fazenda (Produtor Rural)`,
                    person: `Responsável`,
                    position: `Posição`,
                    typeOfCompany: `Tipo de Empresa`,
                    requirement: 'REQUERIMENTO',
                }
                break
            default:
                return {
                    other: 'Other',
                    questionanswer: `QUESTION ANSWER`,
                    comments: `Comments`,
                    specificQuestionUsdaFda: `Specific Questions about USDA and/ or FDA, if aplicable`,
                    specificQuestionFsmaPlan: ` Does the company have FSMA Intentional Adulteration Plan?`,
                    specificQuestionAttachEvidence: `If Yes, please attach the Evidence`,
                    a11RequirementTitle: `Does your company have any practices to reduce fuel or energy consumption? Or do you still have some form of self-generation of electricity?`,
                    a11PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field)`,
                    a11PlaceHolderTextNo: `Answer the following questions, please:`,
                    a11yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    a11yaPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    a11yayRequirementTitle: `Was this practice adopted more than one fiscal year?`,
                    a11yayPlaceHolderTextNo: `Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    a11yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    a11yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    a11yayyqlRequirementTitle: `Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    a11yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    a11ybRequirementTitle: `Does the company periodically evaluate the results of adopting this ESG practice?`,
                    a11ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    a11ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example:`,
                    a11ybyPlaceHolderTextYes: `You have indicated KPI, can you provide us with any of the following data:`,
                    a11ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    a11ybyyqt1RequirementTitle: `Total energy consumed - [Gigajoules (GJ) = 0.28 Megawatthour (MWh)], process and reputation indicators, for example:`,
                    a11ybyyqt2RequirementTitle: `Fleet fuel consumed – Type of fuel (Diesel/Gasoline) (in Liters / Gallons / Tons)`,
                    a11ybyyqt3RequirementTitle: `Percentage of fuels from renewable sources over total fuels - %`,
                    a11ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    a11ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    a11ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    a11ycRequirementTitle: `Does the company consider the result positive?`,
                    a11ycPlaceHolderTextNo: `Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    a11ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    a11ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    a11ycyyRequirementTitle: `Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    a11ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    a11ycyyql1RequirementTitle: `Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    a11ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    a11ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    a11ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    a11ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    a11ydyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    a11ydyyRequirementTitle: `Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    a11ydyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    a11ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    a11ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    a11naRequirementTitle: `Does your company intend to adopt this practice?`,
                    a11naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    a11nbRequirementTitle: `Are there any adoption projects in progress?`,
                    a11nbPlaceHolderTextNo: `Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    a11ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    a11ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    aCardHeaderTitleContainer: `A. ENERGY & FUEL USE`,
                    a1aCardHeaderTitleContainer: `A.1.ENERGY & FUEL CONSUMPTION - Answer Yes - A`,
                    a1bCardHeaderTitleContainer: `A.1.ENERGY & FUEL CONSUMPTION - Answer Yes - B`,
                    a1cCardHeaderTitleContainer: `A.1.ENERGY & FUEL CONSUMPTION - Answer Yes - C`,
                    a1dCardHeaderTitleContainer: `A.1.ENERGY & FUEL CONSUMPTION - Answer Yes - D`,
                    a1noCardHeaderTitleContainer: `A.1.ENERGY & FUEL CONSUMPTION - Answer No`,
                    a21RequirementTitle: `Does your company adopt any practices, processes or systems to monitor and manage your energy consumption?`,
                    a21PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions.`,
                    a21PlaceHolderTextNo: `Answer the following questions, please:`,
                    a21yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans`,
                    a21yaPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    a21yayRequirementTitle: `Was this practice adopted more than one fiscal year?`,
                    a21yayPlaceHolderTextNo: `Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    a21yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    a21yayyql1RequirementTitle: `Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    a21yayyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    a21ybRequirementTitle: `Does the company periodically evaluate the results of adopting this ESG practice?`,
                    a21ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    a21ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example:`,
                    a21ybyPlaceHolderTextYes: `You have indicated KPI, can you provide us with any of the following data:`,
                    a21ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    a21ybyyqt1RequirementTitle: `Before the adoption of the practice - (a) Operational energy consumed`,
                    a21ybyyqt2RequirementTitle: `Before the adoption of the practice - (b) percentage grid electricity`,
                    a21ybyyqt3RequirementTitl: `Before the adoption of the practice - (c) percentage energy renewable (%)`,
                    a21ybyyqt4RequirementTitle: `After adopting the practice - (a) Operational energy consumed`,
                    a21ybyyqt5RequirementTitle: `After adopting the practice - (b) percentage grid electricity`,
                    a21ybyyqt6RequirementTitle: `After adopting the practice - (c) percentage energy renewable (%)`,
                    a21ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results`,
                    a21ybyyqtl1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    a21ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    a21ycRequirementTitle: `Does the company consider the result positive?`,
                    a21ycPlaceHolderTextNo: `Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    a21ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    a21ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    a21ycyyRequirementTitle: `Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    a21ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    a21ycyyql1RequirementTitle: `Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    a21ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    a21ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    a21ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    a21ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    a21ydyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding the criteria.`,
                    a21ydyyRequirementTitle: `Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    a21ydyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    a21ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    a21ydyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    a21naRequirementTitle: `Does your company intend to adopt this practice?`,
                    a21naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    a21nbRequirementTitle: `Are there any adoption projects in progress?`,
                    a21nbPlaceHolderTextNo: `Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    a21ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    a21ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    a21CardHeaderTitleContainer: `A.2.1 ENERGY EFFICIENCY`,
                    a21aCardHeaderTitleContainer: `A.2.1 ENERGY EFFICIENCY - Answer Yes - A`,
                    a21bCardHeaderTitleContainer: `A.2.1 ENERGY EFFICIENCY - Answer Yes - B`,
                    a21cCardHeaderTitleContainer: `A.2.1 ENERGY EFFICIENCY - Answer Yes - C`,
                    a21dCardHeaderTitleContainer: `A.2.1 ENERGY EFFICIENCY - Answer Yes - D`,
                    a21noCardHeaderTitleContainer: `A.2.1 ENERGY EFFICIENCY - Answer No`,
                    a22RequirementTitle: ` Does your company have any practices to reduce fuel or energy consumption? Or do you still have some form of self-generation of electricity?`,
                    a22PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions.`,
                    a22PlaceHolderTextNo: `Answer the following questions, please`,
                    a22yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    a22yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    a22yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    a22yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    a22yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    a22yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    a22yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    a22yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    a22ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    a22ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    a22ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    a22ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    a22ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    a22ybyyqt1RequirementTitle: `Percentage of business units that adopt energy efficiency practices.`,
                    a22ybyyqt2RequirementTitle: `Average time for system implementation - Time unit`,
                    a22ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    a22ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    a22ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    a22ycRequirementTitle: ` Does the company consider the result positive?`,
                    a22ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    a22ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    a22ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    a22ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    a22ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    a22ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    a22ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    a22ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    a22ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    a22ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    a22ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    a22ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    a22ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    a22ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    a22ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    a22naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    a22naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    a22nbRequirementTitle: `Are there any adoption projects in progress?`,
                    a22nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    a22ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    a22ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    a22CardHeaderTitleContainer: `A.2.2 ENERGY EFFICIENCY`,
                    a22aCardHeaderTitleContainer: `A.2.2 ENERGY EFFICIENCY - Answer Yes - A`,
                    a22bCardHeaderTitleContainer: `A.2.2 ENERGY EFFICIENCY - Answer Yes - B`,
                    a22cCardHeaderTitleContainer: `A.2.2 ENERGY EFFICIENCY - Answer Yes - C`,
                    a22dCardHeaderTitleContainer: `A.2.2 ENERGY EFFICIENCY - Answer Yes - D`,
                    a22noCardHeaderTitleContainer: `A.2.2 ENERGY EFFICIENCY - Answer No`,
                    b11RequirementTitle: `Does your company measure its carbon footprint and periodically publish an emissions inventory?`,
                    b11PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. `,
                    b11PlaceHolderTextNo: `Answer the following questions, please`,
                    b11yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    b11yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    b11yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    b11yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    b11yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    b11yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    b11yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    b11yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    b11ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    b11ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    b11ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    b11ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    b11ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    b11ybyyqt1RequirementTitle: `Percentage of business units that measure their carbon footprint.`,
                    b11ybyyqt2RequirementTitle: ` Number of annual inventories reported.`,
                    b11ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    b11ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    b11ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    b11ycRequirementTitle: ` Does the company consider the result positive?`,
                    b11ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    b11ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    b11ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    b11ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    b11ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    b11ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    b11ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    b11ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    b11ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    b11ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    b11ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    b11ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    b11ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    b11ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    b11ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    b11naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    b11naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    b11nbRequirementTitle: `Are there any adoption projects in progress?`,
                    b11nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    b11ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    b11ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    b11CardHeaderTitleContainer: `B GREENHOUSE GAS EMISSIONS`,
                    b11aCardHeaderTitleContainer: `B.1.1 CARBON FOOTPRINT REDUCTION - Answer Yes - A`,
                    b11bCardHeaderTitleContainer: `B.1.1 CARBON FOOTPRINT REDUCTION - Answer Yes - B`,
                    b11cCardHeaderTitleContainer: `B.1.1 CARBON FOOTPRINT REDUCTION - Answer Yes - C`,
                    b11dCardHeaderTitleContainer: `B.1.1 CARBON FOOTPRINT REDUCTION - Answer Yes - D`,
                    b11noCardHeaderTitleContainer: `B.1.1 CARBON FOOTPRINT REDUCTION - Answer No`,
                    b12RequirementTitle: `Does the company adopt any program to reduce Scope 1 emissions? `,
                    b12PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. `,
                    b12PlaceHolderTextNo: `Answer the following questions, please`,
                    b12yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    b12yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    b12yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    b12yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    b12yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    b12yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    b12yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    b12yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    b12ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    b12ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    b12ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    b12ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    b12ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    b12ybyyqt1RequirementTitle: `Tons of CO2e measured in the first inventory.`,
                    b12ybyyqt2RequirementTitle: `Tons of CO2e measured in the last inventory.`,
                    b12ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    b12ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    b12ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    b12ycRequirementTitle: ` Does the company consider the result positive?`,
                    b12ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    b12ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    b12ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    b12ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    b12ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    b12ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    b12ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    b12ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    b12ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    b12ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    b12ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    b12ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    b12ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    b12ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    b12ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    b12naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    b12naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    b12nbRequirementTitle: `Are there any adoption projects in progress?`,
                    b12nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    b12ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    b12ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    b12CardHeaderTitleContainer: `B.1.2 CARBON FOOTPRINT REDUCTION`,
                    b12aCardHeaderTitleContainer: `B.1.2 CARBON FOOTPRINT REDUCTION - Answer Yes - A`,
                    b12bCardHeaderTitleContainer: `B.1.2 CARBON FOOTPRINT REDUCTION - Answer Yes - B`,
                    b12cCardHeaderTitleContainer: `B.1.2 CARBON FOOTPRINT REDUCTION - Answer Yes - C`,
                    b12dCardHeaderTitleContainer: `B.1.2 CARBON FOOTPRINT REDUCTION - Answer Yes - D`,
                    b12noCardHeaderTitleContainer: `B.1.2 CARBON FOOTPRINT REDUCTION - Answer No`,
                    c11RequirementTitle: `Does the company adopt any water consumption reduction program?`,
                    c11PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field).`,
                    c11PlaceHolderTextNo: `Answer the following questions, please`,
                    c11yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    c11yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    c11yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    c11yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    c11yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    c11yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    c11yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    c11yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    c11ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    c11ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    c11ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    c11ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    c11ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    c11ybyyqt1RequirementTitle: `Before the adoption of the practice - Total water consumed - M³`,
                    c11ybyyqt2RequirementTitle: `After adopting the practice - Total water consumed - M³`,
                    c11ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    c11ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    c11ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    c11ycRequirementTitle: ` Does the company consider the result positive?`,
                    c11ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    c11ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    c11ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    c11ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    c11ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    c11ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    c11ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    c11ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    c11ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    c11ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    c11ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    c11ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    c11ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    c11ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    c11ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    c11naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    c11naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    c11nbRequirementTitle: `Are there any adoption projects in progress?`,
                    c11nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    c11ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    c11ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    c11CardHeaderTitleContainer: `C.1.1 WATER CONSUMPTION`,
                    c11aCardHeaderTitleContainer: `C.1.1 WATER CONSUMPTION - Answer Yes - A`,
                    c11bCardHeaderTitleContainer: `C.1.1 WATER CONSUMPTION - Answer Yes - B`,
                    c11cCardHeaderTitleContainer: `C.1.1 WATER CONSUMPTION - Answer Yes - C`,
                    c11dCardHeaderTitleContainer: `C.1.1 WATER CONSUMPTION - Answer Yes - D`,
                    c11noCardHeaderTitleContainer: `C.1.1 WATER CONSUMPTION - Answer No`,
                    c21RequirementTitle: `Does the company adopt any process, device or system for reuse or recycling of water?`,
                    c21PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field).`,
                    c21PlaceHolderTextNo: `Answer the following questions, please`,
                    c21yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    c21yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    c21yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    c21yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    c21yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    c21yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    c21yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    c21yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    c21ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    c21ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    c21ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    c21ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    c21ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    c21ybyyqt1RequirementTitle: `Amount of water that is recycled in relation to the total amount of water used.`,
                    c21ybyyqt2RequirementTitle: `Proportion of contaminants that are removed from the recycled water system.`,
                    c21ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    c21ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    c21ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    c21ycRequirementTitle: ` Does the company consider the result positive?`,
                    c21ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    c21ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    c21ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    c21ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    c21ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    c21ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    c21ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    c21ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    c21ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    c21ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    c21ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    c21ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    c21ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    c21ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    c21ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    c21naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    c21naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    c21nbRequirementTitle: `Are there any adoption projects in progress?`,
                    c21nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    c21ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    c21ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    c21CardHeaderTitleContainer: `C.2.1 REUSE AND RECYCLING (WATER)`,
                    c21aCardHeaderTitleContainer: `C.2.1 REUSE AND RECYCLING (WATER) - Answer Yes - A`,
                    c21bCardHeaderTitleContainer: `C.2.1 REUSE AND RECYCLING (WATER) - Answer Yes - B`,
                    c21cCardHeaderTitleContainer: `C.2.1 REUSE AND RECYCLING (WATER) - Answer Yes - C`,
                    c21dCardHeaderTitleContainer: `C.2.1 REUSE AND RECYCLING (WATER) - Answer Yes - D`,
                    c21noCardHeaderTitleContainer: `C.2.1 REUSE AND RECYCLING (WATER) - Answer No`,
                    d11RequirementTitle: `Do you use land for agriculture or animal husbandry? If so, does it seek to use the available land intensively, but in a sustainable way? `,
                    d11PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field) `,
                    d11PlaceHolderTextNo: `Answer the following questions, please`,
                    d11yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    d11yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    d11yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    d11yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    d11yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    d11yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    d11yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    d11yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    d11ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    d11ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    d11ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    d11ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    d11ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    d11ybyyqt1RequirementTitle: `Total land area under active production – Hectares.`,
                    d11ybyyqt2RequirementTitle: `Average property size in your region - Hectares.`,
                    d11ybyyqt3RequirementTitle: `Amount of agricultural products or animal protein produced per hectare`,
                    d11ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    d11ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    d11ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    d11ycRequirementTitle: ` Does the company consider the result positive?`,
                    d11ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    d11ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    d11ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    d11ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    d11ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    d11ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    d11ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    d11ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    d11ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    d11ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    d11ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    d11ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    d11ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    d11ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    d11ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    d11naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    d11naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    d11nbRequirementTitle: `Are there any adoption projects in progress?`,
                    d11nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    d11ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    d11ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    d11CardHeaderTitleContainer: `D.1.1 RATIONAL LAND USE`,
                    d11aCardHeaderTitleContainer: `D.1.1 RATIONAL LAND USE - Answer Yes - A`,
                    d11bCardHeaderTitleContainer: `D.1.1 RATIONAL LAND USE - Answer Yes - B`,
                    d11cCardHeaderTitleContainer: `D.1.1 RATIONAL LAND USE - Answer Yes - C`,
                    d11dCardHeaderTitleContainer: `D.1.1 RATIONAL LAND USE - Answer Yes - D`,
                    d11noCardHeaderTitleContainer: `D.1.1 RATIONAL LAND USE - Answer No`,
                    d21RequirementTitle: `Does the company have a forest conservation program in the areas where it produces or has influence? `,
                    d21PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field) `,
                    d21PlaceHolderTextNo: `Answer the following questions, please`,
                    d21yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    d21yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    d21yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    d21yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    d21yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    d21yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    d21yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    d21yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    d21ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    d21ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    d21ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    d21ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    d21ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    d21ybyyqt1RequirementTitle: ` Percentage of land area owned by the company covered by forests, if applicable.`,
                    d21ybyyqt2RequirementTitle: ` Area reforested or preserved by programs or initiatives.`,
                    d21ybyyqt3RequirementTitle: `Percentage of land area owned by the company protected by government laws and regulations, if applicable.`,
                    d21ybyyqt4RequirementTitle: `Amount invested in the last fiscal year in own reforestation or forest preservation programs or carried out by other organizations.`,
                    d21ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    d21ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    d21ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    d21ycRequirementTitle: ` Does the company consider the result positive?`,
                    d21ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    d21ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    d21ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    d21ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    d21ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    d21ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    d21ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    d21ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    d21ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    d21ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    d21ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    d21ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    d21ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    d21ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    d21ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    d21naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    d21naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    d21nbRequirementTitle: `Are there any adoption projects in progress?`,
                    d21nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    d21ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    d21ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    d21CardHeaderTitleContainer: `D.2.1 FOREST PRESERVATION`,
                    d21aCardHeaderTitleContainer: `D.2.1 FOREST PRESERVATION - Answer Yes - A`,
                    d21bCardHeaderTitleContainer: `D.2.1 FOREST PRESERVATION - Answer Yes - B`,
                    d21cCardHeaderTitleContainer: `D.2.1 FOREST PRESERVATION - Answer Yes - C`,
                    d21dCardHeaderTitleContainer: `D.2.1 FOREST PRESERVATION - Answer Yes - D`,
                    d21noCardHeaderTitleContainer: `D.2.1 FOREST PRESERVATION - Answer No`,
                    d31RequirementTitle: `Do you have strict criteria for the use of chemicals in agriculture, ingredients or processed foods? `,
                    d31PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field) `,
                    d31PlaceHolderTextNo: `Answer the following questions, please`,
                    d31yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    d31yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    d31yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    d31yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    d31yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    d31yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    d31yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    d31yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    d31ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    d31ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    d31ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    d31ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    d31ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    d31ybyyqt1RequirementTitle: `Maximum residue limits - MRLs (Amount of pesticides, herbicides, and other chemicals that can remain on or in food products).`,
                    d31ybyyqt2RequirementTitle: ` Percentage of animal production that received medically important antibiotics, by animal type - % `,
                    d31ybyyqt3RequirementTitle: `Volume and types of hazardous chemicals used in agriculture, food processing, and packaging.`,
                    d31ybyyqt4RequirementTitle: `Frequency of chemical application - Time units.`,
                    d31ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    d31ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    d31ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    d31ycRequirementTitle: ` Does the company consider the result positive?`,
                    d31ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    d31ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    d31ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    d31ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    d31ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    d31ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    d31ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    d31ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    d31ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    d31ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    d31ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    d31ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    d31ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    d31ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    d31ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    d31naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    d31naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    d31nbRequirementTitle: `Are there any adoption projects in progress?`,
                    d31nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    d31ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    d31ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    d31CardHeaderTitleContainer: `D.3.1 RATIONAL USE OF CHEMICAL INGREDIENTS `,
                    d31aCardHeaderTitleContainer: `D.3.1 RATIONAL USE OF CHEMICAL INGREDIENTS - Answer Yes - A`,
                    d31bCardHeaderTitleContainer: `D.3.1 RATIONAL USE OF CHEMICAL INGREDIENTS - Answer Yes - B`,
                    d31cCardHeaderTitleContainer: `D.3.1 RATIONAL USE OF CHEMICAL INGREDIENTS - Answer Yes - C`,
                    d31dCardHeaderTitleContainer: `D.3.1 RATIONAL USE OF CHEMICAL INGREDIENTS - Answer Yes - D`,
                    d31noCardHeaderTitleContainer: `D.3.1 RATIONAL USE OF CHEMICAL INGREDIENTS - Answer No`,
                    d41RequirementTitle: `Do you have strict criteria for the use of GMO (Genetically Modified Organisms) in agriculture, ingredients or processed foods?  `,
                    d41PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field) `,
                    d41PlaceHolderTextNo: `Answer the following questions, please`,
                    d41yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    d41yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    d41yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    d41yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    d41yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    d41yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    d41yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    d41yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    d41ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    d41ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    d41ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    d41ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    d41ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    d41ybyyqt1RequirementTitle: `Percentage of agricultural production that received GMO even if allowed, by type of crop - %.`,
                    d41ybyyqt2RequirementTitle: `Percentage of products with GMO ingredients over total products - %. `,
                    d41ybyyqt3RequirementTitle: `Productivity and yield of GMOs compared to notransgenic crops - %.`,
                    d41ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    d41ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    d41ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    d41ycRequirementTitle: ` Does the company consider the result positive?`,
                    d41ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    d41ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    d41ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    d41ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    d41ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    d41ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    d41ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    d41ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    d41ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    d41ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    d41ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    d41ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    d41ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    d41ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    d41ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    d41naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    d41naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    d41nbRequirementTitle: `Are there any adoption projects in progress?`,
                    d41nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    d41ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    d41ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    d41CardHeaderTitleContainer: `D.4.1 RATIONAL USE OF GENETICALLY MODIFIED ORGANISMS (GMO) `,
                    d41aCardHeaderTitleContainer: `D.4.1 RATIONAL USE OF GENETICALLY MODIFIED ORGANISMS (GMO) - Answer Yes - A`,
                    d41bCardHeaderTitleContainer: `D.4.1 RATIONAL USE OF GENETICALLY MODIFIED ORGANISMS (GMO) - Answer Yes - B`,
                    d41cCardHeaderTitleContainer: `D.4.1 RATIONAL USE OF GENETICALLY MODIFIED ORGANISMS (GMO) - Answer Yes - C`,
                    d41dCardHeaderTitleContainer: `D.4.1 RATIONAL USE OF GENETICALLY MODIFIED ORGANISMS (GMO) - Answer Yes - D`,
                    d41noCardHeaderTitleContainer: `D.4.1 RATIONAL USE OF GENETICALLY MODIFIED ORGANISMS (GMO) - Answer No`,
                    d51RequirementTitle: `Does the company adopt any process or system for waste recycling? `,
                    d51PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field) `,
                    d51PlaceHolderTextNo: `Answer the following questions, please`,
                    d51yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    d51yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    d51yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    d51yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    d51yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    d51yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    d51yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    d51yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    d51ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    d51ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    d51ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    d51ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    d51ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    d51ybyyqt1RequirementTitle: `Percentage of hazardous material over total waste - %.`,
                    d51ybyyqt2RequirementTitle: `Percentage of reusable/recycled material over total waste - % . `,
                    d51ybyyqt3RequirementTitle: ` The amount of recyclable materials recovered during the food production process.`,
                    d51ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    d51ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    d51ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    d51ycRequirementTitle: ` Does the company consider the result positive?`,
                    d51ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    d51ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    d51ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    d51ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    d51ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    d51ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    d51ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    d51ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    d51ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    d51ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    d51ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    d51ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    d51ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    d51ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    d51ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    d51naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    d51naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    d51nbRequirementTitle: `Are there any adoption projects in progress?`,
                    d51nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    d51ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    d51ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    d51CardHeaderTitleContainer: `D.5.1 WASTE REDUCTION AND RECYCLING`,
                    d51aCardHeaderTitleContainer: `D.5.1 WASTE REDUCTION AND RECYCLING - Answer Yes - A`,
                    d51bCardHeaderTitleContainer: `D.5.1 WASTE REDUCTION AND RECYCLING - Answer Yes - B`,
                    d51cCardHeaderTitleContainer: `D.5.1 WASTE REDUCTION AND RECYCLING - Answer Yes - C`,
                    d51dCardHeaderTitleContainer: `D.5.1 WASTE REDUCTION AND RECYCLING - Answer Yes - D`,
                    d51noCardHeaderTitleContainer: `D.5.1 WASTE REDUCTION AND RECYCLING - Answer No`,
                    e11RequirementTitle: ` Does the company have direct involvement with the people who work there, with their families or with the community where it operates?`,
                    e11PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field) `,
                    e11PlaceHolderTextNo: `Answer the following questions, please`,
                    e11yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    e11yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    e11yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    e11yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    e11yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    e11yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    e11yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    e11yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    e11ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    e11ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    e11ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    e11ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    e11ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    e11ybyyqt1RequirementTitle: `Amount of the company's investments in social projects that benefit the local community.`,
                    e11ybyyqt2RequirementTitle: `Number of community relationship events held in recent years - Number  `,
                    e11ybyyqt3RequirementTitle: `Percentage of community complaints resolved - %.`,
                    e11ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    e11ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    e11ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    e11ycRequirementTitle: ` Does the company consider the result positive?`,
                    e11ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    e11ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    e11ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    e11ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    e11ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    e11ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    e11ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    e11ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    e11ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    e11ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    e11ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    e11ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    e11ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    e11ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    e11ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    e11naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    e11naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    e11nbRequirementTitle: `Are there any adoption projects in progress?`,
                    e11nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    e11ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    e11ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    e11CardHeaderTitleContainer: `E.1.1 RESPECT TO THE TERRITORY`,
                    e11aCardHeaderTitleContainer: `E.1.1 RESPECT TO THE TERRITORY - Answer Yes - A`,
                    e11bCardHeaderTitleContainer: `E.1.1 RESPECT TO THE TERRITORY - Answer Yes - B`,
                    e11cCardHeaderTitleContainer: `E.1.1 RESPECT TO THE TERRITORY - Answer Yes - C`,
                    e11dCardHeaderTitleContainer: `E.1.1 RESPECT TO THE TERRITORY - Answer Yes - D`,
                    e11noCardHeaderTitleContainer: `E.1.1 RESPECT TO THE TERRITORY - Answer No`,
                    e21RequirementTitle: `Does the company offer equal opportunities for work and development for all people, regardless of: Physical or mental disability, Race, Religion, Age, Sexual orientation, Gender or gender identity? `,
                    e21PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field) `,
                    e21PlaceHolderTextNo: `Answer the following questions, please`,
                    e21yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    e21yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    e21yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    e21yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    e21yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    e21yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    e21yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    e21yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    e21ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    e21ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    e21ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    e21ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    e21ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    e21ybyyqt1RequirementTitle: `The retention rate of employees from different diversity groups relative to the total number of employees.`,
                    e21ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    e21ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    e21ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    e21ycRequirementTitle: ` Does the company consider the result positive?`,
                    e21ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    e21ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    e21ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    e21ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    e21ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    e21ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    e21ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    e21ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    e21ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    e21ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    e21ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    e21ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    e21ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    e21ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    e21ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    e21naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    e21naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    e21nbRequirementTitle: `Are there any adoption projects in progress?`,
                    e21nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    e21ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    e21ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    e21CardHeaderTitleContainer: `E.2.1 OPPORTUNITY TO THE DIVERSITY`,
                    e21aCardHeaderTitleContainer: `E.2.1 OPPORTUNITY TO THE DIVERSITY - Answer Yes - A`,
                    e21bCardHeaderTitleContainer: `E.2.1 OPPORTUNITY TO THE DIVERSITY - Answer Yes - B`,
                    e21cCardHeaderTitleContainer: `E.2.1 OPPORTUNITY TO THE DIVERSITY - Answer Yes - C`,
                    e21dCardHeaderTitleContainer: `E.2.1 OPPORTUNITY TO THE DIVERSITY - Answer Yes - D`,
                    e21noCardHeaderTitleContainer: `E.2.1 OPPORTUNITY TO THE DIVERSITY - Answer No`,
                    f11RequirementTitle: `Does the company implement, register and evaluate a preventive health program for its direct and temporary employees?`,
                    f11PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field) `,
                    f11PlaceHolderTextNo: `Answer the following questions, please`,
                    f11yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    f11yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    f11yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    f11yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    f11yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    f11yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    f11yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    f11yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    f11ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    f11ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    f11ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    f11ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    f11ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    f11ybyyqt1RequirementTitle: `Training and education (amount and quality of health and safety training and education provided to employees) - time unit annual.`,
                    f11ybyyqt2RequirementTitle: `Percentage of employees who participate in health screenings (such as blood pressure and cholesterol tests) - time unit annual.`,
                    f11ybyyqt3RequirementTitle: `Employee turver (rate at which employees leave a company, which can be influenced by the workplace's health and safety conditions) - time unit annual. `,
                    f11ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    f11ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    f11ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    f11ycRequirementTitle: ` Does the company consider the result positive?`,
                    f11ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    f11ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    f11ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    f11ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    f11ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    f11ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    f11ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    f11ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    f11ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    f11ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    f11ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    f11ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    f11ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    f11ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    f11ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    f11naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    f11naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    f11nbRequirementTitle: `Are there any adoption projects in progress?`,
                    f11nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    f11ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    f11ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    f11CardHeaderTitleContainer: `F.1.1 BETTER WORKING CONDITIONS`,
                    f11aCardHeaderTitleContainer: `F.1.1 BETTER WORKING CONDITIONS - Answer Yes - A`,
                    f11bCardHeaderTitleContainer: `F.1.1 BETTER WORKING CONDITIONS - Answer Yes - B`,
                    f11cCardHeaderTitleContainer: `F.1.1 BETTER WORKING CONDITIONS - Answer Yes - C`,
                    f11dCardHeaderTitleContainer: `F.1.1 BETTER WORKING CONDITIONS - Answer Yes - D`,
                    f11noCardHeaderTitleContainer: `F.1.1 BETTER WORKING CONDITIONS - Answer No`,
                    f11noCardHeaderTitleContainer: `F.1.1 BETTER WORKING CONDITIONS - Answer No`,
                    f12RequirementTitle: `Does the company implement, register and evaluate an occupational safety program for its direct and temporary employees?`,
                    f12PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field) `,
                    f12PlaceHolderTextNo: `Answer the following questions, please`,
                    f12yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    f12yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    f12yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    f12yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    f12yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    f12yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    f12yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    f12yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    f12ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    f12ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    f12ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    f12ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    f12ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    f12ybyyqt1RequirementTitle: `Injury rates (number of work-related injuries per 100 employees) - time unit annual.`,
                    f12ybyyqt2RequirementTitle: `Absenteeism (number of days that employees are absent due to injury) - time unit annual.`,
                    f12ybyyqt3RequirementTitle: `Near-miss incidents (number of incidents that could have resulted in injury but didn't). `,
                    f12ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    f12ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    f12ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    f12ycRequirementTitle: ` Does the company consider the result positive?`,
                    f12ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    f12ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    f12ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    f12ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    f12ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    f12ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    f12ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    f12ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    f12ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    f12ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    f12ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    f12ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    f12ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    f12ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    f12ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    f12naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    f12naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    f12nbRequirementTitle: `Are there any adoption projects in progress?`,
                    f12nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    f12ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    f12ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    f12CardHeaderTitleContainer: `F.1.2 BETTER WORKING CONDITIONS`,
                    f12aCardHeaderTitleContainer: `F.1.2 BETTER WORKING CONDITIONS - Answer Yes - A`,
                    f12bCardHeaderTitleContainer: `F.1.2 BETTER WORKING CONDITIONS - Answer Yes - B`,
                    f12cCardHeaderTitleContainer: `F.1.2 BETTER WORKING CONDITIONS - Answer Yes - C`,
                    f12dCardHeaderTitleContainer: `F.1.2 BETTER WORKING CONDITIONS - Answer Yes - D`,
                    f12noCardHeaderTitleContainer: `F.1.2 BETTER WORKING CONDITIONS - Answer No`,
                    g11RequirementTitle: `Do you consider that your company’s labor hours are below what the Fair Labor Standards Act (FLAS) requires?`,
                    g11PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field) `,
                    g11PlaceHolderTextNo: `Answer the following questions, please`,
                    g11yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    g11yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    g11yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    g11yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    g11yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    g11yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    g11yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    g11yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    g11ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    g11ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    g11ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    g11ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    g11ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    g11ybyyqt1RequirementTitle: `Total work-related hours, including training, travel, and time off - Time unit annual.`,
                    g11ybyyqt2RequirementTitle: `Average overtime per employee - Time unit annual.`,
                    g11ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    g11ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    g11ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    g11ycRequirementTitle: ` Does the company consider the result positive?`,
                    g11ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    g11ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    g11ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    g11ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    g11ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    g11ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    g11ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    g11ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    g11ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    g11ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    g11ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    g11ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    g11ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    g11ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    g11ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    g11naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    g11naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    g11nbRequirementTitle: `Are there any adoption projects in progress?`,
                    g11nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    g11ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    g11ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    g11CardHeaderTitleContainer: `G.1.1 FAIR LABOR CONTRACTS`,
                    g11aCardHeaderTitleContainer: `G.1.1 FAIR LABOR CONTRACTS - Answer Yes - A`,
                    g11bCardHeaderTitleContainer: `G.1.1 FAIR LABOR CONTRACTS - Answer Yes - B`,
                    g11cCardHeaderTitleContainer: `G.1.1 FAIR LABOR CONTRACTS - Answer Yes - C`,
                    g11dCardHeaderTitleContainer: `G.1.1 FAIR LABOR CONTRACTS - Answer Yes - D`,
                    g11noCardHeaderTitleContainer: `G.1.1 FAIR LABOR CONTRACTS - Answer No`,
                    g12RequirementTitle: `Do you consider that wages are above what the Fair Labor Standards Act (FLAS) requires?`,
                    g12PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field) `,
                    g12PlaceHolderTextNo: `Answer the following questions, please`,
                    g12yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    g12yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    g12yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    g12yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    g12yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    g12yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    g12yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    g12yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    g12ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    g12ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    g12ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    g12ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    g12ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    g12ybyyqt1RequirementTitle: ` Percentage of your company's average salary compared to companies in the same industry and region - % .`,
                    g12ybyyqt2RequirementTitle: `Lowest wage value among all employees.`,
                    g12ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    g12ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    g12ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    g12ycRequirementTitle: ` Does the company consider the result positive?`,
                    g12ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    g12ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    g12ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    g12ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    g12ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    g12ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    g12ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    g12ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    g12ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    g12ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    g12ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    g12ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    g12ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    g12ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    g12ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    g12naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    g12naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    g12nbRequirementTitle: `Are there any adoption projects in progress?`,
                    g12nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    g12ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    g12ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    g12CardHeaderTitleContainer: `G.1.2 FAIR LABOR CONTRACTS`,
                    g12aCardHeaderTitleContainer: `G.1.2 FAIR LABOR CONTRACTS - Answer Yes - A`,
                    g12bCardHeaderTitleContainer: `G.1.2 FAIR LABOR CONTRACTS - Answer Yes - B`,
                    g12cCardHeaderTitleContainer: `G.1.2 FAIR LABOR CONTRACTS - Answer Yes - C`,
                    g12dCardHeaderTitleContainer: `G.1.2 FAIR LABOR CONTRACTS - Answer Yes - D`,
                    g12noCardHeaderTitleContainer: `G.1.2 FAIR LABOR CONTRACTS - Answer No`,
                    g13RequirementTitle: `Does your company have clear rules for t allowing child labor and forced labor?`,
                    g13PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field) `,
                    g13PlaceHolderTextNo: `Answer the following questions, please`,
                    g13yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    g13yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    g13yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    g13yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    g13yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    g13yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    g13yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    g13yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    g13ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    g13ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    g13ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    g13ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    g13ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    g13ybyyqt1RequirementTitle: ` Minimum age to become a worker in the company.`,
                    g13ybyyqt2RequirementTitle: `Maximum number of working hours per week.`,
                    g13ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    g13ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    g13ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    g13ycRequirementTitle: ` Does the company consider the result positive?`,
                    g13ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    g13ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    g13ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    g13ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    g13ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    g13ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    g13ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    g13ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    g13ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    g13ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    g13ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    g13ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    g13ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    g13ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    g13ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    g13naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    g13naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    g13nbRequirementTitle: `Are there any adoption projects in progress?`,
                    g13nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    g13ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    g13ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    g13CardHeaderTitleContainer: `G.1.3 FAIR LABOR CONTRACTS`,
                    g13aCardHeaderTitleContainer: `G.1.3 FAIR LABOR CONTRACTS - Answer Yes - A`,
                    g13bCardHeaderTitleContainer: `G.1.3 FAIR LABOR CONTRACTS - Answer Yes - B`,
                    g13cCardHeaderTitleContainer: `G.1.3 FAIR LABOR CONTRACTS - Answer Yes - C`,
                    g13dCardHeaderTitleContainer: `G.1.3 FAIR LABOR CONTRACTS - Answer Yes - D`,
                    g13noCardHeaderTitleContainer: `G.1.3 FAIR LABOR CONTRACTS - Answer No`,
                    h11RequirementTitle: `Do suppliers receive special pricing for Fairtrade products? `,
                    h11PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field) `,
                    h11PlaceHolderTextNo: `Answer the following questions, please`,
                    h11yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    h11yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    h11yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    h11yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    h11yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    h11yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    h11yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    h11yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    h11ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    h11ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    h11ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    h11ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    h11ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    h11ybyyqt1RequirementTitle: ` Average difference between the amount paid for a fairtrade product and a regular product - (%) `,
                    h11ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    h11ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    h11ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    h11ycRequirementTitle: ` Does the company consider the result positive?`,
                    h11ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    h11ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    h11ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    h11ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    h11ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    h11ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    h11ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    h11ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    h11ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    h11ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    h11ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    h11ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    h11ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    h11ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    h11ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    h11naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    h11naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    h11nbRequirementTitle: `Are there any adoption projects in progress?`,
                    h11nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    h11ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    h11ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    h11CardHeaderTitleContainer: `H.1.1 FAIR COMMERCE CONDITIONS`,
                    h11aCardHeaderTitleContainer: `H.1.1 FAIR COMMERCE CONDITIONS - Answer Yes - A`,
                    h11bCardHeaderTitleContainer: `H.1.1 FAIR COMMERCE CONDITIONS - Answer Yes - B`,
                    h11cCardHeaderTitleContainer: `H.1.1 FAIR COMMERCE CONDITIONS - Answer Yes - C`,
                    h11dCardHeaderTitleContainer: `H.1.1 FAIR COMMERCE CONDITIONS - Answer Yes - D`,
                    h11noCardHeaderTitleContainer: `H.1.1 FAIR COMMERCE CONDITIONS - Answer No`,
                    h12RequirementTitle: ` Is there a system to record and ensure traceability of all transactions, products and volumes, especially those of Fairtrade? `,
                    h12PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field) `,
                    h12PlaceHolderTextNo: `Answer the following questions, please`,
                    h12yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    h12yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    h12yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    h12yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    h12yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    h12yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    h12yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    h12yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    h12ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    h12ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    h12ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    h12ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    h12ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    h12ybyyqt1RequirementTitle: `Percentage of products tracked over all products - (%) `,
                    h12ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    h12ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    h12ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    h12ycRequirementTitle: ` Does the company consider the result positive?`,
                    h12ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    h12ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    h12ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    h12ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    h12ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    h12ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    h12ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    h12ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    h12ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    h12ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    h12ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    h12ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    h12ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    h12ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    h12ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    h12naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    h12naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    h12nbRequirementTitle: `Are there any adoption projects in progress?`,
                    h12nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    h12ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    h12ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    h12CardHeaderTitleContainer: `H.1.2 FAIR COMMERCE CONDITIONS`,
                    h12aCardHeaderTitleContainer: `H.1.2 FAIR COMMERCE CONDITIONS - Answer Yes - A`,
                    h12bCardHeaderTitleContainer: `H.1.2 FAIR COMMERCE CONDITIONS - Answer Yes - B`,
                    h12cCardHeaderTitleContainer: `H.1.2 FAIR COMMERCE CONDITIONS - Answer Yes - C`,
                    h12dCardHeaderTitleContainer: `H.1.2 FAIR COMMERCE CONDITIONS - Answer Yes - D`,
                    h12noCardHeaderTitleContainer: `H.1.2 FAIR COMMERCE CONDITIONS - Answer No`,
                    i11RequirementTitle: `Does your company have all required regulatory permissions to produce, process or market food ( example: USDA/FDA)`,
                    i11PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field) `,
                    i11PlaceHolderTextNo: `Answer the following questions, please`,
                    i11yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    i11yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    i11yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    i11yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    i11yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    i11yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    i11yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    i11yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    i11ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    i11ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    i11ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    i11ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    i11ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    i11ybyyqt1RequirementTitle: ` Total amount of monetary losses as a result of legal proceedings associated with regulatory permissions - Amount `,
                    i11ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    i11ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    i11ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    i11ycRequirementTitle: ` Does the company consider the result positive?`,
                    i11ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    i11ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    i11ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    i11ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    i11ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    i11ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    i11ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    i11ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    i11ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    i11ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    i11ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    i11ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    i11ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    i11ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    i11ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    i11naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    i11naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    i11nbRequirementTitle: `Are there any adoption projects in progress?`,
                    i11nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    i11ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    i11ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    i11CardHeaderTitleContainer: `I.1.1 REGULATORY PERMISSIONS `,
                    i11aCardHeaderTitleContainer: `I.1.1 REGULATORY PERMISSIONS  - Answer Yes - A`,
                    i11bCardHeaderTitleContainer: `I.1.1 REGULATORY PERMISSIONS  - Answer Yes - B`,
                    i11cCardHeaderTitleContainer: `I.1.1 REGULATORY PERMISSIONS  - Answer Yes - C`,
                    i11dCardHeaderTitleContainer: `I.1.1 REGULATORY PERMISSIONS  - Answer Yes - D`,
                    i11noCardHeaderTitleContainer: `I.1.1 REGULATORY PERMISSIONS  - Answer No`,
                    i21RequirementTitle: `Does your company have all required environmental permissions to produce, process or market food (example: USDA/FDA)`,
                    i21PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field) `,
                    i21PlaceHolderTextNo: `Answer the following questions, please`,
                    i21yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    i21yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    i21yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    i21yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    i21yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    i21yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    i21yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    i21yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    i21ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    i21ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    i21ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    i21ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    i21ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    i21ybyyqt1RequirementTitle: ` Total amount of monetary losses as a result of legal proceedings associated with environmental permissions - Amount  `,
                    i21ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    i21ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    i21ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    i21ycRequirementTitle: ` Does the company consider the result positive?`,
                    i21ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    i21ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    i21ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    i21ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    i21ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    i21ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    i21ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    i21ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    i21ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    i21ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    i21ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    i21ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    i21ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    i21ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    i21ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    i21naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    i21naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    i21nbRequirementTitle: `Are there any adoption projects in progress?`,
                    i21nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    i21ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    i21ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    i21CardHeaderTitleContainer: `I.2.1 REGULATORY PERMISSIONS `,
                    i21aCardHeaderTitleContainer: `I.2.1 REGULATORY PERMISSIONS  - Answer Yes - A`,
                    i21bCardHeaderTitleContainer: `I.2.1 REGULATORY PERMISSIONS  - Answer Yes - B`,
                    i21cCardHeaderTitleContainer: `I.2.1 REGULATORY PERMISSIONS  - Answer Yes - C`,
                    i21dCardHeaderTitleContainer: `I.2.1 REGULATORY PERMISSIONS  - Answer Yes - D`,
                    i21noCardHeaderTitleContainer: `I.2.1 REGULATORY PERMISSIONS  - Answer No`,
                    i31RequirementTitle: ` Does your company use labeling for communication in addition to regulatory information, such as: Declaring genetically modified organisms (GMOs) as ingredients or efforts to promote responsible consumption of products? `,
                    i31PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field) `,
                    i31PlaceHolderTextNo: `Answer the following questions, please`,
                    i31yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    i31yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    i31yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    i31yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    i31yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    i31yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    i31yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    i31yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    i31ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    i31ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    i31ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    i31ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    i31ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    i31ybyyqt1RequirementTitle: `Percentage of products labeled "proactive"(such as: Declaring GMOs as ingredients or efforts to promote responsible consumption) over total products - % `,
                    i31ybyyqt2RequirementTitle: `Total amount of monetary losses as a result of legal procedures associated with labeling nocompliance - Amount `,
                    i31ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    i31ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    i31ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    i31ycRequirementTitle: ` Does the company consider the result positive?`,
                    i31ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    i31ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    i31ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    i31ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    i31ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    i31ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    i31ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    i31ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    i31ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    i31ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    i31ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    i31ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    i31ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    i31ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    i31ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    i31naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    i31naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    i31nbRequirementTitle: `Are there any adoption projects in progress?`,
                    i31nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    i31ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    i31ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    i31CardHeaderTitleContainer: `I.3.1 REGULATORY PERMISSIONS `,
                    i31aCardHeaderTitleContainer: `I.3.1 REGULATORY PERMISSIONS  - Answer Yes - A`,
                    i31bCardHeaderTitleContainer: `I.3.1 REGULATORY PERMISSIONS  - Answer Yes - B`,
                    i31cCardHeaderTitleContainer: `I.3.1 REGULATORY PERMISSIONS  - Answer Yes - C`,
                    i31dCardHeaderTitleContainer: `I.3.1 REGULATORY PERMISSIONS  - Answer Yes - D`,
                    i31noCardHeaderTitleContainer: `I.3.1 REGULATORY PERMISSIONS  - Answer No`,
                    i41RequirementTitle: `Does your company voluntarily disclose its tax data to all stakeholders (interested parties)`,
                    i41PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field) `,
                    i41PlaceHolderTextNo: `Answer the following questions, please`,
                    i41yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    i41yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    i41yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    i41yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    i41yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    i41yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    i41yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    i41yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    i41ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    i41ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    i41ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    i41ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    i41ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    i41ybyyqt1RequirementTitle: `Satisfaction Level: The measure of stakeholder satisfaction, usually measured through feedback surveys. `,
                    i41ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    i41ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    i41ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    i41ycRequirementTitle: ` Does the company consider the result positive?`,
                    i41ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    i41ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    i41ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    i41ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    i41ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    i41ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    i41ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    i41ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    i41ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    i41ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    i41ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    i41ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    i41ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    i41ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    i41ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    i41naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    i41naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    i41nbRequirementTitle: `Are there any adoption projects in progress?`,
                    i41nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    i41ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    i41ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    i41CardHeaderTitleContainer: `I.4.1 TAX CONFORMITY`,
                    i41aCardHeaderTitleContainer: `I.4.1 TAX CONFORMITYS - Answer Yes - A`,
                    i41bCardHeaderTitleContainer: `I.4.1 TAX CONFORMITY - Answer Yes - B`,
                    i41cCardHeaderTitleContainer: `I.4.1 TAX CONFORMITYS - Answer Yes - C`,
                    i41dCardHeaderTitleContainer: `I.4.1 TAX CONFORMITYS - Answer Yes - D`,
                    i41noCardHeaderTitleContainer: `I.4.1 TAX CONFORMITYS - Answer No`,
                    i51RequirementTitle: `Does your company voluntarily identify its key stakeholders, disclose its ESG strategies and provide auditable data and use frameworks to report its results?`,
                    i51PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions.Describe the practice(text form field) `,
                    i51PlaceHolderTextNo: `Answer the following questions, please`,
                    i51yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans ? `,
                    i51yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    i51yayRequirementTitle: ` Was this practice adopted more than one fiscal year ? `,
                    i51yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    i51yayyRequirementTitle: `Is this ESG theme included in the business strategy ? `,
                    i51yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    i51yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy ? `,
                    i51yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    i51ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice ? `,
                    i51ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    i51ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental(and also social) impact of this practice ? List financial, process and reputation indicators, for example`,
                    i51ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    i51ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    i51ybyyqt1RequirementTitle: `Number of meetings held with stakeholders, such as customers, suppliers, employees, among others.`,
                    i51ybyyqt2RequirementTitle: `Percentage of stakeholders who responded to surveys or questionnaires.`,
                    i51ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    i51ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results ? `,
                    i51ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    i51ycRequirementTitle: ` Does the company consider the result positive ? `,
                    i51ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    i51ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice ? `,
                    i51ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    i51ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain(suppliers, service providers and customers) ? `,
                    i51ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    i51ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic ? `,
                    i51ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    i51ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria ? `,
                    i51ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    i51ydyRequirementTitle: `Does the company use the certification criteria for all its operations ? `,
                    i51ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    i51ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers ? `,
                    i51ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    i51ydyyql1RequirementTitle: `Can you send us a document detailing this criteria ? `,
                    i51ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    i51naRequirementTitle: ` Does your company intend to adopt this practice ? `,
                    i51naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    i51nbRequirementTitle: `Are there any adoption projects in progress ? `,
                    i51nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    i51ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year ? `,
                    i51ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    i51CardHeaderTitleContainer: `I.5.1 REPORT PRACTICES`,
                    i51aCardHeaderTitleContainer: `I.5.1 REPORT PRACTICES - Answer Yes - A`,
                    i51bCardHeaderTitleContainer: `I.5.1 REPORT PRACTICES - Answer Yes - B`,
                    i51cCardHeaderTitleContainer: `I.5.1 REPORT PRACTICES - Answer Yes - C`,
                    i51dCardHeaderTitleContainer: `I.5.1 REPORT PRACTICES - Answer Yes - D`,
                    i51noCardHeaderTitleContainer: `I.5.1 REPORT PRACTICES - Answer No`,
                    i61RequirementTitle: `Does your company have clear rules for t allowing situations of internal or external corruption? `,
                    i61PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field) `,
                    i61PlaceHolderTextNo: `Answer the following questions, please`,
                    i61yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    i61yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    i61yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    i61yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    i61yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    i61yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    i61yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    i61yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    i61ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    i61ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    i61ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    i61ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    i61ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    i61ybyyqt1RequirementTitle: `Number of complaints received relating to corrupt activities such as bribery or money laundering.`,
                    i61ybyyqt2RequirementTitle: `Average time taken to resolve a compliance violation, from the time it was detected to the time it was resolved.`,
                    i61ybyyqt3RequirementTitle: `Number of employees who participated in training on anti-corruption policies and practices.`,
                    i61ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    i61ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    i61ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    i61ycRequirementTitle: ` Does the company consider the result positive?`,
                    i61ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    i61ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    i61ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    i61ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    i61ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    i61ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    i61ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    i61ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    i61ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    i61ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    i61ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    i61ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    i61ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    i61ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    i61ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    i61naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    i61naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    i61nbRequirementTitle: `Are there any adoption projects in progress?`,
                    i61nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    i61ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    i61ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    i61CardHeaderTitleContainer: `I.6.1 ANTI-CORRUPTION PRACTICES`,
                    i61aCardHeaderTitleContainer: `I.6.1 ANTI-CORRUPTION PRACTICES - Answer Yes - A`,
                    i61bCardHeaderTitleContainer: `I.6.1 ANTI-CORRUPTION PRACTICES - Answer Yes - B`,
                    i61cCardHeaderTitleContainer: `I.6.1 ANTI-CORRUPTION PRACTICES - Answer Yes - C`,
                    i61dCardHeaderTitleContainer: `I.6.1 ANTI-CORRUPTION PRACTICES - Answer Yes - D`,
                    i61noCardHeaderTitleContainer: `I.6.1 ANTI-CORRUPTION PRACTICES - Answer No`,
                    j11RequirementTitle: `Does your company demand ESG practices from its Supply Chain?  `,
                    j11PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field) `,
                    j11PlaceHolderTextNo: `Answer the following questions, please`,
                    j11yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    j11yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    j11yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    j11yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    j11yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    j11yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    j11yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    j11yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    j11ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    j11ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    j11ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    j11ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    j11ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    j11ybyyqt1RequirementTitle: `Number of suppliers evaluated for ESG practices.`,
                    j11ybyyqt2RequirementTitle: `Percentage of suppliers that meet sustainability standards established by the company.`,
                    j11ybyyqt3RequirementTitle: `Number of suppliers that received training in ESG practices.`,
                    j11ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    j11ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    j11ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    j11ycRequirementTitle: ` Does the company consider the result positive?`,
                    j11ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    j11ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    j11ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    j11ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    j11ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    j11ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    j11ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    j11ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    j11ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    j11ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    j11ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    j11ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    j11ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    j11ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    j11ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    j11naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    j11naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    j11nbRequirementTitle: `Are there any adoption projects in progress?`,
                    j11nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    j11ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    j11ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    j11CardHeaderTitleContainer: `J.1.1 SUPPLY CHAIN ENGAGEMENT`,
                    j11aCardHeaderTitleContainer: `J.1.1 SUPPLY CHAIN ENGAGEMENT - Answer Yes - A`,
                    j11bCardHeaderTitleContainer: `J.1.1 SUPPLY CHAIN ENGAGEMENT - Answer Yes - B`,
                    j11cCardHeaderTitleContainer: `J.1.1 SUPPLY CHAIN ENGAGEMENT - Answer Yes - C`,
                    j11dCardHeaderTitleContainer: `J.1.1 SUPPLY CHAIN ENGAGEMENT - Answer Yes - D`,
                    j11noCardHeaderTitleContainer: `J.1.1 SUPPLY CHAIN ENGAGEMENT - Answer No`,
                    j21RequirementTitle: `Does your company partner with other companies in the sector, region or industry for common benefits or actions, and does this include ESG practices? `,
                    j21PlaceHolderTextYes: `Your answer was "YES", so please briefly describe the practice adopted and answer the following questions. Describe the practice (text form field) `,
                    j21PlaceHolderTextNo: `Answer the following questions, please`,
                    j21yaRequirementTitle: `Was the adoption of this practice based on structured and documented plans?`,
                    j21yaPlaceHolderTextNo: ` Your answer was "NO", so comment about the reasons for not planning to adopt this practice.`,
                    j21yayRequirementTitle: ` Was this practice adopted more than one fiscal year?`,
                    j21yayPlaceHolderTextNo: ` Your answer was "NO", so comment about what makes it difficult to develop projects to adopt this ESG practice in the short term.`,
                    j21yayyRequirementTitle: `Is this ESG theme included in the business strategy?`,
                    j21yayyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not including this ESG theme in the business strategy.`,
                    j21yayyqlRequirementTitle: ` Can you send us a document that details the relevance of this ESG topic in the business strategy?`,
                    j21yayyqlPlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    j21ybRequirementTitle: ` Does the company periodically evaluate the results of adopting this ESG practice?`,
                    j21ybPlaceHolderTextNo: `Your answer was "NO", so please comment about the reasons for not periodically evaluating the results of this ESG practice.`,
                    j21ybyRequirementTitle: `What types of ESG performance indicators, or KPIs, are used to help the company understand the environmental (and also social) impact of this practice? List financial, process and reputation indicators, for example`,
                    j21ybyPlaceHolderTextYes: ` You have indicated KPI, can you provide us with any of the following data`,
                    j21ybyPlaceHolderTextNo: `You did not list indicators, could you please justify the nonpresentation.`,
                    j21ybyyqt1RequirementTitle: `Number of partnerships established with other companies for the implementation of sustainable practices.`,
                    j21ybyyqt2RequirementTitle: `Total amount invested, together with other companies, in joint initiatives for sustainability.`,
                    j21ybyyqtnRequirementTitle: `Another type of KPI? Please describe it briefly and insert the last measured results.`,
                    j21ybyyql1RequirementTitle: `Can you send us a document detailing the KPIs used and the last measured results?`,
                    j21ybyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible`,
                    j21ycRequirementTitle: ` Does the company consider the result positive?`,
                    j21ycPlaceHolderTextNo: ` Your answer was "NO", so comment on the reasons for not considering the results of adopting this ESG practice positive.`,
                    j21ycyRequirementTitle: `Does the company intend to expand the adoption of this ESG practice?`,
                    j21ycyPlaceHolderTextNo: `Your answer was "NO", so comment what prevents you from expanding this practice.`,
                    j21ycyyRequirementTitle: ` Is it part of the business strategy to engage stakeholders in the practices of this ESG topic, especially in your value chain (suppliers, service providers and customers)?`,
                    j21ycyyPlaceHolderTextNo: `Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    j21ycyyql1RequirementTitle: ` Can you send us a document detailing how stakeholders are engaged in this ESG topic?`,
                    j21ycyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    j21ydRequirementTitle: `The company has some certification that includes this ESG theme in its criteria?`,
                    j21ydPlaceHolderTextNo: `Your answer was "NO", please comment whether or not you consider certification relevant.`,
                    j21ydyRequirementTitle: `Does the company use the certification criteria for all its operations?`,
                    j21ydyPlaceHolderTextNo: ` Your answer was "NO", so comment what prevents you from expanding the criteria`,
                    j21ydyyRequirementTitle: ` Are the criteria adopted for this ESG topic also charged to your suppliers and service providers?`,
                    j21ydyyPlaceHolderTextNo: ` Your answer was "NO", please explain the reasons for not engaging your value chain in this ESG topic.`,
                    j21ydyyql1RequirementTitle: `Can you send us a document detailing this criteria?`,
                    j21ydyyql1PlaceHolderTextNo: `Your answer was "NO", please justify, if possible.`,
                    j21naRequirementTitle: ` Does your company intend to adopt this practice?`,
                    j21naPlaceHolderTextNo: `Your answer was "NO", so comment about the reasons for not intending to adopt this practice.`,
                    j21nbRequirementTitle: `Are there any adoption projects in progress?`,
                    j21nbPlaceHolderTextNo: ` Your answer was "NO", so comment on what makes it difficult to develop projects for adoption.`,
                    j21ncRequirementTitle: `Will the implementation of this practice be completed this fiscal year?`,
                    j21ncPlaceHolderTextNo: `Your answer was "NO", so comment on how long it would take for the practice to be adopted.`,
                    j21CardHeaderTitleContainer: `J.2.1 ASSOCIATION TO THE SUSTAINABILITY`,
                    j21aCardHeaderTitleContainer: `J.2.1 ASSOCIATION TO THE SUSTAINABILITY - Answer Yes - A`,
                    j21bCardHeaderTitleContainer: `J.2.1 ASSOCIATION TO THE SUSTAINABILITY - Answer Yes - B`,
                    j21cCardHeaderTitleContainer: `J.2.1 ASSOCIATION TO THE SUSTAINABILITY - Answer Yes - C`,
                    j21dCardHeaderTitleContainer: `J.2.1 ASSOCIATION TO THE SUSTAINABILITY - Answer Yes - D`,
                    j21noCardHeaderTitleContainer: `J.2.1 ASSOCIATION TO THE SUSTAINABILITY - Answer No`,
                    redMeat: `Red Meat`,
                    poultry: `Poultry`,
                    fish: `Fish`,
                    fruit: `Fruit`,
                    vegetables: `Vegetables`,
                    nuts: `Nuts`,
                    dairyegg: `Dairy & Egg`,
                    mealsSandwich: `Ready meals and sandwiches`,
                    beverages: `Beverages`,
                    alcoholicDrinks: `Alcoholic Drinks`,
                    Coffee: `Coffee`,
                    bakery: `Bakery`,
                    pantryProduct: `Pantry Product (Canned)`,
                    ingredients: `Ingredients`,
                    dryProducts: `Dry Products`,
                    confectionerySnacks: `Confectionery & Snacks`,
                    sauce: `Sauce`,
                    oilFats: `Oil and fats`,
                    plantBased: `Plant Based`,
                    foodProdIndustry: `Food Production Industry`,
                    farmFieldPacking: `Farm (Field and Packing)`,
                    farmRuralProducer: `Farm (Rural Producer)`,
                    person: `Responsável`,
                    position: `Position`,
                    typeOfCompany: `Type of Company`,
                    attachEvidence: `If Yes, please attach the Evidence`,
                    optionNo: `No`,
                    optionYes: `Yes`,
                    buttonOpenFile: `Open File`,
                    buttonChangeFile: `Change File`,
                    priorNoticetoImport: `Does the company filed Prior Notice to Import?`,
                    titleAdditionalInformation: `Additional Information`,
                    auditwithin12months: `Did the company have any type of audit within the last 12 months?`,
                    federalAudit: ` Federal Audit:`,
                    codeFederalRegualations: `In US, For Human Food PC Rule, Part 117/ For Produce Safety Rule,Part 112 of USDA - Code of Federal Regulations(CFR)`,
                    internalAudit: `1st Party - Internal Audit`,
                    noteFirstPartyAudit: `Note: A first-party audit occurs when an audit is performed within your organization by your own auditing resource. This is often called an internal audit.`,
                    companyHired: `Company Hired`,
                    standard: `Standard`,
                    secondPartyAudit: `2nd Party - Supplier Control`,
                    noteSecondPartyAudit: `Note: A second-party audit is performed by a supplier, customer, or contractor, often against their proprietary requirements. (Costco, Walmart, Yum Brands, Etc..)`,
                    thirdPartyAudit: `3rd Party - Certification Audit / Federal Audit`,
                    notethirdPartyAudit: `Note1: A third-party audit is performed by an independent body (i.e., a registrar such as assessor's) against a recognized standard (SQF, BRCGS, PrimusGFS, ISO 22000, etc..)`,
                    certificationBody: `Certification Body`,
                    productDescr: `Product Description and Production`,
                    listOfAllProducts: `List of all product(s) produced`,
                    exampleListOfAllProducts: `Example: beef slaughter, beef cuts, wet fish, fresh fruits, semi- processed fruits(describe which ones), frozen bread, soup, etc.`,
                    listOfProdValidatedMFS: `List of the product(s) to be validated by MyTS:`,
                    establishmentData: `Establishment Data`,
                    numberOfEmployees: `Number of employees(including administrative staff)`,
                    female: `Female:`,
                    male: `Male:`,
                    total: `Total:`,
                    numberEmployeeQualityDept: `Specify the number of employees on quality/ food safety department`,
                    associationOrCooperative: `Is the company part of any association or cooperative?`,
                    supportMinorityGroup: `Does the company belong to or support any minority groups?`,
                    exampleMinorityGroup: `(example: racial, ethnic, gender, sexuality, religious, disabilities)`,
                    usFsvpImporter: `Is the company a qualified individual for US FSVP Importer?`,
                    usdaImportRequirement: `Do you meet USDA Import Requirements?`,
                    importerRecordUs: `Do you have an importer of record in the United States`,
                    recallRegulatoryInspection: `Have you had any recalls/ warning letters from recent regulatory inspections?`,
                    usAlertList: `Are you on any US import alert list?`,
                    supplierQuestionnaireEsg: `Supplier Questionnaire - ESG`,
                    submit: `Submit`,
                    legalCompanyName: `Legal Company Name`,
                    einNumber: `EIN Number:`,
                    requirement: 'REQUIREMENT',
                }
                break
        }
    }

    const RegularPanels = [
        { ref: 'my-wish', title: findlanguage().myWishList },
        { ref: 'message', title: findlanguage().messages },
        { ref: 'views', title: findlanguage().views },
    ]

    const AdminHomePanels = [
        { ref: 'recommended', title: findlanguage().recommendedForYou },
        { ref: 'svp', title: findlanguage().supplierValidaonProcess },
        { ref: 'sgs', title: findlanguage().suppplierGeneralStatus },
        { ref: 'esg', title: 'ESG' },
        { ref: 'supplier-per-category', title: findlanguage().supplierPerCategory },
        { ref: 'document-control', title: findlanguage().documentControl },
        { ref: 'expired-document', title: findlanguage().expiredDocument },
        { ref: 'supplier-per-region', title: findlanguage().supplierPerRegion },
        { ref: 'supplier-map', title: findlanguage().supplierMap },
        { ref: 'my-products', title: findlanguage().competitors },
        { ref: 'my-clients', title: findlanguage().myClients },
        { ref: 'clients-map', title: findlanguage().clientsMap },
    ]

    const BuyerAdminHomePanels = [
        { ref: 'recommended', title: findlanguage().recommendedForYou },
        { ref: 'svp', title: findlanguage().supplierValidaonProcess },
        { ref: 'sgs', title: findlanguage().suppplierGeneralStatus },
        { ref: 'esg', title: 'ESG' },
        { ref: 'supplier-per-category', title: findlanguage().supplierPerCategory },
        { ref: 'document-control', title: findlanguage().documentControl },
        { ref: 'expired-document', title: findlanguage().expiredDocument },
        { ref: 'supplier-per-region', title: findlanguage().supplierPerRegion },
        { ref: 'supplier-map', title: findlanguage().supplierMap },
    ]

    const SupplierAdminHomePanels = [
        { ref: 'document-control', title: findlanguage().documentControl },
        { ref: 'svp', title: findlanguage().buyerValidationProcessRequirements },
        { ref: 'sgs', title: findlanguage().supplierGeneralStatus },
        { ref: 'esg', title: 'ESG' },
        { ref: 'my-products', title: findlanguage().competitors },
        { ref: 'my-clients', title: findlanguage().myClients },
        { ref: 'clients-map', title: findlanguage().clientsMap },
    ]

    const BuyerHomePanels = [
        { ref: 'recommended', title: findlanguage().recommendedForYou },
        { ref: 'my-wish', title: findlanguage().myWishList },
        { ref: 'message', title: findlanguage().messages },
        { ref: 'views', title: findlanguage().views },
        { ref: 'svp', title: findlanguage().supplierValidaonProcess },
        { ref: 'sgs', title: findlanguage().suppplierGeneralStatus },
        { ref: 'esg', title: 'ESG' },
        { ref: 'supplier-per-category', title: findlanguage().supplierPerCategory },
        { ref: 'document-control', title: findlanguage().documentControl },
        { ref: 'expired-document', title: findlanguage().expiredDocument },
        { ref: 'supplier-per-region', title: findlanguage().supplierPerRegion },
        { ref: 'supplier-map', title: findlanguage().supplierMap },
    ]

    const SupplierHomePanels = [
        { ref: 'document-control', title: findlanguage().documentControl },
        { ref: 'svp', title: findlanguage().buyerValidationProcessRequirements },
        { ref: 'sgs', title: findlanguage().supplierGeneralStatus },
        { ref: 'esg', title: 'ESG' },
        { ref: 'message-supplier', title: findlanguage().message },
        { ref: 'views', title: findlanguage().views },
        { ref: 'my-products', title: findlanguage().competitors },
        { ref: 'my-clients', title: findlanguage().myClients },
        { ref: 'clients-map', title: findlanguage().clientsMap },
    ]

    const ServiceProviderHomePanels = [
        { ref: 'recommended', title: findlanguage().recommendedForYou },
        { ref: 'my-wish', title: findlanguage().myWishList },
        { ref: 'message', title: findlanguage().message },
        { ref: 'views', title: findlanguage().views },
        { ref: 'svp', title: findlanguage().buyerValidationProcessRequirements },
        { ref: 'esg', title: 'ESG' },
        { ref: 'clients-per-business', title: findlanguage().clientsPerBusiness },
        { ref: 'document-control', title: findlanguage().documentControl },
        { ref: 'expired-document', title: findlanguage().expiredDocument },
        { ref: 'clients-per-region', title: findlanguage().clientsPerRegion },
        { ref: 'clients-map', title: findlanguage().clientsMap },
    ]

    const [userPanels, setUserPanels] = useState([
        { type: 'basic', title: 'Admin', panels: RegularPanels },
        {
            type: 'supplierAdmin',
            title: findlanguage().supplierTitle,
            panels: SupplierAdminHomePanels,
        },
        { type: 'buyerAdmin', title: findlanguage().buyerTitle, panels: BuyerAdminHomePanels },
        { type: 'supplier', title: findlanguage().supplierTitle, panels: SupplierHomePanels },
        { type: 'buyer', title: findlanguage().buyerTitle, panels: BuyerHomePanels },
        {
            type: 'service-provider',
            title: findlanguage().serviceProviderTitle,
            panels: ServiceProviderHomePanels,
        },
    ])

    const AvailablePanels = [
        { type: 'admin', title: findlanguage().supplierTitle, panels: AdminHomePanels },
        { type: 'buyer-suppplier', title: findlanguage().supplierTitle, panels: AdminHomePanels },
        { type: 'administrative', title: findlanguage().supplierTitle, panels: RegularPanels },
        { type: 'supplier', title: findlanguage().supplierTitle, panels: SupplierHomePanels },
        { type: 'buyer', title: findlanguage().buyerTitle, panels: BuyerHomePanels },
        {
            type: 'service-provider',
            title: findlanguage().serviceProviderTitle,
            panels: ServiceProviderHomePanels,
        },
    ]

    const checkPremium = async () => {
        let plan = {}

        if (user.type === 'admin') {
            const _premiumStatus = {
                type: 'admin',
                due: '2099-01-01',
                expired: false,
                num_users: 999,
            }
            setPremium(_premiumStatus)
            plan = _premiumStatus
        } else {
            plan = await CheckSubscription().then((_premiumStatus) => {
                _premiumStatus.due =
                    _premiumStatus.due === null || _premiumStatus.due === undefined
                        ? '1999-01-01'
                        : _premiumStatus.due

                //forcing pro to premium (due to customers with the old pro plan)
                if (_premiumStatus.type === 'pro') {
                    _premiumStatus.type = 'premium'

                    if (_premiumStatus.num_users === 0 || _premiumStatus.num_users === null) {
                        _premiumStatus.num_users = 3
                    }
                }

                // limite usuários filhos para trial
                if (_premiumStatus.type === 'premium' && _premiumStatus.trial === 1) {
                    _premiumStatus.num_users = 2
                }

                // expired
                if (new Date(_premiumStatus.due) < new Date()) {
                    _premiumStatus = { type: 'free', due: null, expired: true, num_users: 0 }
                }

                setPremium(_premiumStatus)

                return _premiumStatus
            })

            //plan.type = 'premium'
            //plan.expired = false
        }

        return plan
    }

    const showAccessDenied = (offer) => {
        setModal({
            type: 'denied',
            title: '',
            offer: offer,
        })
    }

    const showContactSales = () => {
        setModal({
            type: 'contact-sales',
            title: '',
        })
    }

    const getPermission = (permission) => {
        if (user.IsCompanyUser === undefined) {
            user.IsCompanyUser =
                user.support && user.company_id > 1
                    ? false
                    : user.team_access !== null && user.team_access?.access_account !== null
                    ? true
                    : false
        }

        if (!user.IsCompanyUser || (user.support && user.company_id > 1)) {
            return true
        }

        var _permission = user.team_access?.permissions
            ? user.team_access?.permissions
            : {
                  my_profile: false,
                  filter: false,
                  connections: false,
                  my_whishlist: false,
                  message: false,
                  sms: false,
              }
        let response = false

        switch (permission) {
            case 'my_profile':
                response = _permission.my_profile ? true : false
                break

            case 'filter':
                response = _permission.filter ? true : false
                break

            case 'connections':
                response = _permission.connections ? true : false
                break

            case 'my_whishlist':
                response = _permission.my_whishlist ? true : false
                break

            case 'message':
                response = _permission.message ? true : false
                break

            case 'sms':
                response = _permission.sms ? true : false
                break

            default:
                break
        }

        return response
    }

    const lipsum = (n = 30, dot = false) => {
        if (typeof n !== 'number' || (typeof n === 'number' && n < 1)) n = 30
        const words = [
            'lorem',
            'ipsum',
            'dolor',
            'sit',
            'amet',
            'consectetur',
            'adipiscing',
            'elit',
            'vivamus',
            'et',
            'accumsan',
            'augue',
            'duis',
            'eget',
            'nunc',
            'id',
            'sodales',
            'finibus',
            'vestibulum',
            'sagittis',
            'magna',
            'nec',
            'rutrum',
            'volutpat',
            'risus',
            'tincidunt',
            'justo',
            'non',
            'gravida',
            'tortor',
            'enim',
            'in',
            'urna',
            'ut',
            'vel',
            'metus',
            'pellentesque',
            'porttitor',
            'vitae',
            'nisi',
            'nullam',
            'faucibus',
            'condimentum',
            'quam',
            'imperdiet',
            'class',
            'aptent',
            'taciti',
            'sociosqu',
            'ad',
            'litora',
            'torquent',
            'per',
            'conubia',
            'nostra',
            'inceptos',
            'himenaeos',
            'interdum',
            'malesuada',
            'fames',
            'ac',
            'ante',
            'primis',
            'curabitur',
            'nibh',
            'quis',
            'iaculis',
            'cras',
            'mollis',
            'eu',
            'congue',
            'leo',
        ]
        const count = n
        const sentence = []
        const indexes = new Array(count)
            .fill(0)
            .map((index) => Math.floor(Math.random() * words.length))
        indexes.forEach((index, i) => {
            const word = words[index]
            if (i === 0) sentence.push(word.charAt(0).toUpperCase() + word.substr(1))
            else sentence.push(word)
        })
        if (dot) return sentence.join(' ').concat('.')
        return sentence.join(' ')
    }

    const needToBlockField = (profile) => {
        let connected = false

        // console.log(
        //     'connection',
        //     profile.connection[0].user?.id,
        //     profile.connection[0].receiverUser?.id,
        //     user.company_id
        // )

        if (profile.connection?.length > 0) {
            connected =
                (profile.connection[0].user?.id === user.company_id ||
                    profile.connection[0].receiverUser?.id === user.company_id) &&
                profile.connection[0].acceptedInvitation.toUpperCase() === 'ACCEPTED'
                    ? true
                    : false

            if (profile.connection?.length > 1) {
                if (
                    (profile.connection[1].user?.id === user.company_id ||
                        profile.connection[1].receiverUser?.id === user.company_id) &&
                    profile.connection[1].acceptedInvitation.toUpperCase() === 'ACCEPTED'
                ) {
                    connected = true
                }
            }
        }

        // console.log(
        //     premium.type,
        //     user.id,
        //     profile.connection.length ? profile.connection[0].user.id : '',
        //     connected
        // )

        if (premium.type === 'free' && !connected) return true
        else return false
    }

    const formatDateValue = (dateString) => {
        if (dateString === null || dateString === undefined) {
            return '-'
        }

        const date = new Date(dateString)

        if (language === 'portugues') {
            // Date format: dd/mm/yyyy
            const day = date.getUTCDate().toString().padStart(2, '0')
            const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
            const year = date.getUTCFullYear()
            return `${day}/${month}/${year}`
        } else {
            // Date format: mm/dd/yyyy
            const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
            const day = date.getUTCDate().toString().padStart(2, '0')
            const year = date.getUTCFullYear()
            return `${month}/${day}/${year}`
        }
    }

    const formatDateTimeValue = (dateString) => {
        if (dateString === null || dateString === undefined) {
            return '-'
        }

        const date = new Date(dateString)

        if (language === 'portugues') {
            // Date format: dd/mm/yyyy hh:mm
            const day = date.getUTCDate().toString().padStart(2, '0')
            const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
            const year = date.getUTCFullYear()
            const hour = date.getUTCHours()
            const minute = date.getUTCMinutes()
            return `${day}/${month}/${year} ${hour}:${minute}`
        } else {
            // Date format: mm/dd/yyyy hh:mm
            const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
            const day = date.getUTCDate().toString().padStart(2, '0')
            const year = date.getUTCFullYear()
            const hour = date.getUTCHours()
            const minute = date.getUTCMinutes()
            return `${month}/${day}/${year} ${hour}:${minute}`
        }
    }

    const blurryTextField = (value, userProfile, htmltag = 'div') => {
        let length = value?.trim().split(/\s+/).length
        length = length <= 0 ? 25 : length
        if (needToBlockField(userProfile)) {
            if (htmltag === 'span')
                return (
                    <span className="disable-text-selection" style={{ filter: 'blur(.25rem)' }}>
                        {lipsum(length)}
                    </span>
                )
            else
                return (
                    <div
                        className="disable-text-selection"
                        style={{ filter: 'blur(.25rem)' }}
                        title={'Please connect with the comany to see details'}
                    >
                        {lipsum(length)}
                    </div>
                )
        } else return value
    }

    const contextValue = {
        language,
        setLanguage,
        user,
        setUser,
        permissions,
        setPermissions,
        modal,
        setModal,
        userPanels,
        setUserPanels,
        profile,
        setProfile,
        filter,
        setFilter,
        filterService,
        setFilterService,
        filterScraping,
        setFilterScraping,
        teamAccessList,
        setTeamAccessList,
        premium,
        setPremium,
        newChat,
        setNewChat,
        getPermission,
        blurryTextField,
        needToBlockField,
        showAccessDenied,
        showContactSales,
        checkPremium,
        findlanguage,
        findlanguageEsg,
        AvailablePanels,
        formatDateValue,
        formatDateTimeValue,
        serviceProviderChoosedAccount,
        setServiceProviderChoosedAccount,
        totalMessages,
        setTotalMessages,
        paymentIntent,
        setPaymentIntent,
        showModal,
        setShowModal,
    }

    const init = async () => {
        if (
            user?.acceptedUserTermsAndConditions === undefined ||
            user?.acceptedUserTermsAndConditions === null ||
            user.acceptedUserTermsAndConditions === false
        ) {
            Cookies.remove('auth_token')
        }

        const jwt = Cookies.get('auth_token')

        if (jwt) {
            const readMe = await ReadMe()
            if (readMe.message === 'User Not Found') {
                Cookies.remove('auth_token')
                window.location.reload()
            }
        }
    }
    useEffect(() => {
        init()
    }, [])

    // to persist state when app reload
    useEffect(() => {
        user.IsCompanyUser =
            user.support && user.company_id > 1
                ? false
                : user.team_access !== null && user.team_access?.access_account !== null
                ? true
                : false
        SaveObject('user', user)
    }, [user])
    useEffect(() => {
        SaveObject('profile', profile)
    }, [profile])
    useEffect(() => {
        SaveObject('userPanels', userPanels)
    }, [userPanels])
    useEffect(() => {
        SaveObject('filter', filter)
    }, [filter])
    useEffect(() => {
        SaveObject('filterService', filterService)
    }, [filterService])
    useEffect(() => {
        SaveObject('filterScraping', filterScraping)
    }, [filterScraping])
    useEffect(() => {
        //console.log('save', premium)
        SaveObject('premium', premium)
    }, [premium])

    return <CoreContext.Provider value={contextValue}>{children}</CoreContext.Provider>
}
