import styled from 'styled-components'

export const ProfileFormActions = styled.div.attrs({})`
    display: flex;
    justify-content: center;
`
export const PageFormSpace = styled.div.attrs({})`
    margin-right: 12px;
`

export const Label = styled.div.attrs({})`
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    padding-left: 30px;
    padding-top: 5px;
    margin-bottom: 10px;
`
export const RowForm = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    gap: 0 20px;
    overflow: auto;
`

export const ProfileFormContent = styled.div.attrs({})`
    width: 100%;
    padding: 0px 0 20px;
`

export const ModalFormLabel = styled.div.attrs({})`
    margin-bottom: 7px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--primary-color);
    ${(props) =>
        props.spaced
            ? `
            margin-bottom: 5px;
        `
            : ``}
`
