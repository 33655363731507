import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const Content = styled.div.attrs({})``

export const PageHeader = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 20px;
`
export const PageHeaderTitle = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: var(--grey-color);
`
export const PageHeaderActions = styled.div.attrs({})`
    display: flex;
    align-items: center;
`

export const PageCard = styled.div.attrs({})`
    padding: 24px;
    background: var(--white-color);

    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
    box-shadow: 0px 1.5px 6px var(--shadow-color);
    margin-bottom: 20px;
`
export const ProfileContainer = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
`
export const ProfileContent = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const ProfileContentSpace = styled.div.attrs({})`
    padding: 10px;
`
export const ProfileFormContent = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: space-evenly;
`

export const ProfileFormActions = styled.div.attrs({})`
    flex: 1;
    min-width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px;
`

export const PageScroller = styled.div.attrs({})`
    margin-top: 20px;
    height: 260px;
    overflow: auto;
`

export const CertificateContent = styled.div.attrs({})`
    display: flex;
    justify-content: center;
    padding: 32px 0;
    gap: 0 12px;
`
export const CertificateItem = styled.div.attrs({})`
    width: 93px;
    height: 93px;

    background: url(/icons/certificate-${(props) => props.certificate}.svg) no-repeat center center /
        contain;
`
export const DocumentUploadContent = styled.div.attrs({})`
    display: flex;
    margin-bottom: 20px;
`
export const DocumentUploadWrapper = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    gap: 20px;
`
export const DocumentUploadActions = styled.div.attrs({})`
    display: flex;
    align-items: center;
`

export const DocumentItem = styled.div.attrs({})`
    width: 153px;
    min-height: 120px;
    border-radius: 8px;

    border: 0.5px solid var(--backgroundlightgrey-color);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const DocumentClose = styled.img.attrs({
    src: '/icons/close.svg',
})`
    position: absolute;
    right: 12px;
    top: 12px;

    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
`
export const DocumentIcon = styled.img.attrs({
    src: '/icons/document.svg',
})`
    margin-bottom: 12px;
`
export const DocumentTitle = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: var(--grey-color);
`

export const RowAddress = styled.div.attrs({})`
    display: flex;
    align-items: center;
    gap: 0 20px;
`

export const AddressAdd = styled.img.attrs({
    src: '/icons/circle-add-green.svg',
    width: 32,
})`
    margin-top: 16px;

    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
`

export const AddressClose = styled.img.attrs({
    src: '/icons/close.svg',
    width: 14,
})`
    margin: 16px 0 0 10px;

    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
`

export const RequiredText = styled.div.attrs({})`
    color: var(--primary-color);
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-align: center;
    margin: 35px 0;
    padding-bottom: 20px;
`

export const ItemRow = styled.div.attrs({})`
    display: flex;
    width: 100%;

    ${(props) =>
        props.loading === 'true'
            ? `
            color: #ccc;
            opacity: 0.2;
        `
            : ``}
`

export const Link = styled.a.attrs({})`
    color: var(--black-color) !important;

    &:hover {
        color: var(--black-color) !important;
    }

    &:active {
        color: var(--black-color) !important;
    }

    &:visited {
        color: var(--black-color) !important;
    }
`

export const DeleteIcon = styled.img.attrs({
    src: '/icons/close-red.svg',
})`
    cursor: pointer;
    width: 8px;
`


export const IconInfo = styled.img.attrs({
    src: '/icons/circle-info.svg',
})``
