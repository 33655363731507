import { CoreContext } from 'context/CoreContext'
import React, { useContext, useState } from 'react'
import { DeleteAvatar, UploadAvatar } from 'services/api'
import { toast } from 'react-toastify'

import {
    UserProfileImage,
    UserProfileImageAction,
    UserProfileImageActionIcon,
    UserImageChange,
    UserProfileImageActionIconWithMargin
} from './styled'
import { ReadObject, SaveObject } from 'services/storage'
import { parseStrapiImage } from 'utils'

export default function FormImagePicker({ image }) {
    const { setModal, user, setUser, findlanguage, permissions } = useContext(CoreContext)
    
    const choosePick = () => {
        setModal({
            type: 'choose',
            title: findlanguage().whatToDo,
            options: [
                { title: findlanguage().deletePhoto, action: () => attach('delete') },
                { title: findlanguage().changePhoto, action: () => attach() },
            ],
        })
    }

    const [uploading, setUploading] = useState(false)

    const attach = (action) => {
        setModal(null)
        if (action === 'delete') {
            document.getElementById('change-image').value = ''
            completeChangeImage(null, 'delete')
        } else {
            document.getElementById('change-image')?.click()
        }
    }

    const completeChangeImage = async (image, action) => {
        setUploading(true)
        let result = null
        if (action === 'delete') {
            result = await DeleteAvatar()
        } else {
            result = await UploadAvatar(document.getElementById('change-image')?.files?.[0])
        }

        if (result.id) {
            if (action === 'delete') {
                setUser({ ...user, avatar: null })
                toast.success(findlanguage().avatarDeleted)
            } else {
                setUser({ ...user, avatar: result.avatar })
                toast.success(findlanguage().avatarChanged)
            }
        }
        setUploading(false)
    }

    return (
        <>
            <UserProfileImage
                image={user?.avatar ? parseStrapiImage(user?.avatar?.url) : '/images/no-image.png'}
            >
                {!user.IsCompanyUser && permissions?.myProfile?.canEdit ? (
                    <UserProfileImageAction onClick={choosePick}>
                        {
                            user.status && window.location.pathname !== '/dashboard'?
                                <>
                                    <UserProfileImageActionIconWithMargin />
                                    <img src='/icons/checked.svg' alt='Approved' style={{ marginLeft: '17px', height: '25px' }} />
                                </>
                                :
                                <UserProfileImageActionIcon />
                        }
                    </UserProfileImageAction>
                ) : (
                    ''
                )}
            </UserProfileImage>
            <UserImageChange onChange={completeChangeImage} />
        </>
    )
}
