import React, { useEffect, useState, useContext } from 'react'
import { PageCard } from '../../../../../Panels/SMSControl/styled'
import { TitleGreen } from '../styled'
import InputMask from 'react-input-mask'
import {
    GetSupplierQuestionnarieGetOne,
    PutSupplierQuestionnarie,
    PostSupplierQuestionnarie,
} from 'services/egs'
import { ReadMe } from 'services/authentication'
import DashboardButton from 'components/Dashboard/Button'
import _ from 'lodash'
import { CoreContext } from 'context/CoreContext'

const labelCss = { fontWeight: 'bold', fontSize: '15px', color: 'rgb(86, 86, 86)' }
const inputCss = { borderColor: '#70707059' }
const col6 = { fontSize: '700', color: '#707070' }
const doneTypingInterval = 1000

let typingTimer

export default function Questionarie({
    setForm,
    form,
    setMfsQuestionarie,
    mfsQuestionarie,
    backOffice,
    goBack,
    company_id,
}) {
    const { findlanguageEsg, findlanguage } = useContext(CoreContext)
    const { profile, setModal, user } = useContext(CoreContext)
    const [questionnaire, setQuestionnaire] = useState({
        id: -1,
        legal_company_name: '',
        ein_number: '',
        address: '',
        city: '',
        state: '',
        country: '',
        zip: '',
        phone: '',
        email: '',
        category: '',
        other: '',
        position: '',
        type_of_company: '',
        person: '',
        form_status_esg: '',
        user_accepted_terms: false,
    })

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        waitUserFinishTyping()
    }, [questionnaire])

    const init = async () => {
        const getSupplierQuestionnarie = await GetSupplierQuestionnarieGetOne(
            backOffice ? company_id : user.company_id
        )

        if (
            getSupplierQuestionnarie.statusText !== 'Not Found' &&
            getSupplierQuestionnarie.length !== 0
        ) {
            if (
                !backOffice &&
                (getSupplierQuestionnarie[0].form_status_esg === 'open' ||
                    getSupplierQuestionnarie[0].form_status_esg === null)
            )
                setForm(false)
            else setForm(true)

            setQuestionnaire(getSupplierQuestionnarie[0])
        } else
            setQuestionnaire(
                await PostSupplierQuestionnarie({
                    users_permissions_user: backOffice ? company_id : user.company_id,
                    form_status_esg: 'open',
                })
            )
    }

    function changeQuestionaire(value, field, isInt) {
        const pass = rules(value, field)

        if (pass) {
            setQuestionnaire({
                ...questionnaire,
                [field]: isInt ? parseInt(value.replace(/\D/g, '')) : value,
            })
        }
    }

    function rules(value, field) {
        switch (field) {
            case 'other':
            case 'position':
            case 'email':
                if (value.length > 30) return false
                else return true
            default:
                return true
        }
    }

    function waitUserFinishTyping() {
        clearTimeout(typingTimer)
        typingTimer = setTimeout(saveQuestionarie, doneTypingInterval)
    }

    const saveQuestionarie = async () => {
        if (questionnaire.id !== -1 && !backOffice) await PutSupplierQuestionnarie(questionnaire)
    }

    const submitQuestionarie = async () => {
        let questionnaireClone = _.cloneDeep(questionnaire)
        if (backOffice) questionnaireClone.form_status_esg = 'approved'
        else questionnaireClone.form_status_esg = 'pending'

        questionnaireClone.user_accepted_terms = true

        switch (mfsQuestionarie) {
            case '1':
                questionnaireClone.type_of_form = 'basic'
                break
            default:
                break
        }

        setQuestionnaire(questionnaireClone)
        setForm(true)

        await PutSupplierQuestionnarie(questionnaireClone)

        setModal({
            type: 'alert',
            text: backOffice ? 'Approved' : 'MyTS form submitted.',
            warning: true,
        })

        if (backOffice) goBack()
    }

    const submitMfs = () => {
        if (backOffice) submitQuestionarie()
        else if (backOffice)
            setModal({
                type: 'submit-mfs',
                title: 'Submit MyTS',
                submitQuestionarie: submitQuestionarie,
            })
    }

    return (
        <>
            <PageCard>
                <TitleGreen>
                    {findlanguageEsg().supplierQuestionnaireEsg}{' '}
                    {backOffice ? (
                        <div style={{ float: 'right', marginTop: '-27px', position: 'sticky' }}>
                            <button
                                className="btn btn-secondary"
                                style={{ marginRight: '5px', marginTop: '15px' }}
                                onClick={goBack}
                            >
                                {findlanguage().goBack}
                            </button>
                            {questionnaire.form_status_esg !== 'approved' ? (
                                <button
                                    className="btn"
                                    style={{
                                        marginRight: '5px',
                                        marginTop: '15px',
                                        backgroundColor: '#478d3a',
                                        color: 'white',
                                    }}
                                    onClick={submitMfs}
                                >
                                    Approve
                                </button>
                            ) : null}
                        </div>
                    ) : (
                        <div style={{ float: 'right', marginTop: '-27px', position: 'sticky' }}>
                            <button
                                className="btn btn-secondary"
                                style={{ marginRight: '5px', marginTop: '15px' }}
                                onClick={() => setMfsQuestionarie(1)}
                            >
                                {findlanguage().goBack}
                            </button>
                            {questionnaire.form_status_esg === 'open' ||
                            questionnaire.form_status_esg === null ? (
                                <button
                                    className="btn"
                                    style={{
                                        marginRight: '5px',
                                        marginTop: '15px',
                                        backgroundColor: '#263640',
                                        color: 'white',
                                    }}
                                    onClick={submitMfs}
                                >
                                    {findlanguageEsg().submit}
                                </button>
                            ) : null}
                        </div>
                    )}
                </TitleGreen>
                <div className="row">
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            {findlanguageEsg().legalCompanyName}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.legal_company_name}
                            onChange={(event) =>
                                changeQuestionaire(event.target.value, 'legal_company_name')
                            }
                            required
                            disabled={form}
                        />
                    </div>
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            {findlanguageEsg().einNumber}
                        </label>
                        <InputMask
                            disableunderline="true"
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.ein_number}
                            onChange={(event) =>
                                changeQuestionaire(event.target.value, 'ein_number', true)
                            }
                            mask="99-9999999"
                            required
                            disabled={form}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().address}:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.address}
                            onChange={(event) => changeQuestionaire(event.target.value, 'address')}
                            disabled={form}
                        />
                    </div>
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().city}:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.city}
                            onChange={(event) => changeQuestionaire(event.target.value, 'city')}
                            disabled={form}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().state}:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.state}
                            onChange={(event) => changeQuestionaire(event.target.value, 'state')}
                            disabled={form}
                        />
                    </div>
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().country}:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.country}
                            onChange={(event) => changeQuestionaire(event.target.value, 'country')}
                            disabled={form}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().zip}:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.zip}
                            onChange={(event) => changeQuestionaire(event.target.value, 'zip')}
                            disabled={form}
                        />
                    </div>
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().category}:
                        </label>
                        <select
                            className="form-select"
                            aria-label="Default select example"
                            value={questionnaire.category}
                            onChange={(event) => changeQuestionaire(event.target.value, 'category')}
                            disabled={form}
                        >
                            <option value="Red Meat">{findlanguageEsg().redMeat}</option>
                            <option value="Poultry">{findlanguageEsg().poultry}</option>
                            <option value="Fish">{findlanguageEsg().fish}</option>
                            <option value="Fruit">{findlanguageEsg().fruit}</option>
                            <option value="Vegetables">{findlanguageEsg().vegetables}</option>
                            <option value="Nuts">{findlanguageEsg().nuts}</option>
                            <option value="Dairy & Egg">{findlanguageEsg().dairyEgg}</option>
                            <option value="Ready meals and sandwiches">
                                {findlanguageEsg().mealsSandwich}
                            </option>
                            <option value="Beverages">{findlanguageEsg().beverages}</option>
                            <option value="Alcoholic Drinks">
                                {findlanguageEsg().alcoholicDrinks}
                            </option>
                            <option value="Coffe">{findlanguageEsg().coffee}</option>
                            <option value="Bakery">{findlanguageEsg().bakery}</option>
                            <option value="Pantry Product (Canned)">
                                {findlanguageEsg().pantryProduct}
                            </option>
                            <option value="Ingredients">{findlanguageEsg().ingredients}</option>
                            <option value="Dry Products">{findlanguageEsg().dryProducts}</option>
                            <option value="Confectionery & Snacks">
                                {findlanguageEsg().confectionerySnacks}
                            </option>
                            <option value="Sauce">{findlanguageEsg().sauce}</option>
                            <option value="Oil and fats">{findlanguageEsg().oilFats}</option>
                            <option value="Plant Based">{findlanguageEsg().plantBased}</option>
                            <option value="Other">{findlanguageEsg().other}</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().other}:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.other}
                            onChange={(event) => changeQuestionaire(event.target.value, 'other')}
                            disabled={form}
                        />
                    </div>
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            {findlanguageEsg().typeOfCompany}:
                        </label>
                        <select
                            className="form-select"
                            aria-label="Default select example"
                            value={questionnaire.type_of_company}
                            onChange={(event) =>
                                changeQuestionaire(event.target.value, 'type_of_company')
                            }
                            disabled={form}
                        >
                            <option value="Food Production Industry">
                                {findlanguageEsg().foodProdIndustry}
                            </option>
                            <option value="Farm (Field and Packing)">
                                {findlanguageEsg().farmFieldPacking}
                            </option>
                            <option value="Farm (Rural Producer)">
                                {findlanguageEsg().farmRuralProducer}
                            </option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            {findlanguageEsg().person}:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.person}
                            onChange={(event) => changeQuestionaire(event.target.value, 'person')}
                            disabled={form}
                        />
                    </div>
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            {findlanguageEsg().position}:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.position}
                            onChange={(event) => changeQuestionaire(event.target.value, 'position')}
                            disabled={form}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().email}:
                        </label>
                        <input
                            type="email"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.email}
                            onChange={(event) => changeQuestionaire(event.target.value, 'email')}
                            disabled={form}
                        />
                    </div>

                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().phoneNumber}:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.phone}
                            onChange={(event) => changeQuestionaire(event.target.value, 'phone')}
                            disabled={form}
                        />
                    </div>
                </div>
            </PageCard>
        </>
    )
}
