import React from 'react'

import {
    ModalHeader,
    ModalTitle,
    ModalLoad,
    ModalHeaderSpace,
    ModalHeaderClose,
    ModalHeaderCenterContent,
    ModalBadge,
} from './styled'

export default function ModalChatModeMinimized({ minimize, currentChat, mode, close }) {
    return (
        <>
            <ModalHeader>
                <ModalHeaderSpace />
                {mode === 'loading' ? (
                    <>
                        <ModalHeaderCenterContent onClick={minimize}>
                            <ModalLoad />
                            <ModalTitle>Waiting for attendant</ModalTitle>
                        </ModalHeaderCenterContent>
                    </>
                ) : null}
                {mode === 'chat' ? (
                    <>
                        <ModalHeaderCenterContent onClick={minimize}>
                            <ModalTitle>Hello {currentChat?.name}</ModalTitle>
                            <ModalBadge>2</ModalBadge>
                        </ModalHeaderCenterContent>
                    </>
                ) : null}
                <ModalHeaderClose onClick={close} />
            </ModalHeader>
        </>
    )
}
