import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import Card from '../Card/index.js'

import { PageCard, CardHeader, CardHeaderTitleContainer } from './styled'
import _ from 'lodash'

export default function SelfAssessmentChecklist({
    certifications,
    onChangeCertifications,
    saveCertifications,
    saveDocument,
    form,
    returnHeaders,
    mfsQuestionarie
}) {

    const { findlanguageEsg } = useContext(CoreContext) 


    //#region I.3.1
    const i31 = {
        ref: 'I.3.1',
        requirementTitle: findlanguageEsg().i31RequirementTitle,
        inputForYesAnswer: true,
        placeHolderTextYes: findlanguageEsg().i31PlaceHolderTextYes,
        inputForNoAnswer: false,
        placeHolderTextNo: findlanguageEsg().i31PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    //#region I.3.1 Yes

    //#region I.3.1.Y.A

    const i31ya = {
        ref: 'I.3.1.Y.A',
        requirementTitle: findlanguageEsg().i31yaRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i31yaPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i31yay = {
        ref: 'I.3.1.Y.A.Y',
        requirementTitle: findlanguageEsg().i31yayRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i31yayPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const i31yayy = {
        ref: 'I.3.1.Y.A.Y.Y',
        requirementTitle: findlanguageEsg().i31yayyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i31yayyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
    }

    const i31yayyql1 = {
        ref: 'I.3.1.Y.A.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().i31yayyqlRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i31yayyqlPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        fileForYesAnswerAndNoInput: true,
        color: '#2f8c5d',
        question: true,
    }

    //#endregion

    //#region I.3.1.Y.B

    const i31yb = {
        ref: 'I.3.1.Y.B',
        requirementTitle: findlanguageEsg().i31ybRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i31ybPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i31yby = {
        ref: 'I.3.1.Y.B.Y',
        requirementTitle: findlanguageEsg().i31ybyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: findlanguageEsg().i31ybyPlaceHolderTextYes,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i31ybyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const i31ybyyqt1 = {
        ref: 'I.3.1.Y.B.Y.Y.QT.1',
        requirementTitle: findlanguageEsg().i31ybyyqt1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const i31ybyyqt2 = {
        ref: 'I.3.1.Y.B.Y.Y.QT.2',
        requirementTitle: findlanguageEsg().i31ybyyqt2RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const i31ybyyqtn = {
        ref: 'I.3.1.Y.B.Y.Y.QT.N',
        requirementTitle: findlanguageEsg().i31ybyyqtnRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const i31ybyyql1 = {
        ref: 'I.3.1.Y.B.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().i31ybyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i31ybyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.C

    const i31yc = {
        ref: 'I.3.1.Y.C',
        requirementTitle: findlanguageEsg().i31ycRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i31ycPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i31ycy = {
        ref: 'I.3.1.Y.C.Y',
        requirementTitle: findlanguageEsg().i31ycyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i31ycyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i31ycyy = {
        ref: 'I.3.1.Y.C.Y.Y',
        requirementTitle: findlanguageEsg().i31ycyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i31ycyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i31ycyyql1 = {
        ref: 'I.3.1.Y.C.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().i31ycyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i31ycyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.D

    const i31yd = {
        ref: 'I.3.1.Y.D',
        requirementTitle: findlanguageEsg().i31ydRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i31ydPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i31ydy = {
        ref: 'I.3.1.Y.D.Y',
        requirementTitle: findlanguageEsg().i31ydyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i31ydyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i31ydyy = {
        ref: 'I.3.1.Y.D.Y.Y',
        requirementTitle: findlanguageEsg().i31ydyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i31ydyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i31ydyyql1 = {
        ref: 'I.3.1.Y.D.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().i31ydyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i31ydyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#endregion

    //#region A.1.1 NO
    const i31na = {
        ref: 'I.3.1.N.A',
        requirementTitle: findlanguageEsg().i31naRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i31naPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const i31nb = {
        ref: 'I.3.1.N.B',
        requirementTitle: findlanguageEsg().i31nbRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i31nbPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const i31nc = {
        ref: 'I.3.1.N.C',
        requirementTitle: findlanguageEsg().i31ncRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i31ncPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    const returnCondition = (item, layers, category1, category2, category3, category4) => {
        switch (layers) {
            case 0:
                return (
                    <Card
                        item={item}
                        onChangeCertifications={onChangeCertifications}
                        saveCertifications={saveCertifications}
                        saveDocument={saveDocument}
                        certifications={certifications}
                        form={form}
                        mfsQuestionarie={mfsQuestionarie}
                    />
                )
                break
            case 1:
                if (certifications?.find((x) => x.category === category1)?.next_level === true)
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 2:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 3:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 4:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true &&
                    certifications?.find((x) => x.category === category4)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            default:
                break
        }
    }

    return (
        <>
            <PageCard>
                <CardHeader>
                    <CardHeaderTitleContainer>
                        {findlanguageEsg().i31CardHeaderTitleContainer}
                    </CardHeaderTitleContainer>
                </CardHeader>
                {returnHeaders()}
                {returnCondition(i31, 0)}
            </PageCard>
            {
                mfsQuestionarie !== '1' ?
                    <>
                        {
                            certifications?.find((x) => x.category === 'I.3.1')?.next_level === true ? (
                            <>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().i31aCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(i31ya, 1, 'I.3.1')}
                                    {returnCondition(i31yay, 2, 'I.3.1', 'I.3.1.Y.A')}
                                    {returnCondition(i31yayy, 3, 'I.3.1', 'I.3.1.Y.A', 'I.3.1.Y.A.Y')}
                                    {returnCondition(
                                        i31yayyql1,
                                        4,
                                        'I.3.1',
                                        'I.3.1.Y.A',
                                        'I.3.1.Y.A.Y',
                                        'I.3.1.Y.A.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().i31bCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(i31yb, 1, 'I.3.1')}
                                    {returnCondition(i31yby, 2, 'I.3.1', 'I.3.1.Y.B')}
                                    {returnCondition(i31ybyyqt1, 3, 'I.3.1', 'I.3.1.Y.B', 'I.3.1.Y.B.Y')}
                                    {returnCondition(i31ybyyqt2, 3, 'I.3.1', 'I.3.1.Y.B', 'I.3.1.Y.B.Y')}
                                    {returnCondition(i31ybyyqtn, 3, 'I.3.1', 'I.3.1.Y.B', 'I.3.1.Y.B.Y')}
                                    {returnCondition(i31ybyyql1, 3, 'I.3.1', 'I.3.1.Y.B', 'I.3.1.Y.B.Y')}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().i31cCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(i31yc, 1, 'I.3.1')}
                                    {returnCondition(i31ycy, 2, 'I.3.1', 'I.3.1.Y.C')}
                                    {returnCondition(i31ycyy, 3, 'I.3.1', 'I.3.1.Y.C', 'I.3.1.Y.C.Y')}
                                    {returnCondition(
                                        i31ycyyql1,
                                        4,
                                        'I.3.1',
                                        'I.3.1.Y.C',
                                        'I.3.1.Y.C.Y',
                                        'I.3.1.Y.C.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().i31dCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(i31yd, 1, 'I.3.1')}
                                    {returnCondition(i31ydy, 2, 'I.3.1', 'I.3.1.Y.D')}
                                    {returnCondition(i31ydyy, 3, 'I.3.1', 'I.3.1.Y.D', 'I.3.1.Y.D.Y')}
                                    {returnCondition(
                                        i31ydyyql1,
                                        4,
                                        'I.3.1',
                                        'I.3.1.Y.D',
                                        'I.3.1.Y.D.Y',
                                        'I.3.1.Y.D.Y.Y'
                                    )}
                                </PageCard>
                            </>
                        ) : null}
                        {certifications?.find((x) => x.category === 'I.3.1')?.next_level === false ? (
                            <PageCard>
                                <CardHeader>
                                    <CardHeaderTitleContainer>
                                        {findlanguageEsg().i31noCardHeaderTitleContainer}
                                    </CardHeaderTitleContainer>
                                </CardHeader>
                                {returnHeaders()}
                                {returnCondition(i31na, 0)}
                                {returnCondition(i31nb, 0)}
                                {returnCondition(i31nc, 0)}
                            </PageCard>
                        ) : null}

                    </>
                    :
                    null
            }
        </>
    )
}
