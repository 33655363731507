import React, { useContext } from 'react'

import { CoreContext } from 'context/CoreContext'
import DashboardMore from 'components/Dashboard/More'

import {
    CardSearched,
    CardContent,
    CardTitle,
    CardSubtitle,
    CardLink,
    CardTouch,
    CardProfileContent,
    CardImage,
} from './styled'

export default function DashboardFilterScrapingCard({ item }) {
    const { setModal, findlanguage } = useContext(CoreContext)

    const openDetail = (scraping) => {
        setModal({
            type: 'scraping',
            title: findlanguage().companyDetails,
            scraping,
        })
    }

    const moreOptions = () => {
        let options = [{ title: findlanguage().addToWishList, action: () => null }]

        return options
    }

    return (
        <>
            <CardSearched>
                <CardProfileContent>
                    <CardTouch>
                        <CardImage src={`/images/no-image.png`} />
                    </CardTouch>
                </CardProfileContent>
                <CardContent>
                    <span className="badge bg-danger">{findlanguage().nonMember}</span>
                    <CardTouch onClick={() => openDetail(item)}>
                        <CardTitle>{item?.company_name}</CardTitle>
                        <CardSubtitle>
                            {item?.country}, {item?.state}
                            <br />
                            {item?.city}
                        </CardSubtitle>
                    </CardTouch>
                </CardContent>
                <DashboardMore options={moreOptions()} />
            </CardSearched>
        </>
    )
}
