import React, { useState } from 'react';
import ControlButtons from '../ControlButtons/index';

function Checkbox({ element, sections, currentStep, setSections, handleLabelTextChange, toggleCorrectOption, removeOption, handleResize, removeElement, is_draft }) {
    const [newOptionText, setNewOptionText] = useState('');
    const [nextId, setNextId] = useState(0);

    const addCheckboxOption = (elementId) => {
        setSections(sections => sections.map(step => ({
            ...step,
            elements: step.elements.map(el => {
                if (el.id === elementId && el.type === 'checkbox') {
                    const newOptionId = nextId;
                    const newOption = { id: newOptionId, label: newOptionText, value: newOptionText, is_correct: false };
                    if (!el.allowMultiple && el.options.length === 0) {
                        newOption.is_correct = true;
                        el.correctOption = newOptionId;
                    }
                    return {
                        ...el,
                        options: [...el.options, newOption],
                    };
                }
                return el;
            }),
        })));
        setNextId(nextId + 1);
        setNewOptionText('');
    };

    const handleWeightChange = (elementId, weight) => {
        const newSteps = sections.map((step, sIndex) => {
            if (sIndex === currentStep) {
                return {
                    ...step,
                    elements: step.elements.map(el => {
                        if (el.id === elementId) {
                            return { ...el, weight: parseInt(weight) };
                        }
                        return el;
                    }),
                };
            }
            return step;
        });
        setSections(newSteps);
    };

    const weightOptions = Array.from({ length: 100 }, (_, i) => i + 1);

    return (
        <div key={element.id} className="mb-3 col-12">
            <label className="form-label">Checkbox</label>
            <input
                type="text"
                value={element.label || ''}
                onChange={(e) => handleLabelTextChange(element.id, e)}
                className="form-control"
                placeholder="Add a label for checkbox field here"
                disabled={is_draft}
            />
            <div className="input-group mb-2" style={{ marginTop: '10px' }}>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Type new option"
                    value={newOptionText}
                    onChange={e => setNewOptionText(e.target.value)}
                    disabled={is_draft}
                />
                <button
                    disabled={!newOptionText}
                    className="btn colorTealDark"
                    type="button"
                    onClick={() => addCheckboxOption(element.id)}
                >
                    Add
                </button>
            </div>
            {sections[currentStep]?.type === 'quiz' && (
                <select
                    className="form-select mb-2"
                    value={element.weight || ''}
                    onChange={(e) => handleWeightChange(element.id, e.target.value)}
                    disabled={is_draft}
                >
                    <option value="" disabled>Select weight</option>
                    {weightOptions.map(option => (
                        <option key={option} value={option}>
                            {option}%
                        </option>
                    ))}
                </select>
            )}
            {element?.options?.map((option, index) => (
                <div key={option.id} className="d-flex align-items-center justify-content-between mb-2">
                    <span className="flex-grow-1">{option.value}</span>
                    <div className="d-flex align-items-center">
                        {sections[currentStep]?.type === 'quiz' && (
                            <div style={{ marginRight: '10px' }}>
                                <input
                                    id={`correct-answer-${option.id}`}
                                    type="checkbox"
                                    checked={option.is_correct}
                                    onChange={e => toggleCorrectOption(element.id, option.id, e.target.checked)}
                                    className="form-check-input"
                                    disabled={is_draft}
                                />
                                <label htmlFor={`correct-answer-${option.id}`} className="ms-2">
                                    Mark as correct Answer
                                </label>
                            </div>
                        )}
                        {!is_draft && (
                            <button type="button" className="btn btn-outline-danger btn-sm ms-auto" onClick={() => removeOption(element.id, option.id)}>
                                <i className="bi bi-x-lg"></i>
                            </button>
                        )}
                    </div>
                </div>
            ))}
            <ControlButtons
                elementId={element.id}
                onResize={handleResize}
                onRemove={removeElement}
                is_draft={is_draft}
            />
        </div>
    );
}

export default Checkbox;
