import { CoreContext } from 'context/CoreContext'
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ReadMe } from 'services/authentication'

import { Container, Row, Col } from 'reactstrap'
import { ReadObject } from 'services/storage'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'

import {
    SideBackgroundImageContainer,
    SideBackgroundImage,
    SideBackgroundImageDegree,
    FormContent,
    Content,
    VerticalCenter,
    Logo,
    Title,
    Text,
    ContainerLogo,
    BodyContainer,
    HeaderContent,
    HeaderDegree,
    HeaderLogo,
} from './styled'

export default function ContainerUnauthenticated({ children, landpage, fluid }) {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const { setModal, setUser, findlanguage, setShowModal, language } = useContext(CoreContext)

    const init = async () => {
        const jwt = Cookies.get('auth_token')

        if (jwt && isJwtValid(jwt)) {
            var user = await ReadMe()
            setShowModal(true)

            if (user?.subscription?.expiration_date !== undefined) {
                const dueDate = new Date(user.subscription.expiration_date);
                const currentDate = new Date();
                currentDate.setDate(currentDate.getDate())

                const oneDay = 24 * 60 * 60 * 1000;
                const differenceInMilliseconds = dueDate - currentDate;
                const differenceInDays = Math.round(differenceInMilliseconds / oneDay);

                if (user.subscription.trial === true && user.subscription.type === 'free' && currentDate < dueDate && differenceInDays >= 0) {
                    navigate('dashboard/profile')
                }
                else {
                    navigate('dashboard')
                }
            }
            else {
                navigate('dashboard')
            }
        }
    }

    const isJwtValid = (jwt) => {
        try {
            const decoded = jwtDecode(jwt)
            const currentTime = Date.now() / 1000

            if (decoded.exp < currentTime) {
                return false
            } else {
                return true
            }
        } catch (e) {
            return false
        }
    }

    useEffect(() => {
        init()
    })

    useEffect(() => {
        if (window.location.href.includes("app.myfoodsc")) {
            window.location.href = "https://app.mytrustedsc.com/";
        }
    }, []);

    return (
        <>
            <SideBackgroundImageContainer burned={!!landpage}>
                {landpage ? (
                    <>
                        <SideBackgroundImage />
                        <SideBackgroundImageDegree />
                    </>
                ) : null}
                <Content>
                    {landpage ? (
                        <>
                            <Container fluid>
                                <Row>
                                    <Col md={{ size: 6 }}>
                                        <VerticalCenter degradee={!!landpage}>
                                            <ContainerLogo>
                                                <Logo />
                                                <Title>My Source, Our Future.</Title>
                                                <Text>{findlanguage().loginText}</Text>
                                            </ContainerLogo>
                                        </VerticalCenter>
                                    </Col>
                                    <Col md={{ size: 6 }}>
                                        <VerticalCenter>
                                            <FormContent>{children}</FormContent>
                                        </VerticalCenter>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    ) : (
                        <>
                            <BodyContainer>
                                <HeaderContent>
                                    <HeaderDegree>
                                        <HeaderLogo />
                                    </HeaderDegree>
                                </HeaderContent>
                                <Container
                                    fluid={fluid}
                                    style={fluid ? { margin: 0, padding: 0 } : {}}
                                >
                                    {children}
                                </Container>
                            </BodyContainer>
                        </>
                    )}
                </Content>
            </SideBackgroundImageContainer>
        </>
    )
}
