import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const PanelPageBottomAction = styled.div.attrs({})`
    padding: 32px 20px;
    background: var(--background-color);
    position: fixed;
    bottom: 0;
    width: 100%;

    display: flex;
    justify-content: flex-end;
`
export const PanelPageBottomActionSpacer = styled.div.attrs({})`
    margin-top: 120px;
`

export const PanelPageBottomAlignLeft = styled.div.attrs({})`
    display: flex;
    justify-content: flex-end;
`
