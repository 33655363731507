import { DELETE, GET, POST, PUT } from './api'
import { PostCertificate, PostServiceProviderDocument } from 'services/api'

export const FetchMyProducts = async (userId) => {
    return await GET(`/supplier-products/${userId}`, true)
}

export const FetchMyCertificates = async (userId) => {
    return await GET(`/supplier-certificates/${userId}`, true)
}

export const GetCertificate = async (id) => {
    return await GET(`/supplier-certificates/certificate/${id}`, true)
}

export const GetProduct = async (id) => {
    return await GET(`/supplier-products/product/${id}`, true)
}

export const RemoveProduct = async (id) => {
    return await DELETE(`/supplier-products/${id}`, true)
}

export const RemoveCertificate = async (id) => {
    return await DELETE(`/supplier-certificates/${id}`, true)
}

export const RegisterNewProduct = async (form) => {
    return await POST(`/supplier-products/`, form, true)
}

export const AddCertificate = async (formData) => {
    return await PostCertificate(formData)
}

export const AddProduct = async (form) => {
    return await POST(`/supplier-products/`, form, true)
}

export const UpdateCertificates = async (userId, supplierCertificateId, body) => {
    return await PUT(`/supplier-certificates/`, body, true)
}

export const UpdateProduct = async (productId, body) => {
    return await PUT(`/supplier-certificates/`, body, true)
}

export const FetchMyServiceProviderRegions = async (userId) => {
    return await GET(`/service-provider-regions/${userId}`, true)
}

export const FetchMyServiceProviderDocuments = async (userId) => {
    return await GET(`/service-provider-documents/${userId}`, true)
}

export const AddServiceProviderDocument = async (formData) => {
    return await PostServiceProviderDocument(formData)
}

export const GetServiceProviderDocument = async (userId) => {
    return await GET(`/service-provider-documents/${userId}`, true)
}

export const UpdateServiceProviderDocument = async (userId, documentId, body) => {
    return await PUT(`/service-provider-documents/`, body, true)
}

export const RemoveServiceProviderDocument = async (id) => {
    return await DELETE(`/service-provider-documents/${id}`, true)
}

export const FetchMyRequirements = async (userId) => {
    return await GET(`/buyer-requirements/${userId}`, true)
}
export const RemoveRequirement = async (id) => {
    return await DELETE(`/buyer-requirements/${id}`, true)
}

export const GetRequirement = async (id) => {
    return await GET(`/buyer-requirements/requirement/${id}`, true)
}

export const AddRequirement = async (form) => {
    return await POST(`/buyer-requirements/`, form, true)
}

export const UpdateRequirement = async (id, body) => {
    return await PUT(`/buyer-requirements/${id}`, body, true)
}

export const GetRecommendation = async (id) => {
    return await GET(`/users/recommendation/${id}`, true)
}
