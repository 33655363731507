import React, { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

import {
    ProfileFormContent,
    ProfileFormActions,
    PageFormSpace,
    RowForm,
    ModalFormContent,
    Label,
    LabelLeft,
    UserProfileProgressActions,
    RadioButton,
    RadioButtonLabel,
    RadioHeader,
    RadioContent,
    RadioButtonCircle,
    IconInfo
} from './styled';

import Input from 'components/Form/Input';
import Button from 'components/Form/Button';
import ModalWrapper from '../Wrapper';
import { CoreContext } from 'context/CoreContext';

import { ReadCountries } from 'services/location';
import { CreateNewSupplierReg } from 'services/sms';
import DashboardButton from 'components/Dashboard/Button';
import { SearchNewSuppliers, SearchNoUserSuppliers, SearchEmailExist } from 'services/validationProcess';

export default function ModalAddSupplierInfo() {
    const [userFilter, setUserFilter] = useState(true);
    const { modal, setModal } = useContext(CoreContext);
    const { profile, user, findlanguage, language } = useContext(CoreContext);

    const [form, setForm] = useState({});
    const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
    const [serviceTypeNoUserOptions, setServiceTypeNoUserOptions] = useState([]);
    const [fieldValidation, setFieldValidation] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [country, setCountry] = useState('');
    const [accountType, setAccountType] = useState('');
    const [checked, setChecked] = useState(true);
    const [countrySearch, setCountrySearch] = useState('');
    const [countryList, setCountryList] = useState([]);
    const [segmentoSearch, setsegmentoSearch] = useState('');
    const [segmentoList, setsegmentoList] = useState([
        { name: findlanguage().perishables },
        { name: findlanguage().food },
        { name: findlanguage().nonFoodHlp },
        { name: findlanguage().nonFoodBazaar },
    ]);

    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [street, setStreet] = useState('');
    const [number, setNumber] = useState('');
    const [neighborhood, setNeighborhood] = useState('');
    const [city, setCity] = useState('');
    const [identification, setIdentification] = useState('');
    const [segmento, setsegmento] = useState('');
    const [employeesTotal, setEmployeesTotal] = useState('');
    const [employeesFactory, setEmployeesFactory] = useState('');
    const [employeesCommercial, setEmployeesCommercial] = useState('');
    const [employeesThirdParty, setEmployeesThirdParty] = useState('');
    const [qualityManagement, setQualityManagement] = useState(null);
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const result = await SearchNewSuppliers(user.company_id);
            setServiceTypeOptions(result);
        };
        fetchData();
    }, [user.company_id]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await SearchNoUserSuppliers();
            setServiceTypeNoUserOptions(result[0]);
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchCountries = async () => {
            const countries = await ReadCountries();
            setCountryList(
                language === 'portugues'
                    ? countries
                        .sort((a, b) => (a.name_pt > b.name_pt) - (a.name_pt < b.name_pt))
                        .map((item) => ({ name: item.name_pt, title: item.name_pt, iso2: item.iso2 }))
                    : countries
                        .sort((a, b) => (a.name > b.name) - (a.name < b.name))
                        .map((item) => ({ name: item.name, title: item.name, iso2: item.iso2 }))
            );
        };
        fetchCountries();
    }, [language]);

    const handleFormChange = (value, ref) => {
        if (typeof value === 'object') {
            value.name = value.title;
            setForm(value);
        } else if (!userFilter) {
            setForm({ ...form, [ref]: value });
        }
    };

    const showHighlight = (name) => fieldValidation.some((item) => item.field === name);

    const validateForm = () => {
        setFieldValidation([]);
        const errors = [];

        if (name.length === 0) {
            errors.push({ field: 'name', message: findlanguage().fillTheField + findlanguage().companyName });
        } else if (name.length < 2) {
            errors.push({ field: 'name', message: findlanguage().errorContactName });
        }

        if (email.length === 0) {
            errors.push({ field: 'email', message: findlanguage().fillTheField + findlanguage().email });
        } else if (!email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
            errors.push({ field: 'email', message: findlanguage().errorEmail });
        }

        if (phone.length === 0) {
            errors.push({ field: 'phone', message: findlanguage().fillTheField + findlanguage().phone });
        } else if (!/^\+(?:\d[-.\s]?){6,14}\d$/.test(phone)) {
            errors.push({ field: 'phone', message: findlanguage().errorPhone });
        }

        if (country.length === 0) {
            errors.push({ field: 'country', message: findlanguage().fillTheField + findlanguage().country });
        }

        if (state.length === 0) {
            errors.push({ field: 'state', message: findlanguage().fillTheField + 'Estado' });
        }

        if (zipCode.length === 0) {
            errors.push({ field: 'zipCode', message: findlanguage().fillTheField + 'CEP' });
        }

        if (street.length === 0) {
            errors.push({ field: 'street', message: findlanguage().fillTheField + 'Rua' });
        }

        if (city.length === 0) {
            errors.push({ field: 'city', message: findlanguage().fillTheField + 'Cidade' });
        }

        if (errors.length > 0) {
            let message = findlanguage().someErrorsFound;
            errors.forEach((item) => {
                message += `\n - ${item.message}`;
            });
            toast.error(message);
            setFieldValidation(errors);
            return false;
        }

        return true;
    };

    const handleRegister = async () => {
        if (!userFilter) {
            if (!validateForm()) return;

            const result = await SearchEmailExist(user.company_id, email);
            if (result[0].total === '0') {
                const supplierData = {
                    name,
                    email,
                    phone,
                    country,
                    type: accountType,
                    company_id: user.company_id,
                    isUser: false,
                    is_company_visible: checked,
                    role: accountType === 'supplier' ? 4 : 5,
                    state,
                    zipCode,
                    street,
                    number,
                    neighborhood,
                    city,
                    identification,
                    segmento,
                    employeesTotal,
                    employeesFactory,
                    employeesCommercial,
                    employeesThirdParty,
                    qualityManagement,
                    latitude,
                    longitude,
                };

                const response = await CreateNewSupplierReg(supplierData);

                if (!response || response?.error) {
                    toast.error(findlanguage().systemError)
                    return
                }

                if (response.message === undefined) {
                    modal.refresh()
                    setModal({
                        type: 'alert',
                        text: findlanguage().inviteSent,
                    })
                } else {
                    modal.refresh()
                    return setModal({
                        type: 'alert',
                        warning: true,
                        text: response.message,
                    })
                }

            } else {
                setModal({ type: 'alert', warning: true, text: findlanguage().thisEmailOrPhoneAlreadyExistsAlready });
            }
        } else {
            const result = await SearchEmailExist(user.company_id, form.email);
            if (result[0].total === '0') {
                if (userFilter && form.id === undefined) {
                    setModal({ type: 'alert', warning: true, text: findlanguage().nothingSelected });
                } else {
                    const refreshFunction = modal.refresh;
                    setModal({ type: 'loading', text: findlanguage().requestNewSupplier });
                    if (userFilter) form.users_permissions_user = form.id;

                    form.name = form.title;
                    form.isUser = userFilter;

                    const response = await CreateNewSupplierReg(form);

                    if (!response) {
                        return setModal({ type: 'alert', warning: true, text: 'Failed to create.' });
                    }

                    if (response.message === undefined) {
                        refreshFunction();
                        setModal({ type: 'alert', text: findlanguage().requestSuccessful });
                    } else {
                        setModal({ type: 'alert', warning: true, text: response.message });
                    }
                }
            } else {
                setModal({ type: 'alert', warning: true, text: findlanguage().thisEmailOrPhoneAlreadyExistsAlready });
            }
        }
    };

    const handleCountrySelect = (item) => setCountry(item.name);
    const clearCountry = () => setCountry('');
    const handlesegmentoSelect = (item) => setsegmento(item.name);
    const clearsegmento = () => setsegmento('');
    const handleAccountTypeChange = (e) => setAccountType(e.target.value);
    const toggleUserFilter = () => setUserFilter(!userFilter);
    const toggleVisibility = () => setChecked(!checked);

    const formatResult = (item) => (
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
    );

    const handleOnChange = () => {
        setChecked(!checked)
    }

    return (
        <ModalWrapper big={true}>
            <div style={{ marginTop: '10px' }}>
                <UserProfileProgressActions>
                    <DashboardButton icon={userFilter ? 'check' : ''} dark outline={!userFilter} onClick={toggleUserFilter}>
                        {findlanguage().registerUsers}
                    </DashboardButton>
                    <DashboardButton icon={userFilter ? '' : 'check'} dark outline={userFilter} onClick={toggleUserFilter}>
                        {findlanguage().nonRegisterUsers}
                    </DashboardButton>
                </UserProfileProgressActions>
            </div>
            {userFilter ? (
                <>
                    <Input
                        placeholder={findlanguage().name}
                        value={form?.title}
                        outline
                        onKeyUp={(e) => handleFormChange(e.target.value, 'title')}
                        onChange={(e) => handleFormChange(e.target.value, 'title')}
                        typeahead={serviceTypeOptions}
                    />
                    {form?.id !== undefined && (
                        <ProfileFormContent>
                            <RowForm>
                                <ModalFormContent>
                                    <Input big placeholder={findlanguage().address} disabled value={form?.street} />
                                </ModalFormContent>
                                <ModalFormContent>
                                    <Input placeholder={findlanguage().city} disabled value={form?.city} />
                                </ModalFormContent>
                            </RowForm>
                            <RowForm>
                                <ModalFormContent>
                                    <Input placeholder={findlanguage().state} disabled value={form?.state} />
                                </ModalFormContent>
                                <ModalFormContent>
                                    <Input placeholder={findlanguage().zip} disabled value={form?.zipCode} />
                                </ModalFormContent>
                            </RowForm>
                            <RowForm>
                                <ModalFormContent>
                                    <Input placeholder={findlanguage().country} disabled value={form?.country} />
                                </ModalFormContent>
                                <ModalFormContent>
                                    <Input placeholder={findlanguage().phone} disabled value={form?.phone} />
                                </ModalFormContent>
                            </RowForm>
                            <RowForm>
                                <ModalFormContent>
                                    <Input placeholder={findlanguage().email} disabled value={form?.email} />
                                </ModalFormContent>
                                <ModalFormContent></ModalFormContent>
                            </RowForm>
                        </ProfileFormContent>
                    )}
                </>
            ) : (
                <ProfileFormContent>
                    <RowForm>
                        <Label>{findlanguage().nonRegisterUsersLabel}</Label>
                    </RowForm>
                    <RowForm>
                        <ModalFormContent>
                            <Input
                                big
                                placeholder={findlanguage().companyName}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                highlight={showHighlight('name')}
                            />
                        </ModalFormContent>
                    </RowForm>
                    <RowForm>
                        <ModalFormContent>
                            <Input
                                placeholder={findlanguage().email}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                highlight={showHighlight('email')}
                            />
                        </ModalFormContent>
                        <ModalFormContent>
                            <Input
                                placeholder={findlanguage().phoneNumber}
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                highlight={showHighlight('phone')}
                            />
                        </ModalFormContent>
                    </RowForm>
                    <RowForm>
                        <ModalFormContent>
                            <div style={{ width: 500, marginBottom: 10 }}>
                                <label
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '15px',
                                        color: showHighlight('country') ? 'red' : '#000',
                                    }}
                                >
                                    {findlanguage().country}
                                </label>
                                <ReactSearchAutocomplete
                                    items={countryList}
                                    showNoResultsText={findlanguage().noResultsFilter}
                                    formatResult={formatResult}
                                    onSelect={handleCountrySelect}
                                    onSearch={clearCountry}
                                    onClear={clearCountry}
                                    onFocus={() => setCountrySearch(countrySearch.trim())}
                                    placeholder={findlanguage().placeholderFilter}
                                    inputSearchString={countrySearch}
                                    showIcon
                                    showItemsOnFocus
                                    maxResults={4}
                                    fuseOptions={{
                                        includeScore: true,
                                        includeMatches: true,
                                        threshold: 0.2,
                                        location: 0,
                                        distance: 100,
                                        maxPatternLength: 32,
                                        minMatchCharLength: 1,
                                        keys: ['name'],
                                    }}
                                    styling={{
                                        height: '35px',
                                        borderRadius: '0px',
                                        backgroundColor: '#FFF',
                                        boxShadow: 'none',
                                        hoverBackgroundColor: '#EAEAEA',
                                        color: '#263640',
                                        fontSize: '15px',
                                        iconColor: '#263640',
                                        lineColor: '#EAEAEA',
                                        placeholderColor: '#cccccc',
                                        clearIconMargin: '3px 8px 0 0',
                                        zIndex: 11,
                                    }}
                                />
                            </div>
                        </ModalFormContent>
                    </RowForm>
                    <RowForm>
                        <ModalFormContent>
                            <Input
                                placeholder={findlanguage().state}
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                                highlight={showHighlight('state')}
                            />
                        </ModalFormContent>
                        <ModalFormContent>
                            <Input
                                placeholder={findlanguage().zip}
                                value={zipCode}
                                onChange={(e) => setZipCode(e.target.value)}
                                highlight={showHighlight('zipCode')}
                            />
                        </ModalFormContent>
                    </RowForm>
                    <RowForm>
                        <ModalFormContent>
                            <Input
                                placeholder={findlanguage().address}
                                value={street}
                                onChange={(e) => setStreet(e.target.value)}
                                highlight={showHighlight('street')}
                            />
                        </ModalFormContent>
                        <ModalFormContent>
                            <Input
                                placeholder={findlanguage().city}
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                highlight={showHighlight('city')}
                            />
                        </ModalFormContent>
                    </RowForm>

                    <RowForm>
                        <ModalFormContent style={{ width: '250px', marginTop: '20px' }}>
                            <LabelLeft highlight={showHighlight('accountType')}>
                                {findlanguage().accountType}*
                            </LabelLeft>

                            <RadioHeader>
                                {profile === 'service-provider' ? (
                                    <>
                                        <RadioContent>
                                            <RadioButton
                                                type="radio"
                                                name="radio"
                                                value="buyer"
                                                onChange={(e) =>
                                                    setAccountType(e.target.value)
                                                }
                                                highlight={showHighlight('accountType')}
                                            ></RadioButton>
                                            <RadioButtonCircle></RadioButtonCircle>
                                        </RadioContent>
                                        <RadioButtonLabel>
                                            {findlanguage().buyerTitle}
                                        </RadioButtonLabel>
                                    </>
                                ) : (
                                    ''
                                )}

                                <RadioContent>
                                    <RadioButton
                                        type="radio"
                                        name="radio"
                                        value="supplier"
                                        onChange={(e) =>
                                            setAccountType(e.target.value)
                                        }
                                        highlight={showHighlight('accountType')}
                                    ></RadioButton>
                                    <RadioButtonCircle></RadioButtonCircle>
                                </RadioContent>
                                <RadioButtonLabel>
                                    {findlanguage().supplierTitle}
                                </RadioButtonLabel>
                                <RadioContent>
                                    <RadioButton
                                        type="radio"
                                        name="radio"
                                        value="service-provider"
                                        onChange={(e) => setAccountType(e.target.value)}
                                        highlight={showHighlight('accountType')}
                                    ></RadioButton>
                                    <RadioButtonCircle></RadioButtonCircle>
                                </RadioContent>
                                <RadioButtonLabel>
                                    {findlanguage().serviceProviderTitle}
                                </RadioButtonLabel>
                                <div className="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customSwitches"
                                        checked={checked}
                                        onChange={handleOnChange}
                                        style={{ cursor: 'pointer' }} // Inline style for cursor
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor="customSwitches"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {checked ? 'Visible' : 'Not Visible'}
                                        <IconInfo
                                            title={findlanguage().visibleIcon}
                                            style={{ width: '16px', marginLeft: '4px' }}
                                        />
                                    </label>
                                </div>
                            </RadioHeader>
                        </ModalFormContent>
                        </RowForm>
                        <br></br>
                    <RowForm>
                        <ModalFormContent>
                            <div style={{ width: 500, marginBottom: 10 }}>
                                <label
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '15px',
                                        color: showHighlight('segmento') ? 'red' : '#000',
                                    }}
                                >
                                    {findlanguage().segment}
                                </label>
                                <ReactSearchAutocomplete
                                    items={segmentoList}
                                    showNoResultsText={findlanguage().noResultsFilter}
                                    formatResult={formatResult}
                                    onSelect={handlesegmentoSelect}
                                    onSearch={clearsegmento}
                                    onClear={clearsegmento}
                                    onFocus={() => setsegmentoSearch(segmentoSearch.trim())}
                                    placeholder={findlanguage().placeholderFilter}
                                    inputSearchString={segmentoSearch}
                                    showIcon
                                    showItemsOnFocus
                                    maxResults={4}
                                    fuseOptions={{
                                        includeScore: true,
                                        includeMatches: true,
                                        threshold: 0.2,
                                        location: 0,
                                        distance: 100,
                                        maxPatternLength: 32,
                                        minMatchCharLength: 1,
                                        keys: ['name'],
                                    }}
                                    styling={{
                                        height: '35px',
                                        borderRadius: '0px',
                                        backgroundColor: '#FFF',
                                        boxShadow: 'none',
                                        hoverBackgroundColor: '#EAEAEA',
                                        color: '#263640',
                                        fontSize: '15px',
                                        iconColor: '#263640',
                                        lineColor: '#EAEAEA',
                                        placeholderColor: '#cccccc',
                                        clearIconMargin: '3px 8px 0 0',
                                        zIndex: 11,
                                    }}
                                />
                            </div>
                        </ModalFormContent>
                    </RowForm>
                    <RowForm>
                        <ModalFormContent>
                            <Input
                                placeholder={findlanguage().companyIdentification}
                                value={identification}
                                onChange={(e) => setIdentification(e.target.value)}
                                highlight={showHighlight('identification')}
                            />
                        </ModalFormContent>
                        <ModalFormContent>
                            <Input
                                type="number"
                                placeholder={findlanguage().numberEmployees}
                                value={employeesTotal}
                                onChange={(e) => setEmployeesTotal(e.target.value)}
                                highlight={showHighlight('employeesTotal')}
                            />
                        </ModalFormContent>
                    </RowForm>
                    <RowForm>
                        <ModalFormContent>
                            <LabelLeft>{findlanguage().qualityManagement}</LabelLeft>
                            <RadioHeader>
                                <RadioContent>
                                    <RadioButton
                                        type="radio"
                                        name="qualityManagement"
                                        value="sim"
                                        onChange={() => setQualityManagement(true)}
                                    />
                                    <RadioButtonCircle />
                                </RadioContent>
                                <RadioButtonLabel>{findlanguage().yes}</RadioButtonLabel>
                                <RadioContent>
                                    <RadioButton
                                        type="radio"
                                        name="qualityManagement"
                                        value="nao"
                                        onChange={() => setQualityManagement(false)}
                                    />
                                    <RadioButtonCircle />
                                </RadioContent>
                                <RadioButtonLabel>{findlanguage().no}</RadioButtonLabel>
                            </RadioHeader>
                        </ModalFormContent>
                    </RowForm>
                </ProfileFormContent>
            )}

            <PageFormSpace />
            <ProfileFormActions>
                <Button primary onClick={handleRegister}>
                    {findlanguage().addSupplier}
                </Button>
            </ProfileFormActions>
        </ModalWrapper>
    );
}
