import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CoreContext } from 'context/CoreContext'

const Modal = ({ onClose }) => {
    const { user } = useContext(CoreContext)
    const history = useHistory();

    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                
                <h2>Congratulations!</h2>
                <p>You've received</p>
                <h2>30-days</h2>
                <h2>Premium trial!</h2>
                <br></br>
                <div onClick={onClose} className="divButton" style={{ width: '160px' }} >Close</div>
            </div>
        </div>
    );
};

export default Modal;
