import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'

import ModalAdd from '../Add'
import ModalChat from '../Chat'
import ModalAlert from '../Alert'
import ModalDenied from '../Denied'
import ModalContactSales from '../ContactSales'
import ModalTrialEnd from '../TrialEnd'
import ModalPlanSelected from '../PlanSelected'

import ModalSupportCaseInfo from '../SupportCaseInfo'

import ModalChoose from '../Choose'
import ModalTerms from '../Terms'
import ModalCompanyInformation from '../CompanyInformation'
import ModalMyUserInfo from '../MyUserInfo'

import ModalAddRequirement from '../AddRequirement'
import ModalAddCard from '../AddCard'
import ModalCancelSubscription from '../CancelSubscription'
import ModalNewMessage from '../NewMessage'
import ModalViews from '../Views'
import ModalFilterBy from '../FilterBy'
import ModalMyWidget from '../MyWidgets'
// import ModalAddDocument from '../AddDocument'
import ModalAddServiceProviderDocument from '../AddServiceProviderDocument'

// import ModalApprovalDocument from '../ApprovalDocument'
import ModalCommentDecline from '../CommentDecline'
import ModalAddSupplierInfo from '../AddSupplierInfo'
import ModalAddServiceProvider from '../AddServiceProvider'
import ModalSubmitMfs from '../SubmitMfs'
import ModalAddDocumentSMS from '../AddDocumentSMS'
import ModalReviewSupplier from '../ReviewSupplier'
import ModalReviewDocument from '../ReviewDocument'
import ModalReviewDateForSupplier from '../ReviewDateForSupplier'
import ModalFilterProduct from '../FilterProduct'
import ModalFilterScraping from '../FilterScraping'
import ModalFilterServiceProvider from '../FilterServiceProvider'
import ModalApproved from '../Approved'
import ModalAddProduct from '../AddProduct'
import ModalAddCertificate from '../AddCertificate'

import ModalScrapingDetail from '../ScrapingDetail'
import ModalTeamAccess from '../TeamAccess'
import ModalTeamAccessServiceProvider from '../TeamAccessServiceProvider'
import ModalLoading from '../Loading'
// import ModalRequestDocument from '../RequestDocument'

export default function ModalCore() {
    const { modal } = useContext(CoreContext)

    return (
        <>
            {modal?.type === 'add' ? <ModalAdd /> : null}
            {modal?.type === 'chat' ? <ModalChat /> : null}
            {modal?.type === 'choose' ? <ModalChoose /> : null}
            {modal?.type === 'terms' ? <ModalTerms /> : null}
            {modal?.type === 'company-information' ? <ModalCompanyInformation /> : null}
            {modal?.type === 'company-my-information' ? <ModalMyUserInfo /> : null}
            {modal?.type === 'add-requirement' ? <ModalAddRequirement /> : null}
            {modal?.type === 'add-card' ? <ModalAddCard /> : null}
            {modal?.type === 'add-product' ? <ModalAddProduct /> : null}
            {modal?.type === 'add-certificate' ? <ModalAddCertificate /> : null}

            {modal?.type === 'cancel-subscription' ? <ModalCancelSubscription /> : null}
            {modal?.type === 'new-message' ? <ModalNewMessage /> : null}
            {modal?.type === 'views' ? <ModalViews /> : null}
            {modal?.type === 'filter-by' ? <ModalFilterBy /> : null}
            {modal?.type === 'widgets' ? <ModalMyWidget /> : null}
            {modal?.type === 'team-access' ? <ModalTeamAccess /> : null}
            {modal?.type === 'team-access-service-provider' ? (
                <ModalTeamAccessServiceProvider />
            ) : null}

            {/* {modal?.type === 'add-document' ? <ModalAddDocument /> : null} */}
            {modal?.type === 'add-service-provider-document' ? (
                <ModalAddServiceProviderDocument />
            ) : null}

            {/* {modal?.type === 'request-document' ? <ModalRequestDocument /> : null} */}
            {/* {modal?.type === 'approval-document' ? <ModalApprovalDocument /> : null} */}
            {modal?.type === 'comment-decline' ? <ModalCommentDecline /> : null}
            {modal?.type === 'add-supplier' ? <ModalAddSupplierInfo /> : null}
            {modal?.type === 'add-service-provider' ? <ModalAddServiceProvider /> : null}
            {modal?.type === 'submit-mfs' ? <ModalSubmitMfs /> : null}
            {modal?.type === 'add-documentSMS' ? <ModalAddDocumentSMS /> : null}
            {modal?.type === 'review-supplier' ? <ModalReviewSupplier /> : null}
            {modal?.type === 'review-document' ? <ModalReviewDocument /> : null}
            {modal?.type === 'review-date-for-supplier' ? <ModalReviewDateForSupplier /> : null}
            {modal?.type === 'filter-product' ? <ModalFilterProduct /> : null}
            {modal?.type === 'filter-service-provider' ? <ModalFilterServiceProvider /> : null}
            {modal?.type === 'approved' ? <ModalApproved /> : null}

            {modal?.type === 'alert' ? <ModalAlert /> : null}

            {modal?.type === 'denied' ? <ModalDenied offer={modal?.offer} /> : null}
            {modal?.type === 'contact-sales' ? <ModalContactSales /> : null}
            {modal?.type === 'trial-ended' ? <ModalTrialEnd /> : null}

            {modal?.type === 'plan-selected' ? <ModalPlanSelected /> : null}

            {modal?.type === 'support-case-login' ? <ModalSupportCaseInfo /> : null}

            {modal?.type === 'filter-scraping' ? <ModalFilterScraping /> : null}
            {modal?.type === 'scraping' ? <ModalScrapingDetail /> : null}
            {modal?.type === 'loading' ? <ModalLoading /> : null}
        </>
    )
}
