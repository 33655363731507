import styled from 'styled-components'

export const PageCard = styled.div.attrs({})`
    background: var(--white-color);
    padding: 24px;
    margin-top: 28px;
    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
`

export const FilterAction = styled.div.attrs({})`
    margin-left: 15px;
    display: flex;
    aling-items: center;
    padding: 12px 17px 11px 18px;
    border-radius: 30px;

    background: var(--primary-color);
    border: 1px solid var(--primary-color);

    cursor: pointer;
    &:hover {
        box-shadow: 0px 1.5px 6px var(--shadow-color);
    }

    ${(props) =>
        props.outline
            ? `
            background: var(--background-color);
        `
            : ``}
`

export const FilterActionText = styled.div.attrs({})`
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;

    color: var(--white-color);
    ${(props) =>
        props.outline
            ? `
            color: var(--primary-color);
        `
            : ``}
`
export const FilterActionBadge = styled.div.attrs({})`
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--white-color);
    color: var(--primary-color);

    ${(props) =>
        props.outline
            ? `
            background: var(--primary-color);
            color: var(--white-color);
        `
            : ``}
`
export const ContentFilters = styled.div.attrs({})`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const FilterContent = styled.div.attrs({})`
    display: flex;
    align-items: center;
`