import React, { useContext, useState, useEffect } from 'react';
import { PageCard, CardLineContainer, CardHeader } from './styled';
import Elements from '../SharedComponent/Elements/index';
import { CoreContext } from 'context/CoreContext';
import { GetAssignedStepFormOwner } from 'services/steps';
import _ from 'lodash';
import GridSections from './GridSections/index';

export default function Form() {
    const { findlanguage } = useContext(CoreContext);
    const [editingForm, setEditingForm] = useState(null);
    const [assignedForms, setAssignedForms] = useState([]);

    const groupedForms = _.groupBy(assignedForms, 'company_id.name');

    const getForm = async () => {
        const data = await GetAssignedStepFormOwner();
        data.forEach((x) => {
            x.expanded = false;
            x.sectionExpanded = false;
        });
        setAssignedForms(data);
    };

    const openCloseForm = (formId) => {
        setAssignedForms(prevForms =>
            prevForms.map(form =>
                form.id === formId ? { ...form, expanded: !form.expanded } : form
            )
        );
    };

    const openCloseSection = (formId) => {
        setAssignedForms(prevForms =>
            prevForms.map(form =>
                form.id === formId ? { ...form, sectionExpanded: !form.sectionExpanded } : form
            )
        );
    };

    const getButtonLabel = (form) => {
        if (form.status === 'complete') {
            return 'Complete';
        }
        if (form.status === 'open') {
            return 'View';
        }
        if (form.status === 'pending_approval') {
            return 'Pending Approval';
        }
        if (form.status === 'rejected') {
            return 'Rejected';
        }
        return 'View';
    };

    const getButtonBackgroundColor = (form) => {
        if (form.status === 'complete') {
            return '#8dbd64';
        }
        if (form.status === 'open') {
            return '#516d7f';
        }
        if (form.status === 'pending_approval') {
            return '#dc3545';
        }
        if (form.status === 'rejected') {
            return '#dc3545';
        }
        return '#ffcc00';
    };

    const handleEdit = (form, stepId) => {
        setEditingForm({
            ...form,
            specificStep: stepId || null
        });
    };

    const handleBack = () => {
        setEditingForm(null);
    };

    useEffect(() => {
        getForm();
    }, []);

    return (
        <>
            {editingForm ? (
                <Elements handleBack={handleBack} form={editingForm} getForm={getForm} isOwner={true} />
            ) : (
                <>
                    {assignedForms?.length === 0 ? (
                        <div
                            style={{
                                fontSize: '16px',
                                fontWeight: 'bolder',
                                color: 'black',
                                cursor: 'pointer',
                                marginTop: '60px',
                            }}
                        >
                            Nothing to do for now.
                        </div>
                    ) : (
                        Object.keys(groupedForms).map(company => (
                            <PageCard key={company} style={{ marginBottom: '20px' }}>
                                <CardLineContainer>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div
                                            style={{
                                                fontSize: '30px',
                                                fontWeight: 'bolder',
                                                color: 'black',
                                                cursor: 'pointer',
                                                marginLeft: '10px',
                                                width: '50px'
                                            }}
                                            onClick={() => openCloseForm(groupedForms[company][0].id)}
                                        >
                                            {groupedForms[company][0]?.expanded ? '-' : '+'}
                                        </div>
                                        <CardHeader>{company}</CardHeader>
                                    </div>
                                </CardLineContainer>
                                <div style={{ display: groupedForms[company][0]?.expanded ? '' : 'none', overflow: 'auto' }}>
                                    <div style={{ position: 'relative', overflow: 'auto', maxHeight: '800px' }}>
                                        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
                                            <thead style={{ position: 'sticky', top: 0, backgroundColor: '#4f6b7d', color: 'white', zIndex: 1 }}>
                                                <tr>
                                                    <th style={{ padding: '10px', textAlign: 'left' }}>Expand</th>
                                                    <th style={{ padding: '10px', textAlign: 'left' }}>Action</th>
                                                    <th style={{ padding: '10px', textAlign: 'left' }}>Type of Requirements</th>
                                                    <th style={{ padding: '10px', textAlign: 'left' }}>Form Name</th>
                                                    <th style={{ padding: '10px', textAlign: 'left' }}>Status</th>
                                                    <th style={{ padding: '10px', textAlign: 'left' }}>Expiration Date</th>
                                                    <th style={{ padding: '10px', textAlign: 'left' }}>Current Responsible</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ borderTop: '2px solid #fff' }}>
                                                {groupedForms[company].map(form => (
                                                    <React.Fragment key={form.id}>
                                                        <tr style={{ borderBottom: '1px solid #ccc' }}>
                                                            <td style={{ padding: '10px', cursor: 'pointer' }} onClick={() => openCloseSection(form.id)}>
                                                                {form.sectionExpanded ? '-' : '+'}
                                                            </td>
                                                            <td style={{ padding: '10px' }}>
                                                                <span
                                                                    className="badge"
                                                                    style={{
                                                                        fontSize: '13px',
                                                                        cursor: 'pointer',
                                                                        backgroundColor: getButtonBackgroundColor(form),
                                                                        color: 'white'
                                                                    }}
                                                                    onClick={() => handleEdit(form)}
                                                                >
                                                                    {getButtonLabel(form)}
                                                                </span>
                                                            </td>
                                                            <td style={{ padding: '10px' }}>{form.step_form_id.form_type}</td>
                                                            <td style={{ padding: '10px' }}>{form.step_form_id.name}</td>
                                                            <td style={{ padding: '10px' }}>
                                                                <div
                                                                    style={{
                                                                        backgroundColor: getButtonBackgroundColor(form),
                                                                        width: '20px',
                                                                        height: '20px',
                                                                        borderRadius: '50%',
                                                                        margin: '0 auto',
                                                                        float: 'left'
                                                                    }}></div>
                                                            </td>
                                                            <td style={{ padding: '10px' }}>{form.expired_date}</td>
                                                            <td style={{ padding: '10px' }}>
                                                                {
                                                                    form.step_form_id.sections.filter(item => item.step_section_id.id === 71)
                                                                        .map(item => { return <>{item.user.name} <br /></> })
                                                                       
                                                                }
                                                            </td>
                                                        </tr>
                                                        {form.sectionExpanded && (
                                                            <tr className="section-row">
                                                                <td colSpan="7" className="section-container">
                                                                    <GridSections sections={form.step_form_id.sections} currentStep={form.current_step} handleEdit={handleEdit} form={form} />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </PageCard>
                        ))
                    )}
                </>
            )}
        </>
    );
}

