import styled from 'styled-components'

export const ProfileFormContent = styled.div.attrs({})`
    width: 100%;
    padding: 15px 0 20px;
`

export const ProfileFormActions = styled.div.attrs({})`
    display: flex;
    justify-content: center;
`
export const PageFormSpace = styled.div.attrs({})`
    margin-right: 12px;
`

export const RowForm = styled.div.attrs({})`
    display: flex;
    gap: 0 20px;
`
export const ModalFormContent = styled.div.attrs({})`
    flex: 1;
`
export const ModalFormLabel = styled.div.attrs({})`
    margin-bottom: -28px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--primary-color);
    ${(props) =>
        props.spaced
            ? `
            margin-bottom: 15px;
        `
            : ``}
`

export const UserProfileProgressActions = styled.div.attrs({})`
    display: flex;
    padding: 0px 0 20px;
    margin-top: -10px;
`