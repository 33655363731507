import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'

import { SuccessIcon, SuccessText } from './styled'

import Button from 'components/Form/Button'
import Input from 'components/Form/Input'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

export default function ModalAdd() {
    const { modal, setModal, findlanguage } = useContext(CoreContext)
    const [successable, setSuccessable] = useState(false)
    const [value, setValue] = useState('')

    const valid = (verbose = false) => {
        if (!value || !value.length) {
            if (verbose) {
                toast.error('Fill the field')
            }
            return false
        }
        return true
    }

    const action = () => {
        if (!valid(true)) {
            return
        }
        if (modal?.action && typeof modal?.action === 'function') {
            modal.action(value)

            if (modal.successable) {
                setSuccessable(modal.successable)
            } else {
                close()
            }
        }
    }

    const close = () => {
        setModal(null)
    }

    return (
        <>
            <ModalWrapper successable={successable}>
                {successable ? (
                    <>
                        <SuccessIcon />
                        <SuccessText>{successable}</SuccessText>
                        <Button small primary onClick={close}>
                            Ok
                        </Button>
                    </>
                ) : (
                    <>
                        <Input
                            placelholder=""
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                        <Button small primary onClick={action}>
                            {findlanguage().add}
                        </Button>
                    </>
                )}
            </ModalWrapper>
        </>
    )
}
