import React, { useContext, useState, useRef, useEffect } from 'react'
import { toast } from 'react-toastify'

import {
    ProfileFormContent,
    ProfileFormActions,
    InputBlankContainer,
    InputSpace,
    CheckContainer,
} from './styled'

import { Popover, PopoverHeader, PopoverBody } from 'reactstrap'

import Input from 'components/Form/Input'
import Button from 'components/Form/Button'
import Check from 'components/Form/Check'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'
import { UpdateMyUser, GetMe } from 'services/authentication'
import { exposeStrapiErrorNew } from 'utils'

export default function ModalMyUserInfo() {
    const { modal, setModal, user, setUser, profile, findlanguage } = useContext(CoreContext)
    const [fieldValidation, setFieldValidation] = useState([])
    const [stateTooltipPhone, setStateTooltipPhone] = useState(false)
    const [init, setInit] = useState(false)

    const [contactName, setContactName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [department, setDepartment] = useState('')

    // Init Loading
    useEffect(() => {
        if (!init) {
            setInit(true)
        }

        setContactName(user.contactName)
        setPhone(user.phone)
        setEmail(user.email)
        setDepartment(user.department === null ? '' : user.department)

        return true
    }, [init])

    const close = () => {
        setModal({
            type: 'alert',
            text: findlanguage().changesSaved,
        })
    }

    const [loading, setLoading] = useState(false)

    const buildForm = () => {
        return {
            contactName: contactName,
            email: email,
            phone: phone,
            department: department,
        }
    }

    const action = async () => {
        if (!valid()) {
            return
        }

        setLoading(true)

        const _form = buildForm()

        const result = await UpdateMyUser(_form)
        const loggedUserData = await GetMe()

        setLoading(false)

        if (result && exposeStrapiErrorNew(result)) {
            result.user = loggedUserData
            completeUpdate(result)
        }
    }

    const completeUpdate = (result) => {
        if (result?.user) {
            setUser(result.user)
        }
        toast.success('Account updated')
        close()
    }

    const showHighlight = (name) => {
        const field = fieldValidation.find((item) => item.field === name)
        if (field !== undefined) return true
        return false
    }

    const handleInputPhone = (event) => {
        setPhone(event.target.value)
        togglePhone(false)
    }

    const togglePhone = (_state) => {
        if (typeof _state !== 'boolean') return
        setStateTooltipPhone(_state)
    }

    const valid = () => {
        setFieldValidation([])
        let errors = []

        if (contactName.length === 0) {
            errors.push({ field: 'contactName', message: 'Fill the field: Contact Name' })
        } else if (contactName.length < 2) {
            errors.push({
                field: 'contactName',
                message: 'Contact Name must contain at least 2 characters',
            })
        }

        if (email.length === 0) {
            errors.push({ field: 'email', message: 'Fill the field: Email' })
        } else if (
            !email.match(
                /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
            )
        ) {
            errors.push({ field: 'email', message: 'Your e-mail is invalid' })
        }

        if (phone.length === 0) {
            errors.push({ field: 'phone', message: 'Fill the field: Phone' })
        } else if (!/^\+(?:\d[-.\s]?){6,14}\d$/.test(phone)) {
            errors.push({
                field: 'phone',
                message: findlanguage().errorPhone,
            })
        }

        if (errors.length > 0) {
            let message = ''
            message = 'Some errors was found: '
            errors.forEach((item) => {
                message += '\n - ' + item.message
            })
            toast.error(message)
            setFieldValidation(errors)
            return false
        }

        return true
    }

    return (
        <>
            <ModalWrapper>
                <ProfileFormContent>
                    <Input
                        placeholder={findlanguage().name}
                        value={contactName}
                        onChange={(e) => setContactName(e.target.value)}
                        highlight={showHighlight('name')}
                    />

                    <Input
                        placeholder={findlanguage().phoneNumber}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        onBlur={handleInputPhone}
                        onFocus={(e) => togglePhone(true)}
                        id="phone1"
                        type={'tel'}
                        autoComplete={'xoff'}
                        highlight={showHighlight('phone')}
                    />
                    <Popover
                        placement="top"
                        isOpen={stateTooltipPhone}
                        toggle={togglePhone}
                        target={'phone1'}
                    >
                        <PopoverHeader>{findlanguage().rulesPhoneNumberHeader}</PopoverHeader>
                        <PopoverBody>{findlanguage().rulesPhoneNumberText}</PopoverBody>
                    </Popover>

                    <Input
                        placeholder={findlanguage().email}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        highlight={showHighlight('email')}
                    />

                    <Input
                        placeholder="Department"
                        value={department}
                        onChange={(e) => setDepartment(e.target.value)}
                        highlight={showHighlight('department')}
                    />
                </ProfileFormContent>
                <ProfileFormActions>
                    <Button primary onClick={action} loading={loading}>
                        {findlanguage().updateInformation}
                    </Button>
                </ProfileFormActions>
            </ModalWrapper>
        </>
    )
}
