import styled from 'styled-components'

export const PageHeader = styled.div.attrs({})`
    display: flex;
    width: 100%;
    margin: 20px 0 0;
    padding: 0 16px;
`

export const PageHeaderContent = styled.div.attrs({})`
    flex: 1;
    ${(props) =>
        props.row
            ? `
            display: flex;
            justify-content: center;
        `
            : `
            display: flex;
            flex-direction: column;
            width: 100%;
        `}
`
export const ContentTitle = styled.div.attrs({})`
    font-size: 22px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: #6c6c6c;
`
export const ContentSubtitle = styled.div.attrs({})`
    margin: 9px 0;
    font-size: 16px;
    color: #6c6c6c;
`
