import React from 'react'

import {
    PageCardCard,
    CardBrand,
    CardRowMumbers,
    CardNumbers,
    CardDecoration,
    CardRowInfos,
    CardName,
    CardExpiresAt,
    CardCCV,
} from './styled'

export default function DashboardCreditCardLabel({ number, name, expires_at, cvv }) {
    return (
        <>
            <PageCardCard>
                <CardBrand />
                <CardRowMumbers>
                    <CardNumbers>{number}</CardNumbers>
                    <CardDecoration />
                </CardRowMumbers>
                <CardRowInfos>
                    <CardName>{name}</CardName>
                    <CardExpiresAt>{expires_at}</CardExpiresAt>
                    <CardCCV>{cvv}</CardCCV>
                </CardRowInfos>
            </PageCardCard>
        </>
    )
}
