import React, { useState, useEffect, useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import { PageCard } from '../../../Panels/SMSControl/styled'
import { GetPrecheck, PostPrecheck, PutPrecheck } from 'services/precheck'
import { UpdatePrecheck } from 'services/api'
import { NotificationManagerAdd } from 'services/admin'
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap'

import { TitleGreen } from './styled'
import Select from 'react-select'

const labelCss = { fontWeight: 'bold', fontSize: '15px', color: 'rgb(86, 86, 86)' }
const labelTextCss = { fontSize: '14px', color: 'rgb(86, 86, 86)' }
const fixRow = { marginTop: '-22px' }
const inputCss = { borderColor: '#70707059' }
const col6 = { fontSize: '700', color: '#707070' }
const spacer = { borderTop: '0.1px solid rgb(235 235 235)' }
const doneTypingInterval = 2000

let typingTimer

export default function Precheck({ setScreen, setBasic }) {
    const { user, findlanguage, setModal, language, permissions } = useContext(CoreContext)
    const [form, setForm] = useState(false)
    const [basicInformation, setBasicInformation] = useState({
        id: -1,
        company_legal_name: '',
        company_address: '',
        company_phone_number: '',
        active_company_proof: '',
        active_company_proof_file: {},
        business_license: '',
        business_license_file: {},
        sanitary_license: '',
        sanitary_license_file: {},
        is_company_certified: false,
        what_standards: '',
        what_standards_file: {},
        suppliers_count: '',
        has_multiple_factories: '',
        factory_count: '',
        document_control: '',
        supplier_control: '',
        supplier_management: '',
        platform_name: '',
        avg_documents: '',
        find_suppliers: [],
        sustainability_practice: '',
        full_name: '',
        email: '',
        direct_phone_number: '',
        reason_denied: '',
        reason_denied_message: '',
    })

    const [validation, setValidation] = useState({
        company_legal_name: true,
        company_address: true,
        company_phone_number: true,
        invalid_company_phone_number: true,
        active_company_proof: true,
        business_license: true,
        sanitary_license: true,
        suppliers_count: true,
        supplier_management: true,
        has_multiple_factories: true,
        document_control: true,
        supplier_control: true,
        sustainability_practice: true,
        full_name: true,
        email: true,
        direct_phone_number: true,
        invalid_direct_phone_number: true,
    })

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        const getAdditionalInformation = await GetPrecheck(user.company_id)

        if (
            getAdditionalInformation[0]?.find_supplier !== undefined &&
            getAdditionalInformation[0]?.find_supplier !== null
        )
            getAdditionalInformation[0].find_suppliers = JSON.parse(
                getAdditionalInformation[0]?.find_suppliers
            )?.map((value) => ({ label: value, value }))

        if (
            getAdditionalInformation.statusText !== 'Not Found' &&
            getAdditionalInformation?.length !== 0
        ) {
            if (
                getAdditionalInformation[0].status === 'submitted' ||
                getAdditionalInformation[0].status === 'approved'
            ) {
                setForm(true)
            }

            setBasicInformation(getAdditionalInformation[0])
        } else {
            setBasicInformation(await PostPrecheck({ owner: user.company_id, status: 'open' }))
        }

        if (!permissions?.myProcessOverview?.canEdit) {
            setForm(true)
        }
    }

    const changeQuestionaire = (value, field) => {
        setBasicInformation({
            ...basicInformation,
            [field]: value,
        })

        if (validation.hasOwnProperty(field)) {
            if (field === 'company_phone_number') {
                setValidation((prev) => ({
                    ...prev,
                    ['invalid_company_phone_number']: /^\+(?:\d[-.\s]?){6,14}\d$/.test(
                        basicInformation.company_phone_number
                    ),
                }))
            }

            if (field === 'direct_phone_number') {
                setValidation((prev) => ({
                    ...prev,
                    ['invalid_direct_phone_number']: /^\+(?:\d[-.\s]?){6,14}\d$/.test(
                        basicInformation.direct_phone_number
                    ),
                }))
            }

            setValidation((prev) => ({
                ...prev,
                [field]: value?.toString()?.trim() !== '',
            }))
        }
    }

    useEffect(() => {
        waitUserFinishTyping()
    }, [basicInformation])

    const waitUserFinishTyping = () => {
        clearTimeout(typingTimer)
        typingTimer = setTimeout(saveAdditionalnformation, doneTypingInterval)
    }

    const saveAdditionalnformation = async () => {
        if (basicInformation.id !== -1)
            await PutPrecheck({
                ...basicInformation,
                find_suppliers:
                    typeof basicInformation?.find_suppliers === 'string'
                        ? basicInformation?.find_suppliers
                        : JSON.stringify(
                              basicInformation?.find_suppliers?.map((item) => item.value)
                          ),
            })
    }

    const saveDocument = async (fileName) => {
        await PutPrecheck({
            ...basicInformation,
            find_suppliers:
                typeof basicInformation?.find_suppliers === 'string'
                    ? basicInformation?.find_suppliers
                    : JSON.stringify(basicInformation?.find_suppliers?.map((item) => item.value)),
            [fileName]: null,
        })

        const formData = new FormData()

        if (document.getElementById(fileName)?.files?.[0]) {
            new Promise((resolve, reject) => {
                let reader = new FileReader()
                reader.onload = async () => {
                    let source = `${reader.result}`.split(',')[1]
                    let image = {
                        filename: document.getElementById(fileName)?.files?.[0].name,
                        filetype: document.getElementById(fileName)?.files?.[0].type,
                        source,
                    }

                    let btc = window.atob(image.source)
                    let btn = new Array(btc.length)
                    for (var i = 0; i < btc.length; i++) {
                        btn[i] = btc.charCodeAt(i)
                    }
                    var bta = new Uint8Array(btn)
                    let boobs = new Blob([bta], { type: 'image/png' })
                    let filesss = new File([boobs], `${image.filename}`)

                    formData.append(fileName, filesss, filesss.name)
                    formData.append('id', basicInformation.id)
                    formData.append('filename', fileName)

                    await UpdatePrecheck(formData)

                    await init()
                }
                reader.readAsDataURL(document.getElementById(fileName)?.files?.[0])
            })
        }
    }

    const saveMultipleDocuments = async (fileName) => {
        const fileInput = document.getElementById(fileName)
        if (fileInput?.files) {
            for (const file of fileInput.files) {
                const formData = new FormData() // Moved inside the loop
                await new Promise((resolve, reject) => {
                    let reader = new FileReader()
                    reader.onload = async () => {
                        let source = `${reader.result}`.split(',')[1]
                        let image = {
                            filename: file.name,
                            filetype: file.type,
                            source,
                        }

                        let btc = window.atob(image.source)
                        let btn = new Array(btc.length)
                        for (let i = 0; i < btc.length; i++) {
                            btn[i] = btc.charCodeAt(i)
                        }
                        let bta = new Uint8Array(btn)
                        let blob = new Blob([bta], { type: 'image/png' })
                        let newFile = new File([blob], `${image.filename}`)

                        formData.append(fileName, newFile, newFile.name)
                        formData.append('id', basicInformation.id)
                        formData.append('filename', fileName)

                        await UpdatePrecheck(formData)

                        resolve()
                    }
                    reader.readAsDataURL(file)
                })
            }

            await init()
        }
    }

    const removeFile = (id) => {
        setBasicInformation({
            ...basicInformation,
            what_standards_file: basicInformation?.what_standards_file?.filter((x) => x.id !== id),
        })
    }

    const submitQuestionarie = async () => {
        setBasicInformation({
            ...basicInformation,
            status: 'submitted',
        })

        await PutPrecheck({
            ...basicInformation,
            find_suppliers:
                typeof basicInformation?.find_suppliers === 'string'
                    ? basicInformation?.find_suppliers
                    : JSON.stringify(basicInformation?.find_suppliers?.map((item) => item.value)),
            status: 'submitted',
        })
        setForm(true)

        await NotificationManagerAdd('mfs_submitted', user.company_id, user.name, user, 'full')

        setBasic({
            id: -1,
            status: 'submitted',
        })

        return setModal({
            type: 'alert',
            text: 'MyTS form submitted.',
            warning: true,
        })
    }

    const submitPrecheck = () => {
        const newValidation = { ...validation }

        for (const field in basicInformation) {
            if (validation.hasOwnProperty(field)) {
                newValidation[field] =
                    basicInformation[field] !== null &&
                    basicInformation[field] !== undefined &&
                    basicInformation[field]?.toString()?.trim() !== ''
            }
        }

        if (newValidation.supplier_control === true) {
            newValidation.supplier_management = true
        }

        setValidation(newValidation)

        if (newValidation.supplier_control === true) {
            newValidation.supplier_management = true
        }

        if (Object.values(newValidation).every(Boolean)) {
            setModal({
                type: 'submit-mfs',
                title: 'Submit MyTS',
                submitQuestionarie: submitQuestionarie,
            })
        }
    }

    return (
        <PageCard>
            {basicInformation?.status === 'decline' ? (
                <div className="alert alert-danger alert-dismissible">
                    <h5>
                        <i className="icon fas fa-ban"></i> {basicInformation.reason_denied}
                    </h5>
                    {basicInformation.reason_denied_message}
                </div>
            ) : null}
            <TitleGreen>
                {findlanguage().basicPreCheck}
                {permissions?.myProcessOverview?.canEdit ? (
                    <div style={{ float: 'right', marginTop: '-27px', position: 'sticky' }}>
                        <button
                            className="btn btn-secondary"
                            style={{ marginRight: '5px', marginTop: '15px' }}
                            onClick={() => setScreen(1)}
                        >
                            {findlanguage().goBack}
                        </button>
                        {form ? null : (
                            <button
                                disabled={form}
                                className="btn"
                                onClick={submitPrecheck}
                                style={{
                                    marginRight: '5px',
                                    marginTop: '15px',
                                    backgroundColor: '#263640',
                                    color: 'white',
                                }}
                            >
                                {findlanguage().submit}
                            </button>
                        )}
                    </div>
                ) : null}
            </TitleGreen>
            <div className="row  mb-3" style={{ borderBottom: '1px solid #7070700f' }}>
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().companyLegalName}*
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        style={{
                            ...inputCss,
                            borderColor: !validation.company_legal_name ? 'red' : 'initial',
                        }}
                        value={basicInformation.company_legal_name}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'company_legal_name')
                        }
                        disabled={form}
                        required
                    />
                    {!validation.company_legal_name && (
                        <div className="text-danger">Field required</div>
                    )}
                </div>
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().companyAddress}*
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        style={{
                            ...inputCss,
                            borderColor: !validation.company_address ? 'red' : 'initial',
                        }}
                        value={basicInformation.company_address}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'company_address')
                        }
                        disabled={form}
                        required
                    />
                    {!validation.company_address && (
                        <div className="text-danger">Field required</div>
                    )}
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-6 col-md-4" style={col6}>
                    <label className="form-label" style={labelCss} htmlFor="company_phone_number">
                        {findlanguage().companyPhoneNumber}*
                    </label>
                    <input
                        id="company_phone_number"
                        type="text"
                        className="form-control"
                        style={{
                            ...inputCss,
                            borderColor:
                                !validation.company_phone_number ||
                                !validation.invalid_company_phone_number
                                    ? 'red'
                                    : 'initial',
                        }}
                        value={basicInformation.company_phone_number}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'company_phone_number')
                        }
                        disabled={form}
                        required
                    />
                    {!validation.company_phone_number && (
                        <div className="text-danger">Field required</div>
                    )}
                    {!validation.invalid_company_phone_number && (
                        <div className="text-danger">Phone number is invalid</div>
                    )}
                    <UncontrolledPopover
                        placement="top"
                        target={'company_phone_number'}
                        trigger="focus"
                    >
                        <PopoverHeader>{findlanguage().rulesPhoneNumberHeader}</PopoverHeader>
                        <PopoverBody>{findlanguage().rulesPhoneNumberText}</PopoverBody>
                    </UncontrolledPopover>
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().activeCompanyProof}
                    </label>
                    <br />
                    <label className="form-label" style={labelTextCss}>
                        {findlanguage().proofCompanyActiveRequirement}
                    </label>
                </div>
            </div>
            <div className="row mb-3" style={fixRow}>
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelTextCss}></label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={basicInformation.active_company_proof}
                        style={{
                            borderColor: !validation.active_company_proof ? 'red' : 'initial',
                        }}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'active_company_proof')
                        }
                        disabled={form}
                    >
                        {language === 'portugues' ? (
                            <>
                                <option value=""></option>
                                <option value="CNPJ">CNPJ</option>
                                <option value="CPF">CPF</option>
                            </>
                        ) : (
                            <>
                                <option value=""></option>
                                <option value="EIN Number">EIN Number</option>
                                <option value="Tax ID">Tax ID</option>
                                <option value="DUNS Number">DUNS Number</option>
                                <option value="Driver License Number">Driver License Number</option>
                            </>
                        )}
                    </select>
                    {!validation.active_company_proof && (
                        <div className="text-danger">Field required</div>
                    )}
                </div>
                <div className="col-6" style={col6}>
                    <FileUploader
                        file={basicInformation.active_company_proof_file}
                        saveDocument={saveDocument}
                        name="active_company_proof_file"
                        top="24px"
                        form={form}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().businessLicense}
                    </label>
                    <br />
                    <label className="form-label" style={labelTextCss}>
                        {findlanguage().validOperatingLicenseQuestion}
                    </label>
                </div>
            </div>
            <div className="row mb-3" style={fixRow}>
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelTextCss}></label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={basicInformation.business_license}
                        style={{
                            borderColor: !validation.business_license ? 'red' : 'initial',
                        }}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'business_license')
                        }
                        disabled={form}
                    >
                        <option value=""></option>
                        <option value={findlanguage().no}>{findlanguage().no}</option>
                        <option value={findlanguage().yes}>{findlanguage().yes}</option>
                        <option value="N/A">N/A</option>
                    </select>
                    {!validation.business_license && (
                        <div className="text-danger">Field required</div>
                    )}
                </div>

                <div className="col-6" style={col6}>
                    <FileUploader
                        file={basicInformation.business_license_file}
                        saveDocument={saveDocument}
                        name="business_license_file"
                        top="24px"
                        form={form}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().sanitaryLicense}
                    </label>
                    <br />
                    <label className="form-label" style={labelTextCss}>
                        {findlanguage().healthPermitRequirement}
                    </label>
                </div>
            </div>
            <div className="row  mb-3" style={fixRow}>
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelTextCss}></label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={basicInformation.sanitary_license}
                        style={{
                            borderColor: !validation.sanitary_license ? 'red' : 'initial',
                        }}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'sanitary_license')
                        }
                        disabled={form}
                    >
                        <option value=""></option>
                        <option value={findlanguage().no}>{findlanguage().no}</option>
                        <option value={findlanguage().yes}>{findlanguage().yes}</option>
                        <option value="N/A">N/A</option>
                    </select>
                    {!validation.sanitary_license && (
                        <div className="text-danger">Field required</div>
                    )}
                </div>

                <div className="col-6" style={col6}>
                    <FileUploader
                        file={basicInformation.sanitary_license_file}
                        saveDocument={saveDocument}
                        name="sanitary_license_file"
                        top="24px"
                        form={form}
                    />
                </div>
            </div>
            <div className="row  mb-3">
                <div className="col-4" style={col6}>
                    <label className="form-label" style={labelTextCss}>
                        {findlanguage().companyCertifiedQuestion}
                    </label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={basicInformation.is_company_certified}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'is_company_certified')
                        }
                        disabled={form}
                    >
                        <option value="false">{findlanguage().no}</option>
                        <option value="true">{findlanguage().yes}</option>
                    </select>
                </div>

                {basicInformation.is_company_certified === true ||
                basicInformation.is_company_certified === 'true' ? (
                    <>
                        <div className="col-4" style={col6}>
                            <label className="form-label" style={labelTextCss}>
                                {findlanguage().includeStandards}
                            </label>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                value={basicInformation.what_standards}
                                onChange={(event) =>
                                    changeQuestionaire(event.target.value, 'what_standards')
                                }
                                disabled={form}
                            >
                                <option value=""></option>
                                <option value={findlanguage().regulatoryOption}>
                                    {findlanguage().regulatoryOption}
                                </option>
                                <option value={findlanguage().gfsiRecognizedStandardsOption}>
                                    {findlanguage().gfsiRecognizedStandardsOption}
                                </option>
                                <option value={findlanguage().socialOption}>
                                    {findlanguage().socialOption}
                                </option>
                                <option value={findlanguage().environmentalOption}>
                                    {findlanguage().environmentalOption}
                                </option>
                                <option value={findlanguage().otherOption}>
                                    {findlanguage().otherOption}
                                </option>
                            </select>
                        </div>

                        <div className="col-4" style={col6}>
                            <MultipleFileUploader
                                file={basicInformation.what_standards_file}
                                saveMultipleDocuments={saveMultipleDocuments}
                                name="what_standards_file"
                                top="32px"
                                removeFile={removeFile}
                                form={form}
                            />
                        </div>
                    </>
                ) : null}
            </div>
            <div className="row mb-3">
                <div className="col-4" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().supplierCountQuestion}
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        style={{
                            ...inputCss,
                            borderColor: !validation.suppliers_count ? 'red' : 'initial',
                        }}
                        value={basicInformation.suppliers_count}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'suppliers_count')
                        }
                        disabled={form}
                    />
                    {!validation.suppliers_count && (
                        <div className="text-danger">Field required</div>
                    )}
                </div>
                <div className="col-4" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().multipleFactoriesQuestion}
                    </label>
                    <select
                        className="form-select"
                        aria-label="More than one factory"
                        value={basicInformation.has_multiple_factories}
                        style={{
                            borderColor: !validation.has_multiple_factories ? 'red' : 'initial',
                        }}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'has_multiple_factories')
                        }
                        disabled={form}
                    >
                        <option value=""></option>
                        <option value={findlanguage().no}>{findlanguage().no}</option>
                        <option value={findlanguage().yes}>{findlanguage().yes}</option>
                    </select>
                    {!validation.has_multiple_factories && (
                        <div className="text-danger">Field required</div>
                    )}
                </div>

                {basicInformation.has_multiple_factories === findlanguage().yes ? (
                    <div className="col-4" style={col6}>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().factoryCountQuestion}
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            style={inputCss}
                            value={basicInformation.factory_count}
                            onChange={(event) =>
                                changeQuestionaire(event.target.value, 'factory_count')
                            }
                            disabled={form}
                        />
                    </div>
                ) : null}
            </div>
            <div className="row mb-3">
                <div className="col-4" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().internalDocsControlQuestion}
                    </label>
                    <select
                        className="form-select"
                        value={basicInformation.document_control}
                        style={{
                            borderColor: !validation.document_control ? 'red' : 'initial',
                        }}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'document_control')
                        }
                        disabled={form}
                    >
                        <option value=""></option>
                        <option value={findlanguage().spreadsheetOption}>
                            {findlanguage().spreadsheetOption}
                        </option>
                        <option value={findlanguage().ownSystemOption}>
                            {findlanguage().ownSystemOption}
                        </option>
                        <option value={findlanguage().thirdPartySystemOption}>
                            {findlanguage().thirdPartySystemOption}
                        </option>
                        <option value={findlanguage().othersOption}>
                            {findlanguage().othersOption}
                        </option>
                        <option value={findlanguage().noControlOption}>
                            {findlanguage().noControlOption}
                        </option>
                    </select>
                    {!validation.document_control && (
                        <div className="text-danger">Field required</div>
                    )}
                </div>
                <div className="col-4" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().supplierControlQuestion}
                    </label>
                    <select
                        className="form-select"
                        value={basicInformation.supplier_control}
                        style={{
                            borderColor: !validation.supplier_control ? 'red' : 'initial',
                        }}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'supplier_control')
                        }
                        disabled={form}
                    >
                        <option value=""></option>
                        <option value={findlanguage().spreadsheetOption}>
                            {findlanguage().spreadsheetOption}
                        </option>
                        <option value={findlanguage().ownSystemOption}>
                            {findlanguage().ownSystemOption}
                        </option>
                        <option value={findlanguage().thirdPartySystemOption}>
                            {findlanguage().thirdPartySystemOption}
                        </option>
                        <option value={findlanguage().othersOption}>
                            {findlanguage().othersOption}
                        </option>
                        <option value={findlanguage().noControlOption}>
                            {findlanguage().noControlOption}
                        </option>
                    </select>
                    {!validation.supplier_control && (
                        <div className="text-danger">Field required</div>
                    )}
                </div>
            </div>
            <div className="row mb-3">
                {basicInformation.supplier_control === findlanguage().thirdPartySystemOption ||
                basicInformation.supplier_control === findlanguage().othersOption ? (
                    <>
                        <div className="col-4" style={col6}>
                            <label className="form-label" style={labelCss}>
                                {findlanguage().supplierManagementQuestion}
                            </label>
                            <select
                                className="form-select"
                                value={basicInformation.supplier_management}
                                style={{
                                    borderColor: !validation.supplier_management
                                        ? 'red'
                                        : 'initial',
                                }}
                                onChange={(event) =>
                                    changeQuestionaire(event.target.value, 'supplier_management')
                                }
                                disabled={form}
                            >
                                <option value=""></option>
                                <option value={findlanguage().throughDocumentationOption}>
                                    {findlanguage().throughDocumentationOption}
                                </option>
                                <option value={findlanguage().through2ndPartyAuditOption}>
                                    {findlanguage().through2ndPartyAuditOption}
                                </option>
                                <option
                                    value={findlanguage().throughDocumentationAndAuditingOption}
                                >
                                    {findlanguage().throughDocumentationAndAuditingOption}
                                </option>
                            </select>
                            {!validation.supplier_management && (
                                <div className="text-danger">Field required</div>
                            )}
                        </div>
                        <div className="col-4" style={col6}>
                            <label className="form-label" style={labelCss}>
                                {findlanguage().platformNameQuestion}
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                style={inputCss}
                                value={basicInformation.platform_name}
                                onChange={(event) =>
                                    changeQuestionaire(event.target.value, 'platform_name')
                                }
                                disabled={form}
                            />
                        </div>
                        <div className="col-4" style={col6}>
                            <label className="form-label" style={labelCss}>
                                {findlanguage().averageDocumentsQuestion}
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                style={inputCss}
                                value={basicInformation.avg_documents}
                                onChange={(event) =>
                                    changeQuestionaire(event.target.value, 'avg_documents')
                                }
                                disabled={form}
                            />
                        </div>
                    </>
                ) : null}
                {basicInformation.supplier_control === findlanguage().spreadsheetOption ||
                basicInformation.supplier_control === findlanguage().ownSystemOption ? (
                    <>
                        <div className="col-4" style={col6}>
                            <label className="form-label" style={labelCss}>
                                {findlanguage().supplierManagementQuestion}
                            </label>
                            <select
                                className="form-select"
                                value={basicInformation.supplier_management}
                                style={{
                                    borderColor: !validation.supplier_management
                                        ? 'red'
                                        : 'initial',
                                }}
                                onChange={(event) =>
                                    changeQuestionaire(event.target.value, 'supplier_management')
                                }
                                disabled={form}
                            >
                                <option value=""></option>
                                <option value={findlanguage().throughDocumentationOption}>
                                    {findlanguage().throughDocumentationOption}
                                </option>
                                <option value={findlanguage().through2ndPartyAuditOption}>
                                    {findlanguage().through2ndPartyAuditOption}
                                </option>
                                <option
                                    value={findlanguage().throughDocumentationAndAuditingOption}
                                >
                                    {findlanguage().throughDocumentationAndAuditingOption}
                                </option>
                            </select>
                            {!validation.supplier_management && (
                                <div className="text-danger">Field required</div>
                            )}
                        </div>
                        <div className="col-4" style={col6}>
                            <label className="form-label" style={labelCss}>
                                {findlanguage().averageDocumentsQuestion}
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                style={inputCss}
                                value={basicInformation.avg_documents}
                                onChange={(event) =>
                                    changeQuestionaire(event.target.value, 'avg_documents')
                                }
                                disabled={form}
                            />
                        </div>
                    </>
                ) : null}
                <div className="col-4" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().findNewSuppliersBuyersQuestion}
                    </label>
                    <Select
                        isMulti
                        options={[
                            { label: 'Google', value: 'Google' },
                            {
                                label: findlanguage().recommendations,
                                value: findlanguage().recommendations,
                            },
                            { label: 'LinkedIn', value: 'LinkedIn' },
                            { label: findlanguage().other, value: findlanguage().other },
                        ]}
                        value={basicInformation.find_suppliers}
                        onChange={(values) => changeQuestionaire(values, 'find_suppliers')}
                        isDisabled={form}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().sustainabilityPracticeQuestion}
                    </label>
                    <select
                        className="form-select"
                        value={basicInformation.sustainability_practice}
                        style={{
                            borderColor: !validation.sustainability_practice ? 'red' : 'initial',
                        }}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'sustainability_practice')
                        }
                        disabled={form}
                    >
                        <option value=""></option>
                        <option value={findlanguage().yes}>{findlanguage().yes}</option>
                        <option value={findlanguage().noButLookingToImplement}>
                            {findlanguage().noButLookingToImplement}
                        </option>
                        <option value={findlanguage().no}>{findlanguage().no}</option>
                    </select>
                    {!validation.sustainability_practice && (
                        <div className="text-danger">Field required</div>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().responsibleToFillPreCheck}
                    </label>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().fullName}*
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        style={{
                            ...inputCss,
                            borderColor: !validation.full_name ? 'red' : 'initial',
                        }}
                        value={basicInformation.full_name}
                        onChange={(event) => changeQuestionaire(event.target.value, 'full_name')}
                        disabled={form}
                    />
                    {!validation.full_name && <div className="text-danger">Field required</div>}
                </div>

                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        Email*
                    </label>
                    <input
                        type="email"
                        className="form-control"
                        style={{
                            ...inputCss,
                            borderColor: !validation.email ? 'red' : 'initial',
                        }}
                        value={basicInformation.email}
                        onChange={(event) => changeQuestionaire(event.target.value, 'email')}
                        disabled={form}
                    />
                    {!validation.email && <div className="text-danger">Field required</div>}
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-6 col-md-4" style={col6}>
                    <label className="form-label" style={labelCss} htmlFor="direct_phone_number">
                        {findlanguage().directPhoneNumber}*
                    </label>
                    <input
                        id="direct_phone_number"
                        type="text"
                        className="form-control"
                        style={{
                            ...inputCss,
                            borderColor:
                                !validation.direct_phone_number ||
                                !validation.invalid_direct_phone_number
                                    ? 'red'
                                    : 'initial',
                        }}
                        value={basicInformation.direct_phone_number}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'direct_phone_number')
                        }
                        disabled={form}
                        required
                    />
                    {!validation.direct_phone_number && (
                        <div className="text-danger">Field required</div>
                    )}
                    {!validation.invalid_direct_phone_number && (
                        <div className="text-danger">Phone number is invalid</div>
                    )}
                    <UncontrolledPopover
                        placement="top"
                        target={'direct_phone_number'}
                        trigger="focus"
                    >
                        <PopoverHeader>{findlanguage().rulesPhoneNumberHeader}</PopoverHeader>
                        <PopoverBody>{findlanguage().rulesPhoneNumberText}</PopoverBody>
                    </UncontrolledPopover>
                </div>
            </div>
        </PageCard>
    )
}

const FileUploader = ({ file, saveDocument, name, top, form }) => {
    const { findlanguage } = useContext(CoreContext)
    return (
        <>
            {file === undefined || file === null ? (
                <>
                    <label
                        for={name}
                        style={{
                            fontSize: '13px',
                            color: 'white',
                            height: '35px',
                            padding: '10px',
                            marginTop: top,
                            width: '100%',
                            backgroundColor: '#263640',
                            cursor: 'pointer',
                        }}
                        className="badge"
                    >
                        {findlanguage().openFile}
                    </label>
                    <input
                        type="file"
                        id={name}
                        disabled={form}
                        onChange={() => saveDocument(name)}
                        style={{ display: 'none' }}
                    />
                </>
            ) : (
                <>
                    <label
                        for={name}
                        style={{
                            fontSize: '13px',
                            color: 'white',
                            height: '35px',
                            padding: '10px',
                            marginTop: top,
                            width: '100%',
                            backgroundColor: '#263640',
                            cursor: 'pointer',
                        }}
                        className="badge"
                    >
                        {findlanguage().changeFile}
                    </label>
                    <div style={{ marginTop: '6px', textAlign: 'center' }}>{file?.name}</div>
                    <input
                        type="file"
                        id={name}
                        disabled={form}
                        onChange={() => saveDocument(name)}
                        style={{ display: 'none' }}
                    />
                </>
            )}
        </>
    )
}

const MultipleFileUploader = ({ file, saveMultipleDocuments, name, top, removeFile, form }) => {
    const { findlanguage } = useContext(CoreContext)
    return (
        <>
            {file === undefined || file === null || file?.length === 0 ? (
                <>
                    <label
                        for={name}
                        style={{
                            fontSize: '13px',
                            color: 'white',
                            height: '35px',
                            padding: '10px',
                            marginTop: top,
                            width: '100%',
                            backgroundColor: '#263640',
                            cursor: 'pointer',
                        }}
                        className="badge"
                    >
                        {findlanguage().openFile}
                    </label>
                    <input
                        type="file"
                        id={name}
                        onChange={() => saveMultipleDocuments(name)}
                        multiple
                        disabled={form}
                        style={{ display: 'none' }}
                    />
                </>
            ) : (
                <>
                    <label
                        for={name}
                        style={{
                            fontSize: '13px',
                            color: 'white',
                            height: '35px',
                            padding: '10px',
                            marginTop: top,
                            width: '100%',
                            backgroundColor: '#263640',
                            cursor: 'pointer',
                        }}
                        className="badge"
                    >
                        {findlanguage().changeFile}
                    </label>
                    <div style={{ marginTop: '6px', textAlign: 'center' }}>
                        {file?.map((y) => (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div>{y.name}</div>
                                    <div
                                        onClick={() => removeFile(y.id)}
                                        style={{
                                            cursor: 'pointer',
                                            marginLeft: '5px',
                                            marginTop: '1px',
                                            fontWeight: 'bold',
                                            color: 'red',
                                        }}
                                    >
                                        X
                                    </div>
                                </div>
                                <br></br>
                            </>
                        ))}
                    </div>
                    <input
                        type="file"
                        id={name}
                        disabled={form}
                        onChange={() => saveMultipleDocuments(name)}
                        multiple
                        style={{ display: 'none' }}
                    />
                </>
            )}
        </>
    )
}
