import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { BodyContent } from './styled'

import ContainerUnauthenticated from 'containers/Unauthenticated'

import RegisterCard from 'components/Register/Card'
import RegisterHeader from 'components/Register/Header'
import RegisterStepIndicator from 'components/Register/StepIndicator'
import { CoreContext } from 'context/CoreContext'
import Step from 'screens/Authentication/Register/Step/index'

export default function Register() {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const { findlanguage } = useContext(CoreContext)
    const [chooseType, setChooseType] = useState('')

    const aRegisterProfiles = [
        {
            title: findlanguage().headquarters,
            description: findlanguage().buyerDescription,
            image: '/images/buyer.png',
            action: () => setChooseType('admin'),
        },
        {
            title: findlanguage().serviceProviderTitle,
            description: findlanguage().serviceProviderDescription,
            image: '/images/service-provider.png',
            action: () => setChooseType('serviceprovider'),
        },
    ]

    return (
        <>
            {chooseType === '' ? (
                <ContainerUnauthenticated>
                    <RegisterHeader title={findlanguage().singup} backable chatable={false} />
                    <RegisterStepIndicator
                        steps={[{}, {}]}
                        current={1}
                        title={findlanguage().selectAccountType}
                    />
                    <BodyContent>
                        {aRegisterProfiles.map((item, key) => (
                            <RegisterCard key={key} {...item} onClick={item.action} />
                        ))}
                    </BodyContent>
                </ContainerUnauthenticated>
            ) : (
                <Step chooseType={chooseType} setChooseType={setChooseType} />
            )}
        </>
    )
}
