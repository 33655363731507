import React, { useContext } from 'react'

import { SuccessText, ModalActions } from './styled'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'
import Button from 'components/Form/Button'
import { Load } from 'ui/styled'

export default function ModalLoading() {
    const { modal } = useContext(CoreContext)

    return (
        <>
            <ModalWrapper>
                <SuccessText>{modal?.text}</SuccessText>
                <ModalActions>
                    <Button small primary>
                        <Load />
                    </Button>
                </ModalActions>
            </ModalWrapper>
        </>
    )
}
