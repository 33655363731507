import React, { useState, useContext, useEffect } from 'react'

import { CoreContext } from 'context/CoreContext'
//import { UserProfileProgressActions } from './styled'

//import DashboardButton from 'components/Dashboard/Button'
//import MFSApproval from 'components/Dashboard/Sms/TabOverview/ChooseButton/MfsApproval/index'
import MyApproval from 'components/Dashboard/Sms/TabOverview/ChooseButton/MyApproval/index'
import Select from 'react-select'
import { FetchMyForms } from 'services/sms'
import DashboardButton from 'components/Dashboard/Button'
import { CreateFormRequestForSupplier, GetForm } from 'services/validationProcess'
import { NotificationManagerAdd } from 'services/admin'
export default function ChooseButton({
    usersWithForms,
    viewReport,
    setViewReport,
    changeUsersWithForm,
    setMfsApproval,
    init,
    changeProducts,
    editProductsForm,
    filters,
    item,
    myFormList
}) {
    const { findlanguage, user, setModal } = useContext(CoreContext)
    //const [userFilter, setUserFilter] = useState(false)
    const [choosedForms, setChoosedForms] = useState([])
    const [isPrivate, setIsPrivate] = useState(false);


    //const changeSupplierInformation = () => {
    //    setUserFilter(!userFilter)
    //    setMfsApproval(!userFilter)
    //}

    const handleChangeForms = (values) => {
        let id = []
        setChoosedForms(values)

        values.forEach((y) => {
            usersWithForms.forEach((x) => {
                if (x.form.id === y.value) {
                    if (!id.includes(x.toUser.id)) id.push(x.toUser.id)
                }
            })
        })
    }

    const options = myFormList.map((x) => {
        return {
            value: x.id,
            label: x.name,
        }
    })

    const sendRequirements = async () => {
        await choosedForms.forEach(async (x) => {
            var form_body = await GetForm(x.value)

            await CreateFormRequestForSupplier({
                form: x.value,
                form_body: form_body,
                toUser: { id: item.userId },
                owner: { id: user.company_id },
                status: 'Condition',
                private: isPrivate
            })

            init()

            if (isPrivate) {
                await NotificationManagerAdd('document_request', user.id, user.name, user)
            }
            else {
                await NotificationManagerAdd('document_request', item.userId, user.name, user)
            }
        })

        setModal({
            type: 'alert',
            text: findlanguage().requirementSent,
        })
    }

    return (
        <>
            <div className="row">
                <div className="col-2">
                    <label
                        className="form-label"
                        style={{
                            fontSize: '15px',
                            fontWeight: '700',
                            color: 'rgb(100, 100, 100)',
                            marginTop: '5px',
                        }}
                    >
                        {findlanguage().sendTheRequirements}
                    </label>
                </div>
                <div className="col-4">
                    <Select
                        onChange={(values) => handleChangeForms(values)}
                        isMulti
                        name="colors"
                        options={options.sort(
                            (a, b) => (a.label > b.label) - (a.label < b.label)
                        )}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={findlanguage().select}
                    />
                </div>
                <div className="col-2">
                    <input type="checkbox" id="privateCheck" checked={isPrivate} onChange={() => setIsPrivate(!isPrivate)} />
                    <label htmlFor="privateCheck" style={{ marginLeft: '10px' }}>{findlanguage().private}</label>
                </div>
                <div className="col-2">
                    <button className="btn btn-light btn-block colorTealDark" onClick={sendRequirements} disabled={
                        choosedForms.length === 0
                            ? true
                            : false
                    }>
                        {findlanguage().send}
                    </button>
                </div>
            </div>

            <br />
            <MyApproval
                filters={filters}
                usersWithForms={usersWithForms}
                setViewReport={setViewReport}
                changeUsersWithForm={changeUsersWithForm}
                init={init}
                changeProducts={changeProducts}
                editProductsForm={editProductsForm}
                componentId={item?.supplier}
            />
        </>
    )
}

//{
//    !viewReport ? (
//        <UserProfileProgressActions>
//            <DashboardButton
//                icon={userFilter ? '' : 'check'}
//                dark
//                outline={userFilter}
//                onClick={changeSupplierInformation}
//            >
//                {findlanguage().myApproval}
//            </DashboardButton>
//        </UserProfileProgressActions>
//    ) : null
//}

//return (
//    <>
//        {!viewReport ? (
//            <UserProfileProgressActions>
//                <DashboardButton
//                    icon={userFilter ? 'check' : ''}
//                    dark
//                    outline={!userFilter}
//                    onClick={changeSupplierInformation}
//                >
//                    {findlanguage().mfsApproval}
//                </DashboardButton>
//                <DashboardButton
//                    icon={userFilter ? '' : 'check'}
//                    dark
//                    outline={userFilter}
//                    onClick={changeSupplierInformation}
//                >
//                    {findlanguage().myApproval}
//                </DashboardButton>
//            </UserProfileProgressActions>
//        ) : null}
//        <br />
//        {userFilter ? (
//            <MFSApproval />
//        ) : (
//            <MyApproval
//                usersWithForms={usersWithForms}
//                setViewReport={setViewReport}
//                changeUsersWithForm={changeUsersWithForm}
//                init={init}
//                changeProducts={changeProducts}
//            />
//        )}
//    </>
//)
