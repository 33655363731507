import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { SaveObject, ReadObject } from 'services/storage'
import { GetPermissions } from 'services/permissions'

import Input from 'components/Form/Input'
import Button from 'components/Form/Button'
import Check from 'components/Form/Check'

import {
    FormTitle,
    FormSpacer,
    RegisterCall,
    RememberForgotContent,
    ForgotText,
    InputIcon,
} from './styled'

import ContainerUnauthenticated from 'containers/Unauthenticated'
import { CoreContext } from 'context/CoreContext'
import {
    DoLogin,
    GetMe,
    PostLogAccess,
    UpdateMyUser,
    DoLoginCheckForAuth,
} from 'services/authentication'
import { exposeStrapiError } from 'utils'

export default function Login() {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const { setUser, setProfile, checkPremium, setModal, setLanguage, findlanguage, setPermissions } =
        useContext(CoreContext)
    const [form, setForm] = useState({})
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [shouldNotBeInDev, setShouldNotBeInDev] = useState(false)

    const formValue = (ref) => {
        return form?.[ref] ? form?.[ref] : ''
    }
    const changeForm = (value, ref) => {
        setForm({ ...form, [ref]: value })
    }

    const valid = (verbose = false) => {
        if (!formValue('identifier') || !formValue('identifier').length) {
            if (verbose) {
                toast.error('Email is required')
            }
            return false
        }

        if (!formValue('password') || !formValue('password').length) {
            if (verbose) {
                toast.error('Password is required')
            }
            return false
        }

        return true
    }

    const login = async () => {
        if (!valid(true)) {
            return
        }
        setLoading(true)
        const result = await DoLoginCheckForAuth({
            ...form,
            identifier: form.identifier?.replace(/ /g, ''),
        })

        if (result === false) {
            setShouldNotBeInDev(true)
        } else {
            if (result?.statusText === 'Bad Request' || result?.error === 'Bad Request') {
                toast.error('Login or password invalid')
                setLoading(false)
                return false
            } else if (
                result?.message &&
                result?.message[0]?.messages &&
                result?.message[0]?.messages[0]?.message ===
                    'Too many attempts, please try again in a minute.'
            ) {
                toast.error('Too many attempts')
                setLoading(false)
                return false
            }

            if (
                result.user?.acceptedUserTermsAndConditions === undefined ||
                result.user?.acceptedUserTermsAndConditions === null ||
                result.user.acceptedUserTermsAndConditions === false
            )
                setIsOpen(true)
            else loginUser(false)
        }
    }

    const loginUser = async (accepted) => {
        let result = await DoLogin({ ...form, identifier: form.identifier?.replace(/ /g, '') })
        let loggedUserData = await GetMe()

        if (accepted) {
            loggedUserData.acceptedUserTermsAndConditions = true
            result.user.acceptedUserTermsAndConditions = true

            if (loggedUserData.team_access) {
                await UpdateMyUser({
                    ...loggedUserData,
                    team_access: loggedUserData.team_access.id,
                })
            } else {
                await UpdateMyUser(loggedUserData)
            }
        }

        // Updating active_account
        if (result?.jwt) {
            if (
                result.user.email === 'admin@myfoodsc.com' ||
                (loggedUserData.support && loggedUserData.company_id === 1)
            ) {
                loggedUserData.type = 'admin'
                loggedUserData.active_account = { type: 'admin' }
            } else {
                const plan = await checkPremium()
                if (loggedUserData.support && loggedUserData.company_id !== 1) {
                    showSupportCaseInfo(loggedUserData.active_account.user.name)
                } else if (plan.expired) {
                    showEndOfTrialMsg()
                }
            }

            if (loggedUserData.id !== loggedUserData.company_id && loggedUserData.active_account.type === 'service-provider') {
                loggedUserData.currentWorkingAsServiceProvider = true
            }

            result.user = loggedUserData

            await SaveObject('authentication', result)

            // User Log Access
            const logpayload = {
                userid: result.user.id,
                name: result.user.name,
                log_datetime: new Date(),
            }
            await PostLogAccess(logpayload)
        }

        setLoading(false)

        if (loggedUserData && !exposeStrapiError(result)) {
            const permissions = await GetPermissions(result.user.id)

            await SaveObject('permissions', permissions.permissions)
            setPermissions(permissions.permissions)

            result.user = loggedUserData
            completeLogin(result)
        }
    }

    const saveAcceptUserAgreement = () => {
        loginUser(true)
    }

    const showEndOfTrialMsg = () => {
        setModal({
            type: 'trial-ended',
        })
    }

    const showSupportCaseInfo = (_company) => {
        setModal({
            type: 'support-case-login',
            company: _company,
        })
    }

    const completeLogin = async (result) => {
        if (result?.user) {
            setUser(result.user)
        }

        if (result?.user?.id !== result?.user?.company_id) {
            if (result?.user?.role?.id === 3) {
                setProfile('buyer')
            }
            else if (result?.user?.role?.id === 4) {
                setProfile('supplier')
            }
            else if (result?.user?.role?.id === 5) {
                setProfile('service-provider')
            }
            else if (result?.user?.role?.id === 6) {
                setProfile('buyer-supplier')
            }
            else if (result?.user?.role?.id === 7) {
                setProfile('admin')
            }
            else if (result?.user?.role?.id === 8) {
                setProfile('administrative')
            }
        }
        else {
            setProfile(result?.user?.active_account?.type || 'consumer')
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            login()
        }
    }

    const init = () => {}

    useEffect(() => {
        init()
    }, [])

    const changeLanguage = (changeLanguage) => {
        localStorage.setItem('language', changeLanguage)
        setLanguage(changeLanguage)
    }

    const modalStyle = {
        display: isOpen ? 'block' : 'none',
    }

    const currentDate = new Date().toLocaleDateString()

    const denyTerms = () => {
        setIsOpen(false)
        setLoading(false)
    }

    return (
        <>
            {shouldNotBeInDev ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        textAlign: 'center',
                        backgroundColor: '#f4f4f4',
                        color: '#333',
                        fontFamily: 'Arial, sans-serif',
                        padding: '20px',
                    }}
                >
                    <h1 style={{ marginBottom: '20px' }}>Developer Environment Access</h1>
                    <p style={{ fontSize: '20px', marginBottom: '40px' }}>
                        The developer environment is no longer accessible.
                    </p>
                    <p style={{ fontSize: '20px' }}>
                        Please visit our new site at
                        <a
                            href="https://app.mytrustedsc.com/"
                            style={{
                                color: '#3498db',
                                textDecoration: 'none',
                                marginLeft: '5px',
                            }}
                        >
                            https://app.mytrustedsc.com/
                        </a>
                    </p>
                </div>
            ) : (
                <>
                    <ContainerUnauthenticated landpage>
                        <FormTitle>Login</FormTitle>
                        <Input
                            placeholder={findlanguage().email}
                            value={formValue('identifier')}
                            onChange={(e) => changeForm(e.target.value, 'identifier')}
                            onKeyPress={(e) => handleKeyPress(e)}
                        />
                        <Input
                            placeholder={findlanguage().password}
                            type="password"
                            value={formValue('password')}
                            onChange={(e) => changeForm(e.target.value, 'password')}
                            onKeyPress={(e) => handleKeyPress(e)}
                        />
                        <RememberForgotContent>
                            {/* <Check
                        onChange={() => changeForm(!formValue('remember'), 'remember')}
                        checked={!!formValue('remember')}
                        label={'Remember Me'}
                    /> */}
                            <ForgotText onClick={() => navigate('forgot')}>
                                {findlanguage().forgotPassword}
                            </ForgotText>
                        </RememberForgotContent>
                        <FormSpacer />
                        <Button primary loading={loading} onClick={login}>
                            {findlanguage().enter}
                        </Button>
                         <RegisterCall>{findlanguage().noAccount}</RegisterCall>
                        <Button primary outline onClick={() => navigate('register')}>
                            {findlanguage().createCount}
                        </Button> 
                        <div>
                            <InputIcon
                                onClick={() => changeLanguage('english')}
                                src={'/icons/usa-flag.svg'}
                            />
                            <InputIcon
                                onClick={() => changeLanguage('portugues')}
                                src={'/icons/brazil-flag.svg'}
                            />
                        </div>
                    </ContainerUnauthenticated>

                    <>
                        <div>
                            {isOpen && (
                                <div
                                    className="modal show"
                                    style={modalStyle}
                                    tabIndex="-1"
                                    role="dialog"
                                >
                                    <div
                                        className="modal-dialog modal-dialog-centered modal-lg"
                                        role="document"
                                    >
                                        {/* Add the 'modal-lg' class above */}
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">
                                                    Terms of Use Agreement
                                                </h5>
                                            </div>
                                            <div
                                                className="modal-body"
                                                style={{ maxHeight: '60vh', overflowY: 'auto' }}
                                            >
                                                <h2>TERMS OF USE AGREEMENT</h2>
                                                <p>Latest Version: {currentDate}</p>

                                                <p>
                                                    This Terms of Use Agreement (the “Agreement”) is
                                                    made by and between you and MFSC LLC (the
                                                    “Company”). By accessing and using the Company’s
                                                    website (the “Website”), you agree that you have
                                                    read, understand, and agree to comply with and
                                                    be bound by the following terms and conditions.
                                                </p>

                                                <h3>Agreement</h3>
                                                <p>
                                                    This Agreement specifies the terms and
                                                    conditions for access to and use of the Website
                                                    and describes the terms and conditions
                                                    applicable to your access of and use of the
                                                    Website. This Agreement may be amended,
                                                    modified, revised, or updated at any time by the
                                                    Company upon posting of the latest version of
                                                    this Agreement on the Website. Any such
                                                    amendments, modifications, revisions, or updates
                                                    shall be effective immediately. You can view the
                                                    latest version of this Agreement at any time at{' '}
                                                    <a href="http://www.mytrustedsc.com">
                                                        www.mytrustedsc.com
                                                    </a>
                                                    . Each use by you shall constitute and be deemed
                                                    your unconditional acceptance of this Agreement.
                                                </p>

                                                <h3>Privacy</h3>
                                                <p>
                                                    Your access to and use of the Website is also
                                                    governed by our Privacy Policy. Please review
                                                    our Privacy Policy at{' '}
                                                    <a href="http://www.mytrustedsc.com">
                                                        www.mytrustedsc.com
                                                    </a>
                                                    .
                                                </p>

                                                <h3>Ownership</h3>
                                                <p>
                                                    All content included on the Website is and shall
                                                    continue to be the property of the Company or
                                                    its content suppliers and is protected under
                                                    applicable copyright, patent, trademark, and
                                                    other proprietary rights. Any copying,
                                                    redistribution, use, or publication by you of
                                                    any such content or any part of the Website is
                                                    prohibited, except as expressly permitted in
                                                    this Agreement. Under no circumstances will you
                                                    acquire any ownership rights or other interest
                                                    in any content by or through your use of the
                                                    Website.
                                                </p>

                                                <h3>Trademarks</h3>
                                                <p>
                                                    Trademarks, service marks, logos, copyrighted
                                                    works, and other intellectual property appearing
                                                    on the Website are the property of the Company
                                                    or the party that provided such intellectual
                                                    property on the Website. The Company and any
                                                    party that provides intellectual property on the
                                                    Website shall retain all rights with respect to
                                                    any of their respective intellectual property
                                                    appearing on the Website, and no rights in such
                                                    materials are transferred or assigned to you.
                                                </p>

                                                <h3>Website Use</h3>
                                                <p>
                                                    The Company grants you a limited, revocable,
                                                    nonexclusive license to use the Website solely
                                                    for your own personal use. You agree not to
                                                    assign, collect, copy, distribute, display,
                                                    license, modify, publish, reproduce, scrape,
                                                    sell, send, transfer, reverse engineer, or
                                                    create derivative works from any content, data,
                                                    information, materials, software, intellectual
                                                    property, products, or services contained on or
                                                    otherwise connected to the Website. You agree
                                                    not to use any content, data, information,
                                                    materials, software, intellectual property,
                                                    products, or services contained on or otherwise
                                                    connected to the Website in violation of any
                                                    law. Your access and use of the Website are at
                                                    the discretion of the Company. The Company may
                                                    terminate your access and use of the Website at
                                                    any time.
                                                </p>

                                                <h3>Compliance with Laws</h3>
                                                <p>
                                                    You agree to comply with all applicable laws
                                                    regarding your use of the Website. You further
                                                    agreed that information provided by you is
                                                    truthful and accurate to the best of your
                                                    knowledge.
                                                </p>

                                                <h3>Indemnification</h3>
                                                <p>
                                                    You agree to indemnify, defend, and hold the
                                                    Company and our partners, employees, and
                                                    affiliates harmless from any liability, loss,
                                                    claim, and expense, including reasonable
                                                    attorney's fees, related to your violation of
                                                    this Agreement or use of the Website.
                                                </p>

                                                <h3>Disclaimer</h3>
                                                <p>
                                                    THE INFORMATION ON THE WEBSITE IS PROVIDED ON AN
                                                    "AS IS," "AS AVAILABLE" BASIS. YOU AGREE THAT
                                                    USE OF THE WEBSITE IS AT YOUR SOLE RISK. THE
                                                    COMPANY DISCLAIMS ALL WARRANTIES OF ANY KIND,
                                                    INCLUDING BUT NOT LIMITED TO ANY EXPRESS
                                                    WARRANTIES, STATUTORY WARRANTIES, AND ANY
                                                    IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
                                                    FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                                                    THE COMPANY DOES NOT WARRANT OR MAKE ANY
                                                    REPRESENTATION REGARDING USE, OR THE RESULT OF
                                                    USE, OF ANY PRODUCT, SERVICE, OR INFORMATION IN
                                                    TERMS OF ACCURACY, RELIABILITY, OR OTHERWISE.
                                                    YOUR SOLE AND EXCLUSIVE REMEDY RELATING TO YOUR
                                                    USE OF THE WEBSITE SHALL BE TO DISCONTINUE USING
                                                    THE WEBSITE.
                                                </p>

                                                <h3>Limitation of Liability</h3>
                                                <p>
                                                    UNDER NO CIRCUMSTANCES WILL THE COMPANY BE
                                                    LIABLE OR RESPONSIBLE FOR ANY DIRECT, INDIRECT,
                                                    INCIDENTAL, CONSEQUENTIAL (INCLUDING DAMAGES
                                                    FROM LOSS OF BUSINESS, LOST PROFITS, LITIGATION,
                                                    OR THE LIKE), SPECIAL, EXEMPLARY, PUNITIVE, OR
                                                    OTHER DAMAGES, UNDER ANY LEGAL THEORY, ARISING
                                                    OUT OF OR IN ANY WAY RELATING TO THE WEBSITE,
                                                    YOUR WEBSITE USE, OR THE CONTENT, EVEN IF
                                                    ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOUR
                                                    SOLE REMEDY FOR DISSATISFACTION WITH THE WEBSITE
                                                    AND/OR CONTENT IS TO CEASE USING THE WEBSITE.
                                                </p>

                                                <h3>Use of Information</h3>
                                                <p>
                                                    The Company reserves the right, and you
                                                    authorize us, to use and assign all information
                                                    regarding your use of the Website and all
                                                    information provided by you in any manner
                                                    consistent with our Privacy Policy.
                                                </p>

                                                <h3>Copyrights and Copyright Agent</h3>
                                                <p>
                                                    If you believe your work has been copied in a
                                                    way that constitutes copyright infringement, or
                                                    your intellectual property rights have otherwise
                                                    been violated, please provide a notice
                                                    containing all of the following information to
                                                    our Copyright Agent:
                                                </p>
                                                <ul>
                                                    <li>
                                                        An electronic or physical signature of the
                                                        person authorized to act on behalf of the
                                                        owner of the copyright or other intellectual
                                                        property interest;
                                                    </li>
                                                    <li>
                                                        A description of the copyrighted work that
                                                        you claim has been infringed;
                                                    </li>
                                                    <li>
                                                        A description of where the material that you
                                                        claim is infringing is located on the
                                                        Website;
                                                    </li>
                                                    <li>
                                                        Your address, telephone number, and email
                                                        address;
                                                    </li>
                                                    <li>
                                                        A statement by you that you have a good
                                                        faith belief that the disputed use is not
                                                        authorized by the copyright owner, its
                                                        agent, or the law; and
                                                    </li>
                                                    <li>
                                                        A statement by you, made under penalty of
                                                        perjury, that the above information in your
                                                        notice is accurate and that you are the
                                                        copyright owner or authorized to act on the
                                                        copyright owner's behalf.
                                                    </li>
                                                </ul>
                                                <p>
                                                    Our Copyright Agent for Notice of claims of
                                                    copyright infringement on the Website is Valmir
                                                    Rodrigues, who can be reached as follows:
                                                </p>
                                                <p>
                                                    By Mail: 753 Santolina Court, Waxhaw, NC, Zip
                                                    Code 28173
                                                </p>
                                                <p>By Phone: +980 229 3577</p>
                                                <p>
                                                    By E-mail:{' '}
                                                    <a href="mailto:contact@myt-s.com">
                                                        contact@myt-s.com
                                                    </a>
                                                </p>

                                                <h3>Applicable Law</h3>
                                                <p>
                                                    You agree that the laws of the state of North
                                                    Carolina, without regard to conflicts of laws
                                                    provisions, will govern this Agreement and any
                                                    dispute that may arise between you and the
                                                    Company or its affiliates.
                                                </p>

                                                <h3>Severability</h3>
                                                <p>
                                                    If any provision of this Agreement shall be
                                                    adjudged by any court of competent jurisdiction
                                                    to be unenforceable or invalid, that provision
                                                    shall be limited or eliminated to the minimum
                                                    extent necessary so that this Agreement will
                                                    otherwise remain in full force and effect.
                                                </p>

                                                <h3>Waiver</h3>
                                                <p>
                                                    The failure of the Company to exercise or
                                                    enforce any right or provision of this Agreement
                                                    shall not operate as a waiver of such right or
                                                    provision. Any waiver of this Agreement by the
                                                    Company must be in writing and signed by an
                                                    authorized representative of the Company.
                                                </p>

                                                <h3>Termination</h3>
                                                <p>
                                                    The Company may terminate this Agreement at any
                                                    time, with or without notice, for any reason.
                                                </p>

                                                <h3>Relationship of the Parties</h3>
                                                <p>
                                                    Nothing contained in this Agreement or your
                                                    access to and use of the Website shall be
                                                    construed to constitute either party as a
                                                    partner, joint venturer, employee, or agent of
                                                    the other party, nor shall either party hold
                                                    itself out as such. Neither party has any right
                                                    or authority to incur, assume, or create, in
                                                    writing or otherwise, any warranty, liability,
                                                    or other obligation of any kind, express or
                                                    implied, in the name of or on behalf of the
                                                    other party, it being intended by both parties
                                                    that each shall remain independent contractors
                                                    responsible for its own actions.
                                                </p>

                                                <h3>Age Restriction</h3>
                                                <p>
                                                    You must be at least 18 years of age to access
                                                    and use the Website. By accessing and using the
                                                    Website, you are certifying that you are at
                                                    least 18 years of age.
                                                </p>

                                                <h3>Entire Agreement</h3>
                                                <p>
                                                    This Agreement constitutes the entire agreement
                                                    between you and the Company and governs the
                                                    terms and conditions of your access to and use
                                                    of the Website, and supersedes all prior or
                                                    contemporaneous communications and proposals,
                                                    whether electronic, oral, or written, between
                                                    you and the Company with respect to the Website.
                                                    Notwithstanding the foregoing, you may also be
                                                    subject to additional terms and conditions,
                                                    posted policies (including but not limited to
                                                    the Privacy Policy), guidelines, or rules that
                                                    may apply when you use the Website. The Company
                                                    may amend, modify, revise, or update this
                                                    Agreement at any time by posting the latest
                                                    version on the Website. Accordingly, you should
                                                    periodically review this Agreement on the
                                                    Website to determine if any changes have been
                                                    made. Your continued use of the Website after
                                                    any changes have been made to this Agreement
                                                    signifies and confirms your acceptance of any
                                                    such changes to this Agreement.
                                                </p>

                                                <h3>Contact Information</h3>
                                                <p>
                                                    Please contact us by mail, email, or telephone
                                                    if you have any questions about this Agreement.
                                                </p>

                                                <p>MFSC LLC</p>
                                                <p>
                                                    753 Santolina Court, Waxhaw, NC, Zip Code 28173
                                                </p>
                                                <p>
                                                    <a href="mailto:contact@myt-s.com">
                                                        contact@myt-s.com
                                                    </a>
                                                </p>
                                                <p>+980 229 3577</p>
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={saveAcceptUserAgreement}
                                                >
                                                    Accept Terms of Use
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary"
                                                    onClick={denyTerms}
                                                >
                                                    Decline Terms of Use
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {isOpen && <div className="modal-backdrop fade show"></div>}
                        </div>
                    </>
                </>
            )}
        </>
    )
}
