import React from 'react'

import {
    ProfileFormContent,
    PageFormHeader,
    RowFilters,
    FilterTitle,
} from './styled'

import ModalWrapper from '../Wrapper'
import CardUserAddable from 'components/Dashboard/Cards/UserAddable'
import DashboardFilterSearch from 'components/Dashboard/Filter/Search'
import DashboardButton from 'components/Dashboard/Button'

export default function ModalNewMessage() {
    const actions = [{ icon: 'chatsent' }]

    return (
        <>
            <ModalWrapper big>
                <PageFormHeader>
                    <DashboardFilterSearch placeholder={'Search connection'} />
                </PageFormHeader>
                <RowFilters>
                    <FilterTitle>Filter by</FilterTitle>
                    {[{ title: 'Buyer' }, { title: 'Supplier' }, { title: 'Service Provider' }].map(
                        (item, key) => (
                            <DashboardButton key={key} small outline>
                                {item.title}
                            </DashboardButton>
                        )
                    )}
                </RowFilters>
                <ProfileFormContent>
                    {[0, 1, 2, 3, 4, 5, 6, 7].map((item, key) => (
                        <CardUserAddable key={key} actions={actions} />
                    ))}
                </ProfileFormContent>
            </ModalWrapper>
        </>
    )
}
