import React, { useContext } from 'react'

import { SuccessText, ModalActions } from './styled'

import Button from 'components/Form/Button'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

export default function ModalChoose() {
    const { modal, setModal } = useContext(CoreContext)

    const close = () => {
        setModal(null)
    }

    const action = (item) => {
        if (item.action && typeof item.action === 'function') {
            item.action()
        } else {
            close()
        }
    }

    return (
        <>
            <ModalWrapper>
                <SuccessText>{modal?.text}</SuccessText>
                <ModalActions>
                    {modal?.options?.map((item, key) => (
                        <Button
                            key={key}
                            small
                            primary
                            outline={item.outline}
                            onClick={() => action(item)}
                        >
                            {item.title}
                        </Button>
                    ))}
                </ModalActions>
            </ModalWrapper>
        </>
    )
}
