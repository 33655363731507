import React, { useState } from 'react'

import {
    InputContainer,
    InputBlankContainer,
    InputInput,
    InputInputArea,
    InputAction,
    InputIcon,
    PlaceholderText,
    InputMaxLength,
    SelectBody,
    SelectBodyItem,
} from './styled'

export default function Input(props) {
    const [visible, setVisible] = useState(false)
    const [focus, setFocus] = useState(false)
    const [searchExpression, setSearchExpression] = useState('')

    const GINPUT = props.type === 'textarea' ? InputInputArea : InputInput

    const forwardFunction = (ev, ref) => {
        if (props?.[ref] && typeof props?.[ref] === 'function') {
            props?.[ref]?.(ev)
        }
    }

    const onFocus = (ev) => {
        forwardFunction(ev, 'onFocus')
        setFocus(true)
    }

    const onBlur = (ev) => {
        forwardFunction(ev, 'onBlur')
        if (!props.typeahead || !searchExpression) {
            setFocus(false)
        }
    }

    const onChange = (ev) => {
        if (props.typeahead && focus) {
            setSearchExpression(ev.target.value)
        } else {
            forwardFunction(ev, 'onChange')
        }
    }

    const currentValue = () => {
        if (props.typeahead) {
            return searchExpression
        }
        return props.value
    }

    const optionAction = (item) => {
        setSearchExpression(item.title)
        forwardFunction({ target: { value: item } }, 'onChange')
        setFocus(false)
    }

    const filterExpression = (item) => {
        return (
            !!searchExpression &&
            Object.keys(item).filter(
                (k) => `${item[k]}`.toLowerCase().indexOf(searchExpression.toLowerCase()) !== -1
            ).length > 0
        )
    }

    const closeDropDown = () => {
        setFocus(false)
    }

    if (props.blank_space) {
        return (
            <>
                <InputBlankContainer
                    style={props.style}
                    focused={focus || (currentValue() && currentValue().length > 0)}
                    small={props.small}
                    big={props.big}
                    max_width={props.max_width}
                ></InputBlankContainer>
            </>
        )
    } else {
        return (
            <>
                <InputContainer
                    style={props.style}
                    focused={focus || (currentValue() && currentValue().length > 0)}
                    small={props.small}
                    password={props.type === 'password'}
                    textarea={props.type === 'textarea'}
                    big={props.big}
                    outline={props.outline}
                    max_width={props.max_width}
                    highlight={props.highlight ? true : false}
                >
                    {props.placeholder && props.type !== 'textarea' ? (
                        <PlaceholderText
                            focused={focus || (currentValue() && currentValue().length > 0)}
                            outline={props.outline}
                            highlight={props.highlight ? true : false}
                        >
                            {props.placeholder}
                        </PlaceholderText>
                    ) : null}

                    <GINPUT
                        {...props}
                        type={
                            props.type === undefined
                                ? 'text'
                                : props.type === 'password' && visible
                                ? 'text'
                                : props.type
                        }
                        placeholder={props.type !== 'textarea' ? '' : props.placeholder}
                        value={currentValue()}
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        ref={props.xref}
                        readOnly={props.readOnly}
                        autoComplete={props.type !== 'password' ? 'off' : ''}
                        role={props.type !== 'password' ? 'presentation' : ''}
                    />
                    {props.type === 'password' ? (
                        <InputAction onClick={() => setVisible(!visible)}>
                            <InputIcon src={'/icons/eye.svg'} />
                        </InputAction>
                    ) : null}
                    {props.typeahead ? (
                        <InputAction onClick={closeDropDown}>
                            <InputIcon
                                src={
                                    searchExpression.length === 0
                                        ? '/icons/search.svg'
                                        : '/icons/close.svg'
                                }
                            />
                        </InputAction>
                    ) : null}
                    {props.icon ? (
                        <InputAction onClick={props.onClick}>
                            <InputIcon src={props.icon.icon} />
                        </InputAction>
                    ) : null}
                    {!props.typeahead || !focus ? null : (
                        <SelectBody>
                            {
                                //  props.typeahead

                                props.typeahead !== undefined && props.typeahead.length > 100
                                    ? searchExpression.length > 5
                                        ? props.typeahead
                                              ?.filter(filterExpression)
                                              ?.map((item, key) => (
                                                  <SelectBodyItem
                                                      key={key}
                                                      onClick={() => optionAction(item)}
                                                  >
                                                      {item?.title}
                                                  </SelectBodyItem>
                                              ))
                                        : []
                                    : props.typeahead
                                          ?.filter(filterExpression)
                                          ?.map((item, key) => (
                                              <SelectBodyItem
                                                  key={key}
                                                  onClick={() => optionAction(item)}
                                              >
                                                  {item?.title}
                                              </SelectBodyItem>
                                          ))
                            }
                        </SelectBody>
                    )}
                </InputContainer>
                {props.maxLength ? (
                    <InputMaxLength>
                        {props.value?.length}/{props.maxLength}
                    </InputMaxLength>
                ) : null}
            </>
        )
    }
}
