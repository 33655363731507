import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { toast } from 'react-toastify'
import { DoRegisterConsumer } from 'services/authentication'
import { exposeStrapiError, exposeStrapiErrorNew } from 'utils'

import ContainerUnauthenticated from 'containers/Unauthenticated'

import RegisterHeader from 'components/Register/Header'
import RegisterStepIndicator from 'components/Register/StepIndicator'
import RegisterConsumerStep1 from 'components/Register/Steps/Consumer/Step1'

export default function RegisterConsumer() {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const [loading, setLoading] = useState(false)

    const steps = [{ highlight: 'Consumer', title: 'Information' }]

    const [step, setStep] = useState(0)

    const [form, setForm] = useState({})
    const formValue = (ref) => {
        return form?.[ref] ? form?.[ref] : ''
    }
    const changeForm = (value, ref) => {
        setForm({ ...form, [ref]: value })
    }

    const back = () => {
        if (step === 0) {
            history.goBack()
        } else {
            setStep(step - 1)
        }
    }

    const next = (_form) => {
        const nxt = step + 1
        if (nxt < steps.length) {
            setStep(nxt)
        } else {
            action(_form)
        }
    }

    const action = async (_form) => {
        const _payload = {
            name: _form.name,
            email: _form.email,
            password: _form.password,
            phone: _form.phone,
            website: _form.website,
            address: {
                street: _form.street,
                city: _form.city,
                state: _form.state,
                country: _form.country,
                zipCode: _form.zipCode,
                latitude: _form.latitude,
                longitude: _form.longitude,
            },
        }

        setLoading(true)

        const result = await DoRegisterConsumer(_payload)

        setLoading(false)

        if (result && exposeStrapiErrorNew(result)) {
            completeLogin()
        }
    }

    const completeLogin = () => {
        toast.success('Account created')
        navigate('login')
    }

    const init = async () => {}

    useEffect(() => {
        init()
    }, [])

    return (
        <>
            <ContainerUnauthenticated>
                <RegisterHeader title={'Sign Up'} loading={loading} />
                <RegisterStepIndicator steps={steps} current={step + 1} active {...steps[step]} />
                {step === 0 ? (
                    <RegisterConsumerStep1
                        formValue={formValue}
                        changeForm={changeForm}
                        next={next}
                        loading={loading}
                        form={form}
                        setForm={setForm}
                    />
                ) : null}
            </ContainerUnauthenticated>
        </>
    )
}
