import React, { useState, useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import Select from 'react-select'
import Cards from './Cards/index'
import Box from './Box/index'
import {
    InputIcon,
} from './styled'

const Content = ({
    data,
    dataForTopCountries,
    loading,
    progress,
    setSelectedCountry,
    setSelectedRole,
    setSelectedProduct,
    selectedProduct,
    setSelectedState,
    selectedState,
    setSelectedCity,
    selectedCity,
    openModal,
}) => {
    const { findlanguage, setLanguage } = useContext(CoreContext)
    const countryOptions = [
        { value: '', label: 'Select a Country' },
        { value: 'oman', label: 'Oman' },
        { value: 'pakistan', label: 'Pakistan' },
        { value: 'peru', label: 'Peru' },
        { value: 'paraguay', label: 'Paraguay' },
        { value: 'latvia', label: 'Latvia' },
        { value: 'sweden', label: 'Sweden' },
        { value: 'czech republic', label: 'Czech Republic' },
        { value: "cote d'ivoire", label: "Cote d'Ivoire" },
        { value: 'belgium', label: 'Belgium' },
        { value: 'brazil', label: 'Brazil' },
        { value: 'philippines', label: 'Philippines' },
        { value: 'indonesia', label: 'Indonesia' },
        { value: 'argentina', label: 'Argentina' },
        { value: 'new zealand', label: 'New Zealand' },
        { value: 'chile', label: 'Chile' },
        { value: 'serbia', label: 'Serbia' },
        { value: 'morocco', label: 'Morocco' },
        { value: 'nigeria', label: 'Nigeria' },
        { value: 'turkey', label: 'Turkey' },
        { value: 'denmark', label: 'Denmark' },
        { value: 'mexico', label: 'Mexico' },
        { value: 'bulgaria', label: 'Bulgaria' },
        { value: 'maldives', label: 'Maldives' },
        { value: 'hungary', label: 'Hungary' },
        { value: 'kuwait', label: 'Kuwait' },
        { value: 'switzerland', label: 'Switzerland' },
        { value: 'cambodia', label: 'Cambodia' },
        { value: 'egypt', label: 'Egypt' },
        { value: 'ireland', label: 'Ireland' },
        { value: 'ecuador', label: 'Ecuador' },
        { value: 'netherlands', label: 'Netherlands' },
        { value: 'south africa', label: 'South Africa' },
        { value: 'ethiopia', label: 'Ethiopia' },
        { value: 'algeria', label: 'Algeria' },
        { value: 'italy', label: 'Italy' },
        { value: 'thailand', label: 'Thailand' },
        { value: 'canada', label: 'Canada' },
        { value: 'china', label: 'China' },
        { value: 'united states', label: 'United States' },
        { value: 'germany', label: 'Germany' },
        { value: 'norway', label: 'Norway' },
        { value: 'malaysia', label: 'Malaysia' },
        { value: 'india', label: 'India' },
        { value: 'france', label: 'France' },
        { value: 'spain', label: 'Spain' },
        { value: 'albania', label: 'Albania' },
        { value: 'united kingdom', label: 'United Kingdom' },
        { value: 'sri lanka', label: 'Sri Lanka' },
        { value: 'portugal', label: 'Portugal' },
        { value: 'singapore', label: 'Singapore' },
        { value: 'vietnam', label: 'Vietnam' },
        { value: 'australia', label: 'Australia' },
        { value: 'poland', label: 'Poland' },
        { value: 'slovakia', label: 'Slovakia' },
        { value: 'greece', label: 'Greece' },
        { value: 'united arab emirates', label: 'United Arab Emirates' },
        { value: 'uruguay', label: 'Uruguay' },
        { value: 'georgia', label: 'Georgia' },
        { value: 'colombia', label: 'Colombia' },
        { value: 'south korea', label: 'South Korea' },
        { value: 'finland', label: 'Finland' },
        { value: 'hong kong', label: 'Hong Kong' },
        { value: 'austria', label: 'Austria' },
        { value: 'romania', label: 'Romania' },
        { value: 'russia', label: 'Russia' },
        { value: 'japan', label: 'Japan' },
        { value: 'israel', label: 'Israel' },
    ]

    const sortedCountryOptions = countryOptions.sort((a, b) => a.label.localeCompare(b.label))

    const optionsWithDefault = [{ value: '', label: 'Select a Country' }, ...sortedCountryOptions]

    const optionsRole = [
        { value: '', label: findlanguage().selectARole },
        { value: 'Buyer', label: findlanguage().buyerTitle },
        { value: 'Service Provider', label: findlanguage().serviceProviderTitle },
        { value: 'Supplier', label: findlanguage().supplierTitle },
    ]

    const [selectedOption, setSelectedOption] = useState(
        optionsWithDefault.find((option) => option.value === 'united states')
    )

    const LoadingBar = ({ progress }) => (
        <div style={{ width: '50%' }}>
            <div className="loading-bar-outer">
                <div className="loading-bar-inner" style={{ width: `${progress}%` }}>
                    {progress.toFixed(0)}%
                </div>
            </div>
            {progress > 80
                ? findlanguage().almostFinished
                : findlanguage().encryptingData}
        </div>
    )

    const handleClick = (value) => {
        window.location.href = value
    }

    const changeLanguage = (changeLanguage) => {

        localStorage.setItem('language', changeLanguage)
        setLanguage(changeLanguage)

        setTimeout(() => {
            var selectField = document.querySelector(".goog-te-combo");
            const languageCode = changeLanguage === 'english' ? 'en' : 'pt'

            selectField.value = languageCode;
            selectField.dispatchEvent(new Event('change'));
            selectField.value = languageCode;
            selectField.dispatchEvent(new Event('change'));
            selectField.value = languageCode;
            selectField.dispatchEvent(new Event('change'));
            selectField.value = languageCode;
            selectField.dispatchEvent(new Event('change'));


        }, "1000");
    }

    return (
        <div className="content">
            <div className="row">
                <div className="col-3" style={{ display: 'inline-flex' }}>
                    <img className="logo-filter" src="/icons/logo-text.png"></img>
                    <div style={{ marginTop: '7px' }}>
                        <InputIcon
                            onClick={() => changeLanguage('english')}
                            src={'/icons/usa-flag.svg'}
                        />
                        <InputIcon
                            onClick={() => changeLanguage('portugues')}
                            src={'/icons/brazil-flag.svg'}
                        />
                    </div>
                </div>
                <div className="col-3">
                </div>
                <div className="col-6">
                    <button className="btn button-back" onClick={() => handleClick('https://www.mytrustedsc.com/')}>
                        {findlanguage().backToWebsite}
                    </button>
                    <button className="btn button-contact" onClick={() => handleClick('https://www.mytrustedsc.com/contact-us')}>
                        {findlanguage().contact}
                    </button>
                </div>
            </div>
            <div className="row solid-line-row">
                <div className="col-12 solid-line"></div>
            </div>
            <>
                {loading ? (
                    <div className="loading-container">
                        <LoadingBar progress={progress} />
                    </div>
                ) : null}
            </>
            {loading ? null : (
                <>
                    <div className="row row-selects">
                        <div className="col-3 select-container">
                            <label>{findlanguage().products}</label>
                            <input
                                id="product-input"
                                type="text"
                                className="form-control"
                                value={selectedProduct}
                                onChange={(e) => setSelectedProduct(e.target.value)}
                                placeholder={findlanguage().enterProduct}
                            />
                        </div>
                        <div className="col-3 select-container">
                            <label>{findlanguage().country}</label>
                            <Select
                                inputId="country-select"
                                options={optionsWithDefault}
                                onChange={(value) => {
                                    setSelectedCountry(value)
                                    setSelectedOption(value)
                                }}
                                isSearchable={true}
                                placeholder={findlanguage().selectACountry}
                                value={selectedOption} // Set the selected option state as the value
                            />
                        </div>
                        <div className="col-2 select-container">
                            <label>{findlanguage().state}</label>
                            <input
                                id="state-input"
                                type="text"
                                className="form-control"
                                value={selectedState}
                                onChange={(e) => setSelectedState(e.target.value)}
                                placeholder={findlanguage().enterState}
                            />
                        </div>
                        <div className="col-2 select-container">
                            <label>{findlanguage().city}</label>
                            <input
                                id="city-input"
                                type="text"
                                className="form-control"
                                value={selectedCity}
                                onChange={(e) => setSelectedCity(e.target.value)}
                                placeholder={findlanguage().enterCity}
                            />
                        </div>
                        <div className="col-2 select-container">
                            <label>{findlanguage().role}</label>
                            <Select
                                inputId="role-select"
                                options={optionsRole}
                                onChange={setSelectedRole}
                                isSearchable={true}
                                placeholder={findlanguage().selectARole}
                            />
                        </div>
                    </div>
                </>
            )}
            <div className="boxes">
                <Box dataForTopCountries={dataForTopCountries} whichBox={1} loading={loading} />
                <Box dataForTopCountries={data} whichBox={2} loading={loading} />
            </div>
            {loading ? null : (
                <>

                    <Cards findedUsers={data} progress={progress} openModal={openModal} />
                </>
            )}
        </div>
    )
}

export default Content
