import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const Division = styled.div.attrs({})`
    flex: 0.5;
    ${(props) => `
        margin-bottom: ${props.space || 12}px
    `}
`
export const Division2 = styled.div.attrs({})`
    flex: 1.5;
    ${(props) => `
        margin-bottom: ${props.space || 12}px
    `}
`

export const CardSearched = styled.div.attrs({})`
    min-width: calc(33.33% - 12.5px);
    max-width: calc(33.33% - 12.5px);
    background: var(--white-color);
    padding: 24px;
    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
    box-shadow: 0 3px 8px #00000011;
    display: flex;
    align-items: center;
    gap: 12px;

    @media (max-width: ${mobileSize}px) {
        min-width: calc(100%);
        max-width: calc(100%);
    }
`

export const CardText = styled.div.attrs({})`
    font-size: 16px;
    ${(props) => `
    color: ${props.draft ? '#959595;' : '#303030;'}
    `}
    flex: 1;
    font-weight: 600;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`

export const PageTitle = styled.div.attrs({})`
    font-size: 17px;
    color: #303030;
    font-weight: 600;
`

export const PageDescription = styled.div.attrs({})`
    font-size: 16px;
    color: #959595;
    font-weight: 600;
`

export const ButtonContainer = styled.div.attrs({})`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
`

export const ProcessesContainer = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`

export const PageCard = styled.div.attrs({})`
    background: var(--white-color);
    padding: 24px;
    margin-top: 28px;
    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
`

export const IconButton = styled.div.attrs({})`
    background: #303030;
    border-radius: 99px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

export const ContainerDivider = styled.div.attrs({})`
    display: flex;
    gap: 32px;
`

export const TrashIcon = styled.img.attrs({
    src: '/icons/trash-white.svg',
})`
    width: 16px;
    height: 16px;
`

export const EditIcon = styled.img.attrs({
    src: '/icons/edit.svg',
})`
    width: 16px;
    height: 16px;
`
export const ViewIcon = styled.img.attrs({
    src: '/icons/eye-white.svg',
})`
    width: 16px;
    height: 16px;
`

export const PlusIcon = styled.img.attrs({
    src: '/icons/circle-add.svg',
})`
    width: 16px;
    height: 16px;
    margin-right: 8px;
    position: relative;
    top: -1px;
`

export const BackIcon = styled.img.attrs({
    src: '/icons/back.svg',
})`
    width: 16px;
    height: 16px;
    margin: 8px;
    cursor: pointer;
`
export const CardHeader = styled.div.attrs({})`
    padding: 20px 0;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--primary-color);
    border-bottom: 1px solid var(--lightshadow-color);

    display: flex;
    justify-content: space-between;
`
