import styled from 'styled-components'

export const BodyContentTabs = styled.div.attrs({})`
    display: flex;
    align-items: center;
    margin-top: 5px;
    padding-bottom: 10px;
`
export const BodyTab = styled.div.attrs({})`
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: #5c5c5c;
    margin-right: 30px;

    cursor: pointer;

    ${(props) =>
        props.active
            ? `
            text-decoration: underline;
            color: var(--primary-color);
        `
            : ``}
`
