import React, { useEffect, useState } from 'react'
import { PageCard, CardHeader, CardHeaderTitleContainer } from './styled'

import { GetCertifications, PutCertifications, PostCertifications } from 'services/egs'
import { UpdateCertifications } from 'services/api'

import { ReadMe } from 'services/authentication'
import _ from 'lodash'

const borderLeft = '1px solid #263640'
const readGreenCss = {
    whiteSpace: 'pre-line',
    fontWeight: 'bold',
    fontSize: '15px',
    color: '#263640',
}
const readRedCss = { whiteSpace: 'pre-line', fontWeight: 'bold', fontSize: '15px', color: 'red' }
const readGreenTitleCss = {
    whiteSpace: 'pre-line',
    fontWeight: 'bold',
    fontSize: '15px',
    color: '#263640',
    marginTop: '-45px',
}
const readRedTitleCss = {
    whiteSpace: 'pre-line',
    fontWeight: 'bold',
    fontSize: '15px',
    color: 'red',
    marginTop: '-45px',
}
const readBlackBoldCss = {
    whiteSpace: 'pre-line',
    fontWeight: 'bold',
    fontSize: '15px',
    color: '#565656',
}
const readBlackCss = { whiteSpace: 'pre-line', fontWeight: '', fontSize: '15px', color: '#565656' }
const divStile = { borderLeft: borderLeft, fontSize: '700', color: '#707070' }
const textAreaStyle = { marginBottom: '18px' }

export default function SelfAssessmentChecklist({ form }) {
    const [certifications, setCertifications] = useState([])

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        const readMe = await ReadMe()

        const getCertifications = await GetCertifications(readMe.company_id)
        if (getCertifications.statusText !== 'Not Found' && getCertifications.length !== 0)
            setCertifications(getCertifications)
    }

    const onChangeCertifications = (value, itemRef, field) => {
        let newArr = [...certifications]
        let item = newArr.find((x) => x.category === itemRef)

        if (item === undefined) {
            newArr.push({
                type: 'type',
                category: itemRef,
                comments: '',
                grade: false,
            })

            item = newArr.find((x) => x.category === itemRef)
        }

        switch (field) {
            case 'grade':
                if (value === false) {
                    item.comments = ''
                    item.attach_document = null
                }

                item.grade = value

                saveCertifications(item)
                break
            case 'comments':
                item.comments = value
                break
            case 'category_type':
                item.category_type = JSON.stringify(value)
                break
        }

        setCertifications(newArr)
    }

    const saveCertifications = async (certification) => {
        if (certification.id > 0) {
            await PutCertifications(certification)
        } else {
            const readMe = await ReadMe()

            const newCertificationLine = await PostCertifications({
                ...certification,
                users_permissions_user: readMe.company_id,
            })

            if (
                certifications.find((x) => x.category === newCertificationLine.category) ===
                undefined
            )
                certifications.push(newCertificationLine)
            else
                certifications.find((x) => x.category === newCertificationLine.category).id =
                    newCertificationLine.id

            setCertifications(certifications)
        }
    }

    const saveDocument = async (ref) => {
        await PutCertifications({
            ...certifications?.find((x) => x.category === ref),
            ['attach_document']: null,
        })

        const formData = new FormData()

        if (document.getElementById(ref)?.files?.[0]) {
            new Promise((resolve, reject) => {
                let reader = new FileReader()
                reader.onload = async () => {
                    let source = `${reader.result}`.split(',')[1]
                    let image = {
                        filename: document.getElementById(ref)?.files?.[0].name,
                        filetype: document.getElementById(ref)?.files?.[0].type,
                        source,
                    }

                    let btc = window.atob(image.source)
                    let btn = new Array(btc.length)
                    for (var i = 0; i < btc.length; i++) {
                        btn[i] = btc.charCodeAt(i)
                    }
                    var bta = new Uint8Array(btn)
                    let boobs = new Blob([bta], { type: 'image/png' })
                    let filesss = new File([boobs], `${image.filename}`)

                    formData.append('attach_document', filesss, filesss.name)
                    formData.append('id', certifications?.find((x) => x.category === ref)?.id)
                    formData.append('filename', 'attach_document')

                    await UpdateCertifications(formData)

                    let certificationsClone = _.cloneDeep(certifications)

                    certificationsClone = certificationsClone.map((x) => {
                        if (x.category === ref) {
                            x.attach_document = document.getElementById(ref)?.files[0]
                        }

                        return x
                    })

                    setCertifications(certificationsClone)
                }
                reader.readAsDataURL(document.getElementById(ref)?.files?.[0])
            })
        }
    }

    const legalRequirements = [
        {
            ref: 'A.1',
            requirementTitle: 'ACTIVE COMPANY PROOF -',
            requirementText:
                'The company must send proof that it is duly active; if it is a company, send the EIN/Tax ID number.',
            extraRefText: true,
        },
        {
            ref: 'A.2',
            requirementTitle: 'BUSINESS LICENSE -',
            requirementText:
                'The company must present a valid Operating License provided by the local regulatory agency. ',
            extraRefText: true,
        },
        {
            ref: 'A.3',
            requirementTitle: 'SANITARY LICENSE -',
            requirementText:
                'The company must send the Health Permit issued by the local regulatory agency and, when applicable, FDA Approval, USDA Approval, etc.',
            extraRefText: true,
        },
        {
            ref: 'A.4',
            requirementTitle: 'FIRE SUPPRESSION SYSTEM',
            requirementText:
                'Are annual inspections of the fire suppression system conducted by a licensed contractor or the local fire department?',
            extraRefText: false,
        },
        {
            ref: 'A.5',
            requirementTitle: 'DOES THE COMPANY HAVE INDEMNITY INSURANCE?-',
            requirementText:
                'Does the company have indemnity insurance to cover legal costs or other liabilities that may result from claims or lawsuits related to your product?',
            extraRefText: false,
        },
    ]

    const foodSafety = [
        {
            ref: 'B.1',
            requirementTitle: `SPECIFICATIONS INCLUDING PRODUCT RELEASE -
Does the company have adequate product release procedures in place?`,
            requirementText: `The company will ensure that product specifications are adequate, accurate and ensure compliance with relevant safety, legal and customer requirements.`,
            extraRefText: false,
        },
        {
            ref: 'B.2',
            requirementTitle: `VULNERABILITY ASSESSMENT - Assessment of ingredients for economically motivated substitution, dilution `,
            requirementText: `The company needs to have a program that verifies its suppliers. Are ingredients evaluated based on the history of issues and risks associated with economic fraud activity?`,
            extraRefText: false,
        },
        {
            ref: 'B.3',
            requirementTitle: `TRACEABILITY / RECALL PROGRAM
Does the company have a traceability system and also perform mock recalls? How often do they perform Mock Recalls?`,
            requirementText: `The company will need to establish a traceability system that allows identification of product batches and their relationship to batches of raw materials, primary and final packaging materials, and processing and distribution records. Records will include:
• Identification of any product, ingredient or service provided.
• Records of batches of in-process or final products and packaging throughout the production process.
• Purchaser and delivery destination records for all products supplied.
• At least annualy, simulated recalls.`,
            extraRefText: false,
        },
        {
            ref: 'B.4',
            requirementTitle: `NON-CONFORMING PRODUCT CONTROL
Does the company have a procedure for handling non-conforming products?`,
            requirementText: `The company will ensure that any product that does not conform to requirements is clearly identified and controlled to prevent improper use or delivery.`,
            extraRefText: false,
        },
        {
            ref: 'B.5',
            requirementTitle: `cGMPs - PERSONAL HYGIENE
Does the company have a procedure or guidelines on personal hygiene for employees and visitors?`,
            requirementText: `The company will ensure proper hygienic practices are implemented for all its staff and visitors.
Personal hygiene practices must be in place for employees and visitors:
• Personal hygiene signage (pictures or words), clearly displayed in the worker's native language;
• Emphasizes required training on personal hygiene practices in addition to specific practices for communicable illness;
•The workers and visitors shall have access to toilets and handwashing facilities from the field through post-harvest areas;
Such practices will result in hygienic handling and delivery of quality and safe products to customers.
The facility will follow the Codex Alimentarius Commission's recommendations on personal will be followed by the facility.`,
            extraRefText: false,
        },
        {
            ref: 'B.6',
            requirementTitle: `USE OF WATER -
Is the water used safe and of adequate sanitary quality?`,
            requirementText: `Is the water used safe and of adequate sanitary quality?
All water used must be safe and of adequate sanitary quality for its intended use.
The producer must ensure that the water in contact with the food is potable.
Note: (Applicable only if the company has field production and processing of the product)`,
            extraRefText: false,
        },
        {
            ref: 'B.7',
            requirementTitle: `EMPLOYEE FOOD SAFETY TRAINING PROGRAM`,
            requirementText: `Does the company train all people in food safety and practices according to their job responsibilities?
a) All new people performing work that affects product safety, legality, and quality shall have the required competence by education, work experience, and training that
matches their work based on risk assessment.
b) Training should address both personal health and safety and relevant food safety issues with a focus on avoiding contamination and cross-contact.
c) All people (management, full-time, part-time, or temporary) shall receive relevant training.
d) Each qualification or competency related to best practices and food safety shall be systematically "refreshed" and confirmed.
`,
            extraRefText: false,
        },
        {
            ref: 'B.8',
            requirementTitle: `CLEANING AND DISINFECTION
Does the company have a procedure or guidelines on cleaning and disinfection of the work environment and production area?`,
            requirementText: `The company will ensure that adequate cleaning and disinfection standards are maintained at all times and at all stages of production.`,
            extraRefText: false,
        },
        {
            ref: 'B.9',
            requirementTitle: `HACCP or FSMA Based Food Safety Plan
Does the company have a fully vetted Food Safety Plan`,
            requirementText: `The company will need to establish a Food Safety Preventive Control Plan. This will include a Product Description, Process Flow Diagram, Hazard Analysis and Preventive Control Plan including Process Preventive Controls, Sanitation Process Controls and Supply Chain Process Controls.`,
            extraRefText: false,
        },
        {
            ref: 'B.9.1',
            requirementTitle: `Are CCP/Process PC Verification records (single day) available? `,
            requirementText: ``,
            extraRefText: false,
        },
        {
            ref: 'B.10',
            requirementTitle: `ALLERGENS & ALLERGEN LABELING `,
            requirementText: `Have all 9 USA Allergens been assessed and documented in an Allergen Control Program? Where it applies allergen labeling completed and verified?
The company has assessed all incoming raw materials and process and have developed a program that controls allergen cross-contact in storage, process lines, and finished product packaging. 
Note* Labels must be sent upon request`,
            extraRefText: false,
        },
    ]

    const specificQuestion = [
        {
            ref: 'C.1',
            refText: 'Acidified Foods',
            requirementTitle: `Does the company follow US FDA Regulations Part 114 Acidified Foods of Code of Federal Regulations (CFR)`,
            extraRefText: false,
        },
        {
            ref: 'C.2',
            refText: 'Alcohol, and related ingredients',
            requirementTitle: `Does the company registered for Alcoholic Beverages under Section 415 of Federal Fods??`,
            extraRefText: false,
        },
        {
            ref: 'C.3',
            refText: 'Bottled Drinking Water',
            requirementTitle: `Does the company follow Bottle Drinking Water Rule, following Part 129  of Code of Federal Regulations (CFR)`,
            extraRefText: false,
        },
        {
            ref: 'C.4',
            refText: 'Infant Formula',
            requirementTitle: `Does the company follow all requirements of the USDA Infant Formulae Rule, Parts 106 and 107 of Code of Federal Regulations (CFR)`,
            extraRefText: false,
        },
        {
            ref: 'C.5',
            refText: 'Low Acid Canned Foods',
            requirementTitle: `Does the company have the LACF Certificate in place? Must follow Part 113 of Certificate of Code of Federal Regulations (CFR)`,
            extraRefText: false,
        },
        {
            ref: 'C.5',
            refText: 'Shell eggs from single facility, < 3,000 Hens',
            requirementTitle: `Does the company follow USDA Regulations Shell Eggs Rule if >3,000 hens? Must follow Part 118 of Certificate of Code of Federal Regulations (CFR)`,
            extraRefText: false,
        },
    ]

    return (
        <>
            <PageCard>
                <CardHeader>
                    <CardHeaderTitleContainer>
                        Self-Assessment Checklist - MyTS Requirements
                    </CardHeaderTitleContainer>
                </CardHeader>
                <div className="row">
                    <div className="col-12">
                        <p style={readGreenCss}>A. LEGAL REQUIREMENTS </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-2">
                        <label className="form-label" style={readBlackBoldCss}>
                            #
                        </label>
                    </div>
                    <div className="col-3">
                        <label className="form-label" style={readBlackBoldCss}>
                            REQUIREMENT
                        </label>
                    </div>
                    <div className="col-2">
                        <label className="form-label" style={readBlackBoldCss}>
                            GRADE
                        </label>
                    </div>
                    <div className="col-5">
                        <label className="form-label" style={readBlackBoldCss}>
                            COMMENTS
                        </label>
                    </div>
                </div>
                {legalRequirements.map((legalRequirements) => (
                    <div className="row">
                        <div className="col-2">
                            <label className="form-label" style={readBlackCss}>
                                {legalRequirements.extraRefText ? (
                                    <>
                                        <div style={readGreenCss}>
                                            {legalRequirements.ref} - K.O
                                        </div>
                                        <b>Mandatory.</b> Documentation required
                                    </>
                                ) : (
                                    <div style={readGreenCss}>{legalRequirements.ref}</div>
                                )}
                            </label>
                        </div>
                        <div className="col-3 mb-3" style={divStile}>
                            <label className="form-label" style={readBlackCss}>
                                <b>{legalRequirements.requirementTitle}</b>
                                <br />
                                {legalRequirements.requirementText}
                            </label>
                        </div>
                        <div className="col-2 mb-3" style={divStile}>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                value={
                                    certifications?.find(
                                        (x) => x.category === legalRequirements.ref
                                    )?.grade === undefined
                                        ? false
                                        : certifications?.find(
                                              (x) => x.category === legalRequirements.ref
                                          )?.grade
                                }
                                onChange={(event) =>
                                    onChangeCertifications(
                                        event.target.value === 'true',
                                        legalRequirements.ref,
                                        'grade'
                                    )
                                }
                                disabled={form}
                            >
                                <option value={false}>No</option>
                                <option value={true}>Yes</option>
                            </select>
                        </div>
                        <div className="col-5 mb-3" style={divStile}>
                            <textarea
                                disabled={
                                    form ??
                                    certifications?.find(
                                        (x) => x.category === legalRequirements.ref
                                    )?.grade === undefined
                                        ? true
                                        : !certifications?.find(
                                              (x) => x.category === legalRequirements.ref
                                          )?.grade
                                }
                                className="form-control"
                                placeholder="*Please submit your most up-to-date procedure/evidence"
                                style={textAreaStyle}
                                value={
                                    certifications?.find(
                                        (x) => x.category === legalRequirements.ref
                                    )?.grade === undefined
                                        ? ''
                                        : certifications?.find(
                                              (x) => x.category === legalRequirements.ref
                                          )?.comments
                                }
                                onChange={(event) =>
                                    onChangeCertifications(
                                        event.target.value,
                                        legalRequirements.ref,
                                        'comments'
                                    )
                                }
                                onBlur={() =>
                                    saveCertifications(
                                        certifications?.find(
                                            (x) => x.category === legalRequirements.ref
                                        )
                                    )
                                }
                            ></textarea>
                            {!form ??
                            (certifications?.find((x) => x.category === legalRequirements.ref)
                                ?.grade !== undefined &&
                                certifications?.find((x) => x.category === legalRequirements.ref)
                                    ?.grade !== false) ? (
                                <>
                                    {certifications?.find(
                                        (x) => x.category === legalRequirements.ref
                                    )?.attach_document === undefined ||
                                    certifications?.find(
                                        (x) => x.category === legalRequirements.ref
                                    )?.attach_document === null ? (
                                        <>
                                            <label
                                                for={legalRequirements.ref}
                                                style={{
                                                    fontSize: '13px',
                                                    color: 'white',
                                                    height: '35px',
                                                    padding: '10px',
                                                    width: '100%',
                                                    backgroundColor: '#263640',
                                                    cursor: 'pointer',
                                                }}
                                                className="badge"
                                            >
                                                Open File
                                            </label>
                                            <input
                                                type="file"
                                                id={legalRequirements.ref}
                                                onChange={() => saveDocument(legalRequirements.ref)}
                                                style={{ display: 'none' }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <label
                                                for={legalRequirements.ref}
                                                style={{
                                                    fontSize: '13px',
                                                    color: 'white',
                                                    height: '35px',
                                                    padding: '10px',
                                                    width: '100%',
                                                    backgroundColor: '#263640',
                                                    cursor: 'pointer',
                                                }}
                                                className="badge"
                                            >
                                                Change File
                                            </label>

                                            <div style={{ marginTop: '6px', textAlign: 'center' }}>
                                                {
                                                    certifications?.find(
                                                        (x) => x.category === legalRequirements.ref
                                                    )?.attach_document?.name
                                                }
                                            </div>
                                            <input
                                                type="file"
                                                id={legalRequirements.ref}
                                                onChange={() => saveDocument(legalRequirements.ref)}
                                                style={{ display: 'none' }}
                                            />
                                        </>
                                    )}
                                </>
                            ) : (
                                <div style={{ textAlign: 'center' }}>
                                    {
                                        certifications?.find(
                                            (x) => x.category === legalRequirements.ref
                                        )?.attach_document?.name
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </PageCard>

            <PageCard>
                <div className="row">
                    <div className="col-12">
                        <p style={readGreenCss}>B.FOOD SAFETY</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-2">
                        <label className="form-label" style={readBlackBoldCss}>
                            #
                        </label>
                    </div>
                    <div className="col-3">
                        <label className="form-label" style={readBlackBoldCss}>
                            REQUIREMENT
                        </label>
                    </div>
                    <div className="col-2">
                        <label className="form-label" style={readBlackBoldCss}>
                            GRADE
                        </label>
                    </div>
                    <div className="col-5">
                        <label className="form-label" style={readBlackBoldCss}>
                            COMMENTS
                        </label>
                    </div>
                </div>
                {foodSafety.map((foodSafetyItem) => (
                    <div className="row">
                        <div className="col-2">
                            <label className="form-label" style={readBlackCss}>
                                {foodSafetyItem.extraRefText ? (
                                    <>
                                        <div style={readGreenCss}>{foodSafetyItem.ref} - K.O</div>
                                        <b>Mandatory.</b> Documentation required
                                    </>
                                ) : (
                                    <div style={readGreenCss}>{foodSafetyItem.ref}</div>
                                )}
                            </label>
                        </div>
                        <div className="col-3 mb-3" style={divStile}>
                            <label className="form-label" style={readBlackCss}>
                                <b>{foodSafetyItem.requirementTitle}</b>
                                <br />
                                {foodSafetyItem.requirementText}
                            </label>
                        </div>
                        <div className="col-2 mb-3" style={divStile}>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                value={
                                    certifications?.find((x) => x.category === foodSafetyItem.ref)
                                        ?.grade === undefined
                                        ? false
                                        : certifications?.find(
                                              (x) => x.category === foodSafetyItem.ref
                                          )?.grade
                                }
                                onChange={(event) =>
                                    onChangeCertifications(
                                        event.target.value === 'true',
                                        foodSafetyItem.ref,
                                        'grade'
                                    )
                                }
                                onBlur={() =>
                                    saveCertifications(
                                        certifications?.find(
                                            (x) => x.category === foodSafetyItem.ref
                                        )
                                    )
                                }
                                disabled={form}
                            >
                                <option value={false}>No</option>
                                <option value={true}>Yes</option>
                            </select>
                        </div>
                        <div className="col-5 mb-3" style={divStile}>
                            <textarea
                                className="form-control"
                                placeholder="*Please submit your most up-to-date procedure/evidence"
                                style={textAreaStyle}
                                value={
                                    certifications?.find((x) => x.category === foodSafetyItem.ref)
                                        ?.grade === undefined
                                        ? ''
                                        : certifications?.find(
                                              (x) => x.category === foodSafetyItem.ref
                                          )?.comments
                                }
                                onChange={(event) =>
                                    onChangeCertifications(
                                        event.target.value,
                                        foodSafetyItem.ref,
                                        'comments'
                                    )
                                }
                                onBlur={() =>
                                    saveCertifications(
                                        certifications?.find(
                                            (x) => x.category === foodSafetyItem.ref
                                        )
                                    )
                                }
                                disabled={
                                    form ??
                                    certifications?.find((x) => x.category === foodSafetyItem.ref)
                                        ?.grade === undefined
                                        ? true
                                        : !certifications?.find(
                                              (x) => x.category === foodSafetyItem.ref
                                          )?.grade
                                }
                            ></textarea>
                            {!form ??
                            (certifications?.find((x) => x.category === foodSafetyItem.ref)
                                ?.grade !== undefined &&
                                certifications?.find((x) => x.category === foodSafetyItem.ref)
                                    ?.grade !== false) ? (
                                <>
                                    {certifications?.find((x) => x.category === foodSafetyItem.ref)
                                        ?.attach_document === undefined ||
                                    certifications?.find((x) => x.category === foodSafetyItem.ref)
                                        ?.attach_document === null ? (
                                        <>
                                            <label
                                                for={foodSafetyItem.ref}
                                                style={{
                                                    fontSize: '13px',
                                                    color: 'white',
                                                    height: '35px',
                                                    padding: '10px',
                                                    width: '100%',
                                                    backgroundColor: '#263640',
                                                    cursor: 'pointer',
                                                }}
                                                className="badge"
                                            >
                                                Open File
                                            </label>
                                            <input
                                                type="file"
                                                id={foodSafetyItem.ref}
                                                onChange={() => saveDocument(foodSafetyItem.ref)}
                                                style={{ display: 'none' }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <label
                                                for={foodSafetyItem.ref}
                                                style={{
                                                    fontSize: '13px',
                                                    color: 'white',
                                                    height: '35px',
                                                    padding: '10px',
                                                    width: '100%',
                                                    backgroundColor: '#263640',
                                                    cursor: 'pointer',
                                                }}
                                                className="badge"
                                            >
                                                Change File
                                            </label>

                                            <div style={{ marginTop: '6px', textAlign: 'center' }}>
                                                {
                                                    certifications?.find(
                                                        (x) => x.category === foodSafetyItem.ref
                                                    )?.attach_document?.name
                                                }
                                            </div>
                                            <input
                                                type="file"
                                                id={foodSafetyItem.ref}
                                                onChange={() => saveDocument(foodSafetyItem.ref)}
                                                style={{ display: 'none' }}
                                            />
                                        </>
                                    )}
                                </>
                            ) : null}
                        </div>
                    </div>
                ))}
            </PageCard>

            <PageCard>
                <div className="row">
                    <div className="col-12">
                        <p style={readGreenCss}>C.SPECIFIC QUESTION PER CATEGORY </p>
                        <p style={readGreenCss}>
                            Select only the categories that best fit in your industry
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-2">
                        <label className="form-label" style={readBlackBoldCss}>
                            #
                        </label>
                    </div>
                    <div className="col-3">
                        <label className="form-label" style={readBlackBoldCss}>
                            REQUIREMENT
                        </label>
                    </div>
                    <div className="col-2">
                        <label className="form-label" style={readBlackBoldCss}>
                            GRADE
                        </label>
                    </div>
                    <div className="col-5">
                        <label className="form-label" style={readBlackBoldCss}>
                            COMMENTS
                        </label>
                    </div>
                </div>
                {specificQuestion.map((specificQuestionItem) => {
                    return (
                        <div className="row">
                            <div className="col-2">
                                <label className="form-label" style={readBlackCss}>
                                    <>
                                        <div style={readGreenCss}>{specificQuestionItem.ref}</div>{' '}
                                        <b>{specificQuestionItem.refText}</b>
                                    </>
                                </label>
                            </div>
                            <div className="col-3 mb-3" style={divStile}>
                                <label className="form-label" style={readBlackCss}>
                                    <b>{specificQuestionItem.requirementTitle}</b>
                                    <br />
                                    {specificQuestionItem.requirementText}
                                </label>
                            </div>
                            <div className="col-2 mb-3" style={divStile}>
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    value={
                                        certifications?.find(
                                            (x) => x.category === specificQuestionItem.ref
                                        )?.grade === undefined
                                            ? false
                                            : certifications?.find(
                                                  (x) => x.category === specificQuestionItem.ref
                                              )?.grade
                                    }
                                    onChange={(event) =>
                                        onChangeCertifications(
                                            event.target.value === 'true',
                                            specificQuestionItem.ref,
                                            'grade'
                                        )
                                    }
                                    onBlur={() =>
                                        saveCertifications(
                                            certifications?.find(
                                                (x) => x.category === specificQuestionItem.ref
                                            )
                                        )
                                    }
                                    disabled={form}
                                >
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                                </select>
                            </div>
                            <div className="col-5 mb-3" style={divStile}>
                                <textarea
                                    className="form-control"
                                    placeholder="*Please submit your most up-to-date procedure/evidence"
                                    style={textAreaStyle}
                                    value={
                                        certifications?.find(
                                            (x) => x.category === specificQuestionItem.ref
                                        )?.grade === undefined
                                            ? ''
                                            : certifications?.find(
                                                  (x) => x.category === specificQuestionItem.ref
                                              )?.comments
                                    }
                                    onChange={(event) =>
                                        onChangeCertifications(
                                            event.target.value,
                                            specificQuestionItem.ref,
                                            'comments'
                                        )
                                    }
                                    onBlur={() =>
                                        saveCertifications(
                                            certifications?.find(
                                                (x) => x.category === specificQuestionItem.ref
                                            )
                                        )
                                    }
                                    disabled={
                                        form ??
                                        certifications?.find(
                                            (x) => x.category === specificQuestionItem.ref
                                        )?.grade === undefined
                                            ? true
                                            : !certifications?.find(
                                                  (x) => x.category === specificQuestionItem.ref
                                              )?.grade
                                    }
                                ></textarea>
                                {!form ??
                                (certifications?.find(
                                    (x) => x.category === specificQuestionItem.ref
                                )?.grade !== undefined &&
                                    certifications?.find(
                                        (x) => x.category === specificQuestionItem.ref
                                    )?.grade !== false) ? (
                                    <>
                                        {certifications?.find(
                                            (x) => x.category === specificQuestionItem.ref
                                        )?.attach_document === undefined ||
                                        certifications?.find(
                                            (x) => x.category === specificQuestionItem.ref
                                        )?.attach_document === null ? (
                                            <>
                                                <label
                                                    for={specificQuestionItem.ref}
                                                    style={{
                                                        fontSize: '13px',
                                                        color: 'white',
                                                        height: '35px',
                                                        padding: '10px',
                                                        width: '100%',
                                                        backgroundColor: '#263640',
                                                        cursor: 'pointer',
                                                    }}
                                                    className="badge"
                                                >
                                                    Open File
                                                </label>
                                                <input
                                                    type="file"
                                                    id={specificQuestionItem.ref}
                                                    onChange={() =>
                                                        saveDocument(specificQuestionItem.ref)
                                                    }
                                                    style={{ display: 'none' }}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <label
                                                    for={specificQuestionItem.ref}
                                                    style={{
                                                        fontSize: '13px',
                                                        color: 'white',
                                                        height: '35px',
                                                        padding: '10px',
                                                        width: '100%',
                                                        backgroundColor: '#263640',
                                                        cursor: 'pointer',
                                                    }}
                                                    className="badge"
                                                >
                                                    Change File
                                                </label>

                                                <div
                                                    style={{
                                                        marginTop: '6px',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {
                                                        certifications?.find(
                                                            (x) =>
                                                                x.category ===
                                                                specificQuestionItem.ref
                                                        )?.attach_document?.name
                                                    }
                                                </div>
                                                <input
                                                    type="file"
                                                    id={specificQuestionItem.ref}
                                                    onChange={() =>
                                                        saveDocument(specificQuestionItem.ref)
                                                    }
                                                    style={{ display: 'none' }}
                                                />
                                            </>
                                        )}
                                    </>
                                ) : null}
                            </div>
                        </div>
                    )
                })}
            </PageCard>
        </>
    )
}
