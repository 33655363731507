import React from 'react'

import Button from 'components/Form/Button'

import {
    ModalHeader,
    ModalHeaderSpace,
    ModalHeaderClose,
    ModalBody,
    ModalTitle,
    ModalDescription,
    ModalLoad,
} from './styled'

export default function ModalChatModeLoading({ handleStop, minimize, currentChat }) {
    return (
        <>
            <ModalHeader>
                <ModalHeaderSpace />
                <ModalHeaderClose min onClick={minimize} />
            </ModalHeader>

            <ModalBody>
                <ModalLoad />
                <ModalTitle>Soon one of our attendants will join the chat</ModalTitle>
                <ModalDescription>
                    Wait a little bit <b>{currentChat?.name}</b>
                </ModalDescription>

                <Button small red onClick={handleStop}>
                    Stop Chat
                </Button>
            </ModalBody>
        </>
    )
}
