import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { parseStrapiImage } from 'utils'

import {
    BodyContentTable,
    TableLine,
    LineText,
    LineLinks,
    LinkContent,
    LinkIcon,
    LinkText,
    LineCheckItem,
    LineCheckItemIn,
    LineInfos,
} from './styled'

export default function DashboardUserDetailsTabFoodSafety({ infos }) {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)
    const back = () => history.goBack()

    return (
        <>
            <BodyContentTable>
                {infos?.map((item, key) => (
                    <TableLine key={key} odd={key % 2 === 0}>
                        <LineInfos>
                            <LineCheckItem checked={item?.public}>
                                <LineCheckItemIn />
                            </LineCheckItem>
                            <LineText>{item?.certificate_label}</LineText>
                        </LineInfos>
                        <LineLinks>
                            {item?.public ? (
                                <LinkContent>
                                    <LinkIcon icon={'download'} />
                                    <LinkText>
                                        <a
                                            style={{ all: 'unset' }}
                                            href={parseStrapiImage(item?.filePdf?.url)}
                                            target="_blank"
                                        >
                                            Download
                                        </a>
                                    </LinkText>
                                </LinkContent>
                            ) : null}
                        </LineLinks>
                    </TableLine>
                ))}
            </BodyContentTable>
        </>
    )
}
