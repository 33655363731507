import React, { useEffect, useState } from 'react'
import { PageCard, CardHeader, CardHeaderTitleContainer } from './styled'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { GetCapa, PutCapa, PostCapa } from 'services/egs'
import { UpdateCapaDoc } from 'services/api'

import { ReadMe } from 'services/authentication'
import _ from 'lodash'

const borderLeft = '1px solid #263640'
const readGreenCss = {
    whiteSpace: 'pre-line',
    fontWeight: 'bold',
    fontSize: '15px',
    color: '#263640',
}

const readBlackBoldCss = {
    whiteSpace: 'pre-line',
    fontWeight: 'bold',
    fontSize: '15px',
    color: '#565656',
}
const readBlackCss = { whiteSpace: 'pre-line', fontWeight: '', fontSize: '15px', color: '#565656' }
const divStile = { borderLeft: borderLeft, fontSize: '700', color: '#707070' }
const textAreaStyle = { marginBottom: '18px' }

export default function SelfAssessmentChecklist({ form, questionnaire }) {
    const [certifications, setCertifications] = useState([])

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        const readMe = await ReadMe()

        const getCapa = await GetCapa(readMe.company_id)

        if (getCapa.statusText !== 'Not Found' && GetCapa.length !== 0) setCertifications(getCapa)
    }

    const onChangeCertifications = (value, itemRef, field) => {
        let newArr = [...certifications]
        let item = newArr.find((x) => x.category === itemRef)

        if (item === undefined) {
            newArr.push({
                category: itemRef,
                non_conformity: '',
                due_date: new Date(),
                correction: '',
                corrective: '',
                mfs_comments: '',
                root_cause: '',
            })
            item = newArr.find((x) => x.category === itemRef)
        }

        if (field === 'non_conformity') item.non_conformity = value
        if (field === 'due_date') item.due_date = value
        if (field === 'non_conformity') item.non_conformity = value
        if (field === 'correction') item.correction = value
        if (field === 'root_cause') item.root_cause = value
        if (field === 'root_cause') item.root_cause = value
        if (field === 'corrective') item.corrective = value
        if (field === 'mfs_comments') item.mfs_comments = value

        setCertifications(newArr)
    }

    const saveCertifications = async (certification) => {
        if (certification.id > 0) {
            await PutCapa(certification)
        } else {
            const readMe = await ReadMe()

            const newCertificationLine = await PostCapa({
                ...certification,
                users_permissions_user: readMe.company_id,
            })

            if (
                certifications.find((x) => x.category === newCertificationLine.category) ===
                undefined
            )
                certifications.push(newCertificationLine)
            else
                certifications.find((x) => x.category === newCertificationLine.category).id =
                    newCertificationLine.id

            setCertifications(certifications)
        }
    }

    const saveDocument = async (ref) => {
        await PutCapa({
            ...certifications?.find((x) => x.category === ref),
            ['non_conformity_file']: null,
        })

        const formData = new FormData()

        if (document.getElementById(ref)?.files?.[0]) {
            new Promise((resolve, reject) => {
                let reader = new FileReader()
                reader.onload = async () => {
                    let source = `${reader.result}`.split(',')[1]
                    let image = {
                        filename: document.getElementById(ref)?.files?.[0].name,
                        filetype: document.getElementById(ref)?.files?.[0].type,
                        source,
                    }

                    let btc = window.atob(image.source)
                    let btn = new Array(btc.length)
                    for (var i = 0; i < btc.length; i++) {
                        btn[i] = btc.charCodeAt(i)
                    }
                    var bta = new Uint8Array(btn)
                    let boobs = new Blob([bta], { type: 'image/png' })
                    let filesss = new File([boobs], `${image.filename}`)

                    formData.append('non_conformity_file', filesss, filesss.name)
                    formData.append('id', certifications?.find((x) => x.category === ref)?.id)
                    formData.append('filename', 'non_conformity_file')

                    await UpdateCapaDoc(formData)

                    let certificationsClone = _.cloneDeep(certifications)

                    certificationsClone = certificationsClone.map((x) => {
                        if (x.category === ref) {
                            x.non_conformity_file = document.getElementById(ref)?.files[0]
                        }

                        return x
                    })

                    setCertifications(certificationsClone)
                }
                reader.readAsDataURL(document.getElementById(ref)?.files?.[0])
            })
        }
    }

    const foodSafety = [
        {
            ref: 'B.1',
            requirementTitle: `SPECIFICATIONS INCLUDING PRODUCT RELEASE -
Does the company have adequate product release procedures in place?`,
            requirementText: ``,
            extraRefText: false,
        },
        {
            ref: 'B.2',
            requirementTitle: `"VULNERABILITY ASSESSMENT - Assessment of ingredients for economically motivated substitution, dilution 
The company needs to have a program that verifies it's suppliers. Ingredients are evaluated based on history of issues and risk associated with economic fraud activity."`,
            requirementText: ``,
            extraRefText: false,
        },
        {
            ref: 'B.3',
            requirementTitle: `"TRACEABILITY / RECALL PROGRAM
Does the company have a traceability system and also perform mock recalls? How often do they perform Mock Recalls?
The company will need to establish a traceability system that allows identification of product batches and their relationship to batches of raw materials, primary and final packaging materials, and processing and distribution records. Records will include:
• Identification of any product, ingredient or service provided.
• Records of batches of in-process or final products and packaging throughout the production process.
• Purchaser and delivery destination records for all products supplied.
• At least annualy, simulated recalls."`,
            requirementText: ``,
            extraRefText: false,
        },
    ]

    return (
        <>
            <PageCard>
                <CardHeader>
                    <CardHeaderTitleContainer>MyTS Documents Requirements</CardHeaderTitleContainer>
                </CardHeader>
            </PageCard>

            <PageCard>
                <div className="row">
                    <div className="col-12">
                        <p style={readGreenCss}>B.FOOD SAFETY</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-1">
                        <label className="form-label" style={readBlackBoldCss}>
                            #
                        </label>
                    </div>
                    <div className="col-2">
                        <label
                            className="form-label"
                            style={{
                                whiteSpace: 'pre-line',
                                fontWeight: 'bold',
                                fontSize: '15px',
                                color: '#565656',
                                marginLeft: '-10px',
                            }}
                        >
                            REQUIREMENT
                        </label>
                    </div>
                    <div className="col-2">
                        <label className="form-label" style={readBlackBoldCss}>
                            NON CONFORMITY DESCRIPTION
                        </label>
                    </div>
                    <div className="col-1">
                        <label className="form-label" style={readBlackBoldCss}>
                            DUE DATE
                        </label>
                    </div>
                    <div className="col-2">
                        <label className="form-label" style={readBlackBoldCss}>
                            CORRECTION
                        </label>
                    </div>
                    <div className="col-2">
                        <label className="form-label" style={readBlackBoldCss}>
                            ROOT CAUSE ANALYSIS
                        </label>
                    </div>
                    <div className="col-2">
                        <label className="form-label" style={readBlackBoldCss}>
                            CORRECTIVE
                        </label>
                    </div>
                </div>
                {foodSafety.map((foodSafetyItem) => (
                    <div className="row">
                        <div className="col-1">
                            <label className="form-label" style={readBlackCss}>
                                <div style={readGreenCss}>{foodSafetyItem.ref}</div>
                            </label>
                        </div>
                        <div className="col-2 mb-3" style={divStile}>
                            <label className="form-label" style={readBlackCss}>
                                <b>{foodSafetyItem.requirementTitle}</b>
                                <br />
                                {foodSafetyItem.requirementText}
                            </label>
                        </div>
                        <div className="col-2 mb-3" style={divStile}>
                            <textarea
                                className="form-control"
                                placeholder="*Please submit your most up-to-date procedure/evidence"
                                style={textAreaStyle}
                                value={
                                    certifications?.find((x) => x.category === foodSafetyItem.ref)
                                        ?.non_conformity
                                }
                                onChange={(event) =>
                                    onChangeCertifications(
                                        event.target.value,
                                        foodSafetyItem.ref,
                                        'non_conformity'
                                    )
                                }
                                onBlur={() =>
                                    saveCertifications(
                                        certifications?.find(
                                            (x) => x.category === foodSafetyItem.ref
                                        )
                                    )
                                }
                            ></textarea>
                            <>
                                {certifications?.find((x) => x.category === foodSafetyItem.ref)
                                    ?.non_conformity_file.length === 0 ? (
                                    <>
                                        <label
                                            for={foodSafetyItem.ref}
                                            style={{
                                                fontSize: '13px',
                                                color: 'white',
                                                height: '35px',
                                                padding: '10px',
                                                width: '100%',
                                                backgroundColor: '#263640',
                                                cursor: 'pointer',
                                            }}
                                            className="badge"
                                        >
                                            Open File
                                        </label>
                                        <input
                                            type="file"
                                            id={foodSafetyItem.ref}
                                            onChange={() => saveDocument(foodSafetyItem.ref)}
                                            style={{ display: 'none' }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <label
                                            for={foodSafetyItem.ref}
                                            style={{
                                                fontSize: '13px',
                                                color: 'white',
                                                height: '35px',
                                                padding: '10px',
                                                width: '100%',
                                                backgroundColor: '#263640',
                                                cursor: 'pointer',
                                            }}
                                            className="badge"
                                        >
                                            Change File
                                        </label>

                                        <div style={{ marginTop: '6px', textAlign: 'center' }}>
                                            {
                                                certifications?.find(
                                                    (x) => x.category === foodSafetyItem.ref
                                                )?.non_conformity_file[0]?.name
                                            }
                                        </div>
                                        <input
                                            type="file"
                                            id={foodSafetyItem.ref}
                                            onChange={() => saveDocument(foodSafetyItem.ref)}
                                            style={{ display: 'none' }}
                                        />
                                    </>
                                )}
                            </>
                        </div>
                        <div className="col-1 mb-3" style={divStile}>
                            <DatePicker
                                className="form-control"
                                selected={
                                    certifications?.find((x) => x.category === foodSafetyItem.ref)
                                        ?.due_date === undefined ||
                                    certifications?.find((x) => x.category === foodSafetyItem.ref)
                                        ?.due_date === null
                                        ? new Date()
                                        : new Date(
                                              certifications?.find(
                                                  (x) => x.category === foodSafetyItem.ref
                                              )?.due_date
                                          )
                                }
                                onChange={(date: Date) =>
                                    onChangeCertifications(date, foodSafetyItem.ref, 'due_date')
                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        </div>
                        <div className="col-2 mb-3" style={divStile}>
                            <textarea
                                className="form-control"
                                style={textAreaStyle}
                                value={
                                    certifications?.find((x) => x.category === foodSafetyItem.ref)
                                        ?.correction
                                }
                                onChange={(event) =>
                                    onChangeCertifications(
                                        event.target.value,
                                        foodSafetyItem.ref,
                                        'correction'
                                    )
                                }
                                onBlur={() =>
                                    saveCertifications(
                                        certifications?.find(
                                            (x) => x.category === foodSafetyItem.ref
                                        )
                                    )
                                }
                            ></textarea>
                        </div>
                        <div className="col-2 mb-3" style={divStile}>
                            <textarea
                                className="form-control"
                                style={textAreaStyle}
                                value={
                                    certifications?.find((x) => x.category === foodSafetyItem.ref)
                                        ?.root_cause
                                }
                                onChange={(event) =>
                                    onChangeCertifications(
                                        event.target.value,
                                        foodSafetyItem.ref,
                                        'root_cause'
                                    )
                                }
                                onBlur={() =>
                                    saveCertifications(
                                        certifications?.find(
                                            (x) => x.category === foodSafetyItem.ref
                                        )
                                    )
                                }
                            ></textarea>
                        </div>
                        <div className="col-2 mb-3" style={divStile}>
                            <textarea
                                className="form-control"
                                style={textAreaStyle}
                                value={
                                    certifications?.find((x) => x.category === foodSafetyItem.ref)
                                        ?.corrective
                                }
                                onChange={(event) =>
                                    onChangeCertifications(
                                        event.target.value,
                                        foodSafetyItem.ref,
                                        'corrective'
                                    )
                                }
                                onBlur={() =>
                                    saveCertifications(
                                        certifications?.find(
                                            (x) => x.category === foodSafetyItem.ref
                                        )
                                    )
                                }
                            ></textarea>
                        </div>
                    </div>
                ))}
            </PageCard>
        </>
    )
}
