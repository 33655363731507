import styled from 'styled-components'

export const ProfileProgressAction = styled.div.attrs({})`
    padding: 14px 28px;
    border-radius: 28px;
    margin-right: 18px;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    display: flex;
    align-items: center;
    max-height: 50px;
    ${(props) =>
        props.outline
            ? `
            border: 1px solid var(--primary-color);
            color: var(--primary-color);
            ${
                props.dark
                    ? `
                    border: 1px solid var(--grey-color);
                    color: var(--grey-color);
                `
                    : ``
            }
            ${
                props.grey
                    ? `
                    border: 1px solid var(--lightgrey-color);
                    color: var(--lightgrey-color);
                `
                    : ``
            }
            ${
                props.blue
                    ? `
                    border: 1px solid var(--blue-color);
                    color: var(--blue-color);
                `
                    : ``
            }
            ${
                props.red
                    ? `
                    border: 1px solid var(--red-color);
                    color: var(--red-color);
                `
                    : ``
            }
        `
            : `
            background: var(--primary-color);
            color: var(--white-color);
            ${
                props.dark
                    ? `
                    background: var(--grey-color);
                `
                    : ``
            }
            ${
                props.grey
                    ? `
                    background: var(--lightgrey-color);
                `
                    : ``
            }
            ${
                props.blue
                    ? `
                    background: var(--blue-color);
                `
                    : ``
            }
            ${
                props.red
                    ? `
                    background: var(--red-color);
                `
                    : ``
            }
            ${
                props.disabled
                    ? `
                  opacity: .5;
                  pointer-events: none !important;
                `
                    : ``
            }
        `}

    ${(props) =>
        props.small
            ? `
            padding: 8px 16px;
            font-size: 13px;
        `
            : ``}
            
    ${(props) =>
        props.big
            ? `
            width: 200px;
        `
            : ``}        
            

    &:hover {
        box-shadow: 0px 1px 3px rgba(112, 112, 112, 0.35);
    }
`
export const ProfileProgressActionIcon = styled.img.attrs((props) => ({
    src: props.icon ? `/icons/${props.icon}.svg` : null,
}))`
    width: 18px;
    margin-right: 12px;
`
export const ProfileProgressActionText = styled.div.attrs({})`
    ${(props) =>
        props.big
            ? `
            width: 180px;
            text-align: center;
        `
            : ``}
`
