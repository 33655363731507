import styled from 'styled-components'

export const DummyInputStyled = styled.div.attrs((alignItems, ...props) => ({
    ...props,
}))`
    max-width: 353px;
    width: 100%;
    position: relative;
    gap: 8px;
    display: flex;
    flex-direction: column;
    ${(props) => `align-items: ${props.alignItems || 'normal;'}`}
`
