import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DashboardFilterSearch from 'components/Dashboard/Filter/Search';
import ReactPaginate from 'react-paginate';

import {
    PageCard,
    CardHeader,
    CardHeaderSection,
    HeaderPercentage,
    DownloadIcon,
    CardFormStatus,
    Filter,
} from './styled';

import DashboardButton from 'components/Dashboard/Button';
import { CoreContext } from 'context/CoreContext';
import { RemoveSupplier, FetchSuplierRequestsFind } from 'services/sms';
import * as Papa from 'papaparse';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const defaultColumns = [
    'name',
    'city',
    'state',
    'country',
    'phone',
    'email',
    'connection_requested',
];

const additionalColumns = [
    'identification',
    'segment',
    'number_employees',
    'quality_management',
];

const allColumns = [...defaultColumns, ...additionalColumns];

const columnLabels = {
    name: 'Supplier Name',
    city: 'City',
    state: 'State',
    country: 'Country',
    phone: 'Phone',
    email: 'Email',
    connection_requested: 'Status',
    identification: 'Company Identification',
    segment: 'Segment',
    number_employees: 'Number of Employees',
    quality_management: 'Quality Management',
};

const getColumnSelections = () => {
    const savedColumns = localStorage.getItem('selectedColumns');
    return savedColumns ? JSON.parse(savedColumns) : defaultColumns;
};

const mapSegmentoToSegment = (overviewData) => {
    return overviewData.map(item => {
        return {
            ...item,
            segment: item.segmento,
            segmento: undefined
        };
    });
};

export default function DashboardTabSupplierInfo({ title }) {
    const { setModal, user, findlanguage, permissions } = useContext(CoreContext);
    const [overview, setOverview] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPerPage, setTotalPerPage] = useState(5);
    const [seeFilters, setSeeFilters] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState(getColumnSelections());
    const [filters, setFilters] = useState({
        name: '',
        city: '',
        state: '',
        country: '',
        phone: '',
        email: '',
        connection_requested: '',
        identification: '',
        segment: '',
        number_employees: '',
        quality_management: '',
    });

    const history = useHistory();
    const navigate = (to) => history.push(`/${to}`);
    const back = () => history.goBack();

    const columns = allColumns.map((col) => ({
        title: columnLabels[col],
        label: col,
    }));

    const addSupplier = () => {
        setModal({
            type: 'add-supplier',
            title: findlanguage().addNewUser,
            refresh: searchOverview,
        });
    };

    const renderStatus = (item) => {
        switch (item.connection_requested) {
            case 'Accepted':
                return <CardFormStatus color="#8dbd64" />;
            case 'Denied':
                return <CardFormStatus color="#dc3545" />;
            case 'Pending':
                return <CardFormStatus color="#f0f32b" />;
            default:
                return <CardFormStatus color="#f0f32b" />;
        }
    };

    const searchOverview = async (term = '') => {
        setOverview([]);
        const result = await FetchSuplierRequestsFind();
        const mappedResult = mapSegmentoToSegment(result);
        setOverview(mappedResult);
    };

    useEffect(() => {
        searchOverview();
    }, []);

    const downloadToCsv = () => {
        const downloadArray = [
            ['Supplier Name', 'City', 'State', 'Country', 'Phone', 'Email', 'Status', 'Identification', 'Segment', 'Number of Employees', 'Quality Management'],
        ];

        overview.forEach((x) => {
            downloadArray.push([
                x.name,
                x.city,
                x.state,
                x.country,
                x.phone,
                x.email,
                x.connection_requested,
                x.identification,
                x.segment,
                x.number_employees,
                x.quality_management ? 'Yes' : 'No',
            ]);
        });

        const csv = Papa.unparse(downloadArray, {
            quotes: false,
            quoteChar: '"',
            escapeChar: '"',
            delimiter: ',',
            header: true,
            newline: '\r\n',
            skipEmptyLines: false,
        });

        const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const csvURL = window.URL.createObjectURL(csvData);

        const link = document.createElement('a');
        link.href = csvURL;
        link.setAttribute('download', 'suppliers.csv');
        link.click();
    };

    const removeSupplier = async (id) => {
        setModal({
            type: 'alert',
            warning: true,
            text: findlanguage().deleteQuestion,
            cancel: true,
            confirmRemoveSupplier: confirmRemoveSupplier,
            idConfirm: id,
        });
    };

    const confirmRemoveSupplier = async (id) => {
        const name = overview.find((x) => x.userId === id).name;

        await RemoveSupplier(id);

        setModal({
            type: 'alert',
            warning: true,
            text: findlanguage().supplierDeletedSuccessfully,
        });

        setOverview(overview.filter((x) => x.userId !== id));
    };

    const handlePageChange = (data) => {
        setCurrentPage(data.selected + 1);
    };

    const filteredOverview = overview.filter((x) => {
        return (
            (x?.name?.toLowerCase().includes(filters?.name?.toLowerCase()) || filters?.name === '') &&
            (x?.city?.toLowerCase().includes(filters?.city?.toLowerCase()) || filters?.city === '') &&
            (x?.state?.toLowerCase().includes(filters?.state?.toLowerCase()) || filters?.state === '') &&
            (x?.country?.toLowerCase().includes(filters?.country?.toLowerCase()) || filters?.country === '') &&
            (x?.phone?.toLowerCase().includes(filters?.phone?.toLowerCase()) || filters?.phone === '') &&
            (x?.email?.toLowerCase().includes(filters?.email?.toLowerCase()) || filters?.email === '') &&
            (x?.connection_requested?.toLowerCase().includes(filters?.connection_requested?.toLowerCase()) || filters?.connection_requested === '') &&
            (x?.identification?.toLowerCase().includes(filters?.identification?.toLowerCase()) || filters?.identification === '') &&
            (x?.segment?.toLowerCase().includes(filters?.segment?.toLowerCase()) || filters?.segment === '') &&
            (x?.number_employees?.toString().includes(filters?.number_employees) || filters?.number_employees === '') &&
            ((filters?.quality_management === '' || filters?.quality_management === null) || (filters?.quality_management === 'true' && x.quality_management === true) || (filters?.quality_management === 'false' && (x.quality_management === false || x.quality_management === null)))
        );
    });

    const pageCount = Math.ceil(filteredOverview.length / totalPerPage);

    const indexOfLastItem = currentPage * totalPerPage;
    const indexOfFirstItem = indexOfLastItem - totalPerPage;

    const handleTotalPerPageChange = (event) => {
        setTotalPerPage(Number(event.target.value));
        setCurrentPage(1); // Reset to first page
    };

    const createQuery = (value, field) => {
        setFilters({
            ...filters,
            [field]: value,
        });
        setCurrentPage(1);
    };

    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

    const handleColumnToggle = (label) => {
        setSelectedColumns((prevSelected) =>
            prevSelected.includes(label)
                ? prevSelected.filter((col) => col !== label)
                : [...prevSelected, label]
        );
    };

    const handleColumnToggleClick = (label) => {
        handleColumnToggle(label);
        localStorage.setItem('selectedColumns', JSON.stringify(selectedColumns));
    };

    useEffect(() => {
        localStorage.setItem('selectedColumns', JSON.stringify(selectedColumns));
    }, [selectedColumns]);

    return (
        <PageCard>
            <CardHeader>
                {title} - {findlanguage().totalSupplier}: {filteredOverview.length}
                <CardHeaderSection>
                    {permissions?.mySuppliers?.canEdit ? (
                        <DashboardButton icon={'circle-add'} onClick={addSupplier}>
                            {findlanguage().addNewSupplier}
                        </DashboardButton>
                    ) : null}
                    <div onClick={downloadToCsv} style={{ display: 'flex' }}>
                        <div style={{ marginTop: '15px' }}>{findlanguage().downloadCsv}</div>
                        <HeaderPercentage>
                            <DownloadIcon />
                        </HeaderPercentage>
                    </div>
                </CardHeaderSection>
            </CardHeader>
            <div style={{ overflowX: 'auto', height: '600px' }}>
                <table className="table">
                    <thead style={{ backgroundColor: '#4f6b7d', color: '#f4fff7' }}>
                        <tr>
                            {columns
                                .filter((col) => selectedColumns.includes(col.label) || col.label === '')
                                .map((x, i) => (
                                    <th key={x.title} scope="col">
                                        {x.title}
                                        {seeFilters && x.label !== 'connection_requested' && x.label !== '' && x.label !== 'quality_management' && (
                                            <input
                                                className="form-control"
                                                style={{ height: '31px' }}
                                                onChange={(event) =>
                                                    createQuery(event.target.value, x.label)
                                                }
                                            />
                                        )}
                                        {seeFilters && x.label === 'connection_requested' && (
                                            <select
                                                style={{ height: '31px', paddingBottom: '0px' }}
                                                className="form-select"
                                                onChange={(event) =>
                                                    createQuery(event.target.value, x.label)
                                                }
                                            >
                                                <option value="">All</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Accepted">Accepted</option>
                                            </select>
                                        )}
                                        {seeFilters && x.label === 'quality_management' && (
                                            <select
                                                style={{ height: '31px', paddingBottom: '0px' }}
                                                className="form-select"
                                                onChange={(event) =>
                                                    createQuery(event.target.value, x.label)
                                                }
                                            >
                                                <option value="">All</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        )}
                                    </th>
                                ))}
                            <th style={{ position: 'relative' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                        <DropdownToggle caret>
                                            <span className="filter-icon" />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {allColumns.map((column) => (
                                                <DropdownItem
                                                    key={column}
                                                    toggle={false}
                                                    onClick={() => handleColumnToggleClick(column)}
                                                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedColumns.includes(column)}
                                                        onChange={() => handleColumnToggle(column)}
                                                        style={{ marginRight: '10px' }}
                                                    />
                                                    {columnLabels[column]}
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Filter onClick={() => setSeeFilters(!seeFilters)} />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="table-group-divider" style={{ borderTopColor: '#263640' }}>
                        {filteredOverview
                            .slice(indexOfFirstItem, indexOfLastItem)
                            .map((item, key) => (
                                <tr key={key}>
                                    {columns
                                        .filter((col) => selectedColumns.includes(col.label) || col.label === '')
                                        .map((col, colIndex) => (
                                            <td
                                                key={colIndex}
                                                style={{
                                                    fontSize: '15px',
                                                    fontWeight: '700',
                                                    color: '#646464',
                                                    cursor: col.label === 'name' ? 'pointer' : 'default',
                                                }}
                                                onClick={col.label === 'name' ? () => navigate(`dashboard/user/${item.userId}`) : undefined}
                                            >
                                                {col.label === 'connection_requested' ? renderStatus(item) : col.label === 'quality_management' ? (item[col.label] ? 'Yes' : 'No') : item[col.label]}
                                            </td>
                                        ))}
                                    <td
                                        style={{
                                            fontSize: '15px',
                                            fontWeight: '700',
                                            color: '#646464',
                                            right: 0,
                                            backgroundColor: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        {permissions?.mySuppliers?.canEdit && (
                                            <button
                                                className="btn btn-danger"
                                                style={{
                                                    fontSize: '15px',
                                                    fontWeight: '600',
                                                    padding: 'revert',
                                                }}
                                                onClick={() => removeSupplier(item.userId)}
                                            >
                                                {findlanguage().delete}
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            <div id="reactPaginate" style={{ display: 'flex' }}>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel={`${findlanguage().next} > `}
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel={`< ${findlanguage().previous}`}
                    renderOnZeroPageCount={null}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                    forcePage={currentPage - 1}
                />
                <select
                    className="form-select"
                    style={{ marginLeft: '10px', width: '10%', height: '38px' }}
                    value={totalPerPage}
                    onChange={handleTotalPerPageChange}
                >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                </select>
            </div>
        </PageCard>
    );
}
