import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import MapsExternal from './External/index';
import MapsInternal from './Internal/index';
import MapsExternalProducts from './ExternalProducts/index';

const Maps = () => {
    const [selectedMap, setSelectedMap] = useState('internal');

    const handleMapChange = (event) => {
        setSelectedMap(event.target.value);
    };

    return (
        <>
            <br></br>
            <FormControl variant="outlined" style={{ marginBottom: '20px' }}>
                <InputLabel htmlFor="map-select">Select Map</InputLabel>
                <Select
                    value={selectedMap}
                    onChange={handleMapChange}
                    label="Select Map"
                    id="map-select"
                >
                    <MenuItem value={'internal'}>Map powered My Trusted Source</MenuItem>
                    <MenuItem value={'external'}>Map powered by Google</MenuItem>
                    <MenuItem value={'externalProducts'}>Map powered by USDA</MenuItem>
                    {/* Add more MenuItem components for each additional map you add in the future */}
                </Select>
            </FormControl>

            {selectedMap === 'internal' && <MapsInternal />}
            {selectedMap === 'external' && <MapsExternal />}
            {selectedMap === 'externalProducts' && <MapsExternalProducts />}
            {/* Conditionally render additional maps as you add them in the future */}
        </>
    );
};

export default Maps;
