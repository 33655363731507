import { GET, POST, PUT } from './api'

//Additional Information
export const GetPrecheck = async (userId) => {
    return await GET(`/certification-prechecks?owner=${userId}`, true)
}

export const PutPrecheck = async (params) => {
    return await PUT(`/certification-prechecks/${params.id}`, params, true)
}

export const PostPrecheck = async (params) => {
    return await POST(`/certification-prechecks`, params, true)
}
