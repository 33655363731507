import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { CoreContext } from 'context/CoreContext'

const Modal = ({ onClose }) => {
    const { user } = useContext(CoreContext)
    const history = useHistory()
    
    useEffect(() => {
        const handleBackdropClick = (event) => {
            if (event.target.classList.contains('modal-backdrop')) {
                onClose() // Call the onClose function
            }
        }

        // Add the event listener
        window.addEventListener('click', handleBackdropClick)

        // Cleanup the event listener
        return () => {
            window.removeEventListener('click', handleBackdropClick)
        }
    }, [onClose])

    const handleButtonClick = () => {
        // Navigate to the dashboard profile page
        history.push('/dashboard/profile')
        onClose()
    }

    const dueDate = new Date(user.subscription.expiration_date)
    const currentDate = new Date()

    const oneDay = 24 * 60 * 60 * 1000
    const differenceInMilliseconds = dueDate - currentDate
    const differenceInDays = Math.round(differenceInMilliseconds / oneDay)

    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                {differenceInDays === 0 ? (
                    <p>
                        <b>Last day</b> to complete your profile
                    </p>
                ) : (
                    <p>
                        You have <b>{differenceInDays} days</b> to complete your profile
                    </p>
                )}
                <h2>Finish your profile now!</h2>
                <p>Complete your profile and receive a 30-day PREMIUM Trial</p>
                <div onClick={handleButtonClick} className="divButton">Complete Now!</div>
                <br></br>
                <div onClick={onClose} className="divButton" style={{ width: '160px' }}>
                    Skip
                </div>
            </div>
        </div>
    )
}

export default Modal
