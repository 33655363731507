import styled from 'styled-components'

export const InputContainer = styled.div.attrs({})`
    border-bottom: 0.5px solid var(--lightgrey-color);
    max-width: 353px;
    width: 100%;
    position: relative;
    padding-top: 32px;
    margin-bottom: 20px;

    ${(props) =>
        props.focused
            ? `
            border-bottom: .5px solid var(--primary-color);
        `
            : ``}
    ${(props) =>
        props.small
            ? `
            max-width: 303px;
        `
            : ``}
    ${(props) =>
        props.outline
            ? `
            border: .5px solid var(--lightgrey-color);
            padding-top: 0px;
            border-radius: 4px;
        `
            : ``}

    ${(props) =>
        props.highlight
            ? `
            border-bottom: 0.5px solid #ff4d4d !important;
        `
            : ``}

    ${(props) =>
        props.textarea
            ? `
            max-width: 192px;
            border: .5px solid var(--lightgrey-color);
            border: .5px solid var(--lightgrey-color);
            border-radius: 10px;
            padding-top: 0px;
        `
            : ``}   

    ${(props) =>
        props.big
            ? `
            max-width: 100%;
        `
            : ``}
`

export const InputBlankContainer = styled.div.attrs({})`
    max-width: 353px;
    width: 100%;
    position: relative;
    padding-top: 32px;
    margin-bottom: 20px;

    ${(props) =>
        props.small
            ? `
            max-width: 303px;
        `
            : ``}

    ${(props) =>
        props.big
            ? `
            max-width: 100%;
        `
            : ``}
`

export const InputLabel = styled.div.attrs({})`
    font-size: 12px;
    color: var(--primary-color);
`

export const InputInput = styled.input.attrs((props) => ({
    disabled: props.locked,
}))`
    position: relative;
    border: 0;
    height: 36px;
    color: var(--black-color);
    font-size: 15px;
    outline: none;
    width: 100%;
    padding: 0 8px;
    z-index: 2;
    background: none;

    ${(props) =>
        props.outline
            ? `
            height: 44px;
        `
            : ``}
    ${(props) =>
        props.locked
            ? `
            background: var(--chatgrey-color);
        `
            : ``}

    padding-right: 35px;

    &::placeholder {
        color: var(--lightgrey-color);
    }

    &:read-only {
        background: var(--lightgrey-color);
    }
`

export const InputInputArea = styled.textarea.attrs({})`
    position: relative;
    border: 0;
    height: 36px;
    color: var(--black-color);
    font-size: 15px;
    outline: none;
    width: 100%;
    padding: 5px 8px;
    z-index: 2;
    background: none;
    resize: none;

    height: 86px;
    ${(props) =>
        props.big
            ? `
            height: 144px;
            padding: 10px 16px;
        `
            : ``}
    &::placeholder {
        color: var(--lightgrey-color);
    }
`
export const InputAction = styled.div.attrs({})`
    height: 36px;
    width: 36px;
    background-color: transparent;

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: 4px;
    right: 0;
    z-index: 2;

    cursor: pointer;
`

export const InputIcon = styled.img.attrs({})``

export const PlaceholderText = styled.div.attrs({})`
    position: absolute;
    top: 38px;
    left: 8px;
    font-size: 15px;
    color: var(--lightgrey-color);
    z-index: 1;

    ${(props) =>
        props.highlight
            ? `
             color: #ff4d4d !important;
        `
            : ``}

    ${(props) =>
        props.focused
            ? `
            top: 10px;
            left: 0px;
            font-weight: bold;
            font-size: 14px;
            color: var(--primary-color);
        `
            : ``}

    ${(props) =>
        props.outline
            ? `
            top: 10px;
            ${
                props.focused
                    ? `
                    top: -12px;
                    left: 6px;
                    background: var(--white-color);
                    padding: 0 3px;
                `
                    : ``
            }
        `
            : ``}




    transition: all .3s ease;
`

export const InputMaxLength = styled.div.attrs({})`
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--lightgrey-color);
    text-align: right;
    margin-bottom: 12px;
`

export const SelectBody = styled.div.attrs({})`
    max-height: 180px;
    overflow: auto;
    position: absolute;
    background: var(--white-color);
    z-index: 4;
    border: 0.5px solid var(--chatgrey-color);
    width: 100%;
`

export const SelectBodyItem = styled.div.attrs({})`
    min-height: 43px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    color: var(--black-color);
    font-size: 15px;
    border-top: 0.5px solid var(--chatgrey-color);
    cursor: pointer;
    &:hover {
        opacity: 0.9;
    }
`
