import React, { useEffect, useState, useContext } from 'react'
import Button from '../Button'
import _ from 'lodash'
import { CoreContext } from 'context/CoreContext'

// import Button from '../Button'
import Check from '../Check'
import Input from '../Input'

import {
    Body,
    Option,
    CheckHeader,
    ToggleIcon,
    SubOption,
    ButtonDock,
    RadioButton,
    RadioButtonLabel,
    Item,
    SubItem,
    RadioHeader,
    RadioContent,
    RadioButtonCircle,
    RadioHeaderLabel,
    FilterContent,
} from './styled'

export default function SupplierProductsOptions({ datasource, unique, onSelectChange, grid }) {
    const [options, setOptions] = useState([])
    const [optionsOriginal, setOptionsOriginal] = useState([])
    const [newprod, setNewProd] = useState()
    const [filter, setFilter] = useState('')
    const [filterEnabled, setFilterEnabled] = useState(false)
    const [lastOpened, setLastOpened] = useState()
    const { language, findlanguage } = useContext(CoreContext)

    const doFilter = (data) => {
        setFilter(data)

        if (data !== '') {
            const optionsList = _.cloneDeep(options)

            let newlist = null
            if (language === 'portugues') {
                newlist = optionsList
                    .map((item, key) => {
                        item.options = item.options.filter((subitem) =>
                            subitem.name_pt.toLowerCase().includes(data.toLowerCase())
                        )
                        item.opened = true
                        return item
                    })
                    .filter((item) => item.options.length > 0)
            } else {
                newlist = optionsList
                    .map((item, key) => {
                        item.options = item.options.filter((subitem) =>
                            subitem.name.toLowerCase().includes(data.toLowerCase())
                        )
                        item.opened = true
                        return item
                    })
                    .filter((item) => item.options.length > 0)
            }

            setOptions([...newlist])
            setFilterEnabled(true)
        } else {
            const newlist = _.cloneDeep(optionsOriginal)
            setOptions([...newlist])
            setFilterEnabled(false)
        }
    }

    const addMore = async (master) => {
        if (newprod.length === 0) return
        const rand = new Date().getTime() * -1
        const optionsList = [...options]


        if (language === 'portugues') {
            optionsList.map((item, key) => {
                if (item.category === master) {
                    item.options.push({ id: rand, name_pt: newprod })
                }
                return item
            })
        }
        else {
            optionsList.map((item, key) => {
                if (item.category === master) {
                    item.options.push({ id: rand, name: newprod })
                }
                return item
            })
        }

        setNewProd('')

        setOptions([...optionsList])
        setOptionsOriginal([...optionsList])
    }

    const ToogleView = (check) => {
        setNewProd('')
        const optionsList = [...options]

        optionsList.map((item, key) => {
            if (item.category === lastOpened && item.category !== check.category) {
                item.opened = false
            }

            if (item.category === check.category) {
                item.opened = item.opened !== undefined ? !item.opened : true
                if (item.opened) setLastOpened(item.category)
            }
            return item
        })
        setOptions([...optionsList])
    }

    // Return true if all child is selected otherwise return false
    const childrenIsSelected = (subitems) => {
        const found = subitems.filter((item) => item.checked)
        return subitems.length === found.length
    }

    const ToogleSelected = (check, subitem) => {
        const optionsList = [...options]
        const master = subitem ? false : true

        optionsList.map((item, key) => {
            if (item.category === check.category) {
                item.checked = item.checked !== undefined ? !item.checked : true
                if (master && item.options.length > 0) {
                    item.options.map((el, key) => {
                        el.checked = check.checked
                        return el
                    })
                } else if (subitem !== undefined) {
                    subitem.checked = subitem.checked !== undefined ? !subitem.checked : true
                    item.checked = childrenIsSelected(item.options)
                }
            }
            return item
        })
        setOptions([...optionsList])
    }

    useEffect(() => {
        setOptions(_.cloneDeep(datasource))
        setOptionsOriginal(_.cloneDeep(datasource))
        return () => {}
    }, [datasource])

    return (
        <>
            <Body grid={grid}>
                <FilterContent>
                    <Input
                        placeholder={findlanguage().filter}
                        small
                        value={filter}
                        onChange={(e) => doFilter(e.target.value)}
                    />
                </FilterContent>

                {options.map((item, key) => {
                    if (unique) {
                        return (
                            <>
                                <Item key={key}>
                                    <RadioHeader>
                                        <>
                                            <RadioHeaderLabel onClick={() => ToogleView(item)}>
                                                {item.category}
                                            </RadioHeaderLabel>
                                            <ToggleIcon
                                                opened={item.opened}
                                                onClick={() => ToogleView(item)}
                                            />
                                        </>
                                    </RadioHeader>
                                    {item.opened &&
                                        item.options.map((subitem, k) => {
                                            return (
                                                <>
                                                    {language === 'english' ? (
                                                        <SubItem key={k}>
                                                            <RadioContent>
                                                                <RadioButton
                                                                    type="radio"
                                                                    name="radio"
                                                                    value={subitem.name}
                                                                    onChange={(e) =>
                                                                        onSelectChange(
                                                                            item.category,
                                                                            subitem
                                                                        )
                                                                    }
                                                                ></RadioButton>
                                                                <RadioButtonCircle></RadioButtonCircle>
                                                            </RadioContent>
                                                            <RadioButtonLabel>
                                                                {subitem.name}
                                                            </RadioButtonLabel>
                                                        </SubItem>
                                                    ) : null}
                                                    {language === 'portugues' ? (
                                                        <SubItem key={k}>
                                                            <RadioContent>
                                                                <RadioButton
                                                                    type="radio"
                                                                    name="radio"
                                                                    value={subitem.name}
                                                                    onChange={(e) =>
                                                                        onSelectChange(
                                                                            item.category,
                                                                            subitem
                                                                        )
                                                                    }
                                                                ></RadioButton>
                                                                <RadioButtonCircle></RadioButtonCircle>
                                                            </RadioContent>
                                                            <RadioButtonLabel>
                                                                {subitem.name_pt}
                                                            </RadioButtonLabel>
                                                        </SubItem>
                                                    ) : null}
                                                </>
                                            )
                                        })}

                                    {item.opened && !filterEnabled ? (
                                        <ButtonDock>
                                            <div style={{ width: '180px' }}>
                                                <Input
                                                    placeholder={findlanguage().newProducts}
                                                    small
                                                    value={newprod}
                                                    onChange={(e) => setNewProd(e.target.value)}
                                                />
                                            </div>
                                            <div style={{ paddingTop: '20px' }}>
                                                <Button
                                                    primary
                                                    tiny
                                                    onClick={(e) => {
                                                        addMore(item.category)
                                                    }}
                                                >
                                                    {findlanguage().add}
                                                </Button>
                                            </div>
                                        </ButtonDock>
                                    ) : (
                                        ''
                                    )}
                                </Item>
                            </>
                        )
                    } else
                        return (
                            <>
                                <Option>
                                    <CheckHeader>
                                        <Check
                                            nowidth={true}
                                            onChange={(e) => ToogleSelected(item)}
                                            title={item.category}
                                            label={''}
                                            checked={item.checked}
                                        />
                                        {item.options.length > 0 ? (
                                            <ToggleIcon
                                                opened={item.opened}
                                                onClick={() => ToogleView(item)}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </CheckHeader>

                                    {item.opened &&
                                        item.options.map((subitem, k) => {
                                            return (
                                                <>
                                                    <SubOption>
                                                        <Check
                                                            onChange={(e) =>
                                                                ToogleSelected(item, subitem)
                                                            }
                                                            title={subitem.label}
                                                            label={''}
                                                            checked={subitem.checked}
                                                        />
                                                    </SubOption>
                                                </>
                                            )
                                        })}

                                    {item.opened ? (
                                        <ButtonDock>
                                            <div>
                                                <Input
                                                    placeholder={findlanguage().newProducts}
                                                    small
                                                    value={newprod}
                                                    onChange={(e) => setNewProd(e.target.value)}
                                                />
                                            </div>
                                            <div style={{ paddingTop: '20px' }}>
                                                <Button
                                                    primary
                                                    tiny
                                                    onClick={(e) => {
                                                        addMore(item.category)
                                                    }}
                                                >
                                                    {findlanguage().add}
                                                </Button>
                                            </div>
                                        </ButtonDock>
                                    ) : (
                                        ''
                                    )}
                                </Option>
                            </>
                        )
                })}
            </Body>
        </>
    )
}
