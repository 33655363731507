import React, { useEffect, useState, useContext } from 'react'
import { PageCard } from '../../../../../Panels/SMSControl/styled'
import { TitleGreen } from '../styled'
import { GetProductDescription, PutProductDescription, PostProductDescription } from 'services/egs'
import { ReadMe } from 'services/authentication'
import { CoreContext } from 'context/CoreContext'

const labelCss = { fontWeight: 'bold', fontSize: '15px', color: 'rgb(86, 86, 86)' }
const labelTextCss = { fontSize: '14px', color: 'rgb(86, 86, 86)' }
const inputCss = { borderColor: '#70707059' }
const col6 = { fontSize: '700', color: '#707070' }
const doneTypingInterval = 2000

let typingTimer

export default function ProductDescription({ form, backOffice, company_id }) {
    const { findlanguageEsg, user } = useContext(CoreContext)
    const [productDescriptionAndProduction, setProductDescription] = useState({
        product_description: '',
        list_products_produced: '',
    })

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        waitUserFinishTyping()
    }, [productDescriptionAndProduction])

    const init = async () => {
        const getProductDescription = await GetProductDescription(backOffice ? company_id : user.company_id)

        if (getProductDescription.statusText !== 'Not Found' && getProductDescription.length !== 0)
            setProductDescription(getProductDescription[0])
        else
            setProductDescription(
                await PostProductDescription({ users_permissions_user: backOffice ? company_id : user.company_id })
            )
    }

    function changeQuestionaire(value, ref) {
        setProductDescription({
            ...productDescriptionAndProduction,
            [ref]: value,
        })

        waitUserFinishTyping()
    }

    function waitUserFinishTyping() {
        clearTimeout(typingTimer)
        typingTimer = setTimeout(saveProductDescription, doneTypingInterval)
    }

    const saveProductDescription = async () => {
        if (productDescriptionAndProduction.id !== -1 && !backOffice)
            await PutProductDescription(productDescriptionAndProduction)
    }

    return (
        <PageCard>
            <TitleGreen>{findlanguageEsg().productDescr}</TitleGreen>
            <div className="row">
                <div className="col-12" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguageEsg().listOfAllProducts}
                    </label>
                    <br></br>
                    <label className="form-label" style={labelTextCss}>
                        {findlanguageEsg().exampleListOfAllProducts}
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        style={inputCss}
                        value={productDescriptionAndProduction.list_products_produced}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'list_products_produced')
                        }
                        disabled={form}
                    />
                </div>
                <div className="col-12" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguageEsg().listOfProdValidatedMFS}
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        style={inputCss}
                        value={productDescriptionAndProduction.product_description}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'product_description')
                        }
                        disabled={form}
                    />
                </div>
            </div>
        </PageCard>
    )
}
