import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CoreContext } from 'context/CoreContext'
import { toast } from 'react-toastify'
import { Load } from 'ui/styled'
import Modal from '../Modal/index'

import {
    PageHeader,
    PageHeaderTitle,
    PageCard,
    ProfileContainer,
    ProfileContent,
    ProfileContentSpace,
    ProfileFormContent,
    ProfileFormActions,
    PageScroller,
    ProductItem,
    ProductItemSection,
    ProductItemText,
    ProductItemAction,
    ProductItemActionIcon,
    PageContent,
    PageContentActions,
    CheckRow,
    RequirementsItem,
    RequerimentIcon,
    RequerimentText,
    RequirementItemAction,
    RequirementItemActionIcon,
    RequirementItemSection,
    IconInfo,
} from './styled'

import {
    FetchMyCertificates,
    FetchMyProducts,
    RemoveCertificate,
    RemoveProduct,
} from 'services/myProfile'

import Check from 'components/Form/Check'
import Checklist from 'components/Form/Checklist'
import SelectMany from 'components/Form/SelectMany'
import Input from 'components/Form/Input'
import Button from 'components/Form/Button'
import DashboardButton from 'components/Dashboard/Button'
import FormImagePicker from 'components/Form/ImagePicker'

import { UpdateMe, GetMe, UpdateMyUser } from 'services/authentication'
import { exposeStrapiErrorNew } from 'utils'

import { LoadBlack } from 'ui/styled'

import { FetchMyRequirements, RemoveRequirement } from 'services/myProfile'
import {
    ReadBusinessTypes,
    ReadCompanyCategories,
    ReadSupplierPerRequirements,
} from 'services/infos'
import { SubscribeToPremium } from 'services/premium'
import InfoIcon from 'components/Form/InfoIcon'
import DummyInput from 'components/Form/DummyInput'

export default function UserProfileSupplier() {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)
    const [loading, setLoading] = useState(false)

    const {
        setModal,
        user,
        profile,
        setUser,
        findlanguage,
        language,
        premium,
        setPremium,
        permissions,
    } = useContext(CoreContext)

    const [prerequirements, setPrerequirements] = useState([])
    const [selectedRequirements, setSelectedRequirements] = useState([])
    const [selectedPreRequirements, setSelectedPreRequirements] = useState([])
    const [loadingRequirements, setLoadingRequirements] = useState(false)

    const [categories, setCategories] = useState([])
    const [business, setBusiness] = useState([])
    const [productDescription, setProductDescription] = useState('')
    const [aboutCompany, setAboutCompany] = useState('')
    const [distributionState, setDistributionState] = useState(false)
    const [distributionNational, setDistributionNational] = useState(false)
    const [distributionInternational, setDistributionInternational] = useState(false)

    const [selectedBusiness, setSelectedBusiness] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [selectedCertifications, setSelectedCertifications] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])

    const [loadingCertifications, setLoadingCertifications] = useState(false)
    const [loadingProducts, setLoadingProducts] = useState(false)

    const [colorDistribution, setColorDistribution] = useState('#000000')

    const [checked, setChecked] = useState(false)
    const [dueDate, setDueDate] = useState(null)
    const [currentDate, setCurrentDate] = useState(new Date())
    const [initGet, setInitGet] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [activeTab, setActiveTab] = useState(
        permissions?.myProfile?.canViewBuyer ? 'buyer' : 'supplier'
    )

    const closeModal = () => {
        setIsModalOpen(false)
    }

    useEffect(() => {
        if (user?.subscription?.expiration_date) {
            setDueDate(new Date(user.subscription.expiration_date))
        }
    }, [user?.subscription?.expiration_date])

    useEffect(() => {
        if (initGet) {
            action()
            setInitGet(false)
        }
    }, [user?.address])

    const isValidForPremium = () => {
        return (
            user?.address?.state !== undefined &&
            user?.address?.state !== '' &&
            user?.address?.city !== undefined &&
            user?.address?.city !== '' &&
            user?.address?.zipCode !== undefined &&
            user?.address?.zipCode !== '' &&
            selectedBusiness.length !== 0 &&
            selectedProducts.length !== 0 &&
            selectedCategories.length !== 0 &&
            aboutCompany !== '' &&
            (distributionState || distributionNational || distributionInternational) &&
            user.address?.state !== undefined &&
            user.address?.state !== '' &&
            user.address?.city !== undefined &&
            user.address?.city !== '' &&
            user.address?.zipCode !== undefined &&
            user.address?.zipCode !== '' &&
            prerequirements.length !== 0 &&
            selectedBusiness.length !== 0 &&
            selectedCategories.length !== 0 &&
            aboutCompany !== ''
        )
    }

    const upgradeToPremium = async () => {
        const result = await SubscribeToPremium('freepremium', [])
        if (result) {
            setUser({
                ...user,
                subscription: {
                    ...user.subscription,
                    type: 'premium',
                },
            })

            const currentDate = new Date()
            const dueDate = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000)

            setPremium({
                ...premium,
                due: dueDate.toISOString(),
                type: 'premium',
            })

            setIsModalOpen(true)
        }
    }

    const init = async () => {
        setChecked(user.is_company_visible)
        const __user = { ...user }

        const _loadCompanyCategories = await ReadCompanyCategories()
        const _loadBusinessTypes = await ReadBusinessTypes()
        const _loadPreRequirements = await ReadSupplierPerRequirements()

        const tempCategories = _loadCompanyCategories
            ?.sort((a, b) => (a.title > b.title ? 1 : -1))
            .map((mit, key) => ({ id: key + 1, ...mit }))

        setCategories(tempCategories)

        const tempBusinessTypes = _loadBusinessTypes.supplier
            ?.sort((a, b) => (a > b ? 1 : -1))
            .map((mit, key) => {
                let traducao = mit
                switch (mit) {
                    case 'Farm':
                        traducao = 'Fazenda'
                        break
                    case 'Industry':
                        traducao = 'Industria'
                        break
                    case 'Restaurant':
                        traducao = 'Restaurante'
                        break
                    case 'Retail':
                        traducao = 'Varejo'
                        break
                    case 'Store and Distribuition':
                        traducao = 'Armazenamento e distribuição'
                        break
                    case 'Broker':
                        traducao = 'Corretora'
                        break
                    case 'Process Food':
                        traducao = 'Alimentos Processados'
                        break
                    default:
                        break
                }

                return {
                    id: key + 1,
                    title: mit,
                    value: mit,
                    traducao: traducao,
                }
            })

        setBusiness(tempBusinessTypes)

        if (user.IsCompanyUser) {
            __user.active_account = user.company_account.active_account
        }

        const tempPreRequirements = _loadPreRequirements
            ?.sort((a, b) => (a.label > b.label ? 1 : -1))
            .map((mit, key) => {
                let traducao = mit.label
                switch (mit.label) {
                    case 'Farm':
                        traducao = 'Fazenda'
                        break
                    case 'Industry':
                        traducao = 'Industria'
                        break
                    case 'Restaurant':
                        traducao = 'Restaurante'
                        break
                    case 'Retail':
                        traducao = 'Varejo'
                        break
                    case 'Store and Distribuition':
                        traducao = 'Armazenamento e distribuição'
                        break
                    case 'Broker':
                        traducao = 'Corretora'
                        break
                    case 'Process Food':
                        traducao = 'Alimentos Processados'
                        break
                    case 'Animal Welfare':
                        traducao = 'Bem estar animal'
                        break
                    case 'FDA Approved Companies':
                        traducao = 'Empresas Aprovadas pela FDA'
                        break
                    case 'Food Safety / GFSI Certified':
                        traducao = 'Segurança Alimentar / Certificado GFSI'
                        break
                    case 'None':
                        traducao = 'Nenhum'
                        break
                    case 'Quality':
                        traducao = 'Qualidade'
                        break
                    case 'Religious':
                        traducao = 'Religioso'
                        break
                    case 'Social/Ethical certified':
                        traducao = 'Certificado Social/Ético'
                        break
                    case 'Sustainability Certified':
                        traducao = 'Certificado de Sustentabilidade'
                        break
                    case 'USDA Approved Companies':
                        traducao = 'Empresas aprovadas pelo USDA'
                        break
                    case 'Organic Certified':
                        traducao = 'Certificadas Orgânica'
                        break
                    case 'Organic Certified Companies':
                        traducao = 'Empresas Certificadas Orgânica'
                        break
                    default:
                        break
                }

                return {
                    ...mit,
                    value: mit.label,
                    traducao: traducao,
                }
            })

        setPrerequirements(tempPreRequirements)

        setSelectedBusiness(
            __user.active_account?.businessTypes?.map((mit, key) => {
                let traducao = mit.label
                switch (mit.label) {
                    case 'Farm':
                        traducao = 'Fazenda'
                        break
                    case 'Industry':
                        traducao = 'Industria'
                        break
                    case 'Restaurant':
                        traducao = 'Restaurante'
                        break
                    case 'Retail':
                        traducao = 'Varejo'
                        break
                    case 'Store and Distribuition':
                        traducao = 'Armazenamento e distribuição'
                        break
                    case 'Broker':
                        traducao = 'Corretora'
                        break
                    case 'Process Food':
                        traducao = 'Alimentos Processados'
                        break
                    default:
                        break
                }

                return {
                    id: mit.id,
                    title: mit.label,
                    value: mit.label,
                    traducao: traducao,
                }
            })
        )

        const tmpPR = __user.active_account?.supplierPreRequirements?.map((item) => {
            let traducao = item.label
            switch (item.label) {
                case 'Farm':
                    traducao = 'Fazenda'
                    break
                case 'Industry':
                    traducao = 'Industria'
                    break
                case 'Restaurant':
                    traducao = 'Restaurante'
                    break
                case 'Retail':
                    traducao = 'Varejo'
                    break
                case 'Store and Distribuition':
                    traducao = 'Armazenamento e distribuição'
                    break
                case 'Broker':
                    traducao = 'Corretora'
                    break
                case 'Process Food':
                    traducao = 'Alimentos Processados'
                    break
                default:
                    break
            }

            return {
                id: item.id,
                title: item.label,
                value: item.label,
                traducao: traducao,
            }
        })
        setSelectedPreRequirements(tmpPR)
        await fetchRequirements()

        setSelectedCategories(__user.active_account?.companyCategories)

        fetchCertificates()

        fetchProducts()

        setProductDescription(
            __user.active_account?.productsDescription
                ? __user.active_account?.productsDescription
                : ''
        )
        setAboutCompany(
            __user.active_account?.aboutCompany ? __user.active_account?.aboutCompany : ''
        )
        setDistributionState(__user.active_account?.distributionState)
        setDistributionNational(__user.active_account?.distributionNational)
        setDistributionInternational(__user.active_account?.distributionInternational)

        loadCheckList(
            tempBusinessTypes,
            __user.active_account?.businessTypes,
            tempCategories,
            __user.active_account?.companyCategories
        )
        setInitGet(true)
    }

    const handleOnChange = async () => {
        setChecked(!checked)
        setUser({
            ...user,
            is_company_visible: !checked,
        })

        setLoading(true)

        await UpdateMyUser({
            name: user.name,
            contactName: user.contactName,
            email: user.email,
            phone: user.phone,
            is_company_visible: !checked,
            address: {
                street: user.address?.street,
                country: user.address?.country,
                state: user.address?.state,
                city: user.address?.city,
                zipCode: user.address?.zipCode,
                latitude: user.address?.latitude,
                longitude: user.address?.longitude,
            },
            website: user.address?.website,
        })

        setLoading(false)
    }

    const loadCheckList = (business, selectedBusiness, categories, selectedCategories) => {
        var _temp = [...business]

        selectedBusiness.forEach((item) => {
            const found = _temp.find((element) => element.value === item.label)
            if (!found) {
                _temp.push({ id: item.id, title: item.label, value: item.label })
            }
        })

        setBusiness([..._temp])
    }

    const getSelected = (e) => {
        setSelectedCategories([...e])
    }

    useEffect(() => {
        init()
    }, [])

    const openEdit = () => {
        setModal({
            type: 'company-information',
            title: findlanguage().companyInformation,
            onRefresh: () => {
                if (user.subscription.type === 'free') action()
            },
        })
    }

    const openEditMyInfo = () => {
        setModal({
            type: 'company-my-information',
            title: findlanguage().companyMyInformation,
        })
    }

    // const openDocument = () => {
    //     setModal({
    //         type: 'add-document',
    //         title: findlanguage().addNewDocuments,
    //         upload: true,
    //     })
    // }

    const action = async () => {
        const _payload = {
            ...user,
            is_company_visible: checked,
            aditionalInformations: {
                businessTypes: selectedBusiness?.map((item) => item.value) || [],
                companyCategories: selectedCategories?.map((item) => item.id) || [],
                productsDescription: productDescription,
                aboutCompany: aboutCompany,
                distributionState: distributionState,
                distributionNational: distributionNational,
                distributionInternational: distributionInternational,
                type: profile,
                supplierPreRequirements: selectedPreRequirements?.map((item) => item.value) || [],
            },
        }

        setLoading(true)
        const result = await UpdateMe(_payload)
        const loggedUserData = await GetMe()

        setLoading(false)
        if (result && exposeStrapiErrorNew(result)) {
            result.user = loggedUserData
            completeUpdate(result)
        }

        if (user?.subscription?.expiration_date !== undefined) {
            if (dueDate && currentDate < dueDate) {
                if (user?.subscription?.trial === true && user.subscription.type === 'free') {
                    if (isValidForPremium()) {
                        upgradeToPremium()
                    }
                }
            }
        }
    }

    const completeUpdate = (result) => {
        if (result?.user) {
            setUser(result.user)
        }

        toast.success(findlanguage().accountUpdated)
    }

    const openAddProduct = () => {
        setModal({
            type: 'add-product',
            title: findlanguage().addNewProduct,
            onRefresh: () => {
                fetchProducts()
            },
        })
    }

    const openAddCertificate = () => {
        setModal({
            type: 'add-certificate',
            title: findlanguage().addNewCertificate,
            onRefresh: () => {
                fetchCertificates()
            },
        })
    }

    const removeProduct = (id) => {
        setModal({
            type: 'choose',
            title: findlanguage().doYouWantToDeleteTheProduct,
            options: [
                { title: findlanguage().no, action: () => setModal(null) },
                { title: findlanguage().yes, action: () => doRemoveProduct(id), outline: true },
            ],
        })
    }

    const editCertificate = (id) => {
        setModal({
            type: 'add-certificate',
            title: findlanguage().editCertificate,
            onRefresh: fetchCertificates,
            id: id,
        })
    }

    const removeCertificate = (id) => {
        setModal({
            type: 'choose',
            title: findlanguage().doYouWantToDeleteTheCertificate,
            options: [
                { title: findlanguage().no, action: () => setModal(null) },
                { title: findlanguage().yes, action: () => doRemoveCertificate(id), outline: true },
            ],
        })
    }

    const doRemoveProduct = async (id) => {
        setModal({
            type: 'loading',
            title: findlanguage().doYouWantToDeleteTheProduct,
        })
        const request = await RemoveProduct(id)
            .then(() => true)
            .catch((err) => {
                return false
            })
        setModal(null)
        if (!request) return
        toast.success(findlanguage().productRemoved)
        fetchProducts()
    }

    const doRemoveCertificate = async (id) => {
        setModal({
            type: 'loading',
            title: findlanguage().doYouWantToDeleteTheCertificate,
        })
        const request = await RemoveCertificate(id)
            .then(() => true)
            .catch((err) => {
                return false
            })
        setModal(null)
        if (!request) return
        toast.success(findlanguage().certificateRemoved)
        fetchCertificates()
    }

    const fetchCertificates = async () => {
        setSelectedCertifications([])
        setLoadingCertifications(true)

        const _loadSupplierCertificates = await FetchMyCertificates(user.company_id).then(
            (response) => {
                return response
                    .sort((a, b) => (a.certificate_label > b.certificate_label ? 1 : -1))
                    .map((item) => ({
                        id: item.id,
                        certification: item.certification_title,
                        certificate: item.certificate_label,
                    }))
            }
        )

        setSelectedCertifications(_loadSupplierCertificates)
        setLoadingCertifications(false)
    }

    const fetchProducts = async () => {
        setSelectedProducts([])
        setLoadingProducts(true)

        const _loadSupplierProducts = await FetchMyProducts(user.company_id).then((response) => {
            return response
                .sort((a, b) => (a.product_name > b.product_name ? 1 : -1))
                .map((item) => ({
                    id: item.id,
                    category: item.category,
                    product: item.product_name,
                }))
        })

        setSelectedProducts(_loadSupplierProducts)
        setLoadingProducts(false)
    }

    const removeRequirement = (id) => {
        setModal({
            type: 'choose',
            title: findlanguage().doYouWantToDeleteTheRequirement,
            options: [
                { title: findlanguage().no, action: () => setModal(null) },
                { title: findlanguage().yes, action: () => doRemoveRequirement(id), outline: true },
            ],
        })
    }

    const doRemoveRequirement = async (id) => {
        setModal({
            type: 'loading',
            title: findlanguage().doYouWantToDeleteTheRequirement,
        })
        const request = await RemoveRequirement(id)
            .then(() => true)
            .catch((err) => {
                return false
            })
        setModal(null)
        if (!request) return
        toast.success(findlanguage().requirementRemoved)
        fetchRequirements()
    }

    const editRequirement = (id) => {
        setModal({
            type: 'add-requirement',
            title: findlanguage().editRequirement,
            onRefresh: fetchRequirements,
            id: id,
        })
    }

    const openAddRequirement = () => {
        setModal({
            type: 'add-requirement',
            title: findlanguage().addNewRequirement,
            onRefresh: () => {
                fetchRequirements()

                if (user.subscription.type === 'free') action()
            },
        })
    }

    const fetchRequirements = async () => {
        setSelectedRequirements([])
        setLoadingRequirements(true)

        const _loadRequirements = await FetchMyRequirements(user.company_id).then((response) => {
            return response.map((item, key) => ({
                id: item.id,
                label: item.requirement,
            }))
        })

        //const _loadRequirements = [{ label: 'Requirement A' }, { label: 'Requirement B' }]

        setSelectedRequirements(_loadRequirements)
        setLoadingRequirements(false)
    }

    return (
        <>
            <PageCard>
                <ProfileContainer>
                    <ProfileContent>
                        <FormImagePicker image={'/images/company.png'} />
                        <ProfileContentSpace />

                        {!user.IsCompanyUser && permissions?.myProfile?.canEdit ? (
                            <span style={{ marginLeft: '15px' }}>
                                <DashboardButton
                                    onClick={() => navigate('dashboard/account-information')}
                                >
                                    {findlanguage().accountInformation}
                                </DashboardButton>
                            </span>
                        ) : (
                            <div style={{ width: '235px' }}></div>
                        )}
                    </ProfileContent>
                    <ProfileFormContent>
                        <DummyInput></DummyInput>
                        <DummyInput alignItems="end">
                            <div className="custom-control custom-switch">
                                {profile === 'admin' ? (
                                    <>
                                        <input
                                            disabled={!permissions?.myProfile?.canEdit}
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="customSwitches"
                                            checked={checked}
                                            onChange={handleOnChange}
                                            style={{ cursor: 'pointer' }} // Inline style for cursor
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="customSwitches"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {checked ? 'Visible' : 'Not Visible'}
                                        </label>
                                        <InfoIcon
                                            tooltipContent={findlanguage().visibleIcon}
                                            className="ml-2"
                                        />
                                    </>
                                ) : null}
                            </div>
                            {dueDate &&
                            currentDate < dueDate &&
                            user?.subscription?.trial === true &&
                            user.subscription.type === 'free' &&
                            !isValidForPremium() ? (
                                <div>
                                    <span style={{ fontWeight: '700', color: '#dc3545' }}>
                                        {findlanguage().requiredFields}
                                    </span>
                                    <InfoIcon
                                        tooltipContent={findlanguage().fullAddressInstruction}
                                        className="ml-2"
                                    />
                                </div>
                            ) : null}
                        </DummyInput>
                        <Input
                            placeholder={findlanguage().companyName}
                            value={user.company_account ? user.company_account.name : user.name}
                        />
                        <Input
                            placeholder={findlanguage().contactName}
                            value={
                                user.company_account
                                    ? user.company_account.contactName
                                    : user.contactName
                            }
                        />
                        <Input
                            placeholder={findlanguage().email}
                            value={user.company_account ? user.company_account.email : user.email}
                        />
                        <Input
                            placeholder={findlanguage().phoneNumber}
                            value={user.company_account ? user.company_account.phone : user.phone}
                        />
                        <Input
                            placeholder={findlanguage().address}
                            value={
                                user.company_account
                                    ? user.company_account.address?.street
                                    : user.address?.street
                            }
                        />
                        <Input
                            placeholder={findlanguage().country}
                            value={
                                user.company_account
                                    ? user.company_account.address?.country
                                    : user.address?.country
                            }
                        />
                        <Input
                            placeholder={findlanguage().state}
                            value={
                                user.company_account
                                    ? user.company_account.address?.state
                                    : user.address?.state
                            }
                        />
                        <Input
                            placeholder={findlanguage().city}
                            value={
                                user.company_account
                                    ? user.company_account.address?.city
                                    : user.address?.city
                            }
                        />
                        <Input
                            placeholder={findlanguage().zip}
                            value={
                                user.company_account
                                    ? user.company_account.address?.zipCode
                                    : user.address?.zipCode
                            }
                        />
                        <Input
                            placeholder={findlanguage().website}
                            value={
                                user.company_account ? user.company_account.website : user.website
                            }
                        />
                        <Input
                            placeholder={findlanguage().companyIdentification}
                            value={
                                user?.companyInfo?.identification || ''
                            }
                        />
                        <Input
                            placeholder={findlanguage().numberEmployees}
                            value={
                                user?.companyInfo?.number_employees?.toString() || ''
                            }
                        />
                        <Input
                            placeholder={findlanguage().qualityManagement}
                            value={
                                user?.companyInfo?.quality_management === null || user?.companyInfo?.quality_management === undefined
                                    ? ''
                                    : user?.companyInfo?.quality_management
                                        ? findlanguage().yes
                                        : findlanguage().no
                            }
                        />
                        <Input
                            placeholder={findlanguage().segment}
                            value={
                                user?.companyInfo?.segmento || ''
                            }
                        />

                        {permissions?.myProfile?.canEdit ? (
                            <ProfileFormActions>
                                <DashboardButton onClick={openEdit}>
                                    {findlanguage().editInformation}
                                </DashboardButton>
                            </ProfileFormActions>
                        ) : null}
                    </ProfileFormContent>
                </ProfileContainer>
            </PageCard>

            {user.IsCompanyUser ? (
                <>
                    <PageHeader>
                        <PageHeaderTitle>{findlanguage().companyMyInformation}</PageHeaderTitle>
                    </PageHeader>
                    <PageCard>
                        <ProfileContainer>
                            <ProfileContent>
                                <div style={{ width: '235px' }}></div>

                                {user.IsCompanyUser ? (
                                    <span style={{ marginLeft: '15px' }}>
                                        <ProfileContentSpace />
                                        <DashboardButton
                                            onClick={() =>
                                                navigate('dashboard/account-information')
                                            }
                                        >
                                            {findlanguage().accountInformation}
                                        </DashboardButton>
                                    </span>
                                ) : (
                                    ''
                                )}
                            </ProfileContent>

                            <ProfileFormContent>
                                <Input placeholder={findlanguage().name} value={user.contactName} />
                                <Input placeholder={findlanguage().email} value={user.email} />
                                <Input
                                    placeholder={findlanguage().phoneNumber}
                                    value={user.phone}
                                />
                                <Input placeholder="Department" value={user.department} />

                                <ProfileFormActions>
                                    <DashboardButton onClick={openEditMyInfo}>
                                        {findlanguage().editMyUserInformation}
                                    </DashboardButton>
                                </ProfileFormActions>
                            </ProfileFormContent>
                        </ProfileContainer>
                    </PageCard>
                </>
            ) : (
                ''
            )}

            <p>&nbsp;</p>

            <>
                <PageHeader>
                    <div>
                        <PageHeaderTitle>{findlanguage().additionalInformation}</PageHeaderTitle>
                    </div>
                    <div className="d-flex" style={{ justifyContent: 'right', width: '200' }}>
                        <div>{findlanguage().saveAfterAnyUpdate}</div>
                    </div>
                </PageHeader>

                <PageCard>
                    <PageHeader>
                        <PageHeaderTitle>{findlanguage().aboutCompany}</PageHeaderTitle>
                        {dueDate &&
                        currentDate < dueDate &&
                        user?.subscription?.trial === true &&
                        user.subscription.type === 'free' &&
                        !isValidForPremium() ? (
                            <div>
                                <label
                                    style={{
                                        fontWeight: '700',
                                        color: '#dc3545',
                                        marginLeft: '-75px',
                                    }}
                                >
                                    {findlanguage().requiredFields}
                                    <IconInfo
                                        title="This section was created for users to control their own QMS 
                                                               documents, procedures, formularies, contracts, certificates, etc..."
                                        style={{
                                            width: '16px',
                                            marginLeft: '4px',
                                            marginTop: '-1px',
                                        }}
                                    />
                                    <span
                                        className="info-icon22"
                                        title={findlanguage().fullAddressInstruction}
                                    ></span>
                                </label>
                            </div>
                        ) : null}
                    </PageHeader>

                    <Input
                        type={'textarea'}
                        big
                        maxLength={1500}
                        placeholder=""
                        value={aboutCompany}
                        onChange={(e) => setAboutCompany(e.target.value)}
                        readOnly={!permissions?.myProfile?.canEdit}
                    />
                </PageCard>

                <PageCard>
                    <PageHeader>
                        <PageHeaderTitle>{findlanguage().typeOfBusiness}</PageHeaderTitle>
                        {dueDate &&
                        currentDate < dueDate &&
                        user?.subscription?.trial === true &&
                        user.subscription.type === 'free' &&
                        !isValidForPremium() ? (
                            <div>
                                <label
                                    style={{
                                        fontWeight: '700',
                                        color: '#dc3545',
                                        marginLeft: '-75px',
                                    }}
                                >
                                    {findlanguage().requiredFields}
                                    <IconInfo
                                        title="This section was created for users to control their own QMS 
                                                               documents, procedures, formularies, contracts, certificates, etc..."
                                        style={{
                                            width: '16px',
                                            marginLeft: '4px',
                                            marginTop: '-1px',
                                        }}
                                    />
                                    <span
                                        className="info-icon22"
                                        title={findlanguage().fullAddressInstruction}
                                    ></span>
                                </label>
                            </div>
                        ) : null}
                    </PageHeader>

                    <SelectMany
                        placeholder={''}
                        action={findlanguage().addBusiness}
                        options={business}
                        value={selectedBusiness}
                        onChange={(e) => setSelectedBusiness(e)}
                        itemsselected={selectedBusiness}
                    />
                </PageCard>

                <PageCard>
                    <PageHeader>
                        <PageHeaderTitle>{findlanguage().typeOfCategoryCompanyFit}</PageHeaderTitle>
                        {dueDate &&
                        currentDate < dueDate &&
                        user?.subscription?.trial === true &&
                        user.subscription.type === 'free' &&
                        !isValidForPremium() ? (
                            <div>
                                <label
                                    style={{
                                        fontWeight: '700',
                                        color: '#dc3545',
                                        marginLeft: '-75px',
                                    }}
                                >
                                    {findlanguage().requiredFields}
                                    <IconInfo
                                        title="This section was created for users to control their own QMS 
                                                               documents, procedures, formularies, contracts, certificates, etc..."
                                        style={{
                                            width: '16px',
                                            marginLeft: '4px',
                                            marginTop: '-1px',
                                        }}
                                    />
                                    <span
                                        className="info-icon22"
                                        title={findlanguage().fullAddressInstruction}
                                    ></span>
                                </label>
                            </div>
                        ) : null}
                    </PageHeader>

                    <Checklist
                        options={categories}
                        small
                        value={selectedCategories}
                        onChange={(e) => {
                            getSelected(e)
                        }}
                        itemsselected={selectedCategories}
                    />
                </PageCard>

                {permissions?.myProfile?.canViewSupplier || permissions?.myProfile?.canViewBuyer ? (
                    <>
                        <div id="tab-component" className="tab-component">
                            <div className="tab-header">
                                {permissions?.myProfile?.canViewBuyer ? (
                                    <button
                                        className={`tab-button ${
                                            activeTab === 'buyer' ? 'active' : ''
                                        } tab-button-right`}
                                        onClick={() => setActiveTab('buyer')}
                                    >
                                        {findlanguage().buyerTitle}
                                    </button>
                                ) : null}
                                {permissions?.myProfile?.canViewSupplier ? (
                                    <button
                                        className={`tab-button ${
                                            activeTab === 'supplier' ? 'active' : ''
                                        }`}
                                        onClick={() => setActiveTab('supplier')}
                                    >
                                        {findlanguage().supplierTitle}
                                    </button>
                                ) : null}
                            </div>
                        </div>
                        {activeTab === 'buyer' && permissions?.myProfile?.canViewBuyer ? (
                            <>
                                <PageCard
                                    style={{
                                        borderTopLeftRadius: '0px',
                                        borderTopRightRadius: '0px',
                                    }}
                                >
                                    <PageHeader>
                                        <PageHeaderTitle style={{ width: '100%' }}>
                                            {findlanguage().whatAreThePreRequirements}
                                            {dueDate &&
                                            currentDate < dueDate &&
                                            user?.subscription?.trial === true &&
                                            user.subscription.type === 'free' &&
                                            !isValidForPremium() ? (
                                                <div style={{ float: 'right' }}>
                                                    <label
                                                        style={{
                                                            fontWeight: '700',
                                                            color: '#dc3545',
                                                            marginLeft: '-45px',
                                                        }}
                                                    >
                                                        {findlanguage().requiredFields}
                                                        <IconInfo
                                                            title="This section was created for users to control their own QMS 
                                                               documents, procedures, formularies, contracts, certificates, etc..."
                                                            style={{
                                                                width: '16px',
                                                                marginLeft: '4px',
                                                                marginTop: '-1px',
                                                            }}
                                                        />
                                                        <span
                                                            className="info-icon22"
                                                            title={
                                                                findlanguage()
                                                                    .fullAddressInstruction
                                                            }
                                                        ></span>
                                                    </label>
                                                </div>
                                            ) : null}
                                        </PageHeaderTitle>
                                    </PageHeader>

                                    <SelectMany
                                        title={''}
                                        placeholder={''}
                                        action={findlanguage().other}
                                        options={prerequirements}
                                        value={selectedPreRequirements}
                                        onChange={(e) => setSelectedPreRequirements(e)}
                                        itemsselected={selectedPreRequirements}
                                    />
                                </PageCard>

                                <PageCard>
                                    <PageHeader>
                                        <PageHeaderTitle>
                                            {findlanguage().myRequirements}
                                        </PageHeaderTitle>
                                        {dueDate &&
                                        currentDate < dueDate &&
                                        user?.subscription?.trial === true &&
                                        user.subscription.type === 'free' &&
                                        !isValidForPremium() ? (
                                            <div>
                                                <label
                                                    style={{
                                                        fontWeight: '700',
                                                        color: '#dc3545',
                                                        marginLeft: '-75px',
                                                    }}
                                                >
                                                    {findlanguage().requiredFields}
                                                    <IconInfo
                                                        title="This section was created for users to control their own QMS 
                                                               documents, procedures, formularies, contracts, certificates, etc..."
                                                        style={{
                                                            width: '16px',
                                                            marginLeft: '4px',
                                                            marginTop: '-1px',
                                                        }}
                                                    />
                                                    <span
                                                        className="info-icon22"
                                                        title={
                                                            findlanguage().fullAddressInstruction
                                                        }
                                                    ></span>
                                                </label>
                                            </div>
                                        ) : null}
                                    </PageHeader>
                                    <PageScroller>
                                        <PageContent>
                                            {/* {console.log('selectedRequirements loading list', selectedRequirements)} */}

                                            {loadingRequirements ? (
                                                <LoadBlack size={50} />
                                            ) : selectedRequirements.length === 0 ? (
                                                findlanguage().noRequirementsFound
                                            ) : (
                                                ''
                                            )}

                                            {selectedRequirements.map((item, key) => (
                                                <RequirementsItem key={key}>
                                                    <RequerimentIcon />
                                                    <RequerimentText>{item.label}</RequerimentText>
                                                    {permissions?.myProfile?.canEdit ? (
                                                        <RequirementItemSection>
                                                            <RequirementItemAction
                                                                onClick={() =>
                                                                    editRequirement(item.id)
                                                                }
                                                            >
                                                                <RequirementItemActionIcon
                                                                    icon={'edit'}
                                                                />
                                                            </RequirementItemAction>

                                                            <RequirementItemAction
                                                                onClick={() =>
                                                                    removeRequirement(item.id)
                                                                }
                                                            >
                                                                <RequirementItemActionIcon
                                                                    icon={'trash-white'}
                                                                />
                                                            </RequirementItemAction>
                                                        </RequirementItemSection>
                                                    ) : null}
                                                </RequirementsItem>
                                            ))}
                                        </PageContent>
                                    </PageScroller>
                                    {permissions?.myProfile?.canEdit ? (
                                        <PageContentActions>
                                            <DashboardButton onClick={openAddRequirement}>
                                                {findlanguage().addNewRequirement}
                                            </DashboardButton>
                                        </PageContentActions>
                                    ) : null}
                                </PageCard>
                            </>
                        ) : (
                            <>
                                <PageCard
                                    style={{
                                        borderTopLeftRadius: '0px',
                                        borderTopRightRadius: '0px',
                                    }}
                                >
                                    <PageHeader>
                                        <PageHeaderTitle>
                                            {findlanguage().myCertificates}
                                        </PageHeaderTitle>
                                    </PageHeader>
                                    <PageScroller>
                                        <PageContent>
                                            {loadingCertifications ? (
                                                <>
                                                    <Load black={'true'} size={50} />
                                                </>
                                            ) : selectedCertifications.length === 0 ? (
                                                findlanguage().noCertificatesFound
                                            ) : (
                                                ''
                                            )}
                                            {selectedCertifications.map((item, key) => (
                                                <ProductItem key={key}>
                                                    <ProductItemSection>
                                                        <ProductItemText>
                                                            {item.certification} &#187;{' '}
                                                            {item.certificate}
                                                        </ProductItemText>
                                                    </ProductItemSection>
                                                    {permissions?.myProfile?.canEdit ? (
                                                        <ProductItemSection>
                                                            <ProductItemAction
                                                                onClick={() =>
                                                                    editCertificate(item.id)
                                                                }
                                                            >
                                                                <ProductItemActionIcon
                                                                    icon={'edit-white.png'}
                                                                />
                                                            </ProductItemAction>
                                                            <ProductItemAction
                                                                onClick={() =>
                                                                    removeCertificate(item.id)
                                                                }
                                                            >
                                                                <ProductItemActionIcon
                                                                    icon={'trash-white.svg'}
                                                                />
                                                            </ProductItemAction>
                                                        </ProductItemSection>
                                                    ) : null}
                                                </ProductItem>
                                            ))}
                                        </PageContent>
                                    </PageScroller>
                                    {permissions?.myProfile?.canEdit ? (
                                        <PageContentActions>
                                            <DashboardButton onClick={openAddCertificate}>
                                                {findlanguage().addNewCertificate}
                                            </DashboardButton>
                                        </PageContentActions>
                                    ) : null}
                                </PageCard>

                                <PageCard>
                                    <PageHeader>
                                        <PageHeaderTitle>
                                            {findlanguage().myProducts}
                                        </PageHeaderTitle>
                                        {dueDate &&
                                        currentDate < dueDate &&
                                        user?.subscription?.trial === true &&
                                        user.subscription.type === 'free' &&
                                        !isValidForPremium() ? (
                                            <div>
                                                <label
                                                    style={{
                                                        fontWeight: '700',
                                                        color: '#dc3545',
                                                        marginLeft: '-75px',
                                                    }}
                                                >
                                                    {findlanguage().requiredFields}
                                                    <IconInfo
                                                        title="This section was created for users to control their own QMS 
                                                               documents, procedures, formularies, contracts, certificates, etc..."
                                                        style={{
                                                            width: '16px',
                                                            marginLeft: '4px',
                                                            marginTop: '-1px',
                                                        }}
                                                    />
                                                    <span
                                                        className="info-icon22"
                                                        title={
                                                            findlanguage().fullAddressInstruction
                                                        }
                                                    ></span>
                                                </label>
                                            </div>
                                        ) : null}
                                    </PageHeader>
                                    <PageScroller>
                                        <PageContent>
                                            {/* {console.log('selectedProducts loading list', selectedProducts)} */}

                                            {loadingProducts ? (
                                                <LoadBlack size={50} />
                                            ) : selectedProducts.length === 0 ? (
                                                findlanguage().noProductsFound
                                            ) : (
                                                ''
                                            )}
                                            {selectedProducts.map((item, key) => (
                                                <ProductItem key={key}>
                                                    <ProductItemSection>
                                                        <ProductItemText>
                                                            {item.category} &#187; {item.product}
                                                        </ProductItemText>
                                                    </ProductItemSection>
                                                    {permissions?.myProfile?.canEdit ? (
                                                        <ProductItemSection>
                                                            <ProductItemAction
                                                                onClick={() =>
                                                                    removeProduct(item.id)
                                                                }
                                                            >
                                                                <ProductItemActionIcon
                                                                    icon={'trash-white.svg'}
                                                                />
                                                            </ProductItemAction>
                                                        </ProductItemSection>
                                                    ) : null}
                                                </ProductItem>
                                            ))}
                                        </PageContent>
                                    </PageScroller>
                                    {permissions?.myProfile?.canEdit ? (
                                        <PageContentActions>
                                            <DashboardButton onClick={openAddProduct}>
                                                {findlanguage().addNewProducts}
                                            </DashboardButton>
                                        </PageContentActions>
                                    ) : null}
                                </PageCard>

                                <PageCard>
                                    <PageHeader>
                                        <PageHeaderTitle>
                                            {findlanguage().descriptionProduct}
                                        </PageHeaderTitle>
                                    </PageHeader>

                                    <Input
                                        type={'textarea'}
                                        big
                                        maxLength={1500}
                                        placeholder=""
                                        value={productDescription}
                                        onChange={(e) => setProductDescription(e.target.value)}
                                        readOnly={!permissions?.myProfile?.canEdit}
                                    />
                                </PageCard>

                                <PageCard>
                                    <PageHeader>
                                        <PageHeaderTitle
                                            style={{ color: colorDistribution, width: '100%' }}
                                        >
                                            {findlanguage().distribution}
                                            {dueDate &&
                                            currentDate < dueDate &&
                                            user?.subscription?.trial === true &&
                                            user.subscription.type === 'free' &&
                                            !isValidForPremium() ? (
                                                <div style={{ float: 'right' }}>
                                                    <label
                                                        style={{
                                                            fontWeight: '700',
                                                            color: '#dc3545',
                                                            marginLeft: '-75px',
                                                        }}
                                                    >
                                                        {findlanguage().requiredFields}
                                                        <IconInfo
                                                            title="This section was created for users to control their own QMS 
                                                               documents, procedures, formularies, contracts, certificates, etc..."
                                                            style={{
                                                                width: '16px',
                                                                marginLeft: '4px',
                                                                marginTop: '-1px',
                                                            }}
                                                        />
                                                        <span
                                                            className="info-icon22"
                                                            title={
                                                                findlanguage()
                                                                    .fullAddressInstruction
                                                            }
                                                        ></span>
                                                    </label>
                                                </div>
                                            ) : null}
                                        </PageHeaderTitle>
                                    </PageHeader>

                                    <CheckRow>
                                        <Check
                                            value={distributionState}
                                            onChange={(e) => setDistributionState(e)}
                                            label={findlanguage().distributionState}
                                            checked={distributionState}
                                            locked={!permissions?.myProfile?.canEdit}
                                        />

                                        <Check
                                            value={distributionNational}
                                            onChange={(e) => setDistributionNational(e)}
                                            label={findlanguage().national}
                                            checked={distributionNational}
                                            locked={!permissions?.myProfile?.canEdit}
                                        />

                                        <Check
                                            value={distributionInternational}
                                            onChange={(e) => setDistributionInternational(e)}
                                            label={findlanguage().international}
                                            checked={distributionInternational}
                                            locked={!permissions?.myProfile?.canEdit}
                                        />
                                    </CheckRow>
                                </PageCard>
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}

                {permissions?.myProfile?.canEdit ? (
                    <PageCard>
                        <div className="d-flex" style={{ justifyContent: 'center', width: '100%' }}>
                            <Button primary small onClick={action} loading={loading}>
                                {findlanguage().updateProfile}
                            </Button>
                        </div>
                    </PageCard>
                ) : null}
            </>
            <div className="modaldivrouter">{isModalOpen && <Modal onClose={closeModal} />}</div>
        </>
    )
}
