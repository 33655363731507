import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import Card from '../Card/index.js'

import { PageCard, CardHeader, CardHeaderTitleContainer } from './styled'
import _ from 'lodash'

export default function SelfAssessmentChecklist({
    certifications,
    onChangeCertifications,
    saveCertifications,
    saveDocument,
    form,
    returnHeaders,
    mfsQuestionarie
}) {
    const { findlanguageEsg } = useContext(CoreContext)

    //#region E.2.1
    const e21 = {
        ref: 'E.2.1',
        requirementTitle: findlanguageEsg().e21RequirementTitle,
        inputForYesAnswer: true,
        placeHolderTextYes: findlanguageEsg().e21PlaceHolderTextYes,
        inputForNoAnswer: false,
        placeHolderTextNo: findlanguageEsg().e21PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    //#region E.2.1 Yes

    //#region E.2.1.Y.A

    const e21ya = {
        ref: 'E.2.1.Y.A',
        requirementTitle: findlanguageEsg().e21yaRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e21yaPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const e21yay = {
        ref: 'E.2.1.Y.A.Y',
        requirementTitle: findlanguageEsg().e21yayRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e21yayPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const e21yayy = {
        ref: 'E.2.1.Y.A.Y.Y',
        requirementTitle: findlanguageEsg().e21yayyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e21yayyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
    }

    const e21yayyql1 = {
        ref: 'E.2.1.Y.A.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().e21yayyqlRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e21yayyqlPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        fileForYesAnswerAndNoInput: true,
        color: '#2f8c5d',
        question: true,
    }

    //#endregion

    //#region E.2.1.Y.B

    const e21yb = {
        ref: 'E.2.1.Y.B',
        requirementTitle: findlanguageEsg().e21ybRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e21ybPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const e21yby = {
        ref: 'E.2.1.Y.B.Y',
        requirementTitle: findlanguageEsg().e21ybyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: findlanguageEsg().e21ybyPlaceHolderTextYes,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e21ybyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const e21ybyyqt1 = {
        ref: 'E.2.1.Y.B.Y.Y.QT.1',
        requirementTitle: findlanguageEsg().e21ybyyqt1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const e21ybyyqtn = {
        ref: 'E.2.1.Y.B.Y.Y.QT.N',
        requirementTitle: findlanguageEsg().e21ybyyqtnRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const e21ybyyql1 = {
        ref: 'E.2.1.Y.B.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().e21ybyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e21ybyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.C

    const e21yc = {
        ref: 'E.2.1.Y.C',
        requirementTitle: findlanguageEsg().e21ycRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e21ycPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const e21ycy = {
        ref: 'E.2.1.Y.C.Y',
        requirementTitle: findlanguageEsg().e21ycyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e21ycyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const e21ycyy = {
        ref: 'E.2.1.Y.C.Y.Y',
        requirementTitle: findlanguageEsg().e21ycyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e21ycyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const e21ycyyql1 = {
        ref: 'E.2.1.Y.C.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().e21ycyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e21ycyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.D

    const e21yd = {
        ref: 'E.2.1.Y.D',
        requirementTitle: findlanguageEsg().e21ydRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e21ydPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const e21ydy = {
        ref: 'E.2.1.Y.D.Y',
        requirementTitle: findlanguageEsg().e21ydyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e21ydyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const e21ydyy = {
        ref: 'E.2.1.Y.D.Y.Y',
        requirementTitle: findlanguageEsg().e21ydyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e21ydyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const e21ydyyql1 = {
        ref: 'E.2.1.Y.D.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().e21ydyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e21ydyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#endregion

    //#region A.1.1 NO
    const e21na = {
        ref: 'E.2.1.N.A',
        requirementTitle: findlanguageEsg().e21naRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e21naPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const e21nb = {
        ref: 'E.2.1.N.B',
        requirementTitle: findlanguageEsg().e21nbRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e21nbPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const e21nc = {
        ref: 'E.2.1.N.C',
        requirementTitle: findlanguageEsg().e21ncRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e21ncPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    const returnCondition = (item, layers, category1, category2, category3, category4) => {
        switch (layers) {
            case 0:
                return (
                    <Card
                        item={item}
                        onChangeCertifications={onChangeCertifications}
                        saveCertifications={saveCertifications}
                        saveDocument={saveDocument}
                        certifications={certifications}
                        form={form}
                        mfsQuestionarie={mfsQuestionarie}
                    />
                )
                break
            case 1:
                if (certifications?.find((x) => x.category === category1)?.next_level === true)
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 2:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 3:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 4:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true &&
                    certifications?.find((x) => x.category === category4)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            default:
                break
        }
    }

    return (
        <>
            <PageCard>
                <CardHeader>
                    <CardHeaderTitleContainer>
                        {findlanguageEsg().e21CardHeaderTitleContainer}
                    </CardHeaderTitleContainer>
                </CardHeader>
                {returnHeaders()}
                {returnCondition(e21, 0)}
            </PageCard>
            {
                mfsQuestionarie !== '1' ?
                    <>
                    
            {certifications?.find((x) => x.category === 'E.2.1')?.next_level === true ? (
                <>
                    <PageCard>
                        <CardHeader>
                            <CardHeaderTitleContainer>
                                E.2.1 OPPORTUNITY TO THE DIVERSITY - Answer Yes - A
                            </CardHeaderTitleContainer>
                        </CardHeader>
                        {returnHeaders()}
                        {returnCondition(e21ya, 1, 'E.2.1')}
                        {returnCondition(e21yay, 2, 'E.2.1', 'E.2.1.Y.A')}
                        {returnCondition(e21yayy, 3, 'E.2.1', 'E.2.1.Y.A', 'E.2.1.Y.A.Y')}
                        {returnCondition(
                            e21yayyql1,
                            4,
                            'E.2.1',
                            'E.2.1.Y.A',
                            'E.2.1.Y.A.Y',
                            'E.2.1.Y.A.Y.Y'
                        )}
                    </PageCard>
                    <PageCard>
                        <CardHeader>
                            <CardHeaderTitleContainer>
                                E.2.1 OPPORTUNITY TO THE DIVERSITY - Answer Yes - B
                            </CardHeaderTitleContainer>
                        </CardHeader>
                        {returnHeaders()}
                        {returnCondition(e21yb, 1, 'E.2.1')}
                        {returnCondition(e21yby, 2, 'E.2.1', 'E.2.1.Y.B')}
                        {returnCondition(e21ybyyqt1, 3, 'E.2.1', 'E.2.1.Y.B', 'E.2.1.Y.B.Y')}
                        {returnCondition(e21ybyyqtn, 3, 'E.2.1', 'E.2.1.Y.B', 'E.2.1.Y.B.Y')}
                        {returnCondition(e21ybyyql1, 3, 'E.2.1', 'E.2.1.Y.B', 'E.2.1.Y.B.Y')}
                    </PageCard>
                    <PageCard>
                        <CardHeader>
                            <CardHeaderTitleContainer>
                                E.2.1 OPPORTUNITY TO THE DIVERSITY - Answer Yes - C
                            </CardHeaderTitleContainer>
                        </CardHeader>
                        {returnHeaders()}
                        {returnCondition(e21yc, 1, 'E.2.1')}
                        {returnCondition(e21ycy, 2, 'E.2.1', 'E.2.1.Y.C')}
                        {returnCondition(e21ycyy, 3, 'E.2.1', 'E.2.1.Y.C', 'E.2.1.Y.C.Y')}
                        {returnCondition(
                            e21ycyyql1,
                            4,
                            'E.2.1',
                            'E.2.1.Y.C',
                            'E.2.1.Y.C.Y',
                            'E.2.1.Y.C.Y.Y'
                        )}
                    </PageCard>
                    <PageCard>
                        <CardHeader>
                            <CardHeaderTitleContainer>
                                E.2.1 OPPORTUNITY TO THE DIVERSITY - Answer Yes - D
                            </CardHeaderTitleContainer>
                        </CardHeader>
                        {returnHeaders()}
                        {returnCondition(e21yd, 1, 'E.2.1')}
                        {returnCondition(e21ydy, 2, 'E.2.1', 'E.2.1.Y.D')}
                        {returnCondition(e21ydyy, 3, 'E.2.1', 'E.2.1.Y.D', 'E.2.1.Y.D.Y')}
                        {returnCondition(
                            e21ydyyql1,
                            4,
                            'E.2.1',
                            'E.2.1.Y.D',
                            'E.2.1.Y.D.Y',
                            'E.2.1.Y.D.Y.Y'
                        )}
                    </PageCard>
                </>
            ) : null}
            {certifications?.find((x) => x.category === 'E.2.1')?.next_level === false ? (
                <PageCard>
                    <CardHeader>
                        <CardHeaderTitleContainer>
                            E.2.1 OPPORTUNITY TO THE DIVERSITY - Answer No
                        </CardHeaderTitleContainer>
                    </CardHeader>
                    {returnHeaders()}
                    {returnCondition(e21na, 0)}
                    {returnCondition(e21nb, 0)}
                    {returnCondition(e21nc, 0)}
                </PageCard>
            ) : null}
                    </>
                    :
                    null
            }
        </>
    )
}
