import React, { useContext, useState, useEffect } from 'react'
import { CoreContext } from 'context/CoreContext'
import { useHistory } from 'react-router-dom'

import { PageCard, ContentFilters, FilterContent } from './styled'

import DashboardButton from 'components/Dashboard/Button'
import Response from './Response/index'
import Owner from './Owner/index'
import Admin from './Admin/index'

export default function MySuppliers() {
    const { findlanguage, permissions } = useContext(CoreContext)
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)
    const [screen, setScreen] = useState(1)

    const changeScreen = (screenId) => {
        setScreen(screenId)
    }

    useEffect(() => {
        if (!permissions?.mySuppliers?.canView && !permissions?.mySuppliers?.canEdit) {
            navigate('dashboard')
        }
    }, [permissions, navigate])

    return (
        <>
            <PageCard>
                <ContentFilters>
                    <FilterContent>
                        <DashboardButton
                            icon={'check'}
                            green
                            outline={screen === 1 ? false : true}
                            onClick={() => changeScreen(1)}
                        >
                            Forms to respond
                        </DashboardButton>
                        <DashboardButton
                            icon={'check'}
                            green
                            outline={screen === 2 ? false : true}
                            onClick={() => changeScreen(2)}
                        >
                            Forms you own
                        </DashboardButton>
                        <DashboardButton
                            icon={'check'}
                            green
                            outline={screen === 3 ? false : true}
                            onClick={() => changeScreen(3)}
                        >
                            Admin
                        </DashboardButton>
                    </FilterContent>
                </ContentFilters>
            </PageCard>
            
            {screen === 1 ? <Response /> : null}
            {screen === 2 ? <Owner/> : null}
            {screen === 3 ? <Admin/> : null}
        </>
    )
}
