import styled from 'styled-components'

export const FooterContainer = styled.div.attrs({})`
    display: flex;
    align-items: center;
    border: 0px solid red;
    padding: 0px 40px;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    ${(props) =>
        props.centred
            ? `
            justify-content: center;
        `
            : `
            justify-content: space-between;
        `}
`
export const FooterSpace = styled.div.attrs({})`
    width: 66px;
    ${(props) =>
        props.small
            ? `
            width: 17px
        `
            : `
        `}
`
export const FooterChatIcon = styled.img.attrs({
    src: '/icons/chat.svg',
})`
    width: 25px;
    height: 25px;
`
