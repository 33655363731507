import React from 'react'
import ControlButtons from '../ControlButtons/index'

function Calendar({ element, handleLabelTextChange, toggleRequired, handleResize, removeElement, is_draft }) {
     return (
         <div key={element.id} className="mb-3 col-12">
             <div style={{ display: 'inline-flex' }}>
                 <label className="form-label">
                     Calendar
                 </label>
                 <div className="form-check" style={{ marginLeft: '9px' }}>
                     <input
                         type="checkbox"
                         id={`required-check-${element.id}`}
                         checked={element.is_required}
                         onChange={(e) => toggleRequired(element.id, e.target.checked)}
                         className="form-check-input"
                         disabled={is_draft}
                     />
                     <label htmlFor={`required-check-${element.id}`} className="form-check-label">
                         Week
                     </label>
                 </div>
             </div>
             <input
                 type="text"
                 className="form-control"
                 value={element.label || ''}
                 onChange={(e) => handleLabelTextChange(element.id, e)}
                 placeholder="Add a label for calendar field here"
                 disabled={is_draft}
             />

             <ControlButtons
                 elementId={element.id}
                 onResize={handleResize}
                 onRemove={removeElement}
                 is_draft={is_draft}
             />
         </div>
    );
}

export default Calendar;