import styled from 'styled-components'

export const PageCard = styled.div.attrs({})`
    background: var(--white-color);
    padding: 24px;
    margin-top: 48px;
    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
`
export const MfsGreenCertificate = styled.img.attrs({
    src: '/icons/certificate-mfs.svg',
    width: 200,
})``

export const MfsGrayCertificate = styled.img.attrs({
    src: '/icons/certificate-mfs-gray.svg',
    width: 200,
})``
