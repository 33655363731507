import React, { useContext, useState, useEffect } from 'react'

import { FormTitle, CheckList, CheckContent, CheckListPool } from './styled'

import Button from 'components/Form/Button'
import Check from 'components/Form/Check'
import { CoreContext } from 'context/CoreContext'
import { Load } from 'ui/styled'

export default function Checklist({
    label,
    action,
    options,
    small,
    actionMore,
    successable,
    onChange,
    value,
    itemsselected,
    checkkey,
}) {
    const { setModal, user, language, permissions } = useContext(CoreContext)

    const [currentOptions, setCurrentOptions] = useState(options ? options : [])
    const [currentAdded, setCurrentAdded] = useState(value ? value : [])

    const isAdded = (item) => {
        if (language === 'portugues') {
            if (checkkey === 'title')
                return (
                    currentAdded?.length &&
                    currentAdded?.map((mit) => mit.label_pt).includes(item.title_pt)
                )
            else return currentAdded?.length && currentAdded?.map((mit) => mit.id).includes(item.id)
        } else {
            if (checkkey === 'title')
                return (
                    currentAdded?.length &&
                    currentAdded?.map((mit) => mit.label).includes(item.title)
                )
            else return currentAdded?.length && currentAdded?.map((mit) => mit.id).includes(item.id)
        }
    }

    const toggleAdd = (item, key) => {
        const selected = currentAdded || []

        let nxtValue = null

        if (language === 'portugues') {
            if (checkkey === 'title') {
                nxtValue = isAdded(item, key)
                    ? [...selected.filter((fit) => fit.label_pt !== item.title_pt)]
                    : [
                        ...selected,
                        {
                            id: item.id,
                            title_pt: item.title_pt,
                            label_pt: item.title_pt,
                            title: item.title,
                            label: item.title,
                        },
                    ]
            } else {
                nxtValue = isAdded(item, key)
                    ? [...selected.filter((fit) => fit.id !== item.id)]
                    : [...selected, item]
            }
        } else {
            if (checkkey === 'title') {
                nxtValue = isAdded(item, key)
                    ? [...selected.filter((fit) => fit.label !== item.title)]
                    : [...selected, { id: item.id, title: item.title, label: item.title }]
            } else {
                nxtValue = isAdded(item, key)
                    ? [...selected.filter((fit) => fit.id !== item.id)]
                    : [...selected, item]
            }
        }

        setCurrentAdded(nxtValue)
        if (onChange && typeof onChange === 'function') {
            onChange(nxtValue)
        }
    }

    const addMore = () => {
        const rand = new Date().getTime()

        if (language === 'portugues')
            setModal({
                type: 'add',
                title: actionMore,
                successable,
                action: (value) =>
                    setCurrentOptions([
                        ...currentOptions,
                        { id: rand, title: value, label: value, title_pt: value, label_pt: value },
                    ]),
            })
        else
            setModal({
                type: 'add',
                title: actionMore,
                successable,
                action: (value) =>
                    setCurrentOptions([
                        ...currentOptions,
                        { id: rand, title: value, label: value },
                    ]),
            })
    }

    useEffect(() => {
        if (options) setCurrentOptions(options)
    }, [options])

    useEffect(() => {
        if (itemsselected) {
            setCurrentAdded([...itemsselected])
        }
    }, [itemsselected])

    return (
        <>
            {currentOptions.length === 0 ? <Load black={'1'} size={50} /> : ''}

            <CheckList>
                {label ? <FormTitle>{label}</FormTitle> : null}
                <CheckListPool>
                    {currentOptions.map((item, key) => (
                        <CheckContent key={key}>
                            {language === 'english' ? (
                                <Check
                                    key={key}
                                    title={item.title}
                                    label={item.description}
                                    onChange={() => toggleAdd(item)}
                                    checked={isAdded(item)}
                                    locked={!permissions?.myProfile?.canEdit}
                                />
                            ) : null}
                            {language === 'portugues' ? (
                                <Check
                                    key={key}
                                    title={item.title_pt}
                                    label={item.description_pt}
                                    onChange={() => toggleAdd(item)}
                                    checked={isAdded(item)}
                                    locked={!permissions?.myProfile?.canEdit}
                                />
                            ) : null}
                        </CheckContent>
                    ))}
                </CheckListPool>
            </CheckList>

            {action ? (
                <div className="d-flex flex-row-reverse" style={{ width: '100%' }}>
                    <CheckContent action={'true'} onClick={addMore}>
                        <Button primary>{action}</Button>
                    </CheckContent>
                </div>
            ) : (
                ''
            )}
        </>
    )
}
