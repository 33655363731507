import React from 'react'

import { PageCard, MfsGreenCertificate, MfsGrayCertificate } from './styled'

export default function Score({ questionnaire }) {
    const cssTable = { fontSize: '15px', fontWeight: '700', color: '#646464' }

    return (
        <PageCard>
            <table className="table">
                <thead style={{ backgroundColor: '#263640', color: 'white', textAlign: 'center' }}>
                    <tr>
                        <th scope="col">SCORE</th>
                    </tr>
                </thead>
                <tbody className="table-group-divider" style={{ borderTopColor: '#263640' }}>
                    <tr>
                        <td style={cssTable}>
                            A. LEGAL REQUIREMENTS{' '}
                            <div style={{ float: 'right' }}>{questionnaire.score_a}%</div>
                        </td>
                    </tr>
                    <tr>
                        <td style={cssTable}>
                            B.FOOD SAFETY{' '}
                            <div style={{ float: 'right' }}>{questionnaire.score_b}%</div>
                        </td>
                    </tr>
                    <tr>
                        <td style={cssTable}>
                            C.SPECIFIC QUESTION PER CATEGORY{' '}
                            <div style={{ float: 'right' }}>{questionnaire.score_c}%</div>
                        </td>
                    </tr>
                    <tr>
                        <td
                            style={{
                                fontSize: '15px',
                                fontWeight: '700',
                                color: '#646464',
                                textAlign: 'center',
                            }}
                        >
                            <br />
                            {questionnaire.form_status === 'accepted' &&
                            questionnaire.score_a >= 70 &&
                            questionnaire.score_b >= 70 &&
                            questionnaire.score_c > 0 ? (
                                <MfsGreenCertificate />
                            ) : (
                                <MfsGrayCertificate />
                            )}
                            <br />
                            <br />
                        </td>
                    </tr>
                </tbody>
            </table>
        </PageCard>
    )
}
