import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';

import React, { useEffect, useState } from 'react';
import { GetAllUsersPerRole, GetAllDocuments, GetTierTwo } from 'services/users';
import { Card, Grid, Typography } from '@material-ui/core';
import MarkerClusterer from "@googlemaps/markerclustererplus";

const MapsExternal = () => {
    const [data, setData] = useState([]);
    const [map, setMap] = useState(null);
    const [locations, setLocations] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [countryCount, setCountryCount] = useState([]);
    const [mapHeight, setMapHeight] = useState("500px");

    useEffect(() => {
        const fetchData = async () => {
            const userData = await GetAllUsersPerRole();

            let buyerCount = 0;
            let supplierCount = 0;
            let serviceProviderCount = 0;

            const locationsTemp = [];
            const countryCounter = {};

            userData.forEach((user) => {
                if (user.role === 'Authenticated-buyer') buyerCount++;
                else if (user.role === 'Authenticated-supplier') supplierCount++;
                else if (user.role === 'Authenticated-service-provider') serviceProviderCount++;
                if (user.latitude && user.longitude) {
                    locationsTemp.push({
                        lat: user.latitude,
                        lng: user.longitude,
                        status: user.status,
                        company_name: user.company_name,
                        avatar_url: user.avatar_url ? user.avatar_url : '/images/no-image.png'
                    });
                }

                if (user.country) {
                    if (!countryCounter[user.country]) {
                        countryCounter[user.country] = 0;
                    }
                    countryCounter[user.country]++;
                }
            });

            setLocations(locationsTemp)

            setCountryCount(Object.entries(countryCounter).map(([country, count]) => ({ country, count })).sort((a, b) => b.count - a.count));
        }

        updateMapHeight();
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const gmap = new window.google.maps.Map(document.getElementById("map"), {
                center: { lat: 37.0902, lng: -95.7129 },
                zoom: 3,
                mapTypeId: 'roadmap',
            });
            setMap(gmap);

            markers.forEach(marker => marker.setMap(null));
            setMarkers([]);

            // Await all icons creation and then create markers
            const newMarkersData = await Promise.all(locations.map(async (location) => {
                const iconUrl = await createUserIcon(location.avatar_url, location.status);
                return new window.google.maps.Marker({
                    position: location,
                    map: gmap,
                    icon: {
                        url: iconUrl,
                        scaledSize: new window.google.maps.Size(40, 40)
                    },
                    title: location.company_name
                });
            }));

            setMarkers(newMarkersData);

            new MarkerClusterer(gmap, newMarkersData, {
                imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
            });

        }
        fetchData();
    }, [locations]);

    const createUserIcon = async (avatar_url, status) => {
        const canvas = document.createElement('canvas');
        canvas.width = 40; // match the desired marker size
        canvas.height = 40;
        const context = canvas.getContext('2d');

        // Draw outer circle
        context.beginPath();
        context.arc(20, 20, 20, 0, Math.PI * 2, false); // circle with radius of 20
        context.fillStyle = status ? '#00FF00' : '#FF0000'; // green for online, red for offline
        context.fill();

        const img = new Image();
        img.crossOrigin = "Anonymous"; // Attempt to address any potential CORS issues

        try {
            if (avatar_url === '/images/no-image.png')
                img.src = '/images/no-image.png';
            else
                img.src = await fetchImageAsBlob(avatar_url);

            // Convert image to data URL once loaded
            return new Promise((resolve) => {
                img.onload = () => {
                    context.beginPath();
                    context.arc(20, 20, 18, 0, Math.PI * 2, true);
                    context.closePath();
                    context.clip();
                    context.drawImage(img, 1, 1, 38, 38);

                    resolve(canvas.toDataURL());
                };
            });
        } catch (error) {
            console.error('Error fetching the image:', error);
            return canvas.toDataURL(); // If there's an error fetching the image, just return the status circle
        }
    };

    function fetchImageAsBlob(url) {
        return fetch(url, {
            mode: 'no-cors'
        })
            .then(response => response.blob())
            .then(blob => {
                return URL.createObjectURL(blob);
            });
    }

    const updateMapHeight = () => {
        const contentWrapper = document.querySelector('.content-wrapper');
        if (contentWrapper) {
            setMapHeight(`${contentWrapper.offsetHeight - 120}px`);
        }
    };
    
    return (
        <div className="container-fluid">
            <div id="map" style={{ width: "100%", height: mapHeight }}></div>
        </div>
    );
};
export default MapsExternal;