import React from 'react'
import ControlButtons from '../ControlButtons/index'

function Title({ element, handleLabelTextChange, handleResize, removeElement, is_draft }) {
    return (
        <div key={element.id} className="mb-3 col-12">
            <label className="form-label">Title</label>
            <textarea
                key={element.id}
                className="form-control mb-3"
                value={element.label || ''}
                onChange={(e) => handleLabelTextChange(element.id, e)}
                placeholder="Add your description here"
                disabled={is_draft}
            />


            <ControlButtons
                elementId={element.id}
                onResize={handleResize}
                onRemove={removeElement}
                is_draft={is_draft}
            />
        </div>
    );
}

export default Title;