import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const PageFormRow = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
`

export const PageFormSpace = styled.div.attrs({})`
    margin-right: 12px;
`
