import React, { useContext, useState, useEffect } from 'react'
import 'admin-lte/dist/css/adminlte.min.css'
import { Link, Route, Switch, useRouteMatch, useHistory } from 'react-router-dom'
import { CoreContext } from 'context/CoreContext'
import '@fortawesome/fontawesome-free/css/all.min.css'
import Dashboard from './Dashboard/index'
import Usuarios from './Users/index'
import Requirements from './Requirements/index'
import Maps from './Maps/index'
import ESG from './ESG/index'
import Precheck from './Precheck/index'
import FreeFilter from './FreeFilter/index'

function Backoffice() {
    const { user } = useContext(CoreContext) // access user from context
    const [isSidebarOpen, setIsSidebarOpen] = useState(true)
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    let { path } = useRouteMatch()

    useEffect(() => {
        if (user.company_id !== 1)
            navigate(`dashboard`)
    }, []);

    return (
        <div className="wrapper">
            <aside
                className={`main-sidebar sidebar-dark-primary elevation-4 ${
                    isSidebarOpen ? '' : 'toggled'
                }`}
            >
                <div
                    className="brand-link"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <img
                        src={user?.avatar?.url || '/images/no-image.png'} // URL to the image
                        alt={user.name} // Alt text for the image
                        style={{
                            // Inline styles for the image
                            width: '150px',
                            height: '150px',
                            borderRadius: '50%', // Makes the image circular
                            objectFit: 'cover', // Ensures the aspect ratio is preserved
                            marginBottom: '10px', // Adds some spacing between the image and the text
                        }}
                    />
                    <div className="brand-text font-weight-light">
                        <b>{user.name}</b>
                    </div>
                </div>

                <div className="sidebar">
                    <nav className="mt-2">
                        <ul
                            className="nav nav-pills nav-sidebar flex-column"
                            data-widget="treeview"
                            role="menu"
                        >
                            <li className="nav-item">
                                <Link to={`${path}/dashboard`} className="nav-link">
                                    Dashboard
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`${path}/usuarios`} className="nav-link">
                                    Usuarios
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`${path}/requirements`} className="nav-link">
                                    Requirements
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`${path}/maps`} className="nav-link">
                                    Maps
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`${path}/esg`} className="nav-link">
                                    ESG
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`${path}/precheck`} className="nav-link">
                                    Precheck
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`${path}/freeusers`} className="nav-link">
                                    Free Filter / Users
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={``} className="nav-link">
                                    Go Back
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
            <div className="content-wrapper">
                <section className="content">
                    <div className="container-fluid">
                        <Switch>
                            <Route path={`${path}/dashboard`}>
                                <Dashboard />
                            </Route>
                            <Route path={`${path}/usuarios`}>
                                <Usuarios />
                            </Route>
                            <Route path={`${path}/requirements`}>
                                <Requirements />
                            </Route>
                            <Route path={`${path}/maps`}>
                                <Maps />
                            </Route>
                            <Route path={`${path}/esg`}>
                                <ESG />
                            </Route>
                            <Route path={`${path}/precheck`}>
                                <Precheck />
                            </Route>
                            <Route path={`${path}/freeusers`}>
                                <FreeFilter />
                            </Route>
                        </Switch>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Backoffice
