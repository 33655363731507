import { GET, POST, PUT, POSTAUTH } from './api'
import { ReadObject, SaveObject, ClearStorage } from './storage'
import Cookies from 'js-cookie'

export const DoRegister = async (params) => {
    return await POST(`/users`, params)
}

export const DoRegisterBuyer = async (params) => {
    return await POST(`/register/buyer`, params)
}

export const DoRegisterAdmin = async (params) => {
    return await POST(`/register/admin`, params)
}

export const DoRegisterSupplier = async (params) => {
    return await POST(`/register/supplier`, params)
}

export const DoRegisterServiceProvider = async (params) => {
    return await POST(`/register/service-provider`, params)
}

export const DoRegisterConsumer = async (params) => {
    return await POST(`/register/consumer`, params)
}

export const DoLogin = async (params) => {
    const response = await POST(`/auth/local`, params)

    if (response?.jwt) {
        await SaveObject('authentication', response)
    }
    return response
}

export const DoLoginCheckForAuth = async (params) => {
    const response = await POSTAUTH(`/auth/local`, params)

    // console.log(response)

    if (response?.user) {
        if (
            window.location.href.includes('dev-app') &&
            !response?.user?.username.includes('- Test') &&
            !response?.user?.username.includes('-test')
        ) {
            return false
        }

        if (
            response?.user?.acceptedUserTermsAndConditions === undefined ||
            response?.user?.acceptedUserTermsAndConditions === null ||
            response?.user?.acceptedUserTermsAndConditions === false
        )
            return response

        if (response?.jwt) {
            await SaveObject('authentication', response)
        }
    }

    return response
}

export const GetCompany = async (params) => {
    const response = await GET(`/users/company`, true)
    return response
}

export const GetMe = async (params) => {
    const response = await GET(`/users/me`, true)
    return response
}

export const DoLogout = async () => {
    ClearStorage()
    Cookies.remove('auth_token')
    return true
}

export const DoForgotPassword = async (params) => {
    return await POST(`/auth/forgot-password`, params)
}

export const DoResetPassword = async (params) => {
    return await POST(`/auth/reset-password`, params)
}

export const IsLogged = () => {
    const jwt = Cookies.get('auth_token')
    return jwt
}

export const ReadMe = async () => {
    const response = await GET(`/users/me`, true)
    //console.log('ReadMe', response)
    return response
}

export const ReadRegisterInfos = async () => {
    return await GET(`/register/infos`, false)
}

export const UpdateMe = async (payload) => {
    return await PUT(`/update/me/`, payload, true)
}

export const UpdateCompany = async (payload) => {
    return await PUT(`/update/company/`, payload, true)
}

export const UpdateMyUser = async (payload) => {
    return await PUT(`/update/myuser/`, payload, true)
}

export const UpdatePassword = async (payload) => {
    return await PUT(`/users/me/password`, payload, true)
}

export const CheckSubscription = async () => {
    return await GET(`/my-subscriptions`, true)
}

export const PostLogAccess = async (payload) => {
    return await POST(`/user-log-accesses/`, payload, true)
}
