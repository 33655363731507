import React, { useContext, useEffect, useState } from 'react'

import {
    ProfileFormContent,
    ProfileFormActions,
    PageFormSpace,
    PageFormWrapper,
    ModalFormLabel,
    CheckContent,
    SliderContainer,
    Slider,
    SliderLabel,
    Lock,
} from './styled'

import Input from 'components/Form/Input'
import Button from 'components/Form/Button'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import Check from 'components/Form/Check'

import { ReadCities, ReadCountries, ReadStates } from 'services/location'
import { ReadCertificationsAndApprovals, ReadCompanyCategories, ReadServicesOffered, ReadSupplierProductsSource, ReadProvidedServices } from 'services/infos'
import CheckMany from 'components/Form/CheckMany'
import { Load } from 'ui/styled'
import { ReadAvailableFilters, ReadProducts } from 'services/filters'
import _ from 'lodash'

export default function ModalFilterProduct() {
    const { setModal, filter, setFilter, premium, findlanguage, language } = useContext(CoreContext)

    const [servicesOffered, setServicesOffered] = useState([])
    const [servicesProvided, setServicesProvidedd] = useState([])
    const [form, setForm] = useState(filter)
    const formValue = (ref) => {
        return form?.[ref] ? form?.[ref] : ''
    }
    const changeForm = (value, ref) => {
        setForm({ ...form, [ref]: value })
    }

    const close = () => {
        setModal(null)
    }

    const isAdded = (item, key) => {
        return (
            formValue(key)?.length &&
            formValue(key)
                ?.map((mit) => mit.id)
                .includes(item.id)
        )
    }

    const toggleAdd = (item, key) => {
        const selected = formValue(key) || []
        const nxtValue = isAdded(item, key)
            ? [...selected.filter((fit) => fit.id != item.id)]
            : [...selected, item]
        changeForm(nxtValue, key)
    }

    // add data integration
    const [infos, setInfos] = useState({})
    const [countries, setCountries] = useState([])
    const [loading, setLoading] = useState(false)

    const init = async () => {
        setLoading(true)

        if (language === 'portugues')
            ReadCountries().then((resp) =>
                setCountries(
                    resp.map((item) => ({
                        ...item,
                        label: item.name_pt,
                        title: item.name,
                        value: item.iso2,
                    }))
                )
            )
        else
            ReadCountries().then((resp) =>
                setCountries(
                    resp.map((item) => ({
                        ...item,
                        label: item.name,
                        title: item.name,
                        value: item.iso2,
                    }))
                )
            )
        let products = await ReadSupplierProductsSource()

        let companyCategories = await ReadCompanyCategories()
        const certifications = await ReadCertificationsAndApprovals()


        const availableFilters = await ReadAvailableFilters()
        let certificationsList = []

        let a = []

        if (language === 'portugues') {
            products.forEach((x) =>
                x.items.map((y) =>
                    a.push({
                        value: y.name,
                        label: y.name_pt,
                    })
                )
            )

            companyCategories = companyCategories
                .sort((a, b) => (a.title > b.title) - (a.title < b.title))
                .map((x) => {
                    x.value = x.title
                    x.label = x.title_pt
                    return x
                })
        } else if (language === 'english') {
            products.forEach((x) =>
                x.items.map((y) =>
                    a.push({
                        value: y.name,
                        label: y.name,
                    })
                )
            )

            companyCategories = companyCategories
                .sort((a, b) => (a.title > b.title) - (a.title < b.title))
                .map((x) => {
                    x.value = x.title
                    x.label = x.title
                    return x
                })
        }

        if (language === 'portugues') {
            certifications.forEach((x) => {
                return x.items.forEach((y) => {
                    certificationsList.push({
                        value: y.id,
                        label: y.label_pt,
                    })
                })
            })
        } else {
            certifications.forEach((x) => {
                return x.items.forEach((y) => {
                    certificationsList.push({
                        value: y.id,
                        label: y.label,
                    })
                })
            })
        }

        setInfos({
            companyCategories,
            certifications: certificationsList.sort(
                (a, b) => (a.label > b.label) - (a.label < b.label)
            ),
            products: a.sort((a, b) => (a.label > b.label) - (a.label < b.label)),
            availableFilters,
        })

        const _loadServicesOffered = await ReadServicesOffered()
        const tempServicesOffered = _loadServicesOffered
            .sort((a, b) => (a.title > b.title ? 1 : -1))
            .map((item, key) => {
                if (language === 'portugues')
                    return {
                        value: item.label,
                        label: item.label,
                    }
                else return { value: item.label, label: item.label }
            })

        setServicesOffered(tempServicesOffered)


        const _loadProvidedServices = await ReadProvidedServices()
        const tempProvidedServices = _loadProvidedServices
            .sort((a, b) => (a.title > b.title ? 1 : -1))
            .map((item, key) => {
                if (language === 'portugues') {
                    return {
                        value: item.label,
                        label: item.label,
                    }
                } else return { value: item.label, label: item.label }
            })

        setServicesProvidedd(tempProvidedServices)


        if (formValue('country') !== '') changeCountry()

        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])

    const serviceTypeOptions = [
        { value: 4, label: findlanguage().dataBase, type: 'Non Member' },
        { value: 3, label: findlanguage().buyerTitle, type: 'Buyer' },
        { value: 2, label: findlanguage().serviceProviderTitle, type: 'Service Provider' },
        { value: 1, label: findlanguage().supplierTitle, type: 'Supplier' },
    ]

    const validations = [
        { value: 'approved', label: 'My Pre-Check' },
        //{ value: 'mfsvalidation', label: 'Food Safety' },
        //{ value: 'esg', label: 'ESG' },
    ]

    const distribution = [
        { value: 'state', label: findlanguage().distributionState },
        { value: 'nationwide', label: findlanguage().national },
        { value: 'international', label: findlanguage().international },
    ]

    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])

    const changeCountry = async (item) => {
        let countriesAvailable = []
        setLoading(true)
        setStates([])
        setCities([])

        let states = []

        if (item === undefined) item = formValue('country')

        for (var i = 0; i < item.length; i++) {
            const result = await ReadStates(item[i].value)

            if (!countriesAvailable.includes(item[i].iso2)) countriesAvailable.push(item[i].iso2)

            result.forEach((x) => {
                states.push({
                    ...x,
                    country: item[i].iso2,
                })
            })
        }

        if (states.length !== 0) {
            setStates(
                states
                    .sort((a, b) => (a.name > b.name) - (a.name < b.name))
                    .map((item) => ({
                        ...item,
                        title: item.name,
                        label: item.name,
                        value: item.iso2,
                    }))
            )

            let holdStates = formValue('state') === '' ? [] : _.cloneDeep(formValue('state'))
            holdStates = holdStates.filter((x) => countriesAvailable.includes(x.country))

            let holdCity = formValue('city') === '' ? [] : _.cloneDeep(formValue('city'))
            holdCity = holdCity.filter((x) => countriesAvailable.includes(x.country))

            setForm({
                ...form,
                country: item,
                state: holdStates,
                city: holdCity,
            })

            let city = []
            for (var i = 0; i < holdStates.length; i++) {
                const result = await ReadCities(holdStates[i].country, holdStates[i].iso2)

                result.forEach((x) =>
                    city.push({
                        ...x,
                        country: holdStates[i].country,
                    })
                )
            }

            if (city.length !== 0) {
                setCities(
                    city.map((item) => ({
                        ...item,
                        key: item.id,
                        title: item.name,
                        label: item.name,
                        value: item.name,
                        country: item.country,
                    }))
                )
            }
        } else {
            changeForm(item, 'country')
        }

        setLoading(false)
    }

    const changeState = async (item) => {
        setLoading(true)
        if (item.length !== 0) {
            let city = []
            let stateAvailable = []

            for (var i = 0; i < item.length; i++) {
                const result = await ReadCities(item[i].country, item[i].iso2)

                if (!stateAvailable.includes(item[i].iso2)) stateAvailable.push(item[i].iso2)

                result.forEach((x) =>
                    city.push({
                        ...x,
                        country: item[i].country,
                        state: item[i].iso2,
                    })
                )
            }

            if (city.length !== 0) {
                setCities(
                    city.map((item) => ({
                        ...item,
                        key: item.id,
                        title: item.name,
                        label: item.name,
                        value: item.name,
                        country: item.country,
                    }))
                )

                let holdCity = formValue('city') === '' ? [] : _.cloneDeep(formValue('city'))
                holdCity = holdCity.filter((x) => stateAvailable.includes(x.state))

                setForm({
                    ...form,
                    state: item,
                    city: holdCity,
                })
                setLoading(false)
            } else {
                changeForm(item, 'state')
                setLoading(false)
            }
        } else {
            setCities([])
            setForm({
                ...form,
                state: [],
                city: [],
            })
            setLoading(false)
        }
    }

    const changeCity = async (item) => {
        changeForm(item, 'city')
    }

    const apply = () => {
        if(!isTrueServiceProvider){
            form.servicesOffered = []
            form.servicesProvided = []
        }

        setFilter(form)
        close()
    }

    const clean = () => {
        setFilter({})
        close()
    }

    const isLocked = (term) => {
        if (premium.due) return false
        return !infos?.availableFilters?.includes(term)
    }

    const marginTop = { marginTop: '10px' }

    const isDisabledNonMember = formValue('service_type').length === 1 && formValue('service_type')[0].value === 4;
    const isDisabledServiceProvider = formValue('service_type').length === 1 && formValue('service_type')[0].value === 2;
    
    const isTrueServiceProvider = !formValue('service_type') || formValue('service_type').length === 0 || formValue('service_type').some(obj => obj.value === 2);

    return (
        <>
            <ModalWrapper left>
                <ProfileFormContent>
                    {loading ? (
                        <>
                            <Load white={'true'} />
                            <br></br>
                            <div style={{ textAlign: 'center' }}>Loading data, please wait.</div>
                        </>
                    ) : (
                        <>
                            <ModalFormLabel sublabel={true}>
                                {findlanguage().accountType}
                            </ModalFormLabel>
                            <Select
                                onChange={(values) => changeForm(values, 'service_type')}
                                isMulti
                                name="colors"
                                options={serviceTypeOptions.sort(
                                    (a, b) => (a.label > b.label) - (a.label < b.label)
                                )}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={formValue('service_type')}
                                placeholder={findlanguage().select}
                            />

                            <ModalFormLabel
                                sublabel={true}
                                locked={isLocked('category')}
                                style={marginTop}
                            >
                                {findlanguage().category} {isLocked('category') ? <Lock /> : null}
                            </ModalFormLabel>
                            <Select
                                onChange={(values) => changeForm(values, 'category')}
                                isMulti
                                isDisabled={isDisabledNonMember || isDisabledServiceProvider}
                                name="colors"
                                options={infos?.companyCategories?.sort(
                                    (a, b) => (a.label > b.label) - (a.label < b.label)
                                )}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={formValue('category')}
                                placeholder={isDisabledNonMember || isDisabledServiceProvider ? findlanguage().itemNotAvailable : findlanguage().select}
                            />

                            <ModalFormLabel
                                sublabel={true}
                                locked={isLocked('products_in')}
                                style={marginTop}
                            >
                                {findlanguage().products}{' '}
                                {isLocked('products_in') ? <Lock /> : null}
                            </ModalFormLabel>

                            <CreatableSelect
                                onChange={(values) => changeForm(values, 'products')}
                                isMulti
                                isDisabled={isDisabledServiceProvider}
                                name="colors"
                                options={infos?.products}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={formValue('products')}
                                placeholder={isDisabledServiceProvider ? findlanguage().itemNotAvailable : findlanguage().select}
                            />

                            <ModalFormLabel
                                sublabel={true}
                                locked={isLocked('category')}
                                style={marginTop}
                            >
                                {findlanguage().country} {isLocked('location') ? <Lock /> : null}
                            </ModalFormLabel>

                            <Select
                                onChange={(values) => changeCountry(values)}
                                name="colors"
                                isMulti
                                options={countries}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={formValue('country')}
                                placeholder={findlanguage().select}
                            />

                            <ModalFormLabel
                                sublabel={true}
                                locked={isLocked('category')}
                                style={marginTop}
                            >
                                {findlanguage().state} {isLocked('location') ? <Lock /> : null}
                            </ModalFormLabel>

                            <Select
                                onChange={(values) => changeState(values)}
                                name="colors"
                                isMulti
                                options={states}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={formValue('state')}
                                placeholder={findlanguage().select}
                            />

                            <ModalFormLabel
                                sublabel={true}
                                locked={isLocked('category')}
                                style={marginTop}
                            >
                                {findlanguage().city} {isLocked('location') ? <Lock /> : null}
                            </ModalFormLabel>

                            <Select
                                onChange={(values) => changeCity(values)}
                                name="colors"
                                isMulti
                                options={cities}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={formValue('city')}
                                placeholder={findlanguage().select}
                            />

                            <ModalFormLabel sublabel={true} style={marginTop}>
                                {findlanguage().radious} {isLocked('distance') ? <Lock /> : null}
                            </ModalFormLabel>
                            <SliderContainer>
                                <Slider
                                    min={0}
                                    max={5000}
                                    value={formValue('distance') || 0}
                                    onChange={(e) => changeForm(e.target.value, 'distance')}
                                    disabled={isDisabledNonMember}
                                />
                                <SliderLabel>
                                    {
                                        isDisabledNonMember ?
                                            findlanguage().itemNotAvailable
                                            :
                                            <>
                                                {' '}
                                                {formValue('distance') || `0`} {findlanguage().zeroMilesMeans}
                                            </>
                                    }
                                </SliderLabel>
                            </SliderContainer>

                            <ModalFormLabel
                                sublabel={true}
                                locked={isLocked('category')}
                                style={marginTop}
                            >
                                {findlanguage().mfsPreCheck}{' '}
                                {isLocked('mfs_validation') ? <Lock /> : null}
                            </ModalFormLabel>

                            <Select
                                onChange={(values) => changeForm(values, 'mfs_validation')}
                                isMulti
                                name="colors"
                                options={validations}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={formValue('mfs_validation')}
                                isDisabled={isDisabledNonMember}
                                placeholder={isDisabledNonMember ? findlanguage().itemNotAvailable : findlanguage().select}
                            />

                            <ModalFormLabel
                                locked={isLocked('category')}
                                style={marginTop}
                                sublabel={true}
                            >
                                {findlanguage().certificationApproval}{' '}
                            </ModalFormLabel>

                            <CreatableSelect
                                onChange={(values) => changeForm(values, 'certifications')}
                                isMulti
                                name="colors"
                                options={infos?.certifications}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={formValue('certifications')}
                                placeholder={findlanguage().select}
                            />

                            <ModalFormLabel
                                locked={isLocked('category')}
                                style={marginTop}
                                sublabel={true}
                            >
                                {findlanguage().distribution}{' '}
                                {isLocked('distribution') ? <Lock /> : null}
                            </ModalFormLabel>

                            <Select
                                onChange={(values) => changeForm(values, 'distribution')}
                                isMulti
                                isDisabled={isDisabledNonMember || isDisabledServiceProvider}
                                name="colors"
                                options={distribution}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={formValue('distribution')}
                                placeholder={isDisabledNonMember || isDisabledServiceProvider ? findlanguage().itemNotAvailable : findlanguage().select}
                            />

                            <ModalFormLabel
                                locked={isLocked('category')}
                                style={marginTop}
                                sublabel={true}
                            >
                                {findlanguage().servicesOffered}{' '}
                            </ModalFormLabel>

                            <Select
                                onChange={(values) => changeForm(values, 'servicesOffered')}
                                isMulti
                                name="colors"
                                options={servicesOffered}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={!isTrueServiceProvider ? [] : formValue('servicesOffered')}
                                isDisabled={!isTrueServiceProvider}     
                                placeholder={!isTrueServiceProvider? findlanguage().itemNotAvailable : findlanguage().select}
                            />
                            <ModalFormLabel
                                style={marginTop}
                                sublabel={true}
                            >
                                {findlanguage().servicesProvided}{' '}
                            </ModalFormLabel>

                            <Select
                                onChange={(values) => changeForm(values, 'servicesProvided')}
                                isMulti
                                name="colors"
                                options={servicesProvided}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={!isTrueServiceProvider ? [] : formValue('servicesProvided')}  
                                isDisabled={!isTrueServiceProvider}     
                                placeholder={!isTrueServiceProvider? findlanguage().itemNotAvailable : findlanguage().select}
                            />
                        </>
                    )}
                </ProfileFormContent>
                <PageFormSpace />
                <ProfileFormActions>
                    <Button primary small onClick={apply}>
                        {findlanguage().applyFilters}
                    </Button>
                    <Button outline small primary onClick={clean}>
                        {findlanguage().cleanFilters}
                    </Button>
                </ProfileFormActions>
            </ModalWrapper>
        </>
    )
}
