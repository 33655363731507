import React, { useEffect, useState, useContext } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useHistory } from 'react-router-dom'

import { ContentScroll, ScrollWrapper } from './styled'

import ContainerAuthenticated from 'containers/Authenticated'

import DashboardFilterSearch from 'components/Dashboard/Filter/Search'
import DashboardCardWishlist from 'components/Dashboard/Cards/Wishlist'
import { FetchWishlist } from 'services/wishlist'
import { Load } from 'ui/styled'
import _ from 'lodash'
import { CoreContext } from 'context/CoreContext'
import { useNavigate } from 'react-router-dom';

export default function DashboardConnections() {
    const { premium, profile, findlanguage, permissions } = useContext(CoreContext)

    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const [connectionList, setConnectionList] = useState()
    const [value, setFilterValue] = useState('')

    const listConnections = async () => {
        setConnectionList(null)
        const list = await FetchWishlist()
        setConnectionList(list)
    }

    const showWishlist = () => {
        if (profile === 'supplier' && premium.type === 'free') {
            navigate('dashboard')
        } else if (profile !== 'supplier' && premium.type === 'free') {
            navigate('dashboard')
        }
    }

    useEffect(() => {
        showWishlist()

        listConnections()
    }, [])

    const onChangeList = (value2) => {
        setFilterValue(value2.target.value)
    }

    useEffect(() => {
        if (!permissions?.wishlist?.canView && !permissions?.wishlist?.canEdit) {
            navigate('dashboard')
        }
    }, [permissions, navigate]);

    return (
        <>
            <ContainerAuthenticated>
                <Container fluid={false}>
                    <Row>
                        <Col>
                            <DashboardFilterSearch
                                placeholder={findlanguage().searchWishList}
                                onChangeList={onChangeList}
                            />
                            <ContentScroll>
                                <ScrollWrapper>
                                    {connectionList &&
                                        connectionList
                                            .sort((a, b) =>
                                                ('' + a.user_wish_id.contactName).localeCompare(
                                                    b.user_wish_id.contactName
                                                )
                                            )
                                        ?.filter((x) => {
                                            // Check if value is empty, return true to include all users
                                            if (value === '') {
                                                return true;
                                            }

                                            const lowerCaseValue = value?.toLowerCase();

                                            // Proceed with existing checks, safely handling potentially null values
                                            return (
                                                x?.user_wish_id?.name?.toLowerCase().includes(lowerCaseValue) ||
                                                (x?.user_wish_id?.address?.city?.toLowerCase()?.includes(lowerCaseValue) || false) || // Fallback to false if any part is null
                                                (x?.user_wish_id?.address?.country?.toLowerCase()?.includes(lowerCaseValue) || false) ||
                                                (x?.user_wish_id?.address?.state?.toLowerCase()?.includes(lowerCaseValue) || false)
                                            );
                                        })
                                            .map((x, i) => (
                                                <DashboardCardWishlist
                                                    refresh={listConnections}
                                                    reg={x.user_wish_id}
                                                    deleteId={x.id}
                                                    key={x.user_wish_id.id}
                                                />
                                            ))}
                                    {!connectionList && <Load white={'white'} />}
                                </ScrollWrapper>
                            </ContentScroll>
                        </Col>
                    </Row>
                </Container>
            </ContainerAuthenticated>
        </>
    )
}
