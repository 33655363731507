import styled from 'styled-components'

export const ProfileFormContent = styled.div.attrs({})`
    width: 100%;
    padding: 20px 20px 20px;
    min-height: calc(100vh - 150px);
    max-height: calc(100vh - 150px);
    overflow: auto;
    border-top: 0.5px solid var(--evengrey-color);
`

export const ProfileFormActions = styled.div.attrs({})`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
`
export const PageFormSpace = styled.div.attrs({})`
    margin-right: 12px;
`

export const PageFormWrapper = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
`

export const ModalFormLabel = styled.div.attrs({})`
    margin-bottom: 20px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--primary-color);
    ${(props) =>
        props.spaced
            ? `
            margin-bottom: 15px;
        `
            : ``}

    ${(props) =>
        props.selectlabel
            ? `
            margin-bottom: -24px;
        `
            : ``}
    ${(props) =>
        props.sublabel
            ? `
            margin-bottom: 6px;
        `
            : ``}
    ${(props) =>
        props.locked
            ? `
            color: var(--lightgrey-color);
        `
            : ``}
`

export const CheckContent = styled.div.attrs({})`
    padding-bottom: 30px;
`

export const SliderContainer = styled.div.attrs({})`
    margin-bottom: 12px;
`
export const Slider = styled.input.attrs({
    type: 'range',
})`
    -webkit-appearance: none;
    width: 100%;
    height: 1px;
    border-radius: 5px;
    background-image: linear-gradient(90deg, var(--slidergrey-color), var(--lightgrey-color));
    background-size: 100%;
    background-repeat: no-repeat;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background: var(--primary-color);
        cursor: ew-resize;
        box-shadow: 0 0 2px 0 var(--lightshadow-color);
        transition: background 0.3s ease-in-out;
        ${(props) =>
            props.locked
                ? `
            background: var(--flatlightgrey-color);
        `
                : ``}
    }

    &::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
    }

    &:before {
        content: ' ';
        display: block;
        background: var(--slidergrey-color);
        width: 1px;
        height: 14px;
        margin-top: -6.5px;
    }

    &:after {
        content: ' ';
        display: block;
        background: var(--lightgrey-color);
        width: 1px;
        height: 14px;
        margin-top: -6.5px;
    }
`
export const SliderLabel = styled.div.attrs({})`
    margin-left: 5px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--lightgrey-color);
`

export const Lock = styled.img.attrs({
    src: '/icons/locker.svg',
})`
    margin-top: -3px;
    margin-left: 5px;
`
