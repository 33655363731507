import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'

import {
    FormTitle,
    SelectManyContainer,
    SelectContainer,
    SelectHeader,
    SelectHeaderContent,
    SelectHeaderText,
    SelectHeaderBadge,
    SelectHeaderBadgeText,
    SelectHeaderBadgeIcon,
    SelectHeaderIcon,
    SelectBody,
    SelectManyActionIcon,
    SelectManyText,
    SelectItem,
    SelectFilter,
} from './styled'

import Check from 'components/Form/Check'
import Button from 'components/Form/Button'
import Input from 'components/Form/Input'
import { CoreContext } from 'context/CoreContext'

export default function SelectSupplierProduct({
    datasource,
    onChange,
    value,
    title,
    placeholder,
    action,
}) {
    const [opened, setOpened] = useState(false)
    const [selected, setSelected] = useState(value ? value : [])

    const [options, setOptions] = useState([])
    const [optionsOriginal, setOptionsOriginal] = useState([])

    const [filter, setFilter] = useState('')

    const { setModal, language } = useContext(CoreContext)

    const isAdded = (item) => {
        return selected.map((mit) => mit.id).includes(item.id)
    }

    const doFilter = (data) => {
        setFilter(data)

        if (data !== '') {
            const optionsList = _.cloneDeep(optionsOriginal)
            let newlist = []

            if (language === 'portugues')
                newlist = optionsList
                    .map((item, key) => {
                        item.options = item.options.filter((subitem) =>
                            subitem.name_pt.toLowerCase().includes(data.toLowerCase())
                        )
                        item.opened = true
                        return item
                    })
                    .filter((item) => item.options.length > 0)
            else
                newlist = optionsList
                    .map((item, key) => {
                        item.options = item.options.filter((subitem) =>
                            subitem.name.toLowerCase().includes(data.toLowerCase())
                        )
                        item.opened = true
                        return item
                    })
                    .filter((item) => item.options.length > 0)

            setOptions([...newlist])
        } else {
            const newlist = _.cloneDeep(optionsOriginal)
            setOptions([...newlist])
        }
    }

    const toggleAdd = (category, item) => {
        const new_item = {
            category: category,
            id: item.id,
            name: item.name,
            name_pt: item.name_pt,
        }

        const nxtValue = isAdded(new_item)
            ? [...selected.filter((fit) => fit.id !== new_item.id)]
            : [...selected, new_item]

        setSelected(nxtValue)
        if (onChange && typeof onChange === 'function') {
            onChange(nxtValue)
        }
    }

    const safeOpen = (e) => {
        if (!e.target.closest('.badge')) {
            setOpened(!opened)
        }
    }

    const addMore = () => {
        setModal({
            type: 'add',
            title: action,
            action: (value) =>
                toggleAdd('Custom User Category', {
                    id: new Date().getTime(),
                    name: value,
                }),
        })
    }

    useEffect(() => {
        const handleClick = (e) => {
            if (!e.target.closest('.select-many')) {
                setOpened(false)
                setFilter('')
            }
        }
        window.addEventListener('click', handleClick)
        return () => {
            window.removeEventListener('click', handleClick)
        }
    }, [])

    useEffect(() => {
        setOptions(_.cloneDeep(datasource))
        setOptionsOriginal(_.cloneDeep(datasource))
        return () => {}
    }, [datasource])

    return (
        <>
            {title ? <FormTitle>{title}</FormTitle> : null}
            <SelectManyContainer>
                <SelectContainer className="select-many">
                    <SelectHeader onClick={safeOpen}>
                        <SelectHeaderContent>
                            {!selected.length ? (
                                <SelectHeaderText>{placeholder}</SelectHeaderText>
                            ) : (
                                <>
                                    {selected.map((item, key) => (
                                        <SelectHeaderBadge key={key} className={'badge'}>
                                            <SelectHeaderBadgeText>
                                                {language === 'portugues' ? item.name_pt : null}
                                                {language === 'english' ? item.name : null}
                                            </SelectHeaderBadgeText>
                                            <SelectHeaderBadgeIcon
                                                onClick={() => toggleAdd(item.category, item)}
                                            />
                                        </SelectHeaderBadge>
                                    ))}
                                </>
                            )}
                        </SelectHeaderContent>

                        <SelectHeaderIcon opened={opened} />
                    </SelectHeader>

                    <SelectBody opened={opened}>
                        <SelectFilter>
                            <Input
                                placeholder="Filter"
                                small
                                value={filter}
                                onChange={(e) => doFilter(e.target.value)}
                            />
                        </SelectFilter>

                        {options?.map((item, key) => (
                            <SelectItem key={key}>
                                <p>{item.category}</p>
                                {item.options.map((child, childkey) => {
                                    if (language === 'portugues')
                                        return (
                                            <Check
                                                spaced={true}
                                                key={childkey}
                                                label={child.name_pt}
                                                onChange={() => toggleAdd(item.category, child)}
                                                checked={isAdded(child)}
                                            />
                                        )
                                    else
                                        return (
                                            <Check
                                                spaced={true}
                                                key={childkey}
                                                label={child.name}
                                                onChange={() => toggleAdd(item.category, child)}
                                                checked={isAdded(child)}
                                            />
                                        )
                                })}
                            </SelectItem>
                        ))}
                    </SelectBody>
                </SelectContainer>

                <Button small primary onClick={addMore}>
                    <SelectManyActionIcon />
                    <SelectManyText>{action}</SelectManyText>
                </Button>
            </SelectManyContainer>
        </>
    )
}
