import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { parseStrapiImage } from 'utils'

import {
    SupplierCard,
    SupplierImage,
    SupplierContent,
    SupplierTitle,
    SupplierSubtitle,
    SupplierLabel,
    SupplierLabelText,
    SupplierLabelIcon,
    SupplierDescription,
    SupplierActions,
    CardRemoveAction,
    CardTouch,
} from './styled'

import DashboardButton from 'components/Dashboard/Button'
import { RemoveWishlist } from 'services/wishlist'
import { CoreContext } from 'context/CoreContext'

export default function DashboardCardMyWish({
    wishlist_id,
    id,
    image,
    title,
    subtitle,
    label,
    labelIcon,
    description,
    actions,
    refresh,
}) {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const { setModal, findlanguage } = useContext(CoreContext)

    const doDeleteConnection = async () => {
        setModal({
            type: 'loading',
            text: findlanguage().processing,
        })
        const response = await RemoveWishlist(wishlist_id)
        if (response.statusCode)
            return setModal({
                type: 'alert',
                text: 'Operation failed.',
                warning: true,
            })
        setModal({
            type: 'alert',
            text: findlanguage().wishlistRemoved,
        })

        refresh()
    }

    return (
        <>
            <SupplierCard>
                <CardTouch onClick={() => navigate(`dashboard/user/${id}`)}>
                    <SupplierImage
                        image={image ? parseStrapiImage(image) : `/images/no-image.png`}
                    />
                </CardTouch>
                <SupplierContent>
                    <CardTouch onClick={() => navigate(`dashboard/user/${id}`)}>
                        <SupplierTitle>{title}</SupplierTitle>
                        <SupplierSubtitle>{subtitle}</SupplierSubtitle>
                    </CardTouch>
                    <SupplierDescription>{description}</SupplierDescription>
                    <SupplierActions>
                        {actions?.map((item, key) => (
                            <DashboardButton
                                key={key}
                                outline={item.outline}
                                small={item.small}
                                dark={item.dark}
                                onClick={item.action}
                            >
                                {item.title}
                            </DashboardButton>
                        ))}
                    </SupplierActions>
                </SupplierContent>
                <CardRemoveAction onClick={() => doDeleteConnection()} />
            </SupplierCard>
        </>
    )
}
