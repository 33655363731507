import styled from 'styled-components'

export const Content = styled.div.attrs({})`
    flex: 1;
`

/// accessing childs to remove alert message
export const MapContainer = styled.div.attrs({})`
    height: 370px;
    position: relative;
    flex: 2;

    & > div {
        &:nth-child(1) {
            & > div {
                &:nth-child(2) {
                    display: none;
                }
            }
        }
    }
`

export const MapContent = styled.div.attrs({})`
    height: 370px;
`
export const ChartContent = styled.div.attrs({})`
    flex: 1;
    padding: 55px 60px 0px 20px;
    border-top: 0.5px solid var(--background-color);
`
export const ChartItem = styled.div.attrs({})`
    display: flex;
    align-items: center;
    padding: 7px 0;
`
export const ChartItemBar = styled.div.attrs({})`
    height: 10px;
    border-radius: 15px;
    background: ${(props) => props.color};
    width: ${(props) => props.progress}%;
    margin: 0 8px;
`
export const ChartItemText = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--grey-color);
    ${(props) =>
        props.islabel
            ? `
            color: var(--lightgrey-color);
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            min-width: 130px;
            font-size: 16px;
        `
            : ``}
`

export const MapBlocker = styled.div.attrs({})`
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`
