import styled from 'styled-components'

export const ProfileFormContent = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: space-evenly;
    padding: 40px 0 20px;
    width: 100%;
`

export const ProfileFormActions = styled.div.attrs({})`
    display: flex;
    justify-content: center;
`
export const PageFormSpace = styled.div.attrs({})`
    margin-right: 12px;
`

export const CardAddableUser = styled.div.attrs({})`
    padding: 16px 10px 16px 24px;
    background: var(--backgroundgrey-color);
    width: 100%;
    max-width: 330px;
    border-radius: 10px;
    display: flex;
    align-items: center;
`
export const CardImage = styled.div.attrs({})`
    width: 56px;
    height: 56px;
    border-radius: 28px;

    background: url(/images/carrefour.png) no-repeat center center / cover;
    background-size: 56px;
`
export const CardContent = styled.div.attrs({})`
    padding-left: 17px;
    flex: 1;
`
export const CardTitle = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: var(--grey-color);
`
export const CardText = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 13px;
    color: var(--lightgrey-color);
`
export const CardActions = styled.div.attrs({})`
    display: flex;
`
export const CardAction = styled.div.attrs({})`
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: var(--grey-color);
    margin-right: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    &:hover {
        box-shadow: 0px 3px 6px var(--lightshadow-color);
    }
`
export const CardActionIcon = styled.img.attrs((props) => ({
    src: `/icons/${props.icon}.svg`,
    width: 15,
}))``
