import styled from 'styled-components'
import ReactLoading from 'react-loading'
import Lottie from 'react-lottie'

export const hexToRgb = (hex) => {
    var c
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('')
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = '0x' + c.join('')
        return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')
    }
    return `255,255,255`
}

export const PrimaryColor = hexToRgb('#2e7d32')

export const mobileSize = 1250

export const Touch = styled.div.attrs({})`
    cursor: pointer;
    &:hover {
        box-shadow: 0px 1px 3px var(--shadow-color);
    }
`

export const Load = styled(ReactLoading).attrs((props) => ({
    type: 'spin',
    color: props.color ? props.color : '#fff',
    height: props.size ? props.size : 20,
    width: props.size ? props.size : 20,
}))`
    cursor: pointer;
    &:hover {
        box-shadow: 0px 1px 3px var(--shadow-color);
    }
    margin: 0 auto;
`

export const LoadBlack = styled(ReactLoading).attrs((props) => ({
    type: 'spin',
    color: '#000',
    height: props.size ? props.size : 20,
    width: props.size ? props.size : 20,
}))`
    cursor: pointer;
    &:hover {
        box-shadow: 0px 1px 3px var(--shadow-color);
    }
    margin: 0 auto;
`

export const ButtonWhite = styled.div.attrs({})`
    min-width: 176px;
    height: 41px;
    padding: 0 12px;
    border-radius: 2px;
    margin-left: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    ${(props) =>
        props.outline
            ? `
            border: 1px solid var(--white-color);
            color: var(--white-color);
        `
            : `
            background-color: var(--white-color);
            color: var(--primary-color);
        `}
    ${(props) =>
        props.nomargin
            ? `
            margin: 0 !important;
        `
            : ``}
    ${(props) =>
        props.centred
            ? `
            margin: 12px auto;
        `
            : ` 
        `}
 
    font-size: 15px;
    font-weight: 600;
    text-align: center;

    @media (max-width: 869px) {
        margin-left: 12px;
    }

    @media (max-width: 809px) {
        ${(props) =>
            props.outline
                ? `
                min-width: 120px;
            `
                : `
                margin-left: 0;
            `}
    }

    @media (max-width: 767px) {
        margin-left: 30px;
    }
`

export const Animation = styled(Lottie).attrs((props) => ({
    options: {
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
        animationData: props.animationData,
    },
    width: props.width ? props.width : 320,
}))`
    max-width: 100%;
`
