import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { parseStrapiImage } from 'utils';

export default function SectionsToUploadTheUser({ sections, currentStep, handleEdit, form }) {

    const getStatusBadgeClass = (status) => {
        switch (status) {
            case 'Waiting':
                return 'badge bg-warning text-dark';
            case 'Rejected':
                return 'badge bg-danger';
            case 'Pending Approval':
                return 'badge bg-danger';
            case 'Complete':
                return 'badge bg-success';
            default:
                return 'badge bg-secondary';
        }
    };

    const renderQuizTotal = (score, totalToPass) => {
        const percentage = score || 0;
        let circleColor = 'red';

        if (percentage > totalToPass) {
            circleColor = 'green';
        } else if (percentage === totalToPass) {
            circleColor = 'yellow';
        }

        return (
            <div className="progress-circle">
                <svg viewBox="0 0 36 36" className={`circular-chart ${circleColor}`}>
                    <path
                        className="circle-bg"
                        d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                        className="circle"
                        strokeDasharray={`${percentage}, 100`}
                        d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <text x="18" y="20.35" className="percentage">{`${percentage}%`}</text>
                </svg>
            </div>
        );
    };


    const groupSectionsByStep = (sections) => {
        return sections.reduce((groups, section) => {
            const stepId = section.step_section_id.id;
            if (!groups[stepId]) {
                groups[stepId] = [];
            }
            groups[stepId].push(section);
            return groups;
        }, {});
    };

    const groupedSections = groupSectionsByStep(sections);

    const overalStatus = (groupedSection) => {
        if (groupedSection.find(x => x.status === 'waiting') === undefined && groupedSection.find(x => x.step_section_id.id === currentStep)) {
            if (form.status === 'pending_approval') {
                return "Pending Approval"
            }
            if (form.status === 'rejected') {
                return "Rejected"
            }
            else {
                return "Complete"
            }
        }
        else if (groupedSection.filter(x => x.status === 'waiting').length !== 0) {
            if (form.status === 'pending_approval' || form.status === 'open') {
                return "Waiting"
            }
            else {
                return "Complete"
            }
        }
        else {
            return "Complete"
        }
    }

    return (
        <div className="steps-scroll-container-no-overflow step-owner">
            <div className="steps-container">
                {Object.keys(groupedSections).map((stepId, index) => (
                    <React.Fragment key={stepId}>
                        <div className={`step`}>
                            <div className="step-header">
                                <div className="step-icon">
                                    <i className={`bi bi-${groupedSections[stepId][0].step_section_id.icon}`}></i>
                                </div>
                                <div className="step-title">
                                    {groupedSections[stepId][0].step_section_id.name}
                                    {groupedSections[stepId][0].status.length > 0 && (
                                        <span
                                            className={getStatusBadgeClass(overalStatus(groupedSections[stepId]))}
                                            style={{ marginLeft: '10px' }}
                                        >
                                            {overalStatus(groupedSections[stepId])}
                                        </span>
                                    )}
                                    {parseInt(stepId) === currentStep && (
                                        <span className="badge bg-primary" style={{ marginLeft: '10px' }}>
                                            Current Section
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="user-list-container">
                                <ul className="user-list">
                                    {groupedSections[stepId].map((step, index) => (
                                        <li key={index} className="user-list-item">
                                            <div className="user-info">
                                                <div>
                                                    <img
                                                        width="52"
                                                        className="rounded-circle user-avatar"
                                                        src={step.user.url ? parseStrapiImage(step.user.url) : '/images/no-image.png'}
                                                        alt={step.user.name}
                                                    />
                                                </div>
                                                <div className="user-details">
                                                    <div className="user-name">{step.user.name}</div>
                                                    <span
                                                        className={getStatusBadgeClass(step?.status?.charAt(0)?.toUpperCase() + step?.status?.slice(1))}
                                                        style={{ marginLeft: '10px' }}
                                                    >
                                                        {step?.status?.charAt(0)?.toUpperCase() + step?.status?.slice(1)}
                                                    </span>
                                                    {groupedSections[stepId][0].step_section_id.type === 'quiz' && renderQuizTotal(step.score, step.step_section_id.questions_to_pass)}
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            {['waiting', 'complete', 'rejected'].includes(groupedSections[stepId][0].status) && (
                                <button
                                    className="btn colorTealDark"
                                    style={{ width: '100%', marginTop: '10px' }}
                                    onClick={() => handleEdit(form, groupedSections[stepId][0].step_section_id.id)}
                                >
                                    View Section
                                </button>
                            )}
                        </div>
                        {index < Object.keys(groupedSections).length - 1 && <div className="step-line"></div>}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}
