import React from 'react'

import { PageCard } from './styled'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Title from './Title/index'
import Subtitle from './Subtitle/index'
import Description from './Description/index'
import InputText from './InputText/index'
import List from './List/index'
import Date from './Date/index'
import Calendar from './Calendar/index'
import Checkbox from './Checkbox/index'
import Attachment from './Attachment/index'
import Video from './Video/index'
import Signature from './Signature/index'
import EmptyBox from './EmptyBox/index'

export default function DraggableElement({ sections, setSections, currentStep, is_draft = true }) {
    const handleLabelTextChange = (elementId, e) => {
        const newSteps = sections.map((step, stepIndex) => {
            if (stepIndex === currentStep) {
                return {
                    ...step,
                    elements: step.elements.map(el => {
                        if (el.id === elementId) {
                            return { ...el, label: e.target.value };
                        }
                        return el;
                    }),
                };
            }
            return step;
        });
        setSections(newSteps);
    };

    const removeOption = (elementId, optionId) => {
        setSections(sections => sections.map((step, stepIndex) => {
            if (stepIndex === currentStep) {
                return {
                    ...step,
                    elements: step.elements.map(element => {
                        if (element.id === elementId) {
                            return { ...element, options: element.options.filter(opt => opt.id !== optionId) };
                        }
                        return element;
                    }),
                };
            }
            return step;
        }));
    };

    const handleResize = (elementId, newColSize) => {
        setSections((prevSteps) => prevSteps.map((step, stepIndex) => {
            if (stepIndex === currentStep) {
                return {
                    ...step,
                    elements: step.elements.map((el) => {
                        if (el.id === elementId) {
                            return { ...el, col_size: newColSize };
                        }
                        return el;
                    }),
                };
            }
            return step;
        }));
    };

    const removeElement = (elementId) => {
        setSections(sections => sections.map((step, stepIndex) => {
            if (stepIndex === currentStep) {
                return {
                    ...step,
                    elements: step.elements.filter(element => element.id !== elementId),
                };
            }
            return step;
        }));
    };

    const toggleRequired = (elementId, is_required) => {
        const newSteps = sections.map((step) => ({
            ...step,
            elements: step.elements.map(el => {
                if (el.id === elementId) {
                    return { ...el, is_required: is_required };
                }
                return el;
            }),
        }));
        setSections(newSteps);
    };

    const toggleCorrectOption = (elementId, optionId) => {
        setSections(sections => sections.map(step => ({
            ...step,
            elements: step.elements.map(el => {
                if (el.id === elementId) {
                    const updatedOptions = el.options.map(opt => ({
                        ...opt,
                        is_correct: opt.id === optionId ? !opt.is_correct : opt.is_correct,
                    }));
                    const correctOption = updatedOptions.find(opt => opt.is_correct)?.id || null;
                    return { ...el, options: updatedOptions, correctOption };
                }
                return el;
            }),
        })));
    };

    const renderFormElement = (element) => {
        switch (element.type) {
            case 'title':
                return <Title
                    element={element}
                    handleLabelTextChange={handleLabelTextChange}
                    handleResize={handleResize}
                    removeElement={removeElement}
                    is_draft={is_draft}
                />
            case 'subtitle':
                return <Subtitle
                    element={element}
                    handleLabelTextChange={handleLabelTextChange}
                    handleResize={handleResize}
                    removeElement={removeElement}
                    is_draft={is_draft}
                />
            case 'description':
                return <Description
                    element={element}
                    handleLabelTextChange={handleLabelTextChange}
                    handleResize={handleResize}
                    removeElement={removeElement}
                    is_draft={is_draft}
                />
            case 'text':
                return <InputText
                    element={element}
                    handleLabelTextChange={handleLabelTextChange}
                    toggleRequired={toggleRequired}
                    handleResize={handleResize}
                    removeElement={removeElement}
                    is_draft={is_draft}
                />
            case 'list':
                return <List
                    element={element}
                    sections={sections}
                    setSections={setSections}
                    currentStep={currentStep}
                    handleLabelTextChange={handleLabelTextChange}
                    toggleRequired={toggleRequired}
                    toggleCorrectOption={toggleCorrectOption}
                    removeOption={removeOption}
                    handleResize={handleResize}
                    removeElement={removeElement}
                    is_draft={is_draft}
                />
            case 'date':
                return <Date
                    element={element}
                    handleLabelTextChange={handleLabelTextChange}
                    toggleRequired={toggleRequired}
                    handleResize={handleResize}
                    removeElement={removeElement}
                    is_draft={is_draft}
                />
            case 'calendar':
                return <Calendar
                    element={element}
                    handleLabelTextChange={handleLabelTextChange}
                    toggleRequired={toggleRequired}
                    handleResize={handleResize}
                    removeElement={removeElement}
                    is_draft={is_draft}
                />
            case 'checkbox':
                return <Checkbox
                    element={element}
                    sections={sections}
                    currentStep={currentStep}
                    setSections={setSections}
                    handleLabelTextChange={handleLabelTextChange}
                    toggleCorrectOption={toggleCorrectOption}
                    removeOption={removeOption}
                    handleResize={handleResize}
                    removeElement={removeElement}
                    is_draft={is_draft}
                />
            case 'attach':
                return <Attachment
                    element={element}
                    handleLabelTextChange={handleLabelTextChange}
                    setSections={setSections}
                    handleResize={handleResize}
                    removeElement={removeElement}
                    is_draft={is_draft}
                />
            case 'video':
                return <Video
                    element={element}
                    handleLabelTextChange={handleLabelTextChange}
                    toggleRequired={toggleRequired}
                    handleResize={handleResize}
                    removeElement={removeElement}
                    setSections={setSections}
                    is_draft={is_draft}
                />
            case 'signature':
                return (
                    <Signature
                        element={element}
                        handleLabelTextChange={handleLabelTextChange}
                        toggleRequired={toggleRequired}
                        handleResize={handleResize}
                        removeElement={removeElement}
                        is_draft={is_draft}
                    />
                );
            case 'empty':
                return (
                    <EmptyBox
                        element={element}
                        handleResize={handleResize}
                        removeElement={removeElement}
                        is_draft={is_draft}
                    />
                );
            default:
                return null;
        }
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        if (sourceIndex !== destinationIndex) {
            const newElements = Array.from(sections[currentStep].elements);
            const [removedElement] = newElements.splice(sourceIndex, 1);
            newElements.splice(destinationIndex, 0, removedElement);

            const newSteps = sections.map((step, index) => {
                if (index === currentStep) {
                    return { ...step, elements: newElements };
                }
                return step;
            });

            setSections(newSteps);
        }
    };

    const renderElementsWithRowLogic = () => {
        let rows = [];
        let currentRow = [];
        let currentRowTotalColSize = 0;
        const currentElements = sections[currentStep].elements;

        currentElements.forEach((element, index) => {
            const elementColSize = typeof element.col_size === 'number' ? element.col_size : parseInt(element.col_size?.replace('col-', '')) || 12;
            currentRowTotalColSize += elementColSize;
            currentRow.push(element);
            if (currentRowTotalColSize >= 12 || index === currentElements.length - 1) {
                rows.push([...currentRow]);
                currentRow = [];
                currentRowTotalColSize = 0;
            }
        });

        return (
            <Droppable droppableId="elements">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={`droppable-row ${snapshot.isDraggingOver ? "is-dragging-over" : ""}`}
                    >
                        <div className="row test">
                            {sections[currentStep].elements.map((element, index) => (
                                <Draggable key={element.id} draggableId={String(element.id)} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={`${typeof element.col_size !== 'number' ? element.col_size : 'col-' + element.col_size || 'col-12'} draggable-item ${snapshot.isDragging ? "is-dragging" : ""}`}
                                            style={{ ...provided.draggableProps.style }}
                                        >
                                            {renderFormElement(element)} 
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                        </div>
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        );
    };

    return (
        <PageCard style={{ marginTop: '25px' }} className="content-container">
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="elements">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {renderElementsWithRowLogic()}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </PageCard>
    );
}