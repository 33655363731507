import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import Card from '../Card/index.js'

import { PageCard, CardHeader, CardHeaderTitleContainer } from './styled'
import _ from 'lodash'

export default function SelfAssessmentChecklist({
    certifications,
    onChangeCertifications,
    saveCertifications,
    saveDocument,
    form,
    returnHeaders,
    mfsQuestionarie
}) {
    const { findlanguageEsg } = useContext(CoreContext) 

    //#region I.4.1
    const i41 = {
        ref: 'I.4.1',
        requirementTitle: findlanguageEsg().i41RequirementTitle,
        inputForYesAnswer: true,
        placeHolderTextYes: findlanguageEsg().i41PlaceHolderTextYes,
        inputForNoAnswer: false,
        placeHolderTextNo: findlanguageEsg().i41PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    //#region I.4.1 Yes

    //#region I.4.1.Y.A

    const i41ya = {
        ref: 'I.4.1.Y.A',
        requirementTitle: findlanguageEsg().i41yaRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i41yaPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i41yay = {
        ref: 'I.4.1.Y.A.Y',
        requirementTitle: findlanguageEsg().i41yayRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i41yayPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const i41yayy = {
        ref: 'I.4.1.Y.A.Y.Y',
        requirementTitle: findlanguageEsg().i41yayyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i41yayyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
    }

    const i41yayyql1 = {
        ref: 'I.4.1.Y.A.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().i41yayyqlRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i41yayyqlPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        fileForYesAnswerAndNoInput: true,
        color: '#2f8c5d',
        question: true,
    }

    //#endregion

    //#region I.4.1.Y.B

    const i41yb = {
        ref: 'I.4.1.Y.B',
        requirementTitle: findlanguageEsg().i41ybRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i41ybPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i41yby = {
        ref: 'I.4.1.Y.B.Y',
        requirementTitle: findlanguageEsg().i41ybyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: findlanguageEsg().i41ybyPlaceHolderTextYes,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i41ybyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const i41ybyyqt1 = {
        ref: 'I.4.1.Y.B.Y.Y.QT.1',
        requirementTitle: findlanguageEsg().i41ybyyqt1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const i41ybyyqtn = {
        ref: 'I.4.1.Y.B.Y.Y.QT.N',
        requirementTitle: findlanguageEsg().i41ybyyqtnRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const i41ybyyql1 = {
        ref: 'I.4.1.Y.B.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().i41ybyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i41ybyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.C

    const i41yc = {
        ref: 'I.4.1.Y.C',
        requirementTitle: findlanguageEsg().i41ycRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i41ycPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i41ycy = {
        ref: 'I.4.1.Y.C.Y',
        requirementTitle: findlanguageEsg().i41ycyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i41ycyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i41ycyy = {
        ref: 'I.4.1.Y.C.Y.Y',
        requirementTitle: findlanguageEsg().i41ycyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i41ycyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i41ycyyql1 = {
        ref: 'I.4.1.Y.C.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().i41ycyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i41ycyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.D

    const i41yd = {
        ref: 'I.4.1.Y.D',
        requirementTitle: findlanguageEsg().i41ydRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i41ydPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i41ydy = {
        ref: 'I.4.1.Y.D.Y',
        requirementTitle: findlanguageEsg().i41ydyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i41ydyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i41ydyy = {
        ref: 'I.4.1.Y.D.Y.Y',
        requirementTitle: findlanguageEsg().i41ydyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i41ydyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i41ydyyql1 = {
        ref: 'I.4.1.Y.D.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().i41ydyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i41ydyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#endregion

    //#region A.1.1 NO
    const i41na = {
        ref: 'I.4.1.N.A',
        requirementTitle: findlanguageEsg().i41naRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i41naPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const i41nb = {
        ref: 'I.4.1.N.B',
        requirementTitle: findlanguageEsg().i41nbRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i41nbPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const i41nc = {
        ref: 'I.4.1.N.C',
        requirementTitle: findlanguageEsg().i41ncRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i41ncPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    const returnCondition = (item, layers, category1, category2, category3, category4) => {
        switch (layers) {
            case 0:
                return (
                    <Card
                        item={item}
                        onChangeCertifications={onChangeCertifications}
                        saveCertifications={saveCertifications}
                        saveDocument={saveDocument}
                        certifications={certifications}
                        form={form}
                        mfsQuestionarie={mfsQuestionarie}
                    />
                )
                break
            case 1:
                if (certifications?.find((x) => x.category === category1)?.next_level === true)
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 2:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 3:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 4:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true &&
                    certifications?.find((x) => x.category === category4)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            default:
                break
        }
    }

    return (
        <>
            <PageCard>
                <CardHeader>
                    <CardHeaderTitleContainer>
                        {findlanguageEsg().i41CardHeaderTitleContainer}
                    </CardHeaderTitleContainer>
                </CardHeader>
                {returnHeaders()}
                {returnCondition(i41, 0)}
            </PageCard>

            {
                mfsQuestionarie !== '1' ?
                    <>

                        {certifications?.find((x) => x.category === 'I.4.1')?.next_level === true ? (
                            <>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().i41aCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(i41ya, 1, 'I.4.1')}
                                    {returnCondition(i41yay, 2, 'I.4.1', 'I.4.1.Y.A')}
                                    {returnCondition(i41yayy, 3, 'I.4.1', 'I.4.1.Y.A', 'I.4.1.Y.A.Y')}
                                    {returnCondition(
                                        i41yayyql1,
                                        4,
                                        'I.4.1',
                                        'I.4.1.Y.A',
                                        'I.4.1.Y.A.Y',
                                        'I.4.1.Y.A.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().i41bCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(i41yb, 1, 'I.4.1')}
                                    {returnCondition(i41yby, 2, 'I.4.1', 'I.4.1.Y.B')}
                                    {returnCondition(i41ybyyqt1, 3, 'I.4.1', 'I.4.1.Y.B', 'I.4.1.Y.B.Y')}
                                    {returnCondition(i41ybyyqtn, 3, 'I.4.1', 'I.4.1.Y.B', 'I.4.1.Y.B.Y')}
                                    {returnCondition(i41ybyyql1, 3, 'I.4.1', 'I.4.1.Y.B', 'I.4.1.Y.B.Y')}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().i41cCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(i41yc, 1, 'I.4.1')}
                                    {returnCondition(i41ycy, 2, 'I.4.1', 'I.4.1.Y.C')}
                                    {returnCondition(i41ycyy, 3, 'I.4.1', 'I.4.1.Y.C', 'I.4.1.Y.C.Y')}
                                    {returnCondition(
                                        i41ycyyql1,
                                        4,
                                        'I.4.1',
                                        'I.4.1.Y.C',
                                        'I.4.1.Y.C.Y',
                                        'I.4.1.Y.C.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().i41dCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(i41yd, 1, 'I.4.1')}
                                    {returnCondition(i41ydy, 2, 'I.4.1', 'I.4.1.Y.D')}
                                    {returnCondition(i41ydyy, 3, 'I.4.1', 'I.4.1.Y.D', 'I.4.1.Y.D.Y')}
                                    {returnCondition(
                                        i41ydyyql1,
                                        4,
                                        'I.4.1',
                                        'I.4.1.Y.D',
                                        'I.4.1.Y.D.Y',
                                        'I.4.1.Y.D.Y.Y'
                                    )}
                                </PageCard>
                            </>
                        ) : null}
                        {certifications?.find((x) => x.category === 'I.4.1')?.next_level === false ? (
                            <PageCard>
                                <CardHeader>
                                    <CardHeaderTitleContainer>
                                        {findlanguageEsg().i41noCardHeaderTitleContainer}
                                    </CardHeaderTitleContainer>
                                </CardHeader>
                                {returnHeaders()}
                                {returnCondition(i41na, 0)}
                                {returnCondition(i41nb, 0)}
                                {returnCondition(i41nc, 0)}
                            </PageCard>
                        ) : null}
                    </>
                    :
                    null
            }
        </>
    )
}
