import styled from 'styled-components'

export const ProfileFormContent = styled.div.attrs({})`
    width: 100%;
    padding: 40px 0 20px;
`

export const ProfileFormActions = styled.div.attrs({})`
    display: flex;
    justify-content: center;
`
export const PageFormSpace = styled.div.attrs({})`
    margin-right: 12px;
`

export const RowForm = styled.div.attrs({})`
    display: flex;
    gap: 0 40px;
`
export const ModalFormContent = styled.div.attrs({})``
export const ModalFormLabel = styled.div.attrs({})`
    margin-bottom: 7px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--primary-color);
    ${(props) =>
        props.spaced
            ? `
            margin-bottom: 15px;
        `
            : ``}
`
export const ModalFormStatus = styled.div.attrs({})`
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background: var(--lightgrey-color);
`
export const ModalFormText = styled.div.attrs({})`
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--lightgrey-color);
`

export const RemoveableAttach = styled.div.attrs({})`
    padding-top: 12px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--lightgrey-color);
    text-decoration: underline;
`
export const RemoveableAttachIcon = styled.img.attrs({
    src: '/icons/close.svg',
})`
    margin-left: 10px;
    cursor: pointer;
`
