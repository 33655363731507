import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import Card from '../Card/index.js'

import { PageCard, CardHeader, CardHeaderTitleContainer } from './styled'
import _ from 'lodash'

export default function SelfAssessmentChecklist({
    certifications,
    onChangeCertifications,
    saveCertifications,
    saveDocument,
    form,
    returnHeaders,
    mfsQuestionarie
}) {
    const { findlanguageEsg } = useContext(CoreContext) 

    //#region I.6.1
    const i61 = {
        ref: 'I.6.1',
        requirementTitle: findlanguageEsg().i61RequirementTitle,
        inputForYesAnswer: true,
        placeHolderTextYes: findlanguageEsg().i61PlaceHolderTextYes,
        inputForNoAnswer: false,
        placeHolderTextNo: findlanguageEsg().i61PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    //#region I.6.1 Yes

    //#region I.6.1.Y.A

    const i61ya = {
        ref: 'I.6.1.Y.A',
        requirementTitle: findlanguageEsg().i61yaRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i61yaPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i61yay = {
        ref: 'I.6.1.Y.A.Y',
        requirementTitle: findlanguageEsg().i61yayRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i61yayPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const i61yayy = {
        ref: 'I.6.1.Y.A.Y.Y',
        requirementTitle: findlanguageEsg().i61yayyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i61yayyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
    }

    const i61yayyql1 = {
        ref: 'I.6.1.Y.A.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().i61yayyqlRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i61yayyqlPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        fileForYesAnswerAndNoInput: true,
        color: '#2f8c5d',
        question: true,
    }

    //#endregion

    //#region I.6.1.Y.B

    const i61yb = {
        ref: 'I.6.1.Y.B',
        requirementTitle: findlanguageEsg().i61ybRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i61ybPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i61yby = {
        ref: 'I.6.1.Y.B.Y',
        requirementTitle: findlanguageEsg().i61ybyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: findlanguageEsg().i61ybyPlaceHolderTextYes,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i61ybyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const i61ybyyqt1 = {
        ref: 'I.6.1.Y.B.Y.Y.QT.1',
        requirementTitle: findlanguageEsg().i61ybyyqt1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const i61ybyyqt2 = {
        ref: 'I.6.1.Y.B.Y.Y.QT.2',
        requirementTitle: findlanguageEsg().i61ybyyqt2RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }


    const i61ybyyqt3 = {
        ref: 'I.6.1.Y.B.Y.Y.QT.3',
        requirementTitle: findlanguageEsg().i61ybyyqt3RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const i61ybyyqtn = {
        ref: 'I.6.1.Y.B.Y.Y.QT.N',
        requirementTitle: findlanguageEsg().i61ybyyqtnRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const i61ybyyql1 = {
        ref: 'I.6.1.Y.B.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().i61ybyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i61ybyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.C

    const i61yc = {
        ref: 'I.6.1.Y.C',
        requirementTitle: findlanguageEsg().i61ycRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i61ycPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i61ycy = {
        ref: 'I.6.1.Y.C.Y',
        requirementTitle: findlanguageEsg().i61ycyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i61ycyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i61ycyy = {
        ref: 'I.6.1.Y.C.Y.Y',
        requirementTitle: findlanguageEsg().i61ycyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i61ycyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i61ycyyql1 = {
        ref: 'I.6.1.Y.C.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().i61ycyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i61ycyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.D

    const i61yd = {
        ref: 'I.6.1.Y.D',
        requirementTitle: findlanguageEsg().i61ydRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i61ydPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i61ydy = {
        ref: 'I.6.1.Y.D.Y',
        requirementTitle: findlanguageEsg().i61ydyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i61ydyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i61ydyy = {
        ref: 'I.6.1.Y.D.Y.Y',
        requirementTitle: findlanguageEsg().i61ydyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i61ydyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const i61ydyyql1 = {
        ref: 'I.6.1.Y.D.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().i61ydyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i61ydyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#endregion

    //#region A.1.1 NO
    const i61na = {
        ref: 'I.6.1.N.A',
        requirementTitle: findlanguageEsg().i61naRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i61naPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const i61nb = {
        ref: 'I.6.1.N.B',
        requirementTitle: findlanguageEsg().i61nbRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i61nbPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const i61nc = {
        ref: 'I.6.1.N.C',
        requirementTitle: findlanguageEsg().i61ncRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().i61ncPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    const returnCondition = (item, layers, category1, category2, category3, category4) => {
        switch (layers) {
            case 0:
                return (
                    <Card
                        item={item}
                        onChangeCertifications={onChangeCertifications}
                        saveCertifications={saveCertifications}
                        saveDocument={saveDocument}
                        certifications={certifications}
                        form={form}
                        mfsQuestionarie={mfsQuestionarie}
                    />
                )
                break
            case 1:
                if (certifications?.find((x) => x.category === category1)?.next_level === true)
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 2:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 3:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 4:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true &&
                    certifications?.find((x) => x.category === category4)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            default:
                break
        }
    }

    return (
        <>
            <PageCard>
                <CardHeader>
                    <CardHeaderTitleContainer>
                        {findlanguageEsg().i61CardHeaderTitleContainer}
                    </CardHeaderTitleContainer>
                </CardHeader>
                {returnHeaders()}
                {returnCondition(i61, 0)}
            </PageCard>
            {
                mfsQuestionarie !== '1' ?
                    <>

                        {certifications?.find((x) => x.category === 'I.6.1')?.next_level === true ? (
                            <>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().i61aCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(i61ya, 1, 'I.6.1')}
                                    {returnCondition(i61yay, 2, 'I.6.1', 'I.6.1.Y.A')}
                                    {returnCondition(i61yayy, 3, 'I.6.1', 'I.6.1.Y.A', 'I.6.1.Y.A.Y')}
                                    {returnCondition(
                                        i61yayyql1,
                                        4,
                                        'I.6.1',
                                        'I.6.1.Y.A',
                                        'I.6.1.Y.A.Y',
                                        'I.6.1.Y.A.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().i61bCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(i61yb, 1, 'I.6.1')}
                                    {returnCondition(i61yby, 2, 'I.6.1', 'I.6.1.Y.B')}
                                    {returnCondition(i61ybyyqt1, 3, 'I.6.1', 'I.6.1.Y.B', 'I.6.1.Y.B.Y')}
                                    {returnCondition(i61ybyyqt2, 3, 'I.6.1', 'I.6.1.Y.B', 'I.6.1.Y.B.Y')}
                                    {returnCondition(i61ybyyqt3, 3, 'I.6.1', 'I.6.1.Y.B', 'I.6.1.Y.B.Y')}
                                    {returnCondition(i61ybyyqtn, 3, 'I.6.1', 'I.6.1.Y.B', 'I.6.1.Y.B.Y')}
                                    {returnCondition(i61ybyyql1, 3, 'I.6.1', 'I.6.1.Y.B', 'I.6.1.Y.B.Y')}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().i61cCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(i61yc, 1, 'I.6.1')}
                                    {returnCondition(i61ycy, 2, 'I.6.1', 'I.6.1.Y.C')}
                                    {returnCondition(i61ycyy, 3, 'I.6.1', 'I.6.1.Y.C', 'I.6.1.Y.C.Y')}
                                    {returnCondition(
                                        i61ycyyql1,
                                        4,
                                        'I.6.1',
                                        'I.6.1.Y.C',
                                        'I.6.1.Y.C.Y',
                                        'I.6.1.Y.C.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().i61dCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(i61yd, 1, 'I.6.1')}
                                    {returnCondition(i61ydy, 2, 'I.6.1', 'I.6.1.Y.D')}
                                    {returnCondition(i61ydyy, 3, 'I.6.1', 'I.6.1.Y.D', 'I.6.1.Y.D.Y')}
                                    {returnCondition(
                                        i61ydyyql1,
                                        4,
                                        'I.6.1',
                                        'I.6.1.Y.D',
                                        'I.6.1.Y.D.Y',
                                        'I.6.1.Y.D.Y.Y'
                                    )}
                                </PageCard>
                            </>
                        ) : null}
                        {certifications?.find((x) => x.category === 'I.6.1')?.next_level === false ? (
                            <PageCard>
                                <CardHeader>
                                    <CardHeaderTitleContainer>
                                        {findlanguageEsg().i61noCardHeaderTitleContainer}
                                    </CardHeaderTitleContainer>
                                </CardHeader>
                                {returnHeaders()}
                                {returnCondition(i61na, 0)}
                                {returnCondition(i61nb, 0)}
                                {returnCondition(i61nc, 0)}
                            </PageCard>
                        ) : null}
                    </>
                    :
                    null
            }
        </>
    )
}
