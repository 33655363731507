import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { parseStrapiImage } from 'utils'
import { CardSearched, CardImage, CardContent, CardTitle, CardSubtitle, CardTouch } from './styled'
import DashboardMore from 'components/Dashboard/More'
import { CoreContext } from 'context/CoreContext'
import { NewConnection, FetchConnections } from 'services/connections'
import { RemoveWishlist } from 'services/wishlist'
import { FetchWishList } from 'services/dashboard'

export default function DashboardCardConnection({ reg, refresh, deleteId }) {
    const connect = reg
    const address = reg?.address

    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)
    const back = () => history.goBack()

    const { user, setModal, premium, setNewChat, findlanguage, permissions } = useContext(CoreContext)

    const moreOptions = (id) => {
        let exists = null
        if (connectionList) {
            exists = connectionList.find((el) => el.id === id)
        }

        //console.log(connectionList, exists, id)

        let options = []
        if (exists) {
            options.push({ title: findlanguage().delete, action: () => doDeleteConnection() })
        } else {
            options.push({ title: findlanguage().addConnection, action: () => addConnection() })
        }

        return options
    }

    const addConnection = async () => {
        setModal({
            type: 'loading',
            text: findlanguage().processing,
        })

        const response = await NewConnection(reg.id)

        if (response.statusCode === 400)
            return setModal({
                type: 'alert',
                text: findlanguage().thieConnedctionExistsAlready,
                warning: true,
            })

        if (response.statusCode)
            return setModal({
                type: 'alert',
                text: 'Failed to create connection.',
                warning: true,
            })

        setModal({
            type: 'alert',
            text: findlanguage().newConnectionCreated,
        })

        refresh()
    }

    const doDeleteConnection = async () => {
        setModal({
            type: 'loading',
            text: findlanguage().processing,
        })
        const response = await RemoveWishlist(deleteId)
        if (response.statusCode)
            return setModal({
                type: 'alert',
                text: 'Operation failed.',
                warning: true,
            })
        setModal({
            type: 'alert',
            text: findlanguage().wishlistRemoved,
        })

        refresh()
    }

    const [connectionList, setConnectionList] = useState()

    const listConnections = async () => {
        const list = await FetchWishList()
        //console.log(list)
        setConnectionList(list)
    }

    useEffect(() => {
        listConnections()
    }, [])

    return (
        <>
            <CardSearched>
                <CardTouch onClick={() => navigate(`dashboard/user/${connect.id}`)}>
                    <CardImage
                        url={
                            connect?.avatarUrl
                                ? parseStrapiImage(connect?.avatarUrl)
                                : `/images/no-image.png`
                        }
                    />
                </CardTouch>
                <CardContent>
                    <CardTouch onClick={() => navigate(`dashboard/user/${connect.id}`)}>
                        <CardTitle>
                            {connect?.contactName}
                            {
                                connect.status === 'approved' ?
                                    <img src='/icons/checked.svg' alt='Approved' style={{ marginLeft: '10px', height: '25px' }} />
                                    :
                                    null
                            }
                        </CardTitle>
                        <CardSubtitle>
                            {address?.state} - {address?.country}
                        </CardSubtitle>
                        <CardSubtitle style={{ color: '#95c26e' }}>
                            {reg?.type?.toLowerCase() === 'buyer' ? 'Buyer' : null}
                            {reg?.type?.toLowerCase() === 'supplier' ? 'Supplier' : null}
                            {reg?.type === null ? 'Non member' : null}
                        </CardSubtitle>
                    </CardTouch>
                </CardContent>
                {
                    permissions?.wishlist?.canEdit ?
                        <DashboardMore options={moreOptions(connect.id)} />
                        :
                        null
                }
            </CardSearched>
        </>
    )
}
