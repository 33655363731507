import styled from 'styled-components'

export const Content = styled.div.attrs({})``

export const PageHeader = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px 40px;
    border-radius: 15px;
    background: var(--white-color);
    box-shadow: 0px 1.5px 6px var(--lightshadow-color);
`

export const PageHeaderImage = styled.div.attrs({})`
    width: 120px;
    height: 120px;
    border-radius: 60px;

    background: url(${(props) => props.image}) no-repeat center center / cover #fff;
    background-size: 110px;
`

export const ImageSeal = styled.img.attrs({
    src: '/images/mfs-validated.png',
})`
    margin-top: -5px;
    margin-left: 30px;
`

export const ImageSealOff = styled.img.attrs({
    src: '/images/mfs-not-validated.png',
})`
    margin-top: -5px;
    margin-left: 30px;
`

export const PageHeaderContent = styled.div.attrs({})`
    flex: 1;
    height: 110px;
    ${(props) =>
        props.row
            ? `
            display: flex;
            justify-content: right;
        `
            : `
            display: flex;
            align-items: center;
            flex-direction: column;
        `}
`

export const PageHeaderContentData = styled.div.attrs({})`
    flex: 50%;
    display: flex;
    flex-direction: column;
`

export const ContentTitle = styled.div.attrs({})`
    font-size: 22px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--grey-color);
`
export const ContentSubtitle = styled.div.attrs({})`
    margin: 9px 0;
    font-size: 16px;
    color: #5c5c5c;
`
export const ContentText = styled.div.attrs({})`
    font-size: 16px;
    color: #5c5c5c;
`

export const ContentName = styled.div.attrs({})`
    font-size: 22px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--grey-color);
    margin: 2px 25px;
`

export const ContentAddress = styled.div.attrs({})`
    margin: 2px 25px;
    font-size: 16px;
    color: #5c5c5c;
`

export const ContentEmail = styled.div.attrs({})`
    margin: 2px 25px;
    font-size: 16px;
    color: #5c5c5c;
`

export const ContentContactPhone = styled.div.attrs({})`
    margin: 2px 25px 15px;
    font-size: 16px;
    color: #5c5c5c;
`

export const PageHeaderPercentage = styled.div.attrs({})`
    width: 90px;
    height: 90px;
    border-radius: 45px;

    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--percentblue-color);

    font-size: 25px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--white-color);

    margin: 0 auto 10px;
`
export const PageHeaderPercentageText = styled.div.attrs({})`
    font-size: 16px;
    color: var(--grey-color);
`
