import styled from 'styled-components'

export const SuccessIcon = styled.img.attrs({
    src: '/icons/circle-check.svg',
})`
    margin: 20px 0 0;
`

export const WarningIcon = styled.img.attrs({
    src: '/icons/info.svg',
})`
    margin: 20px 0 0;
`

export const SuccessText = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: var(--grey-color);
    margin: 30px 0;
    ${(props) =>
        props.description
            ? `
              font-family: 'Open Sans', sans-serif;
            font-size: 16px;
            color: var(--grey-color);
            margin: 0 0 30px 0;
        `
            : ``}
`
