import React from 'react'

const ControlButtons = ({ elementId, onResize, onRemove, is_draft }) => {
    return (
        <>
            <button className="btn btn-small btn-secondary m-1" onClick={() => onResize(elementId, 'col-3')} disabled={is_draft}>
                1/4 Width
            </button>
            <button className="btn btn-small btn-secondary m-1" onClick={() => onResize(elementId, 'col-6')} disabled={is_draft}>
                Half Width
            </button>
            <button className="btn btn-small btn-secondary m-1" onClick={() => onResize(elementId, 'col-9')} disabled={is_draft}>
                3/4 Width
            </button>
            <button className="btn btn-small m-1 colorTealDark" onClick={() => onResize(elementId, 'col-12')} disabled={is_draft}>
                Full Width
            </button>
            <button className="btn btn-danger btn-sm trashIconHeight" onClick={() => onRemove(elementId)} disabled={is_draft}>
                <i className="bi bi-trash"></i>
            </button>
        </>
    );
};

export default ControlButtons;