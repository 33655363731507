import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const PlanCheckedKing = styled.img.attrs({
    src: '/icons/king-white.png',
    width: 24,
})``

export const HeaderMobileContainer = styled.div.attrs({})`
    display: flex;
    height: calc(114px - 60px);

    @media (max-width: ${mobileSize}px) {
        width: 100%;
        max-width: 240px;
        position: absolute;
        height: 350px;
        right: 16px;
        top: 114px;
        z-index: 2;
        background: var(--primary-color);
        ${(props) =>
            !props.opened
                ? `
                display: none;
            `
                : `
                display: block;
            `}
    }
`

export const PlanPremium30 = styled.img.attrs({
    src: '/images/header-premium.png',
    height: 40,
})`
    border-radius: 20px;
`

export const PlanPremiumPT = styled.img.attrs({
    src: '/images/header-premium-pt.png',
    height: 40,
})`
    border-radius: 20px;
`

export const PlanPro30 = styled.img.attrs({
    src: '/images/header-pro.png',
    height: 40,
})`
    border-radius: 20px;
`

export const TrialExpiring = styled.div.attrs({})`
    border-radius: 20px;
    height: 40px;
    color: black;
    background: -moz-linear-gradient(80deg, #f5b5b0 10%, #faa49d 90%);
    background: -webkit-linear-gradient(80deg, #f5b5b0 10%, #faa49d 90%);
    background: linear-gradient(80deg, #f5b5b0 10%, #faa49d 90%);
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    margin: auto;
    padding: 10px;
`

export const HeaderContent = styled.div.attrs({})`
    display: flex;
    align-items: center;

    ${(props) =>
        props.menuMobile
            ? `
            @media(max-width:${mobileSize}px){
                flex-direction: column;
                width:100%;
                height: 350px;
                gap: 10px;
                padding-top: 10px;
                div {
                    margin: 15px;
                }
            }
        `
            : ``}
`

export const HeaderContentItem = styled.a.attrs({})`
    padding: 0 15px;
    color: var(--white-color);
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;

    &:hover {
        text-decoration: underline;
        color: var(--white-color);
    }

    ${(props) =>
        props.gold
            ? `
            padding: 15px 30px;
            background: -moz-linear-gradient(80deg, #C09822 10%, #604C11 90%);
            background: -webkit-linear-gradient(80deg, #C09822 10%, #604C11 90%);
            background: linear-gradient(80deg, #C09822 10%, #604C11 90%);
            border-radius: 30px;
            margin:0 15px;
        `
            : ``}
`

export const MobileButton = styled.div.attrs({})`
    padding: 15px 15px;
    cursor: pointer;
    @media (min-width: ${mobileSize}px) {
        display: none;
    }
`

export const MobileButtonIcon = styled.img.attrs({
    src: '/icons/menu.svg',
})``

export const DropdownContainer = styled.div`
    position: relative;
    display: inline-block;
`

export const DropdownButton = styled.button`
    background-color: TRANSPARENT;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
`

export const DropdownContent = styled.div`
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 25;
`

export const DropdownItem = styled.a`
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    color: #333;
    &:hover {
        background-color: #ddd;
    }
`
