import React, { useContext } from 'react'

import { PageFormRow, PageFormSpace } from './styled'
import { CoreContext } from 'context/CoreContext'

import Input from 'components/Form/Input'

export default function FormCreditCard({ formValue, changeForm }) {
    const { findlanguage } = useContext(CoreContext)
    return (
        <>
            <Input
                placeholder="Cardholder name"
                value={formValue('name')}
                onChange={(e) => changeForm(e.target.value, 'name')}
            />
            <PageFormRow>
                <Input
                    placeholder="Card number"
                    value={formValue('number')}
                    onChange={(e) => changeForm(e.target.value, 'number')}
                />
                <PageFormSpace />
                <Input
                    placeholder={findlanguage().expirationDate}
                    value={formValue('expires_at')}
                    onChange={(e) => changeForm(e.target.value, 'expires_at')}
                    small
                />
            </PageFormRow>
            <PageFormRow>
                <Input
                    placeholder="CVV"
                    value={formValue('cvv')}
                    onChange={(e) => changeForm(e.target.value, 'cvv')}
                />
                <PageFormSpace />
                <Input
                    placeholder="Zip code"
                    value={formValue('zipcode')}
                    onChange={(e) => changeForm(e.target.value, 'zipcode')}
                    small
                />
            </PageFormRow>
            <Input
                placeholder="Address"
                value={formValue('address')}
                onChange={(e) => changeForm(e.target.value, 'address')}
            />
        </>
    )
}
