import { GET, POST, PUT, DELETE } from './api'

export const FetchAllChatRooms = async (userId) => {
    return await GET(`/chat-rooms/getroomforid?id=${userId}`, true)
}

export const FetchChatMessages = async (roomId, limit = 10, start = 0) => {
    return await GET(
        `/chat-messages?_sort=created_at:desc&room=${roomId}&_limit=${limit}&_start=${start}`,
        true
    )
}

export const SendMessage = async (body) => {
    return await POST(`/chat-messages`, body, true)
}

export const SetMessagesAsView = async (body) => {
    return await PUT(`/chat-messages`, body, true)
}


export const DeleteChat = async (id) => {
    return await DELETE(`/chat-rooms/${id}`, true)
}

export const CreateRoom = async (body) => {
    return await POST(`/chat-rooms`, body, true)
}

export const UpdateRoom = async (body) => {
    return await PUT(`/chat-rooms/${body.id}`, body, true)
}

export const ShouldUpdateChat = async (id) => {
    return await GET(`/chat-rooms/shouldupdate?id=${id}`, true)
}

export const ShouldShowMessage = async (id) => {
    return await GET(`/chat-rooms/shouldshowmessage?id=${id}`, true)
}
