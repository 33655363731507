import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { CardSearched, CardImage, CardContent, CardTitle, CardSubtitle, CardTouch, CardProfileContent } from './styled'
import DashboardMore from 'components/Dashboard/More'
import { CoreContext } from 'context/CoreContext'
import { RemoveConnection } from 'services/connections'
import { CreateNewSupplierReg } from 'services/sms'
import { SearchEmailExist } from 'services/validationProcess'
import { NewWishlist } from 'services/wishlist'
import { NotificationManagerAdd } from 'services/admin'

export default function DashboardCardConnection({ reg, refresh }) {
    const { user, setModal, premium, setNewChat, findlanguage, permissions } = useContext(CoreContext)

    const connect = reg?.receiverUser.id === user.company_id ? reg?.user : reg?.receiverUser
    const address =
        reg?.receiverUser.id === user.company_id ? reg?.user?.address : reg?.receiverUser?.address

    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)
    const back = () => history.goBack()

    const addWishlist = async (item) => {
        setModal({
            type: 'loading',
            text: findlanguage().creatingNewWishList,
        })

        const response = await NewWishlist(item.id)

        if (response.statusCode === 400)
            return setModal({
                type: 'alert',
                text: response.message,
                warning: true,
            })

        if (response.statusCode)
            return setModal({
                type: 'alert',
                text: findlanguage().failedToCreatedWishList,
                warning: true,
            })

        setModal({
            type: 'alert',
            text: findlanguage().newWishListCreated,
        })
    }

    const moreOptions = (card) => {
        let options = [{ title: findlanguage().sendMessage, action: createNewChat }]
        if (premium.type !== 'free')
            options = options.concat([
                { title: findlanguage().addToMyQMS, action: () => addToMyQMS(card) },
                { title: findlanguage().addToWishList, action: () => addWishlist(card) },
            ])

        options = options.concat([
            { title: findlanguage().removeConnection, action: () => deleteConnection() },
        ])
        return options
    }

    const createNewChat = () => {
        let user_key = user.id
        if (user.team_access) {
            user_key = user.company_id
        }

        setNewChat({
            firstUserId: user_key,
            secondUserId: connect.id,
            newMessageUserOne: false,
            newMessageUserTwo: false,
            lastMessage: new Date().toISOString(),
        })
        navigate('dashboard/message')
    }

    const deleteConnection = () => {
        setModal({
            type: 'choose',
            title: findlanguage().doYouReallyWantToDeleteConnection,
            options: [
                { title: findlanguage().no, action: () => setModal(null) },
                { title: findlanguage().yes, action: () => doDeleteConnection(), outline: true },
            ],
        })
    }

    const doDeleteConnection = async () => {
        setModal({
            type: 'loading',
            text: findlanguage().processing,
        })
        const response = await RemoveConnection(reg.id)
        if (response.statusCode)
            return setModal({
                type: 'alert',
                text: 'Operation failed.',
                warning: true,
            })

        setModal(null)
        refresh()
    }

    const addToMyQMS = async (form) => {
        let user_key = user.id
        if (user.team_access) {
            user_key = user.company_id
        }

        const result = await SearchEmailExist(user_key, form.email)

        if (result[0].total === '0') {
            if (form.id === undefined) {
                setModal({
                    type: 'alert',
                    warning: true,
                    text: findlanguage().nothingSelected,
                })
            } else {
                setModal({
                    type: 'loading',
                    text: findlanguage().processing,
                })

                form.users_permissions_user = form.id

                form.isUser = user_key

                const response = await CreateNewSupplierReg(form)

                if (!response)
                    return setModal({
                        type: 'alert',
                        warning: true,
                        text: 'Failed to create.',
                    })

                if (response.message === undefined) {
                    setModal({
                        type: 'alert',
                        text: findlanguage().requestSuccessful,
                    })
                } else {
                    return setModal({
                        type: 'alert',
                        warning: true,
                        text: response.message,
                    })
                }
            }
        } else {
            setModal({
                type: 'alert',
                warning: true,
                text: findlanguage().emailAlreadyRegisterSupplierList,
            })
        }
    }

    return (
        <>
            <CardSearched>
                <CardProfileContent>
                    <CardTouch onClick={() => navigate(`dashboard/user/${connect.id}`)}>
                        {reg.acceptedInvitation === 'Accepted' ? (
                            <span
                                className="badge"
                                style={{ backgroundColor: '#95c26e', marginLeft: '8px' }}
                            >
                                {findlanguage().accepted}
                            </span>
                        ) : (
                            <span style={{ marginLeft: '8px' }} className="badge text-bg-danger">
                                {findlanguage().pending}
                            </span>
                        )}
                        <CardImage url={connect?.avatarUrl} />
                    </CardTouch>
                </CardProfileContent>
                <CardContent>
                    {connect.role == 4 ? (
                        <span
                            className="badge"
                            style={{ backgroundColor: '#263640', color: 'white' }}
                        >
                            {findlanguage().supplierTitle}
                        </span>
                    ) : null}

                    {
                        connect.role == 3 ?
                            <span className="badge bg-primary">{findlanguage().buyerTitle}</span>
                            :
                            null
                    }
                    {
                        connect.role == 6 ?
                            <span
                                className="badge"
                                style={{ backgroundColor: '#263640', color: 'white' }}
                            >
                                {findlanguage().buyerTitle} / {findlanguage().supplierTitle}
                            </span>
                            :
                            null
                    }
                    {
                        connect.role == 7 ?
                            <>
                                <span
                                    className="badge"
                                    style={{ backgroundColor: '#4182ab', color: 'white' }}
                                >
                                    {findlanguage().headquarters}
                                </span>
                            </>
                            :
                            null
                    }

                    {connect.role == 5 ? (
                        <span
                            className="badge"
                            style={{ backgroundColor: '#ded514', color: 'white' }}
                        >
                            {findlanguage().serviceProviderTitle}
                        </span>
                    ) : null}
                    {
                        connect.status === 'approved' ?
                            <img src='/icons/checked.svg' alt='Approved' style={{ marginLeft: '10px', height: '25px' }} />
                            :
                            null
                    }
                    <CardTouch onClick={() => navigate(`dashboard/user/${connect.id}`)}>
                        <CardTitle>
                            {connect?.name}
                            <br></br>
                            {connect?.contactName}
                            {connect.status === 'approved' ? (
                                <img
                                    src="/icons/checked.svg"
                                    alt="Approved"
                                    style={{ marginLeft: '10px', height: '25px' }}
                                />
                            ) : null}
                        </CardTitle>
                        <CardSubtitle>
                            {address?.state} - {address?.country}
                        </CardSubtitle>
                    </CardTouch>
                </CardContent>
                {
                    permissions?.connections?.canEdit ?
                        <DashboardMore options={moreOptions(connect)} />
                        :
                        null
                }
            </CardSearched>
        </>
    )
}
