import styled from 'styled-components'

export const ModalContainer = styled.div.attrs({})`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--modalshadow-color);

    display: flex;
    align-items: center;
    justify-content: center;

    align-items: flex-end;
    justify-content: flex-start;
    ${(props) =>
        !props.minimized
            ? `
            position: fixed;
        `
            : ``}
`

export const ModalContent = styled.div.attrs({})`
    padding: 20px;
    background: var(--white-color);
    border-radius: 15px 15px 0 0;

    width: 100%;
    max-width: 540px;
    transition: all 0.3s ease;

    ${(props) =>
        props.minimized
            ? `
            position: fixed;
            left: 140px; 
            bottom: 0;
            max-width: 280px; 
        `
            : ``}
`
