import React, { useContext, useEffect, useState } from 'react'

import { ProfileFormContent, ProfileFormActions, PageFormSpace, ModalFormLabel } from './styled'

import Input from 'components/Form/Input'
import Button from 'components/Form/Button'
import Select from 'components/Form/Select'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

import { Load } from 'ui/styled'
import { ReadProducts, ReadScrapings } from 'services/filters'

export default function ModalFilterScraping() {
    const { setModal, filterScraping, setFilterScraping } = useContext(CoreContext)

    const [form, setForm] = useState(filterScraping)
    const formValue = (ref) => {
        return form?.[ref] ? form?.[ref] : ''
    }
    const changeForm = (value, ref) => {
        if (ref === 'scraping' && value?.title === 'All Scrapings') {
            delete form?.[ref]
            setForm({ ...form })
        } else {
            setForm({ ...form, [ref]: value })
        }
    }

    const close = () => {
        setModal(null)
    }

    // add data integration
    const [infos, setInfos] = useState({})
    const [loading, setLoading] = useState(false)

    const init = async () => {
        setLoading(true)

        const products = await ReadProducts()
        const scrapings = await ReadScrapings()

        setInfos({
            products: products.map((item) => ({ id: item.id, title: item.name })),
            scrapings: ['All Scrapings', ...scrapings].map((item) => ({
                title: item,
                value: item,
            })),
        })

        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])

    const apply = () => {
        setFilterScraping(form)
        close()
    }

    const clean = () => {
        setFilterScraping({})
        close()
    }

    return (
        <>
            <ModalWrapper left>
                <ProfileFormContent>
                    {loading ? (
                        <Load white={true} />
                    ) : (
                        <>
                            <ModalFormLabel sublabel={false}>Product</ModalFormLabel>
                            <Input
                                placeholder="Search any product"
                                value={formValue('products')}
                                onChange={(e) => changeForm(e.target.value, 'products')}
                                outline
                                typeahead={infos?.products}
                            />

                            <ModalFormLabel sublabel={false}>Scraping</ModalFormLabel>
                            <Select
                                placeholder="Search by scraping"
                                value={formValue('scraping')}
                                onChange={(value) => changeForm(value, 'scraping')}
                                outline
                                options={infos?.scrapings}
                                style={{ marginTop: 0 }}
                            />
                        </>
                    )}
                </ProfileFormContent>
                <PageFormSpace />
                <ProfileFormActions>
                    <Button primary small onClick={apply}>
                        Apply Filters
                    </Button>
                    <Button outline small primary onClick={clean}>
                        Clean Filters
                    </Button>
                </ProfileFormActions>
            </ModalWrapper>
        </>
    )
}
