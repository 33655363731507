import styled from 'styled-components'

export const BodyContentTable = styled.div.attrs({})`
    max-width: 600px;
`
export const TableLine = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    max-width: 600px;
    padding: 10px 15px;
    margin-bottom: 12px;

    ${(props) =>
        props.odd
            ? `
            background: var(--oddgrey-color);
        `
            : `
            background: var(--evengrey-color);
        `}
`
export const LineText = styled.div.attrs({})`
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--lightgrey-color);

    ${(props) =>
        props.text
            ? `
            color: var(--grey-color);
              font-family: 'Open Sans', sans-serif;
        `
            : ``}

    ${(props) =>
        props.link
            ? `
            color: var(--blue-color);
            cursor: pointer;
        `
            : ``}
`

export const LineCheckItem = styled.div.attrs({})`
    width: 16px;
    height: 16px;
    border-radius: 8px;
    margin: 0 9px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) =>
        props.checked
            ? `
            background: var(--primary-color);
        `
            : `
            background: var(--lightgrey-color);
        `}
`
export const LineCheckItemIn = styled.img.attrs({
    src: '/icons/grey-check.svg',
    width: 10,
})``
export const LineInfos = styled.div.attrs({})`
    display: flex;
    align-items: center;
`
