import React from 'react'

import { HeaderContent, HeaderStepIndicator, HeaderStep, HeaderSubtitle } from './styled'

export default function RegisterStepIndicator({ title, highlight, steps, current, active }) {
    return (
        <>
            <HeaderContent>
                <HeaderStepIndicator single={steps?.length === 1}>
                    {steps?.map((item, key) => {
                        const step = key + 1
                        return (
                            <HeaderStep key={key} current={current === step} active={active}>
                                {step}
                            </HeaderStep>
                        )
                    })}
                </HeaderStepIndicator>
                {!title ? null : (
                    <HeaderSubtitle>
                        {highlight ? <b>{highlight} </b> : null}
                        {title}
                    </HeaderSubtitle>
                )}
            </HeaderContent>
        </>
    )
}
