import styled from 'styled-components'

export const Content = styled.div.attrs({})`
    overflow: hidden;
    background: red;
`

export const ContentBody = styled.div.attrs({})`
    min-height: calc(100vh - 283px);
    display: flex;
    align-items: center;
    justify-content: center;
`

export const ContentAnimation = styled.div.attrs({})``
