import styled from 'styled-components'

export const PageBody = styled.div.attrs({})`
    margin: 0 0 20px;
    padding: 0 16px;
    width: 100%;
`

export const BodySubtitle = styled.div.attrs({})`
    font-size: 16px;
    font-family: ${(props) => (props.bold ? 'OpenSans-Bold' : 'OpenSans-SemiBold')};
    color: var(--primary-color);
    margin: 25px 0 ${(props) => (props.noMargin ? '0' : '15px')};
`
export const BodyText = styled.div.attrs({})`
    font-size: 16px;
    color: #6c6c6c;
   color: transparent;
   text-shadow: 0 0 5px rgba(0,0,0,0.5);
`

export const LineSeparator = styled.div`
    margin-top: 20px;
    border-top: 1px solid var(--lightshadow-color);
`
