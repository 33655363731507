import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import Card from '../Card/index.js'

import { PageCard, CardHeader, CardHeaderTitleContainer } from './styled'
import _ from 'lodash'

export default function A22({
    certifications,
    onChangeCertifications,
    saveCertifications,
    saveDocument,
    form,
    returnHeaders,
    mfsQuestionarie
}) {
    const { findlanguageEsg } = useContext(CoreContext)

    //#region A.2.2
    const a22 = {
        ref: 'A.2.2',
        requirementTitle: findlanguageEsg().a22RequirementTitle,
        inputForYesAnswer: true,
        placeHolderTextYes: findlanguageEsg().a22PlaceHolderTextYes,
        inputForNoAnswer: false,
        placeHolderTextNo: findlanguageEsg().a22PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    //#region A.2.2 Yes

    //#region A.2.2.Y.A

    const a22ya = {
        ref: 'A.2.2.Y.A',
        requirementTitle: findlanguageEsg().a22yaRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a22yaPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a22yay = {
        ref: 'A.2.2.Y.A.Y',
        requirementTitle: findlanguageEsg().a22yayRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a22yayPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const a22yayy = {
        ref: 'A.2.2.Y.A.Y.Y',
        requirementTitle: findlanguageEsg().a22yayyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a22yayyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
    }

    const a22yayyql1 = {
        ref: 'A.2.2.Y.A.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().a22yayyqlRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a22yayyqlPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        fileForYesAnswerAndNoInput: true,
        color: '#2f8c5d',
        question: true,
    }

    //#endregion

    //#region A.2.2.Y.B

    const a22yb = {
        ref: 'A.2.2.Y.B',
        requirementTitle: findlanguageEsg().a22ybRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a22ybPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a22yby = {
        ref: 'A.2.2.Y.B.Y',
        requirementTitle: findlanguageEsg().a22ybyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: findlanguageEsg().a22ybyPlaceHolderTextYes,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a22ybyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const a22ybyyqt1 = {
        ref: 'A.2.2.Y.B.Y.Y.QT.1',
        requirementTitle: findlanguageEsg().a22ybyyqt1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const a22ybyyqt2 = {
        ref: 'A.2.2.Y.B.Y.Y.QT.2',
        requirementTitle: findlanguageEsg().a22ybyyqt2RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const a22ybyyqtn = {
        ref: 'A.2.2.Y.B.Y.Y.QT.N',
        requirementTitle: findlanguageEsg().a22ybyyqtnRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const a22ybyyql1 = {
        ref: 'A.2.2.Y.B.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().a22ybyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a22ybyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.C

    const a22yc = {
        ref: 'A.2.2.Y.C',
        requirementTitle: findlanguageEsg().a22ycRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a22ycPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a22ycy = {
        ref: 'A.2.2.Y.C.Y',
        requirementTitle: findlanguageEsg().a22ycyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a22ycyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a22ycyy = {
        ref: 'A.2.2.Y.C.Y.Y',
        requirementTitle: findlanguageEsg().a22ycyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a22ycyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a22ycyyql1 = {
        ref: 'A.2.2.Y.C.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().a22ycyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a22ycyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.D

    const a22yd = {
        ref: 'A.2.2.Y.D',
        requirementTitle: findlanguageEsg().a22ydRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a22ydPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a22ydy = {
        ref: 'A.2.2.Y.D.Y',
        requirementTitle: findlanguageEsg().a22ydyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a22ydyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a22ydyy = {
        ref: 'A.2.2.Y.D.Y.Y',
        requirementTitle: findlanguageEsg().a22ydyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a22ydyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a22ydyyql1 = {
        ref: 'A.2.2.Y.D.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().a22ydyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a22ydyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#endregion

    //#region A.1.1 NO
    const a22na = {
        ref: 'A.2.2.N.A',
        requirementTitle: findlanguageEsg().a22naRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a22naPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const a22nb = {
        ref: 'A.2.2.N.B',
        requirementTitle: findlanguageEsg().a22nbRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a22nbPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const a22nc = {
        ref: 'A.2.2.N.C',
        requirementTitle: findlanguageEsg().a22ncRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a22ncPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    const returnCondition = (item, layers, category1, category2, category3, category4) => {
        switch (layers) {
            case 0:
                return (
                    <Card
                        item={item}
                        onChangeCertifications={onChangeCertifications}
                        saveCertifications={saveCertifications}
                        saveDocument={saveDocument}
                        certifications={certifications}
                        form={form}
                        mfsQuestionarie={mfsQuestionarie}
                    />
                )
                break
            case 1:
                if (certifications?.find((x) => x.category === category1)?.next_level === true)
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 2:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 3:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 4:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true &&
                    certifications?.find((x) => x.category === category4)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            default:
                break
        }
    }

    return (
        <>
            <PageCard>
                <CardHeader>
                    <CardHeaderTitleContainer>{findlanguageEsg().a22CardHeaderTitleContainer}</CardHeaderTitleContainer>
                </CardHeader>
                {returnHeaders()}
                {returnCondition(a22, 0)}
            </PageCard>
            {
                mfsQuestionarie !== '1' ?
                    <>
                        {
                            certifications?.find((x) => x.category === 'A.2.2')?.next_level === true ? (
                                <>
                                    <PageCard>
                                        <CardHeader>
                                            <CardHeaderTitleContainer>
                                                A.2.2 ENERGY EFFICIENCY - Answer Yes - A
                                            </CardHeaderTitleContainer>
                                        </CardHeader>
                                        {returnHeaders()}
                                        {returnCondition(a22ya, 1, 'A.2.2')}
                                        {returnCondition(a22yay, 2, 'A.2.2', 'A.2.2.Y.A')}
                                        {returnCondition(a22yayy, 3, 'A.2.2', 'A.2.2.Y.A', 'A.2.2.Y.A.Y')}
                                        {returnCondition(
                                            a22yayyql1,
                                            4,
                                            'A.2.2',
                                            'A.2.2.Y.A',
                                            'A.2.2.Y.A.Y',
                                            'A.2.2.Y.A.Y.Y'
                                        )}
                                    </PageCard>
                                    <PageCard>
                                        <CardHeader>
                                            <CardHeaderTitleContainer>
                                                A.2.2 ENERGY EFFICIENCY - Answer Yes - B
                                            </CardHeaderTitleContainer>
                                        </CardHeader>
                                        {returnHeaders()}
                                        {returnCondition(a22yb, 1, 'A.2.2')}
                                        {returnCondition(a22yby, 2, 'A.2.2', 'A.2.2.Y.B')}
                                        {returnCondition(a22ybyyqt1, 3, 'A.2.2', 'A.2.2.Y.B', 'A.2.2.Y.B.Y')}
                                        {returnCondition(a22ybyyqt2, 3, 'A.2.2', 'A.2.2.Y.B', 'A.2.2.Y.B.Y')}
                                        {returnCondition(a22ybyyqtn, 3, 'A.2.2', 'A.2.2.Y.B', 'A.2.2.Y.B.Y')}
                                        {returnCondition(a22ybyyql1, 3, 'A.2.2', 'A.2.2.Y.B', 'A.2.2.Y.B.Y')}
                                    </PageCard>
                                    <PageCard>
                                        <CardHeader>
                                            <CardHeaderTitleContainer>
                                                A.2.2 ENERGY EFFICIENCY - Answer Yes - C
                                            </CardHeaderTitleContainer>
                                        </CardHeader>
                                        {returnHeaders()}
                                        {returnCondition(a22yc, 1, 'A.2.2')}
                                        {returnCondition(a22ycy, 2, 'A.2.2', 'A.2.2.Y.C')}
                                        {returnCondition(a22ycyy, 3, 'A.2.2', 'A.2.2.Y.C', 'A.2.2.Y.C.Y')}
                                        {returnCondition(
                                            a22ycyyql1,
                                            4,
                                            'A.2.2',
                                            'A.2.2.Y.C',
                                            'A.2.2.Y.C.Y',
                                            'A.2.2.Y.C.Y.Y'
                                        )}
                                    </PageCard>
                                    <PageCard>
                                        <CardHeader>
                                            <CardHeaderTitleContainer>
                                                A.2.2 ENERGY EFFICIENCY - Answer Yes - D
                                            </CardHeaderTitleContainer>
                                        </CardHeader>
                                        {returnHeaders()}
                                        {returnCondition(a22yd, 1, 'A.2.2')}
                                        {returnCondition(a22ydy, 2, 'A.2.2', 'A.2.2.Y.D')}
                                        {returnCondition(a22ydyy, 3, 'A.2.2', 'A.2.2.Y.D', 'A.2.2.Y.D.Y')}
                                        {returnCondition(
                                            a22ydyyql1,
                                            4,
                                            'A.2.2',
                                            'A.2.2.Y.D',
                                            'A.2.2.Y.D.Y',
                                            'A.2.2.Y.D.Y.Y'
                                        )}
                                    </PageCard>
                                </>
                            ) : null}
                        {certifications?.find((x) => x.category === 'A.2.2')?.next_level === false ? (
                            <PageCard>
                                <CardHeader>
                                    <CardHeaderTitleContainer>
                                        A.2.2 ENERGY EFFICIENCY - Answer No
                                    </CardHeaderTitleContainer>
                                </CardHeader>
                                {returnHeaders()}
                                {returnCondition(a22na, 0)}
                                {returnCondition(a22nb, 0)}
                                {returnCondition(a22nc, 0)}
                            </PageCard>
                        ) : null}
                    </>
                    :
                    null
            }
        </>
    )
}
