import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import Card from '../Card/index.js'

import { PageCard, CardHeader, CardHeaderTitleContainer } from './styled'
import _ from 'lodash'

export default function SelfAssessmentChecklist({
    certifications,
    onChangeCertifications,
    saveCertifications,
    saveDocument,
    form,
    returnHeaders,
    mfsQuestionarie
}) {
    const { findlanguageEsg } = useContext(CoreContext)

    //#region E.1.1
    const e11 = {
        ref: 'E.1.1',
        requirementTitle: findlanguageEsg().e11RequirementTitle,
        inputForYesAnswer: true,
        placeHolderTextYes: findlanguageEsg().e11PlaceHolderTextYes,
        inputForNoAnswer: false,
        placeHolderTextNo: findlanguageEsg().e11PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    //#region E.1.1 Yes

    //#region E.1.1.Y.A

    const e11ya = {
        ref: 'E.1.1.Y.A',
        requirementTitle: findlanguageEsg().e11yaRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e11yaPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const e11yay = {
        ref: 'E.1.1.Y.A.Y',
        requirementTitle: findlanguageEsg().e11yayRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e11yayPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const e11yayy = {
        ref: 'E.1.1.Y.A.Y.Y',
        requirementTitle: findlanguageEsg().e11yayyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e11yayyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
    }

    const e11yayyql1 = {
        ref: 'E.1.1.Y.A.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().e11yayyqlRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e11yayyqlPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        fileForYesAnswerAndNoInput: true,
        color: '#2f8c5d',
        question: true,
    }

    //#endregion

    //#region E.1.1.Y.B

    const e11yb = {
        ref: 'E.1.1.Y.B',
        requirementTitle: findlanguageEsg().e11ybRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e11ybPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const e11yby = {
        ref: 'E.1.1.Y.B.Y',
        requirementTitle: findlanguageEsg().e11ybyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: findlanguageEsg().e11ybyPlaceHolderTextYes,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e11ybyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const e11ybyyqt1 = {
        ref: 'E.1.1.Y.B.Y.Y.QT.1',
        requirementTitle: findlanguageEsg().e11ybyyqt1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const e11ybyyqt2 = {
        ref: 'E.1.1.Y.B.Y.Y.QT.2',
        requirementTitle: findlanguageEsg().e11ybyyqt2RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const e11ybyyqt3 = {
        ref: 'E.1.1.Y.B.Y.Y.QT.3',
        requirementTitle: findlanguageEsg().e11ybyyqt3RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const e11ybyyqtn = {
        ref: 'E.1.1.Y.B.Y.Y.QT.N',
        requirementTitle: findlanguageEsg().e11ybyyqtnRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const e11ybyyql1 = {
        ref: 'E.1.1.Y.B.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().e11ybyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: `Your answer was "NO", please justify, if possible.`,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.C

    const e11yc = {
        ref: 'E.1.1.Y.C',
        requirementTitle: findlanguageEsg().e11ycRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e11ycPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const e11ycy = {
        ref: 'E.1.1.Y.C.Y',
        requirementTitle: findlanguageEsg().e11ycyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e11ycyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const e11ycyy = {
        ref: 'E.1.1.Y.C.Y.Y',
        requirementTitle: findlanguageEsg().e11ycyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e11ycyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const e11ycyyql1 = {
        ref: 'E.1.1.Y.C.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().e11ycyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e11ycyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.D

    const e11yd = {
        ref: 'E.1.1.Y.D',
        requirementTitle: findlanguageEsg().e11ydRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e11ydPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const e11ydy = {
        ref: 'E.1.1.Y.D.Y',
        requirementTitle: findlanguageEsg().e11ydyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e11ydyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const e11ydyy = {
        ref: 'E.1.1.Y.D.Y.Y',
        requirementTitle: findlanguageEsg().e11ydyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e11ydyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const e11ydyyql1 = {
        ref: 'E.1.1.Y.D.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().e11ydyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e11ydyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#endregion

    //#region A.1.1 NO
    const e11na = {
        ref: 'E.1.1.N.A',
        requirementTitle: findlanguageEsg().e11naRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e11naPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const e11nb = {
        ref: 'E.1.1.N.B',
        requirementTitle: findlanguageEsg().e11nbRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e11nbPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const e11nc = {
        ref: 'E.1.1.N.C',
        requirementTitle: findlanguageEsg().e11ncRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().e11ncPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    const returnCondition = (item, layers, category1, category2, category3, category4) => {
        switch (layers) {
            case 0:
                return (
                    <Card
                        item={item}
                        onChangeCertifications={onChangeCertifications}
                        saveCertifications={saveCertifications}
                        saveDocument={saveDocument}
                        certifications={certifications}
                        form={form}
                        mfsQuestionarie={mfsQuestionarie}
                    />
                )
                break
            case 1:
                if (certifications?.find((x) => x.category === category1)?.next_level === true)
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 2:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 3:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 4:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true &&
                    certifications?.find((x) => x.category === category4)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            default:
                break
        }
    }

    return (
        <>
            <PageCard>
                <CardHeader>
                    <CardHeaderTitleContainer>
                        {findlanguageEsg().e11CardHeaderTitleContainer}
                    </CardHeaderTitleContainer>
                </CardHeader>
                {returnHeaders()}
                {returnCondition(e11, 0)}
            </PageCard>
            {
                mfsQuestionarie !== '1' ?
                    <>

                        {certifications?.find((x) => x.category === 'E.1.1')?.next_level === true ? (
                            <>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            E.1.1 RESPECT TO THE TERRITORY - Answer Yes - A
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(e11ya, 1, 'E.1.1')}
                                    {returnCondition(e11yay, 2, 'E.1.1', 'E.1.1.Y.A')}
                                    {returnCondition(e11yayy, 3, 'E.1.1', 'E.1.1.Y.A', 'E.1.1.Y.A.Y')}
                                    {returnCondition(
                                        e11yayyql1,
                                        4,
                                        'E.1.1',
                                        'E.1.1.Y.A',
                                        'E.1.1.Y.A.Y',
                                        'E.1.1.Y.A.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            E.1.1 RESPECT TO THE TERRITORY - Answer Yes - B
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(e11yb, 1, 'E.1.1')}
                                    {returnCondition(e11yby, 2, 'E.1.1', 'E.1.1.Y.B')}
                                    {returnCondition(e11ybyyqt1, 3, 'E.1.1', 'E.1.1.Y.B', 'E.1.1.Y.B.Y')}
                                    {returnCondition(e11ybyyqt2, 3, 'E.1.1', 'E.1.1.Y.B', 'E.1.1.Y.B.Y')}
                                    {returnCondition(e11ybyyqt3, 3, 'E.1.1', 'E.1.1.Y.B', 'E.1.1.Y.B.Y')}
                                    {returnCondition(e11ybyyqtn, 3, 'E.1.1', 'E.1.1.Y.B', 'E.1.1.Y.B.Y')}
                                    {returnCondition(e11ybyyql1, 3, 'E.1.1', 'E.1.1.Y.B', 'E.1.1.Y.B.Y')}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            E.1.1 RESPECT TO THE TERRITORY - Answer Yes - C
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(e11yc, 1, 'E.1.1')}
                                    {returnCondition(e11ycy, 2, 'E.1.1', 'E.1.1.Y.C')}
                                    {returnCondition(e11ycyy, 3, 'E.1.1', 'E.1.1.Y.C', 'E.1.1.Y.C.Y')}
                                    {returnCondition(
                                        e11ycyyql1,
                                        4,
                                        'E.1.1',
                                        'E.1.1.Y.C',
                                        'E.1.1.Y.C.Y',
                                        'E.1.1.Y.C.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            E.1.1 RESPECT TO THE TERRITORY - Answer Yes - D
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(e11yd, 1, 'E.1.1')}
                                    {returnCondition(e11ydy, 2, 'E.1.1', 'E.1.1.Y.D')}
                                    {returnCondition(e11ydyy, 3, 'E.1.1', 'E.1.1.Y.D', 'E.1.1.Y.D.Y')}
                                    {returnCondition(
                                        e11ydyyql1,
                                        4,
                                        'E.1.1',
                                        'E.1.1.Y.D',
                                        'E.1.1.Y.D.Y',
                                        'E.1.1.Y.D.Y.Y'
                                    )}
                                </PageCard>
                            </>
                        ) : null}
                        {certifications?.find((x) => x.category === 'E.1.1')?.next_level === false ? (
                            <PageCard>
                                <CardHeader>
                                    <CardHeaderTitleContainer>
                                        E.1.1 RESPECT TO THE TERRITORY - Answer No
                                    </CardHeaderTitleContainer>
                                </CardHeader>
                                {returnHeaders()}
                                {returnCondition(e11na, 0)}
                                {returnCondition(e11nb, 0)}
                                {returnCondition(e11nc, 0)}
                            </PageCard>
                        ) : null}
                    </>
                    :
                    null
            }
        </>
    )
}
