import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import Card from '../Card/index.js'

import { PageCard, CardHeader, CardHeaderTitleContainer } from './styled'
import _ from 'lodash'

export default function SelfAssessmentChecklist({
    certifications,
    onChangeCertifications,
    saveCertifications,
    saveDocument,
    form,
    returnHeaders,
    mfsQuestionarie
}) {
    const { findlanguageEsg } = useContext(CoreContext) 

    //#region J.1.1
    const j11 = {
        ref: 'J.1.1',
        requirementTitle: findlanguageEsg().j11RequirementTitle,
        inputForYesAnswer: true,
        placeHolderTextYes: findlanguageEsg().j11PlaceHolderTextYes,
        inputForNoAnswer: false,
        placeHolderTextNo: findlanguageEsg().j11PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    //#region J.1.1 Yes

    //#region J.1.1.Y.A

    const j11ya = {
        ref: 'J.1.1.Y.A',
        requirementTitle: findlanguageEsg().j11yaRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j11yaPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const j11yay = {
        ref: 'J.1.1.Y.A.Y',
        requirementTitle: findlanguageEsg().j11yayRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j11yayPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const j11yayy = {
        ref: 'J.1.1.Y.A.Y.Y',
        requirementTitle: findlanguageEsg().j11yayyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j11yayyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
    }

    const j11yayyql1 = {
        ref: 'J.1.1.Y.A.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().j11yayyqlRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j11yayyqlPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        fileForYesAnswerAndNoInput: true,
        color: '#2f8c5d',
        question: true,
    }

    //#endregion

    //#region J.1.1.Y.B

    const j11yb = {
        ref: 'J.1.1.Y.B',
        requirementTitle: findlanguageEsg().j11ybRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j11ybPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const j11yby = {
        ref: 'J.1.1.Y.B.Y',
        requirementTitle: findlanguageEsg().j11ybyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: findlanguageEsg().j11ybyPlaceHolderTextYes,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j11ybyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const j11ybyyqt1 = {
        ref: 'J.1.1.Y.B.Y.Y.QT.1',
        requirementTitle: findlanguageEsg().j11ybyyqt1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const j11ybyyqt2 = {
        ref: 'J.1.1.Y.B.Y.Y.QT.2',
        requirementTitle: findlanguageEsg().j11ybyyqt2RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }


    const j11ybyyqt3 = {
        ref: 'J.1.1.Y.B.Y.Y.QT.3',
        requirementTitle: findlanguageEsg().j11ybyyqt3RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const j11ybyyqtn = {
        ref: 'J.1.1.Y.B.Y.Y.QT.N',
        requirementTitle: findlanguageEsg().j11ybyyqtnRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const j11ybyyql1 = {
        ref: 'J.1.1.Y.B.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().j11ybyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j11ybyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.C

    const j11yc = {
        ref: 'J.1.1.Y.C',
        requirementTitle: findlanguageEsg().j11ycRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j11ycPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const j11ycy = {
        ref: 'J.1.1.Y.C.Y',
        requirementTitle: findlanguageEsg().j11ycyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j11ycyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const j11ycyy = {
        ref: 'J.1.1.Y.C.Y.Y',
        requirementTitle: findlanguageEsg().j11ycyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j11ycyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const j11ycyyql1 = {
        ref: 'J.1.1.Y.C.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().j11ycyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j11ycyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.D

    const j11yd = {
        ref: 'J.1.1.Y.D',
        requirementTitle: findlanguageEsg().j11ydRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j11ydPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const j11ydy = {
        ref: 'J.1.1.Y.D.Y',
        requirementTitle: findlanguageEsg().j11ydyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j11ydyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const j11ydyy = {
        ref: 'J.1.1.Y.D.Y.Y',
        requirementTitle: findlanguageEsg().j11ydyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j11ydyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const j11ydyyql1 = {
        ref: 'J.1.1.Y.D.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().j11ydyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j11ydyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#endregion

    //#region A.1.1 NO
    const j11na = {
        ref: 'J.1.1.N.A',
        requirementTitle: findlanguageEsg().j11naRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j11naPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const j11nb = {
        ref: 'J.1.1.N.B',
        requirementTitle: findlanguageEsg().j11nbRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j11nbPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const j11nc = {
        ref: 'J.1.1.N.C',
        requirementTitle: findlanguageEsg().j11ncRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().j11ncPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    const returnCondition = (item, layers, category1, category2, category3, category4) => {
        switch (layers) {
            case 0:
                return (
                    <Card
                        item={item}
                        onChangeCertifications={onChangeCertifications}
                        saveCertifications={saveCertifications}
                        saveDocument={saveDocument}
                        certifications={certifications}
                        form={form}
                        mfsQuestionarie={mfsQuestionarie}

                    />
                )
                break
            case 1:
                if (certifications?.find((x) => x.category === category1)?.next_level === true)
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}

                        />
                    )
                break
            case 2:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}

                        />
                    )
                break
            case 3:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}

                        />
                    )
                break
            case 4:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true &&
                    certifications?.find((x) => x.category === category4)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}

                        />
                    )
                break
            default:
                break
        }
    }

    return (
        <>
            <PageCard>
                <CardHeader>
                    <CardHeaderTitleContainer>
                        {findlanguageEsg().j11CardHeaderTitleContainer}
                    </CardHeaderTitleContainer>
                </CardHeader>
                {returnHeaders()}
                {returnCondition(j11, 0)}
            </PageCard>
            {
                mfsQuestionarie !== '1' ?
                    <>

                        {certifications?.find((x) => x.category === 'J.1.1')?.next_level === true ? (
                            <>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().j11aCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(j11ya, 1, 'J.1.1')}
                                    {returnCondition(j11yay, 2, 'J.1.1', 'J.1.1.Y.A')}
                                    {returnCondition(j11yayy, 3, 'J.1.1', 'J.1.1.Y.A', 'J.1.1.Y.A.Y')}
                                    {returnCondition(
                                        j11yayyql1,
                                        4,
                                        'J.1.1',
                                        'J.1.1.Y.A',
                                        'J.1.1.Y.A.Y',
                                        'J.1.1.Y.A.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().j11bCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(j11yb, 1, 'J.1.1')}
                                    {returnCondition(j11yby, 2, 'J.1.1', 'J.1.1.Y.B')}
                                    {returnCondition(j11ybyyqt1, 3, 'J.1.1', 'J.1.1.Y.B', 'J.1.1.Y.B.Y')}
                                    {returnCondition(j11ybyyqt2, 3, 'J.1.1', 'J.1.1.Y.B', 'J.1.1.Y.B.Y')}
                                    {returnCondition(j11ybyyqt3, 3, 'J.1.1', 'J.1.1.Y.B', 'J.1.1.Y.B.Y')}
                                    {returnCondition(j11ybyyqtn, 3, 'J.1.1', 'J.1.1.Y.B', 'J.1.1.Y.B.Y')}
                                    {returnCondition(j11ybyyql1, 3, 'J.1.1', 'J.1.1.Y.B', 'J.1.1.Y.B.Y')}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().j11cCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(j11yc, 1, 'J.1.1')}
                                    {returnCondition(j11ycy, 2, 'J.1.1', 'J.1.1.Y.C')}
                                    {returnCondition(j11ycyy, 3, 'J.1.1', 'J.1.1.Y.C', 'J.1.1.Y.C.Y')}
                                    {returnCondition(
                                        j11ycyyql1,
                                        4,
                                        'J.1.1',
                                        'J.1.1.Y.C',
                                        'J.1.1.Y.C.Y',
                                        'J.1.1.Y.C.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().j11dCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(j11yd, 1, 'J.1.1')}
                                    {returnCondition(j11ydy, 2, 'J.1.1', 'J.1.1.Y.D')}
                                    {returnCondition(j11ydyy, 3, 'J.1.1', 'J.1.1.Y.D', 'J.1.1.Y.D.Y')}
                                    {returnCondition(
                                        j11ydyyql1,
                                        4,
                                        'J.1.1',
                                        'J.1.1.Y.D',
                                        'J.1.1.Y.D.Y',
                                        'J.1.1.Y.D.Y.Y'
                                    )}
                                </PageCard>
                            </>
                        ) : null}
                        {certifications?.find((x) => x.category === 'J.1.1')?.next_level === false ? (
                            <PageCard>
                                <CardHeader>
                                    <CardHeaderTitleContainer>
                                        {findlanguageEsg().j11noCardHeaderTitleContainer}
                                    </CardHeaderTitleContainer>
                                </CardHeader>
                                {returnHeaders()}
                                {returnCondition(j11na, 0)}
                                {returnCondition(j11nb, 0)}
                                {returnCondition(j11nc, 0)}
                            </PageCard>
                        ) : null}
                    </>
                    :
                    null
            }
        </>
    )
}
