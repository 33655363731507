import React from 'react'
import ControlButtons from '../ControlButtons/index'

function Signature({ element, handleLabelTextChange, toggleRequired, handleResize, removeElement, is_draft }) {
     return (
        <div key={element.id} className="mb-3 col-12">
            <div style={{ display: 'inline-flex' }}>
                <label className="form-label">
                     Signature
                </label>
            </div>
            <input
                type="text"
                className="form-control"
                value={element.label || ''}
                onChange={(e) => handleLabelTextChange(element.id, e)}
                 placeholder="Add a label for signature field here"
                 disabled={is_draft}
            />
            <ControlButtons
                elementId={element.id}
                onResize={handleResize}
                 onRemove={removeElement}
                 is_draft={is_draft}
            />
        </div>
    );
}

export default Signature;