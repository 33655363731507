import { ReadObject, SaveObject } from './storage'

import axios from 'axios'
import Cookies from 'js-cookie'

export const DEVELOPMENT_MODE = true
export const API_ENDPOINT = process.env.REACT_APP_API_URL

export const GetHeaders = async (authenticated) => {
    const headers = { 'Content-Type': 'application/json' }

    const jwt = Cookies.get('auth_token')

    if (authenticated && jwt) {
        headers.Authorization = `Bearer ${jwt}`
    }
    return { headers }
}

export const ServerAxios = async (path) => {
    const { headers } = await GetHeaders(true)

    const instance = axios.create({
        timeout: 2500,
    })

    instance.interceptors.response.use(
        (response) => {
            return response
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                Cookies.remove('auth_token')
                window.location.href = '/login'
            } else {
                console.error('ServerAxiosError', error)
            }
            return Promise.reject(error)
        }
    )

    try {
        const response = await instance.get(`${API_ENDPOINT}${path}`, {
            headers: headers,
            timeout: 300000,
        })

        return response.data
    } catch (error) {
        return false
    }
}

export const ServerFetch = async (url, options, authenticated) => {
    const { headers } = await GetHeaders(authenticated)
    let response
    try {
        response = await fetch(url, { ...options, headers })
        if (response.status === 401) {
            Cookies.remove('auth_token')
            window.location.href = '/login'
        } else if (!response.ok) {
            console.error('ServerFetchError', response.status)
        }
    } catch (error) {
        console.error('ServerFetchError', error)
    }

    try {
        return response ? await response.json() : null
    } catch (err) {
        console.error('ServerParseError', err)
        return response
    }
}

export const GET = async (path, authenticated = false) => {
    return await ServerFetch(
        `${API_ENDPOINT}${path}`,
        {
            method: 'GET',
        },
        authenticated
    )
}

export const POSTAUTH = async (path, body, authenticated = false) => {
    const { headers } = await GetHeaders(authenticated)

    let response = ''

    try {
        response = await fetch(`${API_ENDPOINT}${path}`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers,
        })

        if (!response.ok) {
            await checkAndHandleAuthError(response, authenticated)
        }

        return await response.json()
    } catch (error) {
        console.log('ServerFetchError', error)
        return await response.json()
    }
}

export const POST = async (path, body, authenticated = false) => {
    const { headers } = await GetHeaders(authenticated)

    try {
        const response = await fetch(`${API_ENDPOINT}${path}`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers,
        })

        if (!response.ok) {
            await checkAndHandleAuthError(response, authenticated)
        }

        return await response.json()
    } catch (error) {
        console.log('ServerFetchError', error)
        return false
    }
}

export const PUT = async (path, body, authenticated = false) => {
    const { headers } = await GetHeaders(authenticated)

    try {
        const response = await fetch(`${API_ENDPOINT}${path}`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers,
        })

        if (!response.ok) {
            await checkAndHandleAuthError(response, authenticated)
        }

        try {
            return await response.json()
        } catch (error) {
            console.error('Error parsing JSON:', error)
        }
    } catch (error) {
        console.log('ServerFetchError', error)
        return false
    }
}

export const DELETE = async (path, authenticated = false) => {
    const { headers } = await GetHeaders(authenticated)

    try {
        const response = await fetch(`${API_ENDPOINT}${path}`, {
            method: 'DELETE',
            headers,
        })
        console.log('response', response)
        if (!response.ok) {
            await checkAndHandleAuthError(response, authenticated)
        }

        try {
            return await response.json()
        } catch (error) {
            console.error('Error parsing JSON:', error)
        }
    } catch (error) {
        console.log('ServerFetchError', error)
        return false
    }
}

const redirectToLogin = async () => {
    window.location.href = '/login'
}

const checkAndHandleAuthError = async (response, authenticated) => {
    if (response.status === 401 && authenticated) {
        await SaveObject('authentication', {})
        await redirectToLogin()
    } else {
        throw new Error('An error occurred: ' + response.status)
    }
}

export const ReadAddressesByZipCode = async (zipCode) => {
    try {
        let result = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
        return result.json()
    } catch (err) {
        return false
    }
}

export const PostImage = async (fileToUpload) => {
    const formData = new FormData()
    formData.append('file', fileToUpload, fileToUpload.name)
    let { headers } = await GetHeaders(true)
    delete headers['Content-Type']
    let response = await fetch(`${API_ENDPOINT}/upload`, {
        method: 'POST',
        body: formData,
        headers,
    })
    let responseJson = await response.json()
    return responseJson
}

export const PrepareImageFile = (image) => {
    let btc = window.atob(image.source)
    let btn = new Array(btc.length)
    for (var i = 0; i < btc.length; i++) {
        btn[i] = btc.charCodeAt(i)
    }
    var bta = new Uint8Array(btn)
    let boobs = new Blob([bta], { type: 'image/png' })
    return new File([boobs], `${image.filename}`)
}

export const UploadImage = (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = async () => {
            let source = `${reader.result}`.split(',')[1]
            let image = {
                filename: file.name,
                filetype: file.type,
                source,
            }
            let thefile = PrepareImageFile(image)
            let result = await PostImage(thefile)

            resolve(result)
        }
        reader.readAsDataURL(file)
    })
}

export const parseStrapiImage = (url) => {
    return !url ? '' : url.indexOf('://') !== -1 ? url : `${API_ENDPOINT.replace('/api', '')}${url}`
}

export const UploadAvatar = (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = async () => {
            let source = `${reader.result}`.split(',')[1]
            let image = {
                filename: file.name,
                filetype: file.type,
                source,
            }
            let thefile = PrepareImageFile(image)
            let result = await PostAvatar(thefile)

            resolve(result)
        }
        reader.readAsDataURL(file)
    })
}

export const PostAvatar = async (fileToUpload) => {
    const formData = new FormData()
    formData.append('avatar', fileToUpload, fileToUpload.name)
    let { headers } = await GetHeaders(true)
    delete headers['Content-Type']
    let response = await fetch(`${API_ENDPOINT}/users/me/avatar`, {
        method: 'PUT',
        body: formData,
        headers,
    })
    let responseJson = await response.json()
    return responseJson
}

export const UploadPDF = (file, formId) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = async () => {
            let source = `${reader.result}`.split(',')[1]
            let image = {
                filename: file.name,
                filetype: file.type,
                source,
            }
            let thefile = PreparePDF(image)
            let result = await PostPDF(thefile, formId)

            resolve(result)
        }
        reader.readAsDataURL(file)
    })
}

export const UploadPDFForm = (file, formId) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = async () => {
            let source = `${reader.result}`.split(',')[1]
            let image = {
                filename: file.name,
                filetype: file.type,
                source,
            }
            let thefile = PreparePDF(image)
            let result = await PostPDFForm(thefile, formId)

            resolve(result)
        }
        reader.readAsDataURL(file)
    })
}

export const PreparePDF = (image) => {
    let btc = window.atob(image.source)
    let btn = new Array(btc.length)
    for (var i = 0; i < btc.length; i++) {
        btn[i] = btc.charCodeAt(i)
    }
    var bta = new Uint8Array(btn)
    let boobs = new Blob([bta], { type: 'image/png' })
    return new File([boobs], `${image.filename}`)
}

export const PostPDF = async (fileToUpload, formId) => {
    const formData = new FormData()
    formData.append('filePdf', fileToUpload, fileToUpload.name)
    formData.append('formId', formId)

    let { headers } = await GetHeaders(true)
    delete headers['Content-Type']
    let response = await fetch(`${API_ENDPOINT}/validation-process-responses/files`, {
        method: 'POST',
        body: formData,
        headers,
    })
    let responseJson = await response.json()
    return responseJson
}

export const PostPDFForm = async (fileToUpload, formId) => {
    const formData = new FormData()
    formData.append('filePdf', fileToUpload, fileToUpload.name)
    formData.append('formId', formId)

    let { headers } = await GetHeaders(true)
    delete headers['Content-Type']
    let response = await fetch(`${API_ENDPOINT}/validation-process-forms/files`, {
        method: 'POST',
        body: formData,
        headers,
    })
    let responseJson = await response.json()
    return responseJson
}

export const DeleteAvatar = async () => {
    let { headers } = await GetHeaders(true)
    delete headers['Content-Type']
    let response = await fetch(`${API_ENDPOINT}/users/me/avatar/delete`, {
        method: 'PUT',
        headers,
    })
    const ret = await response
    let responseJson = ret.json()
    return responseJson
}

export const PostCertificate = async (formData) => {
    let { headers } = await GetHeaders(true)
    delete headers['Content-Type']
    let response = await fetch(`${API_ENDPOINT}/supplier-certificates`, {
        method: 'POST',
        body: formData,
        headers,
    })

    const resp = await response
    let responseJson = resp.json()
    return responseJson
}

export const PostTeamAccessFile = async (formData) => {
    let { headers } = await GetHeaders(true)
    delete headers['Content-Type']
    let response = await fetch(`${API_ENDPOINT}/team-access/bulk`, {
        method: 'POST',
        body: formData,
        headers,
    })

    const resp = await response
    let responseJson = resp.json()
    return responseJson
}

export const PostCompaniesFile = async (formData) => {
    let { headers } = await GetHeaders(true)
    delete headers['Content-Type']
    let response = await fetch(`${API_ENDPOINT}/users-bulk`, {
        method: 'POST',
        body: formData,
        headers,
    })

    const resp = await response
    let responseJson = resp.json()
    return responseJson
}

export const PostServiceProviderDocument = async (formData) => {
    let { headers } = await GetHeaders(true)
    delete headers['Content-Type']
    let response = await fetch(`${API_ENDPOINT}/service-provider-documents`, {
        method: 'POST',
        body: formData,
        headers,
    })

    const resp = await response
    let responseJson = resp.json()
    return responseJson
}

export const PostDocument = async (formData) => {
    let { headers } = await GetHeaders(true)
    delete headers['Content-Type']
    let response = await fetch(`${API_ENDPOINT}/documents`, {
        method: 'POST',
        body: formData,
        headers,
    })

    const resp = await response
    let responseJson = resp.json()
    return responseJson
}

export const UpdateDocument = async (formData, id) => {
    let { headers } = await GetHeaders(true)
    delete headers['Content-Type']
    let response = await fetch(`${API_ENDPOINT}/documents/${id}`, {
        method: 'PUT',
        body: formData,
        headers,
    })

    const resp = await response
    let responseJson = resp.json()
    return responseJson
}

export const UpdateAdditionalInformation = async (formData) => {
    let { headers } = await GetHeaders(true)
    delete headers['Content-Type']
    let response = await fetch(`${API_ENDPOINT}/additional-informations/savefile`, {
        method: 'PUT',
        body: formData,
        headers,
    })

    const resp = await response
    let responseJson = resp.json()
    return responseJson
}

export const UpdatePrecheck = async (formData) => {
    let { headers } = await GetHeaders(true)
    delete headers['Content-Type']
    let response = await fetch(`${API_ENDPOINT}/certification-prechecks/savefile`, {
        method: 'PUT',
        body: formData,
        headers,
    })

    const resp = await response
    let responseJson = resp.json()
    return responseJson
}

export const UpdatePrecheckServiceProvider = async (formData) => {
    let { headers } = await GetHeaders(true)
    delete headers['Content-Type']
    let response = await fetch(
        `${API_ENDPOINT}/certification-precheck-service-providers/savefile`,
        {
            method: 'PUT',
            body: formData,
            headers,
        }
    )

    const resp = await response
    let responseJson = resp.json()
    return responseJson
}

export const UpdateSpecificsQuestions = async (formData) => {
    let { headers } = await GetHeaders(true)
    delete headers['Content-Type']
    let response = await fetch(`${API_ENDPOINT}/specific-questions/savefile`, {
        method: 'PUT',
        body: formData,
        headers,
    })

    const resp = await response
    let responseJson = resp.json()
    return responseJson
}

export const UpdateCertifications = async (formData) => {
    let { headers } = await GetHeaders(true)
    delete headers['Content-Type']
    let response = await fetch(`${API_ENDPOINT}/certifications-documents/savefile`, {
        method: 'PUT',
        body: formData,
        headers,
    })

    const resp = await response
    let responseJson = resp.json()
    return responseJson
}

export const UpdateCertificationsESG = async (formData) => {
    let { headers } = await GetHeaders(true)
    delete headers['Content-Type']
    let response = await fetch(`${API_ENDPOINT}/certifications-documents-esgs/savefile`, {
        method: 'PUT',
        body: formData,
        headers,
    })

    const resp = await response
    let responseJson = resp.json()
    return responseJson
}

export const UpdateCapaDoc = async (formData) => {
    let { headers } = await GetHeaders(true)
    delete headers['Content-Type']
    let response = await fetch(`${API_ENDPOINT}/capas/savefile`, {
        method: 'PUT',
        body: formData,
        headers,
    })

    const resp = await response
    let responseJson = resp.json()
    return responseJson
}

export const PostStepFiles = async (formData) => {
    let { headers } = await GetHeaders(true)
    delete headers['Content-Type']
    let response = await fetch(`${API_ENDPOINT}/step-forms/uploadFile`, {
        method: 'POST',
        body: formData,
        headers,
    })

    const resp = await response
    let responseJson = resp.json()
    return responseJson
}

export const PostStepAnswerFiles = async (formData) => {
    let { headers } = await GetHeaders(true)
    delete headers['Content-Type']
    let response = await fetch(`${API_ENDPOINT}/step-assigned-answers`, {
        method: 'POST',
        body: formData,
        headers,
    })

    const resp = await response
    let responseJson = resp.json()
    return responseJson
}