import styled from 'styled-components'

export const ModalContainer = styled.div.attrs({})`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: var(--modalshadow-color);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;

    ${(props) =>
        props.left
            ? `
            justify-content: flex-start;
        `
            : ``}
`

export const ModalContent = styled.div.attrs({})`
    padding: 20px;

    border-radius: 15px;

    ${(props) =>
        props.colorized
            ? `
            background: rgb(87,121,142);
            background: linear-gradient(180deg, rgba(87,121,142,1) 0%, rgba(158,184,199,1) 100%);
            `
            : `background: var(--white-color);`}

    width: 100%;
    max-width: 560px;
    margin: 20px;
    ${(props) =>
        props.big
            ? `
            max-width: 820px;
        `
            : ``}

    ${(props) =>
        props.biggest
            ? `
            max-width: 1280px;
        `
            : ``}

    z-index:2;
    ${(props) =>
        props.left
            ? `
            margin: 0px;
            border-radius: 0 15px 15px 0;
            padding: 20px 20px 0;
            max-width: 440px;
            height: 100vh;
        `
            : ``}
`

export const ModalHeader = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const ModalHeaderSpace = styled.div.attrs({})`
    min-width: 16px;
`
export const ModalHeaderTitle = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;

    ${(props) => (props.colorized ? `color: #fff;` : `color: var(--grey-color);`)}
`
export const ModalHeaderClose = styled.img.attrs({
    src: '/icons/close.svg',
    width: 16,
})`
    cursor: pointer;

    ${(props) => (props.colorized ? `filter: brightness(100);` : ``)}
`

export const ModalBody = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 20px;
    max-height: 85vh;
    padding-right: 20px;
    overflow: auto;

    ${(props) =>
        props.left
            ? `
            max-height: calc(100vh - 70px);
            min-height: calc(100vh - 70px);
        `
            : ``}
`
