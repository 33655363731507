import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'

const borderLeft = '1px solid #263640'

const readBlackCss = { whiteSpace: 'pre-line', fontWeight: '', fontSize: '15px', color: '#565656' }
const divStile = { borderLeft: borderLeft, fontSize: '700', color: '#707070' }
const textAreaStyle = { marginBottom: '18px' }

export default function Card({
    item,
    onChangeCertifications,
    saveCertifications,
    saveDocument,
    certifications,
    form,
    mfsQuestionarie
}) {
    const { findlanguage } = useContext(CoreContext)
    const readGreenCss = {
        whiteSpace: 'pre-line',
        fontWeight: 'bold',
        fontSize: '15px',
        color: item.color,
        wordBreak: 'break-word',
    }
    return (
        <div className="row">
            <div className="col-1">
                <label className="form-label" style={readBlackCss}>
                    <div style={readGreenCss}>{item.ref}</div>
                </label>
            </div>
            <div className={mfsQuestionarie !== '1' ? "col-3 mb-3" : "col-8 mb-3"} style={divStile}>
                <label className="form-label" style={readBlackCss}>
                    <b>{item.requirementTitle}</b>
                    <br />
                    {item.requirementText}
                </label>
            </div>
            {item.question ? (
                <div className={mfsQuestionarie !== '1' ? "col-2 mb-3" : "col-3 mb-3"} style={divStile}>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={
                            certifications?.find((x) => x.category === item.ref)?.next_level ===
                            undefined
                                ? null
                                : certifications?.find((x) => x.category === item.ref)?.next_level
                        }
                        onChange={(event) =>
                            onChangeCertifications(
                                event.target.value === 'true',
                                item.ref,
                                'next_level'
                            )
                        }
                        disabled={form}
                    >
                        {certifications?.find((x) => x.category === item.ref)?.next_level ===
                        undefined ? (
                            <option value="">{findlanguage().selectAOption}</option>
                        ) : null}
                        <option value={false}>{findlanguage().no}</option>
                        <option value={true}>{findlanguage().yes}</option>
                    </select>
                </div>
            ) : null}
            {
                mfsQuestionarie !== '1' ? 

                    <div className={item.question ? 'col-5 mb-3' : 'col-7 mb-3'} style={divStile}>
                        <>
                            {certifications?.find((x) => x.category === item.ref)?.next_level === true &&
                                item.inputForYesAnswer ? (
                                <>
                                    <textarea
                                        className="form-control"
                                        placeholder={
                                            certifications?.find((x) => x.category === item.ref)
                                                ?.next_level === true
                                                ? item.placeHolderTextYes
                                                : item.placeHolderTextNo
                                        }
                                        style={textAreaStyle}
                                        value={
                                            certifications?.find((x) => x.category === item.ref)
                                                ?.next_level === undefined
                                                ? ''
                                                : certifications?.find((x) => x.category === item.ref)
                                                    ?.comments
                                        }
                                        onChange={(event) =>
                                            onChangeCertifications(event.target.value, item.ref, 'comments')
                                        }
                                        onBlur={() =>
                                            saveCertifications(
                                                certifications?.find((x) => x.category === item.ref)
                                            )
                                        }
                                    ></textarea>
                                    {item.uploadFile ? (
                                        <>
                                            {!form ??
                                                (certifications?.find((x) => x.category === item.ref)
                                                    ?.next_level !== undefined &&
                                                    certifications?.find((x) => x.category === item.ref)
                                                        ?.next_level !== false) ? (
                                                <>
                                                    {certifications?.find((x) => x.category === item.ref)
                                                        ?.attach_document === undefined ||
                                                        certifications?.find((x) => x.category === item.ref)
                                                            ?.attach_document === null ? (
                                                        <>
                                                            <label
                                                                for={item.ref}
                                                                style={{
                                                                    fontSize: '13px',
                                                                    color: 'white',
                                                                    height: '35px',
                                                                    padding: '10px',
                                                                    width: '100%',
                                                                    backgroundColor: '#263640',
                                                                    cursor: 'pointer',
                                                                }}
                                                                className="badge"
                                                            >
                                                                Open File
                                                            </label>
                                                            <input
                                                                type="file"
                                                                id={item.ref}
                                                                onChange={() => saveDocument(item.ref)}
                                                                style={{ display: 'none' }}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <label
                                                                for={item.ref}
                                                                style={{
                                                                    fontSize: '13px',
                                                                    color: 'white',
                                                                    height: '35px',
                                                                    padding: '10px',
                                                                    width: '100%',
                                                                    backgroundColor: '#263640',
                                                                    cursor: 'pointer',
                                                                }}
                                                                className="badge"
                                                            >
                                                                Change File
                                                            </label>

                                                            <div
                                                                style={{
                                                                    marginTop: '6px',
                                                                    textAlign: 'center',
                                                                }}
                                                            >
                                                                {
                                                                    certifications?.find(
                                                                        (x) => x.category === item.ref
                                                                    )?.attach_document?.name
                                                                }
                                                            </div>
                                                            <input
                                                                type="file"
                                                                id={item.ref}
                                                                onChange={() => saveDocument(item.ref)}
                                                                style={{ display: 'none' }}
                                                            />
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <div style={{ textAlign: 'center' }}>
                                                    {
                                                        certifications?.find((x) => x.category === item.ref)
                                                            ?.attach_document?.name
                                                    }
                                                </div>
                                            )}
                                        </>
                                    ) : null}
                                </>
                            ) : certifications?.find((x) => x.category === item.ref)?.next_level ===
                                true ? (
                                item.placeHolderTextYes
                            ) : null}
                            {certifications?.find((x) => x.category === item.ref)?.next_level === true &&
                                item.fileForYesAnswerAndNoInput ? (
                                <>
                                    <>
                                        {!form ??
                                            (certifications?.find((x) => x.category === item.ref)
                                                ?.next_level !== undefined &&
                                                certifications?.find((x) => x.category === item.ref)
                                                    ?.next_level !== false) ? (
                                            <>
                                                {certifications?.find((x) => x.category === item.ref)
                                                    ?.attach_document === undefined ||
                                                    certifications?.find((x) => x.category === item.ref)
                                                        ?.attach_document === null ? (
                                                    <>
                                                        <label
                                                            for={item.ref}
                                                            style={{
                                                                fontSize: '13px',
                                                                color: 'white',
                                                                height: '35px',
                                                                padding: '10px',
                                                                width: '100%',
                                                                backgroundColor: '#263640',
                                                                cursor: 'pointer',
                                                            }}
                                                            className="badge"
                                                        >
                                                            Open File
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id={item.ref}
                                                            onChange={() => saveDocument(item.ref)}
                                                            style={{ display: 'none' }}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <label
                                                            for={item.ref}
                                                            style={{
                                                                fontSize: '13px',
                                                                color: 'white',
                                                                height: '35px',
                                                                padding: '10px',
                                                                width: '100%',
                                                                backgroundColor: '#263640',
                                                                cursor: 'pointer',
                                                            }}
                                                            className="badge"
                                                        >
                                                            Change File
                                                        </label>

                                                        <div
                                                            style={{
                                                                marginTop: '6px',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            {
                                                                certifications?.find(
                                                                    (x) => x.category === item.ref
                                                                )?.attach_document?.name
                                                            }
                                                        </div>
                                                        <input
                                                            type="file"
                                                            id={item.ref}
                                                            onChange={() => saveDocument(item.ref)}
                                                            style={{ display: 'none' }}
                                                        />
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <div style={{ textAlign: 'center' }}>
                                                {
                                                    certifications?.find((x) => x.category === item.ref)
                                                        ?.attach_document?.name
                                                }
                                            </div>
                                        )}
                                    </>
                                </>
                            ) : null}
                            {certifications?.find((x) => x.category === item.ref)?.next_level === false &&
                                item.inputForNoAnswer ? (
                                <>
                                    <textarea
                                        className="form-control"
                                        placeholder={
                                            certifications?.find((x) => x.category === item.ref)
                                                ?.next_level === true
                                                ? item.placeHolderTextYes
                                                : item.placeHolderTextNo
                                        }
                                        style={textAreaStyle}
                                        value={
                                            certifications?.find((x) => x.category === item.ref)
                                                ?.next_level === undefined
                                                ? ''
                                                : certifications?.find((x) => x.category === item.ref)
                                                    ?.comments
                                        }
                                        onChange={(event) =>
                                            onChangeCertifications(event.target.value, item.ref, 'comments')
                                        }
                                        onBlur={() =>
                                            saveCertifications(
                                                certifications?.find((x) => x.category === item.ref)
                                            )
                                        }
                                    ></textarea>
                                    {item.uploadFile ? (
                                        <>
                                            {!form ??
                                                (certifications?.find((x) => x.category === item.ref)
                                                    ?.next_level !== undefined &&
                                                    certifications?.find((x) => x.category === item.ref)
                                                        ?.next_level !== false) ? (
                                                <>
                                                    {certifications?.find((x) => x.category === item.ref)
                                                        ?.attach_document === undefined ||
                                                        certifications?.find((x) => x.category === item.ref)
                                                            ?.attach_document === null ? (
                                                        <>
                                                            <label
                                                                for={item.ref}
                                                                style={{
                                                                    fontSize: '13px',
                                                                    color: 'white',
                                                                    height: '35px',
                                                                    padding: '10px',
                                                                    width: '100%',
                                                                    backgroundColor: '#263640',
                                                                    cursor: 'pointer',
                                                                }}
                                                                className="badge"
                                                            >
                                                                Open File
                                                            </label>
                                                            <input
                                                                type="file"
                                                                id={item.ref}
                                                                onChange={() => saveDocument(item.ref)}
                                                                style={{ display: 'none' }}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <label
                                                                for={item.ref}
                                                                style={{
                                                                    fontSize: '13px',
                                                                    color: 'white',
                                                                    height: '35px',
                                                                    padding: '10px',
                                                                    width: '100%',
                                                                    backgroundColor: '#263640',
                                                                    cursor: 'pointer',
                                                                }}
                                                                className="badge"
                                                            >
                                                                Change File
                                                            </label>

                                                            <div
                                                                style={{
                                                                    marginTop: '6px',
                                                                    textAlign: 'center',
                                                                }}
                                                            >
                                                                {
                                                                    certifications?.find(
                                                                        (x) => x.category === item.ref
                                                                    )?.attach_document?.name
                                                                }
                                                            </div>
                                                            <input
                                                                type="file"
                                                                id={item.ref}
                                                                onChange={() => saveDocument(item.ref)}
                                                                style={{ display: 'none' }}
                                                            />
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <div style={{ textAlign: 'center' }}>
                                                    {
                                                        certifications?.find((x) => x.category === item.ref)
                                                            ?.attach_document?.name
                                                    }
                                                </div>
                                            )}
                                        </>
                                    ) : null}
                                </>
                            ) : certifications?.find((x) => x.category === item.ref)?.next_level ===
                                false ? (
                                item.placeHolderTextNo
                            ) : null}
                            {item.question === false && item.inputForNoAnswer ? (
                                <>
                                    <textarea
                                        className="form-control"
                                        placeholder={
                                            certifications?.find((x) => x.category === item.ref)
                                                ?.next_level === true
                                                ? item.placeHolderTextYes
                                                : item.placeHolderTextNo
                                        }
                                        style={textAreaStyle}
                                        value={
                                            certifications?.find((x) => x.category === item.ref)
                                                ?.next_level === undefined
                                                ? ''
                                                : certifications?.find((x) => x.category === item.ref)
                                                    ?.comments
                                        }
                                        onChange={(event) =>
                                            onChangeCertifications(event.target.value, item.ref, 'comments')
                                        }
                                        onBlur={() =>
                                            saveCertifications(
                                                certifications?.find((x) => x.category === item.ref)
                                            )
                                        }
                                    ></textarea>
                                </>
                            ) : null}
                        </>
                    </div>
                    :
                    null
            }
        </div>
    )
}
