import { DELETE, GET, POST, PUT } from './api'

export const GetSteps = async () => {
    return await GET(`/step-forms/forms`, true)
}

export const GetFullStep = async (id) => {
    return await GET(`/step-forms/fullstep?id=${id}`, true)
}

export const PostSteps = async (body) => {
    return await POST(`/step-forms`, body, true)
}

export const PutStepsForm = async (body) => {
    return await PUT(`/step-forms/${body.id}`, body, true)
}

export const PutStepsFormComplete = async (body) => {
    return await PUT(`/step-forms/changeForm/${body.id}`, body, true)
}

export const PostAssignedStepForm = async (body) => {
    return await POST(`/step-assigned-to-companies`, body, true)
}

export const GetAssignedStepForm = async () => {
    return await GET(`/step-assigned-to-companies/findForm`, true)
}

export const GetSectionAndElements = async (sectionId, stepId) => {
    const url = stepId
        ? `/step-assigned-to-users/findSection/${sectionId}/${stepId}`
        : `/step-assigned-to-users/findSection/${sectionId}`;
    return await GET(url, true);
}

export const RejectSection = async (body) => {
    return await POST(`/step-assigned-to-users/rejectSection`, body, true)
}

export const ApproveLastSectionSection = async (body) => {
    return await POST(`/step-assigned-to-users/approveLastSection`, body, true)
}

export const ApproveSection = async (body) => {
    return await POST(`/step-assigned-to-users/approveSection`, body, true)
}

export const PostAssignedStepAnswers = async (body) => {
    return await POST(`/step-assigned-answers`, body, true)
}

export const GetAssignedStepFormOwner = async () => {
    return await GET(`/step-assigned-to-companies/findFormOwner`, true)
}