import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { Title, Text } from './styled'

import Button from 'components/Form/Button'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

export default function ModalContactSales() {
    const { setModal, findlanguage } = useContext(CoreContext)

    const history = useHistory()
    const navigate = (to) => {
        history.push(`/${to}`)
        close()
    }

    const close = () => {
        setModal(null)
    }

    return (
        <>
            <ModalWrapper colorized={true}>
                <Title>{findlanguage().salesTitle}</Title>

                <Text>{findlanguage().salesText}</Text>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '30px',
                        marginBottom: '50px',
                        marginLeft: '15px',
                    }}
                >
                    <Button whiteAndBlack onClick={() => close()}>
                        {findlanguage().planButtonStay}
                    </Button>

                    <Button whiteAndBlack onClick={() => navigate('dashboard/account-premium')}>
                        {findlanguage().trialButtonChoose}
                    </Button>
                </div>
            </ModalWrapper>
        </>
    )
}
