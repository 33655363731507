import React, { useEffect, useState, useContext } from 'react'
import { Doughnut } from 'react-chartjs-2'
import 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import DashboardPanel from 'components/Dashboard/Panels/Wrapper'
import { FetchCategoriesChart } from 'services/dashboard'
import { Load } from 'ui/styled'
import { CoreContext } from 'context/CoreContext'

export default function DashboardPanelClientsPerBusiness({ cardTitle, onClose }) {
    const { premium, language, findlanguage } = useContext(CoreContext)
    const panel = {
        title: findlanguage().clientsPerBusiness,
        actions: [],
        onClose,
    }

    const [chartData, setChartData] = useState(null)
    const [chartOptions, setChartOptions] = useState(null)
    const [loading, setLoading] = useState(false)

    const checkAccess = () => premium.type === 'free'

    const loadClientsPerBusiness = () => {
        setLoading(true)

        FetchCategoriesChart().then((chart) => {
            if (!chart || chart.length === 0) {
                setChartData(null)
            } else {
                const labels = []
                const data = []
                const backgroundColors = [
                    '#93C16D',
                    '#542D74',
                    '#174E35',
                    '#6DC29B',
                    '#742D4D',
                    '#9B6DC2',
                    '#C26D93',
                    '#35174E',
                    '#304E17',
                    '#270816',
                    '#6E9B49',
                    '#4D742D',
                    '#162708',
                    '#499B76',
                    '#2D7454',
                    '#082719',
                    '#76499B',
                    '#190827',
                    '#9B496E',
                    '#4E1730',
                ]

                chart.forEach((entry, i) => {
                    labels.push(language === 'portugues' ? entry.category_pt : entry.category)
                    data.push(parseInt(entry.count))
                })

                setChartData({
                    labels: labels,
                    datasets: [
                        {
                            data: data,
                            backgroundColor: backgroundColors.slice(0, data.length),
                            borderColor: '#ffffff',
                            borderWidth: 2,
                        },
                    ],
                })

                setChartOptions({
                    plugins: {
                        legend: {
                            position: 'right',
                            labels: {
                                font: {
                                    size: 12,
                                    family: 'Open Sans',
                                    weight: 'bold',
                                },
                                color: '#303030',
                            },
                            align: 'center',
                        },
                        datalabels: {
                            display: true,
                            color: 'white',
                            font: {
                                size: 16,
                                family: 'Open Sans',
                                weight: 'bold',
                            },
                            formatter: (value) => value,
                        },
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                })
            }

            setLoading(false)
        })
    }

    useEffect(() => {
        if (!checkAccess()) loadClientsPerBusiness()
    }, [])

    return (
        <>
            {loading ? (
                <Load color="#263640" />
            ) : (
                <DashboardPanel {...panel} subscription={premium?.type} locked={checkAccess()}>
                    {!chartData?.datasets?.length ? (
                        <div style={{ width: '100%' }}>No clients found at this moment.</div>
                    ) : (
                        <div style={{ width: '95%', height: '270px' }}>
                            <Doughnut
                                data={chartData}
                                options={chartOptions}
                                plugins={[ChartDataLabels]}
                            />
                        </div>
                    )}
                </DashboardPanel>
            )}
        </>
    )
}
