import styled from 'styled-components'

export const HeaderContainer = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 35px 0 0;
`
export const HeaderBackIcon = styled.img.attrs({
    src: '/icons/back.svg',
})`
    width: 21px;
    height: 20px;
`

export const HeaderContent = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const HeaderTitle = styled.div.attrs({})`
    color: var(--grey-color);
    font-size: 25px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-align: center;
`
export const HeaderActionIcon = styled.img.attrs({
    src: '/icons/chat.svg',
})`
    width: 25px;
    height: 25px;
`

export const HeaderActionSpace = styled.div.attrs({})`
    width: 50px;
    ${(props) =>
        props.bigger
            ? `
            width: 171px;
        `
            : ``}
`
