import { GET, POST, PUT } from './api'

export const FetchAllPlans = async (plan) => {
    const data = await GET(`/pricing-plans?plan=${plan}`, true)
    return data
}

export const FetchPlanIncludes = async () => {
    return await GET(`/pricing-plans-includes?_sort=order:asc`, true)
}

export const SubscribeToPremium = async (type, body) => {
    return await POST(`/subscribe/${type}`, body, true)
}

export const RenewSubscribe = async (body) => {
    return await PUT(`/subscribe/renew`, body, true)
}

export const StripePaymentConfig = async (body) => {
    return await GET(`/payment-gateway/config`, body, true)
}

export const StripePaymentIntent = async (body) => {
    return await POST(`/payment-gateway/create-payment-intent`, body, true)
}

export const StripeGetPaymentIntent = async (id) => {
    return await GET(`/payment-gateway/${id}`, {}, true)
}
