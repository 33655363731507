import React, { useEffect, useState } from 'react'
import { PageCard } from '../../../../../Panels/SMSControl/styled'

import { TitleGreen, CardDocument } from '../styled'

import InputMask from 'react-input-mask'
import DashboardButton from 'components/Dashboard/Button'
import {
    GetAdditionalInformation,
    PutAdditionalInformation,
    PostAdditionalInformation,
} from 'services/egs'
import { ReadMe } from 'services/authentication'

import { UpdateAdditionalInformation } from 'services/api'

const labelCss = { fontWeight: 'bold', fontSize: '15px', color: 'rgb(86, 86, 86)' }
const labelTextCss = { fontSize: '14px', color: 'rgb(86, 86, 86)' }
const inputCss = { borderColor: '#70707059' }
const col6 = { fontSize: '700', color: '#707070' }
const spacer = { borderTop: '0.1px solid rgb(235 235 235)' }
const doneTypingInterval = 2000

let typingTimer

export default function Additionalnformation({ form }) {
    const [additionalInformation, setAdditionalInformation] = useState({
        id: -1,
        did_company_audit: false,
        company_hired: '',
        standard: '',
        standard_second: '',
        acreditation_body: '',
        standard_third: '',
        federal_audit_file: {},
        first_party_file: {},
        second_party_file: {},
        thirdy_party_file: {},
    })

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        waitUserFinishTyping()
    }, [additionalInformation])

    const init = async () => {
        const readMe = await ReadMe()

        const getAdditionalInformation = await GetAdditionalInformation(readMe.company_id)

        if (
            getAdditionalInformation.statusText !== 'Not Found' &&
            getAdditionalInformation?.length !== 0
        )
            setAdditionalInformation(getAdditionalInformation[0])
        else
            setAdditionalInformation(
                await PostAdditionalInformation({ users_permissions_user: readMe.company_id })
            )
    }

    function changeQuestionaire(value, field) {
        setAdditionalInformation({
            ...additionalInformation,
            [field]: value,
        })

        waitUserFinishTyping()
    }

    function changeQuestionaireTrueFalse(value) {
        setAdditionalInformation({
            id: additionalInformation.id,
            did_company_audit: value === 'true',
            company_hired: '',
            standard: '',
            standard_second: '',
            acreditation_body: '',
            standard_third: '',
            federal_audit_file: {},
            first_party_file: {},
            second_party_file: {},
            thirdy_party_file: {},
        })

        PutAdditionalInformation({
            id: additionalInformation.id,
            did_company_audit: value === 'true',
            company_hired: '',
            standard: '',
            standard_second: '',
            acreditation_body: '',
            standard_third: '',
            federal_audit_file: null,
            first_party_file: null,
            second_party_file: null,
            thirdy_party_file: null,
        })
    }

    function waitUserFinishTyping() {
        clearTimeout(typingTimer)
        typingTimer = setTimeout(saveAdditionalnformation, doneTypingInterval)
    }

    const saveAdditionalnformation = async () => {
        if (additionalInformation.id !== -1) await PutAdditionalInformation(additionalInformation)
    }

    const saveDocument = async (fileName) => {
        await PutAdditionalInformation({
            ...additionalInformation,
            [fileName]: null,
        })

        const formData = new FormData()

        if (document.getElementById(fileName)?.files?.[0]) {
            new Promise((resolve, reject) => {
                let reader = new FileReader()
                reader.onload = async () => {
                    let source = `${reader.result}`.split(',')[1]
                    let image = {
                        filename: document.getElementById(fileName)?.files?.[0].name,
                        filetype: document.getElementById(fileName)?.files?.[0].type,
                        source,
                    }

                    let btc = window.atob(image.source)
                    let btn = new Array(btc.length)
                    for (var i = 0; i < btc.length; i++) {
                        btn[i] = btc.charCodeAt(i)
                    }
                    var bta = new Uint8Array(btn)
                    let boobs = new Blob([bta], { type: 'image/png' })
                    let filesss = new File([boobs], `${image.filename}`)

                    formData.append(fileName, filesss, filesss.name)
                    formData.append('id', additionalInformation.id)
                    formData.append('filename', fileName)

                    await UpdateAdditionalInformation(formData)

                    setAdditionalInformation({
                        ...additionalInformation,
                        [fileName]: document.getElementById(fileName)?.files,
                    })
                }
                reader.readAsDataURL(document.getElementById(fileName)?.files?.[0])
            })
        }
    }

    return (
        <PageCard>
            <TitleGreen>Additional Information</TitleGreen>
            <div className="row  mb-3">
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        Did the company have any type of audit within the last 12 months?
                    </label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={additionalInformation.did_company_audit}
                        onChange={(event) => changeQuestionaireTrueFalse(event.target.value)}
                        disabled={form}
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>
                </div>
            </div>
            {additionalInformation.did_company_audit ? (
                <>
                    <div className="row">
                        <div className="col-12" style={col6}>
                            <label className="form-label" style={labelCss}>
                                Federal Audit:
                            </label>
                            <br />
                            <label className="form-label" style={labelTextCss}>
                                In US, For Human Food PC Rule, Part 117/ For Produce Safety Rule,
                                Part 112 of USDA - Code of Federal Regulations (CFR)
                            </label>
                        </div>
                        <div className="col-4" style={col6}>
                            {!form ? (
                                <>
                                    {additionalInformation?.federal_audit_file === undefined ||
                                    additionalInformation?.federal_audit_file?.length === 0 ? (
                                        <>
                                            <label
                                                for="federal_audit_file"
                                                style={{
                                                    fontSize: '13px',
                                                    color: 'white',
                                                    height: '35px',
                                                    padding: '10px',
                                                    width: '100%',
                                                    backgroundColor: '#263640',
                                                    cursor: 'pointer',
                                                }}
                                                className="badge"
                                            >
                                                Open File
                                            </label>
                                            <input
                                                type="file"
                                                id="federal_audit_file"
                                                onChange={() => saveDocument('federal_audit_file')}
                                                style={{ display: 'none' }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <label
                                                for="federal_audit_file"
                                                style={{
                                                    fontSize: '13px',
                                                    color: 'white',
                                                    height: '35px',
                                                    padding: '10px',
                                                    width: '100%',
                                                    backgroundColor: '#263640',
                                                    cursor: 'pointer',
                                                }}
                                                className="badge"
                                            >
                                                Change File
                                            </label>

                                            <div>
                                                {additionalInformation?.federal_audit_file[0]?.name}
                                            </div>
                                            <input
                                                type="file"
                                                id="federal_audit_file"
                                                onChange={() => saveDocument('federal_audit_file')}
                                                style={{ display: 'none' }}
                                            />
                                        </>
                                    )}
                                </>
                            ) : (
                                <div style={{ marginTop: '6px', textAlign: 'center' }}>
                                    {additionalInformation?.federal_audit_file[0]?.name}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mb-3" style={spacer}></div>
                    <div className="row">
                        <div className="col-12" style={col6}>
                            <label className="form-label" style={labelCss}>
                                1st Party - Internal Audit
                            </label>
                            <br />
                            <label className="form-label" style={labelTextCss}>
                                Note: A first-party audit occurs when an audit is performed within
                                your organization by your own auditing resource. This is often
                                called an internal audit.
                            </label>
                        </div>
                    </div>
                    <div className="row  mb-3" style={{ borderBottom: '1px solid #7070700f' }}>
                        <div className="col-4" style={col6}>
                            <label className="form-label" style={labelCss}>
                                Company Hired:
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                style={inputCss}
                                value={additionalInformation.company_hired}
                                onChange={(event) =>
                                    changeQuestionaire(event.target.value, 'company_hired', true)
                                }
                                disabled={form}
                            />
                        </div>
                        <div className="col-4" style={col6}>
                            <label className="form-label" style={labelCss}>
                                Standard:
                            </label>
                            <InputMask
                                type="text"
                                className="form-control"
                                style={inputCss}
                                value={additionalInformation.standard}
                                onChange={(event) =>
                                    changeQuestionaire(event.target.value, 'standard', true)
                                }
                                disabled={form}
                            />
                        </div>
                        <div className="col-4" style={col6}>
                            {!form ? (
                                <>
                                    {additionalInformation?.first_party_file === undefined ||
                                    additionalInformation?.first_party_file?.length === 0 ? (
                                        <>
                                            <label
                                                for="first_party_file"
                                                style={{
                                                    fontSize: '13px',
                                                    color: 'white',
                                                    height: '35px',
                                                    padding: '10px',
                                                    marginTop: '32px',
                                                    width: '100%',
                                                    backgroundColor: '#263640',
                                                    cursor: 'pointer',
                                                }}
                                                className="badge"
                                            >
                                                Open File
                                            </label>
                                            <input
                                                type="file"
                                                id="first_party_file"
                                                onChange={() => saveDocument('first_party_file')}
                                                style={{ display: 'none' }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <label
                                                for="first_party_file"
                                                style={{
                                                    fontSize: '13px',
                                                    color: 'white',
                                                    height: '35px',
                                                    padding: '10px',
                                                    marginTop: '32px',
                                                    width: '100%',
                                                    backgroundColor: '#263640',
                                                    cursor: 'pointer',
                                                }}
                                                className="badge"
                                            >
                                                Change File
                                            </label>
                                            <div style={{ marginTop: '6px', textAlign: 'center' }}>
                                                {additionalInformation?.first_party_file[0]?.name}
                                            </div>
                                            <input
                                                type="file"
                                                id="first_party_file"
                                                onChange={() => saveDocument('first_party_file')}
                                                style={{ display: 'none' }}
                                            />
                                        </>
                                    )}
                                </>
                            ) : (
                                <div style={{ marginTop: '36px' }}>
                                    {additionalInformation?.first_party_file[0]?.name}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mb-3" style={spacer}></div>
                    <div className="row">
                        <div className="col-12" style={col6}>
                            <label className="form-label" style={labelCss}>
                                2nd Party - Supplier Control
                            </label>
                            <br />
                            <label className="form-label" style={labelTextCss}>
                                Note: A second-party audit is performed by a supplier, customer, or
                                contractor, often against their proprietary requirements. (Costco,
                                Walmart, Yum Brands, Etc..)
                            </label>
                        </div>
                    </div>
                    <div className="row  mb-3">
                        <div className="col-4" style={col6}>
                            <label className="form-label" style={labelCss}>
                                Standard:
                            </label>
                            <InputMask
                                type="text"
                                className="form-control"
                                style={inputCss}
                                value={additionalInformation.standard_second}
                                onChange={(event) =>
                                    changeQuestionaire(event.target.value, 'standard_second', true)
                                }
                                disabled={form}
                            />
                        </div>
                        <div className="col-4" style={col6}>
                            {!form ? (
                                <>
                                    {additionalInformation?.second_party_file === undefined ||
                                    additionalInformation?.second_party_file?.length === 0 ? (
                                        <>
                                            <label
                                                for="second_party_file"
                                                style={{
                                                    fontSize: '13px',
                                                    color: 'white',
                                                    height: '35px',
                                                    padding: '10px',
                                                    marginTop: '32px',
                                                    width: '100%',
                                                    backgroundColor: '#263640',
                                                    cursor: 'pointer',
                                                }}
                                                className="badge"
                                            >
                                                Open File
                                            </label>
                                            <input
                                                type="file"
                                                id="second_party_file"
                                                onChange={() => saveDocument('second_party_file')}
                                                style={{ display: 'none' }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <label
                                                for="second_party_file"
                                                style={{
                                                    fontSize: '13px',
                                                    color: 'white',
                                                    height: '35px',
                                                    padding: '10px',
                                                    marginTop: '32px',
                                                    width: '100%',
                                                    backgroundColor: '#263640',
                                                    cursor: 'pointer',
                                                }}
                                                className="badge"
                                            >
                                                Change File
                                            </label>
                                            <div style={{ marginTop: '6px', textAlign: 'center' }}>
                                                {additionalInformation?.second_party_file[0]?.name}
                                            </div>
                                            <input
                                                type="file"
                                                id="second_party_file"
                                                onChange={() => saveDocument('second_party_file')}
                                                style={{ display: 'none' }}
                                            />
                                        </>
                                    )}
                                </>
                            ) : (
                                <div style={{ marginTop: '36px' }}>
                                    {additionalInformation?.second_party_file[0]?.name}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mb-3" style={spacer}></div>
                    <div className="row">
                        <div className="col-6" style={col6}>
                            <label className="form-label" style={labelCss}>
                                3rd Party - Certification Audit / Federal Audit
                            </label>
                            <label className="form-label" style={labelTextCss}>
                                Note1: A third-party audit is performed by an independent body
                                (i.e., a registrar such as assessor's) against a recognized standard
                                (SQF, BRCGS, PrimusGFS, ISO 22000, etc..)
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4" style={col6}>
                            <label className="form-label" style={labelCss}>
                                Acreditation Body:
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                style={inputCss}
                                value={additionalInformation.acreditation_body}
                                onChange={(event) =>
                                    changeQuestionaire(
                                        event.target.value,
                                        'acreditation_body',
                                        true
                                    )
                                }
                                disabled={form}
                            />
                        </div>
                        <div className="col-4" style={col6}>
                            <label className="form-label" style={labelCss}>
                                Standard:
                            </label>
                            <InputMask
                                type="text"
                                className="form-control"
                                style={inputCss}
                                value={additionalInformation.standardThird}
                                onChange={(event) =>
                                    changeQuestionaire(event.target.value, 'standardThird', true)
                                }
                                disabled={form}
                            />
                        </div>
                        <div className="col-4" style={col6}>
                            {!form ? (
                                <>
                                    {additionalInformation?.thirdy_party_file === undefined ||
                                    additionalInformation?.thirdy_party_file?.length === 0 ? (
                                        <>
                                            <label
                                                for="thirdy_party_file"
                                                style={{
                                                    fontSize: '13px',
                                                    color: 'white',
                                                    height: '35px',
                                                    padding: '10px',
                                                    marginTop: '32px',
                                                    width: '100%',
                                                    backgroundColor: '#263640',
                                                    cursor: 'pointer',
                                                }}
                                                className="badge"
                                            >
                                                Open File
                                            </label>
                                            <input
                                                type="file"
                                                id="thirdy_party_file"
                                                onChange={() => saveDocument('thirdy_party_file')}
                                                style={{ display: 'none' }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <label
                                                for="thirdy_party_file"
                                                style={{
                                                    fontSize: '13px',
                                                    color: 'white',
                                                    height: '35px',
                                                    padding: '10px',
                                                    marginTop: '32px',
                                                    width: '100%',
                                                    backgroundColor: '#263640',
                                                    cursor: 'pointer',
                                                }}
                                                className="badge"
                                            >
                                                Change File
                                            </label>
                                            <div style={{ marginTop: '6px', textAlign: 'center' }}>
                                                {additionalInformation?.thirdy_party_file[0]?.name}
                                            </div>
                                            <input
                                                type="file"
                                                id="thirdy_party_file"
                                                onChange={() => saveDocument('thirdy_party_file')}
                                                style={{ display: 'none' }}
                                            />
                                        </>
                                    )}
                                </>
                            ) : (
                                <div style={{ marginTop: '36px' }}>
                                    {additionalInformation?.thirdy_party_file[0]?.name}
                                </div>
                            )}
                        </div>
                    </div>
                </>
            ) : null}
        </PageCard>
    )
}
