import React, { useContext, useState } from 'react'

import { ProfileFormActions, PageFormSpace } from './styled'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

export default function ModalReviewSupplier() {
    const { modal, setModal } = useContext(CoreContext)
    const { setModal2, user, findlanguage } = useContext(CoreContext)
    const labelCss = { fontWeight: 'bold', fontSize: '15px', color: 'rgb(86, 86, 86)' }
    const inputCss = { borderColor: '#70707059' }
    const [inputFields, setInputFields] = useState({ reason: '', buyerResponse: '' })

    const changeValue = (key, value) => {
        setInputFields({ ...inputFields, [key]: value })
    }

    return (
        <>
            <ModalWrapper>
                <label className="form-label" style={labelCss}>
                    {findlanguage().chooseAReason}
                </label>
                <select
                    className="form-select"
                    aria-label="Default select example"
                    value={inputFields.reason}
                    onChange={(event) => changeValue('reason', event.target.value)}
                >
                    <option value="">{findlanguage().selectAOption}</option>
                    <option value="Missing requirements">
                        {findlanguage().missingRequirements}
                    </option>
                    <option value="Invalid Document">{findlanguage().invalidDocument}</option>
                    <option value="Incomplete Document">{findlanguage().incompleteDocument}</option>
                    <option value="Corrupt Document">{findlanguage().corruptDocument}</option>
                </select>
                <br />
                <label className="form-label" style={labelCss}>
                    {findlanguage().typerResponseSupplier}
                </label>
                <textarea
                    type="text"
                    className="form-control"
                    style={inputCss}
                    value={inputFields.buyerResponse}
                    onChange={(event) => changeValue('buyerResponse', event.target.value)}
                />
                <PageFormSpace />
                <br />
                <ProfileFormActions>
                    <button
                        style={{
                            marginRight: '5px',
                            backgroundColor: '#db3939',
                            border: '1px solid #db3939',
                        }}
                        type="button"
                        className="btn btn-success"
                        onClick={() =>
                            modal.saveForm(false, inputFields.reason, inputFields.buyerResponse)
                        }
                    >
                        {findlanguage().decline}
                    </button>
                </ProfileFormActions>
            </ModalWrapper>
        </>
    )
}
