import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import './style.css';

const FarmersMarketLocator = () => {
    const [map, setMap] = useState(null);
    const [infowindow, setInfowindow] = useState(null);
    const mapRef = useRef(null);
    const bigIcon = {
        url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
        scaledSize: new window.google.maps.Size(60, 60) // Sets the size of the icon
    };
    const [mapHeight, setMapHeight] = useState("500px");

    useEffect(() => {
        updateMapHeight();
        if (window.google) {
            const initMap = () => {
                const gmap = new window.google.maps.Map(mapRef.current, {
                    center: { lat: 40.9, lng: -97.2 },
                    zoom: 4,
                    mapTypeId: "roadmap",
                });

                setInfowindow(new window.google.maps.InfoWindow());

                // Event listener for when the map dragging ends
                gmap.addListener("dragend", () => {
                    const center = gmap.getCenter();
                    fetchFarmersMarkets(center.lat(), center.lng(), gmap);
                });

                gmap.addListener("zoom_changed", () => {
                    const center = gmap.getCenter();
                    fetchFarmersMarkets(center.lat(), center.lng(), gmap);
                });

                setMap(gmap);
                fetchFarmersMarkets(40.9, -97.2, gmap); // Fetch initial set of markets
            };

            initMap(); // Initialize map

            window.addEventListener("resize", updateMapHeight);

            return () => {
                window.removeEventListener("resize", updateMapHeight);
            };
        }
    }, []);

    function createCustomMarkerIcon(imageUrl) {
        return {
            path: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z", // this is a circle path for the SVG
            fillColor: '#FFFFFF',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 2.5,
            anchor: new window.google.maps.Point(12, 12),
            url: `data:image/svg+xml;utf-8, \
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"> \
                <circle cx="20" cy="20" r="15" stroke="black" stroke-width="2" fill="white" /> \
                <image x="5" y="5" width="30" height="30" xlink:href="${imageUrl}" /> \
            </svg>`
        };
    }


    const fetchFarmersMarkets = (lat, lng, gmap) => {
        const placesService = new window.google.maps.places.PlacesService(gmap);

        const request = {
            location: new window.google.maps.LatLng(lat, lng),
            radius: '50000',
            keyword: 'farmers+market'
        };

        placesService.nearbySearch(request, (results, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                results.forEach(market => {
                    const {
                        name,
                        vicinity,
                        rating,
                        user_ratings_total,
                        opening_hours,
                        photos
                    } = market;

                    const photoUrl = photos && photos.length > 0 ? photos[0].getUrl() : null;

                    const marker = new window.google.maps.Marker({
                        icon: photoUrl ? createCustomMarkerIcon(photoUrl) : bigIcon,
                        position: { lat: market.geometry.location.lat(), lng: market.geometry.location.lng() },
                        map: gmap,
                        title: name
                    });

                    const isOpen = opening_hours ? (opening_hours.isOpen ? "Open Now" : "Closed") : "Opening hours not available";

                    marker.html = `
        <div class="markerPop">
            <h1>${name}</h1>
            <img src="${photoUrl}" alt="${name}" width="300" /> <!-- Show business photo -->
            <h2>${vicinity}</h2>
            <h3>Rating: ${rating} (${user_ratings_total} reviews)</h3>
            <p>${isOpen}</p>
        </div>
    `;

                    window.google.maps.event.addListener(marker, "click", function () {
                        infowindow.setContent(this.html);
                        infowindow.open(gmap, this);
                    });
                });
            }
        });
    };


    const updateMapHeight = () => {
        const contentWrapper = document.querySelector('.content-wrapper');
        if (contentWrapper) {
            setMapHeight(`${contentWrapper.offsetHeight - 120}px`);
        }
    };



    return (
        <div className="container-fluid">
            <div ref={mapRef} id="google_map" style={{ width: "100%", height: mapHeight }}></div>
        </div>
    );
};

export default FarmersMarketLocator;