import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const Content = styled.div.attrs({})``

export const PageHeader = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 20px;
`
export const PageHeaderTitle = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: var(--grey-color);
`
export const PageHeaderActions = styled.div.attrs({})`
    display: flex;
    align-items: center;
`

export const PageCard = styled.div.attrs({})`
    padding: 24px;
    background: var(--white-color);

    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
    box-shadow: 0px 1.5px 6px var(--shadow-color);
    margin-bottom: 20px;
`
export const ProfileContainer = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
`
export const ProfileContent = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const ProfileContentSpace = styled.div.attrs({})`
    padding: 10px;
`
export const ProfileFormContent = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: space-evenly;
`

export const ProfileFormActions = styled.div.attrs({})`
    flex: 1;
    min-width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px;
`

export const PageScroller = styled.div.attrs({})`
    margin-top: 20px;
    height: 260px;
    overflow: auto;
`

export const InputSpace = styled.div.attrs({})`
    width: 350px;
`

export const CertificateContent = styled.div.attrs({})`
    display: flex;
    padding: 32px 0;
    gap: 0 12px;
`
export const CertificateItem = styled.div.attrs({})`
    width: 93px;
    height: 93px;

    background: url(/icons/certificate-${(props) => props.certificate}.svg) no-repeat center center /
        contain;
`

export const ProductItem = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background: var(--backgroundgrey-color);
    border-radius: 6px;
    width: 48%;
`
export const ProductItemSection = styled.div.attrs({})`
    padding: 23px;
    display: flex;
    align-items: center;
    gap: 0 12px;

    ${(props) =>
        props.width
            ? `width: ${props.width}`
            : `
        `}
`
export const ProductItemIcon = styled.img.attrs((props) => ({
    src: `/icons/${props.icon}.svg`,
    width: 25,
}))``
export const ProductItemText = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 17px;
    color: var(--grey-color);
    width: 280px;
`
export const ProductItemAction = styled.div.attrs({})`
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: var(--grey-color);

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`
export const ProductItemActionIcon = styled.img.attrs((props) => ({
    src: `/icons/${props.icon}`,
    width: 14,
}))``

export const PageContent = styled.div.attrs({})`
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
`

export const PageContentActions = styled.div.attrs({})`
    display: flex;
    padding: 20px 0 0;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
`
export const CheckList = styled.div.attrs({})`
    padding-bottom: 30px;
`

export const CheckContent = styled.div.attrs({})`
    padding-bottom: 30px;
`

export const ProductItemChecked = styled.div.attrs({})`
    width: 16px;
    height: 16px;
    background: var(--primary-color);
    border-radius: 8px;

    display: flex;

    justify-content: center;
    align-items: center;
`
export const ProductItemCheckedIcon = styled.img.attrs((props) => ({
    src: `/icons/${props.icon}.svg`,
    width: 10,
}))``

export const CheckRow = styled.div.attrs({})`
    display: flex;
    width: 400px;
`

export const RequirementsItem = styled.div.attrs({})`
    display: flex;
    padding: 10px 0;
`
export const RequerimentIcon = styled.img.attrs({
    src: '/icons/green-check.svg',
})`
    margin-right: 16px;
`
export const RequerimentText = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: var(--grey-color);
    margin-right: 50px;
    padding-top: 5px;
`

export const RequirementItemAction = styled.div.attrs({})`
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: var(--grey-color);

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;
`
export const RequirementItemActionIcon = styled.img.attrs((props) => ({
    src: `/icons/${props.icon}.svg`,
    width: 14,
}))``

export const RequirementItemSection = styled.div.attrs({})`
    padding: 0px;
    display: flex;
    align-items: center;

    ${(props) =>
        props.width
            ? `width: ${props.width}`
            : `
        `}
`

export const IconInfo = styled.img.attrs({
    src: '/icons/circle-info.svg',
})``