import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const Content = styled.div.attrs({})``

export const PageCard = styled.div.attrs({})`
    background: var(--white-color);
    padding: 24px;
    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
`

export const LoadSpammer = styled.span.attrs({})`
    display: inline-block;
    margin-left: 4px;
`

export const PageHeader = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 20px;
`
export const PageHeaderTitle = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: var(--grey-color);
`
export const PageHeaderActions = styled.div.attrs({})`
    display: flex;
    align-items: center;

    position: relative;
`

export const ContentFilters = styled.div.attrs({})`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
`
export const FilterContent = styled.div.attrs({})`
    display: flex;
    align-items: center;
`
export const FilterText = styled.div.attrs({})`
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--grey-color);
    ${(props) =>
        props.light
            ? `
            color: var(--lightgrey-color);
        `
            : ``}
`

export const ContentScroll = styled.div.attrs({})``

//    overflow: auto;
//    max-height: 450px;

export const ScrollWrapper = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    @media (max-width: ${mobileSize}px) {
        flex-direction: column;
    }
`

//  justify-content: space-between;
