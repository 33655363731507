import './style.css'
import React, { useState, useEffect } from 'react'
import { PrivacySuppliers, Information } from 'services/privacy'
import Content from './Content/index'
import Modal from './Modal/index'

import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primeicons/primeicons.css'

const FreeFilter = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [progress, setProgress] = useState(0)
    const [selectedCountry, setSelectedCountry] = useState({
        value: 'united states',
        label: 'United States',
    })
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [selectedRole, setSelectedRole] = useState(null)
    const [selectedState, setSelectedState] = useState(null)
    const [selectedCity, setSelectedCity] = useState(null)
    const [filteredData, setFilteredData] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)

    useEffect(() => {
        const filterData = () => {
            let filtered = data

            if (selectedCountry && selectedCountry.value) {
                filtered = filtered.filter(
                    (item) =>
                        item?.countryhold?.toLowerCase() === selectedCountry.value.toLowerCase()
                )
            }

            if (selectedProduct && selectedProduct !== '') {
                filtered = filtered.filter((item) =>
                    item?.product?.toLowerCase()?.includes(selectedProduct?.toLowerCase())
                )
            }

            if (selectedRole && selectedRole.value) {
                filtered = filtered.filter(
                    (item) => item?.role?.toLowerCase() === selectedRole.value.toLowerCase()
                )
            }

            if (selectedState && selectedState !== '') {
                filtered = filtered.filter((item) =>
                    item?.state?.toLowerCase()?.includes(selectedState?.toLowerCase())
                )
            }

            if (selectedCity && selectedCity !== '') {
                filtered = filtered.filter((item) =>
                    item?.city?.toLowerCase()?.includes(selectedCity?.toLowerCase())
                )
            }

            setFilteredData(filtered)
        }
        filterData()
    }, [data, selectedCountry, selectedProduct, selectedState, selectedCity, selectedRole])

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            saveSearchData()
        }, 5000) // 5 seconds delay

        return () => clearTimeout(timeoutId)
    }, [selectedCountry, selectedProduct, selectedRole, selectedState, selectedCity])

    useEffect(() => {
        const fakeProgress = () => {
            let progress = 0
            const interval = setInterval(() => {
                progress += 99 / 1000 // increment progress to reach 98% at 6 seconds
                setProgress(progress)
                if (progress >= 99) clearInterval(interval)
            }, 10)
        }

        fakeProgress()

        const fetchData = async () => {
            const data = await PrivacySuppliers([])

            setData(data.result)
            setFilteredData(data.result)
            setProgress(100) // Set progress to 100 once data is fetched
            setLoading(false) // Set loading to false after setting the progress
        }

        // Simulate a fetch request
        setTimeout(() => {
            fetchData()
        }, Math.random() * 5000 + 1000) // Random time for demonstration

        saveSearchData()
    }, [])

    const saveSearchData = () => {
        const searchData = {
            country: selectedCountry?.label,
            product: selectedProduct,
            role: selectedRole?.label,
            state: selectedState,
            city: selectedCity,
        }

        Information(searchData)
    }

    const openModal = () => {
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    return (
        <div className="dashboard dashboardfreefilter">
            <Content
                data={filteredData}
                dataForTopCountries={data}
                loading={loading}
                progress={progress}
                setSelectedCountry={setSelectedCountry}
                setSelectedRole={setSelectedRole}
                setSelectedProduct={setSelectedProduct}
                selectedProduct={selectedProduct}
                setSelectedState={setSelectedState}
                selectedState={selectedState}
                setSelectedCity={setSelectedCity}
                selectedCity={selectedCity}
                openModal={openModal}
            />
            {isModalOpen && <Modal onClose={closeModal} />}
        </div>
    )
}

export default FreeFilter
