import styled, { keyframes } from 'styled-components'

export const SelectContainer = styled.div.attrs({})`
    border-bottom: 0.5px solid var(--lightgrey-color);
    max-width: 353px;
    width: 100%;
    position: relative;
    margin-top: 32px;
    margin-bottom: 20px;
    border-radius: 4px;
    ${(props) =>
        props.focused
            ? `
            border: 1px solid var(--primary-color);
        `
            : ``}
    ${(props) =>
        props.small
            ? `
            max-width: 303px;
        `
            : ``}
    ${(props) =>
        props.outline
            ? `
            border: .5px solid var(--lightgrey-color);
        `
            : ``}
            
     ${(props) =>
        props.highlight
            ? `
            border-bottom: 0.5px solid #ff4d4d !important;
        `
            : ``}
`

export const SelectContainerAC = styled.div.attrs({})`
    border-bottom: 0;
    max-width: 353px;
    width: 100%;
    position: relative;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 4px;
    ${(props) =>
        props.focused
            ? `
            border: 1px solid var(--primary-color);
        `
            : ``}
    ${(props) =>
        props.small
            ? `
            max-width: 303px;
        `
            : ``}
`

export const SelectHeader = styled.div.attrs({})`
    height: 43px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    color: #000000;
    font-size: 15px;
    cursor: pointer;
    &:hover {
        opacity: 0.9;
    }
    ${(props) =>
        props.black
            ? `
            color: var(--black-color);
        `
            : ``}

    ${(props) =>
        props.locked
            ? `
            background: var(--chatgrey-color);
        `
            : ``}
`

export const SelectHeaderText = styled.div.attrs({})`
    flex: 1;

    ${(props) =>
        props.highlight
            ? `
             color: #ff4d4d !important;
        `
            : ``}
`

export const SelectHeaderIcon = styled.img.attrs({
    src: '/icons/chevron.svg',
    width: 14,
})`
    ${(props) =>
        props.opened
            ? `
            transform: rotate(180deg);
        `
            : ``}
`

export const SelectBody = styled.div.attrs({})`
    max-height: 180px;
    overflow: auto;
    position: absolute;
    background: var(--white-color);
    z-index: 4;
    border: 0.5px solid var(--chatgrey-color);
    width: 100%;
`

export const SelectBodyAC = styled.div.attrs({})`
    max-height: 180px;
    overflow: auto;
    position: absolute;
    background: var(--white-color);
    z-index: 4;
    border: 0.5px solid var(--chatgrey-color);
    width: 100%;
    margin-top: -20px;
`
// const hideAnimation = keyframes`
//   to {
//     visibility: hidden;
//     width: 0;
//     height: 0;
//   }
// `

// #hideMeAfter5Seconds {
//   animation: hideAnimation 0s ease-in 5s;
//   animation-fill-mode: forwards;
// }

// @keyframes hideAnimation {
//   to {
//     visibility: hidden;
//     width: 0;
//     height: 0;
//   }
// }

export const SelectBodyItem = styled.div.attrs({})`
    height: 43px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    color: var(--black-color);
    font-size: 15px;
    border-top: 0.5px solid var(--chatgrey-color);
    cursor: pointer;
    &:hover {
        opacity: 0.9;
    }
`
