import React, { useContext, useEffect, useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { CoreContext } from 'context/CoreContext'
import { toast } from 'react-toastify'
import Select from 'react-select'

import {
    PageHeader,
    PageHeaderTitle,
    GrayAlertIcon,
    PageCard,
    IconHolder,
    BlockIcon,
    UnblockIcon,
    TrashIcon,
    ListText,
} from './styled'

import ContainerAuthenticated from 'containers/Authenticated'
import Button from 'components/Form/Button'
import DashboardFilterSearch from 'components/Dashboard/Filter/Search'
import { Load } from 'ui/styled'
import { FetchSupportCases, UpdateSupportCasesStart } from 'services/admin'

export default function DashboardSupportCases() {
    const { user, setModal, findlanguage, formatDateTimeValue, language } = useContext(CoreContext)

    const [searchExpression, setSearchExpression] = useState('')
    const [filter, setFilter] = useState('')

    const [cases, setCases] = useState()

    const fetchSupportCaseList = async () => {
        const updatedList = await FetchSupportCases()
        if (updatedList.statusCode === 400) return
        setCases(updatedList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)))
    }

    const askGet = (id, el) => {
        if (user.id === 1) {
            setModal({
                type: 'choose',
                title:
                    language === 'portugues'
                        ? 'Usuário Admin não pode capturar chamado!'
                        : `Admin User can't to get a case!`,
                options: [{ title: 'OK', action: () => setModal(null) }],
            })
        } else {
            setModal({
                type: 'choose',
                title: findlanguage().doYouWantToGetThisCase,
                options: [
                    { title: findlanguage().no, action: () => setModal(null) },
                    { title: findlanguage().yes, action: () => getCase(id, el), outline: true },
                ],
            })
        }
    }

    const getCase = async (id, el) => {
        setModal({
            type: 'loading',
            title: findlanguage().doYouWantToGetThisCase,
        })

        el.status = 'in-progress'
        el.support_user = user
        const request = await UpdateSupportCasesStart(id, el)
            .then(() => true)
            .catch((err) => {
                return false
            })

        if (!request) return

        toast.success(findlanguage().getCaseSuccess)

        const updatedList = await FetchSupportCases()
        setCases(updatedList)
        setModal(null)
    }

    const askClose = (id, el) => {
        setModal({
            type: 'choose',
            title: findlanguage().doYouWantToCloseThisCase,
            options: [
                { title: findlanguage().no, action: () => setModal(null) },
                { title: findlanguage().yes, action: () => closeCase(id, el), outline: true },
            ],
        })
    }

    const closeCase = async (id, el) => {
        setModal({
            type: 'loading',
            title: findlanguage().doYouWantToCloseThisCase,
        })

        el.status = 'closed'

        const request = await UpdateSupportCasesStart(id, el)
            .then(() => true)
            .catch((err) => {
                return false
            })

        if (!request) return

        toast.success(findlanguage().closedCaseSuccess)

        const updatedList = await FetchSupportCases()
        setCases(updatedList)
        setModal(null)
    }

    useEffect(() => {
        fetchSupportCaseList()
    }, [])

    const listSelector = () => {
        if (!cases) return loadingListComponent
        if (cases.length !== 0) return listComponent()
        else return emtpyListComponent
    }

    const loadingListComponent = (
        <Row
            style={{
                minHeight: '60vh',
                justifyContent: 'center',
                display: 'grid',
                alignItems: 'center',
            }}
        >
            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Load white />
            </Col>
        </Row>
    )

    const emtpyListComponent = (
        <Row
            style={{
                minHeight: '60vh',
                justifyContent: 'center',
                display: 'grid',
                alignItems: 'center',
            }}
        >
            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <GrayAlertIcon />
                <p style={{ color: '#838383' }}>{findlanguage().noData}</p>
            </Col>
        </Row>
    )

    const listComponent = () => (
        <PageCard>
            <Row style={{ marginBottom: '.6rem', color: '#707070' }}>
                <Col md="3">{language === 'portugues' ? 'Cliente' : 'Customer User'}</Col>
                <Col md="3">{language === 'portugues' ? 'Usuário Suporte' : 'Support User'}</Col>
                <Col>{findlanguage().status}</Col>
                <Col>{findlanguage().creation}</Col>
                <Col>{findlanguage().lastUpdate}</Col>
                <Col md="1" style={{ textAlign: 'center' }}>
                    {findlanguage().actions}
                </Col>
            </Row>

            {cases
                .filter((_case) => {
                    if (!filter) return true
                    return _case.status.includes(filter === 'all' ? '' : filter)
                })
                .map((_case, i) => (
                    <>
                        <Row
                            key={i}
                            style={{ padding: '.8rem 0', borderTop: '1px solid #70707020' }}
                        >
                            <Col md="3">
                                <ListText>{_case.users_permissions_user?.name}</ListText>
                            </Col>
                            <Col md="3">
                                <ListText>
                                    {_case.support_user ? _case.support_user?.name : '-'}
                                </ListText>
                            </Col>
                            <Col style={{ width: '50px' }}>
                                <ListText>
                                    {
                                        findlanguage().filterSupportCasesOptions.find(
                                            (el) => el.value === _case?.status
                                        ).label
                                    }
                                </ListText>
                            </Col>
                            <Col>
                                <ListText>{formatDateTimeValue(_case.created_at)}</ListText>
                            </Col>
                            <Col>
                                <ListText>{formatDateTimeValue(_case.updated_at)}</ListText>
                            </Col>
                            <Col md="1">
                                <IconHolder>
                                    <TrashIcon onClick={() => askGet(_case.id, _case)} />
                                    <BlockIcon onClick={() => askClose(_case.id, _case)} />
                                </IconHolder>
                            </Col>
                        </Row>
                        <Row
                            key={i + 'l2'}
                            style={{
                                padding: '.8rem 0',
                                paddingLeft: '40px',
                                borderTop: '1px solid #70707020',
                            }}
                        >
                            <Col>
                                <ListText>
                                    <b>{findlanguage().message}:</b> {_case?.message}
                                </ListText>
                            </Col>
                        </Row>
                    </>
                ))}
        </PageCard>
    )

    return (
        <>
            <ContainerAuthenticated>
                <Container fluid={false}>
                    <Row style={{ gap: '1rem' }} noGutters={true}>
                        <Col md={'auto'} style={{ display: 'flex', justifyContent: 'center' }}>
                            <PageHeader style={{ margin: 0 }}>
                                <PageHeaderTitle>{findlanguage().supportCases}</PageHeaderTitle>
                            </PageHeader>
                        </Col>
                        <Col
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                width: '80%',
                            }}
                        >
                            <Select
                                onChange={(e) => setFilter(e.value)}
                                placeholder={findlanguage().filterBy}
                                name="filter"
                                isSearchable={false}
                                options={findlanguage().filterSupportCasesOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </Col>
                    </Row>

                    {listSelector()}
                </Container>
            </ContainerAuthenticated>
        </>
    )
}
