import { DELETE, GET, PUT } from './api'

export const RemoveNotification = async (id) => {
    return await DELETE(`/notifications/${id}`, true)
}

export const FetchNotifications = async (id) => {
    return await GET(`/notifications?_sort=id:DESC&_limit=100&user=` + id, true)
}

export const UpdateNotifications = async (notification) => {
    return await PUT(`/notifications/${notification.id}`, notification, true)
}

export const FetchMyNotifications = async (sort, direction, start, limit, read) => {
    return await GET(
        `/notifications/paginate?_sort=${sort}&_direction=${direction}&_start=${start}&_limit=${limit}&_read=${read}`,
        true
    )
}
