import styled from 'styled-components'

export const Content = styled.div.attrs({})``

export const PageHeader = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 20px;
`
export const PageHeaderTitle = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: var(--grey-color);
`
export const PageHeaderActions = styled.div.attrs({})`
    display: flex;
    align-items: center;

    position: relative;
`

export const ContentScroll = styled.div.attrs({})`
    overflow: auto;
    max-height: 420px;
`
export const ScrollWrapper = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

export const ContactScroll = styled.div.attrs({})`
    overflow: auto;
    max-height: 155px;
    margin-bottom: 18px;
`
export const ContactScrollWrapper = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

export const ChatContent = styled.div.attrs({})`
    background: var(--white-color);
    padding: 24px;
    margin-top: 48px;
    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
`
export const ChatHeaderContent = styled.div.attrs({})`
    display: flex;
    align-items: center;
`
export const ChatHeaderImage = styled.div.attrs({})`
    width: 76px;
    height: 76px;
    border-radius: 38px;
    ${(props) =>
        props.url
            ? `
    background: url(${props.url}) no-repeat center center / cover #fff;
    background-size: 70px;
        `
            : `
    background: url(/images/no-image.png) no-repeat center center / cover;
        `}
`
export const ChatHeaderInfo = styled.div.attrs({})`
    padding: 0 20px;
    flex: 1;
`
export const ChatHeaderInfoTitle = styled.div.attrs({})`
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--grey-color);
`
export const ChatHeaderInfoText = styled.div.attrs({})`
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--grey-color);
    margin-top: 3px;
`

export const ChatBody = styled.div.attrs({})`
    border-top: 1px solid var(--lightshadow-color);
    margin-top: 16px;
    padding-top: 16px;
    height: 56vh;
    overflow: auto;
`
export const ChatMessage = styled.div.attrs({})`
    display: flex;
    padding-bottom: 16px;
    flex-direction: column;
    ${(props) =>
        props.mine
            ? `
            align-items: flex-end;
        `
            : ``}
`
export const ChatMessageBalloon = styled.div.attrs({})`
    padding: 16px 35px 17px 18px;
    border-radius: 7px;

    background: var(--backgroundgrey-color);
    color: var(--grey-color);
    ${(props) =>
        props.mine
            ? `
    max-width: 100%;
            background: var(--primary-color);
            color: var(--white-color);
            float: right;
        `
            : `
    max-width: 80%;`}
`
export const ChatMessageTime = styled.div.attrs({})`
    color: var(--lightgrey-color);
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    margin: 10px 5px 0;
`

export const ChatInputContent = styled.div.attrs({})`
    display: flex;
    border: 1px solid var(--chatgrey-color);
    padding: 9px 14px;
    border-radius: 8px;
`
export const ChatInput = styled.input.attrs({})`
    flex: 1;
    outline: none;
    background: none;
    border: none;

    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: #262626;
`
export const ChatInputAction = styled.div.attrs({})`
    width: 36px;
    height: 32px;
    border-radius: 4px;
    background: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    border: 0;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 1.5px 6px var(--shadow-color);
    }
`
export const ChatInputActionIcon = styled.img.attrs({
    src: '/icons/chatsent.svg',
    width: 16,
})``

export const ChatBalloonActions = styled.div.attrs({})`
    display: inline-grid;
    align-items: center;
    justify-content: flex-end;
    padding-top: 24px;
`
