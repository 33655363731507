import styled from 'styled-components'

export const PageHeaderMore = styled.img.attrs({
    src: '/icons/dots.svg',
    width: 22,
})`
    cursor: pointer;
    padding: 7px 0;

    ${(props) =>
        props.vertical
            ? `
            transform: rotate(90deg);
        `
            : ``}
`

export const MoreOptionsContainer = styled.div.attrs({})`
    position: absolute;
    top: 30px;
    right: 0px;
    padding: 15px;
    width: 170px;
    background: var(--white-color);
    border: 1px solid var(--primary-color);
    box-shadow: 0px 1.5px 6px var(--shadow-color);
    z-index: 99;
    border-radius: 6px;
`

export const MoreOption = styled.div.attrs({})`
    padding: 8px 0;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: #000;
    ${(props) =>
        props.link
            ? `
            text-decoration: underline;
        `
            : ``}
    ${(props) =>
        props.primary
            ? `
            color: var(--primary-color);
        `
            : ``}
    cursor:pointer;
`

export const MoreContainer = styled.div.attrs({})`
    position: relative;
`
