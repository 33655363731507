import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const PageCard = styled.div.attrs({})`
    background: var(--white-color);
    padding: 24px;
    margin-top: 28px;
    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
`
export const CardHeader = styled.div.attrs({})`
    padding: 20px 0;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--primary-color);
    display: flex;
    justify-content: space-between;
`
export const CardLineContainer = styled.div.attrs({})`
    display: flex;
`
export const CardLine = styled.div.attrs({})`
    display: flex;
    padding: 20px 0;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--lightshadow-color);
    ${(props) =>
        props.mobileHeader
            ? `
            display: none;
        `
            : ``}
    @media(max-width: ${mobileSize}px) {
        flex-direction: column;
        gap: 20px 0;
        flex: 1;
        ${(props) =>
            props.header
                ? `
                display: none;
            `
                : ``}
        ${(props) =>
            props.mobileHeader
                ? `
                display: flex;
                padding: 0 10px;
                flex:0;
                align-items: flex-start;
            `
                : ``}
    }
`
export const CardLineText = styled.div.attrs({})`
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    cursor: pointer;
    color: var(--grey-color);

    ${(props) =>
        props.regular
            ? `
              font-family: 'Open Sans', sans-serif;
            `
            : ``}

    ${(props) =>
        props.link
            ? `
            font-family: 'Open Sans', sans-serif;
            color: var(--lightgrey-color);
            text-decoration-line: underline;
            text-align: center;
            max-width: 120px;
            cursor: pointer;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        `
            : ``}

    ${(props) =>
        props.grey
            ? `
            color: var(--lightgrey-color);
        `
            : ``}
`
export const CardLineBadge = styled.div.attrs({})`
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background: var(--lightgrey-color);

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--white-color);

    ${(props) =>
        props.percentage >= 70
            ? `
            background: var(--primary-color);
        `
            : ``}
    ${(props) =>
        props.percentage >= 20 && props.percentage < 41
            ? `
            background: var(--yellow-color);
            color: var(--grey-color);
        `
            : ``}
    ${(props) =>
        props.percentage >= 41 && props.percentage < 70
            ? `
            background: var(--orange-color);
            color: var(--grey-color);
        `
            : ``}
`

export const CardLineAction = styled.div.attrs({})`
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: var(--grey-color);
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    &:hover {
        box-shadow: 0px 1.5px 6px var(--shadow-color);
    }
`
export const CardLineActionIcon = styled.img.attrs({
    src: '/icons/chatsent.svg',
    width: 16,
})``

export const CardLineActionSpacer = styled.div.attrs({})`
    min-width: 40px;
    flex: 1;
`

export const CardLineColumn = styled.div.attrs({})`
    ${(props) =>
        props.last
            ? ``
            : `
            flex:1;
        `}
    @media(max-width: ${mobileSize}px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`

export const PageCardActions = styled.div.attrs({})`
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
`

export const CardHeaderSection = styled.div.attrs({})`
    display: flex;
    align-items: center;
    color: var(--blue-color);
`

export const HeaderPercentage = styled.div.attrs({})`
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background: var(--blue-color);

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--white-color);

    margin-left: 24px;
`
export const CardFormStatus = styled.div.attrs({})`
    width: 18px;
    height: 18px;
    border-radius: 9px;
    background-color: ${(props) => props.color};
`
export const CardAttachPending = styled.img.attrs({
    src: '/icons/warn.svg',
    width: 24,
})`
    margin: 0 0 0 45px;
    @media (max-width: ${mobileSize}px) {
        margin: 0;
    }
`
export const CardIncommingMail = styled.img.attrs({
    src: '/icons/mail.svg',
    width: 32,
})`
    margin: 0 0 0 18px;
`

export const CardLineInfo = styled.img.attrs({
    src: '/icons/circle-info.svg',
    width: 32,
})`
    margin: 0 0 0 18px;
    border-radius: 16px;

    cursor: pointer;
    &:hover {
        box-shadow: 0px 1.5px 6px var(--shadow-color);
    }
`
export const ImageSeal = styled.div.attrs({})`
    width: 30px;
    height: 30px;
    border-radius: 61px;
    background: var(--lightgrey-color);
    background: url(${(props) => props.image}) no-repeat center center / cover;
    background-size: 30px;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    margin-bottom: 4px;
`
export const UserProfileProgressActions = styled.div.attrs({})`
    display: flex;
    margin-top: 10px;
    margin-left: -5px;
`

export const CardDocument = styled.img.attrs({
    src: '/icons/document.svg',
})``

export const EditIcon = styled.img.attrs({
    src: '/icons/edit.svg',
    height: '20px',
})`
    margin-left: 5px;
    cursor: pointer;
`

export const Filter = styled.img.attrs({
    src: '/icons/filter.svg',
    height: 20,
})`
    cursor: pointer;
`

export const IconInfo = styled.img.attrs({
    src: '/icons/circle-info.svg',
})``
