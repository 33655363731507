import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
    HomeCard,
    HomeCardHeader,
    HomeCardHeaderTitle,
    HomeCardCloseContent,
    HomeCardHeaderClose,
    HomeCardHeaderActions,
    HomeCardBody,
    HomeCardSpacer,
    HomeCardLocked,
    HomeCardHeaderWarning,
} from './styled'

import DashboardButton from 'components/Dashboard/Button'

export default function DashboardPanel({
    children,
    title,
    actions,
    big,
    relative,
    onClose,
    homewidth,
    locked,
    subscription,
    showWarning,
}) {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)
    const back = () => history.goBack()

    const [currentButton, setCurrentButton] = useState(0)

    const callAction = (_action, key) => {
        const result = _action()
        if (result) setCurrentButton(key)
    }

    return (
        <>
            <HomeCard relative={relative}>
                <HomeCardCloseContent>
                    <HomeCardHeaderClose onClick={onClose} />
                </HomeCardCloseContent>
                <HomeCardHeader>
                    <HomeCardHeaderTitle>
                        {title}
                        {showWarning ? <HomeCardHeaderWarning /> : ''}
                    </HomeCardHeaderTitle>
                    <HomeCardHeaderActions>
                        {actions?.map((item, key) => (
                            <DashboardButton
                                key={key}
                                outline={item.outline}
                                dark={item.dark}
                                small={item.small}
                                active={key === currentButton}
                                onClick={() => callAction(item.action, key)}
                            >
                                {item.title}
                            </DashboardButton>
                        ))}
                    </HomeCardHeaderActions>
                </HomeCardHeader>
                <HomeCardBody big={big} relative={relative}>
                    {locked ? (
                        <HomeCardLocked
                            onClick={() => navigate('dashboard/account-premium')}
                            subscription={subscription}
                        />
                    ) : (
                        children
                    )}
                </HomeCardBody>
            </HomeCard>
            <HomeCardSpacer />
        </>
    )
}
