import React, { useEffect, useState } from 'react'
import { PageCard } from '../../../../../Panels/SMSControl/styled'

import { TitleGreen } from '../styled'

import { GetSpecificsQuestions, PutSpecificsQuestions, PostSpecificsQuestions } from 'services/egs'
import { ReadMe } from 'services/authentication'

import { UpdateSpecificsQuestions } from 'services/api'

const labelCss = { fontWeight: 'bold', fontSize: '15px', color: 'rgb(86, 86, 86)' }
const labelTextCss = { fontSize: '14px', color: 'rgb(86, 86, 86)' }
const inputCss = { borderColor: '#70707059' }
const col6 = { fontSize: '700', color: '#707070' }
const spacer = { borderTop: '0.1px solid rgb(235 235 235)' }
const doneTypingInterval = 2000

let typingTimer

export default function Additionalnformation({ form }) {
    const [specificQuestions, setSpecificQuestions] = useState({
        id: -1,
        us_fda_registration_number: 0,
        have_fsma: false,
        have_fsma_file: {},
        prior_notice_import: false,
        prior_notice_import_file: {},
        us_fsvp_importer: false,
        us_fsvp_importer_file: {},
        usda_import_requirements: false,
        usda_import_requirements_file: {},
        importer_record_usa: false,
        importer_record_usa_file: {},
        recalls_warning: false,
        recalls_warning_file: {},
        us_import_alert: false,
        us_import_alert_file: {},
    })

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        waitUserFinishTyping()
    }, [specificQuestions])

    const init = async () => {
        const readMe = await ReadMe()

        const getSpecificsQuestions = await GetSpecificsQuestions(readMe.company_id)

        if (getSpecificsQuestions.statusText !== 'Not Found' && getSpecificsQuestions.length !== 0)
            setSpecificQuestions(getSpecificsQuestions[0])
        else
            setSpecificQuestions(
                await PostSpecificsQuestions({ users_permissions_user: readMe.company_id })
            )
    }

    function changeQuestionaireTrueFalse(value, ref) {
        setSpecificQuestions({
            ...specificQuestions,
            [ref]: value === 'true',
        })

        waitUserFinishTyping()
    }

    function waitUserFinishTyping() {
        clearTimeout(typingTimer)
        typingTimer = setTimeout(saveSpecificsQuestions, doneTypingInterval)
    }

    const saveSpecificsQuestions = async () => {
        if (specificQuestions.id !== -1) await PutSpecificsQuestions(specificQuestions)
    }

    const saveDocument = async (fileName) => {
        await PutSpecificsQuestions({
            ...specificQuestions,
            [fileName]: null,
        })

        const formData = new FormData()

        if (document.getElementById(fileName)?.files?.[0]) {
            new Promise((resolve, reject) => {
                let reader = new FileReader()
                reader.onload = async () => {
                    let source = `${reader.result}`.split(',')[1]
                    let image = {
                        filename: document.getElementById(fileName)?.files?.[0].name,
                        filetype: document.getElementById(fileName)?.files?.[0].type,
                        source,
                    }

                    let btc = window.atob(image.source)
                    let btn = new Array(btc.length)
                    for (var i = 0; i < btc.length; i++) {
                        btn[i] = btc.charCodeAt(i)
                    }
                    var bta = new Uint8Array(btn)
                    let boobs = new Blob([bta], { type: 'image/png' })
                    let filesss = new File([boobs], `${image.filename}`)

                    formData.append(fileName, filesss, filesss.name)
                    formData.append('id', specificQuestions.id)
                    formData.append('filename', fileName)

                    await UpdateSpecificsQuestions(formData)

                    setSpecificQuestions({
                        ...specificQuestions,
                        [fileName]: document.getElementById(fileName)?.files,
                    })
                }
                reader.readAsDataURL(document.getElementById(fileName)?.files?.[0])
            })
        }
    }

    return (
        <PageCard>
            <TitleGreen>Specific Questions about USDA and/or FDA, if aplicable</TitleGreen>
            <div className="row  mb-3">
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        Does the company have FSMA Intentional Adulteration Plan?
                    </label>
                    <br />
                    <label className="form-label" style={labelTextCss}>
                        If Yes, please attach the Evidence
                    </label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={
                            specificQuestions?.have_fsma === null
                                ? false
                                : specificQuestions?.have_fsma
                        }
                        onChange={(event) =>
                            changeQuestionaireTrueFalse(event.target.value, 'have_fsma')
                        }
                        disabled={form}
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>
                </div>
                {specificQuestions?.have_fsma ? (
                    <div className="col-6" style={col6}>
                        <label className="form-label" style={labelCss}>
                            &nbsp;
                        </label>
                        {!form ? (
                            <>
                                {specificQuestions?.have_fsma_file === undefined ||
                                specificQuestions?.have_fsma_file.length === 0 ? (
                                    <>
                                        <label
                                            for="have_fsma_file"
                                            style={{
                                                fontSize: '13px',
                                                color: 'white',
                                                height: '35px',
                                                padding: '10px',
                                                width: '100%',
                                                backgroundColor: '#263640',
                                                cursor: 'pointer',
                                                marginTop: '31px',
                                            }}
                                            className="badge"
                                        >
                                            Open File
                                        </label>
                                        <input
                                            type="file"
                                            id="have_fsma_file"
                                            onChange={() => saveDocument('have_fsma_file')}
                                            style={{ display: 'none' }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <label
                                            for="have_fsma_file"
                                            style={{
                                                fontSize: '13px',
                                                color: 'white',
                                                height: '35px',
                                                padding: '10px',
                                                width: '100%',
                                                backgroundColor: '#263640',
                                                cursor: 'pointer',
                                                marginTop: '31px',
                                            }}
                                            className="badge"
                                        >
                                            Change File
                                        </label>

                                        <div style={{ marginTop: '6px', textAlign: 'center' }}>
                                            {specificQuestions?.have_fsma_file[0]?.name}
                                        </div>
                                        <input
                                            type="file"
                                            id="have_fsma_file"
                                            onChange={() => saveDocument('have_fsma_file')}
                                            style={{ display: 'none' }}
                                        />
                                    </>
                                )}
                            </>
                        ) : (
                            <div style={{ marginTop: '36px' }}>
                                {specificQuestions?.have_fsma_file[0]?.name}
                            </div>
                        )}
                    </div>
                ) : null}
            </div>
            <div className="mb-3" style={spacer}></div>
            <div className="row  mb-3">
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        Does the company filed Prior Notice to Import?
                    </label>
                    <br />
                    <label className="form-label" style={labelTextCss}>
                        If Yes, please attach the Evidence
                    </label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={
                            specificQuestions?.prior_notice_import === null
                                ? false
                                : specificQuestions?.prior_notice_import
                        }
                        onChange={(event) =>
                            changeQuestionaireTrueFalse(event.target.value, 'prior_notice_import')
                        }
                        disabled={form}
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>
                </div>
                {specificQuestions?.prior_notice_import ? (
                    <div className="col-6" style={col6}>
                        <label className="form-label" style={labelCss}>
                            &nbsp;
                        </label>
                        {!form ? (
                            <>
                                {specificQuestions?.prior_notice_import_file === undefined ||
                                specificQuestions?.prior_notice_import_file.length === 0 ? (
                                    <>
                                        <label
                                            for="prior_notice_import_file"
                                            style={{
                                                fontSize: '13px',
                                                color: 'white',
                                                height: '35px',
                                                padding: '10px',
                                                width: '100%',
                                                backgroundColor: '#263640',
                                                cursor: 'pointer',
                                                marginTop: '31px',
                                            }}
                                            className="badge"
                                        >
                                            Open File
                                        </label>
                                        <input
                                            type="file"
                                            id="prior_notice_import_file"
                                            onChange={() =>
                                                saveDocument('prior_notice_import_file')
                                            }
                                            style={{ display: 'none' }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <label
                                            for="prior_notice_import_file"
                                            style={{
                                                fontSize: '13px',
                                                color: 'white',
                                                height: '35px',
                                                padding: '10px',
                                                width: '100%',
                                                backgroundColor: '#263640',
                                                cursor: 'pointer',
                                                marginTop: '31px',
                                            }}
                                            className="badge"
                                        >
                                            Change File
                                        </label>

                                        <div style={{ marginTop: '6px', textAlign: 'center' }}>
                                            {specificQuestions?.prior_notice_import_file[0]?.name}
                                        </div>
                                        <input
                                            type="file"
                                            id="prior_notice_import_file"
                                            onChange={() =>
                                                saveDocument('prior_notice_import_file')
                                            }
                                            style={{ display: 'none' }}
                                        />
                                    </>
                                )}
                            </>
                        ) : (
                            <div style={{ marginTop: '36px' }}>
                                {specificQuestions?.prior_notice_import_file[0]?.name}
                            </div>
                        )}
                    </div>
                ) : null}
            </div>
            <div className="mb-3" style={spacer}></div>
            <div className="row  mb-3">
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        Is the company a qualified individual for US FSVP Importer?
                    </label>
                    <br />
                    <label className="form-label" style={labelTextCss}>
                        If Yes, please attach the Evidence
                    </label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={
                            specificQuestions?.us_fsvp_importer === null
                                ? false
                                : specificQuestions?.us_fsvp_importer
                        }
                        onChange={(event) =>
                            changeQuestionaireTrueFalse(event.target.value, 'us_fsvp_importer')
                        }
                        disabled={form}
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>
                </div>
                {!form ? (
                    <>
                        {specificQuestions?.us_fsvp_importer ? (
                            <div className="col-6" style={col6}>
                                <label className="form-label" style={labelCss}>
                                    &nbsp;
                                </label>
                                {specificQuestions?.us_fsvp_importer_file === undefined ||
                                specificQuestions?.us_fsvp_importer_file.length === 0 ? (
                                    <>
                                        <label
                                            for="us_fsvp_importer_file"
                                            style={{
                                                fontSize: '13px',
                                                color: 'white',
                                                height: '35px',
                                                padding: '10px',
                                                width: '100%',
                                                backgroundColor: '#263640',
                                                cursor: 'pointer',
                                                marginTop: '31px',
                                            }}
                                            className="badge"
                                        >
                                            Open File
                                        </label>
                                        <input
                                            type="file"
                                            id="us_fsvp_importer_file"
                                            onChange={() => saveDocument('us_fsvp_importer_file')}
                                            style={{ display: 'none' }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <label
                                            for="us_fsvp_importer_file"
                                            style={{
                                                fontSize: '13px',
                                                color: 'white',
                                                height: '35px',
                                                padding: '10px',
                                                width: '100%',
                                                backgroundColor: '#263640',
                                                cursor: 'pointer',
                                                marginTop: '31px',
                                            }}
                                            className="badge"
                                        >
                                            Change File
                                        </label>

                                        <div style={{ marginTop: '6px', textAlign: 'center' }}>
                                            {specificQuestions?.us_fsvp_importer_file[0]?.name}
                                        </div>
                                        <input
                                            type="file"
                                            id="us_fsvp_importer_file"
                                            onChange={() => saveDocument('us_fsvp_importer_file')}
                                            style={{ display: 'none' }}
                                        />
                                    </>
                                )}
                            </div>
                        ) : null}
                    </>
                ) : (
                    <div style={{ marginTop: '36px' }}>
                        {specificQuestions?.us_fsvp_importer_file[0]?.name}
                    </div>
                )}
            </div>
            <div className="mb-3" style={spacer}></div>
            <div className="row  mb-3">
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        Do you meet USDA Import Requirements?
                    </label>
                    <br />
                    <label className="form-label" style={labelTextCss}>
                        If Yes, please attach the Evidence
                    </label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={
                            specificQuestions?.usda_import_requirements === null
                                ? false
                                : specificQuestions?.usda_import_requirements
                        }
                        onChange={(event) =>
                            changeQuestionaireTrueFalse(
                                event.target.value,
                                'usda_import_requirements'
                            )
                        }
                        disabled={form}
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>
                </div>
                {!form ? (
                    <>
                        {specificQuestions?.usda_import_requirements ? (
                            <div className="col-6" style={col6}>
                                <label className="form-label" style={labelCss}>
                                    &nbsp;
                                </label>
                                {specificQuestions?.usda_import_requirements_file === undefined ||
                                specificQuestions?.usda_import_requirements_file.length === 0 ? (
                                    <>
                                        <label
                                            for="usda_import_requirements_file"
                                            style={{
                                                fontSize: '13px',
                                                color: 'white',
                                                height: '35px',
                                                padding: '10px',
                                                width: '100%',
                                                backgroundColor: '#263640',
                                                cursor: 'pointer',
                                                marginTop: '31px',
                                            }}
                                            className="badge"
                                        >
                                            Open File
                                        </label>
                                        <input
                                            type="file"
                                            id="usda_import_requirements_file"
                                            onChange={() =>
                                                saveDocument('usda_import_requirements_file')
                                            }
                                            style={{ display: 'none' }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <label
                                            for="usda_import_requirements_file"
                                            style={{
                                                fontSize: '13px',
                                                color: 'white',
                                                height: '35px',
                                                padding: '10px',
                                                width: '100%',
                                                backgroundColor: '#263640',
                                                cursor: 'pointer',
                                                marginTop: '31px',
                                            }}
                                            className="badge"
                                        >
                                            Change File
                                        </label>

                                        <div style={{ marginTop: '6px', textAlign: 'center' }}>
                                            {
                                                specificQuestions?.usda_import_requirements_file[0]
                                                    ?.name
                                            }
                                        </div>
                                        <input
                                            type="file"
                                            id="usda_import_requirements_file"
                                            onChange={() =>
                                                saveDocument('usda_import_requirements_file')
                                            }
                                            style={{ display: 'none' }}
                                        />
                                    </>
                                )}
                            </div>
                        ) : null}
                    </>
                ) : (
                    <div style={{ marginTop: '36px' }}>
                        {specificQuestions?.usda_import_requirements_file[0]?.name}
                    </div>
                )}
            </div>
            <div className="mb-3" style={spacer}></div>
            <div className="row  mb-3">
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        Do you have an importer of record in the United Sates
                    </label>
                    <br />
                    <label className="form-label" style={labelTextCss}>
                        If Yes, please attach the Evidence
                    </label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={
                            specificQuestions?.importer_record_usa === null
                                ? false
                                : specificQuestions?.importer_record_usa
                        }
                        onChange={(event) =>
                            changeQuestionaireTrueFalse(event.target.value, 'importer_record_usa')
                        }
                        disabled={form}
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>
                </div>
                {specificQuestions?.importer_record_usa ? (
                    <div className="col-6" style={col6}>
                        <label className="form-label" style={labelCss}>
                            &nbsp;
                        </label>
                        {!form ? (
                            <>
                                {specificQuestions?.importer_record_usa_file === undefined ||
                                specificQuestions?.importer_record_usa_file.length === 0 ? (
                                    <>
                                        <label
                                            for="importer_record_usa_file"
                                            style={{
                                                fontSize: '13px',
                                                color: 'white',
                                                height: '35px',
                                                padding: '10px',
                                                width: '100%',
                                                backgroundColor: '#263640',
                                                cursor: 'pointer',
                                                marginTop: '31px',
                                            }}
                                            className="badge"
                                        >
                                            Open File
                                        </label>
                                        <input
                                            type="file"
                                            id="importer_record_usa_file"
                                            onChange={() =>
                                                saveDocument('importer_record_usa_file')
                                            }
                                            style={{ display: 'none' }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <label
                                            for="importer_record_usa_file"
                                            style={{
                                                fontSize: '13px',
                                                color: 'white',
                                                height: '35px',
                                                padding: '10px',
                                                width: '100%',
                                                backgroundColor: '#263640',
                                                cursor: 'pointer',
                                                marginTop: '31px',
                                            }}
                                            className="badge"
                                        >
                                            Change File
                                        </label>

                                        <div style={{ marginTop: '6px', textAlign: 'center' }}>
                                            {specificQuestions?.importer_record_usa_file[0]?.name}
                                        </div>
                                        <input
                                            type="file"
                                            id="importer_record_usa_file"
                                            onChange={() =>
                                                saveDocument('importer_record_usa_file')
                                            }
                                            style={{ display: 'none' }}
                                        />
                                    </>
                                )}
                            </>
                        ) : (
                            <div style={{ marginTop: '36px' }}>
                                {specificQuestions?.importer_record_usa_file[0]?.name}
                            </div>
                        )}
                    </div>
                ) : null}
            </div>
            <div className="mb-3" style={spacer}></div>
            <div className="row  mb-3">
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        Have you had any recalls/warning letters from recent regulatory inspections?
                    </label>
                    <br />
                    <label className="form-label" style={labelTextCss}>
                        If Yes, please attach the Evidence
                    </label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={
                            specificQuestions?.recalls_warning === null
                                ? false
                                : specificQuestions?.recalls_warning
                        }
                        onChange={(event) =>
                            changeQuestionaireTrueFalse(event.target.value, 'recalls_warning')
                        }
                        disabled={form}
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>
                </div>
                {!form ? (
                    <>
                        {specificQuestions?.recalls_warning ? (
                            <div className="col-6" style={col6}>
                                <label className="form-label" style={labelCss}>
                                    &nbsp;
                                </label>
                                {specificQuestions?.recalls_warning_file === undefined ||
                                specificQuestions?.recalls_warning_file.length === 0 ? (
                                    <>
                                        <label
                                            for="recalls_warning_file"
                                            style={{
                                                fontSize: '13px',
                                                color: 'white',
                                                height: '35px',
                                                padding: '10px',
                                                width: '100%',
                                                backgroundColor: '#263640',
                                                cursor: 'pointer',
                                                marginTop: '31px',
                                            }}
                                            className="badge"
                                        >
                                            Open File
                                        </label>
                                        <input
                                            type="file"
                                            id="recalls_warning_file"
                                            onChange={() => saveDocument('recalls_warning_file')}
                                            style={{ display: 'none' }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <label
                                            for="recalls_warning_file"
                                            style={{
                                                fontSize: '13px',
                                                color: 'white',
                                                height: '35px',
                                                padding: '10px',
                                                width: '100%',
                                                backgroundColor: '#263640',
                                                cursor: 'pointer',
                                                marginTop: '31px',
                                            }}
                                            className="badge"
                                        >
                                            Change File
                                        </label>

                                        <div style={{ marginTop: '6px', textAlign: 'center' }}>
                                            {specificQuestions?.recalls_warning_file[0]?.name}
                                        </div>
                                        <input
                                            type="file"
                                            id="recalls_warning_file"
                                            onChange={() => saveDocument('recalls_warning_file')}
                                            style={{ display: 'none' }}
                                        />
                                    </>
                                )}
                            </div>
                        ) : null}
                    </>
                ) : (
                    <div style={{ marginTop: '36px' }}>
                        {specificQuestions?.recalls_warning_file[0]?.name}
                    </div>
                )}
            </div>
            <div className="mb-3" style={spacer}></div>
            <div className="row  mb-3">
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        Are you on any US import alert list?
                    </label>
                    <br />
                    <label className="form-label" style={labelTextCss}>
                        If Yes, please attach the Evidence
                    </label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={
                            specificQuestions?.us_import_alert === null
                                ? false
                                : specificQuestions?.us_import_alert
                        }
                        onChange={(event) =>
                            changeQuestionaireTrueFalse(event.target.value, 'us_import_alert')
                        }
                        disabled={form}
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>
                </div>
                {specificQuestions?.us_import_alert ? (
                    <div className="col-6" style={col6}>
                        <label className="form-label" style={labelCss}>
                            &nbsp;
                        </label>
                        {!form ? (
                            <>
                                {specificQuestions?.us_import_alert_file === undefined ||
                                specificQuestions?.us_import_alert_file.length === 0 ? (
                                    <>
                                        <label
                                            for="us_import_alert_file"
                                            style={{
                                                fontSize: '13px',
                                                color: 'white',
                                                height: '35px',
                                                padding: '10px',
                                                width: '100%',
                                                backgroundColor: '#263640',
                                                cursor: 'pointer',
                                                marginTop: '31px',
                                            }}
                                            className="badge"
                                        >
                                            Open File
                                        </label>
                                        <input
                                            type="file"
                                            id="us_import_alert_file"
                                            onChange={() => saveDocument('us_import_alert_file')}
                                            style={{ display: 'none' }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <label
                                            for="us_import_alert_file"
                                            style={{
                                                fontSize: '13px',
                                                color: 'white',
                                                height: '35px',
                                                padding: '10px',
                                                width: '100%',
                                                backgroundColor: '#263640',
                                                cursor: 'pointer',
                                                marginTop: '31px',
                                            }}
                                            className="badge"
                                        >
                                            Change File
                                        </label>

                                        <div style={{ marginTop: '6px', textAlign: 'center' }}>
                                            {specificQuestions?.us_import_alert_file[0]?.name}
                                        </div>
                                        <input
                                            type="file"
                                            id="us_import_alert_file"
                                            onChange={() => saveDocument('us_import_alert_file')}
                                            style={{ display: 'none' }}
                                        />
                                    </>
                                )}
                            </>
                        ) : (
                            <div style={{ marginTop: '36px' }}>
                                {specificQuestions?.us_import_alert_file[0]?.name}
                            </div>
                        )}
                    </div>
                ) : null}
            </div>
        </PageCard>
    )
}
