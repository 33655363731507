import React from 'react'
import ControlButtons from '../ControlButtons/index'

function EmptyBox({ element, handleResize, removeElement, is_draft }) {
     return (
        <div key={element.id} className="mb-3 col-12">
            <ControlButtons
                elementId={element.id}
                onResize={handleResize}
                 onRemove={removeElement}
                 is_draft={is_draft}
            />
        </div>
    );
}

export default EmptyBox;