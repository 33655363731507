import * as CryptoJS from 'crypto-js'
import axios from 'axios'
import Cookies from 'js-cookie'

export const storageKey = 'MyFoodSource'

export const SaveObject = (key, value) => {
    if (key === 'authentication') {
        return SaveStorage(key, value)
    } else return SaveStorage(key, JSON.stringify(value))
}

export const ReadObject = (key) => {
    try {
        const data = JSON.parse(ReadStorage(key))
        return data
    } catch (error) {
        return error
    }
}

const Crypt = (value) => {
    return CryptoJS.AES.encrypt(value, storageKey).toString()
}

const Decrypt = (cvalue) => {
    const bytes = CryptoJS.AES.decrypt(cvalue, storageKey)
    return bytes.toString(CryptoJS.enc.Utf8)
}

export const ClearStorage = () => {
    localStorage.clear()
    sessionStorage.clear()
}

export const SaveStorage = (key, value) => {
    if (key === 'authentication') {
        Cookies.set('auth_token', value.jwt, { secure: true, sameSite: 'strict' })
    } else {
        const cvalue = Crypt(value)
        try {
            return localStorage.setItem(`${storageKey}::${key}`, cvalue)
        } catch (e) {
            return sessionStorage.setItem(`${storageKey}::${key}`, cvalue)
        }
    }
}

export const ReadStorage = (key) => {
    let cvalue = ``
    try {
        cvalue = localStorage.getItem(`${storageKey}::${key}`)
        if (!cvalue) cvalue = sessionStorage.getItem(`${storageKey}::${key}`)
    } catch (e) {
        cvalue = sessionStorage.getItem(`${storageKey}::${key}`)
    }
    if (cvalue !== '' && cvalue !== null) {
        return Decrypt(cvalue)
    }
    return false
}
