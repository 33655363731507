import styled from 'styled-components'

export const CardContainer = styled.div.attrs({})`
    background: var(--white-color);
    border-radius: 15px;
    box-shadow: 0px 1.5px 10px var(--lightshadow-color);
    max-width: 252px;
    margin-bottom: 24px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        transform: scale(1.1);
    }
`
export const CardImage = styled.div.attrs({})`
    height: 169px;
    width: 100%;
    background-color: var(--lightgrey-color);
    background: url(${(props) => props.image}) no-repeat center center / cover;
    border-radius: 15px;
    overflow: hidden;
`
export const CardContent = styled.div.attrs({})`
    padding: 20px;
`
export const CardTitle = styled.div.attrs({})`
    color: var(--grey-color);
    font-size: 17px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    text-align: center;
    margin-bottom: 14px;
`
export const CardText = styled.div.attrs({})`
    color: rgb(124 124 124);
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    margin-bottom: 24px;
`
