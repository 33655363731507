import React, { useContext } from 'react'

import { ProfileFormContent, ProfileFormActions, InputIcon } from './styled'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

import DashboardButton from 'components/Dashboard/Button'
// import { AvailablePanels } from 'services/data'

export default function ModalMyWidget() {
    const { userPanels, setUserPanels, setModal, profile, findlanguage, AvailablePanels, setLanguage } =
        useContext(CoreContext)

    const CPWidgets = AvailablePanels.find((fnd) => fnd.type === profile)?.panels

    const close = () => {
        setModal(null)
    }

    const isAdded = (item) => {
        return userPanels
            ?.find((fnd) => fnd.type === profile)
            ?.panels?.map((mit) => mit?.ref)
            .includes(item?.ref)
    }

    const toggleAdd = (addItem) => {
        const profilePanels = isAdded(addItem)
            ? userPanels.map((item) => {
                  if (item.type === profile) {
                      return {
                          ...item,
                          panels: item.panels.filter((fit) => fit.ref !== addItem.ref),
                      }
                  }
                  return item
              })
            : userPanels.map((item) => {
                  if (item.type === profile) {
                      return {
                          ...item,
                          panels: [...item.panels, addItem],
                      }
                  }
                  return item
              })

        setUserPanels(profilePanels)

        // setUserPanels( isAdded(item) ? userPanels.filter(fit => fit.ref !== item.ref) : [ ...userPanels, item ])
    }


    const changeLanguage = (changeLanguage) => {
        localStorage.setItem('language', changeLanguage)
        setLanguage(changeLanguage)
    }


    return (
        <>
            <ModalWrapper big>
                <div style={{ marginLeft: '13px', marginTop: '-10px' }} >
                    <InputIcon
                        onClick={() => changeLanguage('english')}
                        src={'/icons/usa-flag.svg'}
                    />
                    <InputIcon
                        onClick={() => changeLanguage('portugues')}
                        src={'/icons/brazil-flag.svg'}
                    />
                </div>
                <ProfileFormContent>
                    {CPWidgets?.map((item, key) => (
                        <DashboardButton
                            key={key}
                            primary
                            onClick={() => toggleAdd(item)}
                            outline={isAdded(item)}
                            icon={isAdded(item) ? 'check-green' : 'check'}
                        >
                            {item.title}
                        </DashboardButton>
                    ))}
                </ProfileFormContent>
                <ProfileFormActions>
                    <DashboardButton dark onClick={close}>
                        {findlanguage().apply}
                    </DashboardButton>
                </ProfileFormActions>
            </ModalWrapper>
        </>
    )
}
