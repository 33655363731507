import React, { useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Select from 'react-select';

export default function Section({ sections, setSections, currentStep, setCurrentStep, is_draft }) {
    const renameStep = (index, newName) => {
        const newSteps = sections.map((step, sIndex) => {
            if (sIndex === index) {
                return { ...step, name: newName };
            }
            return step;
        });
        setSections(newSteps);
    };

    const removeStep = (index) => {
        const newSteps = sections.filter((_, sIndex) => sIndex !== index);
        setSections(newSteps);
        if (currentStep >= newSteps.length) {
            setCurrentStep(newSteps.length - 1);
        }
    };

    const changeStepIcon = (index, newIcon) => {
        const newSteps = sections.map((step, sIndex) => {
            if (sIndex === index) {
                return { ...step, icon: newIcon };
            }
            return step;
        });
        setSections(newSteps);
    };

    const handleStepTypeChange = (index, typeValue) => {
        const newSteps = sections.map((step, sIndex) => {
            if (sIndex === index) {
                return { ...step, type: typeValue };
            }
            return step;
        });
        setSections(newSteps);
    };

    const handleApprovalNeededChange = (index, approvalNeeded) => {
        const newSteps = sections.map((step, sIndex) => {
            if (sIndex === index) {
                return { ...step, approval_needed: approvalNeeded.value };
            }
            return step;
        });
        setSections(newSteps);
    };

    const setPassingPercentage = (stepIndex, percentage) => {
        const newSteps = sections.map((step, index) => {
            if (index === stepIndex && step.type) {
                return { ...step, questions_to_pass: percentage.value };
            }
            return step;
        });
        setSections(newSteps);
    };

    const iconNames = {
        'gear': 'Settings',
        'check2-circle': 'Confirm',
        'x-circle': 'Cancel',
        'question-circle': 'Help',
        'exclamation-triangle': 'Warning',
        'plus-circle': 'Add',
        'dash-circle': 'Remove',
        'info-circle': 'Information',
        'house': 'Home',
        'pencil-square': 'Edit',
        'envelope': 'Mail',
        'star': 'Favorite',
        'lock': 'Security',
        'clock': 'Time',
        'trash': 'Delete',
        'file-earmark-text': 'Document',
        'camera': 'Camera',
        'heart': 'Love',
        'bell': 'Notification',
        'bookmark': 'Bookmark',
    };

    const availableIcons = Object.keys(iconNames).sort((a, b) => {
        const nameA = iconNames[a].toUpperCase();
        const nameB = iconNames[b].toUpperCase();
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    });

    const options = availableIcons.map(icon => ({
        value: icon,
        label: (<div><i className={`bi bi-${icon}`}></i> {iconNames[icon]}</div>)
    }));

    const approvalOptions = [
        { value: null, label: 'Select approval needed' },
        { value: false, label: 'No' },
        { value: true, label: 'Yes' }
    ];

    const passingPercentageOptions = Array.from({ length: 100 }, (_, i) => ({
        value: i + 1,
        label: `${i + 1}%`
    }));

    const customStyles = {
        control: (base) => ({
            ...base,
            border: 'none',
            boxShadow: 'none',
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, width: '300px', })
    };

    return (
        <div className="steps-scroll-container">
            <div className="steps-container">
                {sections.map((step, index) => (
                    <React.Fragment key={index}>
                        <div className={`step ${index === currentStep ? 'active' : ''}`}>
                            <div className="step-icon-and-delete">
                                <div className="step-icon" onClick={() => setCurrentStep(index)}>
                                    <i className={`bi bi-${step.icon}`}></i>
                                </div>
                                {sections.length > 1 && (
                                    <button
                                        className="delete-step"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            removeStep(index);
                                        }}
                                        title="Delete Step"
                                    >
                                        <i className="bi bi-trash"></i>
                                        {!is_draft ?? <i className="bi bi-trash"></i>}
                                    </button>
                                )}
                            </div>
                            <input
                                type="text"
                                value={step.name}
                                onChange={(e) => renameStep(index, e.target.value)}
                                className="step-title-input"
                                disabled={is_draft}
                            />
                            <Select
                                value={options.find(option => option.value === step.icon)}
                                onChange={(e) => changeStepIcon(index, e.value)}
                                options={options}
                                styles={customStyles}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                className="step-select step-icon-select"
                                isDisabled={is_draft}
                            />
                            <div style={{ marginTop: '5px' }}></div>
                            <Select
                                value={{ value: step.type === 'quiz' ? 'quiz' : 'form', label: step.type === 'quiz' ? 'Quiz' : 'Form' }}
                                onChange={(selectedOption) => handleStepTypeChange(index, selectedOption.value)}
                                options={[
                                    { value: 'quiz', label: 'Quiz' },
                                    { value: 'form', label: 'Form' },
                                ]}
                                styles={customStyles}
                                className="step-select step-type-select"
                                isDisabled={is_draft}
                            />
                            {sections[index]?.type === 'quiz' && (
                                <>
                                    <div style={{ marginTop: '5px' }}></div>
                                    <Select
                                        value={passingPercentageOptions.find(option => option.value === step.questions_to_pass)}
                                        onChange={(selectedOption) => setPassingPercentage(index, selectedOption)}
                                        options={passingPercentageOptions}
                                        className="step-select"
                                        menuPortalTarget={document.body}
                                        styles={customStyles}
                                        isDisabled={is_draft}
                                    />
                                </>
                            )}
                            <div style={{ marginTop: '5px' }}></div>
                            <Select
                                value={approvalOptions.find(option => option.value === step.approval_needed)}
                                onChange={(selectedOption) => handleApprovalNeededChange(index, selectedOption)}
                                options={approvalOptions}
                                styles={customStyles}
                                className="step-select step-approval-select"
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                isDisabled={is_draft}
                                placeholder="Approval need?"
                                required
                            />
                        </div>
                        {index < sections.length - 1 && <div className="step-line"></div>}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}
