import React, { useContext, useEffect } from 'react'
import FilterUsers from 'components/Dashboard/Filter'
import ContainerAuthenticated from 'containers/Authenticated'
import { Container, Row, Col } from 'reactstrap'
import { CoreContext } from 'context/CoreContext'
import { useHistory } from 'react-router-dom'

export default function Filter() {
    const { permissions } = useContext(CoreContext)
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    useEffect(() => {
        if (!permissions?.filter?.canUse) {
            navigate('dashboard')
        }
    }, [permissions, navigate]);

    return (
        <ContainerAuthenticated>
            <Container fluid={false}>
                <Row>
                    <Col>
                        <FilterUsers />
                    </Col>
                </Row>
            </Container>
        </ContainerAuthenticated>
    )
}
