import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const CardSearched = styled.div.attrs({})`
    min-width: calc(50% - 12.5px);
    max-width: calc(50% - 12.5px);
    background: var(--white-color);
    padding: 24px;
    margin-bottom: 25px;
    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);

    display: flex;
    align-items: flex-start;

    @media (max-width: ${mobileSize}px) {
        min-width: calc(100%);
        max-width: calc(100%);
    }
`
export const CardImage = styled.div.attrs({})`
    width: 76px;
    height: 76px;
    border-radius: 38px;
    background: url(${(props) => props.src}) no-repeat center center / cover;
    background-size: 76px;
`
export const CardContent = styled.div.attrs({})`
    padding: 0 20px;
    flex: 1;
`
export const CardTitle = styled.div.attrs({})`
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--grey-color);
    margin-bottom: 3px;
`
export const CardSubtitle = styled.div.attrs({})`
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: #acacac;
    margin-bottom: 3px;
`
export const CardContentLabel = styled.div.attrs({})`
    /* display: flex;
    align-items: center; */
    margin: 14px 0;
`
export const CardLabelText = styled.div.attrs({})`
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--grey-color);
`
export const CardLabelIcon = styled.img.attrs((props) => ({
    src: `/icons/${props.icon}.svg`,
    width: 25,
}))`
    margin-left: 12px;
`
export const CardLink = styled.div.attrs({})`
    font-size: 15px;
    color: var(--blue-color);
    text-decoration: underline;
    cursor: pointer;
`
export const CardCertificationTitle = styled.div.attrs({})`
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--grey-color);
    margin-top: 16px;
`
export const CardCertificationWrapper = styled.div.attrs({})`
    margin-top: 16px;

    display: flex;
    flex-wrap: wrap;
`
export const CardCertificationItem = styled.div.attrs({})`
    display: flex;
    align-items: center;
    margin-right: 18px;
`
export const CardCertificationCheck = styled.div.attrs({})`
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: var(--primary-color);
    margin-right: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
`
export const CardCertificationCheckIn = styled.img.attrs({
    src: '/icons/check.svg',
})``
export const CardCertificationText = styled.div.attrs({})`
    font-size: 14px;
    color: var(--grey-color);
`
export const CardTouch = styled.div.attrs({})`
    cursor: pointer;
`

export const CardProfileContent = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: center;
`
