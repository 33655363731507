import React from 'react'
import { LiveChatWidget } from '@livechat/widget-react'

import {
    HeaderContainerDecoration,
    HeaderDecoration,
    HeaderContainer,
    HeaderContentFull,
} from './styled'

import { Container } from 'reactstrap'
import HeaderMenu from './Menu'
import HeaderNotifications from './Notifications'

export default function DashboardHeader({ adminView }) {
    return (
        <>
            <LiveChatWidget license="15303942" group="0" />

            {/* <div data-id="VeK0Y4rY_dl" class="livechat_button">
                <a
                    href="https://www.livechat.com/
                ?utm_source=chat_button&utm_campaign=lc_15303942"
                >
                    Live Chat
                </a>
            </div> */}

            <HeaderContainer>
                <HeaderContainerDecoration>
                    <HeaderDecoration></HeaderDecoration>
                </HeaderContainerDecoration>
                <Container fluid>
                    <HeaderContentFull>
                        <HeaderNotifications adminView={adminView} />
                        <HeaderMenu adminView={adminView} />
                    </HeaderContentFull>
                </Container>
            </HeaderContainer>
        </>
    )
}
