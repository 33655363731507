import styled from 'styled-components'

export const Content = styled.div.attrs({})``

export const ButtonColor = styled.div.attrs({})`
    ${(props) =>
        props.outline
            ? `
            border: 1px solid var(--lightgrey-color);
            color: var(--lightgrey-color);
        `
            : `
            background: var(--lightgrey-color);
            color: var(--white-color);
        `}
    height: 56px;
    width: 100%;
    max-width: 353px;
    border-radius: 30px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
    font-weight: bold;
    font-size: 14px;
    margin-top: 12px;

    cursor: pointer;
    ${(props) =>
        props.primary
            ? `
            ${
                props.outline
                    ? `
                    border: 1px solid var(--primary-color);
                    color: var(--primary-color);
                `
                    : `
                    background: var(--primary-color);
                    color: var(--white-color);
                `
            }
        `
            : ``}
    ${(props) =>
        props.secondary
            ? ` 
            ${
                props.outline
                    ? `
                    border: 1px solid var(--secondary-color);
                    color: var(--secondary-color);
                `
                    : `
                    background: var(--secondary-color);
                    color: var(--white-color);
                `
            }
        `
            : ``}
    ${(props) =>
        props.red
            ? ` 
            ${
                props.outline
                    ? `
                    border: 1px solid var(--red-color);
                    color: var(--red-color);
                `
                    : `
                    background: var(--red-color);
                    color: var(--white-color);
                `
            }
        `
            : ``}
    ${(props) =>
        props.gray
            ? ` 
            ${
                props.outline
                    ? `
                    border: 1px solid gray;
                    color: gray;
                `
                    : `
                    background: gray;
                    color: var(--white-color);
                `
            }
        `
            : ``}
    ${(props) =>
        props.white
            ? ` 
            ${
                props.outline
                    ? `
                    border: 1px solid var(--white-color);
                    color: var(--lightgrey-color);
                `
                    : `
                    background: var(--white-color);
                    color: var(--lightgrey-color);
                `
            }
        `
            : ``}
        
    ${(props) =>
        props.whiteAndBlack
            ? ` 
                ${
                    props.outline
                        ? `
                        border: 1px solid var(--white-color);
                        color: #000;
                        font-weight: 600;
                        font-size: 16px;
                    `
                        : `
                        background: var(--white-color);
                        color: #000;
                        font-weight: 600;
                        font-size: 16px;
                    `
                }
            `
            : ``}        

    ${(props) =>
        props.centred
            ? `
            margin: 12px auto;
        `
            : ` 
        `}
    ${(props) =>
        props.small
            ? `
            max-width: 171px;
        `
            : ` 
        `}
    ${(props) =>
        props.tiny
            ? `
            max-width: 80px;
            height: 35px;
        `
            : ` 
        `}        
    ${(props) =>
        props.micro
            ? `
            max-width: 40px;
            height: 35px;
        `
            : ` 
        `}        
                
    &:hover {
        box-shadow: 2px 2px 3px var(--shadow-color);
    }
`
