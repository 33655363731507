import React, { useEffect, useState, useContext } from 'react'
import { PageCard } from '../../../../../Panels/SMSControl/styled'
import { TitleGreen } from '../styled'
import { GetEstablishmentData, PutEstablishmentData, PostEstablishmentData } from 'services/egs'
import { ReadMe } from 'services/authentication'
import { CoreContext } from 'context/CoreContext'

const labelTextCss = { fontSize: '14px', color: 'rgb(86, 86, 86)' }
const labelCss = { fontWeight: 'bold', fontSize: '15px', color: 'rgb(86, 86, 86)' }
const inputCss = { borderColor: '#70707059' }
const col6 = { fontSize: '700', color: '#707070' }
const colWithMargin = { fontSize: '700', color: '#707070', marginTop: '22px' }
const doneTypingInterval = 2000
const spacer = { borderTop: '0.1px solid rgb(235 235 235)' }

let typingTimer

export default function EstablishmentData({ form, backOffice, company_id }) {
    const { findlanguageEsg, findlanguage, user } = useContext(CoreContext)
    const [establishmentData, setEstablishmentData] = useState({
        regular_employes_female: 0,
        regular_employes_male: 0,
        regular_employes_total: 0,
        quality_employes_female: 0,
        quality_employes_male: 0,
        quality_employes_total: 0,
        company_association_cooperative: false,
        company_association_cooperative_name: '',
        company_minority_groups: false,
        company_minority_groups_name: '',
    })

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        waitUserFinishTyping()
    }, [establishmentData])

    const init = async () => {
        const getEstablishmentData = await GetEstablishmentData(backOffice ? company_id : user.company_id)

        if (getEstablishmentData.statusText !== 'Not Found' && getEstablishmentData.length !== 0)
            setEstablishmentData(getEstablishmentData[0])
        else {
            setEstablishmentData(
                await PostEstablishmentData({ users_permissions_user: backOffice ? company_id : user.company_id })
            )
        }
    }

    function changeQuestionaire(value, field) {
        setEstablishmentData({
            ...establishmentData,
            [field]: value,
        })

        waitUserFinishTyping()
    }

    function changeQuestionaireTrueFalse(value) {
        setEstablishmentData({
            ...establishmentData,
            company_association_cooperative: value === 'true',
            company_association_cooperative_name:
                value === 'false' ? '' : establishmentData.company_association_cooperative_name,
        })

        waitUserFinishTyping()
    }

    function changeQuestionaireMinorityTrueFalse(value) {
        setEstablishmentData({
            ...establishmentData,
            company_minority_groups: value === 'true',
            company_minority_groups_name:
                value === 'false' ? '' : establishmentData.company_minority_groups_name,
        })

        waitUserFinishTyping()
    }

    function waitUserFinishTyping() {
        clearTimeout(typingTimer)
        typingTimer = setTimeout(saveEstablishmentData, doneTypingInterval)
    }

    const saveEstablishmentData = async () => {
        if (establishmentData.id !== -1 && !backOffice) await PutEstablishmentData(establishmentData)
    }

    return (
        <PageCard>
            <TitleGreen>{findlanguageEsg().establishmentData}</TitleGreen>
            <div className="row">
                <div className="col-12" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguageEsg().numberOfEmployees}
                    </label>
                </div>
            </div>
            <div className="row">
                <div className="col-4" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguageEsg().female}
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        style={inputCss}
                        value={establishmentData.regular_employes_female}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'regular_employes_female')
                        }
                        disabled={form}
                    />
                </div>
                <div className="col-4" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguageEsg().male}
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        style={inputCss}
                        value={establishmentData.regular_employes_male}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'regular_employes_male')
                        }
                        disabled={form}
                    />
                </div>
                <div className="col-4" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguageEsg().total}
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        style={inputCss}
                        value={
                            Number(establishmentData.regular_employes_female) +
                            Number(establishmentData.regular_employes_male)
                        }
                        disabled
                    />
                </div>
            </div>
            <div className="mb-3" style={spacer}></div>
            <div className="row">
                <div className="col-12" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguageEsg().numberEmployeeQualityDept}
                    </label>
                </div>
            </div>
            <div className="row">
                <div className="col-4" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguageEsg().female}
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        style={inputCss}
                        value={establishmentData.quality_employes_female}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'quality_employes_female')
                        }
                        disabled={form}
                    />
                </div>
                <div className="col-4" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguageEsg().male}
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        style={inputCss}
                        value={establishmentData.quality_employes_male}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'quality_employes_male')
                        }
                        disabled={form}
                    />
                </div>
                <div className="col-4" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguageEsg().total}
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        style={inputCss}
                        value={
                            Number(establishmentData.quality_employes_female) +
                            Number(establishmentData.quality_employes_male)
                        }
                        disabled
                    />
                </div>
            </div>
            <div className="mb-3" style={spacer}></div>
            <div className="row">
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguageEsg().associationOrCooperative}
                    </label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={establishmentData.company_association_cooperative}
                        onChange={(event) =>
                            changeQuestionaireTrueFalse(
                                event.target.value,
                                'company_association_cooperative'
                            )
                        }
                        disabled={form}
                    >
                        <option value={false}>{findlanguageEsg().optionNo}</option>
                        <option value={true}>{findlanguageEsg().optionYes}</option>
                    </select>
                </div>
                {establishmentData.company_association_cooperative ? (
                    <div className="col-6">
                        <label className="form-label" style={labelCss}>
                            {findlanguage().name}:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={establishmentData.company_association_cooperative_name}
                            onChange={(event) =>
                                changeQuestionaire(
                                    event.target.value,
                                    'company_association_cooperative_name'
                                )
                            }
                            disabled={form}
                        />
                    </div>
                ) : null}
            </div>
            <div className="mb-3" style={spacer}></div>
            <div className="row">
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguageEsg().supportMinorityGroup}
                    </label>
                    <br />
                    <label className="form-label" style={labelTextCss}>
                        {findlanguageEsg().exampleMinorityGroup}
                    </label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={establishmentData.company_minority_groups}
                        onChange={(event) =>
                            changeQuestionaireMinorityTrueFalse(event.target.value)
                        }
                        disabled={form}
                    >
                        <option value={false}>{findlanguageEsg().optionNo}</option>
                        <option value={true}>{findlanguageEsg().optionYes}</option>
                    </select>
                </div>
                {establishmentData.company_minority_groups ? (
                    <div className="col-6" style={{ marginTop: '31px' }}>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().name}:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={establishmentData.company_minority_groups_name}
                            onChange={(event) =>
                                changeQuestionaire(
                                    event.target.value,
                                    'company_minority_groups_name'
                                )
                            }
                            disabled={form}
                        />
                    </div>
                ) : null}
            </div>
        </PageCard>
    )
}
