import React from 'react'
import { useHistory } from 'react-router-dom'

import { BodyContentTable, TableLine, LineText } from './styled'

export default function DashboardUserDetailsTabCompany({ infos }) {
    const history = useHistory()

    return (
        <>
            <BodyContentTable>
                {infos?.map((item, key) => (
                    <TableLine key={key} odd={key % 2 === 0}>
                        <LineText>{item.title}</LineText>
                        {item.link ? (
                            <LineText link onClick={item.action}>
                                {item.link}
                            </LineText>
                        ) : null}
                        {item.text ? <LineText text>{item.text}</LineText> : null}
                    </TableLine>
                ))}
            </BodyContentTable>
        </>
    )
}
