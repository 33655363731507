import { DELETE, GET, POST, PUT } from './api'
// import { ReadObject, SaveObject } from './storage'

export const FetchWishlist = async () => {
    return await GET(`/wishlists/zara`, true)
}

export const NewWishlist = async (user_wish_id) => {
    return await POST(`/wishlists`, { user_wish_id: user_wish_id }, true)
}

export const RemoveWishlist = async (connectionId) => {
    return await DELETE(`/wishlists/${connectionId}`, true)
}
