import React, { useContext, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import _ from 'lodash'
import { UploadPDF } from 'services/api'
import { parseStrapiImage } from 'utils'
import { CoreContext } from 'context/CoreContext'

import { CardHeader, CardHeaderSubTitle, Paragraph, CardDocument } from './styled'

export default function Forms({ form, goBack, setForm, saveForm, overviewId }) {
    const { findlanguage, permissions } = useContext(CoreContext)
    const labelCss = { fontWeight: 'bold', fontSize: '15px', color: 'rgb(86, 86, 86)' }
    const inputCss = { borderColor: '#70707059' }
    const paddingTop = { paddingTop: '5px' }
    const [modalOpen, setModalOpen] = useState(true)
    const [isSavingFile, setIsSavingFile] = useState(false)

    const editForm = async (id, value, checked, type = '', formId = '') => {
        let copyForm = _.cloneDeep(form)

        if (type === 'checkbox') {
            const field = copyForm.form_body.find((x) => x.id === id)
            if (checked) {
                // add value to the array if checkbox is checked
                field.value = field.value ? [...field.value.split('|'), value].join('|') : value
            } else {
                // remove value from the array if checkbox is unchecked
                field.value = field.value
                    .split('|')
                    .filter((val) => val !== value)
                    .join('|')
            }
        } else if (type === '') {
            copyForm.form_body.find((x) => x.id === id).value = value
        } else {
            setIsSavingFile(true)
            const result = await UploadPDF(value, id)
            setIsSavingFile(false)

            copyForm.form_body.find((x) => x.id === id).filePdf = result[0].media
        }

        setForm(copyForm)
    }

    const removeMedia = async (id) => {
        let copyForm = _.cloneDeep(form)

        copyForm.form_body.find((x) => x.id === id).filePdf = null

        setForm(copyForm)
    }

    const elements = (x) => {
        switch (x.type) {
            case 'title':
                return (
                    <div
                        key={x.id}
                        disabled={
                            (form.status === 'Condition' || form.status === 'Denied') && permissions?.myBuyers?.canEdit ? false : true
                        }
                        className="col-12"
                    >
                        <CardHeader>{x.title}</CardHeader>{' '}
                    </div>
                )
            case 'subtitle':
                return (
                    <div
                        key={x.id}
                        disabled={
                            (form.status === 'Condition' || form.status === 'Denied') && permissions?.myBuyers?.canEdit ? false : true
                        }
                        className="col-12"
                    >
                        <CardHeaderSubTitle>{x.title}</CardHeaderSubTitle>{' '}
                    </div>
                )
            case 'description':
                return (
                    <div
                        key={x.id}
                        disabled={
                            (form.status === 'Condition' || form.status === 'Denied') && permissions?.myBuyers?.canEdit ? false : true
                        }
                        className="col-12"
                    >
                        <Paragraph>{x.title}</Paragraph>
                    </div>
                )
            case 'text':
                return (
                    <div key={x.id} className="col-6" style={paddingTop}>
                        <label className="form-label" style={labelCss}>
                            {x.title}
                        </label>
                        <input
                            disabled={
                                (form.status === 'Condition' || form.status === 'Denied') && permissions?.myBuyers?.canEdit
                                    ? false
                                    : true
                            }
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={x.value === undefined ? '' : x.value}
                            onChange={(e) => editForm(x.id, e.target.value)}
                        />
                    </div>
                )
            case 'dropdown':
                return (
                    <div key={x.id} className="col-6" style={paddingTop}>
                        <label className="form-label" style={labelCss}>
                            {x.title}
                        </label>
                        <select
                            disabled={
                                (form.status === 'Condition' || form.status === 'Denied') && permissions?.myBuyers?.canEdit
                                    ? false
                                    : true
                            }
                            className="form-select"
                            aria-label="Default select example"
                            value={x.value === undefined ? '' : x.value}
                            onChange={(e) => editForm(x.id, e.target.value)}
                        >
                            <option value="">{findlanguage().selectAOption}</option>
                            {x.options.map((y) => {
                                return <option value={y}>{y}</option>
                            })}
                        </select>
                    </div>
                )
            case 'checkbox':
                return (
                    <div key={x.id} className="col-12" style={paddingTop}>
                        <label className="form-label" style={labelCss}>
                            {x.title}
                        </label>
                        {x.options.map((y, index) => {
                            return (
                                <div className="form-check" key={index}>
                                    <input
                                        disabled={
                                            (form.status === 'Condition' || form.status === 'Denied') && permissions?.myBuyers?.canEdit
                                                ? false
                                                : true
                                        }
                                        className="form-check-input"
                                        type="checkbox"
                                        name={`gridRadios${x.id}`}
                                        id={`gridRadios${x.id}_${index}`}
                                        value={y}
                                        onChange={(e) =>
                                            editForm(x.id, y, e.target.checked, 'checkbox')
                                        }
                                        checked={x.value?.split('|').includes(y) || false}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor={`gridRadios${x.id}_${index}`}
                                    >
                                        {y}
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                )
            case 'date':
                return (
                    <div key={x.id} className="col-6" style={paddingTop}>
                        <label className="form-label" style={labelCss}>
                            {x.title}
                        </label>
                        <DatePicker
                            disabled={
                                (form.status === 'Condition' || form.status === 'Denied') && permissions?.myBuyers?.canEdit
                                    ? false
                                    : true
                            }
                            className="form-control"
                            selected={
                                x.value === undefined || x.value === null
                                    ? new Date()
                                    : new Date(x.value)
                            }
                            onChange={(date) => editForm(x.id, date)}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat={findlanguage().dateFormat}
                        />
                    </div>
                )
            case 'media':
                return (
                    <div key={x.id} className="col-6" style={paddingTop}>
                        <label className="form-label" style={labelCss}>
                            {x.title}
                        </label>
                        {(form.status === 'Condition' || form.status === 'Denied') && permissions?.myBuyers?.canEdit ? (
                            <>
                                {x?.filePdf === null ? (
                                    <input
                                        id={'formFileMultiple' + x.id}
                                        className="form-control"
                                        type="file"
                                        onChange={(e) => {
                                            const file = e.target.files[0] // get the file from the event object
                                            if (file) {
                                                editForm(
                                                    x.id,
                                                    file,
                                                    'media',
                                                    'formFileMultiple' + x.id
                                                )
                                            } else {
                                                console.error('No file chosen')
                                            }
                                        }}
                                        accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsxf,.xlsx,.xls,.zip,.rar,.7zip, .xlsm, .jpg, .jpeg, .png"
                                    />
                                ) : (
                                    <div>
                                        <input
                                            id={'formFileMultiple' + x.id}
                                            className="form-control"
                                            type="file"
                                            onChange={(e) => {
                                                const file = e.target.files[0] // get the file from the event object
                                                if (file) {
                                                    editForm(
                                                        x.id,
                                                        file,
                                                        'media',
                                                        'formFileMultiple' + x.id
                                                    )
                                                } else {
                                                    console.error('No file chosen')
                                                }
                                            }}
                                            accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsxf,.xlsx,.xls,.zip,.rar,.7zip, .xlsm, .jpg, .jpeg, .png"
                                        />
                                        <div style={{ textAlign: 'center' }}>
                                            <b>{findlanguage().currentFile}</b> {x?.filePdf?.name}{' '}
                                            <a href={parseStrapiImage(x?.filePdf?.url)}>Download</a>
                                            <br />
                                            <b>{findlanguage().uploadFileNotice}</b>
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <br />
                                {x?.filePdf === null ? (
                                    <span
                                        style={{
                                            fontSize: '13px',
                                            color: 'white',
                                            height: '35px',
                                            padding: '10px',
                                            width: '100%',
                                        }}
                                        className="badge bg-danger"
                                    >
                                        {findlanguage().noFileSelected}
                                    </span>
                                ) : (
                                    <div style={{ marginTop: '5px', display: 'flex' }}>
                                        <CardDocument />
                                        <a
                                            style={{
                                                fontSize: '13px',
                                                color: 'white',
                                                height: '35px',
                                                padding: '10px',
                                                marginLeft: '10px',
                                                width: '100%',
                                                backgroundColor: '#263640',
                                            }}
                                            className="badge"
                                            href={parseStrapiImage(x?.filePdf?.url)}
                                            download={parseStrapiImage(x?.filePdf?.url)}
                                        >
                                            Download {x?.filePdf?.name}
                                        </a>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                )
            default:
                return null
        }
    }

    const saveAll = () => {
        let copyForm = _.cloneDeep(form)
        copyForm.status = 'Pending'
        setForm(copyForm)
        goBack()
        saveForm(overviewId, form)
        setModalOpen(false)
    }

    return (
        <>
            {modalOpen && (
                <div className="modal show" tabIndex="-1" style={{ display: 'block' }}>
                    <div className="modal-dialog  modal-xl">
                        <div className="modal-content" style={{ zIndex: '111111' }}>
                            <div className="modal-header">
                                <h5 className="modal-title"><b>{findlanguage().typeOfRequirements}:</b>  {form?.name}</h5>
                            </div>
                            <div className="modal-body">
                                {form.status === 'Denied' ? (
                                    <>
                                        <div className="row">
                                            <div className="col-12">
                                                <label className="form-label" style={labelCss}>
                                                    {findlanguage().reasonBuyerDenied}
                                                </label>
                                                <select
                                                    className="form-select"
                                                    disabled={true}
                                                    aria-label="Default select example"
                                                    value={form.reason}
                                                >
                                                    <option value="">{findlanguage().selectAOption}</option>
                                                    <option value="Missing requirements">
                                                        {findlanguage().missingRequirements}
                                                    </option>
                                                    <option value="Invalid Document">
                                                        {findlanguage().invalidDocument}
                                                    </option>
                                                    <option value="Incomplete Document">
                                                        {findlanguage().incompleteDocument}
                                                    </option>
                                                    <option value="Corrupt Document">
                                                        {findlanguage().corruptDocument}
                                                    </option>
                                                </select>
                                                <br />
                                                <label className="form-label" style={labelCss}>
                                                    {findlanguage().buyerResponse}
                                                </label>
                                                <textarea
                                                    disabled={true}
                                                    type="text"
                                                    className="form-control"
                                                    style={inputCss}
                                                    value={form.buyerResponse}
                                                />
                                            </div>
                                        </div>
                                        <br />
                                    </>
                                ) : null}
                                <div className="row">{form.form_body.map((x) => elements(x))}</div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    onClick={() => {
                                        goBack()
                                        setModalOpen(false)
                                    }}
                                    style={{ marginRight: '5px' }}
                                    type="button"
                                    className="btn btn-secondary"
                                >
                                    {findlanguage().goBack}
                                </button>
                                {(form.status === 'Condition' || form.status === 'Denied') && permissions?.myBuyers?.canEdit ? (
                                    <button
                                        onClick={saveAll}
                                        style={{ backgroundColor: '#8dbd64', border: '1px solid #8dbd64' }}
                                        type="button"
                                        className="btn btn-success"
                                        disabled={isSavingFile}
                                    >
                                        {findlanguage().save}
                                    </button>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop show"></div>
                </div>
            )}
        </>
    )
}
