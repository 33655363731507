import styled from 'styled-components'
import { PieChart } from 'react-charts-d3'
import * as d3 from 'd3'

export const Content = styled.div.attrs({})``

export const ESGPieChart = styled(PieChart).attrs({
    useColorScale: false,
    displayLabels: true,
    valueFormatter: d3.format('.0n'),
})``
