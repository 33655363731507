import React, { useContext, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import DashboardButton from 'components/Dashboard/Button'
import { CoreContext } from 'context/CoreContext'
import _ from 'lodash'
import { parseStrapiImage } from 'utils'
import {
    FindValidationProcessForSuppliers,
    UpdateFormRequestForSupplier,
    UpdateForm,
} from 'services/validationProcess'
import { NotificationManagerAdd } from 'services/admin'
import { CardHeader, CardHeaderSubTitle, Paragraph, CardDocument } from './styled'

export default function Reports({ form, goBack, changeUsersWithForm, setForm, init }) {
    const { profile, setModal, user, findlanguage, permissions } = useContext(CoreContext)
    const labelCss = { fontWeight: 'bold', fontSize: '15px', color: 'rgb(86, 86, 86)' }
    const inputCss = { borderColor: '#70707059' }
    const paddingTop = { paddingTop: '5px' }
    const [modalOpen, setModalOpen] = useState(true);

    const reviewSupplier = () => {
        goBack()
        setModalOpen(false)
        setModal({
            type: 'review-supplier',
            title: findlanguage().reviewSupplierForm,
            form: form,
            saveForm: saveForm,
        })
    }

    const reviewDateForSupplier = () => {
        goBack()
        setModalOpen(false)
        setModal({
            type: 'review-date-for-supplier',
            title: findlanguage().chooseExpirationDate,
            form: form,
            saveForm: saveForm,
        })
    }

    const saveForm = async (accept, reason, buyerResponse, expirationDate, reminderDays) => {
        let copyForm = _.cloneDeep(form)

        copyForm.status = accept ? 'Accepted' : 'Denied'
        copyForm.name_who_accepted = user.contactName

        copyForm.buyerResponse = buyerResponse
        copyForm.reason = reason

        if (accept) {
            copyForm.acceptedDate = new Date()
            copyForm.expirationDate = expirationDate
            copyForm.reminderDays = reminderDays
        }

        changeUsersWithForm(copyForm)

        const allGood = await UpdateFormRequestForSupplier(copyForm)
        setForm({
            ...copyForm,
            id: -1,
        })

        if (allGood) {
            setModal({
                type: 'alert',
                text: findlanguage().formSaved,
            })

            if (copyForm.private) {
                if (accept) {
                    await NotificationManagerAdd(
                        'document_accepted',
                        user.name,
                        user.name,
                        user,
                        'full',
                        form.name === '' || form.name === null ? form.file.name : form.name
                    )
                } else {
                    await NotificationManagerAdd(
                        'document_not_accepted',
                        user.name,
                        user.name,
                        user,
                        'full'
                    )
                }
            }
            else {
                if (accept) {
                    await NotificationManagerAdd(
                        'document_accepted',
                        form.toUser,
                        user.name,
                        user,
                        'full',
                        form.name === '' || form.name === null ? form.file.name : form.name
                    )
                } else {
                    await NotificationManagerAdd(
                        'document_not_accepted',
                        form.toUser,
                        user.name,
                        user,
                        'full'
                    )
                }
            }
            init()
        } else {
            setModal({
                type: 'alert',
                text: findlanguage().somethingWentWrong,
            })
        }
        setModalOpen(false)
    }

    const elements = (x) => {
        switch (x.type) {
            case 'title':
                return (
                    <div key={x.id} disabled={true} className="col-12">
                        <CardHeader>{x.title}</CardHeader>{' '}
                    </div>
                )
                break
            case 'subtitle':
                return (
                    <div key={x.id} disabled={true} className="col-12">
                        <CardHeaderSubTitle>{x.title}</CardHeaderSubTitle>{' '}
                    </div>
                )
                break
            case 'description':
                return (
                    <div key={x.id} disabled={true} className="col-12">
                        <Paragraph>{x.title}</Paragraph>
                    </div>
                )
                break
            case 'text':
                return (
                    <div key={x.id} className="col-6" style={paddingTop}>
                        <label className="form-label" style={labelCss}>
                            {x.title}
                        </label>
                        <input
                            disabled={true}
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={x.value === undefined ? '' : x.value}
                        />
                    </div>
                )
                break
            case 'dropdown':
                return (
                    <div key={x.id} className="col-6" style={paddingTop}>
                        <label className="form-label" style={labelCss}>
                            {x.title}
                        </label>
                        <select
                            disabled={true}
                            className="form-select"
                            aria-label="Default select example"
                            value={x.value === undefined ? '' : x.value}
                        >
                            <option value="">{findlanguage().selectAOption}</option>
                            {x.options.map((y) => {
                                return (
                                    <option key={y} value={y}>
                                        {y}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                )
                break
            case 'checkbox':
                return (
                    <div key={x.id} className="col-12" style={paddingTop}>
                        <label className="form-label" style={labelCss}>
                            {x.title}
                        </label>
                        {x.options.map((y, index) => {
                            return (
                                <div className="form-check">
                                    <input
                                        disabled={
                                            form.status === 'Condition' || form.status === 'Denied'
                                                ? false
                                                : true
                                        }
                                        className="form-check-input"
                                        type="checkbox"
                                        name={`gridRadios${x.id}`}
                                        id={`gridRadios${x.id}_${index}`}
                                        value={y}
                                        checked={x.value?.split('|').includes(y) || false}
                                        accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsxf,.xlsx,.xls,.zip,.rar,.7zip, .xlsm, .jpg, .jpeg, .png"
                                    />
                                    <label
                                        className="form-check-label"
                                        for={`gridRadios${x.id}_${index}`}
                                    >
                                        {y}
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                )
                break
            case 'date':
                return (
                    <div key={x.id} className="col-6" style={paddingTop}>
                        <label className="form-label" style={labelCss}>
                            {x.title}
                        </label>
                        <DatePicker
                            dateFormat={findlanguage().dateFormat}
                            disabled={true}
                            className="form-control"
                            selected={
                                x.value === undefined || x.value === null
                                    ? new Date()
                                    : new Date(x.value)
                            }
                        />
                    </div>
                )
                break
            case 'media':
                return (
                    <div key={x.id} className="col-6" style={paddingTop}>
                        <label className="form-label" style={labelCss}>
                            {x.title}
                        </label>
                        <br />
                        {x?.filePdf === null ? (
                            <span
                                style={{
                                    fontSize: '13px',
                                    color: 'white',
                                    height: '35px',
                                    padding: '10px',
                                    width: '100%',
                                }}
                                className="badge bg-danger"
                            >
                                {findlanguage().noFileSelected}
                            </span>
                        ) : (
                            <div style={{ marginTop: '5px', display: 'flex' }}>
                                <CardDocument />
                                <a
                                    style={{
                                        fontSize: '13px',
                                        color: 'white',
                                        height: '35px',
                                        padding: '10px',
                                        marginLeft: '10px',
                                        width: '100%',
                                        backgroundColor: '#263640',
                                    }}
                                    className="badge"
                                    href={parseStrapiImage(x?.filePdf?.url)}
                                    download={x?.filePdf?.name}
                                >
                                    Download {x?.filePdf?.name}
                                </a>
                            </div>
                        )}
                    </div>
                )
                break
                default:
                break;
        }
    }

    return (
        <>
            {modalOpen && (
                <div className="modal show" tabIndex="-1" style={{ display: 'block' }}>
                    <div className="modal-dialog  modal-xl">
                        <div className="modal-content" style={{ zIndex: '111111' }}>
                            <div className="modal-header">
                                <h5 className="modal-title"><b>{findlanguage().typeOfRequirements}:</b>  {form?.form?.name}</h5>
                            </div>
                            <div className="modal-body">
                                {form.status === 'Denied' ? (
                                    <>
                                        <div className="row">
                                            <div className="col-12">
                                                <label className="form-label" style={labelCss}>
                                                    {findlanguage().reason}
                                                </label>
                                                <select
                                                    className="form-select"
                                                    disabled={true}
                                                    aria-label="Default select example"
                                                    value={form.reason}
                                                >
                                                    <option value="">{findlanguage().selectAOption}</option>
                                                    <option value="Missing requirements">
                                                        {findlanguage().missingRequirements}
                                                    </option>
                                                    <option value="Invalid Document">
                                                        {findlanguage().invalidDocument}
                                                    </option>
                                                    <option value="Incomplete Document">
                                                        {findlanguage().incompleteDocument}
                                                    </option>
                                                    <option value="Corrupt Document">
                                                        {findlanguage().corruptDocument}
                                                    </option>
                                                </select>
                                                <br />
                                                <label className="form-label" style={labelCss}>
                                                    {findlanguage().buyerResponse}
                                                </label>
                                                <textarea
                                                    disabled={true}
                                                    type="text"
                                                    className="form-control"
                                                    style={inputCss}
                                                    value={form.buyerResponse}
                                                />
                                            </div>
                                        </div>
                                        <br />
                                    </>
                                ) : null}
                                <div className="row">{form.form_body.map((x) => elements(x))}</div>
                            </div>
                            <div className="modal-footer">
                                <div>
                                    <button
                                        onClick={goBack}
                                        type="button"
                                        className="btn btn-secondary"
                                        style={{ marginRight: '5px' }}
                                    >
                                        {findlanguage().goBack}
                                    </button>
                                    {form.status === 'Pending' && permissions?.mySuppliers?.canEdit ? (
                                        <>
                                            <button
                                                onClick={reviewSupplier}
                                                style={{
                                                    marginRight: '5px',
                                                    backgroundColor: '#db3939',
                                                    border: '1px solid #db3939',
                                                }}
                                                type="button"
                                                className="btn btn-success"
                                            >
                                                {findlanguage().decline}
                                            </button>
                                            <button
                                                onClick={reviewDateForSupplier}
                                                style={{ backgroundColor: '#263640', border: '1px solid #263640' }}
                                                type="button"
                                                className="btn btn-success"
                                            >
                                                {findlanguage().accept}
                                            </button>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop show"></div>
                </div>
            )}

        </>
    )
}
