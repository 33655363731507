import React, { useState, useMemo, useContext } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CoreContext } from 'context/CoreContext';

const ProductsStatusChart = ({ showDataLabels, supplierRequest }) => {
    const { findlanguage } = useContext(CoreContext);
    const [selectedProducts, setSelectedProducts] = useState('All');

    const categories = useMemo(() => {
        const uniqueCategories = new Set(supplierRequest.map(item => item.products).filter(item => item));
        return ['All', ...Array.from(uniqueCategories)];
    }, [supplierRequest]);

    const chartData = useMemo(() => {
        const productsCounts = categories.reduce((acc, products) => (products !== 'All' ? { ...acc, [products]: 0 } : acc), {});

        supplierRequest.forEach(item => {
            if (item.products) {
                if (selectedProducts === 'All' || item.products === selectedProducts) {
                    productsCounts[item.products]++;
                }
            }
        });

        const filteredLabels = Object.keys(productsCounts).filter(key => productsCounts[key] > 0);
        const filteredData = filteredLabels.map(key => productsCounts[key]);

        return {
            labels: filteredLabels,
            datasets: [{
                data: filteredData,
                backgroundColor: [
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(128, 128, 128, 0.6)'
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(128, 128, 128, 1)'
                ],
                borderWidth: 1
            }]
        };
    }, [supplierRequest, selectedProducts, categories]);

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    usePointStyle: true,
                }
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.label}: ${tooltipItem.raw}`;
                    }
                }
            },
            datalabels: {
                display: true,
                color: showDataLabels ? 'black' : 'white',
                align: 'center',
                font: {
                    weight: 'bold',
                    size: 14
                },
                formatter: (value, context) => {
                    if (showDataLabels) {
                        const label = context.chart.data.labels[context.dataIndex];
                        return `${label}: ${value}`;
                    } else {
                        return value;
                    }
                }
            }
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                bottom: 99,
                top: 0
            }
        },
        radius: '100%'
    };

    return (
        <div style={{ height: '385px' }}>
            <select value={selectedProducts} onChange={e => setSelectedProducts(e.target.value)} style={{ marginBottom: '10px' }}>
                {categories.map(products => (
                    <option key={products} value={products}>
                        {products === 'All' ? 'All Products' : products}
                    </option>
                ))}
            </select>
            <h2>{findlanguage().products}</h2>
            <Doughnut data={chartData} options={options} plugins={[ChartDataLabels]} />
        </div>
    );
};

export default ProductsStatusChart;
