import { CoreContext } from 'context/CoreContext'
import React, { useContext } from 'react'

import {
    ModalContainer,
    ModalContent,
    ModalHeader,
    ModalHeaderSpace,
    ModalHeaderTitle,
    ModalHeaderClose,
    ModalBody,
} from './styled'

export default function ModalWrapper({
    children,
    successable,
    big,
    biggest,
    left,
    onCloseModal,
    colorized,
    hideclose = false,
}) {
    const { setModal, modal } = useContext(CoreContext)

    const close = () => {
        setModal(null)

        if (typeof onCloseModal === 'function') {
            onCloseModal()
        }
    }

    const verifyClose = (e) => {
        if (!hideclose)
            if (!e.target.closest('.modal-content')) {
                close()
            }
    }

    return (
        <>
            <ModalContainer onClick={verifyClose} left={left}>
                <ModalContent
                    className={'modal-content'}
                    big={big}
                    biggest={biggest}
                    left={left}
                    colorized={colorized}
                >
                    {!successable ? (
                        <>
                            <ModalHeader colorized={colorized}>
                                <ModalHeaderSpace />
                                <ModalHeaderTitle colorized={colorized}>
                                    {modal?.title}
                                </ModalHeaderTitle>
                                {!hideclose && (
                                    <ModalHeaderClose colorized={colorized} onClick={close} />
                                )}
                            </ModalHeader>
                        </>
                    ) : null}

                    <ModalBody left={left}>{children}</ModalBody>
                </ModalContent>
            </ModalContainer>
        </>
    )
}
