import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const PageCardCard = styled.div.attrs({})`
    width: 293px;
    height: 175px;
    margin: 60px auto 0;
    border-radius: 12px;

    padding: 30px;

    background: transparent url(/images/card-front.png) no-repeat center center / contain;
`

export const CardBrand = styled.img.attrs({
    height: 31,
    src: '/icons/mastercard.svg',
})``
export const CardRowMumbers = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 4px 0 14px;
`
export const CardNumbers = styled.div.attrs({})`
    font-size: 12px;
    color: var(--white-color);
`
export const CardDecoration = styled.div.attrs({})`
    width: 40px;
    height: 28px;
    border-radius: 5px;
    background: var(--shadow-color);
`

export const CardRowInfos = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
`
export const CardName = styled.div.attrs({})`
    font-size: 9px;
    color: var(--white-color);
`
export const CardExpiresAt = styled.div.attrs({})`
    font-size: 9px;
    color: var(--white-color);
`
export const CardCCV = styled.div.attrs({})`
    font-size: 9px;
    color: var(--white-color);
`
