import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Line } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';

import DashboardPanel from 'components/Dashboard/Panels/Wrapper';
import { CoreContext } from 'context/CoreContext';
import { CountViews } from 'services/dashboard';
import { ChartPosition, ChartPanel } from './styled';
import { Load } from 'ui/styled';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export default function DashboardPanelViews({ cardTitle, onClose }) {
    const history = useHistory();
    const { setModal, premium, findlanguage, checkPremium, permissions } = useContext(CoreContext);

    const navigateToProfileViews = async () => {
        const premiumStatus = await checkPremium();
        if (premiumStatus.type !== 'free') {
            history.push('/dashboard/profileViews');
        }
    };

    const panel = {
        title: findlanguage().views,
        actions: [
            {
                title: `7 ${findlanguage().days}`,
                outline: false,
                dark: false,
                small: false,
                action: () => callFilter('0'),
            },
            {
                title: `15 ${findlanguage().days}`,
                outline: false,
                dark: false,
                small: false,
                action: () => callFilter('15'),
            },
            {
                title: `30 ${findlanguage().days}`,
                outline: false,
                dark: false,
                small: false,
                action: () => callFilter('30'),
            },
        ],
        onClose,
        relative: true,
    };

    const [showWeek, setShowWeek] = useState(true);
    const [showLast15days, setShowLast15days] = useState(false);
    const [showLast30days, setShowLast30days] = useState(false);

    const checkAccess = () => premium.type === 'free';

    const callFilter = (filter) => {
        if (checkAccess()) {
            toast.warning('Only Premium');
            return false;
        }
        setShowWeek(filter === '0');
        setShowLast15days(filter === '15');
        setShowLast30days(filter === '30');
        return true;
    };

    const [chartData, setChartData] = useState(null);
    const [chartData1, setChartData1] = useState(null);
    const [chartData2, setChartData2] = useState(null);

    const getData = (datasource, days) => {
        const d = new Date();
        d.setDate(d.getDate() - days);
        const start = d.toISOString().split('T')[0];

        const dataReady = datasource.filter(
            (item) => item.type_data === 'daily' && item.dt_date >= start
        );

        const labels = dataReady.map((item) => item.str_date);
        const values = dataReady.map((item) => parseInt(item.count));

        return {
            labels,
            datasets: [
                {
                    fill: true,
                    label: '',
                    data: values,
                    borderColor: '#263640',
                    backgroundColor: '#263640',
                },
            ],
        };
    };

    const fetchData = async () => {
        const countViews = await CountViews();

        setChartData(getData(countViews, 7));
        setChartData1(getData(countViews, 15));
        setChartData2(getData(countViews, 30));
    };

    useEffect(() => {
        fetchData();
    }, []);

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: { display: false },
            title: { display: false },
        },
        maintainAspectRatio: false,
    };

    if (!permissions?.profileViews?.canView) return null;

    return (
        <DashboardPanel {...panel} subscription={premium?.type}>
            {chartData ? (
                <>
                    <ChartPanel visible={showWeek}>
                        <ChartPosition onClick={navigateToProfileViews} style={{ cursor: 'pointer' }}>
                            {chartData && <Line height={200} options={chartOptions} data={chartData} />}
                        </ChartPosition>
                    </ChartPanel>
                    <ChartPanel visible={showLast15days}>
                        <ChartPosition onClick={navigateToProfileViews} style={{ cursor: 'pointer' }}>
                            {chartData1 && <Line height={200} options={chartOptions} data={chartData1} />}
                        </ChartPosition>
                    </ChartPanel>
                    <ChartPanel visible={showLast30days}>
                        <ChartPosition onClick={navigateToProfileViews} style={{ cursor: 'pointer' }}>
                            {chartData2 && <Line height={200} options={chartOptions} data={chartData2} />}
                        </ChartPosition>
                    </ChartPanel>
                </>
            ) : (
                <Load color="#263640" />
            )}
        </DashboardPanel>
    );
}
    