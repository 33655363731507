import { ServerAxios, POST, GET, PUT } from './api'
import { PostTeamAccessFile, PostCompaniesFile } from 'services/api'

export const Mailchimploader = async (body) => {
    return await ServerAxios(`/mailchimp/update`)
}

export const Usersfileloader = async (formData) => {
    return await PostTeamAccessFile(formData)
}

export const Companiesfileloader = async (formData) => {
    return await PostCompaniesFile(formData)
}

// export const NotificationManagerCreate = async (body) => {
//     return await POST(`/notifications/new`, body, true)
// }

export const NotificationManagerAdd = async (
    type,
    receiver_id,
    from,
    user,
    _format = 'full',
    document_name = ''
) => {
    let body = {
        receiver_id: receiver_id,
        type: type,
        format: _format,
        from_name: from,
        from_contact_name: user.contactName,
        document_name: document_name,
    }

    if (user.support && user.company_id > 1) {
        body = {
            receiver_id: receiver_id,
            type: type,
            format: _format,
            from_name: user.company_account.name,
            from_contact_name: user.company_account.contactName,
            document_name: document_name,
        }
    }

    if (body.receiver_id.id) {
        body.receiver_id = body.receiver_id.id
    }

    const data_notify = await POST(`/notifications/new`, body, true)

    return data_notify
}

export const FetchSupportCases = async () => {
    return await GET(`/support-cases`, true)
}

export const UpdateSupportCasesStart = async (id, el) => {
    return await PUT(`/support-cases-start/${id}`, el, true)
}
