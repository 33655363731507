import React, { useContext, useState } from 'react'

import {
    ProfileFormContent,
    ProfileFormActions,
    PageFormSpace,
    RowForm,
    ModalFormContent,
    ModalFormLabel,
    UserProfileProgressActions,
} from './styled'

import Button from 'components/Form/Button'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

export default function ModalAddSupplierInfo() {
    const { modal, setModal, findlanguage } = useContext(CoreContext)
    const [checked, setChecked] = React.useState(false)
    const [checked2, setChecked2] = React.useState(false)

    return (
        <>
            <ModalWrapper>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                        defaultChecked={checked}
                        onChange={() => setChecked(!checked)}
                    />
                    <label className="form-check-label" for="defaultCheck1">
                        {findlanguage().declareInformationIsAccurate}
                    </label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck2"
                        defaultChecked={checked2}
                        onChange={() => setChecked2(!checked2)}
                    />
                    <label className="form-check-label" for="defaultCheck2">
                        {findlanguage().declareWillingToSendInformationToBuyer}
                    </label>
                </div>
                <PageFormSpace />
                <ProfileFormActions>
                    {checked && checked2 ? (
                        <Button primary onClick={modal.submitQuestionarie}>
                            Submit
                        </Button>
                    ) : (
                        <Button gray>Submit</Button>
                    )}
                </ProfileFormActions>
            </ModalWrapper>
        </>
    )
}
