import './loader.css' // import the CSS file

import React, { useEffect, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import { FindAllForAdmin, FindFormBody } from 'services/backoffice'
import { MenuItem } from '@material-ui/core'
import Select from 'react-select'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useHistory } from 'react-router-dom'
import { CoreContext } from 'context/CoreContext'
import NewForm from './NewForm/index'
import { RemoveValidationForm } from 'services/sms'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { FetchSuplierRequestsFindWithId } from 'services/sms'
import {
    CreateFormRequestForSupplier,
    FindValidationProcess,
    GetForm,
} from 'services/validationProcess'
import { NotificationManagerAdd } from 'services/admin'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    container: {
        margin: '20px',
        overflowY: 'auto',
    },
})

const Usuarios = () => {
    const { setModal, user, findlanguage } = useContext(CoreContext)
    const [open, setOpen] = useState(false)
    const classes = useStyles()
    const [data, setData] = useState([])
    const [userOptions, setUserOptions] = useState([])
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [currentRow, setCurrentRow] = React.useState(null)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [sortConfig, setSortConfig] = React.useState({
        key: 'user_aditional_information_companyName',
        direction: 'asc',
    })
    const [filter, setFilter] = useState({
        companyName: '',
        accountType: '',
        billingPlan: '',
        country: '',
        status: '',
    })
    const [currentPage, setCurrentPage] = useState(0)
    const [loading, setLoading] = useState(true)
    const [currentForm, setCurrentForm] = useState({})
    const [visibleSelection, setVisibleSelection] = useState([])
    const [choosedUsers, setChoosedUsers] = useState([])

    const fetchRequirements = async () => {
        const response = await FindAllForAdmin() // Assuming this fetches all the data

        const mappedData = response.map((data, index) => {
            return {
                id: data.id,
                requirement: data.name,
                companyName: data.ownerName,
                createdAt: data.created_at,
                status: data.draft,
                ownerId: data.ownerId,
                alreadyInUse: data.responseExists,
            }
        })

        setData(mappedData)
        setTotal(mappedData.length)
        setLoading(false)
    }

    useEffect(() => {
        fetchRequirements()
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleAction = async (action) => {
        switch (action) {
            case 'edit':
                var form = await FindFormBody(currentRow.id)
                setCurrentForm({
                    id: currentRow.id,
                    requirement: currentRow.requirement,
                    ownerId: currentRow.ownerId,
                    alreadyInUse: currentRow?.alreadyInUse,
                    form: form[0].form_body,
                })

                setCurrentPage(1)
                break
            case 'delete':
                deleteValidationForm(currentRow)
                break
            default:
                break
        }
    }

    const handleRequestSort = (key) => {
        let direction = 'asc'
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc'
        }
        setSortConfig({ key, direction })
    }

    const isUserOnline = (lastTimeSeen) => {
        const currentDate = new Date()
        const diffInMinutes = (currentDate - lastTimeSeen) / 1000 / 60
        return diffInMinutes <= 2
    }

    const handleFilterChange = (event) => {
        setFilter({
            ...filter,
            [event.target.name]: event.target.value,
        })
    }

    const handleFilterChangeDropdown = (selectedOption) => {
        setFilter({
            ...filter,
            ['status']:
                selectedOption !== null && selectedOption !== undefined ? selectedOption.value : '',
        })
    }

    const filteredData = data
        .filter((item) =>
            Object.keys(filter).every((key) => {
                // if filter is blank, ignore
                if (filter[key] === null || filter[key] === undefined || filter[key] === '')
                    return true

                // if key is 'status', check for equality
                if (key === 'status') {
                    return item[key] === filter[key]
                }

                // for all other keys, check for substring match
                return (
                    item[key] &&
                    item[key]
                        .toString()
                        .toLowerCase()
                        .includes(filter[key].toString().toLowerCase())
                )
            })
        )

        .sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1
            }
            return 0
        })

    // Set the total count
    useEffect(() => {
        setTotal(filteredData.length)
    }, [filteredData])

    const currentPageData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    const deleteValidationForm = async (form) => {
        setLoading2('Removing')
        const response = await RemoveValidationForm(form.id)
        if (response.statusText || response.statusCode) return alertError()
        setModal(false)
        fetchRequirements()
    }

    const alertError = () => {
        setModal({
            type: 'alert',
            warning: true,
            text: 'Error.',
            description:
                'Could not perform requested action. Please check you connection and try again.',
        })
    }

    const setLoading2 = (message = 'saving...') => {
        setModal({
            type: 'loading',
            text: message,
        })
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleUsers = async (ownerId) => {
        const result = await FetchSuplierRequestsFindWithId(ownerId)
        var a = [
            {
                value: 'all',
                label: 'Select All',
            },
            ...result
                .map((x) => ({
                    value: x.userId,
                    label: x.name,
                }))
                .sort((a, b) => (a.label > b.label) - (a.label < b.label)),
        ]
        setVisibleSelection([])
        setUserOptions(a)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleChangeUsers = (selectedOption) => {
        if (!selectedOption) {
            setChoosedUsers([])
            setVisibleSelection([])
            return
        }

        const selectAllSelected = selectedOption.some((option) => option.value === 'all')

        if (
            selectedOption.length > 0 &&
            selectedOption[selectedOption.length - 1].value === 'all'
        ) {
            setChoosedUsers(userOptions.filter((option) => option.value !== 'all'))
            setVisibleSelection([{ value: 'all', label: 'Select All' }])
        } else if (
            choosedUsers.length === userOptions.length - 1 &&
            choosedUsers[0].value !== 'all'
        ) {
            setChoosedUsers([selectedOption[selectedOption.length - 1]])
            setVisibleSelection([selectedOption[selectedOption.length - 1]])
        } else if (selectAllSelected) {
            const newOptions = selectedOption.filter((option) => option.value !== 'all')
            setChoosedUsers(newOptions)
            setVisibleSelection(newOptions)
        } else {
            setChoosedUsers(selectedOption)
            setVisibleSelection(selectedOption)
        }
    }

    const sendRequirements = async () => {
        await choosedUsers.forEach(async (y) => {
            var form_body = await GetForm(currentRow.id)

            await CreateFormRequestForSupplier({
                form: currentRow.id,
                form_body: form_body,
                toUser: { id: y.value },
                owner: { id: currentRow.ownerId },
                status: 'Condition',
            })
            await NotificationManagerAdd('document_request', y.value, user.name, user)
        })

        setModal({
            type: 'alert',
            text: 'Requirements Sent.',
        })

        setOpen(false)
    }

    const options = [
        { value: '', label: 'All' },
        { value: true, label: 'Draft' },
        { value: false, label: 'Published' },
    ]

    if (loading) return <div className="loader"></div>
    return (
        <>
            <br />
            {currentPage === 0 ? (
                <TableContainer component={Paper} className=" table container">
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortConfig.key === 'requirement'}
                                        direction={sortConfig.direction}
                                        onClick={() => handleRequestSort('requirement')}
                                    >
                                        Requirement
                                    </TableSortLabel>
                                    <TextField
                                        name="requirement"
                                        value={filter.requirement}
                                        onChange={handleFilterChange}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortConfig.key === 'companyName'}
                                        direction={sortConfig.direction}
                                        onClick={() => handleRequestSort('companyName')}
                                    >
                                        Company Name
                                    </TableSortLabel>
                                    <TextField
                                        name="companyName"
                                        value={filter.companyName}
                                        onChange={handleFilterChange}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortConfig.key === 'createdAt'}
                                        direction={sortConfig.direction}
                                        onClick={() => handleRequestSort('createdAt')}
                                    >
                                        Created At
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortConfig.key === 'status'}
                                        direction={sortConfig.direction}
                                        onClick={() => handleRequestSort('status')}
                                    >
                                        Status
                                    </TableSortLabel>
                                    <Select
                                        options={options}
                                        value={options.find(
                                            (option) => option.value === filter.status
                                        )}
                                        onChange={handleFilterChangeDropdown}
                                        isClearable
                                    />
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel>Action</TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentPageData.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.requirement}</TableCell>
                                    <TableCell>{row.companyName}</TableCell>
                                    <TableCell>
                                        {new Date(row.createdAt).toLocaleString()}
                                    </TableCell>
                                    <TableCell>{row.status ? 'Draft' : 'Published'}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={(event) => {
                                                handleUsers(row.ownerId)
                                                setAnchorEl(event.currentTarget)
                                                setCurrentRow(row)
                                            }}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={() => setAnchorEl(null)}
                                        >
                                            <MenuItem
                                                onClick={() => {
                                                    handleAction('edit')
                                                    setAnchorEl(null)
                                                }}
                                            >
                                                {!currentRow?.alreadyInUse
                                                    ? 'Edit this version'
                                                    : 'Create new version'}
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    handleAction('sendto')
                                                    handleOpen()
                                                    setAnchorEl(null)
                                                }}
                                            >
                                                Send to
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    handleAction('delete')
                                                    setAnchorEl(null)
                                                }}
                                            >
                                                Delete
                                            </MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableContainer>
            ) : null}
            {currentPage === 1 ? (
                <NewForm
                    currentForm={currentForm}
                    setCurrentPage={setCurrentPage}
                    fetchRequirements={fetchRequirements}
                />
            ) : null}
            <Dialog
                key={currentForm.id}
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                disableEnforceFocus
            >
                <DialogTitle id="form-dialog-title">Send to</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Choose the user you want to send the requirement to.
                    </DialogContentText>
                    <Select
                        onChange={handleChangeUsers}
                        isMulti
                        name="colors"
                        options={userOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={findlanguage().select}
                        value={visibleSelection}
                        menuPosition="fixed"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={sendRequirements} color="primary">
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Usuarios
