import React, { useState } from 'react'

import {
    ProfileProgressAction,
    ProfileProgressActionIcon,
    ProfileProgressActionText,
} from './styled'
import { Load } from 'ui/styled'

export default function DashboardButton({
    children,
    dark,
    small,
    big,
    grey,
    red,
    blue,
    outline,
    icon,
    onClick,
    disabled,
    loading,
    active,
}) {
    return (
        <>
            <ProfileProgressAction
                dark={dark}
                small={small}
                big={big}
                grey={grey}
                red={red}
                blue={blue}
                outline={outline}
                onClick={onClick}
                disabled={disabled}
                active={active}
            >
                {icon ? <ProfileProgressActionIcon icon={icon} /> : null}
                <ProfileProgressActionText big={big}>
                    {loading ? <Load /> : <>{children}</>}
                </ProfileProgressActionText>
            </ProfileProgressAction>
        </>
    )
}
