import { React, useState, useContext } from 'react'
import { CoreContext } from 'context/CoreContext'

import {
    BodyContent,
    FormContent,
    RequiredText,
    FormTitle,
    CheckList,
    CheckContent,
    Label,
    ItemRow,
    DeleteIcon,
} from './styled'

import RegisterFooter from 'components/Register/Footer'

import Check from 'components/Form/Check'
import SelectMany from 'components/Form/SelectMany'
import Textarea from 'components/Form/Textarea'
import Button from 'components/Form/Button'
import Input from 'components/Form/Input'

export default function RegisterBuyerStep2Form({
    formValue,
    changeForm,
    next,
    registerInfos,
    back,
    loading,
}) {
    const { findlanguage, language } = useContext(CoreContext)
    const [formValues, setFormValues] = useState([{ requirement: '' }])

    const business = registerInfos?.businessTypes?.buyer?.length
        ? registerInfos?.businessTypes?.buyer
              ?.sort((a, b) => (a > b ? 1 : -1))
              .map((mit, key) => {
                  let traducao = mit.value
                  switch (mit) {
                      case 'Farm':
                          traducao = 'Fazenda'
                          break
                      case 'Industry':
                          traducao = 'Industria'
                          break
                      case 'Restaurant':
                          traducao = 'Restaurante'
                          break
                      case 'Retail':
                          traducao = 'Varejo'
                          break
                      case 'Store and Distribuition':
                          traducao = 'Armazenamento e distribuição'
                          break
                      case 'Broker':
                          traducao = 'Corretora'
                          break
                      case 'Process Food':
                          traducao = 'Alimentos Processados'
                          break
                  }

                  return {
                      id: key + 1,
                      title: mit,
                      value: mit,
                      traducao: traducao,
                  }
              })
        : []

    const categories = registerInfos?.companyCategories?.length
        ? registerInfos?.companyCategories
              ?.sort((a, b) => (a.title > b.title ? 1 : -1))
              .map((mit, key) => ({ id: key + 1, ...mit }))
        : []
    const prerequirements = registerInfos?.supplierPreRequirements?.length
        ? registerInfos?.supplierPreRequirements
              ?.sort((a, b) => (a.label > b.label ? 1 : -1))
              .map((mit, key) => {
                  let traducao = mit?.label

                  switch (mit?.label) {
                      case 'Animal Welfare':
                          traducao = 'Bem estar animal'
                          break
                      case 'FDA Approved Companies':
                          traducao = 'Empresas Aprovadas pela FDA'
                          break
                      case 'Food Safety / GFSI Certified':
                          traducao = 'Segurança Alimentar / Certificado GFSI'
                          break
                      case 'None':
                          traducao = 'Nenhum'
                          break
                      case 'Quality':
                          traducao = 'Qualidade'
                          break
                      case 'Religious':
                          traducao = 'Religioso'
                          break
                      case 'Social/Ethical certified':
                          traducao = 'Certificado Social/Ético'
                          break
                      case 'Sustainability Certified':
                          traducao = 'Certificado de Sustentabilidade'
                          break
                      case 'USDA Approved Companies':
                          traducao = 'Empresas aprovadas pelo USDA'
                          break
                  }

                  return {
                      id: key + 1,
                      title: mit.label,
                      value: mit.label,
                      traducao: traducao,
                  }
              })
        : []

    const isAdded = (item, key) => {
        return (
            formValue(key)?.length &&
            formValue(key)
                ?.map((mit) => mit.id)
                .includes(item.id)
        )
    }

    const toggleAdd = (item, key) => {
        const selected = formValue(key) || []
        const nxtValue = isAdded(item, key)
            ? [...selected.filter((fit) => fit.id !== item.id)]
            : [...selected, item]
        changeForm(nxtValue, key)
    }

    const action = async () => {
        if (next && typeof next === 'function') {
            const _formValues = formValues.filter((item) => item.requirement.trim().length > 0)

            // console.log(_formValues)

            next(_formValues)
        }
    }

    let addFormFields = () => {
        setFormValues([...formValues, { requirement: '' }])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues]
        newFormValues.splice(i, 1)
        setFormValues(newFormValues)
    }

    const changeRequirement = (index, val) => {
        let newFormValues = [...formValues]
        newFormValues[index]['requirement'] = val
        setFormValues(newFormValues)
    }

    return (
        <>
            <BodyContent>
                <FormContent>
                    <SelectMany
                        title={findlanguage().typeOfBusiness}
                        placeholder={''}
                        action={findlanguage().addBusiness}
                        options={business}
                        value={formValue('businessType')}
                        onChange={(e) => changeForm(e, 'businessType')}
                    />
                    <CheckList>
                        <FormTitle>{findlanguage().typeOfCategoryCompanyFit}</FormTitle>
                        {language === 'portugues'
                            ? categories.map((item, key) => (
                                  <CheckContent key={key}>
                                      <Check
                                          title={item.title_pt}
                                          label={item.description_pt}
                                          onChange={() => toggleAdd(item, 'categories')}
                                          checked={isAdded(item, 'categories')}
                                          nowidth={true}
                                      />
                                  </CheckContent>
                              ))
                            : null}
                        {language === 'english'
                            ? categories.map((item, key) => (
                                  <CheckContent key={key}>
                                      <Check
                                          title={item.title}
                                          label={item.description}
                                          onChange={() => toggleAdd(item, 'categories')}
                                          checked={isAdded(item, 'categories')}
                                          nowidth={true}
                                      />
                                  </CheckContent>
                              ))
                            : null}
                    </CheckList>
                    <SelectMany
                        title={findlanguage().whatAreThePreRequirements}
                        placeholder={''}
                        action={findlanguage().other}
                        options={prerequirements}
                        value={formValue('supplierPreRequirements')}
                        onChange={(e) => changeForm(e, 'supplierPreRequirements')}
                    />

                    <div
                        style={{
                            width: '100%',
                            marginBottom: '40px',
                        }}
                    >
                        <Label style={{ display: 'flex' }}>
                            <div>{findlanguage().myRequirements}:</div>
                        </Label>

                        {formValues.map((element, index) => (
                            <ItemRow key={index}>
                                <div
                                    style={{
                                        width: '550px',
                                        marginTop: '10px',
                                        verticalAlign: 'top',
                                    }}
                                >
                                    <Input
                                        placeholder={findlanguage().requirement}
                                        value={element.requirement || ''}
                                        onChange={(e) => changeRequirement(index, e.target.value)}
                                        big
                                        autoComplete={'xoff'}
                                    />
                                </div>

                                {index ? (
                                    <Button
                                        style={{ marginLeft: '15px', marginTop: '30px' }}
                                        red
                                        micro
                                        outline
                                        onClick={() => {
                                            removeFormFields(index)
                                        }}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                ) : (
                                    ''
                                )}

                                {formValues.length === index + 1 ? (
                                    <Button
                                        style={{
                                            marginLeft: '15px',
                                            marginTop: '30px',
                                        }}
                                        primary
                                        outline
                                        micro
                                        onClick={() => {
                                            addFormFields()
                                        }}
                                    >
                                        +
                                    </Button>
                                ) : (
                                    ''
                                )}
                            </ItemRow>
                        ))}
                    </div>

                    <Textarea
                        label={findlanguage().aboutCompany}
                        maxLength={500}
                        value={formValue('about_company')}
                        onChange={(e) => changeForm(e.target.value, 'about_company')}
                        bigger
                    />
                    <div
                        style={{
                            width: '100%',
                            marginBottom: '40px',
                        }}
                    >
                        <RequiredText>{findlanguage().requiredFields}</RequiredText>
                        <RegisterFooter action={action} back={back} loading={loading} />
                    </div>
                </FormContent>
            </BodyContent>
        </>
    )
}
