import React, { useState, useMemo, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const RequirementExpirationChart = ({ showDataLabels, usersWithForms }) => {
    const [selectedThreshold, setSelectedThreshold] = useState(45);
    const tooltipDataRef = useRef({});

    const thresholds = [45, 30, 20, 15, 10, 5, 3, 1];

    const chartData = useMemo(() => {
        const now = new Date();
        now.setHours(0, 0, 0, 0);

        const expirationCounts = thresholds.reduce((acc, days) => ({ ...acc, [`${days} Days`]: 0 }), {});

        usersWithForms.forEach(doc => {
            if (doc.status === 'Accepted' && doc.expirationDate) {
                const expirationDate = new Date(doc.expirationDate);
                const diffTime = expirationDate - now;
                const daysLeft = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                if (daysLeft > 0 && daysLeft <= selectedThreshold) {
                    thresholds.forEach(threshold => {
                        if (daysLeft <= threshold && threshold <= selectedThreshold) {
                            expirationCounts[`${threshold} Days`]++;
                        }
                    });
                }
            }
        });

        tooltipDataRef.current = expirationCounts;

        const filteredLabels = Object.keys(expirationCounts).filter(key => {
            const total = expirationCounts[key];
            return total > 0 && parseInt(key.split(' ')[0]) <= selectedThreshold;
        });

        const filteredData = filteredLabels.map(key => expirationCounts[key]);

        return {
            labels: filteredLabels,
            datasets: [{
                data: filteredData,
                backgroundColor: [
                    '#7189bf', '#df7599', '#ffc785', '#72d6c9', '#90d7ff', '#3f8cb5', '#ffe156', '#3f88c5'
                ],
                borderColor: '#ffffff',
                borderWidth: 2
            }]
        };
    }, [usersWithForms, selectedThreshold]);

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    usePointStyle: true,
                }
            },
            tooltip: {
                callbacks: {
                    title: function (tooltipItems) {
                        return `${tooltipItems[0].label}`;
                    },
                    label: function (tooltipItem) {
                        const label = tooltipItem.label;
                        const formCounts = tooltipDataRef.current[label];
                        return `${label}: ${formCounts}`;
                    }
                }
            },
            datalabels: {
                display: true,
                color: showDataLabels ? 'black' : 'white',
                align: 'center',
                font: {
                    weight: 'bold',
                    size: 14
                },
                formatter: (value, context) => {
                    if (showDataLabels) {
                        const label = context.chart.data.labels[context.dataIndex];
                        return `${label}: ${value}`;
                    } else {
                        return value;
                    }
                }
            }
        },
        layout: {
            padding: {
                bottom: 80
            }
        }
    };

    return (
        <div style={{ height: '385px' }}>
            <select value={selectedThreshold} onChange={(e) => setSelectedThreshold(parseInt(e.target.value))} style={{ marginBottom: '10px' }}>
                {thresholds.map(threshold => (
                    <option key={threshold} value={threshold}>{`${threshold} Days`}</option>
                ))}
            </select>
            <h2>Requirement Expiration</h2>
            <Doughnut data={chartData} options={options} plugins={[ChartDataLabels]} />
        </div>
    );
};

export default RequirementExpirationChart;
