import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CoreContext } from 'context/CoreContext';
import moment from 'moment';

const VisitCard = ({ visit, date }) => {
    const { user, setNewChat, findlanguage } = useContext(CoreContext);
    const daysAgo = moment(date).fromNow();
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const redirectToProfile = () => {
        history.push(`/dashboard/user/${visit.id}`);
    };

    const createNewChat = (secondUserId) => {
        let user_key = user.id
        if (user.team_access) {
            user_key = user.company_id
        }
        setNewChat({
            firstUserId: user_key,
            secondUserId: secondUserId,
            newMessageUserOne: false,
            newMessageUserTwo: false,
            lastMessage: new Date().toISOString(),
        })
        navigate('dashboard/message')
    }

    const color = (role) => {
        switch (role) {
            case 4:
                return "#263640";
            case 3:
                return "#0d6efd";
            case 5:
                return "#ded514";
            default:
                return "";
        }
    };

    return (
        <div className="d-flex align-items-stretch flex-column">
            <div
                className="card bg-light d-flex flex-fill"
                style={{ borderTop: `3px solid ${color(visit.role)}`, borderTopLeftRadius: '0.4rem', borderTopRightRadius: '0.4rem' }}
            >
                <div className="card-header text-muted border-bottom-0" style={{ backgroundColor: '#f8f9fa' }}>
                    {visit.role === 4 ? (
                        <span
                            className="badge"
                            style={{ backgroundColor: '#263640', color: 'white', fontSize: '13px' }}
                        >
                            {findlanguage().supplierTitle}
                        </span>
                    ) : null}

                    {visit.role === 3 ? (
                        <span className="badge bg-primary" style={{ fontSize: '13px' }}>{findlanguage().buyerTitle}</span>
                    ) : null}
                    {visit.role === 5 ? (
                        <span
                            className="badge"
                            style={{ backgroundColor: '#ded514', color: 'white', fontSize: '13px' }}
                        >
                            {findlanguage().serviceProviderTitle}
                        </span>
                    ) : null}
                </div>
                <div className="card-body pt-0">
                    <div className="row">
                        <div className="col-7">
                            <h2 className="lead"><b>{visit.name}</b></h2>
                            <p className="text-muted text-sm"><b>About: </b>{visit.about}</p>
                            <ul className="ml-4 mb-0 fa-ul text-muted">
                                <li className="small"><span className="fa-li"><i className="fas fa-lg fa-phone"></i></span> Phone #: {visit.phone}</li>
                            </ul>
                        </div>
                        <div className="col-5 text-center">
                            <img
                                src={visit.avatar ? visit.avatar : "/images/no-image.png"}
                                alt="user-avatar"
                                className="img-circle img-fluid"
                                style={{ width: '100px', height: '100px' }}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="text-left">
                        <span>{daysAgo}</span>
                    </div>
                    <div className="text-right">
                        <button onClick={() => createNewChat(visit.id)} className="btn btn-sm bg-teal">
                            <i className="fas fa-comments"></i>
                        </button>
                        <button onClick={redirectToProfile} className="btn btn-sm btn-primary" style={{ marginLeft: '10px' }}>
                            <i className="fas fa-user"></i> View Profile
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VisitCard;