import styled from 'styled-components'

export const ProfileFormContent = styled.div.attrs({})`
    width: 100%;
    padding: 40px 0 20px;
    display: flex;
    flex-direction: column;

    max-height: 80vh;
    overflow: auto;
`

export const ProfileFormActions = styled.div.attrs({})`
    display: flex;
    justify-content: center;
`
export const PageFormSpace = styled.div.attrs({})`
    margin-right: 12px;
`

export const RowApprove = styled.div.attrs({})`
    display: flex;
    padding-bottom: 20px;
    justify-content: space-between;
    align-items: center;
`
export const ApproveContent = styled.div.attrs({})`
    display: flex;
    align-items: center;
`
export const ApproveCheck = styled.div.attrs({})`
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background: var(--primary-color);
    margin-right: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
`
export const ApproveCheckIcon = styled.img.attrs({
    src: '/icons/check.svg',
    width: 10,
})``
