import React, { useContext } from 'react'

import ReactPaginate from 'react-paginate'
import _ from 'lodash'

import { ContentScroll, ScrollWrapper } from './styled'

import DashboardFilterCard from 'components/Dashboard/Cards/Filter'
import DashboardFilterScrapingCard from 'components/Dashboard/Cards/FilterScraping'
import { CoreContext } from 'context/CoreContext'
import UserMap from '../UserMap/userMap'

export default function RegularView({
    findedUsers,
    handlePageClick,
    pageCount,
    page,
    totalPerPage,
    changeValuePerPage,
}) {
    const { findlanguage } = useContext(CoreContext)
    return (
        <>
            <div id="reactPaginate" style={{ display: 'flex' }}>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel={`${findlanguage().next} > `}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={15}
                    pageCount={pageCount}
                    previousLabel={`< ${findlanguage().previous}`}
                    renderOnZeroPageCount={null}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                    forcePage={page}
                />
                <select
                    className="form-select"
                    style={{
                        marginLeft: '10px',
                        width: '10%',
                        height: '38px',
                    }}
                    value={totalPerPage}
                    onChange={(e) => changeValuePerPage(e.target.value)}
                >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={500}>500</option>
                </select>
            </div>
            <UserMap dataForTopCountries={findedUsers} />
            <br />
            <ContentScroll>
                <ScrollWrapper>
                    {findedUsers?.length === 0 ? (
                        <>
                            <div
                                className="alert alert-primary"
                                role="alert"
                                style={{ width: '100%' }}
                            >
                                {findlanguage().noResultsChangeFilter}
                            </div>
                        </>
                    ) : (
                        findedUsers?.map((x, mik) =>
                            x.member === 'member' ? (
                                <DashboardFilterCard
                                    key={x.id + 'member' + x.member}
                                    item={x}
                                    serviceProvider={false}
                                />
                            ) : (
                                <DashboardFilterScrapingCard
                                    key={x.id + 'nonmember' + x.member}
                                    item={x}
                                    serviceProvider={false}
                                />
                            )
                        )
                    )}
                </ScrollWrapper>
            </ContentScroll>
        </>
    )
}
