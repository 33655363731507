import React, { useEffect, useState } from 'react'

import {
    CheckContent,
    CheckBox,
    CheckBoxIn,
    CheckBoxText,
    CheckBoxContent,
    CheckBoxTitle,
} from './styled'

export default function Check({ checked, onChange, label, title, spaced, color, locked, nowidth }) {
    const [active, setActive] = useState(checked)

    const action = () => {
        const nv = !active
        if (onChange && typeof onChange === 'function') {
            onChange(nv)
        }
        setActive(nv)
    }

    useEffect(() => {
        setActive(checked)
    }, [checked])

    return (
        <>
            <CheckContent onClick={locked ? null : action} spaced={spaced} nowidth={nowidth}>
                <CheckBox active={!!active} locked={locked}>
                    {!!active || checked ? <CheckBoxIn /> : null}
                </CheckBox>
                <CheckBoxContent>
                    {title ? <CheckBoxTitle>{title}</CheckBoxTitle> : null}
                    <CheckBoxText color={color}>{label}</CheckBoxText>
                </CheckBoxContent>
            </CheckContent>
        </>
    )
}
