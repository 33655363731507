import React, { useContext, useState } from 'react'

import {
    ProfileFormContent,
    ProfileFormActions,
    PageFormSpace,
    RowForm,
    ModalFormContent,
    ModalFormLabel,
    ModalFormStatus,
    ModalFormText,
    RemoveableAttach,
    RemoveableAttachIcon,
} from './styled'

import Input from 'components/Form/Input'
import Button from 'components/Form/Button'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

import DashboardButton from 'components/Dashboard/Button'
import Select from 'components/Form/Select'
import Check from 'components/Form/Check'

export default function ModalCommentDecline() {
    const { modal, setModal } = useContext(CoreContext)

    const [form, setForm] = useState({})
    const formValue = (ref) => {
        return form?.[ref] ? form?.[ref] : ''
    }
    const changeForm = (value, ref) => {
        setForm({ ...form, [ref]: value })
    }

    const close = () => {
        setModal(null)
    }

    return (
        <>
            <ModalWrapper>
                <ProfileFormContent>
                    <Input
                        type={'textarea'}
                        big
                        maxLength={1200}
                        placeholder="Document Name"
                        value={formValue('document_name')}
                        onChange={(e) => changeForm(e.target.value, 'document_name')}
                    />
                </ProfileFormContent>
                <PageFormSpace />
                <ProfileFormActions>
                    <Button red primary onClick={close}>
                        Declined
                    </Button>
                </ProfileFormActions>
            </ModalWrapper>
        </>
    )
}
