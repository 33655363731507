import React, { useState } from 'react'
import {
    SelectContainer,
    SelectHeader,
    SelectHeaderText,
    SelectHeaderIcon,
    SelectBody,
    SelectBodyItem,
    SelectContainerAC,
    SelectBodyAC,
} from './styled'

import Input from 'components/Form/Input'
import { set } from 'lodash'

export default function Select({
    placeholder,
    options,
    small,
    value,
    onChange,
    outline,
    locked,
    style,
    highlight,
    black,
    setAutoComplete,
}) {
    const [opened, setOpened] = useState(false)
    const [optionsList, setOptionsList] = useState(options)
    const [selected, setSelected] = useState()
    const [filter, setFilter] = useState('')

    const toggleOpen = () => {
        setOpened(!opened)
    }

    const onChangeFilter = (e) => {
        const _value = e.target.value
        setFilter(_value)
        options = options.filter((el) => el.title.toLowerCase().startsWith(_value.toLowerCase()))
        setOptionsList(options)
        setOpened(true)
    }

    const optionAction = (item) => {
        if (item.name) {
            setSelected(item.name)
            setFilter(item.name)
        }

        if (onChange && typeof onChange === 'function') {
            onChange(item)
        }

        toggleOpen()
    }

    return (
        <>
            {setAutoComplete ? (
                <>
                    <SelectContainerAC small={small} style={style || {}}>
                        <Input
                            placeholder={placeholder}
                            onChange={(e) => onChangeFilter(e)}
                            black={black}
                            locked={locked}
                            value={filter}
                            highlight={highlight ? true : false}
                        />
                        {!opened ? null : (
                            <SelectBodyAC>
                                {options?.map((item, key) => (
                                    <SelectBodyItem key={key} onClick={() => optionAction(item)}>
                                        {item?.title ? item?.title : '-'}
                                    </SelectBodyItem>
                                ))}
                            </SelectBodyAC>
                        )}
                    </SelectContainerAC>
                </>
            ) : (
                <>
                    <SelectContainer
                        small={small}
                        outline={outline}
                        style={style || {}}
                        highlight={highlight ? true : false}
                    >
                        <SelectHeader
                            onClick={locked ? null : toggleOpen}
                            black={black}
                            locked={locked}
                        >
                            <SelectHeaderText highlight={highlight ? true : false}>
                                {typeof value === 'string' && value.length > 0
                                    ? value
                                    : value?.title
                                    ? value?.title
                                    : placeholder}
                            </SelectHeaderText>
                            <SelectHeaderIcon opened={opened} />
                        </SelectHeader>
                        {!opened ? null : (
                            <SelectBody>
                                {options?.map((item, key) => (
                                    <SelectBodyItem key={key} onClick={() => optionAction(item)}>
                                        {item?.title ? item?.title : '-'}
                                    </SelectBodyItem>
                                ))}
                            </SelectBody>
                        )}
                    </SelectContainer>
                </>
            )}
        </>
    )
}
