import React, { useContext, useState } from 'react'

import { CoreContext } from 'context/CoreContext'

import ModalChatModeChat from './Modes/Chat'
import ModalChatModeClose from './Modes/Close'
import ModalChatModeLoading from './Modes/Loading'
import ModalChatModeLobby from './Modes/Lobby'
import ModalChatModeRate from './Modes/Rate'

import { ModalContainer, ModalContent } from './styled'
import ModalChatModeMinimized from './Modes/Minimized'

export default function ModalChat({ children }) {
    const { setModal, modal } = useContext(CoreContext)

    const [currentChat, setCurrentChat] = useState({})
    const [mode, setMode] = useState(null)
    const [minimized, setMinimized] = useState(false)

    const close = () => {
        setModal(null)
    }

    const verifyClose = (e) => {
        if (!e.target.closest('.modal-content')) {
            if (mode === 'loading' || mode === 'chat') {
                minimize()
                return
            }
            close()
        }
    }

    const minimize = () => {
        setMinimized(!minimized)
    }

    const start = (name, description) => {
        setMode('loading')

        setCurrentChat({
            name,
            description,
            clerck: 'Dylan',
        })
        // to simulate API delay response
        setTimeout(() => {
            setMode('chat')
        }, 4000)
    }

    const handleStop = () => {
        setMode('close')
    }

    const goRate = () => {
        setMode('rate')
    }

    const backtoChat = () => {
        setMode('chat')
    }

    return (
        <>
            <ModalContainer onClick={verifyClose} minimized={minimized}>
                <ModalContent className={'modal-content'} minimized={minimized}>
                    {minimized ? (
                        <>
                            <ModalChatModeMinimized
                                minimize={minimize}
                                currentChat={currentChat}
                                close={close}
                                mode={mode}
                            />
                        </>
                    ) : (
                        <>
                            {!mode ? (
                                <>
                                    <ModalChatModeLobby close={close} handleStart={start} />
                                </>
                            ) : null}
                            {mode === 'loading' ? (
                                <>
                                    <ModalChatModeLoading
                                        currentChat={currentChat}
                                        handleStop={handleStop}
                                        minimize={minimize}
                                    />
                                </>
                            ) : null}
                            {mode === 'chat' ? (
                                <>
                                    <ModalChatModeChat
                                        currentChat={currentChat}
                                        handleStop={handleStop}
                                        minimize={minimize}
                                    />
                                </>
                            ) : null}
                            {mode === 'close' ? (
                                <>
                                    <ModalChatModeClose
                                        currentChat={currentChat}
                                        close={goRate}
                                        back={backtoChat}
                                    />
                                </>
                            ) : null}
                            {mode === 'rate' ? (
                                <>
                                    <ModalChatModeRate close={close} />
                                </>
                            ) : null}
                        </>
                    )}
                </ModalContent>
            </ModalContainer>
        </>
    )
}
