import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const CardSearched = styled.div.attrs({})`
    min-width: calc(33.33% - 12.5px);
    max-width: calc(33.33% - 12.5px);
    background: var(--white-color);
    padding: 24px;
    margin-bottom: 5px;
    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);

    display: flex;

    @media (max-width: ${mobileSize}px) {
        min-width: calc(100%);
        max-width: calc(100%);
    }
`
export const CardImage = styled.div.attrs({})`
    width: 76px;
    height: 76px;
    border-radius: 38px;
    margin-left: 10px;
    border: 1px solid var(--lightshadow-color);
    ${(props) =>
        props.url
            ? `
    background: url(${props.url}) no-repeat center center / cover #fff;
    background-size: 60px;
        `
            : `
    background: url(/images/no-image.png) no-repeat center center / cover;
    background-size: 60px;
        `}
`

export const CardContent = styled.div.attrs({})`
    padding: 0 10px;
    display: flex;
    align-items: center;
`
export const CardTitle = styled.div.attrs({})`
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    color: var(--grey-color);
    margin-bottom: 10px;
`

export const CardDate = styled.div.attrs({})`
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    color: var(--grey-color);
    margin-bottom: 10px;
`

export const CardReadIcon = styled.div.attrs({})`
    width: 15px;
    height: 15px;
    margin: auto 10px;

    ${(props) =>
        props.url
            ? `
    background: url(${props.url}) no-repeat center center #fff;
    background-size: 12px
        `
            : `
    background: url(/images/no-image.png) no-repeat center center;
    background-size: 25px;
        `}
`

export const CardTouch = styled.div.attrs({})`
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    min-height: 90px;
`

export const CheckHeader = styled.div.attrs({})`
    display: flex;
    cursor: pointer;
    width: 20px;
    height: 20px;
`

export const CheckIcon = styled.img.attrs({
    src: '/icons/chevron.svg',
})`
    padding: 0 20px;
    cursor: pointer;

    ${(props) =>
        props.opened
            ? `transform: rotate(180deg);  filter: invert(100%);`
            : `
        `}
`
