import styled from 'styled-components'

export const Body = styled.div.attrs({})`
    ${(props) =>
        props.grid
            ? `
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        `
            : `
    `}

    height: 400px;
    width: 98%;
    margin-bottom: 30px;
    padding-bottom: 20px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: var(--grey-color);
    overflow: auto;
    overflow-x: hidden;
`

export const Option = styled.div.attrs({})`
    width: 100%;
    white-space: nowrap;
`

export const SubOption = styled.div.attrs({})`
    width: 100%;
    white-space: nowrap;
    padding: 10px 0 0 30px;
`

export const ToggleIcon = styled.img.attrs({
    src: '/icons/chevron.svg',
})`
    padding: 0 20px;
    cursor: pointer;

    ${(props) =>
        props.opened
            ? `transform: rotate(180deg);  filter: invert(100%);`
            : `
        `}
`

export const CheckHeader = styled.div.attrs({})`
    display: flex;
    cursor: pointer;
`

export const ButtonDock = styled.div.attrs({})`
    display: grid;
    width: 98%;
    grid-template-columns: 2fr 2fr;
    grid-gap: 20px;
    margin-left: 35px;
`

export const FilterContent = styled.div.attrs({})`
    margin-left: 5px;
`

// Radio
export const Item = styled.div`
    padding: 5px 0px 0px 15px;
    width: 100%;
    white-space: nowrap;
`

export const SubItem = styled.div.attrs({})`
    width: 100%;
    white-space: nowrap;
    padding: 5px 0 0 5px;
    display: flex;
`

export const RadioHeader = styled.div.attrs({})`
    display: flex;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
`

export const RadioHeaderLabel = styled.div.attrs({})`
    cursor: pointer;
`

export const RadioContent = styled.div.attrs({})`
    height: 25px;
    padding-left: 15px;
    position: relative;
    width: 25px;
`

export const RadioButtonCircle = styled.label`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #eee;
    cursor: pointer;
    position: absolute;
    left: 0;
`

export const RadioButtonLabel = styled.div`
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    height: 25px;
    margin-left: 5px;
    padding-top: 3px;
    width: 200px;
`

export const RadioButton = styled.input.attrs({
    input: 'radio',
})`
    position: absolute;
    left: 0;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    width: 25px;
    height: 25px;

    &:hover ~ ${RadioButtonCircle} {
        background: #eee;
        border: 1px solid #ccc;
        &::after {
            content: url('/icons/check.svg');
            display: block;
            margin: 2px 0px 0px 5px;
        }
    }

    &:checked + ${RadioButtonCircle} {
        background: yellowgreen;
        border: 1px solid yellowgreen;
        &::after {
            content: url('/icons/check.svg');
            display: block;
            margin: 2px 0px 0px 5px;
        }
    }
`
