import styled from 'styled-components'

export const BodyContent = styled.div.attrs({})`
    padding-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
`

export const RequiredText = styled.div.attrs({})`
    color: var(--primary-color);
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-align: center;
    margin: 35px 0;
    padding-bottom: 100px;
`
