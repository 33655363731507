import React, { useContext, useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import {
    ProfileFormContent,
    ProfileFormActions,
    Header,
    SemiHeader,
    PermissionsTab,
    IconInfo
} from './styled'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap'

import DashboardButton from 'components/Dashboard/Button'
import Input from 'components/Form/Input'
import Check from 'components/Form/Check'
import {
    CreateNewTeamMember,
    FetchTeamMembers,
    FetchTeamUser,
    UpdateTeamUser,
} from 'services/teamAccess'

import { GetPermissions } from 'services/permissions'

export default function ModalTeamAccess() {
    const { modal, setModal, setTeamAccessList, findlanguage } = useContext(CoreContext)

    const [form, setForm] = useState({ permissions: {} })
    const [loading, setLoading] = useState(false)
    const [checked, setChecked] = useState(modal.is_company_visible)

    const formValue = (ref) => form?.[ref] || ''

    const [permissions, setPermissions] = useState({
        "myProfile": {
            "canView": true,
            "canEdit": true,
            "canViewSupplier": true,
            "canViewBuyer": true,
        },
        "profileViews": {
            "canView": true,
            "showOnDashboard": true
        },
        "filter": {
            "canUse": true
        },
        "wishlist": {
            "canView": true,
            "canEdit": true,
            "showOnDashboard": true
        },
        "connections": {
            "canView": true,
            "canEdit": true,
            "notifications": true
        },
        "message": {
            "canUse": true,
            "showOnDashboard": true
        },
        "myQms": {
            "canView": true
        },
        "myProcessOverview": {
            "canView": true,
            "canEdit": true
        },
        "myBuyers": {
            "canView": true,
            "canEdit": true,
            "showOnDashboard": true,
            "notifications": true
        },
        "mySuppliers": {
            "canView": true,
            "canEdit": true,
            "notifications": true
        },
        "myDocuments": {
            "canView": true,
            "canEdit": true,
            "notifications": true
        }
    })

    const changeForm = (value, ref) => setForm({ ...form, [ref]: value })
    const changePermission = (value) => {
        const { key, subSection } = value;

        setPermissions(prevForm => ({
            ...permissions,
            [key]: {
                ...permissions[key],
                [subSection]: !permissions[key][subSection]
            }
        }));
    };

    const [fieldValidation, setFieldValidation] = useState([])
    const [stateTooltipPhone, setStateTooltipPhone] = useState(false)

    const [edit, setEdit] = useState(false)
    const [isBuyer, setIsBuyer] = useState(false)
    const [isSupplier, setIsSupplier] = useState(false)
    const [isAdministrative, setIsAdministrative] = useState(false)

    const showHighlight = (name) => {
        const field = fieldValidation.find((item) => item.field === name)
        if (field !== undefined) return true
        return false
    }

    const togglePhone = (_state) => {
        if (typeof _state !== 'boolean') return
        setStateTooltipPhone(_state)
    }

    const handleInputPhone = (event) => {
        changeForm(event.target.value, event.target.name)
        togglePhone(false)
    }

    const valid = () => {
        setFieldValidation([])
        let errors = []

        const name = formValue('name')

        const fillTheField = `${findlanguage().fillTheField}`

        if (name.length === 0) {
            errors.push({
                field: 'name',
                message: fillTheField + findlanguage().nameNonMandatory,
            })
        } else if (name.length < 2) {
            errors.push({
                field: 'name',
                message: findlanguage().errorContactName,
            })
        }

        const email = formValue('email')

        if (email.length === 0) {
            errors.push({
                field: 'email',
                message: fillTheField + findlanguage().emailNonMandatory,
            })
        } else if (
            !email.match(
                /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
            )
        ) {
            errors.push({ field: 'email', message: findlanguage().errorEmail })
        }

        const phone = formValue('phone')

        if (phone.length === 0) {
            errors.push({
                field: 'phone',
                message: fillTheField + findlanguage().phoneNumberNonMandatory,
            })
        } else if (!/^\+(?:\d[-.\s]?){6,14}\d$/.test(phone)) {
            errors.push({
                field: 'phone',
                message: findlanguage().errorPhone,
            })
        }

        const department = formValue('department')

        if (department.length === 0) {
            errors.push({
                field: 'department',
                message: fillTheField + findlanguage().departmentNonMandatory,
            })
        } else if (department.length < 2) {
            errors.push({
                field: 'department',
                message: findlanguage().errorDepartment,
            })
        }

        if (!isAdministrative && !isBuyer && !isSupplier) {
            errors.push({
                field: 'roles',
                message: '- Select a role for the user',
            })
        }

        if (errors.length > 0) {
            let message = ''
            message = findlanguage().someErrorsFound
            errors.forEach((item) => {
                message += '\n - ' + item.message
            })
            toast.error(message)
            setFieldValidation(errors)
            return false
        }

        return true
    }

    const createUser = async () => {
        if (!valid()) {
            return
        }

        setLoading(true)

        form.permissions = permissions

        if (isBuyer && isSupplier) {
            form.role = 6
        }
        else if (!isBuyer && isSupplier) {
            form.role = 4
        }
        else if (isBuyer && !isSupplier) {
            form.role = 3
        }
        else {
            form.role = 8
        }

        form.is_company_visible = checked

        const request = await CreateNewTeamMember(form)

        if (!request) {
            toast.error('Error... try again in a few minutes')
            setLoading(false)
            return
        } else if (request.statusCode && request.statusCode !== 200) {
            toast.error(`Error, ${request.message}`)
            setLoading(false)
            return
        }

        const updatedList = await FetchTeamMembers()

        setTeamAccessList(updatedList)

        setModal({
            type: 'alert',
            text: findlanguage().teamAccessSuccess,
        })
    }

    const updateUser = async () => {
        if (!valid()) {
            return
        }

        setLoading(true)

        form.permissions = permissions

        if (isBuyer && isSupplier) {
            form.role = 6
        }
        else if (!isBuyer && isSupplier) {
            form.role = 4
        }
        else if (isBuyer && !isSupplier) {
            form.role = 3
        }
        else {
            form.role = 8
        }

        form.is_company_visible = checked

        const request = await UpdateTeamUser(modal.id, form)

        if (!request) {
            toast.error('Error... try again in a few minutes')
            setLoading(false)
            return
        } else if (request.statusCode && request.statusCode !== 200) {
            toast.error(`Error, ${request.message}`)
            setLoading(false)
            return
        }

        const updatedList = await FetchTeamMembers()
        setTeamAccessList(updatedList)

        setModal({
            type: 'alert',
            text: findlanguage().teamAccessUpdated,
        })
    }

    useEffect(() => {
        if (modal.id !== undefined) {
            setEdit(true)

            FetchTeamUser(modal.id).then(async (resp) => {

                const getPermissions = await GetPermissions(resp.access_account.id)

                const teamUser = resp.access_account
                const body = {
                    permissions: {},
                    name: teamUser.contactName,
                    email: teamUser.email,
                    phone: teamUser.phone,
                    department: teamUser.department,
                }

                setPermissions(getPermissions.permissions)

                setForm(body)

                switch (teamUser.role) {
                    case 3:
                        setIsBuyer(true)
                        break;
                    case 4:
                        setIsSupplier(true)
                        break;
                    case 6:
                        setIsBuyer(true)
                        setIsSupplier(true)
                        break;
                    case 8:
                        setIsAdministrative(true)
                        break;
                    default:
                        break;
                }
            })
        }
    }, [modal.id])

    const handleCheckboxChange = (type) => {
        switch (type) {
            case 'administrative':
                setIsAdministrative(!isAdministrative);
                if (!isAdministrative) {
                    setIsSupplier(false);
                    setIsBuyer(false);
                }
                break;
            case 'supplier':
                setIsSupplier(!isSupplier);
                if (isAdministrative) {
                    setIsAdministrative(false);
                }
                break;
            case 'buyer':
                setIsBuyer(!isBuyer);
                if (isAdministrative) {
                    setIsAdministrative(false);
                }
                break;
            default:
                break;
        }
    }

    const handleOnChange = async () => {
        setChecked(!checked)
    }

    return (
        <>
            <ModalWrapper big={true}>
                <ProfileFormContent>
                    <Header>{edit ? findlanguage().editUser : findlanguage().newUser}</Header>
                    <div
                        className="custom-control custom-switch"
                        style={{ position: 'absolute', marginLeft: '69%' }}
                    >
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitches"
                            checked={checked}
                            onChange={handleOnChange}
                            style={{ cursor: 'pointer' }} // Inline style for cursor
                        />
                        {
                            permissions?.myProfile?.canEdit ?
                                <label
                                    className="custom-control-label"
                                    htmlFor="customSwitches"
                                    style={{ cursor: 'pointer' }}
                                >
                                    {checked ? findlanguage().visible : findlanguage().notVisible}

                                    <IconInfo
                                        title={findlanguage().visibleIcon}
                                        style={{ width: '16px', marginLeft: '4px', marginTop: '-1px' }}
                                    />
                                </label>
                                :
                                null
                        }
                    </div>
                    <Input
                        placeholder={findlanguage().name}
                        big
                        value={formValue('name')}
                        onChange={(e) => changeForm(e.target.value, 'name')}
                        highlight={showHighlight('name')}
                        autoComplete={'xoff'}
                    />

                    {edit ? (
                        <Input
                            placeholder={findlanguage().emailReadOnly}
                            big
                            value={formValue('email')}
                            type={'text'}
                            readonly
                        />
                    ) : (
                        <Input
                            placeholder={findlanguage().email}
                            big
                            value={formValue('email')}
                            onChange={(e) => changeForm(e.target.value, 'email')}
                            type={'text'}
                            highlight={showHighlight('email')}
                            autoComplete={'xoff'}
                        />
                    )}

                    {edit ? (
                        <Input
                            placeholder={findlanguage().phoneNumberReadOnly}
                            value={formValue('phone')}
                            big
                            readonly
                        />
                    ) : (
                        <Input
                            placeholder={findlanguage().phone + '*'}
                            value={formValue('phone')}
                            onChange={(e) => changeForm(e.target.value, 'phone')}
                            onBlur={handleInputPhone}
                            onFocus={(e) => togglePhone(true)}
                            id="phone1"
                            type={'tel'}
                            autoComplete={'off'}
                            highlight={showHighlight('phone')}
                            big
                        />
                    )}

                    <Popover
                        placement="top"
                        isOpen={stateTooltipPhone}
                        toggle={togglePhone}
                        target={'phone1'}
                    >
                        <PopoverHeader>{findlanguage().rulesPhoneNumberHeader}</PopoverHeader>
                        <PopoverBody>{findlanguage().rulesPhoneNumberText}</PopoverBody>
                    </Popover>

                    <Input
                        placeholder={findlanguage().department}
                        big
                        value={formValue('department')}
                        onChange={(e) => changeForm(e.target.value, 'department')}
                        type={'text'}
                        highlight={showHighlight('department')}
                        autoComplete={'xoff'}
                    />
                    <SemiHeader highlight={showHighlight('roles')}>
                        <label>{findlanguage().role}</label>
                        <Check
                            nowidth
                            title={findlanguage().useForSupplier}
                            value={isSupplier}
                            onChange={() => handleCheckboxChange('supplier')}
                            checked={isSupplier}
                        />
                        <Check
                            nowidth
                            title={findlanguage().useForBuyer}
                            value={isBuyer}
                            onChange={() => handleCheckboxChange('buyer')}
                            checked={isBuyer}
                        />
                        <Check
                            nowidth
                            title={findlanguage().useForAdministrative}
                            value={isAdministrative}
                            onChange={() => handleCheckboxChange('administrative')}
                            checked={isAdministrative}
                        />
                    </SemiHeader>


                    <SemiHeader highlight={showHighlight('permissions')}>
                        <label>{findlanguage().permissions}</label>
                    </SemiHeader>
                    <PermissionsTab style={{ marginTop: '-17px' }}>
                        <div>
                            <label>{findlanguage().myProfile}</label>
                            <Check
                                nowidth
                                title={findlanguage().canViewProfile}
                                value={permissions?.myProfile?.canView}
                                onChange={(e) => changePermission({ key: 'myProfile', subSection: 'canView' })}
                                checked={permissions?.myProfile?.canView}
                            />
                            <Check
                                nowidth
                                title={findlanguage().canViewSupplierInformation}
                                value={permissions?.myProfile?.canViewSupplier}
                                onChange={(e) => changePermission({ key: 'myProfile', subSection: 'canViewSupplier' })}
                                checked={permissions?.myProfile?.canViewSupplier}
                            />
                            <Check
                                nowidth
                                title={findlanguage().canViewBuyerInformation}
                                value={permissions?.myProfile?.canViewBuyer}
                                onChange={(e) => changePermission({ key: 'myProfile', subSection: 'canViewBuyer' })}
                                checked={permissions?.myProfile?.canViewBuyer}
                            />
                            <Check
                                nowidth
                                title={findlanguage().canEditProfile}
                                value={permissions?.myProfile?.canEdit}
                                onChange={(e) => changePermission({ key: 'myProfile', subSection: 'canEdit' })}
                                checked={permissions?.myProfile?.canEdit}
                            />
                        </div>
                        <div>
                            <label>{findlanguage().connection}</label>
                            <Check
                                nowidth
                                title={findlanguage().canView}
                                value={permissions?.connections?.canView}
                                onChange={(e) => changePermission({ key: 'connections', subSection: 'canView' })}
                                checked={permissions?.connections?.canView}
                            />
                            <Check
                                nowidth
                                title={findlanguage().canEdit}
                                value={permissions?.connections?.canEdit}
                                onChange={(e) => changePermission({ key: 'connections', subSection: 'canEdit' })}
                                checked={permissions?.connections?.canEdit}
                            />
                            <Check
                                nowidth
                                title={findlanguage().receiveNotifications}
                                value={permissions?.connections?.notifications}
                                onChange={(e) => changePermission({ key: 'connections', subSection: 'notifications' })}
                                checked={permissions?.connections?.notifications}
                            />
                        </div>
                        <div>
                            <label>{findlanguage().myWishlist}</label>
                            <Check
                                nowidth
                                title={findlanguage().canView}
                                value={permissions?.wishlist?.canView}
                                onChange={(e) => changePermission({ key: 'wishlist', subSection: 'canView' })}
                                checked={permissions?.wishlist?.canView}
                            />
                            <Check
                                nowidth
                                title={findlanguage().canEdit}
                                value={permissions?.wishlist?.canEdit}
                                onChange={(e) => changePermission({ key: 'wishlist', subSection: 'canEdit' })}
                                checked={permissions?.wishlist?.canEdit}
                            />
                            <Check
                                nowidth
                                title={findlanguage().showMyWishlistOnTheDashboard}
                                value={permissions?.wishlist?.showOnDashboard}
                                onChange={(e) => changePermission({ key: 'wishlist', subSection: 'showOnDashboard' })}
                                checked={permissions?.wishlist?.showOnDashboard}
                            />
                        </div>
                        <div>
                            <label>{findlanguage().filter}</label>
                            <Check
                                nowidth
                                title={findlanguage().canUseIt}
                                value={permissions?.filter?.canUse}
                                onChange={(e) => changePermission({ key: 'filter', subSection: 'canUse' })}
                                checked={permissions?.filter?.canUse}
                            />
                        </div>
                        <div>
                            <label>{findlanguage().message}</label>
                            <Check
                                nowidth
                                title={findlanguage().canUseIt}
                                value={permissions?.message?.canUse}
                                onChange={(e) => changePermission({ key: 'message', subSection: 'canUse' })}
                                checked={permissions?.message?.canUse}
                            />
                            <Check
                                nowidth
                                title={findlanguage().showMessagesOnTheDashboard}
                                value={permissions?.message?.showOnDashboard}
                                onChange={(e) => changePermission({ key: 'message', subSection: 'showOnDashboard' })}
                                checked={permissions?.message?.showOnDashboard}
                            />
                        </div>
                        <div>
                            <label>{findlanguage().profileView}</label>
                            <Check
                                nowidth
                                title={findlanguage().canView}
                                value={permissions?.profileViews?.canView}
                                onChange={(e) => changePermission({ key: 'profileViews', subSection: 'canView' })}
                                checked={permissions?.profileViews?.canView}
                            />
                            <Check
                                nowidth
                                title={findlanguage().showProfileViewsOnTheDashboard}
                                value={permissions?.profileViews?.showOnDashboard}
                                onChange={(e) => changePermission({ key: 'profileViews', subSection: 'showOnDashboard' })}
                                checked={permissions?.profileViews?.showOnDashboard}
                            />
                        </div>
                        <div>
                            <label>{findlanguage().myQMS}</label>
                            <Check
                                nowidth
                                title={findlanguage().canView}
                                value={permissions?.myQms?.canView}
                                onChange={(e) => changePermission({ key: 'myQms', subSection: 'canView' })}
                                checked={permissions?.myQms?.canView}
                            />
                        </div>
                        <div>
                            <label>{findlanguage().myProcessOverview}</label>
                            <Check
                                nowidth
                                title={findlanguage().canView}
                                value={permissions?.myProcessOverview?.canView}
                                onChange={(e) => changePermission({ key: 'myProcessOverview', subSection: 'canView' })}
                                checked={permissions?.myProcessOverview?.canView}
                            />
                            <Check
                                nowidth
                                title={findlanguage().canEdit}
                                value={permissions?.myProcessOverview?.canEdit}
                                onChange={(e) => changePermission({ key: 'myProcessOverview', subSection: 'canEdit' })}
                                checked={permissions?.myProcessOverview?.canEdit}
                            />
                        </div>
                        <div>
                            <label>{findlanguage().myBuyers}</label>
                            <Check
                                nowidth
                                title={findlanguage().canView}
                                value={permissions?.myBuyers?.canView}
                                onChange={(e) => changePermission({ key: 'myBuyers', subSection: 'canView' })}
                                checked={permissions?.myBuyers?.canView}
                            />
                            <Check
                                nowidth
                                title={findlanguage().canEdit}
                                value={permissions?.myBuyers?.canEdit}
                                onChange={(e) => changePermission({ key: 'myBuyers', subSection: 'canEdit' })}
                                checked={permissions?.myBuyers?.canEdit}
                            />
                            <Check
                                nowidth
                                title={findlanguage().showOnDashboard}
                                value={permissions?.myBuyers?.showOnDashboard}
                                onChange={(e) => changePermission({ key: 'myBuyers', subSection: 'showOnDashboard' })}
                                checked={permissions?.myBuyers?.showOnDashboard}
                            />
                            <Check
                                nowidth
                                title={findlanguage().receiveNotifications}
                                value={permissions?.myBuyers?.notifications}
                                onChange={(e) => changePermission({ key: 'myBuyers', subSection: 'notifications' })}
                                checked={permissions?.myBuyers?.notifications}
                            />
                        </div>
                        <div>
                            <label>{findlanguage().mySuppliers}</label>
                            <Check
                                nowidth
                                title={findlanguage().canView}
                                value={permissions?.mySuppliers?.canView}
                                onChange={(e) => changePermission({ key: 'mySuppliers', subSection: 'canView' })}
                                checked={permissions?.mySuppliers?.canView}
                            />
                            <Check
                                nowidth
                                title={findlanguage().canEdit}
                                value={permissions?.mySuppliers?.canEdit}
                                onChange={(e) => changePermission({ key: 'mySuppliers', subSection: 'canEdit' })}
                                checked={permissions?.mySuppliers?.canEdit}
                            />
                            <Check
                                nowidth
                                title={findlanguage().receiveNotifications}
                                value={permissions?.mySuppliers?.notifications}
                                onChange={(e) => changePermission({ key: 'mySuppliers', subSection: 'notifications' })}
                                checked={permissions?.mySuppliers?.notifications}
                            />
                        </div>
                        <div>
                            <label>{findlanguage().myDocuments}</label>
                            <Check
                                nowidth
                                title={findlanguage().canView}
                                value={permissions?.myDocuments?.canView}
                                onChange={(e) => changePermission({ key: 'myDocuments', subSection: 'canView' })}
                                checked={permissions?.myDocuments?.canView}
                            />
                            <Check
                                nowidth
                                title={findlanguage().canEdit}
                                value={permissions?.myDocuments?.canEdit}
                                onChange={(e) => changePermission({ key: 'myDocuments', subSection: 'canEdit' })}
                                checked={permissions?.myDocuments?.canEdit}
                            />
                            <Check
                                nowidth
                                title={findlanguage().receiveNotifications}
                                value={permissions?.myDocuments?.notifications}
                                onChange={(e) => changePermission({ key: 'myDocuments', subSection: 'notifications' })}
                                checked={permissions?.myDocuments?.notifications}
                            />
                        </div>
                    </PermissionsTab>


                </ProfileFormContent>

                <ProfileFormActions>
                    {edit ? (
                        <DashboardButton loading={loading} onClick={updateUser} primary>
                            {findlanguage().save}
                        </DashboardButton>
                    ) : (
                        <DashboardButton loading={loading} onClick={createUser} primary>
                            {findlanguage().save}
                        </DashboardButton>
                    )}
                </ProfileFormActions>
            </ModalWrapper>
        </>
    )
}
