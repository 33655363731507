import React, { useEffect, useState, useContext } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import DashboardPanel from 'components/Dashboard/Panels/Wrapper';
import { FetchExpiredChart } from 'services/dashboard';
import { Load } from 'ui/styled';
import { CoreContext } from 'context/CoreContext';

export default function DashboardPanelExpiredDocument({ cardTitle, onClose }) {
    const { premium, profile, findlanguage } = useContext(CoreContext);

    const panel = {
        title: findlanguage().expiredRequirement,
        actions: [],
        onClose,
    };

    const [chartData, setChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState(null);

    const [loading, setLoading] = useState(false);

    const [locked, setLocked] = useState(true);
    const [showWarning, setShowWarning] = useState(false);

    const checkAccess = () => {
        let result = true;
        if (premium.type !== 'free') {
            result = false;
        }
        return result;
    };

    const loadExpiredDocument = () => {
        setLoading(true);

        FetchExpiredChart().then((chart) => {
            if (!chart || chart.length === 0) {
                setChartData(null);
            } else {
                const labels = chart.map((item) => item.document);
                const data = chart.map((item) => parseInt(item.count));
                const backgroundColors = [
                    '#7189bf',
                    '#df7599',
                    '#ffc785',
                    '#72d6c9',
                    '#90d7ff',
                    '#3f8cb5',
                    '#ffe156',
                    '#3f88c5',
                ];

                setChartData({
                    labels: labels,
                    datasets: [
                        {
                            data: data,
                            backgroundColor: backgroundColors.slice(0, data.length),
                            borderColor: '#ffffff',
                            borderWidth: 2,
                        },
                    ],
                });

                setChartOptions({
                    plugins: {
                        legend: {
                            position: 'right',
                            labels: {
                                font: {
                                    size: 14,
                                    family: 'Open Sans',
                                    weight: 'bold',
                                },
                                color: '#303030',
                            },
                            align: 'center',
                        },
                        datalabels: {
                            display: true,
                            color: 'white',
                            font: {
                                size: 16,
                                family: 'Open Sans',
                                weight: 'bold',
                            },
                            formatter: (value) => value,
                        },
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                });
            }

            setLoading(false);
        });
    };

    useEffect(() => {
        if (!checkAccess()) loadExpiredDocument();
    }, []);

    return (
        <>
            {loading ? (
                <Load color="#8dbd64" />
            ) : (
                <DashboardPanel
                    {...panel}
                    subscription={premium?.type}
                    locked={checkAccess()}
                    showWarning={showWarning}
                >
                    {!chartData || chartData.labels.length === 0 ? (
                        <div style={{ width: '100%' }}>{findlanguage().noData}</div>
                    ) : (
                        <div style={{ width: '95%', height: '270px' }}>
                            <Doughnut data={chartData} options={chartOptions} plugins={[ChartDataLabels]} />
                        </div>
                    )}
                </DashboardPanel>
            )}
        </>
    );
}
