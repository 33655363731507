import styled from 'styled-components'

export const CardSearched = styled.div.attrs({})`
    min-width: calc(98%);
    max-width: calc(98%);
    background: var(--white-color);
    padding: 6px 6px 6px 12px;
    margin-bottom: 5px;
    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);

    display: flex;
    align-items: flex-start;
    cursor: pointer;
`

export const CardLineActionIcon = styled.img.attrs({
    src: '/icons/chatsent-black.png',
    width: 16,
})``

export const CardImage = styled.div.attrs({})`
    width: 25px;
    height: 25px;
    border-radius: 20px;
    border: 1px solid #eee;
    ${(props) =>
        props.url
            ? `
    background: url(${props.url}) no-repeat center center / cover #fff;
    background-size: 25px;
        `
            : `
    background: url(/images/no-image.png) no-repeat center center / cover;
        `}
`
export const CardContent = styled.div.attrs({})`
    padding: 0 10px;
    flex: 1;
`
export const CardTitle = styled.div.attrs({})`
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--grey-color);
`

export const CardActions = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    height: 100%;
    margin: 6px 8px 2px 7px;
`

export const CardRemove = styled.img.attrs({
    src: '/icons/trash.svg',
})``
