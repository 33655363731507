import React, { useEffect, useState } from 'react'

import Button from 'components/Form/Button'

import {
    ChatHeader,
    ChatHeaderUser,
    ChatHeaderUserImage,
    ChatHeaderUserText,
    ChatBody,
    ChatMessage,
    ChatMessageBaloon,
    ChatMessageTime,
    ChatInputContainer,
    ChatInputInput,
    ChatButton,
    ChatButtonIcon,
} from './styled'
import moment from 'moment'

export default function ModalChatModeChat({ handleStop, currentChat, minimize }) {
    const [messages, setMessages] = useState([])
    const [chatMessage, setChatMessage] = useState('')

    const sentMessage = (message) => {
        setMessages([
            ...messages,
            { created_at: new Date().getTime(), message, mine: true, sentedBy: currentChat?.name },
        ])
    }

    const handleEnter = (e) => {
        if (e.keyCode === 13) {
            handleSent()
        }
    }

    const handleSent = () => {
        sentMessage(chatMessage)
        setChatMessage('')
    }

    useEffect(() => {
        sentMessage(currentChat?.description)
    }, [])

    useEffect(() => {
        const chatBody = document.getElementById('chatBody')
        chatBody.scrollTo({ top: chatBody.scrollHeight, behavior: 'smooth' })
    }, [messages])

    return (
        <>
            <ChatHeader>
                <ChatHeaderUser onClick={minimize}>
                    <ChatHeaderUserImage />
                    <ChatHeaderUserText>{currentChat?.clerck}</ChatHeaderUserText>
                </ChatHeaderUser>
                <Button small red onClick={handleStop}>
                    Stop Chat
                </Button>
            </ChatHeader>

            <ChatBody id={'chatBody'}>
                {messages?.map((item, key) => (
                    <ChatMessage key={key} mine={item.mine}>
                        <ChatMessageBaloon mine={item.mine}>{item.message}</ChatMessageBaloon>
                        <ChatMessageTime>
                            {item.sentedBy} - Sent at {moment(item.created_at).format('LT')}{' '}
                        </ChatMessageTime>
                    </ChatMessage>
                ))}
            </ChatBody>

            <ChatInputContainer>
                <ChatInputInput
                    placeholder={'Type something'}
                    value={chatMessage}
                    onChange={(e) => setChatMessage(e.target.value)}
                    onKeyDown={handleEnter}
                />
                <ChatButton onClick={handleSent}>
                    <ChatButtonIcon />
                </ChatButton>
            </ChatInputContainer>
        </>
    )
}
