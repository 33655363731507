import styled from 'styled-components'

export const FormTitle = styled.div.attrs({})`
    color: var(--primary-color);
    font-weight: bold;
    font-size: 20px;

    margin: 50px 0 10px;
`
export const FormText = styled.div.attrs({})`
    font-size: 15px;
    color: var(--grey-color);

    max-width: 240px;
    margin-bottom: 30px;
`

export const FormSpacer = styled.div.attrs({})`
    margin-top: 40px;
`
export const FormCentred = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
`

export const PasswordValidator = styled.div.attrs({})`
    margin: 40px 0 60px;
`
export const PasswordValidatorTitle = styled.div.attrs({})`
    font-size: 15px;
    color: var(--grey-color);
    margin-bottom: 15px;
`
export const PasswordValidatorContent = styled.div.attrs({})`
    display: flex;
`
export const PasswordValidatorItem = styled.div.attrs({})`
    width: 31px;
    height: 10px;
    border-radius: 4px;
    ${(props) =>
        props.color
            ? `
            background: var(--password-color${props.color});
        `
            : `
            background: var(--lightgrey-color);
        `}
    ${(props) =>
        !props.active
            ? `
            background: var(--password-color0);
        `
            : ``}
    margin: 0 2.5px;
`
