import styled from 'styled-components'

export const ProfileFormContent = styled.div.attrs({})`
    width: 400px;
    margin-left: 60px;
`

export const ProfileFormActions = styled.div.attrs({})`
    display: flex;
    justify-content: center;
`
export const PageFormSpace = styled.div.attrs({})`
    margin-right: 12px;
`

export const RowBlankSpace = styled.div.attrs({})`
    height: 40px;
`

export const RowForm = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    gap: 0 20px;
    overflow: auto;
`
export const ModalFormContent = styled.div.attrs({})`
    flex: 1;
`
export const ModalFormLabel = styled.div.attrs({})`
    margin-bottom: 7px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--primary-color);
    ${(props) =>
        props.spaced
            ? `
            margin-bottom: 15px;
        `
            : ``}
`
export const CheckContent = styled.div.attrs({})`
    padding-bottom: 30px;
`

export const AttachRow = styled.div.attrs({})`
    display: flex;
    align-items: center;
`
export const AttachItem = styled.div.attrs({})`
    display: flex;
    align-items: center;
`

export const AttachItemLink = styled.div.attrs({})`
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--grey-color);
`
export const AttachItemClose = styled.img.attrs({
    src: '/icons/close.svg',
    width: 12,
})`
    margin: 2px 0 0 6px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`

export const UploadHiddenInput = styled.input.attrs({
    id: 'upload-input-hidden',
    type: 'file',
    accept: 'application/pdf',
})`
    display: none;
`

export const Label = styled.div.attrs({})`
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    padding-left: 30px;
    padding-top: 5px;
    margin-bottom: 10px;
`
