import styled from 'styled-components'

export const SuccessIcon = styled.img.attrs({
    src: '/icons/circle-check.svg',
})`
    margin: 20px 0 0;
`
export const SuccessText = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: var(--grey-color);
    margin: 30px 0;

    max-height: 60vh;
    overflow: auto;
`

export const ModalActions = styled.div.attrs({})`
    display: flex;
`
