import { DELETE, GET, POST, PUT } from './api'
// import { ReadObject, SaveObject } from './storage'

export const FetchConnections = async (type, start = 0, limit = 10) => {
    return await GET(`/connections?_limit=-1${type ? `&type=${type}` : ''}`, true)
}

export const CountConnections = async (type) => {
    return await GET(`/connections/count?${type ? `type=${type}` : ''}`, true)
}

export const NewConnection = async (id) => {
    return await POST(`/connections`, { receiverUser: id }, true)
}

export const RemoveConnection = async (connectionId) => {
    return await DELETE(`/connections/${connectionId}`, true)
}

export const UpdateConnection = async (connection) => {
    return await PUT(`/connections/${connection.id}`, connection, true)
}
