import React, { useRef, useState } from 'react'
import { Tooltip } from 'reactstrap'

import { InfoIconStyled } from './styled'

export default function InfoIcon({
    id,
    className,
    tooltipContent,
    tooltipPlacement,
    tooltipAutoHide,
    tooltipTrigger,
}) {
    const ref = useRef()

    const [isOpen, setIsOpen] = useState(false)

    const toogleIsOpen = () => setIsOpen((prev) => !prev)

    const handleTooltipClick = (e) => {
        e.stopPropagation()
    }

    return (
        <>
            <InfoIconStyled ref={ref} id={id} className={className} />
            {tooltipContent ? (
                <Tooltip
                    onClick={handleTooltipClick}
                    target={ref}
                    isOpen={isOpen}
                    autohide={tooltipAutoHide}
                    placement={tooltipPlacement}
                    trigger={tooltipTrigger}
                    toggle={toogleIsOpen}
                >
                    {tooltipContent}
                </Tooltip>
            ) : null}
        </>
    )
}
