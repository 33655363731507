import React, { useContext, useState, useEffect } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'

import {
    ProfileFormContent,
    ProfileFormActions,
    PageFormSpace,
    RowForm,
    ModalFormContent,
} from './styled'

import Button from 'components/Form/Button'
import { parseStrapiImage } from 'utils'
import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { PostDocument, UpdateDocument } from 'services/api'

export default function ModalAddSupplierInfo() {
    const [lockAllFields, setLockAllFields] = useState(false)
    const { modal, setModal } = useContext(CoreContext)
    const { user, findlanguage, language, permissions } = useContext(CoreContext)
    const [form, setForm] = useState({
        responsible_review: user?.contactName,
        email_responsible_review: user?.email,
    })
    const [addDocumentCliked, setAddDocumentCliked] = useState(false)

    function translate(reason) {
        switch (language) {
            case 'portugues':
                switch (reason) {
                    case 'Agreement':
                        return 'Acordo'
                    case 'Form':
                        return 'Formulário'
                    case 'Procedure':
                        return 'Procedimento'
                    case 'Work Instruction':
                        return 'Instrução de Trabalho'
                    default:
                        return reason
                }
            case 'english':
                switch (reason) {
                    case 'Agreement':
                        return 'Agreement'
                    case 'Form':
                        return 'Form'
                    case 'Procedure':
                        return 'Procedure'
                    case 'Work Instruction':
                        return 'Work Instruction'
                    default:
                        return reason
                }
            default:
                return reason
        }
    }

    useEffect(() => {
        init()
    }, [])

    const init = () => {
        if (modal.typeOfAction === 'pending') {
            setLockAllFields(true)
            const dateRevision =
                modal.document?.emission_revision_date !== null
                    ? new Date(modal.document?.emission_revision_date.replaceAll('-', '/'))
                    : null

            setForm({
                id: modal?.document?.id,
                type: {
                    value: modal?.document?.type,
                    label: translate(modal?.document?.type),
                },
                code: modal?.document?.code,
                emission_revision_date: dateRevision,
                name: modal?.document?.name,
                responsible_approval: {
                    label: modal?.document?.responsible_approval?.contactName,
                    value: modal?.document?.responsible_approval?.contactName,
                },
                email_responsible_approval: modal?.document?.responsible_approval?.email,
                revision: modal?.document?.revision,
                status: modal?.document?.status,
                responsible_review_id: modal.document?.responsible_review?.id,
                responsible_approval_id: modal.document?.responsible_approval?.id,
                responsible_review: modal.document?.responsible_review?.contactName,
                email_responsible_review: modal?.document?.responsible_review?.email,
                issue_date:
                    modal?.document?.issue_date !== null
                        ? new Date(modal?.document?.issue_date?.replaceAll('-', '/'))
                        : undefined,
                file: modal?.document?.file,
                oldversion_id:
                    modal?.document?.oldversion_id === null &&
                    modal?.document?.oldversion_id === undefined
                        ? null
                        : modal?.document?.oldversion_id,
            })
        } else if (modal.typeOfAction === 'returned') {
            setLockAllFields(false)
            const dateRevision =
                modal.document?.emission_revision_date !== null
                    ? new Date(modal.document?.emission_revision_date.replaceAll('-', '/'))
                    : null

            setForm({
                id: modal?.document?.id,
                type: {
                    value: modal?.document?.type,
                    label: translate(modal?.document?.type),
                },
                code: modal?.document?.code,
                emission_revision_date: dateRevision,
                name: modal?.document?.name,
                responsible_approval: {
                    label: modal?.document?.responsible_approval?.contactName,
                    value: modal?.document?.responsible_approval?.contactName,
                },
                email_responsible_approval: modal?.document?.responsible_approval?.email,
                revision: modal?.document?.revision,
                status: modal?.document?.status,
                responsible_review_id: modal.document?.responsible_review?.id,
                responsible_approval_id: modal.document?.responsible_approval?.id,
                responsible_review: modal?.document?.responsible_review?.contactName,
                email_responsible_review: modal?.document?.responsible_review?.email,
                issue_date:
                    modal?.document?.issue_date !== null
                        ? new Date(modal?.document?.issue_date?.replaceAll('-', '/'))
                        : undefined,
                file: modal?.document?.file,
                reason_reject: modal?.document?.reason_reject,
            })
        } else if (modal.typeOfAction === 'active') {
            setLockAllFields(true)
            const dateRevision =
                modal.document?.emission_revision_date !== null
                    ? new Date(modal.document?.emission_revision_date.replaceAll('-', '/'))
                    : null

            setForm({
                id: modal?.document?.id,
                type: {
                    value: modal?.document?.type,
                    label: translate(modal?.document?.type),
                },
                code: modal?.document?.code,
                emission_revision_date: dateRevision,
                name: modal?.document?.name,
                responsible_approval: {
                    label: modal?.document?.responsible_approval?.contactName,
                    value: modal?.document?.responsible_approval?.contactName,
                },
                email_responsible_approval: modal?.document?.responsible_approval?.email,
                revision: modal?.document?.revision,
                status: modal?.document?.status,
                responsible_review_id: modal.document?.responsible_review?.id,
                responsible_approval_id: modal.document?.responsible_approval?.id,
                responsible_review: modal?.document?.responsible_review?.contactName,
                email_responsible_review: modal?.document?.responsible_review?.email,
                issue_date:
                    modal?.document?.issue_date !== null
                        ? new Date(modal?.document?.issue_date?.replaceAll('-', '/'))
                        : undefined,
                file: modal?.document?.file,
            })
        } else if (modal.typeOfAction === 'view' || modal.typeOfAction === 'obsolete') {
            setLockAllFields(true)
            const dateRevision =
                modal.document?.emission_revision_date !== null
                    ? new Date(modal.document?.emission_revision_date.replaceAll('-', '/'))
                    : null

            setForm({
                id: modal?.document?.id,
                type: {
                    value: modal?.document?.type,
                    label: translate(modal?.document?.type),
                },
                code: modal?.document?.code,
                emission_revision_date: dateRevision,
                name: modal?.document?.name,
                responsible_approval: {
                    label: modal?.document?.responsible_approval?.contactName,
                    value: modal?.document?.responsible_approval?.contactName,
                },
                email_responsible_approval: modal?.document?.responsible_approval?.email,
                revision: modal?.document?.revision,
                status: modal?.document?.status,
                responsible_review_id: modal.document?.responsible_review?.id,
                responsible_approval_id: modal.document?.responsible_approval?.id,
                responsible_review: modal?.document?.responsible_review?.contactName,
                email_responsible_review: modal?.document?.responsible_review?.email,
                issue_date:
                    modal?.document?.issue_date !== null
                        ? new Date(modal?.document?.issue_date?.replaceAll('-', '/'))
                        : undefined,
                file: modal?.document?.file,
            })
        }

        if (!permissions?.myDocuments?.canEdit) {
            setLockAllFields(true)
        }
    }

    const formValue = (ref) => {
        return form?.[ref] ? form?.[ref] : ''
    }

    const changeForm = async (value, ref) => {
        if (ref === 'type') setForm({ ...form, [ref]: value })
        else if (
            typeof value === 'object' &&
            ref !== 'emission_revision_date' &&
            ref !== 'issue_date' &&
            ref !== 'responsible_approval'
        )
            setForm({ ...form, [ref]: value.title })
        else if (ref === 'responsible_approval')
            setForm({ ...form, [ref]: value, email_responsible_approval: value.email })
        else setForm({ ...form, [ref]: value })
    }

    const requestRegister = async () => {
        if (
            (modal.typeOfAction === 'active' || modal.typeOfAction === 'new') &&
            (form.responsible_approval === undefined ||
                form.responsible_approval === '' ||
                document.getElementById('formFileMultiple')?.files?.[0] === undefined)
        ) {
            let message = findlanguage().makeSureYouFillFields + ': '

            if (form.name === '' || form.name === undefined)
                message += '\n - ' + findlanguage().documentName

            if (form.responsible_approval === '')
                message += '\n - ' + findlanguage().responsibleApproval

            if (document.getElementById('formFileMultiple')?.files?.[0] === undefined)
                message += '\n - ' + findlanguage().chooseFile

            toast.error(message)
            setAddDocumentCliked(true)
        } else if (
            modal.typeOfAction === 'pending' &&
            (form?.issue_date === undefined || form?.issue_date === null || form?.issue_date === '')
        ) {
            toast.error(findlanguage().chooseIssueDate)
            setAddDocumentCliked(true)
        } else {
            const formData = new FormData()
            const cloneForm = _.cloneDeep(form)

            if (cloneForm.oldversion_id !== null && cloneForm.oldversion_id !== undefined)
                formData.append('oldversion_id', cloneForm.oldversion_id)

            if (cloneForm.code !== undefined) formData.append('code', cloneForm.code)

            if (
                cloneForm.emission_revision_date !== undefined &&
                cloneForm.emission_revision_date !== null
            )
                formData.append(
                    'emission_revision_date',
                    cloneForm.emission_revision_date.toISOString()
                )

            if (cloneForm.responsible_approval !== undefined)
                formData.append(
                    'responsible_approval',
                    modal.companyUsers.find(
                        (x) => x.title === cloneForm.responsible_approval?.value
                    )?.id
                )

            if (cloneForm.revision !== undefined) formData.append('revision', cloneForm.revision)

            if (cloneForm.type !== undefined) formData.append('type', cloneForm.type.value)

            if (cloneForm.name !== undefined) formData.append('name', cloneForm.name)

            if (modal.typeOfAction === 'new' || modal.typeOfAction === 'active')
                formData.append('responsible_review', user.id)

            if (cloneForm.issue_date !== undefined && cloneForm.issue_date !== '')
                formData.append('issue_date', cloneForm.issue_date.toISOString())

            cloneForm.file = formData
            cloneForm.user_id = user.id

            //console.log('form.responsible_approval', form.responsible_approval)

            if (document.getElementById('formFileMultiple')?.files?.[0]) {
                new Promise((resolve, reject) => {
                    let reader = new FileReader()
                    reader.onload = async () => {
                        let source = `${reader.result}`.split(',')[1]
                        let image = {
                            filename: document.getElementById('formFileMultiple')?.files?.[0].name,
                            filetype: document.getElementById('formFileMultiple')?.files?.[0].type,
                            source,
                        }

                        let btc = window.atob(image.source)
                        let btn = new Array(btc.length)
                        for (var i = 0; i < btc.length; i++) {
                            btn[i] = btc.charCodeAt(i)
                        }
                        var bta = new Uint8Array(btn)
                        let boobs = new Blob([bta], { type: 'image/png' })
                        let filesss = new File([boobs], `${image.filename}`)

                        formData.append('filePdf', filesss, filesss.name)

                        if (modal.typeOfAction === 'pending') {
                            formData.append('status', 'Active')
                            formData.append('id', cloneForm.id)

                            const updated = await UpdateDocument(formData, cloneForm.id)
                            if (updated) {
                                setModal({
                                    type: 'alert',
                                    warning: true,
                                    text: findlanguage().documentApproved,
                                })

                                modal.refresh()
                            } else
                                setModal({
                                    type: 'alert',
                                    warning: true,
                                    text: 'Something went wrong.',
                                })
                        } else if (modal.typeOfAction === 'returned') {
                            formData.append('status', 'Pending')
                            formData.append('id', cloneForm.id)

                            const updated = await UpdateDocument(formData, cloneForm.id)
                            if (updated) {
                                setModal({
                                    type: 'alert',
                                    warning: true,
                                    text: findlanguage().documentResubmitted,
                                })

                                modal.refresh()
                            } else
                                setModal({
                                    type: 'alert',
                                    warning: true,
                                    text: 'Something went wrong.',
                                })
                        } else {
                            formData.append('status', 'Pending')
                            const saved = await PostDocument(formData)

                            if (saved) {
                                setModal({
                                    type: 'alert',
                                    warning: true,
                                    text: findlanguage().documentAdded,
                                })

                                modal.refresh()
                            } else
                                setModal({
                                    type: 'alert',
                                    warning: true,
                                    text: 'Something went wrong.',
                                })
                        }
                    }
                    reader.readAsDataURL(document.getElementById('formFileMultiple')?.files?.[0])
                })
            } else if (modal.typeOfAction === 'pending') {
                formData.append('status', 'Active')
                formData.append('id', cloneForm.id)

                const updated = await UpdateDocument(formData, cloneForm.id)

                if (
                    updated &&
                    cloneForm.oldversion_id !== null &&
                    cloneForm.oldversion_id !== undefined
                ) {
                    const formData2 = new FormData()
                    formData2.append('status', 'Obsolete')
                    formData2.append('id', cloneForm.oldversion_id)

                    await UpdateDocument(formData2, cloneForm.oldversion_id)
                }

                if (updated) {
                    setModal({
                        type: 'alert',
                        warning: true,
                        text: findlanguage().documentApproved,
                    })

                    modal.refresh()
                } else
                    setModal({
                        type: 'alert',
                        warning: true,
                        text: 'Something went wrong.',
                    })
            } else if (modal.typeOfAction === 'returned') {
                formData.append('status', 'Pending')
                formData.append('id', cloneForm.id)

                const updated = await UpdateDocument(formData, cloneForm.id)

                if (updated) {
                    setModal({
                        type: 'alert',
                        warning: true,
                        text: findlanguage().documentResubmitted,
                    })

                    modal.refresh()
                } else
                    setModal({
                        type: 'alert',
                        warning: true,
                        text: 'Something went wrong.',
                    })
            }
        }
    }

    const requestRegisterWihoutSubmitting = async () => {
        if (
            (modal.typeOfAction === 'active' || modal.typeOfAction === 'new') &&
            (form.responsible_approval === undefined ||
                form.responsible_approval === '' ||
                document.getElementById('formFileMultiple')?.files?.[0] === undefined)
        ) {
            let message = findlanguage().makeSureYouFillFields + ': '

            if (form.name === '' || form.name === undefined)
                message += '\n - ' + findlanguage().documentName

            if (form.responsible_approval === '')
                message += '\n - ' + findlanguage().responsibleApproval

            if (document.getElementById('formFileMultiple')?.files?.[0] === undefined)
                message += '\n - ' + findlanguage().chooseFile

            toast.error(message)
            setAddDocumentCliked(true)
        } else {
            const formData = new FormData()
            const cloneForm = _.cloneDeep(form)

            if (cloneForm.oldversion_id !== null && cloneForm.oldversion_id !== undefined)
                formData.append('oldversion_id', cloneForm.oldversion_id)

            if (cloneForm.code !== undefined) formData.append('code', cloneForm.code)

            if (
                cloneForm.emission_revision_date !== undefined &&
                cloneForm.emission_revision_date !== null
            )
                formData.append(
                    'emission_revision_date',
                    cloneForm.emission_revision_date.toISOString()
                )

            if (cloneForm.name !== undefined) formData.append('name', cloneForm.name)

            if (cloneForm.responsible_approval !== undefined)
                formData.append(
                    'responsible_approval',
                    modal.companyUsers.find(
                        (x) => x.title === cloneForm.responsible_approval?.value
                    )?.id
                )

            if (cloneForm.revision !== undefined) formData.append('revision', cloneForm.revision)

            if (cloneForm.type !== undefined) formData.append('type', cloneForm.type.value)

            if (modal.typeOfAction === 'new' || modal.typeOfAction === 'active')
                formData.append('responsible_review', user.id)

            if (cloneForm.issue_date !== undefined && cloneForm.issue_date !== '')
                formData.append('issue_date', cloneForm.issue_date.toISOString())

            cloneForm.file = formData
            cloneForm.user_id = user.id

            //console.log('form.responsible_approval', form.responsible_approval)

            if (document.getElementById('formFileMultiple')?.files?.[0]) {
                new Promise((resolve, reject) => {
                    let reader = new FileReader()
                    reader.onload = async () => {
                        let source = `${reader.result}`.split(',')[1]
                        let image = {
                            filename: document.getElementById('formFileMultiple')?.files?.[0].name,
                            filetype: document.getElementById('formFileMultiple')?.files?.[0].type,
                            source,
                        }

                        let btc = window.atob(image.source)
                        let btn = new Array(btc.length)
                        for (var i = 0; i < btc.length; i++) {
                            btn[i] = btc.charCodeAt(i)
                        }
                        var bta = new Uint8Array(btn)
                        let boobs = new Blob([bta], { type: 'image/png' })
                        let filesss = new File([boobs], `${image.filename}`)

                        formData.append('filePdf', filesss, filesss.name)

                        if (modal.typeOfAction === 'pending') {
                            formData.append('status', 'Returned')
                            formData.append('id', cloneForm.id)

                            const updated = await UpdateDocument(formData, cloneForm.id)
                            if (updated) {
                                setModal({
                                    type: 'alert',
                                    warning: true,
                                    text: findlanguage().documentApproved,
                                })

                                modal.refresh()
                            } else
                                setModal({
                                    type: 'alert',
                                    warning: true,
                                    text: 'Something went wrong.',
                                })
                        } else if (modal.typeOfAction === 'returned') {
                            formData.append('status', 'Returned')
                            formData.append('id', cloneForm.id)

                            const updated = await UpdateDocument(formData, cloneForm.id)
                            if (updated) {
                                setModal({
                                    type: 'alert',
                                    warning: true,
                                    text: findlanguage().documentResubmitted,
                                })

                                modal.refresh()
                            } else
                                setModal({
                                    type: 'alert',
                                    warning: true,
                                    text: 'Something went wrong.',
                                })
                        } else {
                            formData.append('status', 'Returned')
                            const saved = await PostDocument(formData)

                            if (saved) {
                                setModal({
                                    type: 'alert',
                                    warning: true,
                                    text: findlanguage().documentAdded,
                                })

                                modal.refresh()
                            } else
                                setModal({
                                    type: 'alert',
                                    warning: true,
                                    text: 'Something went wrong.',
                                })
                        }
                    }
                    reader.readAsDataURL(document.getElementById('formFileMultiple')?.files?.[0])
                })
            } else if (modal.typeOfAction === 'pending') {
                formData.append('status', 'Returned')
                formData.append('id', cloneForm.id)

                const updated = await UpdateDocument(formData, cloneForm.id)

                if (
                    updated &&
                    cloneForm.oldversion_id !== null &&
                    cloneForm.oldversion_id !== undefined
                ) {
                    const formData2 = new FormData()
                    formData2.append('status', 'Returned')
                    formData2.append('id', cloneForm.oldversion_id)

                    await UpdateDocument(formData2, cloneForm.oldversion_id)
                }

                if (updated) {
                    setModal({
                        type: 'alert',
                        warning: true,
                        text: findlanguage().documentApproved,
                    })

                    modal.refresh()
                } else
                    setModal({
                        type: 'alert',
                        warning: true,
                        text: 'Something went wrong.',
                    })
            } else if (modal.typeOfAction === 'returned') {
                formData.append('status', 'Returned')
                formData.append('id', cloneForm.id)

                const updated = await UpdateDocument(formData, cloneForm.id)

                if (updated) {
                    setModal({
                        type: 'alert',
                        warning: true,
                        text: findlanguage().documentResubmitted,
                    })

                    modal.refresh()
                } else
                    setModal({
                        type: 'alert',
                        warning: true,
                        text: 'Something went wrong.',
                    })
            }
        }
    }

    const labelCss = {
        top: '10px',
        left: '0px',
        fontWeight: 'bold',
        fontSize: '14px',
        color: '#263640',
        marginTop: '5px',
    }

    const labelForSelectCss = {
        left: '0px',
        fontWeight: 'bold',
        fontSize: '14px',
        color: '#263640',
    }

    const sendBackToUser = async (reason) => {
        const formData = new FormData()
        const cloneForm = _.cloneDeep(form)

        formData.append('reason_reject', reason)
        formData.append('status', 'Returned')
        formData.append('id', cloneForm.id)

        const updated = await UpdateDocument(formData, cloneForm.id)

        if (updated) {
            setModal({
                type: 'alert',
                warning: true,
                text: findlanguage().documentReturned,
            })

            modal.refresh()
        } else
            setModal({
                type: 'alert',
                warning: true,
                text: 'Something went wrong.',
            })
    }

    const reviewSupplier = () => {
        setModal({
            type: 'review-document',
            title: findlanguage().reviewDocument,
            sendBackToUser: sendBackToUser,
        })
    }

    const generateNewRevision = async () => {
        setLockAllFields(false)
        setForm({
            id: null,
            type: form.type,
            code: '',
            emission_revision_date: '',
            name: '',
            responsible_approval: '',
            email_responsible_approval: '',
            revision: parseInt(form.revision) + 1,
            status: null,
            responsible_review_id: user.id,
            responsible_review: user.contactName,
            email_responsible_review: user.email,
            issue_date: '',
            file: null,
            oldversion_id: form.id,
        })
    }

    return (
        <ModalWrapper>
            {modal.typeOfAction === 'returned' ? (
                <>
                    <b>{findlanguage().rejectionReason}:</b> {formValue('reason_reject')}
                </>
            ) : null}
            <ProfileFormContent>
                <RowForm>
                    <ModalFormContent>
                        <label className="form-label" style={labelForSelectCss}>
                            {findlanguage().serviceType}
                        </label>
                        <CreatableSelect
                            isDisabled={lockAllFields || modal.typeOfAction === 'active'}
                            onChange={(values) => changeForm(values, 'type')}
                            isClearable
                            name="colors"
                            options={modal.docNames}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            value={formValue('type')}
                            placeholder={findlanguage().select}
                        />
                    </ModalFormContent>
                    <ModalFormContent>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().documentName}*
                        </label>
                        <input
                            disabled={lockAllFields}
                            className="form-control"
                            value={form?.name}
                            onChange={(e) => changeForm(e.target.value, 'name')}
                            style={{
                                border:
                                    addDocumentCliked &&
                                    (form.name === undefined || form.name === '')
                                        ? '1px solid red'
                                        : '',
                            }}
                        />
                    </ModalFormContent>
                </RowForm>

                <RowForm>
                    <ModalFormContent>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().documentCode}
                        </label>
                        <input
                            disabled={lockAllFields}
                            className="form-control"
                            value={form?.code}
                            onChange={(e) => changeForm(e.target.value, 'code')}
                        />
                    </ModalFormContent>
                    <ModalFormContent>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().revisionNumber}
                        </label>
                        <input
                            disabled={lockAllFields || modal.typeOfAction === 'active'}
                            className="form-control"
                            value={form?.revision}
                            type="number"
                            onChange={(e) => changeForm(e.target.value, 'revision')}
                        />
                    </ModalFormContent>
                </RowForm>

                <RowForm>
                    <ModalFormContent>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().responsibleReview}
                        </label>
                        <input
                            disabled={true}
                            className="form-control"
                            value={formValue('responsible_review')}
                        />
                    </ModalFormContent>
                    <ModalFormContent>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().responsibleApproval}
                        </label>
                        <Select
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border:
                                        addDocumentCliked &&
                                        (form?.responsible_approval === undefined ||
                                            form.responsible_approval === '')
                                            ? '1px solid red'
                                            : '',
                                }),
                            }}
                            isDisabled={lockAllFields}
                            onChange={(values) => changeForm(values, 'responsible_approval')}
                            name="colors"
                            options={modal.companyUsers.sort((a, b) =>
                                a.label.localeCompare(b.label)
                            )}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder={findlanguage().select}
                            value={formValue('responsible_approval')}
                        />
                    </ModalFormContent>
                </RowForm>
                <RowForm>
                    <ModalFormContent>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().responsibleReviewEmail}
                        </label>
                        <input
                            disabled={true}
                            className="form-control"
                            value={form?.email_responsible_review}
                            onChange={(e) => changeForm(e.target.value, 'email_responsible_review')}
                        />
                    </ModalFormContent>
                    <ModalFormContent>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().responsibleApprovalEmail}
                        </label>
                        <input
                            disabled={true}
                            className="form-control"
                            onChange={(e) =>
                                changeForm(e.target.value, 'email_responsible_approval')
                            }
                            value={form?.email_responsible_approval}
                        />
                    </ModalFormContent>
                </RowForm>
                <RowForm>
                    <ModalFormContent>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().emissionDate}
                        </label>
                        <div style={{ zIndex: 100 }}>
                            <DatePicker
                                className="form-control"
                                selected={form?.emission_revision_date}
                                onChange={(date: Date) =>
                                    changeForm(date, 'emission_revision_date')
                                }
                                dateFormat={findlanguage().dateFormat}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                disabled={lockAllFields}
                            />
                        </div>
                    </ModalFormContent>
                    <ModalFormContent>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().issueDate}
                        </label>
                        <div
                            style={{
                                zIndex: 100,
                                borderRadius: '7px',
                                border:
                                    addDocumentCliked &&
                                    modal.typeOfAction === 'pending' &&
                                    (form?.issue_date === undefined ||
                                        form?.issue_date === null ||
                                        form?.issue_date === '')
                                        ? '1px solid red'
                                        : '',
                            }}
                        >
                            <DatePicker
                                className="form-control"
                                selected={form?.issue_date}
                                onChange={(date: Date) => changeForm(date, 'issue_date')}
                                showMonthDropdown
                                dateFormat={findlanguage().dateFormat}
                                showYearDropdown
                                dropdownMode="select"
                                disabled={modal.typeOfAction !== 'pending'}
                                style={{}}
                            />
                        </div>
                    </ModalFormContent>
                </RowForm>
                <br />
                <RowForm>
                    <ModalFormContent>
                        {lockAllFields ? (
                            <div style={{ textAlign: 'center' }}>
                                <b>{findlanguage().currentFile}</b> {form?.file?.name}{' '}
                                <a href={parseStrapiImage(form?.file?.url)}>Download</a>
                            </div>
                        ) : modal.typeOfAction === 'pending' ||
                          modal.typeOfAction === 'returned' ? (
                            <>
                                <input
                                    id="formFileMultiple"
                                    className="form-control"
                                    type="file"
                                    accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsxf,.xlsx,.xls,.zip,.rar,.7zip, .xlsm, .jpg, .jpeg, .png"
                                />
                                <div style={{ textAlign: 'center' }}>
                                    <b>{findlanguage().currentFile}</b> {form?.file?.name}{' '}
                                    <a href={parseStrapiImage(form?.file?.url)}>Download</a>
                                    <br />
                                    <b>{findlanguage().uploadFileNotice}</b>
                                </div>
                            </>
                        ) : (
                            <input
                                id="formFileMultiple"
                                className="form-control"
                                type="file"
                                accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsxf,.xlsx,.xls,.zip,.rar,.7zip, .xlsm, .jpg, .jpeg, .png"
                                style={{
                                    border:
                                        addDocumentCliked &&
                                        document.getElementById('formFileMultiple')?.files?.[0] ===
                                            undefined
                                            ? '1px solid red'
                                            : '',
                                }}
                            />
                        )}
                    </ModalFormContent>
                </RowForm>
            </ProfileFormContent>
            <PageFormSpace />

            {permissions?.myDocuments?.canEdit ? (
                <ProfileFormActions>
                    {modal.typeOfAction === 'pending' ? (
                        <>
                            <Button red onClick={reviewSupplier}>
                                {findlanguage().returnToReviewer}
                            </Button>

                            {modal.typeOfAction === 'pending' && !lockAllFields ? null : (
                                <>
                                    <Button
                                        style={{ backgroundColor: '#e6cf1a', marginLeft: '10px' }}
                                        onClick={() => setLockAllFields(false)}
                                    >
                                        {findlanguage().edit}
                                    </Button>

                                    <Button
                                        primary
                                        style={{ marginLeft: '10px' }}
                                        onClick={requestRegister}
                                    >
                                        {findlanguage().accept}
                                    </Button>
                                </>
                            )}
                            {modal.typeOfAction === 'pending' && lockAllFields ? null : (
                                <>
                                    <Button
                                        primary
                                        style={{ marginLeft: '10px' }}
                                        onClick={requestRegisterWihoutSubmitting}
                                    >
                                        {findlanguage().updateAndReturnDocument}
                                    </Button>
                                    <Button
                                        primary
                                        style={{ marginLeft: '10px' }}
                                        onClick={requestRegister}
                                    >
                                        {findlanguage().updateAndSubmitDocument}
                                    </Button>
                                </>
                            )}
                        </>
                    ) : null}
                    {modal.typeOfAction === 'new' ? (
                        <>
                            <Button primary onClick={requestRegister}>
                                {findlanguage().addDocument}
                            </Button>
                        </>
                    ) : null}
                    {modal.typeOfAction === 'returned' ? (
                        <>
                            <Button primary onClick={requestRegister}>
                                {findlanguage().resubmit}
                            </Button>
                        </>
                    ) : null}
                    {modal.typeOfAction === 'active' ? (
                        <>
                            {lockAllFields ? (
                                <Button
                                    style={{ backgroundColor: '#e6cf1a', marginLeft: '10px' }}
                                    onClick={generateNewRevision}
                                >
                                    {findlanguage().generateNewRevision}
                                </Button>
                            ) : (
                                <Button primary onClick={requestRegister}>
                                    {findlanguage().addNewRevision}
                                </Button>
                            )}
                        </>
                    ) : null}
                </ProfileFormActions>
            ) : null}
        </ModalWrapper>
    )
}
