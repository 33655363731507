import React, { useContext, useState, useEffect } from 'react'
import { CoreContext } from 'context/CoreContext'
import { parseStrapiImage } from 'utils'

const UserMap = ({ dataForTopCountries }) => {
    const { findlanguage } = useContext(CoreContext)
    return (
        <div className="flex justify-center">
            <MapsExternal dataForTopCountries={dataForTopCountries} />
        </div>
    )
}

const MapsExternal = ({ dataForTopCountries }) => {
    const { findlanguage } = useContext(CoreContext)
    const [map, setMap] = useState(null)
    const [markers, setMarkers] = useState([])
    const mapStyles = [
        {
            featureType: 'all',
            stylers: [{ saturation: -30 }],
        },
        {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [{ hue: '#00ffee' }, { saturation: 50 }],
        },
        {
            // Add more custom style rules
        },
    ]
    function getRoleBadge(item) {
        let badgeStyle = '';
        let badgeText = '';

        switch (item.role) {
            case 4:
                badgeStyle = 'background-color: #263640; color: white;';
                badgeText = findlanguage().supplierTitle;
                break;
            case 3:
                badgeStyle = 'background-color: #007bff; color: white;'; // Assuming bg-primary is similar to this color
                badgeText = findlanguage().buyerTitle;
                break;
            case 6:
                badgeStyle = 'background-color: #263640; color: white;';
                badgeText = `${findlanguage().buyerTitle} / ${findlanguage().supplierTitle}`;
                break;
            case 7:
                badgeStyle = 'background-color: #4182ab; color: white;';
                badgeText = findlanguage().headquarters;
                break;
            case 5:
                badgeStyle = 'background-color: #ded514; color: white;';
                badgeText = findlanguage().serviceProviderTitle;
                break;
            default:
                badgeText = 'nonMember'; // Assuming you want to display 'nonMember' when there's no role
                badgeStyle = 'background-color: grey; color: white;';
        }

        return badgeText !== '' ? `<span style="${badgeStyle} font-size: 1rem; padding: 0.25em 0.4em; border-radius: 0.25rem; display: inline-block;">${badgeText}</span>` : '';
    }

    useEffect(() => {
        if (window.google) {
            const infoWindow = new window.google.maps.InfoWindow()
            const bounds = new window.google.maps.LatLngBounds()
            const gmap = new window.google.maps.Map(document.getElementById('map'), {
                zoom: 3,
                mapTypeId: 'roadmap',
                styles: mapStyles, // Apply the custom styles
            })
            setMap(gmap)

            // Remove previous markers
            markers.forEach((marker) => {
                if (marker) marker.setMap(null)
            })
            setMarkers([])

            // Create markers from provided latitude and longitude
            const newMarkers = dataForTopCountries.map((location) => {
                if (location.latitude && location.longitude) {
                    const position = new window.google.maps.LatLng(
                        location.latitude,
                        location.longitude
                    )
                    // Determine the marker image
                    const markerImage = location.url ? parseStrapiImage(location.url) : '/images/no-image.png';

                    const marker = new window.google.maps.Marker({
                        map: gmap,
                        position: position,
                        title: `${location.city}, ${location.country}`,
                        icon: {
                            url: markerImage,
                            // Adjust these values as needed to fit the marker design
                            scaledSize: new window.google.maps.Size(40, 40), // Size of the custom icon
                            origin: new window.google.maps.Point(0, 0), // Origin of the icon, typically (0, 0)
                            anchor: new window.google.maps.Point(20, 20), // Anchor point of the icon, centering it
                        },
                    });

                    marker.addListener('click', () => {
                        const roleBadgeHtml = getRoleBadge(location);
                        const contentString = `
<div style="font-family: Arial, sans-serif; max-width: 250px; padding: 10px;"> <!-- Add padding for overall spacing -->
  <div style="display: flex; align-items: center; padding-bottom: 8px; border-bottom: 1px solid #ccc; margin-bottom: 8px;">
    <img src="${location?.url ? parseStrapiImage(location.url) : '/images/no-image.png'}" 
         alt="${location.company_name}" 
         style="width: 48px; height: 48px; border-radius: 50%; margin-right: 10px;"> 
    <div>
      <div style="font-size: 1.2rem; font-weight: bold; color: #333;">${location.company_name}</div>
      ${getRoleBadge(location)}
    </div>
  </div>
  <div style="font-size: 0.9rem; color: #333;">
    <div style="font-weight: bold;">Country:</div>
    <div>${location.country}</div>
  </div>
  <div style="font-size: 0.9rem; color: #333; margin-top: 4px;">
    <div style="font-weight: bold;">State:</div>
    <div>${location.state}</div>
  </div>
  <div style="font-size: 0.9rem; color: #333; margin-top: 4px;">
    <div style="font-weight: bold;">City:</div>
    <div>${location.city}</div>
  </div>
    ${location.member !== 'nonMember' ? `
    <button
    style="display: block; width: 100%; padding: 8px; margin-top: 12px; background-color: #4f6a7c; color: white; border: none; border-radius: 4px; cursor: pointer; font-size: 0.9rem;"
    onclick="location.href='/dashboard/user/${location.id}'" 
    >
    See company profile
  </button>
    ` : ''}
  
</div>
`;

                        // Set the content of the InfoWindow
                        infoWindow.setContent(contentString);
                        // Open the InfoWindow
                        infoWindow.open(gmap, marker);
                    })

                    bounds.extend(position)
                    return marker
                }
                return null
            })

            setMarkers(newMarkers)

            if (newMarkers.length) {
                // Only fit the bounds if there are valid markers
                gmap.fitBounds(bounds)
            } else {
                // Fallback to a default position if no markers were valid
                gmap.setCenter({ lat: 37.0902, lng: -95.7129 })
            }
        }
    }, [dataForTopCountries])

    return (
        <div className="container-fluid">
            <div id="map" style={{ width: '100%', height: '500px' }}></div>
        </div>
    )
}

export default UserMap
