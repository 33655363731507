import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const SupplierCard = styled.div.attrs({})`
    border-radius: 10px;
    padding: 20px;
    background: var(--backgroundgrey-color);
    width: 49%;

    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;

    @media (max-width: ${mobileSize}px) {
        width: 100%;
    }
`

export const SupplierImage = styled.div.attrs({})`
    width: 76px;
    height: 76px;
    border-radius: 38px;
    background: var(--white-color);
    ${(props) =>
        props.image
            ? `
            background: url(${props.image}) no-repeat center center / cover;
            background-size: 68px;
        `
            : ``}
`

export const SupplierContent = styled.div.attrs({})`
    padding-left: 13px;
    flex: 1;
`

export const SupplierTitle = styled.div.attrs({})`
    color: var(--grey-color)
    font-size: 15px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
    margin-bottom: 6px;
`

export const SupplierSubtitle = styled.div.attrs({})`
    color: var(--textgrey-color);
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    margin-bottom: 15px;
`

export const SupplierLabel = styled.div.attrs({})`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`

export const SupplierLabelText = styled.div.attrs({})`
    color: var(--grey-color);
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
`

export const SupplierLabelIcon = styled.img.attrs((props) => ({
    src: `/icons/${props.icon}.svg`,
}))`
    margin-left: 14px;
`

export const SupplierDescription = styled.div.attrs({})`
    color: var(--grey-color);
    font-size: 15px;
    line-height: 23px;
    max-height: 69px;
    overflow: hidden;
    margin-bottom: 16px;
    padding-right: 60px;
`

export const SupplierActions = styled.div.attrs({})`
    display: flex;
`

export const CardTouch = styled.div.attrs({})`
    cursor: pointer;
`
