import React from 'react'

import { PageHeader, PageHeaderContent, ContentTitle, ContentSubtitle } from './styled'

export default function DashboardUserDetailsHeader({ profile }) {
    return (
        <>
            <PageHeader>
                <PageHeaderContent>
                    <ContentTitle>{profile?.company_name}</ContentTitle>
                    <ContentSubtitle>
                        {
                            [
                                profile?.city,
                                profile?.state,
                                profile?.country,
                            ]
                                .filter((item) => item)
                                .join(', ')
                        }
                    </ContentSubtitle>
                </PageHeaderContent>
            </PageHeader>
        </>
    )
}
