import React, { useState } from 'react'
import Questionarie from './Supplier/index'
import Additionalnformation from './Additionalnformation/index'
import ProductDescription from './ProductDescription/index'
import EstablishmentData from './EstablishmentData/index'
import SelfAssessmentChecklist from './SelfAssessmentChecklist/index'

export default function DashboardTabESG({ setMfsQuestionarie, mfsQuestionarie, backOffice, goBack, company_id }) {
    const [form, setForm] = useState(backOffice)

    return (
        <>
            <Questionarie setForm={setForm} form={form} setMfsQuestionarie={setMfsQuestionarie} mfsQuestionarie={mfsQuestionarie} backOffice={backOffice} goBack={goBack} company_id={company_id} />
            <Additionalnformation form={form} backOffice={backOffice} company_id={company_id} />
            <ProductDescription form={form} backOffice={backOffice} company_id={company_id} />
            <EstablishmentData form={form} backOffice={backOffice} company_id={company_id} />
            <SelfAssessmentChecklist form={form} mfsQuestionarie={mfsQuestionarie} backOffice={backOffice} company_id={company_id} />
        </>
    )
}
