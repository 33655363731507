import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { parseStrapiImage } from 'utils'
import { CoreContext } from 'context/CoreContext'

import {
    SupplierCard,
    SupplierImage,
    SupplierContent,
    SupplierTitle,
    SupplierSubtitle,
    SupplierLabel,
    SupplierLabelText,
    SupplierLabelIcon,
    SupplierDescription,
    SupplierActions,
    CardRemoveAction,
    CardContentActions,
} from './styled'

import DashboardButton from 'components/Dashboard/Button'
import { DeleteChat } from 'services/chat'

export default function DashboardCardMessage({
    room_id,
    image,
    title,
    subtitle,
    label,
    labelIcon,
    description,
    actions,
    reload,
}) {
    const { findlanguage } = useContext(CoreContext)
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const goRoom = async () => {
        navigate(`dashboard/message/${room_id}`)
    }

    const deleteRoom = async () => {
        await DeleteChat(room_id)
        reload()
    }

    return (
        <>
            <SupplierCard>
                <SupplierImage image={image ? parseStrapiImage(image) : `/images/no-image.png`} />
                <SupplierContent>
                    <SupplierTitle>{title}</SupplierTitle>
                    <SupplierSubtitle>{subtitle}</SupplierSubtitle>
                    <SupplierDescription>{description}</SupplierDescription>
                </SupplierContent>
                <CardContentActions>
                <div></div>
                    <DashboardButton onClick={() => goRoom()}>
                        {findlanguage().reply}
                    </DashboardButton>
                </CardContentActions>
            </SupplierCard>
        </>
    )
}
