import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import {
    UserProfileContainer,
    UserProfile,
    UserProfileTitle,
    UserProfileProgress,
    UserProfileProgressTitle,
    UserProfileProgressBar,
    UserProfileProgressBarIn,
    UserProfileProgressBarText,
    UserProfileProgressActions,
    UserProfileSpacer,
} from './styled';

import DashboardButton from 'components/Dashboard/Button';
import FormImagePicker from 'components/Form/ImagePicker';
import UserProfileSelector from './Selector';
import { CoreContext } from 'context/CoreContext';

import {
    FetchMyRequirements,
    FetchMyCertificates,
    FetchMyProducts,
    FetchMyServiceProviderDocuments,
    FetchMyServiceProviderRegions,
} from 'services/myProfile';

export default function DashboardUserProfile() {
    const history = useHistory();
    const navigate = (to) => history.push(`/${to}`);

    const { profile, user, findlanguage } = useContext(CoreContext);

    const [profileProgress, setProfileProgress] = useState(0);
    const [hide, setHide] = useState(false);

    const init = async () => {
        await checkProfileProgress();
    };

    const isEmpty = (value) => {
        return !value || (Array.isArray(value) && value.length === 0);
    };

    const calculateProgress = (fields, user) => {
        let result = 0;
        const points = parseFloat(100 / fields.length);

        fields.forEach(field => {
            if (field(user)) result += points;
        });

        if (result > 99) result = 100;
        setHide(result >= 100);

        return parseFloat(result).toFixed(0);
    };

    const getProgressBuyer = async () => {
        const fields = [
            user => !isEmpty(user.name),
            user => !isEmpty(user.contactName),
            user => !isEmpty(user.email),
            user => !isEmpty(user.phone),
            user => !isEmpty(user.address?.street),
            user => !isEmpty(user.address?.city),
            user => !isEmpty(user.address?.state),
            user => !isEmpty(user.address?.country),
            user => !isEmpty(user.address?.zipCode),
            user => !isEmpty(user.website),
            user => !isEmpty(user.active_account.businessTypes),
            user => !isEmpty(user.active_account.companyCategories),
            user => !isEmpty(user.active_account.supplierPreRequirements),
            user => !isEmpty(user.active_account.aboutCompany),
            async user => (await FetchMyRequirements(user.company_id)).length > 0,
        ];

        return calculateProgress(fields, user);
    };

    const getProgressSupplier = async () => {
        const fields = [
            user => !isEmpty(user.name),
            user => !isEmpty(user.contactName),
            user => !isEmpty(user.email),
            user => !isEmpty(user.phone),
            user => !isEmpty(user.address?.street),
            user => !isEmpty(user.address?.city),
            user => !isEmpty(user.address?.state),
            user => !isEmpty(user.address?.country),
            user => !isEmpty(user.address?.zipCode),
            user => !isEmpty(user.website),
            user => !isEmpty(user.active_account.businessTypes),
            user => !isEmpty(user.active_account.companyCategories),
            user => !isEmpty(user.active_account.productsDescription),
            user => !isEmpty(user.active_account.aboutCompany),
            async user => (await FetchMyCertificates(user.company_id)).length > 0,
            async user => (await FetchMyProducts(user.company_id)).length > 0,
            user => user.active_account.distributionState || user.active_account.distributionNational || user.active_account.distributionInternational,
        ];

        return calculateProgress(fields, user);
    };

    const getProgressServiceProvider = async () => {
        const fields = [
            user => !isEmpty(user.name),
            user => !isEmpty(user.contactName),
            user => !isEmpty(user.email),
            user => !isEmpty(user.phone),
            user => !isEmpty(user.address?.street),
            user => !isEmpty(user.address?.city),
            user => !isEmpty(user.address?.state),
            user => !isEmpty(user.address?.country),
            user => !isEmpty(user.address?.zipCode),
            user => !isEmpty(user.website),
            user => !isEmpty(user.active_account.myCategories),
            user => !isEmpty(user.active_account.offeredServices),
            user => !isEmpty(user.active_account.providedServices),
            user => !isEmpty(user.active_account.serviceDescription),
            user => !isEmpty(user.active_account.aboutCompany),
            async user => (await FetchMyServiceProviderDocuments(user.company_id)).length > 0,
            async user => (await FetchMyServiceProviderRegions(user.company_id)).length > 0,
        ];

        return calculateProgress(fields, user);
    };

    const checkProfileProgress = async () => {
        let points = 0;

        switch (profile) {
            case 'admin':
            case 'buyer-supplier':
                const buyerPoints = parseFloat(await getProgressBuyer());
                const supplierPoints = parseFloat(await getProgressSupplier());
                points = (buyerPoints + supplierPoints) / 2;
                break;
            case 'buyer':
                points = await getProgressBuyer();
                break;
            case 'supplier':
                points = await getProgressSupplier();
                break;
            case 'service-provider':
                points = await getProgressServiceProvider();
                break;
            default:
                break;
        }

        setProfileProgress(points);
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <>
            <Row>
                <Col md={{ size: 2 }}>
                    <UserProfile>
                        <FormImagePicker image={'/images/user.png'} />
                    </UserProfile>
                </Col>
                <Col md={{ size: 10 }}>
                    <UserProfileContainer>
                        <UserProfileProgress>
                            <UserProfileProgressTitle>
                                {hide
                                    ? findlanguage().profileCompleted
                                    : findlanguage().completeYourProfile}
                            </UserProfileProgressTitle>

                            <UserProfileProgressBar>
                                <UserProfileProgressBarIn progress={profileProgress} />
                                <UserProfileProgressBarText>
                                    {profileProgress}%
                                </UserProfileProgressBarText>
                            </UserProfileProgressBar>

                            <UserProfileProgressActions>
                                {(profile === 'buyer' || profile === 'supplier') ? (
                                    <UserProfileSelector />
                                ) : (
                                    <UserProfileSpacer />
                                )}
                            </UserProfileProgressActions>
                        </UserProfileProgress>

                        {!hide && (
                            <DashboardButton onClick={() => navigate('dashboard/profile')}>
                                {findlanguage().completeProfile}
                            </DashboardButton>
                        )}
                    </UserProfileContainer>
                </Col>
            </Row>
            <Row>
                <Col md={{ size: 12 }}>
                    <UserProfileTitle>
                        {findlanguage().hello}, {user.team_access ? user.contactName : user.name}
                        {user.status && (
                            <img
                                src="/icons/checked.svg"
                                alt="Approved"
                                style={{ marginLeft: '10px', height: '25px' }}
                            />
                        )}
                    </UserProfileTitle>
                </Col>
            </Row>
        </>
    );
}
