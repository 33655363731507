import React, { useContext } from 'react'

import { Title, Text } from './styled'

import Button from 'components/Form/Button'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

export default function ModalSupportCaseInfo() {
    const { modal, setModal, findlanguage } = useContext(CoreContext)

    const close = () => {
        setModal(null)
    }

    return (
        <>
            <ModalWrapper colorized={true}>
                <Title>{findlanguage().supportCaseInfoTitle}</Title>

                <Text>
                    {findlanguage().supportCaseInfoText} <br />
                    {modal.company}
                </Text>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '30px',
                        marginBottom: '50px',
                        marginLeft: '15px',
                    }}
                >
                    <Button whiteAndBlack onClick={() => close()}>
                        OK
                    </Button>
                </div>
            </ModalWrapper>
        </>
    )
}
