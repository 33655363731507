import DashboardButton from 'components/Dashboard/Button'
import Input from 'components/Form/Input'
import React, { useState, useContext } from 'react'
import { CoreContext } from 'context/CoreContext'

import DashboardTabOwnValidationButtons from './OwnValidationButtons'
import DashboardTabOwnValidationConstruct from './OwnValidationConstruct'
import { FetchSuplierRequestsFind } from 'services/sms'

import {
    FindValidationProcess,
} from 'services/validationProcess'

import {
    PageCard,
    ButtonContainer,
    BackIcon,
    ContainerDivider,
    Division,
    Division2,
    PageTitle,
    PageDescription,
} from './styled'
import { useEffect } from 'react'
import {
    FetchMyDraftForms,
    FetchMyForms,
    SaveValidationForms,
    UpdateValidationForms,
} from 'services/sms'

import { UploadPDFForm } from 'services/api'

export default function DashboardTabOwnValidation({ currentForm, setCurrentPage, fetchRequirements }) {
    const [createNew, setCreateNew] = useState(false)
    const [editing, setEditing] = useState(false)
    const [validationForm, setValidationForm] = useState(currentForm.form)
    const [formName, setFormName] = useState(currentForm.requirement)
    const [hasUpdates, setHasUpdates] = useState(false)
    const [readOnly, setReadOnly] = useState(false)
    const [overview, setOverview] = useState([])
    const [myFormList, setMyFormList] = useState([])
    const { setModal, user, findlanguage } = useContext(CoreContext)
    const [myDraftFormList, setDraftMyFormList] = useState([])
    const [requestUpdate, setRequestUpdate] = useState(false)
    const [choosedForms, setChoosedForms] = useState([])
    const [choosedUsers, setChoosedUsers] = useState([])
    const [usersWithForms, setUsersWithForms] = useState([])
    const [saveId, setSaveId] = useState(0)

    useEffect(() => {
        updateMyFormList()
        updateMyDraftFormList()
        autoSaveRoutine()
        searchOverview()
    }, [])

    useEffect(() => {
        if (!editing && !createNew) return
        if (!hasUpdates) return
        saveValidationForm(true, true)
        setHasUpdates(false)
    }, [requestUpdate])

    const waitFor = async (seconds) =>
        new Promise((resolve) => setTimeout(() => resolve(true), seconds * 1000))

    const alertSave = () => {
        setModal({
            type: 'alert',
            text: 'Form Saved.',
            description: 'Your validation form has been saved as a draft.',
        })
    }

    const setLoading = (message = 'saving...') => {
        setModal({
            type: 'loading',
            text: message,
        })
    }

    const alertMissingTitle = () => {
        setModal({
            type: 'alert',
            warning: true,
            text: 'Missing Title.',
            description: 'Your validation form is missing a title an has NOT been saved.',
        })
    }

    const alertError = () => {
        setModal({
            type: 'alert',
            warning: true,
            text: 'Error.',
            description:
                'Could not perform requested action. Please check you connection and try again.',
        })
    }

    const searchOverview = async () => {
        setOverview([])
        const result = await FetchSuplierRequestsFind()
        const result2 = await FindValidationProcess()

        setUsersWithForms(result2)
        setOverview(result)
    }

    const addToForm = (item) => {
        const update = [...validationForm]
        update.push(item)
        setValidationForm(update)
        setHasUpdates(true)
    }

    const editFormItem = (index, value) => {
        const update = validationForm.map((item, i) => {
            if (i === index) return { ...item, ...value }
            else return item
        })
        setHasUpdates(true)

        setValidationForm(update)
    }

    const removeFromForm = (index) => {
        const update = validationForm.filter((item, i) => index !== i)
        setHasUpdates(true)
        setValidationForm(update)
    }

    const updateMyFormList = async () => {
        const request = await FetchMyForms(currentForm.ownerId)
        setMyFormList(request)
    }

    const updateMyDraftFormList = async () => {
        const request = await FetchMyDraftForms(currentForm.ownerId)
        setDraftMyFormList(request)
    }

    const saveValidationForm = async (draft = false, silent = false) => {
        const update = !currentForm.alreadyInUse ? true : false
        setLoading()

        const body = {
            draft,
            owner: currentForm.ownerId,
            form_body: validationForm,
            name: formName,
        }

        let response

        if (update) response = await UpdateValidationForms(body, currentForm.id)
        else response = await SaveValidationForms(body)

        for (let i = 0; i < response?.form_body?.length; i++) {
            if (response.form_body[i].type === 'media') {
                const ref = `formFileMultiple${i}`

                if (document.getElementById(ref)?.files?.[0]) {
                    const result = await UploadPDFForm(
                        document.getElementById(ref)?.files?.[0],
                        response.form_body[i].id
                    )

                    response.form_body[i].filePdf = result[0].media
                }
            }
        }

        await UpdateValidationForms(response, response.id)

        if (response.statusText || response.statusCode) {
            if (!silent) alertError()
            return
        }
        await fetchRequirements()
        alertSave()
        setCurrentPage(0)
    }

    const goBack = () => {
        setCurrentPage(0)
    }

    const autoSaveRoutine = async () => {
        while (true) {
            await waitFor(15)
            setRequestUpdate(new Date())
        }
    }

    return (
        <>
            <PageCard>
                <ContainerDivider>
                    <BackIcon onClick={goBack} />
                    {readOnly ? (
                        <Division2 space={18}>
                            <PageTitle>{formName}</PageTitle>
                        </Division2>
                    ) : (
                        <Division>
                            <Division space={18}>
                                <PageTitle>{findlanguage().newValidationProcess}</PageTitle>
                            </Division>
                            <Division space={24}>
                                <PageDescription>
                                    {findlanguage().whatIsTheNameOfTheProcess}
                                </PageDescription>
                            </Division>

                            <Input
                                placeholder={findlanguage().name}
                                big
                                value={formName}
                                onChange={(e) => {
                                    setFormName(e.target.value)
                                    setHasUpdates(true)
                                }}
                            />
                        </Division>
                    )}
                </ContainerDivider>
            </PageCard>

            <DashboardTabOwnValidationButtons addToForm={addToForm} />

            <DashboardTabOwnValidationConstruct
                componentList={validationForm}
                editFormItem={editFormItem}
                removeFromForm={removeFromForm}
                readOnly={readOnly}
            />
            <ButtonContainer style={{ padding: '10px' }}>
                <DashboardButton onClick={() => saveValidationForm(false)}>
                    {!currentForm.alreadyInUse ? 'Edit' : 'Create new'}
                </DashboardButton>
            </ButtonContainer>
        </>
    )
}
