import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { CoreContext } from 'context/CoreContext';
import { FindIfAnyProviders, UpdateServiceProviderId } from 'services/serviceProvider';
import { parseStrapiImage, permissionsDefault } from 'utils'

const ServiceProviderSelector = () => {
    const { user, setServiceProviderChoosedAccount, setUser, setProfile, setPermissions } = useContext(CoreContext);
    const location = useLocation();
    const history = useHistory();
    const [providers, setProviders] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const fetchedProviders = await FindIfAnyProviders();
            setProviders(fetchedProviders);
        };

        fetchData();
    }, []);

    const navigateToHome = async () => {
        await UpdateServiceProviderId({ company_id: user.id })

        setUser(prevUser => ({
            ...prevUser,
            original_company_id: user.id,
            company_id: user.id,
            currentWorkingAsServiceProvider: true,
            active_account: {
                ...user.active_account,
                //type: 'service-provider'
            }
        }));

        setPermissions(permissionsDefault())

        setProfile("service-provider")
        setServiceProviderChoosedAccount(true)
        setServiceProviderChoosedAccount(true)
        history.push('/dashboard/sms');
    };

    const changeUser = async (provider) => {
        const response = await UpdateServiceProviderId({ company_id: provider.userThatRequested.company_id })

        if (response === 3) {
            setUser(prevUser => ({
                ...prevUser,
                company_id: provider.userThatRequested.company_id,
                currentWorkingAsServiceProvider: true,
                active_account: {
                    ...user.active_account,
                   // type: 'buyer'
                }
            }));
            setPermissions(provider.permissions)
            setProfile("buyer")
        }
        else if (response === 4) {
            setUser(prevUser => ({
                ...prevUser,
                company_id: provider.userThatRequested.company_id,
                currentWorkingAsServiceProvider: true,
                active_account: {
                    ...user.active_account,
                    // type: 'supplier'
                }
            }));
            setPermissions(provider.permissions)
            setProfile("supplier")
        }
        else if (response === 6) {
            setUser(prevUser => ({
                ...prevUser,
                company_id: provider.userThatRequested.company_id,
                currentWorkingAsServiceProvider: true,
                active_account: {
                    ...user.active_account,
                    // type: 'supplier'
                }
            }));
            setPermissions(provider.permissions)
            setProfile("buyer-supplier")
        }
        else if (response === 7) {
            setUser(prevUser => ({
                ...prevUser,
                company_id: provider.userThatRequested.company_id,
                currentWorkingAsServiceProvider: true,
                active_account: {
                    ...user.active_account,
                    // type: 'supplier'
                }
            }));
            setPermissions(provider.permissions)
            setProfile("admin")
        }
        else {
            setUser(prevUser => ({
                ...prevUser,
                company_id: provider.userThatRequested.company_id,
                currentWorkingAsServiceProvider: true,
                active_account: {
                    ...user.active_account,
                    //type: 'service-provider'
                }
            }));

            setPermissions(permissionsDefault())
            setProfile("service-provider")
        }

        setServiceProviderChoosedAccount(true)
        history.push('/dashboard/sms');
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1>Select an Account</h1>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {providers.map((provider) => (
                    <div
                        key={provider.id}
                        onClick={() => changeUser(provider)}
                        style={{
                            width: '150px',
                            height: '150px',
                            margin: '20px',
                            textAlign: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        <img
                            src={provider.userThatRequested.avatar === null ? `/images/no-image.png` : parseStrapiImage(provider.userThatRequested.avatar)}
                            alt=""
                            style={{
                                width: '100px',
                                height: '100px',
                                borderRadius: '50%',
                            }}
                        />
                        <p>{provider.userThatRequested.name}</p>
                    </div>
                ))}

                {/* Add an option for the user's own account */}
                <div
                    onClick={navigateToHome}
                    style={{
                        width: '150px',
                        height: '150px',
                        margin: '20px',
                        textAlign: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <img
                        src={user.avatar === null ? `/images/no-image.png` : user.avatar}
                        alt=""
                        style={{
                            width: '100px',
                            height: '100px',
                            borderRadius: '50%',
                        }}
                    />
                    <p>Access my own account</p>
                </div>
            </div>
        </div>
    );
};

export default ServiceProviderSelector;
