import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import Input from 'components/Form/Input'
import Button from 'components/Form/Button'

import { FormTitle, FormText, FormSpacer, FormCentred } from './styled'

import ContainerUnauthenticated from 'containers/Unauthenticated'
import { DoForgotPassword } from 'services/authentication'
import { exposeStrapiErrorAuth } from 'utils'
import RegisterHeader from 'components/Register/Header'

export default function Forgot() {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const [loading, setLoading] = useState(false)

    const [form, setForm] = useState({})
    const formValue = (ref) => {
        return form?.[ref] ? form?.[ref] : ''
    }
    const changeForm = (value, ref) => {
        setForm({ ...form, [ref]: value })
    }

    const valid = (verbose = false) => {
        const email = formValue('email')
        if (email.length === 0) {
            toast.error('Fill the field: Email')
            return false
        } else if (
            !email.match(
                /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
            )
        ) {
            toast.error('This e-mail is invalid')
            return false
        }

        return true
    }

    const action = async () => {
        if (!valid(true)) {
            return
        }
        setLoading(true)

        const result = await DoForgotPassword({ email: formValue('email')?.replace(/ /g, '') })

        setLoading(false)
        if (result && exposeStrapiErrorAuth(result)) {
            completNext()
        }
    }

    const completNext = () => {
        toast.success('Email sent')
        navigate('login')
    }

    return (
        <>
            <ContainerUnauthenticated>
                <RegisterHeader title={'Find Your Account'} backable />
                <FormText>Enter your email to recover your password</FormText>
                <FormCentred>
                    <Input
                        placeholder="Email"
                        value={formValue('email')}
                        onChange={(e) => changeForm(e.target.value, 'email')}
                    />
                    <FormSpacer />
                    <Button primary loading={loading} onClick={action} small>
                        Send email
                    </Button>
                    {/* <Button primary outline onClick={() => history.goBack()}>Back</Button>   */}
                </FormCentred>
            </ContainerUnauthenticated>
        </>
    )
}
