import React, { useContext, useState, useEffect } from 'react';

import Reports from 'components/Dashboard/Sms/TabOverview/ChooseButton/MyApproval/Reports/index';
import EditForUserReport from 'components/Dashboard/Sms/TabOverview/ChooseButton/MyApproval/EditForUserReport/index';
import { DeleteForm } from 'services/validationProcess';
import { CoreContext } from 'context/CoreContext';
import { CreateFormRequestForSupplier, GetForm } from 'services/validationProcess';
import { NotificationManagerAdd } from 'services/admin';
import { UpdateFormRequestForSupplier } from 'services/validationProcess';
import { CardFormStatus, EditIcon, BlockedIcon } from './styled';

import _ from 'lodash';

export default function MFSApproval({
    usersWithForms,
    setViewReport,
    changeUsersWithForm,
    init,
    changeProducts,
    editProductsForm,
    filters,
    componentId
}) {
    const { user, setModal, findlanguage, language, permissions } = useContext(CoreContext);
    const cssHead = { fontSize: '14px', fontWeight: '600' };
    const cssTable = { fontSize: '14px', fontWeight: '600', color: '#000' };
    const cssTableInLineFlex = {
        fontSize: '14px',
        fontWeight: '600',
        color: '#000',
        display: 'flex',
    };
    const biggerButton = { fontSize: '12px', cursor: 'pointer' };
    const biggerButtonGreen = { fontSize: '12px', cursor: 'pointer', backgroundColor: '#8dbd64' };

    const [form, setForm] = useState({ id: -1 });
    const [openMenuId, setOpenMenuId] = useState(null);
    const [currentMode, setCurrentMode] = useState("view");
    const [showObsolete, setShowObsolete] = useState(false);
    const [showSettings, setShowSettings] = useState(false);

    useEffect(() => {
        const savedPreference = localStorage.getItem(`showObsolete_${componentId}`);
        if (savedPreference) {
            setShowObsolete(JSON.parse(savedPreference));
        }
    }, [componentId]);

    const toggleMenu = (id) => {
        setOpenMenuId(openMenuId === id ? null : id);
    };

    const editForm = (form) => {
        setViewReport(true);
        setForm(form);
        setCurrentMode('edit');
    };

    const deleteForm = async (id) => {
        setModal({
            type: 'alert',
            warning: true,
            text: findlanguage().deleteQuestion,
            cancel: true,
            confirmRemoveSupplier: deleteFormYes,
            idConfirm: id,
        });
    };

    const deleteFormYes = async (id) => {
        await DeleteForm(id);
        init();
        setModal({
            type: 'alert',
            text: findlanguage().formDeleted,
        });
    };

    const goBack = () => {
        setViewReport(false);
        setForm({ id: -1 });
    };

    const saveProducts = (form) => {
        UpdateFormRequestForSupplier(form);
        editProductsForm(form.id, !form.editProducts);
    };

    function translate(reason) {
        switch (language) {
            case 'portugues':
                switch (reason) {
                    case 'Incomplete Document':
                        return 'Documento incompleto';
                    case 'Missing requirements':
                        return 'Requisitos em falta';
                    case 'Invalid Document':
                        return 'Documento inválido';
                    case 'Corrupt Document':
                        return 'Documento corrompido';
                    default:
                        return 'Translation not found';
                }
            case 'english':
                switch (reason) {
                    case 'Incomplete Document':
                        return 'Incomplete Document';
                    case 'Missing requirements':
                        return 'Missing requirements';
                    case 'Invalid Document':
                        return 'Invalid Document';
                    case 'Corrupt Document':
                        return 'Corrupt Document';
                    default:
                        return 'Translation not found';
                }
            default:
                return reason;
        }
    }

    async function resendForm(x) {
        setModal({
            type: 'alert',
            text: findlanguage().requirementSent,
        });

        let cloneForm = _.cloneDeep(x);
        cloneForm.status = 'Obsolete';

        await UpdateFormRequestForSupplier(cloneForm);

        var form_body = await GetForm(x.form.id);

        await CreateFormRequestForSupplier({
            form: x.form.id,
            form_body: form_body,
            toUser: { id: x.toUser.id },
            owner: { id: user.company_id },
            status: 'Condition',
            private: x.private === null || x.private === false? false : true
        });

        if (x.private) {
            NotificationManagerAdd('document_request', user.id, user.name, user);
        } else {
            await NotificationManagerAdd('document_request', x.toUser.id, user.name, user);
        }

        init();
    }

    const formatDate = (dateString) => {
        if (!dateString) {
            return '-';
        }
        const date = new Date(dateString);
        const day = date.getUTCDate().toString().padStart(2, '0');
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = date.getUTCFullYear();

        return language === 'portugues' ? `${day}/${month}/${year}` : `${month}/${day}/${year}`;
    };

    function sendReminder(x) {
        const targetId = x.private ? user.id : x.toUser.id;
        NotificationManagerAdd('document_request', targetId, user.name, user);

        setModal({
            type: 'alert',
            text: findlanguage().reminderSent,
        });
    }

    const toggleShowObsolete = () => {
        const newShowObsolete = !showObsolete;
        setShowObsolete(newShowObsolete);
        localStorage.setItem(`showObsolete_${componentId}`, JSON.stringify(newShowObsolete));
    };

    const toggleSettings = () => {
        setShowSettings(!showSettings);
    };

    return (
        <>
            <table className="table" style={{ display: form.id !== -1 ? 'none' : '' }}>
                <thead style={{ backgroundColor: '#4f6b7d', color: '#f4fff7' }}>
                    <tr>
                        <th scope="col">
                            <div onClick={toggleSettings} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                <span style={{ fontSize: '16px', color: '#fff' }}>&#9881;</span>
                            </div>
                            {showSettings && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        backgroundColor: 'white',
                                        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                                        zIndex: 4,
                                        marginTop: '5px',
                                        padding: '10px',
                                    }}
                                >
                                    <label style={{ display: 'flex', alignItems: 'center', fontSize: '14px', color: '#000' }}>
                                        <input
                                            type="checkbox"
                                            checked={showObsolete}
                                            onChange={toggleShowObsolete}
                                            style={{ marginRight: '8px' }}
                                        />
                                        Show Obsolete
                                    </label>
                                </div>
                            )}
                        </th>
                        <th scope="col" style={cssHead}>{findlanguage().typeOfRequirements}</th>
                        <th scope="col" style={cssHead}>{findlanguage().private}</th>
                        <th scope="col" style={cssHead}>{findlanguage().products}</th>
                        <th scope="col" style={cssHead}>{findlanguage().condition}</th>
                        <th scope="col" style={cssHead}>{findlanguage().responsibleReply}</th>
                        <th scope="col" style={cssHead}>{findlanguage().responsibleApproval}</th>
                        <th scope="col" style={cssHead}>{findlanguage().approvalDate}</th>
                        <th scope="col" style={cssHead}>{findlanguage().expirationDate}</th>
                        <th scope="col" style={cssHead}>{findlanguage().daysForReminder}</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody className="table-group-divider" style={{ borderTopColor: '#263640' }}>
                    {usersWithForms.length === 0 ? null : usersWithForms
                        ?.sort((a, b) => a?.form?.name?.localeCompare(b.form?.name))
                        ?.filter((x) => showObsolete || x.status !== 'Obsolete')
                        .map((x) => {
                            let datesLeft = null;

                            if (x.acceptedDate !== null) {
                                const dateString = x.expirationDate;
                                const dateParts = dateString?.split('-');
                                const date2 = dateParts
                                    ? new Date(
                                        Date.UTC(
                                            dateParts[0],
                                            dateParts[1] - 1,
                                            dateParts[2]
                                        )
                                    )
                                    : '';

                                const now = new Date();
                                const todayUTC = new Date(
                                    Date.UTC(
                                        now.getUTCFullYear(),
                                        now.getUTCMonth(),
                                        now.getUTCDate()
                                    )
                                );
                                const diffTime = date2 - todayUTC;
                                datesLeft = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                            }

                            return (
                                <tr key={x.id}>
                                    <td style={cssTable}>
                                        {permissions?.mySuppliers?.canEdit ? (
                                            <>
                                                {x.status === 'Condition' ? (
                                                    <div style={{ position: 'relative', display: 'inline-block' }}>
                                                        <div onClick={() => toggleMenu(x.id)} style={{ cursor: 'pointer' }}>
                                                            <span style={{ fontSize: '14px', color: '#000' }}>&#8942;</span>
                                                        </div>
                                                        {openMenuId === x.id && (
                                                            <div
                                                                style={{
                                                                    top: '25px',
                                                                    backgroundColor: 'white',
                                                                    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                                                                    zIndex: 4,
                                                                    position: 'absolute'
                                                                }}
                                                            >
                                                                <div onClick={() => { editForm(x); setCurrentMode("view"); }} style={{ padding: '8px', cursor: 'pointer' }}>{findlanguage().view}</div>
                                                                <div onClick={() => { editForm(x); setCurrentMode("edit"); }} style={{ padding: '8px', cursor: 'pointer' }}>{findlanguage().edit}</div>
                                                                <div onClick={() => { sendReminder(x) }} className="kebabOptions">{findlanguage().sendReminder}</div>
                                                            </div>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <span
                                                        onClick={() => { editForm(x); setCurrentMode("view"); }}
                                                        style={biggerButton}
                                                        className="badge bg-secondary"
                                                    >
                                                        {findlanguage().view}
                                                    </span>
                                                )}
                                                {x.status === 'Pending' ? (
                                                    <span
                                                        onClick={() => { editForm(x); setCurrentMode("view"); }}
                                                        style={biggerButtonGreen}
                                                        className="badge"
                                                    >
                                                        {findlanguage().review}
                                                    </span>
                                                ) : null}
                                            </>
                                        ) : (
                                            <span
                                                onClick={() => { editForm(x); setCurrentMode("view"); }}
                                                style={biggerButton}
                                                className="badge bg-secondary"
                                            >
                                                {findlanguage().view}
                                            </span>
                                        )}
                                    </td>
                                    <td style={cssTable}>{x.form?.name}</td>
                                    <td style={cssTable}>
                                        {x.private ? (
                                            <span
                                                style={biggerButton}
                                                className="badge bg-danger"
                                            >
                                                {findlanguage().private}
                                            </span>
                                        ) : null}
                                    </td>
                                    <td style={cssTable}>
                                        {x.editProducts ? (
                                            <>
                                                <input
                                                    value={x.products}
                                                    className="form-control"
                                                    onChange={(event) =>
                                                        changeProducts(
                                                            x.id,
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                                <button
                                                    className="btn"
                                                    style={{
                                                        backgroundColor: '#263640',
                                                        color: '#fff',
                                                        marginLeft: '5px',
                                                    }}
                                                    onClick={() => saveProducts(x)}
                                                >
                                                    {findlanguage().save}
                                                </button>
                                            </>
                                        ) : (
                                            x.products
                                        )}
                                        {x.editProducts === false && permissions?.mySuppliers?.canEdit ? (
                                            <EditIcon
                                                onClick={() =>
                                                    editProductsForm(
                                                        x.id,
                                                        !x.editProducts
                                                    )
                                                }
                                            />
                                        ) : null}
                                    </td>
                                    <td style={cssTable}>
                                        <div style={datesLeft !== null ? cssTableInLineFlex : {}}>
                                            {x.status === 'Condition' && (
                                                <CardFormStatus
                                                    title={findlanguage().condition}
                                                    color="gray"
                                                    className="tooltip_element"
                                                >
                                                    <span className="tooltip">{findlanguage().conditional}</span>
                                                </CardFormStatus>
                                            )}
                                            {x.status === 'Accepted' && (
                                                <CardFormStatus
                                                    title={findlanguage().accepted}
                                                    color={datesLeft !== null && datesLeft < 1 ? '#dc3545' : '#8dbd64'}
                                                    className="tooltip_element"
                                                >
                                                    <span className="tooltip">
                                                        {datesLeft <= 0 ? findlanguage().isExpired : findlanguage().accepted}
                                                    </span>
                                                </CardFormStatus>
                                            )}
                                            {x.status === 'Pending' && (
                                                <CardFormStatus
                                                    title={findlanguage().pending}
                                                    color="yellow"
                                                    className="tooltip_element"
                                                >
                                                    <span className="tooltip">{findlanguage().pending}</span>
                                                </CardFormStatus>
                                            )}
                                            {x.status === 'Denied' && (
                                                <>
                                                    <CardFormStatus
                                                        title={findlanguage().denied}
                                                        color="#dc3545"
                                                        style={{ marginRight: '8px', marginTop: '3px' }}
                                                        className="tooltip_element"
                                                    >
                                                        <span className="tooltip">{findlanguage().denied}</span>
                                                    </CardFormStatus>
                                                    {translate(x.reason)}
                                                </>
                                            )}
                                            {(x.status === '' || x.status === null) && 'teste'}
                                            {datesLeft !== null && datesLeft <= 0 && (
                                                <span
                                                    style={{
                                                        fontSize: '12px',
                                                        cursor: 'pointer',
                                                        color: 'white',
                                                        marginLeft: '5px',
                                                    }}
                                                    className={`badge ${x.status === 'Obsolete' ? 'bg-secondary' : 'bg-danger'}`}
                                                >
                                                    {x.status === 'Obsolete' ? findlanguage().obsolete : findlanguage().isExpired}
                                                </span>
                                            )}
                                            {datesLeft !== null && datesLeft > 0 && datesLeft < 30 && (
                                                <span
                                                    style={{
                                                        fontSize: '12px',
                                                        cursor: 'pointer',
                                                        color: 'white',
                                                        marginLeft: '5px',
                                                    }}
                                                    className="badge bg-warning"
                                                >
                                                    {findlanguage().willExpire} {datesLeft} {findlanguage().days}
                                                </span>
                                            )}
                                        </div>
                                    </td>
                                    <td style={cssTable}>{x.responsible_reply}</td>
                                    <td style={cssTable}>{x.name_who_accepted}</td>
                                    <td style={cssTable}>{formatDate(x.acceptedDate)}</td>
                                    <td style={cssTable}>
                                        {formatDate(x.expirationDate) === '01/01/2200' || formatDate(x.expirationDate) === '12/31/2199' ? 'No expiration' : formatDate(x.expirationDate)}
                                    </td>
                                    <td style={cssTable}>
                                        {formatDate(x.expirationDate) === '01/01/2200' || formatDate(x.expirationDate) === '12/31/2199' ? '' : x.reminderDays}
                                    </td>
                                    <td style={cssTable}>
                                        {permissions?.mySuppliers?.canEdit && (
                                            <div style={{ display: 'flex' }}>
                                                <div onClick={() => deleteForm(x.id)}>
                                                    <span
                                                        style={biggerButton}
                                                        className="badge bg-danger"
                                                    >
                                                        {findlanguage().delete}
                                                    </span>
                                                </div>
                                                {datesLeft !== null && datesLeft <= 0 && x.status !== 'Obsolete' && (
                                                    <>
                                                        <div style={{ marginLeft: '5px' }}></div>
                                                        <span
                                                            style={biggerButtonGreen}
                                                            className="badge"
                                                            onClick={() => resendForm(x)}
                                                        >
                                                            {findlanguage().resend}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            {form.id !== -1 && currentMode === 'view' && (
                <Reports
                    key={form.id}
                    form={form}
                    goBack={() => {
                        goBack();
                        setCurrentMode('view');
                    }}
                    changeUsersWithForm={changeUsersWithForm}
                    setForm={setForm}
                    init={init}
                />
            )}
            {form.id !== -1 && currentMode === 'edit' && (
                <EditForUserReport
                    key={form.id}
                    form={form}
                    goBack={() => {
                        goBack();
                        setCurrentMode('view');
                    }}
                    changeUsersWithForm={changeUsersWithForm}
                    setForm={setForm}
                    init={init}
                />
            )}
        </>
    );
}
