import React, { useContext, useEffect, useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useHistory } from 'react-router-dom'

import {
    PageHeader,
    PageHeaderTitle,
    PageHeaderActions,
    ContentScroll,
    ScrollWrapper,
    LoadSpammer,
} from './styled'

import ContainerAuthenticated from 'containers/Authenticated'

import DashboardFilterSearch from 'components/Dashboard/Filter/Search'
import DashboardCardConnection from 'components/Dashboard/Cards/Connection'
import { CountConnections, FetchConnections } from 'services/connections'
import { Load } from 'ui/styled'
import { CoreContext } from 'context/CoreContext'

import _ from 'lodash'

export default function DashboardConnections() {
    const { getPermission, findlanguage, permissions } = useContext(CoreContext)

    const [connectionsCount, setConnectionsCount] = useState()
    const [connectionList, setConnectionList] = useState()
    const [value, setFilterValue] = useState('')

    const countConnections = async () => {
        setConnectionsCount(null)
        const total = await CountConnections()
        setConnectionsCount(total)
    }

    const listConnections = async () => {
        const list = await FetchConnections()
        setConnectionList(list)
    }

    useEffect(() => {
        countConnections()
        listConnections()
    }, [])

    const onChangeList = (value2) => {
        setFilterValue(value2.target.value)
    }

    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    useEffect(() => {
        if (!permissions?.connections?.canView && !permissions?.connections?.canEdit) {
            navigate('dashboard')
        }
    }, [permissions, navigate]);

    return (
        <>
            <ContainerAuthenticated>
                <Container fluid={false}>
                    <Row>
                        <Col>
                            <PageHeader>
                                <PageHeaderActions>
                                    <PageHeaderTitle>
                                        {findlanguage().connections}{' '}
                                        {connectionsCount ? (
                                            `(${connectionsCount})`
                                        ) : (
                                            <LoadSpammer>
                                                {' '}
                                                <Load white={'true'} />{' '}
                                            </LoadSpammer>
                                        )}
                                    </PageHeaderTitle>
                                </PageHeaderActions>
                            </PageHeader>
                            <DashboardFilterSearch
                                placeholder={findlanguage().searchConnections}
                                onChangeList={onChangeList}
                            />
                            <ContentScroll>
                                <ScrollWrapper>
                                    {connectionList &&
                                        connectionList
                                            .sort((a, b) => {
                                                // Ensure both values are strings. Use an empty string for null or undefined values.
                                                const aValue = a.acceptedInvitation ? '' + a.acceptedInvitation : '';
                                                const bValue = b.acceptedInvitation ? '' + b.acceptedInvitation : '';
                                                return aValue.localeCompare(bValue);
                                            })
                                            .filter((x) => {
                                                // If value is empty, include all users
                                                if (value === '') {
                                                    return true;
                                                }

                                                const lowerCaseValue = value?.toLowerCase();

                                                // Check each field, safely handling potentially null values
                                                return (
                                                    (x?.user?.name?.toLowerCase()?.includes(lowerCaseValue) || false) ||
                                                    (x?.receiverUser?.name?.toLowerCase()?.includes(lowerCaseValue) || false) ||
                                                    (x?.receiverUser?.address?.city?.toLowerCase()?.includes(lowerCaseValue) || false) ||
                                                    (x?.receiverUser?.address?.country?.toLowerCase()?.includes(lowerCaseValue) || false) ||
                                                    (x?.receiverUser?.address?.state?.toLowerCase()?.includes(lowerCaseValue) || false) ||
                                                    (x?.type?.toLowerCase()?.includes(lowerCaseValue) || false) ||
                                                    (x?.acceptedInvitation?.toLowerCase()?.includes(lowerCaseValue) || false)
                                                );
                                            })
                                            .map((x, i) => (
                                                <DashboardCardConnection
                                                    refresh={listConnections}
                                                    reg={x}
                                                    key={x.id}
                                                />
                                            ))}
                                    {!connectionList && <Load white={'true'} />}
                                </ScrollWrapper>
                            </ContentScroll>
                        </Col>
                    </Row>
                </Container>
            </ContainerAuthenticated>
        </>
    )
}
