import React, { useState } from 'react';
import ControlButtons from '../ControlButtons/index';

function List({ element, sections, setSections, currentStep, handleLabelTextChange, toggleRequired, toggleCorrectOption, removeOption, handleResize, removeElement, is_draft }) {
    const [newOptionText, setNewOptionText] = useState('');
    const [nextId, setNextId] = useState(0);

    const addDropdownOption = (elementId) => {
        const newSteps = sections.map((step, stepIndex) => {
            if (stepIndex === currentStep) {
                return {
                    ...step,
                    elements: step.elements.map(el => {
                        if (el.id === elementId) {
                            const newOption = { id: nextId, value: newOptionText || `Option ${nextId}` };
                            const options = el.options ? [...el.options, newOption] : [newOption];
                            return { ...el, options: options };
                        }
                        return el;
                    }),
                };
            }
            return step;
        });
        setSections(newSteps);
        setNextId(nextId + 1);
        setNewOptionText('');
    };

    const handleWeightChange = (elementId, weight) => {
        const newSteps = sections.map((step, sIndex) => {
            if (sIndex === currentStep) {
                return {
                    ...step,
                    elements: step.elements.map(el => {
                        if (el.id === elementId) {
                            return { ...el, weight: parseInt(weight) };
                        }
                        return el;
                    }),
                };
            }
            return step;
        });
        setSections(newSteps);
    };

    const weightOptions = Array.from({ length: 100 }, (_, i) => i + 1);

    return (
        <div key={element.id} className="mb-3 col-12">
            <div style={{ display: 'inline-flex' }}>
                <label className="form-label">
                    List
                </label>
                <div className="form-check" style={{ marginLeft: '9px' }}>
                    <input
                        type="checkbox"
                        id={`required-check-${element.id}`}
                        checked={element.is_required}
                        onChange={(e) => toggleRequired(element.id, e.target.checked)}
                        className="form-check-input"
                        disabled={is_draft}
                    />
                    <label htmlFor={`required-check-${element.id}`} className="form-check-label">
                        Required
                    </label>
                </div>
            </div>
            <input
                type="text"
                className="form-control mb-2"
                value={element.label || ''}
                onChange={(e) => handleLabelTextChange(element.id, e)}
                placeholder="Dropdown Title"
                disabled={is_draft}
            />
            <select
                className="form-select mb-2"
                disabled={is_draft}
            >
                {element?.options?.map(option => (
                    <option key={option.id} value={option.value}>
                        {option.value}
                    </option>
                ))}
            </select>
            {sections[currentStep]?.type === 'quiz' && (
                <>
                    <select
                        className="form-select mb-2"
                        value={element.weight || ''}
                        onChange={(e) => handleWeightChange(element.id, e.target.value)}
                        disabled={is_draft}
                    >
                        <option value="" disabled>Select weight</option>
                        {weightOptions.map(option => (
                            <option key={option} value={option}>
                                {option}%
                            </option>
                        ))}
                    </select>
                </>
            )}
            <div className="d-flex">
                <input
                    type="text"
                    className="form-control me-2"
                    value={newOptionText}
                    onChange={e => setNewOptionText(e.target.value)}
                    placeholder="Type new option"
                    disabled={is_draft}
                />
                <button
                    type="button"
                    className="btn colorTealDark"
                    onClick={() => addDropdownOption(element.id)}
                    disabled={!newOptionText}
                >
                    Add
                </button>
            </div>
            {element?.options?.map(option => (
                <div key={option.id} className="d-flex align-items-center justify-content-between mb-2">
                    <span className="flex-grow-1">{option.value}</span>
                    <div className="d-flex align-items-center">
                        {sections[currentStep]?.type === 'quiz' && (
                            <div style={{ marginRight: '10px' }}>
                                <input
                                    id={`correct-answer-${option.id}`}
                                    type="checkbox"
                                    checked={option.is_correct}
                                    onChange={e => toggleCorrectOption(element.id, option.id, e.target.checked)}
                                    className="form-check-input"
                                    disabled={is_draft}
                                />
                                <label htmlFor={`correct-answer-${option.id}`} className="ms-2">
                                    Mark as correct Answer
                                </label>
                            </div>
                        )}
                        {!is_draft && (
                            <button type="button" className="btn btn-outline-danger btn-sm ms-auto" onClick={() => removeOption(element.id, option.id)}>
                                <i className="bi bi-x-lg"></i>
                            </button>
                        )}
                    </div>
                </div>
            ))}
            <ControlButtons
                elementId={element.id}
                onResize={handleResize}
                onRemove={removeElement}
                is_draft={is_draft}
            />
        </div>
    );
}

export default List;
