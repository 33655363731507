import { CoreContext } from 'context/CoreContext'
import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { DoLogout } from 'services/authentication'

import {
    HeaderMobileContainer,
    HeaderContent,
    HeaderContentItem,
    MobileButton,
    MobileButtonIcon,
    PlanCheckedKing,
    PlanPremium30,
    TrialExpiring,
    DropdownContainer,
    DropdownButton,
    DropdownContent,
    DropdownItem,
    PlanPremiumPT,
} from './styled'
import { SaveObject } from 'services/storage'
import { ShouldShowMessage } from 'services/chat'

export default function HeaderMenu({ adminView }) {
    const {
        user,
        permissions,
        premium,
        checkPremium,
        getPermission,
        profile,
        setProfile,
        showAccessDenied,
        findlanguage,
        language,
        totalMessages,
        setTotalMessages,
    } = useContext(CoreContext)
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const [opened, setOpened] = useState(false)
    const [menuOptions, setMenuOptions] = useState([])

    const action = (item) => {
        if (item.action && typeof item.action === 'function') {
            item.action()
        }
        if (item.page) {
            history.push(item.page)
        }
    }

    const exit = async () => {
        await DoLogout()
        navigate('login')
    }

    const showFilter = () => {
        return { title: findlanguage().filter, page: '/dashboard/filter' }
    }

    const showWishlist = () => {
        if (profile === 'supplier' && premium.type === 'free') {
            return { title: findlanguage().wishlist, action: () => showAccessDenied('premium') }
        } else if (profile !== 'supplier' && premium.type === 'free') {
            return {
                title: findlanguage().wishlist,
                action: () => showAccessDenied('premium'),
            }
        } else {
            return { title: findlanguage().wishlist, page: '/dashboard/wishlist' }
        }
    }

    const showMyQMS = () => {
        return { title: 'MyQMS', page: '/dashboard/sms' }
    }

    const localCheckPremium = async () => {
        if (user.active_account) {
            const plan = await checkPremium()

            buildMenu(plan.type)
        } else if (adminView) {
            buildMenu('')
        }
    }

    const showProfileViews = () => {
        if (premium.type === 'free') {
            return { title: findlanguage().profileViews, action: () => showAccessDenied('premium') }
        } else {
            return { title: findlanguage().profileViews, page: '/dashboard/profileViews' }
        }
    }

    const buildMenu = async (subscriptionPlan) => {
        try {
            if (
                user.currentWorkingAsServiceProvider !== null &&
                user.currentWorkingAsServiceProvider === true &&
                user.id !== user.company_id
            ) {
                let menuOptions = [
                    { title: 'Change my Selection', page: '/dashboard/service-provider-selector' },
                    showMyQMS(),
                    { title: findlanguage().logout, action: () => exit() },
                ]
                setMenuOptions(menuOptions)
            }
            else {
                let menuOptions = [
                    { title: 'Home', page: '/dashboard' },
                    { title: findlanguage().manage, page: '/dashboard/profile' },
                        (permissions?.profileViews?.canView === true) ? showProfileViews() : {},
                    (permissions?.filter?.canUse === true) ? showFilter() : {},
                    (permissions?.wishlist?.canView === true || permissions?.wishlist?.canEdit === true) ? showWishlist() : {},
                    (permissions?.connections?.canView === true || permissions?.connections?.canEdit === true)
                        ? { title: findlanguage().connections, page: '/dashboard/connections' }
                        : {},
                    (permissions?.message?.canUse === true)
                        ? { title: findlanguage().message, page: '/dashboard/message' }
                        : {},
                    (user?.company_id === 1)
                        ? { title: 'Backoffice', page: '/backoffice' }
                        : {},
                ]

                if (user && user.active_account.type === 'service-provider') {
                    menuOptions = [
                        ...menuOptions,
                        {
                            title: 'Change my Selection',
                            page: '/dashboard/service-provider-selector',
                        },
                    ]
                }

                if (premium.trial) {
                    let now = new Date();
                    const timeToExpire = new Date(premium.due).getTime() - now.getTime();
                    const daysToExpire = Math.floor(timeToExpire / (1000 * 3600 * 24));

                    let messageTrial;
                    if (daysToExpire < 0) {
                        messageTrial = language === 'portugues' ? <PlanPremiumPT /> : <PlanPremium30 />;
                    } else if (daysToExpire === 0) {
                        messageTrial = language === 'portugues' ? 'Teste acaba hoje!' : 'Trial ends today!';
                    } else if (daysToExpire === 1) {
                        messageTrial = language === 'portugues' ? 'Teste acaba amanhã!' : 'Trial ends tomorrow!';
                    } else {
                        messageTrial = language === 'portugues' ? `Teste acaba em ${daysToExpire} dias` : `Trial ends in ${daysToExpire} days`;
                    }

                    menuOptions = [
                        ...menuOptions,
                        (permissions?.myQms?.canView === true) ? showMyQMS() : {},
                        { title: findlanguage().logout, action: () => exit() },
                        {
                            title: <TrialExpiring>{messageTrial}</TrialExpiring>,
                            page: '/dashboard/account-premium',
                        },
                    ];
                }
                else {
                    if (subscriptionPlan === 'free')
                        menuOptions = menuOptions.concat([
                            (permissions?.myQms?.canView === true) ? showMyQMS() : {},
                            { title: findlanguage().logout, action: () => exit() },
                            language === 'portugues'
                                ? { title: <PlanPremiumPT />, page: '/dashboard/account-premium' }
                                : { title: <PlanPremium30 />, page: '/dashboard/account-premium' },
                        ])
                    else if (subscriptionPlan === 'premium')
                        menuOptions = menuOptions.concat([
                            (permissions?.myQms?.canView === true) ? showMyQMS() : {},
                            { title: findlanguage().logout, action: () => exit() },
                            { title: <PlanCheckedKing />, page: '/dashboard/account-premium' },
                        ])
                }

                setMenuOptions(menuOptions)
            }
        } catch (error) {
            setMenuOptions([{ title: 'Logout', action: () => exit() }])
        }
    }

    useEffect(() => {
        localCheckPremium()
    }, [])

    useEffect(() => {
        localCheckPremium()
    }, [user])

    useEffect(() => {
        localCheckPremium()
    }, [localStorage.getItem('language'), permissions])

    const [isOpen, setIsOpen] = useState(false)
    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }
    const user_key = user.id

    useEffect(() => {
        const initialFetch = async () => {
            const data = await ShouldShowMessage(user_key)
            setTotalMessages(data[0].count)
        }
        initialFetch()

        const interval = setInterval(async () => {
            const data = await ShouldShowMessage(user_key)
            setTotalMessages(data[0].count)
        }, 60000)

        const handleBeforeUnload = () => {
            clearInterval(interval)
        }

        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            clearInterval(interval)
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])

    return (
        <>
            <MobileButton onClick={() => setOpened(!opened)}>
                <MobileButtonIcon />
            </MobileButton>

            <HeaderMobileContainer opened={opened}>
                <HeaderContent menuMobile>
                    {menuOptions.map((item, key) => {
                        if (item.title === findlanguage().manage) {
                            return (
                                <DropdownContainer key={key}>
                                    <DropdownButton onClick={toggleDropdown}>
                                        {item.title}
                                    </DropdownButton>
                                    <DropdownContent isOpen={isOpen}>
                                        {
                                            (permissions?.myProfile?.canView === true || permissions?.myProfile?.canEdit === true)
                                                ?
                                                <DropdownItem
                                                    href={item.page}
                                                    exact
                                                    onClick={(e) => {
                                                        action(item)
                                                        e.preventDefault()
                                                    }}
                                                >
                                                    {item.title === findlanguage().manage ? findlanguage().myProfile : item.title}
                                                </DropdownItem>
                                                :
                                                null
                                        }
                                        {menuOptions.map((item2, key2) => {
                                            if (
                                                item2.title === findlanguage().profileViews ||
                                                item2.title === findlanguage().wishlist ||
                                                item2.title === findlanguage().connections
                                            ) {
                                                return (
                                                    <DropdownItem
                                                        key={key2}
                                                        href={item2.page}
                                                        exact
                                                        onClick={(e) => {
                                                            action(item2)
                                                            e.preventDefault()
                                                        }}
                                                    >
                                                        {item2.title}
                                                    </DropdownItem>
                                                )
                                            }
                                        })}
                                    </DropdownContent>
                                </DropdownContainer>
                            )
                        } else if (
                            item.title !== findlanguage().profileViews &&
                            item.title !== findlanguage().wishlist &&
                            item.title !== findlanguage().connections
                        )
                            return item.title !== undefined ? (
                                <HeaderContentItem
                                    key={key}
                                    href={item.page}
                                    exact
                                    onClick={(e) => {
                                        action(item)
                                        e.preventDefault()
                                    }}
                                >
                                    {item.title === findlanguage().message ? (
                                        <span
                                            style={{ marginLeft: '75px' }}
                                            className="position-absolute translate-middle badge rounded-pill bg-danger"
                                        >
                                            {totalMessages}
                                        </span>
                                    ) : null}
                                    {item.title}
                                </HeaderContentItem>
                            ) : (
                                ''
                            )
                    })}
                </HeaderContent>
            </HeaderMobileContainer>
        </>
    )
}
