import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const HeaderContent = styled.div.attrs({})`
    display: flex;
    align-items: center;
    ${(props) =>
        props.menuMobile
            ? `
            @media(max-width:${mobileSize}px){
                flex-direction: column;
                width:100%;
                div {
                    margin: 15px;
                }
            }
        `
            : ``}
`

export const HeaderLogoImage = styled.img.attrs({
    src: '/icons/logo-text.svg',
})`
    width: 100%;
    max-width: 200px;
    margin-left: 30px;
    margin-right: 45px;

    @media (max-width: 399px) {
        display: none;
    }
`

export const HeaderBadge = styled.div.attrs({})`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const HeaderBadgeTouch = styled.div.attrs({})`
    cursor: pointer;

    @media (max-width: 399px) {
        margin-left: 20px;
    }
`

export const HeaderBadgeIcon = styled.img.attrs({
    src: '/icons/bell.svg',
})``

export const HeaderBadgeText = styled.div.attrs({})`
    position: absolute;
    bottom: -5px;
    right: -5px;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    background: var(--redlabel-color);
    color: var(--white-color);
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
`

export const NotificationContainer = styled.div.attrs({})`
    position: absolute;
    top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: 3;
`
export const NotificationContentDecoration = styled.div.attrs({})`
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-bottom: 10px solid var(--white-color);
`
export const NotificationContent = styled.div.attrs({})`
    overflow-x: auto;
    height: 600px;
    width: 100%;
    min-width: 320px;
    max-width: 320px;
    padding: 25px 15px;
    background: var(--white-color);
    border-radius: 10px;

    border: 1px solid rgba(112, 112, 112, 0.1);
    box-shadow: 0px 1.5px 3px rgba(0, 0, 0, 0.1);
`
export const NotificationContentTitle = styled.div.attrs({})`
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--primary-color);
    margin-bottom: 15px;
`
export const NotificationItem = styled.div.attrs({})`
    display: flex;
    padding: 12.5px;
`
export const NotificationItemIcon = styled.img.attrs({
    src: '/icons/info.svg',
})`
    margin-right: 15px;
`

export const NotificationItemReadIcon = styled.img.attrs({
    src: '/icons/green-check.svg',
})`
    margin-right: 15px;
`

export const NotificationItemText = styled.div.attrs({})`
    font-size: 14px;
`

export const NotificationButton = styled.div.attrs({})`
    color: #fff;
    font-size: 16px;
    font-style: normal;
    line-height: 28px;
    background-color: #4c6c80;
    min-width: 146px;
    height: 28px;
    text-align: center;
    border-radius: 28px;
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
`
