import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Select from 'components/Form/Select'

import { toast } from 'react-toastify'
import { DoRegisterSupplier } from 'services/authentication'
import { DoRegisterAdmin } from 'services/authentication'
import { DoRegisterServiceProvider } from 'services/authentication'
import { CoreContext } from 'context/CoreContext'

import ContainerUnauthenticated from 'containers/Unauthenticated'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap'

import RegisterHeader from 'components/Register/Header'
import { BodyContent, FormContent } from './styled'
import Input from 'components/Form/Input'
import InputPassword from 'components/Form/InputPassword'
import RegisterFooter from 'components/Register/Footer'
import { exposeStrapiErrorNew } from 'utils'
import { ReadCountries } from 'services/location'

export default function RegisterSupplier({ chooseType, setChooseType }) {
    const { findlanguage } = useContext(CoreContext)
    const [fieldValidation, setFieldValidation] = useState([])
    const [stateTooltipPhone, setStateTooltipPhone] = useState(false)
    const [stateTooltipPassword, setStateTooltipPassword] = useState(false)
    const [name, setName] = useState('')
    const [contactName, setContactName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [contry, setContry] = useState('')
    const [department, setDepartment] = useState('')
    const [whatuserislookingonsolution, setWhatuserislookingonsolution] = useState('')
    const [countries, setCountries] = useState([])
    const [showOverlay, setShowOverlay] = useState(false);

    useEffect(() => {
        const init = async () => {
            ReadCountries().then((resp) =>
                setCountries(
                    resp.map((item, key) => ({
                        id: key + 1,
                        title: item.name,
                        value: item.name,
                    }))
                )
            )
        }
        init()
    }, [])

    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const [form, setForm] = useState({})
    const changeForm = (value, ref) => {
        setForm({ ...form, [ref]: value })
    }
    const completeLogin = () => {
        localStorage.removeItem('form')
        toast.success('Account created')
        navigate('register/full-access')
    }

    const showHighlight = (name) => {
        const field = fieldValidation.find((item) => item.field === name)
        if (field !== undefined) return true
        return false
    }

    const handleInputPhone = (event) => {
        changeForm(event.target.value, event.target.name)
        togglePhone(false)
    }

    const togglePhone = (_state) => {
        if (typeof _state !== 'boolean') return
        setStateTooltipPhone(_state)
    }

    const togglePassword = (_state) => {
        if (typeof _state !== 'boolean') return
        setStateTooltipPassword(_state)
    }

    const handleInputPassword = (event) => {
        changeForm(event.target.value, event.target.name)
        togglePassword(false)
    }

    const action = async () => {
        buildForm()

        if (!valid()) {
            return
        } else {
            setShowOverlay(true);
            const _payload = {
                name: name,
                contactName: contactName,
                email: email,
                phone: phone,
                password: password,
                is_company_visible: true,
                address: {
                    street: '',
                    city: '',
                    state: '',
                    country: contry.title,
                    zipCode: '',
                    latitude: '',
                    longitude: '',
                },
                aditionalInformations: {
                    businessTypes: [],
                    companyCategories: [],
                    supplierPreRequirements: [],
                    aboutCompany: '',
                    department: department.title,
                    whatuserislookingonsolution: whatuserislookingonsolution.title,
                },
            }

            let result = null // Initialize result

            try {
                if (chooseType === 'admin') {
                    result = await DoRegisterAdmin(_payload)
                } else if (chooseType === 'serviceprovider') {
                    result = await DoRegisterServiceProvider(_payload)
                } else {
                    // Handle the case where chooseType is not valid
                    console.error('Invalid chooseType')
                    return
                }

                if (result && exposeStrapiErrorNew(result)) {
                    completeLogin()
                }
            } catch (error) {
                // Handle any errors that might occur during the registration process
                console.error('Error in registration process:', error)
            }
        }
    }
    const buildForm = () => {
        setForm({
            ...form,
            name: name,
            contactName: contactName,
            email: email,
            phone: phone,
            password: password,
        })
    }
    const valid = () => {
        setFieldValidation([])
        let errors = []

        if (name.length === 0) {
            errors.push({
                field: 'name',
                message: findlanguage().fillTheField + findlanguage().companyName,
            })
        } else if (name.length < 2) {
            errors.push({
                field: 'name',
                message: findlanguage().errorCompanyName,
            })
        }

        if (contactName.length === 0) {
            errors.push({
                field: 'contactName',
                message: findlanguage().fillTheField + findlanguage().name,
            })
        } else if (contactName.length < 2) {
            errors.push({
                field: 'contactName',
                message: findlanguage().errorName,
            })
        }

        if (email.length === 0) {
            errors.push({
                field: 'email',
                message: findlanguage().fillTheField + findlanguage().email,
            })
        } else if (
            !email.match(
                /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
            )
        ) {
            errors.push({ field: 'email', message: findlanguage().errorEmail })
        }

        if (phone.length === 0) {
            errors.push({
                field: 'phone',
                message: findlanguage().fillTheField + findlanguage().phone,
            })
        } else if (!/^\+(?:\d[-.\s]?){6,14}\d$/.test(phone)) {
            errors.push({
                field: 'phone',
                message: findlanguage().errorPhone,
            })
        }

        if (contry === '') {
            errors.push({
                field: 'country',
                message: 'Fill the field: Country',
            })
        }

        if (password.length === 0) {
            errors.push({ field: 'password', message: 'Fill the field: Password' })
        } else if (!password.match('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{7,})')) {
            errors.push({
                field: 'password',
                message:
                    'Password must have at least 8 characters, include at least 1 Capital letter, 1 number and 1 special character (!@#$%^&*)',
            })
        }

        if (confirmPassword.length === 0) {
            errors.push({ field: 'confirmPassword', message: 'Fill the field: Confirm Password' })
        }

        if (password !== confirmPassword) {
            errors.push({
                field: 'confirmPassword',
                message: 'Password and Confirm Password not match',
            })
        }

        if (errors.length > 0) {
            let message = ''
            message = findlanguage().someErrorsFound
            errors.forEach((item) => {
                message += '\n - ' + item.message
            })
            toast.error(message)
            setFieldValidation(errors)
            return false
        }

        return true
    }

    const provideDepartmentList = [
        {
            id: 1,
            title: findlanguage().ceo,
            value: findlanguage().ceo,
        },
        {
            id: 2,
            title: findlanguage().procurement,
            value: findlanguage().procurement,
        },
        {
            id: 3,
            title: findlanguage().sales,
            value: findlanguage().sales,
        },
        {
            id: 4,
            title: findlanguage().salesRepresentative,
            value: findlanguage().salesRepresentative,
        },
        {
            id: 5,
            title: findlanguage().researchAndDevelopment,
            value: findlanguage().researchAndDevelopment,
        },
        {
            id: 6,
            title: findlanguage().quality,
            value: findlanguage().quality,
        },
        {
            id: 7,
            title: findlanguage().projectManagement,
            value: findlanguage().projectManagement,
        },
        {
            id: 8,
            title: findlanguage().other,
            value: findlanguage().other,
        },
    ]

    const whatuserislookingonsolutionList = [
        {
            id: 1,
            title: findlanguage().findNewSuppliers,
            value: findlanguage().findNewSuppliers,
        },
        {
            id: 2,
            title: findlanguage().findNewConsultants,
            value: findlanguage().findNewConsultants,
        },
        {
            id: 3,
            title: findlanguage().controlTheDocumentationOfMySuppliers,
            value: findlanguage().controlTheDocumentationOfMySuppliers,
        },
        {
            id: 4,
            title: findlanguage().controlMyInternalDocuments,
            value: findlanguage().controlMyInternalDocuments,
        },
        {
            id: 5,
            title: findlanguage().beValidatedForNewBuyers,
            value: findlanguage().beValidatedForNewBuyers,
        },
        {
            id: 6,
            title: findlanguage().supplyToNewCompanies,
            value: findlanguage().supplyToNewCompanies,
        },
    ]
    
    const LoadingOverlay = ({ isVisible }) => {
        if (!isVisible) return null;

        return (
            <div className="overlay">
                <span className="loader"></span>
            </div>
        );
    };

    return (
        <div className="stepscreen">
            <LoadingOverlay isVisible={showOverlay} />
            <ContainerUnauthenticated>
                <RegisterHeader title={findlanguage().createCount} />

                <BodyContent>
                    <FormContent>
                        <Input
                            placeholder={findlanguage().companyName}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type={'text'}
                            highlight={showHighlight('name')}
                            autoComplete={'xoff'}
                            small
                        />
                        <Input
                            placeholder={findlanguage().contactName}
                            value={contactName}
                            onChange={(e) => setContactName(e.target.value)}
                            type={'text'}
                            highlight={showHighlight('contactName')}
                            autoComplete={'xoff'}
                            small
                        />
                        <Input
                            placeholder={findlanguage().email}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type={'text'}
                            highlight={showHighlight('email')}
                            autoComplete={'xoff'}
                            small
                        />

                        <Input
                            placeholder={findlanguage().phoneNumber}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            onBlur={handleInputPhone}
                            onFocus={(e) => togglePhone(true)}
                            id="phone1"
                            type={'tel'}
                            autoComplete={'xoff'}
                            highlight={showHighlight('phone')}
                            small
                        />
                        <Popover
                            placement="top"
                            isOpen={stateTooltipPhone}
                            toggle={togglePhone}
                            target={'phone1'}
                        >
                            <PopoverHeader>{findlanguage().rulesPhoneNumberHeader}</PopoverHeader>
                            <PopoverBody>{findlanguage().rulesPhoneNumberText}</PopoverBody>
                        </Popover>

                        <InputPassword
                            placeholder={findlanguage().password}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            id="password"
                            onFocus={(e) => togglePassword(true)}
                            onBlur={handleInputPassword}
                            highlight={showHighlight('password')}
                            autoComplete={'xoff'}
                            small
                        />
                        <Popover
                            placement="top"
                            isOpen={stateTooltipPassword}
                            toggle={togglePassword}
                            target={'password'}
                        >
                            <PopoverHeader>{findlanguage().rulesPasswordHeader}</PopoverHeader>
                            <PopoverBody>{findlanguage().rulesPasswordText}</PopoverBody>
                        </Popover>

                        <div style={{ width: '303px' }}>
                            <Input
                                type={'password'}
                                placeholder={findlanguage().confirmPassword}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                highlight={showHighlight('confirmPassword')}
                                autoComplete={'new-password'}
                                small
                            />
                            <p></p>
                        </div>
                        <div
                            className={
                                contry === '' && !showHighlight('country') ? 'opacitymydiv' : ''
                            }
                            style={{ width: '303px', zIndex: '1000000' }}
                        >
                            <Select
                                placeholder={findlanguage().selectYourCountry}
                                value={contry}
                                highlight={showHighlight('country')}
                                onChange={(e) => setContry(e)}
                                small
                                style={{ marginTop: '0px' }}
                                setAutoComplete={false}
                                options={countries}
                            />
                        </div>
                        <div
                            className={department === '' ? 'opacitymydiv' : ''}
                            style={{ width: '303px', zIndex: '1000000' }}
                        >
                            <Select
                                placeholder={findlanguage().selectYourDepartment}
                                value={department}
                                onChange={(e) => setDepartment(e)}
                                small
                                style={{ marginTop: '0px' }}
                                setAutoComplete={false}
                                options={provideDepartmentList}
                            />
                        </div>
                        <div
                            className={whatuserislookingonsolution === '' ? 'opacitymydiv' : ''}
                            style={{ width: '303px', zIndex: '1000' }}
                        >
                            <Select
                                placeholder={findlanguage().whatAreYouLookingFor}
                                value={whatuserislookingonsolution}
                                onChange={(e) => setWhatuserislookingonsolution(e)}
                                small
                                style={{ marginTop: '0px' }}
                                setAutoComplete={false}
                                options={whatuserislookingonsolutionList}
                            />
                        </div>
                    </FormContent>

                    <FormContent>
                        <RegisterFooter
                            action={action}
                            back={() => setChooseType('')}
                            loading={false}
                        />
                    </FormContent>
                </BodyContent>
            </ContainerUnauthenticated>
        </div>
    )
}
