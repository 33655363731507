import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import DashboardButton from 'components/Dashboard/Button'

import { FilterContent, FilterText } from './styled'

import DashboardFilterSearch from 'components/Dashboard/Filter/Search'
import DashboardFilterAction from 'components/Dashboard/Filter/Action'
import { CoreContext } from 'context/CoreContext'
import { SearchUsers } from 'services/filters'
import { Load } from 'ui/styled'
import RegularView from './RegularView'
import AIHelper from './AIHelper'
import AIFreeForm from './AIFreeForm'

export default function DashboardFilter() {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const { setModal, filter, getPermission, premium, showAccessDenied, findlanguage, user, language } = useContext(CoreContext)
    const [mode, setMode] = useState('products')
    const [totalPerPage, setTotalPerPage] = useState(10)
    const [countItems, setcountItems] = useState(1)
    const pageCount = Math.ceil(countItems / totalPerPage)
    const [searchExpression, setSearchExpression] = useState('')
    const [itemOffset, setItemOffset] = useState(0)
    const endOffset = itemOffset + totalPerPage
    const [totalFilters, setTotalFilters] = useState(0)
    const [page, setPage] = useState(0)
    const [holdQuery, setHoldQuery] = useState([])
    const [screen, setScreen] = useState(0)
    const [seeResults, setSeeResults] = useState(true)

    const filterProduct = () => {
        if (premium.type === 'free') {
            showAccessDenied(premium.type)
            return
        }

        setMode('products')
        setModal({
            type: 'filter-product',
            title: findlanguage().filterProducts,
        })
    }

    const [findedUsers, setFindedUsers] = useState([])

    const [loading, setLoading] = useState(false)

    const init = async () => {
        setLoading(true)
        const query = []
        let total = 0

        if (filter?.category !== undefined && filter?.category?.length !== 0) {
            total++
            let fillQuery = ''

            filter?.category?.forEach((x, i) => {
                if (i !== 0) fillQuery += '|' + x.value
                else fillQuery += x.value
            })
            if (fillQuery !== '') query.push(`categories=${fillQuery}`)
        }

        if (filter?.products !== undefined && filter?.products?.length !== 0) {
            total++
            let fillQuery = ''

            filter?.products?.forEach((x, i) => {
                if (i !== 0) fillQuery += '|' + x.value
                else fillQuery += x.value
            })

            if (filter?.searchByName !== undefined && filter?.searchByName) {
                fillQuery += '|' + filter?.searchByName
            }

            if (fillQuery !== '') query.push(`product=${fillQuery}`)
        }

        if (filter?.servicesOffered !== undefined && filter?.servicesOffered?.length !== 0) {
            total++
            let fillQuery = ''

            filter?.servicesOffered?.forEach((x, i) => {
                if (i !== 0) fillQuery += '|' + x.value
                else fillQuery += x.value
            })
            if (fillQuery !== '') query.push(`servicesOffered=${fillQuery}`)
        }

        if (filter?.servicesProvided !== undefined && filter?.servicesProvided?.length !== 0) {
            total++
            let fillQuery = ''

            filter?.servicesProvided?.forEach((x, i) => {
                if (i !== 0) fillQuery += '|' + x.value
                else fillQuery += x.value
            })
            if (fillQuery !== '') query.push(`servicesProvided=${fillQuery}`)
        }

        if (filter?.mfs_validation !== undefined && filter?.mfs_validation?.length !== 0) {

            total++
            let fillQuery = ''

            filter?.mfs_validation?.forEach((x, i) => {
                if (i !== 0) fillQuery += '|' + x.value
                else fillQuery += x.value
            })
            if (fillQuery !== '') query.push(`mfs_validation=${fillQuery}`)
        }

        if (filter?.service_type !== undefined && filter?.service_type?.length !== 0) {
            total++
            let fillQuery = ''

            filter?.service_type?.forEach((x, i) => {
                let thisValue = x.type

                if (thisValue === 'Service Provider') thisValue = 'service-provider'

                if (i !== 0) fillQuery += '|' + thisValue
                else fillQuery += thisValue
            })

            if (fillQuery !== '') query.push(`type=${fillQuery}`)
        }

        if (filter?.distribution !== undefined && filter?.distribution?.length !== 0) {
            total++
            let fillQuery = ''

            filter?.distribution?.forEach((x, i) => {
                let thisValue = x.value

                if (i !== 0) fillQuery += '|' + thisValue
                else fillQuery += thisValue
            })

            if (fillQuery !== '') query.push(`distribution=${fillQuery}`)
        }

        if (filter?.city !== undefined && filter?.city?.length !== 0) {
            total++
            let fillQuery = ''

            filter?.city?.forEach((x, i) => {
                if (i !== 0) fillQuery += '|' + x.label
                else fillQuery += x.label
            })

            if (fillQuery !== '') query.push(`city=${fillQuery}`)
        }

        if (filter?.state !== undefined && filter?.state?.length !== 0) {
            total++
            let fillQuery = ''

            filter?.state?.forEach((x, i) => {
                if (i !== 0) fillQuery += '|' + x.label
                else fillQuery += x.label
            })

            if (fillQuery !== '') query.push(`state=${fillQuery}`)
        }

        if (filter?.certifications !== undefined && filter?.certifications?.length !== 0) {
            total++
            let fillQuery = ''

            filter?.certifications?.forEach((x, i) => {
                if (i !== 0) fillQuery += '|' + x.label
                else fillQuery += x.label
            })

            if (fillQuery !== '') query.push(`certifications=${fillQuery}`)
        }

        if (filter?.country !== undefined && filter?.country?.length !== 0) {
            total++
            let fillQuery = ''

            filter?.country?.forEach((x, i) => {
                if (i !== 0) fillQuery += '|' + x.label
                else fillQuery += x.label
            })

            if (fillQuery !== '') query.push(`country=${fillQuery}`)
        }

        if (filter?.distance !== undefined && filter?.distance && filter?.distance !== '0') {
            total++
            query.push(`radius=${filter?.distance}`)
        }

        if (filter?.searchByName !== undefined && filter?.searchByName) {
            total++
            query.push(`company_name=${filter?.searchByName}`)

            if (filter?.products === undefined || filter?.products?.length === 0) {
                query.push(`product=${filter?.searchByName}`)
            }
        }

        query.push(`language=${language}`)
        query.push(`offSet=0`)
        query.push(`totalToShow=${totalPerPage}`)

        setHoldQuery(query)

        const data = await SearchUsers(query)

        setcountItems(parseInt(data?.result?.length !== 0 ? data?.result[0]?.full_count : 0))
        setTotalFilters(total)

        setFindedUsers(data.result)

        setItemOffset(0)
        setLoading(false)
    }

    const handlePageClick = async (event) => {
        setPage(event.selected)
        setLoading(true)
        const newOffset = (event.selected * totalPerPage) % countItems
        let holdQueryClone = _.cloneDeep(holdQuery)

        setItemOffset(newOffset)

        holdQueryClone = holdQueryClone.map((x) => {
            if (x.includes('offSet')) return `offSet=${newOffset}`
            else return x
        })

        const data = await SearchUsers(holdQueryClone)

        setFindedUsers(data.result)

        setLoading(false)
    }

    const changeValuePerPage = (value) => {
        setTotalPerPage(value)
    }

    const changeScreen = (screenId) => {
        setScreen(screenId)

        if (screenId === 1 || screenId === 2) setSeeResults(false)
        else setSeeResults(true)
    }

    useEffect(() => {
        init()
    }, [filter, mode, totalPerPage])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            filter.searchByName = searchExpression
            init()
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [searchExpression])

    return (
        <>
            {user.username.includes('-test') ? (
                <FilterContent>
                    <DashboardButton
                        icon={'check'}
                        outline={screen === 0 ? false : true}
                        green
                        onClick={() => changeScreen(0)}
                    >
                        Regular View
                    </DashboardButton>
                    <DashboardButton
                        icon={'check'}
                        green
                        outline={screen === 1 ? false : true}
                        onClick={() => changeScreen(1)}
                    >
                        AI Helper
                    </DashboardButton>
                    <DashboardButton
                        icon={'check'}
                        green
                        outline={screen === 2 ? false : true}
                        onClick={() => changeScreen(2)}
                    >
                        AI free form
                    </DashboardButton>
                    <DashboardButton
                        icon={'check'}
                        green
                        outline={screen === 3 ? false : true}
                        onClick={() => changeScreen(3)}
                    >
                        AI
                    </DashboardButton>
                </FilterContent>
            ) : null}
            <br />
            {seeResults === true ? (
                <>
                    <FilterContent style={{ marginLeft: '-13px' }}>
                        <div style={{ marginTop: '-27px' }}>
                            <DashboardFilterAction
                                title={loading ? findlanguage().loading : findlanguage().filters}
                                outline={mode !== 'products'}
                                badge={totalFilters}
                                onClick={loading ? null : filterProduct}
                            />
                        </div>
                        <DashboardFilterSearch
                            style={{ width: '88.8%', marginLeft: '5px' }}
                            placeholder={findlanguage().searchByCompanyName}
                            onChangeList={(e) => setSearchExpression(e.target.value)}
                            value={searchExpression}
                            disabled={loading}
                        />
                    </FilterContent>
                    <FilterContent>
                        <FilterText light>
                            {countItems} {findlanguage().totalResultsFound} {totalPerPage}{' '}
                            {findlanguage().perPage}.
                        </FilterText>
                    </FilterContent>
                </>
            ) : null}

            {loading ? (
                <Load color="#263640" />
            ) : (
                <>
                    {screen === 0 ? (
                            <RegularView
                                findedUsers={findedUsers}
                                handlePageClick={handlePageClick}
                                pageCount={pageCount}
                                page={page}
                                totalPerPage={totalPerPage}
                                changeValuePerPage={changeValuePerPage}
                            />
                    ) : null}
                            {screen === 1 ? <AIHelper changeScreen={changeScreen} /> : null}
                            {screen === 2 ? <AIFreeForm changeScreen={changeScreen} /> : null}
                        </>
                    )}
                </>
            )
            }
