import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { toast } from 'react-toastify'
import { DoRegisterSupplier } from 'services/authentication'
import { exposeStrapiErrorNew } from 'utils'
import { CoreContext } from 'context/CoreContext'

import ContainerUnauthenticated from 'containers/Unauthenticated'

import RegisterHeader from 'components/Register/Header'
import RegisterStepIndicator from 'components/Register/StepIndicator'
import RegisterSupplierStep1 from 'components/Register/Steps/Supplier/Step1'
import RegisterSupplierStep2 from 'components/Register/Steps/Supplier/Step2'

import {
    ReadBusinessTypes,
    ReadCertificationsAndApprovals,
    ReadCompanyCategories,
    ReadSupplierProductsSource,
} from 'services/infos'

export default function RegisterSupplier() {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)
    const { findlanguage } = useContext(CoreContext)

    const [loading, setLoading] = useState(false)

    const steps = [
        { highlight: findlanguage().supplierTitle, title: findlanguage().businessInformation },
        { title: findlanguage().additionalInformation },
    ]

    const [step, setStep] = useState(0)
    const [registerInfos, setRegisterInfos] = useState({})

    const [form, setForm] = useState({})
    const formValue = (ref) => {
        return form?.[ref] ? form?.[ref] : ''
    }
    const changeForm = (value, ref) => {
        setForm({ ...form, [ref]: value })
    }

    const back = () => {
        if (step === 0) {
            history.goBack()
        } else {
            setStep(step - 1)
        }
    }

    const next = () => {
        const nxt = step + 1
        if (nxt < steps.length) {
            setStep(nxt)
        } else {
            action()
        }
    }

    const getCertLabels = (suboptions) => {
        let list = []

        suboptions.forEach((element) => {
            list.push(element.label)
        })

        return list
    }

    const action = async () => {
        const _payload = {
            ...form,
            address: {
                street: form?.street,
                city: form?.city,
                state: form?.state,
                country: form?.country,
                zipCode: form?.zipCode,
                latitude: form?.latitude,
                longitude: form?.longitude,
            },

            certifications: form?.certifications?.length
                ? form?.certifications?.map((item) => ({
                      id: item.id,
                      title: item.title,
                      items: item.suboptions.map((subitem) => ({
                          id: subitem.id,
                          label: subitem.label,
                      })),
                  }))
                : [],

            products_supplier: form?.products_supplier?.length
                ? form?.products_supplier?.map((item) => ({
                      item,
                  }))
                : [],

            aditionalInformations: {
                businessTypes: form?.businessType?.map((it) => it.value) || [],
                companyCategories: form?.categories?.map((it) => it.id) || [],
                productsDescription: form?.products_description,
                aboutCompany: form?.about_company,
                distributionState: form?.State,
                distributionNational: form?.National,
                distributionInternational: form?.International,
            },
        }

        // console.log('_payload', _payload)

        //return

        setLoading(true)

        const result = await DoRegisterSupplier(_payload)
        setLoading(false)

        if (result && exposeStrapiErrorNew(result)) {
            completeLogin()
        }
    }

    const completeLogin = () => {
        localStorage.removeItem('form')
        toast.success('Account created')
        navigate('register/supplier/full-access')
    }

    const init = async () => {
        const companyCategories = await ReadCompanyCategories()
        const businessTypes = await ReadBusinessTypes()
        const certificationsAndApprovals = await ReadCertificationsAndApprovals()
        const supplierProductsSource = await ReadSupplierProductsSource()

        const infos = {
            businessTypes,
            companyCategories,
            certificationsAndApprovals,
            supplierProductsSource,
        }

        setRegisterInfos(infos)
    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        if (step === 1) {
            localStorage.setItem('form', JSON.stringify(form))
        }
    }, [step])

    return (
        <>
            <ContainerUnauthenticated>
                <RegisterHeader title={'Sign Up'} loading={loading} />
                <RegisterStepIndicator steps={steps} current={step + 1} active {...steps[step]} />

                {step === 0 ? (
                    <RegisterSupplierStep1
                        formValue={formValue}
                        changeForm={changeForm}
                        form={form}
                        setForm={setForm}
                        next={next}
                        registerInfos={registerInfos}
                    />
                ) : null}
                {step === 1 ? (
                    <RegisterSupplierStep2
                        formValue={formValue}
                        changeForm={changeForm}
                        form={form}
                        setForm={setForm}
                        next={next}
                        back={back}
                        loading={loading}
                        registerInfos={registerInfos}
                    />
                ) : null}
            </ContainerUnauthenticated>
        </>
    )
}
