import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const ContentSearch = styled.div.attrs({})`
    display: flex;
    background: var(--white-color);
    margin-bottom: 25px;

    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
    box-shadow: 0px 1.5px 6px var(--lightshadow-color);
`
export const SearchInput = styled.input.attrs({})`
    height: 3rem;
    border: none;
    outline: none;
    background: none;
    padding: 0 20px;
    flex: 1;

    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--grey-color);
    &::placeholder {
        color: var(--lightgrey-color);
    }
`
export const SearchIcon = styled.img.attrs({
    src: '/icons/search.svg',
})`
    margin: 0 20px;
    cursor: pointer;
`
