import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const Content = styled.div.attrs({})``

export const HomeActions = styled.div.attrs({})`
    position: sticky;
    position: fixed;
    bottom: 15px;
    right: 70px;
    display: flex;
`
export const ActionItem = styled.div.attrs({})`
    width: 66px;
    height: 66px;
    border-radius: 33px;
    margin: 0 10px;
    background: var(--grey-color);
    ${(props) =>
        props.primary
            ? `
            background: var(--primary-color);
        `
            : ``}

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    &:hover {
        box-shadow: 0px 3px 6px var(--shadow-color);
    }
`
export const ActionItemIcon = styled.img.attrs((props) => ({
    src: `/icons/${props.icon}.svg`,
    width: 25,
}))``
