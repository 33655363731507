import React, { useContext, useState, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import Button from 'components/Dashboard/Button'
import { Load } from 'ui/styled'
import {
    PageTitle,
    PageSubTitle,
    Content,
    Cupom,
    ImgOK,
    CupomBody,
    CupomText,
    CupomButton,
    ImgError,
    CupomError,
} from './styled'

import ContainerAuthenticated from 'containers/Authenticated'
import { CoreContext } from 'context/CoreContext'

import { StripeGetPaymentIntent } from 'services/premium'

export default function BuyComplete() {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const { findlanguage, language } = useContext(CoreContext)

    const queryParams = new URLSearchParams(window.location.search)

    const [response, setResponse] = useState('')
    const [intentData, setIntentData] = useState({})

    useEffect(() => {
        const fetchData = async () => {
            let id = queryParams.get('payment_intent')

            const response_intent = await StripeGetPaymentIntent(id)

            if (response_intent.error) {
                setResponse('error')
                setIntentData({
                    id: id ? id : 'NI',
                    status: 'ERROR',
                    last_payment_error: response_intent.message,
                })
            } else {
                setResponse(response_intent.intent.status)
                setIntentData(response_intent.intent)
            }
        }

        fetchData().catch(console.error)
    }, [])

    const back = () => {
        navigate('dashboard')
    }

    return (
        <>
            {response !== '' ? (
                response === 'succeeded' ? (
                    <ContainerAuthenticated full={true}>
                        <Container fluid={true} style={{ margin: 0, padding: 0 }}>
                            <Row style={{ margin: 0, padding: 0 }}>
                                <Col style={{ margin: 0, padding: 0 }}>
                                    <PageTitle>{findlanguage().paymentComplete}</PageTitle>
                                    <PageSubTitle></PageSubTitle>

                                    <Content
                                        style={{
                                            margin: 0,
                                            backgroundColor: '#fff',
                                            borderTop: '1px solid #dddddd',
                                        }}
                                    >
                                        <Cupom>
                                            <CupomBody>
                                                <ImgOK />
                                                <CupomText>
                                                    {language === 'portugues' ? (
                                                        <div>
                                                            Enviamos um e-mail com todos os detalhes
                                                            da sua assinatura.
                                                            <br />
                                                            <br />
                                                            Bem-vindo ao MTS!
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            We've sent you an email with all the
                                                            details of your subscription!
                                                            <br />
                                                            <br />
                                                            Enjoy MTS!
                                                        </div>
                                                    )}
                                                </CupomText>

                                                <CupomButton>
                                                    <Button
                                                        big={true}
                                                        outline={true}
                                                        onClick={back}
                                                    >
                                                        {language === 'portugues' ? (
                                                            <div>Voltar para Home</div>
                                                        ) : (
                                                            <div>Back to Home</div>
                                                        )}
                                                    </Button>
                                                </CupomButton>
                                            </CupomBody>
                                        </Cupom>
                                    </Content>
                                </Col>
                            </Row>
                        </Container>
                    </ContainerAuthenticated>
                ) : (
                    <ContainerAuthenticated full={true}>
                        <Container fluid={true} style={{ margin: 0, padding: 0 }}>
                            <Row style={{ margin: 0, padding: 0 }}>
                                <Col style={{ margin: 0, padding: 0 }}>
                                    <PageTitle>
                                        {language === 'portugues' ? (
                                            <>Ocorreu um problema com o pagamento</>
                                        ) : (
                                            <>A problem occurred with the payment</>
                                        )}
                                    </PageTitle>
                                    <PageSubTitle></PageSubTitle>

                                    <Content
                                        style={{
                                            margin: 0,
                                            backgroundColor: '#fff',
                                            borderTop: '1px solid #dddddd',
                                        }}
                                    >
                                        <Cupom>
                                            <CupomBody>
                                                <ImgError />
                                                <CupomText>
                                                    {language === 'portugues' ? (
                                                        <div>
                                                            Infelizmente não foi possível <br></br>
                                                            processar o pagamento.
                                                            <br />
                                                            <br />
                                                            Entre em contato com o suporte ou tente
                                                            novamente mais tarde
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            Unfortunately, it was not possible to
                                                            process the payment.
                                                            <br />
                                                            <br />
                                                            Please contact support or try again
                                                            later.
                                                        </div>
                                                    )}
                                                </CupomText>

                                                <CupomError>
                                                    ID: {intentData.id}
                                                    <br />
                                                    Status: {intentData.status}
                                                    {intentData.last_payment_error ? (
                                                        <>
                                                            <br />
                                                            Payment Error:{' '}
                                                            {intentData.last_payment_error}
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </CupomError>

                                                <CupomButton>
                                                    <Button
                                                        big={true}
                                                        outline={true}
                                                        onClick={back}
                                                    >
                                                        {language === 'portugues' ? (
                                                            <div>Voltar para Home</div>
                                                        ) : (
                                                            <div>Back to Home</div>
                                                        )}
                                                    </Button>
                                                </CupomButton>
                                            </CupomBody>
                                        </Cupom>
                                    </Content>
                                </Col>
                            </Row>
                        </Container>
                    </ContainerAuthenticated>
                )
            ) : (
                <Load color="#263640" />
            )}
        </>
    )
}
