import React, { useContext, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap';
import { ContentScroll, ScrollWrapper, PageCard } from './styled';
import ContainerAuthenticated from 'containers/Authenticated';
import ProfileViews from 'components/Dashboard/ProfileViews/index';
import { CoreContext } from 'context/CoreContext'
import { useHistory } from 'react-router-dom'

export default function DashboardProfileViews() {
    const { permissions } = useContext(CoreContext)
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    useEffect(() => {
        if (!permissions?.profileViews?.canView) {
            navigate('dashboard')
        }
    }, [permissions, navigate]);

    return (
        <ContainerAuthenticated>
            <Container fluid={false}>
                <PageCard>
                    <Row>
                        <Col>
                            <ContentScroll>
                                <ProfileViews />
                            </ContentScroll>
                        </Col>
                    </Row>
                </PageCard>
            </Container>
        </ContainerAuthenticated>
    );
}