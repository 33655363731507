import React, { useState, useEffect, useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import { PageCard } from '../../../../components/Dashboard/Panels/SMSControl/styled'
import { GetPrecheck, PutPrecheck } from 'services/precheck'
import { NotificationManagerAdd } from 'services/admin'

import { TitleGreen } from './styled'
import Select from 'react-select'
import { parseStrapiImage } from 'utils'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

export default function BuyerSupplier({ company_id, status, goBack }) {
    const [form, setForm] = useState(true)
    const { findlanguage, setModal, language, user } = useContext(CoreContext)
    const [basicInformation, setBasicInformation] = useState({
        id: -1,
        company_legal_name: '',
        company_address: '',
        company_phone_number: '',
        active_company_proof: '',
        active_company_proof_file: {},
        business_license: '',
        business_license_file: {},
        sanitary_license: '',
        sanitary_license_file: {},
        is_company_certified: false,
        what_standards: '',
        what_standards_file: {},
        suppliers_count: '',
        has_multiple_factories: '',
        factory_count: '',
        document_control: '',
        supplier_control: '',
        supplier_management: '',
        platform_name: '',
        avg_documents: '',
        find_suppliers: [],
        sustainability_practice: '',
        full_name: '',
        email: '',
        direct_phone_number: '',
    })
    const labelCss = { fontWeight: 'bold', fontSize: '15px', color: 'rgb(86, 86, 86)' }
    const labelTextCss = { fontSize: '14px', color: 'rgb(86, 86, 86)' }
    const fixRow = { marginTop: '-22px' }
    const inputCss = { borderColor: '#70707059' }
    const col6 = { fontSize: '700', color: '#707070' }
    const [show, setShow] = useState(false)
    const [reason, setReason] = useState('')
    const [reasonMessage, setReasonMessage] = useState('')

    const showModal = () => setShow(true)
    const hideModal = () => setShow(false)
    const handleSelectChange = (event) => setReason(event.target.value)

    const options = [
        'Missing Requirements',
        'Invalid Document',
        'Incomplete Document',
        'Corrupt Document',
    ]

    const [showReminderModal, setShowReminderModal] = useState(false)
    const [expirationDate, setExpirationDate] = useState('')
    const [reminderDays, setReminderDays] = useState(30) // Default to 30 days

    const showReminder = () => setShowReminderModal(true)
    const hideReminder = () => setShowReminderModal(false)
    const handleDateChange = (event) => setExpirationDate(event.target.value)
    const handleReminderDaysChange = (event) => setReminderDays(event.target.value)

    useEffect(() => {
        const init = async () => {
            const getAdditionalInformation = await GetPrecheck(company_id)

            if (
                getAdditionalInformation[0]?.find_supplier !== undefined &&
                getAdditionalInformation[0]?.find_supplier !== null
            )
                getAdditionalInformation[0].find_suppliers = JSON.parse(
                    getAdditionalInformation[0]?.find_suppliers
                )?.map((value) => ({ label: value, value }))

            if (
                getAdditionalInformation.statusText !== 'Not Found' &&
                getAdditionalInformation?.length !== 0
            ) {
                if (getAdditionalInformation[0].status === 'submitted') setForm(true)

                setBasicInformation(getAdditionalInformation[0])
            }
        }
        init()
    }, [])

    const changeQuestionaire = (value, field) => {}

    const submit = async () => {
        setBasicInformation({
            ...basicInformation,
            expiration_date: expirationDate,
            reminder_days: reminderDays,
            status: 'approved',
        })

        await PutPrecheck({
            ...basicInformation,
            expiration_date: expirationDate,
            reminder_days: reminderDays,
            status: 'approved',
        })

        await NotificationManagerAdd('mfs_approved', company_id, user.name, user, 'full')

        goBack()

        return setModal({
            type: 'alert',
            text: 'MyTS form approved.',
            warning: true,
        })
    }

    const decline = async () => {
        setBasicInformation({
            ...basicInformation,
            reason_denied: reason,
            reason_denied_message: reasonMessage,
            status: 'decline',
        })

        await PutPrecheck({
            ...basicInformation,
            reason_denied: reason,
            reason_denied_message: reasonMessage,
            status: 'decline',
        })

        await NotificationManagerAdd('mfs_declined', company_id, user.name, user, 'full')

        goBack()

        return setModal({
            type: 'alert',
            text: 'Declined.',
            warning: true,
        })
    }

    const downloadPdfDocument = () => {
        const input = document.getElementById('form-precheck')

        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png')
                const pdf = new jsPDF({
                    orientation: 'portrait',
                    unit: 'px',
                    format: [canvas.width, canvas.height],
                })
                pdf.addImage(imgData, 'PNG', 0, 0)
                pdf.save('download.pdf')
            })
            .catch((err) => {
                console.error('Error generating PDF', err)
            })
    }

    return (
        <PageCard id="form-precheck">
            <TitleGreen>
                {findlanguage().basicPreCheck}
                <div style={{ float: 'right', marginTop: '-27px', position: 'sticky' }}>
                    <button
                        className="btn btn-secondary"
                        style={{ marginRight: '5px', marginTop: '15px' }}
                        onClick={downloadPdfDocument}
                    >
                        Export PDF
                    </button>
                    <button
                        className="btn btn-secondary"
                        style={{ marginRight: '5px', marginTop: '15px' }}
                        onClick={() => goBack()}
                    >
                        {findlanguage().goBack}
                    </button>
                    {status === 'submitted' ? (
                        <>
                            <button
                                className="btn"
                                onClick={showModal}
                                style={{
                                    marginRight: '5px',
                                    marginTop: '15px',
                                    backgroundColor: '#ff4f4f',
                                    color: 'white',
                                }}
                            >
                                {findlanguage().reasonBuyerDenied}
                            </button>
                            <button
                                className="btn"
                                onClick={showReminder}
                                style={{
                                    marginRight: '5px',
                                    marginTop: '15px',
                                    backgroundColor: '#199b2f',
                                    color: 'white',
                                }}
                            >
                                {findlanguage().submit}
                            </button>
                        </>
                    ) : null}
                </div>
            </TitleGreen>
            <div className="row  mb-3" style={{ borderBottom: '1px solid #7070700f' }}>
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().companyLegalName}
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        style={inputCss}
                        value={basicInformation.company_legal_name}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'company_legal_name')
                        }
                        disabled={form}
                    />
                </div>
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().companyAddress}
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        style={inputCss}
                        value={basicInformation.company_address}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'company_address')
                        }
                        disabled={form}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-6 col-md-4" style={col6}>
                    <label className="form-label" style={labelCss} htmlFor="company_phone_number">
                        {findlanguage().companyPhoneNumber}
                    </label>
                    <input
                        id="company_phone_number"
                        type="text"
                        className="form-control"
                        value={basicInformation.company_phone_number}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'company_phone_number')
                        }
                        disabled={form}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().activeCompanyProof}
                    </label>
                    <br />
                    <label className="form-label" style={labelTextCss}>
                        {findlanguage().proofCompanyActiveRequirement}
                    </label>
                </div>
            </div>
            <div className="row mb-3" style={fixRow}>
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelTextCss}></label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={basicInformation.active_company_proof}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'active_company_proof')
                        }
                        disabled={form}
                    >
                        {language === 'portugues' ? (
                            <>
                                <option value=""></option>
                                <option value="CNPJ">CNPJ</option>
                                <option value="CPF">CPF</option>
                            </>
                        ) : (
                            <>
                                <option value=""></option>
                                <option value="EIN Number">EIN Number</option>
                                <option value="Tax ID">Tax ID</option>
                                <option value="DUNS Number">DUNS Number</option>
                                <option value="Driver License Number">Driver License Number</option>
                            </>
                        )}
                    </select>
                </div>
                <div className="col-6" style={col6}>
                    <FileUploader file={basicInformation.active_company_proof_file} />
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().businessLicense}
                    </label>
                    <br />
                    <label className="form-label" style={labelTextCss}>
                        {findlanguage().validOperatingLicenseQuestion}
                    </label>
                </div>
            </div>
            <div className="row mb-3" style={fixRow}>
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelTextCss}></label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={basicInformation.business_license}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'business_license')
                        }
                        disabled={form}
                    >
                        <option value=""></option>
                        <option value={findlanguage().no}>{findlanguage().no}</option>
                        <option value={findlanguage().yes}>{findlanguage().yes}</option>
                        <option value="N/A">N/A</option>
                    </select>
                </div>
                <div className="col-6" style={col6}>
                    <FileUploader file={basicInformation.business_license_file} />
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().sanitaryLicense}
                    </label>
                    <br />
                    <label className="form-label" style={labelTextCss}>
                        {findlanguage().healthPermitRequirement}
                    </label>
                </div>
            </div>
            <div className="row  mb-3" style={fixRow}>
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelTextCss}></label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={basicInformation.sanitary_license}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'sanitary_license')
                        }
                        disabled={form}
                    >
                        <option value=""></option>
                        <option value={findlanguage().no}>{findlanguage().no}</option>
                        <option value={findlanguage().yes}>{findlanguage().yes}</option>
                        <option value="N/A">N/A</option>
                    </select>
                </div>
                <div className="col-6" style={col6}>
                    <FileUploader file={basicInformation.sanitary_license_file} />
                </div>
            </div>
            <div className="row  mb-3">
                <div className="col-4" style={col6}>
                    <label className="form-label" style={labelTextCss}>
                        {findlanguage().companyCertifiedQuestion}
                    </label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        value={basicInformation.is_company_certified}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'is_company_certified')
                        }
                        disabled={form}
                    >
                        <option value="false">{findlanguage().no}</option>
                        <option value="true">{findlanguage().yes}</option>
                    </select>
                </div>
                {basicInformation.is_company_certified ? (
                    <>
                        <div className="col-4" style={col6}>
                            <label className="form-label" style={labelTextCss}>
                                {findlanguage().includeStandards}
                            </label>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                value={basicInformation.what_standards}
                                onChange={(event) =>
                                    changeQuestionaire(event.target.value, 'what_standards')
                                }
                                disabled={form}
                            >
                                <option value=""></option>
                                <option value={findlanguage().regulatoryOption}>
                                    {findlanguage().regulatoryOption}
                                </option>
                                <option value={findlanguage().gfsiRecognizedStandardsOption}>
                                    {findlanguage().gfsiRecognizedStandardsOption}
                                </option>
                                <option value={findlanguage().socialOption}>
                                    {findlanguage().socialOption}
                                </option>
                                <option value={findlanguage().environmentalOption}>
                                    {findlanguage().environmentalOption}
                                </option>
                                <option value={findlanguage().otherOption}>
                                    {findlanguage().otherOption}
                                </option>
                            </select>
                        </div>

                        <div className="col-4" style={col6}>
                            <MultipleFileUploader file={basicInformation.what_standards_file} />
                        </div>
                    </>
                ) : null}
            </div>
            <div className="row mb-3">
                <div className="col-4" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().supplierCountQuestion}
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        style={inputCss}
                        value={basicInformation.suppliers_count}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'suppliers_count')
                        }
                        disabled={form}
                    />
                </div>
                <div className="col-4" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().multipleFactoriesQuestion}
                    </label>
                    <select
                        className="form-select"
                        aria-label="More than one factory"
                        value={basicInformation.has_multiple_factories}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'has_multiple_factories')
                        }
                        disabled={form}
                    >
                        <option value={findlanguage().no}>{findlanguage().no}</option>
                        <option value={findlanguage().yes}>{findlanguage().yes}</option>
                    </select>
                </div>
                {basicInformation.has_multiple_factories === findlanguage().yes ? (
                    <div className="col-4" style={col6}>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().factoryCountQuestion}
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            style={inputCss}
                            value={basicInformation.factory_count}
                            onChange={(event) =>
                                changeQuestionaire(event.target.value, 'factory_count')
                            }
                            disabled={form}
                        />
                    </div>
                ) : null}
            </div>
            <div className="row mb-3">
                <div className="col-4" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().internalDocsControlQuestion}
                    </label>
                    <select
                        className="form-select"
                        value={basicInformation.document_control}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'document_control')
                        }
                        disabled={form}
                    >
                        <option value=""></option>
                        <option value={findlanguage().spreadsheetOption}>
                            {findlanguage().spreadsheetOption}
                        </option>
                        <option value={findlanguage().ownSystemOption}>
                            {findlanguage().ownSystemOption}
                        </option>
                        <option value={findlanguage().thirdPartySystemOption}>
                            {findlanguage().thirdPartySystemOption}
                        </option>
                        <option value={findlanguage().othersOption}>
                            {findlanguage().othersOption}
                        </option>
                        <option value={findlanguage().noControlOption}>
                            {findlanguage().noControlOption}
                        </option>
                    </select>
                </div>
                <div className="col-4" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().supplierControlQuestion}
                    </label>
                    <select
                        className="form-select"
                        value={basicInformation.supplier_control}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'supplier_control')
                        }
                        disabled={form}
                    >
                        <option value=""></option>
                        <option value={findlanguage().spreadsheetOption}>
                            {findlanguage().spreadsheetOption}
                        </option>
                        <option value={findlanguage().ownSystemOption}>
                            {findlanguage().ownSystemOption}
                        </option>
                        <option value={findlanguage().thirdPartySystemOption}>
                            {findlanguage().thirdPartySystemOption}
                        </option>
                        <option value={findlanguage().othersOption}>
                            {findlanguage().othersOption}
                        </option>
                        <option value={findlanguage().noControlOption}>
                            {findlanguage().noControlOption}
                        </option>
                    </select>
                </div>
                {basicInformation.supplier_control === findlanguage().noControlOption ? null : (
                    <div className="col-4" style={col6}>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().supplierManagementQuestion}
                        </label>
                        <select
                            className="form-select"
                            value={basicInformation.supplier_management}
                            onChange={(event) =>
                                changeQuestionaire(event.target.value, 'supplier_management')
                            }
                            disabled={form}
                        >
                            <option value=""></option>
                            <option value={findlanguage().throughDocumentationOption}>
                                {findlanguage().throughDocumentationOption}
                            </option>
                            <option value={findlanguage().through2ndPartyAuditOption}>
                                {findlanguage().through2ndPartyAuditOption}
                            </option>
                            <option value={findlanguage().throughDocumentationAndAuditingOption}>
                                {findlanguage().throughDocumentationAndAuditingOption}
                            </option>
                        </select>
                    </div>
                )}
            </div>
            <div className="row mb-3">
                {basicInformation.supplier_control === findlanguage().thirdPartySystemOption ||
                basicInformation.thirdPartySystemOption === findlanguage().othersOption ? null : (
                    <div className="col-4" style={col6}>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().platformNameQuestion}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={basicInformation.platform_name}
                            onChange={(event) =>
                                changeQuestionaire(event.target.value, 'platform_name')
                            }
                            disabled={form}
                        />
                    </div>
                )}
                <div className="col-4" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().averageDocumentsQuestion}
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        style={inputCss}
                        value={basicInformation.avg_documents}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'avg_documents')
                        }
                        disabled={form}
                    />
                </div>
                <div className="col-4" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().findNewSuppliersBuyersQuestion}
                    </label>
                    <Select
                        isMulti
                        options={[
                            { label: 'Google', value: 'Google' },
                            {
                                label: findlanguage().recommendations,
                                value: findlanguage().recommendations,
                            },
                            { label: 'LinkedIn', value: 'LinkedIn' },
                            { label: findlanguage().other, value: findlanguage().other },
                        ]}
                        value={basicInformation.find_suppliers}
                        onChange={(values) => changeQuestionaire(values, 'find_suppliers')}
                        isDisabled={form}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().sustainabilityPracticeQuestion}
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        style={inputCss}
                        value={basicInformation.sustainability_practice}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'sustainability_practice')
                        }
                        disabled={form}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().responsibleToFillPreCheck}
                    </label>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        {findlanguage().fullName}
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        style={inputCss}
                        value={basicInformation.full_name}
                        onChange={(event) => changeQuestionaire(event.target.value, 'full_name')}
                        disabled={form}
                    />
                </div>
                <div className="col-6" style={col6}>
                    <label className="form-label" style={labelCss}>
                        Email
                    </label>
                    <input
                        type="email"
                        className="form-control"
                        style={inputCss}
                        value={basicInformation.email}
                        onChange={(event) => changeQuestionaire(event.target.value, 'email')}
                        disabled={form}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-6 col-md-4" style={col6}>
                    <label className="form-label" style={labelCss} htmlFor="direct_phone_number">
                        {findlanguage().directPhoneNumber}
                    </label>
                    <input
                        id="direct_phone_number"
                        type="text"
                        className="form-control"
                        value={basicInformation.direct_phone_number}
                        onChange={(event) =>
                            changeQuestionaire(event.target.value, 'direct_phone_number')
                        }
                        disabled={form}
                    />
                </div>
            </div>
            <div
                className={`modal fade ${show ? 'show' : ''}`}
                style={{ display: show ? 'block' : 'none' }}
                tabIndex="-1"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Review Pre-check</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={hideModal}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="reasonSelect">Choose a reason</label>
                                    <select
                                        className="form-control"
                                        id="reasonSelect"
                                        value={reason}
                                        onChange={handleSelectChange}
                                    >
                                        {options.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="responseText">
                                        Type a response to the supplier
                                    </label>
                                    <textarea
                                        className="form-control"
                                        id="responseText"
                                        rows="3"
                                        onChange={(e) => setReasonMessage(e.target.value)}
                                    ></textarea>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={decline}>
                                Decline
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {show && <div className="modal-backdrop fade show"></div>}
            <div
                className={`modal fade ${showReminderModal ? 'show' : ''}`}
                style={{ display: showReminderModal ? 'block' : 'none' }}
                tabIndex="-1"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Choose a expiration date</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={hideReminder}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={expirationDate}
                                        onChange={handleDateChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="reminderDays">
                                        Send reminder to this company starting in{' '}
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="reminderDays"
                                        value={reminderDays}
                                        onChange={handleReminderDaysChange}
                                        min="1"
                                    />
                                    <small>days before expiration</small>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={submit}>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {showReminderModal && <div className="modal-backdrop fade show"></div>}
        </PageCard>
    )
}

const FileUploader = ({ file }) => {
    return (
        <>
            {file === undefined || file === null ? null : (
                <a
                    style={{
                        fontSize: '13px',
                        color: 'white',
                        height: '35px',
                        padding: '10px',
                        marginLeft: '10px',
                        marginTop: '25px',
                        width: '100%',
                        backgroundColor: '#263640',
                    }}
                    className="badge"
                    href={parseStrapiImage(file?.url)}
                    download={parseStrapiImage(file?.url)}
                >
                    Download {file?.name}
                </a>
            )}
        </>
    )
}

const MultipleFileUploader = ({ file }) => {
    return (
        <>
            {file === undefined || file === null || file?.length === 0
                ? null
                : file?.map((y) => (
                      <a
                          style={{
                              fontSize: '13px',
                              color: 'white',
                              height: '35px',
                              padding: '10px',
                              marginLeft: '10px',
                              marginTop: '25px',
                              width: '100%',
                              backgroundColor: '#263640',
                          }}
                          className="badge"
                          href={parseStrapiImage(y?.url)}
                          download={parseStrapiImage(y?.url)}
                      >
                          Download {y?.name}
                      </a>
                  ))}
        </>
    )
}
