import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const PageCard = styled.div.attrs({})`
    background: var(--white-color);
    padding: 24px;
    margin-top: 28px;
    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
`
export const CardHeader = styled.div.attrs({})`
    padding: 20px 0;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--primary-color);
    border-bottom: 1px solid var(--lightshadow-color);
`

export const CardLineContainer = styled.div.attrs({})`
    display: flex;
`

export const CardLine = styled.div.attrs({})`
    display: flex;
    padding: 20px 0;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--lightshadow-color);

    ${(props) =>
        props.mobileHeader
            ? `
            display: none;
        `
            : ``}
    @media(max-width: ${mobileSize}px) {
        flex-direction: column;
        gap: 20px 0;
        flex: 1;
        ${(props) =>
            props.header
                ? `
                display: none;
            `
                : ``}
        ${(props) =>
            props.mobileHeader
                ? `
                display: flex;
                padding: 0 10px;
                flex:0;
                align-items: flex-start;
            `
                : ``}
    }
`
export const CardLineText = styled.div.attrs({})`
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--grey-color);

    ${(props) =>
        props.grey
            ? `
            color: var(--lightgrey-color);
        `
            : ``}
`

export const CardLineAction = styled.div.attrs({})`
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: var(--grey-color);
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    &:hover {
        box-shadow: 0px 1.5px 6px var(--shadow-color);
    }
`
export const CardLineActionIcon = styled.img.attrs({
    src: '/icons/chatsent.svg',
    width: 16,
})``

export const CardLineActionSpacer = styled.div.attrs({})`
    min-width: 40px;
    flex: 1;
`

export const CardLineColumn = styled.div.attrs({})`
    ${(props) =>
        props.last
            ? ``
            : `
            flex:1;
        `}
    @media(max-width: ${mobileSize}px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`

export const PageCardActions = styled.div.attrs({})`
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
`

export const CardFormStatus = styled.div.attrs({})`
    width: 18px;
    height: 18px;
    border-radius: 9px;
    background-color: ${(props) => props.color};
`

export const MfsGreenCertificate = styled.img.attrs({
    src: '/icons/certificate-mfs.svg',
    width: 54,
})``

export const MfsGrayCertificate = styled.img.attrs({
    src: '/icons/certificate-mfs-gray.svg',
    width: 54,
})``

export const ContentFilters = styled.div.attrs({})`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const FilterContent = styled.div.attrs({})`
    display: flex;
    align-items: center;
`
