import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'

import Card from '../Card/index.js'

import { PageCard, CardHeader, CardHeaderTitleContainer } from './styled'
import _ from 'lodash'

export default function SelfAssessmentChecklist({
    certifications,
    onChangeCertifications,
    saveCertifications,
    saveDocument,
    form,
    returnHeaders,
    mfsQuestionarie
}) {
    const { findlanguageEsg } = useContext(CoreContext) 

    //#region G.1.2
    const g12 = {
        ref: 'G.1.2',
        requirementTitle: findlanguageEsg().g12RequirementTitle,
        inputForYesAnswer: true,
        placeHolderTextYes: findlanguageEsg().g12PlaceHolderTextYes,
        inputForNoAnswer: false,
        placeHolderTextNo: findlanguageEsg().g12PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    //#region G.1.2 Yes

    //#region G.1.2.Y.A

    const g12ya = {
        ref: 'G.1.2.Y.A',
        requirementTitle: findlanguageEsg().g12yaRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g12yaPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const g12yay = {
        ref: 'G.1.2.Y.A.Y',
        requirementTitle: findlanguageEsg().g12yayRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g12yayPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const g12yayy = {
        ref: 'G.1.2.Y.A.Y.Y',
        requirementTitle: findlanguageEsg().g12yayyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g12yayyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
    }

    const g12yayyql1 = {
        ref: 'G.1.2.Y.A.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().g12yayyqlRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g12yayyqlPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        fileForYesAnswerAndNoInput: true,
        color: '#2f8c5d',
        question: true,
    }

    //#endregion

    //#region G.1.2.Y.B

    const g12yb = {
        ref: 'G.1.2.Y.B',
        requirementTitle: findlanguageEsg().g12ybRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g12ybPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const g12yby = {
        ref: 'G.1.2.Y.B.Y',
        requirementTitle: findlanguageEsg().g12ybyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: findlanguageEsg().g12ybyPlaceHolderTextYes,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g12ybyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const g12ybyyqt1 = {
        ref: 'G.1.2.Y.B.Y.Y.QT.1',
        requirementTitle: findlanguageEsg().g12ybyyqt1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const g12ybyyqt2 = {
        ref: 'G.1.2.Y.B.Y.Y.QT.2',
        requirementTitle: findlanguageEsg().g12ybyyqt2RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const g12ybyyqtn = {
        ref: 'G.1.2.Y.B.Y.Y.QT.N',
        requirementTitle: findlanguageEsg().g12ybyyqtnRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const g12ybyyql1 = {
        ref: 'G.1.2.Y.B.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().g12ybyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g12ybyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.C

    const g12yc = {
        ref: 'G.1.2.Y.C',
        requirementTitle: findlanguageEsg().g12ycRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g12ycPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const g12ycy = {
        ref: 'G.1.2.Y.C.Y',
        requirementTitle: findlanguageEsg().g12ycyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g12ycyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const g12ycyy = {
        ref: 'G.1.2.Y.C.Y.Y',
        requirementTitle: findlanguageEsg().g12ycyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g12ycyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const g12ycyyql1 = {
        ref: 'G.1.2.Y.C.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().g12ycyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g12ycyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.D

    const g12yd = {
        ref: 'G.1.2.Y.D',
        requirementTitle: findlanguageEsg().g12ydRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g12ydPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const g12ydy = {
        ref: 'G.1.2.Y.D.Y',
        requirementTitle: findlanguageEsg().g12ydyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g12ydyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const g12ydyy = {
        ref: 'G.1.2.Y.D.Y.Y',
        requirementTitle: findlanguageEsg().g12ydyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g12ydyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const g12ydyyql1 = {
        ref: 'G.1.2.Y.D.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().g12ydyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g12ydyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#endregion

    //#region A.1.1 NO
    const g12na = {
        ref: 'G.1.2.N.A',
        requirementTitle: findlanguageEsg().g12naRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g12naPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const g12nb = {
        ref: 'G.1.2.N.B',
        requirementTitle: findlanguageEsg().g12nbRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g12nbPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const g12nc = {
        ref: 'G.1.2.N.C',
        requirementTitle: findlanguageEsg().g12ncRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g12ncPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    const returnCondition = (item, layers, category1, category2, category3, category4) => {
        switch (layers) {
            case 0:
                return (
                    <Card
                        item={item}
                        onChangeCertifications={onChangeCertifications}
                        saveCertifications={saveCertifications}
                        saveDocument={saveDocument}
                        certifications={certifications}
                        form={form}
                        mfsQuestionarie={mfsQuestionarie}
                    />
                )
                break
            case 1:
                if (certifications?.find((x) => x.category === category1)?.next_level === true)
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 2:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 3:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 4:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true &&
                    certifications?.find((x) => x.category === category4)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            default:
                break

        }
    }

    return (
        <>
            <PageCard>
                <CardHeader>
                    <CardHeaderTitleContainer>
                        {findlanguageEsg().g12CardHeaderTitleContainer}
                    </CardHeaderTitleContainer>
                </CardHeader>
                {returnHeaders()}
                {returnCondition(g12, 0)}
            </PageCard>
            {
                mfsQuestionarie !== '1' ?
                    <>

                        {certifications?.find((x) => x.category === 'G.1.2')?.next_level === true ? (
                            <>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().g12aCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(g12ya, 1, 'G.1.2')}
                                    {returnCondition(g12yay, 2, 'G.1.2', 'G.1.2.Y.A')}
                                    {returnCondition(g12yayy, 3, 'G.1.2', 'G.1.2.Y.A', 'G.1.2.Y.A.Y')}
                                    {returnCondition(
                                        g12yayyql1,
                                        4,
                                        'G.1.2',
                                        'G.1.2.Y.A',
                                        'G.1.2.Y.A.Y',
                                        'G.1.2.Y.A.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().g12bCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(g12yb, 1, 'G.1.2')}
                                    {returnCondition(g12yby, 2, 'G.1.2', 'G.1.2.Y.B')}
                                    {returnCondition(g12ybyyqt1, 3, 'G.1.2', 'G.1.2.Y.B', 'G.1.2.Y.B.Y')}
                                    {returnCondition(g12ybyyqt2, 3, 'G.1.2', 'G.1.2.Y.B', 'G.1.2.Y.B.Y')}
                                    {returnCondition(g12ybyyqtn, 3, 'G.1.2', 'G.1.2.Y.B', 'G.1.2.Y.B.Y')}
                                    {returnCondition(g12ybyyql1, 3, 'G.1.2', 'G.1.2.Y.B', 'G.1.2.Y.B.Y')}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().g12cCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(g12yc, 1, 'G.1.2')}
                                    {returnCondition(g12ycy, 2, 'G.1.2', 'G.1.2.Y.C')}
                                    {returnCondition(g12ycyy, 3, 'G.1.2', 'G.1.2.Y.C', 'G.1.2.Y.C.Y')}
                                    {returnCondition(
                                        g12ycyyql1,
                                        4,
                                        'G.1.2',
                                        'G.1.2.Y.C',
                                        'G.1.2.Y.C.Y',
                                        'G.1.2.Y.C.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().g12dCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(g12yd, 1, 'G.1.2')}
                                    {returnCondition(g12ydy, 2, 'G.1.2', 'G.1.2.Y.D')}
                                    {returnCondition(g12ydyy, 3, 'G.1.2', 'G.1.2.Y.D', 'G.1.2.Y.D.Y')}
                                    {returnCondition(
                                        g12ydyyql1,
                                        4,
                                        'G.1.2',
                                        'G.1.2.Y.D',
                                        'G.1.2.Y.D.Y',
                                        'G.1.2.Y.D.Y.Y'
                                    )}
                                </PageCard>
                            </>
                        ) : null}
                        {certifications?.find((x) => x.category === 'G.1.2')?.next_level === false ? (
                            <PageCard>
                                <CardHeader>
                                    <CardHeaderTitleContainer>
                                        {findlanguageEsg().g12noCardHeaderTitleContainer}
                                    </CardHeaderTitleContainer>
                                </CardHeader>
                                {returnHeaders()}
                                {returnCondition(g12na, 0)}
                                {returnCondition(g12nb, 0)}
                                {returnCondition(g12nc, 0)}
                            </PageCard>
                        ) : null}
                    </>
                    :
                    null
            }
        </>
    )
}
