import React, { useEffect, useState } from 'react'
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    IconButton,
    Menu,
    MenuItem,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { FindAllESG } from 'services/backoffice'
import DashboardTabESG from '../../../components/Dashboard/Sms/MyValidationProcess/Esg/Questionarie/index'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    container: {
        margin: '20px',
        overflowY: 'auto',
    },
})

const ESG = () => {
    const classes = useStyles()
    const [data, setData] = useState([])
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [anchorEl, setAnchorEl] = useState(null)
    const [currentRow, setCurrentRow] = useState(null)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [showReview, setShowReview] = useState(false)

    const fetchUsers = async () => {
        const response = await FindAllESG()
        const mappedData = response.map((item) => ({
            id: item.id,
            logo: item.avatar_url ? item.avatar_url : '/images/no-image.png',
            companyName: item.legal_company_name,
            accountType:
                item.role === 3 ? 'Buyer' : item.role === 4 ? 'Supplier' : 'Service Provider',
            companyType: item.category,
            createdAt: item.created_at,
            approvedOn: item.approved_on,
            status: item.form_status_esg,
            company_id: item.company_id,
            type_of_form: item.type_of_form,
        }))
        setData(mappedData)
        setTotal(mappedData.length)
    }

    useEffect(() => {
        fetchUsers()
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleAction = async (action) => {
        if (action === 'review') {
            setShowReview(true)
        } else if (action === 'sendReminder') {
        } else if (action === 'delete') {
        }
    }

    const goBackToGrid = () => {
        fetchUsers()
        setShowReview(false)
    }

    return (
        <>
            <br />
            {showReview ? (
                <DashboardTabESG
                    setMfsQuestionarie={() => {}}
                    mfsQuestionarie={currentRow.type_of_form === 'basic' ? '1' : ''}
                    backOffice={true}
                    goBack={goBackToGrid}
                    company_id={currentRow.company_id}
                />
            ) : (
                <TableContainer component={Paper} className="table container">
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Logo</TableCell>
                                <TableCell>Company Name</TableCell>
                                <TableCell>Account Type</TableCell>
                                <TableCell>Company Type</TableCell>
                                <TableCell>Created At</TableCell>
                                <TableCell>MyTS Approved</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>
                                            <img
                                                src={row.logo}
                                                alt="Logo"
                                                style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    borderRadius: '50%',
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>{row.companyName}</TableCell>
                                        <TableCell>{row.accountType}</TableCell>
                                        <TableCell>{row.companyType}</TableCell>
                                        <TableCell>{row.createdAt}</TableCell>
                                        <TableCell>{row.approvedOn}</TableCell>
                                        <TableCell>
                                            {row?.type_of_form !== null
                                                ? row?.type_of_form?.charAt(0).toUpperCase() +
                                                  row?.type_of_form?.slice(1)
                                                : ''}
                                        </TableCell>
                                        <TableCell>
                                            <span
                                                className={`badge px-3 py-2 ${
                                                    row.status === 'open'
                                                        ? 'bg-primary'
                                                        : row.status === 'pending'
                                                        ? 'bg-warning'
                                                        : 'bg-success'
                                                }`}
                                            >
                                                {row?.status?.charAt(0).toUpperCase() +
                                                    row?.status.slice(1)}
                                            </span>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                aria-label="more"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                onClick={(event) => {
                                                    setAnchorEl(event.currentTarget)
                                                    setCurrentRow(row)
                                                }}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                id="long-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={() => setAnchorEl(null)}
                                            >
                                                <MenuItem
                                                    onClick={() => {
                                                        handleAction('review')
                                                        setAnchorEl(null)
                                                    }}
                                                >
                                                    Review
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => {
                                                        handleAction('sendReminder')
                                                        setAnchorEl(null)
                                                    }}
                                                >
                                                    Send Reminder
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => {
                                                        handleAction('delete')
                                                        setAnchorEl(null)
                                                    }}
                                                >
                                                    Delete
                                                </MenuItem>
                                            </Menu>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableContainer>
            )}
        </>
    )
}

export default ESG
