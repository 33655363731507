import React, { useContext, useEffect, useState } from 'react'
import Forms from 'components/Dashboard/Sms/MyBuyers/Requirements/Forms/index'
import { CoreContext } from 'context/CoreContext'

import { CardFormStatus, CardDocument } from './styled'

export default function Requirements({ usersWithForms, saveForm }) {
    const { findlanguage, language, permissions } = useContext(CoreContext)
    const cssTable = { fontSize: '15px', fontWeight: '700', color: '#646464' }
    const cssTableInLineFlex = {
        fontSize: '15px',
        fontWeight: '700',
        color: '#646464',
        display: 'flex',
    }
    const biggerButton = { fontSize: '13px', cursor: 'pointer' }
    const [form, setForm] = useState({ id: -1 })
    const biggerButtonGreen = { fontSize: '13px', cursor: 'pointer', backgroundColor: '#8dbd64' }

    const editForm = (form) => {
        setForm(form)
    }

    const goBack = () => {
        setForm({ id: -1 })
    }

    function translate(reason) {
        switch (language) {
            case 'portugues':
                switch (reason) {
                    case 'Incomplete Document':
                        return 'Documento incompleto'
                    case 'Missing requirements':
                        return 'Requisitos em falta'
                    case 'Invalid Document':
                        return 'Documento inválido'
                    case 'Corrupt Document':
                        return 'Documento corrompido'
                    default:
                        return 'Translation not found'
                }
            case 'english':
                switch (reason) {
                    case 'Incomplete Document':
                        return 'Incomplete Document'
                    case 'Missing requirements':
                        return 'Missing requirements'
                    case 'Invalid Document':
                        return 'Invalid Document'
                    case 'Corrupt Document':
                        return 'Corrupt Document'
                    default:
                        return 'Translation not found'
                }
            default:
                return reason
        }
    }

    function convertToUTC(dateString) {
        if (!dateString) {
            return ''
        }

        const date = new Date(dateString)
        const day = date.getUTCDate()
        const month = date.getUTCMonth() + 1
        const year = date.getUTCFullYear()

        const formattedDay = String(day).padStart(2, '0')
        const formattedMonth = String(month).padStart(2, '0')
        const formattedYear = String(year)

        const formattedDate = `${formattedDay}/${formattedMonth}/${formattedYear}`

        return formattedDate
    }

    const formatDate = (dateString) => {
        if (dateString === null || dateString === undefined) {
            return '-'
        }

        const date = new Date(dateString)

        if (language === 'portugues') {
            // Date format: dd/mm/yyyy
            const day = date.getUTCDate().toString().padStart(2, '0')
            const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
            const year = date.getUTCFullYear()
            return `${day}/${month}/${year}`
        } else {
            // Date format: mm/dd/yyyy
            const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
            const day = date.getUTCDate().toString().padStart(2, '0')
            const year = date.getUTCFullYear()
            return `${month}/${day}/${year}`
        }
    }

    return (
        <>
            <table className="table" style={{ display: form.id !== -1 ? 'none' : '' }}>
                <thead style={{ backgroundColor: '#4f6b7d', color: '#f4fff7' }}>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">{findlanguage().typeOfRequirements}</th>
                        <th scope="col">{findlanguage().products}</th>
                        <th scope="col">{findlanguage().condition}</th>
                        <th scope="col">{findlanguage().responsibleApproval}</th>
                        <th scope="col">{findlanguage().approvalDate}</th>
                        <th scope="col">{findlanguage().expirationDate}</th>
                    </tr>
                </thead>
                <tbody className="table-group-divider" style={{ borderTopColor: '#263640' }}>
                    {usersWithForms?.form
                        ?.filter((x) => x.status !== 'Obsolete')
                        .map((x) => {
                            let datesLeft = null

                            if (x.acceptedDate !== null) {
                                const dateString = x.expirationDate
                                const dateParts = dateString?.split('-')

                                const date2 = dateParts
                                    ? new Date(
                                          Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2])
                                      )
                                    : ''

                                const now = new Date()
                                const todayUTC = new Date(
                                    Date.UTC(
                                        now.getUTCFullYear(),
                                        now.getUTCMonth(),
                                        now.getUTCDate()
                                    )
                                )
                                const diffTime = date2 - todayUTC
                                datesLeft = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
                            }

                            return (
                                <tr key={x.id}>
                                    <td style={cssTable}>
                                        {
                                            permissions?.myBuyers?.canEdit ? 
                                                <>
                                                    {x.status === 'Condition' ? (
                                                        <div>
                                                            <span
                                                                onClick={() => editForm(x)}
                                                                style={biggerButtonGreen}
                                                                className="badge"
                                                            >
                                                                {findlanguage().edit}
                                                            </span>
                                                        </div>
                                                    ) : null}
                                                    {x.status === 'Accepted' ? (
                                                        <span
                                                            onClick={() => editForm(x)}
                                                            style={biggerButton}
                                                            className="badge bg-secondary"
                                                        >
                                                            {findlanguage().view}
                                                        </span>
                                                    ) : null}
                                                    {x.status === 'Pending' ? (
                                                        <span
                                                            onClick={() => editForm(x)}
                                                            style={biggerButton}
                                                            className="badge bg-secondary"
                                                        >
                                                            {findlanguage().view}
                                                        </span>
                                                    ) : null}
                                                    {x.status === 'Denied' ? (
                                                        <span
                                                            onClick={() => editForm(x)}
                                                            style={biggerButton}
                                                            className="badge bg-secondary"
                                                        >
                                                            {findlanguage().review}
                                                        </span>
                                                    ) : null}
                                                </>
                                                :
                                                <span
                                                    onClick={() => editForm(x)}
                                                    style={biggerButton}
                                                    className="badge bg-secondary"
                                                >
                                                    {findlanguage().view}
                                                </span>
                                        }
                                    </td>
                                    <td style={cssTable}>{x.name}</td>
                                    <td style={cssTable}>{x.products}</td>
                                    <td
                                        style={
                                            x.status === 'Denied' ||
                                            (x.status === 'Accepted' &&
                                                datesLeft !== null &&
                                                datesLeft < 30)
                                                ? cssTableInLineFlex
                                                : cssTable
                                        }
                                    >
                                        {x.status === 'Condition' ? (
                                            <CardFormStatus
                                                color="gray"
                                                className="tooltip_element"
                                            >
                                                <span className="tooltip">
                                                    {findlanguage().conditional}
                                                </span>
                                            </CardFormStatus>
                                        ) : null}
                                        {x.status === 'Accepted' ? (
                                            <>
                                                <CardFormStatus
                                                    color={
                                                        datesLeft !== null && datesLeft < 1
                                                            ? '#dc3545'
                                                            : '#8dbd64'
                                                    }
                                                    className="tooltip_element"
                                                >
                                                    <span className="tooltip">
                                                        {datesLeft <= 0
                                                            ? findlanguage().isExpired
                                                            : findlanguage().accepted}
                                                    </span>
                                                </CardFormStatus>
                                                <>
                                                    {datesLeft <= 0 ? (
                                                        <span
                                                            style={{
                                                                fontSize: '13px',
                                                                cursor: 'pointer',
                                                                color: 'white',
                                                                marginLeft: '5px',
                                                            }}
                                                            className="badge bg-danger"
                                                        >
                                                            {findlanguage().isExpired}
                                                        </span>
                                                    ) : null}
                                                    {datesLeft > 0 && datesLeft < 30 ? (
                                                        <span
                                                            style={{
                                                                fontSize: '13px',
                                                                cursor: 'pointer',
                                                                color: 'white',
                                                                marginLeft: '5px',
                                                            }}
                                                            className="badge bg-warning"
                                                        >
                                                            {findlanguage().willExpire} {datesLeft}{' '}
                                                            {findlanguage().days}
                                                        </span>
                                                    ) : null}
                                                </>
                                            </>
                                        ) : null}
                                        {x.status === 'Pending' ? (
                                            <CardFormStatus
                                                color="yellow"
                                                className="tooltip_element"
                                            >
                                                <span className="tooltip">
                                                    {findlanguage().pending}
                                                </span>
                                            </CardFormStatus>
                                        ) : null}
                                        {x.status === 'Denied' ? (
                                            <>
                                                <CardFormStatus
                                                    color="#dc3545"
                                                    style={{
                                                        marginRight: '8px',
                                                        marginTop: '3px',
                                                    }}
                                                    className="tooltip_element"
                                                >
                                                    <span className="tooltip">
                                                        {findlanguage().denied}
                                                    </span>
                                                </CardFormStatus>
                                                {translate(x.reason)}
                                            </>
                                        ) : null}
                                    </td>
                                    <td style={cssTable}>{x.name_who_accepted}</td>
                                    <td style={cssTable}>{formatDate(x.acceptedDate)}</td>
                                    <td style={cssTable}>{formatDate(x.expirationDate)}</td>
                                </tr>
                            )
                        })
                        ?.sort((a, b) => (a.name > b.name) - (a.name < b.name))}
                </tbody>
            </table>
            {form.id !== -1 && (
                <Forms
                    key={form.id}
                    form={form}
                    goBack={goBack}
                    setForm={setForm}
                    saveForm={saveForm}
                    overviewId={usersWithForms.id}
                />
            )}
        </>
    )
}
