import React, { useEffect, useState, useContext } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import DashboardPanel from 'components/Dashboard/Panels/Wrapper';
import { FindValidationProcess } from 'services/validationProcess'
import { Load } from 'ui/styled';
import { CoreContext } from 'context/CoreContext';

export default function DashboardPanelDocumentControl({ cardTitle, onClose }) {
    const { premium, findlanguage } = useContext(CoreContext);
    const panel = {
        title: findlanguage().conditionStatus,
        actions: [],
        onClose,
    };

    const [chartData, setChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showWarning, setShowWarning] = useState(false);

    const checkAccess = () => {
        return premium.type === 'free';
    };

    const loadDocumentControl = async () => {
        setLoading(true);

        try {
            const chart = await FindValidationProcess();

            const statusCounts = {
                'Condition': 0,
                'Denied': 0,
                'Expired': 0,
                'Pending': 0,
                'Accepted': 0,
                'Will Expire in Less Than 30 Days': 0
            };

            chart.forEach(doc => {
                const status = doc.status;

                statusCounts[status] = (statusCounts[status] || 0) + 1;

                // Calculate the days left for expiration
                if (doc.acceptedDate && doc.expirationDate) {
                    const expirationDate = new Date(doc.expirationDate);
                    const now = new Date();
                    const diffTime = expirationDate - now;
                    const daysLeft = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                    if (daysLeft < 30 && doc.status === 'Accepted') {
                        statusCounts['Will Expire in Less Than 30 Days']++;
                    }
                }
            });

            const labels = Object.keys(statusCounts).filter(key => statusCounts[key] > 0);
            const data = labels.map(key => statusCounts[key]);
            const backgroundColors = [
                '#7189bf',
                '#df7599',
                '#72d6c9',
                '#ffc785',
                '#90d7ff',
                '#3f8cb5'
            ];

            if (data.some(count => count > 0)) {
                setChartData({
                    labels: labels,
                    datasets: [{
                        data: data,
                        backgroundColor: backgroundColors.slice(0, data.length),
                        borderColor: '#ffffff',
                        borderWidth: 2,
                    }],
                });

                setChartOptions({
                    plugins: {
                        legend: {
                            position: 'right',
                            labels: {
                                font: {
                                    size: 16,
                                    family: 'Open Sans',
                                    weight: 'bold',
                                },
                                color: '#303030',
                            },
                            align: 'center',
                        },
                        datalabels: {
                            display: true,
                            color: 'white',
                            font: {
                                size: 16,
                                family: 'Open Sans',
                                weight: 'bold',
                            },
                            formatter: (value) => value,
                        },
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                });

                // Show warning if there are any expired or will expire soon documents
                if (statusCounts['Expired'] > 0 || statusCounts['Will Expire in Less Than 30 Days'] > 0) {
                    setShowWarning(true);
                } else {
                    setShowWarning(false);
                }
            } else {
                setChartData(null);
            }
        } catch (error) {
            console.error("Failed to load document control data:", error);
            setChartData(null);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!checkAccess()) loadDocumentControl();
    }, []);

    return (
        <>
            {loading ? (
                <Load color="#263640" />
            ) : (
                <DashboardPanel {...panel} subscription={premium?.type} showWarning={showWarning}>
                    {!chartData?.datasets?.length ? (
                        <div style={{ width: '100%' }}>{findlanguage().noData}</div>
                    ) : (
                        <div style={{ width: '95%', height: '270px' }}>
                            <Doughnut
                                data={chartData}
                                options={chartOptions}
                                plugins={[ChartDataLabels]}
                            />
                        </div>
                    )}
                </DashboardPanel>
            )}
        </>
    );
}
