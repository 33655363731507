import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const Content = styled.div.attrs({})``

export const PageHeader = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 20px;
`
export const PageHeaderTitle = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: var(--grey-color);
`
export const PageHeaderActions = styled.div.attrs({})`
    display: flex;
    align-items: center;
`
export const PageHeaderBack = styled.img.attrs({
    src: '/icons/back.svg',
})`
    cursor: pointer;
    margin-right: 20px;
`

export const PageCard = styled.div.attrs({})`
    padding: 24px;
    background: var(--white-color);

    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
    box-shadow: 0px 1.5px 6px var(--shadow-color);
    margin-bottom: 20px;
`
export const ProfileContainer = styled.div.attrs({})``
export const ProfileContentSpace = styled.div.attrs({})`
    padding: 10px;
`

export const PlanContainer = styled.div.attrs({})`
    display: flex;
    align-items: center;
    flex: 1;
`
export const PlanSection = styled.div.attrs({})`
    padding: 20px 30px;
    border-right: 0.5px solid var(--shadow-color);
    ${(props) =>
        props.row
            ? `
            display: flex;
            align-items: center;
            flex:1;
        `
            : ``}
    ${(props) =>
        props.toend
            ? `
            display: flex;
            flex-direction: column;
            align-items: flex-end;

        `
            : ``}
    ${(props) =>
        props.last
            ? `
            border-right: 0px;
        `
            : ``}
`
export const PlanIconContent = styled.div.attrs({})`
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin-right: 30px;
    background-image: linear-gradient(to bottom right, #22c0a2, #116051);

    ${(props) =>
        props.gold
            ? `
            background-image: linear-gradient(to bottom right, #C09822, #604C11);
        `
            : ``}
    display: flex;
    align-items: center;
    justify-content: center;
`

export const PlanIconIcon = styled.img.attrs({
    src: '/icons/logo.svg',
})``

export const PlanTitle = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 17px;
    color: var(--grey-color);
`
export const PlanText = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: var(--lightgrey-color);
`
export const PlanCard = styled.div.attrs({})`
    display: flex;
    align-items: center;
`
export const PlanCardIcon = styled.img.attrs((props) => ({
    src: `/icons/${props.icon}.svg`,
}))`
    margin-right: 6px;
`
export const PlanLink = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: var(--blue-color);
    cursor: pointer;
`
