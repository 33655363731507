import styled from 'styled-components'

export const ProfileFormContent = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: center;
    padding: 20px 0 30px;
    gap: 20px 0;
`

export const ProfileFormActions = styled.div.attrs({})`
    display: flex;
    justify-content: center;
`
export const PageFormSpace = styled.div.attrs({})`
    margin-right: 12px;
`

export const InputIcon = styled.img.attrs({})`
    margin-top: 10px;
    height: 20px;
    margin-left: 10px;
    cursor: pointer;
`