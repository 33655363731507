import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';

import React, { useEffect, useState, useRef } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, Label, BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';
import { GetAllUsersPerRole, GetAllDocuments, GetTierTwo } from 'services/users';
import { Card, Grid, Typography } from '@material-ui/core';
import MarkerClusterer from "@googlemaps/markerclustererplus";
import { OrganizationChart } from 'primereact/organizationchart';
import Fullscreen from '@material-ui/icons/Fullscreen';
import FullscreenExit from '@material-ui/icons/FullscreenExit';
import Select from 'react-select';

const Dashboard = () => {
    const [data, setData] = useState([]);
    const [map, setMap] = useState(null);
    const [locations, setLocations] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [countryCount, setCountryCount] = useState([]);
    const [documentData, setDocumentData] = useState([]);
    const [tierTwo, setTierTwo] = useState([]);
    const [expiryData, setExpiryData] = useState([]);
    const [user, setUser] = useState([]);
    const COLORS = ['#0088FE', '#a4de6c', '#FF8042', '#00C49F', '#FFBB28', '#8884d8', '#8dd1e1', '#a4de6c', '#d0ed57', '#ffc658', '#d73027'];

    useEffect(() => {
        const fetchData = async () => {
            const userData = await GetAllUsersPerRole();
            const documents = await GetAllDocuments();

            setUser(userData.map(company => ({
                value: company.company_id || company.id,  // I used company.id as a fallback in case company_id is null
                label: company.company_name,
                avatar_url: company.avatar_url ? company.avatar_url : '/images/no-image.png'
            })))

            let expiryCounts = {
                'Expired': 0,
                '3 days to expire': 0,
                '7 days to expire': 0,
                '15 days to expire': 0,
                '30 days to expire': 0,
                'More than 30 days to expire': 0,
            };

            let statusCounter = {}; // Object to hold counts of each document status

            documents.forEach(document => {
                if (!statusCounter[document.status]) {
                    statusCounter[document.status] = 0;
                }
                statusCounter[document.status]++;

                const category = getExpiryCategory(document.issue_date);
                expiryCounts[category]++;
            });

            setExpiryData(Object.entries(expiryCounts).map(([name, value]) => ({ name, value })));

            // Convert statusCounter object to array
            let documentData = Object.entries(statusCounter).map(([status, count]) => ({ name: status, value: count }));

            setDocumentData(documentData); // Set document status data state

            let buyerCount = 0;
            let supplierCount = 0;
            let serviceProviderCount = 0;

            const locationsTemp = [];
            const countryCounter = {};

            userData.forEach((user) => {
                if (user.role === 'Authenticated-buyer') buyerCount++;
                else if (user.role === 'Authenticated-supplier') supplierCount++;
                else if (user.role === 'Authenticated-service-provider') serviceProviderCount++;
                if (user.latitude && user.longitude) {
                    locationsTemp.push({
                        lat: user.latitude,
                        lng: user.longitude,
                        status: user.status,
                        company_name: user.company_name,
                        avatar_url: user.avatar_url ? user.avatar_url : '/images/no-image.png'
                    });
                }

                if (user.country) {
                    if (!countryCounter[user.country]) {
                        countryCounter[user.country] = 0;
                    }
                    countryCounter[user.country]++;
                }
            });

            setLocations(locationsTemp)

            setData([
                { name: 'Buyer', value: buyerCount },
                { name: 'Supplier', value: supplierCount },
                { name: 'Service Provider', value: serviceProviderCount },
            ]);

            setCountryCount(Object.entries(countryCounter).map(([country, count]) => ({ country, count })).sort((a, b) => b.count - a.count));
        }

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const gmap = new window.google.maps.Map(document.getElementById("map"), {
                center: { lat: 37.0902, lng: -95.7129 },
                zoom: 3,
                mapTypeId: 'roadmap',
            });
            setMap(gmap);

            markers.forEach(marker => marker.setMap(null));
            setMarkers([]);

            // Await all icons creation and then create markers
            const newMarkersData = await Promise.all(locations.map(async (location) => {
                const iconUrl = await createUserIcon(location.avatar_url, location.status);
                return new window.google.maps.Marker({
                    position: location,
                    map: gmap,
                    icon: {
                        url: iconUrl,
                        scaledSize: new window.google.maps.Size(40, 40)
                    },
                    title: location.company_name
                });
            }));

            setMarkers(newMarkersData);

            new MarkerClusterer(gmap, newMarkersData, {
                imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
            });

        }
        fetchData();
    }, [locations]);

    function transformToOrgChartFormat(data) {
        const transformNode = node => {
            return {
                expanded: true,
                type: 'person',
                className: 'bg-indigo-500 text-white',
                style: { borderRadius: '12px' },
                data: {
                    name: node.name,
                    image: node.avatar_url ? node.avatar_url : '/images/no-image.png'
                },
                children: node?.secondTier?.map(transformNode)
            };
        };

        return data.map(transformNode);
    }

    const nodeTemplate = (node) => {
        if (node.type === 'person') {
            return (
                <div className="flex flex-column">
                    <div className="flex flex-column align-items-center">
                        <img alt={node.data.name} src={node.data.image} className="mb-3 w-3rem h-3rem" />
                        <span className="font-bold mb-2">{node.data.name}</span>
                        <span>{node.data.title}</span>
                    </div>
                </div>
            );
        }

        return node.label;
    };

    const getExpiryCategory = (issueDate) => {
        // Convert issueDate to Date object if it's not already
        if (typeof issueDate === "string") {
            issueDate = new Date(issueDate);
        }

        const now = new Date();
        const diffInDays = Math.floor((issueDate - now) / (1000 * 60 * 60 * 24)); // Difference in days

        if (diffInDays < 0) {
            return 'Expired';
        } else if (diffInDays <= 3) {
            return '3 days to expire';
        } else if (diffInDays <= 7) {
            return '7 days to expire';
        } else if (diffInDays <= 15) {
            return '15 days to expire';
        } else if (diffInDays <= 30) {
            return '30 days to expire';
        } else {
            return 'More than 30 days to expire';
        }
    }

    const total = data.reduce((acc, cur) => acc + cur.value, 0);

    const createUserIcon = async (avatar_url, status) => {
        const canvas = document.createElement('canvas');
        canvas.width = 40; // match the desired marker size
        canvas.height = 40;
        const context = canvas.getContext('2d');

        // Draw outer circle
        context.beginPath();
        context.arc(20, 20, 20, 0, Math.PI * 2, false); // circle with radius of 20
        context.fillStyle = status ? '#00FF00' : '#FF0000'; // green for online, red for offline
        context.fill();

        const img = new Image();
        img.crossOrigin = "Anonymous"; // Attempt to address any potential CORS issues

        try {
            if (avatar_url === '/images/no-image.png')
                img.src = '/images/no-image.png';
            else
                img.src = await fetchImageAsBlob(avatar_url);

            // Convert image to data URL once loaded
            return new Promise((resolve) => {
                img.onload = () => {
                    context.beginPath();
                    context.arc(20, 20, 18, 0, Math.PI * 2, true);
                    context.closePath();
                    context.clip();
                    context.drawImage(img, 1, 1, 38, 38);

                    resolve(canvas.toDataURL());
                };
            });
        } catch (error) {
            console.error('Error fetching the image:', error);
            return canvas.toDataURL(); // If there's an error fetching the image, just return the status circle
        }
    };

    const cardRef = useRef(null);

    const enterFullScreen = () => {
        if (cardRef.current.requestFullscreen) {
            cardRef.current.requestFullscreen();
        } else if (cardRef.current.mozRequestFullScreen) { /* Firefox */
            cardRef.current.mozRequestFullScreen();
        } else if (cardRef.current.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
            cardRef.current.webkitRequestFullscreen();
        } else if (cardRef.current.msRequestFullscreen) { /* IE/Edge */
            cardRef.current.msRequestFullscreen();
        }
    };

    const exitFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { /* Chrome, Safari & Opera */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE/Edge */
            document.msExitFullscreen();
        }
    };

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            enterFullScreen();
        } else {
            exitFullScreen();
        }
    };

    const handleCompanyChange = async (selectedOption) => {
        const tierTwo = await GetTierTwo(selectedOption.value);

        setTierTwo([{
            expanded: true,
            type: 'person',
            className: 'bg-indigo-500 text-white',
            style: { borderRadius: '12px' },
            data: {
                image: selectedOption.avatar_url,
                name: selectedOption.label,
                title: 'CEO'
            },
            children: transformToOrgChartFormat(tierTwo)
        }]
        )

    };

    function fetchImageAsBlob(url) {
        return fetch(url, {
            mode: 'no-cors'
        })
            .then(response => response.blob())
            .then(blob => {
                return URL.createObjectURL(blob);
            });
    }

    return (
        <>
            <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" align="center">Users</Typography>
                    <Card style={{ backgroundColor: 'white', padding: '10px', margin: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <PieChart width={400} height={400}>
                            <Pie
                                dataKey="value"
                                isAnimationActive={false}
                                data={data}
                                cx={200}
                                cy={200}
                                outerRadius={80}
                                innerRadius={60}
                                fill="#8884d8"
                                label
                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                                <Label value={`Total: ${total}`} position="center" fill="#8884d8" />
                            </Pie>
                            <Tooltip />
                            <Legend />
                        </PieChart>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" align="center">User per country</Typography>
                    <Card style={{ backgroundColor: 'white', padding: '10px', margin: '10px' }}>
                        <div id="map" style={{ width: '100%', height: '400px' }}></div>
                    </Card>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography variant="h6" align="center">User per country</Typography>
                    <Card style={{ backgroundColor: 'white', padding: '10px', margin: '10px' }}>
                        <BarChart width={730} height={250} data={countryCount}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="country" />
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="count">
                                {
                                    countryCount.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))
                                }
                            </Bar>
                        </BarChart>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" align="center">Document Status</Typography>
                    <Card style={{ backgroundColor: 'white', padding: '10px', margin: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <PieChart width={400} height={400}>
                            <Pie
                                dataKey="value"
                                isAnimationActive={false}
                                data={documentData}
                                cx={200}
                                cy={200}
                                outerRadius={80}
                                innerRadius={60}
                                fill="#8884d8"
                                label
                            >
                                {documentData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                                <Label value={`Total: ${documentData.reduce((acc, cur) => acc + cur.value, 0)}`} position="center" fill="#8884d8" />
                            </Pie>
                            <Tooltip />
                            <Legend />
                        </PieChart>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" align="center">Document Expiry Status</Typography>
                    <Card style={{ backgroundColor: 'white', padding: '10px', margin: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <PieChart width={400} height={400}>
                            <Pie
                                dataKey="value"
                                isAnimationActive={false}
                                data={expiryData}
                                cx={200}
                                cy={200}
                                outerRadius={80}
                                innerRadius={60}
                                fill="#8884d8"
                                label
                            >
                                {expiryData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                                <Label value={`Total: ${expiryData.reduce((acc, cur) => acc + cur.value, 0)}`} position="center" fill="#8884d8" />
                            </Pie>
                            <Tooltip />
                            <Legend />
                        </PieChart>
                    </Card>
                </Grid>
                <Grid item xs={12} md={12}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6">Document Expiry Status</Typography>
                        <Select
                            value={user.find(option => option.value === user.company_id)}
                            onChange={handleCompanyChange}
                            options={user}
                            isSearchable={true}   // Ensures the select is searchable
                            placeholder="Select a company..."
                            styles={{
                                container: (base) => ({
                                    ...base,
                                    width: '500px',
                                    marginLeft: '10px'
                                })
                            }}
                        />
                        {
                            document.fullscreenElement ? (
                                <FullscreenExit
                                    aria-hidden="false"
                                    aria-label="Exit fullscreen"
                                    tabIndex="0"
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') toggleFullScreen();
                                    }}
                                    style={{ cursor: 'pointer', zIndex: 1 }}
                                    onClick={toggleFullScreen}
                                />
                            ) : (
                                <Fullscreen
                                    aria-hidden="false"
                                    aria-label="Enter fullscreen"
                                    tabIndex="0"
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') toggleFullScreen();
                                    }}
                                    style={{ cursor: 'pointer', zIndex: 1 }}
                                    onClick={toggleFullScreen}
                                />
                            )}
                    </div>
                    <Card
                        ref={cardRef}
                        style={{
                            backgroundColor: 'white',
                            marginTop: '16px', // Add some space between the elements
                            maxHeight: '500px',
                            overflow: 'auto'
                        }}
                    >
                        <br></br>
                        {
                            tierTwo.length !== 0 ?
                                <OrganizationChart value={tierTwo} nodeTemplate={nodeTemplate} />
                                :
                                <>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                </>
                        }
                    </Card>
                </Grid>
            </Grid>

        </>
    );
};

export default Dashboard;