import React, { useEffect, useState, useContext } from 'react'
import Button from '../Button'

// import Button from '../Button'
import Check from '../Check'
import Input from '../Input'
import { CoreContext } from 'context/CoreContext'

import {
    Body,
    Option,
    CheckHeader,
    ToggleIcon,
    SubOption,
    ButtonDock,
    RadioButton,
    RadioButtonLabel,
    Item,
    SubItem,
    RadioHeader,
    RadioContent,
    RadioButtonCircle,
    RadioHeaderLabel,
} from './styled'

export default function CertificationsOptions({ datasource, unique, onSelectChange, grid }) {
    const { language, findlanguage } = useContext(CoreContext)
    const [options, setOptions] = useState([])
    const [newcert, setNewCert] = useState()
    const [lastOpened, setLastOpened] = useState()

    const addMore = async (master) => {
        if (newcert.length === 0) return
        const rand = new Date().getTime() * -1
        const optionsList = [...options]

        optionsList.map((item, key) => {
            if (item.title === master || item.title_pt === master) {
                if (language === 'portugues')
                    item.items.push({
                        id: rand,
                        title: newcert,
                        title_pt: newcert,
                        label: newcert,
                        label_pt: newcert,
                    })
                else item.items.push({ id: rand, title: newcert, label: newcert })
            }
            return item
        })

        setNewCert('')

        setOptions([...optionsList])
    }

    const ToogleView = (check) => {
        setNewCert('')
        const optionsList = [...options]

        optionsList.map((item, key) => {
            if (item.title === lastOpened && item.title !== check.title) {
                item.opened = false
            }

            if (item.title === check.title) {
                item.opened = item.opened !== undefined ? !item.opened : true
                if (item.opened) setLastOpened(item.title)
            }
            return item
        })
        setOptions([...optionsList])
    }

    // Return true if all child is selected otherwise return false
    const childrenIsSelected = (subitems) => {
        const found = subitems.filter((item) => item.checked)
        return subitems.length === found.length
    }

    const ToogleSelected = (check, subitem) => {
        const optionsList = [...options]
        const master = subitem ? false : true

        optionsList.map((item, key) => {
            if (item.title === check.title) {
                item.checked = item.checked !== undefined ? !item.checked : true
                if (master && item.items.length > 0) {
                    item.items.map((el, key) => {
                        el.checked = check.checked
                        return el
                    })
                } else if (subitem !== undefined) {
                    subitem.checked = subitem.checked !== undefined ? !subitem.checked : true
                    item.checked = childrenIsSelected(item.items)
                }
            }
            return item
        })
        setOptions([...optionsList])
    }

    useEffect(() => {
        setOptions([...datasource])

        return () => {}
    }, [datasource])

    return (
        <>
            <Body grid={grid}>
                {options.map((item, key) => {
                    if (unique) {
                        return (
                            <>
                                <Item key={item.id}>
                                    <RadioHeader>
                                        <>
                                            <RadioHeaderLabel onClick={() => ToogleView(item)}>
                                                {language === 'english' ? item.title : null}
                                                {language === 'portugues' ? item.title_pt : null}
                                            </RadioHeaderLabel>
                                            <ToggleIcon
                                                opened={item.opened}
                                                onClick={() => ToogleView(item)}
                                            />
                                        </>
                                    </RadioHeader>
                                    {item.opened &&
                                        item.items.map((subitem, k) => {
                                            return (
                                                <>
                                                    <SubItem>
                                                        <RadioContent>
                                                            <RadioButton
                                                                key={k}
                                                                type="radio"
                                                                name="radio"
                                                                value={subitem.label}
                                                                onChange={(e) =>
                                                                    onSelectChange(
                                                                        item.id,
                                                                        language === 'portugues'
                                                                            ? item.title_pt
                                                                            : item.title,
                                                                        subitem
                                                                    )
                                                                }
                                                            ></RadioButton>
                                                            <RadioButtonCircle></RadioButtonCircle>
                                                        </RadioContent>
                                                        <RadioButtonLabel>
                                                            {language === 'english'
                                                                ? subitem.label
                                                                : null}
                                                            {language === 'portugues'
                                                                ? subitem.label_pt
                                                                : null}
                                                        </RadioButtonLabel>
                                                    </SubItem>
                                                </>
                                            )
                                        })}

                                    {item.opened ? (
                                        <ButtonDock>
                                            <div style={{ width: '180px' }}>
                                                <Input
                                                    placeholder={
                                                        findlanguage().newKindOfCertificate
                                                    }
                                                    small
                                                    value={newcert}
                                                    onChange={(e) => setNewCert(e.target.value)}
                                                />
                                            </div>
                                            <div style={{ paddingTop: '20px' }}>
                                                <Button
                                                    primary
                                                    tiny
                                                    onClick={(e) => {
                                                        addMore(
                                                            language === 'portugues'
                                                                ? item.title_pt
                                                                : item.title
                                                        )
                                                    }}
                                                >
                                                    {findlanguage().add}
                                                </Button>
                                            </div>
                                        </ButtonDock>
                                    ) : (
                                        ''
                                    )}
                                </Item>
                            </>
                        )
                    } else
                        return (
                            <>
                                <Option key={item.id}>
                                    <CheckHeader>
                                        <Check
                                            nowidth={true}
                                            onChange={(e) => ToogleSelected(item)}
                                            title={item.title}
                                            label={''}
                                            checked={item.checked}
                                        />
                                        {item.items.length > 0 ? (
                                            <ToggleIcon
                                                opened={item.opened}
                                                onClick={() => ToogleView(item)}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </CheckHeader>

                                    {item.opened &&
                                        item.items.map((subitem, k) => {
                                            return (
                                                <>
                                                    <SubOption>
                                                        <Check
                                                            onChange={(e) =>
                                                                ToogleSelected(item, subitem)
                                                            }
                                                            title={subitem.label}
                                                            label={''}
                                                            checked={subitem.checked}
                                                        />
                                                    </SubOption>
                                                </>
                                            )
                                        })}

                                    {item.opened ? (
                                        <ButtonDock>
                                            <div>
                                                <Input
                                                    placeholder={
                                                        findlanguage().newKindOfCertificate
                                                    }
                                                    small
                                                    value={newcert}
                                                    onChange={(e) => setNewCert(e.target.value)}
                                                />
                                            </div>
                                            <div style={{ paddingTop: '20px' }}>
                                                <Button
                                                    primary
                                                    tiny
                                                    onClick={(e) => {
                                                        addMore(item.title)
                                                    }}
                                                >
                                                    {findlanguage().add}
                                                </Button>
                                            </div>
                                        </ButtonDock>
                                    ) : (
                                        ''
                                    )}
                                </Option>
                            </>
                        )
                })}
            </Body>
        </>
    )
}
