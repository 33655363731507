import styled from 'styled-components'

export const FormTitle = styled.div.attrs({})`
    color: var(--primary-color);
    font-weight: bold;
    font-size: 20px;
    margin: 50px 0 10px;
`
export const FormText = styled.div.attrs({})`
    font-size: 15px;
    color: var(--grey-color);
    max-width: 240px;
    margin-bottom: 30px;
`
export const FormSpacer = styled.div.attrs({})`
    margin-top: 40px;
`
export const RegisterCall = styled.div.attrs({})`
    margin: 30px 0 0px;
`

export const HeaderContainer = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 35px 0 0;
`
export const HeaderBackIcon = styled.img.attrs({
    src: '/icons/back.svg',
})`
    width: 21px;
    height: 20px;
`

export const HeaderContent = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const HeaderTitle = styled.div.attrs({})`
    color: var(--grey-color);
    font-size: 25px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-align: center;
`
export const HeaderStepIndicator = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid var(--lightgrey-color);
    margin-top: 29px;
    margin-bottom: 48px;
    min-width: 80px;
    ${(props) =>
        props.active
            ? `
            border-bottom: .5px solid var(--primary-color);
        `
            : ``}
`
export const HeaderStep = styled.div.attrs({})`
    border: 1px solid var(--lightgrey-color);
    width: 38px;
    height: 38px;
    border-radius: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -38px -19px;

    color: var(--lightgrey-color);
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;

    ${(props) =>
        props.current
            ? `
            color: var(--white-color);
            border: 1px solid var(--primary-color);
            background: var(--primary-color);
        `
            : ``}

    ${(props) =>
        props.active
            ? `
            border: 1px solid var(--primary-color);
            color: var(--primary-color);
        `
            : ``}
`
export const HeaderSubtitle = styled.div.attrs({})`
    color: var(--grey-color);
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    text-align: center;
`
export const HeaderActionIcon = styled.img.attrs({
    src: '/icons/chat.svg',
})`
    width: 25px;
    height: 25px;
`

export const BodyContent = styled.div.attrs({})`
    padding-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
`
export const CardContainer = styled.div.attrs({})`
    background: var(--white-color);
    border-radius: 15px;
    box-shadow: 0px 1.5px 10px var(--lightshadow-color);
    max-width: 252px;
    margin-bottom: 24px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        transform: scale(1.1);
    }
`
export const CardImage = styled.div.attrs({})`
    height: 169px;
    width: 100%;
    background-color: var(--lightgrey-color);
    background: url(${(props) => props.image}) no-repeat center center / cover;
    border-radius: 15px;
    overflow: hidden;
`
export const CardContent = styled.div.attrs({})`
    padding: 20px;
`
export const CardTitle = styled.div.attrs({})`
    color: var(--grey-color);
    font-size: 17px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    text-align: center;
    margin-bottom: 14px;
`
export const CardText = styled.div.attrs({})`
    color: var(--lightgrey-color);
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    text-align: center;
    margin-bottom: 24px;
`
