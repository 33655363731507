import React from 'react'

import { Row, Col, Container } from 'reactstrap'
import { useHistory } from 'react-router-dom'

import {
    FooterContainer,
    FooterCopyright,
} from './styled'

export default function Footer() {
    const history = useHistory()

    return (
        <>
            <FooterContainer>
                <Container>
                    <Row>
                        <Col>
                            <FooterCopyright>My Trusted Source @ 2022</FooterCopyright>
                        </Col>
                    </Row>
                </Container>
            </FooterContainer>
        </>
    )
}
