import React, { useState, useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const SpecificStatusByRequirement = ({ showDataLabels, usersWithForms, optionsRequirements }) => {
    const [selectedForm, setSelectedForm] = useState('All');

    const chartData = useMemo(() => {
        const statusCounts = {};

        usersWithForms.forEach(item => {
            if (selectedForm === 'All' || item.form.name === selectedForm) {
                const status = item.status;
                if (status) {
                    statusCounts[status] = (statusCounts[status] || 0) + 1;
                }
            }
        });

        const filteredLabels = Object.keys(statusCounts).filter(key => statusCounts[key] > 0);
        const filteredData = filteredLabels.map(key => statusCounts[key]);

        return {
            labels: filteredLabels,
            datasets: [{
                data: filteredData,
                backgroundColor: [
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(128, 128, 128, 0.6)'
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(128, 128, 128, 1)'
                ],
                borderWidth: 1
            }]
        };
    }, [usersWithForms, selectedForm]);

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    usePointStyle: true,
                }
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.label}: ${tooltipItem.raw}`;
                    }
                }
            },
            datalabels: {
                display: true,
                color: showDataLabels ? 'black' : 'white',
                align: 'center',
                font: {
                    weight: 'bold',
                    size: 14
                },
                formatter: (value, context) => {
                    if (showDataLabels) {
                        const label = context.chart.data.labels[context.dataIndex];
                        return `${label}: ${value}`;
                    } else {
                        return value;
                    }
                }
            }
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                bottom: 109,
                top: 0
            }
        },
        radius: '90%'
    };

    return (
        <div style={{ height: '385px' }}>
            <select
                value={selectedForm}
                onChange={e => setSelectedForm(e.target.value)}
                style={{ marginBottom: '10px' }}
            >
                <option value="All">All Forms</option>
                {optionsRequirements.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <h2>Specific Status by Requirement</h2>
            <Doughnut data={chartData} options={options} plugins={[ChartDataLabels]} />
        </div>
    );
};

export default SpecificStatusByRequirement;
