import React, { useContext, useEffect, useState } from 'react'
import { CoreContext } from 'context/CoreContext'
import { NotificationManagerAdd } from 'services/admin'
import { useHistory } from 'react-router-dom'

import {
    HeaderContent,
    HeaderLogoImage,
    HeaderBadge,
    HeaderBadgeTouch,
    HeaderBadgeIcon,
    HeaderBadgeText,
    NotificationContainer,
    NotificationContentDecoration,
    NotificationContent,
    NotificationContentTitle,
    NotificationItem,
    NotificationItemIcon,
    NotificationItemReadIcon,
    NotificationItemText,
    NotificationButton,
} from './styled'

import { FetchNotifications, UpdateNotifications } from 'services/dashboard'
import { UpdateConnection } from 'services/connections'
import { UpdateSupplierReg } from 'services/sms'

export default function HeaderNotifications({ adminView }) {
    const [opened, setOpened] = useState(false)
    const [notifications, setNotifications] = useState([])
    const [newNotifications, setNewNotifications] = useState(0)

    const { user, profile, findlanguage } = useContext(CoreContext)
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)
    const searchNotifications = async () => {
        if (profile !== 'profile_not_found') {
            let result = await FetchNotifications()
            result = result.slice(0, 6)
            setNotifications(result)
            setNewNotifications(result.filter((x) => x.read === false).length)
        }
    }
    useEffect(() => {
        searchNotifications()
    }, [])

    const acceptOrDeclineConnection = async (accept, item) => {
        await UpdateConnection({
            id: item.connection_id,
            acceptedInvitation: accept ? 'Accepted' : 'Denied',
        })

        if (accept) {
            item.type = 'connection_accepted'

            await NotificationManagerAdd(
                'connection_accepted',
                item.user_requesting_id,
                user.name,
                user
            )
        } else {
            item.type = 'connection_not_accepted'

            await NotificationManagerAdd(
                'connection_not_accepted',
                item.user_requesting_id,
                user.name,
                user
            )
        }
        
        item.decided_connection = true
        item.receiver_id = item.user_requesting_id
        item.from_name = item.label.replace(' wants to connect with you','')

        const updateNotification = await UpdateNotifications(item)

        searchNotifications()
    }

    const acceptOrDeclineSupplier = async (accept, item) => {
        await UpdateSupplierReg({
            id: item.connection_id,
            connection_requested: accept ? 'Accepted' : 'Denied',
        })

        if (accept) {
            item.type = 'myqms_accepted'

            await NotificationManagerAdd('myqms_accepted', item.user_requesting_id, user.name, user)
        } else {
            item.type = 'myqms_not_accepted'

            await NotificationManagerAdd(
                'myqms_not_accepted',
                item.user_requesting_id,
                user.name,
                user
            )
        }

        item.decided_connection = true
        item.receiver_id = item.user_requesting_id
        item.from_name = user.name

        const updateNotification = await UpdateNotifications(item)

        searchNotifications()
    }

    const openNotifications = async () => {
        setNewNotifications(0)
        notifications.forEach(
            async (x) =>
                await UpdateNotifications({
                    ...x,
                    read: true,
                })
        )

        setOpened(!opened)
    }

    return (
        <>
            <HeaderContent>
                <HeaderLogoImage />
                {!adminView && (
                    <HeaderBadge>
                        <HeaderBadgeTouch>
                            <HeaderBadgeIcon onClick={openNotifications} />
                            <HeaderBadgeText>{newNotifications}</HeaderBadgeText>
                        </HeaderBadgeTouch>

                        {!opened ? null : (
                            <NotificationContainer>
                                <NotificationContentDecoration />
                                <NotificationContent>
                                    <NotificationContentTitle>
                                        {findlanguage().notification}
                                    </NotificationContentTitle>
                                    {notifications.map((item, key) => (
                                        <div key={item.id}>
                                            <NotificationItem>
                                                {item.read ? (
                                                    <NotificationItemReadIcon />
                                                ) : (
                                                    <NotificationItemIcon />
                                                )}

                                                <NotificationItemText>
                                                    {item.label}
                                                </NotificationItemText>
                                            </NotificationItem>
                                            {item.type === 'add_connection' &&
                                            item.decided_connection === false ? (
                                                <NotificationItem
                                                    style={{
                                                        display: 'inherit',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <NotificationItemText>
                                                        <button
                                                            className="btn btn-success"
                                                            onClick={() =>
                                                                acceptOrDeclineConnection(
                                                                    true,
                                                                    item
                                                                )
                                                            }
                                                        >
                                                            {findlanguage().yes}
                                                        </button>
                                                        <button
                                                            style={{ marginLeft: '20px' }}
                                                            onClick={() =>
                                                                acceptOrDeclineConnection(
                                                                    false,
                                                                    item
                                                                )
                                                            }
                                                            className="btn btn-danger"
                                                        >
                                                            {findlanguage().no}
                                                        </button>
                                                    </NotificationItemText>
                                                </NotificationItem>
                                            ) : null}
                                            {item.type === 'new_myqms' &&
                                            item.decided_connection === false ? (
                                                <NotificationItem
                                                    style={{
                                                        display: 'inherit',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <NotificationItemText>
                                                        <button
                                                            className="btn btn-success"
                                                            onClick={() =>
                                                                acceptOrDeclineSupplier(true, item)
                                                            }
                                                        >
                                                            {findlanguage().yes}
                                                        </button>
                                                        <button
                                                            style={{ marginLeft: '20px' }}
                                                            onClick={() =>
                                                                acceptOrDeclineSupplier(false, item)
                                                            }
                                                            className="btn btn-danger"
                                                        >
                                                            {findlanguage().no}
                                                        </button>
                                                    </NotificationItemText>
                                                </NotificationItem>
                                            ) : null}
                                        </div>
                                    ))}

                                    <NotificationItem style={{ justifyContent: 'center' }}>
                                        <NotificationButton
                                            onClick={() => {
                                                navigate('dashboard/notification')
                                            }}
                                        >
                                            {findlanguage().seeMore}
                                        </NotificationButton>
                                    </NotificationItem>
                                </NotificationContent>
                            </NotificationContainer>
                        )}
                    </HeaderBadge>
                )}
            </HeaderContent>
        </>
    )
}
