import React, { useContext, useState, useEffect, useRef } from 'react'

import { toast } from 'react-toastify'

import { BodyContent, FormContent, RequiredText, InputSpace, CheckContainer } from './styled'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap'

import Input from 'components/Form/Input'

import RegisterFooter from 'components/Register/Footer'
import InputPassword from 'components/Form/InputPassword'
import Check from 'components/Form/Check'
import { CoreContext } from 'context/CoreContext'

export default function RegisterConsumerStep1({
    formValue,
    changeForm,
    next,
    loading,
    form,
    setForm,
}) {
    const { findlanguage } = useContext(CoreContext)
    const autoCompleteRef = useRef()
    const inputRef = useRef()
    const options = {
        fields: ['address_components', 'geometry', 'icon', 'name'],
        types: ['address'],
    }
    const [fieldValidation, setFieldValidation] = useState([])
    const [stateTooltipPhone, setStateTooltipPhone] = useState(false)
    const [stateTooltipPassword, setStateTooltipPassword] = useState(false)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [street, setStreet] = useState('')
    const [country, setCountry] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [website, setWebSite] = useState('')
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')

    const [customAddress, setCustomAddress] = useState(false)

    const buildForm = () => {
        const _form = {
            ...form,
            name: name,
            email: email,
            phone: phone,
            password: password,
            street: street,
            country: country,
            state: state,
            city: city,
            zipCode: zipCode,
            website: website,
            latitude: latitude,
            longitude: longitude,
        }

        setForm(_form)

        return _form
    }

    useEffect(() => {
        buildForm()
    }, [name, email, phone, password, street, zipCode, website])

    useEffect(() => {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current,
            options
        )

        autoCompleteRef.current.addListener('place_changed', async function () {
            const place = await autoCompleteRef.current.getPlace()
            const _place = readPlace(place)

            setStreet(_place.name)
            setCountry(_place.country)
            setState(_place.state)
            setCity(_place.city)
            setZipCode(_place.zip_code)
            setLatitude(_place.latitude)
            setLongitude(_place.longitude)

            buildForm()

            return true
        })
    }, [])

    const showHighlight = (name) => {
        const field = fieldValidation.find((item) => item.field === name)
        if (field !== undefined) return true
        return false
    }

    const showWarningAddress = (name) => {
        if (!customAddress) {
            toast.warning(`Field ${name} is read only. Fill Address Field.`)
            return false
        }
    }

    const handleAddress = (value) => {
        if (!customAddress) {
            setCountry('')
            setState('')
            setCity('')
            setZipCode('')
            setLatitude('')
            setLongitude('')
        }

        setStreet(value)
    }

    const valid = () => {
        setFieldValidation([])
        let errors = []

        if (name.length === 0) {
            errors.push({ field: 'name', message: 'Fill the field: Name' })
        } else if (name.length < 2) {
            errors.push({ field: 'name', message: 'Your Name must contain at least 2 characters' })
        }

        if (email.length === 0) {
            errors.push({ field: 'email', message: 'Fill the field: Email' })
        } else if (
            !email.match(
                /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
            )
        ) {
            errors.push({ field: 'email', message: 'Your e-mail is invalid' })
        }

        if (phone.length === 0) {
            errors.push({ field: 'phone', message: 'Fill the field: Phone' })
        } else if (!/^\+(?:\d[-.\s]?){6,14}\d$/.test(phone)) {
            errors.push({
                field: 'phone',
                message: findlanguage().errorPhone,
            })
        }

        if (password.length === 0) {
            errors.push({ field: 'password', message: 'Fill the field: Password' })
        } else if (!password.match('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{7,})')) {
            errors.push({
                field: 'password',
                message:
                    'Password must have at least 8 characters, include at least 1 Capital letter, 1 number and 1 special character (!@#$%^&*)',
            })
        }

        if (confirmPassword.length === 0) {
            errors.push({ field: 'confirmPassword', message: 'Fill the field: Confirm Password' })
        }

        if (password !== confirmPassword) {
            errors.push({
                field: 'confirmPassword',
                message: 'Password and Confirm Password not match',
            })
        }

        if (street.length === 0) {
            errors.push({
                field: 'street',
                message: 'Fill the field: Address',
            })
        }

        if (country.length === 0) {
            errors.push({
                field: 'country',
                message: 'Fill the field: Country',
            })
        }

        if (state.length === 0) {
            errors.push({
                field: 'state',
                message: 'Fill the field: State',
            })
        }

        if (website.length > 0) {
            if (
                !/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%.+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/.test(
                    website
                )
            ) {
                errors.push({
                    field: 'website',
                    message:
                        'Wrong website format: http://www.google.com or https://www.amazon.com',
                })
            }
        }

        if (errors.length > 0) {
            let message = ''
            message = 'Some errors was found: '
            errors.forEach((item) => {
                message += '\n - ' + item.message
            })
            toast.error(message)
            setFieldValidation(errors)
            return false
        }

        return true
    }

    const action = async () => {
        const _form = buildForm()

        if (!valid()) {
            return
        }

        if (next && typeof next === 'function') {
            next(_form)
        }
    }

    const togglePhone = (_state) => {
        if (typeof _state !== 'boolean') return
        setStateTooltipPhone(_state)
    }

    const togglePassword = (_state) => {
        if (typeof _state !== 'boolean') return
        setStateTooltipPassword(_state)
    }

    //

    const handleInputPhone = (event) => {
        changeForm(event.target.value, event.target.name)
        togglePhone(false)
    }

    //

    const handleInputPassword = (event) => {
        changeForm(event.target.value, event.target.name)
        togglePassword(false)
    }

    const readPlace = (place) => {
        const _address = place.address_components
        let _lat = 0
        let _lng = 0

        try {
            _lat = place.geometry.location?.lat()
            _lng = place.geometry.location?.lng()
        } catch (error) {
            _lat = -1
            _lng = -1
        }

        let dataAddress = {
            country: '',
            state: 'Not Found',
            city: '',
            street: '',
            zip_code: '',
            longitude: '',
            latitude: '',
            name: '',
        }

        if (place.name.length > 0 && place.name !== undefined) dataAddress.name = place.name

        dataAddress.latitude = _lat
        dataAddress.longitude = _lng

        _address.forEach((element) => {
            switch (element.types[0].toString().toLowerCase()) {
                case 'route':
                    dataAddress.street = element.short_name.toString()
                    break
                case 'administrative_area_level_1':
                    dataAddress.state = element.long_name.toString()
                    break
                case 'country':
                    dataAddress.country = element.long_name.toString()
                    break
                case 'administrative_area_level_2':
                    dataAddress.city = element.long_name.toString()
                    break
                case 'postal_code':
                    dataAddress.zip_code = element.long_name.toString()
                    break
                default:
                    break
            }
        })

        return dataAddress
    }

    //

    return (
        <>
            <form>
                <BodyContent>
                    <FormContent>
                        <Input
                            placeholder="Name*"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            small
                            type={'text'}
                            highlight={showHighlight('name')}
                            autoComplete={'xoff'}
                        />
                        <Input
                            placeholder="E-mail*"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            small
                            type={'email'}
                            autoComplete={'xoff'}
                            highlight={showHighlight('email')}
                        />
                        <Input
                            placeholder="Phone Number*"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            onBlur={handleInputPhone}
                            onFocus={(e) => togglePhone(true)}
                            id="phone1"
                            type={'tel'}
                            autoComplete={'xoff'}
                            highlight={showHighlight('phone')}
                            small
                        />
                        <Popover
                            placement="top"
                            isOpen={stateTooltipPhone}
                            toggle={togglePhone}
                            target={'phone1'}
                        >
                            <PopoverHeader>{findlanguage().rulesPhoneNumberHeader}</PopoverHeader>
                            <PopoverBody>{findlanguage().rulesPhoneNumberText}</PopoverBody>
                        </Popover>
                        <div style={{ width: '303px' }}></div>
                        {/* Only First field has Password Strength Meter */}
                        <InputPassword
                            placeholder="Password*"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            id="password"
                            onFocus={(e) => togglePassword(true)}
                            onBlur={handleInputPassword}
                            highlight={showHighlight('password')}
                            autoComplete={'xoff'}
                            small
                        />
                        <Popover
                            placement="top"
                            isOpen={stateTooltipPassword}
                            toggle={togglePassword}
                            target={'password'}
                        >
                            <PopoverHeader>{findlanguage().rulesPasswordHeader}</PopoverHeader>
                            <PopoverBody>{findlanguage().rulesPasswordText}</PopoverBody>
                        </Popover>

                        <div style={{ width: '303px' }}>
                            <Input
                                type={'password'}
                                placeholder="Confirm Password*"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                highlight={showHighlight('confirmPassword')}
                                autoComplete={'new-password'}
                                small
                            />
                            <p></p>
                        </div>

                        <Input
                            placeholder="Address*"
                            value={street}
                            onChange={(e) => handleAddress(e.target.value)}
                            xref={inputRef}
                            xautoComplete={'xoff'}
                            highlight={showHighlight('street')}
                            small
                        />

                        <CheckContainer>
                            <Check
                                value={customAddress}
                                onChange={(e) => setCustomAddress(e)}
                                label={`I'd like to fill Address by myself`}
                                checked={customAddress}
                            />
                        </CheckContainer>

                        <Input
                            placeholder="Country*"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            onFocus={(e) => showWarningAddress('Country')}
                            small
                            highlight={showHighlight('country')}
                            readOnly={!customAddress}
                            autoComplete={'xoff'}
                        />

                        <Input
                            placeholder="State*"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            onFocus={(e) => showWarningAddress('State')}
                            small
                            autoComplete={'xoff'}
                            readOnly={!customAddress}
                            highlight={showHighlight('state')}
                        />

                        <Input
                            placeholder="City"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            onFocus={(e) => showWarningAddress('City')}
                            small
                            highlight={showHighlight('city')}
                            readOnly={!customAddress}
                            autoComplete={'xoff'}
                        />

                        <Input
                            placeholder="ZIP Code"
                            value={zipCode}
                            onChange={(e) => setZipCode(e.target.value)}
                            small
                            autoComplete={'xoff'}
                        />

                        <Input
                            placeholder="Website"
                            value={website}
                            onChange={(e) => setWebSite(e.target.value)}
                            highlight={showHighlight('website')}
                            autoComplete={'xoff'}
                            small
                        />
                        <InputSpace />

                        <div style={{ width: '100%' }}>
                            <RequiredText>*Required fields</RequiredText>
                            <RegisterFooter action={action} single loading={loading} />
                        </div>
                    </FormContent>
                </BodyContent>
            </form>
        </>
    )
}
