import { DELETE, GET, POST, PUT } from './api'

export const FetchUsers = async () => {
    return await GET(`/users/getUsers?_limit=-1`, true)
}

export const FetchSuplierRequests = async (page, limit, sort, direction, filterParams = '') => {
    return await GET(`/user-views/all`, true)
}

export const FetchProfileViews = async () => {
    return await GET(`/user-views/profileviews`, true)
}

export const IsUserOnline = async (body, method = 'POST') => {
    if (!body.id) {
        return
    }
    return (await method) === 'POST'
        ? POST(`/user-statuses`, body, true)
        : PUT(`/user-statuses/${body.id}`, body, true)
}

export const GetUserOnline = async (userId) => {
    if (!userId) {
        return
    }
    return await GET(`/user-statuses/${userId}`, true)
}

export const GetOneUser = async (id) => {
    return await GET(`/user-views/giveuser?id=${id}`, true)
}

export const GetAllUsersPerRole = async () => {
    return await GET(`/user-statuses/findtotalusersperrole?_limit=-1`, true)
}

export const GetAllDocuments = async () => {
    return await GET(`/user-statuses/findtotaldocuments?_limit=-1`, true)
}

export const GetTierTwo = async (id) => {
    return await GET(`/user-statuses/findtiertwo?id=${id}&_limit=-1`, true)
}

export const GetAllServiceProviders = async (id) => {
    return await GET(`/user-views/findallproviders`, true)
}
