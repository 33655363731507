import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ControlButtons from '../ControlButtons/index';
import { CoreContext } from 'context/CoreContext';
import { parseStrapiImage } from 'utils';

function Video({ element, handleLabelTextChange, setSections, handleResize, removeElement, is_draft }) {
    const [showPreview, setShowPreview] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');
    const { findlanguage } = useContext(CoreContext);

    const handleVideoUpload = (elementId, file) => {
        const reader = new FileReader();
        reader.onload = () => {
            let video = {
                filename: file.name,
                filetype: file.type,
                source: reader.result.split(',')[1],
            };
            let binaryContent = window.atob(video.source);
            let numericArray = new Array(binaryContent.length);
            for (let i = 0; i < binaryContent.length; i++) {
                numericArray[i] = binaryContent.charCodeAt(i);
            }
            var byteArray = new Uint8Array(numericArray);
            let blob = new Blob([byteArray], { type: video.filetype });
            let fileObject = new File([blob], video.filename);

            setSections(prevSteps => prevSteps.map(step => ({
                ...step,
                elements: step.elements.map(el => el.id === elementId ? {
                    ...el,
                    stepFiles: {
                        fileObject,
                        fileName: video.filename,
                        fileType: video.filetype,
                        status: 'Active',
                        elementId,
                    }
                } : el),
            })));
        };
        reader.readAsDataURL(file);
    };

    const removeVideo = (elementId) => {
        setSections(prevSteps => prevSteps.map(step => ({
            ...step,
            elements: step.elements.map(el => el.id === elementId ? {
                ...el,
                fileUrl: '',
                fileName: '',
                resetCounter: el.resetCounter != null ? el.resetCounter + 1 : 1,
            } : el),
        })));
    };

    const fileName = element.files && element.files.file ? element.files.file.name : '';
    const fileUrl = element.files && element.files.file ? parseStrapiImage(element.files.file.url) : '';

    const handleDownload = (event, url) => {
        event.preventDefault();
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handlePreview = (url) => {
        setPreviewUrl(url);
        setShowPreview(true);
    };

    const PreviewModal = () => (
        <Modal show={showPreview} onHide={() => setShowPreview(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Preview Video
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <video src={previewUrl} controls style={{ width: '100%' }} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShowPreview(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    );

    return (
        <div key={element.id} className="mb-3 col-12" style={{ wordWrap: 'break-word' }}>
            <label className="form-label">Attach Video</label>
            <input
                type="text"
                className="form-control"
                value={element.label || ''}
                onChange={(e) => handleLabelTextChange(element.id, e)}
                placeholder="Add a label for Video field here"
                disabled={is_draft}
            />
            <br />
            <input
                key={element.resetCounter}
                type="file"
                accept="video/*"
                className="form-control"
                onChange={(e) => {
                    if (e.target.files[0]) {
                        handleVideoUpload(element.id, e.target.files[0]);
                    }
                }}
                disabled={is_draft}
            />
            {fileName && (
                <div style={{ marginTop: '10px' }}>
                    <div style={{ textAlign: 'center' }}>
                        <b>{findlanguage().currentFile}</b> {fileName}
                        <button onClick={(e) => handleDownload(e, fileUrl)} className="btn btn-link">Download</button>
                        <button onClick={() => handlePreview(fileUrl)} className="btn btn-link">Preview</button>
                        <br />
                        <b>{findlanguage().uploadFileNotice}</b>
                    </div>
                    {!is_draft && (
                        <button className="btn btn-danger btn-sm" onClick={() => removeVideo(element.id)}>
                            Remove
                        </button>
                    )}
                </div>
            )}
            <ControlButtons
                elementId={element.id}
                onResize={handleResize}
                onRemove={removeElement}
                is_draft={is_draft}
            />
            <PreviewModal />
        </div>
    );
}

export default Video;
