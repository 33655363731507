import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import Card from '../Card/index.js'

import { PageCard, CardHeader, CardHeaderTitleContainer } from './styled'
import _ from 'lodash'

export default function A21({
    certifications,
    onChangeCertifications,
    saveCertifications,
    saveDocument,
    form,
    returnHeaders,
    mfsQuestionarie
}) {
    const { findlanguageEsg } = useContext(CoreContext)

    //#region A.2.1
    const a21 = {
        ref: 'A.2.1',
        requirementTitle: findlanguageEsg().a21RequirementTitle,
        inputForYesAnswer: true,
        placeHolderTextYes: findlanguageEsg().a21PlaceHolderTextYes,
        inputForNoAnswer: false,
        placeHolderTextNo: findlanguageEsg().a21PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    //#region A.2.1 Yes

    //#region A.2.1.Y.A

    const a21ya = {
        ref: 'A.2.1.Y.A',
        requirementTitle: findlanguageEsg().a21yaRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a21yaPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a21yay = {
        ref: 'A.2.1.Y.A.Y',
        requirementTitle: findlanguageEsg().a21yayRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a21yayPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const a21yayy = {
        ref: 'A.2.1.Y.A.Y.Y',
        requirementTitle: findlanguageEsg().a21yayyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a21yayyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
    }

    const a21yayyql1 = {
        ref: 'A.2.1.Y.A.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().a21yayyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a21yayyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        fileForYesAnswerAndNoInput: true,
        color: '#2f8c5d',
        question: true,
    }

    //#endregion

    //#region A.2.1.Y.B

    const a21yb = {
        ref: 'A.2.1.Y.B',
        requirementTitle: findlanguageEsg().a21ybRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a21ybPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a21yby = {
        ref: 'A.2.1.Y.B.Y',
        requirementTitle: findlanguageEsg().a21ybyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: findlanguageEsg().a21ybyPlaceHolderTextYes,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a21ybyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const a21ybyyqt1 = {
        ref: 'A.2.1.Y.B.Y.Y.QT.1',
        requirementTitle: findlanguageEsg().a21ybyyqt1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const a21ybyyqt2 = {
        ref: 'A.2.1.Y.B.Y.Y.QT.2',
        requirementTitle: findlanguageEsg().a21ybyyqt2RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const a21ybyyqt3 = {
        ref: 'A.2.1.Y.B.Y.Y.QT.3',
        requirementTitle: findlanguageEsg().a21ybyyqt3RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const a21ybyyqt4 = {
        ref: 'A.2.1.Y.B.Y.Y.QT.4',
        requirementTitle: findlanguageEsg().a21ybyyqt4RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const a21ybyyqt5 = {
        ref: 'A.2.1.Y.B.Y.Y.QT.5',
        requirementTitle: findlanguageEsg().a21ybyyqt5RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const a21ybyyqt6 = {
        ref: 'A.2.1.Y.B.Y.Y.QT.6',
        requirementTitle: findlanguageEsg().a21ybyyqt6RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const a21ybyyqtn = {
        ref: 'A.2.1.Y.B.Y.Y.QT.N',
        requirementTitle: findlanguageEsg().a21ybyyqtnRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const a21ybyyql1 = {
        ref: 'A.2.1.Y.B.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().a21ybyyqtl1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a21ybyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.C

    const a21yc = {
        ref: 'A.2.1.Y.C',
        requirementTitle: findlanguageEsg().a21ycRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a21ycPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a21ycy = {
        ref: 'A.2.1.Y.C.Y',
        requirementTitle: findlanguageEsg().a21ycyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a21ycyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a21ycyy = {
        ref: 'A.2.1.Y.C.Y.Y',
        requirementTitle: findlanguageEsg().a21ycyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a21ycyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a21ycyyql1 = {
        ref: 'A.2.1.Y.C.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().a21ycyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a21ycyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.D

    const a21yd = {
        ref: 'A.2.1.Y.D',
        requirementTitle: findlanguageEsg().a21ydRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a21ydPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a21ydy = {
        ref: 'A.2.1.Y.D.Y',
        requirementTitle: findlanguageEsg().a21ydyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a21ydyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a21ydyy = {
        ref: 'A.2.1.Y.D.Y.Y',
        requirementTitle: findlanguageEsg().a21ydyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a21ydyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a21ydyyql1 = {
        ref: 'A.2.1.Y.D.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().a21ydyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a21ydyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#endregion

    //#region A.1.1 NO
    const a21na = {
        ref: 'A.2.1.N.A',
        requirementTitle: findlanguageEsg().a21naRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a21naPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const a21nb = {
        ref: 'A.2.1.N.B',
        requirementTitle: findlanguageEsg().a21nbRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a21nbPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const a21nc = {
        ref: 'A.2.1.N.C',
        requirementTitle: findlanguageEsg().a21ncRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a21ncPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    const returnCondition = (item, layers, category1, category2, category3, category4) => {
        switch (layers) {
            case 0:
                return (
                    <Card
                        item={item}
                        onChangeCertifications={onChangeCertifications}
                        saveCertifications={saveCertifications}
                        saveDocument={saveDocument}
                        certifications={certifications}
                        form={form}
                        mfsQuestionarie={mfsQuestionarie}
                    />
                )
                break
            case 1:
                if (certifications?.find((x) => x.category === category1)?.next_level === true)
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 2:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 3:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 4:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true &&
                    certifications?.find((x) => x.category === category4)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            default:
                break
        }
    }

    return (
        <>
            <PageCard>
                <CardHeader>
                    <CardHeaderTitleContainer>{findlanguageEsg().a21CardHeaderTitleContainer}</CardHeaderTitleContainer>
                </CardHeader>
                {returnHeaders()}
                {returnCondition(a21, 0)}
            </PageCard>
            {
                mfsQuestionarie !== '1' ?
                    <>

                        {certifications?.find((x) => x.category === 'A.2.1')?.next_level === true ? (
                            <>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            A.2.1 ENERGY EFFICIENCY - Answer Yes - A
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(a21ya, 1, 'A.2.1')}
                                    {returnCondition(a21yay, 2, 'A.2.1', 'A.2.1.Y.A')}
                                    {returnCondition(a21yayy, 3, 'A.2.1', 'A.2.1.Y.A', 'A.2.1.Y.A.Y')}
                                    {returnCondition(
                                        a21yayyql1,
                                        4,
                                        'A.2.1',
                                        'A.2.1.Y.A',
                                        'A.2.1.Y.A.Y',
                                        'A.2.1.Y.A.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            A.2.1 ENERGY EFFICIENCY - Answer Yes - B
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(a21yb, 1, 'A.2.1')}
                                    {returnCondition(a21yby, 2, 'A.2.1', 'A.2.1.Y.B')}
                                    {returnCondition(a21ybyyqt1, 3, 'A.2.1', 'A.2.1.Y.B', 'A.2.1.Y.B.Y')}
                                    {returnCondition(a21ybyyqt2, 3, 'A.2.1', 'A.2.1.Y.B', 'A.2.1.Y.B.Y')}
                                    {returnCondition(a21ybyyqt3, 3, 'A.2.1', 'A.2.1.Y.B', 'A.2.1.Y.B.Y')}
                                    {returnCondition(a21ybyyqt4, 3, 'A.2.1', 'A.2.1.Y.B', 'A.2.1.Y.B.Y')}
                                    {returnCondition(a21ybyyqt5, 3, 'A.2.1', 'A.2.1.Y.B', 'A.2.1.Y.B.Y')}
                                    {returnCondition(a21ybyyqt6, 3, 'A.2.1', 'A.2.1.Y.B', 'A.2.1.Y.B.Y')}
                                    {returnCondition(a21ybyyqtn, 3, 'A.2.1', 'A.2.1.Y.B', 'A.2.1.Y.B.Y')}
                                    {returnCondition(a21ybyyql1, 3, 'A.2.1', 'A.2.1.Y.B', 'A.2.1.Y.B.Y')}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            A.2.1 ENERGY EFFICIENCY - Answer Yes - C
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(a21yc, 1, 'A.2.1')}
                                    {returnCondition(a21ycy, 2, 'A.2.1', 'A.2.1.Y.C')}
                                    {returnCondition(a21ycyy, 3, 'A.2.1', 'A.2.1.Y.C', 'A.2.1.Y.C.Y')}
                                    {returnCondition(
                                        a21ycyyql1,
                                        4,
                                        'A.2.1',
                                        'A.2.1.Y.C',
                                        'A.2.1.Y.C.Y',
                                        'A.2.1.Y.C.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            A.2.1 ENERGY EFFICIENCY - Answer Yes - D
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(a21yd, 1, 'A.2.1')}
                                    {returnCondition(a21ydy, 2, 'A.2.1', 'A.2.1.Y.D')}
                                    {returnCondition(a21ydyy, 3, 'A.2.1', 'A.2.1.Y.D', 'A.2.1.Y.D.Y')}
                                    {returnCondition(
                                        a21ydyyql1,
                                        4,
                                        'A.2.1',
                                        'A.2.1.Y.D',
                                        'A.2.1.Y.D.Y',
                                        'A.2.1.Y.D.Y.Y'
                                    )}
                                </PageCard>
                            </>
                        ) : null}
                        {certifications?.find((x) => x.category === 'A.2.1')?.next_level === false ? (
                            <PageCard>
                                <CardHeader>
                                    <CardHeaderTitleContainer>
                                        A.2.1 ENERGY EFFICIENCY - Answer No
                                    </CardHeaderTitleContainer>
                                </CardHeader>
                                {returnHeaders()}
                                {returnCondition(a21na, 0)}
                                {returnCondition(a21nb, 0)}
                                {returnCondition(a21nc, 0)}
                            </PageCard>
                        ) : null}
                    </>
                    :
                    null
            }
        </>
    )
}
