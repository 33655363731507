import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const Content = styled.div.attrs({})``

export const LoadSpammer = styled.span.attrs({})`
    display: inline-block;
    margin-left: 4px;
`

export const PageHeader = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 20px;
`

export const IconButton = styled.div.attrs({})`
    background: #303030;
    border-radius: 99px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

export const PageHeaderTitle = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: var(--grey-color);
`
export const PageHeaderActions = styled.div.attrs({})`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;
`

export const ContentFilters = styled.div.attrs({})`
    min-width: 400px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
`

export const ReadIcon = styled.img.attrs({
    src: '/icons/read.png',
    height: 20,
    width: 20,
})`
    margin: 0 0.5rem;
    cursor: pointer;
    filter: brightness(0) invert(1);
`

export const FilterContent = styled.div.attrs({})`
    display: flex;
    align-items: center;
`
export const FilterText = styled.div.attrs({})`
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--grey-color);
    ${(props) =>
        props.light
            ? `
            color: var(--lightgrey-color);
        `
            : ``}
`

export const ContentScroll = styled.div.attrs({})``

//    overflow: auto;
//    max-height: 450px;

export const ScrollWrapper = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    @media (max-width: ${mobileSize}px) {
        flex-direction: column;
    }
`

//  justify-content: space-between;
