import './styled.css'

import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'

export default function DashboardFilterScrapingCard({ item, openModal }) {
    const { findlanguage } = useContext(CoreContext)
    let imageUrl = ''
    let style = {}

    switch (item.country) {
        case 'Brazil':
            imageUrl =
                'https://upload.wikimedia.org/wikipedia/commons/4/4a/Brazilian_flag_icon_round.svg'
            break
        case 'United States':
            imageUrl =
                'https://upload.wikimedia.org/wikipedia/commons/8/88/United-states_flag_icon_round.svg'
            break
        case 'Mexico':
            imageUrl = 'https://upload.wikimedia.org/wikipedia/commons/c/c0/Mexico_flag_icon.svg'
            break
        case 'United Kingdom':
            imageUrl =
                'https://upload.wikimedia.org/wikipedia/commons/1/13/United-kingdom_flag_icon_round.svg'
            break
        case 'China':
            imageUrl =
                'https://upload.wikimedia.org/wikipedia/commons/a/ae/Circle_Flag_of_the_People%27s_Republic_of_China.svg'
            break
        case 'Italy':
            imageUrl = 'https://upload.wikimedia.org/wikipedia/commons/4/43/Flag-italy.png'
            break
        case 'Canada':
            imageUrl = 'https://upload.wikimedia.org/wikipedia/commons/9/97/Circle-icons-Canada.svg'
            break
        case 'Spain':
            imageUrl = 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Spain_flag_icon.svg'
            break
        case 'Netherlands':
            style = { transform: 'rotate(180deg)' }
            imageUrl =
                'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/Roundel_of_Yugoslavia_%281992%E2%80%932003%29.svg/800px-Roundel_of_Yugoslavia_%281992%E2%80%932003%29.svg.png'
            break
        default:
            imageUrl =
                'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b0/No_flag.svg/225px-No_flag.svg.png?20230521144040'
            break
    }

    return (
        <>
            <div className="col-md-6 mb-4">
                <div className="user-card">
                    <div className="user-card-header">
                        <img
                            className="user-avatar"
                            src="https://upload.wikimedia.org/wikipedia/commons/1/1b/Semi-protection-shackle.svg"
                            alt="name"
                        />
                        <div className="user-info">
                            <div className="user-name">{item.country}</div>
                            <div className="user-role">{item.state}</div>
                            <div className="user-role">{item.city}</div>
                        </div>
                        <div className="user-earnings">
                            <span className="earnings-amount">{findlanguage()?.businessType}</span>
                            <img
                                className="country-rank-img2"
                                src={imageUrl}
                                alt="Flag"
                                style={{ ...style }}
                            />
                            <span className="earnings-period">{item?.role} </span>
                            <span
                                className="earnings-period"
                                style={{ marginLeft: '10px', marginRight: '10px' }}
                            >
                                {item?.businessType}
                            </span>
                            <img
                                src="https://assets.codepen.io/3685267/res-react-dash-bull.svg"
                                alt=""
                                className="w-8 h-8"
                            />
                        </div>
                    </div>
                    <div className="user-card-footer">
                        <div className="task-progress">
                            <span>
                                {item?.product}
                            </span>
                            <div className="">
                                <button
                                    className="btn button-contact"
                                    style={{ marginLeft: '-2px' }}
                                    onClick={openModal}
                                >
                                    {findlanguage().viewProfile}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
