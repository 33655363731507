import React, { useState, useEffect, useRef, useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import nlp from 'compromise'
import Typist from 'react-typist'
import 'react-typist/dist/Typist.css'
import { ReadCountries } from 'services/location'
import { ReadSupplierProductsSource } from 'services/infos'

function findSimilar(input, list) {
    if (!list) return null

    const isArrayOfStrings = typeof list[0] === 'string'
    let foundItem

    if (isArrayOfStrings) {
        foundItem = list.find((item) => item.toLowerCase().includes(input))
        if (!foundItem) {
            const words = input.split(' ')
            foundItem = list.find((item) =>
                words.some((word) => item.toLowerCase().startsWith(word))
            )
        }
    } else {
        foundItem = list.find((item) => item.label.toLowerCase().includes(input))
        if (!foundItem) {
            const words = input.split(' ')
            foundItem = list.find((item) =>
                words.some((word) => item.label.toLowerCase().startsWith(word))
            )
        }
    }

    return foundItem
}

export default function AIFreeForm({ changeScreen }) {
    const { findlanguage, setFilter, language } = useContext(CoreContext)
    const [userInput, setUserInput] = useState('')
    const [queryw, setQueryw] = useState({})
    const [suggestion, setSuggestion] = useState('')
    const [botMessage, setBotMessage] = useState(
        'Hi, type in your search query in free form. For example, "I want Turkey Breasts from Charlotte."'
    )
    const [isUserTyping, setIsUserTyping] = useState(false)
    const typingTimeoutRef = useRef(null)
    const [products, setProducts] = useState([])
    const [country, setCountries] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const productsDb = await ReadSupplierProductsSource()

            let countriesDb = []
            let a = []

            if (language === 'portugues') {
                productsDb.forEach((x) =>
                    x.items.map((y) =>
                        a.push({
                            value: y.name,
                            label: y.name_pt,
                        })
                    )
                )
                countriesDb = await ReadCountries().then((resp) =>
                    resp.map((item) => ({
                        ...item,
                        label: item.name_pt,
                        title: item.name,
                        value: item.iso2,
                    }))
                )
            } else {
                productsDb.forEach((x) =>
                    x.items.map((y) =>
                        a.push({
                            value: y.name,
                            label: y.name,
                        })
                    )
                )

                countriesDb = await ReadCountries().then((resp) =>
                    resp.map((item) => ({
                        ...item,
                        label: item.name,
                        title: item.name,
                        value: item.iso2,
                    }))
                )
            }

            setProducts(a)
            setCountries(countriesDb)
        }

        fetchData()
    }, [])

    const handleChange = (e) => {
        setUserInput(e.target.value)
        setIsUserTyping(true)
        const words = e.target.value.toLowerCase().split(' ')
        const lastWord = words[words.length - 1]

        if (lastWord.length === 0) {
            setSuggestion('')
        } else {
            const foundSuggestion = [...products, ...country].find((item) =>
                findSimilar(lastWord, [item])
            )

            setSuggestion(
                foundSuggestion
                    ? typeof foundSuggestion === 'string'
                        ? foundSuggestion
                        : e.target.value.slice(0, e.target.value.lastIndexOf(' ') + 1) +
                          foundSuggestion.label
                    : ''
            )
        }

        clearTimeout(typingTimeoutRef.current)
        typingTimeoutRef.current = setTimeout(() => {
            generateBotMessage(e.target.value)
            setIsUserTyping(false)
        }, 500)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Tab' && suggestion) {
            e.preventDefault()
            const newPartOfSuggestion = suggestion.slice(userInput.length)
            setUserInput(userInput + newPartOfSuggestion)
            setSuggestion('')
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    function createQuery(userInput) {
        let queryParams = { products: [], country: [] }

        // Split userInput into phrases by "and", "or", ","
        const phrases = userInput.split(/( and | or |,)/g)

        phrases.forEach((phrase) => {
            let doc = nlp(phrase)
            let phraseIncludesProductLabel = false

            for (const item of products) {
                const itemWords = item.label.split(' ')
                const matchingPhrase = itemWords.every((word) => doc.has(word))
                if (matchingPhrase) {
                    queryParams.products.push(item)
                    phraseIncludesProductLabel = true
                }
            }

            for (const item of country) {
                if (doc.has(item.label) && !phraseIncludesProductLabel) {
                    queryParams.country.push(item)
                }
            }
        })

        return queryParams
    }

    function isPartOfProductName(countryName, products) {
        return products.some((product) => product.label.includes(countryName))
    }

    function generateBotMessage(input) {
        if (input.length === 0) {
            setBotMessage('Type something to create a query.')
            return
        }

        const newQuery = createQuery(input)
        let message = 'Okay, I understand.'

        if (newQuery.city) message += ` In the city of ${newQuery.city}, right?`

        if (newQuery.country.length > 0) {
            const countryNames = newQuery.country.map((country) => country.label).join(', ')
            message += ` In ${countryNames}, I see.`
        }

        if (newQuery.category) message += ` You're interested in ${newQuery.category}, got it.`

        if (newQuery.products.length > 0) {
            const productNames = newQuery.products.map((product) => product.label).join(', ')
            message += ` So, you're looking for ${productNames}, correct?`
        }

        if (newQuery.certification)
            message += ` With ${newQuery.certification} certification, noted.`
        if (newQuery.zip) message += ` In the area of ZIP code ${newQuery.zip}, understood.`
        if (newQuery.radius) message += ` In a radius of ${newQuery.radius} miles, alright.`

        message += ' Is this all correct? Click submit to see results.'

        setBotMessage(message)
        setQueryw(newQuery)
    }

    useEffect(() => {
        generateBotMessage(userInput)
    }, [])

    const seeResults = () => {
        const newQuery = createQuery(userInput)
        changeScreen(0)
        setFilter(newQuery)
    }

    return (
        <div className="container py-5">
            <div className="bg-white rounded p-5 shadow">
                <div className="form-group">
                    <div className="bot-message">
                        {isUserTyping ? (
                            <Typist key="typing" avgTypingDelay={25}>
                                ...
                            </Typist>
                        ) : (
                            <Typist key={botMessage} avgTypingDelay={25}>
                                {botMessage}
                            </Typist>
                        )}
                    </div>
                    <label htmlFor="userInput" className="form-label">
                        {findlanguage().search}
                    </label>
                    <div style={{ position: 'relative', marginBottom: '1rem' }}>
                        <textarea
                            id="userInputBg"
                            value={suggestion}
                            disabled
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100px',
                                backgroundColor: 'transparent',
                                color: '#ddd',
                                zIndex: 1,
                            }} // changed color to #ddd (light gray)
                            className="form-control"
                        />
                        <textarea
                            id="userInput"
                            value={userInput}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100px',
                                backgroundColor: 'transparent',
                                color: 'black',
                                zIndex: 2,
                            }}
                            className="form-control"
                        />
                    </div>
                    <div style={{ marginTop: '115px' }}>
                        <button type="submit" className="btn btn-primary" onClick={seeResults}>
                            Submit
                        </button>
                    </div>
                </div>
                <pre className="mt-5">{JSON.stringify(queryw, null, 2)}</pre>
            </div>
        </div>
    )
}
