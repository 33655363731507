import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { HomeActions, ActionItem, ActionItemIcon } from './styled'

import ContainerAuthenticated from 'containers/Authenticated'
import { Container, Row, Col } from 'reactstrap'

import DashboardUserProfile from 'components/Dashboard/UserProfile'
import DashboardPanelRecommended from 'components/Dashboard/Panels/Recommended'
import DashboardPanelMyWish from 'components/Dashboard/Panels/MyWish'
import DashboardPanelMessage from 'components/Dashboard/Panels/Messages'
import DashboardPanelViews from 'components/Dashboard/Panels/Views'
import DashboardPanelSVP from 'components/Dashboard/Panels/SVP'
import DashboardPanelSGS from 'components/Dashboard/Panels/SGS'
import DashboardPanelESG from 'components/Dashboard/Panels/ESG'
import DashboardPanelSupplierPerCategory from 'components/Dashboard/Panels/SupplierPerCategory'
import DashboardPanelClientsPerBusiness from 'components/Dashboard/Panels/ClientsPerBusiness'
import DashboardPanelDocumentControl from 'components/Dashboard/Panels/DocumentControl'
import DashboardPanelExpiredDocument from 'components/Dashboard/Panels/ExpiredDocument'
import DashboardPanelSupplierMap from 'components/Dashboard/Panels/SupplierMap'
import DashboardPanelSupplierPerRegion from 'components/Dashboard/Panels/SupplierPerRegion'
import DashboardPanelClientsPerRegion from 'components/Dashboard/Panels/ClientsPerRegion'

import { CoreContext } from 'context/CoreContext'

import DashboardPanelClientsMap from 'components/Dashboard/Panels/ClientsMap'
import DashboardPanelMyProducts from 'components/Dashboard/Panels/MyProducts'
import DashboardPanelMyClients from 'components/Dashboard/Panels/MyClients'
import DashboardPanelMessageSupplier from 'components/Dashboard/Panels/MessagesSupplier'

export default function DashboardHome() {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)
    const {
        user,
        profile,
        userPanels,
        setUserPanels,
        setModal,
        premium,
        findlanguage,
        permissions,
    } = useContext(CoreContext)

    const panelsRef = {
        // recommended: { component: DashboardPanelRecommended, size: 12 },
        svp: { component: DashboardPanelSVP, size: 6 },
        sgs: { component: DashboardPanelSGS, size: 6 },
        'supplier-per-category': { component: DashboardPanelSupplierPerCategory, size: 6 },
        'document-control': { component: DashboardPanelDocumentControl, size: 6 },
        'expired-document': { component: DashboardPanelExpiredDocument, size: 6 },
        'supplier-per-region': { component: DashboardPanelSupplierPerRegion, size: 6 },
        'clients-per-region': { component: DashboardPanelClientsPerRegion, size: 6 },
        'clients-per-business': { component: DashboardPanelClientsPerBusiness, size: 6 },
        'my-products': { component: DashboardPanelMyProducts, size: 6 },
        'my-clients': { component: DashboardPanelMyClients, size: 6 },
        'message-supplier': { component: DashboardPanelMessage, size: 6 },
        // esg: { component: DashboardPanelESG, size: 6 },
        'supplier-map': { component: DashboardPanelSupplierMap, size: 12 },
        'clients-map': { component: DashboardPanelClientsMap, size: 12 },
    }

    if (permissions?.wishlist?.showOnDashboard) {
        panelsRef['my-wish'] = { component: DashboardPanelMyWish, size: 6 }
    }
    if (permissions?.message?.showOnDashboard) {
        panelsRef['message'] = { component: DashboardPanelMessage, size: 6 }
    }
    if (permissions?.profileViews?.showOnDashboard) {
        panelsRef['views'] = { component: DashboardPanelViews, size: 12 }
    }
    //if (permissions?.myBuyers?.showOnDashboard) {
    //    panelsRef['my-wish'] = { component: DashboardPanelMyWish, size: 6 };
    //}

    const [activeTab, setActiveTab] = useState(
        permissions?.myProfile?.canViewBuyer ? 'buyer' : 'supplier'
    )

    const handleClose = (ref) => {
        const profilePanels = userPanels.map((item) => {
            if (item.type === profile) {
                return {
                    ...item,
                    panels: item.panels.filter((fit) => fit.ref !== ref),
                }
            }
            return item
        })

        setUserPanels(profilePanels)
    }

    const openWidget = () => {
        setModal({
            type: 'widgets',
            title: findlanguage().myWidgets,
        })
    }

    const openChat = () => {
        setModal({ type: 'chat' })
    }

    return (
        <>
            <ContainerAuthenticated>
                <Container fluid={false}>
                    <DashboardUserProfile />
                    <Row>
                        {profile === 'admin' || profile === 'buyer-supplier' ? (
                            <>
                                {userPanels
                                    ?.find((fnd) => fnd.type === 'basic')
                                    ?.panels // .filter(panel => availablePanels.includes(panel.ref))
                                    .map((userPanelRef, kz) => {
                                        const psz = panelsRef[userPanels[kz - 1]?.ref]?.size || null
                                        const nsz = panelsRef[userPanels[kz + 1]?.ref]?.size || null

                                        const size =
                                            panelsRef[userPanelRef?.ref]?.size === 6 &&
                                            psz === 12 &&
                                            nsz === 12
                                                ? 12
                                                : panelsRef[userPanelRef?.ref]?.size || 12

                                        const Panel =
                                            panelsRef[userPanelRef?.ref]?.component || null
                                        return !Panel ? null : (
                                            <Col key={kz} lg={{ size: 12 }} xl={{ size }}>
                                                <Panel
                                                    cardTitle={userPanelRef?.title}
                                                    onClick={() => {}}
                                                    onClose={() => handleClose(userPanelRef?.ref)}
                                                />
                                            </Col>
                                        )
                                    })}
                                <div
                                    id="tab-component-dashboard"
                                    className="tab-component-dashboard"
                                >
                                    <div className="tab-header">
                                        {permissions?.myProfile?.canViewBuyer ? (
                                            <button
                                                className={`tab-button ${
                                                    activeTab === 'buyer' ? 'active' : ''
                                                } tab-button-right`}
                                                onClick={() => setActiveTab('buyer')}
                                            >
                                                Buyer
                                            </button>
                                        ) : null}
                                        {permissions?.myProfile?.canViewSupplier ? (
                                            <button
                                                className={`tab-button ${
                                                    activeTab === 'supplier' ? 'active' : ''
                                                }`}
                                                onClick={() => setActiveTab('supplier')}
                                            >
                                                Supplier
                                            </button>
                                        ) : null}
                                    </div>
                                </div>
                                {activeTab === 'buyer' && permissions?.myProfile?.canViewBuyer
                                    ? userPanels
                                          ?.find((fnd) => fnd.type === 'buyerAdmin')
                                          ?.panels // .filter(panel => availablePanels.includes(panel.ref))
                                          .map((userPanelRef, kz) => {
                                              const psz =
                                                  panelsRef[userPanels[kz - 1]?.ref]?.size || null
                                              const nsz =
                                                  panelsRef[userPanels[kz + 1]?.ref]?.size || null

                                              const size =
                                                  panelsRef[userPanelRef?.ref]?.size === 6 &&
                                                  psz === 12 &&
                                                  nsz === 12
                                                      ? 12
                                                      : panelsRef[userPanelRef?.ref]?.size || 12

                                              const Panel =
                                                  panelsRef[userPanelRef?.ref]?.component || null
                                              return !Panel ? null : (
                                                  <Col key={kz} lg={{ size: 12 }} xl={{ size }}>
                                                      <Panel
                                                          style={{
                                                              borderTopLeftRadius:
                                                                  kz === 0 ? 0 : 22,
                                                          }}
                                                          cardTitle={userPanelRef?.title}
                                                          onClick={() => {}}
                                                          onClose={() =>
                                                              handleClose(userPanelRef?.ref)
                                                          }
                                                      />
                                                  </Col>
                                              )
                                          })
                                    : userPanels
                                          ?.find((fnd) => fnd.type === 'supplierAdmin')
                                          ?.panels // .filter(panel => availablePanels.includes(panel.ref))
                                          .map((userPanelRef, kz) => {
                                              const psz =
                                                  panelsRef[userPanels[kz - 1]?.ref]?.size || null
                                              const nsz =
                                                  panelsRef[userPanels[kz + 1]?.ref]?.size || null

                                              const size =
                                                  panelsRef[userPanelRef?.ref]?.size === 6 &&
                                                  psz === 12 &&
                                                  nsz === 12
                                                      ? 12
                                                      : panelsRef[userPanelRef?.ref]?.size || 12

                                              const Panel =
                                                  panelsRef[userPanelRef?.ref]?.component || null
                                              return !Panel ? null : (
                                                  <Col key={kz} lg={{ size: 12 }} xl={{ size }}>
                                                      <Panel
                                                          cardTitle={userPanelRef?.title}
                                                          onClick={() => {}}
                                                          onClose={() =>
                                                              handleClose(userPanelRef?.ref)
                                                          }
                                                      />
                                                  </Col>
                                              )
                                          })}
                            </>
                        ) : null}

                        {profile === 'administrative' ? (
                            <>
                                {userPanels
                                    ?.find((fnd) => fnd.type === 'basic')
                                    ?.panels // .filter(panel => availablePanels.includes(panel.ref))
                                    .map((userPanelRef, kz) => {
                                        const psz = panelsRef[userPanels[kz - 1]?.ref]?.size || null
                                        const nsz = panelsRef[userPanels[kz + 1]?.ref]?.size || null

                                        const size =
                                            panelsRef[userPanelRef?.ref]?.size === 6 &&
                                            psz === 12 &&
                                            nsz === 12
                                                ? 12
                                                : panelsRef[userPanelRef?.ref]?.size || 12

                                        const Panel =
                                            panelsRef[userPanelRef?.ref]?.component || null
                                        return !Panel ? null : (
                                            <Col key={kz} lg={{ size: 12 }} xl={{ size }}>
                                                <Panel
                                                    cardTitle={userPanelRef?.title}
                                                    onClick={() => {}}
                                                    onClose={() => handleClose(userPanelRef?.ref)}
                                                />
                                            </Col>
                                        )
                                    })}
                            </>
                        ) : null}
                        {!userPanels?.find((fnd) => fnd.type === profile)?.panels
                            ? null
                            : userPanels
                                  ?.find((fnd) => fnd.type === profile)
                                  ?.panels // .filter(panel => availablePanels.includes(panel.ref))
                                  .map((userPanelRef, kz) => {
                                      const psz = panelsRef[userPanels[kz - 1]?.ref]?.size || null
                                      const nsz = panelsRef[userPanels[kz + 1]?.ref]?.size || null

                                      const size =
                                          panelsRef[userPanelRef?.ref]?.size === 6 &&
                                          psz === 12 &&
                                          nsz === 12
                                              ? 12
                                              : panelsRef[userPanelRef?.ref]?.size || 12

                                      const Panel = panelsRef[userPanelRef?.ref]?.component || null
                                      return !Panel ? null : (
                                          <Col key={kz} lg={{ size: 12 }} xl={{ size }}>
                                              <Panel
                                                  cardTitle={userPanelRef?.title}
                                                  onClick={() => {}}
                                                  onClose={() => handleClose(userPanelRef?.ref)}
                                              />
                                          </Col>
                                      )
                                  })}
                    </Row>
                </Container>

                <HomeActions>
                    {/* <ActionItem onClick={openChat}>
                        <ActionItemIcon icon={"chat"} />
                    </ActionItem> */}
                    <ActionItem primary onClick={openWidget}>
                        <ActionItemIcon icon={'cog'} />
                    </ActionItem>
                </HomeActions>
            </ContainerAuthenticated>
        </>
    )
}
