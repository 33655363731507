import React from 'react'

import { DummyInputStyled } from './styled'

/**
 * Mimics the same style (size) as Inputs (so it keeps the same position)
 * TODO: input should not have specific size, it should be defined by the parent.
 */
export default function DummyInput(props) {
    return <DummyInputStyled {...props} />
}
