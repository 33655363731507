import styled from 'styled-components'

export const ProfileFormContent = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: space-evenly;
    padding: 40px 0 20px;
`

export const ProfileFormActions = styled.div.attrs({})`
    display: flex;
    justify-content: center;
    width: 100%;
`
export const PageFormSpace = styled.div.attrs({})`
    margin-right: 12px;
`

export const PageTitle = styled.div.attrs({})`
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    color: var(--grey-color);
    text-align: center;

    max-width: 260px;
`
export const PageText = styled.div.attrs({})`
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    color: var(--lightgrey-color);
    text-align: center;

    margin: 30px 0;
    max-width: 260px;
`
export const PageIcon = styled.img.attrs({
    src: '/icons/exclamation.svg',
})`
    margin: 0 0 20px 0;
`
