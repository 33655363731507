import React, { useState, useMemo, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const ExpiredRequirementChart = ({ showDataLabels, usersWithForms }) => {
    const [selectedThreshold, setSelectedThreshold] = useState(45);
    const tooltipDataRef = useRef({});

    const thresholds = [45, 30, 20, 15, 10, 5, 3, 1];

    const chartData = useMemo(() => {
        const now = new Date();
        now.setHours(0, 0, 0, 0);

        const expirationCounts = thresholds.reduce((acc, days) => ({ ...acc, [`${days} Days Ago`]: {} }), {});

        usersWithForms.forEach(doc => {
            if (doc.status === 'Accepted' && doc.expirationDate) {
                const expirationDate = new Date(doc.expirationDate);
                const diffTime = now - expirationDate;
                const daysExpired = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                if (daysExpired > 0 && daysExpired <= selectedThreshold) {
                    thresholds.forEach(threshold => {
                        if (daysExpired <= threshold && threshold <= selectedThreshold) {
                            const formName = doc.form.name;
                            if (!expirationCounts[`${threshold} Days Ago`][formName]) {
                                expirationCounts[`${threshold} Days Ago`][formName] = 0;
                            }
                            expirationCounts[`${threshold} Days Ago`][formName]++;
                        }
                    });
                }
            }
        });

        tooltipDataRef.current = expirationCounts;

        const filteredLabels = Object.keys(expirationCounts).filter(key => {
            const total = Object.values(expirationCounts[key]).reduce((a, b) => a + b, 0);
            return total > 0 && parseInt(key.split(' ')[0]) <= selectedThreshold;
        });

        const filteredData = filteredLabels.map(key => {
            return Object.values(expirationCounts[key]).reduce((a, b) => a + b, 0);
        });

        return {
            labels: filteredLabels,
            datasets: [{
                data: filteredData,
                backgroundColor: [
                    '#7189bf', '#df7599', '#ffc785', '#72d6c9', '#90d7ff', '#3f8cb5', '#ffe156', '#3f88c5'
                ],
                borderColor: '#ffffff',
                borderWidth: 2
            }]
        };
    }, [usersWithForms, selectedThreshold]);

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    usePointStyle: true,
                }
            },
            tooltip: {
                callbacks: {
                    title: function (tooltipItems) {
                        return `${tooltipItems[0].label}`;
                    },
                    label: function (tooltipItem) {
                        const label = tooltipItem.label;
                        const formCounts = tooltipDataRef.current[label];
                        const details = Object.entries(formCounts)
                            .map(([formName, count]) => `${formName}: ${count}`)
                            .join('\n');
                        return details;
                    }
                }
            },
            datalabels: {
                display: true,
                color: showDataLabels ? 'black' : 'white',
                align: 'center',
                font: {
                    weight: 'bold',
                    size: 14
                },
                formatter: (value, context) => {
                    if (showDataLabels) {
                        const label = context.chart.data.labels[context.dataIndex];
                        return `${label}: ${value}`;
                    } else {
                        return value;
                    }
                }
            }
        },
        layout: {
            padding: {
                bottom: 90
            }
        }
    };

    return (
        <div style={{ height: '385px' }}>
            <select value={selectedThreshold} onChange={(e) => setSelectedThreshold(parseInt(e.target.value))} style={{ marginBottom: '10px' }}>
                {thresholds.map(threshold => (
                    <option key={threshold} value={threshold}>{`${threshold} Days Ago`}</option>
                ))}
            </select>
            <h2>Expired Requirement</h2>
            <Doughnut data={chartData} options={options} plugins={[ChartDataLabels]} />
        </div>
    );
};

export default ExpiredRequirementChart;
