import React, { useState, useContext } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
    PageHeader,
    PageHeaderTitle,
    PageHeaderActions,
    PageHeaderBack,
    PageCard,
    ProfileContainer,
    ProfileContent,
    ProfileContentSpace,
    ProfileFormContent,
    PageScroller,
    PageWrapper,
    CardContent,
    CardContentInfo,
    CardContentImage,
    CardContentText,
    CardContentRemove,
} from './styled'

import ContainerAuthenticated from 'containers/Authenticated'

import Input from 'components/Form/Input'
import InputPassword from 'components/Form/InputPassword'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap'

import DashboardButton from 'components/Dashboard/Button'
import DashboardMore from 'components/Dashboard/More'
import { CoreContext } from 'context/CoreContext'

import { UpdatePassword } from 'services/authentication'
import { exposeStrapiErrorNew } from 'utils'
import { InputBlankContainer } from 'components/Form/Input/styled'

export default function DashboardAccountInformation() {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)
    const back = () => history.goBack()

    const { setModal, user, profile, findlanguage } = useContext(CoreContext)
    const [fieldValidation, setFieldValidation] = useState([])
    const [stateTooltipPassword, setStateTooltipPassword] = useState(false)
    const [loading, setLoading] = useState(false)

    const [currentPassword, setCurrentPassword] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const cards = [
        // { title:'Credit **** 1234', icon:'mastercard' },
        // { title:'Visa Electon Debit **** 1234', icon:'visa' },
    ]
    let currentDate = new Date()
    let formattedDate =
        currentDate.getFullYear() +
        '-' +
        String(currentDate.getMonth() + 1).padStart(2, '0') +
        '-' +
        String(currentDate.getDate()).padStart(2, '0')

    const moreOptions = [
        { title: findlanguage().termsOfUse, link: true, action: () => openTerms() },
        { title: findlanguage().privacyPolicy, link: true, action: () => openPolicy() },
        // {
        //     title: findlanguage().membershipAndSubscription,
        //     action: () => navigate('dashboard/account-subscription'),
        // },
    ]

    const openTerms = () => {
        setModal({
            type: 'terms',
            title: findlanguage().termsOfUse,
            text: `
TERMS OF USE AGREEMENT

Latest Version: ${formattedDate}

This Terms of Use Agreement (the “Agreement”) is made by and between you and MFSC LLC (the “Company”).  By accessing and using the Company’s website (the “Website”), you agree that you have read, understand, and agree to comply with and be bound by the following terms and conditions. 

Agreement. This Agreement specifies the terms and conditions for access to and use of the Website and describes the terms and conditions applicable to your access of and use of the Website. This Agreement may be amended, modified, revised, or updated at any time by the Company upon posting of the latest version of this Agreement on the Website. Any such amendments, modifications, revisions, or updates shall be effective immediately. You can view the latest version of this Agreement at any time at [www.mytrustedsc.com]. Each use by you shall constitute and be deemed your unconditional acceptance of this Agreement.

Privacy. Your access to and use of the Website is also governed by our Privacy Policy. Please review our Privacy Policy at [www.mytrustedsc.com].

Ownership. All content included on the Website is and shall continue to be the property of the Company or its content suppliers and is protected under applicable copyright, patent, trademark, and other proprietary rights. Any copying, redistribution, use or publication by you of any such content or any part of the Website is prohibited, except as expressly permitted in this Agreement. Under no circumstances will you acquire any ownership rights or other interest in any content by or through your use of the Website.

Trademarks. Trademarks, service marks, logos, copyrighted works, and other intellectual property appearing on the Website are the property of the Company or the party that provided such intellectual property on the Website. The Company and any party that provides intellectual property on the Website shall retain all rights with respect to any of their respective intellectual property appearing on the Website, and no rights in such materials are transferred or assigned to you.

Website Use. The Company grants you a limited, revocable, nonexclusive license to use the Website solely for your own personal use. You agree not to assign, collect, copy, distribute, display, license, modify, publish, reproduce, scrape, sell, send, transfer, reverse engineer, or create derivative works from any content, data, information, materials, software, intellectual property, products or services contained on or otherwise connected to the Website. You agree not to use any content, data, information, materials, software, intellectual property, products or services contained on or otherwise connected to the Website in violation of any law. Your access and use of the Website is at the discretion of the Company. The Company may terminate your access and use of the Website at any time.

Compliance with Laws. You agree to comply with all applicable laws regarding your use of the Website. You further agreed that information provided by you is truthful and accurate to the best of your knowledge.

Indemnification. You agree to indemnify, defend and hold the Company and our partners, employees, and affiliates, harmless from any liability, loss, claim and expense, including reasonable attorney's fees, related to your violation of this Agreement or use of the Website.

Disclaimer. THE INFORMATION ON THE WEBSITE IS PROVIDED ON AN "AS IS," "AS AVAILABLE" BASIS. YOU AGREE THAT USE OF THE WEBSITE IS AT YOUR SOLE RISK. THE COMPANY DISCLAIMS ALL WARRANTIES OF ANY KIND, INCLUDING BUT NOT LIMITED TO ANY EXPRESS WARRANTIES, STATUTORY WARRANTIES, AND ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. THE COMPANY DOES NOT WARRANT OR MAKE ANY REPRESENTATION REGARDING USE, OR THE RESULT OF USE, OF ANY PRODUCT, SERVICE OR INFORMATION IN TERMS OF ACCURACY, RELIABILITY, OR OTHERWISE. YOUR SOLE AND EXCLUSIVE REMEDY RELATING TO YOUR USE OF THE WEBSITE SHALL BE TO DISCONTINUE USING THE WEBSITE.

Limitation of Liability. UNDER NO CIRCUMSTANCES WILL THE COMPANY BE LIABLE OR RESPONSIBLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL (INCLUDING DAMAGES FROM LOSS OF BUSINESS, LOST PROFITS, LITIGATION, OR THE LIKE), SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN ANY WAY RELATING TO THE WEBSITE, YOUR WEBSITE USE, OR THE CONTENT, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE WEBSITE AND/OR CONTENT IS TO CEASE USING THE WEBSITE.

Use of Information. The Company reserves the right, and you authorize us, to use and assign all information regarding your use of the Website and all information provided by you in any manner consistent with our Privacy Policy.

Copyrights and Copyright Agent. If you believe your work has been copied in a way that constitutes copyright infringement, or your intellectual property rights have otherwise been violated, please provide a notice containing all of the following information to our Copyright Agent:

An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest;

A description of the copyrighted work that you claim has been infringed;

A description of where the material that you claim is infringing is located on the Website;

Your address, telephone number, and email address;

A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and

A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.

Our Copyright Agent for Notice of claims of copyright infringement on the Website is Valmir Rodrigues, who can be reached as follows:

By Mail: [753 Santolina Court, Waxhaw, NC, Zip Code 28173]

By Phone: [+ 980 229 3577]

By E-mail: [contact@myt-s.com]

Applicable Law. You agree that the laws of the state of North Carolina, without regard to conflicts of laws provisions, will govern this Agreement and any dispute that may arise between you and the Company or its affiliates.

Severability. If any provision of this Agreement shall be adjudged by any court of competent jurisdiction to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect.

Waiver. The failure of the Company to exercise or enforce any right or provision of this Agreement shall not operate as a waiver of such right or provision. Any waiver of this Agreement by the Company must be in writing and signed by an authorized representative of the Company.

Termination. The Company may terminate this Agreement at any time, with or without notice, for any reason.

Relationship of the Parties. Nothing contained in this Agreement or your access to and use of the Website shall be construed to constitute either party as a partner, joint venturer, employee or agent of the other party, nor shall either party hold itself out as such. Neither party has any right or authority to incur, assume or create, in writing or otherwise, any warranty, liability or other obligation of any kind, express or implied, in the name of or on behalf of the other party, it being intended by both parties that each shall remain independent contractors responsible for its own actions.

Age Restriction. You must be at least 18 years of age to access and use the Website. By accessing and using the Website, you are certifying that you are at least 18 years of age.

Entire Agreement. This Agreement constitutes the entire agreement between you and the Company and governs the terms and conditions of your access to and use of the Website, and supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and the Company with respect to the Website. Notwithstanding the foregoing, you may also be subject to additional terms and conditions, posted policies (including but not limited to the Privacy Policy), guidelines, or rules that may apply when you use the Website. The Company may amend, modify, revise, or update this Agreement at any time by posting the latest version on the Website. Accordingly, you should periodically review this Agreement on the Website to determine if any changes have been made. Your continued use of the Website after any changes have been made to this Agreement signifies and confirms your acceptance of any such changes to this Agreement.

Contact Information. Please contact us by mail, email or telephone if you have any questions about this Agreement. 

MFSC LLC

753 Santolina Court, 
Waxhaw, NC, Zip Code 28173
contact@myt-s.com
+ 980 229 3577


            `,
        })
    }

    const openPolicy = () => {
        setModal({
            type: 'terms',
            title: findlanguage().privacyPolicy,
            text: `PRIVACY POLICY

Latest Version: 06/04/2023

This privacy policy (the “Privacy Policy”) applies to any information collected by MFSC LLC (the “Company,” “we,” “us,” or “our”). We have created this Privacy Policy to tell you how and why we collect, store, use and share any personal information that may be collected through our Website (the “Website”), online directory, products and services. It also explains your rights in relation to your personal information and how to contact us or supervisory authorities in the event you have a complaint. By accessing or using our Website, online directory, products and services, you are agreeing to the terms and conditions of this Privacy Policy. This Privacy Policy may be updated from time to time, as necessary.

Who We Are. The Company collects, stores, uses, shares, and is responsible for certain personal information about you. When we do so we are subject to various laws in the United States, the General Data Protection Regulation (“GDPR”) which applies across the European Union (including in the United Kingdom), and the General Personal Data Protection Act (“LGPD”) which applies in Brazil, and we are responsible as “controller” of that personal information for the purposes of those laws.

Personal Information We Collect About You. We may collect the following personal information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household:

Contact information, including names, email addresses, mailing addresses, phone numbers, and fax numbers.

Professional or employment-related information, including products, services, certifications, and licenses;

Any and all other personal information that is provided via our Website, online directory, products, and services;

Commercial information, including records of products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies;

Credit card, debit card, checking account, billing address, or other payment information of customers and subscribers; and

Internet or other electronic network activity information, including browsing history, search history, and information regarding interactions with our Website, online directory, products, and services.

This personal information is required to provide our online directory, products, and services to you. If you do not provide personal information we ask for, it may delay or prevent us from providing our online directory, products, and services to you.

How Your Personal Information is Collected. We collect most of this personal information directly from you – in person, by telephone, email, or via our Website, online directory, products, and services. However, we may also collect information:

From publicly accessible sources;

From a third party with your consent; and

From cookies on our Website.

How and Why We Use Your Personal Information. Under data protection law, we can only use your personal information if we have a proper reason for doing so, e.g.,:

For the performance of our contracts;

For our legitimate interests or those of a third party;

To comply with our legal and regulatory obligations; and

Where you have given consent.

A legitimate interest is when we have a business or commercial reason to use your information, so long as this is not overridden by your own rights and interests.

Promotional Communications. We may use personal information to send you updates about our products and services, including exclusive offers, promotions or new products and services. We have a legitimate interest in processing your personal information for promotional purposes (see above “How and Why We Use Your Personal Information”). This means we do not usually need your consent to send you promotional communications. However, where consent is needed, we will ask for this consent separately and clearly. We will always treat personal information with the utmost respect and never sell or share it with other organizations for marketing purposes. You have the right to opt out of receiving promotional communications at any time by using the “unsubscribe” link in emails or “STOP” number in texts.

We may ask you to confirm or update your marketing preferences if you request further products or services in the future, or if there are changes in the law, regulation, or the structure of our business.

Who We Share Your Personal Information With. We share personal information with service providers we use to help deliver our online directory, products, and services to you.

We only allow our service providers to handle your personal information if we are satisfied they take appropriate measures to protect your personal information. We also impose contractual obligations on service providers relating to ensuring they can only use your personal information to provide services to us and to you.

We may disclose and exchange information with law enforcement agencies and regulatory bodies to comply with our legal and regulatory obligations.

We may also need to share some personal information with other parties, such as potential buyers of some or all of our business or during a re-structuring. We will typically anonymize information, but this may not always be possible. The recipient of the information will be bound by confidentiality obligations.

We will not share your personal information with any other third party, unless you authorize us to do so. 

Personal Information We Sold for a Business Purpose. In the preceding 12 months, we have sold to one or more third parties the following categories of personal information that identifies, relates to, describes, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household:

Identifiers (e.g., a real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, social security number, driver’s license number, passport number, or other similar identifiers);

Information that identifies, relates to, describes, or is capable of being associated with, a particular individual, including, but not limited to, his or her name, signature, social security number, physical characteristics or description, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information;

Characteristics of protected classifications under California or federal law;;

Internet or other electronic network activity information (e.g., browsing history, search history, and information regarding a consumer’s interaction with an Internet Web site, application, or advertisement);

Geolocation data;

Professional or employment-related information;

Education information, defined as information that is not publicly available personally identifiable information as defined in the Family Educational Rights and Privacy Act (FERPA); and

Inferences drawn from any of the information identified above to create a profile about a consumer reflecting the consumer’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.

Personal Information We Disclosed for a Business Purpose. In the preceding 12 months, we have disclosed for a business purpose to one or more third parties the following categories of personal information that identifies, relates to, describes, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household:

Identifiers (e.g., a real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, social security number, driver’s license number, passport number, or other similar identifiers);

Information that identifies, relates to, describes, or is capable of being associated with, a particular individual, including, but not limited to, his or her name, signature, social security number, physical characteristics or description, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information;

Characteristics of protected classifications under California or federal law;

Internet or other electronic network activity information (e.g., browsing history, search history, and information regarding a consumer’s interaction with an Internet Web site, application, or advertisement);

Geolocation data;

Professional or employment-related information;

Education information, defined as information that is not publicly available personally identifiable information as defined in the Family Educational Rights and Privacy Act (“FERPA”); and

Inferences drawn from any of the information identified above to create a profile about a consumer reflecting the consumer’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.

Where Your Personal Information is Held. Information may be held at our offices and those of our service providers as described above (see above: “Who We Share Your Personal Information with”). Some of these third parties may be based outside the European Economic Area. For more information, including on how we safeguard your personal information when this occurs, see below: “Transferring Your Personal Information Out of the EEA”.

How Long Your Personal Information Will Be Kept. We will keep your personal information while you have an account with us or while we are providing our online directory, products, and services to you. Thereafter, we will keep your personal information for as long as is necessary:

To respond to any questions, complaints or claims made by you or on your behalf.

To show that we treated you fairly; or

To keep records required by law.

We will not retain your personal information for longer than necessary for the purposes set out in this policy. Different retention periods apply for different types of personal information.

Transferring Your Personal Information. By using our Website, online directory, products and services, you consent and agree to the transfer of your personal information between Brazil, the European Union, the United Kingdom, and the United States. We will ensure that such transfers comply with data protection law and all personal information is secure. 

Your Rights Under the CCPA. If you are a natural person who is a resident of California, you have the following rights under the California Consumer Privacy Act of 2018 (“CCPA”) and certain other privacy and data protection laws, as applicable, to exercise free of charge:

Disclosure of Personal Information We Collect About You
You have the right to know:

The categories of personal information we have collected about you;

The categories of sources from which the personal information is collected;

Our business or commercial purpose for collecting or selling personal information;

The categories of third parties with whom we share personal information, if any; and

The specific pieces of personal information we have collected about you.

Please note that we are not required to:

Retain any personal information about you that was collected for a single one-time transaction if, in the ordinary course of business, that information about you is not retained;

Reidentify or otherwise link any data that, in the ordinary course of business, is not maintained in a manner that would be considered personal information; or

Provide the personal information to you more than twice in a 12-month period.


Personal Information Sold or Used for a Business Purpose
In connection with any personal information we may sell or disclose to a third party for a business purpose, you have the right to know:

The categories of personal information about you that we sold and the categories of third parties to whom the personal information was sold; and

The categories of personal information that we disclosed about you for a business purpose.

You have the right under the California Consumer Privacy Act of 2018 (CCPA) and certain other privacy and data protection laws, as applicable, to opt-out of the sale or disclosure of your personal information. If you exercise your right to opt-out of the sale or disclosure of your personal information, we will refrain from selling your personal information, unless you subsequently provide express authorization for the sale of your personal information.


Right to Deletion
Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:

Delete your personal information from our records; and

Direct any service providers to delete your personal information from their records.

Please note that we may not delete your personal information if it is necessary to:

Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;

Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;

Debug to identify and repair errors that impair existing intended functionality;

Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;

Comply with the California Electronic Communications Privacy Act;

Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;

Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;

Comply with an existing legal obligation; or

Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.


Protection Against Discrimination
You have the right to not be discriminated against by us because you exercised any of your rights under the CCPA. This means we cannot, among other things:

Deny goods or services to you;

Charge different prices or rates for goods or services, including through the use of discounts or other benefits or imposing penalties;


Provide a different level or quality of goods or services to you; or

Suggest that you will receive a different price or rate for goods or services or a different level or quality of goods or services.

Please note that we may charge a different price or rate or provide a different level or quality of goods and/or services to you, if that difference is reasonably related to the value provided to our business by your personal information.


Your Rights Under the GDPR. If you are a European Union data subject, you have the following rights under the General Data Protection Regulation (“GDPR”) and certain other privacy and data protection laws, as applicable, to exercise free of charge:

Right to Access
The right to be provided with a copy of your personal information (the right of access)


Right to Rectification
The right to require us to correct any mistakes in your personal information


Right to be Forgotten
The right to require us to delete your personal information—in certain situations


Right to Restriction of Processing
The right to require us to restrict processing of your personal information—in certain circumstances, e.g. if you contest the accuracy of the data


Right to Data Portability
The right to receive the personal information you provided to us, in a structured, commonly used and machine-readable format and/or transmit that data to a third party—in certain situations


Right to Object
The right to object:

at any time to your personal information being processed for direct marketing (including profiling);

in certain other situations to our continued processing of your personal information, e.g. processing carried out for the purpose of our legitimate interests.


Right Not to be Subject to Automated Individual Decision-Making
The right not to be subject to a decision based solely on automated processing (including profiling) that produces legal effects concerning you or similarly significantly affects you


Your Rights Under the LGPD. If you are a Brazilian data subject, you have the following rights under the General Personal Data Protection Act (“LGPD”) and certain other privacy and data protection laws, as applicable, to exercise free of charge:

The right to be informed how your personal data is processed;

The right to confirm the existence of your personal data;

The right to access your personal data;

The right to correct your personal data that is incomplete, inaccurate or outdated;

The right to have your personal data anonymized, blocked, or deleted that is unnecessary, excessive or processed in noncompliance;

The right to portability of your personal data to another service provider;

The right to withdraw a previously granted consent for processing your personal data;

The right to have your personal data eliminated when consent has been withdrawn;

The right to information about the public or private entities with whom we have shared your personal data;

The right to information about the possibility of not providing consent and the potential negative consequences; and

The right to oppose the unlawful processing of your personal data.

Keeping Your Personal Information Secure. We have appropriate security measures in place to prevent personal information from being accidentally lost, used, or accessed in an unauthorized way. We limit access to your personal information to those who have a genuine business need to access it. Those processing your information will do so only in an authorized manner and are subject to a duty of confidentiality. 

We also have procedures in place to deal with any suspected data security breach. We will notify you and any applicable regulator of a suspected data security breach where we are legally required to do so.

How to Exercise Your Rights. If you would like to exercise any of your rights as described in this Privacy Policy, please email us at contact@myt-s.com

How to File a GDPR Complaint. We hope that we can resolve any query or concern you raise about our use of your information.

The General Data Protection Regulation also gives you right to lodge a complaint with a supervisory authority, in particular in the European Union (or European Economic Area) state where you work, normally live, or where any alleged infringement of data protection laws occurred.

Changes to This Privacy Policy. We may change this privacy notice from time to time – when we do, we will inform you via our Website or your account.

How to Contact Us. Please contact us by mail, email, or telephone if you have any questions about this Privacy Policy or the information that we hold about you.

Contact Information:

MFSC LLC
753 Santolina Court, 
Waxhaw, NC, Zip Code 28173
contact@myt-s.com
+ 980 229 3577

Do You Need Extra Help? If you would like this notice in another format (for example: audio, large print, braille) please contact us (see “How to contact us” above).
`,
        })
    }

    const openAddCard = () => {
        setModal({
            type: 'add-card',
            title: 'Add New Credit Card',
        })
    }

    const valid = () => {
        setFieldValidation([])
        let errors = []

        if (currentPassword.length === 0) {
            errors.push({
                field: 'currentPassword',
                message: findlanguage().fillTheField + findlanguage().currentPassword,
            })
        }

        if (password.length === 0) {
            errors.push({
                field: 'password',
                message: findlanguage().fillTheField + findlanguage().newPassword,
            })
        } else if (!password.match('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{7,})')) {
            errors.push({
                field: 'password',
                message: findlanguage().rulesPasswordText,
            })
        }

        if (confirmPassword.length === 0) {
            errors.push({
                field: 'confirmPassword',
                message: findlanguage().fillTheField + findlanguage().confirmPassword,
            })
        }

        if (password !== confirmPassword) {
            errors.push({
                field: 'confirmPassword',
                message: findlanguage().passwordNotMatch,
            })
        }

        if (errors.length > 0) {
            let message = ''
            message = findlanguage().someErrorsFound
            errors.forEach((item) => {
                message += '\n - ' + item.message
            })
            toast.error(message)
            setFieldValidation(errors)
            return false
        }

        return true
    }

    const showHighlight = (name) => {
        const field = fieldValidation.find((item) => item.field === name)
        if (field !== undefined) return true
        return false
    }

    const togglePassword = (_state) => {
        if (typeof _state !== 'boolean') return
        setStateTooltipPassword(_state)
    }

    const handleInputPassword = (event) => {
        togglePassword(false)
    }

    const action = async () => {
        if (!valid()) {
            return
        }

        const _payload = {
            email: user.email,
            oldPassword: currentPassword,
            password: password,
        }

        // console.log(_payload)

        setLoading(true)

        const result = await UpdatePassword(_payload)
        setLoading(false)

        // console.log(result)

        if (result && exposeStrapiErrorNew(result)) {
            toast.success(findlanguage().passwordhasBeenChanged)
            navigate('dashboard')
        }
    }

    return (
        <>
            <ContainerAuthenticated>
                <Container fluid={false}>
                    <Row>
                        <Col>
                            <PageCard>
                                <PageHeader>
                                    <PageHeaderActions>
                                        <PageHeaderBack onClick={back} />
                                        <PageHeaderTitle>
                                            {findlanguage().accountInformation}
                                        </PageHeaderTitle>
                                    </PageHeaderActions>
                                    <PageHeaderActions>
                                        <DashboardMore options={moreOptions} />
                                    </PageHeaderActions>
                                </PageHeader>
                                <ProfileContainer>
                                    <ProfileFormContent>
                                        <Input
                                            placeholder={findlanguage().email}
                                            value={user.email}
                                            type={'email'}
                                            autoComplete={'xoff'}
                                            readOnly
                                        />

                                        <Input
                                            placeholder={findlanguage().accountType}
                                            value={user.accountType ? user.accountType : profile}
                                            autoComplete={'xoff'}
                                            readOnly
                                        />

                                        <div style={{ width: '353px' }}>
                                            <Input
                                                type={'password'}
                                                placeholder={findlanguage().currentPassword}
                                                value={currentPassword}
                                                onChange={(e) => setCurrentPassword(e.target.value)}
                                                highlight={showHighlight('currentPassword')}
                                                autoComplete={'new-password'}
                                            />
                                        </div>

                                        <InputBlankContainer></InputBlankContainer>

                                        <InputPassword
                                            placeholder={findlanguage().newPassword}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            id="password"
                                            onFocus={(e) => togglePassword(true)}
                                            onBlur={handleInputPassword}
                                            highlight={showHighlight('password')}
                                            autoComplete={'xoff'}
                                            small={false}
                                        />
                                        <Popover
                                            placement="top"
                                            isOpen={stateTooltipPassword}
                                            toggle={togglePassword}
                                            target={'password'}
                                        >
                                            <PopoverHeader>
                                                {findlanguage().rulesPasswordHeader}
                                            </PopoverHeader>
                                            <PopoverBody>
                                                {findlanguage().rulesPasswordText}
                                            </PopoverBody>
                                        </Popover>

                                        <div style={{ width: '353px' }}>
                                            <Input
                                                type={'password'}
                                                placeholder={findlanguage().confirmPassword}
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                highlight={showHighlight('confirmPassword')}
                                                autoComplete={'new-password'}
                                            />
                                        </div>
                                    </ProfileFormContent>
                                    <ProfileContent>
                                        <DashboardButton onClick={action}>
                                            {findlanguage().changePassword}
                                        </DashboardButton>
                                    </ProfileContent>
                                </ProfileContainer>
                            </PageCard>
                        </Col>
                    </Row>
                </Container>
            </ContainerAuthenticated>
        </>
    )
}
