import styled from 'styled-components'

export const ProfileFormContent = styled.div.attrs({})`
    height: 80px;
    margin-top: 20px;
    width: 100%;
`

export const ProfileFormActions = styled.div.attrs({})`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`

export const RequirementContent = styled.div.attrs({})`
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    color: var(--lightgrey-color);
    border-bottom: 1px solid var(--lightshadow-color);
    padding: 5px 12px;
    cursor: pointer;
`

export const ProfileFormTitle = styled.div.attrs({})`
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--lightgrey-color);
    width: 100%;
    margin: 10px 0;
`

export const PageFormSpace = styled.div.attrs({})`
    margin-right: 12px;
`
