import React, { useContext, useEffect, useState } from 'react'

import {
    PageCard,
    CardLineContainer,
    CardLine,
    CardLineText,
    CardLineColumn,
    CardHeader,
} from './styled'

import Requirements from 'components/Dashboard/Sms/MyBuyers/Requirements/index'
import { CoreContext } from 'context/CoreContext'
import {
    FindValidationProcessForSuppliers,
    UpdateFormRequestForSupplier,
    UpdateForm,
} from 'services/validationProcess'
import _ from 'lodash'
import { NotificationManagerAdd } from 'services/admin'

export default function MyBuyers() {
    const { setModal, user, findlanguage, permissions } = useContext(CoreContext)
    const [overview, setOverview] = useState([])
    const [responseOverview, setResponseOverview] = useState([])

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        const request = await FindValidationProcessForSuppliers()

        let a = []

        request
            .filter((x) => x.status !== 'Obsolete' && x.private !== true)
            .forEach((x) => {
            x.form.status = x.status
            x.form.reason = x.reason
            x.form.buyerResponse = x.buyerResponse
            x.form.responseId = x.id
            x.form.acceptedDate = x.acceptedDate
            x.form.expirationDate = x.expirationDate
            x.form.form_body = x.form_body
            x.form.name_who_accepted = x.name_who_accepted
            x.form.products = x.products

            let b = a.find((y) => y.ownerId === x.owner.id)
            if (b === null || b === undefined)
                a.push({
                    id: x.id,
                    form: [x.form],
                    ownerId: x.owner.id,
                    ownerUserName: x.owner.name,
                    updated_at: x.updated_at,
                    updated_by: x.updated_by,
                    status: x.status,
                    expanded: false,
                })
            else b.form.push(x.form)
        })

        setResponseOverview(request)
        setOverview(a.sort((a, b) => a.ownerUserName.localeCompare(b.ownerUserName)))
    }

    const openCloseDiv = (id, openClose) => {
        let overviewChange = [...overview]
        overview.find((x) => x.id === id).expanded = !openClose

        setOverview(overviewChange)
    }

    const saveForm = async (overviewId, form) => {
        let copyOverview = _.cloneDeep(overview)
        let copyResponseOverview = _.cloneDeep(responseOverview)

        copyOverview.find((x) => x.id === overviewId).form.find((x) => x.id === form.id).status =
            'Pending'
        copyOverview.find((x) => x.id === overviewId).form.find((x) => x.id === form.id).form_body =
            form.form_body

        copyResponseOverview.find((x) => x.id === form.responseId).status = 'Pending'
        copyResponseOverview.find((x) => x.id === form.responseId).form.status = 'Pending'
        copyResponseOverview.find((x) => x.id === form.responseId).form.form_body = form.form_body
        copyResponseOverview.find((x) => x.id === form.responseId).form_body = form.form_body

        const allGood = await UpdateFormRequestForSupplier(
            copyResponseOverview.find((x) => x.id === form.responseId)
        )

        setOverview(copyOverview)
        setResponseOverview(copyResponseOverview)

        if (allGood) {
            setModal({
                type: 'alert',
                text: findlanguage().formSaved,
            })

            await NotificationManagerAdd('requirement_responded', form.owner, user.name, user)
        } else {
            setModal({
                type: 'alert',
                text: findlanguage().somethingWentWrong,
            })

            init()
        }
    }

    return (
        <>
            {overview?.map((item) => (
                <PageCard key={item.id}>
                    <CardLineContainer>
                        <CardLine>
                            <div style={{ width: '50px' }}>
                                {item.form.filter(
                                    (x) => x.status === 'Denied' || x.status === 'Condition'
                                ).length !== 0 ? (
                                    <>
                                        <button
                                            type="button"
                                            style={{
                                                backgroundColor: '#263640',
                                                color: 'white',
                                                width: '206px',
                                                marginTop: '-95px',
                                                cursor: 'default',
                                            }}
                                            className="btn position-relative"
                                        >
                                            {findlanguage().pendingAction}
                                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                {
                                                    item.form.filter(
                                                        (x) =>
                                                            x.status === 'Denied' ||
                                                            x.status === 'Condition'
                                                    ).length
                                                }
                                            </span>
                                        </button>
                                        <div
                                            style={{
                                                fontSize: '30px',
                                                fontWeight: 'bolder',
                                                color: 'black',
                                                cursor: 'pointer',
                                                marginTop: '-25px',
                                            }}
                                            onClick={() => openCloseDiv(item.id, item.expanded)}
                                        >
                                            {item.expanded ? '-' : '+'}
                                        </div>
                                    </>
                                ) : (
                                    <div
                                        style={{
                                            fontSize: '30px',
                                            fontWeight: 'bolder',
                                            color: 'black',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => openCloseDiv(item.id, item.expanded)}
                                    >
                                        {item.expanded ? '-' : '+'}
                                    </div>
                                )}
                            </div>
                            <CardLineColumn>
                                <CardLineText>
                                    <CardHeader>{item.ownerUserName}</CardHeader>
                                </CardLineText>
                            </CardLineColumn>
                        </CardLine>
                    </CardLineContainer>
                    <div style={{ display: item.expanded ? '' : 'none' }}>
                        <Requirements usersWithForms={item} saveForm={saveForm} />
                    </div>
                </PageCard>
            ))}
        </>
    )
}
