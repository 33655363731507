import './loader.css' // import the CSS file

import React, { useEffect, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import { FetchSuplierRequests, GetOneUser } from 'services/users'
import { Box } from '@material-ui/core'
import { Select, MenuItem } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useHistory } from 'react-router-dom'
import { CoreContext } from 'context/CoreContext'
import moment from 'moment-timezone'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    container: {
        margin: '20px',
        overflowY: 'auto',
    },
})

const Usuarios = () => {
    const { user, setUser } = useContext(CoreContext)
    const classes = useStyles()
    const [data, setData] = useState([])
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [currentRow, setCurrentRow] = React.useState(null)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [sortConfig, setSortConfig] = React.useState({
        key: 'user_aditional_information_companyName',
        direction: 'asc',
    })
    const [filter, setFilter] = useState({
        companyName: '',
        accountType: '',
        billingPlan: '',
        country: '',
        status: '',
        billingPlanPaymentStatus: '',
    })
    const [currentPage, setCurrentPage] = useState(0)
    const [loading, setLoading] = useState(true)
    let history = useHistory()

    const fetchUsers = async () => {
        const response = await FetchSuplierRequests() // Assuming this fetches all the data

        const mappedData = response.data.map((user, index) => {
            return {
                id: user.id,
                logo: user.avatarUrl ? user.avatarUrl : '/images/no-image.png',
                companyName: user.name,
                accountType: user.role ? user.role.type : '',
                billingPlan: user.subscriptionType,
                billingPlanPaymentStatus: user.subscriptionPaymentStatus,
                country: user.country,
                created_at: convertUtcToEst(user.created_at),
                lastTimeSeen: new Date(user.lastTimeSeen),
                status: isUserOnline(new Date(user.lastTimeSeen)) ? 'Online' : 'Offline',
            }
        })

        setData(mappedData)
        setTotal(mappedData.length)
        setLoading(false)
    }

    useEffect(() => {
        fetchUsers()
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleAction = async (action) => {
        switch (action) {
            case 'resend':
                // Resend password logic here
                break
            case 'view':
                history.push(`/dashboard/user/${currentRow.id}`)
                break
            case 'edit':
                var a = await GetOneUser(currentRow.id)
                setUser(a)
                history.push(`/dashboard/profile`)
                break
            case 'delete':
                // Delete account logic here
                break
            default:
                break
        }
    }

    const handleRequestSort = (key) => {
        let direction = 'asc'
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc'
        }
        setSortConfig({ key, direction })
    }

    const isUserOnline = (lastTimeSeen) => {
        const currentDate = new Date()
        const diffInMinutes = (currentDate - lastTimeSeen) / 1000 / 60
        return diffInMinutes <= 2
    }

    const handleFilterChange = (event) => {
        const { name, value } = event.target

        if (name === 'created_at') {
            // Convert the filter input date to EST and format it for comparison
            const inputDate = value
                ? moment.tz(value, 'YYYY-MM-DD', 'America/New_York').format('MM/DD/YYYY')
                : ''
            setFilter({
                ...filter,
                created_at: inputDate,
            })
        } else {
            setFilter({
                ...filter,
                [name]: value,
            })
        }
    }

    const filteredData = data
        .filter((item) =>
            Object.keys(filter).every((key) => {
                if (!filter[key]) return true // if filter is blank, ignore
                return (
                    item[key] &&
                    item[key]
                        .toString()
                        .toLowerCase()
                        .includes(filter[key].toString().toLowerCase())
                )
            })
        )
        .sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1
            }
            return 0
        })

    // Calculate the data for the current page
    const currentPageData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    // Set the total count
    useEffect(() => {
        setTotal(filteredData.length)
    }, [filteredData])

    const convertUtcToEst = (utcDate) => {
        // Convert UTC date to EST and format it
        return moment.utc(utcDate).tz('America/New_York').format('MM/DD/YYYY HH:mm')
    }

    if (loading) return <div className="loader"></div>
    return (
        <>
            <br />
            {currentPage === 0 ? (
                <TableContainer component={Paper} className=" table container">
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel>Logo</TableSortLabel>
                                    <Box height="32px" />
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortConfig.key === 'companyName'}
                                        direction={sortConfig.direction}
                                        onClick={() => handleRequestSort('companyName')}
                                    >
                                        Company Name
                                    </TableSortLabel>
                                    <TextField
                                        name="companyName"
                                        value={filter.companyName}
                                        onChange={handleFilterChange}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortConfig.key === 'accountType'}
                                        direction={sortConfig.direction}
                                        onClick={() => handleRequestSort('accountType')}
                                    >
                                        Account Type
                                    </TableSortLabel>
                                    <TextField
                                        name="accountType"
                                        value={filter.accountType}
                                        onChange={handleFilterChange}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortConfig.key === 'billingPlan'}
                                        direction={sortConfig.direction}
                                        onClick={() => handleRequestSort('billingPlan')}
                                    >
                                        Billing Plan
                                    </TableSortLabel>
                                    <TextField
                                        name="billingPlan"
                                        value={filter.billingPlan}
                                        onChange={handleFilterChange}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortConfig.key === 'country'}
                                        direction={sortConfig.direction}
                                        onClick={() => handleRequestSort('country')}
                                    >
                                        Country
                                    </TableSortLabel>
                                    <TextField
                                        name="country"
                                        value={filter.country}
                                        onChange={handleFilterChange}
                                    />
                                </TableCell>
                                <TableCell style={{ width: '170px' }}>
                                    <TableSortLabel
                                        active={sortConfig.key === 'billingPlanPaymentStatus'}
                                        direction={sortConfig.direction}
                                        onClick={() =>
                                            handleRequestSort('billingPlanPaymentStatus')
                                        }
                                    >
                                        Payment Status
                                    </TableSortLabel>
                                    <Select
                                        value={filter.billingPlanPaymentStatus}
                                        onChange={handleFilterChange}
                                        name="billingPlanPaymentStatus"
                                        style={{ width: '120px' }}
                                    >
                                        <MenuItem value="">All</MenuItem>
                                        <MenuItem value="new">New</MenuItem>
                                        <MenuItem value="link_sent">Link Sent</MenuItem>
                                        <MenuItem value="paid">Paid</MenuItem>
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortConfig.key === 'created_at'}
                                        direction={sortConfig.direction}
                                        onClick={() => handleRequestSort('created_at')}
                                    >
                                        Created At (EST)
                                    </TableSortLabel>
                                    <TextField
                                        name="created_at"
                                        type="date"
                                        value={filter.created_at}
                                        onChange={handleFilterChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortConfig.key === 'status'}
                                        direction={sortConfig.direction}
                                        onClick={() => handleRequestSort('status')}
                                    >
                                        Status
                                    </TableSortLabel>
                                    <Select
                                        value={filter.status}
                                        onChange={handleFilterChange}
                                        name="status"
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        <MenuItem value="online">Online</MenuItem>
                                        <MenuItem value="offline">Offline</MenuItem>
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel>Action</TableSortLabel>
                                    <Box height="32px" />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentPageData.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {row.logo !== '' ? (
                                            <img
                                                src={row.logo}
                                                alt="Logo"
                                                style={{ width: '50px', height: '50px' }}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>
                                    <TableCell>{row.companyName}</TableCell>
                                    <TableCell>{row.accountType}</TableCell>
                                    <TableCell>{row.billingPlan}</TableCell>
                                    <TableCell>{row.country}</TableCell>
                                    <TableCell>
                                        {row.billingPlanPaymentStatus === 'new' ||
                                        row.billingPlanPaymentStatus === null ? (
                                            <div
                                                style={{
                                                    width: '120px',
                                                    color: 'red',
                                                }}
                                            >
                                                New
                                            </div>
                                        ) : row.billingPlanPaymentStatus === 'link_sent' ? (
                                            <div
                                                style={{
                                                    width: '120px',
                                                    color: 'blue',
                                                }}
                                            >
                                                Link Sent
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    width: '120px',
                                                    color: 'green',
                                                }}
                                            >
                                                Paid
                                            </div>
                                        )}
                                    </TableCell>
                                    <TableCell>{row.created_at}</TableCell>
                                    <TableCell>
                                        <div
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                borderRadius: '10px',
                                                backgroundColor: isUserOnline(row.lastTimeSeen)
                                                    ? 'green'
                                                    : 'red',
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={(event) => {
                                                setAnchorEl(event.currentTarget)
                                                setCurrentRow(row)
                                            }}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={() => setAnchorEl(null)}
                                        >
                                            <MenuItem
                                                onClick={() => {
                                                    handleAction('resend')
                                                    setAnchorEl(null)
                                                }}
                                            >
                                                Resend Password
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    handleAction('view')
                                                    setAnchorEl(null)
                                                }}
                                            >
                                                View Profile
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    handleAction('edit')
                                                    setAnchorEl(null)
                                                }}
                                            >
                                                Edit as User
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    handleAction('delete')
                                                    setAnchorEl(null)
                                                }}
                                            >
                                                Delete Account
                                            </MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableContainer>
            ) : null}
            {currentPage === 1 ? <></> : null}
        </>
    )
}

export default Usuarios
