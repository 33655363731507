import styled from 'styled-components'

export const PageHeader = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 20px;
`
export const PageHeaderTitle = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: var(--grey-color);
`
export const PageHeaderActions = styled.div.attrs({})`
    display: flex;
    align-items: center;

    position: relative;
`

export const PageTabSelector = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    gap: 15px 65px;
    background-color: white;
`
export const PageTabItem = styled.div.attrs({})`
    display: flex;
    align-items: center;
    padding-right: 6px;
    padding-bottom: 4px;
    padding-left:20px;
    padding-right:20px;
    height: 50px;
    cursor: pointer;

    ${(props) =>
        props.active
            ? `
            border-bottom: 2px solid var(--primary-color);
    background-color: #4b6273;
        `
            : ``}
`
export const PageTabItemIcon = styled.img.attrs((props) => ({
    src: `/icons/${props.icon}.svg`,
}))`
    margin-right: 8px;
`
export const PageTabItemText = styled.div.attrs({})`
    font-size: 16px;
    color: var(--grey-color);

    ${(props) =>
        props.active
            ? `
            color: white;
        `
            : ``}
`

export const IconInfo = styled.img.attrs({
    src: '/icons/circle-info.svg',
})``