import React, { useEffect, useState, useContext } from 'react'
import { CoreContext } from 'context/CoreContext'

import { GetCertificationsESG, PutCertificationsESG, PostCertificationsESG } from 'services/egs'
import { UpdateCertificationsESG } from 'services/api'

import { ReadMe } from 'services/authentication'
import _ from 'lodash'

import A11 from './A11/index'
import A21 from './A21/index'
import A22 from './A22/index'
import B11 from './B11/index'
import B12 from './B12/index'
import C11 from './C11/index'
import C21 from './C21/index'
import D11 from './D11/index'
import D21 from './D21/index'
import D31 from './D31/index'
import D41 from './D41/index'
import D51 from './D51/index'
import E11 from './E11/index'
import E21 from './E21/index'
import F11 from './F11/index'
import F12 from './F12/index'
import G11 from './G11/index'
import G12 from './G12/index'
import G13 from './G13/index'
import H11 from './H11/index'
import H12 from './F12/index'
import I11 from './I11/index'
import I21 from './I21/index'
import I31 from './I31/index'
import I41 from './I41/index'
import I51 from './I51/index'
import I61 from './I61/index'
import J11 from './J11/index'
import J21 from './J21/index'

export default function SelfAssessmentChecklist({ form, mfsQuestionarie, backOffice, company_id }) {
    const [certifications, setCertifications] = useState([])
    const { findlanguageEsg, findlanguage, user } = useContext(CoreContext) 

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        const getCertifications = await GetCertificationsESG(backOffice ? company_id : user.company_id)
        if (getCertifications.statusText !== 'Not Found' && getCertifications.length !== 0)
            setCertifications(getCertifications)
    }

    const onChangeCertifications = (value, itemRef, field) => {
        let newArr = [...certifications]
        let item = newArr.find((x) => x.category === itemRef)

        if (item === undefined) {
            newArr.push({
                type: 'type',
                category: itemRef,
                comments: '',
                next_level: false,
            })

            item = newArr.find((x) => x.category === itemRef)
        }

        switch (field) {
            case 'next_level':
                if (value === false) {
                    item.comments = ''
                    item.attach_document = null
                }

                item.next_level = value

                saveCertifications(item)
                break
            case 'comments':
                item.comments = value
                break
            default:
                break
        }

        setCertifications(newArr)
    }

    const saveCertifications = async (certification) => {
        if (certification.id > 0) {
            await PutCertificationsESG(certification)
        } else {
            const newCertificationLine = await PostCertificationsESG({
                ...certification,
                users_permissions_user: backOffice ? company_id : user.company_id,
            })

            if (
                certifications.find((x) => x.category === newCertificationLine.category) ===
                undefined
            )
                certifications.push(newCertificationLine)
            else
                certifications.find((x) => x.category === newCertificationLine.category).id =
                    newCertificationLine.id

            setCertifications(certifications)
        }
    }

    const saveDocument = async (ref) => {
        await PutCertificationsESG({
            ...certifications?.find((x) => x.category === ref),
            ['attach_document']: null,
        })

        const formData = new FormData()

        if (document.getElementById(ref)?.files?.[0]) {
            new Promise((resolve, reject) => {
                let reader = new FileReader()
                reader.onload = async () => {
                    let source = `${reader.result}`.split(',')[1]
                    let image = {
                        filename: document.getElementById(ref)?.files?.[0].name,
                        filetype: document.getElementById(ref)?.files?.[0].type,
                        source,
                    }

                    let btc = window.atob(image.source)
                    let btn = new Array(btc.length)
                    for (var i = 0; i < btc.length; i++) {
                        btn[i] = btc.charCodeAt(i)
                    }
                    var bta = new Uint8Array(btn)
                    let boobs = new Blob([bta], { type: 'image/png' })
                    let filesss = new File([boobs], `${image.filename}`)

                    formData.append('attach_document', filesss, filesss.name)
                    formData.append('id', certifications?.find((x) => x.category === ref)?.id)
                    formData.append('filename', 'attach_document')

                    await UpdateCertificationsESG(formData)

                    let certificationsClone = _.cloneDeep(certifications)

                    certificationsClone = certificationsClone.map((x) => {
                        if (x.category === ref) {
                            x.attach_document = document.getElementById(ref)?.files[0]
                        }

                        return x
                    })

                    setCertifications(certificationsClone)
                }
                reader.readAsDataURL(document.getElementById(ref)?.files?.[0])
            })
        }
    }

    const readBlackBoldCss = {
        whiteSpace: 'pre-line',
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#565656',
    }

    function returnHeaders(){
        return (
            <div className="row">
                <div className="col-1">
                    <label className="form-label" style={readBlackBoldCss}>
                        #
                    </label>
                </div>
                <div className={mfsQuestionarie !== '1' ? "col-3" : "col-8"} >
                    <label className="form-label" style={readBlackBoldCss}>
                        {findlanguageEsg().requirement}
                    </label>
                </div>
                <div className={mfsQuestionarie !== '1' ? "col-2" : "col-3"}>
                    <label className="form-label" style={readBlackBoldCss}>
                        {findlanguageEsg().questionanswer}
                    </label>
                </div>
                {
                    mfsQuestionarie !== '1' ?
                        <div className="col-6">
                            <label className="form-label" style={readBlackBoldCss}>
                                {findlanguageEsg().comments}
                            </label>
                        </div>
                        :
                        null
                }
            </div>
        )
    }

    return (
        <>
            <A11
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <A21
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <A22
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <B11
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <B12
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <C11
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <C21
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <D11
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <D21
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <D31
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <D41
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <D51
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <E11
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <E21
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <F11
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <F12
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <G11
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <G12
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <G13
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <H11
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <H12
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <I11
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <I21
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <I31
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <I31
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <I41
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <I51
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <I61
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <J11
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
            <J21
                certifications={certifications}
                onChangeCertifications={onChangeCertifications}
                saveCertifications={saveCertifications}
                saveDocument={saveDocument}
                form={form}
                returnHeaders={returnHeaders}
                mfsQuestionarie={mfsQuestionarie}
            />
        </>
    )
}