import React, { useContext, useEffect, useState } from 'react'

import {
    FormTitle,
    SelectManyContainer,
    SelectContainer,
    SelectHeader,
    SelectHeaderContent,
    SelectHeaderText,
    SelectHeaderBadge,
    SelectHeaderBadgeText,
    SelectHeaderBadgeIcon,
    SelectHeaderIcon,
    SelectBody,
    SelectManyActionIcon,
    SelectManyText,
    SelectItem,
} from './styled'

import Check from 'components/Form/Check'
import Button from 'components/Form/Button'
import { CoreContext } from 'context/CoreContext'

export default function SelectMany({
    options,
    onChange,
    value,
    title,
    placeholder,
    action,
    itemsselected,
    checkkey,
}) {
    const [opened, setOpened] = useState(false)

    const [selected, setSelected] = useState(value ? value : [])

    const { setModal, language, permissions } = useContext(CoreContext)

    const isAdded = (item) => {
        if (checkkey === 'title')
            return selected?.length && selected.map((mit) => mit.title).includes(item.title)
        else {
            return (
                selected?.length &&
                !!selected.find((el) => el.value === item.value || el.id === item.id)
            )
        }
    }

    const toggleAdd = (item, key) => {
        const _selected = selected || []

        let nxtValue = null
        if (checkkey === 'title') {
            nxtValue = isAdded(item, key)
                ? [..._selected.filter((fit) => fit.title !== item.title)]
                : [..._selected, { id: item.id, title: item.title, value: item.title }]
        } else {
            nxtValue = isAdded(item, key)
                ? [
                    ..._selected.filter((fit) =>
                        fit.id === undefined ? fit.value !== item.value : fit.id !== item.id
                    ),
                ]
                : [..._selected, item]
        }

        setSelected(nxtValue)
        if (onChange && typeof onChange === 'function') {
            onChange(nxtValue)
        }
    }

    const safeOpen = (e) => {
        if (!e.target.closest('.badge')) {
            setOpened(!opened)
        }
    }

    const addMore = () => {
        setModal({
            type: 'add',
            title: action,
            action: (value) => toggleAdd({ id: new Date().getTime(), value, traducao: value }),
        })
    }

    useEffect(() => {
        const handleClick = (e) => {
            if (!e.target.closest('.select-many')) {
                setOpened(false)
            }
        }
        window.addEventListener('click', handleClick)
        return () => {
            window.removeEventListener('click', handleClick)
        }
    }, [])

    useEffect(() => {
        if (itemsselected) {
            setSelected([...itemsselected])
        }
    }, [itemsselected])

    useEffect(() => {
        if (value === '' || value === undefined) {
            setSelected([])
        }
    }, [value])

    return (
        <>
            {title ? <FormTitle>{title}</FormTitle> : null}
            <SelectManyContainer>
                <SelectContainer className="select-many">
                    <SelectHeader onClick={safeOpen}>
                        <SelectHeaderContent>
                            {!selected.length ? (
                                <SelectHeaderText>{placeholder}</SelectHeaderText>
                            ) : (
                                <>
                                    {language === 'portugues'
                                        ? selected.map((item, key) => (
                                            <SelectHeaderBadge key={key} className={'badge'}>
                                                <SelectHeaderBadgeText>
                                                    {item.traducao}
                                                </SelectHeaderBadgeText>
                                                <SelectHeaderBadgeIcon
                                                    onClick={() => toggleAdd(item)}
                                                />
                                            </SelectHeaderBadge>
                                        ))
                                        : null}
                                    {language === 'english'
                                        ? selected.map((item, key) => (
                                            <SelectHeaderBadge key={key} className={'badge'}>
                                                <SelectHeaderBadgeText>
                                                    {item.value}
                                                </SelectHeaderBadgeText>
                                                {
                                                    permissions?.myProfile?.canEdit ?
                                                        <SelectHeaderBadgeIcon
                                                            onClick={() => toggleAdd(item)}
                                                        />
                                                        :
                                                        null
                                                }
                                            </SelectHeaderBadge>
                                        ))
                                        : null}
                                </>
                            )}
                        </SelectHeaderContent>
                        <SelectHeaderIcon opened={opened} />
                    </SelectHeader>
                    {
                        permissions?.myProfile?.canEdit ?
                            <SelectBody opened={opened}>
                                {options?.map((item, key) => (
                                    <SelectItem key={key}>
                                        {language === 'portugues' ? (
                                            <Check
                                                label={item.traducao}
                                                onChange={() => toggleAdd(item)}
                                                checked={isAdded(item)}
                                            />
                                        ) : null}
                                        {language === 'english' ? (
                                            <Check
                                                label={item.value}
                                                onChange={() => toggleAdd(item)}
                                                checked={isAdded(item)}
                                            />
                                        ) : null}
                                    </SelectItem>
                                ))}
                            </SelectBody>
                            :
                            null
                    }
                </SelectContainer>

                {
                    permissions?.myProfile?.canEdit ?
                        <>
                            {action ? (
                                <Button small primary onClick={addMore}>
                                    <SelectManyActionIcon />
                                    <SelectManyText>{action}</SelectManyText>
                                </Button>
                            ) : (
                                ''
                            )}
                        </>
                        :
                        null
                }
            </SelectManyContainer>
        </>
    )
}
