import React, { useEffect, useState, useContext } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useParams, useHistory } from 'react-router-dom'
import { ReadCountries } from 'services/location'
import { NotificationManagerAdd } from 'services/admin'

import {
    ContentTitle,
    PageBody,
    BodySubtitle,
    BodyText,
    BodyItem,
    ApproveCheckIcon,
    ApproveContent,
    ApproveCheck,
    DocumentItem,
    DocumentIcon,
    DocumentTitle,
    Link,
    DocumentWrapper,
} from './styled'

import ContainerAuthenticated from 'containers/Authenticated'

import DashboardUserDetailsHeader from 'components/Dashboard/Details/Header'
import DashboardUserDetailsUserTabs from 'components/Dashboard/Details/UserTabs'

import { FetchUserByUserId } from 'services/filters'
import {
    FetchMyProducts,
    FetchMyCertificates,
    FetchMyRequirements,
    FetchMyServiceProviderRegions,
    FetchMyServiceProviderDocuments,
} from 'services/myProfile'
import { Load } from 'ui/styled'
import DashboardUserDetailsServiceProfile from 'components/Dashboard/Details/ServiceProfile'
import { CoreContext } from 'context/CoreContext'

export default function DashboardUser() {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const { user, blurryTextField, needToBlockField, findlanguage, language } = useContext(CoreContext)

    const params = useParams()

    const [loading, setLoading] = useState(false)
    const [userProfile, setUserProfile] = useState({})
    const [products, setProducts] = useState([])
    const [certificates, setCertificates] = useState([])
    const [buyerRequirements, setBuyerRequirements] = useState([])
    const [serviceProviderRegions, setServiceProviderRegions] = useState([])
    const [serviceProviderDocuments, setServiceProviderDocuments] = useState([])
    const [activeTab, setActiveTab] = useState('buyer');

    const init = async () => {
        if (params?.slug) {
            setLoading(true)
            const result = await FetchUserByUserId(params?.slug)

            if (result[0] !== undefined) {
                if (result[0]?.type === 'supplier') {
                    let distributionArray = []
                    if (result[0]?.distributionInternational === true)
                        distributionArray.push('International')

                    if (result[0]?.distributionNational === true) distributionArray.push('National')

                    if (result[0]?.distributionState === true) distributionArray.push('State')


                    result[0].distributionArray = distributionArray ?? []

                    const data = await FetchMyProducts(result[0]?.user?.id)

                    setProducts(data)

                    const dataCertificates = await FetchMyCertificates(result[0]?.user?.id)
                    setCertificates(dataCertificates)
                }

                setUserProfile(result[0])

                if (result[0]?.type === 'service-provider') {
                    // regions
                    const countries = await ReadCountries()
                    const _loadServiceProviderRegions = await FetchMyServiceProviderRegions(
                        result[0]?.user?.id
                    ).then((response) => {
                        const translatedResponse = response.map(mit => {
                            const countryData = countries.find(country => country.name === mit.country);

                            return {
                                ...mit,
                                country: language === 'english' ? countryData?.name : countryData?.name_pt,
                                state: mit.state === 'Entire country' ? findlanguage().entireCountry : mit.state
                            };
                        });

                        return translatedResponse
                            .sort((a, b) => (a.country > b.country ? 1 : -1))
                            .map(mit => ({ country: mit.country, state: mit.state }));
                    });

                    setServiceProviderRegions(_loadServiceProviderRegions)

                    // documents
                    const _loadServiceProviderDocuments = await FetchMyServiceProviderDocuments(
                        result[0]?.user?.id
                    ).then((response) => {
                        return response
                            .sort((a, b) => (a.document_name > b.document_name ? 1 : -1))
                            .map((item) => ({
                                id: item.id,
                                document_name: item.document_name,
                                file: item.file.length ? item.file[0] : null,
                            }))
                    })
                    setServiceProviderDocuments([..._loadServiceProviderDocuments])
                }

                if (result[0]?.type === 'buyer') {
                    const _loadRequirements = await FetchMyRequirements(result[0]?.user?.id).then(
                        (response) => {
                            return response.map((item, key) => ({
                                id: item.id,
                                label: item.requirement,
                            }))
                        }
                    )
                    setBuyerRequirements(_loadRequirements)
                }

                if (result[0]?.type === 'admin') {
                    const _loadRequirements = await FetchMyRequirements(result[0]?.user?.id).then(
                        (response) => {
                            return response.map((item, key) => ({
                                id: item.id,
                                label: item.requirement,
                            }))
                        }
                    )
                    setBuyerRequirements(_loadRequirements)

                    let distributionArray = []
                    if (result[0]?.distributionInternational === true)
                        distributionArray.push('International')

                    if (result[0]?.distributionNational === true) distributionArray.push('National')

                    if (result[0]?.distributionState === true) distributionArray.push('State')


                    result[0].distributionArray = distributionArray ?? []

                    const data = await FetchMyProducts(result[0]?.user?.id)

                    setProducts(data)

                    const dataCertificates = await FetchMyCertificates(result[0]?.user?.id)
                    setCertificates(dataCertificates)
                }

                // notify owner of this profile
                await NotificationManagerAdd(
                    'profile_viewed',
                    result[0]?.user?.id,
                    user.name,
                    user,
                    'notification'
                )
                //
            } else {
                navigate('user-not-found')
            }
            setLoading(false)
        }
    }

    const callbackHeader = () => {
        init()
    }

    useEffect(() => {
        init()
    }, [])

    const changeServices = (value) => {

        if (language === 'english') {
            return value
        }
        else {
            switch (value) {
                case 'Training':
                    return findlanguage().training
                case '3rd Party Audits':
                    return findlanguage().thirdPartyAudits
                case '2nd party audits':
                    return findlanguage().secondPartyAudits
                case 'Internal audits':
                    return findlanguage().internalAudits
                case 'Cleaning company':
                    return findlanguage().cleaningCompany
                case 'Consultant':
                    return findlanguage().consultant
                default:
                    return value
            }
        }
    }


    const changeProvidedServices = (value) => {

        if (language === 'english') {
            return value
        }
        else {
            switch (value) {
                case 'GFSI Certified Companies':
                    return 'Empresas Certificadas em Normas Reconhecidas pelo GFSI'
                case 'Other Food Safety Approval':
                    return 'Outras Aprovações em Segurança dos Alimentos'
                case 'FDA Approved Companies':
                    return 'Empresas Aprovadas FDA'
                case 'USDA Approved Companies':
                    return 'Empresas Aprovadas pelo USDA'
                case 'Quality':
                    return 'Qualidade'
                case 'Organic Certified Companies':
                    return 'Empresas Certificadas Orgânica'
                case 'Social Certified/Audit Companies':
                    return 'Empresas Certificadas/Auditadas em Normas Sociais'
                default:
                    return value
            }
        }
    }
    
    return (
        <>
            <ContainerAuthenticated>
                <Container fluid={false}>
                    <Row>
                        <Col>
                            {loading ? (
                                <Load white={'true'} />
                            ) : (
                                <>
                                    {userProfile?.type === 'service-provider' ? (
                                        <>
                                            <DashboardUserDetailsHeader
                                                profile={userProfile}
                                                callback={callbackHeader}
                                            />
                                            <PageBody>
                                                <ContentTitle>
                                                    {findlanguage().serviceProviderDetails}
                                                </ContentTitle>
                                                <BodySubtitle>{findlanguage().aboutTheCompany}</BodySubtitle>
                                                <BodyText>
                                                    {userProfile?.aboutCompany
                                                        ? userProfile?.aboutCompany
                                                        : findlanguage().noInfo}
                                                </BodyText>
                                            </PageBody>
                                            <PageBody>
                                                <BodySubtitle>{findlanguage().servicesOffered}</BodySubtitle>
                                                {userProfile?.offeredServices.length > 0
                                                    ? userProfile?.offeredServices.map(
                                                        (item, key) => (
                                                            <ApproveContent
                                                                key={key + 'ApproveContent'}
                                                            >
                                                                <ApproveCheck>
                                                                    <ApproveCheckIcon />
                                                                </ApproveCheck>
                                                                {
                                                                    blurryTextField(
                                                                        changeServices(item.label),
                                                                        userProfile
                                                                    )
                                                                }
                                                            </ApproveContent>
                                                        )
                                                    )
                                                    : blurryTextField(
                                                        findlanguage().noInfo,
                                                        userProfile
                                                    )}
                                            </PageBody>
                                            <PageBody>
                                                <BodySubtitle>{findlanguage().servicesProvided}</BodySubtitle>
                                                {userProfile?.providedServices.length > 0
                                                    ? userProfile?.providedServices.map(
                                                        (item, key) => (
                                                            <ApproveContent
                                                                key={key + 'services_provided'}
                                                            >
                                                                <ApproveCheck>
                                                                    <ApproveCheckIcon />
                                                                </ApproveCheck>
                                                                {blurryTextField(
                                                                    changeProvidedServices(item.label),
                                                                    userProfile
                                                                )}
                                                            </ApproveContent>
                                                        )
                                                    )
                                                    : blurryTextField(
                                                        findlanguage().noInfo,
                                                        userProfile
                                                    )}
                                            </PageBody>
                                            <PageBody>
                                                <BodySubtitle>
                                                    {findlanguage().descriptionOfServiceProvided}
                                                </BodySubtitle>
                                                <BodyText>
                                                    {blurryTextField(
                                                        userProfile?.serviceDescription
                                                            ? userProfile?.serviceDescription
                                                            : findlanguage().noInfo,
                                                        userProfile
                                                    )}
                                                </BodyText>
                                            </PageBody>

                                            <PageBody>
                                                <BodySubtitle>
                                                    {findlanguage().whatServicesAreOffered.slice(0, -1)}
                                                </BodySubtitle>
                                                {serviceProviderRegions.length > 0
                                                    ? serviceProviderRegions.map((item, key) => (
                                                        <ApproveContent
                                                            key={key + 'whereoffered'}
                                                        >
                                                            <ApproveCheck>
                                                                <ApproveCheckIcon />
                                                            </ApproveCheck>
                                                            {blurryTextField(
                                                                item.country + ' - ' + item.state,
                                                                userProfile
                                                            )}
                                                        </ApproveContent>
                                                    ))
                                                    : blurryTextField(
                                                        findlanguage().noInfo,
                                                        userProfile
                                                    )}
                                            </PageBody>

                                            <PageBody>
                                                <BodySubtitle>{findlanguage().documents}</BodySubtitle>
                                                <DocumentWrapper>
                                                    {needToBlockField(userProfile) ? (
                                                        <>
                                                            {[1, 2, 3, 4].map((item, key) => (
                                                                <DocumentItem
                                                                    key={key + 'document'}
                                                                >
                                                                    <DocumentIcon />
                                                                    <DocumentTitle>
                                                                        {blurryTextField(
                                                                            'Not visible',
                                                                            userProfile
                                                                        )}
                                                                    </DocumentTitle>
                                                                </DocumentItem>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {serviceProviderDocuments.length > 0
                                                                ? serviceProviderDocuments.map(
                                                                    (item, key) => (
                                                                        <DocumentItem
                                                                            key={key + 'document'}
                                                                        >
                                                                            <DocumentIcon />
                                                                            <DocumentTitle>
                                                                                <Link
                                                                                    href={
                                                                                        item.file
                                                                                            .url
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noreferrer noopener"
                                                                                    download={
                                                                                        item.file
                                                                                            .name
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        item.document_name
                                                                                    }
                                                                                </Link>
                                                                            </DocumentTitle>
                                                                        </DocumentItem>
                                                                    )
                                                                )
                                                                : findlanguage().noInfo}
                                                        </>
                                                    )}
                                                </DocumentWrapper>
                                            </PageBody>

                                            <PageBody>
                                                <DashboardUserDetailsUserTabs
                                                    userProfile={userProfile}
                                                    typeOfView="service-provider"
                                                />
                                            </PageBody>
                                        </>
                                    ) : null}

                                    {userProfile?.type === 'supplier' ? (
                                        <>
                                            <DashboardUserDetailsHeader
                                                profile={userProfile}
                                                callback={callbackHeader}
                                            />
                                            <PageBody>
                                                <ContentTitle>
                                                    {findlanguage().supplierDetails}
                                                </ContentTitle>
                                                <BodySubtitle>
                                                    {findlanguage().aboutTheCompany}
                                                </BodySubtitle>
                                                <BodyText>
                                                    {userProfile?.aboutCompany
                                                        ? userProfile?.aboutCompany
                                                        : findlanguage().noInfo}
                                                </BodyText>
                                            </PageBody>
                                            <PageBody>
                                                <BodySubtitle>
                                                    {findlanguage().productDetails}
                                                </BodySubtitle>
                                                <BodyText>
                                                    {blurryTextField(
                                                        userProfile?.productsDescription
                                                            ? userProfile?.productsDescription
                                                            : findlanguage().noInfo,
                                                        userProfile
                                                    )}
                                                </BodyText>
                                            </PageBody>
                                            <PageBody>
                                                <DashboardUserDetailsUserTabs
                                                    userProfile={userProfile}
                                                    products={products}
                                                    certificates={certificates}
                                                    typeOfView="supplier"
                                                />
                                            </PageBody>
                                        </>
                                    ) : null}

                                    {userProfile?.type === 'buyer' ? (
                                        <>
                                            <DashboardUserDetailsHeader
                                                profile={userProfile}
                                                callback={callbackHeader}
                                            />
                                            <PageBody>
                                                <ContentTitle>
                                                    {findlanguage().buyerDetails}
                                                </ContentTitle>
                                                <BodySubtitle>
                                                    {findlanguage().aboutTheCompany}
                                                </BodySubtitle>
                                                <BodyText>
                                                    {userProfile?.aboutCompany
                                                        ? userProfile?.aboutCompany
                                                        : findlanguage().noInfo}
                                                </BodyText>
                                            </PageBody>
                                            <PageBody>
                                                <BodySubtitle>
                                                    {findlanguage().buyerRequirements}
                                                </BodySubtitle>
                                                {buyerRequirements.length > 0
                                                    ? buyerRequirements.map((item) => (
                                                        <ApproveContent>
                                                            <ApproveCheck>
                                                                <ApproveCheckIcon />
                                                            </ApproveCheck>
                                                            {blurryTextField(
                                                                item.label,
                                                                userProfile
                                                            )}
                                                        </ApproveContent>
                                                    ))
                                                    : findlanguage().noInfo}
                                            </PageBody>
                                            <PageBody>
                                                <DashboardUserDetailsUserTabs
                                                    userProfile={userProfile}
                                                    products={products}
                                                    certificates={certificates}
                                                    typeOfView="buyer"
                                                />
                                            </PageBody>
                                        </>
                                    ) : null}

                                    {userProfile?.type === 'admin' ? (
                                        <>
                                            <DashboardUserDetailsHeader
                                                profile={userProfile}
                                                callback={callbackHeader}
                                            />

                                            <PageBody>
                                                <ContentTitle>
                                                    {findlanguage().buyerDetails} / {findlanguage().supplierDetails}
                                                </ContentTitle>
                                                <BodySubtitle>
                                                    {findlanguage().aboutTheCompany}
                                                </BodySubtitle>
                                                <BodyText>
                                                    {userProfile?.aboutCompany
                                                        ? userProfile?.aboutCompany
                                                        : findlanguage().noInfo}
                                                </BodyText>
                                            </PageBody>

                                            <div id="tab-component" className="tab-component">
                                                <div className="tab-header">
                                                    <button
                                                        className={`tab-button ${activeTab === 'buyer' ? 'active' : ''} tab-button-right`}
                                                        onClick={() => setActiveTab('buyer')}
                                                    >
                                                        Buyer
                                                    </button>
                                                    <button
                                                        className={`tab-button ${activeTab === 'supplier' ? 'active' : ''}`}
                                                        onClick={() => setActiveTab('supplier')}
                                                    >
                                                        Supplier
                                                    </button>
                                                </div>
                                            </div>
                                            {
                                                activeTab === 'buyer' ?
                                                    <>
                                                        <PageBody>
                                                            <BodySubtitle>
                                                                {findlanguage().buyerRequirements}
                                                            </BodySubtitle>
                                                            {buyerRequirements.length > 0
                                                                ? buyerRequirements.map((item) => (
                                                                    <ApproveContent>
                                                                        <ApproveCheck>
                                                                            <ApproveCheckIcon />
                                                                        </ApproveCheck>
                                                                        {blurryTextField(
                                                                            item.label,
                                                                            userProfile
                                                                        )}
                                                                    </ApproveContent>
                                                                ))
                                                                : findlanguage().noInfo}
                                                        </PageBody>
                                                        <PageBody>
                                                            <DashboardUserDetailsUserTabs
                                                                userProfile={userProfile}
                                                                products={products}
                                                                certificates={certificates}
                                                                typeOfView="buyer"
                                                            />
                                                        </PageBody>
                                                    </>
                                                    :
                                                    <>


                                                        <PageBody>
                                                            <BodySubtitle>
                                                                {findlanguage().productDetails}
                                                            </BodySubtitle>
                                                            <BodyText>
                                                                {blurryTextField(
                                                                    userProfile?.productsDescription
                                                                        ? userProfile?.productsDescription
                                                                        : findlanguage().noInfo,
                                                                    userProfile
                                                                )}
                                                            </BodyText>
                                                        </PageBody>

                                                        <PageBody>
                                                            <DashboardUserDetailsUserTabs
                                                                userProfile={userProfile}
                                                                products={products}
                                                                certificates={certificates}
                                                                typeOfView="supplier"
                                                            />
                                                        </PageBody>
                                                    </>
                                            }
                                        </>
                                    ) : null}

                                    {userProfile?.type === 'buyer-supplier' ? (
                                        <>
                                            <DashboardUserDetailsHeader
                                                profile={userProfile}
                                                callback={callbackHeader}
                                            />

                                            <PageBody>
                                                <ContentTitle>
                                                    {findlanguage().buyerDetails} / {findlanguage().supplierDetails}
                                                </ContentTitle>
                                                <BodySubtitle>
                                                    {findlanguage().aboutTheCompany}
                                                </BodySubtitle>
                                                <BodyText>
                                                    {userProfile?.aboutCompany
                                                        ? userProfile?.aboutCompany
                                                        : findlanguage().noInfo}
                                                </BodyText>
                                            </PageBody>

                                            <div id="tab-component" className="tab-component">
                                                <div className="tab-header">
                                                    <button
                                                        className={`tab-button ${activeTab === 'buyer' ? 'active' : ''} tab-button-right`}
                                                        onClick={() => setActiveTab('buyer')}
                                                    >
                                                        Buyer
                                                    </button>
                                                    <button
                                                        className={`tab-button ${activeTab === 'supplier' ? 'active' : ''}`}
                                                        onClick={() => setActiveTab('supplier')}
                                                    >
                                                        Supplier
                                                    </button>
                                                </div>
                                            </div>
                                            {
                                                activeTab === 'buyer' ?
                                                    <>
                                                        <PageBody>
                                                            <BodySubtitle>
                                                                {findlanguage().buyerRequirements}
                                                            </BodySubtitle>
                                                            {buyerRequirements.length > 0
                                                                ? buyerRequirements.map((item) => (
                                                                    <ApproveContent>
                                                                        <ApproveCheck>
                                                                            <ApproveCheckIcon />
                                                                        </ApproveCheck>
                                                                        {blurryTextField(
                                                                            item.label,
                                                                            userProfile
                                                                        )}
                                                                    </ApproveContent>
                                                                ))
                                                                : findlanguage().noInfo}
                                                        </PageBody>
                                                        <PageBody>
                                                            <DashboardUserDetailsUserTabs
                                                                userProfile={userProfile}
                                                                products={products}
                                                                certificates={certificates}
                                                                typeOfView="buyer"
                                                            />
                                                        </PageBody>
                                                    </>
                                                    :
                                                    <>


                                                        <PageBody>
                                                            <BodySubtitle>
                                                                {findlanguage().productDetails}
                                                            </BodySubtitle>
                                                            <BodyText>
                                                                {blurryTextField(
                                                                    userProfile?.productsDescription
                                                                        ? userProfile?.productsDescription
                                                                        : findlanguage().noInfo,
                                                                    userProfile
                                                                )}
                                                            </BodyText>
                                                        </PageBody>

                                                        <PageBody>
                                                            <DashboardUserDetailsUserTabs
                                                                userProfile={userProfile}
                                                                products={products}
                                                                certificates={certificates}
                                                                typeOfView="supplier"
                                                            />
                                                        </PageBody>
                                                    </>
                                            }
                                        </>
                                    ) : null}
                                </>
                            )}
                        </Col>
                    </Row>
                </Container>
            </ContainerAuthenticated >
        </>
    )
}
