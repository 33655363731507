import React from 'react'

import { ContentSearch, SearchInput, SearchIcon } from './styled'
export default function DashboardFilterSearch({
    placeholder,
    onChangeList,
    value,
    style,
    disabled,
}) {
    return (
        <>
            <ContentSearch style={style}>
                <SearchInput
                    placeholder={disabled ? 'Disabled while loading' : placeholder}
                    value={value}
                    onChange={onChangeList}
                    disabled={disabled}
                />
                <SearchIcon />
            </ContentSearch>
        </>
    )
}
