import React, { useContext, useState } from 'react'

import { ProfileFormActions, PageFormSpace } from './styled'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

export default function ModalReviewDocument() {
    const { modal, setModal, findlanguage } = useContext(CoreContext)
    const { setModal2, user } = useContext(CoreContext)
    const labelCss = { fontWeight: 'bold', fontSize: '15px', color: 'rgb(86, 86, 86)' }
    const inputCss = { borderColor: '#70707059' }
    const [inputFields, setInputFields] = useState({ reason: '' })

    const changeValue = (key, value) => {
        setInputFields({ ...inputFields, [key]: value })
    }

    return (
        <>
            <ModalWrapper>
                <label className="form-label" style={labelCss}>
                    {findlanguage().typeReasonRejecting}
                </label>
                <textarea
                    type="text"
                    className="form-control"
                    style={inputCss}
                    value={inputFields.reason}
                    onChange={(event) => changeValue('reason', event.target.value)}
                />
                <PageFormSpace />
                <br />
                <ProfileFormActions>
                    <button
                        style={{
                            marginRight: '5px',
                            backgroundColor: '#db3939',
                            border: '1px solid #db3939',
                        }}
                        type="button"
                        className="btn btn-success"
                        onClick={() => modal.sendBackToUser(inputFields.reason)}
                    >
                        {findlanguage().reject}
                    </button>
                </ProfileFormActions>
            </ModalWrapper>
        </>
    )
}
