import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'

import { ProfileFormContent, ProfileFormActions, PageFormSpace } from './styled'

import Input from 'components/Form/Input'
import Button from 'components/Form/Button'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

export default function ModalFilterBy() {
    const { modal, setModal } = useContext(CoreContext)

    const [form, setForm] = useState({})
    const formValue = (ref) => {
        return form?.[ref] ? form?.[ref] : ''
    }
    const changeForm = (value, ref) => {
        setForm({ ...form, [ref]: value })
    }

    const close = () => {
        setModal(null)
    }
    return (
        <>
            <ModalWrapper big>
                <ProfileFormContent>
                    <Input placeholder="Per Day" value={''} icon={'/icons/calendar.svg'} />
                    <Input placeholder="Per Week" value={''} icon={'/icons/calendar.svg'} />
                    <Input placeholder="Per year" value={''} icon={'/icons/calendar.svg'} />
                </ProfileFormContent>
                <ProfileFormActions>
                    <Button primary onClick={close}>
                        Apply Filters
                    </Button>
                </ProfileFormActions>
            </ModalWrapper>
        </>
    )
}
