import styled from 'styled-components'

export const FormContent = styled.div.attrs({})`
    padding: 40px 50px 0px;
    background: var(--white-color);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;

    ${(props) =>
        props.full
            ? `
            max-width: 100%;
        `
            : ``}

    box-shadow: 0px 1.5px 6px var(--shadow-color);
    margin-bottom: 30px;
`

export const FormTitle = styled.div.attrs({})`
    color: var(--primary-color);
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    padding-bottom: 30px;
`

export const CheckList = styled.div.attrs({})`
    padding-bottom: 30px;
`

export const CheckContent = styled.div.attrs({})`
    padding-bottom: 30px;
`
export const BodyContent = styled.div.attrs({})`
    padding-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
`

export const RequiredText = styled.div.attrs({})`
    color: var(--primary-color);
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-align: center;
    margin: 35px 0;
    padding-bottom: 10px;
`

export const Label = styled.div.attrs({})`
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    color: var(--primary-color);
    font-weight: 600;
    padding-top: 5px;
    margin-bottom: 10px;
`

export const CheckRow = styled.div.attrs({})`
    display: flex;
    width: 400px;
`
