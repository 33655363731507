import styled from 'styled-components'

export const ProfileFormContent = styled.div.attrs({})`
    width: 100%;
    padding: 20px 20px 20px;
    min-height: calc(100vh - 150px);
    max-height: calc(100vh - 150px);
    overflow: auto;
    border-top: 0.5px solid var(--evengrey-color);
`

export const ProfileFormActions = styled.div.attrs({})`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
`
export const PageFormSpace = styled.div.attrs({})`
    margin-right: 12px;
`
