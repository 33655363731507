import React, { useEffect, useState } from 'react'
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Button,
} from '@material-ui/core'
import { FreeUsers } from 'services/privacy'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    container: {
        margin: '20px',
        overflowY: 'auto',
    },
    mapContainer: {
        height: '500px',
        width: '100%',
    },
})

const FreeFilter = () => {
    const classes = useStyles()
    const [data, setData] = useState([])
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [showMap, setShowMap] = useState(false)

    useEffect(() => {
        const fetchUsers = async () => {
            const response = await FreeUsers()
            
            const mappedData = response.map((item) => ({
                id: item.id,
                product: item.product,
                role: item.role
                    ? item.role === 3
                        ? 'Buyer'
                        : item.role === 4
                        ? 'Supplier'
                        : 'Service Provider'
                    : 'Not Assigned',
                country: item.country,
                userCountry: item.user_country,
                userState: item.user_state,
                userCity: item.user_city,
                userIP: item.user_ip,
                lastSession: item.user_last_session,
                publishedAt: item.published_at,
                latitude: item.latitude,
                longitude: item.longitude,
            }))

            setData(mappedData)
            setTotal(mappedData.length)
        }
        fetchUsers()
    }, [])

    useEffect(() => {
        if (showMap && window.google) {
            const map = new window.google.maps.Map(document.getElementById('map'), {
                zoom: 3,
                center: { lat: 0, lng: 0 },
            })

            data.forEach((item) => {
                const latitude = parseFloat(item.latitude)
                const longitude = parseFloat(item.longitude)

                if (!isNaN(latitude) && !isNaN(longitude)) {
                    const marker = new window.google.maps.Marker({
                        position: { lat: latitude, lng: longitude },
                        map: map,
                        title: item.userCity,
                    })
                }
            })
        }
    }, [showMap, data])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const toggleView = () => {
        setShowMap(!showMap)
    }

    return (
        <>
            <br />
            <Button onClick={toggleView} style={{ margin: '10px 0' }}>
                {showMap ? 'Show Table' : 'Show Map'}
            </Button>
            {showMap ? (
                <div id="map" className={classes.mapContainer}></div>
            ) : (
                <TableContainer component={Paper} className="table container">
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Product</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Country</TableCell>
                                <TableCell>User Country</TableCell>
                                <TableCell>User State</TableCell>
                                <TableCell>User City</TableCell>
                                <TableCell>User IP</TableCell>
                                <TableCell>Last Session</TableCell>
                                <TableCell>Published At</TableCell>
                                <TableCell>Latitude</TableCell>
                                <TableCell>Longitude</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.product}</TableCell>
                                        <TableCell>{row.role}</TableCell>
                                        <TableCell>{row.country}</TableCell>
                                        <TableCell>{row.userCountry}</TableCell>
                                        <TableCell>{row.userState}</TableCell>
                                        <TableCell>{row.userCity}</TableCell>
                                        <TableCell>{row.userIP}</TableCell>
                                        <TableCell>{row.lastSession}</TableCell>
                                        <TableCell>
                                            {new Date(row.publishedAt).toLocaleString()}
                                        </TableCell>
                                        <TableCell>{row.latitude}</TableCell>
                                        <TableCell>{row.longitude}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableContainer>
            )}
        </>
    )
}

export default FreeFilter
