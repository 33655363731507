import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
    ProfileFormContent,
    ProfileFormActions,
    PageFormSpace,
    RowForm,
    ModalFormLabel,
    RowBlankSpace,
    AttachRow,
    AttachItem,
    AttachItemLink,
    UploadHiddenInput,
    Label,
} from './styled'

import Button from 'components/Form/Button'
import Input from 'components/Form/Input'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

import DashboardButton from 'components/Dashboard/Button'

import { AddServiceProviderDocument, GetServiceProviderDocument } from 'services/myProfile'

import { Load } from 'ui/styled'

export default function ModalAddServiceProviderDocument() {
    const { user, modal, setModal, findlanguage } = useContext(CoreContext)

    const [documentSelected, setDocumentSelected] = useState({})
    const [fileSelected, setFileSelected] = useState('')
    const [documentName, setDocumentName] = useState('')

    const [colorValidation, setColorValidation] = useState('#000')

    const action = async () => {
        setFieldValidation([])
        let errors = []
        let body_doc

        setColorValidation('#000')

        if (modal.id === undefined) {
            if (fileSelected === undefined || fileSelected === '') {
                errors.push({ field: 'file', message: 'Fill the field: Document' })
                setColorValidation('red')
            }

            if (documentName.length === 0) {
                errors.push({ field: 'document_name', message: 'Fill the field: Document Name' })
            }

            body_doc = {
                document_name: documentName,
                user_id: user.company_id,
            }
        } else {
            body_doc = { ...documentSelected }
        }

        if (errors.length > 0) {
            let message = ''
            message = 'Some errors was found: '
            errors.forEach((item) => {
                message += '\n - ' + item.message
            })
            toast.error(message)
            setFieldValidation(errors)
            return false
        }

        const formData = new FormData()
        formData.append('id', modal.id === undefined ? 0 : modal.id)
        formData.append('file', document.getElementById('upload-input-hidden')?.files?.[0])
        formData.append('document_name', body_doc.document_name)
        formData.append('user_id', body_doc.user_id)

        await AddServiceProviderDocument(formData)

        setModal({
            type: 'alert',
            text:
                modal.id === undefined ? findlanguage().documentAdded : findlanguage().changesSaved,
        })

        const requestRefresh = modal.onRefresh

        if (requestRefresh) requestRefresh()
    }

    useEffect(() => {
        if (modal.id === undefined) return

        GetServiceProviderDocument(modal.id).then((doc) => {
            const _doc = {
                document_name: doc.document_name,
                user_id: user.company_id,
            }

            setDocumentSelected({ ..._doc })

            setFileSelected(_doc.file)

            setDocumentName(_doc.document_name)
        })
    }, [modal.id])

    const attach = (action) => {
        document.getElementById('upload-input-hidden')?.click()
    }

    const showFileName = () => {
        const file = document.getElementById('upload-input-hidden')?.files?.[0]
        setFileSelected(file)
    }

    const [fieldValidation, setFieldValidation] = useState([])
    const showHighlight = (name) => {
        const field = fieldValidation.find((item) => item.field === name)
        if (field !== undefined) return true
        return false
    }

    return (
        <>
            <ModalWrapper>
                <ProfileFormContent>
                    <ModalFormLabel spaced>Document</ModalFormLabel>
                    <AttachRow>
                        <DashboardButton dark small onClick={attach}>
                            Choose file
                        </DashboardButton>
                        <AttachItem>
                            <AttachItemLink>
                                {fileSelected ? (
                                    fileSelected.name
                                ) : (
                                    <div style={{ color: colorValidation }}>
                                        {findlanguage().noFileSelected}
                                    </div>
                                )}
                            </AttachItemLink>
                        </AttachItem>

                        <UploadHiddenInput onChange={showFileName} />
                    </AttachRow>

                    <RowBlankSpace />

                    <RowForm>
                        <Input
                            placeholder={findlanguage().documentName + '*'}
                            value={documentName}
                            onChange={(e) => setDocumentName(e.target.value)}
                            highlight={showHighlight('document_name')}
                        />
                    </RowForm>
                </ProfileFormContent>
                <PageFormSpace />
                <ProfileFormActions>
                    <Button primary onClick={action}>
                        {findlanguage().save}
                    </Button>
                </ProfileFormActions>
            </ModalWrapper>
        </>
    )
}
