import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import Card from '../Card/index.js'

import { PageCard, CardHeader, CardHeaderTitleContainer } from './styled'
import _ from 'lodash'

export default function A11({
    certifications,
    onChangeCertifications,
    saveCertifications,
    saveDocument,
    form,
    returnHeaders,
    mfsQuestionarie
}) {
    const { findlanguageEsg } = useContext(CoreContext)
    //#region A.1.1
    const a11 = {
        ref: 'A.1.1',
        requirementTitle: findlanguageEsg().a11RequirementTitle,
        inputForYesAnswer: true,
        placeHolderTextYes: findlanguageEsg().a11PlaceHolderTextYes,
        inputForNoAnswer: false,
        placeHolderTextNo: findlanguageEsg().a11PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    //#region A.1.1 Yes

    //#region A.1.1.Y.A

    const a11ya = {
        ref: 'A.1.1.Y.A',
        requirementTitle: findlanguageEsg().a11yaRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a11yaPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a11yay = {
        ref: 'A.1.1.Y.A.Y',
        requirementTitle: findlanguageEsg().a11yayRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a11yayPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const a11yayy = {
        ref: 'A.1.1.Y.A.Y.Y',
        requirementTitle: findlanguageEsg().a11yayyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a11yayyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
    }

    const a11yayyql1 = {
        ref: 'A.1.1.Y.A.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().a11yayyqlRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a11yayyqlPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        fileForYesAnswerAndNoInput: true,
        color: '#2f8c5d',
        question: true,
    }

    //#endregion

    //#region A.1.1.Y.B

    const a11yb = {
        ref: 'A.1.1.Y.B',
        requirementTitle: findlanguageEsg().a11ybRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a11ybPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a11yby = {
        ref: 'A.1.1.Y.B.Y',
        requirementTitle: findlanguageEsg().a11ybyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: findlanguageEsg().a11ybyPlaceHolderTextYes,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a11ybyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const a11ybyqt1 = {
        ref: 'A.1.1.Y.B.Y.Y.QT.1',
        requirementTitle: findlanguageEsg().a11ybyyqt1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const a11ybyqt2 = {
        ref: 'A.1.1.Y.B.Y.Y.QT.2',
        requirementTitle: findlanguageEsg().a11ybyyqt2RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const a11ybyqt3 = {
        ref: 'A.1.1.Y.B.Y.Y.QT.3',
        requirementTitle: findlanguageEsg().a11ybyyqt3RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const a11ybyqtn = {
        ref: 'A.1.1.Y.B.Y.Y.QT.N',
        requirementTitle: findlanguageEsg().a11ybyyqtnRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const a11ybyql1 = {
        ref: 'A.1.1.Y.B.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().a11ybyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a11ybyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        fileForYesAnswerAndNoInput: true,
        question: true,
    }
    //#endregion

    //#region A.1.1.Y.C

    const a11yc = {
        ref: 'A.1.1.Y.C',
        requirementTitle: findlanguageEsg().a11ycRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a11ycPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a11ycy = {
        ref: 'A.1.1.Y.C.Y',
        requirementTitle: findlanguageEsg().a11ycyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a11ycyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a11ycyy = {
        ref: 'A.1.1.Y.C.Y.Y',
        requirementTitle: findlanguageEsg().a11ycyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a11ycyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a11ycyyql1 = {
        ref: 'A.1.1.Y.C.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().a11ycyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a11ycyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.D

    const a11yd = {
        ref: 'A.1.1.Y.D',
        requirementTitle: findlanguageEsg().a11ydRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a11ydPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a11ydy = {
        ref: 'A.1.1.Y.D.Y',
        requirementTitle: findlanguageEsg().a11ydyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a11ydyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a11ydyy = {
        ref: 'A.1.1.Y.D.Y.Y',
        requirementTitle: findlanguageEsg().a11ydyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a11ydyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const a11ydyyql1 = {
        ref: 'A.1.1.Y.D.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().a11ydyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a11ydyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#endregion

    //#region A.1.1 NO
    const a11na = {
        ref: 'A.1.1.N.A',
        requirementTitle: findlanguageEsg().a11naRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a11naPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const a11nb = {
        ref: 'A.1.1.N.B',
        requirementTitle: findlanguageEsg().a11nbRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a11nbPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const a11nc = {
        ref: 'A.1.1.N.C',
        requirementTitle: findlanguageEsg().a11ncRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().a11ncPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    const returnCondition = (item, layers, category1, category2, category3, category4) => {
        switch (layers) {
            case 0:
                return (
                    <Card
                        item={item}
                        onChangeCertifications={onChangeCertifications}
                        saveCertifications={saveCertifications}
                        saveDocument={saveDocument}
                        certifications={certifications}
                        form={form}
                        mfsQuestionarie={mfsQuestionarie}
                    />
                )
            case 1:
                if (certifications?.find((x) => x.category === category1)?.next_level === true)
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 2:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 3:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 4:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true &&
                    certifications?.find((x) => x.category === category4)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            default:
                break
        }
    }

    return (
        <>
            <PageCard>
                <CardHeader>
                    <CardHeaderTitleContainer>{findlanguageEsg().aCardHeaderTitleContainer}</CardHeaderTitleContainer>
                </CardHeader>
                {returnHeaders()}
                {returnCondition(a11, 0)}
            </PageCard>
            {
                mfsQuestionarie !== '1' ?
                    <>

                        {certifications?.find((x) => x.category === 'A.1.1')?.next_level === true ? (
                            <>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            A.1. ENERGY & FUEL CONSUMPTION - Answer Yes - A
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(a11ya, 1, 'A.1.1')}
                                    {returnCondition(a11yay, 2, 'A.1.1', 'A.1.1.Y.A')}
                                    {returnCondition(a11yayy, 3, 'A.1.1', 'A.1.1.Y.A', 'A.1.1.Y.A.Y')}
                                    {returnCondition(
                                        a11yayyql1,
                                        4,
                                        'A.1.1',
                                        'A.1.1.Y.A',
                                        'A.1.1.Y.A.Y',
                                        'A.1.1.Y.A.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            A.1. ENERGY & FUEL CONSUMPTION - Answer Yes - B
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(a11yb, 1, 'A.1.1')}
                                    {returnCondition(a11yby, 2, 'A.1.1', 'A.1.1.Y.B')}
                                    {returnCondition(a11ybyqt1, 3, 'A.1.1', 'A.1.1.Y.B', 'A.1.1.Y.B.Y')}
                                    {returnCondition(a11ybyqt2, 3, 'A.1.1', 'A.1.1.Y.B', 'A.1.1.Y.B.Y')}
                                    {returnCondition(a11ybyqt3, 3, 'A.1.1', 'A.1.1.Y.B', 'A.1.1.Y.B.Y')}
                                    {returnCondition(a11ybyqtn, 3, 'A.1.1', 'A.1.1.Y.B', 'A.1.1.Y.B.Y')}
                                    {returnCondition(a11ybyql1, 3, 'A.1.1', 'A.1.1.Y.B', 'A.1.1.Y.B.Y')}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            A.1. ENERGY & FUEL CONSUMPTION - Answer Yes - C
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(a11yc, 1, 'A.1.1')}
                                    {returnCondition(a11ycy, 2, 'A.1.1', 'A.1.1.Y.C')}
                                    {returnCondition(a11ycyy, 3, 'A.1.1', 'A.1.1.Y.C', 'A.1.1.Y.C.Y')}
                                    {returnCondition(
                                        a11ycyyql1,
                                        4,
                                        'A.1.1',
                                        'A.1.1.Y.C',
                                        'A.1.1.Y.C.Y',
                                        'A.1.1.Y.C.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            A.1. ENERGY & FUEL CONSUMPTION - Answer Yes - D
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(a11yd, 1, 'A.1.1')}
                                    {returnCondition(a11ydy, 2, 'A.1.1', 'A.1.1.Y.D')}
                                    {returnCondition(a11ydyy, 3, 'A.1.1', 'A.1.1.Y.D', 'A.1.1.Y.D.Y')}
                                    {returnCondition(
                                        a11ydyyql1,
                                        4,
                                        'A.1.1',
                                        'A.1.1.Y.D',
                                        'A.1.1.Y.D.Y',
                                        'A.1.1.Y.D.Y.Y'
                                    )}
                                </PageCard>
                            </>
                        ) : null}
                        {certifications?.find((x) => x.category === 'A.1.1')?.next_level === false ? (
                            <PageCard>
                                <CardHeader>
                                    <CardHeaderTitleContainer>
                                        A.1. ENERGY & FUEL CONSUMPTION - Answer No
                                    </CardHeaderTitleContainer>
                                </CardHeader>
                                {returnHeaders()}
                                {returnCondition(a11na, 0)}
                                {returnCondition(a11nb, 0)}
                                {returnCondition(a11nc, 0)}
                            </PageCard>
                        ) : null}
                    </>
                    :
                    null
            }
        </>
    )
}
