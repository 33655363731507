import React, { useState } from 'react'

import { PageHeaderMore, MoreOptionsContainer, MoreOption, MoreContainer } from './styled'

export default function DashboardMore({ options, vertical, sender, selectedRoomId }) {
    const [opened, setOpened] = useState(false)

    const action = (item) => {
        if (item.action && typeof item.action === 'function') {
            item.action()
        }
        setOpened(false)
    }

    return (
        <MoreContainer>
            {options.length > 0 ? (
                <>
                    <PageHeaderMore onClick={() => setOpened(!opened)} vertical={vertical} />
                    {!opened ? null : (
                        <MoreOptionsContainer className="MoreOptionsContainer">
                            {options?.map((item, key) => (
                                <MoreOption
                                    key={key}
                                    link={item.link}
                                    primary={item.primary}
                                    onClick={() => {
                                        item.action(sender, selectedRoomId)
                                        setOpened(!opened)
                                    }}
                                >
                                    {item.title}
                                </MoreOption>
                            ))}
                        </MoreOptionsContainer>
                    )}
                </>
            ) : (
                ''
            )}
        </MoreContainer>
    )
}
