import { GET, POST, DELETE, PUT } from './api'

export const FetchMyServiceProviders = async (type) => {
    return await GET(`/service-provider-accesses/allproviders`, true)
}

export const GetServiceProvider = async (id) => {
    return await GET(`/service-provider-accesses/${id}`, true)
}
export const PostNewServiceProvider = async (body) => {
    return await POST(`/service-provider-accesses`, body, true)
}

export const UpdateServiceProvider = async (id, body) => {
    return await PUT(`/service-provider-accesses/${id}`, body, true)
}

export const DeleteServiceProvider = async (id) => {
    return await DELETE(`/service-provider-accesses/${id}`, true)
}

export const FindIfAnyProviders = async (type) => {
    return await GET(`/service-provider-accesses/allselectors`, true)
}

export const UpdateServiceProviderId = async (body) => {
    return await POST(`/service-provider-accesses/allchange`, body, true)
}