import { DELETE, GET, POST, PUT } from './api'

export const GetDocuments = async (query) => {
    query = query.map((x) => x.replace(/&/g, '12345678909897'))
    const suffix = query?.length ? `?${query.join('&')}` : ``

    return await GET(`/documents/allfromuser${suffix}`, true)
}

export const GetUsersFromCompany = async (language) => {
    return await GET(`/documents/usersfromcompany?language=${language}`, true)
}

export const PostDocuments = async (body) => {
    return await POST(`/documents`, body, true)
}
