import styled from 'styled-components'

export const ContentTextarea = styled.div.attrs({})`
    width: 100%;
    max-width: 353px;
    margin-bottom: 12px;
    ${(props) =>
        props.bigger
            ? `
            max-width: 100%;
            margin-bottom: 40px;
        `
            : ``}

    ${(props) =>
        props.medium
            ? `
            max-width: 100%;
            margin-bottom: 40px;
        `
            : ``}
`
export const TextareaLabel = styled.div.attrs({})`
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--primary-color);
    margin-bottom: 12px;
`
export const TextareaInputContent = styled.div.attrs({})`
    border: 0.5px solid var(--lightgrey-color);
    border-radius: 10px;
    padding: 12px;
`
export const TextareaInput = styled.textarea.attrs({})`
    resize: none;
    border: none;
    background: none;
    outline: none;
    width: 100%;
    height: 70px;
    ${(props) =>
        props.bigger
            ? `
            height: 300px;
        `
            : ``}

    ${(props) =>
        props.medium
            ? `
            height: 100px;
        `
            : ``}
`
export const TextareaLength = styled.div.attrs({})`
    text-align: right;
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--blue-color);
`
