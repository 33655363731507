import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const Content = styled.div.attrs({})`
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
`

export const PageCard = styled.div.attrs({})`
    padding: 38px 24px;
    background: var(--white-color);

    margin-bottom: 5px;
    width: 100%;
`

export const PageTitle = styled.div.attrs({})`
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 50px;
    color: var(--grey-color);
    line-height: 68px;
    text-align: center;
    margin-top: 50px;
`

export const PageSubTitle = styled.div.attrs({})`
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: var(--grey-color);
    margin-bottom: 20px;
    text-align: center;
`
export const Tabs = styled.div.attrs({})`
    display: flex;
    margin: 0 auto;
    padding: 0px;
    width: 392px;
`

export const Tab11 = styled.div.attrs({})`
    width: 116px;
    height: 28px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 5px;
`

export const Tab21 = styled.div.attrs({})`
    width: 101px;
    height: 28px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 5px;
`

export const Tab31 = styled.div.attrs({})`
    width: 165px;
    height: 28px;
    flex: none;
    order: 0;
    flex-grow: 0;
`

export const Tab12 = styled.div.attrs({})`
    /*cursor: pointer;*/
    width: 116px;
    height: 40px;
    margin-right: 5px;

    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 14px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 9px 12px;

    box-sizing: border-box;
    border: 1px solid #dddddd;
    border-bottom: 0px;
    box-shadow: inset 0px -1px 0px #ffffff;
    border-radius: 2px 2px 0px 0px;
`

export const Tab22 = styled.div.attrs({})`
    /*cursor: pointer;*/
    width: 101px;
    height: 40px;
    background: #f8f8f8;

    margin-right: 5px;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 14px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 9px 12px;

    box-sizing: border-box;
    border: 1px solid #dddddd;
    border-bottom: 0px;
    box-shadow: inset 0px -1px 0px #ffffff;
    border-radius: 2px 2px 0px 0px;
`

export const Tab32 = styled.div.attrs({})`
    /*cursor: pointer;*/
    width: 165px;
    height: 40px;
    background: #f8f8f8;

    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 14px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 9px 10px;

    box-sizing: border-box;
    border: 1px solid #dddddd;
    border-bottom: 0px;
    box-shadow: inset 0px -1px 0px #ffffff;
    border-radius: 2px 2px 0px 0px;
`

export const Tab12Active = styled.div.attrs({})`
    /*cursor: pointer;*/
    width: 116px;
    height: 40px;
    margin-right: 5px;

    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 14px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 9px 12px;

    background: #ffffff;

    box-sizing: border-box;
    border: 1px solid #dddddd;
    border-bottom: 0px;
    box-shadow: inset 0px -1px 0px #ffffff;
    border-radius: 2px 2px 0px 0px;
`

export const Tab22Active = styled.div.attrs({})`
    /*cursor: pointer;*/
    width: 101px;
    height: 40px;
    background: #ffff;

    margin-right: 5px;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 14px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 9px 12px;

    box-sizing: border-box;
    border: 1px solid #dddddd;
    border-bottom: 0px;
    box-shadow: inset 0px -1px 0px #ffffff;
    border-radius: 2px 2px 0px 0px;
`

export const Tab32Active = styled.div.attrs({})`
    cursor: pointer;
    width: 165px;
    height: 40px;
    background: #ffff;

    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 14px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 9px 10px;

    box-sizing: border-box;
    border: 1px solid #dddddd;
    border-bottom: 0px;
    box-shadow: inset 0px -1px 0px #ffffff;
    border-radius: 2px 2px 0px 0px;
`

export const SupplierRecomendedLabel = styled.img.attrs((props) => ({
    src: `/images/supplier-recomended-label.png`,
}))``

export const BuyerRecomendedLabel = styled.img.attrs((props) => ({
    src: `/images/buyer-recomended-label.png`,
}))``

export const ServiceProviderRecomendedLabel = styled.img.attrs((props) => ({
    src: `/images/service-provider-recomended-label.png`,
}))``

export const PlansBody = styled.div.attrs({})`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 42px;
    flex-wrap: wrap;
    max-width: 1020px;

    margin: 0 auto;
    margin-bottom: 50px;
`

export const PlansBodyCentered = styled.div.attrs({})`
    display: flex;
    flex-direction: row;

    justify-content: center;
    padding: 0px;
    gap: 42px;
    flex-wrap: wrap;
    max-width: 1020px;

    margin: 0 auto;
`

export const PlanContent = styled.div.attrs({})`
    padding: 24px 32px;
    gap: 42px;

    width: 310px;

    background: #ffffff;
    border: 1px solid #e0e3e8;
    border-radius: 8px;
`

export const PlanTitlePanel = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;

    width: 246px;
`

export const FeaturesPanel = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 5px;
    margin-top: 42px;
    width: 246px;
`

export const PlanTitle = styled.div.attrs({})`
    height: 39px;
    width: 100%;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: -0.01em;

    color: #000000;
`

export const PlanTitleDescription = styled.div.attrs({})`
    width: 215px;
    height: 19px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 10px;

    text-align: center;
    letter-spacing: 0.01em;

    color: #536174;
`

export const PlanPricePanel = styled.div.attrs({})`
    padding: 0px;
    gap: 2px;

    width: 100%;
    height: 58px;
`

export const PlanPriceGroup = styled.div.attrs({})`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 2px;
    height: 58px;
`

export const PlanPriceSimbol = styled.div.attrs({})`
    width: 45px;
    height: 39px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */

    text-align: right;
    letter-spacing: -0.01em;

    color: #000000;

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const PlanPriceDecimal = styled.div.attrs({})`
    width: 40px;
    height: 39px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 39px;

    text-align: left;
    letter-spacing: -0.01em;

    color: #000000;

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const PlanPrice = styled.div.attrs({})`
    height: 58px;
    margin-left: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;

    text-align: center;
    letter-spacing: -0.01em;
    color: #000000;
`

export const PlanActive = styled.div.attrs({})`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;

    width: 100px;
    height: 36px;

    background: #f6f6f6;
    border-radius: 8px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.01em;

    color: #7e8b9e;
`

export const PlanInfo = styled.div.attrs({})`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;

    width: 106px;
    height: 36px;

    background: #78accc;
    border-radius: 8px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.01em;

    color: #ffffff;
`

export const FeatureTitle = styled.div.attrs({})`
    width: 100%;
    height: 23px;
    text-align: left;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    font-weight: bold;
    letter-spacing: 0.01em;

    color: #14171c;
`

export const FeatureDescription = styled.div.attrs({})`
    width: 100%;
    margin-bottom: 18px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    text-align: left;
    letter-spacing: 0.01em;

    color: #536174;
`

export const ItemsPanel = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    width: 246px;
`

export const Item = styled.div.attrs({})`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    width: 100%;
`

export const ItemIco = styled.img.attrs((props) => ({
    src: `/icons/item-check.png`,
}))``

export const ItemText = styled.div.attrs({})`
    width: 100%;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    margin-left: 10px;

    text-align: left;
    letter-spacing: 0.01em;

    color: #536174;
`

export const PlanTitleGroup = styled.div.attrs({})`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 100%;
    height: 39px;
    margin-bottom: 30px;
`

export const PlanTrial = styled.div.attrs({})`
    padding: 8px 10px;
    gap: 10px;

    width: 94px;
    height: 31px;

    background: #78accc;
    border-radius: 100px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.01em;

    color: #ffffff;
`

export const PlanLabel = styled.div.attrs({})`
    width: 150px;
    height: 16px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    letter-spacing: 0.01em;
    color: #98a2b2;

    margin-left: 150px;
`

export const ButtonPlan = styled.div.attrs({})`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 10px;
    gap: 10px;

    margin-top: 20px;

    width: 244px;
    height: 43px;

    background: #78accc;
    border-radius: 4px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    text-align: center;
    letter-spacing: 0.01em;

    color: #ffffff;
`

export const FeaturesSeparator = styled.div.attrs({})`
    width: 246px;
    height: 0px;

    border: 1px solid #e1e1e1;

    margin-top: 5px;
`

export const PlansAction = styled.div.attrs({})`
    display: flex;
    justify-content: center;
    padding: 60px; 0 0;
`

export const BillingSellector = styled.div.attrs({})`
    width: 270px;
    height: 83px;

    margin: 0 auto;
    padding: 0px;

    margin-bottom: 15px;
`

export const SaveUpGroup = styled.div.attrs({})`
    display: flex;
    justify-content: center;

    position: relative;
`

export const SaveUp = styled.div.attrs({})`
    position: relative;
    left: 40%;

    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;

    color: #000000;

    transform: rotate(10.85deg);

    @media (max-width: 767px) {
        left: 1%;
        margin-bottom: 20px;
    }
`

export const SaveUpImg = styled.img.attrs({
    src: `/images/save-up-leg.png`,
})`
    position: relative;
    left: 1;
    top: 25px;

    @media (max-width: 767px) {
        display: none;
    }
`

export const BillingSellectorChoices = styled.div.attrs({})`
    display: flex;
    align-items: center;
    gap: 10px;
`

export const BillingSellectorMonthly = styled.div.attrs({})`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    text-align: right;

    color: #000000;
`

export const BillingSellectorYearly = styled.div.attrs({})`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    text-align: center;

    color: #000000;
`

export const BillingSellectorButton = styled.img.attrs((props) => ({
    src: props.state ? `/images/switch-${props.state}.png` : null,
}))`
    cursor: pointer;
`

export const ComboContainer = styled.div.attrs({})`
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
`

export const ComboItem = styled.div.attrs({})`
    width: 200px;
    margin: 0 auto;
`
