import React, { useContext, useState } from 'react'
import ReactPaginate from 'react-paginate'
import DashboardFilterScrapingCard from './FilterScraping/index'
import { CoreContext } from 'context/CoreContext'

export default function RegularView({ findedUsers, progress, openModal }) {
    const { findlanguage } = useContext(CoreContext)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPerPage, setTotalPerPage] = useState(10)

    // Determine the current items to display
    const indexOfLastItem = currentPage * totalPerPage
    const indexOfFirstItem = indexOfLastItem - totalPerPage
    const currentItems = findedUsers.slice(indexOfFirstItem, indexOfLastItem)

    // Update page count based on the total number of items and items per page
    const pageCount = Math.ceil(findedUsers.length / totalPerPage)

    // Handle page change
    const handlePageChange = (data) => {
        setCurrentPage(data.selected + 1)
    }

    // Handle items per page change
    const handleTotalPerPageChange = (event) => {
        setTotalPerPage(Number(event.target.value))
        setCurrentPage(1) // Reset to first page
    }

    return (
        <div className="internal-div">
            <div className="row">
                {currentItems.length === 0 ? (
                    <div className="alert alert-primary" role="alert" style={{ width: '100%' }}>
                        {findlanguage().noResultsChangeFilter}
                    </div>
                ) : (
                    currentItems.map((x, index) => (
                        <DashboardFilterScrapingCard key={index} item={x} openModal={openModal} />
                    ))
                )}
            </div>
            <div id="reactPaginate" style={{ display: 'flex' }}>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel={`${findlanguage().next} > `}
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel={`< ${findlanguage().previous}`}
                    renderOnZeroPageCount={null}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                    forcePage={currentPage - 1}
                />
                <select
                    className="form-select"
                    style={{ marginLeft: '10px', width: '10%', height: '38px' }}
                    value={totalPerPage}
                    onChange={handleTotalPerPageChange}
                >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                </select>
            </div>
        </div>
    )
}
