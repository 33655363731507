import React, { useContext } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useHistory } from 'react-router-dom'

import {
    PageHeader,
    PageHeaderTitle,
    PageHeaderActions,
    PageHeaderBack,
    PageCard,
    ProfileContainer,
    ProfileContentSpace,
    PlanContainer,
    PlanSection,
    PlanIconContent,
    PlanIconIcon,
    PlanTitle,
    PlanText,
    PlanCard,
    PlanCardIcon,
    PlanLink,
} from './styled'

import ContainerAuthenticated from 'containers/Authenticated'

import DashboardButton from 'components/Dashboard/Button'
import { CoreContext } from 'context/CoreContext'

export default function DashboardAccountInformation() {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)
    const back = () => history.goBack()

    const { setModal, findlanguage } = useContext(CoreContext)

    const plans = [
        {
            title: findlanguage().premiumSubscription,
            color: 'gold',
            price: '$30.99/' + findlanguage().month,
            renew_at: findlanguage().renewdText,
            payment: { brand: 'visa', card_final: '0987' },
        },
        {
            title: findlanguage().proSubscription,
            price: '$49.99/Month' + findlanguage().month,
            renew_at: findlanguage().renewdText,
            payment: { brand: 'visa', card_final: '0987' },
        },
    ]

    const openCancelSubscription = () => {
        setModal({
            type: 'choose',
            title: 'Do you really want to unsubscribe?',
            options: [
                { title: 'No, Keep', action: () => setModal(null) },
                { title: 'Yes, Cancel', action: () => openReallyCancel(), outline: true },
            ],
        })
    }

    const openReallyCancel = () => {
        setModal({ type: 'cancel-subscription' })
    }

    return (
        <>
            <ContainerAuthenticated>
                <Container fluid={false}>
                    <Row>
                        <Col>
                            <PageCard>
                                <PageHeader>
                                    <PageHeaderActions>
                                        <PageHeaderBack onClick={back} />
                                        <PageHeaderTitle>
                                            {findlanguage().membershipAndSubscription}
                                        </PageHeaderTitle>
                                    </PageHeaderActions>
                                    <PageHeaderActions />
                                </PageHeader>
                                <ProfileContainer>
                                    {plans?.map((item, key) => (
                                        <PlanContainer key={key}>
                                            <PlanSection row>
                                                <PlanIconContent gold={item.color === 'gold'}>
                                                    <PlanIconIcon />
                                                </PlanIconContent>
                                                <PlanTitle>{item.title}</PlanTitle>
                                            </PlanSection>
                                            <PlanSection>
                                                <PlanTitle>{item.price}</PlanTitle>
                                                <PlanText>{item.renew_at}</PlanText>
                                            </PlanSection>
                                            <PlanSection toend>
                                                <PlanTitle>
                                                    {findlanguage().paymentMethod}
                                                </PlanTitle>
                                                <PlanCard>
                                                    <PlanCardIcon icon={item.payment.brand} />
                                                    <PlanText>
                                                        Ending {item.payment.card_final}
                                                    </PlanText>
                                                </PlanCard>
                                                <PlanLink>{findlanguage().change}</PlanLink>
                                            </PlanSection>
                                            <PlanSection last>
                                                <ProfileContentSpace />
                                                <DashboardButton
                                                    onClick={() =>
                                                        navigate('dashboard/buy/premium')
                                                    }
                                                >
                                                    {findlanguage().upgradeSubscription}
                                                </DashboardButton>
                                                <ProfileContentSpace />
                                                <DashboardButton
                                                    grey
                                                    onClick={openCancelSubscription}
                                                >
                                                    {findlanguage().cancelSubscription}
                                                </DashboardButton>
                                            </PlanSection>
                                        </PlanContainer>
                                    ))}
                                </ProfileContainer>
                            </PageCard>
                        </Col>
                    </Row>
                </Container>
            </ContainerAuthenticated>
        </>
    )
}
