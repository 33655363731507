import React, { useContext } from 'react'

import { ProfileFormActions, PageTitle, PageText, PageIcon } from './styled'

import DashboardButton from 'components/Dashboard/Button'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

export default function ModalCancelSubscription() {
    const { setModal, findlanguage } = useContext(CoreContext)

    const close = () => {
        setModal(null)
    }

    const cancelAction = () => {
        setModal({
            type: 'alert',
            text: findlanguage().cancellationSuccessful,
        })
    }
    return (
        <>
            <ModalWrapper>
                <PageIcon />
                <PageTitle>When you cancel, you lose full access to the subscription</PageTitle>
                <PageText>Do you want to cancel?</PageText>
                <ProfileFormActions>
                    <DashboardButton primary onClick={close}>
                        No, Keep
                    </DashboardButton>
                    <DashboardButton outline grey onClick={cancelAction}>
                        Yes, Cancel
                    </DashboardButton>
                </ProfileFormActions>
            </ModalWrapper>
        </>
    )
}
