import React, { useContext, useState, useEffect, useRef } from 'react';
import { PageCard } from './styled';
import { CoreContext } from 'context/CoreContext';
import { GetSectionAndElements, RejectSection, ApproveSection, ApproveLastSectionSection } from 'services/steps';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignatureCanvas from 'react-signature-canvas';
import { parseStrapiImage } from 'utils';
import { PostStepAnswerFiles } from 'services/api';
import { Modal, Button, Form as BootstrapForm } from 'react-bootstrap';

const BackButton = ({ handleBack }) => (
    <button
        type="button"
        className="btn colorTealDark"
        onClick={handleBack}
        style={{
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            backgroundColor: '#4f6b7d',
            color: 'white',
            marginRight: '10px'
        }}
    >
        Back
    </button>
);

const SaveButton = ({ handleSubmit, disabled }) => (
    <button
        type="button"
        className="btn btn-primary"
        onClick={handleSubmit}
        style={{
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
        }}
        disabled={disabled}
    >
        Save
    </button>
);

const RenderElement = ({ element, value, handleChange, errors, signatureRefs, clearSignature, isOwner }) => {
    const colClass = `col-md-${element.col_size}`;
    const error = errors[element.id];

    const commonProps = {
        className: `form-control ${error ? 'is-invalid' : ''}`,
        value: value || '',
        onChange: (e) => handleChange(e, element.id),
        required: element.is_required,
        disabled: isOwner,
    };

    useEffect(() => {
        if (element.type === 'signature' && value) {
            signatureRefs[element.id].fromDataURL(value);
        }
    }, [element.type, value, signatureRefs, element.id]);

    return (
        <div className={`form-group ${colClass}`} key={element.id}>
            <label>{element.label}</label>
            {element.type === 'title' && <h1>{element.label}</h1>}
            {element.type === 'subtitle' && <h3>{element.label}</h3>}
            {element.type === 'description' && <p>{element.label}</p>}
            {element.type === 'text' && <input type="text" {...commonProps} />}
            {element.type === 'list' && (
                <select {...commonProps} multiple={element.allow_multiples}>
                    <option value=""></option>
                    {element.options.map(option => (
                        <option key={option.id} value={option.value}>
                            {option.value}
                        </option>
                    ))}
                </select>
            )}
            {element.type === 'date' && <input type="date" {...commonProps} />}
            {element.type === 'calendar' && <input type="datetime-local" {...commonProps} />}
            {element.type === 'checkbox' && element.options.map(option => (
                <div key={option.id} className="form-check">
                    <input
                        type="checkbox"
                        className={`form-check-input ${error ? 'is-invalid' : ''}`}
                        id={`checkbox-${option.id}`}
                        value={option.value}
                        checked={value?.includes(option.value) || false}
                        onChange={(e) => {
                            const valueArray = Array.isArray(value) ? value : [];
                            if (e.target.checked) {
                                handleChange({ target: { value: [...valueArray, option.value] } }, element.id);
                            } else {
                                handleChange({ target: { value: valueArray.filter(v => v !== option.value) } }, element.id);
                            }
                        }}
                        required={element.is_required}
                        disabled={isOwner}
                    />
                    <label className="form-check-label" htmlFor={`checkbox-${option.id}`}>
                        {option.value}
                    </label>
                </div>

            ))}
            {element.type === 'attach' && (
                <>
                    <input type="file" className={`form-control ${error ? 'is-invalid' : ''}`} onChange={(e) => handleChange(e, element.id)} disabled={isOwner} />
                    {element.files?.file && (
                        <div key={element.files.file.id}>
                            <a href={parseStrapiImage(element?.files?.file?.url)} target="_blank" rel="noopener noreferrer">{element.files.file.name}</a>
                        </div>
                    )}
                </>
            )}
            {element.type === 'video' && element.files?.file && (
                <div key={element.files.id}>
                    <video width="320" height="240" controls>
                        <source src={parseStrapiImage(element.files.file.url)} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            )}
            {element.type === 'signature' && (
                <div>
                    <SignatureCanvas
                        penColor="black"
                        ref={ref => { signatureRefs[element.id] = ref; }}
                        canvasProps={{
                            width: 400,
                            height: 200,
                            className: `signature-canvas ${error ? 'is-invalid' : ''}`,
                            style: { border: '1px solid lightgray' }
                        }}
                        disabled={isOwner}
                    />
                    {!isOwner && (
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => clearSignature(element.id)}
                            style={{ marginTop: '10px' }}
                        >
                            Clear Signature
                        </button>
                    )}
                </div>
            )}
            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export default function Element({ handleBack, form, getForm, isOwner }) {
    const { findlanguage } = useContext(CoreContext);
    const [section, setSection] = useState(null);
    const [formValues, setFormValues] = useState({});
    const [errors, setErrors] = useState({});
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [showExpireModal, setShowExpireModal] = useState(false);
    const [rejectReason, setRejectReason] = useState('');
    const [expireDate, setExpireDate] = useState(new Date());
    const signatureRefs = useRef({});

    useEffect(() => {
        const fetchForm = async () => {
            const section = await GetSectionAndElements(form.id, form.specificStep);

            const initialFormValues = {};
            section.elements.forEach(element => {
                if (element.existing_answer) {
                    initialFormValues[element.id] = element.existing_answer.answer;
                }
            });

            setSection(section);
            setFormValues(initialFormValues);
        };

        fetchForm();
    }, [form.id, form.specificStep]);

    const handleChange = (e, elementId) => {
        const { type, files } = e.target;
        if (type === 'file') {
            setFormValues(prevValues => ({
                ...prevValues,
                [elementId]: files[0]
            }));
        } else {
            setFormValues(prevValues => ({
                ...prevValues,
                [elementId]: e.target.value
            }));
        }
    };

    const handleSubmit = async () => {
        const newErrors = {};
        const formData = new FormData();
        formData.append('step_assigned_to_company_id', form.id);
        formData.append('step_assigned_to_users_id', form.step_assigned_to_users_id);

        const answers = [];

        section.elements.forEach(element => {
            const value = formValues[element.id];
            if (element.is_required && !value) {
                newErrors[element.id] = 'Field required';
            }
            if (element.type === 'signature' && signatureRefs.current[element.id] && signatureRefs.current[element.id].isEmpty()) {
                newErrors[element.id] = 'Signature required';
            }

            if (element.type === 'attach' && value instanceof File) {
                formData.append(`files.${element.id}`, value);
            } else if (value) {
                answers.push({
                    step_element_id: element.id,
                    answer: value
                });
            }
        });

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            toast.error('Please fill out the required fields.', {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setErrors({});
            Object.keys(signatureRefs.current).forEach(id => {
                if (signatureRefs.current[id]) {
                    answers.push({
                        step_element_id: id,
                        answer: signatureRefs.current[id].toDataURL()
                    });
                }
            });

            formData.append('answers', JSON.stringify(answers));

            await PostStepAnswerFiles(formData);
            await getForm();
            handleBack();
        }
    };

    const clearSignature = (id) => {
        if (signatureRefs.current[id]) {
            signatureRefs.current[id].clear();
        }
    };

    const handleApprove = async () => {
        const isLastStep = form.current_step === Math.max(...form.step_form_id.sections.map(section => section.step_section_id.id));
        if (isLastStep) {
            setShowExpireModal(true);
        } else {
            try {
                const response = await ApproveSection({
                    step_assigned_to_company_id: form.id
                });

                if (response.message === 'Approved successfully') {
                    toast.success('Approved successfully.', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setShowRejectModal(false);
                    await getForm();
                    handleBack();
                } else {
                    toast.error('Failed to reject section.', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } catch (error) {
                toast.error('An error occurred while approving the section.', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };

    const handleReject = () => {
        setShowRejectModal(true);
    };

    const submitApproval = async () => {
        try {
            const response = await ApproveLastSectionSection({
                step_assigned_to_company_id: form.id,
                expired_date: expireDate
            });

            if (response.message === 'Approved') {
                toast.success('Approved successfully.', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setShowRejectModal(false);
                await getForm();
                handleBack();
            } else {
                toast.error('Failed to reject section.', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (error) {
            toast.error('An error occurred while approving the section.', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        setShowExpireModal(false);
    };

    const submitRejection = async () => {
        try {
            const response = await RejectSection({
                sections: form.step_form_id.sections.filter(x => x.step_section_id.id === form.current_step).map(section => ({
                    sectionId: section.id
                })),
                reasonRejected: rejectReason,
            });

            if (response.message === 'Sections rejected successfully') {
                toast.success('Section rejected successfully.', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setShowRejectModal(false);
                await getForm();
                handleBack();
            } else {
                toast.error('Failed to reject section.', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (error) {
            toast.error('An error occurred while rejecting the section.', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    return (
        <PageCard style={{ padding: '20px' }}>
            <div style={{ position: 'sticky', top: 0, zIndex: 1000, backgroundColor: 'white', padding: '10px' }}>
                <BackButton handleBack={handleBack} />
                {!isOwner && <SaveButton handleSubmit={handleSubmit} disabled={isOwner} />}
                {isOwner && form.status === 'pending_approval' && (
                    <>
                        <Button variant="success" onClick={handleApprove} style={{ marginLeft: '5px' }}>
                            Approve
                        </Button>
                        <Button variant="danger" onClick={handleReject} style={{ marginLeft: '10px' }}>
                            Reject
                        </Button>
                    </>
                )}
            </div>
            {form.status === 'rejected' && (
                <div className="alert alert-danger" role="alert" style={{ marginTop: '10px' }}>
                    Rejected: {form.reason_rejected}
                </div>
            )}
            <div style={{ marginTop: '20px' }}>
                <h2>Editing Form: {form.step_form_id.name}</h2>
                <div className="row">
                    {section && section.elements.map(element => (
                        <RenderElement
                            key={element.id}
                            element={element}
                            value={formValues[element.id]}
                            handleChange={handleChange}
                            errors={errors}
                            signatureRefs={signatureRefs.current}
                            clearSignature={clearSignature}
                            isOwner={isOwner}
                        />
                    ))}
                </div>
            </div>
            <ToastContainer />

            <Modal show={showRejectModal} onHide={() => setShowRejectModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Rejection Reason</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BootstrapForm.Group controlId="rejectReason">
                        <BootstrapForm.Label>Reason</BootstrapForm.Label>
                        <BootstrapForm.Control
                            as="textarea"
                            rows={3}
                            value={rejectReason}
                            onChange={(e) => setRejectReason(e.target.value)}
                        />
                    </BootstrapForm.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowRejectModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={submitRejection}>
                        Submit Rejection
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showExpireModal} onHide={() => setShowExpireModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Set Expiration Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BootstrapForm.Group controlId="expireDate">
                        <BootstrapForm.Label>Expiration Date</BootstrapForm.Label>
                        <BootstrapForm.Control
                            type="date"
                            value={expireDate.toISOString().slice(0, 10)}
                            onChange={(e) => setExpireDate(new Date(e.target.value))}
                        />
                    </BootstrapForm.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowExpireModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={submitApproval}>
                        Submit Approval
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageCard>
    );
}
