import { GET, POST, PUT } from './api'

//Additional Information
export const GetPrecheckServiceProvider = async (userId) => {
    return await GET(`/certification-precheck-service-providers?owner=${userId}`, true)
}

export const PutPrecheck = async (params) => {
    return await PUT(`/certification-precheck-service-providers/${params.id}`, params, true)
}

export const PostPrecheck = async (params) => {
    return await POST(`/certification-precheck-service-providers`, params, true)
}
