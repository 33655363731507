import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const Content = styled.div.attrs({})`
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
`

export const PageCard = styled.div.attrs({})`
    padding: 38px 24px;
    background: var(--white-color);

    margin-bottom: 5px;
    width: 100%;
`

export const PageTitle = styled.div.attrs({})`
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 50px;
    color: var(--grey-color);
    line-height: 68px;
    text-align: center;
    margin-top: 50px;
`

export const PageSubTitle = styled.div.attrs({})`
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: var(--grey-color);
    margin-bottom: 20px;
    text-align: center;
`

export const PlansBody = styled.div.attrs({})`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 2px;
    flex-wrap: wrap;
    width: 600px;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 20px;
`

export const PlansBodyCentered = styled.div.attrs({})`
    display: flex;
    flex-direction: row;

    justify-content: center;
    padding: 0px;
    gap: 42px;
    flex-wrap: wrap;
    max-width: 1020px;

    margin: 0 auto;
`

export const PlanContent = styled.div.attrs({})`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    padding: 24px 32px;
    gap: 42px;

    width: 310px;

    background: #ffffff;
    border: 1px solid #e0e3e8;
    border-radius: 8px;
`

export const PlanTitlePanel = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;

    width: 246px;
`

export const FeaturesPanel = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 5px;

    width: 400px;
`

export const PlanTitle = styled.div.attrs({})`
    height: 39px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    text-align: left;
    letter-spacing: -0.01em;

    color: #000000;
`

export const PlanTitleDescription = styled.div.attrs({})`
    width: 215px;
    height: 19px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 10px;

    text-align: center;
    letter-spacing: 0.01em;

    color: #536174;
`

export const PlanPricePanel = styled.div.attrs({})`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0px;
    gap: 2px;

    width: 225px;
    height: 58px;
`

export const PlanPriceGroup = styled.div.attrs({})`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 2px;
    height: 58px;
`

export const PlanPriceSimbol = styled.div.attrs({})`
    width: 21px;
    height: 39px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */

    text-align: center;
    letter-spacing: -0.01em;

    color: #000000;

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const PlanPrice = styled.div.attrs({})`
    height: 58px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;

    text-align: center;
    letter-spacing: -0.01em;

    color: #000000;
`

export const PlanActive = styled.div.attrs({})`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;

    width: 100px;
    height: 36px;

    background: #f6f6f6;
    border-radius: 8px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.01em;

    color: #7e8b9e;
`

export const PlanInfo = styled.div.attrs({})`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;

    width: 106px;
    height: 36px;

    background: #78accc;
    border-radius: 8px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.01em;

    color: #ffffff;
`

export const FeatureTitle = styled.div.attrs({})`
    width: 100%;
    height: 23px;
    text-align: left;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;

    letter-spacing: 0.01em;

    color: #14171c;
`

export const FeatureDescription = styled.div.attrs({})`
    width: 100%;
    margin-bottom: 18px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    text-align: left;
    letter-spacing: 0.01em;

    color: #536174;
`

export const ItemsPanel = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    width: 346px;
`

export const Item = styled.div.attrs({})`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    width: 100%;
`

export const ItemIco = styled.img.attrs((props) => ({
    src: `/icons/item-check.png`,
}))``

export const ItemText = styled.div.attrs({})`
    width: 100%;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    margin-left: 10px;

    text-align: left;
    letter-spacing: 0.01em;

    color: #536174;
`

export const ButtonPlan = styled.div.attrs({})`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 10px;
    gap: 10px;

    margin-top: 20px;

    width: 244px;
    height: 43px;

    background: #78accc;
    border-radius: 4px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    text-align: center;
    letter-spacing: 0.01em;

    color: #ffffff;
`

export const FeaturesSeparator = styled.div.attrs({})`
    width: 350px;
    height: 0px;

    border: 1px solid #e1e1e1;

    margin-top: 5px;
`

export const PlansAction = styled.div.attrs({})`
    display: flex;
    justify-content: center;
    padding: 10px; 0 0;
`

export const SuccessIcon = styled.img.attrs({
    src: '/icons/circle-check.svg',
})`
    margin: 0;
    width: 32px;
`

export const WarningIcon = styled.img.attrs((props) => ({
    src: `/icons/warn.svg`,
}))`
    margin: 0;
    width: 32px;
`

export const UploadHiddenInput = styled.input.attrs({
    id: 'upload-input-hidden',
    type: 'file',
    accept: 'application/csv',
})`
    display: none;
`

export const AttachItem = styled.div.attrs({})`
    display: flex;
    align-items: center;

    margin-right: 30px;
`

export const AttachItemLink = styled.div.attrs({})`
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--grey-color);
`

export const Link = styled.a.attrs({})`
    color: var(--black-color) !important;

    &:hover {
        color: var(--black-color) !important;
    }

    &:active {
        color: var(--black-color) !important;
    }

    &:visited {
        color: var(--black-color) !important;
    }
`

export const LogPanel = styled.div.attrs({})`
    padding: 5px;
    gap: 5px;
    flex-wrap: wrap;
    width: 800px;
    height: 300px;
    overflow: scroll;
    border: 1px solid var(--grey-color);
    margin-top: 20px;
`

export const LogPanelTitle = styled.div.attrs({})`
    display: flex;
    flex-direction: row;
    padding: 0px 0px 0px 10px;
    width: 100%;
    margin: 0 auto;
    font-size: 12px;
    font-family: 'Nunito', sans-serif;
    color: #fff;
    font-weight: 700;
    line-height: 30px;
    background-color: var(--grey-color);
`

export const ResultItem = styled.div.attrs({})`
    display: flex;
    flex-direction: row;
    padding: 5px;
    width: 100%;

    margin: 0 auto;

    font-size: 12px;
    font-family: 'Lucida Console', 'Courier New', monospace;
    color: var(--grey-color);
`
