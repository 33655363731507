import styled from 'styled-components'

export const ModalBody = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ModalBodyTitle = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: var(--grey-color);
    max-width: 250px;
    text-align: center;
    margin-bottom: 12px;
`

export const RateContent = styled.div.attrs({})`
    display: flex;
    padding: 20px 0 30px;
`
export const RateStar = styled.img.attrs((props) => ({
    src: props.active ? '/icons/star-on.svg' : '/icons/star-off.svg',
    width: 33,
}))`
    margin: 0 8px;
    cursor: pointer;
`
