import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { BodyContentTabs, BodyTab } from './styled'

import DashboardUserDetailsTabCompany from 'components/Dashboard/Details/TabCompany'
import DashboardUserDetailsTabFoodSafety from 'components/Dashboard/Details/TabFoodSafety'
import DashboardUserDetailsTabSustainability from 'components/Dashboard/Details/TabSustainability'
import { CoreContext } from 'context/CoreContext'

export default function DashboardUserDetailsUserTabs({
    userProfile,
    products,
    certificates,
    typeOfView,
}) {
    //console.log(certificates)
    const history = useHistory()

    const { setModal, language, premium, needToBlockField, findlanguage } = useContext(CoreContext)

    const [tab, setTab] = useState(0)

    const tabs = () => {
        const t = [{ title: findlanguage().companyInformation }]
        if (premium.type !== 'free' && typeOfView === 'supplier') {
            t.push({ title: findlanguage().certificationApproval })
        }
        return t
    }

    const companyInfos =
        typeOfView === 'supplier'
            ? [
                  {
                      title: findlanguage().typeOfBusiness,
                      link:
                          userProfile?.businessTypes?.length > 0
                              ? findlanguage().seeAll
                              : findlanguage().noItems,
                      action: () =>
                          userProfile?.businessTypes?.length > 0 ? openViewSupplier('business') : {},
                  },
                  {
                      title: findlanguage().supplierProducts,
                      link: products?.length > 0 ? findlanguage().seeAll : findlanguage().noItems,
                      action: () => (products?.length > 0 ? openViewSupplier('products') : {}),
                  },
                  {
                      title: findlanguage().category,
                      link:
                          userProfile?.companyCategories?.length > 0
                              ? findlanguage().seeAll
                              : findlanguage().noItems,
                      action: () =>
                          userProfile?.companyCategories?.length > 0
                              ? openViewSupplier('category')
                              : {},
                  },
                  {
                      title: findlanguage().distribution,
                      link:
                          userProfile?.distributionArray?.length > 0
                              ? findlanguage().seeAll
                              : findlanguage().noItems,
                      action: () =>
                          userProfile?.distributionArray?.length > 0
                              ? openViewSupplier('distribution')
                              : {},
                  },
              ]
            : typeOfView === 'buyer'
            ? [
                  {
                      title: findlanguage().typeOfBusiness,
                      link:
                          userProfile?.businessTypes?.length > 0
                              ? findlanguage().seeAll
                              : findlanguage().noItems,
                      action: () =>
                          userProfile?.businessTypes?.length > 0 ? openViewBuyer('business') : {},
                  },
                  {
                      title: findlanguage().preRequirementChooseSupplier,
                      link:
                          userProfile?.supplierPreRequirements?.length > 0
                              ? findlanguage().seeAll
                              : findlanguage().noItems,
                      action: () =>
                          userProfile?.supplierPreRequirements?.length > 0
                              ? openViewBuyer('prerequirements')
                              : {},
                  },
                  //{
                  //    title: findlanguage().mostSearchedCategories,
                  //    link:
                  //        userProfile?.myCategories?.length > 0
                  //            ? findlanguage().seeAll
                  //            : findlanguage().noItems,
                  //    action: () =>
                  //        userProfile?.myCategories?.length > 0 ? openViewBuyer('category') : {},
                  //},
              ]
            : [
                  {
                      title: findlanguage().category,
                      link:
                          userProfile?.myCategories?.length > 0
                              ? findlanguage().seeAll
                              : findlanguage().noItems,
                      action: () =>
                          userProfile?.myCategories?.length > 0
                              ? openViewServiceProvider('category')
                              : {},
                  },
              ]

    const openViewSupplier = (changeList) => {
        if (changeList === 'business')
            setModal({
                type: 'approved',
                title: findlanguage().typeOfBusiness,
                list: userProfile?.businessTypes,
                changeList: changeList,
            })
        else if (changeList === 'products')
            setModal({
                type: 'approved',
                title: findlanguage().productsApprovedByMyTS,
                list: products,
                changeList: changeList,
            })
        else if (changeList === 'category')
            setModal({
                type: 'approved',
                title: findlanguage().companyCategories,
                list: userProfile?.companyCategories,
                changeList: changeList,
            })
        else if (changeList === 'distribution')
            setModal({
                type: 'approved',
                title: findlanguage().distribution,
                list: userProfile?.distributionArray,
                changeList: changeList,
            })
    }

    const openViewServiceProvider = (changeList) => {
        if (changeList === 'category')
            setModal({
                type: 'approved',
                title: findlanguage().serviceProviderCategories,
                list: userProfile?.myCategories,
                changeList: changeList,
            })
    }

    const openViewBuyer = (changeList) => {
        if (changeList === 'business')
            setModal({
                type: 'approved',
                title: findlanguage().typeOfBusiness,
                list: userProfile?.businessTypes,
                changeList: changeList,
            })
        else if (changeList === 'prerequirements')
            setModal({
                type: 'approved',
                title: 'Pre-requirements for choosing a supplier',
                list: userProfile?.supplierPreRequirements,
                changeList: changeList,
            })
        else if (changeList === 'category')
            setModal({
                type: 'approved',
                title: 'Company categories',
                list: userProfile?.companyCategories,
                changeList: changeList,
            })
        else if (changeList === 'distribution')
            setModal({
                type: 'approved',
                title: findlanguage().distribution,
                list: userProfile?.distributionArray,
                changeList: changeList,
            })
    }

    return (
        <>
            <BodyContentTabs>
                {tabs().map((item, key) => (
                    <BodyTab key={key} active={tab === key} onClick={() => setTab(key)}>
                        {item.title}
                    </BodyTab>
                ))}
            </BodyContentTabs>

            {tab === 0 ? (
                <>
                    {needToBlockField(userProfile) ? (
                        <>
                            <img
                                src={
                                    language === 'portugues'
                                        ? '/images/items-blurry-pt.png'
                                        : '/images/items-blurry.png'
                                }
                                width={500}
                            ></img>
                        </>
                    ) : (
                        <DashboardUserDetailsTabCompany infos={companyInfos} />
                    )}
                </>
            ) : null}

            {tab === 1 ? (
                <>
                    {needToBlockField(userProfile) ? (
                        <>
                            <img src={'/images/items-blurry.png'} width={500}></img>
                        </>
                    ) : (
                        <DashboardUserDetailsTabFoodSafety infos={certificates} />
                    )}
                </>
            ) : null}
        </>
    )
}
