import React from 'react'

import { ProfileFormContent } from './styled'

import ModalWrapper from '../Wrapper'
import CardUserAddable from 'components/Dashboard/Cards/UserAddable'

export default function ModalViews() {
    const actions = [{ icon: 'user-add' }, { icon: 'chatsent' }]

    return (
        <>
            <ModalWrapper big>
                <ProfileFormContent>
                    {[0, 1, 2, 3, 4, 5, 6, 7].map((item, key) => (
                        <CardUserAddable key={key} actions={actions} />
                    ))}
                </ProfileFormContent>
            </ModalWrapper>
        </>
    )
}
