import React, { useState } from 'react';
import { PageCard } from './styled';

export default function AddElementsButtons({ sections, setSections, currentStep, setCurrentStep, setShowPreview }) {
    const [nextId, setNextId] = useState(0);

    const addStep = () => {
        const newStepName = `Step ${sections.length + 1}`;
        const newStep = {
            name: newStepName,
            elements: [],
            icon: 'plus-circle',
            questions_to_pass: 0,
            type: "form"
        };
        setSections([...sections, newStep]);
        setCurrentStep(sections.length);
    };

    const addElement = (type) => {
        let newElement = {
            id: nextId,
            type: type,
            col_size: 'col-12',
            is_required: false,
        };

        switch (type) {
            case 'checkbox':
                newElement = {
                    ...newElement,
                    type: 'checkbox',
                    label: '',
                    allowMultiple: false,
                    options: [],
                    correctOption: null,
                };
                break;
            case 'list':
                newElement = {
                    ...newElement,
                    type: 'list',
                    label: '',
                    options: [],
                    correctOption: '',
                };
                break;
            case 'signature':
                newElement = {
                    ...newElement,
                    type: 'signature',
                    label: '',
                };
                break;
            case 'video':
                newElement = {
                    ...newElement,
                    type: 'video',
                    label: '',
                    fileUrl: '',
                    fileName: '',
                };
                break;
            case 'attach':
                newElement = {
                    ...newElement,
                    type: 'attach',
                    label: '',
                    fileUrl: '',
                };
                break;
            case 'empty':
                newElement = {
                    ...newElement,
                    type: 'empty',
                };
                break;
            default:
                newElement = {
                    ...newElement,
                    type: type,
                    content: '',
                };
                break;
        }

        const newSteps = sections.map((step, index) => {
            if (index === currentStep) {
                return { ...step, elements: [...step.elements, newElement] };
            }
            return step;
        });

        setSections(newSteps);
        setNextId(nextId + 1);
    };

    const handleLivePreview = () => {
        setShowPreview(true);
    };

    return (
        <PageCard className="menu-elements-container">
            <div className="container py-3">
                <div className="row">
                    <div className="col-12 mb-3">
                        <div className="row">
                            <div className="col-2">
                                <button className="btn colorTealDark" onClick={handleLivePreview} style={{ width: '100%' }}>
                                    Live Preview
                                </button>
                            </div>
                            <div className="col-10">
                                <button className="btn btn-light btn-block colorTealDark" onClick={addStep}>
                                    <i className="bi bi-plus-lg"></i> Create Section
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="row">
                            <div className="col">
                                <button onClick={() => addElement('title')} className="btn btn-light btn-block">Add Título</button>
                            </div>
                            <div className="col">
                                <button onClick={() => addElement('subtitle')} className="btn btn-light btn-block">Add Subtítulo</button>
                            </div>
                            <div className="col">
                                <button onClick={() => addElement('description')} className="btn btn-light btn-block">Add Descrição</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            {
                                sections[currentStep]?.type === 'form' ?
                                    <div className="col">
                                        <button onClick={() => addElement('text')} className="btn btn-light btn-block">
                                            <i className="bi bi-fonts"></i> Text
                                        </button>
                                    </div>
                                    : null
                            }
                            <div className="col">
                                <button onClick={() => addElement('list')} className="btn btn-light btn-block">
                                    <i className="bi bi-list"></i> List
                                </button>
                            </div>
                            {
                                sections[currentStep]?.type === 'form' ?
                                    (
                                        <div className="col">
                                            <button onClick={() => addElement('date')} className="btn btn-light btn-block">
                                                <i className="bi bi-calendar"></i> Data
                                            </button>
                                        </div>
                                    )
                                    : null
                            }
                            {
                                sections[currentStep]?.type === 'form' ?
                                    (
                                        <div className="col">
                                            <button onClick={() => addElement('calendar')} className="btn btn-light btn-block">
                                                <i className="bi bi-calendar2-week"></i> Calendar
                                            </button>
                                        </div>
                                    )
                                    : null
                            }
                            <div className="col">
                                <button onClick={() => addElement('checkbox')} className="btn btn-light btn-block">
                                    <i className="bi bi-check-square"></i> Checkbox
                                </button>
                            </div>
                            {
                                sections[currentStep]?.type === 'form' ?
                                    (
                                        <div className="col">
                                            <button onClick={() => addElement('attach')} className="btn btn-light btn-block">
                                                <i className="bi bi-paperclip"></i> Anexar
                                            </button>
                                        </div>
                                    )
                                    : null
                            }
                            {
                                sections[currentStep]?.type === 'form' ?
                                    (
                                        <div className="col">
                                            <button onClick={() => addElement('video')} className="btn btn-light btn-block">
                                                <i className="bi bi-camera-video"></i> Video
                                            </button>
                                        </div>
                                    )
                                    : null
                            }
                            {
                                sections[currentStep]?.type === 'form' ?
                                    <div className="col">
                                        <button onClick={() => addElement('signature')} className="btn btn-light btn-block">
                                            <i className="bi bi-pencil"></i> Signature
                                        </button>
                                    </div>
                                    : null
                            }
                            <div className="col">
                                <button onClick={() => addElement('empty')} className="btn btn-light btn-block">
                                    <i className="bi bi-file"></i> Empty Box
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageCard>
    );
}
