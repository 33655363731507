import React, { useContext, useState } from 'react'
import DashboardButton from 'components/Dashboard/Button'
import { CoreContext } from 'context/CoreContext'
import { GetDocuments, GetUsersFromCompany } from 'services/document'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Grid from './Grid/grid'

export default function DashboardTabSupplierInfo() {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)
    const { setModal, user, findlanguage, language, permissions } = useContext(CoreContext)
    const [overview, setOverview] = useState([])
    const [overviewSecondaryGrid, setOverviewSecondaryGrid] = useState([])
    const [companyUsers, setCompanyUsers] = useState([])
    const [docNames, setDocNames] = useState([])

    const addDocument = (typeOfAction, document) => {
        setModal({
            type: 'add-documentSMS',
            title: findlanguage().supplierInformation,
            refresh: searchOverview,
            companyUsers: companyUsers,
            typeOfAction: typeOfAction,
            document: document,
            docNames: docNames
        })
    }

    const searchOverview = async () => {
        let query = []
        const getallempty = [
            { name: 'status', value: '' },
            { name: 'type', value: '' },
            { name: 'name', value: '' },
            { name: 'code', value: '' },
            { name: 'revision', value: '' },
            { name: 'emission_revision_date', value: '' },
            { name: 'issue_date', value: '' },
            { name: 'responsible_review', value: '' },
            { name: 'responsible_approval', value: '' },
        ]

        getallempty.forEach((x) => {
            if (x.value !== '') query.push(`${x.name}=${x.value}`)
        })

        setOverview([])
        setOverviewSecondaryGrid([])
        const result = await GetDocuments([])

        const hardcodedValues = [
            { value: 'Agreement', label: findlanguage().agreement },
            { value: 'Form', label: findlanguage().form },
            { value: 'Procedure', label: findlanguage().procedure },
            { value: 'Work Instruction', label: findlanguage().workInstruction }
        ];

        const uniqueNames = result.reduce((acc: { value: string; label: string }[], currentItem) => {
            const { type } = currentItem;
            if (type && !acc.some(item => item.value === type) && type !== 'null') {
                acc.push({ value: type, label: type });
            }
            return acc;
        }, [...hardcodedValues]);
        
        setDocNames(uniqueNames.sort(
            (a, b) => (a.label > b.label) - (a.label < b.label)
        ))

        const usersFromCompany = await GetUsersFromCompany(language)

        let reformulateUsers = []

        usersFromCompany
            .sort((a, b) => (a.name > b.name) - (a.name < b.name))
            .forEach((x) => {
                reformulateUsers.push({
                    id: x.id,
                    value: x.contactName,
                    label: x.contactName,
                    email: x.email,
                    title: x.contactName,
                })
            })

        setCompanyUsers(reformulateUsers)
        setOverview(result.sort((a, b) => a?.type?.localeCompare(b?.type)))
        setOverviewSecondaryGrid(
            result?.filter((x) => {
                if (
                    (x?.status === 'Pending' &&
                        (x?.responsible_approval?.id === user.id ||
                            x?.responsible_review?.id === user.id)) ||
                    (x?.status === 'Returned' &&
                        (x?.responsible_review?.id === user.id ||
                            x?.responsible_approval?.id === user.id))
                )
                    return x
            }).sort((a, b) => a?.type?.localeCompare(b?.type))
        )
    }

    useEffect(() => {
        searchOverview()
    }, [])

    useEffect(() => {
        if (!permissions?.myDocuments?.canView && !permissions?.myDocuments?.canEdit) {
            navigate('dashboard')
        }
    }, [permissions, navigate]);

    return (
        <>
            {
                permissions?.myDocuments?.canEdit ?
                    <div style={{ width: '23%', marginTop: '12px', marginBottom: '-37px' }}>
                        <DashboardButton icon={'circle-add'} onClick={() => addDocument('new')}>
                            {findlanguage().addNewDocuments}
                        </DashboardButton>
                    </div>
                    :
                    null
            }
            {
                overview?.filter((x) => {
                    if (
                        (x?.status === 'Pending' &&
                            (x?.responsible_approval?.id === user.id ||
                                x?.responsible_review?.id === user.id)) ||
                        (x?.status === 'Returned' &&
                            (x?.responsible_review?.id === user.id ||
                                x?.responsible_approval?.id === user.id))
                    )
                        return x
                }).length !== 0 ?
                    <Grid addDocument={addDocument} companyUsers={companyUsers} overview={overviewSecondaryGrid} isOwnerGrid={true} />
                    :
                    null
            }
            <Grid addDocument={addDocument} companyUsers={companyUsers} overview={overview} isOwnerGrid={false} />
        </>
    )
}
