import React, { useContext, useEffect, useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { CoreContext } from 'context/CoreContext'
import { toast } from 'react-toastify'
import { NotificationManagerAdd } from 'services/admin'
import { PageHeader, PageHeaderTitle, PageCard, Terms, ProfileFormActions } from './styled'

import ContainerAuthenticated from 'containers/Authenticated'
import DashboardButton from 'components/Dashboard/Button'
import Check from 'components/Form/Check'
import Textarea from 'components/Form/Textarea'

import { CreateSupportCases } from 'services/dashboard'

export default function DashboardAskSupport() {
    const { user, findlanguage, language } = useContext(CoreContext)

    const [message, setMessage] = useState('')
    const [agree, setAgree] = useState(false)
    const [fieldValidation, setFieldValidation] = useState([])
    const [loading, setLoading] = useState(false)

    const showHighlight = (name) => {
        const field = fieldValidation.find((item) => item.field === name)
        if (field !== undefined) return true
        return false
    }

    const valid = () => {
        setFieldValidation([])
        let errors = []

        if (message.length === 0) {
            errors.push({
                field: 'message',
                message: findlanguage().fillTheField + findlanguage().whatYouNeed,
            })
        }

        if (!agree) {
            errors.push({
                field: 'aggree',
                message: findlanguage().fillTheField + findlanguage().iAgree,
            })
        }

        if (errors.length > 0) {
            let message = ''
            message = findlanguage().someErrorsFound
            errors.forEach((item) => {
                message += '\n - ' + item.message
            })
            toast.error(message)
            setFieldValidation(errors)
            return false
        }

        return true
    }

    const sendCase = async () => {
        if (!valid()) {
            return
        }

        let form = {
            message: message,
            acceptance: agree,
            status: 'open',
            users_permissions_user: user,
        }

        setLoading(true)

        const request = await CreateSupportCases(form)

        if (!request) {
            toast.error('Error... try again in a few minutes')
            setLoading(false)
            return
        } else if (request.statusCode && request.statusCode !== 200) {
            toast.error(`Error, ${request.message}`)
            setLoading(false)
            return
        }
        // console.log(request)
        if (!request.error) {
            await NotificationManagerAdd('support_ask', 1, user.name, user)
        }

        toast.success(findlanguage().caseSentSuccess)
        setLoading(false)

        setMessage('')
        setAgree(false)
    }

    return (
        <>
            <ContainerAuthenticated>
                <Container fluid={false}>
                    <Row>
                        <Col>
                            <PageHeader>
                                <PageHeaderTitle>{findlanguage().askSupport}</PageHeaderTitle>
                            </PageHeader>

                            <PageCard>
                                <Terms>
                                    {language === 'portugues' ? (
                                        <>
                                            Eu permito que o suporte da My Trusted Source tenha acesso
                                            aos meus dados.
                                        </>
                                    ) : (
                                        <>
                                                I'm giving access to my personal data to My Trusted Source
                                            Support.
                                        </>
                                    )}
                                </Terms>

                                <Textarea
                                    label={findlanguage().whatYouNeed}
                                    maxLength={300}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    medium
                                    highlight={showHighlight('message')}
                                />

                                <div>
                                    <Check
                                        label={findlanguage().iAgree}
                                        checked={agree}
                                        onChange={(e) => setAgree(!agree)}
                                        highlight={showHighlight('agree')}
                                    />
                                </div>

                                <ProfileFormActions>
                                    <DashboardButton onClick={sendCase} loading={loading}>
                                        {findlanguage().sendCase}
                                    </DashboardButton>
                                </ProfileFormActions>
                            </PageCard>
                        </Col>
                    </Row>
                </Container>
            </ContainerAuthenticated>
        </>
    )
}
