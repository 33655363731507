import { GET, POST } from './api'

export const PrivacySuppliers = async (query) => {
    query = query?.map((x) => x.replace(/&/g, '12345678909897'))
    const suffix = query?.length ? `?${query.join('&')}` : ``

    return await GET(`/privacy/suppliers${suffix}`, true)
}

export const Information = async (body) => {
    return await POST(`/privacy/information`, body, true)
}

export const FreeUsers = async (query) => {
    return await GET(`/free-users?_limit=-1`, true)
}
