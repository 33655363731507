import React, { useState, useContext } from 'react'
import { CoreContext } from 'context/CoreContext'

import {
    PageCard,
    ProcessesContainer,
    Division,
    PageTitle,
    CardButton,
    PlusGreenIcon,
    TextGreenIcon,
    CheckboxGreenIcon,
    DropdownGreenIcon,
    DateGreenIcon,
    AttachGreenIcon,
} from './styled'

export default function DashboardTabOwnValidationButtons({ addToForm }) {
    const { findlanguage } = useContext(CoreContext)
    const add = (type) => {
        addToForm({
            type,
            title: '',
            variation: null,
            options: [
                findlanguage().option + ' 1',
                findlanguage().option + ' 2',
                findlanguage().option + ' 3',
            ],
        })
    }

    return (
        <PageCard>
            <Division>
                <PageTitle>Formating</PageTitle>
            </Division>

            <Division space={24}>
                <ProcessesContainer>
                    <CardButton onClick={() => add('title')}>
                        <PlusGreenIcon />
                        {findlanguage().addTitle}
                    </CardButton>

                    <CardButton onClick={() => add('subtitle')}>
                        <PlusGreenIcon />
                        {findlanguage().addSubTitle}
                    </CardButton>

                    <CardButton onClick={() => add('description')}>
                        <PlusGreenIcon />
                        {findlanguage().description}
                    </CardButton>
                </ProcessesContainer>
            </Division>

            <Division>
                <PageTitle>Select the Fields</PageTitle>
            </Division>

            <Division space={24}>
                <ProcessesContainer>
                    <CardButton onClick={() => add('text')}>
                        <TextGreenIcon />
                        {findlanguage().text}
                    </CardButton>

                    <CardButton onClick={() => add('dropdown')}>
                        <DropdownGreenIcon />
                        {findlanguage().dropdown}
                    </CardButton>

                    <CardButton onClick={() => add('checkbox')}>
                        <CheckboxGreenIcon />
                        {findlanguage().checkbox}
                    </CardButton>

                    <CardButton onClick={() => add('date')}>
                        <DateGreenIcon />
                        {findlanguage().date}
                    </CardButton>

                    <CardButton onClick={() => add('media')}>
                        <AttachGreenIcon />
                        {findlanguage().attachDocument}
                    </CardButton>
                </ProcessesContainer>
            </Division>
        </PageCard>
    )
}
