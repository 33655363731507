import React, { useState, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { parseStrapiImage } from 'utils';

export default function SectionsToUploadTheUser({ sections, currentStep }) {
    const [cardWidth, setCardWidth] = useState(300); 

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            const numberOfCards = Math.floor(screenWidth / 300);
            setCardWidth(screenWidth / numberOfCards - 10);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getStatusBadgeClass = (status) => {
        switch (status) {
            case 'waiting':
                return 'badge bg-warning text-dark';
            case 'rejected':
                return 'badge bg-danger';
            case '[ending approval':
                return 'badge bg-danger';
            case 'complete':
                return 'badge bg-success';
            default:
                return 'badge bg-secondary';
        }
    };

    const renderQuizTotal = (score, totalToPass) => {
        const percentage = score || 0;
        let circleColor = 'red';

        if (percentage > totalToPass) {
            circleColor = 'green';
        } else if (percentage === totalToPass) {
            circleColor = 'yellow';
        }

        return (
            <div className="progress-circle">
                <svg viewBox="0 0 36 36" className={`circular-chart ${circleColor}`}>
                    <path
                        className="circle-bg"
                        d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                        className="circle"
                        strokeDasharray={`${percentage}, 100`}
                        d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <text x="18" y="20.35" className="percentage">{`${percentage}%`}</text>
                </svg>
            </div>
        );
    };

    return (
        <div className="steps-scroll-container-no-overflow step-owner">
            <div className="steps-container" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {sections.map((x, index) => (
                    <React.Fragment key={x.id}>
                        <div className={`step`} style={{ width: `${cardWidth}px`, boxSizing: 'border-box' }}>
                            <div className="step-header">
                                <div className="step-icon">
                                    <i className={`bi bi-${x.icon}`}></i>
                                </div>
                                <div className="step-title">
                                    {x.name}
                                    <span
                                        className={getStatusBadgeClass(x?.assigned_users?.status)}
                                        style={{ marginLeft: '10px' }}
                                    >
                                        {x?.assigned_users?.status?.charAt(0)?.toUpperCase() + x?.assigned_users?.status?.slice(1)}
                                    </span>
                                    {x.id === currentStep && (
                                        <span className="badge bg-primary" style={{ marginLeft: '10px' }}>
                                            Current Section
                                        </span>
                                    )}
                                </div>
                            </div>
                            {x.assigned_users.status === 'rejected' && <div
                                className="alert alert-danger"
                                role="alert"
                                style={{ width: '100%', borderRadius: '0px', marginBottom: '0px' }}>
                                Rejected: {x.assigned_users.reason_rejected}
                            </div>}
                            <div className="user-list-container">
                                <ul className="user-list">
                                    <li className="user-list-item">
                                        <div className="user-info">
                                            <div>
                                                <img
                                                    width="52"
                                                    className="rounded-circle user-avatar"
                                                    src={x?.assigned_users?.user?.url ? parseStrapiImage(x?.assigned_users?.user?.url) : '/images/no-image.png'}
                                                    alt={x?.assigned_users?.user?.name}
                                                />
                                            </div>
                                            <div className="user-details">
                                                <div className="user-name">{x?.assigned_users?.user?.name}</div>
                                                {x.type === 'quiz' && renderQuizTotal(x.assigned_users.score, x.questions_to_pass)}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            {['waiting', 'complete', 'rejected'].includes(x.status) && (
                                <button
                                    className="btn colorTealDark"
                                    style={{ width: '100%', marginTop: '10px' }}
                                >
                                    View Section
                                </button>
                            )}
                        </div>
                        {index < sections.length - 1 && <div className="step-line"></div>}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}
