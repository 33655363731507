import styled from 'styled-components'

export const CheckContent = styled.div.attrs({})`
    display: flex;
    flex-direction: row;
    cursor: pointer;

    ${(props) => (props.nowidth ? `` : `width: 300px;`)}

    ${(props) =>
        props.spaced
            ? `
            margin-bottom: 12px;
        `
            : ``}
`
export const CheckBox = styled.div.attrs({})`
    width: 21px;
    height: 21px;
    min-width: 21px;
    min-height: 21px;
    border-radius: 4px;
    border: 1px solid rgba(112, 112, 112, 0.45);
    margin-top: 1px;
    margin-right: 12px;

    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) =>
        props.active
            ? `
            border: 1px solid var(--primary-color);
            background: var(--primary-color);
        `
            : ``}

    ${(props) =>
        props.locked
            ? `
            background: var(--chatgrey-color);
        `
            : ``}
`
export const CheckBoxIn = styled.img.attrs({
    src: '/icons/check.svg',
})`
    width: 12px;

    animation-name: pulseIn;
    animation-duration: 0.3s;
    animation-iteration-count: 1;

    @keyframes pulseIn {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.8);
        }
        100% {
            transform: scale(1);
        }
    }
`
export const CheckBoxText = styled.div.attrs({})`
    color: var(--grey-color);
    font-weight: 600;
    font-size: 14px;
    ${(props) =>
        props.color
            ? `
            color: var(--${props.color}-color);
        `
            : ``}
`
export const CheckBoxContent = styled.div.attrs({})``
export const CheckBoxTitle = styled.div.attrs({})`
    color: var(--grey-color);
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
`
