import styled from 'styled-components'

export const FormTitle = styled.div.attrs({})`
    color: var(--primary-color);
    font-weight: bold;
    font-size: 20px;

    margin: 50px 0 10px;
`
export const FormText = styled.div.attrs({})`
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--grey-color);

    margin: 20px 0;
    text-align: center;
`

export const FormSpacer = styled.div.attrs({})`
    margin-top: 40px;
`

export const FormCentred = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: center;
`
