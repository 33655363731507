import React, { useContext, useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'

import { PageBody, BodySubtitle, BodyText, LineSeparator } from './styled'

import ModalWrapper from '../Wrapper'

import DashboardScrapingDetailsHeader from 'components/Dashboard/Scraping/Header'
import DashboardScrapingDetailsProducts from 'components/Dashboard/Scraping/Products'
import { SearchGetProducts } from 'services/validationProcess'

import { CoreContext } from 'context/CoreContext'

export default function ModalScrapingDetail() {
    const { setModal, modal, findlanguage } = useContext(CoreContext)
    const [scraping, setScraping] = useState({})
    const [certificationsInfo, setCertificationsInfo] = useState([])
    const [products, setProducts] = useState([])

    const init = async () => {
        const result = await SearchGetProducts(modal.scraping.id)

        // console.log(result, 'result')

        setProducts(result.length === 1 && result[0].name === null ? [] : result)

        const url = new URL(window.location)

        if (modal?.scraping?.id) {
            url.searchParams.set('show_detail', modal?.scraping?.id)
            setScraping(modal.scraping)
        } else {
            url.searchParams.delete('show_detail')
            setModal(null)
        }

        window.history.pushState({}, '', url)
    }

    const handleClose = () => {
        const url = new URL(window.location)
        url.searchParams.delete('show_detail')
        window.history.pushState({}, '', url)
    }

    useEffect(() => {
        init()
    }, [modal.scraping])

    useEffect(() => {
        setCertificationsInfo([
            {
                label: 'Type',
                value: scraping?.type,
            },
            {
                label: 'Auditor',
                value: scraping?.certification_auditor,
            },
            {
                label: 'Score',
                value: scraping?.certification_score,
            },
            {
                label: 'Audit Date',
                value: scraping?.certification_audit_date,
            },
            {
                label: 'Reaudit Date',
                value: scraping?.certification_reaudit_date,
            },
            {
                label: 'Expiration',
                value: scraping?.certification_expiration,
            },
            {
                label: 'Body',
                value: scraping?.certification_body,
            },
        ])
    }, [scraping])

    return (
        <>
            <ModalWrapper onCloseModal={handleClose} big>
                <div className="alert alert-warning" role="alert">
                    {findlanguage().contactMfsForMoreInformation}
                </div>
                <DashboardScrapingDetailsHeader profile={scraping} />
                <PageBody>
                    <Row>
                        <Col xs={12} md={6}>
                            <BodySubtitle>{findlanguage().businessType}</BodySubtitle>
                            <div>{scraping.businessType}</div>
                        </Col>
                        <Col xs={12} md={6}>
                            <BodySubtitle>{findlanguage().approvals}</BodySubtitle>
                            <div>{scraping.scraping}</div>
                        </Col>
                        <Col xs={12} md={6}>
                            <BodySubtitle>{findlanguage().nameOnly}</BodySubtitle>
                            <div>{scraping.name}</div>
                        </Col>
                        <Col xs={12} md={6}>
                            <BodySubtitle>{findlanguage().email}</BodySubtitle>
                            <div>{scraping.email === null ? scraping.email2 : scraping.email}</div>
                        </Col>
                        <Col xs={12} md={6}>
                            <BodySubtitle>{findlanguage().phone}</BodySubtitle>
                            <div>{scraping.phone}</div>
                        </Col>
                    </Row>

                    <DashboardScrapingDetailsProducts products={products} />
                </PageBody>
            </ModalWrapper>
        </>
    )
}
