import React, { useContext, useEffect, useState } from 'react'

import { ProfileFormContent, ProfileFormActions, PageFormSpace } from './styled'

import Button from 'components/Form/Button'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

import Select from 'components/Form/Select'
import { ReadAvailableFilters } from 'services/filters'
import { ReadProvidedServices } from 'services/infos'

export default function ModalFilterServiceProvider() {
    const { modal, setModal, setFilterService } = useContext(CoreContext)

    const [form, setForm] = useState({})
    const formValue = (ref) => {
        return form?.[ref] ? form?.[ref] : ''
    }
    const changeForm = (value, ref) => {
        setForm({ ...form, [ref]: value })
    }

    const close = () => {
        setModal(null)
    }

    const [infos, setInfos] = useState({})
    const [loading, setLoading] = useState(false)

    const init = async () => {
        setLoading(true)

        const services = await ReadProvidedServices()
        const availableFilters = await ReadAvailableFilters()

        setInfos({
            services: services.map((item) => ({ ...item, title: item.label })),
            availableFilters,
        })

        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])

    const apply = () => {
        setFilterService(form)
        close()
    }

    const clean = () => {
        setFilterService({})
        close()
    }

    return (
        <>
            <ModalWrapper left>
                <ProfileFormContent>
                    <Select
                        placeholder={'Select service'}
                        value={formValue('service')}
                        onChange={(e) => changeForm(e, 'service')}
                        options={infos?.services}
                        outline
                    />
                </ProfileFormContent>
                <PageFormSpace />
                <ProfileFormActions>
                    <Button primary small onClick={apply}>
                        Apply Filters
                    </Button>
                    <Button outline small primary onClick={clean}>
                        Clean Filters
                    </Button>
                </ProfileFormActions>
            </ModalWrapper>
        </>
    )
}
