import DashboardButton from 'components/Dashboard/Button'
import Input from 'components/Form/Input'
import React, { useState, useContext } from 'react'
import { CoreContext } from 'context/CoreContext'

import { CardTitle } from 'reactstrap'
import DashboardTabOwnValidationButtons from '../OwnValidationButtons'
import DashboardTabOwnValidationConstruct from '../OwnValidationConstruct'
import Select from 'react-select'
import { FetchSuplierRequestsFind } from 'services/sms'
import { NotificationManagerAdd } from 'services/admin'
import DashboardFilterSearch from 'components/Dashboard/Filter/Search'

import {
    CreateFormRequestForSupplier,
    FindValidationProcess,
    GetForm,
} from 'services/validationProcess'

import {
    CardSearched,
    PageCard,
    CardText,
    TrashIcon,
    IconButton,
    EditIcon,
    ButtonContainer,
    ProcessesContainer,
    PlusIcon,
    BackIcon,
    ContainerDivider,
    Division,
    Division2,
    PageTitle,
    PageDescription,
    ViewIcon,
    CardHeader,
} from './styled'
import { useEffect } from 'react'
import {
    FetchMyDraftForms,
    FetchMyForms,
    RemoveValidationForm,
    SaveValidationForms,
    UpdateValidationForms,
} from 'services/sms'

import { UploadPDFForm } from 'services/api'
import Button from 'components/Form/Button'

export default function DashboardTabOwnValidation() {
    const [createNew, setCreateNew] = useState(false)
    const [editing, setEditing] = useState(false)
    const [validationForm, setValidationForm] = useState([])
    const [formName, setFormName] = useState('')
    const [hasUpdates, setHasUpdates] = useState(false)
    const [readOnly, setReadOnly] = useState(false)
    const [overview, setOverview] = useState([])
    const [myFormList, setMyFormList] = useState([])
    const [myDraftFormList, setDraftMyFormList] = useState([])
    const { setModal, user, findlanguage, permissions } = useContext(CoreContext)
    const [requestUpdate, setRequestUpdate] = useState(false)
    const [choosedForms, setChoosedForms] = useState([])
    const [choosedUsers, setChoosedUsers] = useState([])
    const [usersWithForms, setUsersWithForms] = useState([])
    const [ids, setIds] = useState([])
    const [saveId, setSaveId] = useState(0)
    const [searchValue, setSearchValue] = useState('')
    const [visibleSelection, setVisibleSelection] = useState([])
    const [isPrivate, setIsPrivate] = useState(false);

    useEffect(() => {
        updateMyFormList()
        updateMyDraftFormList()
        autoSaveRoutine()
        searchOverview()
    }, [])

    useEffect(() => {
        if (!editing && !createNew) return
        if (!hasUpdates) return
        saveValidationForm(true, true)
        setHasUpdates(false)
    }, [requestUpdate])

    const waitFor = async (seconds) =>
        new Promise((resolve) => setTimeout(() => resolve(true), seconds * 1000))

    const alertSave = () => {
        setModal({
            type: 'alert',
            text: findlanguage().formSaved,
            description: findlanguage().formSavedDescriptionMyQMS,
        })
    }

    const setLoading = (message = findlanguage().processing) => {
        setModal({
            type: 'loading',
            text: message,
        })
    }

    const alertMissingTitle = () => {
        setModal({
            type: 'alert',
            warning: true,
            text: 'Missing Title.',
            description: 'Your validation form is missing a title an has NOT been saved.',
        })
    }

    const alertError = () => {
        setModal({
            type: 'alert',
            warning: true,
            text: 'Error.',
            description:
                'Could not perform requested action. Please check you connection and try again.',
        })
    }

    const searchOverview = async () => {
        setOverview([])
        const result = await FetchSuplierRequestsFind()
        const result2 = await FindValidationProcess()
        setUsersWithForms(result2)
        setOverview(result)
    }

    const addToForm = (item) => {
        const update = [...validationForm]
        update.push(item)
        setValidationForm(update)
        setHasUpdates(true)
    }

    const editFormItem = (index, value) => {
        const update = validationForm.map((item, i) => {
            if (i === index) return { ...item, ...value }
            else return item
        })
        setHasUpdates(true)

        setValidationForm(update)
    }

    const removeFromForm = (index) => {
        const update = validationForm.filter((item, i) => index !== i)
        setHasUpdates(true)
        setValidationForm(update)
    }

    const updateMyFormList = async () => {
        const request = await FetchMyForms(user.company_id)
        setMyFormList(request)
    }

    const updateMyDraftFormList = async () => {
        const request = await FetchMyDraftForms(user.company_id)
        setDraftMyFormList(request)
    }

    const saveValidationForm = async (draft = false, silent = false) => {
        if (!formName) {
            if (!silent) alertMissingTitle()
            return
        }
        if (!silent) setLoading()
        const body = {
            draft,
            owner: user.company_id,
            form_body: validationForm,
            name: formName,
        }
        let response

        if (editing) response = await UpdateValidationForms(body, editing.id)
        else response = await SaveValidationForms(body)

        for (let i = 0; i < response?.form_body?.length; i++) {
            if (response.form_body[i].type === 'media') {
                const ref = `formFileMultiple${i}`

                if (document.getElementById(ref)?.files?.[0]) {
                    const result = await UploadPDFForm(
                        document.getElementById(ref)?.files?.[0],
                        response.form_body[i].id
                    )

                    response.form_body[i].filePdf = result[0].media
                }
            }
        }

        await UpdateValidationForms(response, response.id)

        if (response.statusText || response.statusCode) {
            if (!silent) alertError()
            return
        }

        if (!editing) setEditing(response)

        if (!silent) alertSave()
        if (!draft) goBack()

        updateMyDraftFormList()
        updateMyFormList()
    }

    const createNewForm = () => {
        setEditing(false)
        setFormName('')
        setValidationForm([])
        setCreateNew(true)
        setReadOnly(false)
    }

    const clone = () => {
        setEditing(false)
        setFormName(editing.name + ' {New Version}')
        setValidationForm(editing.form_body)
        setCreateNew(true)
        setReadOnly(false)
    }

    const editValidationForm = (form) => {
        setEditing(form)
        setFormName(form.name)
        setValidationForm(form.form_body)
        setCreateNew(true)
        setReadOnly(false)
    }

    const readValidationForm = (form) => {
        setEditing(form)
        setFormName(form.name)
        setValidationForm(form.form_body)
        setCreateNew(true)
        setReadOnly(true)
    }

    const deleteValidationForm = async (form) => {
        setLoading('Removing')
        const response = await RemoveValidationForm(form.id)
        if (response.statusText || response.statusCode) return alertError()
        setModal(false)
        if (form.draft) updateMyDraftFormList()
        else updateMyFormList()
    }

    const goBack = () => {
        setEditing(false)
        setCreateNew(false)
    }

    const autoSaveRoutine = async () => {
        while (true) {
            await waitFor(15)
            setRequestUpdate(new Date())
        }
    }

    const options = myFormList.map((x) => {
        return {
            value: x.id,
            label: x.name,
        }
    })

    const userOptions = [
        {
            value: 'all',
            label: findlanguage().selectAll,
        },
        ...overview
            .map((x) => ({
                value: x.userId,
                label: x.name,
            }))
            .sort((a, b) => (a.label > b.label) - (a.label < b.label)),
    ]

    const handleChangeForms = (values) => {
        let id = []
        setChoosedForms(values)

        values.forEach((y) => {
            usersWithForms.forEach((x) => {
                if (x.form.id === y.value) {
                    if (!id.includes(x.toUser.id)) id.push(x.toUser.id)
                }
            })
        })

        setIds(id)
    }

    const handleChangeUsers = (selectedOption) => {
        if (!selectedOption) {
            setChoosedUsers([])
            setVisibleSelection([])
            return
        }

        const selectAllSelected = selectedOption.some((option) => option.value === 'all')

        if (
            selectedOption.length > 0 &&
            selectedOption[selectedOption.length - 1].value === 'all'
        ) {
            setChoosedUsers(userOptions.filter((option) => option.value !== 'all'))
            setVisibleSelection([{ value: 'all', label: findlanguage().selectAll }])
        } else if (
            choosedUsers.length === userOptions.length - 1 &&
            choosedUsers[0].value !== 'all'
        ) {
            setChoosedUsers([selectedOption[selectedOption.length - 1]])
            setVisibleSelection([selectedOption[selectedOption.length - 1]])
        } else if (selectAllSelected) {
            const newOptions = selectedOption.filter((option) => option.value !== 'all')
            setChoosedUsers(newOptions)
            setVisibleSelection(newOptions)
        } else {
            setChoosedUsers(selectedOption)
            setVisibleSelection(selectedOption)
        }
    }

    const sendRequirements = async () => {
        await choosedForms.forEach(async (x) => {
            choosedUsers.forEach(async (y) => {
                var form_body = await GetForm(x.value)

                await CreateFormRequestForSupplier({
                    form: x.value,
                    form_body: form_body,
                    toUser: { id: y.value },
                    owner: { id: user.company_id },
                    status: 'Condition',
                    private: isPrivate
                })


                if (isPrivate) {
                    await NotificationManagerAdd('document_request', user.id, user.name, user)
                }
                else {
                    await NotificationManagerAdd('document_request', y.value, user.name, user)
                }
            })
        })

        setModal({
            type: 'alert',
            text: findlanguage().requirementSent,
        })

        setSaveId(saveId + 1)
        await searchOverview()
    }

    return (
        <>
            {!createNew && !editing && (
                <>

                    {
                        permissions?.mySuppliers?.canEdit ?
                            <PageCard>
                                <div
                                    style={{
                                        borderBottom: '1px solid #7070700f',
                                        padding: '20px 0',
                                    }}
                                >
                                    <label
                                        style={{
                                            fontSize: '18px',
                                            fontFamily: 'Open Sans',
                                            fontWeight: '700',
                                        }}
                                    >
                                        {findlanguage().myRequirements}
                                    </label>
                                    <p>{findlanguage().myRequirementsText}</p>
                                </div>
                                <br></br>
                                <ProcessesContainer>
                                    <div className="container text-center">
                                        <div className="row">
                                            <div className="col-2">
                                                <label
                                                    className="form-label"
                                                    style={{
                                                        fontSize: '15px',
                                                        fontWeight: '700',
                                                        color: 'rgb(100, 100, 100)',
                                                        marginTop: '5px',
                                                    }}
                                                >
                                                    {findlanguage().sendTheRequirements}
                                                </label>
                                            </div>
                                            <div className="col">
                                                <Select
                                                    key={saveId}
                                                    onChange={(values) => handleChangeForms(values)}
                                                    isMulti
                                                    name="colors"
                                                    options={options.sort(
                                                        (a, b) => (a.label > b.label) - (a.label < b.label)
                                                    )}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder={findlanguage().select}
                                                />
                                            </div>
                                            <div className="col-1">
                                                <label
                                                    className="form-label"
                                                    style={{
                                                        fontSize: '15px',
                                                        fontWeight: '700',
                                                        color: 'rgb(100, 100, 100)',
                                                        marginTop: '5px',
                                                    }}
                                                >
                                                    {findlanguage().to}
                                                </label>
                                            </div>
                                            <div className="col">
                                                <Select
                                                    key={saveId}
                                                    onChange={handleChangeUsers}
                                                    isMulti
                                                    name="colors"
                                                    options={userOptions}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder={findlanguage().select}
                                                    value={visibleSelection}
                                                />
                                            </div>
                                            <div className="col-2">
                                                <input type="checkbox" id="privateCheck" checked={isPrivate} onChange={() => setIsPrivate(!isPrivate)} />
                                                <label htmlFor="privateCheck" style={{ marginLeft: '10px' }}>{findlanguage().private}</label>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-3">
                                                <DashboardButton
                                                    onClick={sendRequirements}
                                                    disabled={
                                                        choosedForms.length === 0 ||
                                                            choosedUsers.length === 0
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    <div style={{ marginLeft: '40px' }}>
                                                        {findlanguage().sendTheRequirements}
                                                    </div>
                                                </DashboardButton>
                                            </div>
                                        </div>
                                    </div>
                                </ProcessesContainer>
                            </PageCard>
                            :
                            null
                    }
                    <PageCard>
                        <CardHeader>
                            {findlanguage().totalRequirements}: {myFormList.length}
                        </CardHeader>
                        {
                            permissions?.mySuppliers?.canEdit ?
                                <div style={{ display: 'inline-flex', width: '100%' }}>
                                    <DashboardFilterSearch
                                        placeholder={findlanguage().totalRequirements}
                                        style={{ width: '84%' }}
                                        onChangeList={(e) => setSearchValue(e.target.value)}
                                    />
                                    <ButtonContainer style={{ marginLeft: '15px' }}>
                                        <DashboardButton onClick={createNewForm}>
                                            <PlusIcon />
                                            {findlanguage().createNew}
                                        </DashboardButton>
                                    </ButtonContainer>
                                </div>
                                :
                                null
                        }
                        <ProcessesContainer>
                            {myDraftFormList.map((form, k) => (
                                <CardSearched key={k}>
                                    <CardText draft>
                                        ({findlanguage().draft}) {form.name}
                                    </CardText>
                                    {
                                        permissions?.mySuppliers?.canEdit ?
                                            <>
                                                <IconButton onClick={() => editValidationForm(form)}>
                                                    <EditIcon />
                                                </IconButton>

                                                <IconButton onClick={() => deleteValidationForm(form)}>
                                                    <TrashIcon />
                                                </IconButton>
                                            </>
                                            :
                                            null
                                    }
                                </CardSearched>
                            ))}
                            {myFormList
                                .filter((x) =>
                                    x.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
                                )
                                .map((form, k) => (
                                    <CardSearched key={k}>
                                        <CardText draft>{form.name}</CardText>
                                        <IconButton onClick={() => readValidationForm(form)}>
                                            <ViewIcon />
                                        </IconButton>
                                    </CardSearched>
                                ))}
                        </ProcessesContainer>
                    </PageCard>
                </>
            )}

            {createNew && (
                <>
                    <PageCard>
                        <ContainerDivider>
                            <BackIcon onClick={goBack} />
                            {readOnly ? (
                                <Division2 space={18}>
                                    <PageTitle>{formName}</PageTitle>
                                </Division2>
                            ) : (
                                <Division>
                                    <Division space={18}>
                                        <PageTitle>{findlanguage().newValidationProcess}</PageTitle>
                                    </Division>
                                    <Division space={24}>
                                        <PageDescription>
                                            {findlanguage().whatIsTheNameOfTheProcess}
                                        </PageDescription>
                                    </Division>

                                    <Input
                                        placeholder={findlanguage().name}
                                        big
                                        value={formName}
                                        onChange={(e) => {
                                            setFormName(e.target.value)
                                            setHasUpdates(true)
                                        }}
                                    />
                                </Division>
                            )}
                            {readOnly && (
                                <div>
                                    {
                                        permissions?.mySuppliers?.canEdit ?
                                            <DashboardButton onClick={clone}>
                                                <PlusIcon />
                                                {findlanguage().generateNewVersion}
                                            </DashboardButton>
                                            :
                                            null
                                    }
                                </div>
                            )}
                        </ContainerDivider>
                    </PageCard>

                    {!readOnly && <DashboardTabOwnValidationButtons addToForm={addToForm} />}

                    <DashboardTabOwnValidationConstruct
                        componentList={validationForm}
                        editFormItem={editFormItem}
                        removeFromForm={removeFromForm}
                        readOnly={readOnly}
                    />
                    {!readOnly && (
                        <ButtonContainer style={{ margin: '24px 0' }}>
                            <DashboardButton dark onClick={() => saveValidationForm(true)}>
                                {findlanguage().saveDraft}
                            </DashboardButton>
                            <DashboardButton onClick={() => saveValidationForm(false)}>
                                {findlanguage().publishForm}
                            </DashboardButton>
                        </ButtonContainer>
                    )}
                </>
            )}
        </>
    )
}
