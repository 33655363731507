import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const HeaderContainer = styled.div.attrs({})`
    position: relative;
    height: 114px;
    display: flex;
`

export const HeaderContainerDecoration = styled.div.attrs({})`
    background: white url(/images/background.png) no-repeat top + 55% center / cover;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    ${(props) =>
        props.menuMobile
            ? `
            @media(min-width:${mobileSize}px){
                display:none;
            }
        `
            : ``}
`
export const HeaderDecoration = styled.div.attrs({})`
    background: -moz-linear-gradient(80deg, #3b5667 10%, #4c6c80 90%);
    background: -webkit-linear-gradient(80deg, #3b5667 10%, #4c6c80 90%);
    background: linear-gradient(80deg, #3b5667 10%, #4c6c80 90%);
    height: 100%;
    opacity: 0.94;
    ${(props) =>
        props.menuMobile
            ? `
            background: -moz-linear-gradient(25deg, #3b5667 10%, #4C6C80 90%);
            background: -webkit-linear-gradient(25deg, #3b5667 10%, #4C6C80 90%);
            background: linear-gradient(25deg, #3b5667 10%, #4C6C80 90%);
        `
            : ``}
`

export const HeaderContentFull = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
`
