import styled from 'styled-components'

export const UserProfileImage = styled.div.attrs({})`
    width: 122px;
    height: 122px;
    border-radius: 61px;
    background: var(--lightgrey-color);
    background: url(${(props) => props.image}) no-repeat center center / cover #fff;
    background-size: 110px;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    margin-bottom: 20px;
`
export const UserProfileImageAction = styled.div.attrs({})`
    width: 44px;
    height: 44px;
    border-radius: 22px;
    background: var(--white-color);
    border: 1px solid (112, 112, 112, 0.15);
    box-shadow: 0px 1px 3px rgba(112, 112, 112, 0.15);

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    &:hover {
        box-shadow: 0px 1px 3px rgba(112, 112, 112, 0.5);
    }
`
export const UserProfileImageActionIcon = styled.img.attrs({
    src: '/icons/cam.svg',
})``

export const UserProfileImageActionIconWithMargin = styled.img.attrs({
    src: '/icons/cam.svg',
})`
    margin-left: 44px
`


export const UserImageChange = styled.input.attrs({
    id: 'change-image',
    type: 'file',
    accept: 'image/*',
})`
    display: none;
`
