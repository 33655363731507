import React, { useContext, useState, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { CoreContext } from 'context/CoreContext'
import { useHistory } from 'react-router-dom'

import {
    PageHeader,
    PageHeaderTitle,
    PageHeaderActions,
    PageTabSelector,
    PageTabItem,
    PageTabItemIcon,
    PageTabItemText,
    IconInfo,
} from './styled'

import ContainerAuthenticated from 'containers/Authenticated'

import MySuppliers from 'components/Dashboard/Sms/MySuppliers'
import ServiceProvider from 'components/Dashboard/Sms/ServiceProvider'
import MyDocuments from 'components/Dashboard/Sms/MyDocuments'
import MyBuyers from 'components/Dashboard/Sms/MyBuyers'
import MyValidationProcess from 'components/Dashboard/Sms/MyValidationProcess'
import MyServiceProviders from 'components/Dashboard/Sms/MyServiceProviders'
import MySuppliersServiceProviderView from 'components/Dashboard/Sms/MySuppliersServiceProviderView'
import MySteps from 'components/Dashboard/Sms/MySteps'

export default function DashboardSMS() {
    const {
        premium,
        getPermission,
        profile,
        setProfile,
        showAccessDenied,
        showContactSales,
        findlanguage,
        user,
        permissions,
    } = useContext(CoreContext)
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const [currentTab, setCurrentTab] = useState(0)
    // * check premium
    useEffect(() => {
        const searchParams = new URLSearchParams(history.location.search)
        const tab = parseInt(searchParams.get('tab'), 10)
        let hasPermission = false

        switch (tab) {
            case 0:
                hasPermission =
                    permissions.myProcessOverview?.canView || permissions.myProcessOverview?.canEdit
                break
            case 1:
                hasPermission = permissions.myDocuments?.canView || permissions.myDocuments?.canEdit
                break
            case 2:
                hasPermission = permissions.myBuyers?.canView || permissions.myBuyers?.canEdit
                break
            case 3:
                hasPermission = permissions.mySuppliers?.canView || permissions.mySuppliers?.canEdit
                break
            default:
                hasPermission = false
        }

        if (hasPermission) {
            setCurrentTab(tab)
        } else {
            // Redirect to the first tab they have permission for or handle accordingly
            if (permissions.myProcessOverview?.canView || permissions.myProcessOverview?.canEdit) {
                setCurrentTab(0)
            } else if (permissions.myDocuments?.canView || permissions.myDocuments?.canEdit) {
                setCurrentTab(1)
            } else if (permissions.myBuyers?.canView || permissions.myBuyers?.canEdit) {
                setCurrentTab(2)
            } else if (permissions.mySuppliers?.canView || permissions.mySuppliers?.canEdit) {
                setCurrentTab(3)
            } else {
                // If no permissions for any tab, handle accordingly, perhaps setting to an "unauthorized" state
            }
        }
    }, []) // Empty depen

    const profilesTabs = {
        buyer: [
            permissions?.myProcessOverview?.canView === true ||
            permissions?.myProcessOverview?.canEdit === true
                ? { title: findlanguage().validationProcessOverview }
                : {},
            permissions?.myDocuments?.canView === true || permissions?.myDocuments?.canEdit === true
                ? { title: findlanguage().myDocuments }
                : {},
            permissions?.myBuyers?.canView === true || permissions?.myBuyers?.canEdit === true
                ? { title: findlanguage().myBuyers }
                : {},
            permissions?.mySuppliers?.canView === true || permissions?.mySuppliers?.canEdit === true
                ? { title: findlanguage().mySuppliers }
                : {},
            // { title: 'My Steps' }
        ],
        'service-provider': [
            { title: findlanguage().validationProcessOverview },
            { title: findlanguage().myDocuments },
            { title: findlanguage().myBuyers },
            { title: findlanguage().myClients },
            // { title: 'My Steps' }
        ],
    }

    const tabs = profilesTabs[profile === 'service-provider' ? profile : 'buyer'] || []

    const setTab = (key) => {
        if (
            profile === 'buyer' ||
            profile === 'supplier' ||
            profile === 'admin' ||
            profile === 'buyer-supplier' ||
            profile === 'administrative'
        ) {
            if (premium.type === 'free') {
                switch (key) {
                    case 3: // my suppliers
                        showAccessDenied(premium.type)
                        return false
                    case 1: // my documents
                        showContactSales()
                        return false
                    default:
                        break
                }
            }
        } else if (profile === 'service-provider') {
            if (premium.type === 'free') {
                switch (key) {
                    case 1: // my documents
                        showContactSales()
                        return false
                    case 2: // my clients
                        break
                    case 3: // my buyers
                        showAccessDenied(premium.type)
                        return false
                    default:
                        break
                }
            }
        } else {
            if (premium.type === 'free') {
                switch (key) {
                    case 1: // my buyers
                        // open
                        break
                    case 2: // my documents
                        showContactSales()
                        return false
                    case 3:
                        showContactSales()
                        return false
                    default:
                        break
                }
            }
        }

        setCurrentTab(key)

        if (key === 2)
            history.push({
                pathname: '/dashboard/sms',
                search: `?tab=2&subTab=1`,
            })
        else if (key === 3)
            history.push({
                pathname: '/dashboard/sms',
                search: `?tab=3&subTab=1`,
            })
        else
            history.push({
                pathname: '/dashboard/sms',
                search: `?tab=${key}`,
            })
    }

    return (
        <>
            <ContainerAuthenticated>
                <Container fluid={false}>
                    <Row>
                        <Col>
                            <PageHeader>
                                <PageHeaderActions>
                                    <PageHeaderTitle>MyQMS</PageHeaderTitle>
                                </PageHeaderActions>
                            </PageHeader>

                            <PageTabSelector>
                                {tabs.map((item, key) => (
                                    <PageTabItem
                                        key={key}
                                        active={key === currentTab}
                                        onClick={() => setTab(key)}
                                        style={{ display: item.title === undefined ? 'none' : '' }}
                                    >
                                        {item.icon ? <PageTabItemIcon icon={item.icon} /> : null}
                                        <PageTabItemText active={key === currentTab}>
                                            {item.title}
                                            {item.title === 'My Documents' ? (
                                                <IconInfo
                                                    title="This section was created for users to control their own QMS 
                                                               documents, procedures, formularies, contracts, certificates, etc..."
                                                    style={{ width: '16px', marginLeft: '6px' }}
                                                />
                                            ) : null}
                                        </PageTabItemText>
                                    </PageTabItem>
                                ))}
                            </PageTabSelector>
                            {profile === 'buyer' ||
                            profile === 'supplier' ||
                            profile === 'admin' ||
                            profile === 'buyer-supplier' ||
                            profile === 'administrative' ? (
                                <>
                                    {currentTab === 0 ? <MyValidationProcess /> : null}
                                    {currentTab === 1 ? <MyDocuments /> : null}
                                    {currentTab === 2 ? <MyBuyers /> : null}
                                    {currentTab === 3 ? <MySuppliers /> : null}
                                    {currentTab === 4 ? <MySteps /> : null}
                                </>
                            ) : null}
                            {profile === 'service-provider' ? (
                                <>
                                    {currentTab === 0 ? <MyValidationProcess /> : null}
                                    {currentTab === 1 ? <MyDocuments /> : null}
                                    {currentTab === 2 ? <MySuppliersServiceProviderView /> : null}
                                    {currentTab === 3 ? <ServiceProvider /> : null}
                                    {currentTab === 4 ? <MySteps /> : null}
                                </>
                            ) : null}
                        </Col>
                    </Row>
                </Container>
            </ContainerAuthenticated>
        </>
    )
}
