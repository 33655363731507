import React, { useContext, useEffect, useState } from 'react'
import { CoreContext } from 'context/CoreContext'

import Questionarie from 'components/Dashboard/Sms/MyValidationProcess/Esg/Questionarie/index'
import { PageCard, CardHeader, CardLine, CardLineColumn } from './styled'

export default function MyValidationProcess({ setScreen }) {
    const [mfsQuestionarie, setMfsQuestionarie] = useState('0')
    const { findlanguage } = useContext(CoreContext)

    const readBlackBoldCss = {
        whiteSpace: 'pre-line',
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#565656',
    }

    return (
        <>
            {mfsQuestionarie === '0' ? (
                <PageCard>
                    <CardHeader>{findlanguage().myValidationProcess}</CardHeader>
                    <div style={{ float: 'right', marginTop: '-67px', position: 'sticky' }}>
                        <button
                            className="btn btn-secondary"
                            style={{ marginRight: '5px', marginTop: '15px' }}
                            onClick={() => setScreen(1)}
                        >
                            {findlanguage().goBack}
                        </button>
                    </div>
                    <CardLine>
                        <CardLineColumn>
                            <label style={readBlackBoldCss} className="form-label">
                                {findlanguage().pleaseChooseTheQuestionnaireThatBestFitsYourCompany}
                            </label>
                            <select
                                className="form-control"
                                onChange={(event) => setMfsQuestionarie(event.target.value)}
                            >
                                <option value="0">{findlanguage().chooseYourQuestionarie}</option>
                                <option value="1">{findlanguage().basicPreCheck}</option>
                            </select>
                        </CardLineColumn>
                    </CardLine>
                </PageCard>
            ) : null}
            {mfsQuestionarie === '1' ? (
                <Questionarie setMfsQuestionarie={setMfsQuestionarie} mfsQuestionarie={mfsQuestionarie} backOffice={false} goBack={() => { }} company_id={-1} />
            ) : null}
        </>
    )
}
