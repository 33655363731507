import styled from 'styled-components'

// import {
//     mobileSize
// } from 'ui/styled'

export const Content = styled.div.attrs({})``

export const PageHeader = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px 0;

    margin-bottom: 20px;
`

export const PageHeaderTitle = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: var(--grey-color);
`

export const PageCard = styled.div.attrs({})`
    padding: 24px;
    background: var(--white-color);

    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
    box-shadow: 0px 1.5px 6px var(--shadow-color);
    margin: 32px 0;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`

export const Terms = styled.div.attrs({})`
    width: 100%;
    height: 100px;
    overflow-y: scroll;
    margin-bottom: 20px;
    padding: 30px;
    border: 1px solid #ccc;
`

export const ProfileFormActions = styled.div.attrs({})`
    flex: 1;
    min-width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px;
`
