import React, { useState, useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const SupplierStatusDoughnutChart = ({ showDataLabels, supplierRequest }) => {
    const [timeFrame, setTimeFrame] = useState('all');

    const chartData = useMemo(() => {
        const now = new Date();
        now.setHours(0, 0, 0, 0);

        const statusCounts = {
            'Active': 0,
            'Inactive': 0,
            'In Progress': 0
        };
        const statusDetails = {
            'Active': [],
            'Inactive': [],
            'In Progress': []
        };

        supplierRequest.forEach(item => {
            if (item.supplier_general_requirements) {
                const createdAt = new Date(item.updated_at);
                const daysAgo = (now - createdAt) / (1000 * 60 * 60 * 24);

                let include = false;
                switch (timeFrame) {
                    case 'last7days':
                        include = daysAgo < 7;
                        break;
                    case 'last30days':
                        include = daysAgo < 30;
                        break;
                    case 'current':
                        include = daysAgo < 1;
                        break;
                    case 'all':
                        include = true;
                        break;
                    default:
                        break;
                }

                if (include) {
                    let status = item.supplier_general_requirements;
                    if (status === 'Inative') status = 'Inactive';
                    statusCounts[status] = (statusCounts[status] || 0) + 1;
                    statusDetails[status]?.push(item?.name);
                }
            }
        });

        return {
            labels: Object.keys(statusCounts),
            datasets: [{
                data: Object.values(statusCounts),
                backgroundColor: [
                    'rgba(75, 192, 192, 0.6)', // Active
                    'rgba(255, 99, 132, 0.6)', // Inactive
                    'rgba(255, 206, 86, 0.6)' // In Progress
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(255, 206, 86, 1)'
                ],
                borderWidth: 1
            }],
            tooltipItems: statusDetails
        };
    }, [supplierRequest, timeFrame]);

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    usePointStyle: true,
                }
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.label}: ${tooltipItem.raw}`;
                    }
                }
            },
            datalabels: {
                display: true,
                color: showDataLabels ? 'black' : 'white',
                align: 'center',
                font: {
                    weight: 'bold',
                    size: 14
                },
                formatter: (value, context) => {
                    if (showDataLabels) {
                        const label = context.chart.data.labels[context.dataIndex];
                        return `${label}: ${value}`;
                    } else {
                        return value;
                    }
                }
            }
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                bottom: 75,
                top: 0
            }
        },
        radius: '90%'
    };

    return (
        <div style={{ height: '385px' }}>
            <select value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)} style={{ marginBottom: '10px' }}>
                <option value="current">Current</option>
                <option value="last7days">Last 7 Days</option>
                <option value="last30days">Last 30 Days</option>
                <option value="all">All</option>
            </select>
            <h2>Supplier Status</h2>
            <Doughnut data={chartData} options={options} plugins={[ChartDataLabels]} />
        </div>
    );
};

export default SupplierStatusDoughnutChart;
