import React, { useContext, useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap'
import { useHistory } from 'react-router-dom'

import {
    PageHeader,
    PageHeaderTitle,
    PageHeaderActions,
    PageHeaderBack,
    PageCard,
    ProfileContainer,
    ProfileContent,
    ProfileFormContent,
    PageScroller,
} from './styled'

import Input from 'components/Form/Input'

import { CoreContext } from 'context/CoreContext'

import RegisterServiceProviderStep2Form from 'components/Register/Steps/ServiceProvider/Step2/Form'
import { ReadCountries } from 'services/location'
import { ReadCompanyCategories, ReadServicesOffered } from 'services/infos'

export default function DashboardAccountCompleteServiceProvider() {
    const history = useHistory()
    const back = () => history.goBack()

    const { setModal, user, findlanguage } = useContext(CoreContext)

    const [registerInfos, setRegisterInfos] = useState({})
    const [form, setForm] = useState({})
    const formValue = (ref) => {
        return form?.[ref] ? form?.[ref] : ''
    }
    const changeForm = (value, ref) => {
        setForm({ ...form, [ref]: value })
    }

    const init = async () => {
        const countries = await ReadCountries()
        const companyCategories = await ReadCompanyCategories()
        const servicesOffered = await ReadServicesOffered()

        const infos = {
            servicesOffered,
            companyCategories,
            countries: countries.map((item) => ({ ...item, label: item.name, value: item.iso2 })),
        }

        if (infos?.countries) {
            setRegisterInfos(infos)
        }
    }

    useEffect(() => {
        init()
    }, [])

    return (
        <>
            <Row>
                <Col>
                    <PageCard>
                        <PageHeader>
                            <PageHeaderActions>
                                <PageHeaderBack onClick={back} />
                                <PageHeaderTitle>
                                    {findlanguage().completeYourProfile}
                                </PageHeaderTitle>
                            </PageHeaderActions>
                            <PageHeaderActions></PageHeaderActions>
                        </PageHeader>
                        <ProfileContainer>
                            <ProfileFormContent>
                                <Input placeholder="Company Name" value={user.name} />
                                <Input placeholder="Contact Name" value={user.contact_name} />
                                <Input placeholder="Email" value={user.email} />
                                <Input placeholder="Phone Number (optional)" value={user.phone} />
                                <Input placeholder="Address" value={user.address?.street} />
                                <Input placeholder="City" value={user.address?.city} />
                                <Input placeholder="State" value={user.address?.state} />
                                <Input placeholder="Country" value={user.address?.country} />
                                <Input placeholder="ZIP Code" value={user.zip_code} />
                                <Input placeholder="Website" value={user.website} />
                            </ProfileFormContent>
                            <ProfileContent>
                                {/* <ProfileContentSpace /> */}
                                {/* <DashboardButton>Change Password</DashboardButton>  */}
                            </ProfileContent>
                        </ProfileContainer>
                    </PageCard>

                    <PageCard>
                        <PageHeader>
                            <PageHeaderTitle></PageHeaderTitle>
                            <PageHeaderActions></PageHeaderActions>
                        </PageHeader>
                        <PageScroller>
                            <RegisterServiceProviderStep2Form
                                formValue={formValue}
                                changeForm={changeForm}
                                next={() => null}
                                registerInfos={registerInfos}
                            />
                        </PageScroller>
                    </PageCard>
                </Col>
            </Row>
        </>
    )
}
