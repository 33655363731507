import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { Title, Text } from './styled'

import Button from 'components/Form/Button'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

export default function ModalDenied({ offer }) {
    const { setModal, findlanguage } = useContext(CoreContext)

    const history = useHistory()
    const navigate = (to) => {
        history.push(`/${to}`)
        close()
    }

    const close = () => {
        setModal(null)
    }

    return (
        <>
            <ModalWrapper colorized={true}>
                <Title>{findlanguage().modalPlanTitle}</Title>

                {offer === '' || offer === undefined || offer === 'free' ? (
                    <Text>{findlanguage().premiumPlanText}</Text>
                ) : offer === 'premium' ? (
                    <Text>{findlanguage().premiumPlanText}</Text>
                ) : (
                    ''
                )}

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '30px',
                        marginBottom: '50px',
                        marginLeft: '15px',
                    }}
                >
                    <Button whiteAndBlack onClick={() => close()}>
                        {findlanguage().planButtonStay}
                    </Button>

                    <Button whiteAndBlack onClick={() => navigate('dashboard/account-premium')}>
                        {findlanguage().trialButtonChoose}
                    </Button>
                </div>
            </ModalWrapper>
        </>
    )
}
