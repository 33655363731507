import { GET } from './api'

// CompanyCategories
export const ReadCompanyCategories = async () => {
    return await GET(`/company-categories`, false)
}

export const CountCompanyCategories = async () => {
    return await GET(`/company-categories/count`, false)
}

// CertificationsAndApprovals
export const ReadCertificationsAndApprovals = async () => {
    return await GET(`/certifications-and-approvals`, false)
}

export const CountCertificationsAndApprovals = async () => {
    return await GET(`/certifications-and-approvals/count`, false)
}

// SupplierPerRequirements
export const ReadSupplierPerRequirements = async () => {
    return await GET(`/supplier-pre-requirements`, false)
}

export const CountSupplierPerRequirements = async () => {
    return await GET(`/supplier-pre-requirements/count`, false)
}

// ServicesOffered
export const ReadServicesOffered = async () => {
    return await GET(`/service-offereds`, false)
}

export const CountServicesOffered = async () => {
    return await GET(`/service-offereds/count`, false)
}

// ProvidedServices
export const ReadProvidedServices = async () => {
    return await GET(`/provided-services`, false)
}

export const CountProvidedServices = async () => {
    return await GET(`/provided-services/count`, false)
}

// BusinessTypes
export const ReadBusinessTypes = async () => {
    return await GET(`/business-types/info`, false)
}

export const CountBusinessTypes = async () => {
    return await GET(`/business-types/info/count`, false)
}

// Supplier Products Source
export const ReadSupplierProductsSource = async () => {
    return await GET(`/supplier-products-sources`, false)
}
