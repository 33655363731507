import React, { useEffect, useState, useContext } from 'react'
import { PageCard } from '../../../../../Panels/SMSControl/styled'
import { TitleGreen } from '../styled'
import InputMask from 'react-input-mask'
import {
    GetSupplierQuestionnarieGetOne,
    PutSupplierQuestionnarie,
    PostSupplierQuestionnarie,
} from 'services/egs'
import { ReadMe } from 'services/authentication'
import DashboardButton from 'components/Dashboard/Button'
import _ from 'lodash'
import { CoreContext } from 'context/CoreContext'

const labelCss = { fontWeight: 'bold', fontSize: '15px', color: 'rgb(86, 86, 86)' }
const inputCss = { borderColor: '#70707059' }
const col6 = { fontSize: '700', color: '#707070' }
const doneTypingInterval = 1000

let typingTimer

export default function Questionarie({ setForm, form, setMfsQuestionarie }) {
    const { profile, setModal, user } = useContext(CoreContext)
    const [questionnaire, setQuestionnaire] = useState({
        id: -1,
        legal_company_name: '',
        ein_number: '',
        address: '',
        city: '',
        state: '',
        country: '',
        zip: '',
        phone: '',
        email: '',
        category: '',
        other: '',
        position: '',
        type_of_company: '',
        person: '',
        form_status: '',
        user_accepted_terms: false,
    })

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        waitUserFinishTyping()
    }, [questionnaire])

    const init = async () => {
        const readMe = await ReadMe()
        const getSupplierQuestionnarie = await GetSupplierQuestionnarieGetOne(user.company_id)

        if (
            getSupplierQuestionnarie.statusText !== 'Not Found' &&
            getSupplierQuestionnarie.length !== 0
        ) {
            if (
                getSupplierQuestionnarie[0].form_status === 'open' ||
                getSupplierQuestionnarie[0].form_status === null
            )
                setForm(false)
            else setForm(true)

            setQuestionnaire(getSupplierQuestionnarie[0])
        } else
            setQuestionnaire(
                await PostSupplierQuestionnarie({
                    users_permissions_user: readMe.company_id,
                    form_status: 'open',
                })
            )
    }

    function changeQuestionaire(value, field, isInt) {
        const pass = rules(value, field)

        if (pass) {
            setQuestionnaire({
                ...questionnaire,
                [field]: isInt ? parseInt(value.replace(/\D/g, '')) : value,
            })
        }
    }

    function rules(value, field) {
        switch (field) {
            case 'other':
            case 'position':
            case 'email':
                if (value.length > 30) return false
                else return true
                break
            default:
                return true
                break
        }
    }

    function waitUserFinishTyping() {
        clearTimeout(typingTimer)
        typingTimer = setTimeout(saveQuestionarie, doneTypingInterval)
    }

    const saveQuestionarie = async () => {
        if (questionnaire.id !== -1) await PutSupplierQuestionnarie(questionnaire)
    }

    const submitQuestionarie = async () => {
        let questionnaireClone = _.cloneDeep(questionnaire)
        questionnaireClone.form_status = 'pending'
        questionnaireClone.user_accepted_terms = true

        setQuestionnaire(questionnaireClone)
        setForm(true)

        await PutSupplierQuestionnarie(questionnaire)

        return setModal({
            type: 'alert',
            text: 'MyTS form submitted.',
            warning: true,
        })
    }

    const submitMfs = () => {
        setModal({
            type: 'submit-mfs',
            title: 'Submit MyTS',
            submitQuestionarie: submitQuestionarie,
        })
    }

    return (
        <>
            <PageCard>
                <TitleGreen>
                    Supplier Questionnaire - ESG{' '}
                    <div style={{ float: 'right', marginTop: '-27px', position: 'sticky' }}>
                        <button
                            className="btn btn-secondary"
                            style={{ marginRight: '5px', marginTop: '15px' }}
                            onClick={() => setMfsQuestionarie('0')}
                        >
                            Go Back
                        </button>
                        {questionnaire.form_status === 'open' ||
                        questionnaire.form_status === null ? (
                            <button
                                className="btn"
                                style={{
                                    marginRight: '5px',
                                    marginTop: '15px',
                                    backgroundColor: '#263640',
                                    color: 'white',
                                }}
                                onClick={submitMfs}
                            >
                                Submit
                            </button>
                        ) : null}
                    </div>
                </TitleGreen>
                <div className="row">
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            Legal Company Name:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.legal_company_name}
                            onChange={(event) =>
                                changeQuestionaire(event.target.value, 'legal_company_name')
                            }
                            required
                            disabled={form}
                        />
                    </div>
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            EIN Number:
                        </label>
                        <InputMask
                            disableunderline="true"
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.ein_number}
                            onChange={(event) =>
                                changeQuestionaire(event.target.value, 'ein_number', true)
                            }
                            mask="99-9999999"
                            required
                            disabled={form}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            Address:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.address}
                            onChange={(event) => changeQuestionaire(event.target.value, 'address')}
                            disabled={form}
                        />
                    </div>
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            City:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.city}
                            onChange={(event) => changeQuestionaire(event.target.value, 'city')}
                            disabled={form}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            State:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.state}
                            onChange={(event) => changeQuestionaire(event.target.value, 'state')}
                            disabled={form}
                        />
                    </div>
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            Country:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.country}
                            onChange={(event) => changeQuestionaire(event.target.value, 'country')}
                            disabled={form}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            ZIP:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.zip}
                            onChange={(event) => changeQuestionaire(event.target.value, 'zip')}
                            disabled={form}
                        />
                    </div>
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            Category:
                        </label>
                        <select
                            className="form-select"
                            aria-label="Default select example"
                            value={questionnaire.category}
                            onChange={(event) => changeQuestionaire(event.target.value, 'category')}
                            disabled={form}
                        >
                            <option value="Red Meat">Red Meat</option>
                            <option value="Poultry">Poultry</option>
                            <option value="Fish">Fish</option>
                            <option value="Fruit">Fruit</option>
                            <option value="Vegetables">Vegetables</option>
                            <option value="Nuts">Nuts</option>
                            <option value="Dairy & Egg">Dairy & Egg</option>
                            <option value="Ready meals and sandwiches">
                                Ready meals and sandwiches
                            </option>
                            <option value="Beverages">Beverages</option>
                            <option value="Alcoholic Drinks">Alcoholic Drinks</option>
                            <option value="Coffe">Coffe</option>
                            <option value="Bakery">Bakery</option>
                            <option value="Pantry Product (Canned)">Pantry Product (Canned)</option>
                            <option value="Ingredients">Ingredients</option>
                            <option value="Dry Products">Dry Products</option>
                            <option value="Confectionery & Snacks">Confectionery & Snacks</option>
                            <option value="Sauce">Sauce</option>
                            <option value="Oil and fats">Oil and fats</option>
                            <option value="Plant Based">Plant Based</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            Other:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.other}
                            onChange={(event) => changeQuestionaire(event.target.value, 'other')}
                            disabled={form}
                        />
                    </div>
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            Type of Company::
                        </label>
                        <select
                            className="form-select"
                            aria-label="Default select example"
                            value={questionnaire.type_of_company}
                            onChange={(event) =>
                                changeQuestionaire(event.target.value, 'type_of_company')
                            }
                            disabled={form}
                        >
                            <option value="Food Production Industry">
                                Food Production Industry
                            </option>
                            <option value="Farm (Field and Packing)">
                                Farm (Field and Packing)
                            </option>
                            <option value="Farm (Rural Producer)">Farm (Rural Producer)</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            Person:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.person}
                            onChange={(event) => changeQuestionaire(event.target.value, 'person')}
                            disabled={form}
                        />
                    </div>
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            Position:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.position}
                            onChange={(event) => changeQuestionaire(event.target.value, 'position')}
                            disabled={form}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            Email:
                        </label>
                        <input
                            type="email"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.email}
                            onChange={(event) => changeQuestionaire(event.target.value, 'email')}
                            disabled={form}
                        />
                    </div>

                    <div className="col-6 mb-3" style={col6}>
                        <label className="form-label" style={labelCss}>
                            Phone:
                        </label>
                        <InputMask
                            type="text"
                            className="form-control"
                            style={inputCss}
                            value={questionnaire.phone}
                            onChange={(event) => changeQuestionaire(event.target.value, 'phone')}
                            maskChar=" "
                            disabled={form}
                        />
                    </div>
                </div>
            </PageCard>
        </>
    )
}
