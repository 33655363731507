import styled from 'styled-components'

export const BodyContentTable = styled.div.attrs({})`
    max-width: 600px;
`
export const TableLine = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    max-width: 600px;
    padding: 10px 15px;
    margin-bottom: 12px;

    ${(props) =>
        props.odd
            ? `
            background: var(--oddgrey-color);
        `
            : `
            background: var(--evengrey-color);
        `}
`
export const LineText = styled.div.attrs({})`
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: #5c5c5c;

    ${(props) =>
        props.text
            ? `
            color: var(--grey-color);
              font-family: 'Open Sans', sans-serif;
        `
            : ``}

    ${(props) =>
        props.link
            ? `
            color: var(--primary-color);
            cursor: pointer;
        `
            : ``}
`