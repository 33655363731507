import React, { useState, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-moment';

const ResponsesLineChart = ({ supplierRequest }) => {
    const [timeFrame, setTimeFrame] = useState('last30days');

    const timeFrames = {
        current: 1,
        last7days: 7,
        last15days: 15,
        last30days: 30,
        last60days: 60
    };

    const selectedDays = timeFrames[timeFrame];

    const filteredData = useMemo(() => {
        const now = new Date();
        return supplierRequest.filter(item => {
            const createdAt = new Date(item.created_at);
            const diffTime = now - createdAt;
            const diffDays = diffTime / (1000 * 60 * 60 * 24);
            return diffDays <= selectedDays;
        });
    }, [supplierRequest, selectedDays]);

    const chartData = useMemo(() => {
        const groupedData = filteredData.reduce((acc, item) => {
            const name = item?.name || 'Unknown';
            if (!acc[name]) {
                acc[name] = [];
            }
            acc[name].push(item);
            return acc;
        }, {});

        const datasets = Object.keys(groupedData).map(name => {
            const sortedData = groupedData[name].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
            let count = 0;
            const dataPoints = sortedData.map(item => {
                count++;
                return { x: new Date(item.created_at), y: count };
            });

            return {
                label: name,
                data: dataPoints,
                fill: false,
                borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                tension: 0.1
            };
        });

        const now = new Date();
        const minDate = new Date(now.getTime() - selectedDays * 24 * 60 * 60 * 1000);
        const dateArray = [];
        for (let d = new Date(minDate); d <= now; d.setDate(d.getDate() + 1)) {
            dateArray.push(new Date(d));
        }

        const transparentDataset = {
            label: 'Transparent',
            data: dateArray.map(date => ({ x: date, y: null })),
            fill: false,
            borderColor: 'rgba(0,0,0,0)',
            pointRadius: 0,
            borderWidth: 0,
            hidden: true
        };

        return { datasets: [...datasets, transparentDataset] };
    }, [filteredData, selectedDays]);

    const options = useMemo(() => {
        const now = new Date();
        const minDate = new Date(now.getTime() - selectedDays * 24 * 60 * 60 * 1000);

        return {
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: 'day',
                        tooltipFormat: 'll',
                        min: minDate,
                        max: now
                    }
                },
                y: {
                    beginAtZero: true,
                    ticks: {
                        stepSize: 1
                    },
                    title: {
                        display: true,
                        text: 'Number of Forms Answered'
                    }
                }
            },
            plugins: {
                legend: {
                    position: 'top',
                    labels: {
                        filter: (legendItem, chartData) => {
                            return legendItem.text !== 'Transparent';
                        }
                    }
                }
            },
            responsive: true,
            maintainAspectRatio: false
        };
    }, [selectedDays]);

    return (
        <div style={{ height: '400px', width: '100%', marginTop: '-80px' }}>
            <select value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)} style={{ marginBottom: '10px' }}>
                <option value="current">Current</option>
                <option value="last7days">Last 7 Days</option>
                <option value="last15days">Last 15 Days</option>
                <option value="last30days">Last 30 Days</option>
                <option value="last60days">Last 60 Days</option>
            </select>
            <h2>Number of Responses</h2>
            <Line data={chartData} options={options} />
        </div>
    );
};

export default ResponsesLineChart;
