import styled from 'styled-components'

export const BodyContent = styled.div.attrs({})`
    padding-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
`

export const FormContent = styled.div.attrs({})`
    padding: 40px 50px 0px;
    background: var(--white-color);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    box-shadow: 0px 1.5px 6px var(--shadow-color);
    margin-bottom: 30px;
`

export const RequiredText = styled.div.attrs({})`
    color: var(--primary-color);
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-align: center;
    margin: 35px 0;
    padding-bottom: 100px;
`

export const FormTitle = styled.div.attrs({})`
    color: var(--primary-color);
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    padding-bottom: 30px;
`

export const CheckList = styled.div.attrs({})`
    padding-bottom: 30px;
`

export const CheckListPool = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;

    vertical-align: top;
`

export const CheckContent = styled.div.attrs({})`
    padding-right: 30px;
    padding-bottom: 30px;
    vertical-align: top;

    ${(props) => (props.style ? props.style : ``)}

    ${(props) =>
        props.action
            ? `
            margin-top: -15px;
        `
            : ``}
`
