import { GET, POST, PUT } from './api'

export const GetSupplierQuestionnarie = async () => {
    return await GET(`/supplier-questionnaires`, true)
} ///${id}

export const GetSupplierQuestionnarieGetOne = async (userId) => {
    return await GET(`/supplier-questionnaires?users_permissions_user=${userId}`, true)
}

export const PostSupplierQuestionnarie = async (params) => {
    return await POST(`/supplier-questionnaires`, params, true)
}

export const PutSupplierQuestionnarie = async (params) => {
    return await PUT(`/supplier-questionnaires/${params.id}`, params, true)
}

//checklist
export const GetCertifications = async (userId) => {
    return await GET(`/certifications-documents?users_permissions_user=${userId}`, true)
}

export const PutCertifications = async (params) => {
    return await PUT(`/certifications-documents/${params.id}`, params, true)
}

export const PostCertifications = async (params) => {
    return await POST(`/certifications-documents`, params, true)
}

//ESG
export const GetCertificationsESG = async (userId) => {
    return await GET(`/certifications-documents-esgs?users_permissions_user=${userId}`, true)
}

//ESG
export const GetOneCertificationsESG = async (company_id) => {
    return await GET(`/certifications-documents-esgs/findoneesg?company_id=${company_id}`, true)
}

export const PutCertificationsESG = async (params) => {
    return await PUT(`/certifications-documents-esgs/${params.id}`, params, true)
}

export const PostCertificationsESG = async (params) => {
    return await POST(`/certifications-documents-esgs`, params, true)
}

//ESG Basic
export const GetCertificationsESGBasic = async (userId) => {
    return await GET(`/certifications-documents-esg-basics?users_permissions_user=${userId}`, true)
}

export const GetOneCertificationsESGBasic = async (company_id) => {
    return await GET(`/certifications-documents-esg-basics/findoneesg?company_id=${company_id}`, true)
}

export const PutCertificationsESGBasic = async (params) => {
    return await PUT(`/certifications-documents-esg-basics/${params.id}`, params, true)
}

export const PostCertificationsESGBasic = async (params) => {
    return await POST(`/certifications-documents-esg-basics`, params, true)
}

//Additional Information
export const GetAdditionalInformation = async (userId) => {
    return await GET(`/additional-informations?users_permissions_user=${userId}`, true)
}

export const PutAdditionalInformation = async (params) => {
    return await PUT(`/additional-informations/${params.id}`, params, true)
}

export const PostAdditionalInformation = async (params) => {
    return await POST(`/additional-informations`, params, true)
}

//Product Description
export const GetProductDescription = async (userId) => {
    return await GET(`/product-descriptions?users_permissions_user=${userId}`, true)
}

export const PutProductDescription = async (params) => {
    return await PUT(`/product-descriptions/${params.id}`, params, true)
}

export const PostProductDescription = async (params) => {
    return await POST(`/product-descriptions`, params, true)
}

//Establishment Data
export const GetEstablishmentData = async (userId) => {
    return await GET(`/establishment-data?users_permissions_user=${userId}`, true)
}

export const PutEstablishmentData = async (params) => {
    return await PUT(`/establishment-data/${params.id}`, params, true)
}

export const PostEstablishmentData = async (params) => {
    return await POST(`/establishment-data`, params, true)
}

//Specific Questions
export const GetSpecificsQuestions = async (userId) => {
    return await GET(`/specific-questions?users_permissions_user=${userId}`, true)
}

export const PutSpecificsQuestions = async (params) => {
    return await PUT(`/specific-questions/${params.id}`, params, true)
}

export const PostSpecificsQuestions = async (params) => {
    return await POST(`/specific-questions`, params, true)
}

//capa
export const GetCapa = async (userId) => {
    return await GET(`/capas?users_permissions_user=${userId}`, true)
}

export const PutCapa = async (params) => {
    return await PUT(`/capas/${params.id}`, params, true)
}

export const PostCapa = async (params) => {
    return await POST(`/capas`, params, true)
}
