import React, { useState } from 'react'
import Questionarie from './Supplier/index'
import Additionalnformation from './Additionalnformation/index'
import ProductDescription from './ProductDescription/index'
import EstablishmentData from './EstablishmentData/index'
import SpecificQuestions from './SpecificQuestions/index'
import SelfAssessmentChecklist from './SelfAssessmentChecklist/index'

export default function DashboardTabESG({ setMfsQuestionarie }) {
    const [form, setForm] = useState(false)

    return (
        <>
            <Questionarie setForm={setForm} form={form} setMfsQuestionarie={setMfsQuestionarie} />
            <Additionalnformation form={form} />
            <ProductDescription form={form} />
            <EstablishmentData form={form} />
            <SpecificQuestions form={form} />
            <SelfAssessmentChecklist form={form} />
        </>
    )
}
