import React, { useContext, useState } from 'react'
import { CoreContext } from 'context/CoreContext'

import { CardContainer, CardImage, CardContent, CardTitle, CardText } from './styled'

export default function RegisterCard({ image, title, description, onClick }) {
    const { findlanguage } = useContext(CoreContext)
    console.log(title)
    return (
        <>
            <CardContainer onClick={onClick}>
                <CardImage image={image} />
                <CardContent>
                    <CardTitle>
                        {
                            title === 'Headquarters' ?
                                <div>
                                    <div style={{ fontSize: 'x-large'}}>{title}</div>
                                    {findlanguage().buyerOrSupplier}
                                </div>

                                :
                                <div>
                                    <div style={{ fontSize: 'x-large' }}>{title}</div>
                                    {findlanguage().offerYourServices}
                                </div>
                        }
                    </CardTitle>
                    <CardText>{
                        title === 'Headquarters' ?
                            <ul>
                                <li>{findlanguage().connectsBuyersAndSuppliers}</li>
                                <li>{findlanguage().sellProductsToAWiderAudience}</li>
                                <li>{findlanguage().controlRequirementsAndDueDates}</li>
                                <li>{findlanguage().controlInternalDocuments}</li>
                            </ul>

                            :
                            <ul>
                                <li>{findlanguage().createDetailedProfiles}</li>
                                <li>{findlanguage().setCompetitivePricing}</li>
                                <li>{findlanguage().promoteServicesThroughAdvertising}</li>
                            </ul>
                    }</CardText>
                </CardContent>
            </CardContainer>
        </>
    )
}
