import React, { useState, useContext } from 'react'
import { toast } from 'react-toastify'

import {
    BodyContent,
    FormContent,
    RequiredText,
    FormTitle,
    CheckList,
    CheckContent,
    Label,
    CheckRow,
} from './styled'

import Check from 'components/Form/Check'
import SelectMany from 'components/Form/SelectMany'
import SelectSupplierProduct from 'components/Form/SelectSupplierProduct'
import CheckMany from 'components/Form/CheckMany'
import RegisterFooter from 'components/Register/Footer'
import Textarea from 'components/Form/Textarea'
import { CoreContext } from 'context/CoreContext'

export default function RegisterSupplierStep2Form({
    formValue,
    changeForm,
    registerInfos,
    full,
    next,
    back,
    loading,
}) {
    const { findlanguage, language } = useContext(CoreContext)

    const business = registerInfos?.businessTypes?.supplier?.length
        ? registerInfos?.businessTypes?.supplier
              ?.sort((a, b) => (a > b ? 1 : -1))
              .map((mit, key) => {
                  let traducao = mit.value
                  switch (mit) {
                      case 'Farm':
                          traducao = 'Fazenda'
                          break
                      case 'Industry':
                          traducao = 'Industria'
                          break
                      case 'Restaurant':
                          traducao = 'Restaurante'
                          break
                      case 'Retail':
                          traducao = 'Varejo'
                          break
                      case 'Store and Distribuition':
                          traducao = 'Armazenamento e distribuição'
                          break
                      case 'Broker':
                          traducao = 'Corretora'
                          break
                      case 'Process Food':
                          traducao = 'Alimentos Processados'
                          break
                  }

                  return {
                      id: key + 1,
                      title: mit,
                      value: mit,
                      traducao: traducao,
                  }
              })
        : []

    const categories = registerInfos?.companyCategories?.length
        ? registerInfos?.companyCategories
              ?.sort((a, b) => (a.title > b.title ? 1 : -1))
              .map((mit, key) => ({ id: key + 1, ...mit }))
        : []

    const loadSupplierProductsSource = () => {
        // console.log(registerInfos?.supplierProductsSource, '')

        if (language === 'portugues')
            return registerInfos?.supplierProductsSource?.length
                ? registerInfos?.supplierProductsSource
                      ?.sort((a, b) => (a.category_pt > b.category_pt ? 1 : -1))
                      .map((item) => ({
                          category: item.category_pt,
                          checked: false,
                          options: item.items?.length
                              ? item.items.sort((a, b) => (a.name > b.name ? 1 : -1))
                              : [],
                      }))
                : []
        else
            return registerInfos?.supplierProductsSource?.length
                ? registerInfos?.supplierProductsSource
                      ?.sort((a, b) => (a.category > b.category ? 1 : -1))
                      .map((item) => ({
                          category: item.category,
                          checked: false,
                          options: item.items?.length
                              ? item.items.sort((a, b) => (a.name > b.name ? 1 : -1))
                              : [],
                      }))
                : []
    }

    const loadCerts = () => {
        if (language === 'portugues')
            return registerInfos?.certificationsAndApprovals?.length
                ? registerInfos?.certificationsAndApprovals
                      ?.sort((a, b) => (a.title_pt > b.title_pt ? 1 : -1))
                      .map((item) => ({
                          ...item,
                          title: item.title_pt,
                          description: item.label,
                          checked: false,
                          suboptions: item.items?.length
                              ? item.items.sort((a, b) => (a.label_pt > b.label_pt ? 1 : -1))
                              : [],
                      }))
                : []
        else
            return registerInfos?.certificationsAndApprovals?.length
                ? registerInfos?.certificationsAndApprovals
                      ?.sort((a, b) => (a.title > b.title ? 1 : -1))
                      .map((item) => ({
                          ...item,
                          title: item.title,
                          description: item.label,
                          checked: false,
                          suboptions: item.items?.length
                              ? item.items.sort((a, b) => (a.label > b.label ? 1 : -1))
                              : [],
                      }))
                : []
    }

    const [certifications, setCertifications] = useState(loadCerts())

    const backwards = () => {
        uncheck()
        back()
    }

    const isAdded = (item, key) => {
        return (
            formValue(key)?.length &&
            formValue(key)
                ?.map((mit) => mit.id)
                .includes(item.id)
        )
    }

    const setCheckBox = (master, child, state, propagate) => {
        let certsList = [...certifications]
        propagate = propagate === undefined ? true : propagate

        certsList.forEach((element) => {
            if (element.title === master && !child) {
                element.checked = state

                if (propagate) {
                    element.suboptions.forEach((item) => {
                        item.checked = state
                    })
                }
            } else if (element.title === master && child) {
                element.suboptions.forEach((item) => {
                    if (item.label === child) {
                        item.checked = state
                    }
                })

                const countOption = element.suboptions.length
                let countChildren = 0

                element.suboptions.forEach((item) => {
                    if (item.checked === true) {
                        countChildren++
                    }
                })

                // Setting State of Master
                if (countOption === countChildren) {
                    element.checked = true
                }
            }
        })

        setCertifications([...certsList])

        getChecked()
    }

    const returnValue = (master, suboptions) => {
        let certsList = [...certifications]

        certsList.reduce(function (new_array, item) {
            if (item.title === master) {
                item.suboptions = suboptions
            }
            new_array.push(item)
            return new_array
        }, [])

        setCheckBox(master, null, false)

        setCertifications([...certsList])
    }

    const uncheck = () => {
        let certsList = [...certifications]

        certsList.map(function (item) {
            item.checked = false
            if (item.suboptions)
                item.suboptions.map(function (item) {
                    item.checked = false
                    return item
                })
            return item
        })

        setCertifications([...certsList])
    }

    const getChecked = () => {
        let selected = []
        let certsList = [...certifications]

        certsList.forEach((element) => {
            if (element.suboptions.length === 0 && element.checked) {
                selected.push(element)
            } else {
                let children = []
                if (element.suboptions) {
                    element.suboptions.forEach((child) => {
                        if (child.checked) {
                            children.push(child)
                        }
                    })

                    if (children.length > 0) {
                        selected.push({
                            id: element.id,
                            title: element.title,
                            label: element.label,
                            suboptions: children,
                        })
                    }
                }
            }
        })

        changeForm([...selected], 'certifications')

        return selected
    }

    const toggleAdd = (item, key, param) => {
        const selected = formValue(key) || []
        const nxtValue = isAdded(item, key)
            ? [...selected.filter((fit) => fit.id !== item.id)]
            : [...selected, item]
        changeForm(nxtValue, key)
    }

    const [colorDistribution, setColorDistribution] = useState('var(--primary-color)')

    const action = async () => {
        // const distributionState = formValue('State')
        // const distributionNational = formValue('National')
        // const distributionInternational = formValue('International')

        // if (
        //     distributionState === '' &&
        //     distributionNational === '' &&
        //     distributionInternational === ''
        // ) {
        //     toast.error('Fill the field: Distribution')
        //     setColorDistribution('#ff4d4d')
        //     return
        // } else {
        //     setColorDistribution('var(--primary-color)')
        // }

        if (next && typeof next === 'function') {
            next()
        }
    }

    return (
        <>
            <BodyContent>
                <FormContent full={full}>
                    <SelectMany
                        title={findlanguage().typeOfBusiness}
                        placeholder={''}
                        action={findlanguage().addBusiness}
                        options={business}
                        value={formValue('businessType')}
                        onChange={(e) => changeForm(e, 'businessType')}
                    />

                    <CheckList>
                        <FormTitle>{findlanguage().typeOfCategoryCompanyFit}</FormTitle>
                        {language === 'portugues'
                            ? categories.map((item, key) => (
                                  <CheckContent key={key}>
                                      <Check
                                          title={item.title_pt}
                                          label={item.description_pt}
                                          onChange={() => toggleAdd(item, 'categories')}
                                          checked={isAdded(item, 'categories')}
                                          nowidth={true}
                                      />
                                  </CheckContent>
                              ))
                            : null}
                        {language === 'english'
                            ? categories.map((item, key) => (
                                  <CheckContent key={key}>
                                      <Check
                                          title={item.title}
                                          label={item.description}
                                          onChange={() => toggleAdd(item, 'categories')}
                                          checked={isAdded(item, 'categories')}
                                          nowidth={true}
                                      />
                                  </CheckContent>
                              ))
                            : null}
                    </CheckList>

                    <CheckList>
                        <FormTitle>{findlanguage().doYouHaveCertificationApproval}</FormTitle>

                        {certifications.map((item, key) => (
                            <CheckContent key={key}>
                                <CheckMany
                                    title={item.title}
                                    label={item.description}
                                    options={item.suboptions}
                                    value={isAdded(item, 'certifications')}
                                    onChange={setCheckBox}
                                    checked={item.checked}
                                    returnValue={returnValue}
                                />
                            </CheckContent>
                        ))}
                    </CheckList>

                    <SelectSupplierProduct
                        title={findlanguage().whatProductsAreProducedSupplied}
                        placeholder={''}
                        action={findlanguage().addProduct}
                        datasource={loadSupplierProductsSource()}
                        value={formValue('products_supplier')}
                        onChange={(e) => changeForm(e, 'products_supplier')}
                    />

                    <Textarea
                        label={findlanguage().descriptionProduct}
                        maxLength={500}
                        value={formValue('products_description')}
                        onChange={(e) => changeForm(e.target.value, 'products_description')}
                        bigger
                    />

                    <Textarea
                        label={findlanguage().aboutCompany}
                        maxLength={500}
                        value={formValue('about_company')}
                        onChange={(e) => changeForm(e.target.value, 'about_company')}
                        bigger
                    />

                    <Label style={{ color: colorDistribution }}>
                        {findlanguage().distribution}
                    </Label>

                    <CheckRow>
                        <Check
                            value={formValue('State')}
                            onChange={(i) => changeForm(i, 'State')}
                            label={findlanguage().distributionState}
                        />

                        <Check
                            value={formValue('National')}
                            onChange={(i) => changeForm(i, 'National')}
                            label={findlanguage().national}
                        />

                        <Check
                            value={formValue('International')}
                            onChange={(i) => changeForm(i, 'International')}
                            label={findlanguage().international}
                        />
                    </CheckRow>

                    <div
                        style={{
                            width: '100%',
                            marginBottom: '40px',
                        }}
                    >
                        <RequiredText>{findlanguage().requiredFields}</RequiredText>
                        <RegisterFooter action={action} back={backwards} loading={loading} />
                    </div>
                </FormContent>
            </BodyContent>
        </>
    )
}
