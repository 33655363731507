import React, { useEffect, useState, useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import { useHistory } from 'react-router-dom'

import { toast } from 'react-toastify'
import { DoRegisterServiceProvider } from 'services/authentication'
import { exposeStrapiErrorNew } from 'utils'

import ContainerUnauthenticated from 'containers/Unauthenticated'

import RegisterHeader from 'components/Register/Header'
import RegisterStepIndicator from 'components/Register/StepIndicator'
import RegisterServiceProviderStep1 from 'components/Register/Steps/ServiceProvider/Step1'
import RegisterServiceProviderStep2 from 'components/Register/Steps/ServiceProvider/Step2'

import { ReadServicesOffered, ReadProvidedServices, ReadCompanyCategories } from 'services/infos'

export default function RegisterServiceProvider() {
    const { findlanguage } = useContext(CoreContext)
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const [loading, setLoading] = useState(false)

    const steps = [
        {
            highlight: findlanguage().serviceProviderTitle,
            title: findlanguage().businessInformation,
        },
        { title: findlanguage().additionalInformation },
    ]

    const [step, setStep] = useState(0)
    const [registerInfos, setRegisterInfos] = useState({})

    const [form, setForm] = useState({})
    const formValue = (ref) => {
        return form?.[ref] ? form?.[ref] : ''
    }
    const changeForm = (value, ref) => {
        setForm({ ...form, [ref]: value })
    }

    const back = () => {
        if (step === 0) {
            history.goBack()
        } else {
            setStep(step - 1)
        }
    }

    const next = (auxFormValues) => {
        const nxt = step + 1
        if (nxt < steps.length) {
            setStep(nxt)
        } else {
            action(auxFormValues)
        }
    }

    const action = async (service_provider_regions) => {
        const _payload = {
            ...form,
            address: {
                street: form?.street,
                city: form?.city,
                state: form?.state,
                country: form?.country,
                zipCode: form?.zipCode,
                latitude: form?.latitude,
                longitude: form?.longitude,
            },
            aditionalInformations: {
                offeredServices: form.services?.map((item) => item.title) || [],
                providedServices: form.service_provide?.map((item) => item.title) || [],
                serviceDescription: form.service_description,
                aboutCompany: form.about_company,
                myCategories: form.categories?.map((it) => it.id) || [],
            },
            service_provider_regions: service_provider_regions,
        }

        // console.log(_payload)
        // return

        setLoading(true)

        const result = await DoRegisterServiceProvider(_payload)
        setLoading(false)

        if (result && exposeStrapiErrorNew(result)) {
            completeLogin()
        }
    }

    const completeLogin = () => {
        localStorage.removeItem('form')
        toast.success('Account created')
        navigate('register/service-provider/full-access')
    }

    const init = async () => {
        const servicesOffered = await ReadServicesOffered()
        const providedServices = await ReadProvidedServices()
        const companyCategories = await ReadCompanyCategories()

        const infos = {
            servicesOffered,
            providedServices,
            companyCategories,
        }

        setRegisterInfos(infos)
    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        if (step === 1) {
            localStorage.setItem('form', JSON.stringify(form))
        }
    }, [step])

    return (
        <>
            <ContainerUnauthenticated>
                <RegisterHeader title={'Sign Up'} loading={loading} />
                <RegisterStepIndicator steps={steps} current={step + 1} active {...steps[step]} />

                {step === 0 ? (
                    <RegisterServiceProviderStep1
                        formValue={formValue}
                        changeForm={changeForm}
                        form={form}
                        setForm={setForm}
                        next={next}
                        registerInfos={registerInfos}
                    />
                ) : null}
                {step === 1 ? (
                    <RegisterServiceProviderStep2
                        formValue={formValue}
                        changeForm={changeForm}
                        form={form}
                        setForm={setForm}
                        next={next}
                        back={back}
                        loading={loading}
                        registerInfos={registerInfos}
                    />
                ) : null}
            </ContainerUnauthenticated>
        </>
    )
}
