import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

const BASE_URL = 'https://www.usdalocalfoodportal.com/api/';
const directories = ['agritourism', 'csa', 'foodhub'];

const fetchDataByRadius = async (x, y, radius) => {
    const requests = directories.map(dir =>
        axios.get(`${BASE_URL}${dir}/`, {
            params: {
                apikey: "GkrYiFyxZB",
                x,
                y,
                radius
            }
        })
    );

    const responses = await Promise.all(requests);
    return responses.flatMap(response => response.data.data);
};

const fetchDataForState = async (state) => {
    const requests = directories.map(dir =>
        axios.get(`${BASE_URL}${dir}/`, {
            params: {
                apikey: "GkrYiFyxZB",
                state
            }
        })
    );

    const responses = await Promise.all(requests);
    return responses.flatMap(response => response.data.data);
};

const Dropdown = ({ items, selected, onChange }) => (
    <select value={selected} onChange={onChange}>
        {items.map(item => <option key={item} value={item}>{item}</option>)}
    </select>
);

const USDADataComponent = () => {
    const products = ['apple', 'coffee', 'orange'];
    const states = ['FL', 'MI'];

    const [selectedState, setSelectedState] = useState('FL');
    const [selectedProduct, setSelectedProduct] = useState('apple');
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);

    const mapContainerStyle = {
        width: '100%',
        height: '400px'
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchDataForState(selectedState);
            setLocations(data);
        };

        fetchData();
    }, [selectedState, selectedProduct]);

    const extractProducts = (briefDesc) => {
        // Check if briefDesc is null, undefined, or empty
        if (!briefDesc || typeof briefDesc !== 'string') {
            return [];
        }

        // Extract the part after 'Available Products:'
        const productString = briefDesc.split('Available Products:')[1];

        if (!productString) {
            return [];
        }

        // Split by ';' and format each product name
        return productString.split(';').map(product => {
            product = product.trim();
            return product.charAt(0).toUpperCase() + product.slice(1).toLowerCase();
        }).filter(Boolean); // filter(Boolean) removes any empty or falsy strings
    };

    return (
        <div>
            <Dropdown items={states} selected={selectedState} onChange={(e) => setSelectedState(e.target.value)} />
            <Dropdown items={products} selected={selectedProduct} onChange={(e) => setSelectedProduct(e.target.value)} />

            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={{ lat: 27.9944024, lng: -81.7602544 }}
                zoom={6}
            >
                {locations.map(location => (
                    <Marker
                        key={location.listing_id}
                        position={{
                            lat: parseFloat(location.location_y),
                            lng: parseFloat(location.location_x)
                        }}
                        onClick={() => setSelectedLocation(location)}
                    />
                ))}
                {selectedLocation && (
                    <InfoWindow
                        position={{
                            lat: parseFloat(selectedLocation.location_y),
                            lng: parseFloat(selectedLocation.location_x)
                        }}
                        onCloseClick={() => setSelectedLocation(null)}
                    >
                        <div className="markerPop">
                            <h1>{selectedLocation.listing_name}</h1>
                            <img src={selectedLocation.listing_image} alt={selectedLocation.listing_name} width="300" />
                            <h2>{selectedLocation.location_address}</h2>
                            <h3>Contact: {selectedLocation.contact_name}</h3>
                            <p>Phone: {selectedLocation.contact_phone}</p>
                            <h4>Available Products:</h4>
                            <ul>
                                {extractProducts(selectedLocation.brief_desc).map(product => <li key={product}>{product}</li>)}
                            </ul>
                        </div>
                    </InfoWindow>
                )}
            </GoogleMap>
        </div>
    );
}

export default USDADataComponent;
