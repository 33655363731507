import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { API_ENDPOINT } from 'services/api'
import { parseStrapiImage } from 'utils'

import {
    ProfileFormContent,
    ProfileFormActions,
    PageFormSpace,
    RowForm,
    ModalFormLabel,
    RowBlankSpace,
    AttachRow,
    AttachItem,
    AttachItemLink,
    UploadHiddenInput,
    Label,
    Link,
} from './styled'

import Button from 'components/Form/Button'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

import CertificationsOptions from 'components/Form/CertificationsOptions'
import DashboardButton from 'components/Dashboard/Button'
import { ReadCertificationsAndApprovals } from 'services/infos'

import { AddCertificate, GetCertificate } from 'services/myProfile'

import Check from 'components/Form/Check'
import { LoadBlack } from 'ui/styled'

export default function ModalAddCertificate() {
    const { user, modal, setModal, language, findlanguage } = useContext(CoreContext)
    const [certificateSelected, setCertificateSelected] = useState({})
    const [fileSelected, setFileSelected] = useState('')
    const [certPublic, setCertPublic] = useState(false)

    const getRadioData = (parent_id, parent_title, radio) => {
        setCertificateSelected({
            parent_id: parent_id,
            parent_title: parent_title,
            id: radio.id,
            label: language === 'portugues' ? radio.label_pt : radio.label,
        })
    }

    const action = async () => {
        let body_cert

        if (modal.id === undefined) {
            if (certificateSelected.parent_id === undefined) {
                toast.error('Fill the field: Approval/Certification')
                return
            }

            if (fileSelected === undefined || fileSelected === '') {
                toast.error('Fill the field: Attachment')
                return
            }

            body_cert = {
                certification_id: certificateSelected.parent_id,
                certification_title: certificateSelected.parent_title,
                certificate_id: certificateSelected.id < 0 ? 0 : certificateSelected.id,
                certificate_label: certificateSelected.label,
                user_id: user.company_id,
                public: certPublic,
            }
        } else {
            body_cert = { ...certificateSelected }
        }

        const formData = new FormData()
        formData.append('id', modal.id === undefined ? 0 : modal.id)
        formData.append('filePdf', document.getElementById('upload-input-hidden')?.files?.[0])
        formData.append('certification_id', body_cert.certification_id)
        formData.append('certification_title', body_cert.certification_title)
        formData.append('certificate_id', body_cert.certificate_id)
        formData.append('certificate_label', body_cert.certificate_label)
        formData.append('user_id', body_cert.user_id)
        formData.append('public', certPublic ? 1 : 0)

        await AddCertificate(formData)

        setModal({
            type: 'alert',
            text:
                modal.id === undefined
                    ? findlanguage().certificateAddedSuccessfully
                    : findlanguage().changesSaved,
        })

        const requestRefresh = modal.onRefresh

        if (requestRefresh) requestRefresh()
    }

    const [certifications, setCertifications] = useState([])

    useEffect(() => {
        if (modal.id === undefined) return

        GetCertificate(modal.id).then((cert) => {
            const _cert = {
                id: cert.id,
                certification_id: cert.certification_id,
                certification_title: cert.certification_title,
                certificate_id: cert.certificate_id,
                certificate_label: cert.certificate_label,
                public: cert.public,
                user_id: cert.user_id,
            }

            // console.log(_cert)

            setCertificateSelected({ ..._cert })

            setFileSelected(cert.filePdf)

            setCertPublic(cert.public)
        })
    }, [modal.id])

    useEffect(() => {
        if (modal.id === undefined) {
            ReadCertificationsAndApprovals().then((resp) => {
                const allcerts = resp
                    ?.sort((a, b) =>
                        (language === 'portugues' ? a.title_pt > b.title_pt : a.title > b.title)
                            ? 1
                            : -1
                    )
                    .map((cert) => {
                        const element = { ...cert }
                        if (element.items.length > 0) {
                            element.items = element.items.map((subitem) => {
                                subitem.title = subitem.label
                                return subitem
                            })
                        }
                        return cert
                    })

                // console.log('allcerts', allcerts)

                setCertifications(allcerts)
            })
        }
    }, [])

    const attach = (action) => {
        document.getElementById('upload-input-hidden')?.click()
    }

    const showFileName = () => {
        const file = document.getElementById('upload-input-hidden')?.files?.[0]
        setFileSelected(file)
    }

    return (
        <>
            <ModalWrapper>
                <ProfileFormContent>
                    <ModalFormLabel spaced>{findlanguage().approvalCertification}</ModalFormLabel>
                    {modal.id === undefined ? (
                        <RowForm>
                            {certifications.length === 0 ? <LoadBlack size={50} /> : ''}
                            <CertificationsOptions
                                unique={true}
                                datasource={certifications}
                                onSelectChange={getRadioData}
                            ></CertificationsOptions>
                        </RowForm>
                    ) : (
                        <RowForm>
                            <Label>
                                <div>{certificateSelected.certification_title}</div>

                                <Label>
                                    <Check
                                        checked={true}
                                        label={certificateSelected.certificate_label}
                                        nowidth
                                        locked
                                    />
                                </Label>
                            </Label>
                        </RowForm>
                    )}
                    <ModalFormLabel spaced>{findlanguage().attachment}</ModalFormLabel>
                    <AttachRow>
                        <DashboardButton dark small onClick={attach}>
                            {findlanguage().chooseFile}
                        </DashboardButton>
                        <AttachItem>
                            <AttachItemLink>
                                {fileSelected ? (
                                    <Link
                                        href={parseStrapiImage(fileSelected.url)}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        download={fileSelected.name}
                                    >
                                        {fileSelected.name}
                                    </Link>
                                ) : (
                                    findlanguage().noFileSelected
                                )}
                            </AttachItemLink>
                        </AttachItem>

                        <UploadHiddenInput onChange={showFileName} />
                    </AttachRow>

                    <RowBlankSpace />

                    <RowForm>
                        <Check
                            value={certPublic}
                            onChange={(e) => setCertPublic(e)}
                            checked={certPublic}
                            label={findlanguage().makeDocumentPublic}
                            nowidth
                        />
                    </RowForm>
                </ProfileFormContent>
                <PageFormSpace />
                <ProfileFormActions>
                    <Button primary onClick={action}>
                        {findlanguage().save}
                    </Button>
                </ProfileFormActions>
            </ModalWrapper>
        </>
    )
}
