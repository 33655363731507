import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import {
    PageHeader,
    PageHeaderImage,
    PageHeaderContent,
    ContentTitle,
    ContentSubtitle,
    ContentText,
    PageHeaderPercentage,
    PageHeaderPercentageText,
    ContentName,
    ContentAddress,
    ContentEmail,
    ContentContactPhone,
    PageHeaderContentData,
    ImageSeal,
    ImageSealOff,
} from './styled'

import { GetSupplierQuestionnarieGetOne } from 'services/egs'
import { FetchScoreRecomendation } from 'services/dashboard'
import { CreateNewSupplierReg } from 'services/sms'
import { SearchEmailExist } from 'services/validationProcess'

import DashboardButton from 'components/Dashboard/Button'
import DashboardMore from 'components/Dashboard/More'

import { parseStrapiImage } from 'utils'
import { CoreContext } from 'context/CoreContext'
import { NewConnection } from 'services/connections'
import { RemoveConnection } from 'services/connections'
import { NewWishlist } from 'services/wishlist'

export default function DashboardUserDetailsHeader({ profile, callback }) {
    const { setModal, premium, setNewChat, user, blurryTextField, needToBlockField, findlanguage } =
        useContext(CoreContext)
    const [percent, setPercent] = useState(0)

    const [validated, setValidated] = useState(false)

    const [showScore, setShowScore] = useState(false)

    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)
    const back = () => history.goBack()

    useEffect(() => {
        init()
    }, [])

    const scoreRecommendation = () => {
        FetchScoreRecomendation(profile.user.id).then((result) => {
            let score = 0

            if (!isNaN(result[0].final_score)) {
                score = parseInt(result[0].final_score)
            }

            setPercent(score)
        })
    }

    const init = async () => {
        let count = 0

        if (
            premium.type === 'premium' &&
            user.active_account?.type === 'buyer' &&
            profile.type !== 'buyer'
        ) {
            setShowScore(true)

            scoreRecommendation()
        }

        checkMFSValidated()
    }

    const addConnection = async () => {
        setModal({
            type: 'loading',
            text: findlanguage().creatingNewConnection,
        })

        const response = await NewConnection(profile.user.id)

        if (response.statusCode === 400)
            return setModal({
                type: 'alert',
                text: findlanguage().thieConnedctionExistsAlready,
                warning: true,
            })

        if (response.statusCode)
            return setModal({
                type: 'alert',
                text: findlanguage().failedToCreateConnection,
                warning: true,
            })

        setModal({
            type: 'alert',
            text: findlanguage().newConnectionCreated,
        })

        if (callback) callback()
    }

    const createNewChat = () => {
        let user_key = user.id
        if (user.team_access) {
            user_key = user.company_id
        }
        setNewChat({
            firstUserId: user_key,
            secondUserId: profile.user.id,
            newMessageUserOne: false,
            newMessageUserTwo: false,
            lastMessage: new Date().toISOString(),
        })
        navigate('dashboard/message')
    }

    const deleteConnection = () => {
        setModal({
            type: 'choose',
            title: findlanguage().doYouReallyWantToDisconect,
            options: [
                { title: findlanguage().no, action: () => setModal(null) },
                { title: findlanguage().yes, action: () => doDeleteConnection(), outline: true },
            ],
        })
    }

    const doDeleteConnection = async () => {
        setModal({
            type: 'loading',
            text: findlanguage().processing,
        })

        const response = await RemoveConnection(profile.connection[0].id)
        if (response.statusCode)
            return setModal({
                type: 'alert',
                text: 'Operation failed.',
                warning: true,
            })

        setModal(null)

        if (callback) callback()
    }

    const addWishlist = async () => {
        setModal({
            type: 'loading',
            text: findlanguage().creatingNewWishList,
        })

        const response = await NewWishlist(profile.user?.id)

        if (response.statusCode === 400)
            return setModal({
                type: 'alert',
                text: response.message,
                warning: true,
            })

        if (response.statusCode)
            return setModal({
                type: 'alert',
                text: findlanguage().failedToCreatedWishList,
                warning: true,
            })

        setModal({
            type: 'alert',
            text: findlanguage().newWishListCreated,
        })
    }

    const isConnectionPeding = () => {
        let pending = false

        if (profile.connection.length > 1) {
            if (
                profile.connection[0]?.acceptedInvitation.toUpperCase() === 'PENDING' &&
                profile.connection[1]?.acceptedInvitation.toUpperCase() === 'PENDING'
            )
                pending = true
        } else {
            pending = profile.connection[0]?.acceptedInvitation.toUpperCase() === 'PENDING'
        }

        return pending
    }

    const isConnected = () => {
        let connected = profile.connection[0]?.acceptedInvitation.toUpperCase() === 'ACCEPTED'

        if (profile.connection.length > 1) {
            if (profile.connection[1]?.acceptedInvitation.toUpperCase() === 'ACCEPTED')
                connected = true
        }

        return connected
    }

    const addToMyQMS = async () => {
        const form = profile.user

        let user_key = user.id
        if (user.team_access) {
            user_key = user.company_id
        }

        const result = await SearchEmailExist(user_key, form.email)

        if (result[0].total === '0') {
            if (form.id === undefined) {
                setModal({
                    type: 'alert',
                    warning: true,
                    text: findlanguage().nothingSelected,
                })
            } else {
                setModal({
                    type: 'loading',
                    text: findlanguage().processing,
                })

                form.users_permissions_user = form.id

                form.isUser = user_key

                const response = await CreateNewSupplierReg(form)

                if (!response)
                    return setModal({
                        type: 'alert',
                        warning: true,
                        text: 'Failed to create.',
                    })

                if (response.message === undefined) {
                    setModal({
                        type: 'alert',
                        text: findlanguage().requestSuccessful,
                    })
                } else {
                    return setModal({
                        type: 'alert',
                        warning: true,
                        text: response.message,
                    })
                }
            }
        } else {
            setModal({
                type: 'alert',
                warning: true,
                text: findlanguage().emailAlreadyRegisterSupplierList,
            })
        }
    }

    const moreOptions = () => {
        let options = []

        const connected = isConnected()

        if (premium.type === 'premium') {
            options = options.concat([
                { title: findlanguage().sendMessage, action: createNewChat },
                { title: findlanguage().addToWishList, action: addWishlist },
            ])
            if (connected) {
                options = options.concat([
                    { title: findlanguage().addToMyQMS, action: () => addToMyQMS() },
                ])
            }
        } else {
            if (connected) {
                options = options.concat([
                    { title: findlanguage().sendMessage, action: createNewChat },
                ])
            }
        }

        if (profile.connection.length > 0) {
            options = options.concat([
                { title: findlanguage().disconnect, action: () => deleteConnection() },
            ])
        }

        return options
    }

    const checkMFSValidated = async () => {
        const questionnarie = await GetSupplierQuestionnarieGetOne(profile.user?.id)

        if (questionnarie.length > 0) {
            const form = questionnarie[0]
            if (form?.form_status?.toUpperCase() === 'ACCEPTED')
                setValidated(
                    (parseInt(form.score_a) + parseInt(form.score_b) + parseInt(form.score_c)) / 3 <
                        90
                        ? false
                        : true
                )
        }
    }

    return (
        <>
            <PageHeader>
                <PageHeaderImage
                    image={
                        profile?.user?.avatar
                            ? parseStrapiImage(profile?.user?.avatar?.url)
                            : `/images/no-image.png`
                    }
                />

                <PageHeaderContentData>
                    <ContentName>
                        {profile?.user?.name}
                        {profile.preCheck === 'approved' ? (
                            <img
                                src="/icons/checked.svg"
                                alt="Approved"
                                style={{ marginLeft: '10px', height: '25px' }}
                            />
                        ) : null}
                        {validated ? <ImageSeal /> : ''}  {profile?.user?.role.id === 4 ? (
                            <span
                                className="badge"
                                style={{ backgroundColor: '#263640', color: 'white' }}
                            >
                                {findlanguage().supplierTitle}
                            </span>
                        ) : null}

                        {
                            profile?.user?.role.id === 3 ?
                                <span className="badge bg-primary">{findlanguage().buyerTitle}</span>
                                :
                                null
                        }
                        {
                            profile?.user?.role.id === 6 ?
                                <span
                                    className="badge"
                                    style={{ backgroundColor: '#263640', color: 'white' }}
                                >
                                    {findlanguage().buyerTitle} / {findlanguage().supplierTitle}
                                </span>
                                :
                                null
                        }
                        {
                            profile?.user?.role.id === 7 ?
                                <>
                                    <span
                                        className="badge"
                                        style={{ backgroundColor: '#4182ab', color: 'white' }}
                                    >
                                        {findlanguage().headquarters}
                                    </span>
                                </>
                                :
                                null
                        }

                        {profile?.user?.role.id === 5 ? (
                            <span
                                className="badge"
                                style={{ backgroundColor: '#ded514', color: 'white' }}
                            >
                                {findlanguage().serviceProviderTitle}
                            </span>
                        ) : null}
                        {
                            profile?.user?.status === 'approved' ?
                                <img src='/icons/checked.svg' alt='Approved' style={{ marginLeft: '10px', height: '25px' }} />
                                :
                                null
                        }

                    </ContentName>
                  
                    <ContentAddress>
                        {blurryTextField(
                            profile?.user?.address?.country && profile?.user?.address?.state
                                ? profile?.user?.address?.country +
                                      ', ' +
                                      profile?.user?.address?.state +
                                      ',  ' +
                                      profile?.user?.address?.street
                                : findlanguage().noAddress,

                            profile
                        )}
                    </ContentAddress>
                    <ContentEmail>
                        {blurryTextField(profile?.user?.email, profile)}
                        {profile?.user?.website ? ' - ' + profile?.user?.website : ''}
                    </ContentEmail>
                    <ContentContactPhone>
                        {profile?.user?.contactName}{' '}
                        {needToBlockField(profile) ? (
                            blurryTextField(profile?.user?.phone, profile, 'span')
                        ) : (
                            <>({profile?.user?.phone})</>
                        )}
                    </ContentContactPhone>
                </PageHeaderContentData>
                <PageHeaderContent row>
                    {profile.connection.length === 0 ? (
                        <>
                            <DashboardButton onClick={addConnection}>
                                {findlanguage().connect}
                            </DashboardButton>
                            {premium.type === 'premium' ? (
                                <DashboardMore options={moreOptions()} />
                            ) : (
                                ''
                            )}
                        </>
                    ) : isConnectionPeding() ? (
                        <>
                            <DashboardButton disabled>
                                {findlanguage().connectionPending}
                            </DashboardButton>
                            <DashboardMore options={moreOptions()} />
                        </>
                    ) : (
                        <>
                            <DashboardMore options={moreOptions()} />
                        </>
                    )}
                </PageHeaderContent>
                {showScore ? (
                    <>
                        <PageHeaderContent>
                            <PageHeaderPercentage>{percent} %</PageHeaderPercentage>
                            <PageHeaderPercentageText>
                                {findlanguage().recommended}
                            </PageHeaderPercentageText>
                        </PageHeaderContent>
                    </>
                ) : (
                    ''
                )}
            </PageHeader>
        </>
    )
}
