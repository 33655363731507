import React, { useEffect, useState, useContext } from 'react'
import { Doughnut } from 'react-chartjs-2'
import 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import DashboardPanel from 'components/Dashboard/Panels/Wrapper'
import { FetchCategoriesChart } from 'services/dashboard'
import { Load } from 'ui/styled'
import { CoreContext } from 'context/CoreContext'

export default function DashboardPanelSupplierPerCategory({ cardTitle, onClose }) {
    const { premium, language, findlanguage } = useContext(CoreContext)
    const panel = {
        title: findlanguage().supplierPerCategory,
        actions: [],
        onClose,
    }

    const [chartData, setChartData] = useState(null)
    const [chartOptions, setChartOptions] = useState(null)

    const [loading, setLoading] = useState(false)
    const [showWarning, setShowWarning] = useState(false)

    const checkAccess = () => premium.type === 'free'

    const loadSupplierPerCategory = () => {
        setLoading(true)

        FetchCategoriesChart().then((chart) => {
            if (!chart || chart.length === 0) {
                setChartData(null)
            } else {
                chart = chart.filter((a, i) => i < 5 && a.count > 0)

                const labels = []
                const data = []
                const backgroundColors = [
                    '#7189bf',
                    '#df7599',
                    '#ffc785',
                    '#72d6c9',
                    '#90d7ff',
                    '#3f8cb5',
                    '#ffe156',
                    '#3f88c5',
                ]

                chart.forEach((entry, i) => {
                    labels.push(language === 'portugues' ? entry.category_pt : entry.category)
                    data.push(parseInt(entry.count))
                })

                setChartData({
                    labels: labels,
                    datasets: [
                        {
                            data: data,
                            backgroundColor: backgroundColors.slice(0, data.length),
                            borderColor: '#ffffff',
                            borderWidth: 2,
                        },
                    ],
                })

                setChartOptions({
                    plugins: {
                        legend: {
                            position: 'right',
                            labels: {
                                font: {
                                    size: 14,
                                    family: 'Open Sans',
                                    weight: 'bold',
                                },
                                color: '#303030',
                            },
                            align: 'center',
                        },
                        datalabels: {
                            display: true,
                            color: 'white',
                            font: {
                                size: 16,
                                family: 'Open Sans',
                                weight: 'bold',
                            },
                            formatter: (value) => value,
                        },
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                })
            }

            setLoading(false)
        })
    }

    useEffect(() => {
        if (!checkAccess()) loadSupplierPerCategory()
    }, [])

    return (
        <>
            {loading ? (
                <Load color="#263640" />
            ) : (
                <DashboardPanel
                    {...panel}
                    subscription={premium?.type}
                    locked={checkAccess()}
                    showWarning={showWarning}
                >
                    {!chartData?.datasets?.length ? (
                        <div style={{ width: '100%' }}>{findlanguage().noData}</div>
                    ) : (
                        <div style={{ width: '95%', height: '270px' }}>
                            <Doughnut
                                data={chartData}
                                options={chartOptions}
                                plugins={[ChartDataLabels]}
                            />
                        </div>
                    )}
                </DashboardPanel>
            )}
        </>
    )
}
