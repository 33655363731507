import styled from 'styled-components'

export const ProfileFormContent = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    gap: 20px;
    justify-content: center;
    padding: 0px 0 20px;
    width: 100%;
    max-height: 60vh;
    overflow: auto;
`

export const ProfileFormActions = styled.div.attrs({})`
    display: flex;
    justify-content: center;
`
export const PageFormHeader = styled.div.attrs({})`
    width: 100%;
    padding: 20px 20px 0;
`
export const PageFormSpace = styled.div.attrs({})`
    margin-right: 12px;
`
export const RowFilters = styled.div.attrs({})`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 20px 20px;
`
export const FilterTitle = styled.div.attrs({})`
    font-size: 14px;
    color: var(--grey-color);
    margin-right: 20px;
`
