import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import Card from '../Card/index.js'

import { PageCard, CardHeader, CardHeaderTitleContainer } from './styled'
import _ from 'lodash'

export default function D21({
    certifications,
    onChangeCertifications,
    saveCertifications,
    saveDocument,
    form,
    returnHeaders,
    mfsQuestionarie
}) {
    const { findlanguageEsg } = useContext(CoreContext)

    //#region D.2.1
    const d21 = {
        ref: 'D.2.1',
        requirementTitle: findlanguageEsg().d21RequirementTitle,
        inputForYesAnswer: true,
        placeHolderTextYes: findlanguageEsg().d21PlaceHolderTextYes,
        inputForNoAnswer: false,
        placeHolderTextNo: findlanguageEsg().d21PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    //#region D.2.1 Yes

    //#region D.2.1.Y.A

    const d21ya = {
        ref: 'D.2.1.Y.A',
        requirementTitle: findlanguageEsg().d21yaRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().d21yaPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const d21yay = {
        ref: 'D.2.1.Y.A.Y',
        requirementTitle: findlanguageEsg().d21yayRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().d21yayPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const d21yayy = {
        ref: 'D.2.1.Y.A.Y.Y',
        requirementTitle: findlanguageEsg().d21yayyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().d21yayyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
    }

    const d21yayyql1 = {
        ref: 'D.2.1.Y.A.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().d21yayyqlRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().d21yayyqlPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        fileForYesAnswerAndNoInput: true,
        color: '#2f8c5d',
        question: true,
    }

    //#endregion

    //#region D.2.1.Y.B

    const d21yb = {
        ref: 'D.2.1.Y.B',
        requirementTitle: findlanguageEsg().d21ybRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().d21ybPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const d21yby = {
        ref: 'D.2.1.Y.B.Y',
        requirementTitle: findlanguageEsg().d21ybyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: findlanguageEsg().d21ybyPlaceHolderTextYes,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().d21ybyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const d21ybyyqt1 = {
        ref: 'D.2.1.Y.B.Y.Y.QT.1',
        requirementTitle: findlanguageEsg().d21ybyyqt1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const d21ybyyqt2 = {
        ref: 'D.2.1.Y.B.Y.Y.QT.2',
        requirementTitle: findlanguageEsg().d21ybyyqt2RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const d21ybyyqt3 = {
        ref: 'D.2.1.Y.B.Y.Y.QT.3',
        requirementTitle: findlanguageEsg().d21ybyyqt3RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const d21ybyyqt4 = {
        ref: 'D.2.1.Y.B.Y.Y.QT.4',
        requirementTitle: findlanguageEsg().d21ybyyqt4RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const d21ybyyqtn = {
        ref: 'D.2.1.Y.B.Y.Y.QT.N',
        requirementTitle: findlanguageEsg().d21ybyyqtnRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const d21ybyyql1 = {
        ref: 'D.2.1.Y.B.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().d21ybyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().d21ybyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.C

    const d21yc = {
        ref: 'D.2.1.Y.C',
        requirementTitle: findlanguageEsg().d21ycRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().d21ycPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const d21ycy = {
        ref: 'D.2.1.Y.C.Y',
        requirementTitle: findlanguageEsg().d21ycyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().d21ycylaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const d21ycyy = {
        ref: 'D.2.1.Y.C.Y.Y',
        requirementTitle: findlanguageEsg().d21ycyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().d21ycyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const d21ycyyql1 = {
        ref: 'D.2.1.Y.C.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().d21ycyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().d21ycyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.D

    const d21yd = {
        ref: 'D.2.1.Y.D',
        requirementTitle: findlanguageEsg().d21ydRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().d21ydPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const d21ydy = {
        ref: 'D.2.1.Y.D.Y',
        requirementTitle: findlanguageEsg().d21ydyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().d21ydyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const d21ydyy = {
        ref: 'D.2.1.Y.D.Y.Y',
        requirementTitle: findlanguageEsg().d21ydyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().d21ydyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const d21ydyyql1 = {
        ref: 'D.2.1.Y.D.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().d21ydyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().d21ydyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#endregion

    //#region A.1.1 NO
    const d21na = {
        ref: 'D.2.1.N.A',
        requirementTitle: findlanguageEsg().d21naRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().d21naPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const d21nb = {
        ref: 'D.2.1.N.B',
        requirementTitle: findlanguageEsg().d21nbRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().d21nbPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const d21nc = {
        ref: 'D.2.1.N.C',
        requirementTitle: findlanguageEsg().d21ncRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().d21ncPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    const returnCondition = (item, layers, category1, category2, category3, category4) => {
        switch (layers) {
            case 0:
                return (
                    <Card
                        item={item}
                        onChangeCertifications={onChangeCertifications}
                        saveCertifications={saveCertifications}
                        saveDocument={saveDocument}
                        certifications={certifications}
                        form={form}
                        mfsQuestionarie={mfsQuestionarie}
                    />
                )
            case 1:
                if (certifications?.find((x) => x.category === category1)?.next_level === true)
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 2:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 3:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 4:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true &&
                    certifications?.find((x) => x.category === category4)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            default:
                break
        }
    }

    return (
        <>
            <PageCard>
                <CardHeader>
                    <CardHeaderTitleContainer>{findlanguageEsg().d21CardHeaderTitleContainer}</CardHeaderTitleContainer>
                </CardHeader>
                {returnHeaders()}
                {returnCondition(d21, 0)}
            </PageCard>
            {
                mfsQuestionarie !== '1' ?
                    <>

                        {certifications?.find((x) => x.category === 'D.2.1')?.next_level === true ? (
                            <>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            D.2.1 FOREST PRESERVATION - Answer Yes - A
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(d21ya, 1, 'D.2.1')}
                                    {returnCondition(d21yay, 2, 'D.2.1', 'D.2.1.Y.A')}
                                    {returnCondition(d21yayy, 3, 'D.2.1', 'D.2.1.Y.A', 'D.2.1.Y.A.Y')}
                                    {returnCondition(
                                        d21yayyql1,
                                        4,
                                        'D.2.1',
                                        'D.2.1.Y.A',
                                        'D.2.1.Y.A.Y',
                                        'D.2.1.Y.A.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            D.2.1 FOREST PRESERVATION - Answer Yes - B
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(d21yb, 1, 'D.2.1')}
                                    {returnCondition(d21yby, 2, 'D.2.1', 'D.2.1.Y.B')}
                                    {returnCondition(d21ybyyqt1, 3, 'D.2.1', 'D.2.1.Y.B', 'D.2.1.Y.B.Y')}
                                    {returnCondition(d21ybyyqt2, 3, 'D.2.1', 'D.2.1.Y.B', 'D.2.1.Y.B.Y')}
                                    {returnCondition(d21ybyyqt3, 3, 'D.2.1', 'D.2.1.Y.B', 'D.2.1.Y.B.Y')}
                                    {returnCondition(d21ybyyqt4, 3, 'D.2.1', 'D.2.1.Y.B', 'D.2.1.Y.B.Y')}
                                    {returnCondition(d21ybyyqtn, 3, 'D.2.1', 'D.2.1.Y.B', 'D.2.1.Y.B.Y')}
                                    {returnCondition(d21ybyyql1, 3, 'D.2.1', 'D.2.1.Y.B', 'D.2.1.Y.B.Y')}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            D.2.1 FOREST PRESERVATION - Answer Yes - C
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(d21yc, 1, 'D.2.1')}
                                    {returnCondition(d21ycy, 2, 'D.2.1', 'D.2.1.Y.C')}
                                    {returnCondition(d21ycyy, 3, 'D.2.1', 'D.2.1.Y.C', 'D.2.1.Y.C.Y')}
                                    {returnCondition(
                                        d21ycyyql1,
                                        4,
                                        'D.2.1',
                                        'D.2.1.Y.C',
                                        'D.2.1.Y.C.Y',
                                        'D.2.1.Y.C.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            D.2.1 FOREST PRESERVATION - Answer Yes - D
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(d21yd, 1, 'D.2.1')}
                                    {returnCondition(d21ydy, 2, 'D.2.1', 'D.2.1.Y.D')}
                                    {returnCondition(d21ydyy, 3, 'D.2.1', 'D.2.1.Y.D', 'D.2.1.Y.D.Y')}
                                    {returnCondition(
                                        d21ydyyql1,
                                        4,
                                        'D.2.1',
                                        'D.2.1.Y.D',
                                        'D.2.1.Y.D.Y',
                                        'D.2.1.Y.D.Y.Y'
                                    )}
                                </PageCard>
                            </>
                        ) : null}
                        {certifications?.find((x) => x.category === 'D.2.1')?.next_level === false ? (
                            <PageCard>
                                <CardHeader>
                                    <CardHeaderTitleContainer>
                                        D.2.1 FOREST PRESERVATION - Answer No
                                    </CardHeaderTitleContainer>
                                </CardHeader>
                                {returnHeaders()}
                                {returnCondition(d21na, 0)}
                                {returnCondition(d21nb, 0)}
                                {returnCondition(d21nc, 0)}
                            </PageCard>
                        ) : null}
                    </>
                    :
                    null
            }
        </>
    )
}
