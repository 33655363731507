import React, { useContext, useState, useEffect } from 'react';
import { PageCard, CardLineContainer, CardHeader } from './styled';
import Elements from '../SharedComponent/Elements/index';
import { CoreContext } from 'context/CoreContext';
import { GetAssignedStepForm } from 'services/steps';
import _ from 'lodash';
import GridSections from './GridSections/index';

export default function Form() {
    const { findlanguage } = useContext(CoreContext);
    const [editingForm, setEditingForm] = useState(null);
    const [assignedForms, setAssignedForms] = useState([]);

    const getForm = async () => {
        const assignedStep = await GetAssignedStepForm();

        assignedStep.forEach((x) => {
            x.expanded = false;
            x.sectionExpanded = false;
        });
        setAssignedForms(assignedStep);
    };

    const openCloseDiv = (company) => {
        setAssignedForms(prevForms =>
            prevForms.map(form =>
                form.company_id.name === company ? { ...form, expanded: !form.expanded } : form
            )
        );
    };

    const openCloseSection = (formId) => {
        setAssignedForms(prevForms =>
            prevForms.map(form =>
                form.id === formId ? { ...form, sectionExpanded: !form.sectionExpanded } : form
            )
        );
    };

    const groupedForms = _.groupBy(assignedForms, 'company_id.name');

    const getButtonLabel = (form) => {
        if (form.status === 'complete') {
            return 'Complete';
        }
        if (form.canOpen && form.status === 'rejected') {
            return 'Rejected';
        }

        if (form.canOpen) {
            return 'Edit';
        }
        if (form.status === 'open') {
            return 'Waiting on Someone Else';
        }
        return 'Nothing Else to Do';
    };

    const getStatusColor = (form) => {
        if (form.status === 'closed') {
            return '#808080';
        }
        if (form.status === 'complete') {
            return '#8dbd64';
        }

        if (form.canOpen && form.status === 'rejected') {
            return '#dc3545';
        }
        if (form.canOpen) {
            return '#516d7f';
        }
        return '#ffcc00';
    };

    const handleEdit = (form) => {
        if (form.canOpen) {
            setEditingForm(form);
        }
    };

    const handleBack = () => {
        setEditingForm(null);
    };

    useEffect(() => {
        getForm();
    }, []);

    return (
        <>
            {editingForm ? (
                <Elements handleBack={handleBack} form={editingForm} getForm={getForm} />
            ) : (
                <>
                    {assignedForms?.length === 0 ? (
                        <div
                            style={{
                                fontSize: '16px',
                                fontWeight: 'bolder',
                                color: 'black',
                                cursor: 'pointer',
                                marginTop: '60px',
                            }}
                        >
                            Nothing to do for now.
                        </div>
                    ) : (
                        Object.keys(groupedForms).map(company => (
                            <PageCard key={company} style={{ marginBottom: '20px' }}>
                                <CardLineContainer>
                                    <div>
                                        {
                                            groupedForms[company].filter(
                                                (x) =>
                                                    x.canOpen === true
                                            ).length !== 0 ?
                                                <button
                                                    type="button"
                                                    style={{
                                                        backgroundColor: '#263640',
                                                        color: 'white',
                                                        width: '206px',
                                                        marginTop: '-17px',
                                                        cursor: 'default',
                                                    }}
                                                    className="btn position-relative"
                                                >
                                                    {findlanguage().pendingAction}
                                                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                        {
                                                            groupedForms[company].filter(
                                                                (x) =>
                                                                    x.canOpen === true
                                                            ).length
                                                        }
                                                    </span>
                                                </button>
                                                :
                                                null
                                        }
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div
                                                style={{
                                                    fontSize: '30px',
                                                    fontWeight: 'bolder',
                                                    color: 'black',
                                                    cursor: 'pointer',
                                                    marginLeft: '10px',
                                                    width: '50px'
                                                }}
                                                onClick={() => openCloseDiv(company)}
                                            >
                                                {groupedForms[company][0]?.expanded ? '-' : '+'}
                                            </div>
                                            <CardHeader>{company}</CardHeader>
                                        </div>
                                    </div>
                                </CardLineContainer>
                                <div
                                    style={{
                                        display: groupedForms[company][0]?.expanded ? '' : 'none',
                                        overflow: 'auto',
                                        maxHeight: '800px',
                                    }}
                                >
                                    <table
                                        style={{
                                            width: '100%',
                                            borderCollapse: 'collapse',
                                            marginTop: '20px',
                                        }}
                                    >
                                        <thead>
                                            <tr style={{ backgroundColor: '#4f6b7d', color: 'white' }}>
                                                <th style={{ padding: '10px', textAlign: 'left' }}>Expand</th>
                                                <th style={{ padding: '10px', textAlign: 'left' }}>Action</th>
                                                <th style={{ padding: '10px', textAlign: 'left' }}>Type of Requirements</th>
                                                <th style={{ padding: '10px', textAlign: 'left' }}>Form Name</th>
                                                <th style={{ padding: '10px', textAlign: 'left' }}>Status</th>
                                                <th style={{ padding: '10px', textAlign: 'left' }}>Reason Rejected</th>
                                                <th style={{ padding: '10px', textAlign: 'left' }}>Expiration Date</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ borderTop: '2px solid #fff' }}>
                                            {groupedForms[company].map(form => (
                                                <React.Fragment key={form.id}>
                                                    <tr key={form.id} style={{ borderBottom: '1px solid #ccc' }}>
                                                        <td style={{ padding: '10px', cursor: 'pointer' }} onClick={() => openCloseSection(form.id)}>
                                                            {form.sectionExpanded ? '-' : '+'}
                                                        </td>
                                                        <td style={{ padding: '10px' }}>
                                                            <span
                                                                className="badge"
                                                                style={{
                                                                    fontSize: '13px',
                                                                    cursor: form.canOpen ? 'pointer' : 'not-allowed',
                                                                    backgroundColor: getStatusColor(form),
                                                                    color: form.status === 'open' && !form.canOpen ? 'black' : 'white'
                                                                }}
                                                                onClick={() => handleEdit(form)}
                                                            >
                                                                {getButtonLabel(form)}
                                                            </span>
                                                        </td>
                                                        <td style={{ padding: '10px' }}>{form.step_form_id.is_active ? 'Active' : 'Inactive'}</td>
                                                        <td style={{ padding: '10px' }}>{form.step_form_id.name}</td>
                                                        <td style={{ padding: '10px' }}>
                                                            <div style={{
                                                                width: '20px',
                                                                height: '20px',
                                                                borderRadius: '50%',
                                                                backgroundColor: getStatusColor(form),
                                                                margin: '0 auto',
                                                                float: 'left'
                                                            }}></div>
                                                        </td>
                                                        <td style={{ padding: '10px' }}>{form.reason_rejected}</td>
                                                        <td style={{ padding: '10px' }}>{form.expired_date}</td>
                                                    </tr>
                                                    {form.sectionExpanded && (
                                                        <tr className="section-row">
                                                            <td colSpan="7" className="section-container">
                                                                <GridSections sections={form.sections} currentStep={form.current_step} />
                                                            </td>
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </PageCard>
                        ))
                    )}
                </>
            )}
        </>
    );
}
