import styled from 'styled-components'

export const ChatHeader = styled.div.attrs({})`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px solid var(--shadow-color);
    padding: 0 0 20px;
`
export const ChatHeaderUser = styled.div.attrs({})`
    display: flex;
    align-items: center;
    cursor: pointer;
`
export const ChatHeaderUserImage = styled.div.attrs({})`
    width: 62px;
    height: 62px;
    border-radius: 31px;
    background-color: var(--lightshadow-color);
    background: url(/images/user.png) no-repeat center center / cover;
    background-size: 62px;
    margin-right: 20px;
`
export const ChatHeaderUserText = styled.div.attrs({})`
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--primary-color);
`

export const ChatBody = styled.div.attrs({})`
    height: 400px;
    overflow: auto;
    padding: 30px 0 0;
    display: flex;
    flex-direction: column;
`
export const ChatMessage = styled.div.attrs({})`
    max-width: 70%;
    margin-bottom: 24px;
    ${(props) =>
        props.mine
            ? `
            align-self: flex-end;
        `
            : `
            align-self: flex-start;
        `}
`
export const ChatMessageBaloon = styled.div.attrs({})`
    padding: 12px 15px;
    border-radius: 15px;
    font-size: 16px;
    color: var(--white-color);
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    margin-bottom: 12px;
    ${(props) =>
        props.mine
            ? `
            background: var(--primary-color);
        `
            : `
            background: var(--chatbaloon-color);
        `}
`
export const ChatMessageTime = styled.div.attrs({})`
    font-size: 13px;
    color: var(--lightgrey-color);
    margin-left: 15px;
`

export const ChatInputContainer = styled.div.attrs({})`
    display: flex;
    padding-top: 20px;
`
export const ChatInputInput = styled.input.attrs({})`
    height: 62px;
    flex: 1;
    margin-right: 10px;
    border-radius: 8px;
    border: 0.5px solid var(--lightgrey-color);
    padding: 0 15px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    outline: none;
    &::placeholder {
        color: var(--lightgrey-color);
    }
`
export const ChatButton = styled.div.attrs({})`
    width: 62px;
    height: 62px;
    border-radius: 8px;
    background: var(--primary-color);
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        box-shadow: 2px 2px 3px var(--shadow-color);
    }
`
export const ChatButtonIcon = styled.img.attrs({
    src: '/icons/sent.svg',
    width: 24,
})``
