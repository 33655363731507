import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import Card from '../Card/index.js'

import { PageCard, CardHeader, CardHeaderTitleContainer } from './styled'
import _ from 'lodash'

export default function C21({
    certifications,
    onChangeCertifications,
    saveCertifications,
    saveDocument,
    form,
    returnHeaders,
    mfsQuestionarie
}) {
    const { findlanguageEsg } = useContext(CoreContext)

    //#region C.2.1
    const c21 = {
        ref: 'C.2.1',
        requirementTitle: findlanguageEsg().c21RequirementTitle,
        inputForYesAnswer: true,
        placeHolderTextYes: findlanguageEsg().c21PlaceHolderTextYes,
        inputForNoAnswer: false,
        placeHolderTextNo: findlanguageEsg().c21PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    //#region C.2.1 Yes

    //#region C.2.1.Y.A

    const c21ya = {
        ref: 'C.2.1.Y.A',
        requirementTitle: findlanguageEsg().c21yaRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c21yaPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const c21yay = {
        ref: 'C.2.1.Y.A.Y',
        requirementTitle: findlanguageEsg().c21yayRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c21yayPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const c21yayy = {
        ref: 'C.2.1.Y.A.Y.Y',
        requirementTitle: findlanguageEsg().c21yayyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c21yayyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
    }

    const c21yayyql1 = {
        ref: 'C.2.1.Y.A.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().c21yayyyqlRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c21yayyqlPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        fileForYesAnswerAndNoInput: true,
        color: '#2f8c5d',
        question: true,
    }

    //#endregion

    //#region C.2.1.Y.B

    const c21yb = {
        ref: 'C.2.1.Y.B',
        requirementTitle: findlanguageEsg().c21ybRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c21ybPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const c21yby = {
        ref: 'C.2.1.Y.B.Y',
        requirementTitle: findlanguageEsg().c21ybyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: findlanguageEsg().c21ybyPlaceHolderTextYes,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c21ybyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const c21ybyyqt1 = {
        ref: 'C.2.1.Y.B.Y.Y.QT.1',
        requirementTitle: findlanguageEsg().c21ybyyqt1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const c21ybyyqt2 = {
        ref: 'C.2.1.Y.B.Y.Y.QT.2',
        requirementTitle: findlanguageEsg().c21ybyyqt2RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const c21ybyyqtn = {
        ref: 'C.2.1.Y.B.Y.Y.QT.N',
        requirementTitle: findlanguageEsg().c21ybyyqtnRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const c21ybyyql1 = {
        ref: 'C.2.1.Y.B.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().c21ybyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c21ybyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.C

    const c21yc = {
        ref: 'C.2.1.Y.C',
        requirementTitle: findlanguageEsg().c21ycRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c21ycPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const c21ycy = {
        ref: 'C.2.1.Y.C.Y',
        requirementTitle: findlanguageEsg().c21ycyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c21ycyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const c21ycyy = {
        ref: 'C.2.1.Y.C.Y.Y',
        requirementTitle: findlanguageEsg().c21ycyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c21ycyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const c21ycyyql1 = {
        ref: 'C.2.1.Y.C.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().c21ycyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c21ycyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.D

    const c21yd = {
        ref: 'C.2.1.Y.D',
        requirementTitle: findlanguageEsg().c21ydRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c21ydPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const c21ydy = {
        ref: 'C.2.1.Y.D.Y',
        requirementTitle: findlanguageEsg().c21ydyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c21ydyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const c21ydyy = {
        ref: 'C.2.1.Y.D.Y.Y',
        requirementTitle: findlanguageEsg().c21ydyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c21ydyyPlaceHolderTextNo,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const c21ydyyql1 = {
        ref: 'C.2.1.Y.D.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().c21ydyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c21ydyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#endregion

    //#region A.1.1 NO
    const c21na = {
        ref: 'C.2.1.N.A',
        requirementTitle: findlanguageEsg().c21naRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c21naPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const c21nb = {
        ref: 'C.2.1.N.B',
        requirementTitle: findlanguageEsg().c21nbRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c21nbPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const c21nc = {
        ref: 'C.2.1.N.C',
        requirementTitle: findlanguageEsg().c21ncRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().c21ncPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    const returnCondition = (item, layers, category1, category2, category3, category4) => {
        switch (layers) {
            case 0:
                return (
                    <Card
                        item={item}
                        onChangeCertifications={onChangeCertifications}
                        saveCertifications={saveCertifications}
                        saveDocument={saveDocument}
                        certifications={certifications}
                        form={form}
                        mfsQuestionarie={mfsQuestionarie}
                    />
                )
                break
            case 1:
                if (certifications?.find((x) => x.category === category1)?.next_level === true)
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 2:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 3:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 4:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true &&
                    certifications?.find((x) => x.category === category4)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            default:
                break
        }
    }

    return (
        <>
            <PageCard>
                <CardHeader>
                    <CardHeaderTitleContainer>
                        {findlanguageEsg().c21CardHeaderTitleContainer}
                    </CardHeaderTitleContainer>
                </CardHeader>
                {returnHeaders()}
                {returnCondition(c21, 0)}
            </PageCard>
            {
                mfsQuestionarie !== '1' ?
                    <>

                        {certifications?.find((x) => x.category === 'C.2.1')?.next_level === true ? (
                            <>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            C.2.1 REUSE AND RECYCLING (WATER) - Answer Yes - A
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(c21ya, 1, 'C.2.1')}
                                    {returnCondition(c21yay, 2, 'C.2.1', 'C.2.1.Y.A')}
                                    {returnCondition(c21yayy, 3, 'C.2.1', 'C.2.1.Y.A', 'C.2.1.Y.A.Y')}
                                    {returnCondition(
                                        c21yayyql1,
                                        4,
                                        'C.2.1',
                                        'C.2.1.Y.A',
                                        'C.2.1.Y.A.Y',
                                        'C.2.1.Y.A.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            C.2.1 REUSE AND RECYCLING (WATER) - Answer Yes - B
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(c21yb, 1, 'C.2.1')}
                                    {returnCondition(c21yby, 2, 'C.2.1', 'C.2.1.Y.B')}
                                    {returnCondition(c21ybyyqt1, 3, 'C.2.1', 'C.2.1.Y.B', 'C.2.1.Y.B.Y')}
                                    {returnCondition(c21ybyyqt2, 3, 'C.2.1', 'C.2.1.Y.B', 'C.2.1.Y.B.Y')}
                                    {returnCondition(c21ybyyqtn, 3, 'C.2.1', 'C.2.1.Y.B', 'C.2.1.Y.B.Y')}
                                    {returnCondition(c21ybyyql1, 3, 'C.2.1', 'C.2.1.Y.B', 'C.2.1.Y.B.Y')}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            C.2.1 REUSE AND RECYCLING (WATER) - Answer Yes - C
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(c21yc, 1, 'C.2.1')}
                                    {returnCondition(c21ycy, 2, 'C.2.1', 'C.2.1.Y.C')}
                                    {returnCondition(c21ycyy, 3, 'C.2.1', 'C.2.1.Y.C', 'C.2.1.Y.C.Y')}
                                    {returnCondition(
                                        c21ycyyql1,
                                        4,
                                        'C.2.1',
                                        'C.2.1.Y.C',
                                        'C.2.1.Y.C.Y',
                                        'C.2.1.Y.C.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            C.2.1 REUSE AND RECYCLING (WATER) - Answer Yes - D
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(c21yd, 1, 'C.2.1')}
                                    {returnCondition(c21ydy, 2, 'C.2.1', 'C.2.1.Y.D')}
                                    {returnCondition(c21ydyy, 3, 'C.2.1', 'C.2.1.Y.D', 'C.2.1.Y.D.Y')}
                                    {returnCondition(
                                        c21ydyyql1,
                                        4,
                                        'C.2.1',
                                        'C.2.1.Y.D',
                                        'C.2.1.Y.D.Y',
                                        'C.2.1.Y.D.Y.Y'
                                    )}
                                </PageCard>
                            </>
                        ) : null}
                        {certifications?.find((x) => x.category === 'C.2.1')?.next_level === false ? (
                            <PageCard>
                                <CardHeader>
                                    <CardHeaderTitleContainer>
                                        C.2.1 REUSE AND RECYCLING (WATER) - Answer No
                                    </CardHeaderTitleContainer>
                                </CardHeader>
                                {returnHeaders()}
                                {returnCondition(c21na, 0)}
                                {returnCondition(c21nb, 0)}
                                {returnCondition(c21nc, 0)}
                            </PageCard>
                        ) : null}
                    </>
                    :
                    null
            }
        </>
    )
}
