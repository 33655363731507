import React, { useEffect } from 'react'

import Header from 'components/Dashboard/Header'

import { DashboardPage, DashboardBody, DashboardBodyContent, Content } from './styled'
import { ReadObject } from 'services/storage'
import { useHistory } from 'react-router-dom'
import { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import Cookies from 'js-cookie'

export default function ContainerAuthenticated({ type, children, full }) {
    const { setPremium, checkPremium, profile } = useContext(CoreContext)
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const init = () => {
        const jwt = Cookies.get('auth_token')

        if (profile === 'admin') {
            type = 'admin'
        }

        if (!jwt) {
            navigate('login')
        } else {
            checkPremium()
        }
    }

    useEffect(() => {
        init()
    }, [])

    return (
        <>
            <Content>
                <DashboardPage>
                    <Header adminView={type === 'admin'} />
                    <DashboardBody>
                        <DashboardBodyContent full={full}>{children}</DashboardBodyContent>
                    </DashboardBody>
                </DashboardPage>
            </Content>
        </>
    )
}
