import React, { useEffect, useState, useContext } from 'react'
import { CoreContext } from 'context/CoreContext'

import {
    ProfileFormContent,
    RowForm,
    ModalFormContent,
    ModalFormLabel,
    CheckContent,
} from './styled'

import Input from 'components/Form/Input'

import Select from 'components/Form/Select'
import CheckMany from 'components/Form/CheckMany'
import { ReadCertificationsAndApprovals, ReadCompanyCategories } from 'services/infos'

export default function FormAddProduct({ form, setForm }) {
    const { findlanguage } = useContext(CoreContext)
    const formValue = (ref) => {
        return form?.[ref] ? form?.[ref] : ''
    }
    const changeForm = (value, ref) => {
        setForm({ ...form, [ref]: value })
    }

    const checkCertification = (v, item, subItem) => {
        if (subItem) delete subItem.id
        let update = [...(form.certifications || [])]
        if (
            subItem &&
            update.find((cert) => cert.items?.find((sub) => sub.label === subItem.label))
        ) {
            // ! remove sub item from form
            const insert = update.find((cert) => cert.title === item)
            insert.items = insert.items.filter((sub) => sub.label !== subItem.label)
            update = update.map((entry) => {
                if (entry.title === insert.title) return insert
                else return entry
            })
            // ! if there are no sub itens left, remove  item too
            if (insert.items.length < 1) update = update.filter((cert) => cert.title !== item)
        } else if (!subItem && update.find((cert) => cert.title === item)) {
            // ! remove item
            update = update.filter((cert) => cert.title !== item)
        } else if (subItem && update.find((cert) => cert.title === item)) {
            // * add to form
            const insert = update.find((cert) => cert.title === item)
            insert.items.push(subItem)
            update = update.map((entry) => {
                if (entry.title === insert.title) return insert
                else return entry
            })
        } else if (subItem) {
            update.push({ title: item, items: [subItem] })
        } else {
            update.push({ title: item })
        }
        changeForm(update, 'certifications')
    }
    const [categories, setCategories] = useState()
    const [certifications, setCertifications] = useState([])
    useEffect(() => {
        ReadCompanyCategories().then((resp) => setCategories(resp))
        ReadCertificationsAndApprovals().then((resp) => {
            const apply = resp.map((certification) => {
                const change = { ...certification }
                change.items = certification.items.map((item) => {
                    const updateItem = { ...item }
                    updateItem.title = item.label
                    return updateItem
                })
                return change
            })
            setCertifications(apply)
        })
    }, [])

    return (
        <>
            <ProfileFormContent>
                <Input
                    placeholder="Product Name"
                    big
                    value={formValue('name')}
                    onChange={(e) => changeForm(e.target.value, 'name')}
                />

                <ModalFormLabel spaced>{findlanguage().productDescription}</ModalFormLabel>
                <Input
                    type={'textarea'}
                    big
                    placeholder="Description"
                    value={formValue('description')}
                    onChange={(e) => changeForm(e.target.value, 'description')}
                    maxLength={800}
                />

                <ModalFormLabel>{findlanguage().productOverview}</ModalFormLabel>
                <RowForm>
                    <ModalFormContent>
                        <Select
                            value={formValue('category')}
                            onChange={(item) => changeForm(item, 'category')}
                            options={categories}
                            placeholder="Category"
                        />
                    </ModalFormContent>
                </RowForm>
                <ModalFormLabel spaced>{findlanguage().findlanguage}</ModalFormLabel>

                <RowForm>
                    {certifications.map((cert, key) => (
                        <CheckContent key={key}>
                            <CheckMany
                                title={cert.title}
                                label={cert.description}
                                options={cert.items}
                                value={formValue('certifications')}
                                onChange={(v, item, subItem) =>
                                    checkCertification(v, item, subItem)
                                }
                            />
                        </CheckContent>
                    ))}
                </RowForm>
            </ProfileFormContent>
        </>
    )
}
