import styled from 'styled-components'
import ReactLoading from 'react-loading'

export const ModalHeader = styled.div.attrs({})`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const ModalHeaderSpace = styled.div.attrs({})`
    min-width: 16px;
`
export const ModalHeaderClose = styled.img.attrs((props) => ({
    src: props.min ? '/icons/minimize.svg' : '/icons/close.svg',
    width: 16,
}))`
    cursor: pointer;
`

export const ModalBody = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ModalTitle = styled.div.attrs({})`
    font-size: 21px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--primary-color);
    margin-bottom: 14px;
    max-width: 292px;
    text-align: center;
`
export const ModalDescription = styled.div.attrs({})`
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--lightgrey-color);
    margin-bottom: 12px;
    b {
        font-size: 16px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        color: var(--grey-color);
    }
`
export const ModalLoad = styled(ReactLoading).attrs({
    type: 'spin',
    color: 'var(--primary-color)',
    height: 36,
    width: 36,
})`
    margin-bottom: 20px;
`
