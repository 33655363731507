import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Select from 'react-select';

export default function SectionsToUploadTheUser({ sections, setSections, currentStep, setCurrentStep, is_draft, userAssignments, handleUserAssignmentChange, fakeUsers }) {
    const changeStepIcon = (index, newIcon) => {
        const newSteps = sections.map((step, sIndex) => {
            if (sIndex === index) {
                return { ...step, icon: newIcon };
            }
            return step;
        });
        setSections(newSteps);
    };

    const iconNames = {
        'gear': 'Settings',
        'check2-circle': 'Confirm',
        'x-circle': 'Cancel',
        'question-circle': 'Help',
        'exclamation-triangle': 'Warning',
        'plus-circle': 'Add',
        'dash-circle': 'Remove',
        'info-circle': 'Information',
        'house': 'Home',
        'pencil-square': 'Edit',
        'envelope': 'Mail',
        'star': 'Favorite',
        'lock': 'Security',
        'clock': 'Time',
        'trash': 'Delete',
        'file-earmark-text': 'Document',
        'camera': 'Camera',
        'heart': 'Love',
        'bell': 'Notification',
        'bookmark': 'Bookmark',
    };

    const availableIcons = Object.keys(iconNames).sort((a, b) => {
        const nameA = iconNames[a].toUpperCase();
        const nameB = iconNames[b].toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    const options = availableIcons.map(icon => ({
        value: icon,
        label: (<div><i className={`bi bi-${icon}`}></i> {iconNames[icon]}</div>)
    }));

    const customStyles = {
        control: (base) => ({
            ...base,
            border: 'none',
            boxShadow: 'none',
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, width: '300px', })
    };

    return (
        <div className="steps-scroll-container-no-overflow">
            <div className="steps-container">
                {sections.map((step, index) => (
                    <React.Fragment key={index}>
                        <div className={`step ${index === currentStep ? 'active' : ''}`}>
                            <div className="step-icon-and-delete">
                                <div className="step-icon" onClick={() => setCurrentStep(index)}>
                                    <i className={`bi bi-${step.icon}`}></i>
                                </div>
                            </div>
                            <div className="step-title">{step.name}</div>
                            <Select
                                value={options.find(option => option.value === step.icon)}
                                onChange={(e) => changeStepIcon(index, e.value)}
                                options={options}
                                styles={customStyles}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                className="step-select step-icon-select"
                                isDisabled={true}
                            />
                            <div style={{ marginTop: '5px' }}></div>
                            <Select
                                isMulti
                                options={fakeUsers}
                                onChange={(selectedOptions) => handleUserAssignmentChange(step.id, selectedOptions)}
                                value={fakeUsers.filter(user => userAssignments[step.id]?.includes(user.value))}
                                styles={customStyles}
                                className="step-select step-users-select"
                            />
                        </div>
                        {index < sections.length - 1 && <div className="step-line"></div>}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}
