import React, { useEffect, useState, useContext } from 'react'

import { MarkerClusterer } from '@googlemaps/markerclusterer'

import {
    Content,
    MapContent,
    MapContainer,
    ChartContent,
    ChartItem,
    ChartItemBar,
    ChartItemText,
    MapBlocker,
} from './styled'

import DashboardPanel from 'components/Dashboard/Panels/Wrapper'
import { FetchMapChart } from 'services/dashboard'
import { CoreContext } from 'context/CoreContext'
import { Load } from 'ui/styled'

class CustomCluster {
    render({ count, position }, stats) {
        const color = count > Math.max(10, stats.clusters.markers.mean) ? '#263640' : '#263640'

        const svg = window.btoa(` 
            <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240"> 
                <circle cx="120" cy="120" opacity="1" r="110" />
            </svg>`)

        return new window.google.maps.Marker({
            position,
            icon: {
                url: `data:image/svg+xml;base64,${svg}`,
                scaledSize: new window.google.maps.Size(45, 45),
            },
            label: { text: String(count), color: 'rgba(255,255,255,0.9)', fontSize: '12px' },
            title: `Cluster of ${count} `,
            zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
        })
    }
}

const markersAround = (lat, lng, counter) => {
    const markers = []
    for (let vy = 0; vy < counter; vy++) {
        markers.push({ lat: lat, lng: lng })
    }
    return markers
}

const SuppMap = ({ chartData }) => {
    const ref = React.useRef(null)
    const [map, setMap] = React.useState()
    const [begined, setBegined] = React.useState(false)

    const beginMarkers = () => {
        setBegined(true)

        const infoWindow = new window.google.maps.InfoWindow({
            content: '',
            disableAutoPan: true,
        })

        const labels = '1'

        const locations = []
        chartData.forEach((point) => {
            if (point.lat !== null && point.lng !== null) {
                locations.push(...markersAround(point.lat, point.lng, point.value))
            }
        })

        const svg = window.btoa(` 
            <svg fill="#263640" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240"> 
                <circle cx="120" cy="120" opacity="1" r="110" />
            </svg>`)

        const markers = locations.map((position, i) => {
            const label = labels[i % labels.length]
            const marker = new window.google.maps.Marker({
                position,
                label: { text: label, color: 'rgba(255,255,255,0.9)', fontSize: '12px' },
                icon: {
                    url: `data:image/svg+xml;base64,${svg}`,
                    scaledSize: new window.google.maps.Size(45, 45),
                },
            })

            marker.addListener('click', () => {
                infoWindow.setContent(label)
                infoWindow.open(map, marker)
            })
            return marker
        })

        // Add a marker clusterer to manage the markers.
        new MarkerClusterer({ markers, map, renderer: new CustomCluster() })
    }

    React.useEffect(() => {
        if (ref.current && !map) {
            setMap(
                new window.google.maps.Map(ref.current, {
                    center: { lat: 30, lng: 10 },
                    zoom: 1.2,
                    mapId: '6ecab42694885a',
                    disableDefaultUI: true,
                    draggable: false,
                    zoomControl: false,
                    scrollwheel: false,
                    disableDoubleClickZoom: true,
                })
            )
        }

        if (map && !begined) {
            beginMarkers()
        }
    }, [ref, map])
    return <MapContent ref={ref} />
}

export default function DashboardPanelSupplierMap({ cardTitle, onClose }) {
    const { premium, findlanguage } = useContext(CoreContext)
    const panel = {
        title: findlanguage().supplierMap,
        actions: [],
        onClose,
        big: true,
    }

    const [locked, setLocked] = useState(true)
    const [loading, setLoading] = useState(false)

    const checkAccess = () => {
        let result = true
        if (premium.type !== 'free') {
            result = false
        }
        return result
    }

    const getProgress = (item) => {
        const max = Math.max.apply(
            Math,
            chartData.map((mit) => mit.value)
        )
        return parseInt((100 * item.value) / max)
    }

    const [chartData, setChartData] = useState()

    const loadMap = () => {
        setLoading(true)
        FetchMapChart().then((chart) => {
            const graph = chart.map((entry) => ({
                label: entry.continent_name,
                value: entry.qtd_countries,
                color: '#263640',
                lat: entry.lat,
                lng: entry.lng,
            }))
            setChartData(
                graph.sort((a, b) => (a.label === 'Others' ? 2 : a.label > b.label ? 1 : -1))
            )
            setLoading(false)
        })
    }

    useEffect(() => {
        if (!checkAccess()) loadMap()
    }, [])

    return (
        <>
            {loading ? (
                <Load color="#263640" />
            ) : (
                <DashboardPanel {...panel} subscription={premium?.type} locked={checkAccess()}>
                    {chartData?.length === 0 || chartData === undefined ? (
                        <>
                            <div style={{ width: '100%' }}>{findlanguage().noData}</div>
                        </>
                    ) : (
                        <>
                            <Content>
                                <MapContainer>
                                    <SuppMap chartData={chartData} />
                                    <MapBlocker />
                                </MapContainer>
                                <ChartContent>
                                    {chartData.map((item, key) => (
                                        <ChartItem key={key}>
                                            <ChartItemText islabel>{item.label}</ChartItemText>
                                            <ChartItemBar
                                                progress={getProgress(item)}
                                                color={item.color}
                                            />
                                            <ChartItemText>{parseInt(item.value)}</ChartItemText>
                                        </ChartItem>
                                    ))}
                                </ChartContent>
                            </Content>
                        </>
                    )}
                </DashboardPanel>
            )}
        </>
    )
}
