import React from 'react'

import {
    ContentTextarea,
    TextareaLabel,
    TextareaInputContent,
    TextareaInput,
    TextareaLength,
} from './styled'

export default function Textarea({ label, value, onChange, maxLength, bigger, medium }) {
    return (
        <>
            <ContentTextarea bigger={bigger} medium={medium}>
                {label ? <TextareaLabel>{label}</TextareaLabel> : null}
                <TextareaInputContent>
                    <TextareaInput
                        maxLength={maxLength}
                        value={value}
                        onChange={onChange}
                        bigger={bigger}
                        medium={medium}
                    />
                    {maxLength !== undefined ? (
                        <TextareaLength>
                            {value.length}/{maxLength}
                        </TextareaLength>
                    ) : null}
                </TextareaInputContent>
            </ContentTextarea>
        </>
    )
}
