import styled from 'styled-components'
import { mobileSize } from 'ui/styled'

export const Content = styled.div.attrs({})``

export const ContentScroll = styled.div.attrs({})`
    overflow: auto;
    max-height: 250px;
    width: 100%;
`

export const ScrollWrapper = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    @media (max-width: ${mobileSize}px) {
        flex-direction: column;
    }
`
