import styled from 'styled-components'

export const ContentTitle = styled.div.attrs({})`
    font-size: 22px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--grey-color);
`

export const PageBody = styled.div.attrs({})`
    padding: 20px 40px;
    border-radius: 15px;
    background: var(--white-color);
    box-shadow: 0px 1.5px 6px var(--lightshadow-color);
    margin-bottom: 20px;
`

export const BodySubtitle = styled.div.attrs({})`
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--primary-color);
    margin: 5px 0 15px;
`
export const BodyText = styled.div.attrs({})`
    font-size: 16px;
    color: #5c5c5c;
`

export const BodyItem = styled.div.attrs({})`
    font-size: 16px;
    color: #5c5c5c;
    font-weight: 500;
`

export const ApproveContent = styled.div.attrs({})`
    display: flex;
    align-items: center;
`
export const ApproveCheck = styled.div.attrs({})`
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background: var(--primary-color);
    margin-right: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
`
export const ApproveCheckIcon = styled.img.attrs({
    src: '/icons/check.svg',
    width: 10,
})``

export const DocumentItem = styled.div.attrs({})`
    width: 153px;
    min-height: 120px;
    border-radius: 8px;

    border: 0.5px solid var(--backgroundlightgrey-color);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const DocumentIcon = styled.img.attrs({
    src: '/icons/document.svg',
})`
    margin-bottom: 12px;
`
export const DocumentTitle = styled.div.attrs({})`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: var(--grey-color);
`

export const DocumentWrapper = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    gap: 20px;
`

export const Link = styled.a.attrs({})`
    color: var(--black-color) !important;

    &:hover {
        color: var(--black-color) !important;
    }

    &:active {
        color: var(--black-color) !important;
    }

    &:visited {
        color: var(--black-color) !important;
    }
`
