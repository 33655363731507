import styled from 'styled-components'

export const Descriptor = styled.div.attrs({})`
    font-size: 16px;
    color: #959595;
    text-align: center;
`

export const Division = styled.div.attrs({})`
    ${(props) => `
        margin-bottom: ${props.space || 12}px
    `}
`

export const ComponentDivision = styled.div.attrs({})`
    background: #f1f1f1;
    padding: 16px;
    ${(props) => `
        flex: ${props.size || '.3'};
        margin-bottom: ${props.space || 12}px;
    `}
`

export const CardButton = styled.div.attrs({})`
    background: var(--white-color);
    padding: 16px 24px;
    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
    box-shadow: 0 3px 10px #00000015;
    display: flex;
    align-items: center;
    cursor: pointer;
`

export const PageTitle = styled.div.attrs({})`
    font-size: 17px;
    color: #303030;
    font-weight: 600;
`

export const ProcessesContainer = styled.div.attrs({})`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`

export const PageCard = styled.div.attrs({})`
    background: var(--white-color);
    padding: 24px;
    margin-top: 48px;
    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
`

export const PlusGreenIcon = styled.img.attrs({
    src: '/icons/circle-add-green.svg',
})`
    width: 16px;
    height: 16px;
    margin-right: 8px;
`

export const TextGreenIcon = styled.img.attrs({
    src: '/icons/green-text-fields.svg',
})`
    width: 16px;
    height: 16px;
    margin-right: 8px;
`

export const DropdownGreenIcon = styled.img.attrs({
    src: '/icons/green-dropdown.svg',
})`
    width: 16px;
    height: 16px;
    margin-right: 8px;
`

export const CheckboxGreenIcon = styled.img.attrs({
    src: '/icons/green-checkbox.svg',
})`
    width: 16px;
    height: 16px;
    margin-right: 8px;
`

export const DateGreenIcon = styled.img.attrs({
    src: '/icons/green-calendar.svg',
})`
    width: 16px;
    height: 16px;
    margin-right: 8px;
`

export const AttachGreenIcon = styled.img.attrs({
    src: '/icons/green-attach.svg',
})`
    width: 16px;
    height: 16px;
`

export const GreenImageIcon = styled.img.attrs({
    src: '/icons/green-image-icon.svg',
})`
    width: 16px;
    height: 16px;
`

export const CloseIcon = styled.img.attrs({
    src: '/icons/close-white.svg',
})`
    width: 8px;
    height: 8px;
`

export const CloseGray = styled.img.attrs({
    src: '/icons/close.svg',
})`
    width: 8px;
    height: 8px;
    cursor: pointer;
`

export const GreenCheck = styled.img.attrs({
    src: '/icons/check.svg',
})`
    width: 8px;
    height: 8px;
`

export const Dropdown = styled.img.attrs({
    src: '/icons/dropdown.svg',
})`
    width: 16px;
    height: 16px;
`

export const AddIcon = styled.img.attrs({
    src: '/icons/add.svg',
})`
    width: 8px;
    height: 8px;
`

export const IconButton = styled.div.attrs({})`
    ${(props) => `
        background: ${props.color || '#959595'};
    `}
    border-radius: 99px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

export const DropdownPlusButton = styled.div.attrs({})`
    background: #263640;
    border-radius: 99px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    bottom: 8px;
    right: 8px;
`

export const CheckboxPlusButton = styled.div.attrs({})`
    background: #263640;
    border-radius: 99px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

export const ComponentTitle = styled.div.attrs({})`
    font-size: 16px;
    color: #959595;
    margin-bottom: 16px;
`

export const FakeInputTitle = styled.div.attrs({})`
    font-size: 13px;
    color: #959595;
    flex: 1;
`

export const ComponentTitleInput = styled.input.attrs({})`
    border-radius: 8px;
    border: 1px solid #959595;
    padding: 8px 16px;
    font-size: 18px;
    width: 30vw;
    min-width: 200px;
    font-weight: 600;
    color: #303030;
`

export const ComponentSubTitleInput = styled.input.attrs({})`
    border-radius: 8px;
    border: 1px solid #959595;
    padding: 8px 16px;
    font-size: 16px;
    width: 25vw;
    min-width: 180px;
    font-weight: 600;
    color: #505050;
`

export const ComponentDescriptionInput = styled.textarea.attrs({})`
    border-radius: 8px;
    border: 1px solid #959595;
    padding: 8px 16px;
    font-size: 15px;
    width: 100%;
`

export const ComponentInput = styled.input.attrs({})`
    border-radius: 8px;
    border: 1px solid #959595;
    padding: 8px 16px;
    font-size: 15px;
`

export const ComponentGrid = styled.div.attrs({})`
    ${(props) => `
        gap: ${props.space || 24}px;
    `}
    display: flex;
    flex-wrap: wrap;
`

export const FakeInput = styled.div.attrs({})`
    width: 100%;
    height: 46px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    border-bottom: 2px solid #aaaaaa;
    cursor: pointer;
`

export const FakeOption = styled.div.attrs({})`
    ${(props) => `
        ${
            props.green
                ? `
                background: #263640;
                color: #ffffff;
            `
                : `
                border: 1px solid #707070;
                color: #707070;
            `
        }
    `}
    margin-top: 16px;
    border-radius: 99px;
    width: 128px;
    padding: 6px 12px;
    text-align: center;
    cursor: pointer;
`

export const DropDownWindow = styled.div.attrs({})`
    border-radius: 4px;
    border: 1px solid #70707035;
    padding: 8px;
    display: grid;
    gap: 8px;
    position: relative;
`

export const DropDownItem = styled.input.attrs({})`
    color: #333;
    font-size: 13px;
    font-weight: 600;
    border: 0;
    ouline: 0;
    max-width: 128px;
`
