import React from 'react'

import { FilterAction, FilterActionText, FilterActionBadge } from './styled'

export default function DashboardFilterAction({ title, outline, badge, onClick }) {
    return (
        <>
            <FilterAction outline={outline} onClick={onClick}>
                <FilterActionText outline={outline}>{title}</FilterActionText>
                {!badge ? null : <FilterActionBadge outline={outline}>{badge}</FilterActionBadge>}
            </FilterAction>
        </>
    )
}
