import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import {
    ProfileFormContent,
    ProfileFormActions,
    PageFormSpace,
    RowForm,
    ModalFormContent,
    ModalFormLabel,
    Label,
    LabelLeft,
    UserProfileProgressActions,
    RadioButton,
    RadioButtonLabel,
    RadioHeader,
    RadioContent,
    RadioButtonCircle,
    RadioHeaderLabel,
    IconInfo
} from './styled'

import Input from 'components/Form/Input'
import Button from 'components/Form/Button'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

import Select from 'components/Form/Select'
import { useEffect } from 'react'
import { ReadCountries } from 'services/location'
import { CreateNewSupplierReg } from 'services/sms'
import DashboardButton from 'components/Dashboard/Button'
import { SearchUsers } from 'services/filters'
import {
    SearchNewServiceProviders,
    SearchNoUserSuppliers,
    SearchEmailExist,
    SearchUserEmailExist,
} from 'services/validationProcess'

export default function ModalAddSupplierInfo() {
    const [userFilter, setUserFilter] = useState(true)
    const { profile, setModal2, user, findlanguage, modal, setModal, language } =
        useContext(CoreContext)

    const [form, setForm] = useState({})
    const formValue = (ref) => {
        return form?.[ref] ? form?.[ref] : ''
    }
    const changeForm = async (value, ref) => {
        if (typeof value === 'object') {
            value.name = value.title
            setForm(value)
        } else if (!userFilter) setForm({ ...form, [ref]: value })
    }

    const showHighlight = (name) => {
        const field = fieldValidation.find((item) => item.field === name)
        if (field !== undefined) return true
        return false
    }

    const [serviceTypeOptions, setServiceTypeOptions] = useState([])
    const [serviceTypeNoUserOptions, setServiceTypeNoUserOptions] = useState([])
    const [fieldValidation, setFieldValidation] = useState([])

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [country, setCountry] = useState('')
    const [accountType, setAccountType] = useState('service-provider')
    const [checked, setChecked] = useState(true)

    // *********************************************************

    const [countryAC, setCountryAC] = useState('')
    const [countrySearch, setCountrySearch] = useState('')

    const clearCountry = () => {
        setCountry('')
    }

    const handleOnSelectCountry = (item) => {
        setCountry(item.name)
        setCountryAC(item.name)
    }

    const formatResult = (item) => {
        return (
            <>
                <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

    // *********************************************************

    useEffect(async () => {
        const result = await SearchNewServiceProviders(user.company_id)

        setServiceTypeOptions(result)
    }, [])

    useEffect(async () => {
        const result = await SearchNoUserSuppliers()

        setServiceTypeNoUserOptions(result[0])
    }, [])

    const valid = () => {
        setFieldValidation([])
        let errors = []

        if (name.length === 0) {
            errors.push({
                field: 'name',
                message: findlanguage().fillTheField + findlanguage().companyName,
            })
        } else if (name.length < 2) {
            errors.push({
                field: 'name',
                message: findlanguage().errorContactName,
            })
        }

        if (email.length === 0) {
            errors.push({
                field: 'email',
                message: findlanguage().fillTheField + findlanguage().email,
            })
        } else if (
            !email.match(
                /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
            )
        ) {
            errors.push({ field: 'email', message: findlanguage().errorEmail })
        }

        if (phone.length === 0) {
            errors.push({
                field: 'phone',
                message: findlanguage().fillTheField + findlanguage().phone,
            })
        } else if (phone.length > 0) {
            if (!/^\+(?:\d[-.\s]?){6,14}\d$/.test(phone)) {
                errors.push({
                    field: 'phone',
                    message: findlanguage().errorPhone,
                })
            }
        }

        if (country.length === 0) {
            errors.push({
                field: 'country',
                message: findlanguage().fillTheField + findlanguage().country,
            })
        }

        if (accountType.length === 0) {
            errors.push({
                field: 'accountType',
                message: findlanguage().fillTheField + findlanguage().accountType,
            })
        }

        if (errors.length > 0) {
            let message = ''
            message = findlanguage().someErrorsFound
            errors.forEach((item) => {
                message += '\n - ' + item.message
            })
            toast.error(message)
            setFieldValidation(errors)
            return false
        }

        return true
    }

    const requestRegister = async () => {
        if (!userFilter) {
            if (!valid()) {
                return false
            }

            const result = await SearchEmailExist(user.company_id, email)

            if (result[0].total === '0') {
                const refreshFunction = modal.refresh
                // setModal({
                //     type: 'loading',
                //     text: findlanguage().requestNewSupplier,
                // })

                form.name = name
                form.email = email
                form.phone = phone
                form.country = country
                form.type = accountType
                form.company_id = user.company_id
                form.isUser = false
                form.is_company_visible = checked

                //console.log('form', form)

                const response = await CreateNewSupplierReg(form)

                //console.log('form', form, response)

                if (!response || response?.error) {
                    toast.error(findlanguage().systemError)
                    return
                }

                if (response.message === undefined) {
                    refreshFunction()
                    setModal({
                        type: 'alert',
                        text: findlanguage().inviteSent,
                    })
                } else {
                    return setModal({
                        type: 'alert',
                        warning: true,
                        text: response.message,
                    })
                }
            } else {
                setModal({
                    type: 'alert',
                    warning: true,
                    text: findlanguage().thisEmailOrPhoneAlreadyExistsAlready,
                })
            }
        } else {
            const result = await SearchEmailExist(user.company_id, form.email)

            if (result[0].total === '0') {
                if (userFilter && form.id === undefined) {
                    setModal({
                        type: 'alert',
                        warning: true,
                        text: findlanguage().nothingSelected,
                    })
                } else {
                    const refreshFunction = modal.refresh
                    setModal({
                        type: 'loading',
                        text: findlanguage().requestNewSupplier,
                    })
                    if (userFilter) form.users_permissions_user = form.id

                    form.name = form.title
                    form.isUser = userFilter

                    const response = await CreateNewSupplierReg(form)

                    if (!response)
                        return setModal({
                            type: 'alert',
                            warning: true,
                            text: 'Failed to create.',
                        })

                    if (response.message === undefined) {
                        refreshFunction()
                        setModal({
                            type: 'alert',
                            text: findlanguage().requestSuccessful,
                        })
                    } else {
                        return setModal({
                            type: 'alert',
                            warning: true,
                            text: response.message,
                        })
                    }
                }
            } else {
                setModal({
                    type: 'alert',
                    warning: true,
                    text: findlanguage().thisEmailOrPhoneAlreadyExistsAlready,
                })
            }
        }
    }

    const [countryList, setCountryList] = useState([])
    useEffect(() => {
        ReadCountries().then((r) =>
            setCountryList(
                language === 'portugues'
                    ? r
                        .sort((a, b) => (a.name_pt > b.name_pt) - (a.name_pt < b.name_pt))
                        .map((item) => ({
                            name: item.name_pt,
                            title: item.name_pt,
                            iso2: item.iso2,
                        }))
                    : r
                        .sort((a, b) => (a.name > b.name) - (a.name < b.name))
                        .map((item) => ({
                            name: item.name,
                            title: item.name,
                            iso2: item.iso2,
                        }))
            )
        )
    }, [])

    const changeSupplierInformation = () => {
        setUserFilter(!userFilter)
        setForm({})
    }

    const handleOnChange = () => {
        setChecked(!checked)
    }

    return (
        <>
            <ModalWrapper>
                <div style={{ marginTop: '10px' }}>
                    <UserProfileProgressActions>
                        <DashboardButton
                            icon={userFilter ? 'check' : ''}
                            dark
                            outline={!userFilter}
                            onClick={changeSupplierInformation}
                        >
                            {findlanguage().registerUsers}
                        </DashboardButton>
                        <DashboardButton
                            icon={userFilter ? '' : 'check'}
                            dark
                            outline={userFilter}
                            onClick={changeSupplierInformation}
                        >
                            {findlanguage().nonRegisterUsers}
                        </DashboardButton>
                    </UserProfileProgressActions>
                </div>
                {userFilter ? (
                    <>
                        <Input
                            placeholder={findlanguage().nameNonMandatory}
                            value={form?.title}
                            outline
                            onKeyUp={(e) => changeForm(e.target.value, 'title')}
                            onChange={(e) => changeForm(e.target.value, 'title')}
                            typeahead={serviceTypeOptions}
                        />
                        {form?.id !== undefined ? (
                            <ProfileFormContent>
                                <RowForm>
                                    <ModalFormContent>
                                        <Input
                                            big
                                            placeholder={findlanguage().address}
                                            disabled
                                            value={form?.street}
                                        />
                                    </ModalFormContent>
                                    <ModalFormContent>
                                        <Input
                                            placeholder={findlanguage().city}
                                            disabled
                                            value={form?.city}
                                        />
                                    </ModalFormContent>
                                </RowForm>

                                <RowForm>
                                    <ModalFormContent>
                                        <Input
                                            placeholder={findlanguage().state}
                                            disabled
                                            value={form?.state}
                                        />
                                    </ModalFormContent>
                                    <ModalFormContent>
                                        <Input
                                            placeholder={findlanguage().zip}
                                            disabled
                                            value={form?.zipCode}
                                        />
                                    </ModalFormContent>
                                </RowForm>

                                <RowForm>
                                    <ModalFormContent>
                                        <Input
                                            placeholder={findlanguage().countryNonMandatory}
                                            disabled
                                            value={form?.country}
                                        />
                                    </ModalFormContent>
                                    <ModalFormContent>
                                        <Input
                                            placeholder={findlanguage().phone}
                                            disabled
                                            value={form?.phone}
                                        />
                                    </ModalFormContent>
                                </RowForm>

                                <RowForm>
                                    <ModalFormContent>
                                        <Input
                                            placeholder={findlanguage().emailNonMandatory}
                                            disabled
                                            value={form?.email}
                                        />
                                    </ModalFormContent>
                                    <ModalFormContent></ModalFormContent>
                                </RowForm>
                            </ProfileFormContent>
                        ) : null}
                    </>
                ) : (
                    <>
                        <ProfileFormContent>
                            <RowForm>
                                <Label>{findlanguage().nonRegisterUsersLabel}</Label>
                            </RowForm>

                            <RowForm>
                                <ModalFormContent>
                                    <Input
                                        big
                                        placeholder={findlanguage().companyName}
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        highlight={showHighlight('name')}
                                    />
                                </ModalFormContent>
                            </RowForm>

                            <RowForm>
                                <ModalFormContent>
                                    <Input
                                        placeholder={findlanguage().email}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        highlight={showHighlight('email')}
                                    />
                                </ModalFormContent>

                                <ModalFormContent>
                                    <Input
                                        placeholder={findlanguage().phoneNumber}
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        highlight={showHighlight('phone')}
                                    />
                                </ModalFormContent>
                            </RowForm>

                            <RowForm>
                                <ModalFormContent>
                                    {/* <Select
                                        placeholder={findlanguage().country}
                                        onChange={(e) => setCountry(e)}
                                        small
                                        value={country}
                                        style={{
                                            marginTop: '15px',
                                            width: '240px',
                                        }}
                                        options={countryList}
                                        highlight={showHighlight('country')}
                                    /> */}
                                    <div style={{ width: 500, marginBottom: 10 }}>
                                        <label
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '15px',
                                                color: showHighlight('country') ? 'red' : '#000',
                                            }}
                                        >
                                            {findlanguage().country}
                                        </label>
                                        <ReactSearchAutocomplete
                                            items={countryList}
                                            showNoResultsText={findlanguage().noResultsFilter}
                                            formatResult={formatResult}
                                            onSelect={handleOnSelectCountry}
                                            onSearch={clearCountry}
                                            onClear={clearCountry}
                                            onFocus={() => {
                                                setCountrySearch(countrySearch.trim())
                                            }}
                                            placeholder={findlanguage().placeholderFilter}
                                            inputSearchString={countrySearch}
                                            showIcon={true}
                                            showItemsOnFocus={true}
                                            maxResults={4}
                                            fuseOptions={{
                                                includeScore: true,
                                                includeMatches: true,
                                                threshold: 0.2,
                                                location: 0,
                                                distance: 100,
                                                maxPatternLength: 32,
                                                minMatchCharLength: 1,
                                                keys: ['name'],
                                            }}
                                            styling={{
                                                height: '35px',
                                                borderRadius: '0px',
                                                backgroundColor: '#FFF',
                                                boxShadow: 'none',
                                                hoverBackgroundColor: '#EAEAEA',
                                                color: '#263640',
                                                fontSize: '15px',
                                                iconColor: '#263640',
                                                lineColor: '#EAEAEA',
                                                placeholderColor: '#cccccc',
                                                clearIconMargin: '3px 8px 0 0',
                                                zIndex: 11,
                                            }}
                                        />
                                    </div>
                                </ModalFormContent>
                            </RowForm>

                            <RowForm>
                                <ModalFormContent style={{ width: '250px', marginTop: '20px' }}>
                                    <LabelLeft highlight={showHighlight('accountType')}>
                                        {findlanguage().accountType}*
                                    </LabelLeft>

                                    <RadioHeader>
                                        {profile === 'service-provider' ? (
                                            <>
                                                <RadioContent>
                                                    <RadioButton
                                                        type="radio"
                                                        name="radio"
                                                        value="buyer"
                                                        onChange={(e) =>
                                                            setAccountType(e.target.value)
                                                        }
                                                        highlight={showHighlight('accountType')}
                                                    ></RadioButton>
                                                    <RadioButtonCircle></RadioButtonCircle>
                                                </RadioContent>
                                                <RadioButtonLabel>
                                                    {findlanguage().buyerTitle}
                                                </RadioButtonLabel>
                                            </>
                                        ) : (
                                            ''
                                        )}

                                        {profile === 'buyer' || profile === 'service-provider' ? (
                                            <>
                                                <RadioContent>
                                                    <RadioButton
                                                        type="radio"
                                                        name="radio"
                                                        value="supplier"
                                                        onChange={(e) =>
                                                            setAccountType(e.target.value)
                                                        }
                                                        highlight={showHighlight('accountType')}
                                                    ></RadioButton>
                                                    <RadioButtonCircle></RadioButtonCircle>
                                                </RadioContent>
                                                <RadioButtonLabel>
                                                    {findlanguage().supplierTitle}
                                                </RadioButtonLabel>
                                            </>
                                        ) : (
                                            ''
                                        )}

                                        <RadioContent>
                                            <RadioButton
                                                type="radio"
                                                name="radio"
                                                value="service-provider"
                                                onChange={(e) => setAccountType(e.target.value)}
                                                highlight={showHighlight('accountType')}
                                            ></RadioButton>
                                            <RadioButtonCircle></RadioButtonCircle>
                                        </RadioContent>
                                        <RadioButtonLabel>
                                            {findlanguage().serviceProviderTitle}
                                        </RadioButtonLabel>
                                        <div className="custom-control custom-switch">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="customSwitches"
                                                checked={checked}
                                                onChange={handleOnChange}
                                                style={{ cursor: 'pointer' }} // Inline style for cursor
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="customSwitches"
                                                style={{ cursor: 'pointer' }}
                                            >
                                                    {checked ? 'Visible' : 'Not Visible'}
                                                    <IconInfo
                                                        title={findlanguage().visibleIcon}
                                                        style={{ width: '16px', marginLeft: '4px', marginTop: '-1px' }}
                                                    />
                                            </label>
                                        </div>
                                    </RadioHeader>
                                </ModalFormContent>
                            </RowForm>
                        </ProfileFormContent>
                    </>
                )}

                <PageFormSpace />
                <ProfileFormActions>
                    <Button primary onClick={requestRegister}>
                        {findlanguage().addSupplier}
                    </Button>
                </ProfileFormActions>
            </ModalWrapper>
        </>
    )
}
