import React from 'react'
import TopCountriesComponent from './TopCountriesComponent/index'
import CustomerSatisfaction from './CustomerSatisfaction/index'

const Box = ({ dataForTopCountries, whichBox, loading }) => {
    return (
        <div className="box" style={{ textAlign: loading ? 'center' : '' }}>
            {loading ? (
                <span className="loader"></span>
            ) : (
                <>
                    {dataForTopCountries.length !== 0 && whichBox === 1 ? (
                        <TopCountriesComponent dataForTopCountries={dataForTopCountries} />
                    ) : null}
                    {whichBox === 2 ? (
                        <CustomerSatisfaction dataForTopCountries={dataForTopCountries} />
                    ) : null}
                </>
            )}
        </div>
    )
}

export default Box
