import styled from 'styled-components'

export const FormTitle = styled.div.attrs({})`
    color: var(--primary-color);
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    padding-bottom: 0px;
`

export const SelectManyContainer = styled.div.attrs({})`
    display: flex;
    margin-bottom: 60px;
`

export const SelectContainer = styled.div.attrs({})`
    position: relative;
    width: 100%;
    max-width: 340px;
    margin-top: 5px;
    margin-right: 40px;
`
export const SelectHeader = styled.div.attrs({})`
    min-height: 46px;
    border-bottom: 0.5px solid var(--lightgrey-color);
    display: flex;
    cursor: pointer;
`
export const SelectHeaderContent = styled.div.attrs({})`
    flex: 1;
    display: flex;
    flex-wrap: wrap;
`
export const SelectHeaderText = styled.div.attrs({})`
    font-size: 15px;
    color: var(--grey-color);
    align-self: center;
`

export const SelectHeaderBadge = styled.div.attrs({})`
    border-radius: 30px;
    background: var(--white-color);
    padding: 0 2px;
    display: flex;
    align-items: center;
    margin: 5px 10px 5px 0;
`
export const SelectHeaderBadgeText = styled.div.attrs({})`
    font-size: 15px;
    color: var(--grey-color);
    margin-right: 12px;
`
export const SelectHeaderBadgeIcon = styled.img.attrs({
    src: '/icons/close.svg',
})`
    cursor: pointer;
`

export const SelectHeaderIcon = styled.img.attrs({
    src: '/icons/chevron.svg',
})`
    cursor: pointer;

    ${(props) =>
        !props.opened
            ? ``
            : `
            transform: rotate(180deg);
        `}
`
export const SelectBody = styled.div.attrs({})`
    left: 0;
    right: 0;
    padding: 0px 20px 0;
    background: var(--white-color);
    border: 1px solid var(--shadow-color);
    transition: all 0.3s ease;
    height: fit-content;
    max-height: 260px;
    overflow: auto;

    ${(props) =>
        props.opened
            ? ``
            : `
        border: 0px;
        height: 0px;
        padding: 0px;
        overflow: auto;
    `};
`
export const SelectItem = styled.div.attrs({})`
    margin: 0 0 25px;
`
export const SelectManyActionIcon = styled.img.attrs({
    src: '/icons/add.svg',
})`
    margin-right: 12px;
`
export const SelectManyText = styled.div.attrs({})``

export const SelectFilter = styled.div.attrs({})`
    margin-top: -10px;
`
