import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import Input from 'components/Form/Input'
import Button from 'components/Form/Button'
import InputPassword from 'components/Form/InputPassword'

import {
    FormCentred,
    PasswordValidator,
    PasswordValidatorTitle,
    PasswordValidatorContent,
    PasswordValidatorItem,
} from './styled'

import ContainerUnauthenticated from 'containers/Unauthenticated'
import { DoResetPassword } from 'services/authentication'
import { exposeStrapiError } from 'utils'
import RegisterHeader from 'components/Register/Header'

export default function CreatePassword() {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const params = new URLSearchParams(window.location.search)
    const [loading, setLoading] = useState(false)

    const [form, setForm] = useState({})
    const formValue = (ref) => {
        return form?.[ref] ? form?.[ref] : ''
    }
    const changeForm = (value, ref) => {
        setForm({ ...form, [ref]: value })
    }

    const valid = (verbose = false) => {
        if (!formValue('password') || !formValue('password').length) {
            if (verbose) {
                toast.error('Fill the field: New password')
            }
            return false
        }

        if (!formValue('cpassword') || !formValue('cpassword').length) {
            if (verbose) {
                toast.error('Fill the field: Create new password')
            }
            return false
        }

        if (formValue('password') !== formValue('cpassword')) {
            if (verbose) {
                toast.error('New password and confirm new password not match')
            }
            return false
        }

        return true
    }

    const action = async () => {
        if (!valid(true)) {
            return
        }
        setLoading(true)

        const result = await DoResetPassword({
            code: params.get('code'),
            password: formValue('password'),
            passwordConfirmation: formValue('cpassword'),
        })

        setLoading(false)
        if (result && !exposeStrapiError(result)) {
            completNext()
        }
    }

    const completNext = () => {
        toast.success('Password Changed')
        navigate('login')
    }

    const defaultErrorMsg =
        'Password must have at least 8 characters, include at least 1 Capital letter, 1 number and 1 special character (!@#$%^&*)'
    const [passErrorMsg, setPassErrorMsg] = useState(defaultErrorMsg)

    const handleInputChange = (event) => {
        changeForm(event.target.value, 'password')
        validateInput(event)
    }

    const validateInput = (event) => {
        const fields = [
            {
                name: 'password',
                value: formValue('password'),
                message: defaultErrorMsg,
            },
        ]

        const fullRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{7,})')
        // const sizeRegex = new RegExp('^(?=.{8,})')
        // const specialCharRegex = new RegExp('^(?=.*[!@#$%^&*])')
        // const numbersRegex = new RegExp('^(?=.*[0-9])')
        // const capitalLetterRegex = new RegExp('^(?=.*[A-Z])')

        const isValid = fields.some((field) => {
            if (!fullRegex.test(event.target.value)) {
                setPassErrorMsg(field.message)
                //passwordRef.current.focus()
                return true
            } else {
                setPassErrorMsg('')
            }
            return false
        })
        return isValid
    }

    return (
        <>
            <ContainerUnauthenticated>
                <RegisterHeader title={'Create New Password'} backable />
                <FormCentred>
                    <div className="password-info" style={{ width: '650px' }}>
                        {passErrorMsg}
                    </div>

                    {/* Only First field has Password Strength Meter */}
                    <InputPassword
                        placeholder="New Password"
                        value={formValue('password')}
                        onChange={handleInputChange}
                        small
                    />

                    <div style={{ width: '303px' }}>
                        <Input
                            type={'password'}
                            placeholder="Confirm Password"
                            value={formValue('cpassword')}
                            onChange={(e) => changeForm(e.target.value, 'cpassword')}
                            small
                        />
                        <p></p>
                    </div>

                    <Button primary loading={loading} onClick={action} small>
                        Set New Password{' '}
                    </Button>
                </FormCentred>
            </ContainerUnauthenticated>
        </>
    )
}
