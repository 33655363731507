import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const CardSearched = styled.div.attrs({})`
    min-width: calc(33.33% - 12.5px);
    max-width: calc(33.33% - 12.5px);
    background: var(--white-color);
    padding: 24px;
    margin-bottom: 25px;
    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);

    display: flex;
    align-items: center;

    @media (max-width: ${mobileSize}px) {
        min-width: calc(100%);
        max-width: calc(100%);
    }
`
export const CardImage = styled.div.attrs({})`
    width: 76px;
    height: 76px;
    border-radius: 38px;
    ${(props) =>
        props.url
            ? `
    background: url(${props.url}) no-repeat center center / cover;
    background-size: 76px;
        `
            : `
    background: url(/images/no-image.png) no-repeat center center / cover;
        `}
`
export const CardContent = styled.div.attrs({})`
    padding: 0 20px;
    flex: 1;
`
export const CardTitle = styled.div.attrs({})`
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: var(--grey-color);
    margin-bottom: 3px;
`
export const CardSubtitle = styled.div.attrs({})`
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: #9d9d9d;
    margin-bottom: 3px;
`

export const CardTouch = styled.div.attrs({})`
    cursor: pointer;
`
