import { GET, POST, PUT } from './api'

export const FetchRecomendations = async (type, user_id) => {
    return await GET(`/recommendations?type=${type}&userid=${user_id}`, true)
}

export const FetchScoreRecomendation = async (user_id) => {
    return await GET(`/recommendations-score?userid=${user_id}`, true)
}

export const FetchWishList = async () => {
    return await GET(`/wishlists/my`, true)
}

export const FetchChatRooms = async () => {
    return await GET(`/chat-rooms?_limit=6`, true)
}

export const FetchChatMessages = async (roomId, limit = 10, start = 0) => {
    return await GET(
        `/chat-messages?_sort=created_at:desc&room=${roomId}&_limit=${limit}&_start=${start}`,
        true
    )
}

export const FetchLatestMessages = async () => {
    return await GET(`/chat-messages/lastest`, true)
}

export const FetchSVPChart = async () => {
    return await GET(`/supplier-reg-requests/chart/svp`, true)
}

export const FetchSGSChart = async () => {
    return await GET(`/supplier-reg-requests/chart/sgs`, true)
}

export const FetchESGChart = async () => {
    return await GET(`/chart/esg`, true)
}

export const FetchCategoriesChart = async () => {
    return await GET(`/documents/chart/categories`, true)
}

export const FetchBusinessChart = async () => {
    return await GET(`/chart/business`, true)
}

export const FetchDocumentsChart = async () => {
    return await GET(`/documents/chart/documents`, true)
}

export const FetchExpiredChart = async () => {
    return await GET(`/documents/chart/expired`, true)
}

export const FetchRegionChart = async () => {
    return await GET(`/chart/region`, true)
}

export const FetchMapChart = async () => {
    return await GET(`/chart/map`, true)
}

export const FetchClientMapChart = async () => {
    return await GET(`/chart/client-map`, true)
}

export const FetchPanels = async () => {
    return await GET(`/get-panels`, true)
}

export const CountViews = async () => {
    return await GET(`/user-views/count`, true)
}

export const CountProductViews = async () => {
    return await GET(`/supplier-foods?_sort=views:DESC&_limit=4`, true)
}

export const CountSupplierProductViews = async () => {
    return await GET(`/supplier-products-view`, true)
}

export const CountSupplierMyClients = async () => {
    return await GET(`/supplier-reg-requests/dash/count`, true)
}

export const CountConnections = async (startDate, endDate) => {
    return await GET(
        `/connections/count?${startDate ? `created_at_gt=${startDate.toISOString()}` : ''}${
            endDate ? `&created_at_lt=${endDate.toISOString()}` : ''
        }`,
        true
    )
}

export const FetchNotifications = async () => {
    return await GET(`/notifications/findMy`, true)
}

export const UpdateNotifications = async (notification) => {
    return await PUT(`/notifications/${notification.id}`, notification, true)
}

// export const CreateNotifications = async (notification) => {
//     return await POST(`/notifications`, notification, true)
// }

export const FetchSupportCases = async () => {
    return await GET(`/support-cases`, true)
}

export const UpdateSupportCases = async (_case) => {
    return await PUT(`/support-cases/${_case.id}`, _case, true)
}

export const CreateSupportCases = async (_case) => {
    return await POST(`/support-cases`, _case, true)
}
