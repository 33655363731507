import Check from 'components/Form/Check'
import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import { parseStrapiImage } from 'utils'

import {
    PageCard,
    Division,
    ProcessesContainer,
    DateGreenIcon,
    AttachGreenIcon,
    Descriptor,
    IconButton,
    CloseIcon,
    ComponentTitle,
    ComponentTitleInput,
    ComponentDivision,
    ComponentInput,
    ComponentSubTitleInput,
    ComponentDescriptionInput,
    GreenCheck,
    ComponentGrid,
    FakeInput,
    FakeOption,
    Dropdown,
    DropDownWindow,
    DropDownItem,
    CloseGray,
    AddIcon,
    DropdownPlusButton,
    FakeInputTitle,
    GreenImageIcon,
    CheckboxPlusButton,
} from './styled'

export default function DashboardTabOwnValidationConstruct({
    componentList,
    editFormItem,
    removeFromForm,
    readOnly,
}) {
    const { findlanguage } = useContext(CoreContext)
    const setCompVariation = (index, variation) => {
        const edit = { variation }
        editFormItem(index, edit)
    }

    const updateComptitle = (index, title) => {
        const edit = {
            title: title,
        }
        editFormItem(index, edit)
    }

    const updateCompSecondTitle = (index, second_title) => {
        const edit = { second_title }
        editFormItem(index, edit)
    }

    const addOption = (component, index) => {
        const options = [...component.options]
        options.push(`Option ${options.length + 1}`)
        const edit = { options }
        editFormItem(index, edit)
    }

    const removeOption = (component, index, optionIndex) => {
        const options = component.options.filter((o, oKey) => oKey !== optionIndex)
        const edit = { options }
        editFormItem(index, edit)
    }

    const updateOption = (component, index, optionIndex, optionValue) => {
        const options = component.options.map((option, oKey) =>
            oKey === optionIndex ? optionValue : option
        )
        const edit = { options }
        editFormItem(index, edit)
    }

    const mountComponent = (struct, component, key) => (
        <ProcessesContainer>
            {struct(component, key)}

            {!readOnly && (
                <IconButton onClick={() => removeFromForm(key)}>
                    <CloseIcon />
                </IconButton>
            )}
        </ProcessesContainer>
    )

    const textType1 = (component, key) => (
        <Division>
            {readOnly ? (
                <ComponentInput
                    placeholder={findlanguage().textInputTitle}
                    value={component.title}
                    disabled
                />
            ) : (
                <ComponentInput
                    placeholder={findlanguage().textInputTitle}
                    value={component.title}
                    onChange={(e) => updateComptitle(key, e.target.value)}
                />
            )}
            <FakeInput />
        </Division>
    )

    const textType2 = (component, key) => (
        <Division>
            {readOnly ? (
                <ComponentInput
                    placeholder={findlanguage().textInputTitle}
                    value={component.title}
                    disabled
                />
            ) : (
                <ComponentInput
                    placeholder={findlanguage().textInputTitle}
                    value={component.title}
                    onChange={(e) => updateComptitle(key, e.target.value)}
                />
            )}
            <ComponentGrid space={12}>
                {component.options.map((option, opKey) => (
                    <FakeOption key={opKey} green={opKey === 0}>
                        {option}
                    </FakeOption>
                ))}
            </ComponentGrid>
        </Division>
    )

    const dropdownType = (component, key) => (
        <Division>
            {readOnly ? (
                <ComponentInput
                    placeholder={findlanguage().dropdownTitle}
                    value={component.title}
                    disabled
                />
            ) : (
                <ComponentInput
                    placeholder={findlanguage().dropdownTitle}
                    value={component.title}
                    onChange={(e) => updateComptitle(key, e.target.value)}
                />
            )}
            <FakeInput>
                <Dropdown />
            </FakeInput>
            <DropDownWindow>
                {component.options.map((option, opKey) => (
                    <ComponentGrid space={16} key={opKey}>
                        {readOnly ? (
                            <DropDownItem
                                placeholder={findlanguage().option}
                                value={option}
                                disabled
                            />
                        ) : (
                            <DropDownItem
                                placeholder={findlanguage().option}
                                value={option}
                                onChange={(e) =>
                                    updateOption(component, key, opKey, e.target.value)
                                }
                            />
                        )}
                        {readOnly ? null : (
                            <CloseGray
                                onClick={() => removeOption(component, key, opKey)}
                                style={{ alignSelf: 'center' }}
                            />
                        )}
                    </ComponentGrid>
                ))}

                {readOnly ? null : (
                    <DropdownPlusButton onClick={() => addOption(component, key)}>
                        <AddIcon />
                    </DropdownPlusButton>
                )}
            </DropDownWindow>
        </Division>
    )

    const checkboxType = (component, key) => (
        <Division>
            {readOnly ? (
                <ComponentInput
                    placeholder={findlanguage().checkboxTitle}
                    value={component.title}
                    disabled
                />
            ) : (
                <ComponentInput
                    placeholder={findlanguage().checkboxTitle}
                    value={component.title}
                    onChange={(e) => updateComptitle(key, e.target.value)}
                />
            )}
            <ComponentGrid space={32} style={{ margin: '16px 16px 0 0' }}>
                {component.options.map((option, opKey) => (
                    <ComponentGrid space={1} key={opKey}>
                        {readOnly ? (
                            <>
                                <DropDownItem
                                    style={{ alignSelf: 'center' }}
                                    placeholder="option"
                                    value={option}
                                    disabled
                                />
                            </>
                        ) : (
                            <>
                                <Check />
                                <DropDownItem
                                    style={{ alignSelf: 'center' }}
                                    placeholder="option"
                                    value={option}
                                    onChange={(e) =>
                                        updateOption(component, key, opKey, e.target.value)
                                    }
                                />
                                <CloseGray
                                    onClick={() => removeOption(component, key, opKey)}
                                    style={{ marginLeft: 12, alignSelf: 'center' }}
                                />
                            </>
                        )}
                    </ComponentGrid>
                ))}
                {!readOnly && (
                    <CheckboxPlusButton onClick={() => addOption(component, key)}>
                        <AddIcon />
                    </CheckboxPlusButton>
                )}
            </ComponentGrid>
        </Division>
    )

    const dateType1 = (component, key) => (
        <Division>
            <ComponentTitle>{findlanguage().date}</ComponentTitle>
            <ComponentInput
                placeholder={findlanguage().dateTitle}
                value={component.title}
                onChange={(e) => updateComptitle(key, e.target.value)}
            />
            <FakeInput>
                <ComponentGrid style={{ width: '100%' }}>
                    <FakeInputTitle>DD / MM / YYYY</FakeInputTitle>
                    <DateGreenIcon />
                </ComponentGrid>
            </FakeInput>
        </Division>
    )

    const mediaType1 = (component, key) => (
        <Division>
            <ComponentTitle>{findlanguage().document}:</ComponentTitle>
            <ComponentInput
                placeholder={findlanguage().documentInputTitle}
                value={component.title}
                onChange={(e) => updateComptitle(key, e.target.value)}
            />
            <br />
            <br />
            {component.filePdf ? (
                <>
                    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                        <b>{findlanguage().currentFile}</b> {component.filePdf.name}{' '}
                        <a href={parseStrapiImage(component.filePdf.url)}>Download</a>
                    </div>
                </>
            ) : (
                <></>
            )}

            {readOnly ? (
                <></>
            ) : (
                <>
                    <input
                        id={`formFileMultiple${key}`}
                        className="form-control"
                        type="file"
                        accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsxf,.xlsx,.xls,.zip,.rar,.7zip, .xlsm, .jpg, .jpeg, .png"
                    />
                </>
            )}
        </Division>
    )

    const mediaType2 = (component, key) => (
        <Division>
            <ComponentTitle>Image:</ComponentTitle>
            {readOnly ? (
                <ComponentInput
                    placeholder={findlanguage().imageInputTitle}
                    value={component.title}
                    disabled
                />
            ) : (
                <ComponentInput
                    placeholder={findlanguage().imageInputTitle}
                    value={component.title}
                    onChange={(e) => updateComptitle(key, e.target.value)}
                />
            )}
            <FakeInput>
                <ComponentGrid style={{ width: '100%' }}>
                    <FakeInputTitle>{findlanguage().selectImage}</FakeInputTitle>
                    <GreenImageIcon />
                </ComponentGrid>
            </FakeInput>
        </Division>
    )

    return (
        <PageCard>
            {(!componentList || (componentList.length === 0 && !readOnly)) && (
                <Division>
                    <Descriptor>{findlanguage().selectFormComponent}</Descriptor>
                </Division>
            )}

            {componentList &&
                componentList.length !== 0 &&
                componentList.map((component, key) => {
                    if (component.type === 'title')
                        return (
                            <Division key={key}>
                                <ProcessesContainer>
                                    <Division>
                                        {readOnly ? (
                                            <ComponentTitleInput
                                                placeholder={findlanguage().titleExample}
                                                value={component.title}
                                                disabled
                                            />
                                        ) : (
                                            <ComponentTitleInput
                                                placeholder={findlanguage().titleExample}
                                                value={component.title}
                                                onChange={(e) =>
                                                    updateComptitle(key, e.target.value)
                                                }
                                            />
                                        )}
                                    </Division>
                                    {!readOnly && (
                                        <IconButton onClick={() => removeFromForm(key)}>
                                            <CloseIcon />
                                        </IconButton>
                                    )}
                                </ProcessesContainer>
                            </Division>
                        )

                    if (component.type === 'subtitle')
                        return (
                            <Division key={key}>
                                <ProcessesContainer>
                                    <Division>
                                        {readOnly ? (
                                            <ComponentSubTitleInput
                                                placeholder={findlanguage().subTitleExample}
                                                value={component.title}
                                                disabled
                                            />
                                        ) : (
                                            <ComponentSubTitleInput
                                                placeholder={findlanguage().subTitleExample}
                                                value={component.title}
                                                onChange={(e) =>
                                                    updateComptitle(key, e.target.value)
                                                }
                                            />
                                        )}
                                    </Division>
                                    {!readOnly && (
                                        <IconButton onClick={() => removeFromForm(key)}>
                                            <CloseIcon />
                                        </IconButton>
                                    )}
                                </ProcessesContainer>
                            </Division>
                        )

                    if (component.type === 'description')
                        return (
                            <Division key={key}>
                                <ProcessesContainer>
                                    <Division style={{ flex: 1 }}>
                                        {readOnly ? (
                                            <ComponentDescriptionInput
                                                placeholder={findlanguage().description}
                                                value={component.title}
                                                disabled
                                            />
                                        ) : (
                                            <ComponentDescriptionInput
                                                placeholder={findlanguage().description}
                                                value={component.title}
                                                onChange={(e) =>
                                                    updateComptitle(key, e.target.value)
                                                }
                                            />
                                        )}
                                    </Division>
                                    {!readOnly && (
                                        <IconButton onClick={() => removeFromForm(key)}>
                                            <CloseIcon />
                                        </IconButton>
                                    )}
                                </ProcessesContainer>
                            </Division>
                        )

                    if (component.type === 'text')
                        return (
                            <Division key={key}>
                                {mountComponent(textType1, component, key)}
                            </Division>
                        )

                    if (component.type === 'dropdown')
                        return (
                            <Division key={key}>
                                {mountComponent(dropdownType, component, key)}
                            </Division>
                        )

                    if (component.type === 'checkbox')
                        return (
                            <Division key={key}>
                                {mountComponent(checkboxType, component, key)}
                            </Division>
                        )

                    if (component.type === 'date')
                        return (
                            <Division key={key}>
                                {mountComponent(dateType1, component, key)}
                            </Division>
                        )

                    if (component.type === 'media')
                        return (
                            <Division key={key}>
                                {mountComponent(mediaType1, component, key)}
                            </Division>
                        )
                    else return null
                })}
        </PageCard>
    )
}
