import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { CoreContext } from 'context/CoreContext'
import { parseStrapiImage } from 'utils'

import {
    CardSearched,
    CardImage,
    CardContent,
    CardTitle,
    CardLineActionIcon,
    CardActions,
} from './styled'

export default function DashboardCardContact({ contact, sendMessage }) {
    const { user } = useContext(CoreContext)

    let avatarUrl = contact.avatar ? parseStrapiImage(contact.avatar) : `/images/no-image.png`

    return (
        <>
            <CardSearched>
                <CardImage url={avatarUrl} />
                <CardContent>
                    <CardTitle>{contact.name}</CardTitle>
                </CardContent>
                <CardActions>
                    <CardLineActionIcon onClick={() => sendMessage(contact.id)} />
                </CardActions>
            </CardSearched>
        </>
    )
}
