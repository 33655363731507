import styled from 'styled-components'

import { mobileSize } from 'ui/styled'

export const PageCard = styled.div.attrs({})`
    background: var(--white-color);
    padding: 24px;
    margin-top: 28px;
    border-radius: 15px;
    border: 1px solid var(--lightshadow-color);
`
export const CardHeader = styled.div.attrs({})`
    padding: 20px 0;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--primary-color);
    border-bottom: 1px solid var(--lightshadow-color);

    display: flex;
    justify-content: space-between;
`

export const CardLineContainer = styled.div.attrs({})`
    display: flex;
`

export const CardLine = styled.div.attrs({})`
    display: flex;
    padding: 20px 0;
    flex:1;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--lightshadow-color);
    ${(props) =>
        props.mobileHeader
            ? `
            display: none;
        `
            : ``}
    @media(max-width: ${mobileSize}px){
        flex-direction: column;
        gap: 20px 0;
        flex:1;
        ${(props) =>
            props.header
                ? `
                display: none;
            `
                : ``}
        ${(props) =>
            props.mobileHeader
                ? `
                display: flex;
                padding: 0 10px;
                flex:0;
                align-items: flex-start;
            `
                : ``}
`

export const CardLineText = styled.div.attrs({})`
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--grey-color);
    line-break: anywhere;

    ${(props) =>
        props.regular
            ? `
        font-family: 'Open Sans', sans-serif;
    `
            : ``}

    ${(props) =>
        props.link
            ? `
        font-family: 'Open Sans', sans-serif;
        color: var(--lightgrey-color);
        text-decoration-line: underline;
        max-width: 120px;
        cursor: pointer;
        text-align: center;
        `
            : ``}

    ${(props) =>
        props.grey
            ? `
            color: var(--lightgrey-color);
        `
            : ``}
`

export const CardLineActionSpacer = styled.div.attrs({})`
    flex: 1;
    max-width: 110px;
`

export const CardLineColumn = styled.div.attrs({})`
    ${(props) =>
        props.last
            ? ``
            : `
            flex:1;
        `}
    @media(max-width: ${mobileSize}px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`

export const PageCardActions = styled.div.attrs({})`
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
`

export const CardHeaderSection = styled.div.attrs({})`
    display: flex;
    align-items: center;
    color: var(--blue-color);
    cursor: pointer;
`

export const HeaderPercentage = styled.div.attrs({})`
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background: var(--blue-color);

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: var(--white-color);

    margin-left: 24px;
`

export const DownloadIcon = styled.img.attrs({
    src: '/icons/download.svg',
})``
export const CardFormStatus = styled.div.attrs({})`
    width: 18px;
    height: 18px;
    border-radius: 9px;
    background-color: ${(props) => props.color};
`
export const Filter = styled.img.attrs({
    color: 'white',
    src: '/icons/filter.svg',
    height: 20,
})`
    cursor: pointer;
    filter: invert(100%);
`
