import React from 'react'

import { BodyContent } from './styled'

import RegisterBuyerStep2Form from './Form'

export default function RegisterBuyerStep2({
    formValue,
    changeForm,
    next,
    back,
    loading,
    registerInfos,
}) {
    return (
        <>
            <BodyContent>
                <RegisterBuyerStep2Form
                    formValue={formValue}
                    changeForm={changeForm}
                    next={next}
                    registerInfos={registerInfos}
                    back={back}
                    loading={loading}
                />
            </BodyContent>
        </>
    )
}
