import React, { useContext, useState, useEffect } from 'react';
import Select from 'react-select';
import { ProfileFormContent, ProfileFormActions, Header, PermissionsTab, SemiHeader } from './styled';
import ModalWrapper from '../Wrapper';
import { CoreContext } from 'context/CoreContext';
import Input from 'components/Form/Input';
import DashboardButton from 'components/Dashboard/Button';
import { GetAllServiceProviders } from 'services/users';
import { PostNewServiceProvider, UpdateServiceProvider, GetServiceProvider } from 'services/serviceProvider';
import Check from 'components/Form/Check';
import { FetchMyForms } from 'services/sms';

export default function ModalTeamAccess() {
    const { findlanguage, modal, setModal, user } = useContext(CoreContext);
    const [serviceProviders, setServiceProviders] = useState([]);
    const [form, setForm] = useState({
        id: '',
        contactName: '',
        email: '',
        phone: '',
        avatar: '',
        permissions: {}
    });
    const [loading, setLoading] = useState(false);
    const [permissions, setPermissions] = useState({
        "myQms": { "canView": true },
        "myProcessOverview": { "canView": true, "canEdit": true },
        "myBuyers": { "canView": true, "canEdit": true, "showOnDashboard": true, "notifications": true },
        "mySuppliers": { "canView": true, "canEdit": true, "notifications": true, "forms": [] },
        "myDocuments": { "canView": true, "canEdit": true, "notifications": true }
    });
    const [myFormList, setMyFormList] = useState([]);
    const [choosedForms, setChoosedForms] = useState([]);

    useEffect(() => {
        const fetchServiceProvider = async () => {
            const request = await FetchMyForms(user.company_id);
            const options = request.map((x) => ({
                value: x.id,
                label: x.name,
            }));
            setMyFormList(options);

            if (modal?.id !== -1) {
                const response = await GetServiceProvider(modal?.id);

                setForm({
                    id: response.id,
                    name: response.serviceProvider.contactName,
                    email: response.serviceProvider.email,
                    phone: response.serviceProvider.phone,
                    permissions: response.permissions || permissions
                });
                setPermissions(response.permissions || permissions);
                setChoosedForms(response.permissions?.mySuppliers?.forms?.map(formId => {
                    const form = options.find(f => f.value === formId);
                    return { value: formId, label: form?.label || 'Unknown Form' };
                }) || []);
            }
        };

        const fetchData = async () => {
            const response = await GetAllServiceProviders();
            setServiceProviders(response.map(item => ({
                value: item.id,
                label: `${item.name} (${item.contactName})`,
                data: item
            })));
        };

        fetchData();
        fetchServiceProvider();
    }, [modal?.id, user.company_id]);

    const handleSelectChange = selectedOption => {
        setForm({
            id: selectedOption.data.id,
            name: selectedOption.data.contactName,
            email: selectedOption.data.email,
            phone: selectedOption.data.phone,
            permissions: permissions
        });
    };

    const changePermission = (value) => {
        const { key, subSection } = value;
        setPermissions(prevPermissions => ({
            ...prevPermissions,
            [key]: {
                ...prevPermissions[key],
                [subSection]: !prevPermissions[key][subSection]
            }
        }));
    };

    const saveServiceProvider = async () => {
        form.permissions = {
            ...permissions,
            mySuppliers: {
                ...permissions.mySuppliers,
                forms: choosedForms.map(form => form.value)
            }
        };

        const response = modal?.id !== -1
            ? await UpdateServiceProvider(modal?.id, form)
            : await PostNewServiceProvider(form);

        if (response === false) {
            setModal({
                type: 'alert',
                text: 'Something went wrong',
                warning: true,
            });
        } else {
            setModal({
                type: 'alert',
                text: modal?.id !== -1 ? 'Service Provider Updated' : 'Service Provider Added',
                warning: true,
            });
            modal?.refresh();
        }
    }

    return (
        <>
            <ModalWrapper big={true}>
                <ProfileFormContent>
                    <Header>{modal?.id !== -1 ? findlanguage().editServiceProvider : findlanguage().newServiceProvider}</Header>
                    <div style={{
                        borderBottom: '0.5px solid var(--lightgrey-color)',
                        maxWidth: '100%',
                        width: '100%',
                        position: 'relative',
                        paddingTop: '22px',
                        zIndex: 12331
                    }}>
                        {
                            modal?.id === -1 ?

                                <Select
                                    options={serviceProviders}
                                    onChange={handleSelectChange}
                                    placeholder={findlanguage().selectServiceProvider}
                                />
                                :
                                null
                        }
                    </div>
                    <Input
                        placeholder={findlanguage().name}
                        big
                        value={form.name}
                        readOnly
                    />
                    <Input
                        placeholder={findlanguage().email}
                        big
                        value={form.email}
                        type={'text'}
                        readOnly
                    />
                    <Input
                        placeholder={findlanguage().phone}
                        value={form.phone}
                        type={'tel'}
                        big
                        readOnly
                    />

                    <SemiHeader>
                        <label>{findlanguage().permissions}</label>
                    </SemiHeader>
                    <PermissionsTab>
                        <div>
                            <label>{findlanguage().myQMS}</label>
                            <Check
                                nowidth
                                title={findlanguage().canView}
                                value={permissions?.myQms?.canView}
                                onChange={(e) => changePermission({ key: 'myQms', subSection: 'canView' })}
                                checked={permissions?.myQms?.canView}
                            />
                        </div>
                        <div>
                            <label>{findlanguage().myProcessOverview}</label>
                            <Check
                                nowidth
                                title={findlanguage().canView}
                                value={permissions?.myProcessOverview?.canView}
                                onChange={(e) => changePermission({ key: 'myProcessOverview', subSection: 'canView' })}
                                checked={permissions?.myProcessOverview?.canView}
                            />
                            <Check
                                nowidth
                                title={findlanguage().canEdit}
                                value={permissions?.myProcessOverview?.canEdit}
                                onChange={(e) => changePermission({ key: 'myProcessOverview', subSection: 'canEdit' })}
                                checked={permissions?.myProcessOverview?.canEdit}
                            />
                        </div>
                        <div>
                            <label>{findlanguage().myBuyers}</label>
                            <Check
                                nowidth
                                title={findlanguage().canView}
                                value={permissions?.myBuyers?.canView}
                                onChange={(e) => changePermission({ key: 'myBuyers', subSection: 'canView' })}
                                checked={permissions?.myBuyers?.canView}
                            />
                            <Check
                                nowidth
                                title={findlanguage().canEdit}
                                value={permissions?.myBuyers?.canEdit}
                                onChange={(e) => changePermission({ key: 'myBuyers', subSection: 'canEdit' })}
                                checked={permissions?.myBuyers?.canEdit}
                            />
                        </div>
                        <div>
                            <label>{findlanguage().mySuppliers}</label>
                            <Check
                                nowidth
                                title={findlanguage().canView}
                                value={permissions?.mySuppliers?.canView}
                                onChange={(e) => changePermission({ key: 'mySuppliers', subSection: 'canView' })}
                                checked={permissions?.mySuppliers?.canView}
                            />
                            <Check
                                nowidth
                                title={findlanguage().canEdit}
                                value={permissions?.mySuppliers?.canEdit}
                                onChange={(e) => changePermission({ key: 'mySuppliers', subSection: 'canEdit' })}
                                checked={permissions?.mySuppliers?.canEdit}
                            />
                            <Check
                                nowidth
                                title={findlanguage().receiveNotifications}
                                value={permissions?.mySuppliers?.notifications}
                                onChange={(e) => changePermission({ key: 'mySuppliers', subSection: 'notifications' })}
                                checked={permissions?.mySuppliers?.notifications}
                            />
                            <br />
                            <div className="col">
                                <label>{findlanguage().requirementsCanBeEdit}</label>
                                <Select
                                    onChange={(values) => setChoosedForms(values)}
                                    isMulti
                                    name="forms"
                                    options={myFormList.sort(
                                        (a, b) => (a.label > b.label) - (a.label < b.label)
                                    )}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    value={choosedForms}
                                    placeholder={findlanguage().select}
                                />
                            </div>
                        </div>
                        <div>
                            <label>{findlanguage().myDocuments}</label>
                            <Check
                                nowidth
                                title={findlanguage().canView}
                                value={permissions?.myDocuments?.canView}
                                onChange={(e) => changePermission({ key: 'myDocuments', subSection: 'canView' })}
                                checked={permissions?.myDocuments?.canView}
                            />
                            <Check
                                nowidth
                                title={findlanguage().canEdit}
                                value={permissions?.myDocuments?.canEdit}
                                onChange={(e) => changePermission({ key: 'myDocuments', subSection: 'canEdit' })}
                                checked={permissions?.myDocuments?.canEdit}
                            />
                            <Check
                                nowidth
                                title={findlanguage().receiveNotifications}
                                value={permissions?.myDocuments?.notifications}
                                onChange={(e) => changePermission({ key: 'myDocuments', subSection: 'notifications' })}
                                checked={permissions?.myDocuments?.notifications}
                            />
                        </div>
                    </PermissionsTab>

                </ProfileFormContent>

                <ProfileFormActions>
                    <DashboardButton
                        loading={loading}
                        onClick={saveServiceProvider}
                        primary
                        disabled={form.id === ''}
                    >
                        {modal?.id !== -1 ? findlanguage().updateServiceProvider : findlanguage().addServiceProvider}
                    </DashboardButton>
                </ProfileFormActions>
            </ModalWrapper>
        </>
    );
}
