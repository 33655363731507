// import React, { useContext } from 'react'
// import { Container, Row, Col } from 'reactstrap'
// import { useHistory } from 'react-router-dom'
// import {
//     PageBody,
//     BackContent,
//     BackContentIcon,
//     BackContentText,
//     ContentInfos,
//     SideInfoSection,
//     SideInfoImage,
//     SideInfoSupContent,
//     SupImage,
//     SupInfoContent,
//     SupTitle,
//     SupRate,
//     SupRateIcon,
//     SupText,
//     SideCertificatesContent,
//     SideCertificateIcon,
//     PrincipalInfoSection,
//     ContentProductTitle,
//     ProductTitleText,
//     ProductTitleImage,
//     ProductTitleBadge,
//     ContentProductText,
//     ContentProductActions,
// } from './styled'
// import ContainerAuthenticated from 'containers/Authenticated'
// import DashboardUserDetailsUserTabs from 'components/Dashboard/Details/UserTabs'
// import DashboardButton from 'components/Dashboard/Button'
// import { CoreContext } from 'context/CoreContext'

export default function DashboardProduct() {
    //     const history = useHistory()
    //     const back = () => history.goBack()
    //     const { setModal } = useContext(CoreContext)
    //     const connectToUser = () => {
    //         setModal({
    //             type: 'alert',
    //             text: 'Information Request sent',
    //             description: 'You will receive a notification if your request is approved.',
    //         })
    //     }
    //     const requestInformation = () => {
    //         setModal({
    //             type: 'alert',
    //             text: 'Request sent',
    //             description: 'You will receive a notification if your request is approved.',
    //         })
    //     }
    //     return (
    //         <>
    //             <ContainerAuthenticated>
    //                 <Container fluid={false}>
    //                     <Row>
    //                         <Col>
    //                             <PageBody>
    //                                 <BackContent>
    //                                     <BackContentIcon onClick={back} />
    //                                     <BackContentText>Product Description</BackContentText>
    //                                 </BackContent>
    //                                 <ContentInfos>
    //                                     <SideInfoSection>
    //                                         <SideInfoImage />
    //                                         <SideInfoSupContent>
    //                                             <SupImage />
    //                                             <SupInfoContent>
    //                                                 <SupTitle>Carrefour</SupTitle>
    //                                                 <SupRate>
    //                                                     {[1, 2, 3, 4, 5].map((item, key) => (
    //                                                         <SupRateIcon key={key} />
    //                                                     ))}
    //                                                 </SupRate>
    //                                                 <SupText>carrefour@sale.com</SupText>
    //                                                 <SupText>323-333-444</SupText>
    //                                             </SupInfoContent>
    //                                         </SideInfoSupContent>
    //                                         <SideCertificatesContent>
    //                                             <SideCertificateIcon
    //                                                 src={'/icons/certificate-esg.svg'}
    //                                             />
    //                                             <SideCertificateIcon
    //                                                 src={'/icons/certificate-mfs.svg'}
    //                                             />
    //                                         </SideCertificatesContent>
    //                                     </SideInfoSection>
    //                                     <PrincipalInfoSection>
    //                                         <ContentProductTitle>
    //                                             <ProductTitleText>Salmon</ProductTitleText>
    //                                             <ProductTitleImage />
    //                                             <ProductTitleBadge>Available</ProductTitleBadge>
    //                                         </ContentProductTitle>
    //                                         <ContentProductText>
    //                                             Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
    //                                             sed diam nonumy eirmod tempor invidunt ut labore et
    //                                             dolore magna aliquyam erat, sed diam voluptua. At vero
    //                                             eos et accusam et justo duo dolores et ea rebum. Stet
    //                                             clita kasd gubergren, no sea takimata sanctus est Lorem
    //                                             ipsum dolor sit amet. Lorem ipsum dolor sit amet,
    //                                             consetetur sadipscing elitr, sed diam nonumy eirmod
    //                                             tempor invidunt ut labore et dolore magna aliquyam erat,
    //                                             sed diam voluptua. At vero eos et accusam et justo duo
    //                                             dolores et ea rebum. Stet clita kasd gubergren, no sea
    //                                             takimata sanctus est Lorem ipsum dolor sit amet. Lorem
    //                                             ipsum dolor sit amet, consetetur sadipscing elitr, sed
    //                                             amet. Lorem ipsum dolor sit amet, consetetur sadipscing
    //                                             elitr, sed diam nonumy eirmod tempor invidunt ut labore
    //                                             et dolore magna aliquyam erat, sed diam voluptua. At
    //                                             vero eos et accusam et justo duo dolores et ea rebum.
    //                                             Stet clita kasd gubergren, no sea takimata sanctus est
    //                                             Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
    //                                             consetetur sadipscing elitr, sed
    //                                         </ContentProductText>
    //                                         <ContentProductActions>
    //                                             <DashboardButton onClick={connectToUser}>
    //                                                 Connect
    //                                             </DashboardButton>
    //                                             <DashboardButton dark>Message</DashboardButton>
    //                                             <DashboardButton outline>
    //                                                 Add to Wishlist
    //                                             </DashboardButton>
    //                                             <DashboardButton
    //                                                 outline
    //                                                 dark
    //                                                 onClick={requestInformation}
    //                                             >
    //                                                 Request Information
    //                                             </DashboardButton>
    //                                         </ContentProductActions>
    //                                         <DashboardUserDetailsUserTabs />
    //                                     </PrincipalInfoSection>
    //                                 </ContentInfos>
    //                             </PageBody>
    //                         </Col>
    //                     </Row>
    //                 </Container>
    //             </ContainerAuthenticated>
    //         </>
    //     )
}
