import React, { useEffect, useState, useContext } from 'react';
import { FetchProfileViews } from 'services/users';
import { Load } from 'ui/styled';
import VisitCard from './VisitCard/index'
import { CoreContext } from 'context/CoreContext'
import { useHistory } from 'react-router-dom';

export default function ProfileViews() {
    const [profileViews, setProfileViews] = useState([]);
    const { checkPremium } = useContext(CoreContext)
    const history = useHistory()

    const listProfileViews = async () => {
        const list = await FetchProfileViews();
        setProfileViews(list);
    };

    useEffect(() => {
        const fetchData = async () => {
            const premium = await checkPremium();

            if (premium.type === 'free') {
                history.push('/dashboard');
            }

            listProfileViews();
        };

        fetchData();
    }, []);

    return (
        <div className="row">
            {Object.keys(profileViews).length > 0 ? (
                Object.keys(profileViews)
                    .sort((a, b) => new Date(b) - new Date(a))
                    .map((date, index) => (
                        <div className="col-4" key={index}>
                            {profileViews[date].map((visit, i) => (
                                <VisitCard key={i} visit={visit} date={date} />
                            ))}
                        </div>
                    ))
            ) : (
                <Load white="white" />
            )}
        </div>
    );
}