import React, { useEffect } from 'react'

const Modal = ({ onClose }) => {
    useEffect(() => {
        const handleBackdropClick = (event) => {
            if (event.target.classList.contains('modal-backdrop')) {
                onClose() // Call the onClose function
            }
        }

        // Add the event listener
        window.addEventListener('click', handleBackdropClick)

        // Cleanup the event listener
        return () => {
            window.removeEventListener('click', handleBackdropClick)
        }
    }, [onClose])

    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <h2>Register now!</h2>
                <p>Get FREE access for 7 days</p>
                <div
                    onClick={(e) => {
                        window.location.href = 'https://app.mytrustedsc.com/register'
                    }}
                    className="divButton"
                >
                    Sign-Up Now!
                </div>
                <p>No complication, add your card only after trial period.</p>
            </div>
        </div>
    )
}

export default Modal
