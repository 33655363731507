import { GET } from './api'

export const ReadProducts = async () => {
    return await GET(`/products`, true)
}

export const ReadScrapings = async () => {
    return await GET(`/product-source-certifications/available-scrapings`, true)
}

export const ReadProductSourceCertificationsBasic = async (query) => {
    const suffix = query?.length ? `?${query.join('&')}` : ``
    return await GET(`/product-source-certifications${suffix}`, true)
}

export const GetProductSourceCertificationsBasic = async (id) => {
    return await GET(`/product-source-certifications/${id}`, true)
}

export const CountProductSourceCertificationsBasic = async (query) => {
    const suffix = query?.length ? `?${query.join('&')}` : ``
    return await GET(`/product-source-certifications/count${suffix}`, true)
}

export const ReadProductSourceCertificationsAdvanced = async (query) => {
    const suffix = query?.length ? `?${query.join('&')}` : ``
    return await GET(`/product-source-certifications/advanced-fetch${suffix}`, true)
}

export const ReadAvailableFilters = async () => {
    return await GET(`/product-source-certifications/available-filters`, true)
}

export const SearchUsers = async (query) => {
    query = query.map((x) => x.replace(/&/g, '12345678909897'))
    const suffix = query?.length ? `?${query.join('&')}` : ``

    return await GET(`/user-aditional-informations${suffix}`, true)
}

export const FetchUser = async (id) => {
    return await GET(`/user-aditional-informations/${id}`, true)
}

export const FetchUserByUserId = async (id) => {
    return await GET(`/user-aditional-informations/whereisuser?id=${id}`, true)
}

export const FetchUserCategory = async (id) => {
    return await GET(`/user-aditional-informations/findcategory?id=${id}`, true)
}
