import React, { useContext, useState } from 'react'

import {
    ProfileFormContent,
    RowApprove,
    ApproveContent,
    ApproveCheck,
    ApproveCheckIcon,
} from './styled'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

import DashboardButton from 'components/Dashboard/Button'

export default function ModalApproved() {
    const { modal, setModal, language } = useContext(CoreContext)

    const close = () => {
        setModal(null)
    }

    const changeLanguage = (value) => {
        if (language === 'english') {
            return value
        }
        else {
            switch (value) {
                case 'Farm':
                    return 'Fazenda';
                case 'Industry':
                    return 'Industria';
                case 'Restaurant':
                    return 'Restaurante';
                case 'Retail':
                    return 'Varejo';
                case 'Store and Distribuition':
                    return 'Armazenamento e distribuição';
                case 'Broker':
                    return 'Corretora';
                case 'Process Food':
                    return 'Alimentos Processados';
                case 'Animal Welfare':
                    return 'Bem estar animal';
                case 'FDA Approved Companies':
                    return 'Empresas Aprovadas pela FDA';
                case 'Food Safety / GFSI Certified':
                    return 'Segurança Alimentar / Certificado GFSI';
                case 'None':
                    return 'Nenhum';
                case 'Quality':
                    return 'Qualidade';
                case 'Religious':
                    return 'Religioso';
                case 'Social/Ethical certified':
                    return 'Certificado Social/Ético';
                case 'Sustainability Certified':
                    return 'Certificado de Sustentabilidade';
                case 'USDA Approved Companies':
                    return 'Empresas aprovadas pelo USDA';
                case 'Organic Certified':
                    return 'Certificadas Orgânica'
                case 'Organic Certified Companies':
                    return 'Empresas Certificadas Orgânica'
                default:
                    return value;
            }

        }
    }

    const changeDistribution = (value) => {
        switch (value) {
            case 'State':
                return 'Estadual'
            case 'National':
                return 'Nacional'
            case 'International':
                return 'Internacional'
            default:
                return value
        }
    }

    return (
        <>
            {modal.changeList === 'business' || modal.changeList === 'prerequirements' ? (
                <ModalWrapper>
                    <ProfileFormContent>
                        {modal?.list.length === 0 ? (
                            <>
                                <RowApprove>
                                    <ApproveContent>There are no items</ApproveContent>
                                </RowApprove>
                            </>
                        ) : (
                            modal?.list?.map((item) => (
                                <RowApprove key={item.id}>
                                    <ApproveContent>
                                        <ApproveCheck>
                                            <ApproveCheckIcon />
                                        </ApproveCheck>
                                        {changeLanguage(item.label)}
                                    </ApproveContent>
                                </RowApprove>
                            ))
                        )}
                    </ProfileFormContent>
                </ModalWrapper>
            ) : null}
            {modal.changeList === 'products' ? (
                <ModalWrapper>
                    <ProfileFormContent>
                        {modal?.list.length === 0 ? (
                            <>
                                <RowApprove>
                                    <ApproveContent>There are no items</ApproveContent>
                                </RowApprove>
                            </>
                        ) : (
                            modal?.list?.map((item) => (
                                <RowApprove key={item.id}>
                                    <ApproveContent>
                                        <ApproveCheck>
                                            <ApproveCheckIcon />
                                        </ApproveCheck>
                                        {
                                            language === 'english' ?
                                                item?.name === undefined || item?.name === null || item?.name === '' ? item.product_name : item.name
                                                :
                                                item?.name_pt === undefined || item?.name_pt === null || item?.name_pt === '' ? item.product_name : item.name_pt
                                        }
                                    </ApproveContent>
                                </RowApprove>
                            ))
                        )}
                    </ProfileFormContent>
                </ModalWrapper>
            ) : null}
            {modal.changeList === 'category' ? (
                <ModalWrapper>
                    <ProfileFormContent>
                        {modal?.list.length === 0 ? (
                            <>
                                <RowApprove>
                                    <ApproveContent>There are no items</ApproveContent>
                                </RowApprove>
                            </>
                        ) : (
                            modal?.list?.map((item) => (
                                <RowApprove key={item.id}>
                                    <ApproveContent>
                                        <ApproveCheck>
                                            <ApproveCheckIcon />
                                        </ApproveCheck>
                                        {
                                            language === 'english' ?
                                                item.title
                                                :
                                                item.title_pt
                                        }
                                    </ApproveContent>
                                </RowApprove>
                            ))
                        )}
                    </ProfileFormContent>
                </ModalWrapper>
            ) : null}
            {modal.changeList === 'distribution' ? (
                <ModalWrapper>
                    <ProfileFormContent>
                        {modal?.list.length === 0 ? (
                            <>
                                <RowApprove>
                                    <ApproveContent>There are no items</ApproveContent>
                                </RowApprove>
                            </>
                        ) : (
                            modal?.list?.map((x) => (
                                <RowApprove key={x}>
                                    <ApproveContent>
                                        <ApproveCheck>
                                            <ApproveCheckIcon />
                                        </ApproveCheck>
                                        {changeDistribution(x)}
                                    </ApproveContent>
                                </RowApprove>
                            ))
                        )}
                    </ProfileFormContent>
                </ModalWrapper>
            ) : null}
        </>
    )
}
