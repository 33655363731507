import styled from 'styled-components'

export const HeaderContent = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const HeaderStepIndicator = styled.div.attrs({})`
    display: flex;
    border-bottom: 0.5px solid var(--lightgrey-color);
    margin-top: 29px;
    margin-bottom: 48px;
    ${(props) =>
        !props.single
            ? `
            min-width: 80px;
            justify-content: space-between;
        `
            : `
            justify-content: center;
        `}
    ${(props) =>
        props.active
            ? `
            border-bottom: .5px solid var(--primary-color);
        `
            : ``}
`
export const HeaderStep = styled.div.attrs({})`
    border: 1px solid var(--lightgrey-color);
    width: 38px;
    height: 38px;
    border-radius: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -38px -19px;

    color: var(--lightgrey-color);
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    cursor: pointer;

    ${(props) =>
        props.active
            ? `
            border: 1px solid var(--primary-color);
            color: var(--primary-color);
        `
            : ``}

    ${(props) =>
        props.current
            ? `
            color: var(--white-color);
            border: 1px solid var(--primary-color);
            background: var(--primary-color);
        `
            : ``}
`
export const HeaderSubtitle = styled.div.attrs({})`
    color: var(--grey-color);
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    text-align: center;
    b {
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        color: var(--primary-color);
    }
`
