import { DELETE, GET, POST, PUT } from './api'

export const FindAllForAdmin = async () => {
    return await GET(`/validation-process-forms/findallforadmin`, true)
}

export const FindFormBody = async (id) => {
    return await GET(`/validation-process-forms/findformbody?id=${id}`, true)
}

export const FindAllESG = async (id) => {
    return await GET(`/supplier-questionnaires/findallesg`, true)
}

export const GetAllPrecheck = async () => {
    return await GET(`/user-views/findallprechecks`, true)
};