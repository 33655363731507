import React, { useContext, useState, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import Button from 'components/Dashboard/Button'
import { toast } from 'react-toastify'
import { FetchAllPlans, FetchPlanIncludes } from 'services/premium'
import { Load } from 'ui/styled'

import {
    PageCard,
    PageTitle,
    PageSubTitle,
    Content,
    PlanContent,
    PlanTitle,
    FeaturesPanel,
    PlanTitlePanel,
    PlanTitleDescription,
    PlanPrice,
    PlanActive,
    PlanPriceSimbol,
    PlanPricePanel,
    FeatureTitle,
    FeatureDescription,
    ItemsPanel,
    Item,
    ItemIco,
    ItemText,
    PlanTitleGroup,
    PlanLabel,
    ButtonPlan,
    PlanPriceGroup,
    PlansBodyCentered,
    BillingSellector,
    SaveUp,
    SaveUpGroup,
    SaveUpImg,
    BillingSellectorChoices,
    BillingSellectorMonthly,
    BillingSellectorYearly,
    BillingSellectorButton,
    ComboContainer,
    ComboItem,
    PlanPriceDecimal,
} from './styled'
import Select from 'react-select'
import ContainerAuthenticated from 'containers/Authenticated'
import { CoreContext } from 'context/CoreContext'

import { RenewSubscribe } from 'services/premium'

const labelCss = { fontWeight: 'bold', fontSize: '18px', color: 'rgb(86, 86, 86)' }
const inputCss = { borderColor: '#70707059' }

export default function DashboardNewPremium() {
    const history = useHistory()
    const navigate = (to) => history.push(`/${to}`)

    const { profile, premium, language, setModal, user, setPremium, findlanguage } =
        useContext(CoreContext)

    const [step, setStep] = useState(0)

    const [switchState, setSwitchState] = useState('off')
    const [selectedPlan, setSelectedPlan] = useState({})

    const [selectedBilling, setSelectedBilling] = useState('monthly')
    const [selectedNumUsers, setSelectedNumUsers] = useState({ value: 1, label: 1 })

    const back = () => {
        setStep(0)
    }

    const [plans, setPlans] = useState([])
    const [includes, setIncludes] = useState([])
    const loadPlanIncludes = () => {
        FetchPlanIncludes().then((includes) => {
            setIncludes(
                includes.map((item) => ({
                    id: item.id,
                    item: language === 'portugues' ? item.item_br : item.item,
                    type: item.type,
                    order: item.order,
                }))
            )
        })
    }

    const loadPlans = (plan, numUsers) => {
        FetchAllPlans(plan).then((plan) => {
            const _plans = plan
                .filter((i) => i.num_users === numUsers.value)
                .map((item) => ({
                    id: item.id,
                    type: item.type,
                    name: language === 'portugues' ? item.name_br : item.name,
                    price:
                        language === 'portugues' ? item.price_br.toFixed(2) : item.price.toFixed(2),
                    plan: item.plan,
                    num_users: item.num_users,
                }))
            setPlans(_plans)

            getPrice('premium', _plans)
        })

        loadPlanIncludes()
    }

    const getPrice = (type, _plans) => {
        const _price = _plans
            .filter((i) => i.type === type && i.plan === selectedBilling)
            .map((item) => ({
                id: item.id,
                name: item.name,
                price: item.price,
                plan: item.plan,
                num_users: item.num_users,
                currency: language === 'portugues' ? 'BRL' : 'USD',
            }))[0]

        setSelectedPlan(_price)
    }

    const buildPrice = () => {
        if (selectedPlan.id === undefined) return

        let _label = language === 'portugues' ? 'por mês' : 'per month'

        if (selectedBilling === 'annual') {
            let _price_month = parseInt(selectedPlan.price / 12).toFixed(0)

            _label = _price_month + (language === 'portugues' ? ' por mês' : ' per month')
        }

        let _price_parts = selectedPlan.price.toString().split('.')

        return (
            <>
                <PlanTitleGroup>
                    <PlanTitle>{selectedPlan.name}</PlanTitle>
                </PlanTitleGroup>
                <PlanPricePanel>
                    <PlanPriceGroup>
                        <PlanPriceSimbol>{language === 'portugues' ? 'R$' : '$'}</PlanPriceSimbol>
                        <PlanPrice>{_price_parts[0]}</PlanPrice>
                        <PlanPriceDecimal>.{_price_parts[1]}</PlanPriceDecimal>
                    </PlanPriceGroup>
                    <PlanLabel>{_label}</PlanLabel>
                </PlanPricePanel>

                {premium.type === 'premium' ? (
                    <PlanActive>
                        {language === 'portugues' ? 'Plano Atual' : 'Current Plan'}
                    </PlanActive>
                ) : (
                    ''
                )}
            </>
        )
    }

    const changeNumUsers = (item) => {
        setSelectedNumUsers(item)
    }

    useEffect(() => {
        loadPlans(selectedBilling, selectedNumUsers)
    }, [selectedBilling, selectedNumUsers])

    const action = () => {
        setModal({
            type: 'choose',
            title:
                language === 'portugues'
                    ? selectedNumUsers === 1
                        ? 'Deseja continuar sua assinatura com o plano premium para 1 user?'
                        : `Deseja continuar sua assinatura com o plano premium para ${selectedNumUsers.value} users?`
                    : selectedNumUsers === 1
                    ? 'Do you want to proceed with the subscription as a premium for 1 user?'
                    : `Do you want to proceed with the subscription as a premium for ${selectedNumUsers.value} users?`,
            options: [
                { title: findlanguage().no, action: () => setModal(null) },
                { title: findlanguage().yes, action: () => doSubscription(), outline: true },
            ],
        })
    }

    const doSubscription = (plan) => {
        setModal({
            type: 'loading',
            text: findlanguage().processing,
        })
        
        if (selectedPlan === '') toast.error('Select a plan to proceed.')

        const subscribe = async () => {
            const premium = await RenewSubscribe(selectedPlan)

            if (!premium.due)
                return setModal({
                    type: 'alert',
                    warning: true,
                    text:
                        premium.message ||
                        (language === 'portugues' ? 'Ocorreu um erro' : 'Error ocurred'),
                })

            setPremium(premium)
        }

        subscribe().catch(console.error)

        showPlanSuccess()
    }

    const showPlanSuccess = () => {
        setModal({
            type: 'plan-selected',
        })
    }

    const contactus = () => {
        setModal({
            type: 'alert',
            text: language === 'portugues' ? 'Entre em contato!' : 'Contact Us!',
            description:
                language === 'portugues'
                    ? 'Use o botão do live chat no rodapé da página'
                    : 'Use live chat at the bottom of the page',
        })
    }

    const switchButton = () => {
        if (switchState === 'off') {
            setSwitchState('on')
            setSelectedBilling('annual')
        } else {
            setSwitchState('off')
            setSelectedBilling('monthly')
        }
    }

    const billingSelector = () => {
        return (
            <BillingSellector>
                {language === 'portugues' ? (
                    <SaveUpGroup style={{ left: '1% !important' }}>
                        <SaveUp>Economize 10%</SaveUp>
                        <SaveUpImg />
                    </SaveUpGroup>
                ) : (
                    <SaveUpGroup style={{ left: '32%' }}>
                        <SaveUp>Save up to 10%</SaveUp>
                        <SaveUpImg />
                    </SaveUpGroup>
                )}
                <BillingSellectorChoices>
                    <BillingSellectorMonthly>
                        {language === 'portugues' ? 'Mensal' : 'Billed Monthly'}
                    </BillingSellectorMonthly>
                    <BillingSellectorButton state={switchState} onClick={switchButton} />
                    <BillingSellectorYearly>
                        {language === 'portugues' ? 'Anual' : 'Billed Yearly'}
                    </BillingSellectorYearly>
                </BillingSellectorChoices>
            </BillingSellector>
        )
    }

    const buildButton = (_action) => {
        return (
            <ButtonPlan onClick={() => action(_action)}>
                {language === 'portugues' ? 'Assinar' : 'Subscribe'}
            </ButtonPlan>
        )
    }

    const buildCombo = () => {
        return (
            <>
                <ComboContainer>
                    <ComboItem>
                        <label className="form-label" style={labelCss}>
                            {findlanguage().numUsers}
                        </label>
                    </ComboItem>
                    <ComboItem style={{ width: '80px' }}>
                        <Select
                            options={[
                                { value: 1, label: 1 },
                                { value: 2, label: 2 },
                                { value: 3, label: 3 },
                                { value: 4, label: 4 },
                                { value: 5, label: 5 },
                                { value: 6, label: 6 },
                                { value: 7, label: 7 },
                                { value: 8, label: 8 },
                                { value: 9, label: 9 },
                                { value: 10, label: 10 },
                            ]}
                            value={selectedNumUsers}
                            placeholder={''}
                            style={{
                                ...inputCss,
                                borderColor: 'initial',
                            }}
                            onChange={(e) => changeNumUsers(e)}
                        />
                    </ComboItem>
                </ComboContainer>
            </>
        )
    }

    const buildPlanContent = (profile, subscription, full = false, hidebutton = false) => {
        hidebutton = hidebutton === null || hidebutton === undefined ? false : hidebutton

        if (subscription === 'free') {
            return (
                <>
                    <PlanContent>
                        <PlanTitlePanel>
                            <PlanTitle>Starter</PlanTitle>
                            <PlanTitleDescription>
                                {language === 'portugues'
                                    ? 'Para pequenos times'
                                    : 'For Individuals and Small Teams'}
                            </PlanTitleDescription>
                            <PlanPricePanel>
                                <PlanPriceGroup>
                                    <PlanPriceSimbol>
                                        {language === 'portugues' ? 'R$' : '$'}
                                    </PlanPriceSimbol>
                                    <PlanPrice>
                                        {language === 'portugues' ? 'Grátis' : 'Free'}
                                    </PlanPrice>
                                </PlanPriceGroup>
                            </PlanPricePanel>

                            {premium.type === 'free' ? (
                                <PlanActive>
                                    {language === 'portugues' ? 'Plano Atual' : 'Current Plan'}
                                </PlanActive>
                            ) : (
                                ''
                            )}
                        </PlanTitlePanel>
                        <FeaturesPanel>
                            <FeatureTitle>
                                {language === 'portugues' ? 'Inclui' : 'Includes'}
                            </FeatureTitle>
                            <FeatureDescription></FeatureDescription>
                            <ItemsPanel>
                                {includes.length > 0 ? (
                                    includes
                                        .filter((item) => item.type === 'free')
                                        .map((item, key) => (
                                            <Item key={key}>
                                                <ItemIco />
                                                <ItemText>{item.item}</ItemText>
                                            </Item>
                                        ))
                                ) : (
                                    <Load color="#263640" />
                                )}
                            </ItemsPanel>
                        </FeaturesPanel>
                    </PlanContent>
                </>
            )
        } else if (subscription === 'premium') {
            return (
                <>
                    <PlanContent>
                        <PlanTitlePanel>{plans.length > 0 && buildPrice()}</PlanTitlePanel>
                        <FeaturesPanel>
                            <FeatureTitle>
                                {language === 'portugues' ? 'Inclui' : 'Includes'}
                            </FeatureTitle>
                            <FeatureDescription></FeatureDescription>
                            <ItemsPanel>
                                {includes.length > 0 ? (
                                    includes
                                        .filter((item) => item.type === 'premium')
                                        .map((item, key) => (
                                            <Item key={key}>
                                                <ItemIco />
                                                <ItemText>{item.item}</ItemText>
                                            </Item>
                                        ))
                                ) : (
                                    <Load color="#263640" />
                                )}
                            </ItemsPanel>
                            {!hidebutton ? buildButton('premium') : ''}
                        </FeaturesPanel>
                    </PlanContent>
                </>
            )
        }
    }

    const buildPlanContentYear = (profile, subscription, full = false, hidebutton = false) => {
        hidebutton = hidebutton === null || hidebutton === undefined ? false : hidebutton

        if (subscription === 'free') {
            return (
                <>
                    <PlanContent>
                        <PlanTitlePanel>
                            <PlanTitle>Starter</PlanTitle>
                            <PlanTitleDescription>
                                {language === 'portugues'
                                    ? 'Para pequenos times'
                                    : 'For Individuals and Small Teams'}
                            </PlanTitleDescription>
                            <PlanPricePanel>
                                <PlanPriceGroup>
                                    <PlanPriceSimbol>
                                        {language === 'portugues' ? 'R$' : '$'}
                                    </PlanPriceSimbol>
                                    <PlanPrice>
                                        {language === 'portugues' ? 'Grátis' : 'Free'}
                                    </PlanPrice>
                                </PlanPriceGroup>
                            </PlanPricePanel>

                            {premium.type === 'free' ? (
                                <PlanActive>
                                    {language === 'portugues' ? 'Plano Atual' : 'Current Plan'}
                                </PlanActive>
                            ) : (
                                ''
                            )}
                        </PlanTitlePanel>
                        <FeaturesPanel>
                            <FeatureTitle>
                                {language === 'portugues' ? 'Inclui' : 'Includes'}
                            </FeatureTitle>
                            <FeatureDescription></FeatureDescription>
                            <ItemsPanel>
                                {includes.length > 0 ? (
                                    includes
                                        .filter((item) => item.type === 'free')
                                        .map((item, key) => (
                                            <Item key={key}>
                                                <ItemIco />
                                                <ItemText>{item.item}</ItemText>
                                            </Item>
                                        ))
                                ) : (
                                    <Load color="#263640" />
                                )}
                            </ItemsPanel>
                        </FeaturesPanel>
                    </PlanContent>
                </>
            )
        } else if (subscription === 'premium') {
            return (
                <>
                    <PlanContent>
                        <PlanTitlePanel>{plans.length > 0 && buildPrice()}</PlanTitlePanel>
                        <FeaturesPanel>
                            <FeatureTitle>
                                {language === 'portugues' ? 'Inclui' : 'Includes'}
                            </FeatureTitle>
                            <FeatureDescription></FeatureDescription>
                            <ItemsPanel>
                                {includes.length > 0 ? (
                                    includes
                                        .filter((item) => item.type === 'premium')
                                        .map((item, key) => (
                                            <Item key={key}>
                                                <ItemIco />
                                                <ItemText>{item.item}</ItemText>
                                            </Item>
                                        ))
                                ) : (
                                    <Load color="#263640" />
                                )}
                            </ItemsPanel>
                            {!hidebutton ? buildButton('premium') : ''}
                        </FeaturesPanel>
                    </PlanContent>
                </>
            )
        }
    }

    if (step === 0) {
        return (
            <>
                <ContainerAuthenticated full={true}>
                    <Container fluid={true} style={{ margin: 0, padding: 0 }}>
                        <Row style={{ margin: 0, padding: 0 }}>
                            <Col style={{ margin: 0, padding: 0 }}>
                                <PageTitle>
                                    {language === 'portugues'
                                        ? 'Preços e Planos'
                                        : 'Pricing and Plans'}
                                </PageTitle>
                                <PageSubTitle>
                                    {language === 'portugues'
                                        ? 'Escolha o pacote que mais combina com você'
                                        : 'Choose the package that best suit you'}
                                </PageSubTitle>

                                {buildCombo()}

                                <Content style={{ margin: 0, borderTop: '1px solid #dddddd' }}>
                                    <PageCard>
                                        {billingSelector()}

                                        <PlansBodyCentered>
                                            {switchState === 'off' ? (
                                                <>
                                                    {buildPlanContent(profile, 'free', true, false)}

                                                    {buildPlanContent(
                                                        profile,
                                                        'premium',
                                                        true,
                                                        false
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {buildPlanContentYear(
                                                        profile,
                                                        'free',
                                                        true,
                                                        false
                                                    )}

                                                    {buildPlanContentYear(
                                                        profile,
                                                        'premium',
                                                        true,
                                                        false
                                                    )}
                                                </>
                                            )}

                                            <PlanContent>
                                                <PlanTitlePanel>
                                                    <PlanTitleGroup>
                                                        <PlanTitle>Enterprise</PlanTitle>
                                                    </PlanTitleGroup>
                                                    <PlanPricePanel>
                                                        <PlanPriceGroup>
                                                            <PlanPriceSimbol>
                                                                {language === 'portugues'
                                                                    ? 'R$'
                                                                    : '$'}
                                                            </PlanPriceSimbol>
                                                            {language === 'portugues' ? (
                                                                <PlanPrice
                                                                    style={{ fontSize: '32px' }}
                                                                >
                                                                    Personalizado
                                                                </PlanPrice>
                                                            ) : (
                                                                <PlanPrice>Custom</PlanPrice>
                                                            )}
                                                        </PlanPriceGroup>
                                                    </PlanPricePanel>
                                                </PlanTitlePanel>
                                                <FeaturesPanel>
                                                    <FeatureTitle>
                                                        {language === 'portugues'
                                                            ? 'Precisa de mais?'
                                                            : 'Need more?'}
                                                    </FeatureTitle>
                                                    <FeatureDescription>
                                                        {language === 'portugues'
                                                            ? 'Preços e Planos'
                                                            : 'Contact us if your supply chain have specific needs'}
                                                    </FeatureDescription>
                                                    <ItemsPanel>
                                                        {includes.length > 0 ? (
                                                            includes
                                                                .filter(
                                                                    (item) =>
                                                                        item.type === 'enterprise'
                                                                )
                                                                .map((item, key) => (
                                                                    <Item key={key}>
                                                                        <ItemIco />
                                                                        <ItemText>
                                                                            {item.item}
                                                                        </ItemText>
                                                                    </Item>
                                                                ))
                                                        ) : (
                                                            <Load color="#263640" />
                                                        )}
                                                    </ItemsPanel>
                                                    <ButtonPlan onClick={contactus}>
                                                        {language === 'portugues'
                                                            ? 'Entre em contato'
                                                            : 'Contact Us'}
                                                    </ButtonPlan>
                                                </FeaturesPanel>
                                            </PlanContent>
                                        </PlansBodyCentered>
                                    </PageCard>
                                </Content>
                            </Col>
                        </Row>
                    </Container>
                </ContainerAuthenticated>
            </>
        )
    } else {
        let options = {
            appearance: { theme: 'flat', labels: 'floating' },
        }

        return (
            <>
                <ContainerAuthenticated full={true}>
                    <Container fluid={true} style={{ margin: 0, padding: 0 }}>
                        <Row style={{ margin: 0, padding: 0 }}>
                            <Col style={{ margin: 0, padding: 0 }}>
                                <PageTitle>
                                    {language === 'portugues'
                                        ? 'Preços e Planos'
                                        : 'Pricing and Plans'}
                                </PageTitle>
                                <PageSubTitle></PageSubTitle>

                                <Content style={{ margin: 0, borderTop: '1px solid #dddddd' }}>
                                    xxxx
                                </Content>
                            </Col>
                        </Row>
                    </Container>
                </ContainerAuthenticated>
            </>
        )
    }
}
