import React, { useContext } from 'react'

import { SuccessText } from './styled'

import ModalWrapper from '../Wrapper'
import { CoreContext } from 'context/CoreContext'

export default function ModalTerms() {
    const { modal } = useContext(CoreContext)

    return (
        <>
            <ModalWrapper>
                <SuccessText>{modal?.text}</SuccessText>
            </ModalWrapper>
        </>
    )
}
