import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import Card from '../Card/index.js'

import { PageCard, CardHeader, CardHeaderTitleContainer } from './styled'
import _ from 'lodash'

export default function SelfAssessmentChecklist({
    certifications,
    onChangeCertifications,
    saveCertifications,
    saveDocument,
    form,
    returnHeaders,
    mfsQuestionarie
}) {
    const { findlanguageEsg } = useContext(CoreContext) 

    //#region G.1.3
    const g13 = {
        ref: 'G.1.3',
        requirementTitle: findlanguageEsg().g13RequirementTitle,
        inputForYesAnswer: true,
        placeHolderTextYes: findlanguageEsg().g13PlaceHolderTextYes,
        inputForNoAnswer: false,
        placeHolderTextNo: findlanguageEsg().g13PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    //#region G.1.3 Yes

    //#region G.1.3.Y.A

    const g13ya = {
        ref: 'G.1.3.Y.A',
        requirementTitle: findlanguageEsg().g13yaRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g13yaPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const g13yay = {
        ref: 'G.1.3.Y.A.Y',
        requirementTitle: findlanguageEsg().g13yayRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g13yayPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const g13yayy = {
        ref: 'G.1.3.Y.A.Y.Y',
        requirementTitle: findlanguageEsg().g13yayyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g13yayyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
    }

    const g13yayyql1 = {
        ref: 'G.1.3.Y.A.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().g13yayyqlRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g13yayyqlPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        fileForYesAnswerAndNoInput: true,
        color: '#2f8c5d',
        question: true,
    }

    //#endregion

    //#region G.1.3.Y.B

    const g13yb = {
        ref: 'G.1.3.Y.B',
        requirementTitle: findlanguageEsg().g13ybRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g13ybPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const g13yby = {
        ref: 'G.1.3.Y.B.Y',
        requirementTitle: findlanguageEsg().g13ybyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: findlanguageEsg().g13ybyPlaceHolderTextYes,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g13ybyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#ffff00',
        question: true,
    }

    const g13ybyyqt1 = {
        ref: 'G.1.3.Y.B.Y.Y.QT.1',
        requirementTitle: findlanguageEsg().g13ybyyqt1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const g13ybyyqt2 = {
        ref: 'G.1.3.Y.B.Y.Y.QT.2',
        requirementTitle: findlanguageEsg().g13ybyyqt2RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const g13ybyyqtn = {
        ref: 'G.1.3.Y.B.Y.Y.QT.N',
        requirementTitle: findlanguageEsg().g13ybyyqtnRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: ``,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: false,
    }

    const g13ybyyql1 = {
        ref: 'G.1.3.Y.B.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().g13ybyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g13ybyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#2f8c5d',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.C

    const g13yc = {
        ref: 'G.1.3.Y.C',
        requirementTitle: findlanguageEsg().g13ycRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g13ycPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const g13ycy = {
        ref: 'G.1.3.Y.C.Y',
        requirementTitle: findlanguageEsg().g13ycyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g13ycyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const g13ycyy = {
        ref: 'G.1.3.Y.C.Y.Y',
        requirementTitle: findlanguageEsg().g13ycyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g13ycyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const g13ycyyql1 = {
        ref: 'G.1.3.Y.C.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().g13ycyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g13ycyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#region A.1.1.Y.D

    const g13yd = {
        ref: 'G.1.3.Y.D',
        requirementTitle: findlanguageEsg().g13ydRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g13ydPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const g13ydy = {
        ref: 'G.1.3.Y.D.Y',
        requirementTitle: findlanguageEsg().g13ydyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g13ydyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const g13ydyy = {
        ref: 'G.1.3.Y.D.Y.Y',
        requirementTitle: findlanguageEsg().g13ydyyRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g13ydyyPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }

    const g13ydyyql1 = {
        ref: 'G.1.3.Y.D.Y.Y.QL.1',
        requirementTitle: findlanguageEsg().g13ydyyql1RequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g13ydyyql1PlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
        fileForYesAnswerAndNoInput: true,
    }

    //#endregion

    //#endregion

    //#region A.1.1 NO
    const g13na = {
        ref: 'G.1.3.N.A',
        requirementTitle: findlanguageEsg().g13naRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g13naPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const g13nb = {
        ref: 'G.1.3.N.B',
        requirementTitle: findlanguageEsg().g13nbRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g13nbPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    const g13nc = {
        ref: 'G.1.3.N.C',
        requirementTitle: findlanguageEsg().g13ncRequirementTitle,
        inputForYesAnswer: false,
        placeHolderTextYes: ``,
        inputForNoAnswer: true,
        placeHolderTextNo: findlanguageEsg().g13ncPlaceHolderTextNo,
        nextLevel: null,
        uploadFile: false,
        color: '#993366',
        question: true,
    }
    //#endregion

    const returnCondition = (item, layers, category1, category2, category3, category4) => {
        switch (layers) {
            case 0:
                return (
                    <Card
                        item={item}
                        onChangeCertifications={onChangeCertifications}
                        saveCertifications={saveCertifications}
                        saveDocument={saveDocument}
                        certifications={certifications}
                        form={form}
                        mfsQuestionarie={mfsQuestionarie}
                    />
                )
                break
            case 1:
                if (certifications?.find((x) => x.category === category1)?.next_level === true)
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 2:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 3:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            case 4:
                if (
                    certifications?.find((x) => x.category === category1)?.next_level === true &&
                    certifications?.find((x) => x.category === category2)?.next_level === true &&
                    certifications?.find((x) => x.category === category3)?.next_level === true &&
                    certifications?.find((x) => x.category === category4)?.next_level === true
                )
                    return (
                        <Card
                            item={item}
                            onChangeCertifications={onChangeCertifications}
                            saveCertifications={saveCertifications}
                            saveDocument={saveDocument}
                            certifications={certifications}
                            form={form}
                            mfsQuestionarie={mfsQuestionarie}
                        />
                    )
                break
            default:
                break

        }
    }

    return (
        <>
            <PageCard>
                <CardHeader>
                    <CardHeaderTitleContainer>
                        {findlanguageEsg().g13CardHeaderTitleContainer}
                    </CardHeaderTitleContainer>
                </CardHeader>
                {returnHeaders()}
                {returnCondition(g13, 0)}
            </PageCard>
            {
                mfsQuestionarie !== '1' ?
                    <>

                        {certifications?.find((x) => x.category === 'G.1.3')?.next_level === true ? (
                            <>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().g13aCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(g13ya, 1, 'G.1.3')}
                                    {returnCondition(g13yay, 2, 'G.1.3', 'G.1.3.Y.A')}
                                    {returnCondition(g13yayy, 3, 'G.1.3', 'G.1.3.Y.A', 'G.1.3.Y.A.Y')}
                                    {returnCondition(
                                        g13yayyql1,
                                        4,
                                        'G.1.3',
                                        'G.1.3.Y.A',
                                        'G.1.3.Y.A.Y',
                                        'G.1.3.Y.A.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().g13bCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(g13yb, 1, 'G.1.3')}
                                    {returnCondition(g13yby, 2, 'G.1.3', 'G.1.3.Y.B')}
                                    {returnCondition(g13ybyyqt1, 3, 'G.1.3', 'G.1.3.Y.B', 'G.1.3.Y.B.Y')}
                                    {returnCondition(g13ybyyqt2, 3, 'G.1.3', 'G.1.3.Y.B', 'G.1.3.Y.B.Y')}
                                    {returnCondition(g13ybyyqtn, 3, 'G.1.3', 'G.1.3.Y.B', 'G.1.3.Y.B.Y')}
                                    {returnCondition(g13ybyyql1, 3, 'G.1.3', 'G.1.3.Y.B', 'G.1.3.Y.B.Y')}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().g13cCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(g13yc, 1, 'G.1.3')}
                                    {returnCondition(g13ycy, 2, 'G.1.3', 'G.1.3.Y.C')}
                                    {returnCondition(g13ycyy, 3, 'G.1.3', 'G.1.3.Y.C', 'G.1.3.Y.C.Y')}
                                    {returnCondition(
                                        g13ycyyql1,
                                        4,
                                        'G.1.3',
                                        'G.1.3.Y.C',
                                        'G.1.3.Y.C.Y',
                                        'G.1.3.Y.C.Y.Y'
                                    )}
                                </PageCard>
                                <PageCard>
                                    <CardHeader>
                                        <CardHeaderTitleContainer>
                                            {findlanguageEsg().g13dCardHeaderTitleContainer}
                                        </CardHeaderTitleContainer>
                                    </CardHeader>
                                    {returnHeaders()}
                                    {returnCondition(g13yd, 1, 'G.1.3')}
                                    {returnCondition(g13ydy, 2, 'G.1.3', 'G.1.3.Y.D')}
                                    {returnCondition(g13ydyy, 3, 'G.1.3', 'G.1.3.Y.D', 'G.1.3.Y.D.Y')}
                                    {returnCondition(
                                        g13ydyyql1,
                                        4,
                                        'G.1.3',
                                        'G.1.3.Y.D',
                                        'G.1.3.Y.D.Y',
                                        'G.1.3.Y.D.Y.Y'
                                    )}
                                </PageCard>
                            </>
                        ) : null}
                        {certifications?.find((x) => x.category === 'G.1.3')?.next_level === false ? (
                            <PageCard>
                                <CardHeader>
                                    <CardHeaderTitleContainer>
                                        {findlanguageEsg().g13noCardHeaderTitleContainer}
                                    </CardHeaderTitleContainer>
                                </CardHeader>
                                {returnHeaders()}
                                {returnCondition(g13na, 0)}
                                {returnCondition(g13nb, 0)}
                                {returnCondition(g13nc, 0)}
                            </PageCard>
                        ) : null}
                    </>
                    :
                    null
            }
        </>
    )
}
